import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'
import classnames from 'classnames'
import { Dropdown, OverflowCenter, Badge } from '@wiz/components'
import { toArrayValue } from '@wiz/utils'
import { Sensor, DataSource } from '@wiz/store'
import ObserveModel from '@/containers/ObserveModel'
import DataSourceExplorer from '@/containers/Form/DataSourceExplorer'
import classes from './index.css'

const SelectDataSources = forwardRef(({
  name,
  className,
  value,
  disabled,
  placeholder,
  batchOnly,
  multiselect,
  invalid,
  unique = false,
  hasImportant,
  onChange,
  onBlur,
}, ref) => {
  const refTarget = useRef()
  const refDropdown = useRef()
  const arrayValue = useMemo(() => toArrayValue(value).filter(Boolean), [ value ])
  const ids = useMemo(() => arrayValue.map(item => (item.sensorId || item.id)), [ arrayValue ])

  const handleChange = useCallback((option) => {
    let next = null
    if (multiselect) {
      next = arrayValue
      if (option === null) {
        next = []
      } else if (unique && next.some(item => (
        (item.id && item.id === option.id) ||
        (
          item.sensorId &&
          item.dataType &&
          item.sensorId === option.sensorId &&
          item.dataType === option.dataType
        )
      ))) {
        next = next.filter(item => !(
          (item.id && item.id === option.id) ||
          (
            item.sensorId &&
            item.dataType &&
            item.sensorId === option.sensorId &&
            item.dataType === option.dataType
          )
        ))
      } else {
        next = next.concat(option)
      }
    } else {
      refDropdown.current.close()
      next = option
    }
    onChange?.(next)
  }, [
    unique,
    arrayValue,
    multiselect,
    onChange,
  ])

  const handleRemove = useCallback(({ id, sensorId, dataType }) => {
    let next = null
    if (multiselect) {
      next = arrayValue.filter(item => !(
        (
          item.sensorId &&
          item.dataType &&
          item.sensorId === sensorId &&
          item.dataType === dataType
        ) ||
        (item.id && item.id === id)
      ))
    }
    onChange?.(next)
  }, [ multiselect, arrayValue, onChange ])

  useImperativeHandle(ref, () => ({
    focus () {
      refTarget.current.focus()
    },
  }), [])

  return (
    <>
      <button
        ref={refTarget}
        type="button"
        name={name}
        className={classnames('text-start form-select text-truncate d-flex align-items-center', className, {
          'is-invalid': !!invalid,
        })}
        disabled={disabled}
        onBlur={onBlur}
      >
        {do {
          if (arrayValue.length) {
            <span className={classnames('d-flex align-items-center flex-wrap min-w-0', classes.values)}>
              {arrayValue.map((item, idx) => {
                const displayName = DataSource.getDisplayName(item, false)
                return (
                  <Badge
                    key={`${item.id || idx}/${item.sensorId}/${item.dataType}`}
                    className={classnames('bg-secondary', classes.value)}
                    disabled={disabled}
                    onClose={(event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      handleRemove(item)
                    }}
                  >
                    {item.sensorId ? (
                      <ObserveModel model={Sensor} value={item.sensorId}>
                        {({ data }) => (
                          <>
                            <OverflowCenter
                              value={data?.[0]?.displayName || displayName}
                              className="extra-separate"
                            />
                            {item.dataType ? (
                              <span className="extra-separate">{item.dataType}</span>
                            ) : null}
                          </>
                        )}
                      </ObserveModel>
                    ) : (
                      <OverflowCenter
                        value={displayName}
                        className="extra-separate"
                      />
                    )}
                  </Badge>
                )
              })}
            </span>
          } else if (placeholder) {
            <span className="text-truncate text-muted">
              {placeholder}
            </span>
          } else {
            <>
              &nbsp;
            </>
          }
        }}
      </button>

      <Dropdown
        target={refTarget}
        ref={refDropdown}
        placement="bottom-end"
        size="md"
        height={300}
        arrow
      >
        {() => (
          <DataSourceExplorer
            autoFocus
            hasImportant={hasImportant}
            value={ids}
            batchOnly={batchOnly}
            onChange={handleChange}
          />
        )}
      </Dropdown>
    </>
  )
})

SelectDataSources.displayName = 'SelectDataSources'
export default SelectDataSources
