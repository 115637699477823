import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { toArrayValue } from '@wiz/utils'

export const enhanceData = ({
  defaultType,
} = {}) => withObservables([
  'search',
  'selectedOnly',
  'value',
  'type',
], ({
  search,
  selectedOnly,
  value,
  type,
}) => {
  let query = dbProvider.database.collections.get('files').query()

  if (search) {
    const sanitizeSearch = Q.sanitizeLikeString(search)
    query = query.extend(Q.or(
      Q.where('name', Q.like(`%${sanitizeSearch}%`)),
      Q.where('description', Q.like(`%${sanitizeSearch}%`)),
    ))
  }

  if (type || defaultType) {
    const queryType = type || defaultType
    query = query.extend(Q.where('type', Array.isArray(queryType) ? Q.oneOf(queryType) : queryType))
  }

  if (selectedOnly) {
    query = query.extend(Q.where('id', Q.oneOf(toArrayValue(value))))
  }

  const observe = selectedOnly ?
    query.observeWithColumns([ 'updated_at' ]) :
    query.observe()

  return {
    options: observe,
  }
})

export default WrappedComponent => enhanceData()(WrappedComponent)
