import { auth } from '@/auth'
import { RIGHTS } from '@/config'
import { ForbiddenError } from '@wiz/utils'

export function createApi (
  client,
  endpoint,
  Constructor,
) {
  return {
    list: {
      enumerable: true,
      value (params, onRequest) {
        if (!auth.checkAccessRules(RIGHTS.READ, Constructor.modelName)) {
          return Promise.reject(new ForbiddenError('', {
            subject: null,
            subjectName: Constructor.modelName,
            action: RIGHTS.READ,
          }))
        }

        const request = client.get(`/${endpoint}`, params)
        onRequest?.(request)

        return request
          .fetch()
          .then(data => data.reduce((out, item) => {
            const instance = new Constructor(item)
            if (auth.checkAccessRules(RIGHTS.READ, instance)) {
              out.push(instance)
            }
            return out
          }, []))
      },
    },
    getById: {
      enumerable: true,
      value (id) {
        if (!auth.checkAccessRules(RIGHTS.READ, Constructor.modelName)) {
          return Promise.reject(new ForbiddenError('', {
            subject: null,
            subjectName: Constructor.modelName,
            action: RIGHTS.READ,
          }))
        }

        return client.get(`/${endpoint}/${id}`)
          .fetch()
          .then((data) => {
            const instance = new Constructor(data)
            auth.throwAccessRules(RIGHTS.READ, instance)
            return instance
          })
      },
    },
    remove: {
      enumerable: true,
      value (data, params) {
        if (!Constructor.is(data)) {
          return Promise.reject(new ForbiddenError('', {
            subject: data,
            subjectName: data?.constructor?.name,
            action: RIGHTS.REMOVE,
          }))
        }

        if (!auth.checkAccessRules(RIGHTS.REMOVE, data)) {
          return Promise.reject(new ForbiddenError('', {
            subject: data,
            subjectName: data?.constructor?.name,
            action: RIGHTS.REMOVE,
          }))
        }

        if (!data.id) {
          return Promise.reject()
        }

        return client.delete(`/${endpoint}/${data.id}`, null, { params }).fetch()
      },
    },
    replace: {
      enumerable: true,
      value (data) {
        if (!Constructor.is(data)) {
          return Promise.reject(new ForbiddenError('', {
            subject: data,
            subjectName: data?.constructor?.name,
            action: 'replace',
          }))
        }

        const isUpdated = Boolean(data.id)

        if (isUpdated && !auth.checkAccessRules(RIGHTS.UPDATE, data)) {
          return Promise.reject(new ForbiddenError('', {
            subject: data,
            subjectName: data?.constructor?.name,
            action: RIGHTS.UPDATE,
          }))
        } else if (!isUpdated && !auth.checkAccessRules(RIGHTS.CREATE, data)) {
          return Promise.reject(new ForbiddenError('', {
            subject: data,
            subjectName: data?.constructor?.name,
            action: RIGHTS.CREATE,
          }))
        }

        const store = data.toStore()
        const request = isUpdated ?
          data => client.put(`/${endpoint}/${data.id}`, data) :
          data => client.post(`/${endpoint}`, data)

        return request(store)
          .fetch()
          .then(data => new Constructor(data))
      },
    },
    groupUpdate: {
      enumerable: true,
      value (data) {
        if (!Array.isArray(data)) {
          return Promise.reject()
        }

        if (!data.length) {
          return Promise.resolve([])
        }

        const instanceErrorObject = data.find(item => !Constructor.is(item))
        if (instanceErrorObject) {
          return Promise.reject(new ForbiddenError('', {
            subject: instanceErrorObject,
            subjectName: instanceErrorObject?.constructor?.name,
            action: RIGHTS.UPDATE,
          }))
        }

        const accessErrorObject = data.find(item => !auth.checkAccessRules(RIGHTS.UPDATE, item))
        if (accessErrorObject) {
          return Promise.reject(new ForbiddenError('', {
            subject: accessErrorObject,
            subjectName: accessErrorObject?.constructor?.name,
            action: RIGHTS.UPDATE,
          }))
        }

        const store = data.map(item => item.toStore())
        return client.put(`/${endpoint}`, store)
          .fetch()
          .then(data => data.map(item => new Constructor(item)))
      },
    },
  }
}
