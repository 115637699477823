import { useMemo } from 'react'
import FormDialog from '@/components/Form/FormDialog'
import { Integration } from '@wiz/store'
import SectionList from './SectionList'
import Sections from './Sections'

export default function IntegrationForm ({
  dialog,
  onClose,
  onSubmit,
  integration,
  integrationByType,
  settings,
}) {
  const defaultValues = useMemo(() => ({
    integration: Integration.toJSON(integration) || integrationByType,
    settings,
  }), [
    integration,
    integrationByType,
    settings,
  ])

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="integrationForm"
      dialog={dialog}
    >
      <SectionList scope="integration" />
      <Sections scope="settings" integration={integrationByType} />
    </FormDialog>
  )
}
