import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { Script, ScriptInterface } from '@wiz/store'
import ObservScript from '@/containers/Forms/Script/Observ'
import SectionScript from './SectionScript'
import SectionScriptInterface from './SectionScriptInterface'

export default function SettingsSectionScript ({ scope }) {
  const {
    register,
    watch,
    setValue,
    clearErrors,
  } = useFormContext()
  const id = watch(`${scope}.script.id`)

  const handleChange = useCallback(({ script, interfaces }) => {
    const next = Script.toJSON(script)
    setValue(`${scope}.id`, next.id, { shouldDirty: true })
    setValue(`${scope}.script`, next, { shouldDirty: true })
    setValue(`${scope}.interfaces`, interfaces.map(item => (
      ScriptInterface.toJSON(item)
    )), { shouldDirty: true })
    clearErrors(scope)
  }, [ scope, setValue, clearErrors ])

  return (
    <>
      <ObservScript id={id} onChange={handleChange} />

      <input
        {...register(`${scope}.id`)}
        type="hidden"
        defaultValue={id}
      />

      <SectionScript scope={`${scope}.script`} />
      <SectionScriptInterface scope={`${scope}.interfaces`} />
    </>
  )
}
