import {
  useState, useEffect, useRef, useCallback,
} from 'react'
import { wizataApi } from '@/api'
import { consts } from '@wiz/utils'
import { withProps, withPagination, withSort } from '@wiz/components'
import Component from '@/components/Plots/TableMobile'

const SortByFields = {
  createdAt: 'createdDate',
  name: 'name',
}

const SortDirection = {
  asc: 'Ascending',
  desc: 'Descending',
}

const enhanceProps = withProps(({
  filters,
}) => {
  const [ loading, setLoading ] = useState(true)
  const [ items, setItems ] = useState([])
  const [ total, setTotal ] = useState(0)
  const refresh = useRef(null)

  const handleUpdate = useCallback(() => {
    setLoading(true)

    const limit = filters.pageSize + 1
    const offset = (filters.page - 1) * filters.pageSize

    wizataApi.entities.getList('plots', {
      pagination: {
        take: limit - 1,
        skip: offset,
      },
      sortingList: [
        {
          direction: SortDirection[filters.sortDir],
          propertyName: SortByFields[filters.sortBy],
        },
      ],
      filters: [
        {
          type: consts.FilterType.Text,
          operationType: consts.FilterOperationType.Contains,
          value: filters.search,
          // start: 'string',
          // end: 'string',
          propertyName: 'name',
        },
      ],
    }).then((data) => {
      setTotal(data.totalCount)
      setItems(data.items.slice(0, filters.pageSize))
    })
      .finally(() => {
        refresh.current = filters
        setLoading(false)
      })
  }, [ filters ])

  useEffect(() => {
    handleUpdate()
  }, [ handleUpdate ])

  return {
    total,
    items,
    loading,
  }
})

export default enhanceProps(
  withSort({ localData: false })(
    withPagination({ localData: false })(Component),
  ),
)
