import { map } from 'rxjs/operators'
import { toArrayValue, orderBy } from '@wiz/utils'
import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'

export default withObservables([
  'selectedOnly',
  'search',
  'value',
], ({
  selectedOnly,
  search,
  value,
}) => {
  let query = dbProvider.database.collections.get('scripts').query()

  if (search) {
    const sanitizeSearch = Q.sanitizeLikeString(search)
    query = query.extend(Q.where('name', Q.like(`%${sanitizeSearch}%`)))
  }

  if (selectedOnly) {
    query = query.extend(Q.where('id', Q.oneOf(toArrayValue(value))))
  }

  const observe = selectedOnly ?
    query.observeWithColumns([ 'updated_at' ]) :
    query.observe()

  return {
    options: observe
      .pipe(
        map(items => orderBy(items, [ item => item.name.toLowerCase() ], [ 'asc' ])),
      ),
  }
})
