import { Icon } from '@wiz/components'
import classnames from 'classnames'
import classes from './Marker.css'

export default function Marker ({
  icon,
  title,
  loading,
  status,
}) {
  return (
    <div className={classes.root}>
      <div
        className={classnames(classes.marker, {
          'bg-warning': status === 'normal',
          'bg-danger': status === 'bad',
        })}
      >
        {icon || loading ? (
          <Icon
            className={classes.img}
            name={loading ? 'fa--spinner' : icon}
            size="sm"
            spin={loading}
          />
        ) : null}
      </div>
    </div>
  )
}
