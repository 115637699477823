const apiByEntity = {
  plots: 'Plots',
  mlmodels: 'MlModels',
  dataframes: 'Dataframes',
  executions: 'Executions',
  scripts: 'Scripts',
  experiments: 'Experiments',
  alerts: 'Alerts',
  executionTriggers: 'ExecutionTriggers',
  pipelines: 'Pipelines',
  components: 'Components',
  datapoints: 'DataPoints',
}

export default function entities (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      entities: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value (entity, params) {
              const request = client.post(`/${apiByEntity[entity]}/paged`, { ...params })
              return request.fetch()
            },
          },
          getGroupedList: {
            enumerable: true,
            value (entity, params) {
              const request = client.post(`/${apiByEntity[entity]}/grouped`, { ...params })
              return request.fetch()
            },
          },

          getById: {
            enumerable: true,
            value (entity, id) {
              return client.get(`/${apiByEntity[entity]}/${id}`).fetch()
            },
          },

          deleteById: {
            enumerable: true,
            value (entity, id) {
              return client.delete(`/${apiByEntity[entity]}/${id}`).fetch()
            },
          },
        }),
      },
    })
  }
}
