import { forwardRef, useState, useCallback } from 'react'
import classnames from 'classnames'
import LibraryList from '@/hoc/LibraryList'
import List from '@/components/Form/Tree/List'
import ItemContent from './ItemContent'

const BindedLibraryList = LibraryList(List)

const ListLibraryItems = ({
  businessType,
  canInputValue,
  className,
  Content = ItemContent,
  draggable,
  onResetFilter,
  postfix,
  filterBusinessTypes,
  selectable,
  unusedOnly,
  ...props
}, ref) => (
  <div className={classnames('d-flex flex-column', className)}>
    <div className="d-flex flex-wrap mx-3 mb-1">
      <div className="d-flex align-items-center flex-wrap">
        {postfix}
      </div>
    </div>

    <BindedLibraryList
      {...props}
      ref={ref}
      className="d-flex flex-fill min-h-0"
      canInputValue={canInputValue}
      Content={Content}
      draggable={draggable}
      selectable={selectable}
    />
  </div>
)

export default forwardRef(ListLibraryItems)
