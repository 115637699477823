import { useCallback, memo } from 'react'
import { dbProvider, Formula } from '@wiz/store'
import { withProps } from '@wiz/components'
import FormFormula from '@/components/Forms/Formula'
import enhanceFormula from './enhanceFormula'
import enhanceUsage from './enhanceUsage'

const enhanceProps = withProps(() => {
  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareReplaceData(context, Formula, data.formula)
    await dbProvider.batch(context)
  }, [])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceFormula(
    enhanceUsage(
      enhanceProps(FormFormula),
    ),
  ),
)
