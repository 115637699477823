import { useState, useCallback, useEffect } from 'react'
import { Card, Pagination } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import Timeline from '../containers/Timeline'

export default function Widget ({
  config,
  widget,
  hidden,
  canUpdateDashboard,
  onAction,
}) {
  const intl = useIntl()
  const auth = useAuth()
  const [ pageSize ] = useState(50)
  const [ total, setTotal ] = useState(0)
  const [ currentPage, setCurrentPage ] = useState(1)

  const actions = []
  if (canUpdateDashboard) {
    actions.push({ id: 'settings', name: intl.t('form.actions.settings'), icon: 'fa--cog' })
    if (auth.checkAccessCopy(widget)) {
      actions.push({ id: 'duplicate', name: intl.t('form.actions.duplicate'), icon: 'fa--clone' })
      actions.push({ id: 'multiDuplicate', name: intl.t('form.actions.multiDuplicate'), icon: 'fa--folder-plus' })
    }
    actions.push({ id: 'remove', name: intl.t('form.actions.remove'), icon: 'fa--trash-alt' })
  }

  const handleClickPage = useCallback((page) => {
    let nextPage = currentPage
    if (page === 'prev') {
      nextPage -= 1
    } else if (page === 'next') {
      nextPage += 1
    } else if (page === 'first') {
      nextPage = 1
    } else if (Number.isFinite(page)) {
      nextPage = page
    }

    setCurrentPage(nextPage)
  }, [ currentPage ])

  useEffect(() => {
    setCurrentPage(1)
  }, [ config ])

  return (
    <Card
      className="position-absolute-fill"
      classNameTitle={canUpdateDashboard ? 'userdrag' : undefined}
      classNameBody="m-0"
      title={widget.title || intl.t(widget.info.name)}
      description={widget.description}
      options={actions}
      onAction={onAction}
    >
      <Timeline
        hidden={hidden}
        config={config}
        currentPage={currentPage}
        pageSize={pageSize}
        onChangeCount={setTotal}
      />

      {total ? (
        <Pagination
          className="justify-content-center"
          pageNavigation={false}
          pageSizeNavigation={false}
          showTotal={false}
          currentPage={currentPage}
          pageSize={pageSize}
          total={total}
          alwaysFastFirst
          relative
          onClickPage={handleClickPage}
        />
      ) : null}
    </Card>
  )
}
