import { StreamJob } from '@wiz/store'
import {
  Icon,
  OverflowCenter,
} from '@wiz/components'
import { useAuth } from '@/auth'
import ObserveModel from '@/containers/ObserveModel'
import Link from '@/components/Link'

export default function CellStreamJob ({
  streamJobId,
  onFilter,
}) {
  const auth = useAuth()

  return (
    <ObserveModel model={StreamJob} value={streamJobId} field="block_id">
      {({ data }) => {
        if (!data[0]) {
          return '-'
        }

        if (!auth.checkAccessRead(data[0])) {
          return null
        }

        return (
          <>
            <button
              type="button"
              className="btn btn-sm p-0 border-0 me-2"
              onClick={() => onFilter({ streamJobId })}
            >
              <Icon
                name="fa--search"
              />
            </button>

            {auth.checkAccessRead('SectionStreamJobs') ? (
              <Link
                name="stream-jobs-view"
                params={{ id: streamJobId }}
                className="min-w-0 d-inline-flex"
              >
                <OverflowCenter value={data[0].name} />
              </Link>
            ) : (
              <div className="min-w-0 d-inline-flex">
                <OverflowCenter value={data[0].name} />
              </div>
            )}
          </>
        )
      }}
    </ObserveModel>
  )
}
