import { useCallback, useContext } from 'react'
import { dbProvider } from '@wiz/store'
import { withProps } from '@wiz/components'
import { AppContext } from '@/pages/context'
import { OperateContext } from '@/pages/context/operate'
import { wizataApi } from '@/api'
import events from '@/utils/events'
import withTableActions from '@/containers/withTableActions'
import List from '@/components/StreamJobs/List'

const enhanceProps = withProps(() => {
  const { filters } = useContext(AppContext)
  const { contextTwinId } = useContext(OperateContext)
  const onRemove = useCallback(async (data) => {
    await window.wizConfirm({ message: 't/units.confirmDelete' })
    const context = dbProvider.createBatchContext()
    for (const item of data) {
      if (item.blockId) {
        try {
          const block = await item.block.fetch()
          await block.prepareRemove(context)
        } catch (error) {
          await item.prepareRemove(context)
        }
      } else {
        await item.prepareRemove(context)
      }
    }
    await dbProvider.batch(context)
  }, [])

  const onToggleActive = useCallback(async (data) => {
    let hasError = false

    if (!data.active) {
      try {
        await wizataApi.ml.testModel(data.id)
      } catch (error) {
        hasError = true
        if (error.message) {
          const rawMessage = error?.original?.response?.data?.errors?.join('<br>') ?? error.message
          events.emit('app:notify', {
            type: 'error',
            title: 't/mlworkflow.title',
            duration: -1,
            rawMessage,
          })
        }
      }
    }

    if (!hasError) {
      const context = dbProvider.createBatchContext()
      await data.prepareToggleActive(context)
      await dbProvider.batch(context)
    }
  }, [])

  return {
    contextFilters: {
      ...filters,
      twins: filters.twins || contextTwinId,
    },
    onRemove,
    onToggleActive,
  }
})

export default withTableActions()(
  enhanceProps(List),
)
