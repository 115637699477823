import { useMemo } from 'react'
import filesize from 'filesize'
import { File as File$ } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import { FormField } from '@wiz/components'
import FormDialog from '@/components/Form/FormDialog'
import ButtonFileDownload from '@/components/Form/ButtonFileDownload'
import Removed from '../Removed'
import Section from './Section'

export default function File ({
  id,
  file,
  projects,
  dialog,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()
  const defaultValues = useMemo(() => ({
    file: File$.toJSON(file),
    projects,
  }), [
    file?.updatedAt ?? file,
    projects,
  ])

  if (id && !file) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="fileForm"
      dialog={dialog}
    >
      <Section
        scope="file"
      >
        {defaultValues.file.type ? (
          <FormField
            label={intl.t('files.form.fields.fileType')}
            classNameControl="d-flex align-items-center"
            complex
          >
            {defaultValues.file.type}
          </FormField>
        ) : null}

        {defaultValues.file.contentType ? (
          <FormField
            label={intl.t('files.form.fields.contentType')}
            classNameControl="d-flex align-items-center"
            complex
          >
            {defaultValues.file.contentType}
          </FormField>
        ) : null}

        {defaultValues.file.id ? (
          <FormField
            label={intl.t('files.form.fields.size')}
            classNameControl="d-flex align-items-center"
            complex
          >
            <ButtonFileDownload file={defaultValues.file}>
              {filesize(parseInt(defaultValues.file.length || 0, 10))}
            </ButtonFileDownload>
          </FormField>
        ) : null}
      </Section>
    </FormDialog>
  )
}
