import {
  type,
  intersection,
} from 'superstruct'
import { consts, DataTypes } from '@wiz/utils'
import * as types from '@/utils/types'

export default type({
  histogramType: DataTypes.DefaultNullValue(
    DataTypes.NullableEnum(consts.WidgetHistogramTypes),
    consts.WidgetHistogramType.Linear,
  ),
  normalize: DataTypes.NullableEnum(consts.WidgetHistogramNormalizeTypes),
  cumulative: DataTypes.StructBoolean(),
  infinity: DataTypes.StructBoolean(),
  minValue: intersection([
    DataTypes.NullableNumber(),
    types.LimitMax({ keyMax: 'maxValue' }),
  ]),
  maxValue: intersection([
    DataTypes.NullableNumber(),
    types.LimitMax({ keyMin: 'minValue' }),
  ]),
  maxNumBuckets: DataTypes.NullableNumber(),
  bucketSize: DataTypes.NullableNumber(),
  factor: DataTypes.NullableNumber(),
  bins: DataTypes.NullableArray(DataTypes.CreateSchema({
    id: DataTypes.StructUuid(),
    name: intersection([
      types.Maxlen(),
      DataTypes.NullableString(50),
    ]),
    prevValue: intersection([
      DataTypes.NullableNumber(),
      types.BinPrevValue(),
    ]),
    value: intersection([
      DataTypes.StructNumber(),
      types.BinValue(),
    ]),
  }), 20, 0),
})
