import { useContext } from 'react'
import { withProps } from '@wiz/components'
import DataExplorer from '@/context/DataExplorer'

export default withProps(({ dataSource }) => {
  const context = useContext(DataExplorer)
  dataSource = context?.data?.dataSources?.find(item => (
    item.id === dataSource.id
  )) || dataSource

  return {
    dataSource,
  }
})
