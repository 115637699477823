import '@uppy/status-bar/dist/style.css'
import { useEffect } from 'react'
import classnames from 'classnames'
import { useUniqueId } from '@wiz/components'
import { StatusBarUploader as Uploader } from '@/utils/files'
import classes from './StatusBarUploader.css'

export default function StatusBarUploader () {
  const id = useUniqueId('StatusBarUploader')

  useEffect(() => {
    const progress = new Uploader({
      target: `#${id}`,
    })

    return () => {
      progress.destroy()
    }
  }, [ id ])

  return (
    <div
      id={id}
      className={classnames('UppyInput-Progress', classes.root)}
    />
  )
}
