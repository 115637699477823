import { withProps } from '@wiz/components'

const enhanceData = withProps(({
  search,
  selectedOnly,
  keyName = 'id',
  options = [],
  value,
  isFilteringByTwin,
  twinId,
} = {}) => {
  let next = options

  if (search) {
    next = next.filter(item => (
      item.name &&
      item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
    ))
  }

  if (selectedOnly) {
    const isArrayValue = Array.isArray(value)
    next = next.filter(item => (
      item[keyName] &&
      (isArrayValue ? value.includes(item[keyName]) : value === item[keyName])
    ))
  }
  if (isFilteringByTwin) {
    next = next.filter(item => item.twinId === twinId)
  }

  return {
    options: next,
  }
})

export default WrappedComponent => enhanceData(WrappedComponent)
