import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withObservables } from '@wiz/components'
import { dbProvider } from '@wiz/store'
import StatusBarUploader from './StatusBarUploader'
import classes from './index.css'

const enhanceSettings = withObservables([], () => ({
  isStatusbarOpen: dbProvider.observeSettings('isStatusbarOpen'),
}))

const StatusBar = ({
  isStatusbarOpen,
}) => {
  if (!isStatusbarOpen) {
    return null
  }

  return (
    <section className={classnames(classes.root, 'border-top')}>
      <div className={classes.content}>
        <StatusBarUploader />
      </div>
    </section>
  )
}

StatusBar.propTypes = {
  isStatusbarOpen: PropTypes.bool,
}

StatusBar.defaultProps = {
  isStatusbarOpen: false,
}

export default enhanceSettings(StatusBar)
