import { useContext } from 'react'
import { OverflowCenter } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import DataExplorerEnv from '@/context/DataExplorerEnv'

export default function ExplorerListComponent ({
  value,
  search,
  onClick,
}) {
  const intl = useIntl()
  const { openExplorer } = useContext(DataExplorerEnv)

  return (
    <button
      type="button"
      className="btn p-0 border-0 text-reset min-w-0"
      onClick={() => {
        onClick?.()
        openExplorer(value.id)
      }}
    >
      {value.title ? (
        <OverflowCenter
          value={value.title}
          mark={search}
        />
      ) : intl.t('form.noname')}
    </button>
  )
}
