import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import { get } from '@wiz/utils'
import {
  FormField, FormControl,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import FormTemplates from '@/components/Form/ListTemplates'
import FormPipelines from '@/components/Form/ListPipelines'

export default function Fields ({ scope, templateId }) {
  const intl = useIntl()
  const { watch, formState: { errors }, getValues } = useFormContext()

  const [ key ] = watch([ `${scope}.key` ])

  return (
    <>
      <FormField
        label={intl.t('experiments.form.fields.key')}
        errors={get(errors, `${scope}.key`)}
      >
        <span className="form-control text-truncate text-muted">{key}</span>
      </FormField>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('experiments.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.template')}
        description={intl.t('sensors.form.fields.templateDescr')}
        errors={get(errors, `${scope}.templateId`)}
      >
        <Controller
          name={`${scope}.templateId`}
          render={({ field, fieldState }) => (
            <FormTemplates
              {...field}
              invalid={fieldState.invalid}
              placeholder="--"
              disabled={!!templateId}
            />
          )}
        />
      </FormField>
      <FormField
        label={intl.t('form.fields.pipeline')}
        description={intl.t('sensors.form.fields.pipelineDescr')}
        errors={get(errors, `${scope}.pipelineId`)}
      >
        <Controller
          name={`${scope}.pipelineId`}
          render={({ field, fieldState }) => (
            <FormPipelines
              {...field}
              invalid={fieldState.invalid}
              placeholder="--"
            />
          )}
        />
      </FormField>
    </>
  )
}
