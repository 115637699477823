import { useCallback, useEffect } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'
import {
  FormField,
  FormFieldInline,
  FormControl,
} from '@wiz/components'
import { get, debounce } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { wizataApi } from '@/api'
import Select from '@/components/Form/Select'

export default function FieldsView ({ scope }) {
  const intl = useIntl()
  const { formState: { errors }, watch } = useFormContext()

  const dashboard = watch(`${scope}.dashboard`)
  const source = watch(`${scope}.source`)

  const { data: grafanaPanelsList, isLoading, refetch } = useQuery({
    queryKey: [ 'grafanaPanelsList' ],
    queryFn: () => wizataApi.grafana.getList({ source: source || 'wizata', dashboard }),
    enabled: true,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  })

  const { data: grafanaDashboardsList, isLoading: isDashboardsLoading } = useQuery({
    queryKey: [ 'grafanaDashboardsList' ],
    queryFn: () => wizataApi.grafana.getDashboards()
      .then(items => items.map(item => ({ ...item, name: item.title }))),
    enabled: true,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  })

  const debouncedSubmit = useCallback(debounce(() => refetch(), 1000), [ refetch ])

  useEffect(() => {
    if (grafanaPanelsList && grafanaDashboardsList) {
      refetch()
    }
  }, [ dashboard, grafanaDashboardsList, grafanaPanelsList, refetch ])

  return (
    <>
      <FormField
        label={intl.t('widgets.grafana.form.fields.source')}
        description={intl.t('widgets.grafana.form.fields.sourceDescr')}
        errors={get(errors, `${scope}.source`)}
      >
        <FormControl
          name={`${scope}.source`}
          placeholder="wizata"
          onChange={debouncedSubmit}
        />
      </FormField>

      <FormField
        label={intl.t('widgets.grafana.form.fields.dashboards')}
        description={intl.t('widgets.grafana.form.fields.dashboardsDescr')}
        errors={get(errors, `${scope}.dashboard`)}
      >
        <Controller
          name={`${scope}.dashboard`}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              placeholder="--"
              disabled={isDashboardsLoading}
              options={grafanaDashboardsList}
              keyName="uid"
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('widgets.grafana.form.fields.panel')}
        description={intl.t('widgets.grafana.form.fields.panelDescr')}
        errors={get(errors, `${scope}.panelUrl`)}
      >
        <Controller
          name={`${scope}.panelUrl`}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              placeholder="--"
              disabled={isLoading}
              options={grafanaPanelsList}
              keyName="iframe"
            />
          )}
        />
      </FormField>
    </>
  )
}
