/* eslint-disable no-unused-expressions */
import classnames from 'classnames'
import { useDrag } from '@wiz/components'
import { noop, consts } from '@wiz/utils'
import classes from './index.css'

const onDragOver = event => event.preventDefault()
const onDragEnter = event => event.currentTarget.classList.add(classes.drag)
const onDragLeave = event => event.currentTarget.classList.remove(classes.drag)

export default function Option ({
  active,
  children,
  className,
  dataId,
  disabled,
  draggable,
  dragged,
  onClick,
  onDragEnd,
  onDragStart,
  onDrop,
  selectable,
  style,
  toggle,
}) {
  const drag = useDrag()
  const isInternalDrag = !!(
    draggable === true ||
    (draggable && draggable === drag.context.name) ||
    (Array.isArray(draggable) && draggable.includes(drag.context.name))
  )

  return (
    <div
      className={classnames('list-group-item list-group-item-action', className, {
        active,
        'opacity-50': dragged,
      })}
      style={style}
      data-id={dataId}
      {...(draggable && !disabled ? {
        draggable: 'true',
        onDragEnd,
        onDragOver,
        onDragStart: (event) => {
          event.dataTransfer.setData('text/plain', null)
          event.dataTransfer.setDragImage(consts.DragImagesByModelName.Widget, 20, 20)
          onDragStart?.()
        },
        onDrop: dragged || !isInternalDrag ? undefined : (event) => {
          event.preventDefault()
          event.stopPropagation()
          onDrop?.('inner')
        },
      } : undefined)}
      {...(do {
        if (onClick && !disabled && selectable) {
          ({
            onClick,
            onKeyPress: noop,
            tabIndex: 0,
            role: 'button',
          })
        } else if (toggle) {
          ({
            onClick: toggle,
            onKeyPress: noop,
            tabIndex: 0,
            role: 'button',
          })
        }
      })}
    >
      {children}

      {dragged || disabled || !isInternalDrag ? null : (
        <>
          <div
            className={classes.dropBefore}
            onDragOver={onDragOver}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={(event) => {
              event.preventDefault()
              event.stopPropagation()
              onDragLeave(event)
              onDrop?.('before')
            }}
          />
          <div
            className={classes.dropAfter}
            onDragOver={onDragOver}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={(event) => {
              event.preventDefault()
              event.stopPropagation()
              onDragLeave(event)
              onDrop?.('after')
            }}
          />
        </>
      )}
    </div>
  )
}
