import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { User as User$ } from '@wiz/store'
import { FormSection } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import Schema from './Schema'
import Fields from './Fields'
import SectionTwins from './SectionTwins'
import FieldsRoles from './FieldsRoles'
import resolver from './resolver'

export default function UserCreate ({
  id,
  user,
  accessRoleIds,
  twins,
  dialog,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()
  const auth = useAuth()
  const defaultValues = useMemo(() => ({
    user: User$.toJSON(user),
    accessRoleIds,
    twins,
  }), [
    user?.updatedAt ?? user,
    accessRoleIds,
    twins,
  ])

  if (id && !user) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      schema={Schema}
      resolver={resolver}
      defaultValues={defaultValues}
      dataTestid="userCreateForm"
      dialog={dialog}
    >
      <FormSection>
        <Fields scope="user" />

        {auth.checkAccessRead('AccessRole') ? (
          <FieldsRoles scope="accessRoleIds" />
        ) : null}
      </FormSection>

      {auth.checkAccessRead('Twin') ? (
        <SectionTwins
          title={intl.t('form.fields.twins')}
          description={intl.t('users.form.fields.twinsDescr')}
          scope="twins"
        />
      ) : null}

    </FormDialog>
  )
}

UserCreate.propTypes = {
  id: PropTypes.string,
  user: PropTypes.object,
  dialog: PropTypes.object,
  accessRoleIds: PropTypes.arrayOf(PropTypes.string),
  twins: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

UserCreate.defaultProps = {
  id: undefined,
  user: undefined,
  dialog: undefined,
  accessRoleIds: [],
  twins: [],
  onClose: undefined,
  onSubmit: undefined,
}
