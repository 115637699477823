import { forwardRef } from 'react'
import SensorList from '@/hoc/SensorList'
import Select from '@/components/Form/Select'
import ListSensors from '@/components/Form/ListSensors'

const SelectSensor = forwardRef(({
  keyName = 'id',
  ...props
}, ref) => (
  <Select
    ref={ref}
    keyName={keyName}
    ListSource={false}
    ListController={ListSensors}
    BadgeListSource={SensorList}
    {...props}
  />
))

SelectSensor.displayName = 'SelectSensor'
export default SelectSensor
