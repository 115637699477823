import FormDialog from '@/components/Form/FormDialog'
import WidgetSection from '../Widget/Section'
import SettingsSection from './SettingsSection'

export default function WidgetTableData ({
  widget,
  defaultValues,
  dialog,
  onClose,
  onSubmit,
}) {
  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="widgetSensorForm"
      dialog={dialog}
    >
      <WidgetSection
        scope="widget"
      />

      <SettingsSection
        widget={widget}
      />
    </FormDialog>
  )
}
