import {
  useRef,
  useMemo,
  forwardRef,
  useCallback,
  useEffect,
} from 'react'
import classnames from 'classnames'
import {
  Dialog,
  withResizeObserver,
} from '@wiz/components'
import { useRouter } from '@/router'
import { useIntl } from '@wiz/intl'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import Link from '@/components/Link'
import Icon from '@/shared/icon'
import classes from './index.css'

const RightBar = forwardRef(({
  disabled,
  options,
  value,
  width,
  onChange,
  blocks,
  getWidth,
}, ref) => {
  const intl = useIntl()
  const refNode = useRef(null)
  const id = value?.id
  const menuOptions = options.filter(item => !item.hidden)

  useEffect(() => {
    if (refNode.current) {
      getWidth(refNode.current.offsetWidth)
    }
  }, [ getWidth ])

  return (
    <div
      ref={refNode}
      className={classnames(classes.root, {
        'bg-light-alt shadow-sm ': menuOptions.length > 0,
      })}
    >

      <div className="d-flex flex-column align-items-start p-2">
        {menuOptions.map((item, idx) => {
          const isDisabled = Boolean(disabled && item.canDisable)
          const isEditable = item.id === 'edit' ? !isDisabled && blocks.length === 1 : !isDisabled
          const className = classnames('btn btn-secondary-alt p-2 outline-none', {
            'mt-1': idx > 0,
            active: item.id === id && !isDisabled,
          })

          return item.path ? (
            <Link
              key={item.id}
              title={intl.t(item.title)}
              className={className}
              disabled={!isEditable || isDisabled}
              {...item.path}
            >
              <Icon name={item.icon} size="md" />
            </Link>
          ) : (
            <button
              key={item.id}
              type="button"
              title={intl.t(item.title)}
              className={classnames(className, 'd-flex align-items-center w-100 justify-content-start')}
              disabled={!isEditable || isDisabled}
              onClick={() => onChange(item.id, true)}
            >
              <Icon name={item.icon} />
              <span className="fs-7 ms-1">
                {intl.t(item.title)}
              </span>
            </button>
          )
        })}
      </div>
    </div>
  )
})

const RightBarResize = withResizeObserver(RightBar)

const RightBarMenu = forwardRef(({
  refDialog,
  container = null,
  disabled,
  pinned,
  options,
  selectedProps,
  value,
  width,
  onClose,
  onChange,
  expanded,
  expandedClose,
  attachedSensorsCount,
  ...rest
}, ref) => {
  const intl = useIntl()
  const router = useRouter()
  const refDialogElement = useRef(null)
  const refRightBar = useRef(null)
  const { contextTwinId } = useGlobalExecute()
  const {
    back,
    props,
    id: dialogId,
    component: DialogComponent,
    title,
    dialogTitle,
    showSelectedCount,
  } = value || {}

  const onChangeBack = useMemo(() => (
    back && back !== dialogId ? () => onChange(back) : undefined
  ), [ back, dialogId, onChange ])

  const ComponentTitle = useMemo(() => ({
    children,
  }) => (
    <>
      {onChangeBack ? (
        <button
          type="button"
          className="btn btn-flat-secondary me-2"
          onClick={onChangeBack}
        >
          <Icon name="fa--chevron-left" size="md" />
        </button>
      ) : null}

      <h1 className="h4 flex-fill m-0">
        {children}
      </h1>
    </>
  ), [ onChangeBack ])

  const getRightBarWidth = useCallback((rbWidth) => {
    refRightBar.current = rbWidth
  }, [])

  const ExperimentButton = (
    <button
      type="button"
      title={intl.t('experiments.openButton')}
      className={classnames(classes.btnExperiment, 'btn btn-flat-secondary d-none d-md-flex ms-2')}
      disabled={!props?.id}
      onClick={() => router.push({ name: 'experiment', params: { id: props?.id } })}
    >
      <Icon
        name="faFlask"
        size="md"
      />
    </button>
  )

  useEffect(() => {
    if (!contextTwinId) {
      refDialog.current?.close()
    }
  }, [ contextTwinId ])

  return (
    <>
      <Dialog
        ref={refDialog}
        refDialog={refDialogElement}
        classNameBody="p-0 overflow-hidden"
        className="shadow-none"
        container={container}
        strategy="absolute"
        backdrop={false}
        pinned={pinned}
        expanded={expanded}
        expandedClose={expandedClose}
        showSelectedCount={showSelectedCount}
        selectedSensorsCount={attachedSensorsCount}
        headerActions={props?.id ? ExperimentButton : null}
        size="sm"
        opened={Boolean(DialogComponent)}
        title={intl.t(dialogTitle || title)}
        ComponentTitle={ComponentTitle}
        resize
        right={refRightBar.current}
        dataTestid={`${dialogId}RightBarDialog`}
        onClose={onClose}
      >
        {DialogComponent ? (
          <DialogComponent
            {...props}
            {...selectedProps}
            container={container}
            onChangeBack={onChangeBack}
          />
        ) : null}
      </Dialog>

      <RightBarResize
        refResize={refDialogElement}
        disabled={disabled}
        options={options}
        value={value}
        width={width}
        onChange={onChange}
        blocks={selectedProps.blocks}
        getWidth={getRightBarWidth}
      />
    </>
  )
})

export default withResizeObserver(RightBarMenu)
