import { Duration } from 'luxon'
import { of as of$, Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import {
  OnlineTimeout,
  consts,
  network,
  debounce,
  getDateFrom,
  getDateTo,
  stepByWidth,
  AbortRequestError,
} from '@wiz/utils'
import { Q, dbProvider } from '@wiz/store'
import { wizataApi } from '@/api'

const createSources = (config, {
  width,
  height,
  theme,
  responseType,
}) => {
  const { stepType, stepCustom } = config.dataFilter
  const dateFrom = getDateFrom(config.dataFilter)
  const dateTo = getDateTo(config.dataFilter)

  let interval = 60000
  if (stepType === 'auto') {
    interval = stepByWidth(dateFrom, dateTo, 8, width)
  } else if (stepType === 'dense') {
    interval = stepByWidth(dateFrom, dateTo, 1, width)
  } else if (stepCustom) {
    interval = stepCustom
  }

  const sources = config.dataSources
    .filter(item => Boolean(item.sensorId && item.dataType))
    .map(item => ({
      sensorId: item.sensorId,
      dataType: item.dataType,
      dateFrom: dateFrom.toMillis(),
      dateTo: dateTo.toMillis(),
      plotVariable: config.plotVariable || 'fig',
      dataFrameVariable: config.dataFrameVariable || 'df',
      script: config.script,
      interval: Duration.fromMillis(interval).toISO(),
      settings: {
        height,
        width,
        template: theme?.name === 'moon' ? 'plotly_dark' : 'plotly_white',
        paper_bgcolor: theme?.variables?.white || 'rgba(0,0,0,0)',
        plot_bgcolor: theme?.variables?.white || 'rgba(0,0,0,0)',
        font_color: theme?.variables?.['body-color'] || 'rgb(255,255,255)',
        ...(responseType === 'base64' ? {
          paper_bgcolor: 'rgba(0,0,0,0)',
          plot_bgcolor: 'rgba(0,0,0,0)',
          font_color: theme.textStyle?.color || 'rgb(255,255,255)',
        } : {}),
      },
      responseType: responseType || 'json',
    }))

  return sources
}

export default function observePlotlyChart (
  widgetId,
  {
    width = 500,
    height = 360,
    theme,
    responseType,
  } = {},
) {
  return new Observable((subscriber) => {
    const sendResult = data => subscriber.next(data)
    let timer
    let request
    let sources
    let stepRequest

    const fetchRequest = debounce(() => {
      timer?.cancel()
      request?.abort()

      if (sources.length) {
        request = wizataApi.getPlotlyChart(sources)
        request.fetch()
          .then((data) => {
            if (!subscriber.closed) {
              sendResult(data)
              timer = new OnlineTimeout(
                fetchRequest,
                stepRequest || consts.PlotlyStepRequest,
              )
            }
          })
          .catch((error) => {
            if (!subscriber.closed) {
              sendResult({ error })
              if (!(error instanceof AbortRequestError)) {
                timer = new OnlineTimeout(
                  fetchRequest,
                  stepRequest || consts.PlotlyStepRequest,
                )
              }
            }
          })
      } else {
        sendResult({})
      }
    }, 1000)

    const subscription = dbProvider.database.collections.get('widgets')
      .query(Q.where('id', widgetId))
      .observeWithColumns([ 'updated_at' ])
      .pipe(
        switchMap(items => (items.length ? items[0].observeConfig : of$(undefined))),
      )
      .subscribe((config) => {
        if (config) {
          stepRequest = config.dataFilter.stepRequest
          sources = createSources(config, {
            width,
            height,
            theme,
            responseType,
          })
        } else {
          sources = []
        }

        fetchRequest()
      })

    network.on('online', fetchRequest)
    sendResult()

    return () => {
      subscription.unsubscribe()
      fetchRequest.cancel()
      request?.abort()
      timer?.cancel()
      network.removeListener('online', fetchRequest)
    }
  })
}
