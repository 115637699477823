import { forwardRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import classnames from 'classnames'
import { has } from '@wiz/utils'
import { FormControl } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import FormLocation from '@/components/Forms/Location'

const withLocation = (WrapComponent) => ({ scope, ...props }) => {
  const { watch } = useFormContext()
  const latitude = watch(`${scope}.latitude`)
  const longitude = watch(`${scope}.longitude`)
  const zoom = watch(`${scope}.zoom`)
  return (
    <WrapComponent
      {...props}
      latitude={latitude}
      longitude={longitude}
      zoom={zoom}
    />
  )
}

const BindedFormLocation = withLocation(FormLocation)

const DisplayLocation = withLocation(({
  latitude,
  longitude,
  zoom,
  withZoom,
  placeholder,
}) => (
  Number(latitude) && Number(longitude) ? (
    <>
      {withZoom && zoom ? (
        <span className="me-1">
          {Number(zoom)}
          :
        </span>
      ) : null}
      <span className="me-1 text-truncate">{Number(Number(latitude).toFixed(10))}</span>
      /
      <span className="ms-1 text-truncate">{Number(Number(longitude).toFixed(10))}</span>
    </>
  ) : (
    <span className="text-muted">{placeholder}</span>
  )
))

const SelectLocation = forwardRef(({
  scope,
  className,
  invalid,
  withZoom,
}, ref) => {
  const intl = useIntl()
  const [ isOpen, openDialog ] = useState(false)
  const { formState: { errors }, setValue } = useFormContext()

  return (
    <>
      <button
        ref={ref}
        type="button"
        className={classnames('d-flex justify-content-center form-control', className, {
          'is-invalid': (
            has(errors, `${scope}.latitude`) ||
            has(errors, `${scope}.longitude`) ||
            (withZoom && has(errors, `${scope}.zoom`)) ||
            invalid
          ),
        })}
        onClick={() => openDialog(true)}
      >
        <DisplayLocation
          scope={scope}
          placeholder="Select Location"
          withZoom={withZoom}
        />

        <FormControl
          type="any"
          name={`${scope}.latitude`}
        />

        <FormControl
          type="any"
          name={`${scope}.longitude`}
        />

        {withZoom ? (
          <FormControl
            type="any"
            name={`${scope}.zoom`}
          />
        ) : null}
      </button>

      {isOpen ? (
        <BindedFormLocation
          scope={scope}
          withZoom={withZoom}
          onSubmit={(lat, lng, zoom) => {
            setValue(`${scope}.latitude`, lat, { shouldDirty: true })
            setValue(`${scope}.longitude`, lng, { shouldDirty: true })
            if (withZoom) {
              setValue(`${scope}.zoom`, zoom, { shouldDirty: true })
            }
          }}
          onClose={() => openDialog(false)}
          dialog={{
            title: intl.t('location.form.titleUpdate'),
            dataTestid: 'selectLocationDialog',
          }}
        />
      ) : null}
    </>
  )
})

SelectLocation.displayName = 'SelectLocation'
export default SelectLocation
