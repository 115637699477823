import { useState } from 'react'
import { FormInputSearch, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import EdgeDevice from '@/hoc/EdgeDevice'
import Table from '@/containers/EdgeDevice/Table'
import TableMobile from '@/containers/EdgeDevice/TableMobile'
import ViewModel from './ViewModel'

const ViewModelData = EdgeDevice(ViewModel)

export default function List ({
  filters,
  onFilter,
  onResetFilter,
  ...props
}) {
  const intl = useIntl()
  const isMobile = useMobile()
  const [ viewId, setViewId ] = useState(null)

  const TableComponent = isMobile ? TableMobile : Table

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">

      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <div className="d-flex align-items-center">
          <div className="px-2 mb-2 col-sm-4">
            <FormInputSearch
              placeholder={intl.t('form.actions.searchPlaceholder')}
              value={filters.search}
              onChange={value => onFilter({ search: value })}
            />
          </div>

          <div className="mb-2">
            <button
              type="button"
              className="btn btn-fill-secondary me-2 text-nowrap"
              onClick={onResetFilter}
            >
              {intl.t('form.actions.resetFilters')}
            </button>
          </div>
        </div>

        <TableComponent
          {...props}
          filters={filters}
          onFilter={onFilter}
          onView={setViewId}
        />
      </div>

      {viewId ? (
        <ViewModelData
          id={viewId}
          title={intl.t('edge.devices.titleDetail')}
          onClose={() => setViewId(null)}
          dialog
        />
      ) : null}
    </div>
  )
}
