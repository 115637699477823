import { useState } from 'react'
import { FormInput } from '@wiz/components'
import Select from '@/components/Form/Select'
import styles from './index.module.css'
import cx from 'classnames'

export default function GrafanaVariable({ variable = {}, grafanaVar, updateParams }) {
  const [ value, setValue ] = useState(variable?.defaults?.[0])
  const [ selectValue, setSelectValue ] = useState(variable?.defaults)
  const urlParam = `&var-${variable?.name}=${value}`.replace(/\s/g, "%20");

  const handleChange = (e) => {
    setValue(e.target.value);
  }

  const handleBlur = (e) => {
    if (grafanaVar[variable?.name] !== urlParam) {
      updateParams({...grafanaVar, [variable?.name]: urlParam});
    }
  }

  const handleSelectChange = (val) => {
    const selectedValue = variable?.multi ? val : [val];

    const values = variable?.options
      .filter((v) => selectedValue.includes(v.id))
      .map(a => `&var-${variable?.name}=${a.name}`.replace(/\s/g, "%20"));

    setSelectValue(selectedValue)
    updateParams({...grafanaVar, [variable?.name]: values});
  }
  
  return (
    <div className={cx(styles.grafanaVar)}>
      <span>{variable?.label}</span>
      {variable?.type === 'textbox'
      ?  <FormInput
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      : <Select
          options={variable.options}
          value={selectValue}
          placeholder="Select variable"
          multiselect={!!variable?.multi}
          onChange={handleSelectChange}
        />
      }
    </div>
  )
}