import { useIntl } from '@wiz/intl'
import ButtonCopy from '@/components/Form/ButtonCopy'

const IconsByType = {
  equipment: 'fa--tools',
  machine: 'fa--cogs',
  flow: 'fa--chart-network',
}

export default function TwinTreeContent ({
  className,
  data,
  context,
  ContentDefault,
}) {
  const intl = useIntl()
  const item = data.payload || data
  const { search } = context

  const icon = (
    item.icon ||
    IconsByType[item.type] ||
    (item.color ? 'fa--circle' : null)
  )

  return (
    <>
      <ContentDefault
        className={className}
        name={item.displayName}
        search={search}
        icon={icon}
        color={item.color}
      />

      {item.hardwareId ? (
        <ButtonCopy
          className="hover-inline-flex"
          value={item.hardwareId}
          title={intl.t('message.copy.hardwareId', { value: item.hardwareId })}
        />
      ) : null}
    </>
  )
}
