import {
  useCallback,
  useMemo,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { FormControl } from '@wiz/components'
import { dbProvider, IExplorer } from '@wiz/store'
import { toArrayValue } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import List from '@/components/Form/Tree/List'
import HistogramSourceForm from '@/components/Forms/HistogramSource'
import DataSourceExplorer from '@/components/Form/DataSourceExplorer'
import DataViewRow from './DataViewRow'

export default function SectionSources ({ scope }) {
  const [ editId, setEditId ] = useState(null)
  const intl = useIntl()
  const { watch, setValue } = useFormContext()
  const dataSources = watch(`${scope}.dataSources`)
  const dataViews = watch(`${scope}.dataViews`)

  const handleCreateSource = useCallback(async (sensorId) => {
    let nextSources = []
    let nextViews = []

    if (sensorId) {
      const prevSource = dataSources.find(item => item.sensorId === sensorId)
      if (prevSource) {
        nextSources = dataSources.filter(item => item.id !== prevSource.id)
        nextViews = dataViews.filter(item => item.sourceId !== prevSource.id)
      } else {
        const source = await IExplorer.createDataSourceContext(
          dbProvider.database,
          { sensorId },
        )
        const view = await IExplorer.createDataViewContext(
          dbProvider.database,
          { sourceId: source.id, source },
        )
        nextSources = dataSources.concat(source)
        nextViews = dataViews.concat(view)
      }
    }

    setValue(`${scope}.dataSources`, nextSources, { shouldDirty: true })
    setValue(`${scope}.dataViews`, nextViews, { shouldDirty: true })
  }, [ scope, dataSources, dataViews, setValue ])

  const handleUpdateSource = useCallback(async ({ dataView, dataSource }) => {
    const nextSources = []
    const nextViews = []
    let source

    for (const item of dataSources) {
      if (item.id === dataSource.id) {
        source = await IExplorer.createDataSourceContext(
          dbProvider.database,
          { ...item, ...dataSource },
        )
        nextSources.push(source)
      } else {
        nextSources.push(item)
      }
    }

    for (const item of dataViews) {
      if (item.id === dataView.id) {
        const view = await IExplorer.createDataViewContext(
          dbProvider.database,
          {
            ...item,
            ...dataView,
            sourceId: source.id,
            source,
          },
        )
        nextViews.push(view)
      } else {
        nextViews.push(item)
      }
    }

    setValue(`${scope}.dataSources`, nextSources, { shouldDirty: true })
    setValue(`${scope}.dataViews`, nextViews, { shouldDirty: true })
  }, [ scope, dataSources, dataViews, setValue ])

  const handleAction = useCallback(async (action, data) => {
    if (action === 'update') {
      const nextViews = toArrayValue(data)
        .reduce((out, item) => ({
          ...out,
          [item.id]: item,
        }), {})

      const next = []

      for (const item of dataViews) {
        if (nextViews[item.id]) {
          const nextView = nextViews[item.id]
          delete nextViews[item.id]
          const view = await IExplorer.createDataViewContext(
            dbProvider.database,
            { ...item, ...nextView },
          )
          next.push(view)
        } else {
          next.push(item)
        }
      }

      for (const item of Object.values(nextViews)) {
        const view = await IExplorer.createDataViewContext(dbProvider.database, item)
        next.push(view)
      }

      setValue(`${scope}.dataViews`, next, { shouldDirty: true })
    } else if (action === 'remove') {
      const nextViews = dataViews.filter(item => item.id !== data.id)
      if (data.sourceId) {
        const nextSources = dataSources.filter(item => item.id !== data.sourceId)
        setValue(`${scope}.dataSources`, nextSources, { shouldDirty: true })
      }
      setValue(`${scope}.dataViews`, nextViews, { shouldDirty: true })
    } else if (action === 'edit') {
      setEditId(data.id)
    }
  }, [ scope, dataViews, dataSources, setValue ])

  const sensorIds = useMemo(() => (
    dataSources
      .filter(item => item.sensorId)
      .map(item => item.sensorId)
  ), [ dataSources ])

  const editData = useMemo(() => {
    const dataView = editId && dataViews.find(item => item.id === editId)
    if (!dataView) {
      return {}
    }

    const dataSource = dataSources.find(item => item.id === dataView.sourceId)
    return {
      id: editId,
      dataView,
      dataSource,
    }
  }, [ editId, dataViews, dataSources ])

  return (
    <div className="flex-fill d-flex flex-column mt-2">
      <FormControl type="any" name={`${scope}.dataSources`} />
      <FormControl type="any" name={`${scope}.dataViews`} />

      <div className="flex-fill d-flex flex-column">
        <DataSourceExplorer
          value={sensorIds}
          onChange={handleCreateSource}
        />
      </div>

      <div className="flex-fill d-flex flex-column mt-2 position-relative">
        <List
          className="position-absolute-fill"
          options={dataViews}
          Content={DataViewRow}
          onAction={handleAction}
        />
      </div>

      {editId !== null ? (
        <HistogramSourceForm
          {...editData}
          onSubmit={data => handleUpdateSource(data)}
          onClose={() => setEditId(null)}
          dialog={{
            title: intl.t('widgets.histogram.titleUpdateSource'),
            dataTestid: 'replaceHistogramSourceDialog',
          }}
        />
      ) : null}
    </div>
  )
}

SectionSources.propTypes = {
  scope: PropTypes.string.isRequired,
}
