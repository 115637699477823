import { useRef, useState, useCallback } from 'react'
import classnames from 'classnames'
import { DropdownMenu } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import BulkSensors from '@/components/Dialogs/BulkSensors'

export default function BulkSensorOperations ({
  options,
  className,
  sensorIds = [],
  onBeforeAction,
  onUnlinkSensors,
  onCreateStreamJob,
  onSuccess,
}) {
  const refBulkOperation = useRef()
  const intl = useIntl()
  const [ createForm, setCreateForm ] = useState(null)

  const handleClickBulk = useCallback(async ({ action }) => {
    onBeforeAction?.(action)
    if (action === 'streamJob') {
      const model = await onCreateStreamJob?.(sensorIds)
      onSuccess?.(action, model)
    } else if (action === 'unlink') {
      onUnlinkSensors?.(sensorIds)
    } else {
      setCreateForm(action)
    }
  }, [
    sensorIds,
    onSuccess,
    onBeforeAction,
    onCreateStreamJob,
    onUnlinkSensors,
  ])

  if (!options.length) {
    return null
  }

  return (
    <>
      <button
        ref={refBulkOperation}
        type="button"
        className={classnames('btn text-nowrap', className)}
        disabled={!sensorIds.length}
      >
        {intl.t('form.actions.bulkOperation')}
      </button>

      <DropdownMenu
        arrow
        autoclose
        target={refBulkOperation}
        options={options}
        onClick={handleClickBulk}
      />

      <BulkSensors
        type={createForm}
        sensorIds={sensorIds}
        onClose={() => setCreateForm(null)}
        onSuccess={onSuccess}
      />
    </>
  )
}
