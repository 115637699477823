import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { StreamJob as StreamJobModel, StreamJobSchedule } from '@wiz/store'
import { orderBy } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import Section from './Section'
import SectionTwins from './SectionTwins'

export default function StreamJob ({
  id,
  streamJob,
  schedules,
  twins,
  defaultReady,
  dialog,
  onClose,
  onSubmit,
  onSuccess,
  templateId,
}) {
  const intl = useIntl()
  const defaultValues = useMemo(() => {
    const times = schedules.map(item => StreamJobSchedule.toRuleOptions(item))
    if (!times.length) {
      times.push(StreamJobSchedule.toRuleOptions())
    }

    const schedule = {
      ...times[0],
      times: orderBy(times, [ 'dtstart' ], [ 'asc' ]).map(item => ({
        ...item,
        dtstart: item.dtstart ? DateTime.fromJSDate(item.dtstart).toISO({
          suppressMilliseconds: true,
          suppressSeconds: true,
          includeOffset: false,
        }) : undefined,
      })),
    }

    const item = StreamJobModel.toJSON(streamJob)

    if (templateId) {
      item.templateId = templateId
    }

    return {
      streamJob: item,
      schedule,
      twins,
    }
  }, [
    streamJob,
    schedules,
    twins,
    templateId,
  ])

  if (id && !streamJob) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      onClose={onClose}
      dialog={dialog}
      defaultValues={defaultValues}
      dataTestid="streamJobForm"
      defaultReady={defaultReady}
    >
      <Section scope="streamJob" templateId={templateId} />

      <SectionTwins
        title={intl.t('streamJobs.form.fields.twins')}
        description={intl.t('streamJobs.form.fields.twinsDescr')}
        scope="twins"
        templateId={templateId}
      />
    </FormDialog>
  )
}
