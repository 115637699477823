import { useFormContext } from 'react-hook-form'

export default function Section ({ scope, registerId = true }) {
  const { register } = useFormContext()

  return (
    <>
      {registerId ? (
        <input
          {...register(`${scope}.id`)}
          type="hidden"
        />
      ) : null}
    </>
  )
}
