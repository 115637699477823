export default function twins (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      twinsCore: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value (params) {
              const request = client.post('/Twins/paged', { ...params })
              return request.fetch()
            },
          },
          getById: {
            enumerable: true,
            value (id) {
              return client.get(`/Twins/${id}`).fetch()
            },
          },
          deleteById: {
            enumerable: true,
            value (id) {
              return client.delete(`/Twins/${id}`).fetch()
            },
          },
          create: {
            enumerable: true,
            value (params) {
              return client.post('/Twins', { ...params }).fetch()
            },
          },
          update: {
            enumerable: true,
            value ({ id, ...params }) {
              return client.put(`/Twins/${id}`, { ...params }).fetch()
            },
          },
        }),
      },
    })
  }
}
