import { useFormContext } from 'react-hook-form'
import {
  FormSection,
  FormField,
  FormControl,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function Section ({ scope, widget, ...props }) {
  const intl = useIntl()
  const { register, formState: { errors } } = useFormContext()

  return (
    <FormSection {...props}>
      <input
        {...register(`${scope}.id`)}
        type="hidden"
      />

      <FormField
        label={intl.t('form.fields.title')}
        description={intl.t('widgets.form.fields.titleDescr')}
        errors={get(errors, `${scope}.title`)}
      >
        <FormControl
          name={`${scope}.title`}
          rules={{
            maxLength: {
              value: 100,
              message: intl.t('form.errors.fieldMaxlen', { max: 100 }),
            },
            setValueAs: value => (value || null),
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.description')}
        description={intl.t('widgets.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.description`}
          rules={{
            maxLength: {
              value: 400,
              message: intl.t('form.errors.fieldMaxlen', { max: 400 }),
            },
            setValueAs: value => (value || null),
          }}
        />
      </FormField>
    </FormSection>
  )
}
