import { useState, useCallback, useMemo } from 'react'
import FilterSearch from '@/components/Form/FilterSearch'
import ButtonCreateCondition from './ButtonCreateCondition'
import ListConditions from './ListConditions'

const ConditionExplorer = ({
  className,
  options = [],
  applied,
  exclude,
  readOnly,
  onCreateCondition,
  onRemoveCondition,
  onChangeCondition,
  onDrop,
  ComponentConditionForm,
}) => {
  const [ search, setSearch ] = useState('')
  const handleFilter = useCallback(({ search }) => setSearch(search || ''), [])
  const treeData = useMemo(() => {
    const data = []
    const hash = {}
    const filterSearch = search.trim().toLowerCase()

    for (const item of options) {
      const { name } = item

      if (filterSearch && name && name.toLowerCase().indexOf(filterSearch) === -1) {
        continue
      }

      hash[item.id] = {
        id: item.id,
        name,
        payload: {
          data: item,
          applied: applied?.[item.id],
        },
        children: [],
      }
    }

    for (const item of options) {
      if (!item.groupId && hash[item.id]) {
        data.push(hash[item.id])
      } else if (item.groupId && hash[item.groupId]) {
        hash[item.groupId].children.push(hash[item.id])
      }
    }

    return data
  }, [ options, search, applied ])

  return (
    <FilterSearch
      withSelectedOnly={false}
      listClassName={className}
      searchTestid="conditionSearch"
      onFilter={handleFilter}
      postfix={readOnly ? null : (
        <ButtonCreateCondition
          className="mb-1 me-1"
          exclude={exclude}
          onCreateCondition={onCreateCondition}
          ComponentConditionForm={ComponentConditionForm}
        />
      )}
    >
      {formData => (
        <ListConditions
          {...formData}
          readOnly={readOnly}
          className="position-absolute-fill"
          exclude={exclude}
          options={treeData}
          onRemoveCondition={onRemoveCondition}
          onChangeCondition={onChangeCondition}
          onDrop={onDrop}
          ComponentConditionForm={ComponentConditionForm}
        />
      )}
    </FilterSearch>
  )
}

export default ConditionExplorer
