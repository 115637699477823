/* eslint-disable max-len */
import { useCallback } from 'react'
import { useIntl } from '@wiz/intl'
import { withProps } from '@wiz/components'
import { dbProvider } from '@wiz/store'
import withTableActions from '@/containers/withTableActions'
import Component from '@/components/Jobs/List'
import events from '@/utils/events'

const enhanceProps = withProps(() => {
  const intl = useIntl()

  const onRemove = useCallback((data) => {
    const isMultiple = data.length > 1

    const messageConfirm = `
      ${isMultiple ? intl.t('jobs.confirmDeleteList') : intl.t('jobs.confirmDelete')}
      <div class="mt-2">
        <label class="form-check">
          <input type="checkbox" class="form-check-input" name="delDataFile" />
          <span class="form-check-label">${intl.t('jobs.form.actions.delDataFile')}</span>
        </label>
      </div>`

    // ${(!isMultiple && data[0]?.command === 'createNotebook') ? (
    //   `<label class="form-check">
    //     <input type="checkbox" class="form-check-input" name="delNotebook" />
    //     <span class="form-check-label">${intl.t('jobs.form.actions.delNotebook')}</span>
    //   </label>`
    // ) : ''}

    let delDataFile = false
    // let delNotebook = false

    return window.wizConfirm({
      size: 'md',
      dangerouslyUseHTMLString: true,
      title: 't/form.fields.warning',
      message: messageConfirm,
      onConfirm: (instance) => {
        delDataFile = Boolean(instance.current.querySelector('input[name="delDataFile"]')?.checked)
        // delNotebook = Boolean(instance.current.querySelector('input[name="delNotebook"]')?.checked)
      },
    })
      .then(async () => {
        try {
          const context = dbProvider.createBatchContext()
          for (const item of data) {
            await item.prepareRemove(context, {
              removeFile: delDataFile,
            })
          }
          await dbProvider.batch(context)
        } catch (error) {
          events.emit('app:notify', {
            type: 'error',
            message: error.message,
          })
          throw error
        }
      })
  }, [ intl ])

  return {
    onRemove,
  }
})

export default withTableActions()(
  enhanceProps(Component),
)
