import classnames from 'classnames'
import { Icon } from '@wiz/components'
import { auth } from '@/auth'
import Link from '@/components/Link'

export default function CellTwin ({
  options: [ target ],
  className,
  onFilter,
}) {
  return target ? (
    <div className={classnames('d-flex align-items-center min-w-0', className)}>
      {onFilter ? (
        <button
          type="button"
          className="btn btn-sm p-0 border-0 me-2"
          onClick={() => onFilter?.({ twinId: target.id })}
        >
          <Icon
            name="fa--search"
          />
        </button>
      ) : null}

      {auth.checkAccessRead(target) ? (
        <Link
          className="text-truncate"
          title={target.displayName}
          name="twin-items-list"
          query={{
            type: target?.type,
            search: target.id,
            view: 'table',
          }}
        >
          {target.displayName}
        </Link>
      ) : (
        <span className="text-truncate" title={target.displayName}>
          {target.displayName}
        </span>
      )}
    </div>
  ) : null
}
