import { EdgeDeviceCommand } from '@wiz/store'
import { createApi } from '../utils'

export default function deviceCommands (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      deviceCommands: {
        enumerable: true,
        value: Object.create({}, createApi(client, 'DeviceCommands', EdgeDeviceCommand)),
      },
    })
  }
}
