import classnames from 'classnames'
import { Icon, useMobile } from '@wiz/components'
import { useRouter } from '@/router'
import Link from '@/components/Link'
import NavBar from '@/containers/TwinGraph/NavBar'
import GraphFilters from './GraphFilters'
import GraphFiltersMobile from './GraphFiltersMobile'

export default function GraphToolbar ({
  twinSettings,
  mapView,
  hideGrid,
  request,
  twinGraph,
  linkMode,
  setLinkMode,
  onToggleGrid,
  onToggleMapView,
  onToggleLeftBar,
  onEditTwinGraph,
  onDuplicateTwinGraph,
  onDiagramAlign,
  onDiagramFit,
  onDiagramReshapeLinks,
  onClickCreate,
  onDragStart,
  onDragEnd,
}) {
  const isMobile = useMobile()
  const router = useRouter()

  const GraphFiltersComponent = isMobile ? GraphFiltersMobile : GraphFilters

  return (
    <header className="d-flex align-items-center flex-wrap px-3 pt-2 border-bottom">
      <div className="d-flex align-items-center mx-2 mb-2">
        {isMobile ? null : (
          <>
            <Link
              name="chart-list"
              className="btn btn-flat-secondary btn-text"
              keepQuery
            >
              <Icon name="fa--chevron-double-left" />
            </Link>

            <button
              type="button"
              className="btn btn-flat-secondary btn-text"
              onClick={() => router.back()}
            >
              <Icon name="fa--chevron-left" />
            </button>
          </>
        )}

        <button
          type="button"
          className="btn btn-flat-secondary btn-text"
          onClick={onToggleLeftBar}
        >
          <Icon name="fa--folder-tree" />
        </button>
      </div>

      <NavBar
        className="mb-2 py-2"
        twinGraph={twinGraph}
        nestedTwinGraphs={request.nestedTwinGraphs}
      />

      <GraphFiltersComponent
        className={classnames('mb-2', {
          'px-2': !isMobile,
        })}
        twinSettings={twinSettings}
        mapView={mapView}
        hideGrid={hideGrid}
        twinGraph={twinGraph}
        linkMode={linkMode}
        setLinkMode={setLinkMode}
        onToggleGrid={onToggleGrid}
        onToggleMapView={onToggleMapView}
        onEditTwinGraph={onEditTwinGraph}
        onDuplicateTwinGraph={onDuplicateTwinGraph}
        onDiagramAlign={onDiagramAlign}
        onDiagramFit={onDiagramFit}
        onDiagramReshapeLinks={onDiagramReshapeLinks}
        onClickCreate={onClickCreate}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      />
    </header>
  )
}
