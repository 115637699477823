import { Icon } from '@wiz/components'
import { auth } from '@/auth'
import Link from '@/components/Link'

export default function CellDevice ({
  options: [ target ],
  onFilter,
}) {
  if (!target) {
    return '-'
  }

  if (!auth.checkAccessRead(target)) {
    return null
  }

  return (
    <div className="d-flex align-items-center me-2 min-w-0">
      <button
        type="button"
        className="btn btn-sm p-0 border-0 me-2"
        onClick={() => onFilter?.({ devices: target.id })}
      >
        <Icon
          name="fa--search"
        />
      </button>

      {auth.checkAccessRead('SectionEdgeDevices') ? (
        <Link
          className="text-truncate"
          title={target.name}
          name="edge-devices"
          params={{
            id: target?.id,
          }}
        >
          {target.name}
        </Link>
      ) : (
        <span className="text-truncate" title={target.name}>
          {target.name}
        </span>
      )}
    </div>
  )
}
