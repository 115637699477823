import PropTypes from 'prop-types'
import FieldsSources from './FieldsSources'

export default function SectionSources ({ scope }) {
  return (
    <div className="flex-fill d-flex flex-column mt-2">
      <FieldsSources scope={scope} />
    </div>
  )
}

SectionSources.propTypes = {
  scope: PropTypes.string.isRequired,
}
