import { useQuery } from '@tanstack/react-query'
import { wizataApi } from '@/api'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import { withProps } from '@wiz/components'
import {
  consts,
  createNestedTreeWithoutConnections,
  filterNestedTree,
  toArrayValue,
  createTwinsChain,
  sortByConnections,
} from '@wiz/utils'

const enhanceTree = withProps(({
  excludeFrom,
  keyName,
  search,
  selectedOnly,
  types,
  value,
  withHardware,
  withLocation,
}) => {
  const currentKeyName = keyName || 'id'
  const isArrayValue = Array.isArray(value)
  const isArrayExclude = Array.isArray(excludeFrom)
  const sanitizeSearch = search && String(search).toLowerCase()
  const currentTypes = toArrayValue(types)
  const { contextTwinId } = useGlobalExecute()

  const disabledFrom = (data) => {
    if (isArrayExclude) {
      return excludeFrom.includes(data.id)
    }
    return data.id === excludeFrom
  }

  const checkEnabled = (data) => {
    let enabled = true

    if (enabled && sanitizeSearch) {
      enabled = data.name.toLowerCase().indexOf(sanitizeSearch) !== -1
    }

    if (enabled && withHardware) {
      enabled = !!(data.hardwareId)
    }

    if (enabled && withLocation) {
      enabled = !!(
        data.latitude &&
        data.longitude
      )
    }

    if (enabled) {
      enabled = (
        (!currentTypes.includes(consts.TwinType.Flow))
      )
    }

    if (enabled && selectedOnly) {
      const key = data[currentKeyName]
      if (isArrayValue) {
        enabled = value.includes(key)
      } else {
        enabled = !!(value && key === value)
      }
    }

    return enabled
  }

  const {
    data, refetch,
  } = useQuery({
    queryKey: [ 'twinsList' ],
    queryFn: () => wizataApi.twinsCore.getList({
      pagination: {
        take: 1000,
        skip: 0,
      },
      filters: [],
    }),
    refetchOnWindowFocus: false,
    enabled: true,
  })

  const sortByName = (a, b) => (a.name.localeCompare(b.name))

  if (data) {
    const enhancedOptions = filterNestedTree({ disabledFrom })(
      createNestedTreeWithoutConnections({ checkEnabled })(data?.items),
    )

    const twinsChain = createTwinsChain(data.items, contextTwinId)

    const setParentsAndSiblings = (nodes, parentId = null, prevId = null) => {
      if (!Array.isArray(nodes) || nodes.length === 0) return

      nodes.sort(sortByName)
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i]
        const nextId = i < nodes.length - 1 ? nodes[i + 1].id : null

        node.parentId = parentId
        node.prevId = prevId
        node.nextId = nextId

        if (node.children && node.children.length > 0) {
          setParentsAndSiblings(node.children, node.id)
        }

        prevId = node.id
      }
    }

    setParentsAndSiblings(enhancedOptions)
    sortByConnections(enhancedOptions)

    return {
      options: enhancedOptions,
      twinsChain,
      refetch,
    }
  }
})

export default WrappedComponent => enhanceTree(WrappedComponent)
