import { useCallback } from 'react'
import { dbProvider } from '@wiz/store'
import { withProps } from '@wiz/components'
import withTableActions from '@/containers/withTableActions'
import Component from '@/components/sensors/List'

const enhanceActions = withProps(() => {
  const onUnlink = useCallback(async (sensor) => {
    await window.wizConfirm({ message: 't/sensors.confirmUnlink' })
    const context = dbProvider.createBatchContext()
    await sensor.prepareUnlink(context)
    await dbProvider.batch(context)
  }, [])

  return {
    onUnlink,
  }
})

export default withTableActions({
  defaultFilters () {
    return {
      sortBy: 'createdDate',
    }
  },
})(
  enhanceActions(Component),
)
