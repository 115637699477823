import { useState } from 'react'
import { withProps, useDidUpdate } from '@wiz/components'
import Component from '@/containers/Twin/Graph'
import enhanceListProps from '@/containers/Twin/enhanceListProps'

const enhanceFilters = withProps(({ selectedTwinId }) => {
  const [ filters, onFilter ] = useState({
    tree: selectedTwinId,
  })

  useDidUpdate(() => {
    onFilter({ tree: selectedTwinId })
  }, [ selectedTwinId ])

  return {
    filters,
    onFilter,
  }
})

const enhanceProps = withProps(({
  selectedIds,
  setSelectedIds,
}) => ({
  selected: selectedIds,
  onSelect: setSelectedIds,
}))

export default enhanceFilters(
  enhanceListProps(
    enhanceProps(Component),
  ),
)
