import { useState, useCallback } from 'react'
import {
  Icon,
  FormInputSearch,
  useMobile,
} from '@wiz/components'
import { consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import Job from '@/hoc/Job'
import FormBGJobExport from '@/containers/Forms/BGJobExport'
import FormBGJobNotebook from '@/containers/Forms/BGJobNotebook'
import FormBGJobImport from '@/containers/Forms/BGJobImport'
import Import from '@/containers/Forms/Import'
import FormFile from '@/containers/Forms/File'
import Table from '@/containers/Job/Table'
import TableMobile from '@/containers/Job/TableMobile'
import ViewModel from './ViewModel'

const ViewModelData = Job(ViewModel)

export default function List ({
  filters,
  selected,
  onFilter,
  onResetFilter,
  onRemove,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const isMobile = useMobile()
  const [ viewId, setViewId ] = useState(null)
  const [ editNotebookId, setEditNotebookId ] = useState(null)
  const [ editExportId, setEditExportId ] = useState(null)
  const [ editImportDataId, setEditImportDataId ] = useState(null)
  const [ editFile, setEditFile ] = useState(null)

  const handleEdit = useCallback((model) => {
    if (model.command === consts.JobCommand.FileExport) {
      setEditNotebookId(null)
      setEditImportDataId(null)
      setEditExportId(model.id)
    } else if (model.command === consts.JobCommand.FileImport) {
      setEditNotebookId(null)
      setEditExportId(null)
      setEditImportDataId(model.id)
    } else {
      setEditExportId(null)
      setEditImportDataId(null)
      setEditNotebookId(model.id)
    }
  }, [])

  const handleFileEdit = useCallback((item) => {
    setEditFile(item.id)
  }, [])

  const TableComponent = isMobile ? TableMobile : Table

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">

      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <div className="d-flex align-items-center flex-wrap">
          {(
            auth.checkAccessCreate('Job') ||
            auth.checkAccessRemove('Job') ||
            auth.checkAccessCreate('Notebook')
          ) ? (
            <div className="px-2 mb-2">
              {auth.checkAccessCreate('Job') ? (
                <button
                  type="button"
                  className="btn btn-flat-secondary"
                  onClick={() => setEditExportId('')}
                >
                  <Icon name="fa--file-download" className="me-1" />
                  {intl.t('form.actions.exportTitle')}
                </button>
              ) : null}

              {auth.checkAccessCreate('Job') && auth.checkAccessCreate('Notebook') ? (
                <button
                  type="button"
                  className="btn btn-flat-secondary"
                  onClick={() => setEditNotebookId('')}
                >
                  <Icon name="fa--file-contract" className="me-1" />
                  {intl.t('form.actions.createNotebook')}
                </button>
              ) : null}

              {(
                auth.checkAccessCreate('Job') &&
                auth.checkAccessRead('File')
              ) ? (
                <button
                  type="button"
                  className="btn btn-flat-secondary"
                  onClick={() => setEditImportDataId('')}
                >
                  <Icon name="fa--file-download" className="me-1" />
                  {intl.t('form.actions.importDataTitle')}
                </button>
                ) : null}

              {auth.checkAccessRemove('Job') ? (
                <button
                  type="button"
                  className="btn btn-flat-secondary"
                  onClick={() => onRemove(selected)}
                  disabled={!selected.length}
                >
                  <Icon name="fa--trash-alt" className="me-1" />
                  {intl.t('form.actions.remove')}
                </button>
              ) : null}
            </div>
            ) : null}

          <div className="d-flex align-items-center">
            <div className="px-2 mb-2 col-sm-auto">
              <FormInputSearch
                placeholder={intl.t('form.actions.searchPlaceholder')}
                value={filters.search}
                onChange={value => onFilter({ search: value })}
              />
            </div>

            <div className="mb-2">
              <button
                type="button"
                className="btn btn-fill-secondary text-nowrap"
                onClick={onResetFilter}
              >
                {intl.t('form.actions.resetFilters')}
              </button>
            </div>
          </div>
        </div>

        <TableComponent
          {...props}
          filters={filters}
          onFilter={onFilter}
          onView={setViewId}
          onRemove={onRemove}
          onEdit={handleEdit}
          onFileEdit={handleFileEdit}
        />
      </div>

      {editExportId !== null ? (
        <FormBGJobExport
          id={editExportId}
          onClose={() => setEditExportId(null)}
          dialog={{
            title: intl.t('jobs.titleCreateExport'),
            dataTestid: 'createBGJobExportDialog',
          }}
        />
      ) : null}

      {editNotebookId !== null ? (
        <FormBGJobNotebook
          id={editNotebookId}
          onClose={() => setEditNotebookId(null)}
          dialog={{
            title: editNotebookId ?
              intl.t('jobs.titleUpdateNotebook') :
              intl.t('jobs.titleCreateNotebook'),
            dataTestid: 'replaceBGJobNotebookDialog',
          }}
        />
      ) : null}

      {editImportDataId !== null ? (
        <FormBGJobImport
          id={editImportDataId}
          onClose={() => setEditImportDataId(null)}
          dialog={{
            title: editImportDataId ?
              intl.t('jobs.titleUpdateImportData') :
              intl.t('jobs.titleCreateImportData'),
            dataTestid: 'replaceBGJobImportDataDialog',
          }}
        />
      ) : null}

      {editFile ? (
        <FormFile
          id={editFile}
          onClose={() => setEditFile(null)}
          dialog={{
            title: intl.t('files.titleUpdate'),
            dataTestid: 'updateFileDialog',
          }}
        />
      ) : null}

      {viewId ? (
        <ViewModelData
          id={viewId}
          title={intl.t('jobs.titleDetail')}
          onClose={() => setViewId(null)}
          onRemove={onRemove}
          onEdit={handleEdit}
          dialog
        />
      ) : null}
    </div>
  )
}
