import { useState } from 'react'
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import { useRouter, useLocationQuery } from '@/router'
import events from '@/utils/events'
import { wizataApi } from '@/api'
import { useAuth } from '@/auth'
import { Skeleton, Icon as LegacyIcon } from '@wiz/components'
import Icon from '@/shared/icon'
import useAppContext from '@/hooks/useAppContext'
import { useIntl } from '@wiz/intl'
import FormPipeline from '@/components/Forms/Pipeline'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'

const SinglePipelineActions = ({
  onRun,
}) => {
  const [ editId, setEditId ] = useState()

  const { contextTwinId } = useGlobalExecute()
  const { onFilter } = useAppContext()

  const queryClient = useQueryClient()

  const auth = useAuth()
  const intl = useIntl()
  const { pipelineId } = useLocationQuery()
  const router = useRouter()

  const { data: item, isLoading } = useQuery({
    queryKey: [ 'pipeline', pipelineId ],
    queryFn: () => wizataApi.pipelines.getById(pipelineId),
    enabled: !!pipelineId,
    staleTime: Infinity,
  })

  const {
    mutate: deletePipeline,
  } = useMutation({
    mutationKey: [ 'deletePipeline' ],
    mutationFn: () => wizataApi.pipelines.deleteById(pipelineId),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/templates.createError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'pipelinesList' ] })
      router.push({ name: 'pipelines-list' })
    },
  })

  const handleRemove = async () => {
    await window.wizConfirm({ message: 't/units.confirmDelete' })
    deletePipeline()
  }

  const handleLogsClick = () => {
    onFilter({ pipelineId })
    router.push({ name: 'executions-list' })
  }

  const handleExperimentsClick = () => {
    onFilter({ pipelineId })
    router.push({ name: 'experiments-list' })
  }

  return (
    <>
      <div className="d-flex">
        {auth.checkAccessUpdate('Pipeline') ? (
          <button
            type="button"
            className="btn btn-fill-secondary-alt btn-text me-2"
            onClick={() => setEditId(pipelineId)}
          >
            <Icon name="faPenToSquare" className="me-2" />
            {intl.t('form.actions.edit')}
          </button>
        ) : null}
        {auth.checkAccessRemove('Pipeline') ? (
          <button
            type="button"
            className="btn btn-fill-secondary-alt btn-text me-2"
            onClick={handleRemove}
          >
            <Icon name="faTrash" className="me-2" />
            {intl.t('form.actions.remove')}
          </button>
        ) : null}
        <div className="fw-bold my-auto">
          {isLoading ? <Skeleton width={120} height={30} /> : (
            <>
              <LegacyIcon name="wiz--menu--pipeline" className="me-1" />
              {item?.key}
            </>
          )}
        </div>
      </div>
      <div className="d-flex ms-auto">
        <button
          type="button"
          className="btn btn-fill-secondary-alt btn-text me-2"
          onClick={handleLogsClick}
        >
          <Icon name="faClipboardList" className="me-2" />
          {intl.t('form.actions.logs')}
        </button>
        <button
          type="button"
          className="btn btn-fill-secondary-alt btn-text me-2"
          onClick={handleExperimentsClick}
        >
          <Icon name="faFlask" className="me-2" />
          {intl.t('experiments.title')}
        </button>
        {auth.checkAccessCreate('HistoricalRun') ? (
          <button
            type="button"
            className="btn btn-fill-secondary-alt btn-text me-2"
            onClick={() => onRun('execute', null, null, { pipelineId, pipeline: item })}
          >
            <Icon name="faCirclePlay" className="me-2" />
            {intl.t('form.actions.execute')}
          </button>
        ) : null}
      </div>

      {editId ? (
        <FormPipeline
          id={editId}
          onClose={() => setEditId(null)}
          onUpdate={() => queryClient.invalidateQueries({ queryKey: [ 'pipelinesList' ] })}
          dialog={{
            title: intl.t('pipelines.form.fields.title'),
            dataTestid: 'replacePipelineDialog',
          }}
        />
      ) : null}
    </>
  )
}

export default SinglePipelineActions
