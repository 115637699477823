import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import { get } from '@wiz/utils'
import {
  FormField, FormControl, FormFieldInline, FormCheckbox,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors }, getValues } = useFormContext()

  const request = getValues()

  return (
    <>
      <FormField
        label={intl.t('form.fields.function')}
        description={intl.t('executions.form.fields.functionDescr')}
        errors={get(errors, `${scope}.function`)}
      >
        <FormControl
          name={`${scope}.function`}
          disabled
        />
      </FormField>
      <FormFieldInline
        label={intl.t('entities.form.fields.isAnomalyDetection')}
        description={intl.t('entities.form.fields.isAnomalyDetectionDescr')}
        errors={get(errors, `${scope}.isAnomalyDetection`)}
      >
        <Controller
          shouldUnregister
          name={`${scope}.isAnomalyDetection`}
          render={({ field, fieldState }) => (
            <FormCheckbox
              {...field}
              invalid={fieldState.invalid}
              className="ms-2"
              disabled
              onChange={() => {
              }}
            />
          )}
        />
      </FormFieldInline>
      <FormFieldInline
        label={intl.t('entities.form.fields.request')}
        description={intl.t('entities.form.fields.requestDescr')}
        errors={get(errors, `${scope}.request`)}
      />
      <pre className="overflow-auto form-control mt-2" style={{ maxHeight: 300 }}>
        {JSON.stringify(request, null, 2)}
      </pre>
    </>
  )
}

Fields.propTypes = {
  scope: PropTypes.string.isRequired,
}
