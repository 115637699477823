/* eslint-disable react/no-danger */
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { VirtualTable, Icon } from '@wiz/components'
import { Edge } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import ButtonCopy from '@/components/Form/ButtonCopy'
import Link from '@/components/Link'

export default function TableMobile ({
  items,
  filters,
  onFilter,
  onRemove,
  onEdit,
  onView,
  onUnlink,
  ...props
}) {
  const intl = useIntl()
  const columns = useMemo(() => [
    {
      accessor: 'name',
      Cell: ({ cell, row }) => (
        <div className="min-w-0">
          <div className="d-flex align-items-center min-w-0">
            <span className="user-select-all text-truncate">{cell.value}</span>
            <ButtonCopy value={cell.value} className="text-muted" />
          </div>

          {row.original.runtimeResponse ? (
            <div className="font-italic text-muted small user-select-all text-truncate">
              {row.original.runtimeResponse}
            </div>
          ) : null}
        </div>
      ),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 90,
      width: 90,
      maxWidth: 90,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell }) => (
        <>
          <Link
            name="edge-devices"
            className="p-2"
            params={{ id: cell.value }}
          >
            {intl.t('form.actions.view')}
          </Link>
          <button
            type="button"
            className="btn btn-sm p-2"
            onClick={() => onView?.(cell.value)}
          >
            <Icon name="fa--chevron-right" />
          </button>
        </>
      ),
    },
  ], [
    onView,
    intl,
  ])

  return (
    <VirtualTable
      className="flex-fill"
      placeholder={intl.t('errors.noDataDisplay')}
      columns={columns}
      data={items}
      showHeader={false}
      selection={false}
      {...props}
    />
  )
}

TableMobile.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(Edge)),
  filters: PropTypes.object,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onUnlink: PropTypes.func,
  onFilter: PropTypes.func.isRequired,
}

TableMobile.defaultProps = {
  items: [],
  filters: {},
  onRemove: undefined,
  onEdit: undefined,
  onView: undefined,
  onUnlink: undefined,
}
