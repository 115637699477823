import {
  intersection,
  type,
  array,
  string,
} from 'superstruct'
import { AccessRole } from '@wiz/store'
import * as types from '@/utils/types'

export default type({
  accessRole: type({
    name: intersection([
      types.Required(),
      AccessRole.schema.schema.name,
    ]),
  }),
  accessRoleIds: array(string()),
  groupAccessRoleIds: array(string()),
})
