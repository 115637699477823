import {
  Controller,
  useFormContext,
} from 'react-hook-form'
import { get } from '@wiz/utils'
import {
  useDidUpdate,
  FormField,
  FormFieldInline,
  FormControl,
  FormCheckbox,
  FormSelectNative,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import ProjectList from '@/hoc/ProjectList'
import Select from '@/components/Form/Select'
import SelectDataSources from '@/components/Form/SelectDataSources'
import FormatDateTime from '@/containers/FormatDateTime'

const ListCommands = [
  { id: 'FileExport', name: 'FileExport' },
  { id: 'CreateNotebook', name: 'CreateNotebook' },
]

const ListDataTypes = [
  { id: 'raw', name: 'Raw' },
  { id: 'aggregated', name: 'Aggregated' },
]

export default function Fields ({
  scope,
  exclude,
  disabled,
}) {
  const intl = useIntl()
  const auth = useAuth()

  const {
    register,
    formState: { errors },
    setValue,
    watch,
    trigger,
  } = useFormContext()

  const [
    dateFrom,
    dateTo,
    dataType,
    isBatch,
  ] = watch([
    `${scope}.parameters.From`,
    `${scope}.parameters.To`,
    `${scope}.parameters.DataType`,
    `${scope}.parameters.IsBatch`,
  ])

  useDidUpdate(() => {
    trigger([
      `${scope}.parameters.From`,
      `${scope}.parameters.To`,
    ])
  }, [ dateFrom, dateTo ])

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('jobs.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
            maxLength: {
              value: 200,
              message: intl.t('form.errors.fieldMaxlen', { max: 200 }),
            },
            minLength: {
              value: 5,
              message: intl.t('form.errors.fieldMinlen', { min: 5 }),
            },
          }}
          placeholder={intl.t('jobs.form.fields.namePlaceholder')}
        />
      </FormField>

      {exclude?.includes('command') ? (
        <input
          {...register(`${scope}.command`)}
          type="hidden"
        />
      ) : (
        <FormField
          label={intl.t('form.fields.command')}
          description={intl.t('jobs.form.fields.commandDescr')}
          errors={get(errors, `${scope}.command`)}
        >
          <FormControl
            type="select"
            name={`${scope}.command`}
            rules={{
              required: intl.t('form.errors.fieldRequired'),
            }}
            placeholder={intl.t('form.fields.commandPlaceholder')}
            options={ListCommands}
            disabled={disabled}
          />
        </FormField>
      )}

      <FormField
        label={intl.t('form.fields.sensors')}
        description={intl.t('jobs.form.fields.sensorsDescr')}
        errors={get(errors, `${scope}.parameters.SensorsAggregates`)}
      >
        <Controller
          name={`${scope}.parameters.SensorsAggregates`}
          rules={{
            validate: (value) => {
              if (!value?.length) {
                return intl.t('form.errors.fieldRequired')
              }
              return true
            },
          }}
          render={({ field, fieldState }) => (
            <SelectDataSources
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorsPlaceholder')}
              batchOnly={isBatch}
              disabled={disabled}
              multiselect
              unique
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.dateFrom')}
        description={intl.t('jobs.form.fields.dateFromDescr')}
        errors={get(errors, `${scope}.parameters.From`)}
      >
        <FormControl
          type="dateTime"
          name={`${scope}.parameters.From`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
            validate: (value) => {
              if (value && dateTo && value > dateTo) {
                return intl.t('form.errors.dateFromOutofrange')
              }
              return true
            },
          }}
          placeholder={intl.t('form.fields.datePlaceholder')}
          DateTimeComponent={FormatDateTime}
          disabled={disabled}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.dateTo')}
        description={intl.t('jobs.form.fields.dateToDescr')}
        errors={get(errors, `${scope}.parameters.To`)}
      >
        <FormControl
          type="dateTime"
          name={`${scope}.parameters.To`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
            validate: (value) => {
              if (value && dateFrom && value < dateFrom) {
                return intl.t('form.errors.dateToOutofrange')
              }
              return true
            },
          }}
          placeholder={intl.t('form.fields.datePlaceholder')}
          DateTimeComponent={FormatDateTime}
          disabled={disabled}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.dataType')}
        description={intl.t('jobs.form.fields.dataTypeDescr')}
        errors={get(errors, `${scope}.parameters.DataType`)}
      >
        <Controller
          name={`${scope}.parameters.DataType`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <FormSelectNative
              {...field}
              invalid={fieldState.invalid}
              options={ListDataTypes}
              disabled={disabled}
              onChange={(value) => {
                field.onChange(value)
                setValue(`${scope}.parameters.Interval`, null, { shouldDirty: true })
              }}
            />
          )}
        />
      </FormField>

      {dataType === 'aggregated' ? (
        <>
          <FormField
            label={intl.t('form.fields.step')}
            description={intl.t('jobs.form.fields.stepDescr')}
            errors={get(errors, `${scope}.parameters.Interval`)}
          >
            <FormControl
              shouldUnregister
              type="duration"
              name={`${scope}.parameters.Interval`}
              rules={{
                validate: (value) => {
                  if (isBatch) {
                    return true
                  }

                  if (!value || value < 1000) {
                    return intl.t('form.errors.fieldRequired')
                  }

                  return true
                },
              }}
              parts="years,months,weeks,days,hours,minutes,seconds"
              disabled={isBatch || disabled}
              clearable
            />
          </FormField>

          <FormFieldInline
            label={intl.t('form.fields.batch')}
            description={intl.t('jobs.form.fields.batchDescr')}
            errors={get(errors, `${scope}.parameters.IsBatch`)}
          >
            <Controller
              shouldUnregister
              name={`${scope}.parameters.IsBatch`}
              render={({ field, fieldState }) => (
                <FormCheckbox
                  {...field}
                  invalid={fieldState.invalid}
                  className="ms-2"
                  disabled={disabled}
                  onChange={(value) => {
                    field.onChange(value)
                    setValue(`${scope}.parameters.SensorsAggregates`, [], { shouldDirty: true })
                    setValue(`${scope}.parameters.Interval`, null, { shouldDirty: true })
                  }}
                />
              )}
            />
          </FormFieldInline>
        </>
      ) : null}

      <FormFieldInline
        label={intl.t('form.fields.isLocalTime')}
        description={intl.t('jobs.form.fields.isLocalTimeDescr')}
        errors={get(errors, `${scope}.parameters.IsLocalTime`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.parameters.IsLocalTime`}
          className="ms-2"
          disabled={disabled}
        />
      </FormFieldInline>

      {auth.checkAccessRead('Project') ? (
        <FormField
          label={intl.t('form.fields.projects')}
          description={intl.t('jobs.form.fields.projectsDescr')}
          errors={get(errors, 'projects')}
        >
          <Controller
            name="projects"
            render={({ field, fieldState }) => (
              <Select
                {...field}
                invalid={fieldState.invalid}
                ListSource={ProjectList}
                placeholder={intl.t('form.fields.projects')}
                multiselect
              />
            )}
          />
        </FormField>
      ) : null}
    </>
  )
}
