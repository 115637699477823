import { useRef, useCallback } from 'react'
import FormDialog from '@/components/Form/FormDialog'
import Section from './Section'
import SectionFiles from './SectionFiles'

export default function FileUpload ({
  meta,
  dialog,
  onClose,
}) {
  const refFiles = useRef()

  const handleReset = useCallback(() => {
    refFiles.current.reset()
  }, [])

  const handleSubmit = useCallback(async () => {
    await refFiles.current.upload()
  }, [])

  return (
    <FormDialog
      onSubmit={handleSubmit}
      onReset={handleReset}
      onClose={onClose}
      dataTestid="fileUploadForm"
      dialog={dialog}
    >
      <Section />

      <SectionFiles
        meta={meta}
        refFiles={refFiles}
      />
    </FormDialog>
  )
}
