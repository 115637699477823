import { Icon } from '@wiz/components'
import { useAuth } from '@/auth'
import Observe from '@/containers/Observe'
import Link from '@/components/Link'

export default function CellCategory ({
  sensor,
}) {
  const auth = useAuth()
  return sensor.categoryId ? (
    <Observe query={sensor.category}>
      {({ data }) => (data ? (
        <>
          {data.icon || data.color ? (
            <Icon
              name={data.icon || 'fa--circle'}
              color={data.color}
              className="me-2"
              size="lg"
            />
          ) : null}
          {auth.checkAccessRead('SectionDigitalTwinCategories') ? (
            <Link
              name="categories-list"
              query={{ search: data.id }}
              className="text-truncate"
            >
              {data.name}
            </Link>
          ) : (
            <span className="text-truncate">
              {data.name}
            </span>
          )}
        </>
      ) : null)}
    </Observe>
  ) : '-'
}
