import { memo, useCallback } from 'react'
import {
  dbProvider,
  Dashboard,
  DataSource,
  DataView,
  DataFilter,
} from '@wiz/store'
import { withProps } from '@wiz/components'
import events from '@/utils/events'
import FormCreateWidgets from '@/components/Forms/CreateWidgets'

const enhanceProps = withProps(({ params }) => {
  const onSubmit = useCallback(async ({
    dashboards,
    newDashboards,
    disabledWidgets,
    sensors,
  }) => {
    let widgets = params?.widgets ?? []

    if (sensors.length) {
      widgets.push({ id: 'chart', type: 'chart' })
      widgets = widgets.concat(
        sensors
          .map(sensorId => ({
            id: `sensor/${sensorId}`,
            type: 'sensor',
            sensorId,
          })),
      )
    }

    let newDashboardIdx = 1

    const context = dbProvider.createBatchContext()
    for (const dashboardId of dashboards) {
      const disabled = disabledWidgets[dashboardId] ?? []
      const currentWidgets = widgets.filter(item => !disabled.includes(item.id))
      const dashboardData = newDashboards.find(item => item.id === dashboardId)
      if (dashboardData && !dashboardData.title) {
        dashboardData.title = `New Dashboard ${newDashboardIdx}`
        newDashboardIdx += 1
      }

      const dashboard = await dbProvider.prepareReplaceData(context, Dashboard, {
        ...dashboardData,
        id: dashboardId,
      })

      for (const item of currentWidgets) {
        if (item.type === 'sensor') {
          const ds = DataSource.toJSON({ sensorId: item.sensorId, dataType: 'avg' })
          const dv = DataView.toJSON({ sourceId: ds.id })
          const widget = await dashboard.prepareAttachWidget(context, { type: item.type })
          await widget.prepareReplaceDataViews(context, [ dv ], [ ds ])
        } else if (item.type === 'chart' && !item.prepareSaveContextTo) {
          const dataViews = []
          const dataSources = []
          for (const sensorId of sensors) {
            const ds = DataSource.toJSON({ sensorId, dataType: 'avg' })
            const dv = DataView.toJSON({ sourceId: ds.id })
            dataViews.push(dv)
            dataSources.push(ds)
          }
          const widget = await dashboard.prepareAttachWidget(context, { type: item.type })
          await widget.prepareReplaceDataViews(context, dataViews, dataSources)
          await widget.prepareReplaceFilter(context, DataFilter.toJSON())

        // from Data Explorer
        } else if (item.type === 'chart' && item.prepareSaveContextTo) {
          const widget = await dashboard.prepareAttachWidget(context, {
            type: item.type,
            title: 'Explorer',
          })
          await item.prepareSaveContextTo(context, widget)
        }
      }
    }

    if (context.size) {
      try {
        await dbProvider.batch(context)
        events.emit('app:notify', {
          type: 'success',
          title: 't/dashboards.title',
          message: 't/dashboards.form.success.update',
          duration: 2000,
        })
      } catch (error) {
        events.emit('app:notify', {
          type: 'error',
          title: 't/dashboards.title',
          message: 't/dashboards.form.errors.update',
        })
        throw error
      }
    }
  }, [ params ])

  return {
    onSubmit,
  }
})

export default memo(enhanceProps(FormCreateWidgets))
