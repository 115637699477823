import { of as of$, Observable, combineLatest } from 'rxjs'
import { tap, map, switchMap } from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { network, OnlineTimeout } from '@wiz/utils'
import { intl } from '@/i18n'
import { auth } from '@/auth'
import { appEnv } from '@/config'
import { registerDataSyncStream, unregisterDataSyncStream } from '@/api'
import events from '@/utils/events'
import * as analytics from '@/utils/analytics'

function handleDataSync () {
  dbProvider.sync.sync()
}

function handleOnlineStatus () {
  dbProvider.sync.unobserve()
  unregisterDataSyncStream(handleDataSync)
  events.removeListener('app:sync', handleDataSync)

  if (network.onLine) {
    dbProvider.sync.observe()
    registerDataSyncStream(handleDataSync)
    events.addListener('app:sync', handleDataSync)
    events.emit('app:notify:remove', {
      id: '90144f59-77b1-4dc4-b0b1-b5ccb4b6337e',
    })
  } else {
    events.emit('app:notify', {
      id: '90144f59-77b1-4dc4-b0b1-b5ccb4b6337e',
      group: 'sync',
      type: 'warn',
      title: 't/errors.syncOffline',
      icon: 'fa--wifi-slash',
      weight: 35,
      duration: -1,
    })
  }
}

function handleAppVersion (version) {
  window.__sw_reg__?.update?.()
  dbProvider.scheduledResetDatabase(false)
  const shortVersion = version.split('-', 2)[0]

  events.emit('app:notify', {
    group: 'nextVersion',
    type: 'info',
    weight: 30,
    title: intl.t('app.version.updateTitle', { version: shortVersion }),
    duration: -1,
    onAction: () => {
      document.getElementById('startup-loader').show()
      window.setTimeout(() => {
        window.ReactNativeWebView?.postMessage('reload')
        window.location.reload()
      }, 5000)
    },
  })
}

export default function observeDBInitialize (accountId) {
  const observeUser = () => (
    dbProvider.database.collections.get('users')
      .query(
        Q.where('is_deleted', false),
        Q.where('is_active', true),
        Q.where('ad_id', accountId),
      )
      .observeWithColumns([
        'email',
        'first_name',
        'last_name',
        'phone',
      ])
      .pipe(
        switchMap(items => (
          items.length ? (
            items[0].observeAccessRoles.pipe(
              map(roles => ({
                ...items[0].toJSON(),
                roleIds: roles.map(r => r.id),
              })),
            )
          ) : of$(undefined)
        )),
      )
  )

  const observeRoles = () => (
    combineLatest(
      dbProvider.database.collections.get('access_roles')
        .query()
        .observeWithColumns([ 'updated_at' ]),

      dbProvider.database.collections.get('rel_access_roles')
        .query()
        .observeWithColumns([ 'updated_at' ]),

      dbProvider.database.collections.get('access_permissions')
        .query()
        .observeWithColumns([ 'updated_at' ]),
    ).pipe(
      map(([ roles, rels, permissions ]) => (
        roles.map(item => ({
          ...item.toJSON(),
          roleIds: rels.filter(r => r.groupRoleId === item.id).map(r => r.childRoleId),
          permissions: permissions.filter(m => m.accessRoleId === item.id).map(m => m.toJSON()),
        }))
      )),
    )
  )

  return new Observable((subscriber) => {
    let syncTimeout

    function handleDataSyncTimeout () {
      syncTimeout?.cancel()
      syncTimeout = new OnlineTimeout(() => {
        dbProvider.sync.sync()
        handleDataSyncTimeout()
      }, 3 * 60 * 1000)
    }

    const subscription = combineLatest(observeUser(), observeRoles(), dbProvider.observeFlags())
      .pipe(
        tap(([ user, roles, flags ]) => {
          dbProvider.setUserId(user.id)
          auth.updateCurrentUser(user, roles, flags)
          analytics.registerUser(auth.getCurrentUser())
          events.addListener('app:version', handleAppVersion)
          network.observe({ pingUrl: appEnv.VERSION_URL })
          network.on('status', handleOnlineStatus)
          handleOnlineStatus()
          handleDataSyncTimeout()
          events.emit('app:ready', true)
        }),
      ).subscribe(() => {
        subscriber.next(true)
      })

    return () => {
      subscription.unsubscribe()
      syncTimeout?.cancel()
      events.removeListener('app:version', handleAppVersion)
      network.removeListener('status', handleOnlineStatus)
      network.unobserve()
      analytics.unregisterUser()
      dbProvider.sync.unobserve()
      unregisterDataSyncStream(handleDataSync)
      events.removeListener('app:sync', handleDataSync)
      events.emit('app:ready', false)
    }
  })
}
