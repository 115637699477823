import {
  useState,
  useCallback,
  useContext,
} from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@wiz/intl'
import {
  FormInputSearch,
  Icon,
  Pagination,
  FormSwitch,
} from '@wiz/components'
import TwinGraphExecute from '@/context/TwinGraphExecute'
import Link from '@/components/Link'
import FormEvent from '@/containers/Forms/Event'
import EventsList from '@/containers/RightBarEvents/List'

export default function Events ({
  blocks,
  blockSettings,
  twinIds,
  onChange,
  onRemoveEvent,
}) {
  const intl = useIntl()
  const executeContext = useContext(TwinGraphExecute)
  const [ editId, setEditId ] = useState(null)
  const [ editDefaultValues, setEditDefaultValues ] = useState({})
  const [ search, setSearch ] = useState('')
  const [ sortBy ] = useState('createdDate')
  const [ sortDir ] = useState('desc')
  const [ pageSize ] = useState(20)
  const [ total, setTotal ] = useState(0)
  const [ currentPage, setCurrentPage ] = useState(1)
  const [ forceUpdate, setForceUpdate ] = useState(null)

  const handleRemoveEvent = useCallback(async (event) => {
    await onRemoveEvent(event)
    setForceUpdate(Math.random())
  }, [ onRemoveEvent ])

  const handleEditEvent = useCallback((event) => {
    setEditId(event.id)
  }, [])

  const handleAttachEvent = useCallback((twin) => {
    setEditDefaultValues({ status: 'active', sourceType: 'manual', twinId: twin.id })
    setEditId('')
  }, [])

  const handleOpenExplorer = useCallback(({ sensorIds, event }) => {
    onChange('explorer', {
      title: event.name || intl.t('events.title'),
      props: {
        sensorIds,
        event,
      },
    })
  }, [ onChange, intl ])

  const handleClickPage = useCallback((page) => {
    let nextPage = currentPage
    if (page === 'prev') {
      nextPage -= 1
    } else if (page === 'next') {
      nextPage += 1
    } else if (page === 'first') {
      nextPage = 1
    } else if (Number.isFinite(page)) {
      nextPage = page
    }

    setCurrentPage(nextPage)
  }, [ currentPage ])

  const handleChangeTotal = useCallback((count) => {
    setTotal((currentPage - 1) * pageSize + count)
  }, [ currentPage, pageSize ])

  if (!twinIds.length) {
    return (
      <div className="position-center-fill flex-column">
        <h6>
          {intl.t('twinGraph.form.errors.twinsUndefined')}
        </h6>
      </div>
    )
  }

  return (
    <>
      <form className="d-flex flex-column my-2 mx-3">
        <div className="flex-fill d-flex">
          <div className="flex-fill me-2">
            <FormInputSearch
              size="sm"
              placeholder={intl.t('form.actions.searchPlaceholder')}
              onChange={setSearch}
            />
          </div>

          <Link
            className="btn btn-sm btn-fill-secondary text-nowrap me-2"
            name="events-list"
            query={{
              statuses: 'active',
              twinIds,
            }}
          >
            {intl.t('twinGraph.form.actions.exploreEvents')}
          </Link>

          <button
            className="btn btn-sm btn-fill-secondary btn-text"
            type="button"
            name="refresh"
            onClick={() => setForceUpdate(Math.random())}
          >
            <Icon name="fa--sync" />
          </button>
        </div>

        <div className="mt-1">
          <FormSwitch
            name="includeChildTwinEvents"
            checked={executeContext.includeChildTwinEvents}
            label={intl.t('twinGraph.form.fields.includeChildren')}
            onChange={() => executeContext.toggleIncludeChildTwinEvents()}
          />
        </div>
      </form>

      <EventsList
        forceUpdate={forceUpdate}
        blocks={blocks}
        blockSettings={blockSettings}
        sortBy={sortBy}
        sortDir={sortDir}
        search={search}
        twinIds={twinIds}
        statuses="active"
        limit={(pageSize + 1)}
        offset={(currentPage - 1) * pageSize}
        includeChildTwinEvents={executeContext.includeChildTwinEvents}
        onRemoveEvent={handleRemoveEvent}
        onEditEvent={handleEditEvent}
        onAttachEvent={handleAttachEvent}
        onOpenExplorer={handleOpenExplorer}
        onChangeCount={handleChangeTotal}
      />

      <Pagination
        className="p-2 justify-content-center"
        pageNavigation={false}
        pageSizeNavigation={false}
        showTotal={false}
        currentPage={currentPage}
        pageSize={pageSize}
        total={total}
        alwaysFastFirst
        relative
        onClickPage={handleClickPage}
      />

      {editId !== null ? (
        <FormEvent
          id={editId}
          defaultValues={editDefaultValues}
          onSuccess={() => setForceUpdate(Math.random())}
          onClose={() => {
            setEditDefaultValues({})
            setEditId(null)
          }}
          dialog={{
            title: editId ? intl.t('events.titleUpdate') : intl.t('events.titleCreate'),
            dataTestid: 'replaceEventDialog',
          }}
        />
      ) : null}
    </>
  )
}

Events.propTypes = {
  blocks: PropTypes.array.isRequired,
  blockSettings: PropTypes.object.isRequired,
  twinIds: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemoveEvent: PropTypes.func.isRequired,
}
