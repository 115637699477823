import { useCallback } from 'react'
import { withObservables, withProps } from '@wiz/components'
import { dbProvider } from '@wiz/store'

const enhanceSettings = withObservables([], () => ({
  settings: dbProvider.observeGlobalSettings([
    'SeriesQueryMaxCount',
    'RawDateRangeLimit',
    'RawQueryLimit',
  ]),
}))

const enhanceProps = withProps(({ widget }) => {
  const onSubmit = useCallback(async (data) => {
    if (widget) {
      const context = dbProvider.createBatchContext()
      await widget.prepareUpdateData(context, data.widget)
      await widget.prepareReplaceFilter(context, data.config.dataFilter)
      await widget.prepareReplaceDataViews(context, data.config.dataViews, data.config.dataSources)
      await widget.prepareReplaceConditions(context, data.config.conditions)
      await widget.prepareReplaceEventSources(context, data.config.eventSources)

      context.on('success', async (successBatchContext) => {
        await widget.prepareReplaceDataSourceSettings(successBatchContext, data.config.dataSources)
      })

      await dbProvider.batch(context)
    }
  }, [ widget ])

  return {
    onSubmit,
  }
})

export default function enhanceSettingsChart (Component) {
  return enhanceSettings(enhanceProps(Component))
}
