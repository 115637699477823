import { useCallback, useMemo } from 'react'
import { of as of$ } from 'rxjs'
import { withProps, withObservables } from '@wiz/components'
import { dbProvider, Widget } from '@wiz/store'
import enhanceSettingsSensor from '@/containers/Forms/WidgetSensor/enhanceSettingsSensor'
import enhanceSettingsPlotly from '@/containers/Forms/WidgetPlotly/enhanceSettingsPlotly'
import enhanceSettingsTableData from '@/containers/Forms/WidgetTableData/enhanceSettingsTableData'

const enhanceProps = withProps(({
  block,
  settings,
  onSubmitBlock, // block submit
  onSubmit, // widget submit
}) => {
  const onCreateWidget = useCallback(async (type) => {
    const context = dbProvider.createBatchContext()
    await block.prepareReplaceSettings(context, { type })
    await dbProvider.batch(context)
  }, [ block ])

  const onRemoveWidget = useCallback(async () => {
    const context = dbProvider.createBatchContext()
    await block.prepareRemoveSettings(context)
    await dbProvider.batch(context)
  }, [ block ])

  const onSubmitAll = useCallback(async (data) => {
    const widget = settings ? Widget.toJSON(settings) : undefined
    await onSubmitBlock({ block: data.block, settings: widget })
    if (onSubmitBlock !== onSubmit) {
      await onSubmit({ widget, config: data.config })
    }
  }, [ settings, onSubmitBlock, onSubmit ])

  return {
    onCreateWidget,
    onRemoveWidget,
    onSubmit: onSubmitAll,
  }
})

const enhanceProxyWidget = withProps(({ settings, onSubmit }) => ({
  widget: settings,
  onSubmitBlock: onSubmit,
}))

const enhanceProxyWidgetConfig = withObservables([ 'widget' ], ({ widget }) => ({
  config: widget ? widget.observeConfig : of$({}),
}))

export default function enhanceWidgetConfig (Component) {
  return enhanceProxyWidget(
    enhanceProxyWidgetConfig((props) => {
      const DynamicForm = useMemo(() => {
        let Form = enhanceProps(Component)
        switch (props.settings?.type) {
          case 'plotly':
            Form = enhanceSettingsPlotly(Form)
            break
          case 'sensor':
            Form = enhanceSettingsSensor(Form)
            break
          case 'tableData':
            Form = enhanceSettingsTableData(Form)
            break
          default:
        }
        return Form
      }, [ props.settings?.type ])

      return (
        <DynamicForm {...props} />
      )
    }),
  )
}
