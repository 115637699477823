import { useCallback, memo } from 'react'
import { dbProvider, File } from '@wiz/store'
import { withProps } from '@wiz/components'
import { useAuth } from '@/auth'
import FormFile from '@/components/Forms/File'
import enhanceFile from './enhanceFile'

const enhanceProps = withProps(() => {
  const auth = useAuth()
  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareReplaceData(context, File, data.file)
    if (auth.checkAccessRead('Project')) {
      await model.prepareReplaceProjects(context, data.projects)
    }
    await dbProvider.batch(context)
  }, [ auth ])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceFile(
    enhanceProps(FormFile),
  ),
)
