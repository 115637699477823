/* eslint-disable no-unused-expressions */
import { useMemo } from 'react'
import { Table as StaticTable, Pagination } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'

export default function Table ({
  items,
  onRemove,
  onEdit,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      Cell: ({ cell, row }) => (
        auth.checkAccessUpdate(row.original) ? (
          <a
            className="d-flex align-items-center min-w-0 pointer"
            onClick={() => onEdit?.(row.original.id)}
            aria-hidden
          >
            {cell.value}
          </a>
        ) : cell.value
      ),
    },
    {
      Header: intl.t('edge.commandTemplates.form.fields.topic'),
      accessor: 'topic',
      disableResizing: true,
      disableSortBy: true,
    },
    {
      Header: intl.t('edge.commandTemplates.form.fields.payload'),
      accessor: 'payload',
      disableResizing: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <span className="text-truncate">
          {do {
            if (typeof cell.value === 'boolean' || typeof cell.value === 'number') {
              JSON.stringify(cell.value)
            } else {
              (cell.value)
            }
          }}
        </span>
      ),
    },
    {
      Header: intl.t('form.fields.dataType'),
      accessor: 'dataType',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
    },
    {
      Header: intl.t('form.fields.type'),
      accessor: 'type',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedAt',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 220,
      Cell: ({ cell, row }) => (<UpdatedInfo date={cell.value} user={row.original.updatedUser} />),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdAt',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 220,
      Cell: ({ cell, row }) => (<UpdatedInfo date={cell.value} user={row.original.createdUser} />),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell, row }) => (
        <>
          {auth.checkAccessUpdate(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0 me-2"
              onClick={() => onEdit?.(cell.value)}
            >
              {intl.t('form.actions.edit')}
            </button>
          ) : null}
          {auth.checkAccessRemove(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0"
              onClick={() => onRemove?.([ row.original ])}
            >
              {intl.t('form.actions.remove')}
            </button>
          ) : null}
        </>
      ),
    },
  ], [ intl, onEdit, onRemove, auth ])

  return (
    <>
      <StaticTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={auth.checkAccessRemove('DeviceCommandTemplate')}
        {...props}
      />

      <Pagination {...props} />
    </>
  )
}
