import { useState, useCallback } from 'react'
import { Icon } from '@wiz/components'
import { useAuth } from '@/auth'

export default function CellToggleActive ({
  streamJob,
  onToggleActive,
}) {
  const auth = useAuth()
  const [ loading, setLoading ] = useState(false)

  const handleToggleActive = useCallback(async (data) => {
    setLoading(true)
    await onToggleActive(data)
    setLoading(false)
  }, [ onToggleActive ])

  if (!auth.checkAccessUpdate(streamJob)) {
    return null
  }

  if (loading) {
    return (
      <Icon name="fa--spinner" spin />
    )
  }

  return (
    <div className="form-check form-switch m-0 mb-n1 ps-3">
      <input
        type="checkbox"
        className="form-check-input pointer ms-n3"
        checked={streamJob.active}
        onChange={() => handleToggleActive(streamJob)}
      />
    </div>
  )
}
