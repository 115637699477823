import { of as of$ } from 'rxjs'
import { withObservables } from '@wiz/components'

export default withObservables([
  'id',
  'explorer',
], ({
  id,
  explorer,
}) => ({
  explorerContext: explorer && explorer.id === id ?
    explorer.observeExplorerContext :
    of$({}),
}))
