import { useState, useRef, useEffect } from 'react'
import { Icon } from '@wiz/components'
import DSRedirect from '../Redirect'
import { intl } from '../../i18n'

const ButtonApi = () => {
  const refTarget = useRef()
  const [isRedirect, setIsRedirect] = useState(false)

  useEffect(() => {
    if (isRedirect) {
      setIsRedirect(false)
    }
  }, [ isRedirect ])

  const handleClick = () => setIsRedirect(true)

  return (
    <>
      {isRedirect ? <DSRedirect /> : null}
      <button
        ref={refTarget}
        type="button"
        className="btn btn-text btn-flat-secondary"
        onClick={handleClick}
        title={intl.t('menu.tooltip.api')}
      >
        <Icon name="wiz--docs--docs" width={20}/>
      </button>
    </>
  )
}

export default ButtonApi
