import { type, intersection } from 'superstruct'
import { Notebook } from '@wiz/store'
import * as types from '@/utils/types'

export default type({
  name: intersection([
    types.Required(),
    types.Minlen(5),
    types.Maxlen(200),
    Notebook.schema.schema.name,
  ]),
  environment: intersection([
    types.Required(),
    Notebook.schema.schema.environment,
  ]),
  language: intersection([
    types.Required(),
    Notebook.schema.schema.language,
  ]),
  format: intersection([
    types.Required(),
    Notebook.schema.schema.format,
  ]),
  description: intersection([
    types.Maxlen(450),
    Notebook.schema.schema.description,
  ]),
  templateFileId: intersection([
    types.Required(),
    Notebook.schema.schema.templateFileId,
  ]),
  useBlob: Notebook.schema.schema.useBlob,
  dataFileId: Notebook.schema.schema.dataFileId,
})
