import { useRef, useState, useMemo } from 'react'
import { debounce } from '@wiz/utils'
import { Icon, DropdownMenu } from '@wiz/components'
import classnames from 'classnames'
import { wizataApi } from '@/api'

export default function InputSearchLocation ({
  placeholder,
  onChange,
}) {
  const refInput = useRef()
  const refList = useRef()
  const [ items, setItems ] = useState([])
  const [ loading, setLoading ] = useState(false)

  const fetchRequest = useMemo(() => debounce(() => {
    const query = String(refInput.current.value || '').trim()
    if (!query) {
      refList.current.close()
      return setItems([])
    }

    setLoading(true)
    wizataApi.getGeoLocations(query)
      .then((data) => {
        setItems(data.map(item => ({ ...item, label: item.address })))
        if (data.length) {
          refList.current.open()
        } else {
          refList.current.close()
        }
      })
      .finally(() => setLoading(false))
  }, 500), [])

  return (
    <div className="input-group">
      <input
        ref={refInput}
        className={classnames('form-control rounded', {
          'pe-4': loading,
        })}
        type="text"
        placeholder={placeholder}
        onChange={() => fetchRequest()}
        onClick={() => {
          if (items.length) {
            refList.current.open()
          }
        }}
      />
      {loading ? (
        <span
          className="input-group-text border-start-0 bg-transparent position-absolute px-2"
          style={{ right: 0, zIndex: 3 }}
        >
          &nbsp;
          <Icon name="fa--spinner" spin />
        </span>
      ) : null}
      <DropdownMenu
        ref={refList}
        target={refInput}
        options={items}
        mode={null}
        autoclose
        placement="bottom-end"
        onClick={({ label, location }) => {
          if (location?.lat && location?.lng) {
            refInput.current.value = label
            onChange?.(location.lat, location.lng)
          }
        }}
      />
    </div>
  )
}
