import { useCallback, memo } from 'react'
import { dbProvider, Script } from '@wiz/store'
import { withProps } from '@wiz/components'
import FormScript from '@/components/Forms/Script'
import enhanceScript from './enhanceScript'
import enhanceInterfaces from './enhanceInterfaces'
import enhanceUsage from './enhanceUsage'

const enhanceProps = withProps(() => {
  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    await Script.prepareReplaceSettings(context, dbProvider.database, data)
    await dbProvider.batch(context)
  }, [])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceScript(
    enhanceUsage(
      enhanceInterfaces(
        enhanceProps(FormScript),
      ),
    ),
  ),
)
