import { dbProvider, Q } from '@wiz/store'

export const toUserNames = async (data) => {
  if (Array.isArray(data)) {
    const userIds = data.map(i => i.createdById)

    const updatedUsers = await dbProvider.database.collections.get('users')
      .query(Q.where('id', Q.oneOf(userIds)))
      .fetch()

    return data.map(i => ({
      ...i,
      user: updatedUsers.find(user => user.id === i.createdById),
    }))
  }
  const [ updatedUser ] = await dbProvider.database.collections.get('users')
    .query(Q.where('id', data.createdById))
    .fetch()

  return {
    ...data,
    user: updatedUser,
  }
}
