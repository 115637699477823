import {
  useRef, useCallback, useState, useEffect,
} from 'react'
import { useQuery } from '@tanstack/react-query'
import orderBy from 'lodash/orderBy'
import union from 'lodash/union'
import difference from 'lodash/difference'
import intersection from 'lodash/intersection'
import { Q, dbProvider } from '@wiz/store'
import { toArrayValue } from '@wiz/utils'
import { withProps } from '@wiz/components'
import { wizataApi } from '@/api'

export default withProps(({
  businessType,
  metaBlock,
  sensors,
  fullData,
  ...props
}) => {
  const [ sensorIds, setSensorIds ] = useState(() => sensors)
  const [ hardwareIds, setHardwareIds ] = useState(undefined)
  const [ allSettings, setAllSettings ] = useState(props.blockSettings)

  const experimentRef = useRef()

  const { data: executions } = useQuery({
    queryKey: [ 'executions', experimentRef.current ],
    queryFn: async () => {
      const res = await wizataApi.executions.getListByExperiment(experimentRef.current)

      const sorted = orderBy(res, [
        item => item.createdDate,
      ], [ 'asc' ])

      return sorted
    },
    enabled: !!experimentRef.current,
  })

  const { data: execution } = useQuery({
    queryKey: [ 'execution', executions?.[0]?.id ],
    queryFn: async () => (executions?.length ? wizataApi.executions.getById(executions[0].id) : null),
    enabled: !!executions?.length,
  })

  const onToggleByTwin = useCallback(async (twin) => {
    let query = dbProvider.database.collections.get('sensors')
      .query(Q.where('twin_id', twin.id))

    if (businessType) {
      query = query.extend(Q.where('business_type', businessType))
    }

    const sensorsByTwin = await query.fetch()
    const toggleIds = sensorsByTwin.map(item => item.id)
    const toggleHardwareIds = sensorsByTwin.map(item => item.hardwareId)

    const diff = difference(toggleIds, sensorIds)
    if (diff.length) {
      setSensorIds(union(sensorIds, toggleIds))
      setHardwareIds(union(hardwareIds, toggleHardwareIds))
    } else {
      setSensorIds(difference(sensorIds, toggleIds))
      setHardwareIds(difference(hardwareIds, toggleHardwareIds))
    }
  }, [ sensorIds, businessType, hardwareIds ])

  const onChangeSelection = useCallback(async (id, hardwareId) => {
    const arrayIds = toArrayValue(sensorIds)
    let arrayHardwareIds = toArrayValue(hardwareIds)

    if (arrayHardwareIds.length !== arrayIds.length) {
      const query = dbProvider.database.collections.get('sensors')
        .query(Q.where('id', Q.oneOf(arrayIds)))
      const next = await query.fetch()
      arrayHardwareIds = next.map(item => item.hardwareId).filter(item => !item)
    }
    if (arrayIds.includes(id)) {
      setSensorIds(arrayIds.filter(item => item !== id))
      setHardwareIds(arrayHardwareIds.filter(item => item !== hardwareId))
    } else {
      setSensorIds(arrayIds.concat(id))
      setHardwareIds(arrayHardwareIds.concat(hardwareId))
    }
  }, [ sensorIds, hardwareIds ])

  const onBlockRemove = useCallback(async (ids) => {
    await window.wizConfirm({ message: 't/twinGraph.confirmDeleteBlock' })
    const context = dbProvider.createBatchContext().setContextBlock(metaBlock)
    await metaBlock.prepareStrictRemoveByIds(context, ids)
    await dbProvider.batch(context)
  }, [ metaBlock ])

  const handleSettingsUpdate = useCallback((ids) => {
    setAllSettings((prev) => {
      const next = { ...prev }
      for (const id of ids) {
        delete next[id]
      }

      return next
    })
  }, [ ])

  const onSelectExperiment = (id) => {
    experimentRef.current = id
  }

  useEffect(() => {
    const diff = difference(Object.keys(props.blockSettings), Object.keys(allSettings))
    if (diff.length) {
      const next = { ...allSettings }
      for (const index of diff) {
        next[index] = props.blockSettings[index]
      }
      setAllSettings(next)
    }
  }, [ props.blockSettings ])

  useEffect(() => {
    // FIXME: think about it! it's weird (maybe NOT)
    if (sensorIds?.length !== sensors?.length) {
      if (sensorIds.length > sensors.length) {
        const intersec = intersection(sensors, sensorIds)

        setSensorIds(intersec)
      } else {
        const diff = difference(sensors, sensorIds)
        if (diff.length) {
          setSensorIds(sensors)
        }
      }
    }
  }, [ JSON.stringify(sensors) ])

  return {
    sensorIds,
    hardwareIds,
    onToggleByTwin,
    onChangeSelection,
    onBlockRemove,
    onSelectExperiment,
    handleSettingsUpdate,
    blockSettings: allSettings,
    selectedSettings: props.blockSettings,
    fullData: execution || fullData,
  }
})
