import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { Formula } from '@wiz/store'
import ObservFormula from '@/containers/Forms/Formula/Observ'
import SectionFormula from './SectionFormula'
import SectionFormulaPorts from './SectionFormulaPorts'

export default function SettingsSectionScript ({ scope }) {
  const {
    register,
    watch,
    setValue,
    clearErrors,
  } = useFormContext()
  const id = watch(`${scope}.formula.id`)
  const expression = watch(`${scope}.formula.expression`)

  const handleChange = useCallback(({ formula }) => {
    const next = Formula.toJSON(formula)
    setValue(`${scope}.id`, next.id, { shouldDirty: true })
    setValue(`${scope}.formula`, next, { shouldDirty: true })
    clearErrors(scope)
  }, [ scope, setValue, clearErrors ])

  return (
    <>
      <ObservFormula id={id} onChange={handleChange} />

      <input
        {...register(`${scope}.id`)}
        type="hidden"
        defaultValue={id}
      />

      <SectionFormula scope={`${scope}.formula`} />
      <SectionFormulaPorts scope={`${scope}.ports`} expression={expression} />
    </>
  )
}
