import { useState, useRef, useEffect } from 'react'
import { OnlineTimeout, AbortRequestError } from '@wiz/utils'
import { withProps } from '@wiz/components'
import { wizataApi } from '@/api'

const RealtimeDuration = 5 * 60 * 1000

const enhanceData = withProps(({
  forceUpdate,
  includeChildTwinEvents,
  limit,
  offset,
  realtime,
  search,
  sortBy,
  sortDir,
  statuses,
  twinIds,
  onChangeCount,
}) => {
  const refData = useRef([])
  const refOnChangeCount = useRef()
  const [ isLoading, setLoading ] = useState(false)
  const props = {
    includeChildTwinEvents,
    limit,
    offset,
    realtime,
    search,
    sortBy,
    sortDir,
    statuses,
    twinIds,
  }
  const immutableProps = JSON.stringify({ ...props, forceUpdate })
  refOnChangeCount.current = onChangeCount

  useEffect(() => {
    let request

    setLoading(true)
    wizataApi.events.list(props, (data) => {
      request = data
    })
      .then((data) => {
        refData.current = data
        refOnChangeCount.current?.(data.length)
        setLoading(false)
      })
      .catch((error) => {
        refData.current = []
        if (!(error instanceof AbortRequestError)) {
          setLoading(false)
        }
      })

    // if (realtime) {
    //   timer = new OnlineTimeout(() => {
    //     request = fetchEvents(props, onFinally)
    //   }, RealtimeDuration)
    // }

    return () => {
      // timer?.cancel()
      request?.abort()
    }
  }, [ immutableProps ])

  useEffect(() => () => {
    refData.current = []
  }, [])

  return {
    isLoading,
    options: refData.current,
  }
})

export default WrappedComponent => enhanceData(WrappedComponent)
