import { withObservables } from '@wiz/components'
import { dbProvider, Q } from '@wiz/store'

export default withObservables([ 'blocks' ], ({ blocks }) => {
  const ids = blocks.map(({ id }) => (id))

  let query = dbProvider.database.collections.get('experiments').query()

  if (ids.length) {
    query = query.extend(Q.where('twin_id', Q.oneOf(ids)))
  }

  return {
    items: query.observeWithColumns([ 'status', 'updated_at' ]),
  }
})
