import { forwardRef } from 'react'
import { consts } from '@wiz/utils'
import DropdownSelect from '@/components/Form/DropdownSelect'
import FilterSearch from '@/components/Form/FilterSearch'
import ListTwins from '@/components/Form/ListTwins'

const TwinTypesSelect = [
  consts.DiagramBlockType.Area,
  consts.DiagramBlockType.Equipment,
  consts.DiagramBlockType.Machine,
]

const TwinComponentOptions = forwardRef(({
  draggable,
  onClick,
  onDragStart,
  ...props
}, ref) => (
  <DropdownSelect
    ref={ref}
    {...props}
  >
    {() => (
      <FilterSearch
        withSelectedOnly={false}
      >
        {formData => (
          <ListTwins
            {...formData}
            className="position-absolute-fill"
            types={TwinTypesSelect}
            draggable={draggable}
            onChange={data => onClick?.({
              action: 'clickCreate',
              payload: data.payload || data,
            })}
            onDragStart={data => onDragStart?.({
              payload: data.payload || data,
            })}
          />
        )}
      </FilterSearch>
    )}
  </DropdownSelect>
))

export default TwinComponentOptions
