import { useCallback } from 'react'
import { withProps } from '@wiz/components'
import Widget from './components/Widget'

const enhanceProps = withProps(({ widget, onWidgetAction }) => {
  const onAction = useCallback(async ({ id }) => {
    await onWidgetAction(widget, id)
  }, [ widget, onWidgetAction ])

  return {
    onAction,
  }
})

export default enhanceProps(Widget)
