import { duration } from '@wiz/utils'

const populateTriggerName = (trigger) => {
  const interval = duration(trigger.interval, { sep: '' })
  const delay = duration(trigger.delay, { sep: '' })

  return { ...trigger, name: `${interval || '0s'} - ${delay || '0s'}` }
}

export { populateTriggerName }
