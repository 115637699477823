import { markSearch } from '@wiz/utils'
import Link from '@/components/Link'

export default function CellName ({
  experiment,
  filters,
  onRightBarOpen,
  onOpen,
}) {
  const key = (onRightBarOpen || onOpen) ? (
    <button
      type="button"
      className="btn btn-link text-reset text-start p-0 border-0"
      onClick={() => onRightBarOpen?.(experiment) || onOpen?.(experiment)}
    >
      <span
        dangerouslySetInnerHTML={{ __html: markSearch(experiment.key, filters.search) }}
      />
    </button>
  ) : (
    <Link
      name="experiment"
      params={{ id: experiment.id }}
      className="text-reset text-truncate"
    >
      <span
        dangerouslySetInnerHTML={{ __html: markSearch(experiment.key, filters.search) }}
      />
    </Link>
  )

  return (
    <div className="min-w-0 d-flex">
      {key}
    </div>
  )
}
