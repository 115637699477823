import { FormSection } from '@wiz/components'
import Fields from './Fields'

export default function SectionData ({ scope, ...props }) {
  return (
    <FormSection {...props}>
      <Fields
        scope={scope}
      />
    </FormSection>
  )
}
