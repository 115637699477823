import { useCallback, memo } from 'react'
import { withProps } from '@wiz/components'
import Component from '@/components/Forms/Experiment'
import { dbProvider, Experiment } from '@wiz/store'
import { useQueryClient } from '@tanstack/react-query'
import enhanceExperiment from './enhanceExperiment'
import { wizataApi } from '../../../api'

const enhanceProps = withProps(({ experiment, forceUpdate }) => {
  // const _experiment = Experiment.toJSON(experiment)
  const queryClient = useQueryClient()
  const onSubmit = useCallback((data) => {
    const {
      experiment: {
        id, name, templateId, key, twinGraphId, description, twinId,
      },
    } = data
    const next = {
      id,
      name,
      templateId,
      key,
      twinGraphId,
      twinId,
      description,
    }
    if (data.twins) {
      next.twinId = data.twins
    }
    // const context = dbProvider.createBatchContext()
    // await dbProvider.prepareReplaceData(context, Experiment, next)
    // await dbProvider.batch(context)

    return wizataApi.experiments.update(next)
      .then(() => {
        queryClient.invalidateQueries({ queryKey: [ 'experiment', experiment.id ] })
        queryClient.invalidateQueries({ queryKey: [ 'experimentsList' ] })
        dbProvider.sync.sync()
      })
  }, [ experiment?.id ])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceExperiment(
    enhanceProps(Component),
  ),
)
