import { DateTime } from 'luxon'

export default class SensorStatistics {
  static get modelName () {
    return 'SensorStatistics'
  }

  constructor (data = {}) {
    this.firstOccurrence = data.firstOccurrence ? DateTime.fromISO(data.firstOccurrence, { zone: 'utc' }).toMillis() : null
    this.lastOccurrence = data.lastOccurrence ? DateTime.fromISO(data.lastOccurrence, { zone: 'utc' }).toMillis() : null
    this.timeBracketInDays = data?.timeBracketInDays ?? null
    this.granularityInSeconds = data?.granularityInSeconds ?? null
    this.nbSamples = data?.nbSamples ?? null
    this.minValue = data?.minValue ?? null
    this.maxValue = data?.maxValue ?? null
    this.lastValue = data?.lastValue ?? null
  }
}
