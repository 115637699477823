import { DateTime } from 'luxon'
import classnames from 'classnames'
import {
  Icon,
  Badge,
  FormInputNumber,
  FormSelectDateTime,
  OverflowCenter,
  useUpdateByInterval,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import SelectSensor from '@/components/Form/SelectSensor'
import SensorValue from '@/containers/Sensor/SensorValue'
import SensorList from '@/hoc/SensorList'
import FormatDateTime from '@/containers/FormatDateTime'

function Realtime () {
  useUpdateByInterval(5000)
  return DateTime.local().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
}

const SensorListBinded = SensorList(({ options, size }) => (
  <div
    className={classnames('d-flex flex-wrap fs-5', {
      'justify-content-center': size === 'sm',
    })}
  >
    {options.map(item => (
      <Badge
        key={item.id}
        color={item.color}
        className="bg-secondary mb-1 me-1"
      >
        {item.icon ? <Icon name={item.icon} className="ms-1" /> : null}
        <OverflowCenter value={item.displayName} />
      </Badge>
    ))}
  </div>
))

const SensorValueContent = ({ value, timestamp, unitSymbol }) => (timestamp ? (
  <span
    title={DateTime.fromMillis(timestamp).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
  >
    {value}
    {' '}
    {unitSymbol}
  </span>
) : null)

const SensorValuesBinded = SensorList(({ options, disabled }) => options.map(item => (
  <div
    key={item.id}
    className="d-flex align-items-end mb-1 me-1"
  >
    {options.length > 1 ? (
      <>
        {item.displayName}
        :&nbsp;
      </>
    ) : null}
    <b>
      <SensorValue
        sensor={item}
        disabled={disabled}
        Content={SensorValueContent}
        loader="..."
        showUnit
      />
    </b>
  </div>
)))

export default function InputItem ({
  row,
  idx,
  len,
  size,
  isSingle,
  isLast,
  disabled,
  readOnly,
  showLastValues = true,
  formModify,
  precision = 0,
  businessType,
  onlyNow,
  onUpdateRecord,
  onUpdateValue,
  onRemoveValue,
  onCreateValue,
  onRemoveRecord,
}) {
  const intl = useIntl()
  const isSmall = size === 'sm'

  return (
    <div
      className={classnames('row g-2 bg-light rounded px-3 py-2', {
        'mb-3': !isLast,
        'mb-0': isLast,
      })}
    >
      {isSingle ? null : (
        <span
          className={classnames({
            'col-md-auto': !isSmall,
            'col-12': isSmall,
          })}
          style={{ width: Math.ceil(len / 10) * 10 + 20 }}
        >
          {`#${idx + 1}.`}
        </span>
      )}
      <div
        className={classnames({
          'col-md-4': !isSmall,
          'col-12': isSmall,
        })}
      >
        {readOnly || !formModify ? (
          <SensorListBinded
            value={row.sensorIds}
            keyName="id"
            size={size}
            selectedOnly
          />
        ) : (
          <SelectSensor
            name={`${row.id}.sensorIds`}
            placeholder={intl.t('form.fields.sensorPlaceholder')}
            multiselect
            filters={{
              businessType,
              canInputValue: true,
            }}
            value={row.sensorIds}
            onChange={sensorIds => onUpdateRecord(row.id, { sensorIds })}
          />
        )}
        {showLastValues ? (
          <div
            className={classnames('small d-flex flex-wrap', {
              'justify-content-center': isSmall,
            })}
          >
            <SensorValuesBinded
              value={row.sensorIds}
              keyName="id"
              disabled={disabled}
              selectedOnly
            />
          </div>
        ) : null}
      </div>
      <div
        className={classnames({
          'col-md': !isSmall,
          'col-12': isSmall,
        })}
      >
        {row.values.filter(item => (
          !readOnly ||
          Number.isFinite(item.value)
        )).map(({ id, value, timestamp }) => (
          <div
            key={id}
            className="row g-2 fs-5 mb-2"
          >
            <div
              className={classnames('col-md text-nowrap', {
                'fw-bold': readOnly,
              })}
            >
              {readOnly ? (
                <>
                  {JSON.stringify(value)}
                </>
              ) : (
                <FormInputNumber
                  scale={precision}
                  signed
                  clearable
                  value={value}
                  onChange={data => onUpdateValue(row.id, id, { value: data })}
                />
              )}
            </div>
            <div
              className={classnames('col-md text-nowrap d-flex align-items-center justify-content-center', {
                'fw-light': readOnly || onlyNow,
              })}
            >
              {do {
                if (onlyNow) {
                  <Realtime />
                } else if (readOnly) {
                  <FormatDateTime date={timestamp} />
                } else {
                  <FormSelectDateTime
                    future={false}
                    value={timestamp}
                    DateTimeComponent={FormatDateTime}
                    onChange={data => onUpdateValue(row.id, id, { timestamp: data })}
                  />
                }
              }}
            </div>
            {readOnly || !formModify ? null : (
              <div
                className={classnames('d-flex', {
                  'col-md-auto': !isSmall,
                  'col-12': isSmall,
                })}
              >
                <button
                  type="button"
                  className="btn btn-text btn-fill-secondary me-1 flex-fill"
                  onClick={() => onRemoveValue(row.id, id)}
                >
                  <Icon name="fa--minus" />
                </button>

                <button
                  type="button"
                  className="btn btn-text btn-fill-secondary ms-1 flex-fill"
                  onClick={() => onCreateValue(row.id, id)}
                >
                  <Icon name="fa--plus" />
                </button>
              </div>
            )}
          </div>
        ))}

        {row.values.length || readOnly || !formModify ? null : (
          <div
            className={classnames('d-flex', {
              'col-md-auto': !isSmall,
              'col-12': isSmall,
            })}
          >
            <button
              type="button"
              className="btn btn-text btn-fill-secondary me-1 flex-fill"
              onClick={() => onRemoveRecord(row.id)}
            >
              <Icon name="fa--minus" />
            </button>

            <button
              type="button"
              className="btn btn-text btn-fill-secondary ms-1 flex-fill"
              onClick={() => onCreateValue(row.id, -1)}
            >
              <Icon name="fa--plus" />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
