import { of as of$ } from 'rxjs'
// import { switchMap, map } from 'rxjs/operators'
import { dbProvider, Q, Integration } from '@wiz/store'
import { withObservables } from '@wiz/components'

export default withObservables([ 'integration', 'id' ], ({ integration, id }) => ({
  integrationByType: Integration.availableIntegrations.find(
    ({ type }) => type === integration?.type || id,
  ),

  integrationSettings: integration ? dbProvider.database.collections.get('integration_settings')
    .query(Q.where('integration_id', integration.id))
    .observeWithColumns([ 'updatedAt' ]) :
    of$(undefined),
}))
