import { useMemo, useState } from 'react'
import { Formula as Formula$ } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import { Tabs } from '@wiz/components'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import Section from './Section'
import SectionUsage from './SectionUsage'

export default function Formula ({
  id,
  formula,
  usage,
  dialog,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()
  const [ tab, setTab ] = useState('settings')
  const defaultValues = useMemo(() => ({
    formula: Formula$.toJSON(formula),
  }), [ formula?.updatedAt ?? formula ])

  const usageCount = useMemo(() => (
    usage.reduce((acc, item) => (
      acc + (item.payload.count || 0)
    ), 0)
  ), [ usage ])

  if (id && !formula) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="formulaForm"
      dialog={dialog}
    >
      <Tabs
        className="mb-2"
        value={tab}
        options={[
          { value: 'settings', label: intl.t('formulas.sections.settings') },
          { value: 'usage', label: `${intl.t('formulas.sections.usage')} (${usageCount})` },
        ]}
        onChange={setTab}
      />

      {tab === 'settings' ? (
        <Section scope="formula" />
      ) : null}

      {tab === 'usage' ? (
        <SectionUsage usage={usage} />
      ) : null}
    </FormDialog>
  )
}
