import { Icon } from '@wiz/components'
import SensorValue from '@/containers/Sensor/SensorValue'
import DateRelative from '@/components/DateRelative'
import classes from './Sensor.css'

export default function Sensor ({
  hidden,
  precision,
  sensor,
  category,
  conditions,
  twinSettings,
  value,
  onChange,
}) {
  return (
    <div className={classes.root}>
      <div className={classes.body}>
        {/* {sensor.icon ? (
          <Icon
            name={sensor.icon}
            color={sensor.color || twinSettings?.twinColorSensorsHardware}
            width="20%"
            height="100%"
            className="me-2"
          />
        ) : null} */}

        <SensorValue
          className={classes.value}
          sensor={sensor}
          conditions={conditions}
          precision={precision}
          disabled={hidden}
          initialData={value}
          showUnit
          fit
          onChange={onChange}
        />
      </div>

      {category || value?.timestamp ? (
        <div className={classes.info}>
          <i className="text-truncate flex-fill">{category?.name}</i>
          {value?.timestamp ? <DateRelative value={value.timestamp} /> : null}
        </div>
      ) : null}
    </div>
  )
}
