/* eslint-disable no-unused-expressions */
import {
  forwardRef,
  useRef,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react'
import {
  Icon,
  Dialog,
  FormInputSearch,
  useDebounce,
  useMobile,
  DropdownMenu,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { TwinGraph } from '@wiz/store'
import { useAuth } from '@/auth'
import TwinGraphList from '@/hoc/TwinGraphList'
import TwinGraphTree from '@/hoc/TwinGraphTree'
import SelectMode from '@/components/Form/SelectMode'
import List from '@/components/Form/Tree/List'
import Tree from '@/components/Form/Tree/Tree'
import ItemTreeContent from '@/containers/TwinGraph/Option/ItemTreeContent'
import ItemContent from './Option/ItemContent'

const BindedTwinGraphList = TwinGraphList(List)
const BindedTwinGraphTree = TwinGraphTree(Tree)

const LeftBar = forwardRef(({
  settings,
  setEditTwinGraph,
  onClose,
  onToggle,
  onActionMenu,
}, ref) => {
  const refMenu = useRef()
  const refSearch = useRef()
  const wait = useDebounce(300) // open dialog delay
  const intl = useIntl()
  const auth = useAuth()
  const isMobile = useMobile()

  const [ mode, setMode ] = useState('list')
  const [ menuTarget, setMenuTarget ] = useState(null)
  const [ menuData, setMenuData ] = useState(null)
  const [ search, setSearch ] = useState('')
  const [ isOpenByDefault, setIsOpenByDefault ] = useState(true)

  const menuOptions = useMemo(() => {
    if (!menuData) {
      return []
    }

    const isFavorite = settings.spotlightFavorites?.includes(menuData.target.id)
    const isHomepage = settings.homepageDefaultId === menuData.target.id
    const isTwinGraph = TwinGraph.is(menuData.target) || menuData.target.type === 'twinGraph'
    const isNestedTwinGraph = menuData.target.type === 'nestedTwinGraph'
    const isWidget = menuData.target.type === 'widget'
    const accessUpdate = do {
      if (isWidget) {
        auth.checkAccessUpdate('SectionDashboards')
      } else if (isTwinGraph || isNestedTwinGraph) {
        auth.checkAccessUpdate('SectionDigitalTwinChart')
      } else {
        auth.checkAccessUpdate('SectionDigitalTwinItems')
      }
    }
    const accessCopy = do {
      if (isWidget) {
        auth.checkAccessCopy('SectionDashboards')
      } else if (isTwinGraph || isNestedTwinGraph) {
        auth.checkAccessCopy('SectionDigitalTwinChart')
      } else {
        auth.checkAccessCopy('SectionDigitalTwinItems')
      }
    }

    return [
      ...(accessUpdate ? [
        {
          id: 'edit',
          label: intl.t('form.actions.edit'),
          icon: 'fa--edit',
          enabled: true,
        },
      ] : []),
      ...(accessCopy ? [
        {
          id: 'duplicate',
          label: intl.t('form.actions.duplicate'),
          icon: 'fa--clone',
          enabled: isTwinGraph,
        },
      ] : []),
      {
        id: 'favorite',
        icon: isFavorite ? 'fa--star' : 'far--star',
        label: isFavorite ? intl.t('form.actions.removeFavorite') : intl.t('form.actions.addFavorite'),
        enabled: isTwinGraph,
      },
      {
        id: 'select',
        label: intl.t('form.actions.setDefault'),
        icon: 'fa--paperclip',
        disabled: isHomepage,
        enabled: isTwinGraph,
      },
      ...(auth.checkAccessUpdate('SectionDigitalTwinChart') ? [
        {
          id: 'groupTwinGraph',
          label: 'Group',
          icon: 'fa--object-group',
          enabled: isNestedTwinGraph,
        },
        {
          id: 'ungroupTwinGraph',
          label: 'Ungroup',
          icon: 'fa--object-ungroup',
          enabled: !isTwinGraph && !isNestedTwinGraph && !isWidget,
        },
      ] : []),
      ...(auth.checkAccessRemove('SectionDigitalTwinChart') ? [
        {
          id: 'remove',
          label: intl.t('form.actions.remove'),
          icon: 'fa--trash-alt',
          disabled: isHomepage,
          enabled: false,
        },
      ] : []),
    ].filter(item => item.enabled)
  }, [
    intl,
    auth,
    menuData,
    settings,
  ])

  const handleAction = useCallback((event, name, data) => {
    const item = data?.payload || data
    if (name === 'more') {
      const target = event.currentTarget
      refMenu.current.close()
      // wait to update DOM
      window.setTimeout(() => {
        setMenuTarget(target)
        setMenuData({
          isLeaf: data.isLeaf,
          target: item,
        })
      }, 0)
    } else if (name === 'checkHomepage') {
      return (
        settings.homepageDefaultId &&
        (
          settings.homepageDefaultId === item.id ||
          settings.homepageDefaultId === item.twinGraphId
        )
      )
    } else if (name === 'chartView' && isMobile) {
      ref.current?.close()
    }
  }, [ settings, isMobile ])

  const handleActionMenu = useCallback((params) => {
    onActionMenu?.(params, menuData.target, menuData)
  }, [ menuData, onActionMenu ])

  const handleResetFilter = useCallback(() => {
    refSearch.current.reset()
  }, [])

  const handleCollapseExpandAll = useCallback(() => {
    setIsOpenByDefault(!isOpenByDefault)
  }, [ isOpenByDefault ])

  useEffect(() => {
    // wait to update DOM
    const timeout = menuTarget ? window.setTimeout(() => refMenu.current.open(), 0) : 0
    return () => window.clearTimeout(timeout)
  }, [ menuTarget ])

  return (
    <>
      <Dialog
        ref={ref}
        classNameBody="p-0 overflow-hidden"
        container={null}
        strategy="absolute"
        orient="left"
        backdrop={false}
        size="sm"
        resize
        dataTestid="twinGraphTreeLeftBarDialog"
        onClose={onClose}
        ComponentTitle="div"
        title={() => (
          <>
            <FormInputSearch
              ref={refSearch}
              className="form-control-sm"
              placeholder={intl.t('form.actions.searchPlaceholder')}
              onChange={setSearch}
            />
            <div className="d-flex align-items-center flex-wrap fs-6 mt-1">
              <SelectMode
                icon={false}
                checked={!mode}
                onClick={() => {
                  setIsOpenByDefault(true)
                  setMode(mode === 'list' ? null : 'list')
                }}
              >
                <Icon name={mode === 'list' ? 'fa--bars' : 'fa--folder-tree'} />
              </SelectMode>

              <button
                title={intl.t('form.actions.collapseExpandAll')}
                type="button"
                className="btn badge border me-1 mb-1 bg-transparent d-inline-flex text-secondary"
                disabled={wait || mode}
                onClick={handleCollapseExpandAll}
              >
                <Icon name={isOpenByDefault ? 'fa--compress-alt' : 'fa--expand-alt'} />
              </button>

              {auth.checkAccessCreate('TwinGraph') ? (
                <button
                  title={intl.t('form.actions.add')}
                  type="button"
                  className="btn badge border me-1 mb-1 bg-transparent d-inline-flex text-secondary"
                  disabled={wait}
                  onClick={() => setEditTwinGraph('')}
                >
                  <Icon name="fa--plus" />
                </button>
              ) : null}
            </div>
          </>
        )}
      >
        {do {
          if (wait) {
            (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <Icon name="fa--spinner" size="lg" spin />
              </div>
            )
          } else if (mode === 'list') {
            (
              <BindedTwinGraphList
                className="flex-fill"
                search={search}
                Content={ItemContent}
                onAction={handleAction}
                onResetFilter={handleResetFilter}
              />
            )
          } else {
            (
              <BindedTwinGraphTree
                className="flex-fill"
                search={search}
                Content={ItemTreeContent}
                onAction={handleAction}
                onToggle={onToggle}
                isOpenByDefault={isOpenByDefault}
                defaultOpennessState={settings.twinTreeOpennessState}
                onResetFilter={handleResetFilter}
              />
            )
          }
        }}
      </Dialog>

      <DropdownMenu
        ref={refMenu}
        target={menuTarget}
        mode={null}
        arrow
        autoclose
        options={menuOptions}
        onHide={() => {
          setMenuTarget(null)
          setMenuData(null)
        }}
        onClick={handleActionMenu}
      />
    </>
  )
})

LeftBar.displayName = 'LeftBar'
export default LeftBar
