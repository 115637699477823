import { useState, useCallback } from 'react'
import { Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'

export default function ReadCurrentLocation ({ onChange }) {
  const intl = useIntl()
  const [ loading, setLoading ] = useState(false)
  const handleReadLocation = useCallback(() => {
    setLoading(true)
    navigator.geolocation.getCurrentPosition(position => {
      setLoading(false)
      if (position.coords.latitude && position.coords.longitude) {
        onChange?.(position.coords.latitude, position.coords.longitude)
      }
    }, () => {
      setLoading(false)
    }, {
      enableHighAccuracy: true,
      maximumAge: 30000,
      timeout: 27000,
    })
  }, [])

  return (
    <button
      type="button"
      className="btn btn-secondary text-nowrap"
      disabled={loading}
      onClick={handleReadLocation}
    >
      <Icon
        className="me-2"
        name={loading ? 'fa--spinner' : 'fa--globe'}
        spin={loading}
      />
      {intl.t('form.actions.currentLocation')}
    </button>
  )
}
