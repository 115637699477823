import classnames from 'classnames'
import { markSearch, consts } from '@wiz/utils'
import { Badge } from '@wiz/components'
import Link from '@/components/Link'

/* eslint-disable react/no-danger */
export default function CellName ({
  streamJob,
  filters,
  onOpen,
}) {
  return (
    <div className="min-w-0 text-truncate">
      {onOpen ? (
        <a
          className="text-truncate pointer"
          onClick={() => onOpen?.(streamJob.id)}
          aria-hidden
        >
          <span
            dangerouslySetInnerHTML={{ __html: markSearch(streamJob.name, filters.search) }}
          />
        </a>
      ) : (
        <Link
          name="stream-jobs-view"
          params={{ id: streamJob.id }}
        >
          <span
            dangerouslySetInnerHTML={{ __html: markSearch(streamJob.name, filters.search) }}
          />
        </Link>
      )}
      {streamJob.description || (streamJob.deviceId && streamJob.publishStatus) ? (
        <>
          <br />
          <span className="font-italic text-muted small">
            {streamJob.deviceId && streamJob.publishStatus ? (
              <Badge
                className={classnames('me-1', {
                  'bg-secondary': (
                    streamJob.publishStatus !== consts.StreamJobPublishStatus.Published &&
                    streamJob.publishStatus !== consts.StreamJobPublishStatus.FailedToPublish
                  ),
                  'bg-primary': streamJob.publishStatus === consts.StreamJobPublishStatus.Published,
                  'bg-danger': streamJob.publishStatus === consts.StreamJobPublishStatus.FailedToPublish,
                })}
              >
                {streamJob.publishStatus}
              </Badge>
            ) : null}
            {streamJob.description}
          </span>
        </>
      ) : null}
    </div>
  )
}
