/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'
import TwinList from '@/hoc/TwinList'
import SensorList from '@/hoc/SensorList'
import StreamJobList from '@/hoc/StreamJobList'
import DeviceCommandTemplateList from '@/hoc/DeviceCommandTemplateList'
import EdgeDeviceList from '@/hoc/EdgeDeviceList'
import CellTwin from './CellTwin'
import CellStreamJob from './CellStreamJob'
import CellSensors from './CellSensors'
import CellTemplate from './CellTemplate'
import CellDevice from './CellDevice'

const CellSensorsData = SensorList(CellSensors)
const CellStreamJobData = StreamJobList(CellStreamJob)
const CellTwinData = TwinList(CellTwin)
const CellTemplateData = DeviceCommandTemplateList(CellTemplate)
const CellDeviceData = EdgeDeviceList(CellDevice)

export default function ViewModel ({
  title,
  deviceCommand,
  onClose,
  onFilter,
}) {
  const refDialog = useRef()
  const intl = useIntl()

  const handleFilter = (filter) => {
    refDialog.current.close()
    onFilter?.(filter)
  }

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('edge.commands.form.fields.device'),
          value: () => (deviceCommand.deviceId ? (
            <CellDeviceData
              value={deviceCommand.deviceId}
              selectedOnly
              onFilter={handleFilter}
            />
          ) : '-'),
        },
        {
          title: intl.t('form.fields.status'),
          value: deviceCommand.status,
        },
        {
          title: intl.t('edge.commands.form.fields.template'),
          value: () => (deviceCommand.templateId ? (
            <CellTemplateData
              value={deviceCommand.templateId}
              selectedOnly
            />
          ) : '-'),
        },
        {
          title: intl.t('edge.commands.form.fields.payload'),
          value: String(deviceCommand.payload),
        },
        {
          title: intl.t('form.fields.streamJob'),
          value: () => (deviceCommand.streamJobId ? (
            <CellStreamJobData
              value={deviceCommand.streamJobId}
              selectedOnly
              onFilter={handleFilter}
            />
          ) : '-'),
        },
        {
          title: intl.t('form.fields.twin'),
          value: () => (deviceCommand.twinId ? (
            <CellTwinData
              value={deviceCommand.twinId}
              selectedOnly
            />
          ) : '-'),
        },
        {
          title: intl.t('form.fields.dataPoint'),
          value: () => (deviceCommand.sensorId ? (
            <CellSensorsData
              value={deviceCommand.sensorId}
              keyName="id"
              selectedOnly
              onFilter={handleFilter}
            />
          ) : '-'),
        },
        {
          title: intl.t('form.fields.response'),
          value: () => deviceCommand.response || intl.t('form.noresponse'),
        },
      ],
    },

    {
      title: intl.t('form.fields.updated'),
      value: () => <UpdatedInfo date={deviceCommand.updatedAt} user={deviceCommand.updatedUser} />,
    },
    {
      title: intl.t('form.fields.created'),
      value: () => <UpdatedInfo date={deviceCommand.createdAt} user={deviceCommand.createdUser} />,
    },
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      onClose={onClose}
    />
  )
}
