import classnames from 'classnames'
import { useIntl } from '@wiz/intl'

export default function NoDataDisplay ({
  className,
  onResetFilter,
}) {
  const intl = useIntl()

  return (
    <div className={classnames('d-flex flex-column align-items-center justify-content-center', className)}>
      {intl.t('errors.noDataDisplay')}

      {onResetFilter ? (
        <button
          className="btn btn-sm btn-outline-secondary mt-2"
          type="button"
          onClick={onResetFilter}
        >
          {intl.t('form.actions.resetFilters')}
        </button>
      ) : null}
    </div>
  )
}
