import { memo } from 'react'
import { withProps, useDidUpdate } from '@wiz/components'
import enhanceScript from './enhanceScript'
import enhanceInterfaces from './enhanceInterfaces'

const enhanceProps = withProps(({ script, interfaces, onChange }) => {
  useDidUpdate(() => onChange?.({ script, interfaces }), [ script, interfaces, onChange ])
})

export default memo(
  enhanceScript(
    enhanceInterfaces(
      enhanceProps(() => (null)),
    ),
  ),
)
