import { useState, Fragment } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import classnames from 'classnames'
import {
  FormField,
  FormFieldInline,
  FormSelect,
  FormIndeterminateCheckbox,
  FormControl,
} from '@wiz/components'
import { has, get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { MarkPoints } from '@/config'

const FormFieldRatio = [ 6, 6 ]
const LegendOrients = [
  { id: 'top', name: 'top' },
  { id: 'left', name: 'left' },
  { id: 'bottom', name: 'bottom' },
  { id: 'right', name: 'right' },
]
const DataZoomPositions = [
  { id: 'top', name: 't/enum.dataZoomPosition.top' },
  { id: 'bottom', name: 't/enum.dataZoomPosition.bottom' },
]
const DataZoomTypes = [
  { id: 'static', name: 't/enum.zoomType.static' },
  { id: 'single', name: 't/enum.zoomType.single' },
]

export default function Fields ({
  scope,
  fieldRatio = FormFieldRatio,
}) {
  const intl = useIntl()
  const [ uid ] = useState(Date.now())
  const { register, formState: { errors }, watch } = useFormContext()
  const [
    viewShowLegend,
    viewShowDataZoom,
  ] = watch([
    `${scope}.viewShowLegend`,
    `${scope}.viewShowDataZoom`,
  ])

  return (
    <>
      <FormFieldInline
        label={intl.t('form.fields.tooltip')}
        description={intl.t('form.fields.tooltipDescr')}
        errors={get(errors, `${scope}.viewShowTooltip`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.viewShowTooltip`}
          className="ms-2"
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('form.fields.legend')}
        description={intl.t('form.fields.legendDescr')}
        errors={get(errors, `${scope}.viewShowLegend`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.viewShowLegend`}
          className="ms-2"
        />
      </FormFieldInline>

      <FormField
        ratio={fieldRatio}
        label={intl.t('form.fields.legendOrient')}
        description={intl.t('form.fields.legendOrientDescr')}
        errors={get(errors, `${scope}.viewLegendOrient`)}
      >
        <Controller
          name={`${scope}.viewLegendOrient`}
          render={({ field, fieldState }) => (
            <FormSelect
              {...field}
              invalid={fieldState.invalid}
              options={LegendOrients}
              disabled={!viewShowLegend}
            />
          )}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.showTitle')}
        description={intl.t('form.fields.showTitleDescr')}
        errors={get(errors, `${scope}.viewShowTitle`)}
      >
        <Controller
          name={`${scope}.viewShowTitle`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('form.fields.normalize')}
        description={intl.t('form.fields.normalizeDescr')}
        errors={get(errors, `${scope}.viewNormalize`)}
      >
        <Controller
          name={`${scope}.viewNormalize`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('form.fields.multigrid')}
        description={intl.t('form.fields.multigridDescr')}
        errors={get(errors, `${scope}.viewMultigrid`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.viewMultigrid`}
          className="ms-2"
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('form.fields.interpolate')}
        description={intl.t('form.fields.interpolateDescr')}
        errors={get(errors, `${scope}.viewInterpolate`)}
      >
        <Controller
          name={`${scope}.viewInterpolate`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>

      <FormField
        ratio={fieldRatio}
        label={intl.t('form.fields.columns')}
        description={intl.t('form.fields.columnsDescr')}
        errors={get(errors, `${scope}.viewColumns`)}
      >
        <FormControl
          type="number"
          name={`${scope}.viewColumns`}
          rules={{
            validate: {
              min: value => (
                parseInt(value || 0, 10) >= 1 ||
                intl.t('form.errors.fieldMin', { min: 1 })
              ),
              max: value => (
                parseInt(value || 0, 10) <= 3 ||
                intl.t('form.errors.fieldMax', { max: 3 })
              ),
            },
          }}
          min={1}
          max={3}
          step={1}
        />
      </FormField>

      <FormField
        ratio={fieldRatio}
        label={intl.t('form.fields.precision')}
        description={intl.t('form.fields.precisionDescr')}
        errors={get(errors, `${scope}.viewValuePrecision`)}
      >
        <FormControl
          type="number"
          name={`${scope}.viewValuePrecision`}
          rules={{
            validate: {
              min: value => (
                value === null ||
                value === '' ||
                parseInt(value || 0, 10) >= 0 ||
                intl.t('form.errors.fieldMin', { min: 0 })
              ),
              max: value => (
                value === null ||
                value === '' ||
                parseInt(value || 0, 10) <= 10 ||
                intl.t('form.errors.fieldMax', { max: 10 })
              ),
            },
          }}
          min={0}
          max={10}
          step={1}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.showDataSymbol')}
        description={intl.t('form.fields.showDataSymbolDescr')}
        errors={get(errors, `${scope}.viewShowDataSymbol`)}
      >
        <Controller
          name={`${scope}.viewShowDataSymbol`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('form.fields.showMillisecond')}
        description={intl.t('form.fields.showMillisecondDescr')}
        errors={get(errors, `${scope}.viewShowMillisecond`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.viewShowMillisecond`}
          className="ms-2"
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('form.fields.singleXAxis')}
        description={intl.t('form.fields.singleXAxisDescr')}
        errors={get(errors, `${scope}.viewSingleXAxis`)}
      >
        <Controller
          name={`${scope}.viewSingleXAxis`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('form.fields.singleYAxis')}
        description={intl.t('form.fields.singleYAxisDescr')}
        errors={get(errors, `${scope}.viewSingleYAxis`)}
      >
        <Controller
          name={`${scope}.viewSingleYAxis`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('form.fields.dataZoom')}
        description={intl.t('form.fields.dataZoomDescr')}
        errors={get(errors, `${scope}.viewShowDataZoom`)}
      >
        <Controller
          name={`${scope}.viewShowDataZoom`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>

      <FormField
        ratio={fieldRatio}
        label={intl.t('form.fields.dataZoomPosition')}
        description={intl.t('form.fields.dataZoomPositionDescr')}
        errors={get(errors, `${scope}.viewDataZoomPosition`)}
      >
        <Controller
          name={`${scope}.viewDataZoomPosition`}
          render={({ field, fieldState }) => (
            <FormSelect
              {...field}
              invalid={fieldState.invalid}
              options={DataZoomPositions.map(item => ({ ...item, name: intl.t(item.name) }))}
              disabled={!viewShowDataZoom}
            />
          )}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.singleDataZoom')}
        errors={get(errors, `${scope}.viewSingleDataZoom`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.viewSingleDataZoom`}
          className="ms-2"
          disabled={!viewShowDataZoom}
        />
      </FormFieldInline>

      <FormField
        ratio={fieldRatio}
        label={intl.t('form.fields.zoomType')}
        errors={get(errors, `${scope}.viewZoom`)}
      >
        <Controller
          name={`${scope}.viewZoom`}
          render={({ field, fieldState }) => (
            <FormSelect
              {...field}
              invalid={fieldState.invalid}
              options={DataZoomTypes.map(item => ({ ...item, name: intl.t(item.name) }))}
              disabled={!viewShowDataZoom}
            />
          )}
        />
      </FormField>

      <FormField
        ratio={fieldRatio}
        label={intl.t('explorer.form.fields.markPoints')}
        errors={get(errors, `${scope}.viewMarkPoints`)}
        classNameControl="d-flex justify-content-sm-end align-items-center"
        complex
      >
        <div
          className={classnames('btn-group flex-fill', {
            'is-invalid': has(errors, `${scope}.viewMarkPoints`),
          })}
        >
          {MarkPoints.map(id => (
            <Fragment key={id}>
              <input
                {...register(`${scope}.viewMarkPoints`)}
                id={`${uid}.${id}`}
                type="checkbox"
                value={id}
                className="btn-check"
              />
              <label className="btn btn-fill-primary justify-content-center" htmlFor={`${uid}.${id}`}>
                {id}
              </label>
            </Fragment>
          ))}
        </div>
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.removeNull')}
        description={intl.t('form.fields.removeNullDescr')}
        errors={get(errors, `${scope}.viewRemoveNull`)}
      >
        <Controller
          name={`${scope}.viewRemoveNull`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>
    </>
  )
}
