import startCase from 'lodash/startCase'
import { withProps } from '@wiz/components'
import { sprite } from '@wiz/icons'
import Component from '@/components/Form/SelectIcon'
import * as faProIcons from '@awesome.me/kit-9065c4792f/icons/classic/solid'

function iconsIterator (item) {
  const name = item.g[item.g.length - 1]
  return {
    id: item.n,
    key: item.n,
    name,
    title: startCase(name),
    groups: item.g.slice(1, item.g.length - 1),
    width: item.w,
    height: item.h,
    desiredSize: `${item.w} ${item.h}`,
  }
}

// function iconsIterator2 (item) {
//   const name = item.g[item.g.length - 1]
//   return {
//     key: item.n,
//     name,
//     title: startCase(name),
//     groups: item.g.slice(1, item.g.length - 1),
//     // width: 70,
//     // height: item.h,
//     // desiredSize: '70 70',
//   }
// }

const CustomIcons = sprite.filter(item => item.g[0] === 'cus').reduce((acc, val) => {
  if (acc[val.g[1]]) {
    acc[val.g[1]].push(iconsIterator(val))
  } else {
    acc[val.g[1]] = [ iconsIterator(val) ]
  }
  return acc
}, {})

// const FaIcons = Object.freeze(sprite.filter(item => item.g[0] === 'fa').map(iconsIterator))
const WizIcons = Object.freeze(sprite.filter(item => item.g[0] === 'wiz').map(iconsIterator))
const FlowIcons = Object.freeze(WizIcons.filter(item => (
  item.key === 'wiz--line--conveyor' ||
  item.key === 'wiz--line--pipe'
)))
const FaProIcons = Object.entries(faProIcons).map(([ key, value ]) => ({
  id: key,
  key,
  name: key,
  title: value.iconName,
  icon: value.icon,
  width: 50,
  isFa: true,
}))

const Categories = [
  ...Object.keys(CustomIcons).map(icon => ({ id: icon, icon: `cus--${icon}--${icon}`, name: startCase(icon) })),
  // { id: 'wiz', name: 'Wizata', icon: 'wiz--logo--wizata-w-logo' },
  // { id: 'flow', name: 'Line', icon: 'wiz--line--conveyor' },
  { id: 'fa2', name: 'Other', icon: 'faCircleEllipsis' },
]

const Groups = Object.keys(
  WizIcons
    .reduce((out, item) => out.concat(item.groups), [])
    .reduce((out, item) => ({ ...out, [item]: true }), {}),
)

function getIconInfo (key, category) {
  const data = {
    hasIcon: false,
    img: null,
    emoji: null,
    info: null,
    sprite: null,
    shape: null,
  }

  if (!key) {
    return data
  }

  if (category === 'fa2') {
    return { ...FaProIcons.find(icon => icon.key === key), category }
  }

  data.info = (key.indexOf('cus--') === 0 ? Object.values(CustomIcons).flat() : FaProIcons) //
    .find(item => item.key === key)

  if (!data.info) {
    data.info = (key.indexOf('wiz-') === 0 ? WizIcons : FaProIcons)
      .find(item => item.key === key)
  }

  if (data.info) {
    data.sprite = data.info.key
    data.hasIcon = Boolean(data.sprite)
    data.shape = startCase(data.sprite).replace(/\s/g, '')
  }

  return data
}

const enhanceData = withProps(() => ({
  categories: Categories,
  groups: Groups,
  getIconInfo,
  fetchIcons: async ({ search, category }) => {
    let items = []
    const iconsByCategory = CustomIcons[category]
    if (iconsByCategory) {
      items = iconsByCategory
    } else if (category === 'fa2') {
      items = FaProIcons
    } else if (category === 'flow') {
      items = FlowIcons
    }

    if (search) {
      items = items.filter(item => item.title.toLowerCase().indexOf(search) !== -1)
    }
    return {
      category,
      items,
    }
  },
}))

const SelectIcon = enhanceData(Component)

SelectIcon.displayName = 'SelectIcon'
SelectIcon.getIconInfo = getIconInfo
export default SelectIcon
