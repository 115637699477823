import PropTypes from 'prop-types'
import TimeSettings from '@/components/Form/TimeSettings'

export default function FieldsDateRange ({ scope }) {
  return (
    <TimeSettings
      className="mb-3"
      dateFromFieldName={`${scope}.rangeDateFrom`}
      dateToFieldName={`${scope}.rangeDateTo`}
      durationFieldName={`${scope}.rangeDuration`}
      offsetDurationFieldName={`${scope}.rangeOffsetDuration`}
      stepRequestFieldName={`${scope}.rangeStepRequest`}
      stepRequestDisabled
    />
  )
}

FieldsDateRange.propTypes = {
  scope: PropTypes.string.isRequired,
}
