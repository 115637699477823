import { useCallback, useMemo, memo } from 'react'
import { Q, dbProvider } from '@wiz/store'
import { uniq } from '@wiz/utils'
import { withProps } from '@wiz/components'
import Component from '@/components/Forms/UserBulkEdit'

const enhanceProps = withProps(({
  users,
}) => {
  const selectedIds = useMemo(() => uniq(
    users.map(item => item.id).filter(Boolean),
  ), [ users ])

  const onSubmit = useCallback(async (data) => {
    if (!data.selected?.length || !selectedIds?.length) {
      return
    }

    const nextData = {}
    for (const name of data.selected) {
      if (Object.hasOwnProperty.call(data.users, name)) {
        nextData[name] = data.users[name]
      }
    }

    const items = await dbProvider.database.collections.get('users')
      .query(Q.where('id', Q.oneOf(selectedIds)))
      .fetch()

    const context = dbProvider.createBatchContext()
    for (const item of items) {
      await item.prepareUpdateData(context, nextData)
      if (data.selected.includes('roles')) {
        await item.prepareReplaceAccessRoles(context, data.roles)
      }
      if (data.selected.includes('twins')) {
        await item.prepareReplaceTwins(context, data.twins)
      }
    }
    await dbProvider.batch(context)
  }, [ selectedIds ])

  return {
    onSubmit,
  }
})

export default memo(enhanceProps(Component))
