import { useContext } from 'react'
import { withProps } from '@wiz/components'
import DataExplorer from '@/context/DataExplorer'

export default withProps(({ condition }) => {
  const context = useContext(DataExplorer)
  condition = context?.data?.conditions?.find(item => (
    item.id === condition.id
  )) || condition

  return {
    condition,
  }
})
