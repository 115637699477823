import { useCallback } from 'react'
import { withProps } from '@wiz/components'
import { wizataApi } from '@/api'
import events from '@/utils/events'
import withTableActions from '@/containers/withTableActions'
import Component from '@/components/Events/List'

const enhanceProps = withProps(() => {
  const onRemove = useCallback(async (ids) => {
    try {
      await wizataApi.events.bulkRemove(ids)
    } catch (error) {
      events.emit('app:notify', {
        type: 'error',
        title: 't/events.title',
        message: 't/events.form.errors.remove',
      })
    }
  }, [])

  return {
    onRemove,
  }
})

export default withTableActions({
  initFilters (query = {}) {
    return {
      from: query.from ? Number(query.from) : undefined,
      to: query.to ? Number(query.to) : undefined,
      eventTypes: query.eventTypes,
      statuses: query.statuses,
      sourceTypes: query.sourceTypes,
      streamJobId: query.streamJobId,
      businessLabelId: query.businessLabelId,
      twinIds: query.twinIds,
      sensorIds: query.sensorIds,
      includeChildEvents: query.includeChildEvents ?? true,
    }
  },

  defaultFilters () {
    return {
      sortBy: 'createdAt',
      includeChildEvents: true,
    }
  },
})(
  enhanceProps(Component),
)
