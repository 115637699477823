import {
  useCallback,
  forwardRef,
  useRef,
  useEffect,
  useImperativeHandle,
} from 'react'
import { DropdownMenu, useMobile } from '@wiz/components'
import { consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { auth } from '@/auth'
import { MENU_ALIGMENTS } from '@/config'

const WIDGET_TYPE = {
  sensor: 'sensor',
  plotly: 'plotly',
  tableData: 'tableData',
}

const ContextMenu = forwardRef(({
  block,
  blockIds,
  coords,
  container,
  latlng,
  loc,
  mapView,
  onAction,
  onHide,
  sensorIds,
  functions,
  twin,
  twinSettings,
  blockSettings,
}, ref) => {
  const refTarget = useRef()
  const refMenu = useRef()
  const intl = useIntl()
  const isMobile = useMobile()

  const notEligibleWidgetsByType = [ WIDGET_TYPE.plotly, WIDGET_TYPE.tableData ]
  const isEligibleItem = !notEligibleWidgetsByType.includes(blockSettings?.type)

  useEffect(() => {
    if (coords) {
      refMenu.current?.open()
    }
    return () => {
      refMenu.current?.close()
    }
  }, [ coords ])

  useImperativeHandle(ref, () => ({
    close () {
      refMenu.current?.close()
    },
  }))

  const FunctionActions = functions.length && isEligibleItem ? [
    {
      label: 'Perform an analysis',
      icon: 'fa--chart-network',
      options: functions.map(item => ({
        action: 'runWizard',
        params: { customFunction: item },
        label: item.title,
      })),
      disabled: !auth.checkAccessCreate('Experiment'),
    },
  ] : []

  const DataPointActions = sensorIds.length ? [
    {
      label: intl.t('chart.contextMenu.sensors', { sensorsCount: sensorIds.length }),
      icon: 'fa--signal-stream',
      options: [
        {
          action: 'explorer',
          params: sensorIds,
          label: intl.t('explorer.title'),
          icon: 'fa--search',
          disabled: !auth.checkAccessCreate('SectionDataExplorer'),
        },
        {
          action: 'export',
          params: sensorIds,
          label: intl.t('form.actions.exportTitle'),
          icon: 'fa--file-download',
          disabled: !auth.checkAccessCreate('Job'),
        },
        {
          action: 'notebook',
          params: sensorIds,
          label: intl.t('form.actions.createNotebook'),
          icon: 'fa--file-contract',
          disabled: !auth.checkAccessCreate('Job'),
        },
        {
          action: 'dashboard',
          params: sensorIds,
          label: intl.t('form.actions.dashboard'),
          icon: 'fa--th-large',
          disabled: !auth.checkAccessCreate('SectionDashboards'),
        },
      ],
    },
    { divider: true },
  ] : []

  const BlockActions = block ? [
    {
      action: 'select',
      params: block,
      label: intl.t('form.actions.select'),
      icon: 'fa--search',
      disabled: !(
        block.type === consts.DiagramBlockType.NestedTwinGraph &&
        auth.checkAccessRead(block)
      ),
    },
    ...(twin ? [
      {
        action: 'showOnDigitalTwin',
        params: twin,
        label: intl.t('form.actions.showDigitalTwin'),
        icon: 'machines',
        disabled: !(
          auth.checkAccessRead(twin) &&
          auth.checkAccessManage('SectionDigitalTwinItems')
        ),
      },
    ] : []),
    isEligibleItem && {
      action: 'runWizard',
      params: block,
      label: 'Detect Anomalies',
      icon: 'fa--magic',
      disabled: !(
        block.type !== consts.DiagramBlockType.Flow &&
        auth.checkAccessRead(block)
      ),
    },
    ...FunctionActions,
    {
      action: 'edit',
      params: block,
      label: intl.t('form.actions.edit'),
      icon: 'fa--edit',
      disabled: !auth.checkAccessUpdate(block),
    },
    {
      action: 'copy',
      label: intl.t('form.actions.duplicate'),
      icon: 'fa--copy',
      disabled: !auth.checkAccessCopy(block),
    },
    {
      action: 'duplicateWithChildren',
      label: intl.t('form.actions.duplicateWithChildren'),
      icon: 'fa--copy',
      disabled: !(
        block.type !== consts.DiagramBlockType.Flow &&
        auth.checkAccessCopy(block)
      ),
    },
    // {
    //   action: 'findReplace',
    //   label: intl.t('form.actions.findReplace'),
    //   icon: 'fa--search',
    //   // disabled: !auth.checkAccessCopy(block),
    // },
    {
      action: 'remove',
      params: block,
      label: intl.t('form.actions.remove'),
      icon: 'fa--trash-alt',
      disabled: !auth.checkAccessRemove(block),
    },
    { divider: true },
  ] : []

  const canCreateTwin = auth.checkAccessCreate('DiagramBlock') && auth.checkAccessCreate('Twin')
  const AttachBlocks = [
    {
      action: 'attach',
      params: { type: 'area' },
      targetTypes: [ 'area' ],
      color: twinSettings.twinColorAreas,
      label: 't/enum.twinType.area',
      icon: 'fa--warehouse',
      disabled: !canCreateTwin,
    },
    {
      action: 'attach',
      params: { type: 'machine' },
      targetTypes: [ 'area', 'machine' ],
      color: twinSettings.twinColorMachines,
      label: 't/enum.twinType.machine',
      icon: 'fa--cogs',
      disabled: !canCreateTwin,
    },
    {
      action: 'attach',
      params: { type: 'equipment' },
      targetTypes: [ 'area', 'machine', 'equipment' ],
      color: twinSettings.twinColorEquipment,
      label: 't/enum.twinType.equipment',
      icon: 'fa--tools',
      disabled: !canCreateTwin,
    },
  ]
    .filter(item => (!block || item.targetTypes.includes(block.type)))
    .map(item => ({ ...item, label: intl.t(item.label) }))

  const Alignments = MENU_ALIGMENTS.filter(item => item.toolbar)
    .map(item => ({
      label: intl.t(item.label),
      icon: item.icon,
      action: 'alignment',
      params: item.options,
      disabled: mapView,
    }))

  const options = [
    ...DataPointActions,
    ...BlockActions,
    ...AttachBlocks,
    {
      label: intl.t('chart.contextMenu.alignment'),
      icon: 'fa--align-right',
      options: Alignments,
      disabled: mapView,
    },
    ...(isMobile && blockIds?.length ? [
      {
        label: intl.t('chart.contextMenu.more'),
        icon: 'fa--ellipsis-h',
        options: [
          {
            action: 'commands',
            icon: 'fa--terminal',
            label: intl.t('edge.commands.title'),
            disabled: !auth.checkAccessManage('SectionDeviceCommands'),
          },
          {
            action: 'events',
            icon: 'fa--calendar',
            label: intl.t('events.title'),
            disabled: !auth.checkAccessManage('SectionEvents'),
          },
          {
            action: 'sensors',
            icon: 'fa--sensor',
            label: intl.t('sensors.titleLinked'),
            disabled: !auth.checkAccessManage('SectionEvents'),
          },
          {
            action: 'setPoints',
            icon: 'fa--file-signature',
            label: intl.t('sensors.titleLinkedSetPoints'),
            disabled: !auth.checkAccessManage('SectionDigitalTwinSensors'),
          },
          {
            action: 'measurements',
            icon: 'fa--ruler',
            label: intl.t('sensors.titleLinkedMeasurements'),
            disabled: !auth.checkAccessManage('SectionDigitalTwinSensors'),
          },
          {
            action: 'info',
            icon: 'fa--info-circle',
            label: intl.t('twin.titleLinked'),
          },
          {
            action: 'solutions',
            icon: 'fa--list',
            label: intl.t('experiments.titleRightBar'),
          },
        ],
      },
    ] : []),
  ]

  return (
    <>
      <div
        ref={refTarget}
        style={coords ? {
          display: 'block',
          left: `${coords[0]}px`,
          top: `${coords[1]}px`,
          pointerEvents: 'none',
          position: 'absolute',
          zIndex: 10001,
          width: '1px',
          height: '1px',
        } : {
          display: 'none',
        }}
      />

      <DropdownMenu
        ref={refMenu}
        target={refTarget}
        mode={null}
        container={container}
        placement="right-start"
        options={options}
        onHide={onHide}
        onClick={params => onAction?.(params, {
          block,
          loc,
          latlng,
        })}
      />
    </>
  )
})

export default ContextMenu
