import { useMemo } from 'react'
import { Tree as DataTree } from '@wiz/components'
import TreeOption from './TreeOption'

export default function Tree ({
  options,
  onToggleSubscribe,
}) {
  const itemData = useMemo(() => ({
    onToggleSubscribe,
  }), [
    onToggleSubscribe,
  ])

  return (
    <DataTree
      className="flex-fill min-h-0"
      options={options}
      itemSize={36}
      itemData={itemData}
    >
      {TreeOption}
    </DataTree>
  )
}
