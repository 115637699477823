export default function templates (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      templatesCore: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value (params) {
              const request = client.post('/Templates/paged', { ...params })
              return request.fetch()
            },
          },
          getById: {
            enumerable: true,
            value (id) {
              return client.get(`/Templates/${id}`).fetch()
            },
          },
          deleteById: {
            enumerable: true,
            value (id) {
              return client.delete(`/Templates/${id}`).fetch()
            },
          },
          create: {
            enumerable: true,
            value (params) {
              return client.post('/Templates', { ...params }).fetch()
            },
          },
          update: {
            enumerable: true,
            value ({ id, ...params }) {
              return client.put(`/Templates/${id}`, { ...params }).fetch()
            },
          },
        }),
      },
    })
  }
}
