import { useFormContext } from 'react-hook-form'
import { FormSection } from '@wiz/components'
import Fields from '@/components/Forms/EdgeDeviceCommand/Fields'

export default function Section ({ scope, ...props }) {
  const { register } = useFormContext()

  return (
    <FormSection {...props}>
      <input
        {...register(`${scope}.id`)}
        type="hidden"
      />

      <Fields
        scope={scope}
        withTwin={false}
        withSensorsByDevice
      />
    </FormSection>
  )
}
