import { useCallback, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  FormField,
  FormControl,
} from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import QualityDataTemplateList from '@/hoc/QualityDataTemplateList'
import FormatDateTime from '@/containers/FormatDateTime'
import Select from '@/components/Form/Select'
import ObservQualityDataTemplate from '@/containers/Forms/QualityDataTemplate/Observ'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const [ qualityTemplate, setQualityTemplate ] = useState(null)

  const {
    watch,
    clearErrors,
    setValue,
    formState: { errors },
  } = useFormContext()

  const [
    id,
    qualityDataTemplateId,
  ] = watch([
    `${scope}.id`,
    `${scope}.qualityDataTemplateId`,
  ])

  const handleChange = useCallback(({ qualityDataTemplate }) => {
    setQualityTemplate(qualityDataTemplate)
    if (!qualityDataTemplate?.approval) {
      setValue(`${scope}.approval`, consts.QualityDataApproval.None, { shouldDirty: true })
      clearErrors(`${scope}.approval`)
    }
  }, [ scope, setValue, clearErrors ])

  return (
    <>
      <ObservQualityDataTemplate
        id={qualityDataTemplateId}
        onChange={handleChange}
      />

      <FormField
        label={intl.t('quality.form.fields.qualityTemplate')}
        description={intl.t('quality.form.fields.qualityTemplateDescr')}
        errors={get(errors, `${scope}.qualityDataTemplateId`)}
      >
        <Controller
          name={`${scope}.qualityDataTemplateId`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              readOnly={!!id}
              invalid={fieldState.invalid}
              ListSource={QualityDataTemplateList}
              placeholder={intl.t('quality.form.fields.qualityTemplatePlaceholder')}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('quality.form.fields.approval')}
        description={intl.t('quality.form.fields.approvalDescr')}
        errors={get(errors, `${scope}.approval`)}
      >
        <FormControl
          type="select"
          name={`${scope}.approval`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          options={qualityTemplate?.approval ? consts.QualityDataApprovals.map(item => ({
            id: item,
            name: intl.t(`enum.qualityApproval.${item}`),
          })) : [{
            id: consts.QualityDataApproval.None,
            name: intl.t('enum.qualityApproval.noneed'),
          }]}
          disabled={!qualityTemplate?.approval}
          placeholder={intl.t('quality.form.fields.approvalPlaceholder')}
        />
      </FormField>

      <FormField
        label={intl.t('quality.form.fields.registerAt')}
        description={intl.t('quality.form.fields.registerAtDescr')}
        errors={get(errors, `${scope}.registerAt`)}
      >
        <FormControl
          type="dateTime"
          name={`${scope}.registerAt`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          placeholder={intl.t('form.fields.dateTimePlaceholder')}
          readOnly={!!id}
          DateTimeComponent={FormatDateTime}
        />
      </FormField>

      <FormField
        label={intl.t('quality.form.fields.description')}
        description={intl.t('quality.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.description`}
          rules={{
            maxLength: {
              value: 2000,
              message: intl.t('form.errors.fieldMaxlen', { max: 2000 }),
            },
          }}
        />
      </FormField>
    </>
  )
}
