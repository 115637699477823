import { useRef, useState } from 'react'
import cx from 'classnames'
import { Dropdown, Icon, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { MenuItem } from './MenuItem'

const MobileMenu = ({
  className, id, idx, name, items, onAction, classNameSpan, openMenu, handleMenuOpen, ...props
}) => {
  const ref = useRef()
  const intl = useIntl()

  const active = id === openMenu

  return (
    <>
      <div
        className={cx(className, 'fs-5')}
        key={id}
        role="menu"
        tabIndex={idx}
        aria-hidden="true"
        onClick={() => handleMenuOpen(id)}
      >
        <span className={classNameSpan}>{intl.t(name)}</span>
        <Icon name={active ? 'fa--caret-down' : 'fa--caret-right'} className="ms-1" />
      </div>
      {active && items?.length ? items?.map(({ name, ...rest }, idx) => (
        <MenuItem
          key={rest.id}
          idx={idx}
          className={cx('d-flex align-items-center')}
          {...props}
          {...rest}
          onAction={onAction}
        >
          <span className="d-flex flex-fill" role={!rest.actions && 'menuitem'}>{intl.t(name)}</span>
          {rest.actions?.length ? <Icon name="fa--caret-right" className="ms-4" /> : null}
        </MenuItem>
      )) : null}
    </>
  )
}

export default MobileMenu
