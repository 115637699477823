import { FormSection } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import FieldsTwinGraphs from './FieldsTwinGraphs'

export default function SectionTwinGraphs ({ scope }) {
  const intl = useIntl()

  return (
    <FormSection
      title={intl.t('twin.form.fields.twinGraphs')}
      description={intl.t('twin.form.fields.twinGraphsDescr')}
    >
      <FieldsTwinGraphs scope={scope} />
    </FormSection>
  )
}
