import {
  useRef, useCallback, useState, useMemo,
} from 'react'
import FormDialog from '@/components/Form/FormDialog'
import SectionValidation from '@/containers/Forms/Import/enhanceValidation'
import { Job } from '@wiz/store'
import Section from './Section'
import SectionFiles from './SectionFiles'
import SectionAwait from './SectionAwait'
import SectionResult from './SectionResult'

const submitBtnTextByStep = [
  'form.actions.next',
  'form.actions.next',
  'form.actions.startImport',
  'form.actions.next',
  'form.actions.save',
]

export default function FileUpload ({
  meta,
  dialog,
  onClose,
  twins,
  onSubmit,
  settings,
  bgJob,
}) {
  const [ step, setStep ] = useState(bgJob?.id ? 1 : 0)
  const [ isLoading, setLoading ] = useState(false)
  const [ job, setJob ] = useState()

  const refFiles = useRef()
  const refImport = useRef()
  const refCheck = useRef()
  const refValidation = useRef()
  const refSubmit = useRef({})
  const refMinStep = useRef(bgJob?.id ? 1 : 0)

  const defaultValues = useMemo(() => ({
    import: {
      ...Job.toJSON(bgJob),
      parameters: {
        ...bgJob?.parameters,
        sourceTimeZone: settings.PlatformDateTimeZoneName,
      },
      validationParameters: {
        ...bgJob?.validationParameters,
        AllowDataPointCreation: true,
      },
    },
  }), [
    settings,
    bgJob,
  ])
  const handleSuccess = useCallback((file) => {
    const next = refSubmit.current?.uploadedFiles || []
    next.concat(file.id)

    refSubmit.current.uploadedFiles = next
  }, [])

  const steps = useMemo(() => ({
    0: <SectionFiles
      meta={meta}
      refFiles={refFiles}
      refCheck={refCheck}
      onSuccess={handleSuccess}
    />,
    1: <Section
      scope="import"
      twins={twins}
      refImport={refImport}
    />,
    2: <SectionValidation id={job?.id} scope="import" refValidation={refValidation} />,
    3: <SectionAwait job={job} onNext={setStep} />,
    4: <SectionResult job={job} />,
  }), [ meta, twins, handleSuccess, job, setStep, refFiles ])

  const handleReset = useCallback(() => {
    refFiles.current.reset()
  }, [])

  const isSubmit = useMemo(() => {
    const lastStep = Object.keys(steps).length - 1 === step

    return !!lastStep
  }, [ step, steps ])

  const handleNext = useCallback(async (data) => {
    if (step === 0) {
      const [ isUploading, filesToUpload ] = refCheck.current?.checkFileExist() || []
      let isValid = false
      if (isUploading) {
        setLoading(true)
        try {
          await refFiles.current?.upload()
          isValid = true
        } finally {
          setLoading(false)
        }
      } else if (filesToUpload?.length) {
        refSubmit.current.files = filesToUpload
        isValid = true
      }
      isValid && setStep(prev => prev + 1)
    } else if (step === 1) {
      const values = { ...data }
      if (job?.id) {
        values.import.id = job.id
      }
      const model = await onSubmit(values)
      setJob(model)
      setStep(prev => prev + 1)
    } else if (step === 2) {
      if (job?.state === 'queued') {
        setStep(prev => prev + 2)
      } else {
        const next = {
          ...data.import,
          id: job?.id,
          state: 'queued',
        }

        if (refValidation.current?.checkAccepted()) {
          await onSubmit({ import: next })
          setStep(prev => prev + 1)
          refImport.current?.reset()
        }
      }
    } else {
      !isSubmit && setStep(prev => prev + 1)
    }
  }, [ step, onSubmit, job, isSubmit ])

  const handleSubmit = useCallback(async (data) => {
    handleNext(data)
    if (isSubmit) {
      onClose()
    }
  }, [ isSubmit, onClose, handleNext ])

  const handleClose = useCallback(() => {
    onClose()
    refFiles.current?.cancelAll?.()
  }, [ onClose ])

  const handlePrev = useCallback(async () => {
    refImport.current?.setValue('', '', { shouldDirty: true })
    if (step === 4) {
      if (job?.state === 'queued') {
        setStep(prev => prev - 2)
      }
    } else if (step > 0) {
      setStep(prev => prev - 1)
    }
  }, [ step, job ])

  const isFirstStep = useMemo(() => {
    // we cannot go back on very first step and after job was created
    if (step === refMinStep.current || step === 2) {
      return true
    }

    return false
  }, [ step ])

  const currentSection = steps[step]

  return (
    <FormDialog
      multiStep
      closeOnSuccess={isSubmit}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      onReset={handleReset}
      onClose={handleClose}
      dataTestid="fileUploadForm"
      dialog={dialog}
      isSubmit={isSubmit}
      onNext={handleNext}
      onPrev={handlePrev}
      isFirstStep={isFirstStep}
      submitText={submitBtnTextByStep[step]}
    >
      {currentSection}
    </FormDialog>
  )
}
