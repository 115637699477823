import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { get } from '@wiz/utils'
import { FormField, FormControl } from '@wiz/components'
import { useIntl } from '@wiz/intl'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('plots.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          disabled
        />
      </FormField>
      {/* <FormField
        label={intl.t('form.fields.generatedBy')}
        description={intl.t('events.form.fields.generatedByDescr')}
        errors={get(errors, `${scope}.generatedById`)}
      >
        <FormControl
          name={`${scope}.generatedById`}
          disabled
        />
      </FormField> */}
    </>
  )
}

Fields.propTypes = {
  scope: PropTypes.string.isRequired,
}
