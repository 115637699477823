import Icon from '@/shared/icon'
import ButtonScreen from './ButtonScreen'

export const settingsRoutes = [
  {
    id: 'dc5515de-b393-45ca-a5bc-fcf117b1ec9c',
    name: 't/menu.title.profile',
    group: '692c2996-3415-43cc-a50b-189a8e172a01',
    path: '/user/profile',
    hidden: true,
    route: {
      name: 'profile',
    },
    attr: {
      id: 'profile',
    },
  },
  {
    id: '9cbaa2c7-f8b1-4317-b99e-dde5ff5ba784',
    name: 't/menu.title.global',
    group: '55b6f387-b59f-4e4a-83a2-f68c90062e37',
    path: '/settings',
    route: {
      name: 'settings',
    },
    attr: {
      id: 'settings',
    },
    access: [
      [
        'SectionGeneralSettings',
        [
          'read',
        ],
      ],
    ],
  },
  {
    id: 'f8441323-3963-49e5-a23f-e3dd993f1d84',
    name: 't/menu.title.users',
    group: '55b6f387-b59f-4e4a-83a2-f68c90062e37',
    attr: {
      id: 'userManagement',
    },
    actions: [
      {
        path: 'users-list',
        name: 't/menu.settings.users.users',
        attr: {
          id: 'users',
        },
        route: {
          name: 'users',
        },
        access: [
          [
            'SectionUsers',
            [
              'read',
            ],
          ],
        ],
      },
      {
        path: 'roles-list',
        name: 't/menu.settings.users.roles',
        attr: {
          id: 'roles',
        },
        route: {
          name: 'roles',
        },
        access: [
          [
            'SectionRoles',
            [
              'read',
            ],
          ],
        ],
      },
      {
        path: 'notification-sheets-list',
        name: 't/menu.settings.users.lists',
        attr: {
          id: 'notification_sheets',
        },
        route: {
          name: 'notification-sheets-list',
        },
        access: [
          [
            'SectionNotificationSheets',
            [
              'read',
            ],
          ],
        ],
      },
    ],
  },
  {
    id: 'd1116222-5163-4333-a212-e3plw23f1d84',
    name: 't/menu.title.integrations',
    group: '55b6f387-b59f-4e4a-83a2-f68c90062e37',
    path: '/settings/integrations',
    route: {
      name: 'integrations-list',
    },
    attr: {
      id: 'integrations',
    },
    access: [
      [
        'SectionGeneralSettings',
        [
          'read',
        ],
      ],
    ],
  },
  {
    id: 'f8116113-3963-49e5-a23f-e3ww123f1d84',
    name: 't/menu.title.appearance',
    group: '55b6f387-b59f-4e4a-83a2-f68c90062e37',
    attr: {
      id: 'appearance',
    },
    actions: [
      {
        path: 'twin-style',
        name: 't/menu.settings.colors',
        attr: {
          id: 'style',
        },
        route: {
          name: 'settings-twin-style',
        },
        icon: <Icon name="faPalette" type="solid" size="lg" />,
        access: [
          [
            'SectionDigitalTwinStyle',
            [
              'read',
            ],
          ],
        ],
      },
      {
        path: '/',
        name: 't/menu.settings.fullScreen',
        attr: {
          id: 'fullScreen',
        },
        component: <ButtonScreen label="menu.settings.fullScreen" />,
      },
    ],
  },
]
