import { useCallback, memo } from 'react'
import { withProps } from '@wiz/components'
import { wizataApi } from '@/api'
import Component from '@/components/Forms/HistoricalRun'
import enhanceEvent from './enhanceRun'

const enhanceProps = withProps(({ onSuccess }) => {
  const onSubmit = useCallback(async (data) => {
    // const model = new Event(data.event)
    // await wizataApi.events.replace(model)
    // onSuccess?.()
  }, [ ])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceEvent(
    enhanceProps(Component),
  ),
)
