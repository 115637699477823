import { useIntl } from '@wiz/intl'
import ListFilters from '@/components/Form/ListFilters'
import SelectPipeline from '@/components/Form/SelectPipeline'
import SelectTemplate from '@/components/Form/ListTemplates'
import Select from '@/components/Form/Select'
import useAppContext from '@/hooks/useAppContext'

const statusList = [ 'enabled', 'failed' ]
  .map(status => ({ id: status, value: status, name: status }))

export default function Filters ({
}) {
  const intl = useIntl()
  const { filters, onFilter } = useAppContext()

  return (
    <ListFilters
      filters={filters}
      onFilter={onFilter}
    >
      {({ filters, onFilter }) => (
        <>
          <div className="me-1 mb-2 col-md-auto">
            <SelectPipeline
              placeholder={intl.t('form.fields.pipelinePlaceholder')}
              value={filters.pipelineId}
              // multiselect
              onChange={pipelineId => onFilter({ pipelineId })}
              className="form-select-sm"
            />
          </div>
          <div className="mx-1 mb-2 col-md-auto">
            <SelectTemplate
              placeholder={intl.t('form.fields.templates')}
              value={filters.templateId}
              // multiselect
              onChange={templateId => onFilter({ templateId })}
              className="form-select-sm"
            />
          </div>
          <div className="mx-1 mb-2 col-md-auto">
            <Select
              placeholder={intl.t('form.fields.statusPlaceholder')}
              options={statusList}
              value={filters.triggerStatus}
              onChange={triggerStatus => onFilter({ triggerStatus })}
              className="form-select-sm"
            />
          </div>
          {/* <div className="ms-1 mb-2 col-md-auto">
            <FormSelectDateTimeRange
              value={(
              filters.dateFrom && filters.dateTo ?
                [ Number(filters.dateFrom), Number(filters.dateTo) ] :
                [ null, null ]
            )}
              DateTimeComponent={FormatDateTime}
              clearable
              onChange={([ dateFrom, dateTo ]) => {
                onFilter({ dateFrom, dateTo })
              }}
              extended
              className="form-select-sm"
            />
          </div> */}
        </>
      )}
    </ListFilters>
  )
}
