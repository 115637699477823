import FilterSearch from '@/components/Form/FilterSearch'
import {
  Icon,
  FormCheckButton,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'

export default function LeftBarConnections ({ linkMode, setLinkMode }) {
  const intl = useIntl()

  return (
    <FilterSearch
      // onChange={handleChange}
      withSelectedOnly={false}
      postfix={(
        <div className="mx-2 flex-shrink-0">
          <FormCheckButton
            className="btn btn-flat-secondary btn-text"
            checked={linkMode === 'link-parent-mode'}
            label={<Icon name="fa--sitemap" />}
            title={intl.t('twinGraph.form.actions.linkParentModeTitle')}
            onChange={checked => setLinkMode(checked ? 'link-parent-mode' : null)}
          />
          <FormCheckButton
            className="btn btn-flat-secondary btn-text"
            checked={linkMode === 'link-descr-mode'}
            label={<Icon name="fa--chart-network" />}
            title={intl.t('twinGraph.form.actions.linkDescrModeTitle')}
            onChange={checked => setLinkMode(checked ? 'link-descr-mode' : null)}
          />
        </div>
)}
    >
      {() => (
        <div className="d-flex flex-column align-items-center justify-content-center w-100">
          {intl.t('form.nodata')}
          {/* {onResetFilter ? (
          <button type="button" className="btn btn-sm btn-outline-secondary mt-2" onClick={onResetFilter}>
            {intl.t('form.actions.resetFilters')}
          </button>
        ) : null} */}
        </div>
      )}
    </FilterSearch>
  )
}
