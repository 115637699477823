import { useCallback, memo } from 'react'
import { withProps } from '@wiz/components'
import { Event } from '@wiz/store'
import { wizataApi } from '@/api'
import Component from '@/components/Forms/EventBulkEdit'

const enhanceProps = withProps(({ events, onSuccess }) => {
  const onSubmit = useCallback(async (data) => {
    if (!data.selected?.length) {
      return
    }

    const nextData = {}
    for (const name of data.selected) {
      nextData[name] = data.events[name]
    }

    const nextEvents = []
    for (const item of events) {
      nextEvents.push(new Event({
        ...item.toJSON(),
        ...nextData,
      }))
    }

    const result = await wizataApi.events.groupUpdate(nextEvents)
    onSuccess?.(result)
  }, [ events, onSuccess ])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceProps(Component),
)
