import { useMemo } from 'react'
import { DiagramBlock, Twin } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import BlockSection from '../Block/Section'
import SettingsSection from './SettingsSection'
import Schema from './Schema'
import resolver from './resolver'

export default function BlockTwin ({
  block,
  settings,
  dashboards,
  explorers,
  streamJobs,
  twinGraphs,
  type,
  dialog,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()
  const defaultValues = useMemo(() => {
    const dataBlock = DiagramBlock.toJSON(block)
    dataBlock.type = type

    const dataTwin = Twin.toJSON(settings)
    dataTwin.type = type

    return {
      block: dataBlock,
      settings: {
        twin: dataTwin,
        dashboards,
        explorers,
        streamJobs,
        twinGraphs,
      },
    }
  }, [
    block?.updatedAt ?? block,
    settings?.updatedAt ?? settings,
    dashboards,
    explorers,
    streamJobs,
    twinGraphs,
    type,
  ])

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      resolver={resolver}
      schema={Schema}
      dataTestid="blockTwinForm"
      dialog={dialog}
    >
      <BlockSection
        scope="block"
        titles={{
          nameDescr: intl.t('blockTwin.form.fields.nameDescr'),
          descriptionDescr: intl.t('blockTwin.form.fields.descriptionDescr'),
        }}
      />

      <SettingsSection
        scope="settings"
        type={type}
      />
    </FormDialog>
  )
}
