import { of as of$ } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { dbProvider, Q } from '@wiz/store'
import { orderBy } from '@wiz/utils'

const enhanceDiagramData = withObservables([ 'filters' ], ({ filters }) => ({
  defaultTree: dbProvider.observeSettings('PhysicalTwinDefaultTree'),
  defaultSelected: dbProvider.observeSettings('PhysicalTwinDefaultSelected'),

  twinsChain: filters?.tree ? dbProvider.database.collections.get('twins')
    .query(Q.where('id', filters.tree))
    .observeWithColumns([ 'updated_at' ])
    .pipe(
      switchMap(([ item ]) => (item ? item.observeTwinsChain : of$([]))),
      map(items => items.reverse()),
    ) : of$([]),

  defaultRootTwin: dbProvider.database.collections.get('twins')
    .query(Q.where('parentId', null))
    .observe()
    .pipe(
      map(items => orderBy(
        items,
        [ 'type', item => item.displayName.toLowerCase() ],
        [ 'asc', 'asc' ],
      )[0]),
    ),

  twinSettings: dbProvider.observeGlobalSettings([
    'twinColorAreas',
    'twinColorMachines',
    'twinColorEquipment',
  ]),
}))

export default enhanceDiagramData
