import PropTypes from 'prop-types'
import { OverflowCenter, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import ButtonCopy from '@/components/Form/ButtonCopy'

export default function OptionBlock (props) {
  const intl = useIntl()
  const {
    treeData: {
      blockSettings,
      onAttachEvent,
    },
    data: {
      payload: item,
    },
  } = props
  const hasName = !!item.name
  const twin = blockSettings[item.id]

  const name = hasName ? (
    <OverflowCenter
      value={item.name}
    />
  ) : (
    <span className="text-muted">
      {intl.t('form.noname')}
    </span>
  )

  const copy = twin?.hardwareId ? (
    <ButtonCopy
      className="hover-inline-flex"
      value={twin.hardwareId}
      title={intl.t('message.copy.hardwareId', { value: twin.hardwareId })}
    />
  ) : null

  // const icon = twin?.icon || twin?.color ? (
  //   <Icon
  //     name={twin?.icon || 'fa--circle'}
  //     color={twin.color}
  //     className="me-1"
  //   />
  // ) : null

  return (
    <div className="flex-fill d-flex align-items-start hover-toggle min-w-0">
      {twin ? (
        <>
          {hasName ? (
            <div className="flex-fill min-w-0">
              {name}

              <div className="d-flex align-items-center min-w-0">
                <OverflowCenter
                  value={twin.displayName}
                />

                {copy}
              </div>
            </div>
          ) : (
            <div className="flex-fill d-flex align-items-center min-w-0">
              <OverflowCenter
                value={twin.displayName}
              />

              {copy}
            </div>
          )}

          <button
            type="button"
            className="hover-opacity btn btn-flat-secondary align-self-center p-2 ms-1"
            title={intl.t('twinGraph.form.actions.attachEvent')}
            onClick={() => onAttachEvent(twin)}
          >
            <Icon name="fa--plus" />
          </button>
        </>
      ) : name}
    </div>
  )
}

OptionBlock.propTypes = {
  treeData: PropTypes.shape({
    blockSettings: PropTypes.object.isRequired,
    onAttachEvent: PropTypes.func.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    payload: PropTypes.object.isRequired,
  }).isRequired,
}
