import PropTypes from 'prop-types'
import FormDialog from '@/components/Form/FormDialog'
import Section from './Section'

export default function QualityDataBulkEdit ({
  onSubmit,
  onClose,
  dialog,
}) {
  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      dataTestid="bulkEditQualityDataForm"
      dialog={dialog}
    >
      <Section scope="qualityData" />
    </FormDialog>
  )
}

QualityDataBulkEdit.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dialog: PropTypes.object,
}

QualityDataBulkEdit.defaultProps = {
  dialog: undefined,
}
