import { memo } from 'react'
import Component from '@/components/Forms/NotebookPreview'
import enhanceNotebook from '@/containers/Forms/Notebook/enhanceNotebook'
import enhanceNotebookPreview from './enhanceNotebookPreview'

export default memo(
  enhanceNotebook(
    enhanceNotebookPreview(Component),
  ),
)
