import { of as of$ } from 'rxjs'
import { map, distinctUntilChanged } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { isEqual, consts } from '@wiz/utils'

export default withObservables([
  'id',
  'files',
  'twins',
], ({
  id,
  files = [],
  twins = [],
}) => ({
  bgJob: id ? (
    dbProvider.database.collections.get('jobs')
      .query(Q.where('id', id))
      .observeWithColumns([ 'updated_at' ])
      .pipe(map(items => items[0]))
  ) : (
    of$({
      command: consts.JobCommand.FileImport,
      parameters: {
        twinId: twins?.[0]?.id,
      },
    })
      .pipe(distinctUntilChanged(isEqual))
  ),

  projects: id ? (
    dbProvider.database.collections.get('rel_projects_jobs')
      .query(Q.where('job_id', id))
      .observeWithColumns([ 'updated_at' ])
      .pipe(map(items => items.map(item => item.projectId)))
  ) : (
    of$([])
      .pipe(distinctUntilChanged(isEqual))
  ),

  files: id ? (
    dbProvider.database.collections.get('rel_jobs_files')
      .query(Q.where('job_id', id))
      .observeWithColumns([ 'updated_at' ])
      .pipe(map(items => items.map(item => item.fileId)))
  ) : (
    of$(files)
      .pipe(distinctUntilChanged(isEqual))
  ),
  settings: dbProvider.observeGlobalSettings([
    'PlatformDateTimeZoneName',
  ]),
}))
