import { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@wiz/components'
import { useAuth } from '@/auth'
import { useRouter } from '@/router'

export default function CellSensors ({
  event,
  options,
  onFilter,
}) {
  const auth = useAuth()
  const router = useRouter()
  const content = useMemo(() => (
    options.map(item => item.displayName).join(', ')
  ), [ options ])

  const handleClick = useCallback(() => {
    const sensorIds = options.map(item => item.id)
    router.push({
      name: 'data-observation',
      params: { id: 'new' },
      state: { selection: sensorIds, event },
    })
  }, [ options, router, event ])

  const handleFilter = useCallback(() => {
    const sensorIds = options.map(item => item.id)
    onFilter?.({ sensorIds })
  }, [ options, onFilter ])

  if (!options.length) {
    return null
  }

  if (!auth.checkAccessRead('SectionDataExplorer')) {
    return (
      <div className="d-flex align-items-center me-2 min-w-0">
        <button
          type="button"
          className="btn btn-sm p-0 border-0 me-2"
          onClick={handleFilter}
        >
          <Icon
            name="fa--search"
          />
        </button>

        <div className="text-truncate">
          {content}
        </div>
      </div>
    )
  }

  return (
    <div className="d-flex align-items-center me-2 min-w-0">
      <button
        type="button"
        className="btn btn-sm p-0 border-0 me-2"
        onClick={handleFilter}
      >
        <Icon
          name="fa--search"
        />
      </button>

      <button
        type="button"
        className="d-inline btn btn-sm btn-link justify-content-start text-reset text-truncate p-0 border-0"
        title={content}
        onClick={handleClick}
      >
        {content}
      </button>
    </div>
  )
}

CellSensors.propTypes = {
  event: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  onFilter: PropTypes.func,
}

CellSensors.defaultProps = {
  onFilter: undefined,
}
