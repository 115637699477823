import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormSection } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useRouter } from '@/router'
import ListSensors from '@/components/Form/ListSensors'
import FilterSearch from '@/components/Form/FilterSearch'
import classes from './SectionLinkedSensors.css'

export default function SectionLinkedSensors ({ scope }) {
  const intl = useIntl()
  const router = useRouter()
  const { watch } = useFormContext()
  const id = watch(`${scope}.id`)

  const handleClick = useCallback((data) => {
    router.push({
      name: 'sensors-list',
      query: { search: data.payload.id },
    })
  }, [ router ])

  return (
    <FormSection
      className="flex-fill d-flex flex-column min-h-0"
      classNameContent="flex-fill d-flex flex-column min-h-0 px-0 py-2"
    >
      {id ? (
        <FilterSearch
          withSelectedOnly={false}
          withClearAll={false}
          withSelectAll={false}
        >
          {(formData, refList) => (
            <ListSensors
              {...formData}
              ref={refList}
              className={classes.list}
              keyName="id"
              labelIds={id}
              onChange={handleClick}
            />
          )}
        </FilterSearch>
      ) : (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          {intl.t('errors.noDataDisplay')}
        </div>
      )}
    </FormSection>
  )
}
