import { define } from 'superstruct'
import { intl } from '@/i18n'

const BinValue = () => define('BinValue', (value, { branch }) => {
  const data = branch[branch.length - 2]
  let bins = branch[branch.length - 3]
  const idx = bins.findIndex(item => item.id === data.id)

  if (idx === bins.length - 1) {
    return true
  }

  const len = bins.length
  bins = bins.map((field, index) => ({
    ...field,
    value: index === len - 1 ? null : field.value,
  }))

  const prevValue = bins[idx - 1]?.value ?? null
  const nextValue = bins[idx + 1]?.value ?? null
  let result = true

  if (prevValue !== null && value <= prevValue) {
    result = intl.t('form.errors.mustBeMore', { value: prevValue })
  } else if (nextValue !== null && value >= nextValue) {
    result = intl.t('form.errors.mustBeLess', { value: nextValue })
  }

  return result
})

export default BinValue
