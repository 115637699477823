import { Controller, useFormContext } from 'react-hook-form'
import {
  FormField,
  FormControl,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import SelectNotebookEnvironment from '@/containers/Form/SelectNotebookEnvironment'
import SelectNotebookLanguage from '@/containers/Form/SelectNotebookLanguage'
import SelectNotebookFormat from '@/containers/Form/SelectNotebookFormat'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('notebooks.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (!str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value.length > 200) {
                return intl.t('form.errors.fieldMaxlen', { max: 200 })
              }
              if (value.length < 5) {
                return intl.t('form.errors.fieldMinlen', { min: 5 })
              }
              return true
            },
          }}
          placeholder={intl.t('form.fields.namePlaceholder')}
        />
      </FormField>

      <FormField
        label={intl.t('notebooks.form.fields.databricksPath')}
        description={intl.t('notebooks.form.fields.databricksPathDescr')}
        errors={get(errors, `${scope}.path`)}
      >
        <FormControl
          name={`${scope}.path`}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (!str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value.length > 100) {
                return intl.t('form.errors.fieldMaxlen', { max: 100 })
              }
              return true
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('notebooks.form.fields.userDefinedUrl')}
        description={intl.t('notebooks.form.fields.userDefinedUrlDescr')}
        errors={get(errors, `${scope}.userDefinedUrl`)}
      >
        <FormControl
          name={`${scope}.userDefinedUrl`}
          rules={{
            maxLength: {
              value: 2000,
              message: intl.t('form.errors.fieldMaxlen', { max: 2000 }),
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('notebooks.form.fields.environment')}
        description={intl.t('notebooks.form.fields.environmentDescr')}
        errors={get(errors, `${scope}.environment`)}
      >
        <Controller
          name={`${scope}.environment`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <SelectNotebookEnvironment
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.environmentPlaceholder')}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('notebooks.form.fields.language')}
        description={intl.t('notebooks.form.fields.languageDescr')}
        errors={get(errors, `${scope}.language`)}
      >
        <Controller
          name={`${scope}.language`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <SelectNotebookLanguage
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.languagePlaceholder')}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('notebooks.form.fields.format')}
        description={intl.t('notebooks.form.fields.formatDescr')}
        errors={get(errors, `${scope}.format`)}
      >
        <Controller
          name={`${scope}.format`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <SelectNotebookFormat
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.formatPlaceholder')}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.description')}
        description={intl.t('notebooks.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.description`}
          rules={{
            maxLength: {
              value: 450,
              message: intl.t('form.errors.fieldMaxlen', { max: 450 }),
            },
          }}
        />
      </FormField>
    </>
  )
}
