/* eslint-disable guard-for-in */
/* eslint-disable no-plusplus */
import { of as of$, Observable, combineLatest } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { orderBy } from '@wiz/utils'
import { Q, dbProvider, Condition } from '@wiz/store'
import formatter from '@/utils/formatter'
import observeSensorsData from '@/utils/observeSensorsData'

export default function observeTableData (widgetId) {
  return new Observable((subscriber) => {
    const refLast = { current: [] }

    const subscription = dbProvider.database.collections.get('widgets')
      .query(Q.where('id', widgetId))
      .observeWithColumns([ 'updated_at' ])
      .pipe(
        switchMap(items => (items.length ? items[0].observeConfig : of$(undefined))),
        switchMap(config => (config ? combineLatest(
          of$(config),
          observeSensorsData(config, refLast),
        ) : of$(undefined))),
        switchMap(([ config, data ] = []) => (data ? combineLatest(
          dbProvider.observeSettings([ 'PlatformDateFormat', 'PlatformTimeFormat' ]),
          of$(config),
          of$(data),
          dbProvider.database.collections.get('sensors')
            .query(Q.where('id', Q.oneOf(data.map(({ sensorId }) => sensorId).filter(Boolean))))
            .observeWithColumns([ 'updated_at' ])
            .pipe(map(items => Object.values(
              items.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}),
            ))),
        ) : of$(undefined))),
      )
      .subscribe(([ settings, config, data, sensors ] = []) => {
        if (data?.length && sensors?.length) {
          const conditions = {}
          for (const item of config.conditions) {
            const inputs = item.inputDataSources
            const outputs = item.outputDataSources.length ?
              item.outputDataSources :
              item.inputDataSources
            for (const output of outputs) {
              conditions[output.sensorId] = conditions[output.sensorId] || {}
              for (const input of inputs) {
                conditions[output.sensorId][input.sensorId] = (
                  conditions[output.sensorId][input.sensorId] || []
                )
                conditions[output.sensorId][input.sensorId].push(item)
              }
            }
          }

          let values = {}
          for (const resp of data) {
            const { sensorId } = resp
            for (const item of resp.response) {
              const [ ts, value ] = item
              values[ts] = values[ts] || { ts }
              values[ts][sensorId] = value
            }
          }

          /*
          [
            { items: [ { value: '' }, { value: 'Sensor 1' }, { value: 'Sensor 2' } ] }
            { items: [ { value: 1645698590045 }, { value: 0 }, { value: 0 } ] }
          ]
          */

          values = orderBy(Object.values(values), 'ts', 'desc')
            .slice(-100)

          let col = 0
          const head = { items: [{ value: '', col }] }
          for (const sensor of sensors) {
            head.items.push({
              value: sensor.displayName,
              col: ++col,
              w: Math.ceil(sensor.displayName.length * 7.5),
            })
          }

          const rows = [ head ]
          for (const item of values) {
            col = 0
            const row = {
              items: [{
                col,
                value: formatter({
                  date: item.ts,
                  utcTime: config.utcTime,
                  withMillis: config.msTime,
                  withSecond: true,
                  settings,
                }),
              }],
            }
            for (const sensor of sensors) {
              const cond = []
              for (const inputSensorId in conditions[sensor.id]) {
                const value = item[inputSensorId]
                for (const condition of conditions[sensor.id][inputSensorId]) {
                  if (Condition.pass(condition, item.ts, value)) {
                    cond.push({
                      name: condition.name,
                      color: condition.color,
                    })
                  }
                }
              }

              row.items.push({
                col: ++col,
                value: item[sensor.id],
                w: rows[0].items[col].w,
                cond,
              })
            }
            rows.push(row)
          }

          subscriber.next({
            items: rows,
          })
        }
      })

    return () => {
      subscription.unsubscribe()
    }
  })
}
