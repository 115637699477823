import { useMemo } from 'react'
import { Sensor } from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import Section from './Section'

export default function Measurement ({
  id,
  sensor,
  labels,
  validates,
  dialog,
  onClose,
  onSubmit,
}) {
  const defaultValues = useMemo(() => ({
    sensor: Sensor.toJSON(sensor),
    labels: labels.map(item => item.id),
  }), [
    sensor?.updatedAt ?? sensor,
    labels,
  ])

  if (id && !sensor) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="sensorForm"
      dialog={dialog}
    >
      <Section
        scope="sensor"
        exclude={[ 'sensor.businessType' ]}
        validates={validates}
      />
    </FormDialog>
  )
}
