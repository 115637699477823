import { memo } from 'react'
import { map } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { consts } from '@wiz/utils'
import { Q, dbProvider } from '@wiz/store'
import Component from '@/components/Forms/InputSensorValueGroup'

const enhanceData = withObservables([ 'sensorIds' ], ({
  sensorIds,
}) => ({
  sensorIds: dbProvider.database.collections.get('sensors')
    .query(
      Q.where('id', Q.oneOf(sensorIds)),
      Q.where('business_type', Q.oneOf(consts.SensorBusinessTypesInput)),
      Q.where('input_mode', Q.oneOf(consts.SensorManualInputModes)),
    )
    .observeWithColumns([ 'updated_at' ])
    .pipe(map(items => items.map(item => item.id))),
}))

export default memo(
  enhanceData(Component),
)
