import { of as of$, combineLatest } from 'rxjs'
import { map as map$, switchMap } from 'rxjs/operators'
import sortBy from 'lodash/sortBy'
import { filterNestedTree } from '@wiz/utils'
import { withObservables, withProps } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'

function filterRootTwinGraphs () {
  return (rows, loki) => rows.filter((record) => {
    const twinGraph = loki.getCollection('twin_graphs').findOne({
      block_id: record.id,
    })

    if (twinGraph) {
      const rel = loki.getCollection('rel_diagram_blocks_twin_graphs').findOne({
        twin_graph_id: twinGraph.id,
        _status: { $ne: 'deleted' },
      })
      if (rel) {
        return false
      }
    }

    return true
  })
}

function createNestedTree ({
  checkEnabled,
} = {}) {
  return (items) => {
    for (let { twinGraph, twinTree } of items) {
      const blocks = Object.create(null)
      const hash = Object.create(null)
      twinTree = sortBy(twinTree, 'name')

      for (const item of twinTree) {
        if (!item.fromId || !item.toId) {
          const node = {
            id: item.id,
            name: item.name,
            disabled: !checkEnabled?.(item),
            payload: item,
          }

          blocks[item.id] = node
          hash[item.id] = node
        }
      }

      for (const item of twinTree) {
        if (item.fromId && item.toId && hash[item.fromId] && hash[item.toId]) {
          hash[item.fromId].children = hash[item.fromId].children || []
          hash[item.fromId].children.push(hash[item.toId])
          delete blocks[item.toId]
        }
      }

      twinGraph.children = Object.values(blocks)
    }

    return sortBy(items.map(({
      twinGraph: {
        id,
        name,
        children,
        ...data
      },
    }) => ({
      id,
      name,
      children,
      payload: {
        id,
        name,
        ...data,
      },
    })), 'name')
  }
}

const enhanceData = withObservables([], () => {
  const query = dbProvider.database.collections.get('diagram_blocks')
    .query(
      Q.where('type', 'twinGraph'),
      Q.unsafeLokiTransform(filterRootTwinGraphs()),
    )

  return {
    options: query
      .observe()
      .pipe(
        switchMap(items => (
          items.length ? combineLatest(
            items.map(item => (
              combineLatest(item.observeDiagramContext, item.observeDiagramBlocksTree).pipe(
                map$(([ twinGraph, twinTree ]) => ({ twinGraph, twinTree })),
              )
            )),
          ) : of$([])
        )),
      ),
  }
})

const enhanceTree = withProps(({
  search,
  options,
}) => {
  // const disabledFrom = data => (!data.payload.isNode)

  const checkEnabled = (data) => {
    let enabled = true

    if (enabled && search) {
      enabled = (
        String(data.name || '').toLowerCase().indexOf(search) !== -1 ||
        String(data.description || '').toLowerCase().indexOf(search) !== -1
      )
    }

    return enabled
  }

  return {
    options: filterNestedTree()(
      createNestedTree({ checkEnabled })(options),
    ),
  }
})

export default WrappedComponent => enhanceData(
  enhanceTree(WrappedComponent),
)
