import { useEffect, useContext } from 'react'
import { withProps } from '@wiz/components'
import {
  dbProvider,
  EventSource,
  IExplorer,
  Widget,
} from '@wiz/store'
import { isEmpty } from '@wiz/utils'
import { useLocation } from '@/router'
import DataExplorer from '@/context/DataExplorer'
import DataExplorerEnv from '@/context/DataExplorerEnv'

export default withProps(({
  match,
  explorer,
  explorerContext,
  settings,
  sensorIds,
}) => {
  const location = useLocation()
  const deContext = useContext(DataExplorer)
  const { openExplorer } = useContext(DataExplorerEnv)
  const isWidget = Widget.is(explorer)
  const explorerId = explorer?.id
  const { id } = match.params

  useEffect(() => {
    async function initialize () {
      if (id === 'reset') {
        const context = dbProvider.createBatchContext()
        await dbProvider.prepareReplaceSetting(context, 'explorerDefaultView', null)
        await dbProvider.batch(context)
        openExplorer()
      } else if (id === 'new') {
        let next
        if (!isEmpty(sensorIds) || !isEmpty(location.state?.selection)) {
          const selection = sensorIds?.slice(0, 10) || location.state.selection.slice(0, 10)
          const dataSources = []
          const dataViews = []

          for (const sensorId of selection) {
            const source = await IExplorer.createDataSourceContext(
              dbProvider.database,
              { sensorId, dataType: 'avg' },
            )
            const view = await IExplorer.createDataViewContext(
              dbProvider.database,
              { sourceId: source.id, source },
            )
            dataSources.push(source)
            dataViews.push(view)
          }

          next = {
            dataViews,
            dataSources,
            conditions: [],
            eventSources: [],
          }
        } else if (!isEmpty(location.state?.dataFilter)) {
          next = {
            dataFilter: location.state.dataFilter,
            dataViews: location.state.dataViews,
            dataSources: location.state.dataSources,
            conditions: [],
            eventSources: [],
          }
        }

        if (location.state?.event) {
          const { event } = location.state
          next = {
            ...next,
            eventSources: (next.eventSources || []).concat(
              EventSource.toJSON({
                name: event.name,
                twinId: event.twinId,
                streamJobId: event.streamJobId,
                eventTypes: event.type,
                statuses: event.status,
                sensorIds: event.sensorIds,
                dataViews: (next.dataViews || deContext.data.dataViews).map(item => item.id),
              }),
            ),
          }

          if (event.from) {
            const dataFilter = next.dataFilter || deContext.data.dataFilter
            const eventDuration = event.to ? (event.to - event.from) : 60 * 60 * 1000

            next = {
              ...next,
              dataFilter: {
                ...dataFilter,
                dateFrom: event.from - eventDuration,
                dateTo: event.to ? (event.to + eventDuration) : Date.now(),
                duration: 0,
              },
            }
          }
        }

        if (next) {
          deContext.setCurrentContext({
            ...deContext.data,
            ...next,
          })
        }
      } else if (id) {
        if (explorerId) {
          if (explorerId !== settings.explorerDefaultView && !isWidget) {
            const context = dbProvider.createBatchContext()
            await dbProvider.prepareReplaceSetting(context, 'explorerDefaultView', explorerId)
            await dbProvider.batch(context)
          }
          deContext.setCurrentContext(explorerContext)
        } else {
          if (settings.explorerDefaultView === id) {
            const context = dbProvider.createBatchContext()
            await dbProvider.prepareReplaceSetting(context, 'explorerDefaultView', null)
            await dbProvider.batch(context)
          }
          openExplorer()
        }
      } else if (!id) {
        if (settings.explorerDefaultView) {
          openExplorer(settings.explorerDefaultView)
        } else {
          deContext.setCurrentContext(explorerContext)
        }
      }
    }

    initialize()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ id, explorerId, sensorIds ])
})
