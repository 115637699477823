import { consts } from '@wiz/utils'
import withTableActions from '@/containers/withTableActions'
import Component from '@/components/Twin/List'
import enhanceListProps from './enhanceListProps'

export default withTableActions({
  defaultFilters: () => ({
    view: consts.ViewMode.Tree,
  }),

  initFilters (query) {
    const view = query.view || consts.ViewMode.Tree
    const tree = view === consts.ViewMode.Tree ? query.tree : undefined

    return {
      type: query.type,
      view,
      tree,
    }
  },
})(
  enhanceListProps(Component),
)
