import { useMemo } from 'react'
import FormDialog from '@/components/Form/FormDialog'
import Section from './Section'
import Schema from './Schema'
import resolver from './resolver'

export default function DuplicateWidget ({
  dialog,
  onClose,
  onSubmit,
}) {
  const defaultValues = useMemo(() => ({
    dashboards: [],
    newDashboards: [],
  }), [])

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="duplicateWidgetForm"
      dialog={dialog}
      resolver={resolver}
      schema={Schema}
    >
      <Section />
    </FormDialog>
  )
}
