import {
  type,
  array,
  optional,
  nullable,
} from 'superstruct'
import { DataTypes } from '@wiz/utils'
import SchemaNotebook from './SchemaNotebook'

export default type({
  notebook: SchemaNotebook,
  sensors: optional(nullable(array(DataTypes.StructUuid()))),
  projects: optional(nullable(array(DataTypes.StructUuid()))),
})
