import {
  useCallback,
  useRef,
  useMemo,
} from 'react'
import classnames from 'classnames'
import { useIntl } from '@wiz/intl'
import { dbProvider } from '@wiz/store'
import { DropdownMenu, useOnline } from '@wiz/components'
import { useRouter } from '@/router'
import { useAuth } from '@/auth'
import DateRelative from '@/components/DateRelative'
import useSyncStatus from '@/hooks/useSyncStatus'
import FormatDateTime from '@/containers/FormatDateTime'
import IconStatus from './IconStatus'
import Userpic from './Userpic'
import classes from './ButtonUser.css'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'

const RoutesById = {
  dashboard: 'homepage',
  profile: 'profile',
  logout: 'logout',
}

export default function ButtonUser () {
  const auth = useAuth()
  const router = useRouter()
  const intl = useIntl()
  const refTarget = useRef()
  const online = useOnline()
  const syncStatus = useSyncStatus()
  const { user } = useGlobalExecute()

  const lastSyncTime = (
    syncStatus.lastLog?.newLastPulledAt ||
    syncStatus.lastLog?.lastPulledAt
  )

  const handleClick = useCallback(({ id }) => {
    if (id === 'relogin') {
      auth.relogin()
    } else if (RoutesById[id]) {
      router.push({ name: RoutesById[id] })
    }
  }, [ auth, router ])

  const handleResetLocalData = useCallback(() => {
    window.wizConfirm({ message: 'form.confirmResetLocalData' })
      .then(() => dbProvider.scheduledResetDatabase())
  }, [])

  const options = useMemo(() => ([
    {
      content: () => (
        <div className="px-3 pt-2">
          {syncStatus.status === 'pull-error' ? (
            <div className="mb-2">
              <h5 className="text-danger">{intl.t('errors.syncTitle')}</h5>
              <p className="mb-2">
                An error occurred while retrieving global data.
                You will not see changes made by other members.
                <br />
                Please contact support.
              </p>
            </div>
          ) : null}

          {syncStatus.status === 'push-error' ? (
            <div className="mb-2">
              <h5 className="text-danger">{intl.t('errors.syncTitle')}</h5>
              <p className="mb-2">
                An error occurred while synchronizing local data with the server.
                Your changes will not be visible to other members.
                <br />
                Please contact support or try resetting local changes.
              </p>
              <button
                type="button"
                className="btn btn-sm btn-outline-danger"
                onClick={handleResetLocalData}
              >
                Reset local data
              </button>
            </div>
          ) : null}

          {syncStatus.status === 'error' ? (
            <div className="mb-2">
              <h5 className="text-danger">{intl.t('errors.syncTitle')}</h5>
              <p className="mb-2">
                An error occurred while retrieving global data.
                You will not see changes made by other members and
                your changes will not be visible to other members.
                <br />
                {!online ? 'Please check your internet connection.' : 'Please contact support.'}
              </p>
            </div>
          ) : null}

          {syncStatus.status === 'pause' && !online ? (
            <div className="mb-2">
              <h5 className="text-warning">{intl.t('errors.syncOffline')}</h5>
              <p className="mb-2">
                Please check your internet connection.
              </p>
            </div>
          ) : null}

          {syncStatus.status ? (
            <div className="d-flex align-items-center justify-content-between mb-2">
              {lastSyncTime ? (
                <div>
                  <b>Last Sync Time:</b>
                  <br />
                  <FormatDateTime date={lastSyncTime} />
                  <sup className="ms-1">
                    <DateRelative value={lastSyncTime} />
                  </sup>
                </div>
              ) : null}

              <IconStatus
                className="ms-1"
                syncStatus={syncStatus.status}
                online={online}
                size="lg"
              />
            </div>
          ) : null}
        </div>
      ),
    },
    { divider: true },
    { id: 'profile', name: intl.t('profile.titleMenu') },
    { id: 'dashboard', name: intl.t('user.homepage') },
    { id: 'relogin', name: intl.t('user.relogin') },
    { divider: true },
    { id: 'logout', name: intl.t('user.logout') },
  ]), [
    intl,
    online,
    syncStatus,
    lastSyncTime,
    handleResetLocalData,
  ])

  return (
    <>
      <button
        ref={refTarget}
        type="button"
        className={classnames(classes.btnUser,
          {'p-0 rounded-circle ms-1': user.avatar},
          'btn btn-text btn-flat-secondary')}
        title={intl.t('menu.tooltip.user')}
      >
        <Userpic />

        <IconStatus
          className={classnames(classes.status, {[classes.avatar]: user.avatar})}
          syncStatus={syncStatus.status}
          online={online}
          size="xs"
        />
      </button>

      <DropdownMenu
        target={refTarget}
        options={options}
        className={classes.dropdown}
        placement="bottom-end"
        arrow
        autoclose
        width={300}
        onClick={handleClick}
      />
    </>
  )
}
