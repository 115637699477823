import { Icon } from '@wiz/components'
import { auth } from '@/auth'
import Link from '@/components/Link'

export default function CellStreamJob ({ options: [ target ] }) {
  if (!target) {
    return null
  }

  if (!auth.checkAccessRead(target)) {
    return (
      <div className="d-flex align-items-center me-2 min-w-0">
        <Icon name="mlworkflow" className="me-1" title={target.name} />
        <span className="min-w-0 text-truncate" title={target.name}>{target.name}</span>
      </div>
    )
  }

  return (
    <div className="d-flex align-items-center me-2 min-w-0">
      <Icon name="mlworkflow" className="me-1" title={target.name} />

      <Link
        className="min-w-0 text-truncate"
        title={target.name}
        name="stream-jobs-view"
        params={{ id: target?.id }}
      >
        {target.name}
      </Link>
    </div>
  )
}
