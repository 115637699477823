import { forwardRef, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { wizataApi } from '@/api'
// import { FormSelect } from '@wiz/components'
import Select from '@/components/Form/Select'
import { withObservables } from '@wiz/components'
import { dbProvider, Q } from '@wiz/store'

const enhanceProps = withObservables([], () => ({
  options: dbProvider.database.collections.get('business_labels').query().observeWithColumns([ 'updated_at' ]),
}))

const ListBusinessLabels = forwardRef(({ id, disabled, ...props }, ref) =>
// const { data, isLoading } = useQuery({
//   queryKey: [ 'templates' ],
//   queryFn: () => wizataApi.templates.getList().then((response) => {
//     if (response?.length) {
//       const next = response.map(item => ({ ...item, value: item.id }))
//       return next
//     }
//     return response
//   }),
//   enabled: true,
// })

// return (
//   <FormSelect
//     {...props}
//     invalid={props.invalid}
//     options={data}
//     disabled={disabled || isLoading}
//   />
// )

  (
    <Select
      {...props}
      invalid={props.invalid}
      disabled={disabled}
    />
  ))

export default enhanceProps(ListBusinessLabels)
