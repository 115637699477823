import { useEffect, useState } from 'react'
import { fullscreen } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import Icon from '@/shared/icon'

export default function ButtonScreen ({ label }) {
  const intl = useIntl()
  const [ isFullscreen, setFullscreen ] = useState(false)
  const text = intl.t(label)
  const textLabel = isFullscreen ? `Exit ${text}` : `Enter ${text}`

  useEffect(() => {
    setFullscreen(fullscreen.element === document.documentElement)
    const handleFullscreenChange = (element) => {
      setFullscreen(element === document.documentElement)
    }
    const handleFullscreenError = () => {
      setFullscreen(false)
    }
    fullscreen.on('change', handleFullscreenChange)
    fullscreen.on('error', handleFullscreenError)

    return () => {
      fullscreen.removeListener('change', handleFullscreenChange)
      fullscreen.removeListener('error', handleFullscreenError)
    }
  }, [])

  if (!fullscreen.isSupport) {
    return null
  }

  return (
    <button
      name="setScreenMode"
      type="button"
      className="btn btn-text btn-flat-secondary py-2 px-3"
      title={intl.t('fullScreen.title')}
      onClick={isFullscreen ?
        () => fullscreen.close() :
        () => fullscreen.open(document.documentElement)}
    >
      <Icon
        type="solid"
        name={isFullscreen ? 'faCompress' : 'faExpand'}
        size="lg"
        className="mr-1"
      />
      {label ? textLabel : null}
    </button>
  )
}
