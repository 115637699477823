import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { get, consts } from '@wiz/utils'
import {
  FormField,
  FormFieldInline,
  FormSelectNative,
  FormControl,
  FormAny,
  Icon,
  Dialog,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import TwinTypeList from '@/hoc/TwinTypeList'
import PhysicalTwin from '@/containers/PhysicalTwin'
import SelectLocation from '@/components/Form/SelectLocation'
import SelectIcon from '@/containers/Form/SelectIcon'
import SelectTwin from '@/components/Form/SelectTwin'
import SelectSensor from '@/components/Form/SelectSensor'

const SelectTypes = TwinTypeList(FormSelectNative)

export default function Fields ({
  scope,
  disabledFields,
}) {
  const intl = useIntl()
  const { formState: { errors }, watch, updateObsolete } = useFormContext()
  const [ id, type ] = watch([
    `${scope}.id`,
    `${scope}.type`,
  ])
  const [ openGraph, setOpenGraph ] = useState(false)

  return (
    <>
      <FormField
        label={intl.t('form.fields.type')}
        description={intl.t('twin.form.fields.typeDescr')}
        errors={get(errors, `${scope}.type`)}
      >
        <Controller
          name={`${scope}.type`}
          render={({ field, fieldState }) => (
            do {
              if (!!id || disabledFields?.includes(`${scope}.type`)) {
                <>
                  <FormAny {...field} />
                  <SelectTypes
                    value={field.value}
                    invalid={fieldState.invalid}
                    placeholder={intl.t('form.fields.typePlaceholder')}
                    withSensors={false}
                    disabled
                  />
                </>
              } else {
                <SelectTypes
                  {...field}
                  invalid={fieldState.invalid}
                  placeholder={intl.t('form.fields.typePlaceholder')}
                  withSensors={false}
                />
              }
            }
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('twin.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.hardwareId')}
        description={intl.t('twin.form.fields.hardwareIdDescr')}
        errors={get(errors, `${scope}.hardwareId`)}
      >
        <FormControl
          name={`${scope}.hardwareId`}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.description')}
        description={intl.t('twin.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.description`}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.color')}
        description={intl.t('twin.form.fields.colorDescr')}
        errors={get(errors, `${scope}.color`)}
      >
        <FormControl
          type="color"
          name={`${scope}.color`}
          clearable
        />
      </FormFieldInline>

      <FormField
        label={intl.t('form.fields.icon')}
        description={intl.t('twin.form.fields.iconDescr')}
        errors={get(errors, `${scope}.icon`)}
      >
        <Controller
          name={`${scope}.icon`}
          render={({ field, fieldState }) => (
            <SelectIcon
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.image')}
        description={intl.t('twin.form.fields.imageDescr')}
        errors={get(errors, `${scope}.image`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.image`}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.location')}
        description={intl.t('twin.form.fields.locationDescr')}
      >
        <SelectLocation scope={scope} />
      </FormField>

      <FormField
        label={intl.t('form.fields.latitudeSensor')}
        description={intl.t('twin.form.fields.latitudeSensorDescr')}
      >
        <Controller
          name={`${scope}.latitudeSensorId`}
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorPlaceholder')}
              filters={{ twinIds: id ? [ id ] : [] }}
              disabled={!id}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.longitudeSensor')}
        description={intl.t('twin.form.fields.longitudeSensorDescr')}
      >
        <Controller
          name={`${scope}.longitudeSensorId`}
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorPlaceholder')}
              filters={{ twinIds: id ? [ id ] : [] }}
              disabled={!id}
            />
          )}
        />
      </FormField>

      <FormField
        {...(type === consts.TwinType.Flow ? {
          label: intl.t('twin.form.fields.fromTwin'),
          description: intl.t('twin.form.fields.fromTwinDescr'),
        } : {
          label: intl.t('twin.form.fields.parent'),
          description: intl.t('twin.form.fields.parentDescr'),
        })}
        errors={get(errors, `${scope}.parentId`)}
      >
        <Controller
          name={`${scope}.parentId`}
          render={({ field, fieldState }) => (
            <SelectTwin
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.twinPlaceholder')}
              filters={{
                excludeFrom: id,
              }}
              // postfix={(
              //   <>
              //     <button
              //       type="button"
              //       className="btn btn-outline-secondary"
              //       onClick={() => setOpenGraph(true)}
              //     >
              //       <Icon name="fa--project-diagram" />
              //     </button>

              //     {openGraph ? (
              //       <Dialog
              //         classNameBody="p-0"
              //         title={intl.t('physicalTwin.titleSelect')}
              //         dataTestid="physicalTwinDialog"
              //         onBeforeClose={() => updateObsolete([ `${scope}.parentId` ])}
              //         onClose={() => setOpenGraph(false)}
              //       >
              //         <PhysicalTwin
              //           value={id}
              //           graphScrollTo={id}
              //         />
              //       </Dialog>
              //     ) : null}
              //   </>
              // )}
            />
          )}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('twin.form.fields.asset')}
        description={intl.t('twin.form.fields.assetDescr')}
        errors={get(errors, `${scope}.isAsset`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.isAsset`}
          className="ms-2"
        />
      </FormFieldInline>

      {type === consts.TwinType.Flow ? (
        <FormField
          label={intl.t('twin.form.fields.toTwin')}
          description={intl.t('twin.form.fields.toTwinDescr')}
          errors={get(errors, `${scope}.flowToId`)}
        >
          <Controller
            name={`${scope}.flowToId`}
            shouldUnregister
            render={({ field, fieldState }) => (
              <SelectTwin
                {...field}
                invalid={fieldState.invalid}
                placeholder={intl.t('form.fields.twinPlaceholder')}
                filters={{
                  excludeFrom: id,
                }}
              />
            )}
          />
        </FormField>
      ) : null}
    </>
  )
}
