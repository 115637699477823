import { FormSection } from '@wiz/components'
import FieldsMeasurements from './FieldsMeasurements'

export default function SectionMeasurements ({
  scope,
  qualityDataScope,
  title,
  description,
  addon,
}) {
  return (
    <FormSection
      title={title}
      description={description}
      addon={addon}
    >
      <FieldsMeasurements
        scope={scope}
        qualityDataScope={qualityDataScope}
      />
    </FormSection>
  )
}
