import { appEnv } from '@/config'

export const AUTH_OPTIONS = [
  {
    id: '0a51ffd8-4951-4ee7-bdd7-e5b763fd2fb6',
    title: 'Microsoft',
    authScheme: 'AzureAd',
    active: true,
    icon: 'windows',
  },
  {
    id: 'a45ced2c-186d-4c46-b13d-fe82860c105f',
    title: 'AWS Cognito',
    authScheme: 'AwsCognito',
    active: !!appEnv.COGNITO_AUTH,
    icon: 'aws-cognito',
  },
]
