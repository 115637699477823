import { useAuth } from '@/auth'
import Link from '@/components/Link'
import useAppContext from '../../hooks/useAppContext'

const CellCategory = ({ row }) => {
  const auth = useAuth()
  const { onFilter } = useAppContext()
  return (
    row?.original ? (
      <>
        {auth.checkAccessRead('SectionDigitalTwinCategories') ? (
          <Link
            name="categories-list"
            query={{ defaultSearch: row.original.categoryName, search: row.original.categoryName }}
            className="text-truncate"
            onClick={() => onFilter({ categories: row.original.categoryId })}
          >
            {row.original.categoryName}
          </Link>
        ) : (
          <span className="text-truncate">{row.original.categoryName}</span>
        )}
      </>
    ) : null)
}

export default CellCategory
