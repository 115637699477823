import { useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormSection, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import FieldsInterface from './FieldsInterface'

const SectionRatio = [ 9, 3 ]

export default function SectionInterface ({ scope }) {
  const refFields = useRef()
  const intl = useIntl()
  const { disabled } = useFormContext()

  return (
    <FormSection
      ratio={SectionRatio}
      title={intl.t('mlWebServices.form.fields.params')}
      description={intl.t('mlWebServices.form.fields.paramsDescr')}
      addon={() => (
        <button
          type="button"
          className="btn btn-sm btn-flat-secondary mt-2 mt-md-0 text-nowrap min-w-0"
          disabled={disabled}
          onClick={() => refFields.current.add()}
        >
          <Icon name="fa--plus" size="md" className="me-1" />
          {intl.t('form.actions.addParameter')}
        </button>
      )}
    >
      <FieldsInterface ref={refFields} scope={scope} />
    </FormSection>
  )
}
