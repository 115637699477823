import { type, intersection } from 'superstruct'
import { User } from '@wiz/store'
import * as types from '@/utils/types'

export default type({
  adId: intersection([
    types.Required(),
    User.schema.schema.adId,
  ]),
})
