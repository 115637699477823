import {
  useEffect,
  useState,
  useRef,
} from 'react'
import Plotly from 'plotly.js-dist-min'
import { Icon } from '@wiz/components'

const Config = {
  // staticPlot: true,
  // displayModeBar: true,
  displaylogo: false,
  // responsive: true,
  // modeBarButtonsToRemove: [ 'toImage' ],
}

export default function PlotlyComponent ({
  data,
  width,
  height,
  theme,
}) {
  const refTarget = useRef()
  const [ cache, setCache ] = useState(data)
  const [ customError, setCustomError ] = useState('')

  useEffect(() => {
    if (data) {
      setCache(data)
    }
  }, [ data ])

  useEffect(() => {
    if (cache?.data) {
      try {
        Plotly.react(refTarget.current, {
          data: cache.data,
          layout: {
            ...cache?.layout,
            width,
            mapbox: {
              ...cache?.layout?.mapbox,
              accesstoken: 'pk.eyJ1IjoiZGF1cmVuYmVremhhbm92IiwiYSI6ImNsNTlub2tpbTExOGszcG5yMTlmcG4xcGoifQ.Tf1x8q2I1DWLDu0HpgJhQQ',
            },
            height,
            paper_bgcolor: theme?.variables?.white || 'rgba(0,0,0,0)',
            plot_bgcolor: theme?.variables?.white || 'rgba(0,0,0,0)',
            font: { color: theme?.variables?.['body-color'] || 'rgb(255,255,255)' },
          },
          config: Config,
        })
      } catch (err) {
        setCustomError('Something went wrong. Please check console and contact us')
      }
    } else {
      Plotly.purge(refTarget.current)
    }
  }, [ cache, width, height, theme ])

  useEffect(() => {
    const target = refTarget.current
    return () => {
      Plotly.purge(target)
    }
  }, [])

  return (
    <div className="d-flex position-absolute-fill">
      <div
        ref={refTarget}
        className="position-absolute-fill"
        style={{
          // backgroundImage: `url(${image})`,
          // backgroundSize: 'contain',
          // backgroundRepeat: 'no-repeat',
          // backgroundPosition: 'center center',
        }}
      >
        {do {
          if (!cache) {
            <div className="position-absolute-fill position-center-fill">
              <Icon name="fa--spinner" size="2X" spin />
            </div>
          } else if (cache.error?.message || customError) {
            <div className="position-absolute-fill position-center-fill text-center text-danger">
              {cache.error?.message || customError}
            </div>
          }
        }}
      </div>
    </div>
  )
}
