import 'leaflet/dist/leaflet.css'
import { createRef, Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import classnames from 'classnames'
import leaflet from 'leaflet'
import difference from 'lodash/difference'
import intersection from 'lodash/intersection'
import {
  consts,
  debounce,
  isEqual,
  clamp,
} from '@wiz/utils'
import Marker from './Marker'
import classes from './Location.css'

function markerFactory (item, locations) {
  const lat = (item.latitudeSensorId && locations[item.latitudeSensorId]) ?? item.latitude
  const lng = (item.longitudeSensorId && locations[item.longitudeSensorId]) ?? item.longitude
  return leaflet.marker(new leaflet.LatLng(lat, lng), {
    title: item.name,
    draggable: false,
    keyboard: false,
    icon: leaflet.divIcon({
      className: classes.marker,
      iconSize: [ 6, 6 ],
      iconAnchor: [ 3, 3 ],
      labelAnchor: [ 0, 0 ],
      popupAnchor: [ 0, 0 ],
      tooltipAnchor: [ 3, 0 ],
      html: ReactDOMServer.renderToString(<Marker value={item} />),
    }),
  })
}

export default class Location extends Component {
  static getDerivedStateFromProps (props, state) {
    return {
      locations: {
        ...state?.locations,
        ...props.locations,
      },
    }
  }

  refTarget = createRef()

  state = Location.getDerivedStateFromProps(this.props)

  shouldComponentUpdate (nextProps) {
    const { locations, units, config } = this.props
    return (
      !isEqual(locations, nextProps.locations) ||
      !isEqual(units, nextProps.units) ||
      !isEqual(config, nextProps.config)
    )
  }

  componentDidMount () {
    const {
      config: {
        zoom,
        latitude,
        longitude,
        fixedLocation,
      },
      units,
    } = this.props
    const { locations } = this.state
    const hasCenter = !!(latitude || longitude)
    const coords = hasCenter ?
      new leaflet.LatLng(latitude, longitude) :
      new leaflet.LatLng(49.641002, 6.006849)

    const markers = {}
    const spaces = []
    const devices = []
    const sensors = []
    for (const id in units) {
      if (Object.hasOwnProperty.call(units, id)) {
        const item = units[id]
        markers[id] = markerFactory(item, locations)
        if (
          item.type === consts.TwinType.Area ||
          item.type === consts.TwinType.Machine
        ) {
          spaces.push(markers[id])
        } else if (item.type === consts.TwinType.Equipment) {
          devices.push(markers[id])
        } else {
          sensors.push(markers[id])
        }
      }
    }

    this.$markers = markers
    this.$spaces = leaflet.layerGroup(spaces)
    this.$devices = leaflet.layerGroup(devices)
    this.$sensors = leaflet.layerGroup(sensors)

    this.$map = leaflet.map(this.refTarget.current, {
      minZoom: 2,
      maxZoom: 18,
      zoom: clamp(zoom, 2, 18),
      center: coords,
      layers: [
        this.$spaces,
        this.$devices,
        this.$sensors,
      ],
      zoomControl: false,
      ...(fixedLocation ? {
        keyboard: false,
        boxZoom: false,
        doubleClickZoom: false,
        dragging: false,
        scrollWheelZoom: false,
        touchZoom: false,
      } : {
        keyboard: true,
        boxZoom: true,
        doubleClickZoom: true,
        dragging: true,
        scrollWheelZoom: true,
        touchZoom: true,
      }),
    })

    this.$map.on('zoomend', this.updateWidget)
    this.$map.on('moveend', this.updateWidget)
    this.$map.on('dblclick', () => {})
    this.$map.on('contextmenu', () => {})

    leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors',
      className: 'leaflet-tiles',
    }).addTo(this.$map)

    leaflet.control.layers(null, {
      'Areas/Machines': this.$spaces,
      Equipment: this.$devices,
      'Data Points': this.$sensors,
    }).addTo(this.$map)

    if (!fixedLocation) {
      this.$zoom = leaflet.control.zoom({
        position: 'topleft',
      }).addTo(this.$map)
    }

    if (!hasCenter) {
      window.setTimeout(() => {
        const marker = (
          this.$spaces.getLayers()[0] ||
          this.$devices.getLayers()[0] ||
          this.$sensors.getLayers()[0]
        )

        if (marker) {
          this.$map.setView(marker.getLatLng())
        }
      }, 0)
    }
  }

  componentWillUnmount () {
    this.updateWidget.cancel()
    this.$spaces.remove()
    this.$spaces = null
    this.$devices.remove()
    this.$devices = null
    this.$sensors.remove()
    this.$sensors = null
    this.$map.remove()
    this.$map = null
    this.$markers = null
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      dimension,
      units,
      config: {
        zoom,
        latitude,
        longitude,
        fixedLocation,
      },
    } = this.props
    const { locations } = this.state

    const prevKeys = Object.keys(prevProps.units)
    const nextKeys = Object.keys(units)
    const created = difference(nextKeys, prevKeys)
    const removed = difference(prevKeys, nextKeys)
    const updated = intersection(prevKeys, nextKeys)

    if (removed.length) {
      for (const id of removed) {
        this.$markers[id].remove()
        delete this.$markers[id]
      }
    }

    if (created.length) {
      for (const id of created) {
        const item = units[id]
        this.$markers[id] = markerFactory(item, locations)
        if (
          item.type === consts.TwinType.Area ||
          item.type === consts.TwinType.Machine
        ) {
          this.$markers[id].addTo(this.$spaces)
        } else if (item.type === consts.TwinType.Equipment) {
          this.$markers[id].addTo(this.$devices)
        } else {
          this.$markers[id].addTo(this.$sensors)
        }
      }
    }

    if (updated.length) {
      for (const id of updated) {
        const prevItem = prevProps.units[id]
        const item = units[id]
        if (!isEqual(prevItem, item)) {
          this.$markers[id].remove()
          this.$markers[id] = markerFactory(item, locations)
          if (
            item.type === consts.TwinType.Area ||
            item.type === consts.TwinType.Machine
          ) {
            this.$markers[id].addTo(this.$spaces)
          } else if (item.type === consts.TwinType.Equipment) {
            this.$markers[id].addTo(this.$devices)
          } else {
            this.$markers[id].addTo(this.$sensors)
          }
        }
      }
    }

    if (!isEqual(locations, prevState.locations)) {
      for (const id in this.$markers) {
        if (Object.hasOwnProperty.call(this.$markers, id)) {
          const item = units[id]
          if (
            item &&
            item.latitudeSensorId &&
            item.longitudeSensorId &&
            locations[item.latitudeSensorId] &&
            locations[item.longitudeSensorId]
          ) {
            this.$markers[id].setLatLng(new leaflet.LatLng(
              locations[item.latitudeSensorId],
              locations[item.longitudeSensorId],
            ))
          }
        }
      }
    }

    if (dimension !== prevProps.dimension) {
      this.$map.invalidateSize(true)
    }

    if (zoom !== prevProps.config.zoom) {
      this.$map.off('zoomend', this.updateWidget)
      this.$map.setZoom(clamp(zoom, 2, 18))
      this.$map.on('zoomend', this.updateWidget)
    }

    if (
      latitude !== prevProps.config.latitude ||
      longitude !== prevProps.config.longitude
    ) {
      this.$map.off('moveend', this.updateWidget)
      this.$map.setView(new leaflet.LatLng(latitude, longitude))
      this.$map.on('moveend', this.updateWidget)
    }

    if (fixedLocation !== prevProps.config.fixedLocation) {
      const next = fixedLocation ? {
        keyboard: false,
        boxZoom: false,
        doubleClickZoom: false,
        dragging: false,
        scrollWheelZoom: false,
        touchZoom: false,
      } : {
        keyboard: true,
        boxZoom: true,
        doubleClickZoom: true,
        dragging: true,
        scrollWheelZoom: true,
        touchZoom: true,
      }

      if (fixedLocation) {
        this.$zoom?.remove()
        this.$zoom = null
      } else {
        this.$zoom = leaflet.control.zoom({
          position: 'topleft',
        }).addTo(this.$map)
      }

      for (const name in next) {
        if (Object.hasOwnProperty.call(next, name)) {
          this.setMapOption(name, next[name])
        }
      }
    }
  }

  setMapOption (newMapOptionKey, newMapOptionVal) {
    leaflet.Util.setOptions(this.$map, { [newMapOptionKey]: newMapOptionVal })
    if (this.$map[newMapOptionKey] instanceof leaflet.Handler) {
      if (newMapOptionVal) {
        this.$map[newMapOptionKey].enable()
      } else {
        this.$map[newMapOptionKey].disable()
      }
    }
  }

  updateWidget = debounce(() => {
    const {
      onChangeLocation,
      config: {
        fixedLocation,
      },
    } = this.props

    if (!fixedLocation) {
      const zoom = this.$map.getZoom()
      const center = this.$map.getCenter()
      onChangeLocation?.({
        zoom,
        latitude: center.lat,
        longitude: center.lng,
      })
    }
  }, 300)

  render () {
    return (
      <div
        ref={this.refTarget}
        className={classnames(classes.root, 'position-absolute-fill')}
      />
    )
  }
}
