import { useCallback } from 'react'
import { withProps } from '@wiz/components'
import {
  dbProvider,
  DataSource,
  DataView,
  Condition,
} from '@wiz/store'

const enhanceProps = withProps(({ widget, config }) => {
  const onSubmit = useCallback(async (data) => {
    if (widget) {
      const dataViews = []
      const dataSources = []
      let conditions = []

      if (data.config.sensorId) {
        const dataSource = DataSource.toJSON(config.dataSources?.[0] || { dataType: 'last' })
        const dataView = DataView.toJSON(config.dataViews?.[0] || { sourceId: dataSource.id })

        dataSource.sensorId = data.config.sensorId
        dataView.sourceId = dataSource.id
        dataSources.push(dataSource)
        dataViews.push(dataView)

        conditions = data.config.bins.map((item, idx, items) => Condition.toJSON({
          id: item.id,
          name: item.name,
          color: item.color,
          type: 'between',
          payload: {
            between: {
              from: items[idx - 1]?.value ?? data.config.minInterval,
              to: idx === items.length - 1 ? data.config.maxInterval : item.value,
            },
          },
          inputDataSources: [
            DataSource.toJSON({
              dataType: 'last',
              sensorId: data.config.sensorId,
            }),
          ],
          outputDataSources: [],
        }))
      }

      const context = dbProvider.createBatchContext()
      await widget.prepareUpdateData(context, {
        ...data.widget,
        config: {
          precision: data.config.precision,
          minInterval: data.config.minInterval,
          maxInterval: data.config.maxInterval,
        },
      })
      await widget.prepareReplaceDataViews(context, dataViews, dataSources)
      await widget.prepareReplaceConditions(context, conditions)
      await dbProvider.batch(context)
    }
  }, [ widget, config ])

  return {
    onSubmit,
  }
})

export default function enhanceSettingsSensor (Component) {
  return enhanceProps(Component)
}
