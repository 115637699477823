import { Controller, useFormContext } from 'react-hook-form'
import { FormField } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import Select from '@/components/Form/Select'
import GroupAccessRoleList from '@/hoc/GroupAccessRoleList'

export default function FieldsRoles ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <FormField
      label={intl.t('users.form.fields.roles')}
      description={intl.t('users.form.fields.rolesDescr')}
      errors={get(errors, scope)}
    >
      <Controller
        name={scope}
        render={({ field, fieldState }) => (
          <Select
            {...field}
            invalid={fieldState.invalid}
            multiselect
            ListSource={GroupAccessRoleList}
            placeholder={intl.t('users.form.fields.rolesPlaceholder')}
          />
        )}
      />
    </FormField>
  )
}
