import { DateTime, Duration } from 'luxon'
import { of as of$ } from 'rxjs'
import { map, distinctUntilChanged } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { Q, dbProvider, Job } from '@wiz/store'
import { isEqual, consts } from '@wiz/utils'

function modelToFields (model = { command: consts.JobCommand.FileExport }) {
  const data = Job.toJSON(model)

  return {
    ...data,
    parameters: {
      ...data.parameters,
      SensorsAggregates: Object.keys(data.parameters.SensorsAggregates)
        .reduce((out, sensorId) => {
          const dataTypes = data.parameters.SensorsAggregates[sensorId].split(',')
          return out.concat(dataTypes.map(dataType => ({ dataType, sensorId })))
        }, []),
      From: data.parameters.From ?
        DateTime.fromISO(data.parameters.From, { zone: 'utc' }).toMillis() : undefined,
      To: data.parameters.To ?
        DateTime.fromISO(data.parameters.To, { zone: 'utc' }).toMillis() : undefined,
      Interval: data.parameters.Interval ?
        Duration.fromISO(data.parameters.Interval).toMillis() : undefined,
    },
  }
}

export default withObservables([
  'id',
  'sensorIds',
  'params',
], ({
  id,
  sensorIds,
  params,
}) => {
  let bgJob

  if (id) {
    bgJob = dbProvider.database.collections.get('jobs')
      .query(Q.where('id', id))
      .observeWithColumns([ 'updated_at' ])
      .pipe(map(items => modelToFields(items[0])))
  } else {
    bgJob = dbProvider.database.collections.get('sensors')
      .query(Q.where('id', Q.oneOf(sensorIds || [])))
      .observeWithColumns([ 'updated_at' ])
      .pipe(
        map(items => modelToFields({
          ...params,
          command: consts.JobCommand.FileExport,
          parameters: {
            ...params?.parameters,
            ...(items.length ? { SensorIds: items.map(item => item.id) } : {}),
          },
        })),
        distinctUntilChanged(isEqual),
      )
  }

  return {
    bgJob,

    projects: id ? (
      dbProvider.database.collections.get('rel_projects_jobs')
        .query(Q.where('job_id', id))
        .observeWithColumns([ 'updated_at' ])
        .pipe(map(items => items.map(item => item.projectId)))
    ) : (
      of$([])
        .pipe(distinctUntilChanged(isEqual))
    ),
  }
})
