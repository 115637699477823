import { map } from 'rxjs/operators'
import { of } from 'rxjs'
import { Icon, withObservables } from '@wiz/components'
import Link from '@/components/Link'
import { useIntl } from '@wiz/intl'
import { dbProvider, Q } from '@wiz/store'

const enhanceProps = withObservables([ 'twinIds' ], ({ twinIds }) => {
  if (!twinIds?.length) {
    return {
      twin: of(undefined),
    }
  }

  const query = dbProvider.database.collections.get('twins').query(Q.where('id', Q.oneOf(twinIds)))

  return {
    twins: query.observeWithColumns([ 'updated_at' ]),
  }
})

function CellTwin ({
  onFilter,
  twins,
}) {
  const intl = useIntl()

  if (!twins) {
    return <>-</>
  }
  return (
    <div className="min-w-0 d-flex">
      {twins.map((twin, idx) => (
        <div className="text-truncate" key={twin.id}>
          <Link
            key={twin.id}
            className="min-w-0 text-truncate"
            title={twin.name}
            name="twin-items-list"
            query={{ tree: twin?.id }}
          >
            {twin.name}
          </Link>
          {twins[idx + 1] ? ', ' : ''}
        </div>
      ))}

      {/* {onFilter ? (
        <button
          type="button"
          className="btn btn-sm p-1"
          title={intl.t('form.actions.search')}
          onClick={() => onFilter({ twinId: twin.id })}
        >
          <Icon name="fa--search" />
        </button>
      ) : null} */}
    </div>
  )
}

export default enhanceProps(CellTwin)
