import { createRef, Component } from 'react'
import {
  isEqual,
  getDateFrom,
  getDateTo,
  OnlineTimeout,
  tooltipFormatter,
  echarts,
} from '@wiz/utils'
import { ServiceChannel } from '@wiz/api'
import { wizataApi } from '@/api'
import { Icon } from '@wiz/components'
import classes from './index.css'

const AngleAxisLabels = [
  'N', 'NNE', 'NE', 'ENE',
  'E', 'ESE', 'SE', 'SSE',
  'S', 'SSW', 'SW', 'WSW',
  'W', 'WNW', 'NW', 'NNW', 'N',
]

const DirectionAngles = [
  360, 22.5, 45, 67.5,
  90, 112.5, 135, 157.5,
  180, 202.5, 225, 247.5,
  270, 292.5, 315, 337.5, 0,
]

export default class RoseWind extends Component {
  refTarget = createRef()

  state = {
    loading: false,
  }

  componentDidMount () {
    this.$channel = new ServiceChannel(this.handleServiceChannelSendRequest)
    this.$channel.on('message', this.handleSensorDataMessage)
    this.$channel.on('error', this.handleSensorDataError)
    this.resetPlot()
    this.uploadData()
  }

  componentWillUnmount () {
    this.realtimeUpdateStop()
    this.$fetchRequest?.abort()
    this.$fetchRequest = null
    this.$channel.close()
    this.$channel = null
    this.$plot.dispose()
  }

  componentDidUpdate (prevProps) {
    if (this.props.theme !== prevProps.theme) {
      this.resetPlot()
    }

    if (!isEqual(this.props.config, prevProps.config)) {
      this.uploadData()
    }

    if (this.props.hidden !== prevProps.hidden) {
      this.realtimeUpdateRestart()
    }

    if (this.props.dimension !== prevProps.dimension) {
      this.$plot?.resize()
    }
  }

  realtimeUpdateRestart () {
    this.realtimeUpdateStop()
    if (this.props.config?.stepRequest && !this.props.hidden) {
      this.$realtimeTimer = new OnlineTimeout(
        this.uploadData,
        this.props.config.stepRequest,
      )
    }

    this.setState({ loading: false })
  }

  realtimeUpdateStop () {
    if (this.$realtimeTimer) {
      this.$realtimeTimer.cancel()
      this.$realtimeTimer = null
    }
  }

  resetPlot () {
    const properties = this.getProperties()
    properties.legend.data = this.getLegendData()
    properties.series = this.getSeries()
    properties.dataset = this.$plot?.getOption()?.dataset ?? []

    this.$plot?.dispose()
    this.$plot = echarts.init(this.refTarget.current, this.props.theme)
    this.$plot.setOption(properties, {
      notMerge: true,
      lazyUpdate: true,
      silent: true,
    })
  }

  getSerieName (item, index) {
    let name
    if (item.name) {
      name = item.name
    } else if (index === this.props.config.bins.length - 1) {
      name = `> ${this.props.config.bins[index - 1]?.value || 0}`
    } else if (index === 0) {
      name = `< ${item.value}`
    } else {
      name = `${this.props.config.bins[index - 1].value} - ${item.value}`
    }
    return name
  }

  getSeries () {
    return this.props.config.bins
      .map((item, index) => ({
        id: String(item.value || 10000),
        name: this.getSerieName(item, index),
        type: 'bar',
        coordinateSystem: 'polar',
        datasetIndex: index,
        encode: { angle: 'dir', radius: 'speed' },
        stack: 'sum',
        z: 0,
        itemStyle: { color: item.color },
      }))
  }

  getLegendData () {
    return this.getSeries().map(item => item.name)
  }

  uploadData = () => {
    const dateFrom = getDateFrom(this.props.config, true)
    const dateTo = getDateTo(this.props.config, true)

    this.$request = {
      uid: Math.random(),
      windDirectionSensorId: this.props.config.windDirectionSensorId,
      windSpeedSensorId: this.props.config.windSpeedSensorId,
      windSpeedBins: this.props.config.bins
        .filter(item => Number(item.value || 0) > 0)
        .map(item => item.value)
        .sort(),
      dateFrom,
      dateTo,
    }

    this.realtimeUpdateStop()
    this.$channel.postRequest(this.$request)
  }

  handleServiceChannelSendRequest = (request) => {
    this.setState({ loading: true })
    this.$fetchRequest = wizataApi.getRoseWindData(request)
    return this.$fetchRequest.fetch()
  }

  handleSensorDataMessage = (response) => {
    if (response.request.uid !== this.$request.uid) {
      return
    }

    this.realtimeUpdateRestart()

    const series = this.getSeries()
    const dataset = series
      .map((item) => {
        const sourceHash = response.data.filter(i => i[0] === item.id).reduce((out, i) => {
          out[Number(i[1])] = Number(i[2])
          return out
        }, {})

        return {
          id: item.id,
          source: DirectionAngles.map(angle => ([ angle, sourceHash[angle] || 0 ])),
          dimensions: [
            { name: 'dir', type: 'ordinal' },
            { name: 'speed', type: 'number' },
          ],
          sourceHeader: false,
        }
      })

    const data = this.getProperties()
    data.legend.data = this.getLegendData()
    data.series = series
    data.dataset = dataset

    this.$plot.setOption(data, {
      notMerge: true,
      lazyUpdate: true,
      silent: true,
    })
  }

  handleSensorDataError = (error) => {
    this.realtimeUpdateRestart()
    console.error(error)
  }

  // eslint-disable-next-line class-methods-use-this
  getProperties () {
    const properties = {
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          const value = params[0].axisValue
          return `
            <div>
              ${AngleAxisLabels[DirectionAngles.indexOf(value)]} (${value})
              ${tooltipFormatter()(params)}
            </div>
          `
        },
      },
      polar: {
        radius: '85%',
      },
      legend: {
        show: true,
        data: [],
        orient: 'vertical',
        align: 'left',
        right: 0,
      },
      angleAxis: {
        type: 'value',
        interval: 22.5,
        max: 360,
        min: 0,
        axisLabel: {
          formatter: value => AngleAxisLabels[DirectionAngles.indexOf(value)],
        },
        axisLine: {
          lineStyle: {
            opacity: 0.3,
          },
        },
        splitLine: {
          lineStyle: {
            opacity: 0.3,
          },
        },
        z: 1,
      },
      radiusAxis: {
        axisLabel: {
          // ...(this.theme.name === 'sun' ? {
          //   backgroundColor: 'rgba(255, 255, 255, 0.7)',
          //   // shadowColor: 'rgba(0,0,0,0.3)',
          //   // shadowBlur: 3,
          // } : {
          //   backgroundColor: 'rgba(96, 98, 102, 0.7)',
          // }),
          borderRadius: 4,
          padding: [ 2, 6, 1, 6 ],
          formatter: '{value}', //  {a|%}
          rich: {
            a: { fontSize: 10 },
          },
        },
        axisLine: {
          lineStyle: {
            opacity: 1,
          },
        },
        splitLine: {
          lineStyle: {
            opacity: 0.3,
          },
        },
        z: 1,
      },
    }

    return properties
  }

  render () {
    const { loading } = this.state

    return (
      <div className="flex-fill position-relative">
        <div
          ref={this.refTarget}
          className="position-absolute-fill"
        />
        {loading ? <Icon className={classes.loading} name="fa--spinner" spin /> : null}
      </div>
    )
  }
}
