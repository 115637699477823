import { useFormContext, Controller } from 'react-hook-form'
import { useCallback } from 'react'
import { FormAny } from '@wiz/components'
import { Dashboard } from '@wiz/store'
import { uuid, get, has } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { RIGHTS } from '@/config'
import { useAuth } from '@/auth'
import ListDashboardsWidgets from '@/components/Form/ListDashboardsWidgets'

export default function Section () {
  const intl = useIntl()
  const auth = useAuth()
  const { watch, setValue, formState: { errors } } = useFormContext()
  const dashboards = watch('dashboards')
  const newDashboards = watch('newDashboards')

  const handleChangeDashboard = useCallback((id) => {
    const next = dashboards.includes(id) ?
      dashboards.filter(item => item !== id) :
      dashboards.concat(id)
    setValue('dashboards', next, { shouldDirty: true })
  }, [ dashboards, setValue ])

  const handleAddDashboard = useCallback(() => {
    const next = newDashboards.concat(Dashboard.toJSON({
      id: uuid(),
      title: '',
    }))
    setValue('newDashboards', next, { shouldDirty: true })
  }, [ newDashboards, setValue ])

  const handleRemoveDashboard = useCallback((id) => {
    const next = newDashboards.filter(item => item.id !== id)
    setValue('newDashboards', next, { shouldDirty: true })
    setValue('dashboards', dashboards.filter(item => item !== id), { shouldDirty: true })
  }, [ dashboards, newDashboards, setValue ])

  const handleUpdateTitleDashboard = useCallback((id, title) => {
    const next = newDashboards.map(item => (
      item.id === id ? { ...item, title: String(title).trim() } : item
    ))
    setValue('newDashboards', next, { shouldDirty: true })
  }, [ newDashboards, setValue ])

  return (
    <>
      <Controller name="dashboards" render={({ field }) => <FormAny {...field} />} />
      <Controller name="newDashboards" render={({ field }) => <FormAny {...field} />} />

      {auth.checkAccessCreate('Dashboard') ? (
        <div className="my-3">
          <button
            type="button"
            className="btn btn-fill-secondary"
            onClick={handleAddDashboard}
            disabled={newDashboards.length > 2}
          >
            {intl.t('dashboards.form.actions.new')}
          </button>
        </div>
      ) : null}

      {newDashboards.map((item, idx) => (
        <div key={item.id} className="mx-3 mb-2">
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              className="form-check-input mt-0 me-2 flex-shrink-0"
              checked={dashboards.includes(item.id)}
              onChange={() => handleChangeDashboard(item.id)}
            />
            <input
              type="text"
              className="form-control form-control-sm me-2"
              placeholder={intl.t('dashboards.form.fields.titlePlaceholder')}
              defaultValue={item.title}
              onBlur={event => handleUpdateTitleDashboard(item.id, event.target.value)}
            />
            <button
              type="button"
              className="btn btn-sm btn-flat-secondary"
              onClick={() => handleRemoveDashboard(item.id)}
            >
              {intl.t('form.actions.remove')}
            </button>
          </div>
          {has(errors, `newDashboards.${idx}.title`) ? (
            <div className="small text-danger">
              {get(errors, `newDashboards.${idx}.title.message`)}
            </div>
          ) : null}
        </div>
      ))}

      <ListDashboardsWidgets
        className="d-flex flex-fill min-h-0"
        access={RIGHTS.UPDATE}
        value={dashboards}
        onChange={handleChangeDashboard}
      />
    </>
  )
}
