import { DateTime } from 'luxon'
import { consts } from '@wiz/utils'

export default function formatter ({
  date,
  dateFormat,
  settings = {},
  displayDate = true,
  displayTime = true,
  withMillis = false,
  withSecond = false,
  utcTime = false,
}) {
  let dateTime
  if (date instanceof DateTime) {
    dateTime = date
  } else if (typeof date === 'number') {
    dateTime = DateTime.fromMillis(date)
  } else if (date instanceof Date) {
    dateTime = DateTime.fromJSDate(date)
  }

  if (utcTime) {
    dateTime = dateTime.setZone('utc')
  }

  if (dateFormat) {
    return dateTime.toFormat(dateFormat)
  }

  let timeFormat = settings.PlatformTimeFormat || DateTime.TIME_SIMPLE

  if (withSecond) {
    if (settings.PlatformTimeFormat) {
      timeFormat = settings.PlatformTimeFormat.indexOf('Short12') !== -1 ? 'Short12Sec' : 'ShortSec'
    } else {
      timeFormat = DateTime.TIME_WITH_SECONDS
    }
  }

  if (withMillis) {
    if (settings.PlatformTimeFormat) {
      timeFormat = settings.PlatformTimeFormat.indexOf('Short12') !== -1 ? 'Short12Msec' : 'ShortMsec'
    } else {
      timeFormat = consts.TIME_WITH_MILLISECONDS
    }
  }

  const timeStr = displayTime && (
    settings.PlatformTimeFormat ?
      dateTime.toFormat(consts.PLATFORM_TIME_FORMAT[timeFormat]) :
      dateTime.toLocaleString(timeFormat)
  )

  const dateStr = displayDate && (
    settings.PlatformDateFormat ?
      dateTime.toFormat(consts.PLATFORM_DATE_FORMAT[settings.PlatformDateFormat]) :
      dateTime.toLocaleString(DateTime.DATE_MED)
  )

  if (
    settings.PlatformTimeFormat ||
    settings.PlatformDateFormat
  ) {
    return [ dateStr, timeStr ].filter(Boolean).join(', ')
  }

  let format
  if (displayTime && displayDate) {
    format = DateTime.DATETIME_MED
    if (withSecond) {
      format = DateTime.DATETIME_MED_WITH_SECONDS
    }
    if (withMillis) {
      format = consts.DATETIME_MED_WITH_MILLISECONDS
    }
  } else if (displayTime) {
    format = DateTime.TIME_SIMPLE
    if (withSecond) {
      format = DateTime.TIME_WITH_SECONDS
    }
    if (withMillis) {
      format = consts.TIME_WITH_MILLISECONDS
    }
  } else {
    format = DateTime.DATE_MED
  }

  return dateTime.toLocaleString(format)
}
