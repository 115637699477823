import { useFormContext } from 'react-hook-form'
import {
  FormField,
  FormFieldInline,
  FormControl,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function Fields ({
  scope,
  canUpdateGlobalShare,
}) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('form.fields.title')}
        errors={get(errors, `${scope}.title`)}
      >
        <FormControl
          name={`${scope}.title`}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (!str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value.length > 100) {
                return intl.t('form.errors.fieldMaxlen', { max: 100 })
              }
              return true
            },
          }}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('explorer.form.fields.globalShare')}
        errors={get(errors, `${scope}.isGlobalShared`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.isGlobalShared`}
          className="ms-2"
          disabled={!canUpdateGlobalShare}
        />
      </FormFieldInline>
    </>
  )
}
