import { useCallback, useContext } from 'react'
import Icon from '@/shared/icon'
import { useIntl } from '@wiz/intl'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import events from '@/utils/events'
import { AppContext } from '@/pages/context'
import { wizataApi } from '@/api'
import { useRouter } from '@/router'

const PipelineActions = ({ onAction, id }) => {
  const intl = useIntl()
  const router = useRouter()
  const queryClient = useQueryClient()

  const {
    selectedIds,
  } = useContext(AppContext)

  const {
    mutate: deletePipeline,
  } = useMutation({
    mutationKey: [ 'deletePipeline', id ],
    mutationFn: pipelineId => wizataApi.pipelines.deleteById(pipelineId),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/pipelines.deleteError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'pipelines' ] })
    },
  })

  const handleRemove = useCallback(async () => {
    await window.wizConfirm({ message: 't/units.confirmDelete' })
    await deletePipeline(id || selectedIds[0])
    if (id) {
      router.push({ name: 'pipelines-list' })
    }
  }, [ deletePipeline, id, selectedIds, router ])

  return (
    <>
      {/* <button
        type="button"
        className="btn btn-fill-secondary-alt btn-text me-2"
        onClick={() => handleRemove()}
        disabled={selectedIds?.length !== 1}
      >
        <Icon name="faTrash" className="me-2" />
        {intl.t('form.actions.remove')}
      </button> */}
      {id ? (
        <button
          type="button"
          className="btn btn-fill-secondary-alt btn-text me-2"
          onClick={onAction}
        >
          <Icon name="faEye" className="me-2" />
          {intl.t('form.actions.view')}
        </button>
      ) : null}
    </>
  )
}

export default PipelineActions
