import { useMemo } from 'react'
import { Table as StaticTable, Pagination } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import Observe from '@/containers/Observe'
import UpdatedInfo from '@/components/Form/UpdatedInfo'

function SelectionWrapComponent ({ row, children }) {
  const intl = useIntl()
  return (
    <Observe observe={row.original.observeActiveUsage}>
      {({ data }) => (data ? (
        <input
          className="form-check-input mt-0"
          type="checkbox"
          title={data ? intl.t('mlWebServices.disableActiveUsage') : undefined}
          disabled
        />
      ) : children)}
    </Observe>
  )
}

export default function Table ({
  items,
  onRemove,
  onEdit,
  onDuplicate,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      width: NaN,
      Cell: ({ cell, row }) => (
        <div className="min-w-0">
          <a
            className="text-truncate pointer"
            onClick={() => auth.checkAccessUpdate(row.original) && onEdit?.(row.original.id)}
            aria-hidden
          >
            { cell.value || (
              <span className="text-muted">{intl.t('form.noname')}</span>
            )}
          </a>

          {row.original.description ? (
            <div className="font-italic text-muted text-truncate small">
              {row.original.description}
            </div>
          ) : null}
        </div>
      ),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedAt',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: ({ cell, row }) => (<UpdatedInfo date={cell.value} user={row.original.updatedUser} />),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdAt',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: ({ cell, row }) => (<UpdatedInfo date={cell.value} user={row.original.createdUser} />),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 150,
      width: 150,
      maxWidth: 150,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell, row }) => (
        <Observe observe={row.original.observeActiveUsage}>
          {({ data }) => (
            <>
              {auth.checkAccessUpdate(row.original) ? (
                <button
                  type="button"
                  className="btn btn-sm p-0 me-2"
                  onClick={() => onEdit?.(cell.value)}
                >
                  {intl.t('form.actions.edit')}
                </button>
              ) : null}
              {auth.checkAccessCopy(row.original) ? (
                <button
                  type="button"
                  className="btn btn-sm p-0 me-2"
                  onClick={() => onDuplicate?.(row.original)}
                >
                  {intl.t('form.actions.duplicate')}
                </button>
              ) : null}
              {auth.checkAccessRemove(row.original) ? (
                <button
                  type="button"
                  className="btn btn-sm p-0"
                  disabled={data}
                  title={data ? intl.t('mlWebServices.disableActiveUsage') : undefined}
                  onClick={() => onRemove?.([ row.original ])}
                >
                  {intl.t('form.actions.remove')}
                </button>
              ) : null}
            </>
          )}
        </Observe>
      ),
    },
  ], [ intl, onEdit, onRemove, onDuplicate, auth ])

  return (
    <>
      <StaticTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={auth.checkAccessRemove('MlWebServiceNg')}
        SelectionWrapComponent={SelectionWrapComponent}
        {...props}
      />

      <Pagination {...props} />
    </>
  )
}
