import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { FormField, FormControl } from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

const FieldFactor = ({ scope }) => {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()
  return (
    <FormField
      label={intl.t('widgets.histogram.form.fields.factor')}
      description={intl.t('widgets.histogram.form.fields.factorDescr')}
      errors={get(errors, `${scope}.factor`)}
    >
      <FormControl
        type="number"
        name={`${scope}.factor`}
        scale={6}
        clearable
        placeholder={intl.t('form.fields.placeholderDefaultValue', {
          value: consts.WidgetHistogramFactor,
        })}
      />
    </FormField>
  )
}

FieldFactor.propTypes = {
  scope: PropTypes.string.isRequired,
}

export default FieldFactor
