import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import { FormField } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import Selectable from '@/components/Form/Selectable'
import Select from '@/components/Form/Select'
import GroupAccessRoleList from '@/hoc/GroupAccessRoleList'

export default function FieldsRoles ({ scope }) {
  const intl = useIntl()
  const auth = useAuth()
  const { formState: { errors } } = useFormContext()

  return auth.checkAccessRead('AccessRole') ? (
    <FormField
      complex
      label={intl.t('users.form.fields.roles')}
      description={intl.t('users.form.fields.rolesDescr')}
      errors={get(errors, scope)}
      selectable={() => <Selectable value="roles" />}
    >
      <Controller
        name={scope}
        render={({ field }) => (
          <Select
            {...field}
            multiselect
            ListSource={GroupAccessRoleList}
            placeholder={intl.t('users.form.fields.rolesPlaceholder')}
          />
        )}
      />
    </FormField>
  ) : null
}

FieldsRoles.propTypes = {
  scope: PropTypes.string.isRequired,
}

FieldsRoles.defaultProps = {}
