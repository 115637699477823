import { useCallback, memo } from 'react'
import { dbProvider, Category } from '@wiz/store'
import { withProps } from '@wiz/components'
import Component from '@/components/Forms/GroupSystem'
import enhanceGroupSystem from './enhanceGroupSystem'

const enhanceProps = withProps(() => {
  const onSubmit = (data) => {
    console.log('data', data)
    // const context = dbProvider.createBatchContext()
    // await dbProvider.prepareReplaceData(context, Category, data.category)
    // await dbProvider.batch(context)
  }

  return {
    onSubmit,
  }
})

export default memo(
  enhanceGroupSystem(
    enhanceProps(Component),
  ),
)
