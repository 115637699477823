import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { withObservables } from '@wiz/components'

export default withObservables([
  'explorer',
  'defaultValues',
], ({
  explorer,
  defaultValues,
}) => ({
  permissions: explorer?.queryRelShareUsers ? (
    explorer.queryRelShareUsers
      .observeWithColumns([ 'updated_at' ])
  ) : of$(defaultValues?.permissions ?? []),

  projects: explorer?.queryProjects ? (
    explorer.queryProjects
      .observe()
      .pipe(
        map(items => items.map(item => item.projectId)),
      )
  ) : of$(defaultValues?.projects ?? []),

  twins: explorer?.queryRelTwins ? (
    explorer.queryRelTwins
      .observe()
      .pipe(
        map(items => items.map(item => item.twinId)),
      )
  ) : of$(defaultValues?.twins ?? []),
}))
