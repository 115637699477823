import { map } from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'
import Component from '@/components/LeftBarDigitalTwin'

const enhanceData = withObservables([ 'blockIds' ], ({ blockIds }) => {
  const blocksCollection = dbProvider.database.collections.get('twins')
  return {
    // [ [block model], ... ]
    blocks: blocksCollection
      .query(Q.where('id', Q.oneOf(blockIds)))
      .observeWithColumns([ 'updated_at' ]),

    // { [block model uuid]: [setting model], ... }
    blockSettings: blocksCollection
      .query(Q.where('id', Q.oneOf(blockIds)))
      .observeWithColumns([ 'updated_at' ])
      .pipe(
        map(items => items.reduce((out, item) => ({
          ...out,
          [item.id]: item,
        }), {})),
      ),
  }
})

export default enhanceData(Component)
