import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { combineLatest, of as of$ } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

export default withObservables([ 'blockIds' ], ({
  blockIds,
}) => ({
  widgetSensorIds: blockIds?.length ? dbProvider.database.collections.get('diagram_blocks')
    .query(
      Q.where('id', Q.oneOf(blockIds)),
      Q.where('type', Q.oneOf([
        'widget',
      ])),
    )
    .observe()
    .pipe(
      switchMap(items => (
        items.length ? (
          combineLatest(items.map(item => item.observeDiagramContext))
            .pipe(
              map(settings => settings.map(setting => (setting.sensorId))),
            )
        ) : of$([])
      )),
    ) : of$([]),
}))
