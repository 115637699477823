import { useCallback } from 'react'
import { withProps } from '@wiz/components'
import { dbProvider, DataFilter } from '@wiz/store'

const enhanceProps = withProps(({ widget }) => {
  const onSubmit = useCallback(async (data) => {
    if (widget) {
      const {
        dataFilter,
        dataViews,
        dataSources,
        ...config
      } = data.config

      const context = dbProvider.createBatchContext()
      await widget.prepareUpdateData(context, {
        ...data.widget,
        config,
      })
      await widget.prepareReplaceFilter(context, DataFilter.toJSON(dataFilter))
      await widget.prepareReplaceDataViews(context, dataViews, dataSources)
      await dbProvider.batch(context)
    }
  }, [ widget ])

  return {
    onSubmit,
  }
})

export default function enhanceSettingsHistogram (Component) {
  return enhanceProps(Component)
}
