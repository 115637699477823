import cx from 'classnames'
import camelCase from 'lodash/camelCase'
import {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Dropdown,
  withObservables,
  Icon as OldIcon,
} from '@wiz/components'
import Link from '@/components/Link'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import TwinSelectorTree from '@/hoc/TwinSelectorTree'
import { dbProvider } from '@wiz/store'
import Icon from '@/shared/icon'
import { useLocationQuery, useRouter } from '@/router'
import TwinList from './TwinList'
import s from './index.module.css'

const enhanceSettings = withObservables([], () => ({
  settings: dbProvider.observeGlobalSettings([ 'EnvironmentName' ]),
}))

const twinProps = {
  id: '',
  name: '',
  icon: null,
  prevId: '',
  nextId: '',
  parentId: '',
}

const LeftBar = forwardRef(({
  onClick,
  refDropdown,
  ...props
}, ref) => (
  <Dropdown
    ref={refDropdown}
    arrow
    autoclose
    target={ref}
    width={400}
    placement="bottom"
    className={cx(s.dialog, 'p-3 text-break')}
  >
    <TwinList
      onClick={onClick}
      refDropdown={refDropdown}
      {...props}
    />
  </Dropdown>
))

const TwinSelector = ({
  handleTwinChoose, classNameSpan, loading, settings, options, twinsChain, ...props
}) => {
  const [ rootTwin, setRootTwin ] = useState(twinProps)
  const [ currentTwin, setCurrentTwin ] = useState({
    ...twinProps,
    name: settings.EnvironmentName,
  })

  const {
    contextTwinId, handleTwinChange, isOpenTwinSelector, setIsOpenTwinSelector,
  } = useGlobalExecute()

  const ref = useRef()
  const refDropdown = useRef()

  const router = useRouter()
  const query = useLocationQuery()

  const handlePrevClick = useCallback(() => {
    handleTwinChange(currentTwin?.prevId)
    router.push({ query: { ...query, tree: currentTwin?.prevId } })
  }, [ router, currentTwin?.prevId, handleTwinChange, query ])

  const handleNextClick = useCallback(() => {
    handleTwinChange(currentTwin?.nextId)
    router.push({ query: { ...query, tree: currentTwin?.nextId } })
  }, [ router, currentTwin?.nextId, handleTwinChange, query ])

  const handleRootClick = useCallback(() => {
    handleTwinChange(currentTwin?.parentId)
    router.push({ query: { ...query, tree: currentTwin?.parentId } })
  }, [ router, currentTwin?.parentId, handleTwinChange, query ])

  const handleClick = useCallback((data) => {
    setCurrentTwin({
      ...currentTwin,
      id: data.recordId,
      name: data.name,
      icon: data.payload?.icon,
      prevId: data?.prevId,
      nextId: data?.nextId,
      parentId: data.payload?.parentId,
    })
    handleTwinChange(data.recordId)
    router.push({ query: { ...query, tree: data.recordId } })

    refDropdown.current?.close()
    setIsOpenTwinSelector(false)
  }, [ handleTwinChange, router, currentTwin, setIsOpenTwinSelector, query ])

  const findCurrentTwin = (data, contextId) => {
    if (!Array.isArray(data) || data.length === 0) return

    function findElement (elems) {
      for (let i = 0; i < elems?.length; i++) {
        if (contextId && elems[i].id === contextId) {
          setCurrentTwin({ ...elems[i], icon: elems[i].payload?.icon })
        } else {
          findElement(elems[i].children)
        }
      }
    }
    findElement(data)
  }

  const initTwins = () => {
    if (contextTwinId) {
      if (!currentTwin?.parentId) {
        setRootTwin(twinProps)
        return
      }
      if (twinsChain.length > 0) {
        const startRootTwin = twinsChain.find(twin => twin.id === currentTwin?.parentId)
        setRootTwin(startRootTwin)
      }
    }
  }

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpenTwinSelector(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ ref ])

  useEffect(() => {
    if (isOpenTwinSelector) {
      refDropdown.current?.open()
    }
  }, [ isOpenTwinSelector ])

  useEffect(() => {
    if (contextTwinId) {
      findCurrentTwin(options, contextTwinId)
    }
    initTwins()
  }, [ contextTwinId, currentTwin?.parentId, options ])

  return (
    <div className={cx(
      s.twinControlsBar,
      { [s.twinControlsBarSelected]: contextTwinId },
    )}
    >
      {contextTwinId ? (
        <button
          type="button"
          className={cx(s.rootBtn, 'btn btn-flat-secondary text-truncate')}
          style={{ fontSize: '12px', minWidth: '123px', maxWidth: '123px' }}
          onClick={handleRootClick}
        >
          <span
            className={cx('text-truncate')}
            style={{ maxWidth: '93px' }}
          >
            {rootTwin?.name || settings.EnvironmentName}
          </span>
          <Icon className={cx(s.rootBtnIcon)} name="faAnglesUp" size="sm" />
        </button>
      ) : null}
      {contextTwinId ? (
        <>
          {currentTwin?.prevId ? (
            <Link
              name="controlpanel"
              query={{
                contextTwinId: currentTwin?.prevId,
              }}
              className={cx(s.prevTwinBtn, 'btn btn-flat-secondary', {
                [s.disabled]: !currentTwin?.prevId,
              })}
              disabled={!currentTwin?.prevId}
              onClick={currentTwin?.prevId && handlePrevClick}
            >
              <Icon name="faChevronLeft" size="sm" />
            </Link>
          ) : (
            <button
              type="button"
              className={cx(s.prevTwinBtn, 'btn btn-flat-secondary')}
              disabled={!currentTwin?.prevId}
              onClick={handlePrevClick}
            >
              <Icon name="faChevronLeft" size="sm" />
            </button>
          )}

          {currentTwin?.nextId ? (
            <Link
              name="controlpanel"
              query={{
                contextTwinId: currentTwin?.nextId,
              }}
              className={cx(s.nextTwinBtn, 'btn btn-flat-secondary', {
                [s.disabled]: !currentTwin?.nextId,
              })}
              onClick={currentTwin?.nextId && handleNextClick}
            >
              <Icon name="faChevronRight" size="sm" />
            </Link>
          ) : (
            <button
              type="button"
              className={cx(s.nextTwinBtn, 'btn btn-flat-secondary')}
              disabled={!currentTwin?.nextId}
              onClick={handleNextClick}
            >
              <Icon name="faChevronRight" size="sm" />
            </button>
          )}
        </>
      ) : null}
      <div
        style={{ fontSize: '14px' }}
        ref={ref}
        className={cx(
          'position-relative pointer d-flex align-items-center w-auto justify-content-center',
          s.twinName,
          { [s.twinNameSelected]: contextTwinId },
        )}
        aria-hidden="true"
      >
        {contextTwinId && currentTwin.icon ?
          (currentTwin.icon?.includes('cus-') ? (
            <OldIcon
              name={currentTwin.icon}
              color={currentTwin.icon?.color}
              className="me-1"
            />
          ) : (
            <Icon
              name={camelCase(currentTwin.icon)}
              color={currentTwin.icon?.color}
              className="me-1"
            />
          )
          ) : null }

        <span
          className={cx('text-truncate', classNameSpan)}
          style={{ maxWidth: '162px' }}
        >
          {contextTwinId ? currentTwin.name : settings.EnvironmentName}
        </span>
        {contextTwinId ?
          <Icon className={cx(s.twinBtnIcon)} name="faBarsStaggered" size="sm" /> :
          null}
      </div>
      <LeftBar
        ref={ref}
        refDropdown={refDropdown}
        scrollTo={currentTwin?.id}
        {...props}
        onClick={handleClick}
      />
    </div>
  )
}

TwinSelector.displayName = 'TwinSelector'
export default enhanceSettings(TwinSelectorTree(TwinSelector))
