import { useFormContext } from 'react-hook-form'
import { consts } from '@wiz/utils'
import { FormSection } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Fields from './Fields'
import FieldsTrigger from './FieldsTrigger'
import FieldsSchedule from './FieldsSchedule'

export default function Section ({
  scope,
  title,
  addon,
  registerId = true,
  templateId,
}) {
  const intl = useIntl()
  const { register, watch } = useFormContext()
  const triggerType = watch(`${scope}.triggerType`)

  return (
    <>
      <FormSection
        title={title}
        addon={addon}
      >
        {registerId ? (
          <input
            {...register(`${scope}.id`)}
            type="hidden"
          />
        ) : null}

        <Fields scope={scope} templateId={templateId} />
      </FormSection>

      <FormSection
        title={intl.t('streamJobs.section.trigger')}
        description={intl.t('streamJobs.section.triggerDescr')}
      >
        <FieldsTrigger scope={scope} />
      </FormSection>

      {triggerType === consts.StreamJobTriggerType.Scheduled ? (
        <FormSection
          title={intl.t('streamJobs.section.schedule')}
          description={intl.t('streamJobs.section.scheduleDescr')}
        >
          <FieldsSchedule scope="schedule" />
        </FormSection>
      ) : null}
    </>
  )
}
