import {
  useState,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {
  DiagramMap,
  DiagramThemeContext,
  PhysicalTwinDiagram,
} from '@wiz/components'
import { useAuth } from '@/auth'
import { useTheme } from '@/theme'
import { consts } from '@wiz/utils'
import ContextMenu from '@/containers/Twin/Graph/ContextMenu'
import RightBar from '@/containers/Twin/Graph/RightBar'
import LeftBar from '@/containers/Twin/Graph/LeftBar/floatingBar'

const Diagram = forwardRef(({
  blockFactory,
  blocks,
  className,
  container,
  externalData,
  graphScrollTo,
  linkMode,
  mapView,
  onActionContextMenu,
  onChangeDiagram,
  onDblClick,
  onDropCreateDiagram,
  onLinkDiagram,
  onPaste,
  onRemove,
  onSelect,
  onUpdateData,
  refDiagram,
  rootTwin,
  selectedIds,
  state,
  setEditBlockId,
  setLinkMode,
  onClickCreate,
  hideGrid,
}, ref) => {
  const refMap = useRef()
  const refContextMenu = useRef()
  const refRightBar = useRef()
  const refLeftBar = useRef()
  const [ contextMenuData, setContextMenuData ] = useState()
  const theme = useTheme()
  const auth = useAuth()

  const handleClickEvents = useCallback(() => {
    refRightBar.current.open('events')
  }, [])

  const handleActionContextMenu = useCallback(({ action, params }, context) => {
    refContextMenu.current?.close()

    if (action === 'alignment') {
      refDiagram.current.align(params)
    }

    if (action === 'selectWithChildren') {
      refDiagram.current.selectWithChildren()
    }

    if ([ 'linkParentMode', 'linkDescrMode' ].includes(action) && selectedIds?.length === 2) {
      const fromId = selectedIds[0]
      const toId = selectedIds[1]
      const data = {
        fromId,
        fromPort: '',
        toId,
        toPort: '',
        category: action === 'linkParentMode' ?
          consts.DiagramBlockCategory.Parent :
          consts.DiagramBlockCategory.Descr,
      }

      if (action === 'linkParentMode') {
        data.hyperedge = true
      }

      onLinkDiagram(data)
    }

    onActionContextMenu?.({ action, params }, context)
  }, [
    refDiagram,
    onActionContextMenu,
    selectedIds,
    onLinkDiagram,
  ])

  useImperativeHandle(ref, () => ({
    openRightBar (name, params) {
      refRightBar.current.open(name, params)
    },
  }), [])

  return (
    <div
      id="twin-graph-container"
      className="d-flex flex-fill position-relative overflow-hidden"
    >
      <DiagramThemeContext.Provider value={theme.gojs}>
        <DiagramMap
          ref={refMap}
          refDiagram={refDiagram}
          className={classnames('position-absolute-fill', className)}
          scope={state?.scope}
          scale={state?.diagram?.mapScale}
          position={state?.diagram?.mapPosition}
          disabled={!mapView}
        >
          <PhysicalTwinDiagram
            ref={refDiagram}
            refMap={mapView ? refMap : null}
            className="position-absolute-fill"
            scope={state?.scope}
            linkMode={linkMode}
            blocks={blocks}
            externalData={externalData}
            state={state}
            blockFactory={blockFactory}
            scrollTo={graphScrollTo}
            onSelection={onSelect}
            {...(auth.checkAccessUpdate(rootTwin) ? {
              onChange: onChangeDiagram,
              onClickEvents: handleClickEvents,
              onContextMenu: setContextMenuData,
              onDblClick,
              onDeleted: onRemove,
              onDropCreate: onDropCreateDiagram,
              onLink: onLinkDiagram,
              onPaste,
              onUpdateData,
            } : {
              isReadOnly: true,
            })}
          />
        </DiagramMap>

        <RightBar
          ref={refRightBar}
          container={container || '#twin-graph-container'}
          blockIds={rootTwin && !selectedIds.length ? [ rootTwin.id ].concat(selectedIds) : selectedIds}
          setEditBlockId={setEditBlockId}
          rootTwin={rootTwin}
          fixed
        />

        {/* <LeftBar
          ref={refLeftBar}
          container={container || '#twin-graph-container'}
          blockIds={selectedIds}
          setEditBlockId={setEditBlockId}
          linkMode={linkMode}
          setLinkMode={setLinkMode}
          onClickCreate={onClickCreate}
          rootTwin={rootTwin}
        /> */}

        <ContextMenu
          {...contextMenuData}
          ref={refContextMenu}
          container={container || '#twin-graph-container'}
          rootTwin={rootTwin}
          blockIds={selectedIds}
          mapView={mapView}
          hideGrid={hideGrid}
          onAction={handleActionContextMenu}
          linkMode={linkMode}
          onLink={onLinkDiagram}
        />
      </DiagramThemeContext.Provider>
    </div>
  )
})

Diagram.propTypes = {
  blockFactory: PropTypes.func,
  blocks: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  className: PropTypes.string,
  container: PropTypes.string,
  externalData: PropTypes.shape(),
  graphScrollTo: PropTypes.string,
  linkMode: PropTypes.string,
  mapView: PropTypes.bool,
  onActionContextMenu: PropTypes.func,
  onChangeDiagram: PropTypes.func,
  onDblClick: PropTypes.func,
  onDropCreateDiagram: PropTypes.func,
  onLinkDiagram: PropTypes.func,
  onPaste: PropTypes.func,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
  onUpdateData: PropTypes.func,
  refDiagram: PropTypes.shape(),
  rootTwin: PropTypes.shape(),
  scope: PropTypes.string,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  state: PropTypes.shape(),
  setEditBlockId: PropTypes.func,
  setLinkMode: PropTypes.func,
  topBarComponent: PropTypes.node,
}

Diagram.defaultProps = {
  blockFactory: undefined,
  className: undefined,
  container: undefined,
  externalData: undefined,
  graphScrollTo: undefined,
  linkMode: undefined,
  mapView: false,
  onActionContextMenu: undefined,
  onChangeDiagram: undefined,
  onDblClick: undefined,
  onDropCreateDiagram: undefined,
  onLinkDiagram: undefined,
  onPaste: undefined,
  onRemove: undefined,
  onSelect: undefined,
  onUpdateData: undefined,
  refDiagram: undefined,
  rootTwin: undefined,
  scope: undefined,
  selectedIds: undefined,
  state: undefined,
  setEditBlockId: undefined,
  setLinkMode: undefined,
  topBarComponent: undefined,
}

export default Diagram
