import { lazy } from 'react'

export const Widgets = {
  bulletPlot: lazy(() => import('./BulletPlot')),
  chart: lazy(() => import('./Chart')),
  deviceCommand: lazy(() => import('./DeviceCommand')),
  deviceCommands: lazy(() => import('./DeviceCommands')),
  deviceMonitoringVisual: lazy(() => import('./DeviceMonitoringVisual')),
  dynamicBulletPlot: lazy(() => import('./DynamicBulletPlot')),
  events: lazy(() => import('./Events')),
  gauge: lazy(() => import('./Gauge')),
  grafana: lazy(() => import('./Grafana')),
  histogram: lazy(() => import('./Histogram')),
  inputSensorValues: lazy(() => import('./InputSensorValues')),
  issues: lazy(() => import('./Issues')),
  kpiCalendarHeatmap: lazy(() => import('./KPICalendarHeatmap')),
  location: lazy(() => import('./Location')),
  lossAndSavings: lazy(() => import('./LossAndSavings')),
  plotly: lazy(() => import('./Plotly')),
  projects: lazy(() => import('./Projects')),
  roiProject: lazy(() => import('./RoiProject')),
  roseWind: lazy(() => import('./RoseWind')),
  sensor: lazy(() => import('./Sensor')),
  tableData: lazy(() => import('./TableData')),
  timeline: lazy(() => import('./Timeline')),
}
