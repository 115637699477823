import { useRef, useMemo } from 'react'
import { Form, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { Widget } from '@wiz/store'
import { auth } from '@/auth'
import FieldTitle from './FieldTitle'
import FieldTwins from './FieldTwins'
import FieldAutosave from './FieldAutosave'

export default function ExplorerMini ({
  explorer,
  settings,
  twins,
  onSubmit,
  onClose,
  onRemove,
  onCopy,
}) {
  const refForm = useRef()
  const intl = useIntl()
  const canRemove = explorer && auth.checkAccessRemove(explorer)
  const canCopy = explorer && auth.checkAccessCopy(explorer)
  const canUpdate = explorer && auth.checkAccessUpdate(explorer)
  const canCreate = auth.checkAccessCreate('Explorer')

  const defaultValues = useMemo(() => ({
    explorer: { title: explorer?.title || '' },
    settings,
    twins,
  }), [
    explorer?.updatedAt ?? explorer,
    settings,
    twins,
  ])

  return (
    <Form
      ref={refForm}
      className="d-flex flex-column flex-fill min-h-0"
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    >
      <div className="flex-fill m-3 min-h-0 overflow-auto overflow-x-hidden">
        <FieldTitle />

        {Widget.is(explorer) ? null : <FieldTwins />}

        <FieldAutosave />
      </div>

      <div className="d-flex mb-3 mx-3">
        {canRemove ? (
          <button
            type="button"
            className="btn btn-danger"
            title={intl.t('explorer.form.actions.remove')}
            onClick={() => onRemove?.(explorer.id)}
          >
            <Icon name="fa--trash-alt" />
          </button>
        ) : null}

        <div className="flex-fill d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-outline-secondary ms-2"
            onClick={() => onClose?.()}
          >
            {intl.t('form.actions.close')}
          </button>

          {canCopy ? (
            <button
              type="button"
              className="btn btn-outline-primary ms-2"
              onClick={() => onCopy?.(refForm.current.getValues())}
            >
              {intl.t('explorer.form.actions.saveAs')}
            </button>
          ) : null}

          {do {
            if (canUpdate) {
              <button
                type="button"
                className="btn btn-primary ms-2"
                onClick={() => refForm.current.submit()}
              >
                {intl.t('form.actions.save')}
              </button>
            } else if (canCreate) {
              <button
                type="button"
                className="btn btn-primary ms-2"
                onClick={() => refForm.current.submit()}
              >
                {intl.t('explorer.form.actions.create')}
              </button>
            }
          }}
        </div>
      </div>
    </Form>
  )
}
