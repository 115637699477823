import { useMemo } from 'react'
import classnames from 'classnames'
import {
  Table as StaticTable,
  Pagination,
  Badge,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import Observe from '@/containers/Observe'
import TwinList from '@/hoc/TwinList'
import { markSearch } from '@wiz/utils'
import CellTwins from './CellTwins'

const CellTwinsData = TwinList(CellTwins)

export default function Table ({
  items,
  onRemove,
  onEdit,
  onToggleActive,
  onFilter,
  filters,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    ...(auth.checkAccessUpdate('User') ? [
      {
        Header: intl.t('form.fields.active'),
        accessor: 'isActive',
        disableResizing: true,
        minWidth: 55,
        width: 55,
        maxWidth: 55,
        className: 'justify-content-center',
        Cell: ({ cell, row }) => (auth.checkAccessUpdate(row.original) ? (
          <div className="form-check form-switch m-0 mb-n1 ps-3">
            <input
              type="checkbox"
              className="form-check-input pointer ms-n3"
              checked={cell.value}
              onChange={() => onToggleActive(row.original)}
            />
          </div>
        ) : null),
      },
    ] : []),
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      className: 'text-nowrap',
      Cell: ({ cell, row }) => {
        const allowed = auth.checkAccessUpdate(row.original)

        return (
          <div
            className={classnames('d-flex flex-column text-truncate min-w-0', {
              'link pointer': allowed,
            })}
            onClick={() => (allowed ? onEdit?.(row.original.id) : undefined)}
            aria-hidden
          >
            <span>
              {cell.value}
            </span>
            {row.original.userType === 'app' ? (
              <span
                className="font-italic text-muted text-truncate small"
              >
                {row.original.userType}
              </span>
            ) : null}
          </div>
        )
      },
    },
    {
      Header: intl.t('form.fields.email'),
      accessor: 'email',
      className: 'text-truncate',
    },
    {
      Header: intl.t('form.fields.twins'),
      Cell: ({ row }) => (
        <div className="d-flex flex-wrap">
          <Observe query={row.original.queryRelTwins}>
            {({ data }) => (
              <CellTwinsData
                value={data.map(item => item.twinId)}
                selectedOnly
                onFilter={onFilter}
              />
            )}
          </Observe>
        </div>
      ),
    },
    {
      Header: intl.t('users.form.fields.roles'),
      minWidth: 200,
      Cell: ({ row }) => (
        <div className="d-flex flex-wrap">
          <Observe query={row.original.queryAccessRoles}>
            {({ data }) => data.map(role => (
              <Badge
                key={role.id}
                className="bg-secondary me-1 my-1"
              >
                {role.name || role.code}
              </Badge>
            ))}
          </Observe>
        </div>
      ),
    },
    {
      Header: intl.t('users.form.fields.lastSeen'),
      accessor: 'lastSeen',
      disableResizing: true,
      minWidth: 170,
      width: 170,
      maxWidth: 220,
      Cell: ({ cell }) => (<UpdatedInfo date={cell.value} />),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdAt',
      disableResizing: true,
      minWidth: 170,
      width: 170,
      maxWidth: 220,
      Cell: ({ cell, row }) => (<UpdatedInfo date={cell.value} user={row.original.createdUser} />),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell, row }) => (
        <>
          {auth.checkAccessUpdate(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0 me-2"
              onClick={() => onEdit?.(cell.value)}
            >
              {intl.t('form.actions.edit')}
            </button>
          ) : null}

          {auth.checkAccessRemove(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0"
              onClick={() => onRemove?.([ row.original ])}
            >
              {intl.t('form.actions.remove')}
            </button>
          ) : null}
        </>
      ),
    },
  ], [
    intl,
    auth,
    onEdit,
    onRemove,
    onToggleActive,
    onFilter,
  ])

  return (
    <>
      <StaticTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={auth.checkAccessUpdate('User') || auth.checkAccessRemove('User')}
        {...props}
      />

      <Pagination {...props} />
    </>
  )
}
