import './assets/style/main.css'
import '@wiz/components/lib/index.css'
import { render } from 'react-dom'
import events from '@/utils/events'
import '@/utils/analytics'
import '@/sprite'
import App from './App'

window.wizConfirm = (data) => {
  events.emit('app:confirm', data)
  return new Promise((resolve, reject) => {
    events.once('app:confirm:status', (status) => {
      if (status) {
        resolve()
      } else {
        reject()
      }
    })
  })
}

render((<App />), document.getElementById('app'))
