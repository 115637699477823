import { define } from 'superstruct'
import { intl } from '@/i18n'

const Maxlen = maxlen => define('Maxlen', (value) => {
  let result = true
  const len = typeof value === 'string' ? value.length : (JSON.stringify(value)?.length ?? 0)
  if (typeof value !== 'undefined' && value !== null && len > maxlen) {
    result = intl.t('form.errors.fieldMaxlen', { max: maxlen })
  }
  return result
})

export default Maxlen
