import { useState } from 'react'
import classnames from 'classnames'
import { Tabs } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import SectionData from './SectionData'
import SectionFilters from './SectionFilters'
import SectionView from './SectionView'

export default function SettingsSection ({ widget }) {
  const intl = useIntl()
  const [ tab, setTab ] = useState('data')

  return (
    <>
      <div className="d-flex flex-column p-2 mb-1">
        <h5>
          {intl.t(widget.info.name)}
        </h5>
        <small>
          {intl.t('widgets.plotly.titleDescr')}
        </small>
      </div>

      <Tabs
        className="mx-3"
        value={tab}
        options={[
          { value: 'data', label: intl.t('widgets.chart.form.fields.dataSourceSection') },
          { value: 'filters', label: intl.t('widgets.chart.form.fields.filtersSection') },
          { value: 'view', label: intl.t('widgets.chart.form.fields.viewSection') },
        ]}
        onChange={setTab}
      />

      <div
        className={classnames('min-h-0 overflow-auto flex-fill d-flex flex-column', {
          'd-none': tab !== 'data',
        })}
      >
        <SectionData scope="config" />
      </div>

      <div className={classnames({ 'd-none': tab !== 'filters' })}>
        <SectionFilters scope="config" />
      </div>

      <div className={classnames({ 'd-none': tab !== 'view' })}>
        <SectionView scope="config" />
      </div>
    </>
  )
}
