import { FormSelectDateTimeRange } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Select from '@/components/Form/Select'
import FormatDateTime from '@/containers/FormatDateTime'
import EdgeDeviceList from '@/hoc/EdgeDeviceList'
import EdgeModuleList from '@/hoc/EdgeModuleList'
import ListFilters from '@/components/Form/ListFilters'

export default function Filters ({
  filters,
  onFilter,
  onResetFilter,
}) {
  const intl = useIntl()

  return (
    <ListFilters
      filters={filters}
      onFilter={onFilter}
      onResetFilter={onResetFilter}
    >
      {({ filters, onFilter }) => (
        <>
          <div className="px-md-2 mb-2 col-md-auto">
            <FormSelectDateTimeRange
              className="w-auto"
              placeholderFrom="From"
              placeholderTo="To"
              value={[ filters.from, filters.to ]}
              DateTimeComponent={FormatDateTime}
              onChange={([ from, to ]) => onFilter({ from, to })}
            />
          </div>

          <div className="px-md-2 mb-2 col-md-3 col-lg-2">
            <Select
              placeholder={intl.t('form.fields.devicePlaceholder')}
              value={filters.deviceId}
              ListSource={EdgeDeviceList}
              onChange={data => onFilter({
                deviceId: data,
                moduleId: null,
              })}
            />
          </div>

          <div className="px-md-2 mb-2 col-md-3 col-lg-2">
            <Select
              placeholder={intl.t('form.fields.modulePlaceholder')}
              value={filters.moduleId}
              filters={{ edgeId: filters.deviceId }}
              ListSource={filters.deviceId ? EdgeModuleList : undefined}
              onChange={data => onFilter({ moduleId: data })}
            />
          </div>
        </>
      )}
    </ListFilters>
  )
}
