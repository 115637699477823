import classnames from 'classnames'
import { useIntl } from '@wiz/intl'
import Icon from '@/shared/icon'

const EditButton = ({
  cell, onEdit, onChange, view, className,
}) => {
  const intl = useIntl()

  return (
    <button
      type="button"
      className={classnames('btn btn-text', className)}
      onClick={() => (onChange?.() ?? onEdit?.(cell.value))}
    >
      <Icon name={view ? 'faEye' : 'faPenToSquare'} />
      {/* <span style={{ fontSize: 10 }}>{intl.t('form.actions.edit')}</span> */}
    </button>
  )
}

export default EditButton
