import { useMemo, useState } from 'react'
import { withContext, useDidUpdate } from '@wiz/components'
import DataExplorerExecute from '@/context/DataExplorerExecute'

export default withContext(DataExplorerExecute, ({ explorer }) => {
  const [ appliedConditions, setAppliedConditions ] = useState({})
  const [ appliedEvents, setAppliedEvents ] = useState({})
  const [ autoStepApplied, setAutoStepApplied ] = useState(0)
  const [ denseStepApplied, setDenseStepApplied ] = useState(0)
  const [ errors, setErrors ] = useState({})
  const [ loading, setLoading ] = useState(false)
  const [ rightBar, setRightBar ] = useState(null)
  const [ counts, setCounts ] = useState({})

  useDidUpdate(() => {
    setAppliedConditions({})
    setAppliedEvents({})
    setAutoStepApplied(0)
    setDenseStepApplied(0)
    setErrors({})
    setCounts({})
    setLoading(null)
  }, [ explorer ])

  const context = useMemo(() => ({
    appliedConditions,
    appliedEvents,
    autoStepApplied,
    denseStepApplied,
    errors,
    loading,
    rightBar,
    counts,

    setAppliedConditions,
    setAppliedEvents,
    setAutoStepApplied,
    setDenseStepApplied,
    setErrors,
    setLoading,
    setRightBar,
    setCounts,
  }), [
    appliedConditions,
    appliedEvents,
    autoStepApplied,
    denseStepApplied,
    errors,
    loading,
    rightBar,
    counts,
  ])

  return context
})
