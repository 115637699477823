import { useCallback } from 'react'
import { dbProvider, StreamJob } from '@wiz/store'
import {
  ruleOptionsToRFCString,
  ruleTimesToDates,
  RuleType,
  consts,
} from '@wiz/utils'
import { withProps } from '@wiz/components'

export default withProps(() => {
  const onSubmit = useCallback(async (data) => {
    const schedules = []
    if (
      data.schedule?.times?.length &&
      data.streamJob.triggerType === consts.StreamJobTriggerType.Scheduled
    ) {
      const times = ruleTimesToDates(
        data.schedule.times,
        data.schedule.type === RuleType.Once,
      )

      for (let i = 0; i < times.length; i += 1) {
        schedules.push({
          id: data.schedule.times[i].id,
          rule: ruleOptionsToRFCString({
            ...data.schedule,
            dtstart: times[i],
          }),
        })
      }
    }

    const context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareReplaceData(context, StreamJob, data.streamJob)
    await model.prepareReplaceSchedules(context, schedules)
    await model.prepareReplaceTwins(context, data.twins)
    await dbProvider.batch(context)
    return model
  }, [])

  return {
    onSubmit,
  }
})
