import { Observable } from 'rxjs'
import {
  OnlineTimeout,
  network,
  AbortRequestError,
} from '@wiz/utils'
import {
  wizataApi,
  signalApi,
} from '@/api'

const RealtimeDuration = 1 * 60 * 1000

export default function observeEventsCount ({
  twinIds,
  status,
  includeChildTwinEvents,
}) {
  const fetchCount = (onSuccess, onFinally) => {
    let request

    wizataApi.events.count({
      twinIds,
      status,
      includeChildTwinEvents,
    }, (data) => {
      request = data
    })
      .then((data) => {
        onSuccess(data)
        onFinally()
      })
      .catch((error) => {
        if (!(error instanceof AbortRequestError)) {
          onFinally()
        }
      })

    return request
  }

  return new Observable((subscriber) => {
    let timer
    let request

    const handleRequest = () => {
      timer?.cancel()
      request = fetchCount((data) => {
        if (!subscriber.closed) {
          subscriber.next(data)
        }
      }, () => {
        if (!subscriber.closed) {
          timer = new OnlineTimeout(handleRequest, RealtimeDuration)
        }
      })
    }

    handleRequest()
    network.on('online', handleRequest)
    signalApi.registerStream(signalApi.SignalStream.EventsRefresh, handleRequest)
    subscriber.next([])

    return () => {
      request?.abort()
      network.removeListener('online', handleRequest)
      signalApi.unregisterStream(signalApi.SignalStream.EventsRefresh, handleRequest)
      timer?.cancel()
    }
  })
}
