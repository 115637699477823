import { useContext, useRef } from 'react'
import classnames from 'classnames'
import { FormCheckButton, Icon, DropdownMenu } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { consts } from '@wiz/utils'
import { useAuth } from '@/auth'
import { MENU_ALIGMENTS } from '@/config'
import TwinGraphExecute from '@/context/TwinGraphExecute'
import TwinComponentOptions from './TwinComponentOptions'

export default function GraphFilters ({
  twinSettings,
  mapView,
  hideGrid,
  twinGraph,
  linkMode,
  setLinkMode,
  onToggleGrid,
  onToggleMapView,
  onEditTwinGraph,
  onDuplicateTwinGraph,
  onDiagramAlign,
  onDiagramFit,
  onDiagramReshapeLinks,
  onClickCreate,
  onDragStart,
  onDragEnd,
}) {
  const refMenu = useRef()
  const refMenuTarget = useRef()
  const auth = useAuth()
  const intl = useIntl()
  const executeContext = useContext(TwinGraphExecute)

  const canCreateTwin = (
    auth.checkAccessCreate('DiagramBlock') &&
    auth.checkAccessCreate('Twin')
  )

  return (
    <>
      <div className="d-flex align-items-center mb-2">
        {(
          auth.checkAccessUpdate(twinGraph) ||
          auth.checkAccessCopy(twinGraph)
        ) ? (
          <>
            <div className="mx-2 flex-shrink-0">
              {auth.checkAccessUpdate(twinGraph) ? (
                <button
                  type="button"
                  className="btn btn-flat-secondary btn-text"
                  title={intl.t('twinGraph.form.actions.editTitle')}
                  onClick={onEditTwinGraph}
                >
                  <Icon name="fa--edit" />
                </button>
              ) : null}
              {auth.checkAccessCopy(twinGraph) ? (
                <button
                  type="button"
                  className="btn btn-flat-secondary btn-text"
                  title={intl.t('form.actions.duplicate')}
                  onClick={onDuplicateTwinGraph}
                >
                  <Icon name="fa--clone" />
                </button>
              ) : null}
            </div>

            <div className="border-start rh-4 flex-shrink-0" />
          </>
          ) : null}

        {auth.checkAccessUpdate(twinGraph) ? (
          <>
            <div className="mx-2 flex-shrink-0">
              <FormCheckButton
                className="btn btn-flat-secondary btn-text"
                checked={linkMode === 'link-parent-mode'}
                label={<Icon name="fa--sitemap" />}
                title={intl.t('twinGraph.form.actions.linkParentModeTitle')}
                onChange={checked => setLinkMode(checked ? 'link-parent-mode' : null)}
              />
              <FormCheckButton
                className="btn btn-flat-secondary btn-text"
                checked={linkMode === 'link-descr-mode'}
                label={<Icon name="fa--project-diagram" />}
                title={intl.t('twinGraph.form.actions.linkDescrModeTitle')}
                onChange={checked => setLinkMode(checked ? 'link-descr-mode' : null)}
              />
            </div>

            <div className="border-start rh-4 flex-shrink-0" />
          </>
        ) : null}

        {auth.checkAccessUpdate(twinGraph) ? (
          <>
            <div className="mx-2 flex-shrink-0">
              <button
                ref={refMenuTarget}
                type="button"
                className="btn btn-flat-secondary btn-text"
                title={intl.t('twinGraph.form.actions.addBlockTitle')}
              >
                <Icon name="fa--shapes" />
              </button>

              <button
                type="button"
                className="btn btn-flat-secondary btn-text"
                title={intl.t('twinGraph.form.actions.toggleGridTitle')}
                disabled={mapView}
                onClick={onToggleGrid}
              >
                <Icon name={hideGrid ? 'fa--border-none' : 'fa--border-all'} />
              </button>

              <button
                type="button"
                className={classnames('btn btn-text', {
                  'btn-flat-primary': !!mapView,
                  'btn-flat-secondary': !mapView,
                })}
                title={intl.t('twinGraph.form.actions.toggleMapTitle')}
                onClick={onToggleMapView}
              >
                <Icon name="fa--map-marked" />
              </button>
            </div>

            <div className="border-start rh-4 flex-shrink-0" />
          </>
        ) : null}

        {auth.checkAccessUpdate(twinGraph) ? (
          <>
            <div className="mx-2 flex-shrink-0">
              {MENU_ALIGMENTS.filter(item => item.toolbar).map(item => (
                <button
                  key={item.value}
                  type="button"
                  className="btn btn-flat-secondary btn-text"
                  title={intl.t(item.description)}
                  disabled={mapView}
                  onClick={() => onDiagramAlign(item.options)}
                >
                  <Icon name={item.icon} />
                </button>
              ))}
            </div>

            <div className="border-start rh-4 flex-shrink-0" />
          </>
        ) : null}

        {auth.checkAccessUpdate(twinGraph) ? (
          <div className="mx-2 flex-shrink-0">
            <button
              type="button"
              className="btn btn-flat-secondary btn-text"
              title={intl.t('twinGraph.form.fields.includeChildren')}
              onClick={() => executeContext.toggleIncludeChildTwinEvents()}
            >
              <Icon
                name={executeContext.includeChildTwinEvents ? 'fa--calendar-plus' : 'fa--calendar-minus'}
              />
            </button>

            <button
              type="button"
              className="btn btn-flat-secondary btn-text"
              title={intl.t('chart.form.actions.fitPosition')}
              onClick={onDiagramFit}
            >
              <Icon name="fa--compress-arrows-alt" />
            </button>

            <button
              type="button"
              className="btn btn-flat-secondary btn-text"
              title={intl.t('chart.form.actions.reshapeLinks')}
              onClick={onDiagramReshapeLinks}
            >
              <Icon name="fa--paint-roller" />
            </button>
          </div>
        ) : null}
      </div>

      <DropdownMenu
        ref={refMenu}
        target={refMenuTarget}
        arrow
        draggable
        autoclose
        options={[
          {
            label: intl.t('twinGraph.menuMachines'),
            icon: 'machines',
            ComponentOptions: TwinComponentOptions,
            disabled: !canCreateTwin,
          },
          {
            label: intl.t('twinGraph.menuEmptyBlock'),
            disabled: !canCreateTwin,
            options: [
              {
                type: consts.DiagramBlockType.Area,
                color: twinSettings.twinColorAreas,
                label: intl.t('enum.twinType.area'),
                icon: 'fa--warehouse',
                disabled: !canCreateTwin,
              },
              {
                type: consts.DiagramBlockType.Machine,
                color: twinSettings.twinColorMachines,
                label: intl.t('enum.twinType.machine'),
                icon: 'fa--cogs',
                disabled: !canCreateTwin,
              },
              {
                type: consts.DiagramBlockType.Equipment,
                color: twinSettings.twinColorEquipment,
                label: intl.t('enum.twinType.equipment'),
                icon: 'fa--tools',
                disabled: !canCreateTwin,
              },
            ],
          },
          {
            type: consts.DiagramBlockType.NestedTwinGraph,
            label: intl.t('enum.diagramBlock.nestedTwinGraph'),
            icon: 'flow',
          },
          {
            type: consts.DiagramBlockType.Widget,
            label: intl.t('enum.diagramBlock.widget'),
            icon: 'fa--th-large',
          },
        ]}
        onClick={onClickCreate}
        onDragStart={(data) => {
          onDragStart(data)
          window.setTimeout(() => refMenu.current.close(), 0)
        }}
        onDragEnd={onDragEnd}
      />
    </>
  )
}
