import { useMemo, useState } from 'react'
import { MlWebServiceNg, MlWebServiceNgInterface } from '@wiz/store'
import { Tabs } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import Section from './Section'
import SectionInterface from './SectionInterface'
import SectionUsage from './SectionUsage'

export default function MlWebService ({
  id,
  webService,
  interfaces,
  usage,
  activeUsage,
  dialog,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()
  const [ tab, setTab ] = useState('settings')
  const immutableInterfaces = JSON.stringify(interfaces.map(item => item?.updatedAt))

  const defaultValues = useMemo(() => ({
    webService: MlWebServiceNg.toJSON(webService),
    interfaces: interfaces.map(item => MlWebServiceNgInterface.toJSON(item)),
  }), [
    webService?.updatedAt ?? webService,
    immutableInterfaces,
  ])

  const usageCount = useMemo(() => (
    usage.reduce((acc, item) => (
      acc + (item.payload.count || 0)
    ), 0)
  ), [ usage ])

  if (id && !webService) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="mlWebServiceForm"
      dialog={dialog}
      disabled={activeUsage}
    >
      {activeUsage ? (
        <div className="alert alert-warning" role="alert">
          {intl.t('mlWebServices.disableActiveUsage')}
        </div>
      ) : null}

      <Tabs
        className="mb-2"
        value={tab}
        options={[
          { value: 'settings', label: intl.t('mlWebServices.sections.settings') },
          { value: 'usage', label: `${intl.t('mlWebServices.sections.usage')} (${usageCount})` },
        ]}
        onChange={setTab}
      />

      {tab === 'settings' ? (
        <>
          <Section
            scope="webService"
          />
          <SectionInterface
            scope="interfaces"
          />
        </>
      ) : null}

      {tab === 'usage' ? (
        <SectionUsage usage={usage} />
      ) : null}
    </FormDialog>
  )
}
