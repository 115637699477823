import classnames from 'classnames'
import { Icon } from '@wiz/components'

export default function ItemContent ({
  active,
  data,
  context,
  search,
  className,
  ContentDefault,
}) {
  const { onAction } = context
  return (
    <>
      <button
        type="button"
        className="btn btn-link d-flex min-w-0 text-reset align-self-center"
        onClick={() => onAction(null, 'open', data)}
      >
        <ContentDefault
          color={data.color}
          icon={data.icon}
          name={data.name}
          search={search}
          className={className}
        />
      </button>

      <button
        className={classnames('hover-opacity btn btn-flat-secondary p-2 ms-auto', {
          'text-reset': active,
        })}
        type="button"
        onClick={(event) => {
          event.stopPropagation()
          onAction(event, 'more', data)
        }}
      >
        <Icon name="fa--ellipsis-h" />
      </button>
    </>
  )
}
