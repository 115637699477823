import { useFormContext } from 'react-hook-form'
import Section from '../BlockFlow/Section'

export default function SettingsSection ({ scope }) {
  const {
    watch,
    register,
  } = useFormContext()
  const id = watch(`${scope}.flow.id`)

  return (
    <>
      <input
        {...register(`${scope}.id`)}
        type="hidden"
        defaultValue={id}
      />

      <Section
        scope={`${scope}.flow`}
      />
    </>
  )
}
