const allowedExtensions =
/(\.jpg|\.jpeg|\.png|\.svg)$/i

let logo = process.env.WIZ_CLIENT_LOGO || 'wizata-letter-white'
if (logo === 'wizata') {
  logo = 'wizata-letter-white'
}

const icon = require(`@/assets/img/logo/${logo}.svg?inline`)

export const convertToBase64 = file => new Promise((resolve, reject) => {
  const fileReader = new FileReader()
  fileReader.readAsDataURL(file)

  fileReader.onload = () => {
    resolve(fileReader.result)
  }

  fileReader.onerror = (error) => {
    reject(error)
  }
})

export const validateImageMaxSize = (file, sizeKb = 1024) => {
  const fileName = file?.name

  if (!allowedExtensions.exec(fileName)) {
    return 'Invalid file type'
  }

  const fileSize = file.size
  const sizeMb = Math.round((fileSize / 1024))

  if (sizeMb >= sizeKb) {
    return `File is too big, please select a file less than ${sizeKb}Kb`
  }

  return null
}

const resizeToSquare = (image, { width = 32, height = 32 }) => new Promise((resolve) => {
  const reader = new FileReader()
  reader.readAsDataURL(image)
  reader.name = image.name
  reader.size = image.size
  reader.onload = (event) => {
    const img = new Image()
    img.src = event.target.result
    img.name = event.target.name
    img.size = event.target.size
    img.onload = (el) => {
      const elem = document.createElement('canvas')

      elem.width = width
      elem.height = height

      const ctx = elem.getContext('2d')
      ctx.drawImage(el.target, 0, 0, elem.width, elem.height)

      const srcEncoded = ctx.canvas.toDataURL(image.type, 1)
      resolve(srcEncoded)
    }
  }
})

export const onImageSizeChange = image => new Promise((resolve) => {
  resizeToSquare(image, { width: 32, height: 32 }).then(resolve)
})

export const dataUrlToFile = async (dataUrl, fileName) => {
  const res: Response = await fetch(dataUrl)
  const blob: Blob = await res.blob()
  return new File([ blob ], fileName, { type: blob.type })
}

export const updateFavicon = async (envLogo, name) => {
  let link = document.querySelector("link[rel*='icon']")

  const image = await dataUrlToFile(envLogo, name)

  const resized = await onImageSizeChange(image)

  if (!link) {
    link = document.createElement('link')
    link.rel = 'icon'
    document.getElementsByTagName('head')[0].appendChild(link)
  }
  link.href = resized || icon
}
