import classnames from 'classnames'
import Icon from '@/shared/icon'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import classes from './Userpic.css'

export default function Userpic () {
  const { user } = useGlobalExecute()

  return (
    <div className={classnames(classes.userpic, { 'overflow-hidden rounded-circle': user.avatar })}>
      {user.avatar ?
        <img src={user.avatar} alt="user" style={{ width: '100%' }} /> : (
          <Icon
            type="solid"
            name="faUser"
            size="lg"
            className={classes.ico}
          />
        )}
    </div>
  )
}
