import { useCallback, useEffect } from 'react'
import { withProps } from '@wiz/components'
import { dbProvider } from '@wiz/store'
import { useRouter } from '@/router'
import { auth } from '@/auth'
import useAuthComponents from '@/components/GrafanaDashboards/hooks'

const DropElementId = '__dropping-elem__'

export default withProps(({
  dashboard,
  closeDashboard,
}) => {
  const router = useRouter()
  const { isPersonal } = useAuthComponents()

  // if (!dashboard) {
  // closeDashboard()
  // return null
  // }

  const onLayoutChange = useCallback(async (data) => {
    if (!auth.checkAccessUpdate(dashboard) && !isPersonal) {
      return
    }

    if (data.some(item => item.i === DropElementId)) {
      return
    }

    const widgets = dbProvider.database.collections.get('widgets')
    const context = dbProvider.createBatchContext()

    for (const item of data) {
      const widget = await widgets.find(item.i)
      await widget.prepareUpdateData(context, {
        gridX: item.x,
        gridY: item.y,
        gridW: item.w,
        gridH: item.h,
      })
    }

    await dbProvider.batch(context)
  }, [ dashboard ])

  const onWidgetAction = useCallback(async (widget, action) => {
    if (action === 'dataExplorer') {
      router.push({ name: 'data-observation', params: { id: widget.id } })
    } else if (action === 'duplicate') {
      const context = dbProvider.createBatchContext()
      await dbProvider.prepareDuplicateModel(context, widget)
      await dbProvider.batch(context)
    } else if (action === 'remove') {
      await window.wizConfirm({ message: 't/units.confirmDelete' })
      const context = dbProvider.createBatchContext()
      await widget.prepareRemove(context)
      await dbProvider.batch(context)
    } else if (action === 'editGrafana') {
      const nextUrl = widget.config.panelUrl?.split('/')
      nextUrl[3] = 'd'
      const [ , panelId ] = widget.config.panelUrl?.match(/panelId=([^&]*)/)
      // window.location.replace()
      window.open(`${nextUrl.join('/')}&editPanel=${panelId}`, '_blank')
    } else if (action === 'openGrafana') {
      const nextUrl = widget.config.panelUrl?.split('/')
      nextUrl[3] = 'd'
      // window.location.replace()
      window.open(nextUrl.join('/'), '_blank')
    }
  }, [ router ])

  const onCreateDropWidget = useCallback(async (type, layout) => {
    if (!auth.checkAccessUpdate(dashboard) && !isPersonal) {
      return
    }

    const collection = dbProvider.database.collections.get('widgets')
    const { modelClass } = collection
    const context = dbProvider.createBatchContext()

    for (const item of layout) {
      const coords = {
        gridX: item.x,
        gridY: item.y,
        gridW: item.w,
        gridH: item.h,
      }
      if (item.i !== DropElementId) {
        const widget = await collection.find(item.i)
        await widget.prepareUpdateData(context, coords)
      } else {
        await modelClass.prepareCreateData(context, dbProvider.database, modelClass.toJSON({
          type,
          dashboardId: dashboard.id,
          ...coords,
        }))
      }
    }

    await dbProvider.batch(context)
  }, [ dashboard ])

  useEffect(() => {
    async function updateLastOpened () {
      if (!dashboard) {
        return null
      }
      const lastOpened = await dbProvider.fetchSettings('lastOpened')
      const context = dbProvider.createBatchContext()
      await dbProvider.prepareReplaceSetting(context, 'lastOpened', {
        ...lastOpened,
        [dashboard.id]: Date.now(),
      })
      await dbProvider.batch(context)
    }

    updateLastOpened()
  }, [ dashboard ])

  return {
    onLayoutChange,
    onWidgetAction,
    onCreateDropWidget,
  }
})
