import { define } from 'superstruct'
import { intl } from '@/i18n'

const LimitMin = ({
  keyMax = 'limitMax',
} = {}) => define('LimitMin', (value, { branch }) => {
  const data = branch[branch.length - 2]
  let result = true
  if (Number.isFinite(value) && Number.isFinite(data[keyMax]) && value >= data[keyMax]) {
    result = intl.t('form.errors.rangeMinOutofrange')
  }
  return result
})

export default LimitMin
