import { FormSelectDateTimeRange } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Select from '@/components/Form/Select'
import SelectTwin from '@/components/Form/SelectTwin'
import FormatDateTime from '@/containers/FormatDateTime'
import SelectSensor from '@/components/Form/SelectSensor'
import StreamJobList from '@/hoc/StreamJobList'
import EdgeDeviceList from '@/hoc/EdgeDeviceList'
import ListFilters from '@/components/Form/ListFilters'

export default function Filters ({
  isMobil,
  filters,
  onFilter,
  onResetFilter,
}) {
  const intl = useIntl()

  return (
    <ListFilters
      filters={filters}
      onFilter={onFilter}
      onResetFilter={onResetFilter}
    >
      {({ filters, onFilter }) => (
        <>
          <div className="px-2 mb-2">
            <FormSelectDateTimeRange
              className="w-auto"
              placeholderFrom="From"
              placeholderTo="To"
              value={[ filters.from, filters.to ]}
              DateTimeComponent={FormatDateTime}
              onChange={([ dateFrom, dateTo ]) => onFilter({ from: dateFrom, to: dateTo })}
            />
          </div>

          <div className="px-2 mb-2 col-md-3 col-lg-2">
            <Select
              placeholder={intl.t('edge.commands.form.fields.devicePlaceholder')}
              value={filters.deviceId}
              ListSource={EdgeDeviceList}
              onChange={deviceId => onFilter({ deviceId })}
            />
          </div>

          <div className="px-2 mb-2 col-md-3 col-lg-2">
            <Select
              placeholder={intl.t('form.fields.streamJobPlaceholder')}
              value={filters.streamJobId}
              ListSource={StreamJobList}
              onChange={streamJobId => onFilter({ streamJobId })}
            />
          </div>

          <div className="px-2 mb-2 col-md-3 col-lg-2">
            <SelectTwin
              placeholder={intl.t('form.fields.twinPlaceholder')}
              value={filters.twinId}
              onChange={twinId => onFilter({ twinId })}
            />
          </div>

          <div className="px-2 mb-2 col-md-3 col-lg-2">
            <SelectSensor
              placeholder={intl.t('form.fields.sensorsPlaceholder')}
              value={filters.sensorId}
              onChange={sensorId => onFilter({ sensorId })}
            />
          </div>
        </>
      )}
    </ListFilters>
  )
}
