import {
  useMemo, useState, useEffect, useCallback, useRef,
} from 'react'
import { DateTime } from 'luxon'
import { useQuery } from '@tanstack/react-query'
import {
  Form,
  FormSection,
  FormField,
  FormFieldInline,
  FormSelectNative,
  FormInputNumber,
  FormInputDuration,
  FormSwitch,
  FormCheckbox,
  CustomScrollbars,
  FormControl,
  Icon,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import {
  timezones, consts, debounce, get,
} from '@wiz/utils'
import { auth } from '@/auth'
import Select from '@/components/Form/Select'
import UserList from '@/hoc/UserList'
import { useForm } from 'react-hook-form'
import { appEnv } from '@/config'
import {
  updateFavicon, validateImageMaxSize, convertToBase64, dataUrlToFile,
} from '@/utils/global'
import { wizataApi } from '@/api'
import events from '@/utils/events'

const BASE_URL = String(process.env.WIZATA_API_BASE_URL).replace('[apiVersion]', '1.0')
const uploadConfiguration = {
  custom: {
    endpoint: `${BASE_URL}/CustomConfigurationFiles`,
    fileName: 'custom.ini',
    reconfigure: true,
  },
  py: {
    endpoint: `${BASE_URL}/CustomRequirementsFiles`,
    fileName: 'requirements.txt',
    reconfigure: false,
  },
}

const UserListContent = ({ data }) => (
  <>
    {data.name}
    {data.name !== data.email ? (
      <span className="ms-2 text-muted">
        -
        {' '}
        {data.email}
      </span>
    ) : null}
  </>
)

export default function View ({
  settings,
  onChangeGlobalSetting,
  onChangeZone,
}) {
  const FreeSmsLimit = Number(settings.FreeSmsLimit || 0)
  const FreeSentSmsCount = Number(settings.FreeSentSmsCount || 0)
  const PaidSmsLimit = Number(settings.PaidSmsLimit || 0)
  const PaidSentSmsCount = Number(settings.PaidSentSmsCount || 0)

  const [ currentFreeSmsLimit, setCurrentFreeSmsLimit ] = useState(FreeSmsLimit)
  const [ freeSmsLimitError, setFreeSmsLimitError ] = useState(
    FreeSmsLimit < FreeSentSmsCount,
  )
  const [ currentPaidSmsLimit, setCurrentPaidSmsLimit ] = useState(PaidSmsLimit)
  const [ paidSmsLimitError, setPaidSmsLimitError ] = useState(
    PaidSmsLimit < PaidSentSmsCount,
  )

  const intl = useIntl()
  const { formState: { errors }, setError, clearErrors } = useForm()

  const timeFormatList = useMemo(() => {
    const now = DateTime.local()
    return Object.entries(consts.PLATFORM_TIME_FORMAT).map(item => ({
      id: item[0],
      name: now.toFormat(item[1]),
    }))
  }, [])

  const dateFormatList = useMemo(() => {
    const now = DateTime.local()
    return Object.entries(consts.PLATFORM_DATE_FORMAT).map(item => ({
      id: item[0],
      name: now.toFormat(item[1]),
    }))
  }, [])

  const timezonesList = useMemo(() => timezones.map(item => ({
    id: item.name,
    name: `${item.name} (${item.offset})`,
  })), [])

  const customFileRef = useRef()
  const requirementsFileRef = useRef()

  const updateGlobalSetting = useCallback(async (name, value) => {
    await onChangeGlobalSetting(name, value)
    localStorage.setItem(name, value)
    // FIXME: think how to make it more friendly
    if (name === 'EnvironmentColor') {
      document.documentElement.style.setProperty('--custom-color', value)
    }
  }, [ onChangeGlobalSetting ])

  const onChangeEnvironmentName = debounce(({ target }) => {
    updateGlobalSetting('EnvironmentName', target.value)
  }, 1500)

  const handleDownload = () => {
    wizataApi.settingsPipelines.getCustom()
      .then((text) => {
        const element = document.createElement('a')
        const file = new Blob([ text ], { type: 'text/plain' })
        element.href = URL.createObjectURL(file)
        element.download = 'custom.ini'
        document.body.appendChild(element) // Required for this to work in FireFox
        element.click()
      })
  }
  const handleDownloadPy = (e) => {
    wizataApi.settingsPipelines.getRequirements()
      .then((text) => {
        const element = document.createElement('a')
        const file = new Blob([ text ], { type: 'text/plain' })
        element.href = URL.createObjectURL(file)
        element.download = 'requirements.txt'
        document.body.appendChild(element) // Required for this to work in FireFox
        element.click()
      }).catch((err) => {
        events.emit('app:notify', {
          type: 'error',
          title: 't/settings.pipelines.error',
          message: err.message,
          duration: 5000,
        })
      })
  }

  const handleUploadConfiguration = useCallback(async (event, name) => {
    const { endpoint, fileName, reconfigure } = uploadConfiguration[name]
    const fileList = event.target.files
    clearErrors('settings.development.customConfiguration')
    const formData = new FormData()
    const file = fileList[0]

    const prepareFile = await convertToBase64(file)
    const prep = await dataUrlToFile(prepareFile, fileName)
    formData.append('file', prep, fileName)
    const token = await auth.acquireToken()

    fetch(endpoint, {
      method: 'PUT',
      body: formData,
      headers: {
        Authorization: `BEARER ${token}`,
      },
    })
      .then(async () => {
        if (reconfigure) {
          await wizataApi.pipelines.reconfigure()
        }
        events.emit('app:notify', {
          type: 'success',
          title: 't/settings.pipelines.uploadTitleSuccess',
          message: 't/settings.pipelines.uploadSuccess',
          duration: 5000,
        })
        customFileRef.current.value = ''
        requirementsFileRef.current.value = ''
      })
      .catch((err) => {
        setError('settings.file', { type: 'custom', message: err.message })
        events.emit('app:notify', {
          type: 'error',
          title: 't/settings.pipelines.uploadError',
          message: err.message,
          duration: 5000,
        })
      })
  }, [ clearErrors, setError ])

  useEffect(() => {
    setCurrentFreeSmsLimit(FreeSmsLimit)
    setFreeSmsLimitError(FreeSmsLimit < FreeSentSmsCount)
    setCurrentPaidSmsLimit(PaidSmsLimit)
    setPaidSmsLimitError(PaidSmsLimit < PaidSentSmsCount)
  }, [ FreeSmsLimit, PaidSmsLimit, FreeSentSmsCount, PaidSentSmsCount ])

  return (
    <CustomScrollbars
      horizontal={false}
      shadow={false}
    >
      <div className="d-flex flex-column container mt-4" style={{ maxWidth: 800 }}>
        {auth.checkAccessManage('PlatformEnvironmentSettings') ? (
          <Form>
            <FormSection title={intl.t('settings.environment.title')}>
              <FormField
                label={intl.t('settings.general.environmentName')}
                description={intl.t('settings.general.environmentNameDescr')}
              >
                <input
                  type="text"
                  className="form-control me-2"
                  defaultValue={settings.EnvironmentName}
                  onChange={onChangeEnvironmentName}
                  disabled={!auth.checkAccessManage('SectionGeneralSettings')}
                />
              </FormField>

              <FormFieldInline
                label={intl.t('settings.general.color')}
                description={intl.t('settings.general.colorDescr')}
              >
                <FormControl
                  type="color"
                  clearable
                  defaultColor={settings.EnvironmentColor}
                  onChange={value => updateGlobalSetting('EnvironmentColor', value)}
                  disabled={!auth.checkAccessManage('SectionGeneralSettings')}
                />
              </FormFieldInline>

              <FormField
                label={intl.t('settings.general.logo')}
                description={intl.t('settings.general.logoDescr')}
                errors={get(errors, 'settings.file')}
              >
                <div className="d-flex">
                  <input
                    type="file"
                    className="d-none"
                    accept="image/png, image/jpeg, image/svg+xml"
                    disabled={!auth.checkAccessManage('SectionGeneralSettings')}
                    onChange={async (event) => {
                      const fileList = event.target.files
                      const file = fileList[0]
                      clearErrors('settings.file')
                      const error = validateImageMaxSize(file)
                      if (error) {
                        setError('settings.file', { type: 'custom', message: error })
                      } else {
                        const image = await convertToBase64(file)
                        updateGlobalSetting('EnvironmentLogo', image)
                        updateFavicon(image, file.name)
                      }
                    }}
                  />
                  {settings.EnvironmentLogo ?
                    <img src={settings.EnvironmentLogo} alt="logo" width={40} height={40} /> : (
                      <Icon
                        name={appEnv.WIZ_CLIENT_LOGO}
                        width={40}
                        height={40}
                      />
                    )}
                  <span
                    className="btn btn-fill-primary text-nowrap ms-2 w-100"
                  >
                    Upload Logo
                  </span>
                </div>
              </FormField>
            </FormSection>
          </Form>
        ) : null}

        {auth.checkAccessManage('PlatformTimeSettings') ? (
          <FormSection title={intl.t('settings.region.title')}>
            <FormField
              label={intl.t('settings.general.timeFormat')}
              description={intl.t('settings.general.timeFormatDescr')}
            >
              <FormSelectNative
                options={timeFormatList}
                value={settings.PlatformTimeFormat}
                onChange={value => onChangeGlobalSetting('PlatformTimeFormat', value)}
              />
            </FormField>

            <FormField
              label={intl.t('settings.general.dateFormat')}
              description={intl.t('settings.general.dateFormatDescr')}
            >
              <FormSelectNative
                options={dateFormatList}
                value={settings.PlatformDateFormat}
                onChange={value => onChangeGlobalSetting('PlatformDateFormat', value)}
              />
            </FormField>

            <FormField
              label={intl.t('settings.general.dateTimeZone')}
              description={intl.t('settings.general.dateTimeZoneDescr')}
            >
              <Select
                options={timezonesList}
                value={settings.PlatformDateTimeZoneName || 'Etc/UTC'}
                onChange={onChangeZone}
              />
            </FormField>
          </FormSection>
        ) : null}

        {auth.checkAccessManage('SMSNotificationsSettings') ? (
          <FormSection title={intl.t('settings.smsNotify.title')}>
            <FormField
              label={intl.t('settings.smsNotify.form.enable')}
              description={intl.t('settings.smsNotify.form.enableDescr')}
              complex
            >
              <FormSwitch
                value={Boolean(settings.SmsSendingEnabled)}
                onChange={value => onChangeGlobalSetting('SmsSendingEnabled', value)}
              />

              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="d-flex align-items-center">
                <FormCheckbox
                  className="me-2"
                  value={Boolean(settings.PaidFunctionalityConfirm)}
                  onChange={value => onChangeGlobalSetting('PaidFunctionalityConfirm', value)}
                />
                {intl.t('settings.smsNotify.form.paidConfirm')}
              </label>
            </FormField>

            <FormField
              label={intl.t('settings.smsNotify.form.freeSmsLimit')}
              description={intl.t('settings.smsNotify.form.freeSmsLimitDescr')}
              errors={freeSmsLimitError ? {
                message: intl.t('settings.smsNotify.form.errors.minSmsLimit', {
                  value: FreeSentSmsCount,
                }),
              } : undefined}
            >
              <FormInputNumber
                min={0}
                invalid={freeSmsLimitError}
                value={currentFreeSmsLimit}
                onChange={(value) => {
                  setFreeSmsLimitError(value < FreeSentSmsCount)
                  setCurrentFreeSmsLimit(value)
                  if (value >= FreeSentSmsCount) {
                    onChangeGlobalSetting('FreeSmsLimit', value)
                  }
                }}
                disabled={!auth.checkAccessManage('SettingFreeSmsLimit')}
              />
            </FormField>

            <FormField
              label={intl.t('settings.smsNotify.form.paidSmsLimit')}
              description={intl.t('settings.smsNotify.form.paidSmsLimitDescr')}
              errors={paidSmsLimitError ? {
                message: intl.t('settings.smsNotify.form.errors.minSmsLimit', {
                  value: PaidSentSmsCount,
                }),
              } : undefined}
            >
              <FormInputNumber
                min={0}
                invalid={paidSmsLimitError}
                value={currentPaidSmsLimit}
                onChange={(value) => {
                  setPaidSmsLimitError(value < PaidSentSmsCount)
                  setCurrentPaidSmsLimit(value)
                  if (value >= PaidSentSmsCount) {
                    onChangeGlobalSetting('PaidSmsLimit', value)
                  }
                }}
                disabled={!settings.PaidFunctionalityConfirm}
              />
            </FormField>

            <FormField
              label={intl.t('settings.smsNotify.form.monthUsage')}
              description={intl.t('settings.smsNotify.form.monthUsageDescr')}
            >
              <b>{settings.SentSmsCount || 0}</b>
              &nbsp;/&nbsp;
              <b>{(FreeSmsLimit + PaidSmsLimit)}</b>
            </FormField>

            <FormField
              label={intl.t('settings.smsNotify.form.limitSheetList')}
              description={intl.t('settings.smsNotify.form.limitSheetListDescr')}
            >
              <FormSwitch
                value={Boolean(settings.SendEmailNotificationGroup)}
                onChange={value => onChangeGlobalSetting('SendEmailNotificationGroup', value)}
              />
            </FormField>

            <FormField
              label={intl.t('settings.smsNotify.form.selectedRecipients')}
              description={intl.t('settings.smsNotify.form.selectedRecipientsDescr')}
            >
              <Select
                value={settings.EmailNotificationGroup}
                ListContent={UserListContent}
                ListSource={UserList}
                multiselect
                onChange={value => onChangeGlobalSetting('EmailNotificationGroup', value)}
              />
            </FormField>
          </FormSection>
        ) : null}

        {auth.checkAccessManage('PlatformDataSettings') ? (
          <FormSection
            title={intl.t('settings.data.title')}
          >
            <FormField
              label={intl.t('settings.data.form.rawDataLimit')}
              description={intl.t('settings.data.form.rawDataLimitDescr')}
            >
              <FormInputDuration
                min={0}
                value={Number(settings.RawDataLimit || 0)}
                parts="months,weeks,days,hours,minutes,seconds"
                onChange={value => onChangeGlobalSetting('RawDataLimit', value)}
              />
            </FormField>
          </FormSection>
        ) : null}
        {auth.checkAccessManage('SectionGeneralSettings') ? (
          <FormSection title={intl.t('settings.development.title')}>
            <FormField
              label={intl.t('settings.development.customConfiguration.title')}
              description={intl.t('settings.development.customConfiguration.description')}
              errors={get(errors, 'settings.development.customConfiguration')}
              complex
            >
              <div className="d-flex">
                <button
                  name="download"
                  type="button"
                  data-testid="downloadCustomConfiguration"
                  className="btn btn-outline-secondary"
                  onClick={handleDownload}
                >
                  <span className="">{intl.t('form.actions.download')}</span>
                </button>
                <label className="btn btn-fill-primary ms-2" htmlFor="custom">
                  <input
                    id="custom"
                    type="file"
                    className="d-none"
                    accept=".ini, .txt"
                    ref={customFileRef}
                    onChange={async (event) => {
                      await window.wizConfirm({ message: 't/settings.confirmUpload' })
                      handleUploadConfiguration(event, 'custom')
                    }}
                  />
                  <span>
                    {intl.t('form.actions.upload')}
                  </span>
                </label>
              </div>
            </FormField>

            <FormField
              label={intl.t('settings.development.customPytonPackages.title')}
              description={intl.t('settings.development.customPytonPackages.description')}
              complex
            >
              <div className="d-flex">
                <div>
                  <button
                    name="download"
                    type="button"
                    data-testid="downloadCustomPytonPackages"
                    className="btn btn-outline-secondary"
                    onClick={handleDownloadPy}
                  >
                    <span className="">{intl.t('form.actions.download')}</span>
                  </button>
                </div>
                <label className="btn btn-fill-primary ms-2" htmlFor="py">
                  <input
                    id="py"
                    type="file"
                    className="d-none"
                    accept=".ini, .txt"
                    ref={requirementsFileRef}
                    onChange={async (event) => {
                      await window.wizConfirm({ message: 't/settings.confirmUpload' })
                      handleUploadConfiguration(event, 'py')
                    }}
                  />
                  <span>
                    {intl.t('form.actions.upload')}
                  </span>
                </label>
              </div>
            </FormField>
          </FormSection>
        ) : null}
      </div>
    </CustomScrollbars>
  )
}
