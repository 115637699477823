import {
  useEffect, useRef, useState, useCallback, useMemo,
} from 'react'
import Plotly from 'plotly.js-dist-min'
import { useQueries } from '@tanstack/react-query'
import cx from 'classnames'
import { Tabs, Icon } from '@wiz/components'
import { useRouter, useLocationQuery } from '@/router'
import { wizataApi } from '@/api'
import { useTheme } from '@/theme'
import IconFa from '@/shared/icon'
import classes from './index.module.css'

const config = {
  displaylogo: false,
  responsive: true,
}

const colorByTheme = {
  sun: '#48465b',
  moon: '#d8d9da',
}

const CombinedPlots = ({
  plotsList,
}) => {
  const [ tab, setTab ] = useState(0)
  const { view } = useLocationQuery()

  const fullPlots = useQueries({
    queries: [ ...(plotsList ? plotsList.map(({ id }) => (
      {
        queryKey: [ 'plot', id ],
        queryFn: () => wizataApi.plots.getById(id),
        staleTime: Infinity,
      }
    )) : []) ],
    enabled: !!plotsList,
  })

  const handleChangeTab = useCallback((id) => {
    setTab(+id)
  }, [])

  const charts = useMemo(() => {
    const next = [ ]
    if (plotsList) {
      if (plotsList?.length) {
        const c = next.length
        const prep = plotsList?.map(({ id, name }, idx) => ({
          value: idx + c, label: name, id,
        }))
        next.push(...prep)
      }
    }
    return next
  }, [ plotsList ])

  const ChartMemo = useMemo(() => {
    const current = fullPlots.find(({ data }) => data?.id === charts[tab]?.id)
    return (
      <PlotlyChart
        plot={current}
      />
    )
  }, [ fullPlots, charts, tab ])

  if (view === 'grid') {
    return (
      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <div className={classes.grid}>
          {fullPlots?.map(plot => (
            <div key={plot?.data?.name}>
              {plot?.isFetching ? <IconFa name="faSpinner" spin /> : (
                <>
                  <div className="text-center mb-2">{plot?.data?.name}</div>
                  <div>
                    <PlotlyChart
                      plot={plot}
                    />
                  </div>
                </>
              )}

            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
      <Tabs
        className="mb-2"
        value={+tab}
        options={charts}
        onChange={handleChangeTab}
      />
      {ChartMemo}
    </div>
  )
}

const PlotlyChart = ({ plot, className }) => {
  const refTarget = useRef()
  const theme = useTheme()

  const figure = plot?.data?.figure && JSON.parse(plot.data.figure)

  useEffect(() => {
    if (figure?.data) {
      Plotly.react(refTarget.current, {
        data: figure?.data,
        layout: {
          ...figure?.layout,
          autosize: true,
          mapbox: {
            ...figure?.layout?.mapbox,
            accesstoken: 'pk.eyJ1IjoiZGF1cmVuYmVremhhbm92IiwiYSI6ImNsNTlub2tpbTExOGszcG5yMTlmcG4xcGoifQ.Tf1x8q2I1DWLDu0HpgJhQQ',
          },
          paper_bgcolor: 'rgba(0,0,0,0)',
          plot_bgcolor: 'rgba(0,0,0,0)',
          font: { color: colorByTheme[theme.name] },
        },
        config,
      })
    }
  }, [ figure, theme.name ])

  useEffect(() => {
    const target = refTarget.current
    return () => {
      Plotly.purge(target)
    }
  }, [])

  if (plot?.isLoading) {
    return <div className={cx('w-100 h-100', className)}><Icon spin name="fa--spinner" size="3X" /></div>
  }

  if (!plot) {
    return null
  }

  return (
    <div
      ref={refTarget}
      className={cx('w-100 h-100', className)}
    />
  )
}

export default CombinedPlots
