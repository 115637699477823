import {
  useCallback, useState, useRef, useEffect,
} from 'react'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from '@/router'
import { Icon } from '@wiz/components'
import { wizataApi } from '@/api'
import { useIntl } from '@wiz/intl'
import { useSignal } from '@/components/Pipelines/pipeline/hooks/useSignal'
import useAppContext from '@/hooks/useAppContext'
import Registrations from './components/registrations'
import FormRegistrations from './forms/registrations'
import FormProperties from './forms/properties'

const Template = ({ match: { params: { id } } }) => {
  const [ editId, setEditId ] = useState()
  const [ editRegistration, setEditRegistration ] = useState()
  const [ isRunning, setRunning ] = useState(false)

  const experimentRef = useRef()

  const { onFilter } = useAppContext()

  const intl = useIntl()
  const router = useRouter()

  const {
    destroy,
  } = useSignal()

  const {
    data: template, isLoading, isError, error,
  } = useQuery({
    queryKey: [ 'template', id ],
    queryFn: () => wizataApi.templates.getById(id),
    enabled: !!id,
    staleTime: Infinity,
    retry: false,
  })

  const {
    data: registrations,
    isLoading: registrationsLoading,
    refetch,
    isError: isRegistrationsError,
    error: registrationError,
    isRefetching,
  } = useQuery({
    queryKey: [ 'templateRegistrations', id ],
    queryFn: () => wizataApi.registrations.getList(id),
    enabled: !!id && !!template,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const handleChoosePage = useCallback((id) => {
    router.push({ query: { current: id } })
  }, [ router ])

  const handleRefetchRegistration = useCallback(() => {
    refetch()
  }, [ refetch ])

  const handlePlay = () => {
    experimentRef.current?.onPlay()
  }

  // const handleSelectExecution = (item) => {
  //   experimentRef.current?.onSelectExecution(item)
  // }

  const handleToggleRunning = useCallback(() => {
    if (isRunning) {
      destroy()
    }
    setRunning(!isRunning)
  }, [ isRunning, destroy ])

  useEffect(() => {
    onFilter({ templateId: id })
  }, [])

  if (!id || isError) {
    return (
      <div className="position-center-fill flex-column h-100">
        <h4>{intl.t('template.loadingError')}</h4>
        <p className="text-danger">{error.message}</p>
        <button
          type="button"
          className="btn btn-fill-secondary"
          onClick={refetch}
        >
          {intl.t('form.actions.refresh')}
        </button>
      </div>
    )
  }

  const loading = isLoading || registrationsLoading

  return (
    <>
      {loading ? (
        <div className="position-absolute-fill position-center-fill bg-light opacity-50">
          <Icon name="fa--spinner" size="2X" spin />
        </div>
      ) : (
        <Registrations
          id={id}
          list={registrations}
          onRefetchRegistrations={handleRefetchRegistration}
          onEdit={(reg) => {
            setEditId('registration')
            setEditRegistration(reg)
          }}
          isError={isRegistrationsError}
          error={registrationError}
          isUpdating={isRefetching}
        />
      )}

      {editId === 'registration' ? (
        <FormRegistrations
          id={id}
          template={template}
          onClose={() => setEditId(null)}
          registration={editRegistration}
          registrations={registrations}
          onRefetch={handleRefetchRegistration}
          dialog={{
            title: intl.t('registrations.registration.title'),
            dataTestid: 'updateRegistrationDialog',
          }}
        />
      ) : null}

      {editId === 'properties' ? (
        <FormProperties
          id={id}
          template={template}
          onClose={() => setEditId(null)}
          onRefetch={handleRefetchRegistration}
          dialog={{
            title: intl.t('registrations.properties.title'),
            dataTestid: 'updatePropertiesDialog',
            size: 'sm',
          }}
        />
      ) : null}
    </>
  )
}

export default Template
