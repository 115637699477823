/* eslint-disable no-unused-expressions */
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import { get } from '@wiz/utils'
import {
  FormControl,
  FormField,
  FormFieldInline,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import SelectSensor from '@/components/Form/SelectSensor'
import FormatDateTime from '@/containers/FormatDateTime'

export default function Fields ({
  scope,
  businessType,
}) {
  const intl = useIntl()
  const {
    formState: { errors },
    watch,
  } = useFormContext()

  const [ onlyNow ] = watch([ `${scope}.onlyNow` ])

  return (
    <>
      <FormField
        label={intl.t('form.fields.dataPoint')}
        description={intl.t('sensors.form.fields.dataPointDescr')}
        errors={get(errors, `${scope}.sensorId`)}
      >
        <Controller
          name={`${scope}.sensorId`}
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorsPlaceholder')}
              multiselect
              filters={{
                businessType,
                canInputValue: true,
              }}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('sensors.form.fields.payload')}
        description={intl.t('sensors.form.fields.payloadDescr')}
        errors={get(errors, `${scope}.value`)}
      >
        <FormControl
          type="number"
          name={`${scope}.value`}
          scale={8}
          signed
          clearable
          rules={{
            validate: (value) => {
              let result = true
              if (!Number.isFinite(value)) {
                result = intl.t('form.errors.fieldRequired')
              }
              return result
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('sensors.form.fields.valueTime')}
        description={intl.t('sensors.form.fields.valueTimeDescr')}
        errors={get(errors, `${scope}.timestamp`)}
      >
        <FormControl
          type="dateTime"
          name={`${scope}.timestamp`}
          future={false}
          disabled={onlyNow}
          rules={{
            validate: (value) => {
              let result = true
              if (!value && !onlyNow) {
                result = intl.t('form.errors.fieldRequired')
              }
              return result
            },
          }}
          DateTimeComponent={FormatDateTime}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('sensors.form.fields.onlyNow')}
        description={intl.t('sensors.form.fields.onlyNowDescr')}
        errors={get(errors, `${scope}.onlyNow`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.onlyNow`}
        />
      </FormFieldInline>
    </>
  )
}

Fields.propTypes = {
  scope: PropTypes.string.isRequired,
  businessType: PropTypes.string,
}

Fields.defaultProps = {
  businessType: undefined,
}
