import { withProps } from '@wiz/components'
import { consts } from '@wiz/utils'
import Component from '@/components/RightBarInfo'
import enhanceData from './enhanceData'

const enhanceMeasurementData = withProps(() => ({
  businessType: consts.SensorBusinessType.Measurement,
}))

export default enhanceMeasurementData(
  enhanceData(Component),
)
