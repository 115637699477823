import { Icon } from '@wiz/components'
import { auth } from '@/auth'

export default function CellTwin ({
  options,
  onFilter,
}) {
  if (!options.length) {
    return '-'
  }

  const twins = options.filter(item => auth.checkAccessRead(item))

  if (!twins.length) {
    return null
  }

  return (
    <div className="d-flex align-items-center me-2 min-w-0">
      <button
        type="button"
        className="btn btn-sm p-0 border-0 me-2"
        onClick={() => onFilter?.({ twins: twins.map(({ id }) => id) })}
      >
        <Icon
          name="fa--search"
        />
      </button>

      <span className="text-truncate">
        {twins.map(({ displayName }) => displayName).join(', ')}
      </span>
    </div>
  )
}
