import {
  useState, useCallback, useMemo, useEffect,
} from 'react'
import {
  string, shape, func, arrayOf,
} from 'prop-types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DiagramBlockFlow } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import { uuid } from '@wiz/utils'
import useAppContext from '@/hooks/useAppContext'
import { validateExperimentStep } from '@/utils/experiments'
import { wizataApi } from '@/api'
import events from '@/utils/events'
import Icon from '@/shared/icon'
import {
  TimeRangeChooser,
  Filter,
  ConnectionList,
  ConnectionEdit,
  Datapoints,
  Sensitivity,
  Target,
} from './AnomalyDetection'
import { ActionButtons } from './ActionButtons'
import { ChooseSolution } from './ChooseSolution'

const SENSITIVITY_OPTIONS = [ 'Very Low', 'Low', 'Medium', 'High', 'Very High' ]

const STEPS_ANN = {
  name: 'name',
  dpSelect: 'dp_select',
  target: 'target',
  target_opt: 'target',
  timeAgg: 'time_agg',
  connections: 'connections',
  filters: 'filters',
  exProperties: 'exProperties',
}

const STEPS = [ STEPS_ANN.name, STEPS_ANN.dpSelect, STEPS_ANN.target, STEPS_ANN.timeAgg ]

const DEFAULT_STEPS_BY_ORDER = {
  0: STEPS_ANN.name,
  1: STEPS_ANN.dpSelect,
  2: STEPS_ANN.target,
  3: STEPS_ANN.timeAgg,
  4: STEPS_ANN.connections,
  5: STEPS_ANN.filters,
  6: STEPS_ANN.exProperties,
}

const buildStepper = (steps, isExperiment, isScript) => {
  if (isScript) {
    return {
      0: STEPS_ANN.name,
      1: STEPS_ANN.dpSelect,
      2: STEPS_ANN.timeAgg,
      3: STEPS_ANN.filters,
    }
  }

  if (!steps) {
    return null
  }

  const stepper = steps.reduce((acc, { id, order }) => {
    acc[order - 1] = id

    return acc
  }, {})
  // 0: 'dp_select'
  return stepper
}

export default function RightBarWizard ({
  blockSettings,
  sensorIds,
  onToggleByTwin,
  onChangeSelection,
  experiment,
  diagramBlocks,
  onBlockRemove,
  twins,
  widgetSensors = [],
  onChange,
  customFunction,
  hardwareIds,
  selectedSettings,
  handleSettingsUpdate,
  onChangeDatapoints,
  steps,
  fullData,
  isScript,
  onSelectExperiment,
}) {
  const [ step, setStep ] = useState(0)
  const [ dates, setDates ] = useState(
    {
      dateFrom: undefined,
      dateTo: undefined,
    },
  )
  const [ aggregations, setAggregations ] = useState(
    {
      aggMethod: undefined,
      interval: undefined,
      option: 'drop',
    },
  )
  const [ meta, setMeta ] = useState({ name: experiment?.name || '' })
  const [ filters, setFilters ] = useState([])
  const [ errors, setErrors ] = useState({ connections: '' })
  const [ connections, setConnections ] = useState(experiment?.connections || [])
  const [ connectionEditId, setConnectionEditId ] = useState(null)
  const [ isConnectionPreStep, setConnectionPreStep ] = useState(true)
  const [ sensitivity, setSensitivity ] = useState(SENSITIVITY_OPTIONS[2])
  const [ target, setTarget ] = useState(null)
  const { selectedTwinIds } = useAppContext()

  const intl = useIntl()

  const queryClient = useQueryClient()

  const {
    mutateAsync: create, isError: createError, isLoading: createLoading,
  } = useMutation({
    mutationKey: [ 'createExperiment' ],
    mutationFn: data => wizataApi.wizard.createExperiment(data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/experiments.execution.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      Promise.all([
        queryClient.invalidateQueries([ 'execution', fullData?.id ]),
        queryClient.invalidateQueries([ 'plots', fullData?.id ]),
      ])
    },
  })

  const handleDatesChange = useCallback((data) => {
    setDates(data)
  }, [])

  const handlePreStepClose = useCallback(() => {
    setConnectionPreStep(false)
  }, [])

  const handleTargetSelect = useCallback((targetData) => {
    setTarget(prev => ({ ...prev, ...targetData }))
  }, [])

  const handleSubmit = useCallback(async () => {
    const SHIFT = 1800

    const list = twins?.filter(twin => twin.datapoints.length) || []

    // add widget sensors to the list
    const widgetSensorsList = widgetSensors.length ?
      widgetSensors.map(sensor => ({ id: sensor.hardwareId, type: sensor.businessType })) : []
    if (widgetSensorsList.length) {
      list.push({ id: uuid(), datapoints: widgetSensorsList })
    }

    const _sensitivity = SENSITIVITY_OPTIONS.indexOf(sensitivity) + 1

    const eqList = list.reduce((acc, item) => {
      const datapoints = item.datapoints.map(dp => dp?.id)
      if (datapoints.length) {
        acc.push({ ...item, datapoints })
      }

      return acc
    }, [])

    const equipmentsShiftIds = eqList?.map((_, idx) => idx).reverse()

    const prepareFilters = filters?.length ? filters.reduce((acc, { filterSensor, operator, threshold }) => {
      acc[filterSensor] = {
        ...acc[filterSensor],
        [operator]: threshold,
      }

      return acc
    }, {}) : {}

    const data = {
      name: meta.name,
      equipments_list: eqList.map((item, idx) => ({ ...item, shift: `${equipmentsShiftIds[idx] * SHIFT}s` })),

      timeframe: {
        start: dates.dateFrom,
        end: dates.dateTo,
      },

      aggregations: {
        agg_method: aggregations.aggMethod,
        interval: aggregations.interval,
      },
      null: aggregations.option,

      filters: prepareFilters,
      connections,

      sensitivity: _sensitivity,

      restart_filter: {
        on_off_sensor: null,
        stop_restart_time: '60s',
      },
    }

    if (customFunction) {
      data.function = customFunction.function
    }

    if (target && target.sensor) {
      data.target_feat = target
    }

    const created = await create({
      name: meta.name,
      request: JSON.stringify(data),
      // properties: {},
      function: customFunction?.function || null,
      isAnomalyDetection: !customFunction,
      twinId: selectedTwinIds?.[0],
    })

    onChange?.(
      'experiment',
      {
        title: meta.name,
        props: {
          id: experiment?.id || created.experimentId,
          customFunction: !!customFunction,
          isLoading: createLoading,
          isError: createError,
        },
      },
    )
  }, [
    dates,
    aggregations,
    connections,
    filters,
    meta,
    sensitivity,
    twins,
    create,
    onChange,
    customFunction,
    widgetSensors,
    target,
    experiment,
    createLoading,
    createError,
  ])

  const handleConnectionEdit = useCallback((connectionId) => {
    setConnectionEditId(connectionId)
  }, [])

  const handleAggregationsChange = useCallback((data) => {
    setAggregations(data)
  }, [])

  const handleConnectionConfirm = useCallback((data) => {
    const nextConnections = [ ...connections ]
    const edited = { connectionId: connectionEditId, ...data }
    nextConnections.push(edited)
    setConnections(nextConnections)

    setConnectionEditId()
  }, [ connections, connectionEditId ])

  const handleStoreConnections = useCallback((data) => {
    setConnections(data)
  }, [])

  const connectionsBlock = useMemo(() => diagramBlocks
    .filter(block => block instanceof DiagramBlockFlow && !block.hyperedge), [ diagramBlocks ])

  const handleErrors = useCallback((errSet) => {
    setErrors(errSet)
  }, [])

  const getError = useCallback((key) => {
    const error = errors[key] || null
    return error
  }, [ errors ])

  const customSteps = customFunction?.steps ? JSON.parse(customFunction.steps) : steps

  const preparedSteps = buildStepper(customSteps, !!experiment, isScript) ||
    DEFAULT_STEPS_BY_ORDER

  const current = preparedSteps[step]

  const getDataForValidation = useCallback(() => {
    const list = twins?.filter(({ datapoints }) => datapoints.length) || []

    const eqList = list.concat(hardwareIds)

    const dataSet = {
      [STEPS_ANN.name]: meta,
      [STEPS_ANN.dpSelect]: eqList,
      [STEPS_ANN.timeAgg]: { ...dates, ...aggregations },
      [STEPS_ANN.target]: target,
    }

    return dataSet[current]
  }, [
    aggregations,
    dates,
    hardwareIds,
    meta,
    twins,
    target,
    current,
  ])

  const connectionsStep = Object.values(preparedSteps).indexOf(STEPS_ANN.connections)

  const maxStepsCount = Object.values(preparedSteps).length - 1

  const handleStep = useCallback(async (next) => {
    const values = buildStepper(customSteps, !!experiment, isScript)

    const _STEPS = values ? Object.values(values) : STEPS

    if (next > step && !meta.experimentId) {
      const isValid = await validateExperimentStep(
        _STEPS[step],
        getDataForValidation(),
        handleErrors,
      )

      if (!isValid) {
        return null
      }
    }
    if (step === 0 && next === 1 && meta.experimentId) {
      onSelectExperiment(meta.experimentId)
    }

    const twinsCount = twins ? twins.reduce((acc, twin) => {
      if (twin.datapoints?.length) {
        acc += 1
      }
      return acc
    }, 0) : 0

    const widgetCount = widgetSensors?.length || 0

    const count = twinsCount + widgetCount

    if (step === connectionsStep - 1 && count < connectionsStep - 1 && next > step) {
      setStep(next + 1)
    } else if (step === connectionsStep + 1 && count < connectionsStep - 1 && next < step) {
      setStep(next - 1)
    } else {
      setStep(next)
    }
  }, [
    step,
    twins,
    widgetSensors,
    getDataForValidation,
    handleErrors,
    connectionsStep,
    customSteps,
    isScript,
    experiment,
    meta,
    onSelectExperiment,
  ])

  useEffect(() => {
    if (experiment) {
      const {
        aggregations, sensitivity, timeframe, filters,
      } = experiment

      setDates({
        dateFrom: timeframe.start,
        dateTo: timeframe.end,
      })

      setAggregations({
        aggMethod: aggregations.agg_method,
        interval: aggregations.interval,
        option: experiment.null,
      })

      const prepFilters = Object.entries(filters).map(([ key, value ]) => {
        const [ values ] = Object.entries(value)
        return {
          id: key + values[0] + values[1],
          filterSensor: key,
          operator: values[0],
          threshold: values[1],
        }
      })

      setFilters(prepFilters)

      setSensitivity(SENSITIVITY_OPTIONS[sensitivity - 1])
    }
  }, [ experiment ])

  // !TODO: think on how to improve this steps
  const component = useMemo(() => ({
    [STEPS_ANN.name]: <>
      <div className="d-flex flex-wrap mt-2 px-3 m-1">
        <b>{intl.t('experiments.wizard.titleName')}</b>
      </div>
      <ChooseSolution
        onChange={setMeta}
        meta={meta}
        getError={getError}
        isScript={isScript}
      />
    </>,
    [STEPS_ANN.dpSelect]: <Datapoints
      blockSettings={blockSettings}
      sensorIds={sensorIds}
      onChangeSelection={onChangeSelection}
      onToggleByTwin={onToggleByTwin}
      widgetSensors={widgetSensors}
      getError={getError}
      selectedSettings={selectedSettings}
      handleSettingsUpdate={handleSettingsUpdate}
      onChange={onChangeDatapoints}
      isEdit={!!experiment}
    />,
    [STEPS_ANN.timeAgg]: <>
      <div className="d-flex flex-wrap mt-2 px-3 m-1">
        <b>{intl.t('experiments.wizard.titleTimeframes')}</b>
      </div>
      <TimeRangeChooser
        dates={dates}
        aggregations={aggregations}
        onDatesChange={handleDatesChange}
        onAggregationsChange={handleAggregationsChange}
        getError={getError}
      />
    </>,
    [STEPS_ANN.connections]: connectionEditId ? (
      <ConnectionEdit
        onClose={() => handleConnectionEdit(null)}
        onEdit={handleConnectionConfirm}
      />
    ) :
      (
        <ConnectionList
          onNextStep={() => setStep(prev => prev + 1)}
          isPreStep={isConnectionPreStep}
          onClose={handlePreStepClose}
          blocks={connectionsBlock}
          onRemove={onBlockRemove}
          error={errors.connections}
          onEdit={handleConnectionEdit}
          onSave={handleStoreConnections}
        />

      ),
    [STEPS_ANN.filters]: <Filter
      onFiltersAdd={setFilters}
      filters={filters}
      options={hardwareIds}
      sensorIds={sensorIds}
    />,
    [STEPS_ANN.exProperties]: <Sensitivity
      value={sensitivity}
      onChange={setSensitivity}
      options={SENSITIVITY_OPTIONS}
    />,
    [STEPS_ANN.target]: <Target
      sensors={hardwareIds}
      sensorIds={sensorIds}
      onSave={handleTargetSelect}
      getError={getError}
      item={target}
      optional={!customFunction || Object.values(preparedSteps).includes('target_opt')}
      onOptional={() => setStep(step + 1)}
    />,
  }), [
    aggregations,
    blockSettings,
    connectionEditId,
    connectionsBlock,
    customFunction,
    dates,
    errors.connections,
    filters,
    getError,
    handleAggregationsChange,
    handleConnectionConfirm,
    handleConnectionEdit,
    handleDatesChange,
    handlePreStepClose,
    handleSettingsUpdate,
    handleStoreConnections,
    handleTargetSelect,
    hardwareIds,
    isConnectionPreStep,
    meta,
    onBlockRemove,
    onChangeDatapoints,
    onChangeSelection,
    onToggleByTwin,
    preparedSteps,
    selectedSettings,
    sensitivity,
    sensorIds,
    step,
    target,
    widgetSensors,
    isScript,
    experiment,
    intl,
  ])

  const output = component[current]

  return (
    <div className="position-relative d-flex flex-fill flex-column">
      {output}

      <ActionButtons
        step={step}
        onClick={handleStep}
        onSubmit={handleSubmit}
        isValid
        nextDisabled={isConnectionPreStep}
        maxSteps={maxStepsCount}
        disabledSteps={connectionsStep}
        experimentId={!!meta.experimentId}
        isReExecution={!!experiment}
      />
      {createLoading ? (
        <div className="position-absolute-fill position-center-fill bg-light opacity-50">
          <Icon name="faSpinner" size="xl" spin />
        </div>
      ) : null}
    </div>
  )
}

RightBarWizard.propTypes = {
  blockSettings: shape({}),
  diagramBlocks: arrayOf(shape({})),
  sensorIds: arrayOf(string),
  onChange: func,
  onToggleByTwin: func,
  onChangeSelection: func,
}

RightBarWizard.defaultProps = {
  diagramBlocks: [],
  blockSettings: undefined,
  sensorIds: undefined,
  onChange: undefined,
  onToggleByTwin: undefined,
  onChangeSelection: undefined,
}
