import { useCallback } from 'react'
import { dbProvider, TwinGraph } from '@wiz/store'
import { withProps } from '@wiz/components'

export default withProps(() => {
  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareReplaceData(context, TwinGraph, data.twinGraph)
    await model.prepareReplaceTwins(context, data.twins)
    await dbProvider.batch(context)
    return model
  }, [])

  return {
    onSubmit,
  }
})
