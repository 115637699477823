import { useState, useCallback, useMemo } from 'react'
import classnames from 'classnames'
import { Card, useDidUpdate } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import Sensor from './Sensor'
import SensorInline from './SensorInline'

export default function Widget ({
  widget,
  config,
  explorerContext,
  twinSettings,
  hidden,
  canUpdateDashboard,
  onAction,
}) {
  const intl = useIntl()
  const auth = useAuth()
  const [ value, setValue ] = useState(null)

  const sensor = useMemo(() => (
    explorerContext?.dataSources?.[0]?.sensor
  ), [ explorerContext ])

  const category = useMemo(() => (
    config.displayCategoryName ? (sensor?.category ?? sensor?.unit?.category) : null
  ), [ sensor, config ])

  const handleChange = useCallback((data) => {
    if (data) {
      setValue(data)
    }
  }, [])

  let { title } = widget
  if (!title && config.displayDataPointName && sensor) {
    title = sensor.name
  }

  const subTitle = widget.title && config.displayDataPointName && sensor ?
    sensor.name : undefined

  if (!title && !subTitle) {
    title = intl.t(widget.info.name)
  }

  const actions = []
  if (canUpdateDashboard) {
    actions.push({ id: 'settings', name: intl.t('form.actions.settings'), icon: 'fa--cog' })
  }
  if (auth.checkAccessRead('SectionDataExplorer')) {
    actions.push({ id: 'dataExplorer', name: intl.t('form.actions.openExplorer'), icon: 'explorer' })
  }
  if (auth.checkAccessCopy(widget) && canUpdateDashboard) {
    actions.push({ id: 'duplicate', name: intl.t('form.actions.duplicate'), icon: 'fa--clone' })
    actions.push({ id: 'multiDuplicate', name: intl.t('form.actions.multiDuplicate'), icon: 'fa--folder-plus' })
  }
  if (canUpdateDashboard) {
    actions.push({ id: 'remove', name: intl.t('form.actions.remove'), icon: 'fa--trash-alt' })
  }

  useDidUpdate(() => {
    setValue(null)
  }, [ sensor?.hardwareId ])

  return widget.gridH <= 2 ? (
    <Card
      className={classnames('position-absolute-fill', {
        userdrag: canUpdateDashboard,
      })}
      classNameHeader="flex-fill flex-shrink-1 min-h-0 min-w-0 align-items-center my-0"
      ComponentTitle={() => do {
        if (sensor) {
          <SensorInline
            title={title}
            subTitle={subTitle}
            hidden={hidden}
            sensor={sensor}
            precision={config.precision}
            conditions={explorerContext?.conditions}
            twinSettings={twinSettings}
            value={value}
            onChange={handleChange}
          />
        } else if (canUpdateDashboard) {
          <div className="d-flex align-items-center justify-content-center flex-fill min-w-0">
            <button
              type="button"
              className="d-inline btn btn-sm btn-flat-secondary text-truncate"
              onClick={() => onAction({ id: 'settings' })}
            >
              {intl.t('widgets.selectSensor')}
            </button>
          </div>
        } else {
          <div className="d-flex align-items-center justify-content-center flex-fill">
            {intl.t('widgets.notConfigured')}
          </div>
        }
      }}
      options={actions}
      onAction={onAction}
    />
  ) : (
    <Card
      className="position-absolute-fill"
      classNameTitle={canUpdateDashboard ? 'userdrag' : undefined}
      title={title}
      description={widget.description}
      subTitle={subTitle}
      options={actions}
      onAction={onAction}
    >
      {do {
        if (sensor) {
          <Sensor
            hidden={hidden}
            sensor={sensor}
            category={category}
            precision={config.precision}
            conditions={explorerContext?.conditions}
            twinSettings={twinSettings}
            value={value}
            onChange={handleChange}
          />
        } else if (canUpdateDashboard) {
          <div className="d-flex align-items-center justify-content-center flex-fill">
            <button
              type="button"
              className="btn btn-flat-secondary"
              onClick={() => onAction({ id: 'settings' })}
            >
              {intl.t('widgets.selectSensor')}
            </button>
          </div>
        } else {
          <div className="d-flex align-items-center justify-content-center flex-fill">
            {intl.t('widgets.notConfigured')}
          </div>
        }
      }}
    </Card>
  )
}
