/* eslint-disable no-unused-expressions */
import { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import { get, consts } from '@wiz/utils'
import {
  FormField,
  FormInput,
  FormSwitch,
  FormInputNumber,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Select from '@/components/Form/Select'
import SelectTwin from '@/components/Form/SelectTwin'
import SelectSensor from '@/components/Form/SelectSensor'
import ObservDeviceCommandTemplate from '@/containers/Forms/DeviceCommandTemplate/Observ'
import EdgeDeviceList from '@/hoc/EdgeDeviceList'
import DeviceCommandTemplateList from '@/hoc/DeviceCommandTemplateList'

export default function Fields ({
  scope,
  withTwin,
  withSensorsByTwin,
  withSensorsByDevice,
  withTemplatePayloadPriority,
}) {
  const intl = useIntl()
  const [ dataType, setDataType ] = useState(null)
  const {
    formState: { errors },
    watch,
    getValues,
    setValue,
    clearErrors,
  } = useFormContext()

  const [
    twinId,
    deviceId,
    templateId,
  ] = watch([
    `${scope}.twinId`,
    `${scope}.deviceId`,
    `${scope}.templateId`,
  ])

  const dataPointFilters = useMemo(() => (withSensorsByTwin || withSensorsByDevice ? {
    twinIds: withSensorsByTwin ? twinId : undefined,
    edges: withSensorsByDevice ? deviceId : undefined,
  } : undefined), [
    twinId,
    deviceId,
    withSensorsByTwin,
    withSensorsByDevice,
  ])

  const handleChange = useCallback(({ deviceCommandTemplate }) => {
    const nextDataType = deviceCommandTemplate?.dataType || null
    let payload = withTemplatePayloadPriority ?
      (deviceCommandTemplate?.payload ?? getValues(`${scope}.payload`)) :
      (getValues(`${scope}.payload`) ?? deviceCommandTemplate?.payload)
    setDataType(nextDataType)

    if (nextDataType === consts.DeviceCommandTemplateDataType.Number) {
      payload = Number(payload) || 0
    } else if (nextDataType === consts.DeviceCommandTemplateDataType.Bool) {
      payload = Boolean(payload)
    } else {
      payload = String(payload || '')
    }

    clearErrors(`${scope}.payload`)
    window.setTimeout(() => setValue(`${scope}.payload`, payload), 0)
  }, [
    scope,
    setValue,
    getValues,
    clearErrors,
    withTemplatePayloadPriority,
  ])

  return (
    <>
      <ObservDeviceCommandTemplate
        id={templateId}
        onChange={handleChange}
      />

      <FormField
        label={intl.t('edge.commands.form.fields.device')}
        description={intl.t('edge.commands.form.fields.deviceDescr')}
        errors={get(errors, `${scope}.deviceId`)}
      >
        <Controller
          name={`${scope}.deviceId`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('edge.commands.form.fields.devicePlaceholder')}
              ListSource={EdgeDeviceList}
              onChange={(value) => {
                field.onChange(value)
                setValue(`${scope}.sensorId`, null)
              }}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('edge.commands.form.fields.template')}
        description={intl.t('edge.commands.form.fields.templateDescr')}
        errors={get(errors, `${scope}.templateId`)}
      >
        <Controller
          name={`${scope}.templateId`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('edge.commands.form.fields.templatePlaceholder')}
              ListSource={DeviceCommandTemplateList}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('edge.commands.form.fields.payload')}
        description={intl.t('edge.commands.form.fields.payloadDescr')}
        errors={get(errors, `${scope}.payload`)}
      >
        <Controller
          name={`${scope}.payload`}
          rules={do {
            if (dataType === consts.DeviceCommandTemplateDataType.Number) {
              ({
                setValueAs: value => Number(value),
                required: intl.t('form.errors.fieldRequired'),
                validate: undefined,
              })
            } else if (dataType === consts.DeviceCommandTemplateDataType.Bool) {
              ({
                setValueAs: value => Boolean(value),
                required: false,
                validate: undefined,
              })
            } else {
              ({
                setValueAs: value => String(value),
                validate: (value) => {
                  const str = String(value || '').trim()
                  if (!str.length) {
                    return intl.t('form.errors.fieldRequired')
                  }
                  if (value.length > 20000) {
                    return intl.t('form.errors.fieldMaxlen', { max: 20000 })
                  }
                  return true
                },
              })
            }
          }}
          render={({ field, fieldState }) => (do {
            if (dataType === consts.DeviceCommandTemplateDataType.Number) {
              <FormInputNumber
                {...field}
                invalid={fieldState.invalid}
                scale={8}
              />
            } else if (dataType === consts.DeviceCommandTemplateDataType.Bool) {
              <FormSwitch
                {...field}
                invalid={fieldState.invalid}
              />
            } else {
              <FormInput
                {...field}
                invalid={fieldState.invalid}
              />
            }
          })}
        />
      </FormField>

      {withTwin ? (
        <FormField
          label={intl.t('form.fields.twin')}
          description={intl.t('edge.commands.form.fields.twinDescr')}
          errors={get(errors, `${scope}.twinId`)}
        >
          <Controller
            name={`${scope}.twinId`}
            render={({ field, fieldState }) => (
              <SelectTwin
                {...field}
                invalid={fieldState.invalid}
                filters={{ withConnections: true }}
                placeholder={intl.t('form.fields.twinPlaceholder')}
              />
            )}
          />
        </FormField>
      ) : null}

      <FormField
        label={intl.t('form.fields.dataPoints')}
        description={intl.t('edge.commands.form.fields.dataPointsDescr')}
        errors={get(errors, `${scope}.sensorId`)}
      >
        <Controller
          name={`${scope}.sensorId`}
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorsPlaceholder')}
              filters={dataPointFilters}
            />
          )}
        />
      </FormField>
    </>
  )
}

Fields.propTypes = {
  scope: PropTypes.string.isRequired,
  withTwin: PropTypes.bool,
  withSensorsByTwin: PropTypes.bool,
  withSensorsByDevice: PropTypes.bool,
  withTemplatePayloadPriority: PropTypes.bool,
}

Fields.defaultProps = {
  withTwin: true,
  withSensorsByTwin: false,
  withSensorsByDevice: false,
  withTemplatePayloadPriority: false,
}
