import { memo, Suspense } from 'react'
import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { withObservables, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { Widgets } from '@/widgets'
import { ErrorBoundary } from '@/utils/analytics'

const enhanceWidget = withObservables([ 'id' ], ({ id }) => {
  const widget = id ? dbProvider.database.collections.get('widgets')
    .query(Q.where('id', id))
    .observeWithColumns([ 'updated_at' ])
    .pipe(map(items => items[0])) : of$(undefined)

  return {
    widget,
  }
})

const enhanceWidgetData = withObservables([ 'widget' ], ({ widget }) => ({
  config: widget ? widget.observeConfig : of$(undefined),
}))

const Loading = () => (
  <div className="drag rounded shadow-sm opacity-50 position-absolute-fill bg-white d-flex align-items-center justify-content-center">
    <Icon name="fa--spinner" size="2X" spin />
  </div>
)

const NotFound = () => {
  const intl = useIntl()
  return (
    <div className="drag rounded shadow-sm opacity-50 position-absolute-fill bg-white d-flex align-items-center justify-content-center">
      {intl.t('widgets.notFound')}
    </div>
  )
}

const Exception = (error) => {
  const intl = useIntl()

  console.log('error in widget exception', error)

  return (
    <div className="drag rounded shadow-sm opacity-50 position-absolute-fill bg-white d-flex flex-column align-items-center justify-content-center text-danger">
      <Icon name="fa--bug" size="2X" />
      <div className="mt-2">{intl.t('errors.initializationError')}</div>
    </div>
  )
}

export default memo(
  enhanceWidget(
    enhanceWidgetData(({
      widget,
      config,
      disabled,
      hidden,
      canUpdateDashboard,
      onWidgetAction,
      templateId,
    }) => {
      const Component = Widgets[widget?.type]

      if (!Component) {
        return <NotFound />
      }

      return (
        <ErrorBoundary
          fallback={Exception}
        >
          <Suspense fallback={<Loading />}>
            <Component
              widget={widget}
              config={config}
              disabled={disabled}
              hidden={hidden}
              canUpdateDashboard={canUpdateDashboard}
              onWidgetAction={onWidgetAction}
              templateId={templateId}
            />
          </Suspense>
        </ErrorBoundary>
      )
    }),
  ),
)
