import {
  useRef,
  useContext,
  useCallback,
  useState,
} from 'react'
import { Dialog, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import DataExplorer from '@/context/DataExplorer'
import DataExplorerEnv from '@/context/DataExplorerEnv'
import DataExplorerExecute from '@/context/DataExplorerExecute'
import DataExplorerView from '@/components/Forms/DataExplorerView'
import FormExplorer from '@/containers/Forms/Explorer'
import FormExplorerMini from '@/containers/Forms/ExplorerMini'
import ExplorerDataExplorers from './ExplorerDataExplorers'

const RightBarItems = [
  {
    id: 'explorers',
    title: 't/explorer.title',
    component: ExplorerDataExplorers,
  },
  {
    id: 'view',
    title: 't/form.sections.settings',
    component: DataExplorerView,
  },
  {
    id: 'save',
    title: 't/explorer.titleUpdate',
    component: FormExplorerMini,
  },
  {
    id: 'create',
    title: 't/explorer.titleCreate',
    component: FormExplorerMini,
  },
]

export default function RightBar ({
  container = null,
}) {
  const refRightBar = useRef(null)
  const intl = useIntl()
  const isMobile = useMobile()
  const context = useContext(DataExplorer)
  const envContext = useContext(DataExplorerEnv)
  const runContext = useContext(DataExplorerExecute)
  const [ editExplorerId, setEditExplorerId ] = useState(null)
  const section = runContext.rightBar ?
    RightBarItems.find(item => item.id === runContext.rightBar) : null

  const handleAction = useCallback(async (action, id) => {
    if (action === 'remove') {
      await context.removeExplorer(id)
    } else if (action === 'share') {
      refRightBar.current?.close()
      setEditExplorerId(id)
    } else if (action === 'edit') {
      // refRightBar.current?.close()
      setEditExplorerId(id)
    } else if (action === 'close') {
      refRightBar.current?.close()
    } else if (action === 'view' && isMobile) {
      refRightBar.current?.close()
    }
  }, [ context, isMobile ])

  const handleClose = useCallback(() => {
    refRightBar.current?.close()
  }, [])

  const handleSubmit = useCallback(async (data) => {
    if (section?.id === 'view') {
      await context.updateContext(data)
    } else if (section?.id === 'save') {
      await context.saveContext(data)
    } else if (section?.id === 'create') {
      await context.saveContext(data)
    }

    refRightBar.current?.close()
  }, [ context, section ])

  const handleRemove = useCallback(async (id) => {
    if (section?.id === 'save') {
      await context.removeExplorer(id)
    }

    refRightBar.current?.close()
  }, [ context, section ])

  const handleCopy = useCallback(async (data) => {
    if (section?.id === 'save') {
      await context.copyContext(data)
    }

    refRightBar.current?.close()
  }, [ context, section ])

  return (
    <>
      {section ? (
        <Dialog
          ref={refRightBar}
          classNameBody="p-0 overflow-hidden"
          container={container}
          strategy="absolute"
          backdrop={false}
          resize
          size="sm"
          title={intl.t(section.title)}
          dataTestid={`${section.id}RightBarDialog`}
          onClose={() => {
            runContext.setRightBar(null)
          }}
        >
          <section.component
            id={context.explorer.id}
            value={context.explorer}
            groupByTwinId={envContext.twinId}
            onAction={handleAction}
            onClose={handleClose}
            onSubmit={handleSubmit}
            onRemove={handleRemove}
            onCopy={handleCopy}
          />
        </Dialog>
      ) : null}

      {editExplorerId ? (
        <FormExplorer
          id={editExplorerId}
          onClose={() => setEditExplorerId(null)}
          dialog={{
            title: editExplorerId ?
              intl.t('explorer.titleUpdate') :
              intl.t('explorer.titleCreate'),
            dataTestid: 'updateExplorerDialog',
          }}
        />
      ) : null}
    </>
  )
}
