import {
  useState, useEffect, useMemo, useContext,
} from 'react'
import { withProps } from '@wiz/components'
import { wizataApi } from '@/api'
import { AppContext } from '@/pages/context'

const enhanceData = withProps(({
  filters,
}) => {
  const [ loading, setLoading ] = useState(true)
  const [ items, setItems ] = useState([])
  const [ total, setTotal ] = useState(0)

  const { filters: contextFilters } = useContext(AppContext)

  const nextFilters = useMemo(() => ({
    from: contextFilters.from ? Number(contextFilters.from) : undefined,
    to: contextFilters.to ? Number(contextFilters.to) : undefined,
    recipient: contextFilters.recipient || undefined,
    isPaid: contextFilters.isPaid || undefined,
  }), [ contextFilters ])

  useEffect(() => {
    setLoading(true)

    const limit = filters.pageSize + 1
    const offset = (filters.page - 1) * filters.pageSize
    // TODO: use useQuery instead
    wizataApi.logs.sms({
      ...nextFilters,
      limit,
      offset,
    })
      .then((data) => {
        setTotal(offset + data.length)
        setItems(data.slice(0, filters.pageSize))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [ filters.pageSize, filters.page, nextFilters ])

  return {
    total,
    items,
    loading,
  }
})

export default enhanceData
