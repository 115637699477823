import { useEffect, useMemo } from 'react'
import { VirtualTable } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import EditButton from '@/shared/editButton'
import UpdatedInfo from '@/components/Form/UpdatedInfo'

export default function Table ({
  items,
  onFilter,
  onEdit,
  onRemove,
  onRun,
  refetch,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()

  const columns = useMemo(() => [
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      maxWidth: 30,
      className: 'justify-content-center text-nowrap',
      Cell: ({ cell }) => (cell.value && auth.checkAccessUpdate('Experiment') ? (
        <EditButton cell={cell} onEdit={onEdit} view />
      ) : null),
    },
    {
      Header: intl.t('form.fields.key'),
      accessor: 'key',
      disableResizing: true,
      minWidth: 200,
      Cell: ({ cell }) => (cell.value ? (
        <div className="text-truncate min-w-0">
          <span className="text-truncate">
            { cell.value }
          </span>
        </div>
      ) : '-'),
    },
    {
      Header: intl.t('form.fields.identifier'),
      accessor: 'identifier',
      disableResizing: true,
      minWidth: 200,
      Cell: ({ cell }) => (cell.value ? (
        <div className="text-truncate min-w-0">
          <span className="text-truncate">
            { cell.value }
          </span>
        </div>
      ) : '-'),
    },
    {
      Header: intl.t('form.fields.source'),
      accessor: 'source',
      disableResizing: true,
      minWidth: 200,
      Cell: ({ cell }) => (cell.value ? (
        <div className="text-truncate min-w-0">
          <span className="text-truncate">
            { cell.value }
          </span>
        </div>
      ) : '-'),
    },
    // {
    //   Header: intl.t('form.fields.status'),
    //   accessor: 'status',
    //   disableResizing: true,
    //   minWidth: 200,
    //   Cell: ({ cell }) => (cell.value ? (
    //     <div className="text-truncate min-w-0">
    //       <span className="text-truncate">
    //         { cell.value }
    //       </span>
    //     </div>
    //   ) : '-'),
    // },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedDate',
      disableResizing: true,
      // disableSortBy: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.updatedById}
        />
      ),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdDate',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.createdById}
        />
      ),
    },
  ], [ auth, onEdit, intl ])

  useEffect(() => {
    if (items) {
      refetch()
    }
  }, [ props.filters.sortBy, props.filters.sortDir ])

  return (
    <>
      <VirtualTable
        {...props}
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={false}
      />

      {/* <Pagination {...props} /> */}
    </>
  )
}
