import {
  forwardRef,
  useState,
  useImperativeHandle,
} from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import classnames from 'classnames'
import { NotificationSheetTarget } from '@wiz/store'
import { uuid, has } from '@wiz/utils'
import {
  FormFieldInline,
  FormSelectNative,
  Icon,
  FormInputPhone,
  useDidUpdate,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import SelectSensor from '@/components/Form/SelectSensor'

const InputRecipient = forwardRef(({
  scope,
  name,
  className,
  onChange,
}, ref) => {
  const intl = useIntl()
  const { watch, trigger, setValue } = useFormContext()
  const [ currentType, defaultValue ] = watch([
    `${scope}.type`,
    name,
  ])

  const [ currentValue, setCurrentValue ] = useState(defaultValue)

  useDidUpdate(() => {
    setCurrentValue('')
    setValue(`${scope}.recipient`, '', { shouldDirty: true })
  }, [ currentType ])

  return do {
    if (currentType === 'sensor') {
      <SelectSensor
        ref={ref}
        name={name}
        value={currentValue || ''}
        placeholder={intl.t('form.fields.sensorPlaceholder')}
        className={classnames('form-control w-100', className)}
        onChange={(value) => {
          setCurrentValue(value)
          onChange(value)
          trigger(name)
        }}
      />
    } else if (currentType === 'sms') {
      <FormInputPhone
        ref={ref}
        name={name}
        value={currentValue || ''}
        className={className}
        onChange={(value) => {
          setCurrentValue(value)
          onChange(value)
          trigger(name)
        }}
      />
    } else {
      <input
        ref={ref}
        name={name}
        value={currentValue || ''}
        type="search"
        className={classnames('form-control w-100', className)}
        onChange={(event) => {
          setCurrentValue(event.target.value)
          onChange(event.target.value)
          trigger(name)
        }}
      />
    }
  }
})

const FieldsTargets = forwardRef(({ scope }, ref) => {
  const intl = useIntl()

  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext()

  const { fields, prepend, remove } = useFieldArray({
    name: scope,
    keyName: '_id',
    shouldUnregister: true,
  })

  useImperativeHandle(ref, () => ({
    add () {
      prepend(NotificationSheetTarget.toJSON({
        id: uuid(),
        type: 'email',
        recipient: '',
        active: true,
      }))
    },
  }))

  return (
    <FormFieldInline complex>
      <div className="list-group list-group-flush mx-0 flex-fill">
        {fields.map((item, idx) => {
          const scopeRow = `${scope}.${idx}`

          return (
            <div
              key={item.id}
              className="list-group-item py-2 px-0 d-flex align-items-center"
            >
              <input
                {...register(`${scopeRow}.id`)}
                type="hidden"
              />

              <input
                {...register(`${scopeRow}.active`)}
                type="checkbox"
                className={classnames('form-check-input mt-0 me-3 flex-shrink-0', {
                  'is-invalid': has(errors, `${scopeRow}.active`),
                })}
              />

              <FormSelectNative
                {...register(`${scopeRow}.type`, {
                  required: intl.t('form.errors.fieldRequired'),
                })}
                className={classnames('me-2 w-auto', {
                  'is-invalid': has(errors, `${scopeRow}.type`),
                })}
                options={[
                  { id: 'email', name: intl.t('form.fields.email') },
                  { id: 'sms', name: intl.t('form.fields.sms') },
                  { id: 'sensor', name: intl.t('form.fields.sensor') },
                ]}
                onChange={(value) => {
                  setValue(`${scopeRow}.type`, value, { shouldDirty: true })
                  setValue(`${scopeRow}.recipient`, '', { shouldDirty: true })
                }}
              />

              <InputRecipient
                {...register(`${scopeRow}.recipient`, {
                  required: intl.t('form.errors.fieldRequired'),
                  maxLength: {
                    value: 450,
                    message: intl.t('form.errors.fieldMaxlen', { max: 450 }),
                  },
                })}
                scope={scopeRow}
                className={classnames('me-2', {
                  'is-invalid': has(errors, `${scopeRow}.recipient`),
                })}
                onChange={(value) => {
                  setValue(`${scopeRow}.recipient`, value, { shouldDirty: true })
                }}
              />

              <button
                type="button"
                className="btn btn-flat-secondary"
                title={intl.t('form.actions.remove')}
                onClick={() => remove(idx)}
              >
                <Icon name="fa--trash-alt" />
              </button>
            </div>
          )
        })}
        {!fields.length ? (
          <div className="list-group-item text-center">
            {intl.t('form.info.recipientsNotFound')}
          </div>
        ) : null}
      </div>
    </FormFieldInline>
  )
})

export default FieldsTargets
