import { useCallback } from 'react'
import { dbProvider, TwinGraph } from '@wiz/store'
import { withProps, withObservables } from '@wiz/components'
import { useRouter } from '@/router'
import Component from '@/components/TwinGraph/LeftBar'

const enhanceProps = withProps(({
  setEditTwinGraph,
  setEditBlockId,
}) => {
  const router = useRouter()

  const onToggle = useCallback(async (data, prevIsOpen) => {
    const value = await dbProvider.fetchSettings('twinTreeOpennessState') || {}
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareReplaceSetting(context, 'twinTreeOpennessState', {
      ...value,
      [data.id]: !prevIsOpen,
    })
    await dbProvider.batch(context)
  }, [])

  const onActionMenu = useCallback(async ({ id: action }, item) => {
    if (action === 'edit') {
      if (TwinGraph.is(item)) {
        setEditTwinGraph(item.id)
      } else if (item.type === 'twinGraph') {
        setEditTwinGraph(item.twinGraphId)
      } else {
        setEditBlockId(item.id)
      }
    } else if (action === 'favorite') {
      const id = TwinGraph.is(item) ? item.id : item.twinGraphId
      let favorites = await dbProvider.fetchSettings('spotlightFavorites')
      favorites = favorites.includes(id) ?
        favorites.filter(row => row !== id) :
        favorites.concat(id)
      const context = dbProvider.createBatchContext()
      await dbProvider.prepareReplaceSetting(context, 'spotlightFavorites', favorites)
      await dbProvider.batch(context)
    } else if (action === 'select') {
      const id = TwinGraph.is(item) ? item.id : item.twinGraphId
      const homepageDefaultId = await dbProvider.fetchSettings('homepageDefaultId')
      const context = dbProvider.createBatchContext()
      await dbProvider.prepareReplaceSetting(
        context,
        'homepageDefaultId',
        homepageDefaultId === id ? null : id,
      )
      await dbProvider.batch(context)
    } else if (action === 'remove') {
      await window.wizConfirm({ message: 't/twinGraph.confirmDeleteBlock' })
      const context = dbProvider.createBatchContext()
      if (item.blockId) {
        try {
          const block = await item.block.fetch()
          await block.prepareRemove(context)
        } catch (error) {
          await item.prepareRemove(context)
        }
      } else {
        await item.prepareRemove(context)
      }
      await dbProvider.batch(context)
    } else if (action === 'groupTwinGraph') {
      await window.wizConfirm({ message: 't/block.confirmGroupBlock' })
      const context = dbProvider.createBatchContext()
      const block = await dbProvider.database.collections.get('diagram_blocks').find(item.id)
      await block.prepareGroupTo(context)
      await dbProvider.batch(context)
    } else if (action === 'ungroupTwinGraph') {
      await window.wizConfirm({ message: 't/block.confirmUngroupBlock' })
      const context = dbProvider.createBatchContext()
      const block = await dbProvider.database.collections.get('diagram_blocks').find(item.id)
      await block.prepareUngroupTo(context)
      await dbProvider.batch(context)
    } else if (action === 'duplicate') {
      const id = TwinGraph.is(item) ? item.id : item.twinGraphId
      const twinGraph = await dbProvider.database.collections.get('twin_graphs').find(id)
      const context = dbProvider.createBatchContext()
      const model = await dbProvider.prepareDuplicateModel(context, twinGraph, null, {
        uniqProps: [ 'name' ],
      })
      await dbProvider.batch(context)
      await window.wizConfirm({
        title: 't/form.success.duplicated',
        message: 't/twinGraph.successDuplication',
      })
      router.push({
        name: 'chart-view',
        params: { id: model.id },
      })
    }
  }, [
    setEditTwinGraph,
    setEditBlockId,
    router,
  ])

  return {
    onToggle,
    onActionMenu,
  }
})

const enhanceData = withObservables([], () => ({
  settings: dbProvider.observeSettings([
    'homepageDefaultId',
    'spotlightFavorites',
    'twinTreeOpennessState',
  ]),
}))

export default enhanceData(
  enhanceProps(Component),
)
