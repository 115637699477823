/* eslint-disable react/no-danger */
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { VirtualTable, Icon } from '@wiz/components'
import { Twin } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import CellName from './CellName'

export default function TableMobile ({
  items,
  filters,
  onFilter,
  onRemove,
  onEdit,
  onView,
  onUnlink,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      accessor: 'name',
      Cell: ({ row }) => (
        <CellName
          twin={row.original}
          filters={filters}
        />
      ),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell, row }) => (
        <>
          {auth.checkAccessRemove(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-2"
              onClick={() => onRemove?.([ row.original ])}
            >
              <Icon name="fa--trash-alt" />
            </button>
          ) : null}
          {auth.checkAccessUpdate(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-2"
              onClick={() => onEdit?.(cell.value)}
            >
              <Icon name="fa--edit" />
            </button>
          ) : null}
          <button
            type="button"
            className="btn btn-sm p-2"
            onClick={() => onView?.(cell.value)}
          >
            <Icon name="fa--chevron-right" />
          </button>
        </>
      ),
    },
  ], [
    auth,
    onEdit,
    onView,
    onRemove,
    filters,
  ])

  return (
    <VirtualTable
      className="flex-fill mx-3"
      placeholder={intl.t('errors.noDataDisplay')}
      columns={columns}
      data={items}
      showHeader={false}
      selection={auth.checkAccessRemove('Twin') || auth.checkAccessUpdate('Twin')}
      {...props}
    />
  )
}

TableMobile.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(Twin)),
  filters: PropTypes.object,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onUnlink: PropTypes.func,
  onFilter: PropTypes.func.isRequired,
}

TableMobile.defaultProps = {
  items: [],
  filters: {},
  onRemove: undefined,
  onEdit: undefined,
  onView: undefined,
  onUnlink: undefined,
}
