import { useFormContext } from 'react-hook-form'
import { FormSection } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import FieldsGeneral from './FieldsGeneral'
import FieldsContent from './FieldsContent'
import FieldsAxis from './FieldsAxis'
import FieldsConditions from './FieldsConditions'
import FieldsRegressions from './FieldsRegressions'

export default function Section ({ scope }) {
  const intl = useIntl()
  const { register, watch } = useFormContext()
  const isGroup = !watch(`${scope}.sourceId`)

  return (
    <>
      <FormSection
        title={intl.t('form.sections.general')}
        folding
      >
        <input
          {...register(`${scope}.id`)}
          type="hidden"
        />

        <FieldsGeneral scope="dataView" />
      </FormSection>

      <FormSection
        title={intl.t('form.sections.content')}
        folding
      >
        <FieldsContent scope="dataView" />
      </FormSection>

      <FormSection
        title={intl.t('form.sections.axis')}
        folding
      >
        <FieldsAxis scope="dataView" />
      </FormSection>

      {isGroup ? null : (
        <FormSection
          title={intl.t('form.sections.horizontalConditions')}
          description={intl.t('form.sections.horizontalConditionsDescr')}
          folding
        >
          <FieldsConditions scope="dataView" />
        </FormSection>
      )}

      <FormSection
        title={intl.t('form.sections.regressions')}
        description={intl.t('form.sections.regressionsDescr')}
        folding
      >
        <FieldsRegressions scope="dataView" />
      </FormSection>
    </>
  )
}
