import { map } from 'rxjs/operators'
import {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react'
import difference from 'lodash/difference'
import intersection from 'lodash/intersection'
import {
  withProps,
  useDidUpdate,
} from '@wiz/components'
import {
  round,
  OnlineTimeout,
  debounce,
  isEqual,
} from '@wiz/utils'
import { Condition } from '@wiz/store'
import TwinGraphExecute from '@/context/TwinGraphExecute'
import observeSensorValue from '@/utils/observeSensorValue'
import observeEventsCount from '@/utils/observeEventsCount'
import observePlotlyChart from '@/utils/observePlotlyChart'
import observeTableData from '@/utils/observeTableData'
import { intl } from '@/i18n'
import { useTheme } from '@/theme'

export default withProps(({
  blocks,
}) => {
  const theme = useTheme()
  const { includeChildTwinEvents } = useContext(TwinGraphExecute)
  const refBlocks = useRef()
  const refRefreshTimer = useRef()
  const refTwinIds = useRef([])
  const refEventsCountSubscription = useRef(null)
  const refEventsCount = useRef({})
  const refSensorValueSubscriptions = useRef({})
  const refSensorsData = useRef({})
  const refPlotlyValueSubscriptions = useRef({})
  const refPlotlyData = useRef({})
  const refTableValueSubscriptions = useRef({})
  const refTableData = useRef({})
  const [ externalData, setExternalData ] = useState({})

  refBlocks.current = blocks || []

  const syncExternalData = useMemo(() => debounce(() => {
    const now = Date.now()
    const sensors = {}
    const twins = {}
    const plotly = {}
    const tableData = {}

    for (const item of refBlocks.current) {
      if (item.category === 'sensor' && item.sensorId) {
        const data = refSensorsData.current[item.sensorId]
        const value = data?.value ?? null
        const timestamp = data?.timestamp ?? null
        const conditions = item.conditions || []
        const condition = conditions.find(cond => Condition.pass(cond, timestamp, value))

        sensors[item.id] = {
          value,
          timestamp,
          conditionColor: condition?.color ?? null,
          formatValue: condition?.name || (
            Number.isFinite(value) ?
              round(value, item.precision || 0) + (item.unit ? ` ${item.unit}` : '') :
              null
          ),
          formatTime: Number.isFinite(timestamp) ?
            intl.ago.format(new Date(Math.min(timestamp, now)), 'mini-minute-now') :
            null,
        }
      }

      if (item.category === 'plotly' && item.widgetId) {
        const data = refPlotlyData.current[item.widgetId]
        plotly[item.id] = {
          image: data?.image,
          error: data?.error,
        }
      }

      if (item.category === 'tableData' && item.widgetId) {
        const data = refTableData.current[item.widgetId]
        tableData[item.id] = {
          items: data?.items || [],
        }
      }

      if (item.twinId) {
        const cnt = refEventsCount.current[item.twinId] || {}
        const twinData = {
          cntActiveEvents: cnt.active || 0,
          latlng: undefined,
        }

        if (
          item.latSensorId &&
          item.lngSensorId &&
          refSensorsData.current[item.latSensorId] &&
          refSensorsData.current[item.lngSensorId]
        ) {
          twinData.latlng = `${refSensorsData.current[item.latSensorId].value} ${refSensorsData.current[item.lngSensorId].value}`
        }

        twins[item.twinId] = twinData
      }
    }

    setExternalData({
      sensors,
      plotly,
      twins,
      tableData,
    })

    refRefreshTimer.current?.cancel()
    refRefreshTimer.current = new OnlineTimeout(syncExternalData, 60 * 1000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, 1000, { maxWait: 2000 }), [])

  // subscribe / unsubscribe sensors stream data
  const subscribeSensors = useCallback(() => {
    let sensorIds = new Set()

    for (const item of refBlocks.current) {
      if (item.category === 'sensor' && item.sensorId) {
        sensorIds.add(item.sensorId)
      }

      if (item.twinId && item.latSensorId && item.lngSensorId) {
        sensorIds.add(item.latSensorId)
        sensorIds.add(item.lngSensorId)
      }
    }

    sensorIds = Array.from(sensorIds)

    const prevSensorIds = Object.keys(refSensorValueSubscriptions.current)
    const createSensor = difference(sensorIds, prevSensorIds)
    const removeSensor = difference(prevSensorIds, sensorIds)

    for (const sensorId of createSensor) {
      refSensorValueSubscriptions.current[sensorId] = observeSensorValue(sensorId)
        .subscribe((data) => {
          refSensorsData.current[sensorId] = data
          syncExternalData()
        })
    }

    for (const sensorId of removeSensor) {
      const subscription = refSensorValueSubscriptions.current[sensorId]
      if (subscription) {
        subscription.unsubscribe()
        delete refSensorValueSubscriptions.current[sensorId]
        delete refSensorsData.current[sensorId]
      }
    }
  }, [ syncExternalData ])

  // subscribe / unsubscribe twin active events data
  const subscribeEvents = useCallback((force) => {
    let twinIds = new Set()

    for (const item of refBlocks.current) {
      if (item.twinId) {
        twinIds.add(item.twinId)
      }
    }

    twinIds = Array.from(twinIds)

    const prevTwinIds = refTwinIds.current
    const hasTwinChanges = !isEqual(twinIds.sort(), prevTwinIds.sort())
    refTwinIds.current = twinIds

    if (!twinIds.length || hasTwinChanges || force) {
      refEventsCountSubscription.current?.unsubscribe()
      refEventsCountSubscription.current = null
      refEventsCount.current = {}
    }

    if (twinIds.length && (hasTwinChanges || force)) {
      refEventsCountSubscription.current = observeEventsCount({
        twinIds,
        status: 'active',
        includeChildTwinEvents,
      })
        .pipe(
          map(data => (
            data?.reduce?.((out, item) => {
              if (item.twin_id) {
                return { ...out, [item.twin_id]: item }
              }
              return out
            }, {}) ?? {}
          )),
        )
        .subscribe((data) => {
          refEventsCount.current = data
          syncExternalData()
        })
    }
  }, [ syncExternalData, includeChildTwinEvents ])

  // subscribe / unsubscribe plotly charts
  const subscribePlotly = useCallback(() => {
    let widgetIds = new Set()

    for (const item of refBlocks.current) {
      if (item.category === 'plotly' && item.widgetId) {
        widgetIds.add(item.widgetId)
      }
    }

    widgetIds = Array.from(widgetIds)

    const prevWidgetIds = Object.keys(refPlotlyValueSubscriptions.current)
    const createPlotly = difference(widgetIds, prevWidgetIds)
    const removePlotly = difference(prevWidgetIds, widgetIds)
    const updatePlotly = intersection(widgetIds, prevWidgetIds)

    for (const widgetId of createPlotly) {
      refPlotlyValueSubscriptions.current[widgetId] = observePlotlyChart(widgetId, {
        theme: theme.echarts,
        responseType: 'base64',
      })
        .subscribe((data) => {
          if (data) {
            refPlotlyData.current[widgetId] = data
            syncExternalData()
          }
        })
    }

    for (const widgetId of removePlotly) {
      const subscription = refPlotlyValueSubscriptions.current[widgetId]
      if (subscription) {
        subscription.unsubscribe()
        delete refPlotlyValueSubscriptions.current[widgetId]
        delete refPlotlyData.current[widgetId]
      }
    }

    for (const widgetId of updatePlotly) {
      const subscription = refPlotlyValueSubscriptions.current[widgetId]
      subscription?.unsubscribe()
      refPlotlyValueSubscriptions.current[widgetId] = observePlotlyChart(widgetId, {
        theme: theme.echarts,
        responseType: 'base64',
      })
        .subscribe((data) => {
          if (data) {
            refPlotlyData.current[widgetId] = data
            syncExternalData()
          }
        })
    }
  }, [ syncExternalData, theme ])

  const subscribeTableData = useCallback(() => {
    let widgetIds = new Set()

    for (const item of refBlocks.current) {
      if (item.category === 'tableData' && item.widgetId) {
        widgetIds.add(item.widgetId)
      }
    }

    widgetIds = Array.from(widgetIds)

    const prevWidgetIds = Object.keys(refTableValueSubscriptions.current)
    const createTable = difference(widgetIds, prevWidgetIds)
    const removeTable = difference(prevWidgetIds, widgetIds)
    const updateTable = intersection(widgetIds, prevWidgetIds)

    for (const widgetId of createTable) {
      refTableValueSubscriptions.current[widgetId] = observeTableData(widgetId)
        .subscribe((data) => {
          if (data) {
            refTableData.current[widgetId] = data
            syncExternalData()
          }
        })
    }

    for (const widgetId of removeTable) {
      const subscription = refTableValueSubscriptions.current[widgetId]
      if (subscription) {
        subscription.unsubscribe()
        delete refTableValueSubscriptions.current[widgetId]
        delete refTableData.current[widgetId]
      }
    }

    for (const widgetId of updateTable) {
      const subscription = refTableValueSubscriptions.current[widgetId]
      subscription?.unsubscribe()
      refTableValueSubscriptions.current[widgetId] = observeTableData(widgetId)
        .subscribe((data) => {
          if (data) {
            refTableData.current[widgetId] = data
            syncExternalData()
          }
        })
    }
  }, [ syncExternalData ])

  const onUpdateData = useCallback(async (nextBlocks) => {
    refBlocks.current = nextBlocks
    subscribeSensors()
    subscribeEvents()
    subscribePlotly()
    subscribeTableData()
  }, [
    subscribeSensors,
    subscribeEvents,
    subscribePlotly,
    subscribeTableData,
  ])

  useDidUpdate(() => {
    syncExternalData.cancel()
    refRefreshTimer.current?.cancel()
    subscribeSensors()
    subscribeEvents(true)
    subscribePlotly()
    subscribeTableData()
  }, [
    syncExternalData,
    subscribeSensors,
    subscribeEvents,
    subscribePlotly,
    subscribeTableData,
  ])

  useDidUpdate(() => {
    syncExternalData()
  }, [ blocks ])

  useEffect(() => () => {
    refRefreshTimer.current?.cancel()
    syncExternalData.cancel()
    refEventsCountSubscription.current?.unsubscribe()

    for (const key in refSensorValueSubscriptions.current) {
      if (Object.hasOwnProperty.call(refSensorValueSubscriptions.current, key)) {
        refSensorValueSubscriptions.current[key].unsubscribe()
      }
    }

    for (const key in refPlotlyValueSubscriptions.current) {
      if (Object.hasOwnProperty.call(refPlotlyValueSubscriptions.current, key)) {
        refPlotlyValueSubscriptions.current[key].unsubscribe()
      }
    }
  }, [ syncExternalData ])

  return {
    externalData,
    onUpdateData,
  }
})
