import { useReducer, useMemo, createContext } from 'react'
import { useMatchWidth } from '@wiz/components'
import { useAuth } from '@/auth'
import useAuthComponents from '@/components/GrafanaDashboards/hooks'

function reducer (state, action) {
  switch (action.type) {
    case 'disabled':
      return {
        ...state,
        disabled: action.payload,
      }
    case 'dragType':
      return {
        ...state,
        dragType: action.payload,
      }
    default:
      return state
  }
}

export const DashboardExecute = createContext({})

export function DashboardExecuteProvider ({
  dashboard,
  width,
  children,
  isEditable,
}) {
  const auth = useAuth()
  const isMobil = useMatchWidth(width, 'mobil')
  const { isPersonal } = useAuthComponents()
  const canUpdate = useMemo(() => {
    if (typeof isEditable === 'boolean') {
      return isEditable
    }
    return auth.checkAccessUpdate(dashboard) || isPersonal
  }, [ auth, isEditable, dashboard, isPersonal ])

  const [ state, dispatch ] = useReducer(reducer, {
    disabled: false,
    dragType: null,
  })

  const context = useMemo(() => ({
    ...state,
    isMobil,
    isDraggable: canUpdate && !isMobil,
    isResizable: canUpdate && !isMobil,
    isDroppable: canUpdate && !isMobil,
    // can not update layout during the drag
    canUpdateLayout: canUpdate && !isMobil && !state.dragType,
    canUpdateDashboard: canUpdate,
    setDisabled: payload => dispatch({ type: 'disabled', payload }),
    setDragType: payload => dispatch({ type: 'dragType', payload }),
  }), [
    isMobil,
    canUpdate,
    state,
    dispatch,
  ])

  return (
    <DashboardExecute.Provider value={context}>
      {children}
    </DashboardExecute.Provider>
  )
}
