import { useMemo } from 'react'
import { withContext } from '@wiz/components'
import { dbProvider, Q } from '@wiz/store'
import DataExplorerEnv from '@/context/DataExplorerEnv'
import ExplorerListComponent from './ExplorerListComponent'

export default withContext(DataExplorerEnv, ({
  twinId,
  onChange,
  onChangeBack,
}) => {
  const context = useMemo(() => ({
    twinId,

    ExplorerListComponent: props => (
      <ExplorerListComponent
        {...props}
        onChange={onChange}
      />
    ),

    openExplorer: async (id) => {
      const [ model ] = id ? await dbProvider.database.collections.get('explorers')
        .query(Q.where('id', id))
        .fetch() : []

      if (model) {
        onChange('explorer', {
          title: model.title,
          props: {
            id: model.id,
            twinId,
          },
        })
      } else {
        onChangeBack()
      }
    },
  }), [
    twinId,
    onChange,
    onChangeBack,
  ])

  return context
})
