import {
  useRef,
  useCallback,
  useState,
  useMemo,
} from 'react'
import classnames from 'classnames'
import { DataSource } from '@wiz/store'
import { DropdownMenu } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import DataSourceForm from '@/hoc/DataSourceForm'
import FormDataSource from '@/components/Forms/DataSource'

const BindedFormDataSource = DataSourceForm(FormDataSource)

export default function ButtonCreateSource ({
  className,
  canCreateSource = true,
  canCreateVirtualSource = true,
  onCreateSource,
}) {
  const refTarget = useRef()
  const refDropdowm = useRef()
  const intl = useIntl()
  const [ createDataSource, setCreateDataSource ] = useState(null)
  const [ createVirtualDataSource, setCreateVirtualDataSource ] = useState(null)

  const handleClick = useCallback(async ({ id }) => {
    refDropdowm.current?.close()
    if (id === 'virtual') {
      setCreateVirtualDataSource(DataSource.toJSON())
    } else if (id === 'source') {
      setCreateDataSource(DataSource.toJSON())
    }
  }, [])

  const options = useMemo(() => [
    ...(canCreateSource ? [
      {
        id: 'source',
        name: intl.t('form.actions.addDataSource'),
      },
    ] : []),
    ...(canCreateVirtualSource ? [
      {
        id: 'virtual',
        name: intl.t('form.actions.addVirtualDataSource'),
      },
    ] : []),
  ], [ intl, canCreateSource, canCreateVirtualSource ])

  return (
    <>
      {do {
        if (options.length > 1) {
          <>
            <button
              ref={refTarget}
              type="button"
              className={classnames('btn btn-sm btn-fill-secondary text-nowrap', className)}
            >
              {intl.t('form.actions.addSource')}
            </button>

            <DropdownMenu
              ref={refDropdowm}
              target={refTarget}
              options={options}
              placement="bottom"
              width={200}
              onClick={handleClick}
              arrow
            />
          </>
        } else if (options.length === 1) {
          <button
            type="button"
            className={classnames('btn btn-sm btn-fill-secondary text-nowrap', className)}
            onClick={() => handleClick(options[0])}
          >
            {intl.t('form.actions.addSource')}
          </button>
        }
      }}

      {createDataSource || createVirtualDataSource ? (
        <BindedFormDataSource
          dataSource={createDataSource || createVirtualDataSource}
          isDataSource={!!createDataSource}
          onSubmit={({ dataSource }) => {
            onCreateSource?.(dataSource)
          }}
          onClose={() => {
            setCreateDataSource(null)
            setCreateVirtualDataSource(null)
          }}
          dialog={{
            title: createDataSource ?
              intl.t('explorer.titleCreateDataSource') :
              intl.t('explorer.titleCreateVirtualDataSource'),
            dataTestid: 'replaceDataSourceDialog',
          }}
        />
      ) : null}
    </>
  )
}
