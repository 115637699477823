import { TwinDiagram, withObservables } from '@wiz/components'
import enhanceBlocksExternalData from './enhanceBlocksExternalData'

const enhanceBlocks = withObservables([ 'metaBlock' ], ({ metaBlock }) => ({
  blocks: metaBlock?.observeDiagramBlocks,
}))

export default enhanceBlocks(
  enhanceBlocksExternalData(TwinDiagram),
)
