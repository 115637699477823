import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { map } from 'rxjs/operators'
import { of } from 'rxjs'
import { Results } from '@/components/ExperimentResult'
import withTableActions from '@/containers/withTableActions'
import enhanceResult from './enhanceResult'
import enhanceContext from './enhanceContext'

const enhanceExperiment = withObservables([ 'id', 'match' ], ({ id, match }) => {
  const _id = id || match?.params?.id

  const query = dbProvider.database.collections.get('experiments')
    .query(
      Q.where('id', _id),
    )

  const settings = dbProvider.observeGlobalSettings([
    'SeriesQueryMaxCount',
    'RawDateRangeLimit',
  ])

  return ({
    experiment: query.observeWithColumns([ 'status', 'updated_at' ])
      .pipe(map(items => (items.length ? items[0] : of({})))),
    businessLabels: dbProvider.database.collections.get('business_labels').query().observe(),
    settings,
  })
})

export default withTableActions({
  initFilters () {
    return {}
  },
  defaultFilters () {
    return {
      sortBy: 'from',
      includeChildEvents: true,
    }
  },
})(enhanceExperiment(enhanceResult(enhanceContext(Results))))
