import { Dialog } from '@wiz/components'
import { consts } from '@wiz/utils'
import Result from '@/containers/ExperimentResult'

export default function ExperimentResult ({
  id,
  experiment,
  container,
  onClose,
}) {
  return (
    <Dialog
      title={experiment.name}
      classNameBody="p-0"
      container={container}
      strategy="absolute"
      onClose={onClose}
    >
      <Result
        id={id}
        customFunction={experiment.type === consts.ExperimentType.Function}
      />
    </Dialog>
  )
}
