import { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import LibraryItems from './LibraryItems'

export default function RightBarInfo ({
  businessType,
  onClickCreate,
  blocks,
}) {
  const [ selectedBlockSettings, setSelectedBlockSettings ] = useState(null)

  useEffect(() => {
    if (blocks?.length && selectedBlockSettings?.id !== blocks[0]?.id) {
      setSelectedBlockSettings(blocks[0])
    }
  }, [ blocks ])

  return (
    <LibraryItems
      businessType={businessType}
      onClickCreate={onClickCreate}
    />
  )
}

RightBarInfo.propTypes = {
  businessType: PropTypes.string,
  onClickCreate: PropTypes.func,
  blocks: PropTypes.arrayOf(PropTypes.shape({})),
}

RightBarInfo.defaultProps = {
  businessType: undefined,
  onClickCreate: undefined,
  blocks: undefined,
}
