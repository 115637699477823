import { useContext } from 'react'
import { withProps } from '@wiz/components'
import DataExplorer from '@/context/DataExplorer'

export default withProps(({ eventSource }) => {
  const context = useContext(DataExplorer)
  eventSource = context?.data?.eventSources?.find(item => (
    item.id === eventSource.id
  )) || eventSource

  return {
    eventSource,
  }
})
