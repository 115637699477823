import { of as of$, throwError } from 'rxjs'
import {
  map,
  withLatestFrom,
  mergeMap,
  switchMap,
} from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'
import { createLocation } from '@/router'
import defaultDiagramState from '@/utils/defaultDiagramState'
import { withGlobalExecute } from '@/context/GlobalExecuteProvider'
import Component from '@/components/StreamJobs/View'
import enhanceBlocks from './enhanceBlocks'
import enhancePropsDiagram from './enhancePropsDiagram'

const enhanceStreamJob = withObservables([ 'match' ], ({ match, ...props }) => {
  const streamJobObserver = dbProvider.database.collections.get('stream_jobs')
    .query(Q.where('id', String(match.params.id)))
    .observeWithColumns([ 'updated_at' ])
    .pipe(map(items => items[0]))

  const metaBlockStreamJobObserver = dbProvider.database.collections.get('diagram_blocks')
    .query(Q.where('id', String(match.params.id)))
    .observeWithColumns([ 'updated_at' ])
    .pipe(switchMap(items => (items.length ? items[0].observeFullSettings : of$(undefined))))

  const querySns = dbProvider.database.collections.get('integrations').query(Q.where('type', 'sns')).observeWithColumns([ 'updated_at' ])

  return {
    streamJob: streamJobObserver
      .pipe(
        withLatestFrom(metaBlockStreamJobObserver),
        mergeMap(([ streamJob, streamJobFromBlock ]) => (
          streamJob || streamJobFromBlock ?
            of$(streamJob || streamJobFromBlock) :
            throwError('Stream job not found')
        )),
      ),
    snsAvailable: querySns.pipe(
      switchMap(items => (items?.length && items[0].isOn ? of$(true) : of$(false))),
    ),
  }
}, {
  onError (error, { history, match }) {
    history.push(createLocation({
      name: 'stream-jobs-list',
      params: match.params,
    }))
  },
})

const enhanceMetaBlock = withObservables([ 'streamJob' ], ({ streamJob }) => ({
  diagramState: streamJob.observeDiagramState.pipe(
    defaultDiagramState(streamJob),
  ),
  metaBlock: dbProvider.database.collections.get('diagram_blocks')
    .query(Q.where('id', String(streamJob?.blockId)))
    .observeWithColumns([ 'updated_at' ])
    .pipe(
      mergeMap(items => (items.length ? of$(items[0]) : throwError('Meta block not found'))),
    ),
}), {
  onError (error, { history, match }) {
    history.push(createLocation({
      name: 'stream-jobs-list',
      params: match.params,
    }))
  },
})

const enhanceGlobalContext = withGlobalExecute((context, { streamJob, edges }) => {
  context.setLocalFilters({
    edges: streamJob.deviceId,
  })
  return () => {
    context.resetLocalFilters('edges')
  }
})

export default enhanceStreamJob(
  enhanceMetaBlock(
    enhanceBlocks(
      enhanceGlobalContext(
        enhancePropsDiagram(Component),
      ),
    ),
  ),
)
