import { useContext } from 'react'
import { FormSelect, FormSelectDateTimeRange } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Select from '@/components/Form/Select'
import StreamJobList from '@/hoc/StreamJobList'
import UserList from '@/hoc/UserList'
import { AppContext } from '@/pages/context'
import FormatDateTime from '@/containers/FormatDateTime'
import ListFilters from '@/components/Form/ListFilters'

const EnumStreamJobLogSource = [ 'backgroundJob', 'testRun', 'user' ]

export default function Filters () {
  const { filters, onFilter } = useContext(AppContext)

  const intl = useIntl()

  return (
    <ListFilters
      filters={filters}
      onFilter={onFilter}
    >
      {({ filters, onFilter }) => (
        <>
          <div className="me-1 mb-2 col-md-auto">
            <FormSelectDateTimeRange
              className="w-auto"
              placeholderFrom="From"
              placeholderTo="To"
              value={[ filters.dateFrom, filters.dateTo ]}
              DateTimeComponent={FormatDateTime}
              onChange={([ dateFrom, dateTo ]) => onFilter({ dateFrom, dateTo })}
            />
          </div>

          <div className="mx-1 mb-2 col-md-3">
            <FormSelect
              placeholder={intl.t('streamJobLogs.form.fields.logSourcePlaceholder')}
              options={EnumStreamJobLogSource.map(id => ({ id, name: intl.t(`enum.streamJobLogSource.${id}`) }))}
              value={filters.logSource || ''}
              onChange={value => onFilter({ logSource: value })}
            />
          </div>

          <div className="mx-1 mb-2 col-md-3">
            <Select
              placeholder={intl.t('streamJobLogs.form.fields.streamJobPlaceholder')}
              value={filters.streamJobId}
              keyName="blockId"
              filters={{ withBlockId: true }}
              ListSource={StreamJobList}
              onChange={value => onFilter({ streamJobId: value })}
            />
          </div>

          <div className="me-1 mb-2 col-md-3">
            <Select
              placeholder={intl.t('streamJobLogs.form.fields.userEmailPlaceholder')}
              value={filters.userEmail}
              keyName="email"
              ListContent={({ data }) => (
                <>
                  {data.name}
                  {data.name !== data.email ? (
                    <span className="ms-2 text-muted">
                      -
                      {data.email}
                    </span>
                  ) : null}
                </>
              )}
              ListSource={UserList}
              onChange={value => onFilter({ userEmail: value })}
            />
          </div>
        </>
      )}
    </ListFilters>
  )
}
