import { useState, useMemo, useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import { useRouter } from '@/router'
import Sensor from '@/hoc/Sensor'
import FormSensor from '@/containers/Forms/Sensor'
import FormSensorBulkEdit from '@/containers/Forms/SensorBulkEdit'
import { consts } from '@wiz/utils'
import Table from '@/containers/Sensor/Table'
import TableMobile from '@/containers/Sensor/TableMobile'
import ChartsDrawer from '@/components/ChartsDrawer'

import { wizataApi } from '@/api'
import useAppContext from '@/hooks/useAppContext'
import ViewModel from './ViewModel'
import { useGlobalExecute } from '../../context/GlobalExecuteProvider'

const ViewSensor = Sensor(ViewModel)

export default function List ({
  filters,
  selected,
  onFilter,
  onResetFilter,
  onRemove,
  onUnlink,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const router = useRouter()
  const isMobile = useMobile()
  const [ editId, setEditId ] = useState(null)
  const [ viewId, setViewId ] = useState(null)
  const [ bulkEdit, setBulkEdit ] = useState(false)
  // const sensorIds = useMemo(() => selected.map(item => item.id), [ selected ])

  const { contextTwinId } = useGlobalExecute()

  const { filters: contextFilters } = useAppContext()

  // const templates = useQuery({
  //   queryKey: [ 'templatesList' ],
  //   queryFn: () => wizataApi.templates.getList('false'),
  //   enabled: true,
  //   refetchOnWindowFocus: false,
  //   retry: false,
  // })

  // const handleSuccessBulkOperation = useCallback(async (action, model) => {
  //   if (action === 'streamJob') {
  //     await window.wizConfirm({
  //       title: 't/streamJobs.titleCreate',
  //       message: 't/streamJobs.successCreate',
  //     })
  //     router.push({
  //       name: 'stream-jobs-view',
  //       params: { id: model.id },
  //     })
  //   }
  // }, [ router ])

  const itemsList = useMemo(() => ({
    inputMode: consts.SensorInputModes.map(id => ({
      id,
      name: intl.t(`enum.sensorInputMode.${id}`),
    })),
  }), [ intl ])

  const drawerSequence = [
    {
      name: 'categoryId',
      chart: consts.PanelChartType.pie,
    },
    {
      name: 'businessType',
      chart: consts.PanelChartType.pie,
    },
    {
      name: 'twinId',
      chart: consts.PanelChartType.bar, // histogram
    },
    // {
    //   name: 'labelId',
    //   chart: consts.PanelChartType.pie,
    // },
    {
      name: 'unitId',
      chart: consts.PanelChartType.pie,
    },
    {
      name: 'inputMode',
      chart: consts.PanelChartType.pie,
    },
  ]

  const possibleFilters = useMemo(() => ({
    categoryId: contextFilters?.categoryId,
    businessType: contextFilters?.businessType,
    // labelId: contextFilters?.labelIds,
    unitId: contextFilters?.unitId,
    inputMode: contextFilters?.inputMode,
    twinId: contextTwinId,
  }), [ contextFilters, contextTwinId ])

  const TableComponent = isMobile ? TableMobile : Table

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">
      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <TableComponent
          filters={filters}
          onFilter={onFilter}
          onRemove={onRemove}
          onUnlink={onUnlink}
          onEdit={setEditId}
          onView={setViewId}
          {...props}
        />
      </div>
      <ChartsDrawer
        filters={possibleFilters}
        sequence={drawerSequence}
        entity="datapoints"
        itemsList={itemsList}
        includeChildren
      />
      {bulkEdit ? (
        <FormSensorBulkEdit
          sensors={selected}
          onClose={() => setBulkEdit(false)}
          dialog={{
            title: intl.t('sensors.titleBulkEdit'),
            dataTestid: 'bulkEditSensorDialog',
          }}
        />
      ) : null}

      {editId !== null ? (
        <FormSensor
          id={editId}
          onClose={() => setEditId(null)}
          dialog={{
            title: editId ? intl.t('sensors.titleUpdate') : intl.t('sensors.titleCreate'),
            dataTestid: 'replaceSensorDialog',
          }}
        />
      ) : null}

      {viewId ? (
        <ViewSensor
          id={viewId}
          title={intl.t('sensors.titleDetail')}
          onClose={() => setViewId(null)}
          onUnlink={onUnlink}
          onFilter={onFilter}
          onRemove={onRemove}
          onEdit={setEditId}
          dialog
        />
      ) : null}
    </div>
  )
}
