import { Controller, useFormContext } from 'react-hook-form'
import { FormFieldInline } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import Select from '@/components/Form/Select'
import ExplorerList from '@/hoc/ExplorerList'

export default function FieldsExplorers ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormFieldInline
        errors={get(errors, scope)}
      >
        <Controller
          name={scope}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              ListSource={ExplorerList}
              placeholder={intl.t('form.fields.explorerPlaceholder')}
              multiselect
            />
          )}
        />
      </FormFieldInline>
    </>
  )
}
