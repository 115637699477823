import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { withObservables } from '@wiz/components'

export default withObservables([
  'twinGraph',
  'defaultValues',
], ({
  twinGraph,
  defaultValues,
}) => ({
  twins: twinGraph?.queryRelTwins ? (
    twinGraph.queryRelTwins
      .observe()
      .pipe(
        map(items => items.map(item => item.twinId)),
      )
  ) : of$(defaultValues?.twins ?? []),
}))
