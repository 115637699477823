import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { User as User$ } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import { FormSection, FormField, FormControl } from '@wiz/components'
import { useAuth } from '@/auth'
import FormDialog from '@/components/Form/FormDialog'
import FieldsRoles from '@/components/Forms/UserCreate/FieldsRoles'
import ADUser from '@/hoc/ADUser'
import { get } from '@wiz/utils'
import Removed from '../Removed'
import Fields from './Fields'
import SectionTwins from './SectionTwins'

// FIXME: LEGACY
// const ADUserData = ADUser(({ data, error }) => {
//   const intl = useIntl()

//   if (error) {
//     return (
//       <span className="text-truncate text-danger">
//         {intl.t('users.form.errors.adNotFound')}
//       </span>
//     )
//   }

//   if (data) {
//     return (
//       <span className="text-truncate">
//         {data.fullName}
//       </span>
//     )
//   }

//   return (
//     <Icon name="fa--spinner" spin />
//   )
// })

const ADUserData = ADUser(({ user }) => {
  if (!user) {
    return null
  }
  return (
    <span className="text-truncate">
      {user.fullName}
    </span>
  )
})

export default function UserUpdate ({
  id,
  user,
  accessRoleIds,
  twins,
  dialog,
  onClose,
  onSubmit,
  settings,
  scope = 'user',
}) {
  const intl = useIntl()
  const auth = useAuth()

  // const {
  //   formState: { errors },
  // } = useFormContext()

  const defaultValues = useMemo(() => ({
    user: User$.toJSON(user),
    accessRoleIds,
    twins,
    defaultTwin: settings.DefaultTwin,
  }), [
    user?.updatedAt ?? user,
    accessRoleIds,
    twins,
    settings.DefaultTwin,
  ])

  if (id && !user) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="userUpdateForm"
      dialog={dialog}
    >
      <FormSection>
        <FormField
          label={intl.t('users.form.fields.adId')}
          description={intl.t('users.form.fields.adIdDescr')}
          classNameControl="d-flex align-items-center"
        >
          <ADUserData id={defaultValues.user.adId} user={defaultValues.user} />
        </FormField>

        {defaultValues.user.firstName && defaultValues.user.userType === 'user' ? (
          <FormField
            label={intl.t('users.form.fields.firstName')}
          >
            <span className="form-control text-truncate text-muted">{defaultValues.user.firstName}</span>
          </FormField>
        ) : null}

        {defaultValues.user.userType === 'app' ? (
          <FormField
            label={intl.t('form.fields.user')}
            description={intl.t('files.form.fields.userDescr')}
          >
            <FormControl
              name={`${scope}.firstName`}
              rules={{
                validate: (value) => {
                  const str = String(value || '').trim()
                  if (!str.length) {
                    return intl.t('form.errors.fieldRequired')
                  }
                  if (value.length > 36) {
                    return intl.t('form.errors.fieldMaxlen', { max: 36 })
                  }
                  return true
                },
              }}
            />
          </FormField>
        ) : null}

        {defaultValues.user.lastName ? (
          <FormField
            label={intl.t('users.form.fields.lastName')}
          >
            <span className="form-control text-truncate text-muted">{defaultValues.user.lastName}</span>
          </FormField>
        ) : null}

        <FormField
          label={intl.t('users.form.fields.userType')}
        >
          <span className="form-control text-truncate text-muted">{defaultValues.user.userType}</span>
        </FormField>

        {defaultValues.user.email ? (
          <FormField
            label={intl.t('users.form.fields.email')}
          >
            <span className="form-control text-truncate text-muted">{defaultValues.user.email}</span>
          </FormField>
        ) : null}

        {defaultValues.user.userType === 'app' ? (
          <FormField
            label={intl.t('users.form.fields.id')}
          >
            <span className="form-control text-truncate text-muted">{defaultValues.user.id}</span>
          </FormField>
        ) : null}

        {defaultValues.user.phone ? (
          <FormField
            label={intl.t('users.form.fields.phone')}
          >
            <span className="form-control text-truncate text-muted">{defaultValues.user.phone}</span>
          </FormField>
        ) : null}

        <Fields scope="user" />

        {auth.checkAccessRead('AccessRole') ? (
          <FieldsRoles scope="accessRoleIds" />
        ) : null}
      </FormSection>

      {auth.checkAccessRead('Twin') ? (
        <SectionTwins
          title={intl.t('form.fields.twins')}
          description={intl.t('users.form.fields.twinsDescr')}
          scope="twins"
        />
      ) : null}

      {/* {auth.checkAccessRead('Twin') && twins?.length ? (
        <SectionTwins
          title={intl.t('form.fields.defaultTwin')}
          description={intl.t('users.form.fields.defaultTwinDescr')}
          scope="defaultTwin"
          multiselect={false}
        />
      ) : null} */}
    </FormDialog>
  )
}

UserUpdate.propTypes = {
  id: PropTypes.string,
  user: PropTypes.object,
  dialog: PropTypes.object,
  accessRoleIds: PropTypes.arrayOf(PropTypes.string),
  twins: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  defaultTwin: PropTypes.string,
}

UserUpdate.defaultProps = {
  id: undefined,
  user: undefined,
  dialog: undefined,
  accessRoleIds: [],
  twins: [],
  onClose: undefined,
  onSubmit: undefined,
  defaultTwin: undefined,
}
