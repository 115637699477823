import { useRef } from 'react'
import { FormSection, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import FieldsSubscribers from './FieldsSubscribers'

export default function SectionSubscribers ({ scope }) {
  const refFields = useRef()
  const intl = useIntl()

  return (
    <FormSection
      title={intl.t('sns.section.subscribers')}
      description={intl.t('sns.section.subscribersDescr')}
      addon={(
        <button
          type="button"
          className="btn btn-sm btn-flat-secondary mt-2 mt-md-0 ms-md-3 text-nowrap min-w-0"
          onClick={() => refFields.current.add()}
        >
          <Icon name="fa--plus" size="md" className="me-1" />
          {intl.t('form.actions.addSubscriber')}
        </button>
      )}
    >
      <FieldsSubscribers ref={refFields} scope={scope} />
    </FormSection>
  )
}
