/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  useRef,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react'
import cx from 'classnames'
import { useQuery, useMutation } from '@tanstack/react-query'
import {
  DiagramThemeContext,
  Icon,
  useDrag,
  Skeleton,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useTheme } from '@/theme'
import Link from '@/components/Link'
import ContextMenu from '@/containers/StreamJob/ContextMenu'
import events from '@/utils/events'
import useSplit from '@/hooks/useSplit'
import { useRouter, useLocationQuery } from '@/router'
import { wizataApi } from '@/api'
import FormPipeline from '@/components/Forms/Pipeline'
import IconFa from '@/shared/icon'
import RightBar from './rightBar'
import Diagram from './diagram'
import Console from './console'
import { useSignal } from './hooks/useSignal'

const DefaultSplitSizes = [ 100, 0 ]
const DefaultSplitSizesFullScreen = [ 0, 100 ]
const SplitMinSizes = [ 300, 0 ]

// const StreamBlockTypeAlias = {
//   flow: 'flowStream',
// }

export default function View ({
  messages, onStop, execution, embed,
}) {
  const [ contextMenuData, setContextMenuData ] = useState()
  const [ selectedIds, setSelectedIds ] = useState([])
  const [ isOpen, setOpen ] = useState(false)

  const refDiagram = useRef()
  const refRightBar = useRef()
  const refContextMenu = useRef()
  const mainSectionRef = useRef()
  const consoleSectionRef = useRef()

  const intl = useIntl()
  const theme = useTheme()
  const drag = useDrag()
  const { pipelineId } = useLocationQuery()

  const { data: pipeline, isLoading, isFetching } = useQuery({
    queryKey: [ 'pipeline', pipelineId ],
    queryFn: () => wizataApi.pipelines.getById(pipelineId),
    enabled: !!pipelineId,
    // staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const {
    mutateAsync: run,
    isLoading: isRunning,
  } = useMutation({
    mutationKey: [ 'runPipeline', pipelineId ],
    mutationFn: data => wizataApi.pipelines.execute(data), // FIXME: change execute to proper call
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/pipelines.runError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      events.emit('app:notify', {
        type: 'success',
        title: 't/pipelines.runSuccess',
        duration: 5000,
      })
      // queryClient.invalidateQueries([ 'templateRegistrations', id ])
    },
  })

  const splitParams = useMemo(() => ({
    items: [ mainSectionRef, consoleSectionRef ],
    sizes: DefaultSplitSizes, // explorerViewSplit
    defaultSizes: DefaultSplitSizes,
    minSize: 0,
    onChange: console.log,
  }), [])

  const refSplit = useSplit(splitParams)

  const handleDiagramDblClick = useCallback(({ id }) => {
    // setEditBlockId(id)
    console.log('dbl click', id)
  }, [])
  console.log('messages in pipeline: ', messages)
  const handleActionContextMenu = useCallback(async ({ action, params }, { block, loc }) => {
    refContextMenu.current?.close()
    if (action === 'edit' && params.id) {
      console.log('edit')
      // setEditBlockId(params.id)
    } else if (action === 'remove' && params.id) {
      console.log('remove')
    // onDeletedDiagram(selectedIds.length ? selectedIds : [ params.id ])
    }
  }, [
    // drag,
    // selectedIds,
    // onDeletedDiagram,
    // onCopyBlock,
    // onDropCreateDiagram,
  ])

  // const onClickCreate = useCallback(async (type, state) => {
  //   let blockState = state
  //   if (blockState.loc) {
  //     let { loc } = blockState
  //     const items = Object.values(elements)
  //     for (let i = 0; i < items.length; i += 1) {
  //       const item = items[i]
  //       if (item.loc === loc) {
  //         i = 0
  //         loc = go.Point.stringify(
  //           go.Point.parse(loc)
  //             .add(new go.Point(30, 30)),
  //         )
  //       }
  //     }
  //     blockState = {
  //       ...blockState,
  //       loc,
  //     }
  //   }
  // }, [ ])

  // useEffect(() => {
  //   if (execution) {
  //     refSplit.current?.setSizes([ 70, 30 ])
  //   } else {
  //     refSplit.current?.destroy()
  //   }
  // }, [ execution, refSplit ])

  useEffect(() => {
    console.log(refSplit)
    // split.pairs.gutter.style.display= 'none';

    return () => {
      onStop?.()
    }
  }, [])

  return (
    <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
      <div className="d-flex flex-column flex-fill">
        <div
          id="dfd-graph-container"
          className="d-flex flex-fill position-relative overflow-hidden"
        >
          <DiagramThemeContext.Provider value={theme.gojs}>
            <div className="d-flex flex-column min-h-0 min-w-0 flex-fill">
              <div ref={mainSectionRef} className="position-relative overflow-hidden bg-white d-flex">
                <Diagram data={pipeline} isRunning={isRunning} execution={execution} />
              </div>
              <div
                ref={consoleSectionRef}
                className={cx('d-flex flex-fill position-relative overflow-hidden m-2', {
                  // 'd-none': !messages?.length,
                })}
              >
                {messages?.length ? (
                  <Console messages={messages} isRunning />
                ) : null}
              </div>
            </div>
          </DiagramThemeContext.Provider>

          <ContextMenu
            {...contextMenuData}
            ref={refContextMenu}
            container="#dfd-graph-container"
            onAction={handleActionContextMenu}
          />
        </div>
      </div>
    </div>
  )
}
