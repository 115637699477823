import { set } from '@wiz/utils'
import { Q, dbProvider, checkUniqueField } from '@wiz/store'
import { intl } from '@/i18n'

export default async function resolver (data) {
  const errors = {}
  const row = data?.user || {}

  if (row.adId) {
    const isUniqADId = await checkUniqueField(
      row,
      'adId',
      dbProvider.database.collections.get('users').query(Q.where('is_deleted', false)),
    )

    if (!isUniqADId) {
      set(errors, 'user.adId', {
        type: 'unique',
        message: intl.t('form.errors.unique'),
      })
    }
  }

  return {
    errors,
  }
}
