import { FormSection } from '@wiz/components'
import FieldsView from './FieldsView'

export default function SectionView ({ scope, ...props }) {
  return (
    <FormSection {...props}>
      <FieldsView
        scope={scope}
      />
    </FormSection>
  )
}
