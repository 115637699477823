export default function executions (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      executionsCore: {
        enumerable: true,
        value: Object.create({}, {
          getWarningById: {
            enumerable: true,
            value (id) {
              return client.get(`/ExecutionWarnings/${id}`).fetch()
            },
          },
        }),
      },
    })
  }
}
