import { useMemo } from 'react'
import { FormSelect, withProps } from '@wiz/components'
import { useIntl } from '@wiz/intl'

const DataTypes = [ 'avg', 'min', 'max', 'count', 'sum', 'stdDev', 'first', 'last' ]

const enhanceData = withProps(() => {
  const intl = useIntl()
  const options = useMemo(() => (
    DataTypes.map(id => ({ id, name: intl.t(`enum.dataTypes.${id}`) }))
  ), [ intl ])

  return {
    options,
  }
})

const SelectDataType = enhanceData(FormSelect)

SelectDataType.displayName = 'SelectDataType'

export default SelectDataType
