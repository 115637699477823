import PropTypes from 'prop-types'
import { useIntl } from '@wiz/intl'
import List from '@/containers/RightBarCommands/List'

export default function RightBarCommands ({
  twinIds,
  onChange,
}) {
  const twinId = twinIds[0]
  const intl = useIntl()

  if (!twinId) {
    return (
      <div className="position-center-fill flex-column">
        <h6>
          {intl.t('twinGraph.form.errors.twinsUndefined')}
        </h6>
      </div>
    )
  }

  return (
    <List
      twinId={twinId}
      onChange={onChange}
    />
  )
}

RightBarCommands.propTypes = {
  twinIds: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}
