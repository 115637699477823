import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'

const enhanceDataConnections = withObservables([ 'edgeId' ], ({ edgeId }) => {
  let query = dbProvider.database.collections.get('edge_hub_connections').query()

  if (edgeId) {
    query = query.extend(Q.where('edge_id', edgeId))
  }

  return {
    items: query.observeWithColumns([ 'updated_at' ]),
  }
})

export default enhanceDataConnections
