import {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react'
import {
  OnlineTimeout,
  getDateFrom,
  getDateTo,
  consts,
} from '@wiz/utils'
import { withProps } from '@wiz/components'
import { wizataApi } from '@/api'
import Component from '../components/Events'

const DefaultPage = 1
const DefaultPageSize = 20
const SortByFields = {
  createdAt: 'createdDate',
  name: 'name',
  sourceType: 'sourceType',
  status: 'status',
  type: 'type',
}

function initFilters (query = {}) {
  return {
    force: undefined,
    search: query.search || undefined,
    sortBy: query.sortBy || 'createdAt',
    sortDir: query.sortDir || 'desc',
    page: query.page ? Number(query.page) : DefaultPage,
    pageSize: query.pageSize ? Number(query.pageSize) : DefaultPageSize,
    sensorIds: query.sensorIds || undefined,
  }
}

const enhanceData = withProps(({
  hidden,
  config,
}) => {
  const refTimer = useRef()
  const [ filters, setFilters ] = useState(initFilters(config))
  const [ loading, setLoading ] = useState(false)
  const [ items, setItems ] = useState([])
  const [ total, setTotal ] = useState(0)

  const immutableFilters = JSON.stringify(filters)
  const immutableConfig = JSON.stringify(config)

  useEffect(() => {
    setFilters({ ...filters, ...config, page: 1 })
  }, [ immutableConfig ])

  useEffect(() => {
    let request

    async function fetchData () {
      if (hidden) {
        return
      }

      setLoading(true)
      try {
        const page = Math.max(filters.page || 0, DefaultPage)
        const pageSize = Math.max(filters.pageSize || 0, DefaultPageSize)
        const offset = (page - 1) * pageSize
        const from = getDateFrom(config, true)
        const to = getDateTo(config, true)

        const data = await wizataApi.events.list({
          offset,
          from,
          to,
          search: filters.search,
          sortBy: SortByFields[filters.sortBy],
          sortDir: filters.sortDir,
          limit: pageSize + 1,
          eventTypes: config.eventTypes,
          statuses: config.statuses,
          sourceTypes: config.sourceTypes,
          streamJobId: config.streamJobId,
          twinIds: config.twinIds,
          sensorIds: filters.sensorIds || config.sensorIds,
          includeChildTwinEvents: config.includeChildEvents,
        }, (data) => {
          request = data
        })

        setTotal(offset + data.length)
        setItems(data.slice(0, pageSize))
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    function updateRelativeTime () {
      refTimer.current?.cancel()
      if (!hidden) {
        refTimer.current = new OnlineTimeout(() => {
          fetchData()
            .then(updateRelativeTime)
            .catch(updateRelativeTime)
        }, config.stepRequest || consts.StepRequest)
      }
    }

    fetchData()
      .then(updateRelativeTime)
      .catch(updateRelativeTime)

    return () => {
      request?.abort()
      refTimer.current?.cancel()
    }
  }, [ immutableConfig, immutableFilters, hidden ])

  const onSort = useCallback((data) => {
    setFilters(initFilters({
      ...filters,
      ...(data[0] ? {
        sortBy: data[0].id,
        sortDir: data[0].desc ? 'desc' : 'asc',
      } : {}),
    }))
  }, [ immutableFilters ])

  const onClickPage = useCallback((page) => {
    page = page === 'next' ? filters.page + 1 : filters.page - 1
    page = Math.max(page, 1)
    setFilters(initFilters({ ...filters, page }))
  }, [ immutableFilters ])

  const onClickPageSize = useCallback((event) => {
    setFilters(initFilters({ ...filters, pageSize: Number(event.target.value), page: 1 }))
  }, [ immutableFilters ])

  const onFilter = useCallback((data) => {
    setFilters(initFilters({ ...filters, ...data }))
  }, [ immutableFilters ])

  const onResetFilter = useCallback(() => {
    setFilters(initFilters({}))
  }, [ immutableFilters ])

  const forceUpdate = useCallback(() => {
    setFilters({ ...filters, force: Date.now() })
  }, [ immutableFilters ])

  return {
    loading,
    items,
    total,
    filters,
    forceUpdate,
    onFilter,
    onResetFilter,
    onSort,
    onClickPage,
    onClickPageSize,
  }
})

export default enhanceData(Component)
