import {
  memo,
  useRef,
  useMemo,
  useState,
  useCallback,
} from 'react'
import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { withProps, withObservables } from '@wiz/components'
import { isEqual } from '@wiz/utils'
import observeSensorsData from '@/utils/observeSensorsData'
import Component from '../components/TableData'

const enhanceProps = withProps(() => {
  const refLast = useRef([])
  const [ sortBy, setSortBy ] = useState('ts')
  const [ sortDir, setSortDir ] = useState('desc')
  const defaultSort = useMemo(() => ([{
    id: sortBy,
    desc: sortDir,
  }]), [ sortBy, sortDir ])

  const onSort = useCallback((data) => {
    if (data[0]) {
      setSortBy(data[0].id)
      setSortDir(data[0].desc ? 'desc' : 'asc')
    }
  }, [])

  return {
    sortBy,
    sortDir,
    refLast,
    defaultSort,
    onSort,
  }
})

const enhanceRealtimeData = withObservables([
  'config',
  'disabled',
  'refLast',
], ({
  config,
  disabled,
  refLast,
}) => ({
  data: disabled || !config.dataSources.length ?
    of$(refLast.current) :
    observeSensorsData(config, refLast),
}))

const enhanceData = withObservables([
  'data',
], ({
  data,
}) => {
  const sensorIds = data.map(({ sensorId }) => sensorId).filter(Boolean)
  return {
    sensors: sensorIds.length ? (
      dbProvider.database.collections.get('sensors')
        .query(Q.where('id', Q.oneOf(sensorIds)))
        .observeWithColumns([ 'updated_at' ])
        .pipe(
          map(items => items.reduce((acc, item) => ({ ...acc, [item.id]: item }), {})),
        )
    ) : of$({}),
  }
})

export default memo(
  enhanceProps(
    enhanceRealtimeData(
      enhanceData(Component),
    ),
  ),
  (prevProps, nextProps) => (
    isEqual(prevProps.config, nextProps.config) &&
    prevProps.disabled === nextProps.disabled
  ),
)
