import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { dbProvider, Q } from '@wiz/store'
import Component from '@/components/Forms/Import/SectionValidation'

// const ii = '819819af-bf87-4260-b3da-f4b28e523dfe'

const enhanceValidation = withObservables([ 'id' ], ({ id }) => ({
  // update if need
  job: id ? dbProvider.database.collections.get('jobs')
    .query(Q.where('id', id))
    .observeWithColumns([ 'updated_at' ])
    .pipe(map(items => items[0])) : of$({}),
}))

export default enhanceValidation(Component)
