import { useCallback, memo } from 'react'
import { dbProvider, QualityData } from '@wiz/store'
import { withProps } from '@wiz/components'
import Component from '@/components/Forms/QualityData'
import enhanceData from './enhanceData'
import enhanceExtraData from './enhanceExtraData'

const enhanceProps = withProps(() => {
  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareReplaceData(context, QualityData, data.qualityData)
    await model.prepareReplaceMeasurements(context, data.measurements)
    await dbProvider.batch(context)
  }, [])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceData(
    enhanceExtraData(
      enhanceProps(Component),
    ),
  ),
)
