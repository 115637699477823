import { Sensor, DiagramBlockFlow, Widget } from '@wiz/store'
import OptionSensor from './Sensor'
import OptionGroup from './Group'
import OptionFlow from './Flow'
import OptionWidget from './Widget'
import OptionTitle from './Title'

const Option = (props) => {
  const item = props.data.options[props.index]
  let Component

  if (item instanceof Sensor) {
    Component = OptionSensor
  } else if (item instanceof DiagramBlockFlow) {
    Component = OptionFlow
  } else if (item instanceof Widget) {
    Component = OptionWidget
  } else if (item === 'divider') {
    Component = OptionTitle
  } else {
    Component = OptionGroup
  }

  return <Component {...props} />
}

export default Option
