import { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@wiz/components'
import { useRouter } from '@/router'

export default function CellSensors ({
  event,
  options,
  onFilter,
}) {
  const router = useRouter()

  const content = useMemo(() => (
    options.map(item => item.displayName).join(', ')
  ), [ options ])

  const handleClick = useCallback(() => {
    const sensorIds = options.map(item => item.id)
    router.push({
      name: 'data-observation',
      params: { id: 'new' },
      state: {
        selection: sensorIds,
        event,
      },
    })
  }, [ options, event, router ])

  const handleFilter = useCallback(() => {
    const sensorIds = options.map(item => item.id)
    onFilter?.({ sensorIds })
  }, [ options, onFilter ])

  return options.length ? (
    <div className="d-flex align-items-center me-2 min-w-0 hover-toggle">
      <Icon
        name="fa--dot-circle"
        className="hover-hidden me-1"
        title={content}
        width={12}
      />

      <button
        type="button"
        className="hover-flex btn p-0 border-0 me-1"
        onClick={handleFilter}
      >
        <Icon
          name="fa--search"
          width={12}
        />
      </button>

      <button
        type="button"
        className="d-inline btn btn-link justify-content-start text-reset text-truncate p-0 border-0"
        title={content}
        onClick={handleClick}
      >
        {content}
      </button>
    </div>
  ) : null
}

CellSensors.propTypes = {
  options: PropTypes.array.isRequired,
  onFilter: PropTypes.func,
}

CellSensors.defaultProps = {
  onFilter: undefined,
}
