import { Controller, useFormContext } from 'react-hook-form'
import { FormField } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import SelectIcon from '@/containers/Form/SelectIcon'

export default function FieldIcon ({ scope }) {
  const intl = useIntl()
  const { disabled, formState: { errors } } = useFormContext()

  return (
    <FormField
      label={intl.t('form.fields.icon')}
      description={intl.t('label.form.fields.iconDescr')}
      errors={get(errors, `${scope}.icon`)}
    >
      <Controller
        name={`${scope}.icon`}
        render={({ field, fieldState }) => (
          <SelectIcon
            {...field}
            disabled={disabled}
            invalid={fieldState.invalid}
          />
        )}
      />
    </FormField>
  )
}
