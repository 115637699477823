import {
  forwardRef,
  useImperativeHandle,
  Fragment,
} from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import classnames from 'classnames'
import { get, has, consts } from '@wiz/utils'
import { FormFieldInline, Icon } from '@wiz/components'
import { DiagramBlockEventInterface } from '@wiz/store'
import { useIntl } from '@wiz/intl'

const FieldsInterface = forwardRef(({ scope }, ref) => {
  const intl = useIntl()
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    clearErrors,
  } = useFormContext()

  const { fields, prepend, remove } = useFieldArray({
    name: scope,
    keyName: '_id',
    shouldUnregister: true,
  })

  useImperativeHandle(ref, () => ({
    add () {
      const usedDataTypes = fields.map(item => item.dataType)
      const dataTypes = consts.DiagramBlockEventInterfaceDataTypes.filter(item => (
        !usedDataTypes.includes(item)
      ))

      if (dataTypes.length) {
        prepend(DiagramBlockEventInterface.toJSON({
          type: 'output',
          dataType: dataTypes[0],
          name: intl.t(`enum.diagramBlockEventInterfaceDataType.${dataTypes[0]}`),
          targetId: '00000000-0000-0000-0000-000000000000',
        }))
      }
    },
  }))

  return (
    <FormFieldInline complex>
      <div className="list-group list-group-flush flex-fill mx-0">
        {fields.map((item, idx) => {
          const scopeRow = `${scope}.${idx}`
          const error = (
            get(errors, `${scopeRow}.type`) ||
            get(errors, `${scopeRow}.dataType`) ||
            get(errors, `${scopeRow}.name`)
          )

          return (
            <Fragment key={item.id}>
              <div
                className={classnames('list-group-item py-2 px-0 d-flex align-items-center', {
                  'is-invalid': !!error,
                })}
              >
                <input
                  {...register(`${scopeRow}.id`)}
                  type="hidden"
                />

                <input
                  {...register(`${scopeRow}.targetId`)}
                  type="hidden"
                />

                <select
                  {...register(`${scopeRow}.type`, {
                    required: intl.t('form.errors.fieldRequired'),
                  })}
                  className={classnames('form-select me-2 w-auto', {
                    'is-invalid': has(errors, `${scopeRow}.type`),
                  })}
                >
                  <option value="output">output</option>
                </select>

                <select
                  {...register(`${scopeRow}.dataType`, {
                    required: intl.t('form.errors.fieldRequired'),
                    validate: {
                      uniq: (value) => {
                        const rowTypes = get(getValues(), scope, [])
                          .filter(row => row.dataType === value)
                        return rowTypes.length >= 2 ? intl.t('form.errors.unique') : true
                      },
                    },
                    onChange: (event) => {
                      clearErrors(scope)

                      const name = getValues(`${scopeRow}.name`)
                      const dataType = event.target.value
                      const defaultName = intl.t(`enum.diagramBlockEventInterfaceDataType.${dataType}`)

                      if (
                        !name ||
                        consts.DiagramBlockEventInterfaceDataTypes.some(id => (
                          intl.t(`enum.diagramBlockEventInterfaceDataType.${id}`) === name
                        ))
                      ) {
                        setValue(`${scopeRow}.name`, defaultName)
                      }
                    },
                  })}
                  className={classnames('form-select me-2 w-auto', {
                    'is-invalid': has(errors, `${scopeRow}.dataType`),
                  })}
                >
                  {consts.DiagramBlockEventInterfaceDataTypes.map(id => (
                    <option key={id} value={id}>
                      {intl.t(`enum.diagramBlockEventInterfaceDataType.${id}`)}
                    </option>
                  ))}
                </select>

                <input
                  {...register(`${scopeRow}.name`, {
                    validate: (value) => {
                      const str = String(value || '').trim()
                      if (!str.length) {
                        return intl.t('form.errors.fieldRequired')
                      }
                      if (value?.length > 450) {
                        return intl.t('form.errors.fieldMaxlen', { max: 450 })
                      }
                      return true
                    },
                  })}
                  type="search"
                  className={classnames('form-control me-2 w-100', {
                    'is-invalid': has(errors, `${scopeRow}.name`),
                  })}
                />

                <button
                  type="button"
                  className="btn btn-flat-secondary"
                  title={intl.t('form.actions.remove')}
                  onClick={() => remove(idx)}
                >
                  <Icon name="fa--trash-alt" />
                </button>
              </div>

              {error ? (
                <div className="invalid-feedback m-0">
                  {error.message}
                </div>
              ) : null}
            </Fragment>
          )
        })}
        {!fields.length ? (
          <div className="list-group-item text-center">
            {intl.t('form.info.paramsNotFound')}
          </div>
        ) : null}
      </div>
    </FormFieldInline>
  )
})

export default FieldsInterface
