import { useCallback, useContext } from 'react'
import {
  FormSelectDateTimeRange,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { consts } from '@wiz/utils'
import { AppContext } from '@/pages/context'
import FormatDateTime from '@/containers/FormatDateTime'
import ListFilters from '@/components/Form/ListFilters'
import SelectPipeline from '@/components/Form/SelectPipeline'
import SelectTrigger from '@/components/Form/SelectTrigger'
import Select from '@/components/Form/Select'
import SelectTemplate from '@/components/Form/ListTemplates'

const statusList = [ 'received', 'queued', 'started', 'completed', 'failed', 'aborted' ]
  .map(status => ({ id: status, value: status, name: status }))

export default function Filters ({
  exclude = '',
}) {
  const intl = useIntl()

  const { filters, onFilter } = useContext(AppContext)

  const checkIsAvailable = useCallback((name) => {
    const excludedFilters = exclude.split(',')
    return !excludedFilters.includes(name)
  }, [ exclude ])

  return (
    <ListFilters
      filters={filters}
      onFilter={onFilter}
    >
      {({ filters, onFilter }) => (
        <>
          {/* <div className="px-2 mb-2 col-md-3">
            <FormInputSearch
              placeholder={intl.t('form.actions.searchPlaceholder')}
              value={filters.search}
              onChange={debounce(value => onFilter({ search: value }), 500)}
            />
          </div> */}
          {checkIsAvailable('pipelines') ? (
            <div className="me-1 mb-2" style={{ width: '24%' }}>
              <SelectPipeline
                placeholder={intl.t('form.fields.pipelinePlaceholder')}
                value={filters.pipelineId}
              // multiselect
                onChange={pipelineId => onFilter({ pipelineId })}
                className="form-select-sm"
              />
            </div>
          ) : null}
          {checkIsAvailable('triggers') ? (
            <div className="mx-1 mb-2" style={{ width: '24%' }}>
              <SelectTrigger
                placeholder={intl.t('form.fields.triggerPlaceholder')}
                value={filters.executionTriggerId}
                onChange={executionTriggerId => onFilter({ executionTriggerId })}
                className="form-select-sm"
              />
            </div>
          ) : null}
          {checkIsAvailable('templates') ? (
            <div className="mx-1 mb-2" style={{ width: '24%' }}>
              <SelectTemplate
                placeholder={intl.t('form.fields.templatePlaceholder')}
                value={filters.templateId}
                onChange={templateId => onFilter({ templateId })}
                className="form-select-sm"
              />
            </div>
          ) : null}
          {checkIsAvailable('experiments') ? (
            <div className="mx-1 mb-2" style={{ width: '24%' }}>
              <Select
                placeholder={intl.t('form.fields.experimentPlaceholder')}
                options={consts.ExperimentFilterOptions}
                value={filters.experiments}
                onChange={experiments => onFilter({ experiments })}
                className="form-select-sm"
              />
            </div>
          ) : null}
          {checkIsAvailable('status') ? (
            <div className="me-1 mb-2" style={{ width: '24%' }}>
              <Select
                placeholder={intl.t('form.fields.statusPlaceholder')}
                options={statusList}
                value={filters.status}
                onChange={status => onFilter({ status })}
                className="form-select-sm"
              />
            </div>
          ) : null}
          {checkIsAvailable('dateRange') ? (
            <div className="ms-1 mb-2">
              <FormSelectDateTimeRange
                className="form-select-sm"
                value={(
              filters.dateFrom && filters.dateTo ?
                [ Number(filters.dateFrom), Number(filters.dateTo) ] :
                [ null, null ]
            )}
                DateTimeComponent={FormatDateTime}
                // clearable
                onChange={([ dateFrom, dateTo, dateRange ]) => {
                  onFilter({ dateFrom, dateTo, dateRange })
                }}
                extended
              />
            </div>
          ) : null}
        </>
      )}
    </ListFilters>
  )
}
