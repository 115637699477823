import { of as of$, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { withObservables, withProps } from '@wiz/components'
import { createNestedTree, filterNestedTree, filterByTypes } from '@wiz/utils'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import { enhanceData, enhanceList, enhanceGlobalContext } from './SensorList'

function reduceTwinsChain (out, item) {
  if (item.twinId && !out[item.twinId]) {
    return { ...out, [item.twinId]: item.observeTwinsChain }
  }
  return out
}

const enhanceTreeData = withObservables([ 'options' ], ({ options }) => ({
  options: options.length ? combineLatest(
    Object.values(options.reduce(reduceTwinsChain, {})).concat(of$(options)),
  ).pipe(map(items => Array.from(new Set(items.flat(Infinity))))) : of$([]),
}))

const enhanceTree = withProps(({
  excludeFrom,
  options,
  excludeBusinessTypes,
}) => {
  const isArrayExclude = Array.isArray(excludeFrom)
  const disabledFrom = data => (
    isArrayExclude ? excludeFrom.includes(data.id) : data.id === excludeFrom
  )
  const { contextTwinId } = useGlobalExecute()
  const checkEnabled = () => (true)
  const excludedOptions = filterByTypes(excludeBusinessTypes, options)
  const newOptions = {
    options: filterNestedTree({ disabledFrom })(
      createNestedTree({ checkEnabled })(excludedOptions),
    ),
  }

  const findTwinTree = (data, contextId) => {
    const result = []
    function findElement (elems) {
      for (let i = 0; i < elems?.length; i++) {
        if (elems[i].payload.selected) {
          result.push(elems[i])
        }
        if (elems[i].id === contextId) {
          result.push(elems[i])
          break
        } else {
          findElement(elems[i].children)
        }
      }
    }
    findElement(data)
    return result
  }
  const tempOpts = {
    options: contextTwinId ?
      findTwinTree(newOptions.options, contextTwinId) :
      filterNestedTree({ disabledFrom })(
        createNestedTree({ checkEnabled })(excludedOptions),
      ),
  }

  const sortByName = (a, b) => {
    if (a.payload.selected && !b.payload.selected) {
      return -1
    }
    if (!a.payload.selected && b.payload.selected) {
      return 1
    }
    return a.name.localeCompare(b.name)
  }

  tempOpts.options.sort(sortByName)

  return tempOpts
})

export default WrappedComponent => enhanceGlobalContext(
  enhanceData(
    enhanceTreeData(
      enhanceList(
        enhanceTree(WrappedComponent),
      ),
    ),
  ),
)
