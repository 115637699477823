import { set } from '@wiz/utils'
import { dbProvider, checkUniqueField, AccessRole } from '@wiz/store'
import { intl } from '@/i18n'

export default async function resolver (data) {
  const errors = {}
  const row = data?.accessRole || {}

  if (row.name) {
    const isUniqName = await checkUniqueField(
      row,
      'name',
      dbProvider.database.collections
        .get('access_roles')
        .query(AccessRole.queryWithoutPermissions()),
    )

    if (!isUniqName) {
      set(errors, 'accessRole.name', {
        type: 'unique',
        message: intl.t('form.errors.unique'),
      })
    }
  }

  return {
    errors,
  }
}
