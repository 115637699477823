import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import { FormField, FormFieldInline, FormControl } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import Selectable from '@/components/Form/Selectable'
import SelectTwin from '@/components/Form/SelectTwin'
import SelectIcon from '@/containers/Form/SelectIcon'

export default function Fields ({ scope, selectedIds }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormFieldInline
        complex
        label={intl.t('form.fields.color')}
        description={intl.t('twin.form.fields.colorDescr')}
        errors={get(errors, `${scope}.color`)}
        selectable={() => <Selectable value="color" />}
      >
        <FormControl
          type="color"
          name={`${scope}.color`}
          clearable
        />
      </FormFieldInline>

      <FormField
        complex
        label={intl.t('form.fields.icon')}
        description={intl.t('twin.form.fields.iconDescr')}
        errors={get(errors, `${scope}.icon`)}
        selectable={() => <Selectable value="icon" />}
      >
        <Controller
          name={`${scope}.icon`}
          render={({ field, fieldState }) => (
            <SelectIcon
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormField>

      <FormField
        complex
        label={intl.t('twin.form.fields.parent')}
        description={intl.t('twin.form.fields.parentDescr')}
        errors={get(errors, `${scope}.parentId`)}
        selectable={() => <Selectable value="parentId" />}
      >
        <Controller
          name={`${scope}.parentId`}
          render={({ field, fieldState }) => (
            <SelectTwin
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.twinPlaceholder')}
              filters={{
                excludeFrom: selectedIds,
              }}
            />
          )}
        />
      </FormField>
    </>
  )
}

Fields.propTypes = {
  scope: PropTypes.string.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
}

Fields.defaultProps = {
  selectedIds: [],
}
