import { useCallback, memo } from 'react'
import { dbProvider, Job } from '@wiz/store'
import { withProps } from '@wiz/components'
import { useAuth } from '@/auth'
import Component from '@/components/Forms/BGJobNotebook'
import enhanceJob from './enhanceJob'

const enhanceProps = withProps(() => {
  const auth = useAuth()
  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareReplaceData(context, Job, data.bgJob)
    if (auth.checkAccessRead('Project')) {
      await model.prepareReplaceProjects(context, data.projects)
    }
    await dbProvider.batch(context)
  }, [ auth ])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceJob(
    enhanceProps(Component),
  ),
)
