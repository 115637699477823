import { Controller, useFormContext } from 'react-hook-form'
import { get, consts } from '@wiz/utils'
import { FormField, FormControl } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import SelectSensor from '@/components/Form/SelectSensor'
import SelectDataType from '@/containers/Form/SelectDataType'
import FormatDateTime from '@/containers/FormatDateTime'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors }, watch } = useFormContext()
  const dateFrom = watch(`${scope}.dateFrom`)
  const dateTo = watch(`${scope}.dateTo`)

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('dataSource.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            maxLength: {
              value: 100,
              message: intl.t('form.errors.fieldMaxlen', { max: 100 }),
            },
          }}
          placeholder={intl.t('form.fields.namePlaceholder')}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.sensor')}
        description={intl.t('dataSource.form.fields.sensorDescr')}
        errors={get(errors, `${scope}.sensorId`)}
      >
        <Controller
          name={`${scope}.sensorId`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorPlaceholder')}
              excludeBusinessTypes={consts.ExcludedBusinessTypes}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.dataType')}
        description={intl.t('dataSource.form.fields.dataTypeDescr')}
        errors={get(errors, `${scope}.dataType`)}
      >
        <Controller
          name={`${scope}.dataType`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <SelectDataType
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.dataTypePlaceholder')}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.dateFrom')}
        description={intl.t('dataSource.form.fields.dateFromDescr')}
        errors={get(errors, `${scope}.dateFrom`)}
      >
        <FormControl
          type="dateTime"
          name={`${scope}.dateFrom`}
          rules={{
            validate: (value) => {
              if (value && dateTo && value >= dateTo) {
                return intl.t('form.errors.dateFromOutofrange')
              }
              return true
            },
          }}
          placeholder={intl.t('form.fields.datePlaceholder')}
          DateTimeComponent={FormatDateTime}
          clearable
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.dateTo')}
        description={intl.t('dataSource.form.fields.dateToDescr')}
        errors={get(errors, `${scope}.dateTo`)}
      >
        <FormControl
          type="dateTime"
          name={`${scope}.dateTo`}
          rules={{
            validate: (value) => {
              if (value && dateFrom && value <= dateFrom) {
                return intl.t('form.errors.dateToOutofrange')
              }
              return true
            },
          }}
          placeholder={intl.t('form.fields.datePlaceholder')}
          DateTimeComponent={FormatDateTime}
          clearable
        />
      </FormField>
    </>
  )
}
