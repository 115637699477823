import Icon from '@/shared/icon'
import { useIntl } from '@wiz/intl'
import Link from '@/components/Link'

const ButtonDocs = () => {
  const intl = useIntl()

  return (
    <Link
      onClick={() => window?.open?.('/auth-docs', '_blank')}
      className="btn btn-flat-secondary"
      title={intl.t('menu.tooltip.docs')}
    >
      <Icon name="faFileCode" size="lg" />
    </Link>
  )
}

export default ButtonDocs
