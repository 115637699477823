import { useIntl } from '@wiz/intl'
import Section from './Section'
import SectionConditions from './SectionConditions'

export default function SettingsSection ({ widget, widgetScope }) {
  const intl = useIntl()
  return (
    <>
      <Section
        scope="config"
        widgetScope={widgetScope}
        title={intl.t(widget.info.name)}
        description={intl.t('widgets.sensor.titleDescr')}
      />

      <SectionConditions
        scope="config"
        title={intl.t('form.fields.conditionsSection')}
        description={intl.t('widgets.sensor.form.fields.conditionsSectionDescr')}
      />
    </>
  )
}
