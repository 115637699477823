import { createRef, Component } from 'react'
import { clone, echarts } from '@wiz/utils'

export default class BulletPlot extends Component {
  static getDerivedStateFromProps (nextProps) {
    return {
      config: clone(nextProps.widget.config),
    }
  }

  refTarget = createRef()

  state = {
    config: clone(this.props.widget.config),
  }

  componentDidMount () {
    this.resetPlot()
  }

  componentWillUnmount () {
    this.$plot.dispose()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.theme !== prevProps.theme) {
      this.resetPlot()
    }

    if (this.props.dimension !== prevProps.dimension) {
      this.$plot?.resize()
    }

    if (this.state.config !== prevState.config) {
      const properties = this.getProperties()
      this.$plot?.setOption(properties, {
        notMerge: true,
        lazyUpdate: true,
        silent: true,
      })
    }
  }

  resetPlot () {
    const properties = this.getProperties()

    this.$plot?.dispose()
    this.$plot = echarts.init(this.refTarget.current, this.props.theme)
    this.$plot.setOption(properties, {
      notMerge: true,
      lazyUpdate: true,
      silent: true,
    })
  }

  getProperties () {
    const sources = [].concat(this.state.config.sources).reverse()
    const left = sources.reduce((out, item) => {
      const l = Math.min(item.label?.length * 7 + 20, 200)
      return l > out ? l : out
    }, 70)

    const properties = {
      grid: sources.map((item, index) => ({
        left,
        right: 20,
      })),

      xAxis: sources.map((item, index) => ({
        gridIndex: index,
        name: item.xAxisLabel,
        type: 'value',
        min: 'dataMin',
        max: 'dataMax',
        nameLocation: 'center',
        nameGap: 5,
        axisLabel: {
          formatter: '{value}',
          inside: true,
          margin: 2,
        },
        splitLine: { show: false },
      })),

      yAxis: sources.map((item, index) => ({
        gridIndex: index,
        type: 'category',
        data: [ item.label ],
        splitLine: { show: false },
        axisLine: { show: false },
      })),

      series: sources.map((item, index) => ({
        name: item.label,
        type: 'bar',
        barWidth: 10,
        z: 0,
        animation: false,
        data: item.data,
        xAxisIndex: index,
        yAxisIndex: index,
        markArea: {
          itemStyle: { color: 'red', opacity: 0.6 },
          data: [
            [{xAxis: item.data[1]}, {xAxis: item.data[2]}]
          ]
        }
      })),
    }

    return this.updateConfigProperties(properties)
  }

  updateConfigProperties (properties) {
    const countGrids = properties.grid.length
    const rect = this.refTarget.current.getBoundingClientRect()
    const gapHeight = 20
    const offsetTop = 30
    const offsetBottom = 30
    const chartHeight = (rect.height - offsetBottom - offsetTop - gapHeight * (countGrids - 1)) / countGrids

    return {
      ...properties,
      grid: properties.grid.map((item, idx) => ({
        ...item,
        bottom: offsetBottom + (chartHeight + gapHeight) * idx,
        top: offsetTop + (chartHeight + gapHeight) * (countGrids - idx - 1),
      })),
    }
  }

  render () {
    return (
      <div className="flex-fill position-relative">
        <div
          ref={this.refTarget}
          className="position-absolute-fill"
        />
      </div>
    )
  }
}
