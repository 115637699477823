import { of as of$, combineLatest } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { assignDefaultIsset, consts } from '@wiz/utils'
import enhanceBlocksExternalData from '@/containers/TwinGraph/enhanceBlocksExternalData'
import Component from '@/components/Twin/Graph/Diagram'

const DiagramGroupTypes = [
  consts.TwinType.Area,
  consts.TwinType.Machine,
]

const IconsByType = {
  [consts.TwinType.Area]: 'fa--warehouse',
  [consts.TwinType.Equipment]: 'fa--tools',
  [consts.TwinType.Flow]: 'fa--chart-network',
  [consts.TwinType.Machine]: 'fa--cogs',
}

const enhanceBlocks = withObservables([ 'rootTwin' ], ({ rootTwin }) => ({
  blocks: rootTwin ? rootTwin.observeTwinsTree
    .pipe(
      switchMap(items => (items.length ? combineLatest(
        items.map(item => item.observeDiagramContext),
      ) : of$([]))),

      map((items) => {
        const blocks = items
          .filter(item => item.type !== consts.TwinType.Flow)
          .map(item => assignDefaultIsset(item, {
            icon: IconsByType[item.type],
            color: null, // empty binding for gojs
            category: item.type,
            isGroup: DiagramGroupTypes.includes(item.type),
          }))

        const links = items
          .filter(item => item.type !== consts.TwinType.Flow)
          .reduce((out, item) => {
            if (item.parentId && item.parentId !== rootTwin.id) {
              out.push({
                id: `${item.parentId}/${item.id}`,
                fromId: item.parentId,
                toId: item.id,
                type: consts.DiagramBlockCategory.Parent,
                category: consts.DiagramBlockCategory.Parent,
              })
            }
            return out
          }, [])

        const flows = items
          .filter(item => (
            item.type === consts.TwinType.Flow &&
            items.some(i => i.id === item.parentId) &&
            items.some(i => i.id === item.flowToId)
          ))
          .map(item => assignDefaultIsset(item, {
            fromId: item.parentId,
            toId: item.flowToId,
            type: consts.DiagramBlockCategory.Descr,
            category: consts.DiagramBlockCategory.Descr,
          }))

        return blocks.concat(links, flows)
      }),
    ) : of$([]),
}))

export default enhanceBlocks(
  enhanceBlocksExternalData(Component),
)
