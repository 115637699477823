import PropTypes from 'prop-types'
import { Badge } from '@wiz/components'
import { truncate } from '@wiz/utils'
import TwinList from '@/hoc/TwinList'
import SensorList from '@/hoc/SensorList'
import StreamJobList from '@/hoc/StreamJobList'
import EdgeDeviceList from '@/hoc/EdgeDeviceList'
import DeviceCommandTemplateList from '@/hoc/DeviceCommandTemplateList'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import CellTwin from './CellTwin'
import CellStreamJob from './CellStreamJob'
import CellSensor from './CellSensor'
import CellDevice from './CellDevice'
import CellTemplate from './CellTemplate'

const CellSensorData = SensorList(CellSensor)
const CellStreamJobData = StreamJobList(CellStreamJob)
const CellTwinData = TwinList(CellTwin)
const CellDeviceData = EdgeDeviceList(CellDevice)
const CellTemplateData = DeviceCommandTemplateList(CellTemplate)

export default function Content ({
  data,
  onFilter,
}) {
  return (
    <div className="d-flex flex-column flex-fill min-w-0">
      <div className="d-flex align-items-baseline mb-1">
        <CellDeviceData
          className="me-2"
          value={data.deviceId}
          selectedOnly
          onFilter={onFilter}
        />

        <span className="ms-auto me-2 flex-shrink-0">
          {data.status}
        </span>

        <UpdatedInfo
          className="me-2 fw-light flex-shrink-0 small"
          date={data.createdAt}
        />
      </div>

      <div className="d-flex align-items-baseline">
        <CellTemplateData
          className="me-2"
          value={data.templateId}
          selectedOnly
          onFilter={onFilter}
        />

        <Badge className="bg-light text-dark me-2 flex-shrink-0">
          {data.payload === '' ? 'empty' : String(data.payload)}
        </Badge>

        {data.response ? (
          <Badge className="bg-light text-dark me-2 flex-shrink-0">
            {truncate(data.response)}
          </Badge>
        ) : null}

        {data.streamJobId ? (
          <CellStreamJobData
            className="me-2"
            value={data.streamJobId}
            selectedOnly
            onFilter={onFilter}
          />
        ) : null}

        {data.twinId ? (
          <CellTwinData
            className="me-2"
            value={data.twinId}
            selectedOnly
            onFilter={onFilter}
          />
        ) : null}

        {data.sensorId ? (
          <CellSensorData
            value={data.sensorId}
            keyName="id"
            selectedOnly
            onFilter={onFilter}
          />
        ) : null}
      </div>
    </div>
  )
}

Content.propTypes = {
  data: PropTypes.shape({
    deviceId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    templateId: PropTypes.string.isRequired,
    payload: PropTypes.string.isRequired,
    streamJobId: PropTypes.string,
    twinId: PropTypes.string,
    sensorId: PropTypes.string,
    createdAt: PropTypes.number.isRequired,
  }).isRequired,
  onFilter: PropTypes.func,
}

Content.defaultProps = {
  onFilter: undefined,
}
