import round from 'lodash/round'

export const appEnv = name => appEnv[name]
appEnv.I18N_LOCALE = String(process.env.I18N_LOCALE || 'en')
appEnv.I18N_FALLBACK_LOCALE = String(process.env.I18N_FALLBACK_LOCALE || 'en')
appEnv.COMMITHASH = window.__WIZ_COMMITHASH__
appEnv.UPDATED = window.__WIZ_UPDATED__ || Date.now()
appEnv.VERSION = window.__WIZ_VERSION__
appEnv.VERSION_SHORT = window.__WIZ_VERSION__?.split('-', 1)?.[0] ?? 'none'
appEnv.VERSION_URL = '/VERSION'
appEnv.SENTRY_DSN = String(process.env.SENTRY_DSN || '')
appEnv.SENTRY_ENV = String(process.env.SENTRY_ENV || '')
appEnv.NODE_ENV = String(process.env.NODE_ENV || '')
appEnv.TESTBUILD = Boolean(process.env.TESTBUILD)
appEnv.WIZ_CLIENT = String(process.env.WIZ_CLIENT || '')
appEnv.WIZ_CLIENT_TITLE = String(process.env.WIZ_CLIENT_TITLE || '')
appEnv.WIZ_CLIENT_TITLE_LOGIN = String(process.env.WIZ_CLIENT_TITLE_LOGIN || '')
appEnv.WIZ_CLIENT_LOGO = String(process.env.WIZ_CLIENT_LOGO || '')
appEnv.WIZ_CLIENT_BASE_COLOR = String(process.env.WIZ_CLIENT_BASE_COLOR || '')
appEnv.WIZ_CLIENT_ACTION_SMS_LIMIT = Number(process.env.WIZ_CLIENT_ACTION_SMS_LIMIT || 0)
appEnv.MLPIPELINE_RAWWINDOW_MAXDURATION = Number(process.env.MLPIPELINE_RAWWINDOW_MAXDURATION || 0)
appEnv.MLSERVICE_URL = 'https://wizata-ml-service.azurewebsites.net/anomaly_detection/autoencoder_anomaly_detection_train'
appEnv.DS_API_BASE_URL = process.env.DS_API_BASE_URL
appEnv.DS_API_RECENT_URL = process.env.DS_API_RECENT_URL || 'https://dev-wizard.onwizata.com/dsapi'
appEnv.COGNITO_AUTH = Boolean(process.env.COGNITO_AUTH)

window.__WIZ_ENV__ = appEnv

export const SensorValidityDefault = 3600 // 1 hour in sec
export const BlockInputAggregationTypes = [ 'count', 'min', 'max', 'sum', 'avg', 'first', 'last' ]
export const SensorDataTypes = [ 'avg', 'min', 'max', 'count', 'sum', 'stdDev', 'first', 'last' ]
export const MarkPoints = [ 'min', 'max', 'average', 'last' ]
export const ChartTypes = [ 'line', 'lineRough', 'lineStep', 'bar', 'scatter', 'histogram' ]
export const ChartTypesGroup = [ 'line', 'lineRough', 'lineStep', 'bar', 'scatter', 'scatter2d', 'histogram' ]
export const HistogramTypes = [ 'squareRoot', 'freedmanDiaconis', 'scott', 'sturges' ]
export const RegressionTypes = [ 'linear', 'linearThroughOrigin', 'exponential', 'polynomial', 'logarithmic', 'loess', 'pow', 'quad' ]
export const TimeShifts = [
  -30 * 24 * 60 * 60 * 1000,
  -7 * 24 * 60 * 60 * 1000,
  -24 * 60 * 60 * 1000,
  -8 * 60 * 60 * 1000,
  -4 * 60 * 60 * 1000,
  -2 * 60 * 60 * 1000,
  -60 * 60 * 1000,
  -30 * 60 * 1000,
  0,
  30 * 60 * 1000,
  60 * 60 * 1000,
  2 * 60 * 60 * 1000,
  4 * 60 * 60 * 1000,
  8 * 60 * 60 * 1000,
  24 * 60 * 60 * 1000,
  7 * 24 * 60 * 60 * 1000,
  30 * 24 * 60 * 60 * 1000,
]
export const ConditionIntervalTypes = [ 'eq', 'lte', 'gte', 'between' ]
export const EventStepRequests = [
  { name: '1m', id: 60000 },
  { name: '2m', id: 120000 },
  { name: '3m', id: 180000 },
  { name: '4m', id: 240000 },
  { name: '5m', id: 300000 },
  { name: '6m', id: 360000 },
  { name: '10m', id: 600000 },
  { name: '12m', id: 720000 },
  { name: '15m', id: 900000 },
  { name: '20m', id: 1200000 },
  { name: '30m', id: 1800000 },
  { name: '1h', id: 3600000 },
  { name: '2h', id: 7200000 },
  { name: '3h', id: 10800000 },
  { name: '4h', id: 14400000 },
  { name: '6h', id: 21600000 },
  { name: '8h', id: 28800000 },
]

export const ROLE_SUPER_CODE = 'roles_super'

export const RIGHTS = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  REMOVE: 'remove',
  COPY: 'copy',
  SHARE: 'share',
  MANAGE: 'manage',
  PERSONAL: 'personal',
  ORGANIZATION: 'organization',
}

export const UNPARENT_ID = '%UNPARENTID%' // Symbol()
export const IMPORTANT_ID = '%IMPORTANT%'

export const ISSUE_TYPES = {
  QUALITY: 1,
  MAINTENANCE: 2,
  PERFORMANCE: 3,
}

// image: 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
// image: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"/>',

export const MENU_ALIGMENTS = [
  {
    value: 'hr-tree',
    label: 't/chart.aligments.hrTree',
    icon: 'fa--arrow-right',
    description: 't/chart.aligments.hrTreeDescr',
    options: {
      type: 'TreeLayout',
      angle: 0,
      alternateAngle: 90,
      treeStyle: 'Layered',
      alignment: 'CenterChildren',
    },
    menu: true,
    toolbar: true,
  },
  {
    value: 'vd-tree',
    label: 't/chart.aligments.vdTree',
    icon: 'fa--arrow-down',
    description: 't/chart.aligments.vdTreeDescr',
    options: {
      type: 'TreeLayout',
      angle: 90,
      alternateAngle: 0,
      treeStyle: 'Layered',
      alignment: 'CenterChildren',
    },
    menu: true,
    toolbar: true,
  },
  {
    value: 'circular',
    label: 't/chart.aligments.circular',
    icon: 'fa--vector-square',
    description: 't/chart.aligments.circularDescr',
    options: { type: 'CircularLayout' },
    menu: true,
    toolbar: true,
  },
  {
    value: 'digraph',
    label: 't/chart.aligments.digraph',
    description: 't/chart.aligments.digraphDescr',
    options: { type: 'LayeredDigraphLayout' },
  },
  {
    value: 'grid',
    label: 't/chart.aligments.grid',
    icon: 'fa--grip-horizontal',
    description: 't/chart.aligments.gridDescr',
    options: { type: 'GridLayout' },
    menu: true,
    toolbar: true,
  },
  {
    value: 'directed',
    label: 't/chart.aligments.directed',
    icon: 'fa--bezier-curve',
    description: 't/chart.aligments.directedDescr',
    options: { type: 'ForceDirectedLayout' },
    menu: true,
    toolbar: true,
  },
  {
    value: 'hl-tree',
    label: 't/chart.aligments.hlTree',
    icon: 'fa--grip-lines',
    description: 't/chart.aligments.hlTreeDescr',
    options: {
      type: 'TreeLayout',
      angle: 180,
      alternateAngle: 0,
      treeStyle: 'Layered',
      alignment: 'CenterChildren',
    },
  },
  {
    value: 'vu-tree',
    label: 't/chart.aligments.vuTree',
    icon: 'fa--grip-lines-vertical',
    description: 't/chart.aligments.vuTreeDescr',
    options: {
      type: 'TreeLayout',
      angle: 270,
      alternateAngle: 0,
      treeStyle: 'Layered',
      alignment: 'CenterChildren',
    },
  },
]

export const BREAKPOINTS = {
  mobil: 480,
  tablet: 768,
  tabletWide: 992,
  desktop: 1240,
  xdesktop: 1680,
  xxdesktop: Infinity,
}

export const LAYOUT_OPTIONS = {
  type: 'TreeLayout',
  angle: 0,
  alternateAngle: 90,
  treeStyle: 'Layered',
  alignment: 'CenterChildren',
}

export const LAYOUT_GROUP_OPTIONS = {
  type: 'TreeLayout',
  angle: 0,
  alternateAngle: 0,
  treeStyle: 'Layered',
  alignment: 'CenterChildren',
}

export const DATA_FIELD = {
  CUTTING: 'cutting',
  EC: 'ec',
  FAILRUE: 'failure',
  HARDNESS: 'hardness',
  HEALTH: 'health',
  HP: 'hp',
  TEMPERATURE: 'temperature',
  THICKNESS: 'thickness',
  VIBRATION: 'vibration',
  WEAR: 'wear',
  WIDTH: 'width',
}

export const DATA_TYPES = {
  boolean: {
    value: 'boolean',
    label: 'Boolean',
  },
  range: {
    value: 'range',
    label: 'Range of values',
  },
  percent: {
    value: 'percent',
    label: 'Percent',
  },
}

export const DATA_FORMAT = {
  [DATA_FIELD.FAILRUE]: {
    type: 'boolean',
    value: DATA_FIELD.FAILRUE,
    title: 't/enum.sensor.failure',
    icon: 'fa--check-circle',
    format: '',
    measure: '',
    breakpoints: [
      [ false, 'good', 'fa--check-circle' ],
      [ true, 'bad', 'fa--exclamation-circle' ],
    ],
    isVisualModelAvailable: true,
    transform: value => Boolean(value),
    visualMapRange: [],
  },
  [DATA_FIELD.TEMPERATURE]: {
    type: 'range',
    value: DATA_FIELD.TEMPERATURE,
    title: 't/enum.sensor.temperature',
    icon: 'fa--thermometer-half',
    format: '{value} ℃',
    measure: '℃',
    breakpoints: [
      [ 30, 'good', 'fa--thermometer-quarter' ],
      [ 90, 'normal', 'fa--thermometer-half' ],
      [ 120, 'bad', 'fa--thermometer-full' ],
    ],
    isChartAvailable: true,
    isVisualModelAvailable: true,
    transform: value => round(value, 3),
    visualMapRange: [ 10, 100 ],
  },
  [DATA_FIELD.HP]: {
    type: 'range',
    value: DATA_FIELD.HP,
    title: 't/enum.sensor.hp',
    icon: 'fa--weight-hanging',
    format: '{value} kPa',
    measure: 'kPa',
    breakpoints: [
      [ 0.5, 'good', 'fa--weight-hanging' ],
      [ 2, 'normal', 'fa--weight-hanging' ],
      [ 2.5, 'bad', 'fa--weight-hanging' ],
    ],
    isChartAvailable: true,
    isVisualModelAvailable: true,
    transform: value => round(value, 3),
    visualMapRange: [ 0, 10 ],
  },
  [DATA_FIELD.EC]: {
    type: 'range',
    value: DATA_FIELD.EC,
    title: 't/enum.sensor.ec',
    icon: 'fa--bolt',
    format: '{value} kWh',
    measure: 'kWh',
    breakpoints: [
      [ 0.5, 'good', 'fa--bolt' ],
      [ 2, 'normal', 'fa--bolt' ],
      [ 2.5, 'bad', 'fa--bolt' ],
    ],
    isChartAvailable: true,
    transform: value => round(value, 4),
    visualMapRange: [ 0, 10 ],
  },
  [DATA_FIELD.VIBRATION]: {
    type: 'range',
    value: DATA_FIELD.VIBRATION,
    title: 't/enum.sensor.vibration',
    icon: 'fa--signature',
    format: '{value} Hz',
    measure: 'Hz',
    breakpoints: [
      [ 0.5, 'good', 'fa--signature' ],
      [ 2, 'normal', 'fa--signature' ],
      [ 2.5, 'bad', 'fa--signature' ],
    ],
    isChartAvailable: true,
    transform: value => round(value, 4),
    visualMapRange: [ 0, 10 ],
  },
  [DATA_FIELD.HEALTH]: {
    type: 'percent',
    value: DATA_FIELD.HEALTH,
    title: 't/enum.sensor.health',
    icon: 'fa--heartbeat',
    format: '{value} %',
    measure: '%',
    breakpoints: [
      [ 0.2, 'bad', 'fa--heartbeat' ],
      [ 0.8, 'normal', 'fa--heartbeat' ],
      [ 1, 'good', 'fa--heartbeat' ],
    ],
    isChartAvailable: true,
    transform: value => round(value * 100, 3),
    visualMapRange: [ 0, 100 ],
  },
  [DATA_FIELD.CUTTING]: {
    type: 'boolean',
    value: DATA_FIELD.CUTTING,
    title: 't/enum.sensor.cutting',
    icon: 'fa--check-circle',
    format: '',
    measure: '',
    breakpoints: [
      [ true, 'good', 'fa--check-circle' ],
      [ false, 'bad', 'fa--exclamation-circle' ],
    ],
    isVisualModelAvailable: true,
    transform: value => Boolean(value),
    visualMapRange: [],
  },
  [DATA_FIELD.THICKNESS]: {
    type: 'range',
    value: DATA_FIELD.THICKNESS,
    title: 't/enum.sensor.thickness',
    icon: 'fa--ruler-vertical',
    format: '{value} mm',
    measure: 'mm',
    breakpoints: [ 0, 'good', 'fa--ruler-vertical' ],
    isVisualModelAvailable: true,
    transform: value => round(value, 1),
    visualMapRange: [ 0, 100 ],
  },
  [DATA_FIELD.WEAR]: {
    type: 'percent',
    value: DATA_FIELD.WEAR,
    title: 't/enum.sensor.wear',
    icon: 'fa--cut',
    format: '{value}',
    measure: '',
    breakpoints: [ 0, 'good', 'fa--cut' ],
    isVisualModelAvailable: true,
    transform: value => round(value * 100, 3),
    visualMapRange: [ 0, 100 ],
  },
  [DATA_FIELD.WIDTH]: {
    type: 'range',
    value: DATA_FIELD.WIDTH,
    title: 't/enum.sensor.width',
    icon: 'fa--ruler-horizontal',
    format: '{value} mm',
    measure: 'mm',
    breakpoints: [ 0, 'good', 'fa--ruler-horizontal' ],
    isVisualModelAvailable: true,
    transform: value => round(value, 1),
    visualMapRange: [ 0, 2000 ],
  },
  [DATA_FIELD.HARDNESS]: {
    type: 'range',
    value: DATA_FIELD.HARDNESS,
    title: 't/enum.sensor.hardness',
    icon: 'fa--gem',
    format: '{value} HB',
    measure: 'HB',
    breakpoints: [ 0, 'good', 'fa--gem' ],
    isVisualModelAvailable: true,
    transform: value => round(value, 4),
    visualMapRange: [ 0, 1 ],
  },
}

export function getBreakpointData (dataFormat, value) {
  const data = {
    icon: undefined,
    status: undefined,
  }

  if (value === null || value === undefined) {
    return data
  }

  const isBool = typeof value === 'boolean'

  if (Array.isArray(dataFormat.breakpoints[0])) {
    for (let i = 0; i < dataFormat.breakpoints.length; i++) {
      const breakpoint = dataFormat.breakpoints[i]
      data.icon = breakpoint[2]
      data.status = breakpoint[1]

      if (isBool) {
        if (value === breakpoint[0]) {
          break
        }
      } else if (value <= breakpoint[0]) {
        break
      }
    }
  } else {
    data.icon = dataFormat.breakpoints[2]
    data.status = dataFormat.breakpoints[1]
  }

  return data
}

export const PendingMessages = [
  'Making things smarter, one device at a time...',
  'Loading... because even machines need a coffee break!',
  'Your smart control center is almost ready...',
  'Booting up the future...',
  'Connecting to your smart world...',
  'Teaching your machines new tricks... please wait!',
  'Optimizing models... the AI is becoming a genius!',
  'Teaching machines to think like humans …',
  'Machine learning in progress... robots have to study!',
  'Fine-tuning the model... your smart factory is getting smarter!',
  'Smartening up the system... one byte at a time!',
  'Connecting intelligence dots... just a moment!',
  'Upgrading your factory IQ... stay tuned!',
  'Making manufacturing magnificent, one line of code at a time.',
  'Training the A.I. overlord... responsibly (mostly).',
  "We're calculating the future of manufacturing... hold your hard hats!",
  'Smelting away inefficiencies with the power of A.I.',
  'Gear up for a smarter production line with machine learning.',
  'Excavating efficiency - one algorithm at a time.',
  'Calibrating your success, with slightly less drama than a robot uprising.',
  'Machine learning? More like profit making!',
]
