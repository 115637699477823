import { useState, useCallback } from 'react'
import { Icon, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import Table from '@/containers/ExecutionScript/Table'
import TableMobile from '@/containers/ExecutionScript/TableMobile'
import FormExecutionScript from '@/containers/Forms/ExecutionScript'
import useAppContext from '@/hooks/useAppContext'

export default function List ({
  filters,
  forceUpdate,
  onFilter,
  onResetFilter,
  onRemove,
  ...props
}) {
  const [ editId, setEditId ] = useState(null)

  const intl = useIntl()
  const auth = useAuth()
  const isMobile = useMobile()

  const { onRowSelect } = useAppContext()

  const handleCloseDialog = useCallback(() => {
    setEditId(null)
  }, [])

  const handleRemove = useCallback(async (id) => {
    await window.wizConfirm({ message: 't/units.confirmDelete' })
    await onRemove(id)
    forceUpdate()
  }, [ onRemove, forceUpdate ])

  const TableComponent = isMobile ? TableMobile : Table

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">
      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <TableComponent
          {...props}
          filters={filters}
          onFilter={onFilter}
          onEdit={setEditId}
          onRemove={handleRemove}
          onSelect={onRowSelect}
        />
      </div>

      {editId !== null ? (
        <FormExecutionScript
          id={editId}
          onSuccess={forceUpdate}
          onClose={handleCloseDialog}
          dialog={{
            title: editId ?
              intl.t('scripts.titleUpdate') :
              intl.t('scripts.titleCreate'),
            dataTestid: 'replaceScriptDialog',
          }}
        />
      ) : null}

    </div>
  )
}
