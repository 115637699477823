import withTableActions from '@/containers/withTableActions'
import Component from '@/components/QualityDataList/List'

export default withTableActions({
  initFilters (query) {
    return {
      tmpl: query.tmpl,
      approval: query.approval,
      user: query.user,
      dateFrom: query.dateFrom ? Number(query.dateFrom) : null,
      dateTo: query.dateTo ? Number(query.dateTo) : null,
    }
  },
})(Component)
