export default function ItemContent ({
  data,
  search,
  className,
  ContentDefault,
}) {
  const item = data.payload || data
  return (
    <ContentDefault
      color={item.color}
      icon={item.icon}
      name={item.displayName || item.name}
      search={search}
      className={className}
    />
  )
}
