import { useMemo } from 'react'
import { QualityData as QualityDataModel } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import Section from './Section'
import SectionMeasurements from './SectionMeasurements'

export default function QualityData ({
  id,
  qualityData,
  measurements,
  dialog,
  onClose,
  onSubmit,
}) {
  const auth = useAuth()
  const intl = useIntl()
  const defaultValues = useMemo(() => ({
    qualityData: QualityDataModel.toJSON(qualityData),
    measurements,
  }), [
    qualityData?.updatedAt ?? qualityData,
    measurements,
  ])

  if (id && !qualityData) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="qualityDataForm"
      dialog={dialog}
    >
      <Section
        scope="qualityData"
      />

      <SectionMeasurements
        title={intl.t('form.fields.measurements')}
        description={intl.t('quality.form.fields.measurementsDescr')}
        scope="measurements"
        qualityDataScope="qualityData"
      />
    </FormDialog>
  )
}
