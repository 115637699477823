import { useCallback, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormFieldInline } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import ObservQualityDataTemplate from '@/containers/Forms/QualityDataTemplate/Observ'
import SelectMeasurements from './SelectMeasurements'

export default function FieldsMeasurements ({
  scope,
  qualityDataScope,
}) {
  const intl = useIntl()
  const [ tmplMeasurements, setTmplMeasurements ] = useState([])
  const {
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext()
  const qualityDataTemplateId = watch(`${qualityDataScope}.qualityDataTemplateId`)

  const handleChange = useCallback(({ measurements }) => {
    setTmplMeasurements(measurements)
    // setValue(scope, {}, { shouldDirty: true })
    // clearErrors(scope)
  }, [ scope, setValue, clearErrors ])

  return (
    <>
      <ObservQualityDataTemplate
        id={qualityDataTemplateId}
        onChange={handleChange}
      />

      <FormFieldInline
        errors={get(errors, scope)}
        complex
      >
        <Controller
          name={scope}
          rules={{
            validate: (value) => {
              const hasValue = Object.values(value).some(item => (typeof item === 'number'))

              if (!hasValue) {
                return intl.t('quality.form.errors.measurementsNotFilled')
              }

              return true
            },
          }}
          render={({ field, fieldState }) => (
            <SelectMeasurements
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('quality.form.fields.measurementsPlaceholder')}
              tmplMeasurements={tmplMeasurements}
            />
          )}
        />
      </FormFieldInline>
    </>
  )
}
