import { intersection, type } from 'superstruct'
import { BusinessLabel } from '@wiz/store'
import * as types from '@/utils/types'

export default type({
  businessLabel: type({
    name: intersection([
      types.Required(),
      BusinessLabel.schema.schema.name,
    ]),
    description: BusinessLabel.schema.schema.description,
  }),
})
