import withTableActions from '@/containers/withTableActions'
import Component from '@/components/EdgeDeviceCommand/List'

export default withTableActions({
  initFilters (query = {}) {
    return {
      from: query.from ? Number(query.from) : undefined,
      to: query.to ? Number(query.to) : undefined,
      deviceId: query.deviceId,
      twinId: query.twinId,
      streamJobId: query.streamJobId,
      sensorId: query.sensorId,
    }
  },

  defaultFilters () {
    return {
      sortBy: 'createdAt',
    }
  },
})(Component)
