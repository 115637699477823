import { define } from 'superstruct'
import { intl } from '@/i18n'

const Minlen = minlen => define('Minlen', (value) => {
  let result = true
  const len = typeof value === 'string' ? value.length : (JSON.stringify(value)?.length ?? 0)
  if (typeof value !== 'undefined' && value !== null && len < minlen) {
    result = intl.t('form.errors.fieldMinlen', { min: minlen })
  }
  return result
})

export default Minlen
