import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'

export default withObservables([ 'id', 'defaultValues' ], ({ id, defaultValues }) => ({
  streamJob: id ? dbProvider.database.collections.get('stream_jobs')
    .query(Q.where('id', id))
    .observeWithColumns([ 'updated_at' ])
    .pipe(map(items => items[0])) : of$(defaultValues?.streamJob),
}))
