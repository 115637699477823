import { useState } from 'react'
import classnames from 'classnames'
import { Tabs } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import WidgetSection from '../Widget/Section'
import SectionData from './SectionData'
import SectionConditions from './SectionConditions'
import SectionEvents from './SectionEvents'
import SectionFilters from './SectionFilters'
import SectionView from './SectionView'

export default function WidgetChart ({
  config,
  defaultValues,
  dialog,
  onClose,
  onSubmit,
  settings,
}) {
  const intl = useIntl()
  const [ tab, setTab ] = useState('data')
  const [ filtersError, setFiltersError ] = useState(false)

  return (
    <FormDialog
      classNameBody="overflow-hidden p-0"
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="widgetChartForm"
      dialog={dialog}
    >
      <Tabs
        className="mx-3"
        value={tab}
        options={[
          {
            value: 'data',
            label: intl.t('widgets.chart.form.fields.dataSourceSection'),
          },
          {
            value: 'conditions',
            label: intl.t('widgets.chart.form.fields.conditionsSection'),
          },
          // {
          //   value: 'events',
          //   label: intl.t('widgets.chart.form.fields.eventsSection'),
          // },
          {
            value: 'filters',
            label: intl.t('widgets.chart.form.fields.filtersSection'),
            invalid: filtersError && tab !== 'filters',
          },
          {
            value: 'view',
            label: intl.t('widgets.chart.form.fields.viewSection'),
          },
        ]}
        onChange={setTab}
      />

      <div
        className={classnames('min-h-0 overflow-auto px-3 flex-fill d-flex flex-column', {
          'd-none': tab !== 'data',
        })}
      >
        <SectionData
          scope="config"
        />
      </div>

      <div
        className={classnames('min-h-0 overflow-auto px-3 flex-fill d-flex flex-column', {
          'd-none': tab !== 'conditions',
        })}
      >
        <SectionConditions
          scope="config"
        />
      </div>

      {/* <div
        className={classnames('min-h-0 overflow-auto px-3 flex-fill d-flex flex-column', {
          'd-none': tab !== 'events',
        })}
      >
        <SectionEvents
          scope="config"
        />
      </div> */}

      <div
        className={classnames('min-h-0 overflow-auto px-3 flex-fill d-flex flex-column', {
          'd-none': tab !== 'filters',
        })}
      >
        <SectionFilters
          scope="config"
          onError={setFiltersError}
          maxNumberChartValues={settings.SeriesQueryMaxCount}
          rawDateRangeLimit={settings.RawDateRangeLimit}
          rawSampleMaxCount={settings.RawQueryLimit}
        />
      </div>

      <div
        className={classnames('min-h-0 overflow-auto px-3 flex-fill d-flex flex-column', {
          'd-none': tab !== 'view',
        })}
      >
        <WidgetSection
          scope="widget"
        />
        <SectionView
          scope="config"
          config={config}
        />
      </div>

    </FormDialog>
  )
}
