/* eslint-disable react/no-danger */
import { useMemo } from 'react'
import {
  VirtualTable,
  Pagination,
  OverflowCenter,
  Icon,
} from '@wiz/components'
import { copyText } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import EditButton from '@/shared/editButton'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import CellName from './CellName'
import CellParent from './CellParent'
import useAppContext from '../../hooks/useAppContext'

export default function Table ({
  filters,
  items,
  onRemove,
  onEdit,
  onUnlinkParent,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()

  const { onRowSelect } = useAppContext()

  const columns = useMemo(() => [
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      width: 30,
      maxWidth: 30,
      className: 'justify-content-center text-nowrap',
      Cell: ({ cell, row }) => (auth.checkAccessUpdate(row.original) ? (
        <EditButton cell={cell} onEdit={onEdit} />
      ) : null),
    },
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      minWidth: 300,
      Cell: ({ row }) => (
        <CellName
          twin={row.original}
          filters={filters}
        />
      ),
    },
    {
      Header: intl.t('twin.form.fields.hardwareId'),
      accessor: 'hardwareId',
      disableResizing: true,
      width: 200,
      maxWidth: 250,
      Cell: ({ cell }) => (cell.value ? (
        <>
          <button
            type="button"
            className="btn btn-sm btn-link me-1 text-reset p-1"
            title={intl.t('message.copy.hardwareId', { value: cell.value })}
            onClick={() => copyText(cell.value)}
          >
            <Icon name="fa--copy" />
          </button>

          <OverflowCenter
            value={cell.value}
            mark={filters.search}
          />
        </>
      ) : null),
    },
    {
      Header: intl.t('twin.form.fields.type'),
      accessor: 'type',
      disableResizing: true,
      width: 100,
      maxWidth: 100,
      Cell: ({ cell }) => (cell.value),
    },
    {
      Header: intl.t('twin.form.fields.parent'),
      accessor: 'parentId',
      disableResizing: true,
      width: 200,
      maxWidth: 200,
      Cell: ({ row }) => (
        <CellParent
          twin={row.original}
          onUnlinkParent={onUnlinkParent}
        />
      ),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedAt',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: ({ cell, row }) => (
        <UpdatedInfo date={cell.value} user={row.original.updatedUser} userId={row.original.updatedById} />),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdAt',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: ({ cell, row }) => (
        <UpdatedInfo date={cell.value} user={row.original.createdUser} userId={row.original.createdById} />),
    },
  ], [
    filters,
    auth,
    intl,
    onEdit,
    onUnlinkParent,
  ])

  // if (props.loading && !items) {
  //   return (
  //     <div className="position-absolute-fill position-center-fill bg-light opacity-50">
  //       <Icon name="faSpinner" size="xl" spin />
  //     </div>
  //   )
  // }

  return (
    <>
      <VirtualTable
        className="flex-fill mb-3 mx-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={auth.checkAccessRemove('Twin') || auth.checkAccessUpdate('Twin')}
        {...props}
        onSelect={onRowSelect}
      />

      <Pagination
        className="mb-3 mx-3"
        {...props}
      />
    </>
  )
}
