import { useCallback } from 'react'
import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { withProps, withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import Widget from './components/Widget'

const enhanceTwin = withObservables([ 'config' ], ({ config }) => {
  const twinId = config?.twinId
  const streamJobId = config?.streamJobId
  let query = of$(undefined)

  if (twinId) {
    query = dbProvider.database.collections.get('twins')
      .query(Q.where('id', twinId))
      .observeWithColumns([ 'updated_at' ])
      .pipe(map(items => items[0]))
  } else if (streamJobId) {
    query = dbProvider.database.collections.get('stream_jobs')
      .query(Q.where('id', streamJobId))
      .observeWithColumns([ 'updated_at' ])
      .pipe(map(items => items[0]))
  }

  return {
    target: query,
  }
})

const enhanceProps = withProps(({ widget, onWidgetAction }) => {
  const onAction = useCallback(async ({ id }) => {
    await onWidgetAction(widget, id)
  }, [ widget, onWidgetAction ])

  return {
    onAction,
  }
})

export default enhanceTwin(
  enhanceProps(Widget),
)
