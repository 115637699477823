import queryString from 'query-string'
import { Route, Redirect } from '@/router'
import { useAuth } from '@/auth'

export default function RoutePrivate ({ render, ...props }) {
  const auth = useAuth()

  return (
    <Route
      {...props}
      render={(data) => {
        const isAuth = auth.checkAuthenticated()
        if (isAuth) {
          return render(data)
        }

        const search = queryString.stringify({
          redirect: window.location.href.replace(window.location.origin, ''),
        })

        return (
          <Redirect
            to={{
              pathname: `/${data.match.params.lang}/login`,
              search: `?${search}`,
            }}
          />
        )
      }}
    />
  )
}
