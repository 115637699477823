import { useCallback, useState, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormSection, FormField } from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import FormLogical from '@/containers/Forms/Sensor/Logical'
import SelectSensor from '@/components/Form/SelectSensor'

export default function Section ({ scope, ...props }) {
  const [ createSensor, setCreateSensor ] = useState(false)
  const intl = useIntl()
  const { register, formState: { errors } } = useFormContext()

  const formDialogParams = useMemo(() => ({
    title: intl.t('sensors.titleCreate'),
    dataTestid: 'replaceSensorDialog',
  }), [ intl ])

  const handleCloseForm = useCallback(() => {
    setCreateSensor(false)
  }, [])
  const handleFilterBusinessTypes = useCallback(item => (
    consts.SensorBusinessType.SetPoint === item ||
    consts.SensorBusinessType.Logical === item ||
    consts.SensorBusinessType.Measurement === item
  ), [])

  return (
    <>
      <FormSection {...props}>
        <input
          {...register(`${scope}.id`)}
          type="hidden"
        />

        <FormField
          label={intl.t('form.fields.sensor')}
          description={intl.t('blockOutput.form.fields.sensorDescr')}
          errors={get(errors, `${scope}.sensorId`)}
        >
          <Controller
            name={`${scope}.sensorId`}
            rules={{
              required: intl.t('form.errors.fieldRequired'),
            }}
            render={({ field, fieldState }) => (
              <SelectSensor
                {...field}
                invalid={fieldState.invalid}
                placeholder={intl.t('form.fields.sensorPlaceholder')}
                filters={{
                  filterBusinessTypes: handleFilterBusinessTypes,
                  outputAutoInputValue: true,
                }}
                listProps={{
                  postfix: (
                    <button
                      type="button"
                      className="btn btn-secondary badge me-1 mb-1"
                      onClick={() => setCreateSensor(true)}
                    >
                      {intl.t('form.actions.create')}
                    </button>
                  ),
                }}
              />
            )}
          />
        </FormField>
      </FormSection>
      {createSensor ? (
        <FormLogical
          onClose={handleCloseForm}
          dialog={formDialogParams}
        />
      ) : null}
    </>
  )
}
