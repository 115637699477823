import { useCallback } from 'react'
import { dbProvider, IntegrationSettings, Integration } from '@wiz/store'
import { withProps } from '@wiz/components'

export default withProps(({ settings }) => {
  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    const { name, description, type } = data.integration

    const integrationData = {
      name, description, type,
    }

    const model = await dbProvider.prepareReplaceData(context, Integration, integrationData)

    const setting = settings.find(setting => setting.type === type)

    for (const field of setting.fields) {
      const settingData = {
        integrationId: model.id,
        type: field,
        value: data.integration[field],
      }

      await dbProvider.prepareReplaceData(context, IntegrationSettings, settingData)
    }
    await dbProvider.batch(context)
    // return model
  }, [ settings ])

  return {
    onSubmit,
  }
})
