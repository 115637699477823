import PropTypes from 'prop-types'
import { FormSection } from '@wiz/components'
import FieldsRange from './FieldsRange'
import FieldsDateRange from './FieldsDateRange'

export default function SectionRange ({ scope, title, description }) {
  return (
    <FormSection title={title} description={description}>
      <FieldsDateRange scope={scope} />
      <FieldsRange scope={scope} />
    </FormSection>
  )
}

SectionRange.propTypes = {
  scope: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
}

SectionRange.defaultProps = {
  title: undefined,
  description: undefined,
}
