import { define } from 'superstruct'
import { intl } from '@/i18n'

const SlidingDateFromRequired = ({
  keyDuration = 'duration',
} = {}) => define('SlidingDateFromRequired', (value, { branch }) => {
  const data = branch[branch.length - 2]
  let result = true
  if (!value && !data[keyDuration]) {
    result = intl.t('form.errors.fieldOptionalRequired')
  }
  return result
})

export default SlidingDateFromRequired
