/* eslint-disable react/no-danger */
import { useMemo } from 'react'
import { ResponsiveList, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import DateRelative from '@/components/DateRelative'
import { autolink } from '@/utils/string'
import FormatDateTime from '@/containers/FormatDateTime'

function Row ({ data, index, style }) {
  const { items } = data
  const item = items[index]

  return (
    <div
      className="list-group-item list-group-item-action d-flex align-items-center"
      style={style}
    >
      <div
        className="text-truncate flex-fill min-w-0"
        dangerouslySetInnerHTML={{ __html: autolink(item.message) }}
      />
      <div className="text-nowrap text-muted mx-1">
        <FormatDateTime date={item.timestamp} withSecond />
        <sup className="ms-1">
          <DateRelative value={item.timestamp.toMillis()} />
        </sup>
      </div>
    </div>
  )
}

export default function Timeline ({
  loading,
  items,
}) {
  const intl = useIntl()
  const itemData = useMemo(() => ({
    items,
  }), [ items ])

  if (!items?.length) {
    return (
      <div className="flex-fill d-flex flex-column align-items-center justify-content-center">
        <Icon name="fa--bell" size="2X" className="opacity-50" />
        <div className="mt-2">{intl.t('notifications.form.noRecent')}</div>
      </div>
    )
  }

  return (
    <>
      <ResponsiveList
        className="flex-fill min-h-0"
        itemCount={items.length}
        itemData={itemData}
        itemSize={36}
      >
        {Row}
      </ResponsiveList>

      {loading ? (
        <div className="position-absolute-fill position-center-fill opacity-50 bg-white">
          <Icon name="fa--spinner" size="2X" spin />
        </div>
      ) : null}
    </>
  )
}
