import { useMemo } from 'react'
import { Notebook as Notebook$ } from '@wiz/store'
import { FormField } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import ButtonCopy from '@/components/Form/ButtonCopy'
import Removed from '../Removed'
import Section from './Section'
import Schema from './Schema'
import resolver from './resolver'

export default function Notebook ({
  id,
  notebook,
  projects,
  sensors,
  dialog,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()
  const defaultValues = useMemo(() => ({
    notebook: Notebook$.toJSON(notebook),
    projects,
    sensors,
  }), [
    notebook?.updatedAt ?? notebook,
    projects,
    sensors,
  ])

  if (id && !notebook) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="notebookForm"
      dialog={dialog}
      resolver={resolver}
      schema={Schema}
    >
      <Section
        scope="notebook"
        disabled={!!id}
      >
        {defaultValues.notebook.url ? (
          <FormField
            label={intl.t('notebooks.form.fields.hyperlink')}
            description={intl.t('notebooks.form.fields.hyperlinkDescr')}
          >
            <div className="d-flex align-items-center min-w-0">
              <a
                href={defaultValues.notebook.url}
                target="_blank"
                rel="noreferrer"
                className="text-truncate user-select-all"
              >
                {defaultValues.notebook.url}
              </a>
              <ButtonCopy value={defaultValues.notebook.url} />
            </div>
          </FormField>
        ) : null}

        {defaultValues.notebook.path ? (
          <FormField
            label={intl.t('notebooks.form.fields.databricksPath')}
            description={intl.t('notebooks.form.fields.databricksPathDescr')}
          >
            <div className="d-flex align-items-center min-w-0">
              <span className="text-truncate user-select-all">
                {defaultValues.notebook.path}
              </span>
              <ButtonCopy value={defaultValues.notebook.path} />
            </div>
          </FormField>
        ) : null}

        {defaultValues.notebook.userDefinedUrl ? (
          <FormField
            label={intl.t('notebooks.form.fields.userDefinedUrl')}
            description={intl.t('notebooks.form.fields.userDefinedUrlDescr')}
          >
            <div className="d-flex align-items-center min-w-0">
              <span className="text-truncate user-select-all">
                {defaultValues.notebook.userDefinedUrl}
              </span>
              <ButtonCopy value={defaultValues.notebook.userDefinedUrl} />
            </div>
          </FormField>
        ) : null}
      </Section>
    </FormDialog>
  )
}
