import { useMemo } from 'react'
import { VirtualTable, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'

export default function TableMobile ({
  items,
  onRemove,
  onEdit,
  onView,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      Header: intl.t('files.form.fields.name'),
      accessor: 'name',
      Cell: ({ cell, row }) => (
        <div className="d-flex align-items-center min-w-0">
          <div className="text-truncate">
            {cell.value}
            {row.original.description ? (
              <div className="font-italic text-muted text-truncate small">
                {row.original.description}
              </div>
            ) : null}
          </div>
        </div>
      ),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell, row }) => (
        <>
          {auth.checkAccessRemove(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-2"
              onClick={() => onRemove?.([ row.original ])}
            >
              <Icon name="fa--trash-alt" />
            </button>
          ) : null}
          {auth.checkAccessUpdate(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-2"
              onClick={() => onEdit?.(cell.value)}
            >
              <Icon name="fa--edit" />
            </button>
          ) : null}
          <button
            type="button"
            className="btn btn-sm p-2"
            onClick={() => onView?.(cell.value)}
          >
            <Icon name="fa--chevron-right" />
          </button>
        </>
      ),
    },
  ], [ intl, onEdit, onRemove, onView, auth ])

  return (
    <VirtualTable
      className="flex-fill"
      placeholder={intl.t('errors.noDataDisplay')}
      columns={columns}
      data={items}
      showHeader={false}
      selection={auth.checkAccessRemove('File') || auth.checkAccessCreate('File')}
      {...props}
    />
  )
}
