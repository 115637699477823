import { forwardRef, useCallback } from 'react'
import classnames from 'classnames'
import { consts } from '@wiz/utils'
import { FormInputNumber } from '@wiz/components'
import SelectLabel from '@/components/Form/SelectLabel'
import SelectSensor from '@/components/Form/SelectSensor'

const SelectMeasurements = forwardRef(({
  placeholder,
  value,
  tmplMeasurements,
  onChange,
}, ref) => {
  const handleChange = useCallback((measurement, data) => {
    const next = tmplMeasurements.reduce((out, item) => ({
      ...out,
      [item.id]: measurement.id === item.id ? data : (value?.[item.id] ?? null),
    }), {})
    onChange(next)
  }, [ value, onChange, tmplMeasurements ])

  return (
    <div className="flex-fill">
      {tmplMeasurements.map((item, index) => (
        <div
          key={item.id}
          className={classnames('row g-3', index !== tmplMeasurements.length - 1 && 'mb-2')}
        >
          <div className="col-md-6">
            {item.type === consts.QualityDataTemplateMeasurementType.Label ? (
              <SelectLabel
                value={item.labelId}
                readOnly
              />
            ) : null}
            {item.type === consts.QualityDataTemplateMeasurementType.Sensor ? (
              <SelectSensor
                value={item.sensorId}
                readOnly
              />
            ) : null}
          </div>
          <div className="col-md-6">
            <FormInputNumber
              scale={10}
              signed
              clearable
              value={value[item.id] ?? null}
              onChange={data => handleChange(item, data)}
            />
          </div>
        </div>
      ))}
      {!tmplMeasurements.length && placeholder ? (
        <div className="text-center">
          {placeholder}
        </div>
      ) : null}
    </div>
  )
})

export default SelectMeasurements
