import PropTypes from 'prop-types'
import { OverflowCenter, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Observe from '@/containers/Observe'
import ButtonCopy from '@/components/Form/ButtonCopy'
import NameDiagramBlockFlow from '@/components/Form/NameDiagramBlockFlow'

export default function OptionFlow (props) {
  const intl = useIntl()
  const {
    treeData: {
      onAttachEvent,
    },
    data: {
      payload: item,
    },
  } = props

  return (
    <div
      className="flex-fill d-flex align-items-start hover-toggle min-w-0"
    >
      <div className="flex-fill min-w-0">
        <div className="d-flex align-items-center justify-content-between">
          {item.twinId ? (
            <Observe query={item.twin}>
              {({ data }) => (
                <div className="d-flex align-items-center min-w-0">
                  <OverflowCenter value={data.displayName} />
                  {data.hardwareId ? (
                    <ButtonCopy
                      className="hover-inline-flex"
                      value={data.hardwareId}
                      title={intl.t('message.copy.hardwareId', { value: data.hardwareId })}
                    />
                  ) : null}
                </div>
              )}
            </Observe>
          ) : (
            <NameDiagramBlockFlow value={item} />
          )}
        </div>
        {item.twinId ? (
          <NameDiagramBlockFlow value={item} />
        ) : null}
      </div>

      {onAttachEvent && item.twinId ? (
        <Observe query={item.twin}>
          {({ data }) => (
            <button
              type="button"
              className="hover-opacity btn btn-flat-secondary align-self-center p-2 ms-1"
              title={intl.t('twinGraph.form.actions.attachEvent')}
              onClick={() => onAttachEvent(data)}
            >
              <Icon name="fa--plus" />
            </button>
          )}
        </Observe>
      ) : null}
    </div>
  )
}

OptionFlow.propTypes = {
  treeData: PropTypes.shape({
    onAttachEvent: PropTypes.func.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    payload: PropTypes.object.isRequired,
  }).isRequired,
}
