import { forwardRef, useImperativeHandle } from 'react'
import {
  useWatch,
  useFormContext,
  useFieldArray,
  Controller,
} from 'react-hook-form'
import classnames from 'classnames'
import { has, consts } from '@wiz/utils'
import {
  FormFieldInline,
  Icon,
} from '@wiz/components'
import { dbProvider, ScriptInterface, IExplorer } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import SelectDataSources from '@/components/Form/SelectDataSources'

const ConditionalDataSources = ({
  idx,
  name,
  scope,
}) => {
  const intl = useIntl()
  const { setValue } = useFormContext()
  const value = useWatch({ name: scope })
  const scopeRow = `${scope}.${idx}`
  const isInput = value?.[idx]?.type === 'input'

  return (
    <Controller
      name={name}
      rules={{
        validate: data => (isInput && !data ? intl.t('form.errors.fieldRequired') : true),
      }}
      defaultValue={value}
      render={({ field, fieldState }) => (isInput ? (
        <SelectDataSources
          {...field}
          invalid={fieldState.invalid}
          className="me-2 w-100"
          hasImportant
          onChange={async (data) => {
            const dataSource = data ? await IExplorer.createDataSourceContext(
              dbProvider.database,
              data,
            ) : null
            setValue(`${scopeRow}.dataSource`, dataSource, {
              shouldDirty: true,
              shouldValidate: true,
            })
          }}
        />
      ) : null)}
    />
  )
}

const SectionScriptInterfaceFields = forwardRef(({ scope }, ref) => {
  const intl = useIntl()
  const { register, formState: { errors } } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name: scope,
    keyName: '_id',
    shouldUnregister: true,
  })

  useImperativeHandle(ref, () => ({
    add () {
      append(ScriptInterface.toJSON({
        type: 'input',
        dataType: 'number',
        name: '',
        targetId: '00000000-0000-0000-0000-000000000000',
      }))
    },
  }))

  return (
    <FormFieldInline complex>
      <div className="list-group list-group-flush flex-fill mx-0">
        {fields.map((item, idx) => {
          const scopeRow = `${scope}.${idx}`

          return (
            <div
              key={item.id}
              className="list-group-item py-2 px-0 d-flex align-items-center"
            >
              <input
                {...register(`${scopeRow}.id`)}
                type="hidden"
              />

              <input
                {...register(`${scopeRow}.targetId`)}
                type="hidden"
              />

              <input
                {...register(`${scopeRow}.relDSPortId`)}
                type="hidden"
              />

              <select
                {...register(`${scopeRow}.type`, {
                  required: intl.t('form.errors.fieldRequired'),
                })}
                className={classnames('form-select me-2 w-auto', {
                  'is-invalid': has(errors, `${scopeRow}.type`),
                })}
              >
                {consts.ScriptInterfaceTypes.map(id => (
                  <option key={id} value={id}>{id}</option>
                ))}
              </select>

              <select
                {...register(`${scopeRow}.dataType`, {
                  required: intl.t('form.errors.fieldRequired'),
                })}
                className={classnames('form-select me-2 w-auto', {
                  'is-invalid': has(errors, `${scopeRow}.type`),
                })}
              >
                {consts.ScriptInterfaceDataTypes.map(id => (
                  <option key={id} value={id}>{id}</option>
                ))}
              </select>

              <input
                {...register(`${scopeRow}.name`, {
                  validate: (value) => {
                    const str = String(value || '').trim()
                    if (!str.length) {
                      return intl.t('form.errors.fieldRequired')
                    }
                    if (value.length > 450) {
                      return intl.t('form.errors.fieldMaxlen', { max: 450 })
                    }
                    return true
                  },
                })}
                type="search"
                className={classnames('form-control me-2 w-100', {
                  'is-invalid': has(errors, `${scopeRow}.name`),
                })}
              />

              <ConditionalDataSources
                name={`${scopeRow}.dataSource`}
                scope={scope}
                idx={idx}
              />

              <button
                type="button"
                className="btn btn-flat-secondary"
                title={intl.t('form.actions.remove')}
                onClick={() => remove(idx)}
              >
                <Icon name="fa--trash-alt" />
              </button>
            </div>
          )
        })}
        {!fields.length ? (
          <div className="list-group-item text-center">
            {intl.t('form.info.paramsNotFound')}
          </div>
        ) : null}
      </div>
    </FormFieldInline>
  )
})

export default SectionScriptInterfaceFields
