import { memo } from 'react'
import { withProps, useDidUpdate } from '@wiz/components'
import enhanceFormula from './enhanceFormula'

const enhanceProps = withProps(({ formula, onChange }) => {
  useDidUpdate(() => onChange?.({ formula }), [ formula, onChange ])
})

export default memo(
  enhanceFormula(
    enhanceProps(() => (null)),
  ),
)
