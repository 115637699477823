import PropTypes from 'prop-types'
import classnames from 'classnames'
import Icon from '@/shared/icon'

const StatusIcon = {
  'pull-error': 'faExclamationCircle',
  'push-error': 'faExclamationCircle',
  error: 'faExclamationCircle',
  pause: 'faClock',
  pending: 'faClock',
  process: 'faSync',
  synced: 'faCheckCircle',
}

export default function IconStatus ({
  syncStatus,
  className,
  size,
  online,
}) {
  return (
    <div
      className={classnames('d-flex bg-white rounded-circle', className, {
        'text-secondary': online && (
          syncStatus === 'pause' ||
          syncStatus === 'pending' ||
          syncStatus === 'process'
        ),
        'text-danger': online && (
          syncStatus === 'pull-error' ||
          syncStatus === 'push-error' ||
          syncStatus === 'error'
        ),
        'text-success': online && syncStatus === 'synced',
        'text-warning': !online,
      })}
    >
      <Icon
        type="solid"
        name={online ? (StatusIcon[syncStatus] || 'faExclamation-circle') : 'faWifiSlash'}
        size={size}
        spin={syncStatus === 'process'}
      />
    </div>
  )
}

IconStatus.propTypes = {
  className: PropTypes.string,
  syncStatus: PropTypes.string,
  size: PropTypes.string,
  online: PropTypes.bool,
}

IconStatus.defaultProps = {
  className: undefined,
  syncStatus: undefined,
  size: undefined,
  online: false,
}
