import { useCallback } from 'react'
import { of as of$, combineLatest } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { withObservables, withProps } from '@wiz/components'
import { createNestedTree, filterNestedTree } from '@wiz/utils'
import { useAuth } from '@/auth'
import Component from '@/components/EdgeTelemetry/Tree'

const enhanceData = withObservables([ 'filters' ], ({ filters }) => {
  let query = dbProvider.database.collections.get('edges').query()

  if (filters.id) {
    query = query.extend(Q.where('id', filters.id))
  }

  return {
    options: query
      .observeWithColumns([ 'updated_at' ])
      .pipe(
        switchMap(items => (
          items.length ?
            combineLatest(items.map(item => item.observeTreeContext)) :
            of$([])
        )),
        map(items => items.flat(Infinity)),
      ),
  }
})

const enhanceTree = withProps(({
  filters,
  subscribedOnly,
  options,
}) => {
  const auth = useAuth()
  const onToggleSubscribe = useCallback(async (id) => {
    const model = (await dbProvider.database.collections.get('edge_endpoint_nodes')
      .query(Q.where('id', id))
      .fetch())[0]

    if (model && auth.checkAccessUpdate(model)) {
      const context = dbProvider.createBatchContext()
      await model.prepareToggleSubscribe(context)
      await dbProvider.batch(context)
    }
  }, [ auth ])

  const disabledFrom = data => (!data.payload.isNode)

  const checkEnabled = (data) => {
    let enabled = true

    if (enabled && filters?.search) {
      enabled = (
        data.name
          .toLowerCase()
          .indexOf(filters.search) !== -1
      )
    }

    if (enabled && subscribedOnly) {
      enabled = !!data.subscribed
    }

    return enabled
  }

  options = createNestedTree({ checkEnabled })(options)
  options = filterNestedTree({ disabledFrom, forceDisableChildren: false })(options)

  return {
    options,
    onToggleSubscribe,
  }
})

export default enhanceData(
  enhanceTree(Component),
)
