import { of as of$, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { withObservables, withProps } from '@wiz/components'
import { createNestedTree, filterNestedTree } from '@wiz/utils'
import { enhanceData, enhanceList, enhanceGlobalContext } from './SensorList'

function reduceTwinsChain (out, item) {
  if (item.twinId && !out[item.twinId]) {
    return { ...out, [item.twinId]: item.observeTwinsChain }
  }
  return out
}

const enhanceTreeData = withObservables([ 'options' ], ({ options }) => ({
  options: options.length ? combineLatest(
    Object.values(options.reduce(reduceTwinsChain, {})).concat(of$(options)),
  ).pipe(map(items => Array.from(new Set(items.flat(Infinity))))) : of$([]),
}))

const enhanceTree = withProps(({
  excludeFrom,
  options,
}) => {
  const isArrayExclude = Array.isArray(excludeFrom)
  const disabledFrom = data => (
    isArrayExclude ? excludeFrom.includes(data.id) : data.id === excludeFrom
  )
  const checkEnabled = () => (true)

  return {
    options: filterNestedTree({ disabledFrom })(
      createNestedTree({ checkEnabled })(options),
    ),
  }
})

export default WrappedComponent => enhanceGlobalContext(
  enhanceData(
    enhanceTreeData(
      enhanceList(
        enhanceTree(WrappedComponent),
      ),
    ),
  ),
)
