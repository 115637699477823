import { DateTime } from 'luxon'
import { duration } from '@wiz/utils'
import DateRelative from '@/components/DateRelative'
import FormatDateTime from '@/containers/FormatDateTime'

export default function Duration ({ data }) {
  const from = DateTime.fromMillis(data.from)
  const to = data.to ? DateTime.fromMillis(data.to) : null

  return (
    <span className="me-2">
      {from && to ? <b className="me-2">{duration(to.toMillis() - from.toMillis())}</b> : null}
      <FormatDateTime date={data.from} withSecond />
      <sup className="ms-1"><DateRelative value={data.from} future /></sup>
    </span>
  )
}
