import { useCallback } from 'react'
import { Card } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import { useRouter } from '@/router'
import Events from '../containers/Events'

export default function Widget ({
  widget,
  config,
  target,
  hidden,
  canUpdateDashboard,
  onAction,
}) {
  const router = useRouter()
  const intl = useIntl()
  const auth = useAuth()
  const actions = []

  if (canUpdateDashboard) {
    actions.push({ id: 'settings', name: intl.t('form.actions.settings'), icon: 'fa--cog' })
  }

  if (auth.checkAccessRead('SectionEvents')) {
    actions.push({ id: 'events', name: intl.t('form.actions.exploreEvents'), icon: 'fa--calendar' })
  }

  if (auth.checkAccessCopy(widget) && canUpdateDashboard) {
    actions.push({ id: 'duplicate', name: intl.t('form.actions.duplicate'), icon: 'fa--clone' })
    actions.push({ id: 'multiDuplicate', name: intl.t('form.actions.multiDuplicate'), icon: 'fa--folder-plus' })
  }

  if (canUpdateDashboard) {
    actions.push({ id: 'remove', name: intl.t('form.actions.remove'), icon: 'fa--trash-alt' })
  }

  const handleAction = useCallback((action) => {
    if (action.id === 'events') {
      const to = config.dateTo || (config.duration ? Date.now() : undefined)
      const from = config.duration ? to - config.duration : config.dateFrom

      router.push({
        name: 'events-list',
        query: {
          eventTypes: config.eventTypes,
          from,
          sourceTypes: config.sourceTypes,
          statuses: config.statuses,
          streamJobId: config.streamJobId,
          to,
          twinIds: config.twinIds,
          sensorIds: config.sensorIds,
        },
      })
    } else {
      onAction?.(action)
    }
  }, [ config, onAction, router ])

  return (
    <Card
      className="position-absolute-fill"
      classNameTitle={canUpdateDashboard ? 'userdrag' : undefined}
      classNameBody="m-0"
      title={widget.title || ((target ? `${target.name} ` : '') + intl.t(widget.info.name))}
      description={widget.description}
      options={actions}
      onAction={handleAction}
    >
      <Events
        hidden={hidden}
        config={config}
        widget={widget}
      />
    </Card>
  )
}
