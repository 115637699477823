import { define } from 'superstruct'
import { intl } from '@/i18n'

const LimitMax = ({
  keyMin = 'limitMin',
} = {}) => define('LimitMax', (value, { branch }) => {
  const data = branch[branch.length - 2]
  let result = true
  if (Number.isFinite(value) && Number.isFinite(data[keyMin]) && value <= data[keyMin]) {
    result = intl.t('form.errors.rangeMaxOutofrange')
  }
  return result
})

export default LimitMax
