import { FormSection } from '@wiz/components'
import FieldsProjects from './FieldsProjects'

export default function SectionProjects ({
  scope,
  title,
  addon,
}) {
  return (
    <FormSection
      title={title}
      addon={addon}
    >
      <FieldsProjects scope={scope} />
    </FormSection>
  )
}
