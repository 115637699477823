import {
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { Icon, FormCheckButton } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { MENU_ALIGMENTS } from '@/config'
import Graph from '@/containers/PhysicalTwin/Graph'
import LeftBar from './LeftBar'
import Breadcrumb from './Breadcrumb'

export default function PhysicalTwin ({
  blockFactory,
  graphScrollTo,
  linkMode,
  onChangeDiagram,
  onToggleGrid,
  rootTwin,
  setLinkMode,
  setTwinTree,
  state,
  twinsChain,
  twinTree,
}) {
  const refDiagram = useRef()
  const refLeftBar = useRef()
  const intl = useIntl()
  const [ leftBarExpanded, setLeftBarExpanded ] = useState(!rootTwin)
  const [ scrollTo, setScrollTo ] = useState(graphScrollTo)

  const handleView = useCallback(({ recordId }) => {
    setScrollTo(recordId)
    setTwinTree(recordId)
    refDiagram.current.scrollTo(recordId)
  }, [ setTwinTree ])

  useEffect(() => {
    if (twinTree && twinTree === rootTwin?.id) {
      refDiagram.current.fit()
    }
  }, [ twinTree, rootTwin ])

  return (
    <div className="d-flex flex-fill flex-column">
      <header className="d-flex align-items-center flex-wrap px-2 border-bottom">
        <div className="px-2 mb-2">
          <button
            type="button"
            className="btn btn-flat-secondary btn-text"
            onClick={() => {
              if (!leftBarExpanded) {
                setLeftBarExpanded(true)
              } else {
                refLeftBar.current.close()
              }
            }}
          >
            <Icon name="fa--folder-tree" />
          </button>
        </div>

        <Breadcrumb
          className="flex-fill px-2 mb-2"
          options={twinsChain}
          onChange={handleView}
        />

        <div className="d-flex align-items-center flex-wrap mb-2">
          <div className="mx-2 flex-shrink-0">
            <FormCheckButton
              className="btn btn-flat-secondary btn-text"
              checked={linkMode === 'link-parent-mode'}
              label={<Icon name="fa--sitemap" />}
              title={intl.t('twinGraph.form.actions.linkParentModeTitle')}
              onChange={checked => setLinkMode(checked ? 'link-parent-mode' : null)}
            />
            <FormCheckButton
              className="btn btn-flat-secondary btn-text"
              checked={linkMode === 'link-descr-mode'}
              label={<Icon name="fa--chart-network" />}
              title={intl.t('twinGraph.form.actions.linkDescrModeTitle')}
              onChange={checked => setLinkMode(checked ? 'link-descr-mode' : null)}
            />
          </div>

          <div className="border-start rh-4 flex-shrink-0" />

          <div className="mx-2 flex-shrink-0">
            <button
              type="button"
              className="btn btn-flat-secondary btn-text"
              title={intl.t('twinGraph.form.actions.toggleGridTitle')}
              onClick={onToggleGrid}
            >
              <Icon name={state?.diagram?.hideGrid ? 'fa--border-none' : 'fa--border-all'} />
            </button>
          </div>

          <div className="border-start rh-4 flex-shrink-0" />

          <div className="mx-2 flex-shrink-0">
            {MENU_ALIGMENTS.filter(item => item.toolbar).map((item, idx) => (
              <button
                key={item.value}
                type="button"
                className="btn btn-flat-secondary btn-text"
                title={intl.t(item.description)}
                onClick={() => refDiagram.current.align(item.options)}
              >
                <Icon name={item.icon} />
              </button>
            ))}
          </div>

          <div className="border-start rh-4 flex-shrink-0" />

          <div className="mx-2 flex-shrink-0">
            <button
              type="button"
              className="btn btn-flat-secondary btn-text"
              title={intl.t('chart.form.actions.fitPosition')}
              onClick={() => refDiagram.current.fit()}
            >
              <Icon name="fa--compress-arrows-alt" />
            </button>

            <button
              type="button"
              className="btn btn-flat-secondary btn-text"
              title={intl.t('chart.form.actions.reshapeLinks')}
              onClick={() => refDiagram.current.reshapeLinks()}
            >
              <Icon name="fa--paint-roller" />
            </button>
          </div>
        </div>
      </header>

      <div className="d-flex flex-fill position-relative overflow-hidden">
        <Graph
          refDiagram={refDiagram}
          state={state}
          rootTwin={rootTwin}
          linkMode={linkMode}
          graphScrollTo={scrollTo}
          blockFactory={blockFactory}
          onChangeDiagram={onChangeDiagram}
        />

        {leftBarExpanded ? (
          <LeftBar
            ref={refLeftBar}
            value={twinTree}
            scrollTo={scrollTo}
            onView={handleView}
            onClose={() => setLeftBarExpanded(false)}
          />
        ) : null}
      </div>
    </div>
  )
}
