import {
  useRef,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react'
import { fullscreen } from '@wiz/utils'
import { Card } from '@wiz/components'
import { DataSource } from '@wiz/store'
import { useTheme } from '@/theme'
import { useAuth } from '@/auth'
import { useIntl } from '@wiz/intl'
import SensorChart from '@/containers/SensorChart'

export default function Widget ({
  widget,
  explorerContext,
  hidden,
  canUpdateDashboard,
  onAction,
  onToggleSelectedLegend,
  onResizeLegend,
  onChangeViewZoom,
}) {
  const refCard = useRef()
  const refChart = useRef()
  const intl = useIntl()
  const theme = useTheme()
  const auth = useAuth()
  const [ errors, setErrors ] = useState({})
  const [ counts, setCounts ] = useState({})
  const [ enabledFullscreen, setEnabledFullscreen ] = useState(false)
  const configured = (
    explorerContext.dataSources.length > 0 &&
    explorerContext.dataViews.length > 0
  )

  const title = useMemo(() => {
    if (widget.title) {
      return widget.title
    }
    if (explorerContext.dataSources.length === 1) {
      const source = explorerContext.dataSources[0]
      return DataSource.getDisplayName(source)
    }
    return intl.t(widget.info.name)
  }, [ widget.title, explorerContext ])

  const handleAction = useCallback((data) => {
    switch (data.id) {
      case 'exploreValues':
        refChart.current.exploreValues()
        break
      case 'downloadCSV':
        refChart.current.downloadCSV({ title })
        break
      case 'saveAsImage':
        refChart.current.saveAsImage()
        break
      case 'fullscreenOff':
        fullscreen.close()
        setEnabledFullscreen(false)
        break
      case 'fullscreenOn':
        fullscreen.open(refCard.current)
        setEnabledFullscreen(true)
        break
      default:
        onAction?.(data)
    }
  }, [ onAction, title ])

  let actions = []
  if (canUpdateDashboard && !enabledFullscreen) {
    actions.push({ id: 'settings', name: intl.t('form.actions.settings'), icon: 'fa--cog' })
  }
  if (auth.checkAccessRead('SectionDataExplorer') && !enabledFullscreen) {
    actions.push({ id: 'dataExplorer', name: intl.t('form.actions.openExplorer'), icon: 'explorer' })
  }
  if (auth.checkAccessCopy(widget) && canUpdateDashboard && !enabledFullscreen) {
    actions.push({ id: 'duplicate', name: intl.t('form.actions.duplicate'), icon: 'fa--clone' })
    actions.push({ id: 'multiDuplicate', name: intl.t('form.actions.multiDuplicate'), icon: 'fa--folder-plus' })
  }

  if (enabledFullscreen) {
    actions.push({ id: 'fullscreenOff', name: intl.t('form.actions.fullscreenOff'), icon: 'fa--compress' })
  } else if (configured) {
    actions.push({ id: 'fullscreenOn', name: intl.t('form.actions.fullscreenOn'), icon: 'fa--expand' })
  }

  if (configured) {
    actions = actions.concat(
      actions.length ? { divider: true } : [],
      { id: 'exploreValues', name: intl.t('form.actions.exploreValues'), icon: 'fa--list-alt' },
      { id: 'downloadCSV', name: intl.t('form.actions.downloadCSV'), icon: 'fa--file-csv' },
      { id: 'saveAsImage', name: intl.t('form.actions.saveAsImage'), icon: 'fa--file-image' },
    )
  }

  if (canUpdateDashboard && !enabledFullscreen) {
    actions = actions.concat(
      actions.length ? { divider: true } : [],
      { id: 'remove', name: intl.t('form.actions.remove'), icon: 'fa--trash-alt' }
    )
  }

  useEffect(() => {
    const onChange = (element) => {
      if (!element || element === refCard.current) {
        setEnabledFullscreen(fullscreen.isEnabled)
      }
    }
    fullscreen.on('change', onChange)
    return () => {
      fullscreen.removeListener('change', onChange)
    }
  }, [])

  return (
    <Card
      ref={refCard}
      className="position-absolute-fill"
      classNameTitle={canUpdateDashboard ? 'userdrag' : undefined}
      title={title}
      description={widget.description}
      fullscreen={enabledFullscreen}
      options={actions}
      onAction={handleAction}
    >
      {do {
        if (configured) {
          <SensorChart
            refChart={refChart}
            className={configured ? undefined : 'd-none'}
            theme={theme.echarts}
            name={widget.id}
            disabled={hidden}
            dataFilter={explorerContext.dataFilter}
            conditions={explorerContext.conditions}
            dataSources={explorerContext.dataSources}
            dataViews={explorerContext.dataViews}
            eventSources={explorerContext.eventSources}
            errors={errors}
            counts={counts}
            onToggleSelectedLegend={onToggleSelectedLegend}
            onResizeLegend={onResizeLegend}
            onChangeViewZoom={onChangeViewZoom}
            onErrors={setErrors}
            onChangeCounts={setCounts}
          />
        } else if (canUpdateDashboard) {
          <div className="d-flex align-items-center justify-content-center flex-fill">
            <button
              type="button"
              className="btn btn-flat-secondary"
              onClick={() => onAction({ id: 'settings' })}
            >
              {intl.t('widgets.selectSensor')}
            </button>
          </div>
        } else {
          <div className="d-flex align-items-center justify-content-center flex-fill">
            {intl.t('widgets.notConfigured')}
          </div>
        }
      }}
    </Card>
  )
}
