import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ReportingObserver, Dedupe, Offline } from '@sentry/integrations'
import { appEnv } from '@/config'
import { ExpectedError, UnauthorizedError, ForbiddenError } from '@wiz/utils'

export { ErrorBoundary, withProfiler } from '@sentry/react'

if (
  appEnv.SENTRY_DSN &&
  appEnv.NODE_ENV === 'production' &&
  !appEnv.TESTBUILD
) {
  Sentry.init({
    dsn: appEnv.SENTRY_DSN,
    release: appEnv.VERSION,
    environment: appEnv.SENTRY_ENV || appEnv.NODE_ENV,
    maxBreadcrumbs: 10,
    sampleRate: 0.5,
    tracesSampleRate: 0.1,
    debug: false,
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
      new ReportingObserver({
        types: [ 'crash', 'intervention' ],
      }),
      new Dedupe(),
      new Offline({ maxStoredEvents: 10 }),
    ],
    ignoreErrors: [
      'NavigationDuplicated',
      'Error parsing handshake response',
      'hash_empty_error',
      'endpoints_resolution_error',
      'post_request_failed',
      'no_network_connectivity',
      'Failed to fetch',
      'No error message',
    ],
    denyUrls: [
      /extensions\//i,
      /^chrome:\/\//i,
    ],
    beforeSend (event, hint) {
      const error = hint.originalException

      if (
        (error instanceof ExpectedError) ||
        (error instanceof UnauthorizedError) ||
        (error instanceof ForbiddenError)
      ) {
        return null
      }

      // if (event.exception) {
      //   Sentry.showReportDialog({
      //     eventId: event.event_id
      //   })
      // }

      return event
    },
  })
}

export function registerUser ({ id, email }) {
  Sentry.configureScope((scope) => {
    scope.setUser({ id, email })
  })
}

export function unregisterUser () {
  Sentry.configureScope(scope => scope.clear())
}

export function captureException (error) {
  Sentry.captureException(error)
}
