import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { toArrayValue, orderBy } from '@wiz/utils'
import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { auth } from '@/auth'

export default withObservables([
  'groupByTwinId',
  'search',
  'selectedOnly',
  'value',
], ({
  groupByTwinId,
  search,
  selectedOnly,
  value,
}) => {
  let query = dbProvider.database.collections.get('explorers').query()
  let pipes = [
    map(items => orderBy(
      items.filter(item => auth.checkAccessRead(item)),
      [ 'updatedAt', 'createdAt' ],
      [ 'desc', 'desc' ],
    )),
  ]

  if (search) {
    const sanitizeSearch = Q.sanitizeLikeString(search)
    query = query.extend(Q.or(
      Q.where('title', Q.like(`%${sanitizeSearch}%`)),
      Q.where('id', search),
    ))
  }

  if (selectedOnly) {
    query = query.extend(Q.where('id', Q.oneOf(toArrayValue(value))))
  }

  // in DE opened from right bar in diagrams
  if (groupByTwinId) {
    query = query.extend(Q.unsafeLokiTransform((rows, loki) => (rows.filter((record) => {
      const rel = loki.getCollection('rel_twins_explorers').findOne({
        twin_id: groupByTwinId,
        explorer_id: record.id,
        _status: { $ne: 'deleted' },
      })

      return !rel
    }))))
  }

  let observe = selectedOnly ?
    query.observeWithColumns([ 'updated_at' ]) :
    query.observe()

  if (groupByTwinId) {
    observe = combineLatest(
      dbProvider.database.collections.get('twins')
        .query(Q.where('id', groupByTwinId)),
      dbProvider.database.collections.get('explorers')
        .query(Q.on('rel_twins_explorers', Q.where('twin_id', groupByTwinId))),
      observe,
    )

    pipes = [
      map(([ twins, itemsTwin, items ]) => {
        itemsTwin = itemsTwin.filter(item => auth.checkAccessRead(item))
        items = items.filter(item => auth.checkAccessRead(item))

        let data = []
        if (twins.length && itemsTwin.length) {
          data = data.concat(
            {
              id: 'linked',
              name: 't/explorer.titleLinkedToTwin',
              disabled: true,
              twinName: twins[0].displayName,
            },
            orderBy(itemsTwin, [ 'updatedAt', 'createdAt' ], [ 'desc', 'desc' ]),
          )

          if (items.length) {
            data = data.concat({
              id: 'others',
              name: 't/explorer.titleOthers',
              disabled: true,
            })
          }
        }

        if (items.length) {
          data = data.concat(
            orderBy(items, [ 'updatedAt', 'createdAt' ], [ 'desc', 'desc' ]),
          )
        }

        return data
      }),
    ]
  }

  return {
    options: observe.pipe(...pipes),
  }
})
