import { useMemo, useState } from 'react'
import { map } from 'rxjs/operators'
import {
  Form, FormSelect, FormControl, Icon, withObservables,
} from '@wiz/components'
import { Condition, dbProvider, Q } from '@wiz/store'
import { uuid } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import events from '@/utils/events'

const enhanceProps = withObservables([ 'options', 'sensorIds' ], ({ options, sensorIds }) => {
  if (options?.length) {
    return {
      options,
    }
  }

  const query = dbProvider.database.collections.get('sensors').query(Q.where('id', Q.oneOf(sensorIds)))

  return {
    options: query.observeWithColumns([ 'updated_at' ]).pipe(map(items => items.map(item => ({
      id: item.hardwareId,
      name: item.name,
    })))),
  }
})

const Filter = enhanceProps(({ options, filters, onFiltersAdd }) => {
  const [ filter, setFilter ] = useState({
    filterSensor: undefined,
    operator: undefined,
    threshold: null,
  })
  const [ isAdding, setAdding ] = useState(false)

  const intl = useIntl()

  const normalizedFilters = useMemo(() => {
    const isArray = Array.isArray(filters)
    if (isArray) {
      return filters
    }

    let next = []

    Object.entries(filters).map(([ key, value ]) => {
      const item = Object.entries(value).map(([ operator, threshold ]) => ({
        id: uuid(),
        filterSensor: key,
        operator,
        threshold,
      }))

      next = next.concat(item)
    })

    return next
  }, [ filters ])

  const handleEdit = (id) => {
    const next = normalizedFilters.find(f => f.id === id)
    setFilter(next)
    setAdding(true)
  }

  const handleDelete = (id) => {
    const next = normalizedFilters.filter(f => f.id !== id)
    onFiltersAdd(next)
  }

  const handleClose = (isEdit) => {
    if (isEdit) {
      setFilter({
        filterSensor: undefined,
        operator: undefined,
        threshold: null,
      })
    }
    setAdding(false)
  }

  const handleSave = () => {
    // const next = {
    //   ...filters,
    //   [filter.filterSensor]: {
    //     ...filters[filter.filterSensor],
    //     [filter.operator]: filter.threshold,
    //   },
    // }
    const exist = normalizedFilters
      .find(f => f.filterSensor === filter.filterSensor &&
      f.operator === filter.operator &&
      f.threshold === filter.threshold)

    if (exist) {
      events.emit('app:notify', {
        type: 'error',
        title: 'Error',
        message: 'Same filter already exists',
        duration: 5000,
      })
      return null
    }

    const next = filters.map((f) => {
      if (f.id === filter.id) {
        return filter
      }
      return f
    })
    if (!filter.id) {
      next.push({ ...filter, id: uuid() })
    }
    onFiltersAdd(next)
    setFilter({
      filterSensor: undefined,
      operator: undefined,
      threshold: null,
    })
    setAdding(false)
  }

  return (
    <div className="d-flex flex-fill mt-2 px-3 m-1 flex-column">
      <div className="d-flex flex-wrap justify-content-between">
        <b>Filters:</b>
        <button
          type="button"
          className="btn btn-flat-secondary"
          onClick={() => setAdding(true)}
        >
          <Icon name="fa--plus" className="me-1" />
          {intl.t('form.actions.add')}
        </button>
      </div>
      <hr className="my-2" />

      {!isAdding && normalizedFilters?.length ? (
        <div className="d-flex flex-column mt-2">
          {normalizedFilters.map(({
            filterSensor, operator, threshold, id,
          }) => (
            <div className="d-flex py-2 align-items-center" key={id}>
              <span className="flex-fill">{filterSensor}</span>
              <span className="px-2 border-end">
                {intl.t(`enum.conditionType.${operator}`)}
              &nbsp;
                {threshold}
              </span>
              <div className="d-flex ms-2 pointer" onClick={() => handleEdit(id)} aria-hidden>
                <Icon name="fa--edit" />

              </div>
              <div className="d-flex ms-2 pointer" onClick={() => handleDelete(id)} aria-hidden>
                <Icon name="fa--trash" />
              </div>
            </div>
          ))}
        </div>
      ) : null }

      {!normalizedFilters.length && !isAdding ?
        <div className="flex-fill mt-4">No active filters! Add one by pushing the button above!</div> :
        null}

      {isAdding ? (
        <AddForm
          filter={filter}
          sensors={options || []}
          setFilter={setFilter}
          onSave={handleSave}
          onClose={handleClose}
        />
      ) : null}
    </div>
  )
})

function AddForm ({
  filter, sensors, setFilter, onSave, onClose,
}) {
  const intl = useIntl()
  const sensorOptions = useMemo(() => sensors.map((sensor) => {
    if (typeof sensor === 'string') {
      return {
        id: sensor,
        name: sensor,
      }
    }
    return sensor
  }), [ sensors ])

  return (
    <Form>
      <div>
        <div className="flex-fill my-2">Select sensor to filter:</div>
        <FormSelect
          placeholder="Select sensor"
          options={sensorOptions}
          onChange={(filterSensor) => {
            setFilter(prev => ({ ...prev, filterSensor }))
          }}
          value={filter.filterSensor}
          disabled={!!filter.id}
        />
      </div>

      <div>
        <div className="flex-fill my-2">Choose condition:</div>
        <FormSelect
          placeholder="Select condition"
          options={Condition.TYPES.map(conditionType => (
            { id: conditionType, name: intl.t(`enum.conditionType.${conditionType}`) }))}
          onChange={(operator) => { setFilter(prev => ({ ...prev, operator })) }}
          value={filter.operator}
        />
      </div>

      <div>
        <div className="flex-fill my-2">Input value:</div>
        <FormControl
          type="number"
          clearable
          scale={6}
          step={1}
          value={filter.threshold}
          defaultValue={filter.threshold}
          onChange={(threshold) => { setFilter(prev => ({ ...prev, threshold })) }}
        />
      </div>
      <div className="my-2 mt-4">
        <button
          name="cancel"
          type="button"
          data-testid="saveAndNextButton"
          className="btn btn-outline-secondary"
          onClick={onSave}
        >
          <span className="d-none d-sm-inline">{intl.t('form.actions.save')}</span>
        </button>
        <button
          type="button"
          className="btn btn-fill-secondary mx-2"
          onClick={() => onClose(filter.id)}
        >
          {intl.t('form.actions.cancel')}
        </button>
      </div>
    </Form>
  )
}

export { Filter }
