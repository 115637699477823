/* eslint-disable react/no-danger */
import {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import queryString from 'query-string'
import classnames from 'classnames'
import { useIntl } from '@wiz/intl'
import { Icon } from '@wiz/components'
import { appEnv } from '@/config'
import { auth, EventType } from '@/auth'
import { AUTH_SCHEMES } from '@/auth/cognito'
import { useRouter } from '@/router'
import { AUTH_OPTIONS } from './options'
import { CognitoLogin } from './Cognito'
import classes from './Login.css'

export default function View () {
  const intl = useIntl()
  const router = useRouter()
  const [ loading, setLoading ] = useState(false)
  const [ open, setOpen ] = useState(false)
  const { redirect } = queryString.parse(window.location.search)

  const handleLogin = useCallback((scheme) => {
    setLoading(scheme)
    if (redirect?.endsWith('logout')) {
      router.push('/')
    }
    if (scheme === AUTH_SCHEMES.aws) {
      setOpen(true)
      return null
    }
    auth.login()
    return null
  }, [ router, redirect ])

  const handleClose = useCallback(() => {
    setOpen(false)
    setLoading(false)
  }, [])

  useEffect(() => {
    const handleLoginFailure = (message) => {
      setLoading(false)
      console.error(message)
    }

    const handleLoginSuccess = () => {
      router.reload()
    }

    auth.on(EventType.LoginFailure, handleLoginFailure)
    auth.on(EventType.LoginSuccess, handleLoginSuccess)
    return () => {
      auth.removeListener(EventType.LoginFailure, handleLoginFailure)
      auth.removeListener(EventType.LoginSuccess, handleLoginSuccess)
    }
  }, [ router ])

  const authSchemes = useMemo(() => AUTH_OPTIONS.filter(option => option.active), [])

  const env = {
    logo: localStorage.getItem('EnvironmentLogo'),
    color: localStorage.getItem('EnvironmentColor'),
    name: localStorage.getItem('EnvironmentName'),
  }

  return (
    <div
      className={classes.root}
    >
      <div
        className={classes.bg}
      />

      <div className={classnames('card border-0 m-3', classes.card, {
        'pt-3 px-4 pb-4': !open,
      })}
      >
        {open ? (
          <>
            <div className="card-header pt-4 text-uppercase text-white align-items-center d-flex justify-content-center">
              <Icon
                className="me-2"
                width="2em"
                height="2em"
                name="aws-cognito"
              />
              Cognito login
            </div>
            <div className="card-body text-white py-0 pb-3">
              <CognitoLogin open={open} onClose={handleClose} scheme={loading} />
            </div>
          </>
        ) : (
          <div className="card-body p-4">
            <h5 className="card-title d-flex align-items-center justify-content-center mb-4">
              {env.logo ? <img src={env.logo} alt="logo" style={{ width: '3em', height: '3em', objectFit: 'contain' }} className="me-2" /> : (
                <Icon
                  style={{ color: env.color || appEnv.WIZ_CLIENT_BASE_COLOR }}
                  name={appEnv.WIZ_CLIENT_LOGO}
                  size="3X"
                  className="me-2"
                />
              ) }
              <div
                style={{ color: env.color || appEnv.WIZ_CLIENT_BASE_COLOR }}
                dangerouslySetInnerHTML={{
                  __html: env.name || appEnv.WIZ_CLIENT_TITLE_LOGIN,
                }}
              />
            </h5>
            <p className="card-text text-center">
              {intl.t('login.description', { title: env.name || appEnv.WIZ_CLIENT_TITLE })}
            </p>
            <hr />
            <div className="text-center d-flex gap-3 justify-content-center">
              {authSchemes?.map(({
                title, id, authScheme, icon,
              }) => (
                <button
                  type="button"
                  className={classnames('btn btn-outline-secondary fs-7')}
                  disabled={loading && authScheme === loading}
                  onClick={() => handleLogin(authScheme)}
                  key={id}
                >
                  <Icon
                    className="me-2"
                    name={!!loading && authScheme === loading ? 'fa--spinner' : icon}
                    spin={!!loading && authScheme === loading}
                  />
                  {title}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
