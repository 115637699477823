import { useMemo } from 'react'
import { Table as StaticTable, Pagination } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import UpdatedInfo from '@/components/Form/UpdatedInfo'

export default function TableConnections ({
  items,
  ...props
}) {
  const intl = useIntl()
  const columns = useMemo(() => [
    {
      Header: intl.t('edge.connections.form.fields.clientId'),
      accessor: 'clientId',
      width: 300,
    },
    {
      Header: intl.t('edge.connections.form.fields.status'),
      accessor: 'status',
      width: 200,
    },
    {
      Header: intl.t('edge.connections.form.fields.lastConnectedTime'),
      accessor: 'lastConnectedTime',
      width: 220,
      Cell: ({ cell }) => (<UpdatedInfo date={cell.value} />),
    },
    {
      Header: intl.t('edge.connections.form.fields.lastDisconnectedTime'),
      accessor: 'lastDisconnectedTime',
      width: 220,
      Cell: ({ cell }) => (<UpdatedInfo date={cell.value} />),
    },
  ], [ intl ])

  return (
    <>
      <StaticTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        selection={false}
        columns={columns}
        data={items}
        {...props}
      />

      <Pagination {...props} />
    </>
  )
}
