import { Controller, useFormContext } from 'react-hook-form'
import { FormFieldInline } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import ProjectList from '@/hoc/ProjectList'
import Select from '@/components/Form/Select'

export default function FieldsProjects ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <FormFieldInline
      errors={get(errors, scope)}
    >
      <Controller
        name={scope}
        render={({ field, fieldState }) => (
          <Select
            {...field}
            invalid={fieldState.invalid}
            ListSource={ProjectList}
            placeholder={intl.t('form.fields.projects')}
            multiselect
          />
        )}
      />
    </FormFieldInline>
  )
}
