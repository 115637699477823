import {
  useState, useEffect, useCallback,
} from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { withProps, withPagination, withSort } from '@wiz/components'
import { wizataApi } from '@/api'
import { consts } from '@wiz/utils'
import Component from '@/components/Plots/Table'
// import useAppContext from '../../hooks/useAppContext'

const SortByFields = {
  createdDate: 'createdDate',
  updatedDate: 'updatedDate',
  name: 'name',
}

const SortDirection = {
  asc: 'Ascending',
  desc: 'Descending',
}

const enhanceProps = withProps(({
  filters,
}) => {
  // const { contextFilters } = useAppContext()
  const {
    data: plots, isLoading, refetch, isRefetching, isFetching,
  } = useQuery({
    queryKey: [ 'plotsList', filters?.page ],
    queryFn: () => {
      const limit = filters.pageSize + 1
      const offset = (filters.page - 1) * filters.pageSize

      const nextFilters = [
        {
          type: consts.FilterType.Text,
          operationType: consts.FilterOperationType.Contains,
          value: filters.search || '',
          propertyName: 'name',
        },
      ]

      return wizataApi.entities.getList('plots', {
        pagination: {
          take: limit - 1,
          skip: offset,
        },
        sortingList: [
          {
            direction: SortDirection[filters.sortDir],
            propertyName: SortByFields[filters.sortBy],
          },
        ],
        filters: nextFilters,
      })
    },
    retry: false,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (plots && !isRefetching) {
      refetch()
    }
  }, [
    filters.page,
    filters.pageSize,
    filters.sortDir,
    filters.sortBy,
    filters.search,
  ])

  return {
    total: plots?.totalCount || 0,
    items: plots?.items || [],
    data: plots?.items || [],
    loading: isLoading || isFetching,
  }
})

export default enhanceProps(
  withSort()(
    withPagination()(Component),
  ),
)
