import {
  useImperativeHandle, useCallback, useMemo, useState,
} from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import classnames from 'classnames'
import { consts, get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { FormField } from '@wiz/components'
import SelectFiles from '@/components/Form/SelectFiles'
import Select from '@/components/Form/Select'
import FileList from '@/hoc/FileList'
import s from './index.css'

export default function SectionFiles ({
  refFiles,
  meta,
  refCheck,
}) {
  const [ added, setAdded ] = useState()
  const intl = useIntl()

  const { watch, formState: { errors }, setValue } = useFormContext()
  const description = watch('description') || ''
  const projectIds = watch('projects') || []
  const [ uploadedFiles, files, rawFile ] = watch([ 'uploadedFiles', 'files', 'rawFile' ])

  useImperativeHandle(refCheck, () => ({
    checkFileExist () {
      return [ !!uploadedFiles?.length, files ]
    },
  }))

  const handleSuccess = useCallback((file) => {
    const next = uploadedFiles || []
    next.push(file.id)
    setValue('rawFile', file)
    setValue('uploadedFiles', next) // TODO: remove it if not needed
  }, [ setValue, uploadedFiles ])

  const handleAdd = useCallback((data) => {
    setAdded(data)
  }, [])

  return (
    <>
      <p className="mb-2">{intl.t('jobs.form.fileSelect')}</p>
      <FormField
        label={intl.t('form.fields.uploadFile')}
        description={intl.t('jobs.form.fields.uploadFileDescr')}
        errors={get(errors, 'uploadedFiles')}
      >
        <Controller
          disabled
          name="uploadedFiles"
          render={({ field, fieldState }) => (
            <SelectFiles
              {...field}
              className="align-items-center"
              invalid={fieldState.invalid}
              ref={refFiles}
              onSuccess={handleSuccess}
              onAdd={handleAdd}
              disabled
              inline={false}
              meta={{
                fileType: consts.FileType.UserFile,
                description,
                projectIds,
                ...meta,
              }}
              restrictions={{
                maxNumberOfFiles: 1,
              }}
            >
              {added ? <span className={classnames('me-2', s.noWrap)}>{added.name}</span> : null}
              <button
                // ref={refTarget}
                type="button"
                className={classnames('form-control uploader-select-files', {
                  'is-invalid': fieldState.invalid,
                })}
                disabled={files}
              >
                {intl.t('form.actions.upload')}
              </button>
            </SelectFiles>
          )}
        />
      </FormField>
      <p className="my-2">OR</p>
      <FormField
        label={intl.t('form.fields.files')}
        description={intl.t('jobs.form.fields.filesDescr')}
        errors={get(errors, 'files')}
      >
        <Controller
          name="files"
          rules={{
            required: added ? false : intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              ListSource={FileList}
              placeholder={intl.t('form.fields.filesPlaceholder')}
              disabled={!!uploadedFiles?.length}
            />
          )}
        />
      </FormField>
    </>
  )
}
