import {
  useState, useEffect, useCallback,
} from 'react'
import { withProps, withPagination, withSort } from '@wiz/components'
import { wizataApi } from '@/api'
import { consts } from '@wiz/utils'
import Component from '@/components/Dataframes/Table'

const SortByFields = {
  createdDate: 'createdDate',
  updatedDate: 'updatedDate',
}

const SortDirection = {
  asc: 'Ascending',
  desc: 'Descending',
}

const enhanceProps = withProps(({
  filters,
}) => {
  const [ loading, setLoading ] = useState(true)
  const [ items, setItems ] = useState([])
  const [ total, setTotal ] = useState(0)
  const handleUpdate = useCallback(() => {
    setLoading(true)

    const limit = filters.pageSize + 1
    const offset = (filters.page - 1) * filters.pageSize

    wizataApi.entities.getList('dataframes', {
      pagination: {
        take: limit - 1,
        skip: offset,
      },
      sortingList: [
        {
          direction: SortDirection[filters.sortDir],
          propertyName: SortByFields[filters.sortBy],
        },
      ],
      // filters: [
      //   {
      //     type: consts.FilterType.Text,
      //     operationType: consts.FilterOperationType.Contains,
      //     value: filters.search || '',
      //     // start: 'string',
      //     // end: 'string',
      //     propertyName: 'name',
      //   },
      // ],
    })
      .then((data) => {
        const { items, totalCount } = data || {}
        setTotal(totalCount)
        const _items = items.slice(0, filters.pageSize)
        setItems(_items)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [ filters ])
  useEffect(() => {
    handleUpdate()
  }, [ handleUpdate ])

  return {
    total,
    items,
    loading,
  }
})

export default enhanceProps(
  withSort({ localData: false })(
    withPagination({ localData: false })(Component),
  ),
)
