import { type, array, nullable } from 'superstruct'
import { DataTypes } from '@wiz/utils'
import { Sensor } from '@wiz/store'

export default type({
  sensors: type({
    categoryId: Sensor.schema.schema.categoryId,
    unitId: Sensor.schema.schema.unitId,
    validity: Sensor.schema.schema.validity,
    // batch: Sensor.schema.schema.batch,
    // batchDataSource: Sensor.schema.schema.batchDataSource,
    latitude: Sensor.schema.schema.latitude,
    longitude: Sensor.schema.schema.longitude,
    icon: Sensor.schema.schema.icon,
    color: Sensor.schema.schema.color,
    twinId: Sensor.schema.schema.twinId,
    // labels: nullable(array(DataTypes.NullableUuid())),
  }),
})
