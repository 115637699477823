import { define } from 'superstruct'
import { intl } from '@/i18n'

const SlidingDuration = ({
  keyFrom = 'dateFrom',
} = {}) => define('SlidingDuration', (value, { branch }) => {
  const data = branch[branch.length - 2]
  let result = true
  if (value && data[keyFrom]) {
    result = intl.t('form.errors.fieldOptionalRequired')
  }
  return result
})

export default SlidingDuration
