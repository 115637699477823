import { withObservables } from '@wiz/components'
import observeStreamJobLogs from '@/utils/observeStreamJobLogs'

const enhanceData = withObservables([ 'filters', 'contextFilters' ], ({ filters, contextFilters }) => {
  const limit = filters.pageSize + 1
  const offset = (filters.page - 1) * filters.pageSize
  return {
    items: observeStreamJobLogs({
      dateFrom: contextFilters.dateFrom,
      dateTo: contextFilters.dateTo,
      userEmail: contextFilters.userEmail,
      streamJobId: contextFilters.streamJobId,
      logSource: contextFilters.logSource,
      limit,
      offset,
      withStepRequest: false,
      withDateDuration: false,
    }),
  }
})

export default enhanceData
