/* eslint-disable no-unused-expressions */
import classnames from 'classnames'

export default function Option ({
  children,
  className,
  dataId,
  style,
}) {
  return (
    <div
      className={classnames('list-group-item list-group-item-action', className)}
      style={style}
      data-id={dataId}
    >
      {children}
    </div>
  )
}
