import { FormSelect, withProps } from '@wiz/components'

const Languages = [
  {
    id: 'PYTHON',
    name: 'Python',
  },
  {
    id: 'SCALA',
    name: 'Scala',
  },
  {
    id: 'SQL',
    name: 'SQL',
  },
  {
    id: 'R',
    name: 'R',
  },
]

const enhanceData = withProps(() => ({
  options: Languages,
}))

const SelectNotebookLanguage = enhanceData(FormSelect)

SelectNotebookLanguage.displayName = 'SelectNotebookLanguage'

export default SelectNotebookLanguage
