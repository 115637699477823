import { Icon, OverflowCenter } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import ButtonCopy from '@/components/Form/ButtonCopy'

export default function Group ({ data, index, style }) {
  const intl = useIntl()
  const auth = useAuth()
  const {
    options,
    showDescription,
    onAttachSensors,
    onCreateCommand,
    onToggleByTwin,
    onToggleAll,
  } = data
  const item = options[index]

  return (
    <div
      style={style}
      className="d-flex align-items-start hover-toggle list-group-item bg-light"
    >
      <div className="flex-fill min-w-0">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center min-w-0">
            <OverflowCenter value={item.displayName} />
            {item.hardwareId ? (
              <ButtonCopy
                className="hover-inline-flex"
                value={item.hardwareId}
                title={intl.t('message.copy.hardwareId', { value: item.hardwareId })}
              />
            ) : null}
          </div>
        </div>
        {showDescription && item.description ? (
          <div className="d-flex">
            <span className="me-1 text-secondary text-truncate">{item.description}</span>
          </div>
        ) : null}
      </div>

      {onToggleAll ? (
        <button
          type="button"
          className="hover-opacity btn btn-flat-secondary align-self-center p-2 ms-1"
          title={intl.t('form.actions.toggleChecked')}
          onClick={() => onToggleAll(options.slice(1))}
        >
          <Icon name="fa--tasks" />
        </button>
      ) : null}

      {onCreateCommand && auth.checkAccessManage('SectionDeviceCommands') ? (
        <button
          type="button"
          className="hover-opacity btn btn-flat-secondary align-self-center p-2 ms-1"
          title={intl.t('form.actions.createCommand')}
          onClick={() => onCreateCommand({
            twinId: item.id,
          })}
        >
          <Icon name="fa--terminal" />
        </button>
      ) : null}

      {onAttachSensors ? (
        <button
          type="button"
          className="hover-opacity btn btn-flat-secondary align-self-center p-2 ms-1"
          title={intl.t('form.actions.attachDataPoint')}
          onClick={() => onAttachSensors(item, index)}
        >
          <Icon name="fa--plus" />
        </button>
      ) : null}
    </div>
  )
}
