import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'
import CellStreamJob from './CellStreamJob'
import CellLogSource from './CellLogSource'
import CellUserEmail from './CellUserEmail'

export default function ViewModel ({
  title,
  streamJobLog,
  onClose,
  onFilter,
}) {
  const refDialog = useRef()
  const intl = useIntl()

  const handleFilter = (filter) => {
    refDialog.current.close()
    onFilter?.(filter)
  }

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('streamJobLogs.form.fields.timestamp'),
          value: () => (
            <UpdatedInfo
              date={streamJobLog.timestamp}
              withMillis
              withSecond
            />
          ),
        },
        {
          title: intl.t('streamJobLogs.form.fields.message'),
          value: streamJobLog.message,
        },
        {
          title: intl.t('streamJobLogs.form.fields.streamJob'),
          value: () => (
            <CellStreamJob
              streamJobId={streamJobLog.streamJobId}
              onFilter={handleFilter}
            />
          ),
        },
        {
          title: intl.t('streamJobLogs.form.fields.logSource'),
          value: () => (
            <CellLogSource
              logSource={streamJobLog.logSource}
              onFilter={handleFilter}
            />
          ),
        },
        {
          title: intl.t('streamJobLogs.form.fields.user'),
          value: () => (
            <CellUserEmail
              userEmail={streamJobLog.value}
              onFilter={handleFilter}
            />
          ),
        },
      ],
    },
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      onClose={onClose}
    />
  )
}
