/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import { Badge } from '@wiz/components'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'
import Observe from '@/containers/Observe'
import TwinList from '@/hoc/TwinList'
import CellTwins from './CellTwins'

const CellTwinsData = TwinList(CellTwins)

export default function ViewModel ({
  title,
  user,
  onClose,
  onRemove,
  onFilter,
  onEdit,
}) {
  const refDialog = useRef()
  const intl = useIntl()
  const auth = useAuth()

  const handleFilter = (filter) => {
    refDialog.current.close()
    onFilter?.(filter)
  }

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('form.fields.name'),
          value: user.name || '-',
        },
        {
          title: intl.t('form.fields.email'),
          value: user.email || '-',
        },
        {
          title: intl.t('form.fields.twins'),
          value: () => (
            <div className="d-flex flex-wrap">
              <Observe query={user.queryRelTwins}>
                {({ data }) => (
                  <CellTwinsData
                    value={data.map(item => item.twinId)}
                    selectedOnly
                    onFilter={handleFilter}
                  />
                )}
              </Observe>
            </div>
          ),
        },
        {
          title: intl.t('users.form.fields.roles'),
          value: () => (
            <div className="d-flex flex-wrap">
              <Observe query={user.queryAccessRoles}>
                {({ data }) => data.map(role => (
                  <Badge
                    key={role.id}
                    className="bg-secondary me-1 my-1"
                  >
                    {role.name || role.code}
                  </Badge>
                ))}
              </Observe>
            </div>
          ),
        },
      ],
    },

    {
      title: intl.t('users.form.fields.lastSeen'),
      value: () => (
        <UpdatedInfo
          date={user.lastSeen}
        />
      ),
    },
    {
      title: intl.t('form.fields.updated'),
      value: () => (
        <UpdatedInfo
          date={user.updatedAt}
          user={user.updatedUser}
        />
      ),
    },
    {
      title: intl.t('form.fields.created'),
      value: () => (
        <UpdatedInfo
          date={user.createdAt}
          user={user.createdUser}
        />
      ),
    },
  ]

  const actions = [
    ...(onRemove && auth.checkAccessRemove(user) ? ([{
      title: intl.t('form.actions.remove'),
      name: 'remove',
      testid: 'removeButton',
      onClick: () => onRemove([ user ]),
    }]) : []),

    ...(onEdit && auth.checkAccessUpdate(user) ? ([{
      title: intl.t('form.actions.edit'),
      name: 'edit',
      testid: 'editButton',
      onClick: () => onEdit(user.id),
    }]) : []),
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      actions={actions}
      onClose={onClose}
    />
  )
}
