import { of as of$ } from 'rxjs'
import { withObservables } from '@wiz/components'
import observeSensorValue from '@/utils/observeSensorValue'
import Component from '@/components/sensors/SensorValue'

const enhanceUnit = withObservables([
  'sensor',
  'showUnit',
], ({
  sensor,
  showUnit,
}) => ({
  unit: showUnit && sensor ?
    (sensor.unit?.observe?.() ?? of$(sensor.unit)) :
    of$(undefined),
}))

const enhanceValue = withObservables([
  'sensor',
  'disabled',
], ({
  sensor,
  disabled,
}) => ({
  data: disabled || !sensor ? of$(null) : observeSensorValue(sensor.id),
}))

export default enhanceUnit(
  enhanceValue(Component),
)
