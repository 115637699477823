import { FormInputSearch, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import {
  arrayOf, shape, string, func,
} from 'prop-types'
import NameDiagramBlockFlow from '@/components/Form/NameDiagramBlockFlow'

const ConnectionList = ({
  blocks,
  onRemove,
  error,
  onEdit,
  onNextStep,
  onClose,
  isPreStep,
}) => {
  const intl = useIntl()

  if (isPreStep) {
    return <PreStep onClose={onClose} onSkip={onNextStep} />
  }

  return (
    <>
      <div className="d-flex flex-wrap mt-2 px-3 m-1">
        <b>Connections list:</b>
      </div>
      <div className="d-flex flex-column flex-fill my-2 overflow-auto">
        {error ? (
          <div className="d-flex align-items-center justify-content-between px-3 text-danger">
            *
            {error}
          </div>
        ) : null}
        {blocks.map(item => (
          <div
            key={item.id}
            className="d-flex align-items-start hover-toggle list-group-item"
          >
            <div className="flex-fill min-w-0">
              <div className="d-flex align-items-center">
                <NameDiagramBlockFlow value={item} className="flex-fill" />
                <button
                  type="button"
                  className="btn btn-sm p-0 mx-2"
                  onClick={() => onEdit(item.blockId)}
                >
                  {intl.t('form.actions.edit')}
                </button>
                <button
                  type="button"
                  className="btn btn-sm p-0"
                  onClick={() => onRemove([ item.blockId ])}
                >
                  {intl.t('form.actions.remove')}
                </button>
              </div>
            </div>
          </div>
        ))}

        {!blocks.length ? (
          <div className="d-flex flex-wrap flex-fill px-3 m-1">
            <div className="d-flex align-items-center">
              You have no connections, start adding them using in-line connection mode
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}

const PreStep = ({ onSkip, onClose }) => {
  const intl = useIntl()

  return (
    <div className="d-flex flex-wrap flex-fill mt-2 px-3">
      <div className="d-flex flex-fill flex-column justify-content-center align-items-center text-center">
        <div className="d-flex">
          There is more than one digital twin unit selected.
        </div>
        <div className="d-flex">
          Do you want to apply time shift to the data through connections ?
        </div>
        <div className="my-2">
          <button
            type="button"
            className="btn btn-fill-secondary mx-1"
            onClick={onClose}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary mx-1"
            onClick={onSkip}
          >
            No
          </button>
        </div>
      </div>

    </div>
  )
}

export { ConnectionList }

ConnectionList.propTypes = {
  blocks: arrayOf(shape({})).isRequired,
  onRemove: func.isRequired,
  error: string,
  onEdit: func.isRequired,
}
ConnectionList.defaultProps = {
  error: '',
}
