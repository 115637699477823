import cx from 'classnames'
import {
  Form, FormControl,
} from '@wiz/components'
import {
  string, shape, func,
} from 'prop-types'
import { useIntl } from '@wiz/intl'

const ChooseSolution = ({
  onChange, meta, getError,
}) => {
  const error = getError('name')
  const intl = useIntl()

  return (
    <div className="d-flex flex-wrap flex-column flex-fill mt-2 px-3 m-1">
      <Form>
        <div>
          <div className="flex-fill my-2">Name:</div>
          <FormControl
            type="text"
            name="value"
            scale={8}
            onChange={name => onChange(prev => ({ ...prev, name }))}
            defaultValue={meta.name}
            className={cx({ 'is-invalid': error?.name })}
            disabled={meta.experimentId}
          />
          {error?.name ? <div className="invalid-feedback">{intl.t(error?.name)}</div> : null}
        </div>
      </Form>
    </div>
  )
}

export { ChooseSolution }

ChooseSolution.propTypes = {
  onChange: func.isRequired,
  meta: shape({ name: string }).isRequired,
  getError: func.isRequired,
}

ChooseSolution.defaultProps = {
}
