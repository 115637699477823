import { useCallback, memo, useMemo } from 'react'
import { dbProvider, Widget } from '@wiz/store'
import { withProps } from '@wiz/components'
import enhanceWidget from './enhanceWidget'
import enhanceConfig from './enhanceConfig'
import FormWidget from './FormWidget'

const enhanceProps = withProps(({ widget, config }) => {
  let chartConfigExtended
  if (config.dataFilter?.isRounded === null || config.dataFilter?.isTimestampAtEndOfPeriod === null) {
    chartConfigExtended = {
      isRounded: !!config.dataFilter.isRounded,
      isTimestampAtEndOfPeriod: !config.dataFilter.isTimestampAtEndOfPeriod,
    }
  }
  const defaultValues = useMemo(() => ({
    widget: Widget.toJSON(widget),
    config: {
      ...config,
      dataFilter: {
        ...config.dataFilter,
        ...chartConfigExtended,
      },
    },
  }), [ widget, config, chartConfigExtended ])

  const onSubmit = useCallback(async (data) => {
    if (widget) {
      const context = dbProvider.createBatchContext()
      await widget.prepareUpdateData(context, data.widget)
      await widget.prepareUpdateConfig(context, data.config)
      await dbProvider.batch(context)
    }
  }, [ widget ])

  return {
    defaultValues,
    onSubmit,
  }
})

export default memo(
  enhanceWidget(
    enhanceConfig(
      enhanceProps(FormWidget),
    ),
  ),
)
