import { useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useFormState } from 'react-hook-form'
import { Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { isEmpty } from '@wiz/utils'

export default function FormDialogActions ({
  defaultReady,
  size,
  disabled,
  onClose,
  onSubmit,
  onReset,
  isFirstStep,
  onPrev,
  submitText = 'form.actions.save',
  noCancelChanges,
}) {
  const refDefaultReady = useRef(defaultReady)
  const intl = useIntl()
  const { dirtyFields, isSubmitting, errors } = useFormState()
  const isDirty = !isEmpty(dirtyFields)

  useLayoutEffect(() => {
    refDefaultReady.current = false
  }, [ isDirty ])

  return (
    <footer className="d-flex flex-wrap justify-content-end m-2 p-1">
      <button
        name="close"
        type="button"
        data-testid="closeButton"
        className={classnames('btn btn-fill-secondary-alt m-1', {
          'flex-fill': size === 'sm',
        })}
        onClick={onClose}
      >
        {intl.t('form.actions.close')}
      </button>

      {onPrev ? (
        <button
          name="prev"
          type="button"
          data-testid="prevButton"
          className={classnames('btn btn-fill-secondary-alt m-1', {
            'flex-fill': size === 'sm',
          })}
          disabled={isFirstStep}
          onClick={onPrev}
        >
          <span className="d-none d-sm-inline">{intl.t('form.actions.previous')}</span>
          <span className="d-sm-none">{intl.t('form.actions.previous')}</span>
        </button>
      ) : (
        <button
          name="cancel"
          type="button"
          data-testid="cancelButton"
          className={classnames('btn btn-fill-secondary-alt m-1', {
            'flex-fill': size === 'sm',
            'd-none': noCancelChanges,
          })}
          disabled={!isDirty || isSubmitting || disabled}
          onClick={onReset}
        >
          <span className="d-none d-sm-inline">{intl.t('form.actions.cancelChanges')}</span>
          <span className="d-sm-none">{intl.t('form.actions.cancel')}</span>
        </button>
      )}

      <button
        name="save"
        type="button"
        data-testid="saveButton"
        className={classnames('btn btn-fill-secondary-alt m-1', {
          'flex-fill': size === 'sm',
        })}
        disabled={
          disabled ||
          (
            !refDefaultReady.current &&
            (
              !isDirty ||
              isSubmitting ||
              !isEmpty(errors)
            )
          )
        }
        onClick={onSubmit}
      >
        {isSubmitting ? <Icon name="fa--spinner" className="me-1 ms-n1" spin /> : null}
        {intl.t(submitText)}
      </button>
    </footer>
  )
}

FormDialogActions.propTypes = {
  size: PropTypes.oneOf([ 'sm', 'md', 'lg' ]),
  defaultReady: PropTypes.bool,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
}

FormDialogActions.defaultProps = {
  size: undefined,
  defaultReady: false,
  disabled: false,
  onClose: undefined,
  onSubmit: undefined,
  onReset: undefined,
}
