import { useCallback, useState } from 'react'
import union from 'lodash/union'
import difference from 'lodash/difference'
import { Q, dbProvider } from '@wiz/store'
import { toArrayValue } from '@wiz/utils'
import { withProps } from '@wiz/components'

export default withProps(({
  businessType,
}) => {
  const [ sensorIds, setSensorIds ] = useState(undefined)

  const onAttachSensors = useCallback(async (blockSettings, ids) => {
    const sensors = await dbProvider.database.collections.get('sensors')
      .query(Q.where('id', Q.oneOf(ids)))
      .fetch()

    const context = dbProvider.createBatchContext()
    for (const sensor of sensors) {
      await sensor.prepareLinkTo(context, blockSettings)
    }
    await dbProvider.batch(context)
  }, [])

  const onToggleByTwin = useCallback(async (twin) => {
    let query = dbProvider.database.collections.get('sensors')
      .query(Q.where('twin_id', twin.id))

    if (businessType) {
      query = query.extend(Q.where('business_type', businessType))
    }

    const sensors = await query.fetch()
    const toggleIds = sensors.map(item => item.id)
    const diff = difference(toggleIds, sensorIds)
    if (diff.length) {
      setSensorIds(union(sensorIds, toggleIds))
    } else {
      setSensorIds(difference(sensorIds, toggleIds))
    }
  }, [ sensorIds, businessType ])

  const onChangeSelection = useCallback((id) => {
    const arrayIds = toArrayValue(sensorIds)
    if (arrayIds.includes(id)) {
      setSensorIds(arrayIds.filter(item => item !== id))
    } else {
      setSensorIds(arrayIds.concat(id))
    }
  }, [ sensorIds ])

  const onBeforeAction = useCallback((action) => {
    if (action === 'unlink') {
      setSensorIds([])
    }
  }, [])

  return {
    sensorIds,
    onAttachSensors,
    onToggleByTwin,
    onChangeSelection,
    onBeforeAction,
  }
})
