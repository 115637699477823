import { useAuth } from '@/auth'
import Link from '@/components/Link'

export default function CellExperiment ({ options: [ target ] }) {
  const auth = useAuth()

  if (!target) {
    return '-'
  }

  if (!auth.checkAccessRead(target)) {
    return null
  }

  if (!auth.checkAccessRead('SectionExperiments')) {
    return (
      <div className="min-w-0 text-truncate" title={target.name}>
        {target.name}
      </div>
    )
  }

  return (
    <div className="min-w-0 text-truncate">
      <Link
        title={target.name}
        name="experiments-list"
        query={{ search: target?.id }}
      >
        {target.name}
      </Link>
    </div>
  )
}
