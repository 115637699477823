/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'

export default function ViewModel ({
  title,
  project,
  onClose,
  onRemove,
  onEdit,
}) {
  const refDialog = useRef()
  const intl = useIntl()
  const auth = useAuth()

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('projects.form.fields.name'),
          value: project.name,
        },
        {
          title: intl.t('form.fields.description'),
          value: project.description || '-',
        },
        {
          title: intl.t('projects.form.fields.type'),
          value: project.type,
        },
        {
          title: intl.t('projects.form.fields.status'),
          value: project.status,
        },
      ],
    },

    {
      title: intl.t('form.fields.updated'),
      value: () => <UpdatedInfo date={project.updatedAt} user={project.updatedUser} />,
    },
    {
      title: intl.t('form.fields.created'),
      value: () => <UpdatedInfo date={project.createdAt} user={project.createdUser} />,
    },
  ]

  const actions = [
    ...(onRemove && auth.checkAccessRemove(project) ? ([{
      title: intl.t('form.actions.remove'),
      name: 'remove',
      testid: 'removeButton',
      onClick: () => onRemove([ project ]),
    }]) : []),

    ...(onEdit && auth.checkAccessUpdate(project) ? ([{
      title: intl.t('form.actions.edit'),
      name: 'edit',
      testid: 'editButton',
      onClick: () => onEdit(project.id),
    }]) : []),
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      actions={actions}
      onClose={onClose}
    />
  )
}
