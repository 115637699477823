import { useMemo } from 'react'
import FormDialog from '@/components/Form/FormDialog'
import Section from './Section'
import Schema from './Schema'
import resolver from './resolver'

export default function CreateWidgets ({
  params,
  sensorIds,
  dialog,
  onClose,
  onSubmit,
}) {
  const defaultValues = useMemo(() => ({
    dashboards: [],
    newDashboards: [],
    sensors: sensorIds,
    disabledWidgets: {},
  }), [ sensorIds ])

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="createWidgetsForm"
      dialog={dialog}
      resolver={resolver}
      schema={Schema}
    >
      <Section
        params={params}
        defaultValues={defaultValues}
        sensorIds={sensorIds}
      />
    </FormDialog>
  )
}
