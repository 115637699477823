import { useState, useCallback, useEffect } from 'react'
import { DateTime } from 'luxon'
import { consts } from '@wiz/utils'
import { Pagination, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import TreeList from '@/components/Form/Tree/List'
import Link from '@/components/Link'
import DateRelative from '@/components/DateRelative'
import Content from './Content'

export default function Logs ({
  streamJob,
  loading,
  items,
  total,
  filters,
  forceUpdate,
  onClickPage,
}) {
  const intl = useIntl()
  const auth = useAuth()
  const [ isMs, setIsMs ] = useState(false)
  const [ loadingTime, setLoadingTime ] = useState(null)

  const handleAction = useCallback((action) => {
    if (action === 'dateFormatMs') {
      return isMs
    }
    return undefined
  }, [ isMs ])

  useEffect(() => {
    if (loading) {
      setLoadingTime(Date.now())
    }
  }, [ loading ])

  return (
    <>
      <div className="d-flex align-items-center my-2 mx-3">
        {auth.checkAccessRead('SectionStreamJobLogs') ? (
          <Link
            name="stream-job-logs-list"
            query={{ streamJobId: streamJob.blockId }}
            className="d-inline btn btn-sm btn-fill-secondary me-2 text-truncate"
            title={intl.t('streamJobLogs.title')}
          >
            {intl.t('form.actions.exploreLogs')}
          </Link>
        ) : null}

        <button
          className="d-inline btn btn-sm btn-fill-secondary me-2 text-truncate"
          type="button"
          name="toggleMs"
          onClick={() => setIsMs(!isMs)}
        >
          {isMs ? intl.t('form.fields.s') : intl.t('form.fields.ms')}
        </button>

        <button
          className="btn btn-sm btn-fill-secondary btn-text me-2"
          type="button"
          name="refresh"
          onClick={forceUpdate}
        >
          <Icon name="fa--sync" />

          {loadingTime ? (
            <DateRelative
              className="ms-1"
              value={loadingTime}
            />
          ) : null}
        </button>
      </div>

      <div className="d-flex flex-fill min-h-0 position-relative">
        <TreeList
          className="flex-fill min-h-0"
          placeholder={intl.t('errors.noDataDisplay')}
          options={items}
          itemSize={consts.ListItemHeightMd}
          Content={Content}
          onAction={handleAction}
        />

        {loading ? (
          <div className="position-absolute-fill position-center-fill bg-light opacity-50">
            <Icon name="fa--spinner" size="2X" spin />
          </div>
        ) : null}
      </div>

      <Pagination
        className="p-2 justify-content-center"
        pageNavigation={false}
        pageSizeNavigation={false}
        showTotal={false}
        currentPage={filters.page}
        pageSize={filters.pageSize}
        total={total}
        alwaysFastFirst
        relative
        onClickPage={onClickPage}
      />
    </>
  )
}
