import { useCallback, useMemo } from 'react'
import { withProps } from '@wiz/components'
import { Twin } from '@wiz/store'
import { wizataApi } from '@/api'
import Component from '@/components/RightBarCommands'

const enhanceData = withProps(({ blockSettings }) => {
  const twinIds = useMemo(() => (
    Object.values(blockSettings).reduce((out, item) => {
      if (item instanceof Twin) {
        out.push(item.id)
      } else if (item?.twinId) {
        out.push(item.twinId)
      }
      return out
    }, [])
  ), [ blockSettings ])

  const onRemoveEvent = useCallback(async (event) => {
    await window.wizConfirm({ message: 't/units.confirmDelete' })
    await wizataApi.events.remove(event)
  }, [])

  return {
    twinIds,
    onRemoveEvent,
  }
})

export default enhanceData(Component)
