import { useEffect, useMemo } from 'react'
import { Twin as Twin$ } from '@wiz/store'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import Section from './Section'
import SectionDashboards from './SectionDashboards'
import SectionExplorers from './SectionExplorers'
import SectionStreamJobs from './SectionStreamJobs'
import SectionTwinGraphs from './SectionTwinGraphs'
import Schema from './Schema'
import resolver from './resolver'

export default function Twin ({
  dashboards,
  dialog,
  explorers,
  id,
  createdTwinId,
  onClose,
  onSubmit,
  onSuccess,
  streamJobs,
  twinGraphs,
  twin,
}) {
  const defaultValues = useMemo(() => ({
    twin: {
      ...Twin$.toJSON(twin),
    },
    dashboards,
    explorers,
    streamJobs,
    twinGraphs,
  }), [
    twin?.updatedAt ?? twin,
    dashboards,
    explorers,
    streamJobs,
    twinGraphs,
  ])

  const { handleTwinChange } = useGlobalExecute()

  useEffect(() => {
    if (createdTwinId) {
      handleTwinChange(createdTwinId)
    }
  }, [ createdTwinId ])

  if (id && !twin) {
    return <Removed />
  }

  return (
    <FormDialog
      dataTestid="twinForm"
      defaultValues={defaultValues}
      dialog={dialog}
      onClose={onClose}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      resolver={resolver}
      schema={Schema}
    >
      <Section scope="twin" />
      <SectionTwinGraphs scope="twinGraphs" />
      <SectionDashboards scope="dashboards" />
      <SectionExplorers scope="explorers" />
      <SectionStreamJobs scope="streamJobs" />
    </FormDialog>
  )
}
