import {
  forwardRef,
  useState,
  useMemo,
  useRef,
  useImperativeHandle,
  useContext,
  useEffect,
} from 'react'
import { useRouter, useLocation } from '@/router'
import { useQuery } from '@tanstack/react-query'
import cx from 'classnames'
import {
  Icon,
  CustomScrollbars,
} from '@wiz/components'
import { wizataApi } from '@/api'
import { AppContext } from '@/pages/context'
import { useIntl } from '@wiz/intl'
import FormExecution from '@/components/Forms/Execution'
import { CorePages as PAGES, PagesMap } from '@/utils/consts'
import RightBar from './RightBar'
import Combined from './Combined'

const Results = forwardRef(({
  data,
  experiment,
}, ref) => {
  const [ isDebug, setDebug ] = useState(false)
  const [ isEdit, setEdit ] = useState(false)
  const [ isRightbarOpen, setRightbarOpen ] = useState(false)

  const {
    experiments: {
      isExecuting, execution, isExecutionLoading, refetchExecution, onSelectExecution,
    },
  } = useContext(AppContext)

  const refRightBar = useRef()

  const intl = useIntl()
  const loc = useLocation()
  const [ ,, corePage, currentPage ] = loc.pathname.split('/')
  const router = useRouter()

  const { data: plotsList, isLoading: plotsLoading, isFetching: plotsFetching } = useQuery({
    queryKey: [ 'plots', execution?.id ],
    queryFn: () => (execution ? wizataApi.plots.getListByExecutionId(execution.id) : undefined),
    enabled: execution?.status === 'completed',
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const loadExecutionForWizard = () => {
    wizataApi.executions.getListByExperiment(experiment?.id)
      .then((sorted) => {
        onSelectExecution(sorted?.[0]?.id)
        refetchExecution()
      })
  }

  const customFunction = !execution?.isAnomalyDetection

  const handlePlay = () => {
    if (execution) {
      setRightbarOpen(true)
    }
  }

  const handleCreateExecution = () => {
    // setExecution(null)
    setRightbarOpen(false)
  }

  useImperativeHandle(ref, () => ({
    onPlay () {
      handlePlay()
    },
  }))

  useEffect(() => {
    if (currentPage === PagesMap.Twins && experiment?.id) {
      loadExecutionForWizard()
    }
  }, [ experiment ])

  const configured = (
    data.dataSources.length > 0 &&
    data.dataViews.length > 0
  ) || customFunction

  const hasCorrectExecution = experiment?.id === execution?.experimentId

  const isError = useMemo(() => {
    if (execution?.warnings) {
      return true
    }
    return false
  }, [ execution ])

  const isPlotsLoading = plotsLoading && plotsFetching

  const isFullSuccess = !isError &&
  !isExecuting &&
  !plotsLoading &&
  !execution?.warnings &&
  !plotsList?.length &&
  execution?.status === 'completed' &&
  hasCorrectExecution

  const isLoading = !hasCorrectExecution || isExecutionLoading || isPlotsLoading || isExecuting ||
  [ 'queued', 'started', 'received' ].includes(execution?.status)

  const isFullError = !isLoading && (!!execution?.warnings || execution?.status === 'failed')

  const isNoData = !execution && !plotsList && !isLoading && !isError

  const hasData = configured &&
  !isLoading &&
  !isExecuting &&
  !isError &&
  execution?.status === 'completed' &&
  plotsList?.length &&
  hasCorrectExecution

  return (
    <>
      <div className="d-flex flex-column flex-fill min-h-0" id="experiment-container">
        {isFullSuccess ?
          (
            <div className="position-center-fill d-flex flex-column">
              <span>Everything seems alright, we didn&apos;t detect any anomalies.</span>
            </div>
          ) : null}

        {isFullError ? (
          <div className="position-center-fill d-flex flex-column text-center">
            <span className="text-danger">
              Execution&nbsp;
              {execution?.status}
            </span>
            {execution?.warnings ? (
              <span>
                :
                {execution.warnings}
              </span>
            ) : null}
          </div>
        ) : null}

        {isLoading ? (
          <div className="position-center-fill d-flex flex-column">
            <Icon name="fa--spinner" size="2X" spin />
            <span>Experiment is proccessing right now</span>
          </div>
        ) : null}

        {isNoData ? (
          <div className="position-center-fill d-flex flex-column">
            {intl.t('experiments.topbar.noData')}
          </div>
        ) : null}

        {hasData ? (
          <CustomScrollbars
            className={cx('flex-fill', { 'd-none': isDebug })}
            classNameContent="d-flex flex-column"
            horizontal={false}
            shadow={false}
            autoHide
          >
            <Combined
              plotsList={plotsList}
              data={data}
            />
          </CustomScrollbars>
        ) : null }

        <div className="d-flex mx-1 my-2">
          <button
            type="button"
            className="btn btn-sm btn-fill-secondary text-nowrap"
            onClick={() => setDebug(!isDebug)}
          >
            <Icon name="fa--bug" />
          </button>

          {execution ? (
            <button
              type="button"
              className="btn btn-sm btn-fill-secondary text-nowrap ms-1"
              onClick={() => setEdit(true)}
            >
              <Icon name="fa--eye" />
            </button>
          ) : null}

        </div>

        {isDebug ? (
          <Debug {...{ execution }} />
        ) : null}
      </div>

      {isRightbarOpen ? (
        <RightBar
          ref={refRightBar}
          container="#experiment-container"
          blockIds={[]}
          isOpen={isRightbarOpen}
          onClose={() => setRightbarOpen(false)}
          execution={execution} // in the middle of Sync and REST
          experiment={experiment}
          onCreate={handleCreateExecution}
        />
      ) : null}

      {isEdit ? (
        <FormExecution
          id={execution.id}
          onClose={() => setEdit(null)}
          dialog={{
            title: intl.t('executions.titleView'),
            dataTestid: 'executionViewDialog',
          }}
        />
      ) : null}
    </>
  )
})

const Debug = ({ execution }) => {
  const request = useMemo(() => {
    if (execution) {
      return {
        ...execution,
        properties: execution?.properties ? JSON.parse(execution.properties) : null,
      }
    }
    return 'no execution found'
  }, [ execution ])

  return (
    <div className="overflow-auto">
      <hr />
      request:
      <pre>
        {JSON.stringify(request || 'no request found', undefined, 2)}
      </pre>
    </div>
  )
}

export { Results, Debug }
