import { createRef, Component } from 'react'
import PropTypes from 'prop-types'
import { echarts } from '@wiz/utils'

export default class DynamicBulletPlot extends Component {
  static propTypes = {
    config: PropTypes.object.isRequired,
    dimension: PropTypes.string,
    theme: PropTypes.object,
    realtimeData: PropTypes.array,
    statData: PropTypes.array,
  }

  static defaultProps = {
    dimension: undefined,
    theme: undefined,
    realtimeData: [],
    statData: [],
  }

  refTarget = createRef()

  componentDidMount () {
    this.resetPlot()
  }

  componentWillUnmount () {
    this.$plot?.dispose()
  }

  componentDidUpdate (prevProps) {
    const {
      config,
      dimension,
      realtimeData,
      statData,
      theme,
    } = this.props

    if (theme !== prevProps.theme) {
      this.resetPlot()
    } else if (this.$plot) {
      if (
        config !== prevProps.config ||
        dimension !== prevProps.dimension
      ) {
        const properties = this.getProperties()
        this.$plot.setOption(properties, {
          notMerge: true,
          lazyUpdate: false,
          silent: true,
        })
        this.$plot.resize()
      } else if (
        realtimeData !== prevProps.realtimeData ||
        statData !== prevProps.statData
      ) {
        const properties = this.getDatasetProperties()
        this.$plot.setOption(properties, {
          notMerge: false,
          lazyUpdate: false,
          silent: true,
        })
      }
    }
  }

  resetPlot () {
    const { theme } = this.props
    const properties = this.getProperties()

    this.$plot?.dispose()
    this.$plot = echarts.init(this.refTarget.current, theme)
    this.$plot.setOption(properties, {
      notMerge: true,
      lazyUpdate: true,
      silent: true,
    })
  }

  getDatasetProperties () {
    const {
      config,
      realtimeData,
      statData,
      config: { bulletPlotSources: sources },
    } = this.props

    const dataset = this.$plot?.getOption().dataset ?? []

    const properties = {
      dataset: sources
        .filter(item => !item.disabled)
        .map((item) => {
          const [ prevMin, prevMax ] = dataset.find(i => i.id === item.id)?.source ?? [{}, {}]
          const realtime = realtimeData.find(i => (i && i.sensorId === item.sensorId))
          const stat = statData.find(i => (i.sensorId === item.sensorId))
          const realtimeValue = realtime?.value ?? (prevMin.realtime ?? null)
          const hasRealtime = Number.isFinite(realtimeValue)

          const dataMin = stat?.dataMin ?? prevMin.data ?? null
          const percentileMin = (
            stat?.percentileMin ??
            prevMin.percentile ??
            (hasRealtime ? realtimeValue - 1 : null)
          )
          const validMin = item.limitMin ?? config.limitMin ?? prevMin.valid ?? null
          const totalMin = Math.min(dataMin, percentileMin, validMin, realtime)

          const dataMax = stat?.dataMax ?? prevMax.data ?? null
          const percentileMax = (
            stat?.percentileMax ??
            prevMax.percentile ??
            (hasRealtime ? realtimeValue + 1 : null)
          )
          const validMax = item.limitMax ?? config.limitMax ?? prevMax.valid ?? null
          const totalMax = Math.min(dataMax, percentileMax, validMax, realtime)

          return {
            id: item.id,
            source: [
              {
                sensor: item.sensor.name,
                total: totalMin,
                realtime: realtimeValue,
                data: dataMin,
                percentile: percentileMin,
                valid: validMin,
              },
              {
                sensor: item.sensor.name,
                total: totalMax,
                realtime: realtimeValue,
                data: dataMax,
                percentile: percentileMax,
                valid: validMax,
              },
            ],
            sourceHeader: false,
          }
        }),
    }

    // console.log(properties)

    return properties
  }

  getProperties () {
    const dataset = this.getDatasetProperties()
    const {
      config: {
        bulletPlotSources,
        colorPercentile,
        colorGreen,
        colorCurrent,
        colorRealtime,
      },
    } = this.props
    const sources = bulletPlotSources.filter(item => !item.disabled)
    const len = sources.length
    const { height } = this.refTarget.current.getBoundingClientRect()
    const XAxisLabelsHeight = 12
    const OffsetTop = 15
    const FontSize = 12
    const Colors = {
      Percentile: colorPercentile,
      Green: colorGreen,
      Current: colorCurrent,
      Realtime: colorRealtime,
    }
    const gridHeight = Math.floor(height / len) - XAxisLabelsHeight - 1
    const isFreeSpace = gridHeight > 25
    const precise = data => (
      data > -1 && data < 1 ?
        Number.parseFloat(data).toPrecision(3).replace(/\.?[0]+$/, '') :
        Number.parseFloat(data).toFixed(3).replace(/\.?[0]+$/, '')
    )

    const maxSensorName = sources.reduce((out, item) => (
      Math.max(out, item.sensor.name.length * 7)
    ), 80)

    const properties = {
      ...dataset,

      tooltip: {
        confine: true,
        enterable: false,
        transitionDuration: 1,
        showContent: false,
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          animation: false,
        },
      },

      // axisPointer: {
      //   link: { xAxisIndex: 'all' },
      // },

      grid: sources.map((item, idx) => ({
        top: gridHeight * idx + XAxisLabelsHeight * idx + OffsetTop,
        height: gridHeight,
        right: 10,
        left: maxSensorName,
      })),

      yAxis: sources.map((item, idx) => ({
        gridIndex: idx,
        type: 'category',
        splitLine: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        axisPointer: { label: { show: false }, lineStyle: { width: 0 } },
      })),

      xAxis: sources.map((item, idx) => ({
        gridIndex: idx,
        type: 'value',
        min: 'dataMin',
        max: 'dataMax',
        // splitNumber: 5,
        // minInterval: 0,
        splitLine: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: { show: false },
      })),

      series: sources.map((item, idx) => ({
        xAxisIndex: idx,
        yAxisIndex: idx,
        datasetIndex: idx,
        encode: {
          x: [
            'total',
            'data',
            'percentile',
            'valid',
            'realtime',
          ],
          y: 'sensor',
        },
        type: 'line',
        animation: false,
        hoverAnimation: false,
        showSymbol: false,
        showAllSymbol: false,
        itemStyle: { opacity: 0 },
        lineStyle: { width: 0 },
        emphasis: { itemStyle: { opacity: 0 } },
        markLine: {
          silent: true,
          symbol: [ 'none', 'none' ],
          label: { formatter: ({ value }) => precise(value) },
          data: [
            // Percentile
            [
              {
                type: 'min',
                valueDim: 'percentile',
                lineStyle: { type: 'solid', width: gridHeight, color: Colors.Percentile },
                label: {
                  position: 'insideStartTop',
                  distance: [ 5, isFreeSpace ? -(gridHeight / 2) + 3 : -6 ],
                },
              },
              {
                type: 'max',
                valueDim: 'percentile',
              },
            ],
            [
              {
                type: 'max',
                valueDim: 'percentile',
                lineStyle: { type: 'solid', width: gridHeight, color: 'transparent' },
                label: {
                  position: 'insideStartTop',
                  distance: [ 5, isFreeSpace ? gridHeight / 2 - FontSize - 2 : -6 ],
                },
              },
              {
                type: 'min',
                valueDim: 'percentile',
              },
            ],
            // Green Range
            [
              {
                type: 'min',
                valueDim: 'valid',
                lineStyle: { type: 'solid', width: gridHeight, color: Colors.Green },
                label: {
                  position: 'insideStartTop',
                  distance: [ 5, isFreeSpace ? 1 : -6 ],
                },
              },
              {
                type: 'max',
                valueDim: 'valid',
              },
            ],
            [
              {
                type: 'max',
                valueDim: 'valid',
                lineStyle: { type: 'solid', width: gridHeight, color: 'transparent' },
                label: {
                  position: 'insideStartTop',
                  distance: [ 5, isFreeSpace ? 1 : -6 ],
                },
              },
              {
                type: 'min',
                valueDim: 'valid',
              },
            ],
            // Current Values
            [
              {
                type: 'min',
                valueDim: 'data',
                lineStyle: { type: 'solid', width: 3, color: Colors.Current },
                label: {
                  show: isFreeSpace,
                  position: 'insideStartBottom',
                  distance: 3,
                },
              },
              {
                type: 'max',
                valueDim: 'data',
              },
            ],
            [
              {
                type: 'max',
                valueDim: 'data',
                lineStyle: { type: 'solid', width: 3, color: 'transparent' },
                label: {
                  show: isFreeSpace,
                  position: 'insideStartBottom',
                  distance: 3,
                },
              },
              {
                type: 'min',
                valueDim: 'data',
              },
            ],
          ],
        },
        // Realtime Value
        markPoint: {
          silent: true,
          symbol: 'diamond',
          symbolOffset: [ 0, '-50%' ],
          symbolSize: Math.max(Math.min(gridHeight / 2.5, 20), 5),
          label: {
            position: 'top',
            distance: 1,
            color: '#ffffff',
            backgroundColor: 'rgba(0,0,0,0.7)',
            borderRadius: 3,
            padding: [ 2, 4 ],
            formatter: ({ value }) => precise(value),
          },
          itemStyle: { color: Colors.Realtime },
          data: [{
            type: 'max',
            valueDim: 'realtime',
          }],
        },
      })),
    }

    return properties
  }

  render () {
    return (
      <div className="flex-fill position-relative">
        <div
          ref={this.refTarget}
          className="position-absolute-fill"
        />
      </div>
    )
  }
}
