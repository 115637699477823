import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Table as StaticTable, Pagination } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { AccessRole } from '@wiz/store'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import Detail from './Detail'

const CellUpdated = ({ cell, row }) => (
  <UpdatedInfo
    date={cell.value}
    user={row.original.updatedUser}
  />
)

CellUpdated.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.number,
  }).isRequired,
  row: PropTypes.shape({
    original: PropTypes.instanceOf(AccessRole).isRequired,
  }).isRequired,
}

const CellCreated = ({ cell, row }) => (
  <UpdatedInfo
    date={cell.value}
    user={row.original.createdUser}
  />
)

CellCreated.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.number,
  }).isRequired,
  row: PropTypes.shape({
    original: PropTypes.instanceOf(AccessRole).isRequired,
  }).isRequired,
}

export default function Table ({
  items,
  onRemove,
  onEdit,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedAt',
      disableResizing: true,
      minWidth: 170,
      width: 170,
      maxWidth: 220,
      Cell: CellUpdated,
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdAt',
      disableResizing: true,
      minWidth: 170,
      width: 170,
      maxWidth: 220,
      Cell: CellCreated,
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell, row }) => (
        <>
          {auth.checkAccessUpdate(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0 me-2"
              onClick={() => onEdit?.(cell.value)}
            >
              {intl.t('form.actions.edit')}
            </button>
          ) : null}
          {auth.checkAccessRemove(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0"
              onClick={() => onRemove?.([ row.original ])}
            >
              {intl.t('form.actions.remove')}
            </button>
          ) : null}
        </>
      ),
    },
  ], [ onEdit, onRemove, intl, auth ])

  return (
    <>
      <StaticTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={false}
        ExpandeComponent={Detail}
        expande
        {...props}
      />

      <Pagination {...props} />
    </>
  )
}

Table.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(AccessRole)),
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
}

Table.defaultProps = {
  items: [],
  onRemove: undefined,
  onEdit: undefined,
}
