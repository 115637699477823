import { useEffect, useMemo } from 'react'
import classnames from 'classnames'
import { VirtualTable, Pagination, Icon as OldIcon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import useAppContext from '@/hooks/useAppContext'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import EditButton from '../../shared/editButton'

export default function Table ({
  items,
  onRemove,
  onEdit,
  onDuplicate,
  filters,
  refetch,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const { onRowSelect } = useAppContext()
  const allowed = auth.checkAccessUpdate('Unit')

  const columns = useMemo(() => [
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      width: 30,
      maxWidth: 30,
      className: 'justify-content-center text-nowrap',
      Cell: ({ cell }) => (auth.checkAccessUpdate('Unit') ? (
        <EditButton cell={cell} onEdit={onEdit} />
      ) : null),
    },
    {
      Header: intl.t('form.fields.key'),
      accessor: 'key',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 200,
      Cell: ({ cell, row }) => (cell.value ? (
        <div className="min-w-0 text-truncate">
          <a
            className="text-truncate pointer"
            onClick={() => (allowed ? onEdit?.(row.original.id) : undefined)}
            aria-hidden
          >
            {cell.value}
          </a>
        </div>
      ) : '-'),
    },
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      minWidth: 300,
      Cell: ({ cell, row }) => (
        <a
          className={classnames('d-flex align-items-center min-w-0', {
            'link pointer': allowed,
          })}
          onClick={() => (allowed ? onEdit?.(row.original.id) : undefined)}
          aria-hidden
        >
          <div className="text-truncate">
            {cell.value}
            {row.original.description ? (
              <div className="font-italic text-muted text-truncate small">
                {row.original.description}
              </div>
            ) : null}
          </div>
        </a>
      ),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedDate',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={row.original.updatedDate}
          userId={row.original.updatedById}
          user={row.original.updatedUser}
        />
      ),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdDate',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={row.original.createdDate}
          userId={row.original.createdById}
          user={row.original.createdUser}
        />
      ),
    },
  ], [ intl, onEdit, onRemove, onDuplicate, auth ])

  useEffect(() => {
    if (items && !props.loading) {
      refetch()
    }
  }, [
    filters.search,
    filters.page,
    filters.pageSize,
    filters.sortDir,
    filters.sortBy,
  ])

  return (
    <>
      <VirtualTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={auth.checkAccessRemove('Label') || auth.checkAccessCreate('Label')}
        {...props}
        onSelect={onRowSelect}
      />

      <Pagination {...props} />
    </>
  )
}
