import { useCallback } from 'react'
import { dbProvider } from '@wiz/store'
import { withProps } from '@wiz/components'
import withTableActions from '@/containers/withTableActions'
import Component from '@/components/users/List'

const enhanceProps = withProps(() => {
  const onToggleActive = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    await data.prepareToggleActive(context)
    await dbProvider.batch(context)
  }, [])

  return {
    onToggleActive,
  }
})

export default withTableActions({
  initFilters (query) {
    return {
      twin: query.twin,
    }
  },
})(
  enhanceProps(Component),
)
