import { useState, useEffect, useRef } from 'react'
import { withProps } from '@wiz/components'

const enhanceProps = withProps(({
  items,
  filters,
}) => {
  const refData = useRef([])
  const [ loading, setLoading ] = useState(false)
  const offset = (filters.page - 1) * filters.pageSize

  useEffect(() => {
    setLoading(!items)
    if (items) {
      refData.current = items
    }
  }, [ items ])

  const nextItems = items || refData.current
  return {
    total: offset + nextItems.length,
    items: nextItems.slice(0, filters.pageSize),
    loading,
  }
})

export default enhanceProps
