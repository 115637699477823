import { useCallback, useMemo } from 'react'
import { DataView as DataView$ } from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import Section from './Section'

const DataView = ({
  id,
  dataView,
  dialog,
  onClose,
  onSubmit,
}) => {
  const defaultValues = useMemo(() => ({
    dataView: DataView$.toJSON(dataView),
  }), [ dataView?.updatedAt ?? dataView ])

  const handleSubmit = useCallback((data) => {
    const next = {
      ...data,
      dataView: {
        ...data.dataView,
        conditions: data.dataView?.conditions || [],
      },
    }

    onSubmit?.(next)
  }, [ onSubmit ])

  if (id && !dataView) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={handleSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="dataViewForm"
      dialog={dialog}
    >
      <Section scope="dataView" />
    </FormDialog>
  )
}

export default DataView
