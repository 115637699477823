import { useState } from 'react'
import { useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Unit from '@/hoc/Unit'
import FormUnit from '@/containers/Forms/Unit'
import Table from '@/containers/Unit/Table'
import TableMobile from '@/containers/Unit/TableMobile'
import ViewModel from './ViewModel'

const ViewModelData = Unit(ViewModel)

export default function List ({
  filters,
  selected,
  onFilter,
  onResetFilter,
  onRemove,
  ...props
}) {
  const intl = useIntl()
  const isMobile = useMobile()
  const [ viewId, setViewId ] = useState(null)
  const [ editId, setEditId ] = useState(null)

  const TableComponent = isMobile ? TableMobile : Table

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">
      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <TableComponent
          {...props}
          filters={filters}
          onFilter={onFilter}
          onRemove={onRemove}
          onEdit={setEditId}
          onView={setViewId}
        />
      </div>

      {editId !== null ? (
        <FormUnit
          id={editId}
          onClose={() => setEditId(null)}
          dialog={{
            title: editId ? intl.t('units.titleUpdate') : intl.t('units.titleCreate'),
            dataTestid: 'replaceUnitDialog',
          }}
        />
      ) : null}

      {viewId ? (
        <ViewModelData
          id={viewId}
          title={intl.t('units.titleDetail')}
          onClose={() => setViewId(null)}
          onRemove={onRemove}
          onEdit={setEditId}
          dialog
        />
      ) : null}
    </div>
  )
}
