import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { consts, uniq } from '@wiz/utils'
import observeSensorsHistogram from '@/utils/observeSensorsHistogram'
import Component from '../components/Histogram'

const enhanceRealtimeData = withObservables([
  'config',
  'disabled',
], ({
  config,
  disabled,
}) => {
  const bins = config.bins.reduce((out, bin, idx) => {
    if (!idx) {
      out.push(Number(bin.prevValue) || 0)
    }
    if (Number.isFinite(bin.value) && bin.value > 0) {
      out.push(bin.value)
    }
    return out
  }, [])

  if (!bins.length && config.bins.length === 1) {
    bins.push(0)
  }

  const sources = config.dataViews
    .filter(item => (
      item.checked &&
      item.source.sensorId
    ))
    .map(item => ({
      id: item.id,
      sensorId: item.source.sensorId,
      dateFrom: item.source.dateFrom ?? config.dataFilter.dateFrom,
      dateTo: item.source.dateTo ?? config.dataFilter.dateTo,
      duration: item.source.duration || config.dataFilter.duration,
      offsetDuration: item.source.offsetDuration || config.dataFilter.offsetDuration,
      normalize: config.normalize ?? consts.WidgetHistogramNormalize,
      cumulative: config.cumulative ?? consts.WidgetHistogramCumulative,
      binType: config.histogramType || consts.WidgetHistogramType.Linear,
      minValue: config.minValue,
      maxValue: config.maxValue,
      width: config.bucketSize,
      factor: config.factor || consts.WidgetHistogramFactor,
      count: config.maxNumBuckets || consts.WidgetHistogramBinCount,
      infinity: config.infinity ?? consts.WidgetHistogramInfinity,
      binBoundaryPrecision: config.binBoundaryPrecision,
      bins,
    }))

  return {
    data: disabled || !sources.length ?
      of$([]) :
      observeSensorsHistogram(sources, {
        stepRequest: config.dataFilter.stepRequest,
      }).pipe(
        map((items) => {
          const responses = items.filter(item => !item.response?.errors)
          const bins = uniq(responses.reduce((out, item) => (
            out.concat(item.response.map(i => i[0]))
          ), [])).sort((a, b) => {
            let out = 0
            if (a > b) {
              out = 1
            } else if (b > a) {
              out = -1
            }
            return out
          })

          const out = responses.map((item) => {
            const tmp = Object.fromEntries(item.response)
            return {
              sensorId: item.request.source.sensorId,
              source: bins.map(bin => ([ bin, tmp[bin] ?? null ])),
            }
          })

          return out
        }),
      ),
  }
})

export default enhanceRealtimeData(Component)
