import { useState, useCallback } from 'react'
import { useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Table from '@/containers/Dataframe/Table'
import TableMobile from '@/containers/Dataframe/TableMobile'
import FormHistoricalRun from '@/containers/Forms/HistoricalRun'
import FormDataframe from '@/containers/Forms/Dataframe'
import Filters from './Filters'

export default function List ({
  filters,
  forceUpdate,
  onFilter,
  onResetFilter,
  onRemove,
  ...props
}) {
  const intl = useIntl()
  const isMobile = useMobile()
  const [ editId, setEditId ] = useState(null)
  const [ runId, setRunId ] = useState(null)

  const handleCloseDialog = useCallback(() => {
    setEditId(null)
  }, [])

  const handleRemove = useCallback(async (id) => {
    await window.wizConfirm({ message: 't/units.confirmDelete' })
    await onRemove(id)
    forceUpdate()
  }, [ onRemove, forceUpdate ])

  const TableComponent = isMobile ? TableMobile : Table

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">

      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <div className="d-flex align-items-start flex-wrap">
          <div className="px-sm-2 mb-2">

            <button
              type="button"
              className="btn btn-flat-secondary"
              onClick={forceUpdate}
            >
              {intl.t('form.actions.refresh')}
            </button>
          </div>

          {/* <Filters
            filters={filters}
            onFilter={onFilter}
            onResetFilter={onResetFilter}
          /> */}
        </div>

        <TableComponent
          {...props}
          filters={filters}
          onFilter={onFilter}
          onEdit={setEditId}
          onRun={setRunId}
          onRemove={handleRemove}
        />
      </div>

      {editId !== null ? (
        <FormDataframe
          id={editId}
          onSuccess={forceUpdate}
          onClose={handleCloseDialog}
          dialog={{
            title: editId ?
              intl.t('dataframes.titleUpdate') :
              intl.t('dataframes.titleCreate'),
            dataTestid: 'replaceDataframeDialog',
          }}
        />
      ) : null}

      {runId ? (
        <FormHistoricalRun
          id={runId}
          onSuccess={forceUpdate}
          onClose={() => setRunId(null)}
          dialog={{
            title: intl.t('executions.form.fields.title'),
            dataTestid: 'replaceExecutionDialog',
          }}
        />
      ) : null}

    </div>
  )
}
