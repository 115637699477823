import { Controller, useFormContext } from 'react-hook-form'
import { FormField } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import FileExportList from '@/hoc/FileExportList'
import ProjectList from '@/hoc/ProjectList'
import Select from '@/components/Form/Select'
import SelectSensor from '@/components/Form/SelectSensor'

export default function FieldsExtra ({ scope, disabled }) {
  const intl = useIntl()
  const auth = useAuth()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('notebooks.form.fields.dataFileId')}
        description={intl.t('notebooks.form.fields.dataFileIdDescr')}
        errors={get(errors, `${scope}.dataFileId`)}
      >
        <Controller
          name={`${scope}.dataFileId`}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              disabled={disabled}
              ListSource={FileExportList}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('notebooks.form.fields.sensors')}
        description={intl.t('notebooks.form.fields.sensorsDescr')}
        errors={get(errors, 'sensors')}
      >
        <Controller
          name="sensors"
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              disabled={disabled}
              placeholder={intl.t('form.fields.sensorsPlaceholder')}
              multiselect
            />
          )}
        />
      </FormField>

      {auth.checkAccessRead('Project') ? (
        <FormField
          label={intl.t('notebooks.form.fields.projects')}
          description={intl.t('notebooks.form.fields.projectsDescr')}
          errors={get(errors, 'projects')}
        >
          <Controller
            name="projects"
            render={({ field, fieldState }) => (
              <Select
                {...field}
                invalid={fieldState.invalid}
                ListSource={ProjectList}
                placeholder={intl.t('form.fields.projects')}
                multiselect
              />
            )}
          />
        </FormField>
      ) : null}
    </>
  )
}
