import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { FormSection, FormFieldInline, FormControl } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function SectionView ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <FormSection>
      {/* <FormField
        label={intl.t('widgets.dynamicBulletPlot.form.fields.axisTicks')}
        description={intl.t('widgets.dynamicBulletPlot.form.fields.axisTicksDescr')}
        errors={get(errors, `${scope}.axisTicks`)}
      >
        <FormControl
          type="number"
          name={`${scope}.axisTicks`}
          min={1}
          max={24}
          step={1}
        />
      </FormField> */}

      <FormFieldInline
        label={intl.t('widgets.dynamicBulletPlot.form.fields.colorPercentile')}
        description={intl.t('widgets.dynamicBulletPlot.form.fields.colorPercentileDescr')}
        errors={get(errors, `${scope}.colorPercentile`)}
      >
        <FormControl
          type="color"
          name={`${scope}.colorPercentile`}
          alpha
          clearable
          className="ms-2"
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('widgets.dynamicBulletPlot.form.fields.colorGreen')}
        description={intl.t('widgets.dynamicBulletPlot.form.fields.colorGreenDescr')}
        errors={get(errors, `${scope}.colorGreen`)}
      >
        <FormControl
          type="color"
          name={`${scope}.colorGreen`}
          alpha
          clearable
          className="ms-2"
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('widgets.dynamicBulletPlot.form.fields.colorCurrent')}
        description={intl.t('widgets.dynamicBulletPlot.form.fields.colorCurrentDescr')}
        errors={get(errors, `${scope}.colorCurrent`)}
      >
        <FormControl
          type="color"
          name={`${scope}.colorCurrent`}
          alpha
          clearable
          className="ms-2"
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('widgets.dynamicBulletPlot.form.fields.colorRealtime')}
        description={intl.t('widgets.dynamicBulletPlot.form.fields.colorRealtimeDescr')}
        errors={get(errors, `${scope}.colorRealtime`)}
      >
        <FormControl
          type="color"
          name={`${scope}.colorRealtime`}
          alpha
          clearable
          className="ms-2"
        />
      </FormFieldInline>
    </FormSection>
  )
}

SectionView.propTypes = {
  scope: PropTypes.string.isRequired,
}
