import classnames from 'classnames'
import { auth } from '@/auth'
import Link from '@/components/Link'

export default function CellTemplate ({
  options: [ target ],
  className,
}) {
  return target ? (
    <>
      {auth.checkAccessRead(target) ? (
        <Link
          className={classnames('text-truncate', className)}
          title={target.name}
          name="edge-command-templates-list"
          query={{
            search: target.id,
          }}
        >
          {target.name}
        </Link>
      ) : (
        <span
          className={classnames('text-truncate', className)}
          title={target.name}
        >
          {target.name}
        </span>
      )}
    </>
  ) : null
}
