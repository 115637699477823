import { of as of$, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { mergeArray } from '@wiz/utils'
import observeSensorValue from '@/utils/observeSensorValue'
import observeSensorsStat from '@/utils/observeSensorsStat'
import Component from '../components/DynamicBulletPlot'

const enhanceRealtimeData = withObservables([
  'config',
  'disabled',
], ({
  config,
  disabled,
}) => {
  const bulletPlotSources = config.bulletPlotSources
    .filter(item => !item.disabled)

  const sensorIds = bulletPlotSources
    .map(source => source.sensorId)
    .filter(Boolean)

  const percentileSources = bulletPlotSources
    .map(item => ({
      id: `${item.id}/percentile`,
      sensorId: item.sensorId,
      hardwareId: item.sensor.hardwareId,
      dataTypes: [ 'percentile' ],
      dateFrom: item.rangeDateFrom ?? config.rangeDateFrom,
      dateTo: item.rangeDateTo ?? config.rangeDateTo,
      duration: item.rangeDuration || config.rangeDuration,
      offsetDuration: item.rangeOffsetDuration || config.rangeOffsetDuration,
      percentile: [
        item.rangeMin ?? config.rangeMin,
        item.rangeMax ?? config.rangeMax,
      ],
    }))

  const presentSources = bulletPlotSources
    .map(item => ({
      id: `${item.id}/minmax`,
      sensorId: item.sensorId,
      hardwareId: item.sensor.hardwareId,
      dataTypes: [ 'min', 'max' ],
      dateFrom: item.dateFrom ?? config.dateFrom,
      dateTo: item.dateTo ?? config.dateTo,
      duration: item.duration || config.duration,
      offsetDuration: item.offsetDuration || config.offsetDuration,
    }))

  const sources = [].concat(percentileSources, presentSources)

  return {
    realtimeData: disabled || !sensorIds.length ?
      of$([]) :
      combineLatest(sensorIds.map(sensorId => observeSensorValue(sensorId))),

    statData: disabled || !sources.length ?
      of$([]) :
      observeSensorsStat(sources).pipe(
        map(items => Object.values(items.reduce((out, item) => {
          const {
            sensorId,
            hardwareId,
          } = item.request.source
          return {
            ...out,
            [sensorId]: mergeArray(out[sensorId], {
              sensorId,
              hardwareId,
              percentileMin: item.response.percentile?.[0],
              percentileMax: item.response.percentile?.[1],
              dataMin: item.response.min,
              dataMax: item.response.max,
            }),
          }
        }, {}))),
      ),
  }
})

export default enhanceRealtimeData(Component)
