import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Icon, OverflowCenter, FormCheckbox } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Sensor from '@/hoc/Sensor'

const DisplayName = Sensor(({
  sensor,
  disabled,
}) => (sensor ? (
  <div
    className={classnames('flex-fill d-flex align-items-center min-w-0', {
      'opacity-50': disabled,
    })}
  >
    {sensor.icon || sensor.color ? (
      <Icon
        name={sensor.icon || 'fa--circle'}
        color={sensor.color}
        className="me-2"
      />
    ) : null}

    <OverflowCenter value={sensor.displayName} />
  </div>
) : (
  <div className="flex-fill">-</div>
)))

export default function Option ({ data, index, style }) {
  const intl = useIntl()
  const {
    options,
    onRemoveById,
    onUpdateById,
    onUpdateDisabledById,
  } = data

  const item = options[index]

  return (
    <div
      style={style}
      className="list-group-item list-group-item-action d-flex align-items-center text-nowrap"
    >
      <FormCheckbox
        className="me-2"
        value={!item.disabled}
        onChange={value => onUpdateDisabledById(item.id, !value)}
      />

      <DisplayName
        id={item.sensorId}
        disabled={item.disabled}
      />

      <div className="hover-toggle d-flex align-items-center ms-1 position-relative flex-shrink-0">
        <div className="hover-flex align-items-center position-absolute end-0">
          <div className="border-start rh-4 flex-shrink-0 mx-2" />

          <button
            type="button"
            className="btn btn-sm btn-fill-secondary"
            onClick={() => onRemoveById(item.id)}
          >
            <Icon name="fa--trash-alt" />
          </button>

          <button
            type="button"
            className="btn btn-sm ms-1 btn-fill-secondary"
            title={intl.t('form.actions.edit')}
            onClick={() => onUpdateById(item.id)}
          >
            <Icon name="fa--edit" />
          </button>
        </div>

        <button
          type="button"
          className="btn btn-sm ms-1 btn-flat-primary"
        >
          <Icon name="fa--ellipsis-h" />
        </button>
      </div>
    </div>
  )
}

Option.propTypes = {
  data: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onRemoveById: PropTypes.func.isRequired,
    onUpdateById: PropTypes.func.isRequired,
    onUpdateDisabledById: PropTypes.func.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
}
