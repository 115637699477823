import { useContext } from 'react'
import { AppContext } from '@/pages/context'
import { OperateContext } from '@/pages/context/operate'

const useAppContext = () => {
  const appContext = useContext(AppContext)
  const operateContext = useContext(OperateContext)

  if (!appContext && !operateContext) {
    throw new Error('You are out of context!')
  }

  return {
    ...(appContext || {}),
    ...(operateContext || {}),
  }
}

export default useAppContext
