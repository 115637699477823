import { FormSection } from '@wiz/components'
import InputBins from '@/components/Form/InputBins'

export default function SectionBins ({ scope, title, description }) {
  return (
    <FormSection
      title={title}
      description={description}
    >
      <InputBins
        name={`${scope}.bins`}
        max={9999}
      />
    </FormSection>
  )
}
