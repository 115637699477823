import { useIntl } from '@wiz/intl'
import ButtonCopy from '@/components/Form/ButtonCopy'
import { Icon } from '@wiz/components'

export default function ItemContent ({
  data,
  search,
  className,
  ContentDefault,
  context,
}) {
  const intl = useIntl()
  const item = data.payload || data
  const { onEdit } = context || {}
  return (
    <>
      <ContentDefault
        color={item.color}
        icon={item.icon}
        name={item.displayName || item.name}
        search={search}
        className={className}
      />

      {item.hardwareId ? (
        <ButtonCopy
          className="hover-inline-flex"
          value={item.hardwareId}
          title={intl.t('message.copy.hardwareId', { value: item.hardwareId })}
        />
      ) : null}

      {onEdit ? (
        <button
          type="button"
          className="btn btn-sm btn-link p-1 mt-n1 mb-n1 ms-auto text-reset hover-inline-flex"
          title={intl.t('form.actions.edit')}
          onClick={() => onEdit(item.id)}
        >
          <Icon name="fa--edit" />
        </button>
      ) : null}
    </>
  )
}
