import { useCallback } from 'react'
import { withProps } from '@wiz/components'
import { dbProvider } from '@wiz/store'
import Widget from './components/Widget'

const enhanceProps = withProps(({
  canUpdateDashboard,
  widget,
  onWidgetAction,
}) => {
  const onAction = useCallback(async ({ id }) => {
    await onWidgetAction(widget, id)
  }, [ widget, onWidgetAction ])

  const onChangeSelectedDataRange = useCallback(async (rangeSelected) => {
    if (!canUpdateDashboard) {
      return
    }

    const context = dbProvider.createBatchContext()
    await widget.prepareUpdateData(context, { config: { ...widget.config, rangeSelected } })
    await dbProvider.batch(context)
  }, [ widget, canUpdateDashboard ])

  return {
    onAction,
    onChangeSelectedDataRange,
  }
})

export default enhanceProps(Widget)
