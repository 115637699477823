import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { FormFieldInline, FormControl } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import Selectable from '@/components/Form/Selectable'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormFieldInline
        complex
        label={intl.t('users.form.fields.active')}
        description={intl.t('users.form.fields.activeDescr')}
        errors={get(errors, `${scope}.isActive`)}
        selectable={() => <Selectable value="isActive" />}
      >
        <FormControl
          type="checkbox"
          className="ms-2"
          name={`${scope}.isActive`}
        />
      </FormFieldInline>
    </>
  )
}

Fields.propTypes = {
  scope: PropTypes.string.isRequired,
}

Fields.defaultProps = {}
