import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { ResponsiveList } from '@wiz/components'
import Option from './Option'

export default function SourcesExplorer ({
  options,
  onRemoveById,
  onUpdateById,
  onUpdateDisabledById,
}) {
  const itemData = useMemo(() => ({
    options,
    onRemoveById,
    onUpdateById,
    onUpdateDisabledById,
  }), [
    options,
    onRemoveById,
    onUpdateById,
    onUpdateDisabledById,
  ])

  return (
    <ResponsiveList
      className="flex-fill min-h-0"
      itemCount={itemData.options.length}
      itemData={itemData}
      itemSize={36}
    >
      {Option}
    </ResponsiveList>
  )
}

SourcesExplorer.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRemoveById: PropTypes.func.isRequired,
  onUpdateById: PropTypes.func.isRequired,
  onUpdateDisabledById: PropTypes.func.isRequired,
}
