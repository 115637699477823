import { set } from '@wiz/utils'
import { dbProvider, checkUniqueField } from '@wiz/store'
import { intl } from '@/i18n'

export default async function resolver (data) {
  const errors = {}
  const row = data?.settings?.twin || {}

  if (row.hardwareId) {
    const isUniqHardwareId = await checkUniqueField(
      row,
      'hardwareId',
      dbProvider.database.collections.get('twins').query(),
    )

    if (!isUniqHardwareId) {
      set(errors, 'settings.twin.hardwareId', {
        type: 'unique',
        message: intl.t('twins.form.errors.hardwareIdExists'),
      })
    }
  }

  return {
    errors,
  }
}
