import {
  forwardRef,
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react'
import {
  Icon,
  Dialog,
  FormInputSearch,
  useDebounce,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import TwinTree from '@/hoc/TwinTree'
import FormTwinTree from '@/components/Form/Tree/Tree'
import TwinTreeContent from './TwinTreeContent'

const DataTwinTree = TwinTree(FormTwinTree)

const LeftBar = forwardRef(({
  value,
  scrollTo,
  onView,
  onClose,
}, ref) => {
  const intl = useIntl()
  const wait = useDebounce(300) // open dialog delay
  const refSearch = useRef()
  const refTree = useRef()
  const [ search, setSearch ] = useState('')

  const handleResetFilter = useCallback(() => {
    refSearch.current.reset()
  }, [])

  useEffect(() => {
    if (!wait && scrollTo && refTree.current) {
      refTree.current?.scrollTo(scrollTo)
    }
  }, [
    wait,
    scrollTo,
  ])

  return (
    <Dialog
      ref={ref}
      classNameBody="p-0 overflow-hidden position-relative"
      container={null}
      strategy="absolute"
      orient="left"
      backdrop={false}
      resize
      size="sm"
      title={() => (
        <FormInputSearch
          ref={refSearch}
          className="form-control-sm"
          placeholder={intl.t('form.actions.searchPlaceholder')}
          onChange={setSearch}
        />
      )}
      dataTestid="twinTreeLeftBarDialog"
      onClose={onClose}
    >
      {wait ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <Icon name="fa--spinner" size="lg" spin />
        </div>
      ) : (
        <DataTwinTree
          ref={refTree}
          className="position-absolute-fill"
          Content={TwinTreeContent}
          value={value}
          search={search}
          withSensors={false}
          onChange={onView}
          onResetFilter={handleResetFilter}
        />
      )}
    </Dialog>
  )
})

LeftBar.displayName = 'LeftBar'
export default LeftBar
