/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import EdgeDeviceList from '@/hoc/EdgeDeviceList'
import TwinList from '@/hoc/TwinList'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'
import Observe from '@/containers/Observe'
import CellDevice from './CellDevice'
import CellTwin from './CellTwin'

const CellDeviceData = EdgeDeviceList(CellDevice)
const CellTwinData = TwinList(CellTwin)

export default function ViewModel ({
  id,
  title,
  streamJob,
  onClose,
  onFilter,
  onDuplicate,
  onRemove,
  onEdit,
}) {
  const refDialog = useRef()
  const intl = useIntl()
  const auth = useAuth()

  const handleFilter = (filter) => {
    refDialog.current.close()
    onFilter?.(filter)
  }

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('form.fields.type'),
          value: intl.t(`enum.streamJobs.${streamJob.type}`),
        },
        {
          title: intl.t('form.fields.name'),
          value: streamJob.name,
        },
        {
          title: intl.t('form.fields.description'),
          value: streamJob.description,
        },
      ],
    },

    {
      title: intl.t('streamJobs.section.extra'),
      options: [
        {
          title: intl.t('form.fields.device'),
          value: () => (streamJob.deviceId ? (
            <CellDeviceData
              value={streamJob.deviceId}
              selectedOnly
              onFilter={handleFilter}
            />
          ) : '-'),
        },
        {
          title: intl.t('form.fields.twins'),
          value: () => (
            <Observe query={streamJob.queryRelTwins}>
              {({ data }) => (
                <CellTwinData
                  value={data.map(item => item.twinId)}
                  selectedOnly
                  onFilter={handleFilter}
                />
              )}
            </Observe>
          ),
        },
      ],
    },

    {
      title: intl.t('form.fields.updated'),
      value: () => <UpdatedInfo date={streamJob.updatedAt} user={streamJob.updatedUser} />,
    },
    {
      title: intl.t('form.fields.created'),
      value: () => <UpdatedInfo date={streamJob.createdAt} user={streamJob.createdUser} />,
    },
  ]

  const actions = [
    ...(onRemove && auth.checkAccessRemove(streamJob) ? ([{
      title: intl.t('form.actions.remove'),
      name: 'remove',
      testid: 'removeButton',
      onClick: () => onRemove([ streamJob ]),
    }]) : []),

    ...(auth.checkAccessCopy(streamJob) ? ([{
      title: intl.t('form.actions.duplicate'),
      name: 'duplicate',
      testid: 'duplicateButton',
      onClick: () => onDuplicate?.(
        streamJob,
        { active: false },
      ),
    }]) : []),

    ...(onEdit && auth.checkAccessUpdate(streamJob) ? ([{
      title: intl.t('form.actions.edit'),
      name: 'edit',
      testid: 'editButton',
      onClick: () => onEdit(id),
    }]) : []),
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      actions={actions}
      onClose={onClose}
    />
  )
}
