import { useEffect, useState } from 'react'
import { withProps } from '@wiz/components'
import { wizataApi } from '@/api'

export default withProps(({ id }) => {
  const [ loading, setLoading ] = useState(false)
  const [ data, setData ] = useState(null)
  const [ error, setError ] = useState(null)

  useEffect(() => {
    const fetchData = async (adId) => {
      setLoading(true)
      try {
        const user = await wizataApi.adUsers.getById(adId)
        setData(user)
      } catch (err) {
        setData(null)
        setError(err)
      }
      setLoading(false)
    }
    if (id) {
      fetchData(id)
    }
  }, [ id ])

  return {
    data,
    loading,
    error,
  }
})
