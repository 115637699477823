/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'
import TwinList from '@/hoc/TwinList'
import SensorList from '@/hoc/SensorList'
import StreamJobList from '@/hoc/StreamJobList'
import BusinessLabelList from '@/hoc/BusinessLabelList'
import ExperimentList from '@/hoc/ExperimentList'
import CellTwin from './CellTwin'
import CellStreamJob from './CellStreamJob'
import CellSensors from './CellSensors'
import CellDuration from './CellDuration'
import CellBusinessLabels from './CellBusinessLabels'
import CellExperiment from './CellExperiment'

const CellSensorsData = SensorList(CellSensors)
const CellStreamJobData = StreamJobList(CellStreamJob)
const CellTwinData = TwinList(CellTwin)
const CellBusinessLabelsData = BusinessLabelList(CellBusinessLabels)
const CellExperimentData = ExperimentList(CellExperiment)

export default function ViewModel ({
  title,
  event,
  onClose,
  onFilter,
}) {
  const refDialog = useRef()
  const intl = useIntl()
  const auth = useAuth()

  const handleFilter = (filter) => {
    refDialog.current.close()
    onFilter?.(filter)
  }

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('form.fields.name'),
          value: event.name,
        },
        {
          title: intl.t('form.fields.description'),
          value: event.description || '-',
        },
        {
          title: intl.t('form.fields.sourceType'),
          value: event.sourceType ? intl.t(`enum.eventSourceType.${event.sourceType}`) : '-',
        },
        {
          title: intl.t('form.fields.type'),
          value: event.type ? intl.t(`enum.eventType.${event.type}`) : '-',
        },
        {
          title: intl.t('form.fields.status'),
          value: event.status ? intl.t(`enum.eventStatus.${event.status}`) : '-',
        },
        {
          title: intl.t('form.fields.duration'),
          value: () => (<CellDuration data={event} />),
        },
        ...(auth.checkAccessRead('StreamJob') ? [
          {
            title: intl.t('events.form.fields.streamJob'),
            value: () => (
              <CellStreamJobData
                value={event.streamJobId}
                selectedOnly
              />
            ),
          },
        ] : []),
        ...(auth.checkAccessRead('Twin') ? [
          {
            title: intl.t('form.fields.twin'),
            value: () => (
              <CellTwinData
                value={event.twinId}
                selectedOnly
              />
            ),
          },
        ] : []),
        ...(auth.checkAccessRead('Sensor') ? [
          {
            title: intl.t('form.fields.dataPoints'),
            value: () => (
              <CellSensorsData
                value={event.sensorIds}
                event={event}
                keyName="id"
                selectedOnly
                onFilter={handleFilter}
              />
            ),
          },
        ] : []),
        ...(auth.checkAccessRead('BusinessLabel') ? [
          {
            title: intl.t('events.form.fields.businessLabels'),
            value: () => (
              <CellBusinessLabelsData
                value={event.businessLabelIds}
                keyName="id"
                selectedOnly
              />
            ),
          },
        ] : []),
        ...(auth.checkAccessRead('Experiment') ? [
          {
            title: intl.t('events.form.fields.experiment'),
            value: () => (
              <CellExperimentData
                value={event.experimentId}
                keyName="id"
                selectedOnly
              />
            ),
          },
        ] : []),
      ],
    },

    {
      title: intl.t('form.fields.updated'),
      value: () => <UpdatedInfo date={event.updatedAt} user={event.updatedUser} />,
    },
    {
      title: intl.t('form.fields.created'),
      value: () => <UpdatedInfo date={event.createdAt} user={event.createdUser} />,
    },
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      onClose={onClose}
    />
  )
}
