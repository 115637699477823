import { Controller, useFormContext } from 'react-hook-form'
import {
  FormField,
  FormControl,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import ProjectList from '@/hoc/ProjectList'
import Select from '@/components/Form/Select'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const auth = useAuth()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('files.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (!str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value.length > 450) {
                return intl.t('form.errors.fieldMaxlen', { max: 450 })
              }
              return true
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.description')}
        description={intl.t('files.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.description`}
          rules={{
            maxLength: {
              value: 450,
              message: intl.t('form.errors.fieldMaxlen', { max: 450 }),
            },
          }}
        />
      </FormField>

      {auth.checkAccessRead('Project') ? (
        <FormField
          label={intl.t('form.fields.projects')}
          description={intl.t('files.form.fields.projectsDescr')}
          errors={get(errors, 'projects')}
        >
          <Controller
            name="projects"
            render={({ field, fieldState }) => (
              <Select
                {...field}
                invalid={fieldState.invalid}
                ListSource={ProjectList}
                placeholder={intl.t('form.fields.projects')}
                multiselect
              />
            )}
          />
        </FormField>
      ) : null}
    </>
  )
}
