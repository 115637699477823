import {
  useState,
  useCallback,
  useEffect,
} from 'react'
import {
  Icon,
  FormInputSearch,
  useMobile,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import { useLocation, useRouter } from '@/router'
import SearchList from '@/containers/Dashboards/SearchList'
import FormDashboard from '@/containers/Forms/Dashboard'
import Filters from './Filters'

export default function List ({
  filters,
  onAction,
  onFilter,
  onResetFilter,
  templateId,
  onOpen,
}) {
  const intl = useIntl()
  const auth = useAuth()
  const location = useLocation()
  const router = useRouter()
  const isMobile = useMobile()
  const [ search, setSearch ] = useState('')
  const [ editId, setEditId ] = useState(null)
  const isCreate = location?.state?.create

  const handleAction = useCallback(async (action, dashboard) => {
    if (action.id === 'settings') {
      setEditId(dashboard.id)
    } else {
      await onAction?.(action, dashboard)
    }
  }, [ onAction ])

  useEffect(() => {
    if (isCreate) {
      setEditId('')
    }
  }, [ isCreate ])

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">

      <div className="content-container content-container-sm d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-2">
        <div className="d-flex flex-shrink-0 align-items-center">
          <FormInputSearch
            className="mb-2"
            placeholder={intl.t('form.actions.searchPlaceholder')}
            onChange={setSearch}
          />

          {auth.checkAccessCreate('Dashboard') ? (
            <button
              type="button"
              className="btn btn-fill-secondary text-nowrap mb-2 ms-2"
              onClick={() => setEditId('')}
            >
              <Icon name="fa--plus" className="me-1" />
              {intl.t('form.actions.add')}
            </button>
          ) : null}
        </div>

        <div className="d-flex align-items-center flex-shrink-0 mx-n1 mb-2">
          <Filters
            filters={filters}
            onFilter={onFilter}
          />
        </div>

        <SearchList
          className="flex-fill"
          search={search}
          filters={filters}
          onAction={handleAction}
          onResetFilter={onResetFilter}
          onOpen={onOpen}
          templateId={templateId}
        />
      </div>

      {editId !== null ? (
        <FormDashboard
          id={editId}
          onSuccess={(data) => {
            if (!editId) {
              if (templateId) {
                router.push({ query: { dashboardId: data.id, page: 'dashboards' } })
              } else {
                router.push({ name: 'dashboard', params: { id: data.id } })
              }
            }
          }}
          templateId={templateId}
          onClose={() => setEditId(null)}
          dialog={{
            title: editId ? intl.t('dashboards.titleUpdate') : intl.t('dashboards.titleCreate'),
            dataTestid: 'replaceDashboardDialog',
          }}
        />
      ) : null}
    </div>
  )
}
