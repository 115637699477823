import { DateTime } from 'luxon'
import { duration } from '@wiz/utils'
import DateRelative from '@/components/DateRelative'
import FormatDateTime from '@/containers/FormatDateTime'

export default function CellDuration ({ data }) {
  if (!data.from && !data.to) {
    return '-'
  }

  const from = DateTime.fromMillis(data.from)
  const to = data.to ? DateTime.fromMillis(data.to) : null
  const dur = from && to ? duration(to.toMillis() - from.toMillis()) : null

  return (
    <span>
      {dur ? (
        <>
          <b className="me-2 text-nowrap">
            {dur}
          </b>
          <br />
        </>
      ) : null}
      <FormatDateTime date={from} withSecond />
      <sup className="ms-1">
        <DateRelative value={data.from} future />
      </sup>
    </span>
  )
}
