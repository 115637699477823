import { of as of$ } from 'rxjs'
import { map, distinctUntilChanged } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { isEqual } from '@wiz/utils'

export default withObservables([ 'id', 'defaultValues' ], ({ id, defaultValues }) => ({
  sensor: id ? (
    dbProvider.database.collections.get('sensors')
      .query(Q.where('id', id))
      .observeWithColumns([ 'updated_at' ])
      .pipe(map(items => items[0]))
  ) : (
    of$(defaultValues)
      .pipe(distinctUntilChanged(isEqual))
  ),
}))
