import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import {
  FormField,
  FormControl,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { get } from '@wiz/utils'

export default function FieldsRange ({ scope }) {
  const intl = useIntl()
  const { formState: { errors }, watch, clearErrors } = useFormContext()
  const rangeMin = watch(`${scope}.rangeMin`)
  const rangeMax = watch(`${scope}.rangeMax`)

  useEffect(() => {
    clearErrors([
      `${scope}.rangeMin`,
      `${scope}.rangeMax`,
    ])
  }, [ scope, rangeMin, rangeMax, clearErrors ])

  return (
    <>
      <FormField
        label={intl.t('bulletPlotSource.form.fields.rangeMin')}
        description={intl.t('bulletPlotSource.form.fields.rangeMinDescr')}
        errors={get(errors, `${scope}.rangeMin`)}
      >
        <FormControl
          type="number"
          name={`${scope}.rangeMin`}
          min={0}
          max={100}
          step={1}
          clearable
        />
      </FormField>

      <FormField
        label={intl.t('bulletPlotSource.form.fields.rangeMax')}
        description={intl.t('bulletPlotSource.form.fields.rangeMaxDescr')}
        errors={get(errors, `${scope}.rangeMax`)}
      >
        <FormControl
          type="number"
          name={`${scope}.rangeMax`}
          min={0}
          max={100}
          step={1}
          clearable
        />
      </FormField>
    </>
  )
}

FieldsRange.propTypes = {
  scope: PropTypes.string.isRequired,
}
