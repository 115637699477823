import { useFormContext } from 'react-hook-form'
import { FormFieldInline, FormControl } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function FieldAutosave () {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <FormFieldInline
      label={intl.t('explorer.form.fields.autosave')}
      description={intl.t('explorer.form.fields.autosaveDescr')}
      errors={get(errors, 'settings.explorerAutosave')}
    >
      <FormControl
        type="checkbox"
        name="settings.explorerAutosave"
        className="ms-2"
      />
    </FormFieldInline>
  )
}
