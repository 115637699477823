import { forwardRef, useEffect } from 'react'
import { DateTime } from 'luxon'
import { useQuery } from '@tanstack/react-query'
import { wizataApi } from '@/api'
import Select from '@/components/Form/Select'

const SelectPipelineImage = forwardRef(({
  id, disabled, pipelineId, pipelineKey, ...props
}, ref) => {
  const {
    data, isLoading, refetch, isFetching,
  } = useQuery({
    queryKey: [ 'pipelineImages' ],
    queryFn: () => wizataApi.pipelines.getPipelineImages(pipelineKey)
      .then(list => list.sort((a, b) => b.datetime - a.datetime)
        .map(item => ({ ...item, name: item.id }))),
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false,
  })

  useEffect(() => {
    if (data) {
      refetch()
    }
  }, [ pipelineId ])

  return (
    <Select
      {...props}
      invalid={props.invalid}
      options={data}
      disabled={disabled || isLoading || isFetching}
      isLoading={isLoading || isFetching}
    />
  )
})

export default SelectPipelineImage
