import { useMemo } from 'react'
import { DiagramBlock, DiagramBlockOutput } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import BlockSection from '../Block/Section'
import Section from './Section'
import TimeSettingsSection from './TimeSettingsSection'

export default function BlockOutput ({
  block,
  settings,
  dialog,
  onClose,
  onSubmit,
  sensorIds,
}) {
  const intl = useIntl()
  const defaultValues = useMemo(() => ({
    block: DiagramBlock.toJSON(block),
    settings: {
      ...DiagramBlockOutput.toJSON(settings),
      outputTimestampType: settings?.outputTimestampType || 'current',
    },
  }), [
    block?.updatedAt ?? block,
    settings?.updatedAt ?? settings,
  ])

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="blockOutputForm"
      dialog={dialog}
    >
      <BlockSection scope="block" />
      <Section scope="settings" title={intl.t('form.sections.output')} />
      <TimeSettingsSection scope="settings" title={intl.t('form.sections.timeSettings')} sensorIds={sensorIds} />
    </FormDialog>
  )
}
