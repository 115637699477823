import { Icon, Badge, OverflowCenter } from '@wiz/components'
import classnames from 'classnames'
import Observe from '@/containers/Observe'

export default function CellLabels ({
  sensor,
}) {
  return (
    <Observe query={sensor.queryLabels}>
      {({ data }) => {
        if (!data.length) {
          return '-'
        }

        const label = data[0]
        return (
          <>
            <Badge
              className={classnames({ 'bg-secondary': !label.color })}
              color={label.color}
            >
              {label.icon ? <Icon name={label.icon} className="me-1" /> : null}
              <OverflowCenter value={label.name} />
            </Badge>
            {data.length > 1 ? (
              <small className="text-secondary ms-1">
                +{data.length - 1}
              </small>
            ) : null}
          </>
        )
      }}
    </Observe>
  )
}
