/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import {
  useEffect, useMemo, useRef, useCallback,
} from 'react'
import classnames from 'classnames'
import {
  VirtualTable,
  Card,
  useMobile,
} from '@wiz/components'
import { markSearch } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import Link from '@/components/Link'
import DateRelative from '@/components/DateRelative'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import FormatDateTime from '@/containers/FormatDateTime'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'

export default function TwinGraphTable ({
  search,
  settings,
  options,
  filters,
  onAction,
  onResetFilter,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const isMobile = useMobile()
  const { saveScrollPosition, boards } = useGlobalExecute()
  const ref = useRef()
  const refScrollPos = useRef(boards.scrollPosition)
  const columns = useMemo(() => [
    {
      accessor: 'id',
      className: 'd-flex pb-2',
      Cell: ({ row }) => (
        <Card
          className={classnames('flex-fill', {
            'border border-primary': row.original.id === settings.homepageDefaultId,
          })}
          classNameBody={classnames('mt-0 justify-content-end', {
            'mb-1': isMobile,
          })}
          title={(
            <Link
              name="chart-view"
              params={{ id: row.original.blockId }}
              className={classnames('text-truncate h5', {
                disabled: !row.original.blockId,
              })}
              disabled={!row.original.blockId}
              keepQuery
            >
              {search ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: markSearch(row.original.name, search),
                  }}
                />
              ) : row.original.name}
            </Link>
          )}
          options={(
            [
              {
                id: 'view',
                label: intl.t('form.actions.view'),
                icon: 'fa--eye',
              },
              ...(auth.checkAccessUpdate(row.original) ? [
                {
                  id: 'settings',
                  label: intl.t('form.actions.settings'),
                  icon: 'fa--cog',
                },
              ] : []),
              ...(auth.checkAccessCopy(row.original) ? [
                {
                  id: 'duplicate',
                  label: intl.t('form.actions.duplicate'),
                  icon: 'fa--clone',
                },
              ] : []),
              {
                id: 'favorite',
                icon: settings.spotlightFavorites?.includes(row.original.id) ?
                  'fa--star' : 'far--star',
                label: settings.spotlightFavorites?.includes(row.original.id) ?
                  intl.t('form.actions.removeFavorite') :
                  intl.t('form.actions.addFavorite'),
              },
              {
                id: 'select',
                label: intl.t('form.actions.setDefault'),
                icon: 'fa--paperclip',
                disabled: row.original.id === settings.homepageDefaultId,
              },
              ...(auth.checkAccessRemove(row.original) ? [
                {
                  id: 'remove',
                  label: intl.t('form.actions.remove'),
                  icon: 'fa--trash-alt',
                  disabled: row.original.id === settings.homepageDefaultId,
                },
              ] : []),
            ]
          )}
          onAction={action => onAction?.(action, row.original)}
        >
          {row.original.description ? (
            <div className="flex-shrink-0 text-muted text-truncate">
              {search ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: markSearch(row.original.description, search),
                  }}
                />
              ) : row.original.description}
            </div>
          ) : null}

          {isMobile ? null : (
            <>
              <div className="d-flex align-items-center">
                <b className="me-1">
                  {intl.t('form.fields.created')}
                  :
                </b>
                <UpdatedInfo date={row.original.createdAt} />
              </div>

              <div className="d-flex align-items-center">
                <b className="me-1">
                  {intl.t('form.fields.author')}
                  :
                </b>
                <UpdatedInfo
                  user={row.original.createdUser}
                  userClassName=""
                />
              </div>

              <div className="d-flex align-items-center flex-wrap text-nowrap">
                <div className="d-flex align-items-center me-1">
                  <b className="me-1">
                    {intl.t('form.fields.lastModified')}
                    :
                  </b>
                  <UpdatedInfo date={row.original.updatedAt} />
                </div>
                {intl.t('form.fields.by', { value: ' ' })}
                &nbsp;
                <UpdatedInfo
                  user={row.original.updatedUser}
                  userClassName=""
                />
              </div>

              {settings.lastOpened?.[row.original.id] ? (
                <div className="d-flex align-items-center text-nowrap">
                  <b className="me-1">Last Opened At:</b>
                  <div className="d-flex align-items-center">
                    <FormatDateTime date={settings.lastOpened[row.original.id]} />
                    <sup className="ms-1">
                      <DateRelative value={settings.lastOpened[row.original.id]} />
                    </sup>
                  </div>
                </div>
              ) : null}
            </>
          )}
        </Card>
      ),
    },
  ], [
    intl,
    auth,
    search,
    settings,
    isMobile,
    onAction,
  ])

  const handleScroll = useCallback((event) => {
    refScrollPos.current = event?.target?.scrollTop ?? boards.scrollPosition
  }, [ boards.scrollPosition ])

  const itemSize = useCallback((idx) => {
    const item = options[idx]
    let height = isMobile ? 45 : 135
    if (item.description) {
      height += 25
    }
    if (!isMobile && settings.lastOpened?.[item.id]) {
      height += 30
    }
    return height
  }, [ options, settings, isMobile ])

  useEffect(() => {
    ref.current?.scrollTo({ top: boards.scrollPosition })
    return () => {
      saveScrollPosition({ entity: 'boards', scrollPosition: refScrollPos.current })
    }
  }, [])

  return (
    <VirtualTable
      className="flex-fill no-hover"
      onScroll={handleScroll}
      forwardedRef={(r) => {
        ref.current = r
      }}
      columns={columns}
      data={options}
      itemSize={itemSize}
      estimatedItemSize={135}
      selection={false}
      showHeader={false}
      placeholder={(
        <div className="flex-column position-center-fill position-absolute-fill">
          {intl.t('errors.noDataDisplay')}
          {onResetFilter ? (
            <button
              className="btn btn-sm btn-outline-secondary mt-2"
              type="button"
              onClick={onResetFilter}
            >
              {intl.t('form.actions.resetFilters')}
            </button>
          ) : null}
        </div>
      )}
      resetCellStyle
      {...props}
    />
  )
}
