import { useCallback, useState, useMemo } from 'react'
import xor from 'lodash/xor'
import union from 'lodash/union'
import { Icon, OverflowCenter, useDrag } from '@wiz/components'
import { DiagramBlockFlow } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import { consts, toArrayValue } from '@wiz/utils'
import NameDiagramBlockFlow from '@/components/Form/NameDiagramBlockFlow'
import FilterSearch from '@/components/Form/FilterSearch'
import ListSensors from '@/components/Form/ListSensors'
import FormSetPoint from '@/containers/Forms/Sensor/SetPoint'
import FormMeasurement from '@/containers/Forms/Sensor/Measurement'

const SensorForms = {
  [consts.SensorBusinessType.SetPoint]: FormSetPoint,
  [consts.SensorBusinessType.Measurement]: FormMeasurement,
}

export default function AttachSensors ({
  businessType,
  selectedBlockSettings,
  onAttachSensors,
  onResetSelectedBlockSettings,
  setAttachedSensorsCount,
}) {
  const drag = useDrag()
  const intl = useIntl()
  const [ value, setValue ] = useState([])
  const [ createSensor, setCreateSensor ] = useState(false)

  const handleActive = useCallback((data) => {
    const item = data?.payload ?? data
    return (
      (value && item.id === value) ||
      value?.includes?.(item.id)
    )
  }, [ value ])

  const handleChange = useCallback((data) => {
    const ids = toArrayValue(data).map(item => (item.payload || item).id)
    let nextValue

    if (data === null) {
      nextValue = []
    } else if (Array.isArray(data)) {
      nextValue = union(value, ids)
    } else {
      nextValue = xor(value, ids)
    }

    setAttachedSensorsCount(nextValue.length)
    setValue(nextValue)
  }, [ value ])

  const handleDragStart = useCallback((data) => {
    const ids = toArrayValue(data).map(item => (item.payload || item).id)
    drag.context.id = selectedBlockSettings.id
    union(value, ids).forEach(id => drag.data.add(id))
  }, [ drag, value, selectedBlockSettings ])

  const handleDragEnd = useCallback(() => {
    drag.clear()
    setAttachedSensorsCount(0)
    setValue([])
  }, [ drag ])

  const FormComponent = (
    businessType &&
    consts.SensorBusinessTypesUserCreate.includes(businessType)
  ) ? SensorForms[businessType] : null

  const formDefaultValues = useMemo(() => ({
    twinId: selectedBlockSettings.id,
  }), [ selectedBlockSettings ])

  const formDialogParams = useMemo(() => ({
    title: intl.t('sensors.titleCreate'),
    dataTestid: 'replaceSensorDialog',
  }), [ intl ])

  const onCloseForm = useCallback(() => {
    setCreateSensor(false)
    onResetSelectedBlockSettings()
  }, [ onResetSelectedBlockSettings ])

  return (
    <>
      <header className="mx-3 mt-2 d-flex align-items-center">
        <button
          type="button"
          className="btn btn-flat-secondary me-2 p-2 flex-shrink-0"
          onClick={onResetSelectedBlockSettings}
        >
          <Icon name="fa--chevron-left" />
        </button>

        <div className="flex-fill d-flex flex-wrap min-w-0">
          <span className="me-1">
            {intl.t('twin.attachDataPoints')}
          </span>

          {DiagramBlockFlow.is(selectedBlockSettings) ? (
            <NameDiagramBlockFlow
              value={selectedBlockSettings}
              className="fw-bold"
            />
          ) : (
            <OverflowCenter
              value={selectedBlockSettings.name}
              className="fw-bold"
            />
          )}
        </div>
      </header>

      <FilterSearch
        onChange={handleChange}
        withSelectAll
        postfix={do {
          if (businessType && consts.SensorBusinessTypesUserCreate.includes(businessType)) {
            <button
              type="button"
              className="btn btn-sm btn-fill-secondary-alt text-nowrap mb-1 me-1"
              onClick={() => setCreateSensor(true)}
            >
              {intl.t('form.actions.create')}
            </button>
          }
        }}
      >
        {(formData, refList) => (
          <ListSensors
            {...formData}
            ref={refList}
            className="flex-fill min-h-0"
            unusedOnly
            draggable
            keyName="id"
            value={value}
            businessType={businessType}
            active={handleActive}
            onChange={handleChange}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          />
        )}
      </FilterSearch>

      <footer className="d-flex justify-content-end mx-3 my-2">
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={onResetSelectedBlockSettings}
        >
          {intl.t('form.actions.back')}
        </button>
        <button
          type="button"
          className="btn btn-primary ms-2"
          disabled={!value.length}
          onClick={() => onAttachSensors(selectedBlockSettings, value)}
        >
          {intl.t('form.actions.attach')}
        </button>
      </footer>

      {createSensor && FormComponent ? (
        <FormComponent
          defaultValues={formDefaultValues}
          onClose={onCloseForm}
          dialog={formDialogParams}
        />
      ) : null}
    </>
  )
}
