import { Controller, useFormContext } from 'react-hook-form'
import {
  FormField,
  FormControl,
  FormInputJSON,
  FormFieldInline,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('edge.modules.form.fields.moduleId')}
        errors={get(errors, `${scope}.moduleId`)}
      >
        <FormControl
          name={`${scope}.moduleId`}
          disabled
        />
      </FormField>

      <FormFieldInline
        label={intl.t('edge.modules.form.fields.config')}
        description={intl.t('edge.modules.form.fields.configDescr')}
        errors={get(errors, `${scope}.config`)}
        vertical
      >
        <Controller
          name={`${scope}.config`}
          rules={{
            maxLength: {
              value: 20000,
              message: intl.t('form.errors.fieldMaxlen', { max: 20000 }),
            },
          }}
          render={({ field, fieldState }) => (
            <FormInputJSON
              {...field}
              invalid={fieldState.invalid}
              className="mt-1 font-monospace"
              rows={15}
            />
          )}
        />
      </FormFieldInline>
    </>
  )
}
