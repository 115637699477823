import { useState } from 'react'
import { func } from 'prop-types'
import {
  Form, FormControl, Icon,
} from '@wiz/components'
import SelectSensor from '@/components/Form/SelectSensor'
import { useIntl } from '@wiz/intl'

const ConnectionEdit = ({
  onEdit,
  onClose,
}) => {
  const [ connection, setConnection ] = useState({ speed: null, distance: null })
  const intl = useIntl()

  return (
    <>
      <header className="mx-3 mt-2 d-flex align-items-center">
        <button
          type="button"
          className="btn btn-flat-secondary me-2 p-2 flex-shrink-0"
          onClick={onClose}
        >
          <Icon name="fa--chevron-left" />
        </button>

        <div className="flex-fill d-flex flex-wrap min-w-0">
          <span className="me-1">
            Edit connection
          </span>
        </div>
      </header>
      <Form className="d-flex flex-column flex-wrap px-3 flex-fill">
        <div>
          <div className="flex-fill my-2">Set up the speed:</div>
          <div className="d-flex align-items-center">
            <FormControl
              type="text"
              name="value"
              scale={8}
              placeholder="Enter value"
              onChange={speed => setConnection(prev => ({ ...prev, speed }))}
            />
            <span className="mx-2">
              or
            </span>
            <SelectSensor
              placeholder="Select Data Point"
              onChange={speed => setConnection(prev => ({ ...prev, speed }))}
              value={connection.speed}
            />
          </div>
        </div>
        <div>
          <div className="flex-fill my-2">Set up distance:</div>
          <FormControl
            type="text"
            name="value"
            scale={8}
            onChange={distance => setConnection(prev => ({ ...prev, distance }))}
          />
        </div>

        <div className="my-2 mt-4">
          <button
            name="cancel"
            type="button"
            data-testid="saveAndNextButton"
            className="btn btn-outline-secondary"
            onClick={() => onEdit(connection)}
          >
            <span className="d-none d-sm-inline">{intl.t('form.actions.save')}</span>
          </button>
          <button
            type="button"
            className="btn btn-fill-secondary mx-2"
            onClick={onClose}
          >
            {intl.t('form.actions.cancel')}
          </button>
        </div>
      </Form>
    </>
  )
}

ConnectionEdit.propTypes = {
  onEdit: func.isRequired,
  onClose: func.isRequired,
}

export { ConnectionEdit }
