import { useCallback } from 'react'
import { Q, dbProvider } from '@wiz/store'
import { withProps, useDrag } from '@wiz/components'
import { getIconByName } from '@wiz/icons'
import { consts, go } from '@wiz/utils'
import { wizataApi } from '@/api'
import events from '@/utils/events'

export default withProps(({
  diagramState,
  metaBlock,
  streamJob,
}) => {
  const { hideGrid } = diagramState.diagram
  const { elements } = diagramState
  const drag = useDrag()

  const blockFactory = useCallback(async (block) => {
    const iconSource = block.icon && getIconByName(block.icon)
    let icon = block.icon === false ? null : 'Rectangle'
    let size = '40 40'
    let { list } = block

    if (iconSource) {
      icon = iconSource.sprite
      size = `${iconSource.width} ${iconSource.height}`
    }

    if (Array.isArray(list)) {
      list = list.map(item => ({
        ...item,
        icon: item.icon ? (getIconByName(item.icon)?.sprite || null) : null,
      }))
    }

    return {
      ...block,
      icon,
      size,
      list,
      category: block.type === 'flow' ? '' : block.category,
    }
  }, [])

  const onClickCreate = useCallback(async (type, state) => {
    let blockState = state
    if (blockState.loc) {
      let { loc } = blockState
      const items = Object.values(elements)
      for (let i = 0; i < items.length; i += 1) {
        const item = items[i]
        if (item.loc === loc) {
          i = 0
          loc = go.Point.stringify(
            go.Point.parse(loc)
              .add(new go.Point(30, 30)),
          )
        }
      }
      blockState = {
        ...blockState,
        loc,
      }
    }

    const context = dbProvider.createBatchContext()
    const model = await metaBlock.prepareReplaceBlock(context, { type })
    await metaBlock.prepareReplaceHyperedge(context, model)
    await metaBlock.prepareReplaceBlockState(context, model, blockState)
    await dbProvider.batch(context)
  }, [ metaBlock, elements ])

  const onCopyBlock = useCallback(async (block, state) => {
    const context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareDuplicateModel(context, block)
    await metaBlock.prepareReplaceHyperedge(context, model)
    await metaBlock.prepareReplaceBlockState(context, model, state)
    await dbProvider.batch(context)
  }, [ metaBlock ])

  const onPaste = useCallback(async (data) => {
    const hash = data.reduce((out, item) => ({ ...out, [item.id]: item }), {})
    const blocks = await dbProvider.database.collections.get('diagram_blocks')
      .query(Q.where('id', Q.oneOf(Object.keys(hash))))
      .fetch()
    const nodes = blocks.filter(item => item.type !== consts.DiagramBlockType.Flow)
    const links = blocks.filter(item => item.type === consts.DiagramBlockType.Flow)
    const pairs = {}
    const state = {}
    const nextIds = []
    const context = dbProvider.createBatchContext()

    for (const node of nodes) {
      const { loc } = hash[node.id]
      const model = await dbProvider.prepareDuplicateModel(context, node)
      await metaBlock.prepareReplaceHyperedge(context, model)
      pairs[node.id] = model.id
      state[model.id] = { loc }
      nextIds.push(model.id)
    }

    for (const link of links) {
      const { fromId, toId } = hash[link.id]
      if (pairs[fromId] && pairs[toId]) {
        const model = await dbProvider.prepareDuplicateModel(context, link, {
          fromId: pairs[fromId],
          toId: pairs[toId],
        })
        nextIds.push(model.id)
      }
    }

    await metaBlock.prepareReplaceBlocksState(context, state)
    await dbProvider.batch(context)
    return nextIds
  }, [ metaBlock ])

  const onDropCreateDiagram = useCallback(async (state = {}) => {
    const { type } = drag.context
    const dragData = drag.data
    drag.clear()

    if (consts.StreamJobBlockTypes.includes(type)) {
      let { loc } = state
      const context = dbProvider.createBatchContext()
      if (dragData.size) {
        for (const data of dragData) {
          const block = await metaBlock.prepareReplaceBlock(context, { type })
          await block.prepareLinkSettings(context, { model: data.payload || data })
          await metaBlock.prepareReplaceHyperedge(context, block)
          await metaBlock.prepareReplaceBlockState(context, block, { loc })

          if (loc) {
            loc = go.Point.stringify(
              go.Point.parse(loc)
                .add(new go.Point(30, 30)),
            )
          }
        }
      } else {
        const block = await metaBlock.prepareReplaceBlock(context, { type })
        await metaBlock.prepareReplaceHyperedge(context, block)
        await metaBlock.prepareReplaceBlockState(context, block, { loc })
      }
      await dbProvider.batch(context)
    }
  }, [ drag, metaBlock ])

  const onDeletedDiagram = useCallback(async (ids) => {
    await window.wizConfirm({ message: 't/streamJobs.confirmDeleteBlock' })
    const context = dbProvider.createBatchContext()
    await metaBlock.prepareStrictRemoveByIds(context, ids)
    await dbProvider.batch(context)
  }, [ metaBlock ])

  const onLinkDiagram = useCallback(async (data) => {
    const next = { ...data, type: 'flow' }
    const {
      fromId,
      fromPort,
      toId,
      toPort,
      hyperedge,
      ...blockData
    } = next

    const context = dbProvider.createBatchContext()
    const block = await metaBlock.prepareReplaceBlock(context, blockData)
    await block.prepareReplaceSettings(context, {
      flow: {
        fromId,
        fromPort,
        toId,
        toPort,
        hyperedge,
      },
    })
    await dbProvider.batch(context)
  }, [ metaBlock ])

  const onChangeDiagram = useCallback(async ({ elements, diagram }) => {
    const context = dbProvider.createBatchContext()
    await streamJob.prepareUpdateElementsState(context, elements)
    await streamJob.prepareUpdateDiagramState(context, diagram)
    await dbProvider.batch(context)
  }, [ streamJob ])

  const onToggleGrid = useCallback(async () => {
    const context = dbProvider.createBatchContext()
    await streamJob.prepareUpdateDiagramState(context, 'hideGrid', !hideGrid)
    await dbProvider.batch(context)
  }, [ streamJob, hideGrid ])

  const onToggleActive = useCallback(async () => {
    let hasError = false

    if (!streamJob.active) {
      try {
        await wizataApi.ml.testModel(streamJob.id)
      } catch (error) {
        hasError = true
        if (error.message) {
          const rawMessage = error?.original?.response?.data?.errors?.join('<br>') ?? error.message
          events.emit('app:notify', {
            type: 'error',
            title: 't/mlworkflow.title',
            duration: -1,
            rawMessage,
          })
        }
      }
    }

    if (!hasError) {
      const context = dbProvider.createBatchContext()
      await streamJob.prepareToggleActive(context)
      await dbProvider.batch(context)
    }
  }, [ streamJob ])

  const onPlayDiagram = useCallback(() => (
    wizataApi.ml.testModel(streamJob.id)
      .then(() => {
        events.emit('app:notify', {
          type: 'success',
          title: 't/mlworkflow.title',
          message: 't/mlworkflow.success.testModel',
        })
      })
      .catch((error) => {
        if (error?.message) {
          const rawMessage = error?.original?.response?.data?.errors?.join('<br>') ?? error.message
          events.emit('app:notify', {
            type: 'error',
            title: 't/mlworkflow.title',
            duration: -1,
            rawMessage,
          })
        }
      })
  ), [ streamJob ])

  const onDuplicate = useCallback(async (model) => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareDuplicateModel(context, model, {
      active: false,
    }, {
      uniqProps: [ 'name' ],
    })
    await dbProvider.batch(context)
    events.emit('app:notify', {
      type: 'success',
      title: 't/streamJobs.title',
      message: 't/streamJobs.successDuplication',
      duration: 2000,
    })
  }, [])

  return {
    blockFactory,
    onChangeDiagram,
    onClickCreate,
    onCopyBlock,
    onDeletedDiagram,
    onDropCreateDiagram,
    onLinkDiagram,
    onPaste,
    onPlayDiagram,
    onToggleActive,
    onToggleGrid,
    onDuplicate,
  }
})
