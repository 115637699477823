import UserAD from '@/store/models/UserAD'
import { createApi } from '../utils'

export default function users (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      users: {
        enumerable: true,
        value: Object.create({}, {
          avatar: {
            enumerable: true,
            value ({ id }) {
              return client.get(`/Users/${id}/avatar`)
                .fetch(true)
                .then((data) => {
                  if (data && data.indexOf('data&colon;') === 0) {
                    return data.replace(/^data&colon;/, 'data:')
                  }
                  return Promise.reject('Undefined format')
                })
            },
          },
          getById: {
            enumerable: true,
            value (id) {
              return client.get(`/Users/${id}`).fetch()
            },
          },
          updateById: {
            enumerable: true,
            value (id, params) {
              return client.put(`/Users/${id}`, { ...params }).fetch()
            },
          },
          getAvatar: {
            enumerable: true,
            value (id) {
              return client.get(`/Users/${id}/image`).fetch(true)
            },
          },
          setAvatar: {
            enumerable: true,
            value (id, params) {
              return client.put(`/Users/${id}/image`, { ...params }).fetch()
            },
          },
        }),
      },
    })

    Object.defineProperties(target.prototype, {
      adUsers: {
        enumerable: true,
        value: Object.create({}, createApi(client, 'IdentityUsers', UserAD)),
      },
    })
  }
}
