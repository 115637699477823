import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { FormFieldInline, FormControl } from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

const FieldCumulative = ({ scope }) => {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()
  return (
    <FormFieldInline
      label={intl.t('widgets.histogram.form.fields.cumulative')}
      description={intl.t('widgets.histogram.form.fields.cumulativeDescr')}
      errors={get(errors, `${scope}.cumulative`)}
    >
      <FormControl
        type="checkbox"
        name={`${scope}.cumulative`}
        defaultValue={consts.WidgetHistogramCumulative}
        className="ms-2"
      />
    </FormFieldInline>
  )
}

FieldCumulative.propTypes = {
  scope: PropTypes.string.isRequired,
}

export default FieldCumulative
