import { useState } from 'react'
import Select from '@/components/Form/Select'
import { useIntl } from '@wiz/intl'
import { consts } from '@wiz/utils'

const Filters = ({
  onFilter, chart, value, groupBy, groupByList = [], valuesList = [], isSingleValue,
}) => {
  const intl = useIntl()

  return (
    <div className="d-flex align-items-start flex-wrap my-4">
      <div className="px-2 mb-2 col-md-4">
        <Select
          placeholder={intl.t('form.fields.typePlaceholder')}
          options={consts.PanelChartOptions}
          value={chart}
          onChange={chart => onFilter({ chart })}
          size="sm"
          controlPrefix="Type"
          initialOptionStyle
          noRemove
          disabled={!!isSingleValue}
        />
      </div>
      <div className="px-2 mb-2 col-md-4">
        <Select
          placeholder={intl.t('form.fields.groupByPlaceholder')}
          options={groupByList}
          value={groupBy}
          onChange={groupBy => onFilter({ groupBy })}
          size="sm"
          controlPrefix="Group By"
          initialOptionStyle
          noRemove
          disabled={!!isSingleValue}
        />
      </div>
      <div className="px-2 mb-2 col-md-4">
        <Select
          placeholder={intl.t('form.fields.valuePlaceholder')}
          options={[ 'count' ].concat(valuesList).map(item => ({ id: item, name: intl.t(`form.fields.${item}`) }))}
          value={value}
          onChange={value => onFilter({ value })}
          size="sm"
          controlPrefix="Value"
          initialOptionStyle
          noRemove
          disabled={!valuesList?.length}
        />
      </div>
    </div>
  )
}

export default Filters
