import {
  FormSection,
  FormFieldInline,
  FormSelectColor,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'

export default function View ({
  settings,
  onChangeGlobalSetting
}) {
  const intl = useIntl()
  const auth = useAuth()

  return (
    <div className="flex-fill d-flex flex-column pb-3 min-h-0 px-3 px-sm-5 overflow-auto">
      <div className="d-flex flex-column container" style={{ maxWidth: 800 }}>
        <FormSection
          title={intl.t('settings.twin.titleColor')}
        >
          <FormFieldInline
            label={intl.t('settings.twin.form.fields.areaColor')}
            description={intl.t('settings.twin.form.fields.areaColorDescr')}
          >
            <FormSelectColor
              className="ms-2"
              value={settings.twinColorAreas}
              disabled={!auth.checkAccessManage('SectionDigitalTwinStyle')}
              onChange={value => onChangeGlobalSetting('twinColorAreas', value)}
            />
          </FormFieldInline>

          <FormFieldInline
            label={intl.t('settings.twin.form.fields.machineColor')}
            description={intl.t('settings.twin.form.fields.machineColorDescr')}
          >
            <FormSelectColor
              className="ms-2"
              value={settings.twinColorMachines}
              disabled={!auth.checkAccessManage('SectionDigitalTwinStyle')}
              onChange={value => onChangeGlobalSetting('twinColorMachines', value)}
            />
          </FormFieldInline>

          <FormFieldInline
            label={intl.t('settings.twin.form.fields.equipmentColor')}
            description={intl.t('settings.twin.form.fields.equipmentColorDescr')}
          >
            <FormSelectColor
              className="ms-2"
              value={settings.twinColorEquipment}
              disabled={!auth.checkAccessManage('SectionDigitalTwinStyle')}
              onChange={value => onChangeGlobalSetting('twinColorEquipment', value)}
            />
          </FormFieldInline>

          <FormFieldInline
            label={intl.t('settings.twin.form.fields.sensorHardwareColor')}
            description={intl.t('settings.twin.form.fields.sensorHardwareColorDescr')}
          >
            <FormSelectColor
              className="ms-2"
              value={settings.twinColorSensorsHardware}
              disabled={!auth.checkAccessManage('SectionDigitalTwinStyle')}
              onChange={value => onChangeGlobalSetting('twinColorSensorsHardware', value)}
            />
          </FormFieldInline>

          <FormFieldInline
            label={intl.t('settings.twin.form.fields.sensorVirtColor')}
            description={intl.t('settings.twin.form.fields.sensorVirtColorDescr')}
          >
            <FormSelectColor
              className="ms-2"
              value={settings.twinColorSensorsVirtual}
              disabled={!auth.checkAccessManage('SectionDigitalTwinStyle')}
              onChange={value => onChangeGlobalSetting('twinColorSensorsVirtual', value)}
            />
          </FormFieldInline>

          <FormFieldInline
            label={intl.t('settings.twin.form.fields.nestedTwinGraphColor')}
            description={intl.t('settings.twin.form.fields.nestedTwinGraphColorDescr')}
          >
            <FormSelectColor
              className="ms-2"
              value={settings.twinColorNestedTwinGraph}
              disabled={!auth.checkAccessManage('SectionDigitalTwinStyle')}
              onChange={value => onChangeGlobalSetting('twinColorNestedTwinGraph', value)}
            />
          </FormFieldInline>
        </FormSection>
      </div>
    </div>
  )
}
