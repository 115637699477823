import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { AccessRole as AccessRoleModel } from '@wiz/store'
import { FormSection } from '@wiz/components'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import resolver from './resolver'
import Schema from './Schema'
import Fields from './Fields'
import FieldsRoles from './FieldsRoles'
import SectionPermissionsThree from './SectionPermissionsThree'

export default function AccessRole ({
  id,
  accessRole,
  accessRoleIds,
  groupAccessRoleIds,
  dialog,
  onClose,
  onSubmit,
}) {
  const defaultValues = useMemo(() => ({
    accessRole: AccessRoleModel.toJSON(accessRole),
    accessRoleIds,
    groupAccessRoleIds,
  }), [
    accessRole?.updatedAt ?? accessRole,
    accessRoleIds,
    groupAccessRoleIds,
  ])

  if (id && !accessRole) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      schema={Schema}
      resolver={resolver}
      dialog={dialog}
      dataTestid="accessRoleForm"
    >
      <FormSection>
        <Fields scope="accessRole" />
        <FieldsRoles
          scope="groupAccessRoleIds"
          accessRoleScope="accessRole"
        />
      </FormSection>

      <SectionPermissionsThree
        scope="accessRoleIds"
        groupScope="groupAccessRoleIds"
      />
    </FormDialog>
  )
}

AccessRole.propTypes = {
  id: PropTypes.string,
  accessRole: PropTypes.object,
  accessRoleIds: PropTypes.arrayOf(PropTypes.string),
  groupAccessRoleIds: PropTypes.arrayOf(PropTypes.string),
  dialog: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

AccessRole.defaultProps = {
  id: undefined,
  accessRole: undefined,
  accessRoles: [],
  groupAccessRoleIds: [],
  dialog: undefined,
  onClose: undefined,
  onSubmit: undefined,
}
