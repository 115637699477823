import { useMemo, useState } from 'react'
import classnames from 'classnames'
import { VirtualTable, Pagination } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import { testJSON } from '@/utils/json'
import Icon from '@/shared/icon'
import EditButton from '@/shared/editButton'

export default function Table ({
  items,
  data,
  onFilter,
  onEdit,
  onRemove,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      maxWidth: 30,
      className: 'justify-content-center text-nowrap',
      Cell: ({ cell }) => (auth.checkAccessUpdate('Experiment') ? ( // FIXME: should use it's own Permission
        <EditButton cell={cell} onEdit={onEdit} />
      ) : null),
    },
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      minWidth: 200,
      Cell: ({ cell, row }) => {
        const allowed = auth.checkAccessUpdate('ExecutionScript')

        return (
          <div className="text-truncate min-w-0">
            <div className="d-flex align-items-center">
              <div
                className={classnames('text-truncate', { 'link pointer': allowed })}
                onClick={() => (allowed ? onEdit?.(row.original.id) : undefined)}
                aria-hidden
              >
                { cell.value }
              </div>
            </div>

            {row.original.description ? (
              <div className="font-italic text-muted text-truncate small">
                {row.original.description}
              </div>
            ) : null}
          </div>
        )
      },
    },
    {
      Header: intl.t('form.fields.status'),
      accessor: 'status',
      disableResizing: true,
      minWidth: 120,
      width: 120,
      maxWidth: 120,
    },
    {
      Header: intl.t('form.fields.inputColumns'),
      accessor: 'inputColumns',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 200,
      width: 240,
      maxWidth: 300,
      Cell: ({ cell }) => {
        const isJSON = testJSON(cell.value)
        const parsed = isJSON ? JSON.parse(cell.value) : cell.value
        return (
          <div className="text-truncate min-w-0">
            {isJSON ? parsed.join(', ') : cell.value}
          </div>
        )
      },
    },
    {
      Header: intl.t('form.fields.outputColumns'),
      accessor: 'outputColumns',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 200,
      width: 240,
      maxWidth: 300,
      Cell: ({ cell }) => {
        const isJSON = testJSON(cell.value)
        const parsed = isJSON ? JSON.parse(cell.value) : cell.value
        return (
          <div className="text-truncate min-w-0">
            {isJSON ? parsed.join(', ') : cell.value}
          </div>
        )
      },
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedDate',
      disableResizing: true,
      // disableSortBy: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.updatedById}
        />
      ),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdDate',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.createdById}
        />
      ),
    },
  ], [ onEdit, intl, auth ])

  return (
    <>
      <VirtualTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={data}
        selection={auth.checkAccessManage('SectionExecutionScripts')}
        {...props}
      />

      <Pagination {...props} relative />
    </>
  )
}
