import { intersection, type } from 'superstruct'
import { DataSource, DataView } from '@wiz/store'
import * as types from '@/utils/types'

export default type({
  dataView: type({
    userColor: DataView.schema.schema.userColor,
    checked: DataView.schema.schema.checked,
  }),
  dataSource: type({
    sensorId: intersection([
      types.Required(),
      DataSource.schema.schema.sensorId,
    ]),
    dateFrom: intersection([
      DataSource.schema.schema.dateFrom,
      types.SlidingDateFrom(),
    ]),
    dateTo: intersection([
      DataSource.schema.schema.dateTo,
      types.SlidingDateTo(),
    ]),
    duration: intersection([
      DataSource.schema.schema.duration,
      types.SlidingDuration(),
    ]),
    offsetDuration: DataSource.schema.schema.offsetDuration,
  }),
})
