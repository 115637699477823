import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'
import { auth } from '@/auth'
import { get } from '@wiz/utils'
import {
  FormField, FormControl, FormFieldInline, FormCheckbox,
} from '@wiz/components'
import FormTemplates from '@/components/Form/ListTemplates'
import { useIntl } from '@wiz/intl'
import SelectTwin from '@/components/Form/SelectTwin'
import useAuthComponents from '@/components/GrafanaDashboards/hooks'
import ListBusinessLabels from '@/components/Form/ListBusinessLabels'
import { wizataApi } from '@/api'

// {
//   "name": "string", !
//   "order": 0, !
//   "templateId": "string", !
//   "labelId": "string",
//   "twinId": "string",
//   "ownerId": "string"
// }
const DEFAULT_VALUE_REGEXP = /^now([+-]\d+([yMwdHhms]{1,2}))?$/
const URL_REGEXP = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}|https?:\/\/localhost:\d{1,5})/gi

export default function Fields ({
  scope, isIframe, isGrafana, isStreamlit,
}) {
  const intl = useIntl()
  const { formState: { errors }, watch } = useFormContext()
  const { isPersonalStrictly, isOrganizational } = useAuthComponents()
  // const { data: experiment, isLoading } = useQuery({
  //   queryKey: [ 'experimentById', experimentKey ],
  //   queryFn: () => wizataApi.experiments.getById(experimentKey),
  //   enabled: !!experimentKey,
  //   staleTime: Infinity,
  // })

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('components.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
        />
      </FormField>
      <FormField
        label={intl.t('form.fields.template')}
        description={intl.t('components.form.fields.templateDescr')}
        errors={get(errors, `${scope}.templateId`)}
      >
        <Controller
          name={`${scope}.templateId`}
          render={({ field, fieldState }) => (
            <FormTemplates
              {...field}
              invalid={fieldState.invalid}
              disabled={!auth.checkAccessUpdate('Template')}
              placeholder="--"
            />
          )}
        />
      </FormField>
      <FormField
        label={intl.t('form.fields.label')}
        description={intl.t('components.form.fields.labelDescr')}
        errors={get(errors, `${scope}.labelId`)}
      >
        <Controller
          name={`${scope}.labelId`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <ListBusinessLabels
              {...field}
              invalid={fieldState.invalid}
              placeholder="--"
            />
          )}
        />
      </FormField>
      {isGrafana ? (
        <>
          <FormField
            label={intl.t('form.fields.defaultFrom')}
            description={intl.t('components.form.fields.defaultFromDescr')}
            errors={get(errors, `${scope}.defaultFrom`)}
          >
            <FormControl
              name={`${scope}.defaultFrom`}
              rules={{
                pattern: DEFAULT_VALUE_REGEXP,
              }}
            />
          </FormField>
          <FormField
            label={intl.t('form.fields.defaultTo')}
            description={intl.t('components.form.fields.defaultToDescr')}
            errors={get(errors, `${scope}.defaultTo`)}
          >
            <FormControl
              name={`${scope}.defaultTo`}
              rules={{
                pattern: DEFAULT_VALUE_REGEXP,
              }}
            />
          </FormField>
          <FormField
            label={intl.t('form.fields.grafana')}
            description={intl.t('components.form.fields.grafanaDescr')}
            errors={get(errors, `${scope}.content`)}
          >
            <FormControl
              name={`${scope}.content`}
            />
          </FormField>
        </>
      ) : null}
      {isIframe ? (
        <FormField
          label={intl.t('form.fields.iframe')}
          description={intl.t('components.form.fields.iframeDescr')}
          errors={get(errors, `${scope}.content`)}
        >
          <FormControl
            name={`${scope}.content`}
            rules={{
              pattern: {
                value: URL_REGEXP,
                message: intl.t('form.errors.fieldURL'),
              },
            }}
          />
        </FormField>
      ) : null}
      {isStreamlit ? (
        <FormField
          label={intl.t('form.fields.streamlit')}
          description={intl.t('components.form.fields.StreamlitDescr')}
          errors={get(errors, `${scope}.content`)}
        >
          <FormControl
            name={`${scope}.content`}
            rules={{
              pattern: {
                value: URL_REGEXP,
                message: intl.t('form.errors.fieldURL'),
              },
            }}
          />
        </FormField>
      ) : null}
      <FormFieldInline
        label={intl.t('form.fields.organizational')}
        description={intl.t('form.fields.organizationalDescr')}
        errors={get(errors, `${scope}.isOrganizational`)}
      >
        <Controller
          shouldUnregister
          name={`${scope}.isOrganizational`}
          render={({ field, fieldState }) => (
            <FormCheckbox
              {...field}
              invalid={fieldState.invalid}
              className="ms-2"
              disabled={!isOrganizational}
            />
          )}
        />
      </FormFieldInline>
      {/* <FormField
        label={intl.t('form.fields.twin')}
        description={intl.t('components.form.fields.twinDescr')}
        errors={get(errors, scope)}
      >
        <Controller
          name={`${scope}.twinId`}
          render={({ field, fieldState }) => (
            <SelectTwin
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.twinPlaceholder')}
              templateId={templateId}
            />
          )}
        />
      </FormField>
      <FormField
        label={intl.t('form.fields.order')}
        description={intl.t('components.form.fields.orderDescr')}
        errors={get(errors, `${scope}.order`)}
      >
        <FormControl
          type="number"
          name={`${scope}.order`}
        />
      </FormField> */}
    </>
  )
}

Fields.propTypes = {
  scope: PropTypes.string.isRequired,
  isIframe: PropTypes.bool,
}

Fields.defaultProps = {
  isIframe: false,
}
