import { of as of$, distinctUntilChanged, combineLatest } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { isEqual, consts, uniq } from '@wiz/utils'
import observeSensorValue from '@/utils/observeSensorValue'
import Component from '../components/Location'

const enhanceData = withObservables([ 'config' ], ({ config }) => ({
  units: config?.units ? (
    combineLatest(
      dbProvider.database.collections.get('twins')
        .query(
          Q.where('id', Q.oneOf(config.units)),
          Q.where('type', Q.oneOf([
            consts.TwinType.Area,
            consts.TwinType.Machine,
            consts.TwinType.Equipment,
          ])),
          Q.where('latitude', Q.notEq(null)),
          Q.where('longitude', Q.notEq(null)),
        )
        .observeWithColumns([ 'updated_at' ]),

      dbProvider.database.collections.get('sensors')
        .query(
          Q.where('id', Q.oneOf(config.units)),
          Q.where('latitude', Q.notEq(null)),
          Q.where('longitude', Q.notEq(null)),
        )
        .observeWithColumns([ 'updated_at' ]),
    ).pipe(
      map(([ twins, sensors ]) => {
        const data = {}
        for (const item of twins) {
          data[item.id] = {
            name: item.displayName,
            icon: item.icon,
            color: item.color,
            type: item.type,
            latitude: item.latitude,
            longitude: item.longitude,
            latitudeSensorId: item.latitudeSensorId,
            longitudeSensorId: item.longitudeSensorId,
          }
        }
        for (const item of sensors) {
          data[item.id] = {
            name: item.displayName,
            icon: item.icon,
            color: item.color,
            type: 'sensor',
            latitude: item.latitude,
            longitude: item.longitude,
          }
        }
        return data
      }),
      distinctUntilChanged(isEqual),
    )
  ) : of$([]).pipe(distinctUntilChanged(isEqual)),

  locations: config?.units ? (
    dbProvider.database.collections.get('twins')
      .query(
        Q.where('id', Q.oneOf(config.units)),
        Q.where('type', Q.oneOf([
          consts.TwinType.Area,
          consts.TwinType.Machine,
          consts.TwinType.Equipment,
        ])),
        Q.where('latitude_sensor_id', Q.notEq(null)),
        Q.where('longitude_sensor_id', Q.notEq(null)),
      )
      .observeWithColumns([ 'latitude_sensor_id', 'longitude_sensor_id' ])
      .pipe(
        switchMap((items) => {
          const ids = uniq(items.reduce((out, item) => (
            out.concat(item.latitudeSensorId, item.longitudeSensorId)
          ), [])).filter(Boolean)
          return ids.length ? combineLatest(ids.map(id => observeSensorValue(id))) : of$([])
        }),
        map(items => items.filter(Boolean).reduce((out, item) => ({
          ...out,
          [item.sensorId]: item.value,
        }), {})),
        distinctUntilChanged(isEqual),
      )
  ) : of$([]).pipe(distinctUntilChanged(isEqual)),
}))

export default enhanceData(Component)
