import PropTypes from 'prop-types'
import { FormSection } from '@wiz/components'
import TimeSettings from '@/components/Form/TimeSettings'

export default function SectionFilters ({ scope }) {
  return (
    <FormSection>
      <TimeSettings
        dateFromFieldName={`${scope}.dateFrom`}
        dateToFieldName={`${scope}.dateTo`}
        durationFieldName={`${scope}.duration`}
        offsetDurationFieldName={`${scope}.offsetDuration`}
        stepRequestFieldName={`${scope}.stepRequest`}
      />
    </FormSection>
  )
}

SectionFilters.propTypes = {
  scope: PropTypes.string.isRequired,
}
