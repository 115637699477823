import { set, consts } from '@wiz/utils'
import { dbProvider, checkUniqueField } from '@wiz/store'
import { intl } from '@/i18n'

export default async function resolver (data) {
  const errors = {}
  const row = data?.twin || {}

  if (row.hardwareId) {
    const isUniqHardwareId = await checkUniqueField(
      row,
      'hardwareId',
      dbProvider.database.collections.get('twins').query(),
    )

    if (!isUniqHardwareId) {
      set(errors, 'twin.hardwareId', {
        type: 'unique',
        message: intl.t('twins.form.errors.hardwareIdExists'),
      })
    }
  }

  if (row.type === consts.TwinType.Flow) {
    if (!row.flowToId) {
      set(errors, 'twin.flowToId', {
        type: 'flowToRequired',
        message: intl.t('form.errors.fieldRequired'),
      })
    }

    if (!row.parentId) {
      set(errors, 'twin.parentId', {
        type: 'flowFromRequired',
        message: intl.t('form.errors.fieldRequired'),
      })
    }

    if (row.parentId && row.flowToId && row.parentId === row.flowToId) {
      set(errors, 'twin.flowToId', {
        type: 'fromEqualTo',
        message: intl.t('twins.form.errors.fromEqualTo'),
      })
    }
  }

  return {
    errors,
  }
}
