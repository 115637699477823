import PropTypes from 'prop-types'
import { useIntl } from '@wiz/intl'
import { Icon } from '@wiz/components'

export default function Sensors ({
  options,
  onOpenExplorer,
}) {
  const intl = useIntl()

  if (!options.length) {
    return null
  }

  const content = options.map(item => item.displayName).join(', ')
  const sensorIds = options.map(item => item.id)

  return (
    <button
      type="button"
      className="btn btn-link btn-sm text-reset border-0 p-0 justify-content-start"
      title={intl.t('form.actions.exploreData')}
      onClick={() => onOpenExplorer?.(sensorIds)}
    >
      <Icon
        name="fa--dot-circle"
        className="me-1"
        title={content}
      />

      <span className="text-truncate">
        {content}
      </span>
    </button>
  )
}

Sensors.propTypes = {
  options: PropTypes.array,
  onOpenExplorer: PropTypes.func,
}

Sensors.defaultProps = {
  options: [],
  onOpenExplorer: undefined,
}
