import { map } from 'rxjs/operators'
import { orderBy, toArrayValue } from '@wiz/utils'
import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'

export default withObservables([
  'search',
  'selectedOnly',
  'value',
], ({
  search,
  selectedOnly,
  value,
}) => {
  let query = dbProvider.database.collections.get('device_command_templates').query()

  if (search) {
    const sanitizeSearch = Q.sanitizeLikeString(search)
    query = query.extend(Q.or(
      Q.where('id', search),
      Q.where('name', Q.like(`%${sanitizeSearch}%`)),
      Q.where('topic', Q.like(`%${sanitizeSearch}%`)),
    ))
  }

  if (selectedOnly) {
    query = query.extend(Q.where('id', Q.oneOf(toArrayValue(value))))
  }

  const observe = selectedOnly ?
    query.observeWithColumns([ 'updated_at' ]) :
    query.observe()

  return {
    options: observe
      .pipe(
        map(items => orderBy(items, [ item => String(item.name).toLowerCase() ], [ 'asc' ])),
      ),
  }
})
