import { forwardRef, useMemo } from 'react'
import { useAuth } from '@/auth'
import RightBarInfo from '@/containers/StreamJob/RightBar/Info'
import RightBarShapes from '@/containers/StreamJob/RightBar/Shapes'
import RightBarLogs from '@/containers/StreamJob/RightBar/Logs'
import RightBarExplorer from '@/containers/RightBarExplorer'
import RightBarDiagram from '@/components/RightBarDiagram'

const RightBar = forwardRef((props, ref) => {
  const auth = useAuth()
  const { streamJob } = props

  const options = useMemo(() => [
    {
      id: 'logs',
      icon: 'ico--logs',
      title: 't/logs.titleMenu',
      component: RightBarLogs,
      access: () => auth.checkAccessRead('SectionStreamJobLogs'),
    },
    {
      id: 'shapes',
      icon: 'fa--shapes',
      title: 't/streamJobs.tooltipAddBlock',
      component: RightBarShapes,
      access: () => auth.checkAccessUpdate(streamJob),
    },
    {
      id: 'info',
      icon: 'fa--sensor',
      title: 't/sensors.titleLinked',
      canDisable: true,
      component: RightBarInfo,
    },
    {
      id: 'explorer',
      icon: 'fa--info-circle',
      hidden: true,
      component: RightBarExplorer,
      access: () => auth.checkAccessRead('SectionDataExplorer'),
    },
  ].filter(item => (!item.access || item.access())), [ auth, streamJob ])

  return (
    <RightBarDiagram
      ref={ref}
      {...props}
      options={options}
    />
  )
})

export default RightBar
