import { useIntl } from '@wiz/intl'
import { autolink } from '@/utils/string'

export default function SectionValidation ({
  job,
}) {
  const intl = useIntl()

  return (
    <>
      <div className="row">
        <div className="col">
          <h6>{intl.t('jobs.form.fields.result')}</h6>
          <pre className="overflow-auto small">
            {job.result ? (
            // eslint-disable-next-line react/no-danger
              <span dangerouslySetInnerHTML={{ __html: autolink(job.result, true) }} />
            ) : intl.t('form.nodata')}
          </pre>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h6>{intl.t('jobs.form.fields.result')}</h6>
          <pre className="overflow-auto small">
            {JSON.stringify(job.parameters, (key, value) => value, 2)}
          </pre>
        </div>
      </div>
    </>
  )
}
