import { useFormContext } from 'react-hook-form'
import { FormField, FormControl } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors }, register } = useFormContext()

  return (
    <>
      <input
        {...register(`${scope}.id`)}
        type="hidden"
      />

      <FormField
        label={intl.t('roles.form.fields.name')}
        description={intl.t('roles.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
        />
      </FormField>
    </>
  )
}
