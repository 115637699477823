import { withPagination } from '@wiz/components'
import Component from '@/components/StreamJobLogs/TableMobile'
import enhanceData from './enhanceData'
import enhanceProps from './enhanceProps'

export default enhanceData(
  enhanceProps(
    withPagination({ localData: false })(Component),
  ),
)
