import { useMemo } from 'react'
import { Card } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import Gauge from '../containers/Gauge'

export default function Widget ({
  widget,
  config,
  explorerContext,
  hidden,
  canUpdateDashboard,
  onAction,
}) {
  const intl = useIntl()
  const auth = useAuth()

  const sensor = useMemo(() => (
    explorerContext?.dataSources?.[0]?.sensor
  ), [ explorerContext ])

  let { title } = widget
  if (!title && sensor) {
    title = sensor.name
  }

  const subTitle = widget.title && sensor ?
    sensor.name : undefined

  if (!title && !subTitle) {
    title = intl.t(widget.info.name)
  }

  const actions = []
  if (canUpdateDashboard) {
    actions.push({ id: 'settings', name: intl.t('form.actions.settings'), icon: 'fa--cog' })
  }
  if (auth.checkAccessRead('SectionDataExplorer')) {
    actions.push({ id: 'dataExplorer', name: intl.t('form.actions.openExplorer'), icon: 'explorer' })
  }
  if (auth.checkAccessCopy(widget) && canUpdateDashboard) {
    actions.push({ id: 'duplicate', name: intl.t('form.actions.duplicate'), icon: 'fa--clone' })
    actions.push({ id: 'multiDuplicate', name: intl.t('form.actions.multiDuplicate'), icon: 'fa--folder-plus' })
  }
  if (canUpdateDashboard) {
    actions.push({ id: 'remove', name: intl.t('form.actions.remove'), icon: 'fa--trash-alt' })
  }

  return (
    <Card
      className="position-absolute-fill"
      classNameTitle={canUpdateDashboard ? 'userdrag' : undefined}
      title={title}
      description={widget.description}
      subTitle={subTitle}
      options={actions}
      onAction={onAction}
    >
      {do {
        if (sensor) {
          <Gauge
            widget={widget}
            hidden={hidden}
            sensor={sensor}
            config={config}
            conditions={explorerContext?.conditions}
          />
        } else if (canUpdateDashboard) {
          <div className="d-flex align-items-center justify-content-center flex-fill">
            <button
              type="button"
              className="btn btn-flat-secondary"
              onClick={() => onAction({ id: 'settings' })}
            >
              {intl.t('widgets.selectSensor')}
            </button>
          </div>
        } else {
          <div className="d-flex align-items-center justify-content-center flex-fill">
            {intl.t('widgets.notConfigured')}
          </div>
        }
      }}
    </Card>
  )
}
