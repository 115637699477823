import { useEffect } from 'react'
import { useAuth } from '@/auth'
import {
  useRouteMatch, useRouter, useLocation, useLocationQuery,
} from '@/router'
import { CorePages } from '@/utils/consts'
import Routes from '@/pages/routes.json'
import { validate } from 'uuid'
import { settingsRoutes } from '@/components/Header/settingsRoutes'

const routeFinder = (route) => {
  for (const routes of Routes) {
    if (routes.children?.length) {
      for (const children of routes.children) {
        if (children.attr.id === route) {
          return children.route.name
        }
        if (children.actions?.length) {
          for (const action of children.actions) {
            if (action.attr.id === route) {
              return action.route.name
            }
          }
        }
      }
    }
  }

  return null
}

const settingsRouteFinder = (route) => {
  for (const routes of settingsRoutes) {
    if (routes.actions?.length) {
      for (const action of routes.actions) {
        if (action.attr.id === route) {
          return action.route.name
        }
      }
    } else if (routes.attr.id === route) {
      return routes.route.name
    }
  }

  return null
}

export default function useDefaultRedirect (path) {
  const auth = useAuth()
  const router = useRouter()
  const location = useLocation()
  const query = useLocationQuery()
  const [ ,, corePage, currentPage ] = location.pathname.split('/')

  let match = useRouteMatch({
    path,
    exact: true,
  })

  if (!path) {
    match = true
  }

  const handleRedirect = () => {
    const route = { name: 'controlpanel' }
    if (auth.checkAccessRead('SectionDashboards') && corePage === 'dashboards') {
      route.name = 'dashboards'
      const hasId = validate(currentPage)
      if (hasId) {
        route.name = 'dashboard'
        route.params = { id: currentPage }
      }
    } else if (corePage === 'auth-docs') {
      route.name = 'auth-docs'
      route.query = query
    } else if (corePage === 'notifications') {
      route.name = 'notifications'
      route.query = query
    }
    router.replace(route, { originalPathname: location.pathname })
  }

  useEffect(() => {
    async function defaultRedirect () {
      const next = { name: 'controlpanel' }

      router.push(next, {
        originalPathname: location.pathname,
      })
    }

    if (match) {
      defaultRedirect()
    } else if (!Object.values(CorePages).includes(corePage)) {
      const nextRouteName = routeFinder(currentPage || corePage)
      const settingsRoute = settingsRouteFinder(currentPage || corePage)
      if (!settingsRoute) {
        if (nextRouteName) {
          router.replace({ name: nextRouteName, query }, { originalPathname: location.pathname })
        } else {
          handleRedirect()
        }
      }
    }
  }, [ match, auth, router ])
}
