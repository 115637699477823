import { useFormContext } from 'react-hook-form'
import { FormSection } from '@wiz/components'
import Fields from './Fields'
import FieldsExtra from './FieldsExtra'

export default function Section ({
  children,
  scope,
  disabled,
  registerId = true
}) {
  const { register } = useFormContext()

  return (
    <FormSection>
      {registerId ? (
        <input
          {...register(`${scope}.id`)}
          type="hidden"
        />
      ) : null}

      {children}

      <Fields
        scope={scope}
        disabled={disabled}
      />

      <FieldsExtra
        scope={scope}
        disabled={disabled}
      />
    </FormSection>
  )
}
