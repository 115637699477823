import PropTypes from 'prop-types'
import { FormSection } from '@wiz/components'
import FieldsData from './FieldsData'

export default function SectionData ({ scope, title, description }) {
  return (
    <FormSection title={title} description={description}>
      <FieldsData scope={scope} />
    </FormSection>
  )
}

SectionData.propTypes = {
  scope: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
}

SectionData.defaultProps = {
  title: undefined,
  description: undefined,
}
