import difference from 'lodash/difference'
import { set } from '@wiz/utils'
import { Q, dbProvider } from '@wiz/store'
import { intl } from '@/i18n'

async function checkQualityRecords (labels) {
  if (!labels.length) {
    return false
  }

  const count = await dbProvider.database.collections.get('quality_data')
    .query(
      Q.experimentalNestedJoin(
        'quality_data_templates',
        'quality_data_template_measurements',
      ),
      Q.on(
        'quality_data_templates',
        Q.on('quality_data_template_measurements', 'label_id', Q.oneOf(labels)),
      ),
    )
    .fetchCount()

  return count > 0
}

export default async function resolver (data, context) {
  const errors = {}
  const selected = data?.selected || []
  const sensorData = data?.sensors || {}
  const sensors = context?.sensors || []

  if (selected.includes('labels')) {
    const nextLabels = sensorData.labels || []
    for (const sensor of sensors) {
      const prevLabels = (await sensor.queryLabels.fetch()).map(item => item.id)
      const removedLabels = difference(prevLabels, nextLabels)
      const createdLabels = difference(nextLabels, prevLabels)
      const hasRemovedLabelsQR = await checkQualityRecords(removedLabels)
      const hasCreatedLabelsQR = await checkQualityRecords(createdLabels)

      if (hasCreatedLabelsQR) {
        set(errors, 'sensors.labels', {
          type: 'addLabelWithQualityData',
          message: intl.t('sensors.form.errors.addLabelWithQualityData'),
        })
        break
      } else if (hasRemovedLabelsQR) {
        set(errors, 'sensors.labels', {
          type: 'removeLabelWithQualityData',
          message: intl.t('sensors.form.errors.removeLabelWithQualityData'),
        })
        break
      }
    }
  }

  return {
    errors,
  }
}
