import {
  any,
  assign,
  intersection,
  type,
} from 'superstruct'
import { DataFilter } from '@wiz/store'
import SchemaWidget from '@/components/Forms/Widget/Schema'
import * as types from '@/utils/types'
import SchemaConfig from './SchemaConfig'

export default type({
  widget: SchemaWidget,
  config: assign(
    SchemaConfig,
    type({
      dataViews: any(),
      dataSources: any(),
      dataFilter: type({
        dateFrom: intersection([
          DataFilter.schema.schema.dateFrom,
          types.SlidingDateFromRequired(),
          types.SlidingDateFrom(),
        ]),
        dateTo: intersection([
          DataFilter.schema.schema.dateTo,
          types.SlidingDateFromRequired(),
          types.SlidingDateTo(),
        ]),
        duration: intersection([
          DataFilter.schema.schema.duration,
          types.SlidingDurationRequired(),
          types.SlidingDuration(),
        ]),
        offsetDuration: DataFilter.schema.schema.offsetDuration,
        stepRequest: DataFilter.schema.schema.stepRequest,
      }),
    }),
  ),
})
