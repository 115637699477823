import { Icon, FormSection } from '@wiz/components'
import { Dashboard } from '@wiz/store'
import { useIntl } from '@wiz/intl'

const TwinWidgets = [ 'sensor', 'plotly', 'tableData' ]
const BlockWidgets = Dashboard.availableWidgets()
  .filter(item => TwinWidgets.includes(item.type))

export default function SectionEmpty ({ onCreateWidget, ...props }) {
  const intl = useIntl()

  return (
    <FormSection {...props}>
      <div className="text-center mb-3 fs-6">
        <div className="h6">{intl.t('form.sections.createNewWidget')}</div>
        {intl.t('form.sections.createNewWidgetDescr')}
      </div>

      {BlockWidgets.map(item => (
        <button
          key={item.type}
          type="button"
          className="btn btn-fill-secondary flex-column px-3 m-1"
          onClick={() => onCreateWidget?.(item.type)}
        >
          <Icon name={item.icon} size="2X" className="m-1" />
          <div>
            {intl.t(item.name)}
          </div>
        </button>
      ))}
    </FormSection>
  )
}
