import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { withObservables } from '@wiz/components'

export default withObservables([ 'twin' ], ({ twin }) => ({
  dashboards: twin?.queryRelDashboards ? (
    twin.queryRelDashboards
      .observe()
      .pipe(
        map(items => items.map(item => item.dashboardId)),
      )
  ) : of$([]),

  explorers: twin?.queryRelExplorers ? (
    twin.queryRelExplorers
      .observe()
      .pipe(
        map(items => items.map(item => item.explorerId)),
      )
  ) : of$([]),

  streamJobs: twin?.queryRelStreamJobs ? (
    twin.queryRelStreamJobs
      .observe()
      .pipe(
        map(items => items.map(item => item.streamJobId)),
      )
  ) : of$([]),

  twinGraphs: twin?.queryRelTwinGraphs ? (
    twin.queryRelTwinGraphs
      .observe()
      .pipe(
        map(items => items.map(item => item.twinGraphId)),
      )
  ) : of$([]),
}))
