import { memo } from 'react'
import Component from '@/components/Forms/Sensor'
import enhanceSensor from './enhanceSensor'
import enhanceLabels from './enhanceLabels'
import enhanceProps from './enhanceProps'

export default memo(
  enhanceSensor(
    enhanceLabels(
      enhanceProps(Component),
    ),
  ),
)
