import { useMemo } from 'react'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import SectionNotebook from './SectionNotebook'
import Section from './Section'
import Removed from '../Removed'

export default function BGJobNotebook ({
  id,
  bgJob,
  projects,
  dialog,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()
  const disabled = !!id
  const immutableProjects = JSON.stringify(projects)
  const defaultValues = useMemo(() => ({
    bgJob,
    projects,
  }), [
    bgJob?.updatedAt ?? bgJob,
    immutableProjects,
  ])

  if (id && !bgJob?.id) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="bgJobNotebookForm"
      dialog={dialog}
    >
      <Section
        scope="bgJob"
        disabled={disabled}
      />

      <SectionNotebook
        title={intl.t('form.fields.notebook')}
        scope="bgJob.parameters.notebook"
        disabled={disabled}
        registerId={false}
      />
    </FormDialog>
  )
}
