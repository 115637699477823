import { useEffect } from 'react'
import { useRouter } from '@/router'

export default function Logoutcb () {
  const router = useRouter()

  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('logout')
      window.setTimeout(() => {
        router.push({ name: 'login' })
      }, 1000)
    } else {
      router.push({ name: 'login' })
    }
  }, [ router ])

  return null
}
