import {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react'
import {
  withObservables,
  withProps,
  withPagination,
} from '@wiz/components'
import observeStreamJobLogs from '@/utils/observeStreamJobLogs'
import useFilters from '@/hooks/useFilters'
import Component from '@/components/StreamJobs/RightBar/Logs'

const DefaultPage = 1
const DefaultPageSize = 20

function initFilters (query = {}) {
  return {
    _: query._ || undefined,
    page: Number(query.page) || DefaultPage,
    pageSize: Number(query.pageSize) || DefaultPageSize,
    streamJobId: query.streamJobId,
  }
}

const enhanceFilters = withProps(({ streamJob }) => {
  const [ sourceFilters, setSourceFilters ] = useState(initFilters({
    streamJobId: streamJob.blockId,
  }))
  const {
    filters,
    onFilter,
    forceUpdate,
  } = useFilters({
    initFilters,
    source: sourceFilters,
    setSource: setSourceFilters,
  })

  const onPagination = useCallback((page, pageSize) => {
    onFilter({ page, pageSize })
  }, [ onFilter ])

  const onResetFilter = useCallback(() => {
    setSourceFilters(initFilters({
      streamJobId: streamJob.blockId,
    }))
  }, [ streamJob ])

  const onTableFilter = useCallback((data) => {
    let next

    if (data) {
      next = {
        ...data,
        page: DefaultPage,
      }
    }

    onFilter(next)
  }, [ onFilter ])

  useEffect(() => {
    onResetFilter()
  }, [ onResetFilter ])

  return {
    DefaultPage,
    DefaultPageSize,
    filters,
    onFilter: onTableFilter,
    forceUpdate,
    onResetFilter,
    onPagination,
  }
})

const enhanceData = withObservables([
  'filters',
], ({
  filters,
}) => {
  const limit = filters.pageSize + 1
  const offset = (filters.page - 1) * filters.pageSize
  return {
    items: observeStreamJobLogs({
      streamJobId: filters.streamJobId,
      limit,
      offset,
    }),
  }
})

const enhanceProps = withProps(({
  items,
  filters,
}) => {
  const refData = useRef([])
  const [ loading, setLoading ] = useState(false)
  const offset = (filters.page - 1) * filters.pageSize

  useEffect(() => {
    setLoading(!items)
    if (items) {
      refData.current = items
    }
  }, [ items ])

  const nextItems = items || refData.current
  return {
    total: offset + nextItems.length,
    items: nextItems.slice(0, filters.pageSize),
    loading,
  }
})

export default enhanceFilters(
  enhanceData(
    enhanceProps(
      withPagination({ localData: false })(Component),
    ),
  ),
)
