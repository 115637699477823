import { useMemo } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { Sensor as Sensor$ } from '@wiz/store'
import { wizataApi } from '@/api'
import FormDialog from '@/components/Form/FormDialog'
import { Icon } from '@wiz/components'
import { testJSON } from '@/utils/json'
import events from '@/utils/events'
import { uuid } from '@wiz/utils'
import Removed from '../Removed'
import Section from './Section'
import Schema from './Schema'
import resolver from './resolver'

export default function Sensor ({
  id,
  // sensor,
  labels,
  dialog,
  onClose,
  onSubmit,
}) {
  const queryClient = useQueryClient()

  const { data: item, isLoading } = useQuery({
    queryKey: [ 'sensor', id ],
    queryFn: () => wizataApi.sensors.getById(id),
    enabled: !!id,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  })

  const {
    mutateAsync: mutateDatapoint,
  } = useMutation({
    mutationKey: [ 'mutateDatapoint' ],
    mutationFn: (data) => {
      if (!id) {
        return wizataApi.sensors.create({ ...data, id: uuid() })
      }
      return wizataApi.sensors.update(data)
    },
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/sensors.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: (payload) => {
      // const next = {
      //   sensor: {
      //     ...payload,
      //   },
      // }
      // onSubmit?.(next)
      queryClient.invalidateQueries({ queryKey: [ 'sensor', id ] })
      queryClient.invalidateQueries({ queryKey: [ 'sensorsPagedList' ] })
    },
  })

  const handleSubmit = async ({ sensor, labels }) => {
    const next = { ...sensor, labelIds: labels }
    if (!sensor.extraProperties || !testJSON(sensor.extraProperties)) {
      next.extraProperties = JSON.stringify({})
    }
    await mutateDatapoint(next)
  }

  const defaultValues = useMemo(() => ({
    sensor: {
      ...Sensor$.toJSON(item),
      extraProperties: item?.extraProperties,
      groupSystemId: item?.groupSystemId,
    },
    labels: item?.labelIds,
  }), [
    item?.updatedDate ?? item,
    labels,
  ])

  if (id && isLoading) {
    return (
      <div className="position-absolute-fill position-center-fill bg-light opacity-50">
        <Icon name="fa--spinner" size="2X" spin />
      </div>
    )
  }

  if (id && !item) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={handleSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="sensorForm"
      dialog={dialog}
      resolver={resolver}
      schema={Schema}
    >
      <Section
        scope="sensor"
      />
    </FormDialog>
  )
}
