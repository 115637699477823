import {
  forwardRef,
  useRef,
  useMemo,
} from 'react'
import classnames from 'classnames'
import { Icon, DropdownMenu, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import UserList from '@/hoc/UserList'
import Select from '@/components/Form/Select'

function TwinGraphUserList (WrappedComponent) {
  const Component = UserList(WrappedComponent)
  return forwardRef((props, ref) => (
    <Component ref={ref} {...props} withTwinGraphs />
  ))
}

const FilterByList = [
  'favorite',
  'my',
]

export default function Filters ({
  filters,
  onFilter,
}) {
  const refSort = useRef()
  const refFilter = useRef()
  const intl = useIntl()
  const isMobile = useMobile()

  const filterOptions = useMemo(() => FilterByList.map(id => ({
    id,
    name: intl.t(`dashboards.enum.filters.${id}`),
    active: filters.filterBy === id,
  })), [ intl, filters ])

  const sortOptions = useMemo(() => [
    {
      id: 'opened',
      name: intl.t('dashboards.enum.sort.opened'),
      icon: 'fa--sort-amount-down-alt',
      active: filters.sortBy === 'opened',
    },
    {
      id: 'alpha-down',
      name: intl.t('dashboards.enum.sort.alpha'),
      icon: 'fa--sort-alpha-down',
      active: filters.sortBy === 'alpha-down',
    },
  ], [ intl, filters ])

  const sortCurrent = useMemo(() => (
    sortOptions.find(item => item.id === filters.sortBy)
  ), [ sortOptions, filters ])

  const filterCurrent = useMemo(() => (
    filters.filterBy && filterOptions.find(item => item.id === filters.filterBy)
  ), [ filterOptions, filters ])

  const classNameButton = 'btn btn-sm btn-text btn-flat-secondary mx-1 mb-2 text-nowrap border-0'

  return (
    <>
      <button
        ref={refSort}
        type="button"
        className={classNameButton}
      >
        <Icon name={sortCurrent.icon} />
      </button>

      <DropdownMenu
        arrow
        autoclose
        target={refSort}
        options={sortOptions}
        onClick={data => onFilter({ sortBy: data.id })}
      />

      {isMobile ? (
        <>
          <button
            ref={refFilter}
            type="button"
            className={classnames(classNameButton, {
              active: !!filterCurrent,
            })}
          >
            {filterCurrent?.name || intl.t('form.actions.filters')}
          </button>

          <DropdownMenu
            arrow
            autoclose
            target={refFilter}
            options={filterOptions}
            onClick={data => onFilter({ filterBy: data.active ? null : data.id })}
          />
        </>
      ) : (
        <>
          {filterOptions.map(item => (
            <button
              key={item.id}
              type="button"
              className={classnames(classNameButton, {
                active: item.active,
              })}
              onClick={() => onFilter({
                filterBy: item.active ? null : item.id,
              })}
            >
              {item.name}
            </button>
          ))}
        </>
      )}

      <Select
        className={classnames(classNameButton, {
          active: !!filters.followedBy,
        })}
        placeholder={intl.t('dashboards.form.fields.createdByUserPlaceholder')}
        value={filters.followedBy}
        ListContent={({ data }) => (
          <>
            {data.name}
            {data.name !== data.email ? (
              <span className="ms-2 text-muted">
                {'- '}
                {data.email}
              </span>
            ) : null}
          </>
        )}
        ListSource={TwinGraphUserList}
        onChange={followedBy => onFilter({ followedBy })}
        initialStyle
        initialOptionStyle
      />
    </>
  )
}
