import { Badge } from '@wiz/components'

const ListBadges = ({ options, onRemove }) => options.map(item => (
  <Badge
    key={item.id}
    className="bg-info me-1 mb-1"
    onClose={() => onRemove?.(item.id)}
  >
    {item.name}
  </Badge>
))

export default ListBadges
