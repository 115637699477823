export default function library (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      plots: {
        enumerable: true,
        value: Object.create({}, {
          getListByExecutionId: {
            enumerable: true,
            value (id) {
              return client.get(`/plots/?generatedById=${id}`).fetch()
            },
          },

          getById: {
            enumerable: true,
            value (id) {
              return client.get(`/plots/${id}`).fetch()
            },
          },
        }),
      },
      dataframes: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value () {
              return client.get('/dataframes').fetch()
            },
          },
          getById: {
            enumerable: true,
            value (id, format = 'json-data') { // json-data || csv
              return client.get(`/dataframes/${id}/?format=${format}`).fetch()
            },
          },
        }),
      },
      scripts: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value () {
              return client.get('/scripts').fetch()
            },
          },
        }),
      },
      mlmodels: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value (params) {
              const {
                generatedById, experimentId, key, templateId,
              } = params || {}
              let query = ''
              if (generatedById) {
                query += `generatedById=${generatedById}&`
              }
              if (experimentId) {
                query += `experimentId=${experimentId}&`
              }
              if (templateId) {
                query += `templateId=${templateId}&`
              }
              if (key) {
                query += `key=${key}`
              }
              return client.get(`/mlmodels/?${query}`).fetch()
            },
          },
        }),
      },
    })
  }
}
