import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import { get } from '@wiz/utils'
import {
  FormField, FormControl, FormFieldInline, FormCheckbox,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('scripts.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          // rules={{
          //   validate: (value) => {
          //     const str = String(value || '').trim()
          //     if (!str.length) {
          //       return intl.t('form.errors.fieldRequired')
          //     }
          //     if (value.length > 450) {
          //       return intl.t('form.errors.fieldMaxlen', { max: 450 })
          //     }
          //     return true
          //   },
          // }}
          disabled
        />
      </FormField>
      <FormField
        label={intl.t('form.fields.description')}
        description={intl.t('scripts.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          name={`${scope}.description`}
          disabled
        />
      </FormField>
      {/* <FormField
        label={intl.t('form.fields.generatedBy')}
        description={intl.t('scripts.form.fields.generatedByDescr')}
        errors={get(errors, `${scope}.generatedById`)}
      >
        <FormControl
          name={`${scope}.generatedById`}
          disabled
        />
      </FormField> */}
      <FormFieldInline
        label={intl.t('entities.form.fields.canGenerateData')}
        description={intl.t('entities.form.fields.canGenerateDataDescr')}
        errors={get(errors, `${scope}.canGenerateData`)}
      >
        <Controller
          shouldUnregister
          name={`${scope}.canGenerateData`}
          render={({ field, fieldState }) => (
            <FormCheckbox
              {...field}
              invalid={fieldState.invalid}
              className="ms-2"
              disabled
              onChange={() => {
              }}
            />
          )}
        />
      </FormFieldInline>
      <FormFieldInline
        label={intl.t('entities.form.fields.canGenerateModel')}
        description={intl.t('entities.form.fields.canGenerateModelDescr')}
        errors={get(errors, `${scope}.canGenerateModel`)}
      >
        <Controller
          shouldUnregister
          name={`${scope}.canGenerateModel`}
          render={({ field, fieldState }) => (
            <FormCheckbox
              {...field}
              invalid={fieldState.invalid}
              className="ms-2"
              disabled
              onChange={() => {
              }}
            />
          )}
        />
      </FormFieldInline>
      <FormFieldInline
        label={intl.t('entities.form.fields.canGeneratePlot')}
        description={intl.t('entities.form.fields.canGeneratePlotDescr')}
        errors={get(errors, `${scope}.canGeneratePlot`)}
      >
        <Controller
          shouldUnregister
          name={`${scope}.canGeneratePlot`}
          render={({ field, fieldState }) => (
            <FormCheckbox
              {...field}
              invalid={fieldState.invalid}
              className="ms-2"
              disabled
              onChange={() => {
              }}
            />
          )}
        />
      </FormFieldInline>
      <FormFieldInline
        label={intl.t('entities.form.fields.needExactColumnNames')}
        description={intl.t('entities.form.fields.needExactColumnNamesDescr')}
        errors={get(errors, `${scope}.needExactColumnNames`)}
      >
        <Controller
          shouldUnregister
          name={`${scope}.needExactColumnNames`}
          render={({ field, fieldState }) => (
            <FormCheckbox
              {...field}
              invalid={fieldState.invalid}
              className="ms-2"
              disabled
              onChange={() => {
              }}
            />
          )}
        />
      </FormFieldInline>
      <FormFieldInline
        label={intl.t('entities.form.fields.needExactColumnNumbers')}
        description={intl.t('entities.form.fields.needExactColumnNumbersDescr')}
        errors={get(errors, `${scope}.needExactColumnNumbers`)}
      >
        <Controller
          shouldUnregister
          name={`${scope}.needExactColumnNumbers`}
          render={({ field, fieldState }) => (
            <FormCheckbox
              {...field}
              invalid={fieldState.invalid}
              className="ms-2"
              disabled
              onChange={() => {
              }}
            />
          )}
        />
      </FormFieldInline>
      <FormField
        label={intl.t('entities.form.fields.inputColumns')}
        description={intl.t('entities.form.fields.inputColumnsDescr')}
        errors={get(errors, `${scope}.inputColumns`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.inputColumns`}
          rows={10}
          disabled
        />
      </FormField>
      <FormField
        label={intl.t('entities.form.fields.outputColumns')}
        description={intl.t('entities.form.fields.outputColumnsDescr')}
        errors={get(errors, `${scope}.outputColumns`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.outputColumns`}
          rows={10}
          disabled
        />
      </FormField>
    </>
  )
}

Fields.propTypes = {
  scope: PropTypes.string.isRequired,
}
