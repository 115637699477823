import { useEffect, useMemo } from 'react'
import classnames from 'classnames'
import {
  VirtualTable, Pagination, Icon as OldIcon,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import useAppContext from '@/hooks/useAppContext'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import Icon from '@/shared/icon'
import EditButton from '@/shared/editButton'
import CellCategory from './CellCategory'

const CellUpdated = ({ cell, row }) => (
  <UpdatedInfo
    userId={row.original.updatedById}
    date={cell.value}
    user={row.original.updatedUser}
  />
)

const CellCreated = ({ cell, row }) => (
  <UpdatedInfo
    userId={row.original.createdById}
    date={cell.value}
    user={row.original.createdUser}
  />
)

export default function Table ({
  items,
  onRemove,
  onEdit,
  onFilter,
  filters,
  refetch,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const { onRowSelect } = useAppContext()

  const columns = useMemo(() => [
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      width: 30,
      maxWidth: 30,
      className: 'justify-content-center text-nowrap',
      Cell: ({ cell }) => (auth.checkAccessUpdate('Unit') ? (
        <EditButton cell={cell} onEdit={onEdit} />
      ) : null),
    },
    {
      Header: intl.t('units.form.fields.fullName'),
      accessor: 'fullName',
      minWidth: 200,
      Cell: ({ cell, row }) => {
        const allowed = auth.checkAccessUpdate(row.original)

        return (
          <a
            className={classnames('d-flex align-items-center min-w-0', {
              'link pointer': allowed,
            })}
            onClick={() => (allowed ? onEdit?.(row.original.id) : undefined)}
            aria-hidden
          >
            {(row.original.icon || row.original.color) && row.original?.icon?.includes('cus-') ? (
              <OldIcon
                name={row.original.icon}
                color={row.original.color}
                className="me-2"
              />
            ) : (
              <Icon
                name={row.original.icon}
                color={row.original.color}
                className="me-2"
              />
            )}

            <div className="text-truncate">
              {cell.value}
            </div>
          </a>
        )
      },
    },
    {
      Header: intl.t('units.form.fields.shortName'),
      accessor: 'shortName',
      minWidth: 100,
    },
    {
      Header: intl.t('units.form.fields.symbol'),
      accessor: 'symbol',
      minWidth: 50,
    },
    {
      Header: intl.t('units.form.fields.category'),
      accessor: 'categoryId',
      minWidth: 100,
      Cell: CellCategory,
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedDate',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 220,
      Cell: CellUpdated,
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdDate',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 220,
      Cell: CellCreated,
    },
  ], [ onEdit, intl, auth ])

  useEffect(() => {
    if (items && !props.loading) {
      refetch()
    }
  }, [
    filters.search,
    filters.page,
    filters.pageSize,
    filters.sortDir,
    filters.sortBy,
  ])

  return (
    <>
      <VirtualTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={(
          auth.checkAccessRemove('Unit') ||
          auth.checkAccessUpdate('Unit')
        )}
        {...props}
        onSelect={onRowSelect}
      />

      <Pagination {...props} />
    </>
  )
}
