import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Twin } from '@wiz/store'
import { FormInputSearch } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import ListDashboardsByTwin from '@/containers/Form/ListDashboardsByTwin'

export default function RightBarLinked ({
  blockSettings,
  onChange,
}) {
  const intl = useIntl()
  const auth = useAuth()
  const [ search, setSearch ] = useState('')

  const twins = useMemo(() => (
    Object.values(blockSettings)
      .filter(item => Twin.is(item))
  ), [ blockSettings ])

  return (
    <>
      <div className="my-2 mx-3">
        <FormInputSearch
          size="sm"
          placeholder={intl.t('form.actions.searchPlaceholder')}
          onChange={setSearch}
        />
      </div>

      {auth.checkAccessRead('SectionDashboards') ? (
        <ListDashboardsByTwin
          className={classnames('flex-fill min-h-0 h-100 overflow-hidden')}
          twins={twins}
          search={search}
          onSelect={model => onChange('dashboard', {
            title: model.title,
            props: {
              id: model.id,
            },
          })}
        />
      ) : null}

    </>
  )
}

RightBarLinked.propTypes = {
  blockSettings: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
}
