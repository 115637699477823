import { useCallback } from 'react'
import { withProps } from '@wiz/components'
import { useRouter } from '@/router'
import { wizataApi } from '@/api'
import events from '@/utils/events'
import withTableActions from '@/containers/withTableActions'
import Component from '@/components/Notebook/List'

const enhanceProps = withProps(() => {
  const router = useRouter()

  const onExport = useCallback(async (model) => {
    const file = await wizataApi.notebookExportRequest(model.id).fetch()

    if (file) {
      events.emit('app:sync')
      router.push({
        name: 'exportresult-list',
        query: {
          search: file.id,
        },
      })
    }
  }, [ router ])

  return {
    onExport,
  }
})

export default withTableActions()(
  enhanceProps(Component),
)
