import { useState, useCallback } from 'react'
import { withProps, useDrag } from '@wiz/components'

export default withProps(({
  onDragEnd,
  onDragStart,
  onDrop,
}) => {
  const drag = useDrag()
  const [ forceUpdate, setForceUpdate ] = useState({})

  const handleDragStart = useCallback(async (data, twin) => {
    await onDragStart?.(data, twin)
    setForceUpdate({})
  }, [ onDragStart ])

  const handleDragEnd = useCallback(async (data) => {
    drag.clear()
    await onDragEnd?.(data)
    setForceUpdate({})
  }, [ drag, onDragEnd ])

  const handleDrop = useCallback(async (type, data) => {
    await onDrop?.(type, data, Array.from(drag.data))
    setForceUpdate({})
  }, [ drag, onDrop ])

  const checkDragged = useCallback((data) => {
    if (drag) {
      for (const item of drag.data) {
        // "id1/id2/id3".indefOf("id1/id2")
        if (data.id.indexOf(item.id) === 0) {
          return true
        }
      }
    }
    return false
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ forceUpdate ])

  return {
    checkDragged,
    onDragStart: handleDragStart,
    onDragEnd: handleDragEnd,
    onDrop: handleDrop,
  }
})
