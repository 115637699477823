import { useState } from 'react'
import classnames from 'classnames'
import { Tabs } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import WidgetSection from '../Widget/Section'
import SectionData from './SectionData'
import SectionFilters from './SectionFilters'
import SectionView from './SectionView'

export default function WidgetPlotly ({
  defaultValues,
  dialog,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()
  const [ tab, setTab ] = useState('data')

  return (
    <FormDialog
      classNameBody="overflow-hidden p-0"
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="widgetPlotlyForm"
      dialog={dialog}
    >
      <Tabs
        className="mx-3"
        value={tab}
        options={[
          { value: 'data', label: intl.t('widgets.chart.form.fields.dataSourceSection') },
          { value: 'filters', label: intl.t('widgets.chart.form.fields.filtersSection') },
          { value: 'view', label: intl.t('widgets.chart.form.fields.viewSection') },
        ]}
        onChange={setTab}
      />

      <div
        className={classnames('min-h-0 overflow-auto px-3 flex-fill d-flex flex-column', {
          'd-none': tab !== 'data',
        })}
      >
        <SectionData scope="config" />
      </div>

      <div
        className={classnames('min-h-0 overflow-auto px-3 flex-fill d-flex flex-column', {
          'd-none': tab !== 'filters',
        })}
      >
        <SectionFilters scope="config" />
      </div>

      <div
        className={classnames('min-h-0 overflow-auto px-3 flex-fill d-flex flex-column', {
          'd-none': tab !== 'view',
        })}
      >
        <WidgetSection scope="widget" />
        <SectionView scope="config" />
      </div>

    </FormDialog>
  )
}
