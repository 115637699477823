/* eslint-disable no-continue */
import {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react'
import _ from 'lodash'
import * as far from '@awesome.me/kit-9065c4792f/icons/classic/solid'
// import { far } from '@awesome.me/kit-9065c4792f/icons'
import { withProps, useDrag } from '@wiz/components'
import { getIconByName } from '@wiz/icons'
import {
  dbProvider,
  Q,
  Twin,
  DiagramState,
  duplicateActionsIterator,
  duplicateActionsPairsIterator,
} from '@wiz/store'
import {
  consts,
  mergeElementLocations,
  mergeGraphState,
  sanitizeGraphState,
} from '@wiz/utils'
import events from '@/utils/events'
import useAppContext from '@/hooks/useAppContext'

const ColorByType = {
  area: 'twinColorAreas',
  machine: 'twinColorMachines',
  equipment: 'twinColorEquipment',
}

const enhanceDiagramProps = withProps(({
  defaultSelected,
  diagramState,
  rootTwin,
  twinsChain,
  twinSettings,
}) => {
  const drag = useDrag()
  const refState = useRef(diagramState)
  const refStateIgnore = useRef(false)

  const { linkMode, setLinkMode } = useAppContext()

  const rootTwinDiagram = useMemo(() => (
    twinsChain[Math.max(defaultSelected ? twinsChain.length - 2 : 0, 0)]
  ), [ defaultSelected, twinsChain ])

  const blockFactory = useCallback(async (block) => {
    const color = block.color || twinSettings[ColorByType[block.type]]
    let icon = 'Rectangle'
    let size = '40 40'
    if (block.icon?.indexOf('--') === -1) {
      const f = far[block.icon]
      icon = _.startCase(`fa--pro--${f.iconName}`).replaceAll(' ', '')

      return {
        ...block,
        icon,
        size,
        color,
      }
    }
    const iconSource = block.icon && getIconByName(block.icon)

    if (iconSource) {
      icon = iconSource.sprite

      if (icon?.includes('Cus')) {
        size = '70 70'
      }
    }

    return {
      ...block,
      icon,
      size,
      color,
    }
  }, [ twinSettings ])

  const onChangeDiagram = useCallback(async (data) => {
    refStateIgnore.current = true
    const sanitizeState = sanitizeGraphState(data)
    refState.current = mergeGraphState(refState.current, sanitizeState)
    const context = dbProvider.createBatchContext()

    await DiagramState.prepareReplacePhysicalTwinDiagramState(
      context,
      dbProvider.database,
      refState.current,
      rootTwin,
    )

    await dbProvider.batch(context)
  }, [ rootTwin ])

  const onToggleGrid = useCallback(() => (
    onChangeDiagram({
      diagram: {
        hideGrid: !refState.current?.diagram?.hideGrid,
      },
    })
  ), [ onChangeDiagram ])

  const onLinkDiagram = useCallback(async (data, prev) => {
    if (data.category === consts.DiagramBlockCategory.Parent) {
      const rootTwinId = rootTwin?.id || null
      const collection = dbProvider.database.collections.get('twins')
      const context = dbProvider.createBatchContext()
      const twin = await collection.find(data.toId)
      const twinParentId = twin.parentId
      await twin.prepareMoveInner(context, data.fromId)

      // when update link
      if (prev && prev.id !== twinParentId) {
        const twinPrev = await collection.find(prev.id)
        await twinPrev.prepareMoveInner(context, rootTwinId)
      }

      await dbProvider.batch(context)
    } else if (data.category === consts.DiagramBlockCategory.Descr) {
      const context = dbProvider.createBatchContext()
      const collection = dbProvider.database.collections.get('twins')
      const fromName = (await collection.find(data.fromId))?.name
      const toName = (await collection.find(data.toId))?.name
      const model = await dbProvider.prepareReplaceData(context, collection.modelClass, {
        id: data.twinId,
        type: consts.TwinType.Flow,
        name: data.name || `${fromName} -> ${toName}`,
        parentId: data.fromId,
        flowToId: data.toId,
      })
      await model.prepareMoveInner(context, data.fromId)
      await dbProvider.batch(context)
    }
  }, [ rootTwin ])

  const onDropCreateDiagram = useCallback(async (
    blockState,
    parentNode,
  ) => {
    let currentBlockState = mergeElementLocations(
      blockState,
      parentNode,
      refState.current?.elements?.[parentNode?.id],
      rootTwin,
      refState.current?.elements?.[rootTwin?.id],
    )

    const { type, id: contextId } = drag.context
    const dragData = drag.data
    drag.clear()

    if (consts.TwinTypes.includes(type)) {
      const context = dbProvider.createBatchContext()
      const model = await dbProvider.prepareReplaceData(context, Twin, {
        type,
        name: type,
        parentId: rootTwin?.id || parentNode?.id || null,
        ...currentBlockState,
      })
      await dbProvider.batch(context)
      await onChangeDiagram({
        elements: {
          [model.id]: currentBlockState,
        },
      })
    } else if (dragData.size) {
      const ids = Array.from(dragData)

      const sensors = await dbProvider.database.collections
        .get('sensors')
        .query(
          Q.where('id', Q.oneOf(ids)),
          // Q.where('twin_id', null),
        )
        .fetch()

      if (sensors.length && parentNode) {
        const twin = await dbProvider.database.collections
          .get('twins')
          .find(parentNode.id)

        // if (contextId !== twin.id) {
        //   await window.wizConfirm({ message: 't/form.actions.attachSensorsConfirm' })
        // }

        try {
          const context = dbProvider.createBatchContext()
          for (const sensor of sensors) {
            if (sensor.twinId) {
              await sensor.prepareUnlink(context)
            }
            await twin.prepareLinkSensor(context, sensor)
          }
          await dbProvider.batch(context)
          events.emit('digitalTwin:sensorsAttached')
          events.emit('app:notify', {
            type: 'success',
            message: 't/form.success.attachSensors',
            duration: 2000,
          })
        } catch (error) {
          events.emit('app:notify', {
            type: 'error',
            message: 't/form.errors.attachSensors',
          })
        }
      }

      const twins = await dbProvider.database.collections
        .get('twins')
        .query(Q.where('id', Q.oneOf(ids)))
        .fetch()

      if (twins.length) {
        const elements = {}
        for (const twin of twins) {
          elements[twin.id] = currentBlockState
          currentBlockState = mergeElementLocations(currentBlockState, parentNode)
        }
        await onChangeDiagram({
          elements,
        })
      }
    }
  }, [
    drag,
    rootTwin,
    onChangeDiagram,
  ])

  const onUpdateDefaultTree = useCallback(async (id) => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareReplaceSetting(context, 'PhysicalTwinDefaultTree', id)
    await dbProvider.batch(context)
  }, [])

  const onDuplicate = useCallback(async (
    duplicateIds,
    elementsState,
    {
      withChildren,
      moveAfter,
    } = {},
  ) => {
    const twinIds = duplicateIds
      .filter(id => id.indexOf('/') === -1)
    const twinLinks = duplicateIds
      .filter(id => id.indexOf('/') !== -1)
      .map(id => id.split('/'))
      .filter(id => (
        twinIds.includes(id[0]) &&
        twinIds.includes(id[1])
      ))

    if (moveAfter && twinIds.length > 1) {
      throw new Error('Unable to move after more than one block')
    }

    let moveAfterDuplicatedModel
    const chain = {}
    const actions = {}
    const onDuplicateModel = (source, duplicate) => {
      chain[`${duplicate.parentId}/${duplicate.id}`] = [
        source.prevId,
        source.nextId,
      ]
    }

    const items = await dbProvider.database.collections.get('twins')
      .query(
        Q.where('id', Q.oneOf(twinIds)),
        Q.where('type', Q.notEq(consts.TwinType.Flow)),
      )
      .fetch()

    const flows = await dbProvider.database.collections.get('twins')
      .query(
        Q.where('id', Q.oneOf(twinIds)),
        Q.where('type', consts.TwinType.Flow),
      )
      .fetch()

    for (const item of items) {
      moveAfterDuplicatedModel = await item.prepareDuplicate(actions, undefined, {
        uniqProps: [ 'name', 'hardwareId' ],
        withDuplicate: true,
        shiftLocation: true,
        onDuplicate: onDuplicateModel,
        twinLinks,
        withChildren,
      })
    }

    const pairsIt = duplicateActionsPairsIterator(actions)
    const elements = {}
    const selectedIds = {}
    // need to update state for all blocks, include children blocks
    for (const [ oldId, newId ] of pairsIt) {
      selectedIds[oldId] = newId
      elements[newId] = mergeElementLocations(
        elementsState?.[oldId],
        refState.current?.elements?.[oldId],
        refState.current?.elements?.[rootTwin?.id],
      )
    }

    for (const [ from, to ] of twinLinks) {
      if (selectedIds[from] && selectedIds[to]) {
        selectedIds[`${from}/${to}`] = `${selectedIds[from]}/${selectedIds[to]}`
      }
    }

    for (const flow of flows) {
      await flow.prepareDuplicate(actions, {
        parentId: selectedIds[flow.parentId] || flow.parentId,
        flowToId: selectedIds[flow.flowToId] || flow.flowToId,
      }, {
        uniqProps: [ 'name', 'hardwareId' ],
        onDuplicate: onDuplicateModel,
      })
    }

    const context = dbProvider.createBatchContext()
    const it = duplicateActionsIterator(actions)
    for (const item of it) {
      const key = `${item.parentId}/${item.id}`
      if (chain[key]) {
        const [ prevId, nextId ] = chain[key]
        const prevKey = `${item.parentId}/${selectedIds[prevId]}`
        const nextKey = `${item.parentId}/${selectedIds[nextId]}`
        if (chain[prevKey] || chain[nextKey]) {
          await item.prepareUpdateModel({
            prevId: selectedIds[prevId] || null,
            nextId: selectedIds[nextId] || null,
          })
        }
      }

      context.add(item)
    }

    await dbProvider.batch(context)

    if (moveAfter && moveAfterDuplicatedModel) {
      const moveContext = dbProvider.createBatchContext()
      await moveAfterDuplicatedModel.prepareMoveAfter(moveContext, moveAfter)
      await dbProvider.batch(moveContext)
    }

    await onChangeDiagram({ elements })
    return Object.values(selectedIds)
  }, [
    rootTwin,
    onChangeDiagram,
  ])

  const onPaste = useCallback(async (blocks) => {
    const elementsState = {}
    const ids = []

    for (const item of blocks) {
      ids.push(item.twinId || item.id) // block (twinId) or link (id)
      if (item.twinId) {
        elementsState[item.twinId] = item
      }
    }

    return onDuplicate(ids, elementsState)
  }, [ onDuplicate ])

  const onMove = useCallback(async (action, target, dragData) => {
    const context = dbProvider.createBatchContext()
    const twins = dragData.map(item => (item.payload || item))
    const targetId = target.payload?.id ?? target.id

    if (action === 'inner') {
      for (const item of twins) {
        await item.prepareMoveInner(context, targetId)
      }
    } else if (action === 'before') {
      for (const item of twins) {
        await item.prepareMoveBefore(context, targetId)
      }
    } else if (action === 'after') {
      for (const item of twins) {
        await item.prepareMoveAfter(context, targetId)
      }
    }
    await dbProvider.batch(context)
  }, [])

  useEffect(() => {
    if (refStateIgnore.current) {
      refStateIgnore.current = false
    } else {
      refState.current = diagramState
    }
  }, [ diagramState ])

  return {
    blockFactory,
    linkMode,
    onChangeDiagram,
    onDropCreateDiagram,
    onDuplicate,
    onLinkDiagram,
    onMove,
    onPaste,
    onToggleGrid,
    onUpdateDefaultTree,
    rootTwin,
    rootTwinDiagram,
    setLinkMode,
    state: refState.current,
  }
})

export default enhanceDiagramProps
