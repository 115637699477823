import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormField } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import Select from '@/components/Form/Select'
import GroupAccessRoleList from '@/hoc/GroupAccessRoleList'

export default function FieldsRoles ({
  scope,
  accessRoleScope,
}) {
  const intl = useIntl()
  const {
    watch,
    formState: { errors },
  } = useFormContext()

  const id = watch(`${accessRoleScope}.id`)
  const filters = useMemo(() => ({
    ignoreIds: [ id ],
  }), [ id ])

  return (
    <FormField
      label={intl.t('roles.form.fields.roles')}
      description={intl.t('roles.form.fields.rolesDescr')}
      errors={get(errors, scope)}
    >
      <Controller
        name={scope}
        render={({ field, fieldState }) => (
          <Select
            {...field}
            invalid={fieldState.invalid}
            multiselect
            filters={filters}
            ListSource={GroupAccessRoleList}
            placeholder={intl.t('roles.form.fields.rolesPlaceholder')}
          />
        )}
      />
    </FormField>
  )
}
