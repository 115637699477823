import { useState, useEffect } from 'react'
import queryString from 'query-string'
import { Route, Redirect } from '@/router'
import { useAuth } from '@/auth'
import { Auth } from 'aws-amplify'

export default function RoutePublic ({ render, ...props }) {
  const [ isAuth, setAuth ] = useState()
  const auth = useAuth()

  const handleAuthCheck = isAuthenticated => Auth.userSession()
    .then(session => session)
    .catch((err) => {
      setAuth(isAuthenticated)
      if (!isAuthenticated) {
        document.getElementById('startup-loader')?.hide()
      }
    })

  useEffect(() => {
    const isAuthenticated = auth?.checkAuthenticated()

    handleAuthCheck(isAuthenticated).then(async (session) => {
      session?.isValid ? await setAuth(true) : setAuth(isAuthenticated)
    })
  }, [ auth ])

  return (
    <Route
      {...props}
      render={(data) => {
        if (!isAuth) {
          return render(data)
        }

        const query = queryString.parse(data.location.search)
        const redirect = query.redirect || `/${data.match.params.lang}`

        return (
          <Redirect
            to={redirect}
          />
        )
      }}
    />
  )
}
