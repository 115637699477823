import { useMemo } from 'react'
import { Category as Category$ } from '@wiz/store'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { wizataApi } from '@/api'
import FormDialog from '@/components/Form/FormDialog'
import events from '@/utils/events'
import { uuid } from '@wiz/utils'
import Section from './Section'
import Removed from '../Removed'
import Schema from './Schema'

export default function Category ({
  id,
  category,
  dialog,
  onClose,
  onSubmit,
}) {
  const queryClient = useQueryClient()

  const { data: item, isLoading } = useQuery({
    queryKey: [ 'category', id ],
    queryFn: () => wizataApi.categories.getById(id),
    enabled: !!id,
    staleTime: Infinity,
  })

  const {
    mutateAsync: mutateCategory,
  } = useMutation({
    mutationKey: [ 'mutateCategory' ],
    mutationFn: (data) => {
      if (!id) {
        return wizataApi.categories.create({ ...data?.category, id: uuid() })
      }
      return wizataApi.categories.update({ id, ...data?.category })
    },
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/categories.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'category', id ] })
      queryClient.invalidateQueries({ queryKey: [ 'categoriesList' ] })
    },
  })

  const handleSubmit = async (data) => {
    await mutateCategory(data)
  }

  const defaultValues = useMemo(() => ({
    category: { ...item },
  }), [ item ])

  if (id && !item) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={handleSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      schema={Schema}
      dataTestid="categoryForm"
      dialog={dialog}
    >
      <Section
        scope="category"
      />
    </FormDialog>
  )
}
