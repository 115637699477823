import { useRef, useContext } from 'react'
import { Form } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import DataExplorer from '@/context/DataExplorer'
import Fields from './Fields'

export default function DataExplorerView ({ onSubmit, onClose }) {
  const refForm = useRef()
  const intl = useIntl()
  const context = useContext(DataExplorer)

  return (
    <Form
      ref={refForm}
      className="d-flex flex-column flex-fill min-h-0"
      onSubmit={onSubmit}
      defaultValues={{ dataFilter: context.data.dataFilter }}
    >
      <div className="flex-fill p-3 min-h-0 overflow-auto">
        <Fields scope="dataFilter" />
      </div>

      <div className="d-flex m-3 justify-content-end">
        <button
          type="button"
          className="btn btn-outline-secondary me-1"
          onClick={() => onClose?.()}
        >
          {intl.t('form.actions.close')}
        </button>
        <button
          type="button"
          className="btn btn-primary ms-1"
          onClick={() => refForm.current.submit()}
        >
          {intl.t('form.actions.save')}
        </button>
      </div>
    </Form>
  )
}
