import { DateTime } from 'luxon'
import { useUpdateByInterval } from '@wiz/components'
import { intl } from '@/i18n'

const Time5Sec = 5000
const TimeMin = 60 * 1000
const Time5Min = 5 * 60 * 1000
const Time10Min = 10 * 60 * 1000
const TimeHour = 60 * 60 * 1000
const TimeDay = 24 * 60 * 60 * 1000

export default function DateRelative ({
  className,
  value,
  title,
  future = false,
  flavour = 'mini-minute-now',
}) {
  const now = new Date()
  value = Number.isInteger(value) ? new Date(value) : value
  value = future ? value : new Date(Math.min(value, now))

  const diff = Math.abs(now - value)
  const time = diff < TimeMin ? Time5Sec :
    diff < TimeHour ? TimeMin :
    diff < TimeDay ? TimeHour : TimeDay

  useUpdateByInterval(time)

  return (
    <time
      title={title || DateTime.fromJSDate(value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
      className={className}
    >
      {future && value > now ? '+' : null}
      {intl.ago.format(value, flavour)}
    </time>
  )
}
