import ListSensorsByTwin from '@/containers/Form/ListSensorsByTwin'
import { useIntl } from '@wiz/intl'
import SelectSensor from '@/components/Form/SelectSensor'

const Datapoints = ({
  blockSettings,
  sensorIds,
  onChangeSelection,
  onToggleByTwin,
  widgetSensors,
  getError,
  selectedSettings,
  handleSettingsUpdate,
  onChange,
  isEdit,
}) => {
  const intl = useIntl()
  const error = getError('datapoints')

  return (
    <>
      <div className="d-flex flex-wrap mt-2 px-3 m-1">
        <b>Please review data points selection:</b>
      </div>
      {isEdit ? (
        <div className="d-flex px-3 w-100 mx-1">
          <SelectSensor
            value={sensorIds}
            placeholder={intl.t('form.fields.sensorsPlaceholder')}
            onChange={onChange}
            multiselect
          >
            Add datapoints
          </SelectSensor>
        </div>
      ) : null}

      <div className="d-flex flex-fill flex-column mt-4">
        <ListSensorsByTwin
          className="d-flex flex-fill"
          twinsRequired
          withSensors
          defaultCheckedAll
          blockSettings={blockSettings}
          value={sensorIds}
          onChange={onChangeSelection}
          onToggleByTwin={onToggleByTwin}
          showDescription={false}
          otherValues={widgetSensors}
          selectedSettings={selectedSettings}
          onSettingsUpdate={handleSettingsUpdate}
        />
        {error ? <div className="invalid-feedback d-block px-3">{intl.t(error)}</div> : <div>&nbsp;</div>}
      </div>
    </>
  )
}

export { Datapoints }
