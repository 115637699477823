import { FormSection } from '@wiz/components'
import FormDialog from '@/components/Form/FormDialog'
import Fields from './Fields'

export default function TwinBulkEdit ({
  dialog,
  onSubmit,
  onClose,
  selectedIds,
}) {
  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      dataTestid="twinBulkEditForm"
      dialog={dialog}
    >
      <FormSection>
        <Fields scope="twins" selectedIds={selectedIds} />
      </FormSection>
    </FormDialog>
  )
}
