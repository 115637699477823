import { useState, useMemo, createContext } from 'react'

export const SpotlightContext = createContext({})

export function SpotlightProvider ({ children }) {
  const [ spotlightSearch, setSpotlightSearch ] = useState(false)

  const spotlight = useMemo(() => ({
    spotlightSearch,
    setSpotlightSearch,
  }), [ spotlightSearch ])

  return (
    <SpotlightContext.Provider value={spotlight}>
      {children}
    </SpotlightContext.Provider>
  )
}
