import { map } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { auth } from '@/auth'

export default withObservables([ 'project', 'filters' ], ({ project, filters }) => {
  const data = {
    dashboards: dbProvider.database.collections.get('dashboards')
      .query(Q.on('rel_projects_dashboards', Q.where('project_id', project.id))),
    jobs: dbProvider.database.collections.get('jobs')
      .query(Q.on('rel_projects_jobs', Q.where('project_id', project.id))),
    notebooks: dbProvider.database.collections.get('notebooks')
      .query(Q.on('rel_projects_notebooks', Q.where('project_id', project.id))),
    files: dbProvider.database.collections.get('files')
      .query(Q.on('rel_projects_files', Q.where('project_id', project.id))),
    qualityDataTemplates: dbProvider.database.collections.get('quality_data_templates')
      .query(Q.on('rel_projects_quality_data_templates', Q.where('project_id', project.id))),
    explorers: dbProvider.database.collections.get('explorers')
      .query(Q.on('rel_projects_explorers', Q.where('project_id', project.id))),
  }

  if (filters?.search) {
    const searchSanitize = Q.sanitizeLikeString(filters.search)
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        data[key] = data[key].extend(Q.or(
          Q.where('id', filters.search),
          Q.where('title', Q.like(`%${searchSanitize}%`)),
          Q.where('name', Q.like(`%${searchSanitize}%`)),
        ))
      }
    }
  }

  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      data[key] = data[key].observeWithColumns([ 'updated_at' ])
        .pipe(
          map(items => items.filter(item => auth.checkAccessRead(item))),
        )
    }
  }

  return data
})
