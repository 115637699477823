import { useMemo, useState } from 'react'
import { VirtualTable, Pagination, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ButtonCopy from '@/components/Form/ButtonCopy'

export default function Table ({
  items,
  onRemove,
  onEdit,
  onExport,
  onPreview,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      Header: intl.t('notebooks.form.fields.name'),
      accessor: 'name',
      minWidth: 300,
      Cell: ({ cell, row }) => (
        <div className="d-flex align-items-center min-w-0">
          <div className="text-truncate">
            <a
              className="text-truncate pointer"
              onClick={() => onPreview?.(row.original.id)}
              aria-hidden
            >
              {cell.value}
            </a>
            {row.original.path ? (
              <div className="d-flex align-items-center min-w-0">
                <span
                  title={intl.t('notebooks.form.fields.databricksPath')}
                  className="font-italic text-muted text-truncate small user-select-all"
                >
                  {row.original.path}
                </span>

                <ButtonCopy value={row.original.path} className="text-muted" />
              </div>
            ) : null}
            {row.original.description ? (
              <div className="font-italic text-muted text-truncate small">
                {row.original.description}
              </div>
            ) : null}
          </div>
        </div>
      ),
    },
    {
      Header: intl.t('notebooks.form.fields.environment'),
      accessor: 'environment',
      width: 120,
    },
    {
      Header: intl.t('notebooks.form.fields.language'),
      accessor: 'language',
      width: 80,
    },
    {
      Header: intl.t('notebooks.form.fields.format'),
      accessor: 'format',
      width: 80,
    },
    {
      Header: intl.t('notebooks.form.fields.hyperlink'),
      accessor: 'url',
      width: 100,
      Cell: ({ cell }) => (
        <div className="d-flex align-items-center min-w-0">
          {cell.value ? (
            <>
              <a
                href={cell.value}
                target="_blank"
                rel="noreferrer"
                className="text-truncate user-select-all"
              >
                {cell.value}
              </a>
              <ButtonCopy value={cell.value} />
            </>
          ) : '-'}
        </div>
      ),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedAt',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: ({ cell, row }) => (<UpdatedInfo date={cell.value} user={row.original.updatedUser} />),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdAt',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: ({ cell, row }) => (<UpdatedInfo date={cell.value} user={row.original.createdUser} />),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell, row }) => {
        const [ loading, setLoading ] = useState(false)

        return (
          <>
            <button
              type="button"
              className="btn btn-sm p-0 me-2"
              onClick={() => onPreview?.(cell.value)}
            >
              {intl.t('form.actions.preview')}
            </button>

            {onExport && auth.checkAccessRead('SectionExportResults') ? (
              <button
                type="button"
                className="btn btn-sm p-0 me-2"
                disabled={loading}
                onClick={() => {
                  setLoading(true)
                  onExport(row.original).finally(() => setLoading(false))
                }}
              >
                {loading ? (
                  <Icon name="fa--spinner" className="me-1" spin />
                ) : null}
                {intl.t('form.actions.export')}
              </button>
            ) : null}

            {auth.checkAccessUpdate(row.original) ? (
              <button
                type="button"
                className="btn btn-sm p-0 me-2"
                onClick={() => onEdit?.(cell.value)}
              >
                {intl.t('form.actions.edit')}
              </button>
            ) : null}

            {auth.checkAccessRemove(row.original) ? (
              <button
                type="button"
                className="btn btn-sm p-0"
                disabled={loading}
                onClick={() => onRemove?.([ row.original ])}
              >
                {intl.t('form.actions.remove')}
              </button>
            ) : null}
          </>
        )
      },
    },
  ], [
    intl,
    auth,
    onEdit,
    onRemove,
    onExport,
    onPreview,
  ])

  return (
    <>
      <VirtualTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={auth.checkAccessRemove('Notebook')}
        {...props}
      />

      <Pagination {...props} />
    </>
  )
}
