import { useCallback } from 'react'
import { withProps } from '@wiz/components'
import { useRouter } from '@/router'
import withTableActions from '@/containers/withTableActions'
import Component from '@/components/Unit/List'

const enhanceProps = withProps(({ onRemove }) => {
  const router = useRouter()

  const onRemoveUnit = useCallback(async (data) => {
    try {
      await onRemove?.(data)
    } catch (error) {
      if (data.length === 1 && error?.message === 't/units.form.errors.removalSensorsUsed') {
        try {
          await window.wizConfirm({ message: 't/units.confirmOpenDataPoints' })
          router.push({
            name: 'sensors-list',
            query: {
              unit: data[0].id,
            },
          })
        } catch (e) {
          // no-error
        }
      }
      throw error
    }
  }, [ onRemove, router ])

  return {
    onRemove: onRemoveUnit,
  }
})

export default withTableActions({
  defaultFilters () {
    return {
      sortBy: 'createdDate',
    }
  },
})(
  enhanceProps(Component),
)
