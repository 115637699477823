import { useCallback, memo } from 'react'
import { dbProvider, NotificationSheet } from '@wiz/store'
import { withProps } from '@wiz/components'
import FormNotificationSheet from '@/components/Forms/NotificationSheet'
import enhanceSheet from './enhanceSheet'
import enhanceSheetTargets from './enhanceSheetTargets'
import enhanceUsage from './enhanceUsage'

const enhanceProps = withProps(() => {
  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    await NotificationSheet.prepareReplaceSettings(context, dbProvider.database, data)
    await dbProvider.batch(context)
  }, [])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceSheet(
    enhanceSheetTargets(
      enhanceUsage(
        enhanceProps(FormNotificationSheet),
      ),
    ),
  ),
)
