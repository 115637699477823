import FormInputSensorValue from '@/containers/Forms/InputSensorValue'

export default function RightBarInputSensorValue ({
  sensorId,
  businessType,
  onChangeBack,
}) {
  return (
    <FormInputSensorValue
      businessType={businessType}
      defaultValues={{ sensorId }}
      onClose={onChangeBack}
      closeOnSuccess={false}
      resetOnSuccess
    />
  )
}
