import { intersection, type } from 'superstruct'
import { BulletPlotSource } from '@wiz/store'
import * as types from '@/utils/types'

export default type({
  bulletPlotSource: type({
    disabled: BulletPlotSource.schema.schema.disabled,
    sensorId: intersection([
      types.Required(),
      BulletPlotSource.schema.schema.sensorId,
    ]),
    dateFrom: intersection([
      BulletPlotSource.schema.schema.dateFrom,
      types.SlidingDateFrom(),
    ]),
    dateTo: intersection([
      BulletPlotSource.schema.schema.dateTo,
      types.SlidingDateTo(),
    ]),
    duration: intersection([
      BulletPlotSource.schema.schema.duration,
      types.SlidingDuration(),
    ]),
    offsetDuration: BulletPlotSource.schema.schema.offsetDuration,
    stepRequest: BulletPlotSource.schema.schema.stepRequest,
    rangeDateFrom: intersection([
      BulletPlotSource.schema.schema.rangeDateFrom,
      types.SlidingDateFrom({ keyTo: 'rangeDateTo', keyDuration: 'rangeDuration' }),
    ]),
    rangeDateTo: intersection([
      BulletPlotSource.schema.schema.rangeDateTo,
      types.SlidingDateTo({ keyFrom: 'rangeDateFrom' }),
    ]),
    rangeDuration: intersection([
      BulletPlotSource.schema.schema.rangeDuration,
      types.SlidingDuration({ keyFrom: 'rangeDateFrom' }),
    ]),
    rangeOffsetDuration: BulletPlotSource.schema.schema.rangeOffsetDuration,
    rangeStepRequest: BulletPlotSource.schema.schema.rangeStepRequest,
    limitMax: intersection([
      BulletPlotSource.schema.schema.limitMax,
      types.LimitMaxRequired(),
      types.LimitMax(),
    ]),
    limitMin: intersection([
      BulletPlotSource.schema.schema.limitMin,
      types.LimitMinRequired(),
      types.LimitMin(),
    ]),
    rangeMax: intersection([
      BulletPlotSource.schema.schema.rangeMax,
      types.LimitMaxRequired({ keyMin: 'rangeMin' }),
      types.LimitMax({ keyMin: 'rangeMin' }),
    ]),
    rangeMin: intersection([
      BulletPlotSource.schema.schema.rangeMin,
      types.LimitMinRequired({ keyMax: 'rangeMax' }),
      types.LimitMin({ keyMax: 'rangeMax' }),
    ]),
  }),
})
