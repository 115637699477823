import { useState, useCallback, useEffect } from 'react'
import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { consts } from '@wiz/utils'
import { withObservables, withProps } from '@wiz/components'
import { Q, dbProvider, EdgeDeviceCommand } from '@wiz/store'
import { wizataApi } from '@/api'
import events from '@/utils/events'
import Component from '../components/DeviceCommand'

const enhanceData = withObservables([ 'config' ], ({ config }) => ({
  device: config.deviceId ? dbProvider.database.collections.get('edges')
    .query(Q.where('id', config.deviceId))
    .observeWithColumns([ 'updated_at' ])
    .pipe(map(items => items[0])) : of$(undefined),
  template: config.templateId ? dbProvider.database.collections.get('device_command_templates')
    .query(Q.where('id', config.templateId))
    .observeWithColumns([ 'updated_at' ])
    .pipe(map(items => items[0])) : of$(undefined),
  sensor: config.sensorId ? dbProvider.database.collections.get('sensors')
    .query(Q.where('id', config.sensorId))
    .observeWithColumns([ 'updated_at' ])
    .pipe(map(items => items[0])) : of$(undefined),
}))

const enhanceProps = withProps(({
  device,
  sensor,
  template,
}) => {
  const [ payload, setPayload ] = useState(template?.payload ?? null)
  const [ loading, setLoading ] = useState(false)

  const onSendCommand = useCallback(async () => {
    try {
      setLoading(true)
      const model = new EdgeDeviceCommand({
        templateId: template.id,
        deviceId: device.id,
        sensorId: sensor?.id,
        payload,
      })
      await wizataApi.deviceCommands.replace(model)
      events.emit('app:notify', {
        type: 'success',
        title: 't/edge.commands.title',
        message: 't/edge.commands.form.success.create',
        duration: 2000,
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      events.emit('app:notify', {
        type: 'error',
        title: 't/edge.commands.title',
        message: error.message,
      })
      throw error
    }
  }, [
    payload,
    device,
    sensor,
    template,
  ])

  useEffect(() => {
    let nextPayload
    if (template.dataType === consts.DeviceCommandTemplateDataType.Number) {
      nextPayload = Number(payload) || 0
    } else if (template.dataType === consts.DeviceCommandTemplateDataType.Bool) {
      nextPayload = Boolean(payload)
    } else {
      nextPayload = String(payload || '')
    }
    setPayload(nextPayload)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ template?.dataType ])

  return {
    loading,
    payload,
    onChange: setPayload,
    onSendCommand,
  }
})

export default enhanceData(
  enhanceProps(Component),
)
