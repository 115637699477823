import {
  intersection,
  type,
} from 'superstruct'
import { DeviceCommandTemplate } from '@wiz/store'
import * as types from '@/utils/types'

export default type({
  deviceCommandTemplate: type({
    name: intersection([
      types.Required(),
      DeviceCommandTemplate.schema.schema.name,
    ]),
    topic: DeviceCommandTemplate.schema.schema.topic,
    payload: intersection([
      types.Maxlen(20000),
      DeviceCommandTemplate.schema.schema.payload,
    ]),
    dataType: intersection([
      types.Required(),
      DeviceCommandTemplate.schema.schema.dataType,
    ]),
    type: intersection([
      types.Required(),
      DeviceCommandTemplate.schema.schema.type,
    ]),
  }),
})
