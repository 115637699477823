import PropTypes from 'prop-types'
import TimeSettings from '@/components/Form/TimeSettings'

export default function FieldsData ({ scope }) {
  return (
    <TimeSettings
      className="mb-3"
      dateFromFieldName={`${scope}.dateFrom`}
      dateToFieldName={`${scope}.dateTo`}
      durationFieldName={`${scope}.duration`}
      offsetDurationFieldName={`${scope}.offsetDuration`}
      stepRequestFieldName={`${scope}.stepRequest`}
      stepRequestDisabled
    />
  )
}

FieldsData.propTypes = {
  scope: PropTypes.string.isRequired,
}
