import {
  forwardRef,
  useMemo,
  useCallback,
  useState,
} from 'react'
import classnames from 'classnames'
import { Tree } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { consts } from '@wiz/utils'
import FormExplorer from '@/containers/Forms/Explorer'
import Option from './Option'

const ListDataExplorerByTwin = forwardRef(({
  className,
  options,
  search,
  onSelect,
  onUnlink,
  onAttach,
}, ref) => {
  const intl = useIntl()
  const [ defaultData, setDefaultData ] = useState(null)

  const onCreate = useCallback(({ twinId }) => {
    setDefaultData({ twins: [ twinId ] })
  }, [])

  const itemData = useMemo(() => ({
    options,
    search,
    onSelect,
    onUnlink,
    onAttach,
    onCreate,
  }), [
    options,
    search,
    onSelect,
    onUnlink,
    onAttach,
    onCreate,
  ])

  if (!options.length) {
    return (
      <div className={classnames('d-flex flex-column align-items-center justify-content-center', className)}>
        {intl.t('form.nodata')}
      </div>
    )
  }

  return (
    <>
      <Tree
        className={className}
        options={options}
        itemData={itemData}
        itemSize={consts.ListItemHeight}
        isOpenByDefault
      >
        {Option}
      </Tree>

      {defaultData ? (
        <FormExplorer
          defaultValues={defaultData}
          onSuccess={model => onSelect(model.id)}
          onClose={() => setDefaultData(null)}
          dialog={{
            title: intl.t('explorer.titleCreate'),
            dataTestid: 'createExplorerDialog',
          }}
        />
      ) : null}
    </>
  )
})

export default ListDataExplorerByTwin
