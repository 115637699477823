import { useMemo, useState } from 'react'
import { VirtualTable as StaticTable, Pagination } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import { isEmpty } from '@wiz/utils'

function Detail ({ row }) {
  const intl = useIntl()
  const { message } = row.original;

  if (isEmpty(message)) {
    return intl.t('edgeLogs.table.columns.emptyMessage')
  }

  return (
    <div>
      {message}
    </div>
  )
}

export default function Table ({
  items,
  ...props
}) {
  const intl = useIntl()
  const [ isMs, setIsMs ] = useState(false)
  const columns = useMemo(() => [
    {
      Header: intl.t('form.fields.message'),
      accessor: 'message',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 400,
      Cell: ({ cell }) => (<span className="text-truncate">{cell.value}</span>),
    },
    {
      Header: intl.t('form.fields.device'),
      accessor: 'device',
      disableResizing: true,
      disableSortBy: true,
    },
    {
      Header: intl.t('form.fields.module'),
      accessor: 'module',
      disableResizing: true,
      disableSortBy: true,
    },
    {
      Header: () => (
        <>
          {intl.t('form.fields.created')}

          <button
            type="button"
            className="btn btn-sm btn-fill-secondary ms-2"
            name="toggleMs"
            title=""
            onClick={(event) => {
              event.stopPropagation()
              setIsMs(!isMs)
            }}
          >
            {isMs ? intl.t('form.fields.ms') : intl.t('form.fields.s')}
          </button>
        </>
      ),
      accessor: 'timestamp',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 260,
      width: 260,
      maxWidth: 260,
      Cell: ({ cell }) => (<UpdatedInfo date={cell.value} withMillis={isMs} withSecond />),
    },
  ], [ intl, isMs ])

  return (
    <>
      <StaticTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={false}
        expande={true}
        ExpandeComponent={Detail}
        estimatedItemSize={38}
        {...props}
      />

      <Pagination {...props} relative />
    </>
  )
}
