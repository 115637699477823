import { useCallback, memo } from 'react'
import { withProps } from '@wiz/components'
import { toArrayValue } from '@wiz/utils'
import { wizataApi } from '@/api'
import events from '@/utils/events'
import Component from '@/components/Forms/InputSensorValue'

const enhanceProps = withProps(({ onSuccess }) => {
  const onSubmit = useCallback(async ({ inputSensorValue }) => {
    const sensorIds = toArrayValue(inputSensorValue.sensorId)

    if (
      Number.isFinite(inputSensorValue.value) &&
      (inputSensorValue.timestamp || inputSensorValue.onlyNow) &&
      sensorIds.length
    ) {
      const now = Date.now()
      const values = []

      for (const sensorId of sensorIds) {
        values.push({
          sensorId,
          value: inputSensorValue.value,
          timestamp: inputSensorValue.onlyNow ? now : inputSensorValue.timestamp,
        })
      }

      const data = await wizataApi.setSensorValues(values)
      if (data.some(item => item.result?.error)) {
        events.emit('app:notify', {
          type: 'error',
          title: 't/sensors.titleInputValue',
          message: '',
        })
      } else {
        events.emit('app:notify', {
          type: 'success',
          title: 't/sensors.titleInputValue',
          message: 't/sensors.form.success.inputValue',
          duration: 2000,
        })
        onSuccess?.()
      }
    }
  }, [ onSuccess ])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceProps(Component),
)
