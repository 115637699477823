export default function grafana (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      grafana: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value ({ templateId, source, dashboard }) {
              let query = ''

              if (templateId) {
                query += `templateId=${templateId}&`
              }
              if (source) {
                query += `source=${source}&`
              }
              if (dashboard) {
                query += `dashboardUid=${dashboard}`
              }
              return client.get(`/grafana/search?${query}`).fetch()
            },
          },
          getExplore: {
            enumerable: true,
            value () {
              return client.get('/grafana/explore').fetch()
            },
          },
          getDashboards: {
            enumerable: true,
            value () {
              return client.get('/grafana/search_dashboards').fetch()
            },
          },
          check: {
            enumerable: true,
            value () {
              return client.get('/grafana/check').fetch()
            },
          },
          getVariablesById: {
            enumerable: true,
            value (id) {
              return client.get(`/grafana/dashboard/${id}/variables`).fetch()
            },
          },
          createDashboardGrafana: {
            enumerable: true,
            value (params) {
              return client.post('/grafana/create_component', { ...params }).fetch()
            },
          },
        }),
      },
    })
  }
}
