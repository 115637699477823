import { Q, dbProvider } from '@wiz/store'
import { withObservables, withProps } from '@wiz/components'
import { orderBy } from '@wiz/utils'
import Component from '@/components/TwinGraph/Table'
import { auth } from '@/auth'

const enhanceSettings = withObservables([], () => ({
  settings: dbProvider.observeSettings([
    'homepageDefaultId',
    'lastOpened',
    'spotlightFavorites',
  ]),
}))

const enhanceData = withObservables([
  'search',
  'filters',
  'settings',
], ({
  search,
  filters,
  settings,
}) => {
  let query = dbProvider.database.collections.get('twin_graphs').query()

  if (search) {
    const searchSanitize = Q.sanitizeLikeString(search)
    query = query.extend(Q.or(
      Q.where('id', search),
      Q.where('name', Q.like(`%${searchSanitize}%`)),
      Q.where('description', Q.like(`%${searchSanitize}%`)),
    ))
  }

  switch (filters.filterBy) {
    case 'favorite':
      query = query.extend(Q.where('id', Q.oneOf(settings.spotlightFavorites)))
      break
    case 'my':
      query = query.extend(Q.where('created_by_id', auth.getCurrentUserId()))
      break
    default:
  }

  if (filters.followedBy) {
    query = query.extend(Q.where('created_by_id', filters.followedBy))
  }

  return {
    options: query.observeWithColumns([ 'updated_at' ]),
  }
})

const enhanceFilters = withProps(({ options, filters, settings }) => {
  let nextOptions
  switch (filters.sortBy) {
    case 'opened':
      nextOptions = orderBy(options, [
        item => (settings.lastOpened?.[item.id] || 0),
      ], [ 'desc' ])
      break
    default:
      nextOptions = orderBy(options, [
        item => String(item.name || '').toLowerCase(),
      ], [ 'asc' ])
  }

  return {
    options: nextOptions,
  }
})

export default enhanceSettings(
  enhanceData(
    enhanceFilters(Component),
  ),
)
