import {
  type,
  array,
  optional,
  nullable,
  intersection,
} from 'superstruct'
import { DataTypes } from '@wiz/utils'
import { TwinGraph } from '@wiz/store'
import * as types from '@/utils/types'

export default type({
  newBoards: optional(nullable(array(type({
    id: DataTypes.StructUuid(),
    name: intersection([
      types.Required(),
      types.Maxlen(200),
      TwinGraph.schema.schema.name,
    ]),
  })))),
  boards: optional(nullable(array(DataTypes.StructUuid()))),
})
