import { useMemo, useState } from 'react'
import { VirtualTable as StaticTable, Pagination, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import UpdatedInfo from '@/components/Form/UpdatedInfo'

export default function Table ({
  items,
  onFilter,
  ...props
}) {
  const intl = useIntl()
  const [ isMs, setIsMs ] = useState(false)
  const columns = useMemo(() => [
    {
      Header: intl.t('form.fields.recipient'),
      accessor: 'recipient',
      disableResizing: true,
      width: 100,
      minWidth: 100,
      Cell: ({ cell }) => (
        <>
          <button
            type="button"
            className="btn btn-sm btn-flat-secondary me-2"
            onClick={() => onFilter({ recipient: cell.value })}
          >
            <Icon name="fa--search" />
          </button>
          <span className="text-truncate user-select-all">{cell.value}</span>
        </>
      ),
    },
    {
      Header: intl.t('form.fields.message'),
      accessor: 'message',
      disableResizing: true,
      minWidth: 400,
    },
    {
      Header: intl.t('form.fields.status'),
      accessor: 'status',
      disableResizing: true,
      disableSortBy: true,
      width: 100,
      maxWidth: 100,
    },
    {
      Header: intl.t('form.fields.recipientList'),
      accessor: 'recipientList',
      disableResizing: true,
      disableSortBy: true,
      width: 100,
      minWidth: 100,
      Cell: ({ cell }) => (<span className="text-truncate">{cell.value}</span>),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedDate',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 180,
      width: 180,
      maxWidth: 180,
      Cell: ({ cell }) => (<UpdatedInfo date={cell.value} />),
    },
    {
      Header: () => (
        <>
          {intl.t('form.fields.created')}
          <button
            type="button"
            className="btn btn-sm btn-flat-secondary ms-2"
            title=""
            onClick={(e) => {
              e.stopPropagation()
              setIsMs(!isMs)
            }}
          >
            {isMs ? intl.t('form.fields.ms') : intl.t('form.fields.s')}
          </button>
        </>
      ),
      accessor: 'createdDate',
      disableResizing: true,
      minWidth: 260,
      width: 260,
      maxWidth: 260,
      Cell: ({ cell }) => (<UpdatedInfo date={cell.value} withMillis={isMs} withSecond />),
    },
  ], [ onFilter, intl, isMs ])

  return (
    <>
      <StaticTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={false}
        {...props}
      />

      <Pagination {...props} relative />
    </>
  )
}
