import { memo } from 'react'
import { of as of$ } from 'rxjs'
import { map, withLatestFrom } from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'
import { auth } from '@/auth'
import FormExplorerMini from '@/components/Forms/ExplorerMini'
import enhanceExplorerData from '../Explorer/enhanceExplorerData'

const enhanceData = withObservables([ 'id' ], ({ id }) => {
  const explorer$ = id ? dbProvider.database.collections.get('explorers')
    .query(Q.where('id', id))
    .observeWithColumns([ 'updated_at' ])
    .pipe(
      map(items => items.filter(item => auth.checkAccessRead(item))),
      map(items => items[0]),
    ) : of$(undefined)

  const widget$ = id ? dbProvider.database.collections.get('widgets')
    .query(Q.where('id', id))
    .observeWithColumns([ 'updated_at' ])
    .pipe(
      map(items => items.filter(item => auth.checkAccessRead(item))),
      map(items => items[0]),
    ) : of$(undefined)

  return {
    explorer: explorer$.pipe(
      withLatestFrom(widget$),
      map(([ explorer, widget ]) => (explorer || widget)),
    ),

    settings: dbProvider.observeSettings([
      'explorerAutosave',
    ]),
  }
})

export default memo(
  enhanceData(
    enhanceExplorerData(FormExplorerMini),
  ),
)
