import { useRef, useMemo } from 'react'
import { Form } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Fields from './Fields'

export default function DataViewMini ({ dataView, onSubmit, onClose, onShowSettings }) {
  const refForm = useRef()
  const intl = useIntl()
  const defaultValues = useMemo(() => ({ dataView }), [ dataView ])

  return (
    <Form
      ref={refForm}
      className="d-flex flex-column p-3"
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    >
      <div className="row">
        <div className="col d-flex mb-3">
          <button
            type="button"
            className="btn btn-sm btn-outline-primary flex-fill"
            onClick={() => {
              onShowSettings?.()
              onClose?.()
            }}
          >
            {intl.t('form.actions.moreSettings')}
          </button>
        </div>
      </div>

      <Fields scope="dataView" dataView={dataView} />

      <div className="d-flex mt-2">
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary flex-fill me-1"
          onClick={() => onClose?.()}
        >
          {intl.t('form.actions.close')}
        </button>
        <button
          type="button"
          className="btn btn-sm btn-primary flex-fill ms-1"
          onClick={() => refForm.current.submit()}
        >
          {intl.t('form.actions.save')}
        </button>
      </div>
    </Form>
  )
}
