import {
  forwardRef, useRef, useState, useMemo,
} from 'react'
import classnames from 'classnames'
import { debounce, uniqKey } from '@wiz/utils'
import { Icon, DropdownMenu } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { wizataApi } from '@/api'

const InputSearchADUser = forwardRef(({ onChange }, ref) => {
  const refInput = useRef()
  const refList = useRef()
  const [ items, setItems ] = useState([])
  const [ loading, setLoading ] = useState(false)

  const fetchRequest = useMemo(() => debounce(async () => {
    const query = String(refInput.current.value || '').trim()
    if (!query) {
      refList.current.close()
      setItems([])
      return
    }

    if (query.length >= 3) {
      setLoading(true)

      let data = await wizataApi.adUsers.list({ searchTerm: query })
      data = data.slice(0, 10).map(item => ({
        id: item.id,
        name: item.name,
        payload: item,
      }))

      if (data.length) {
        let adUsers = await dbProvider.database.collections.get('users')
          .query(
            Q.where('ad_id', Q.oneOf(data.map(item => item.id))),
            Q.where('is_deleted', false),
          )
          .fetch()

        if (adUsers.length) {
          adUsers = uniqKey(adUsers, 'adId')
          data = data.map(item => ({
            ...item,
            disabled: adUsers.includes(item.id),
          }))
        }
      }

      setItems(data)
      setLoading(false)

      if (data.length) {
        refList.current.open()
      } else {
        refList.current.close()
      }
    }
  }, 500), [])

  return (
    <div className="input-group">
      <input
        ref={refInput}
        className={classnames('form-control rounded', {
          'pe-4': loading,
        })}
        type="search"
        onChange={() => fetchRequest()}
        onClick={() => {
          if (items.length) {
            refList.current.open()
          }
        }}
      />

      {loading ? (
        <span
          className="input-group-text border-start-0 bg-transparent position-absolute px-2"
          style={{ right: 0, zIndex: 3 }}
        >
          &nbsp;
          <Icon name="fa--spinner" spin />
        </span>
      ) : null}

      <DropdownMenu
        ref={refList}
        target={refInput}
        options={items}
        mode={null}
        autoclose
        placement="bottom-end"
        onClick={({ name, payload }) => {
          refInput.current.value = name
          onChange?.(payload)
        }}
      />
    </div>
  )
})

export default InputSearchADUser
