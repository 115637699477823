import { shape, string } from 'prop-types'

export default function Title ({ style, text }) {
  return (
    <div style={style} className="d-flex align-items-start hover-toggle list-group-item bg-light">{text}</div>
  )
}

Title.propTypes = {
  style: shape({}).isRequired,
  text: string,
}

Title.defaultProps = {
  text: 'Other data points',
}
