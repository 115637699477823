import { useCallback, useMemo, forwardRef } from 'react'
import { useFormContext } from 'react-hook-form'
import classnames from 'classnames'
import { FormSection, FormFieldInline, FormControl } from '@wiz/components'
import { Condition, Sensor } from '@wiz/store'
import { get, has, toArrayValue } from '@wiz/utils'
import ConditionExplorer from '@/components/Form/ConditionExplorer'
import WidgetTableDataCondition from '@/components/Forms/WidgetTableDataCondition'
import SelectSensor from '@/components/Form/SelectSensor'
import classes from './SectionConditions.css'

export default function SectionConditions ({ scope, title, description }) {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()

  const [
    conditions,
    labelIds,
    sensorIds,
    qualityTemplateIds,
  ] = watch([
    `${scope}.conditions`,
    `${scope}.labelIds`,
    `${scope}.sensorIds`,
    `${scope}.qualityTemplateIds`,
  ])

  const filters = useMemo(() => ({
    rawQuery: Sensor.filterBy({
      labelIds,
      sensorIds,
      qualityTemplateIds,
    }),
  }), [
    labelIds,
    sensorIds,
    qualityTemplateIds,
  ])

  const ComponentSelectDataSources = useMemo(() => forwardRef(({
    value,
    onChange,
    multiselect,
    ...props
  }, ref) => (
    <SelectSensor
      ref={ref}
      {...props}
      value={toArrayValue(value).map(item => item.sensorId)}
      filters={filters}
      multiselect={multiselect}
      onChange={(data) => {
        let ds = multiselect ? [] : null
        if (multiselect) {
          if (Array.isArray(data)) {
            ds = data.map(sensorId => ({ sensorId, dataType: 'last' }))
          }
        } else if (data) {
          ds = { sensorId: data, dataType: 'last' }
        }
        onChange?.(ds)
      }}
    />
  )), [ filters ])

  const FormCondition = useMemo(() => props => (
    <WidgetTableDataCondition
      {...props}
      ComponentInputDataSources={ComponentSelectDataSources}
      ComponentOutputDataSources={ComponentSelectDataSources}
    />
  ), [ ComponentSelectDataSources ])

  const handleCreateConditions = useCallback((condition) => {
    const next = conditions.concat(Condition.toJSON(condition))
    setValue(`${scope}.conditions`, next, { shouldDirty: true })
  }, [ conditions, scope, setValue ])

  const handleRemoveConditions = useCallback((condition) => {
    const next = conditions
      .filter(item => item.id !== condition.id)
      .map(item => (item.groupId === condition.id ? { ...item, groupId: null } : item))
    setValue(`${scope}.conditions`, next, { shouldDirty: true })
  }, [ conditions, scope, setValue ])

  const handleUpdateConditions = useCallback((data) => {
    const hash = (Array.isArray(data) ? data : [ data ])
      .reduce((out, item) => ({ ...out, [item.id]: item }), {})

    const next = conditions
      .map((item) => {
        if (hash[item.id]) {
          const nextCondition = hash[item.id]
          delete hash[item.id]
          return Condition.toJSON({ ...item, ...nextCondition })
        }
        return item
      })
      .concat(Object.values(hash).map(item => Condition.toJSON(item)))

    setValue(`${scope}.conditions`, next, { shouldDirty: true })
  }, [ conditions, scope, setValue ])

  return (
    <FormSection
      title={title}
      description={description}
      classNameContent={classnames({
        'border border-danger': has(errors, `${scope}.conditions`),
      })}
    >
      <FormControl
        type="any"
        name={`${scope}.conditions`}
      />

      <FormFieldInline
        errors={get(errors, `${scope}.conditions`)}
        complex
        vertical
      >
        <ConditionExplorer
          className={classes.conditions}
          options={conditions}
          onCreateCondition={handleCreateConditions}
          onRemoveCondition={handleRemoveConditions}
          onChangeCondition={handleUpdateConditions}
          ComponentConditionForm={FormCondition}
        />
      </FormFieldInline>
    </FormSection>
  )
}
