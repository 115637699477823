import { map } from 'rxjs/operators'
import { toArrayValue, orderBy } from '@wiz/utils'
import { withObservables } from '@wiz/components'
import { Q, dbProvider, User } from '@wiz/store'

export default withObservables([
  'search',
  'selectedOnly',
  'value',
  'keyName',
  'withDashboards',
  'withTwinGraphs',
], ({
  search,
  selectedOnly,
  value,
  keyName = 'id',
  withDashboards,
  withTwinGraphs,
}) => {
  let query = dbProvider.database.collections.get('users')
    .query(
      Q.where('email', Q.notEq(null)),
      Q.where('email', Q.notEq('')),
    )

  if (search) {
    const sanitizeSearch = Q.sanitizeLikeString(search)
    query = query.extend(Q.or(
      Q.where('email', Q.like(`%${sanitizeSearch}%`)),
      Q.where('first_name', Q.like(`%${sanitizeSearch}%`)),
      Q.where('last_name', Q.like(`%${sanitizeSearch}%`)),
      Q.where('phone', Q.like(`%${sanitizeSearch}%`)),
    ))
  }

  if (selectedOnly) {
    query = query.extend(Q.where(keyName, Q.oneOf(toArrayValue(value))))
  }

  if (withDashboards) {
    query = query.extend(User.conditionWithDashboards())
  }

  if (withTwinGraphs) {
    query = query.extend(User.conditionWithTwinGraphs())
  }

  const observe = selectedOnly ?
    query.observeWithColumns([ 'updated_at' ]) :
    query.observe()

  return {
    options: observe
      .pipe(
        map(items => orderBy(items, [ item => item.name.toLowerCase() ], [ 'asc' ])),
      ),
  }
})
