import classnames from 'classnames'
import { useIntl } from '@wiz/intl'
import Link from '@/components/Link'
import classes from './Breadcrumb.css'

export default function Breadcrumb ({
  options,
  current,
  onlyLast,
  className,
}) {
  const intl = useIntl()
  let items = options || []
  let len = items.length

  if (current) {
    items = items.concat(current)
    len += 1
  }

  if (!len) {
    return null
  }

  if (onlyLast) {
    items = items.slice(-2)
    len = items.length
  }

  return (
    <nav
      aria-label="breadcrumb"
      className={classnames('mx-3', className, {
        [classes.mobile]: onlyLast,
      })}
    >
      <ol className="breadcrumb m-0">
        {items.map((item, idx) => {
          const last = idx === len - 1
          return (
            <li
              key={item.id}
              className={classnames('breadcrumb-item text-truncate', { active: last })}
              aria-current="page"
            >
              {(last || !item.to) ? intl.t(item.name) : (
                <Link name={item.to} params={item.params}>
                  {intl.t(item.name)}
                </Link>
              )}
            </li>
          )
        })}
      </ol>
    </nav>
  )
}
