import { useFormContext } from 'react-hook-form'
import { FormField, FormControl } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

const FormFieldRatio = [ 6, 6 ]

export default function FieldTitle () {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <FormField
      ratio={FormFieldRatio}
      label={intl.t('form.fields.title')}
      description={intl.t('explorer.form.fields.titleDescr')}
      errors={get(errors, 'explorer.title')}
    >
      <FormControl
        name="explorer.title"
        rules={{
          validate: (value) => {
            const str = String(value || '').trim()
            if (!str.length) {
              return intl.t('form.errors.fieldRequired')
            }
            if (value.length > 100) {
              return intl.t('form.errors.fieldMaxlen', { max: 100 })
            }
            return true
          },
        }}
      />
    </FormField>
  )
}
