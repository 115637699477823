import { useMemo, useState } from 'react'
import { useIntl } from '@wiz/intl'
import { NotificationSheet as NotificationSheet$, NotificationSheetTarget } from '@wiz/store'
import { Tabs } from '@wiz/components'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import Section from './Section'
import SectionTargets from './SectionTargets'
import SectionUsage from './SectionUsage'

export default function NotificationSheet ({
  id,
  notificationSheet,
  notificationSheetTargets,
  usage,
  dialog,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()
  const [ tab, setTab ] = useState('settings')

  const immutableNotificationSheetTargets = JSON.stringify(
    notificationSheetTargets.map(item => item?.updatedAt),
  )

  const defaultValues = useMemo(() => ({
    notificationSheet: NotificationSheet$.toJSON(notificationSheet),
    notificationSheetTargets: notificationSheetTargets.map(item => (
      NotificationSheetTarget.toJSON(item)
    )),
  }), [
    notificationSheet?.updatedAt ?? notificationSheet,
    immutableNotificationSheetTargets,
  ])

  const usageCount = useMemo(() => (
    usage.reduce((acc, item) => (
      acc + (item.payload.count || 0)
    ), 0)
  ), [ usage ])

  if (id && !notificationSheet) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="notificationSheetForm"
      dialog={dialog}
    >
      <Tabs
        className="mb-2"
        value={tab}
        options={[
          { value: 'settings', label: intl.t('notificationSheet.sections.settings') },
          { value: 'usage', label: `${intl.t('notificationSheet.sections.usage')} (${usageCount})` },
        ]}
        onChange={setTab}
      />

      {tab === 'settings' ? (
        <>
          <Section
            scope="notificationSheet"
          />
          <SectionTargets
            scope="notificationSheetTargets"
          />
        </>
      ) : null}

      {tab === 'usage' ? (
        <SectionUsage usage={usage} />
      ) : null}
    </FormDialog>
  )
}
