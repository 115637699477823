import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import { FormField, FormSelect } from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

const FieldHistogramType = ({ scope }) => {
  const intl = useIntl()
  const { formState: { errors }, watch } = useFormContext()
  const histogramType = watch(`${scope}.histogramType`)

  return (
    <FormField
      label={intl.t('widgets.histogram.form.fields.histogramType')}
      description={() => (
        <>
          {intl.t('widgets.histogram.form.fields.histogramTypeDescr')}
          {do {
            if (histogramType === consts.WidgetHistogramType.Linear) {
              intl.t('widgets.histogram.form.fields.histogramTypeLinearDescr')
            } else if (histogramType === consts.WidgetHistogramType.Logarithmic) {
              intl.t('widgets.histogram.form.fields.histogramTypeLogDescr')
            } else if (histogramType === consts.WidgetHistogramType.Custom) {
              intl.t('widgets.histogram.form.fields.histogramTypeCustomDescr')
            }
          }}
        </>
      )}
      errors={get(errors, `${scope}.histogramType`)}
    >
      <Controller
        name={`${scope}.histogramType`}
        render={({ field, fieldState }) => (
          <FormSelect
            {...field}
            invalid={fieldState.invalid}
            options={consts.WidgetHistogramTypes.map(id => ({ id, name: id }))}
            value={field.value || consts.WidgetHistogramType.Linear}
          />
        )}
      />
    </FormField>
  )
}

FieldHistogramType.propTypes = {
  scope: PropTypes.string.isRequired,
}

export default FieldHistogramType
