import { of } from 'rxjs'
import { validate } from 'uuid'
import { useQuery } from '@tanstack/react-query'
import { withObservables, withProps } from '@wiz/components'
import { dbProvider, Q } from '@wiz/store'
import { wizataApi } from '@/api'

const enhanceData = withObservables([ 'twins' ], ({ twins }) => {
  if (!twins) {
    return {
      options: of(undefined),
    }
  }

  return {
    options: dbProvider.database.collections
      .get('twins')
      .query(Q.where('id', Q.oneOf(twins)))
      .observeWithColumns([ 'updated_at' ]),
  }
})

const enhanceProps = withProps(({ templateId }) => {
  if (!templateId || (templateId && !validate(templateId))) {
    return {
      twins: undefined,
    }
  }

  const { data, isLoading } = useQuery({
    queryKey: [ 'twinsByTemplateId', templateId ],
    queryFn: () => wizataApi.templates.getRegistrationsById(templateId)
      .then(templates => templates.map(item => item.twinId)),
    enabled: !!templateId,
  })

  return {
    twins: data,
    isLoading,
  }
})

export default WrappedComponent => enhanceProps(enhanceData(WrappedComponent))
