import FieldName from './FieldName'
import FieldDescription from './FieldDescription'
import FieldColor from './FieldColor'
import FieldIcon from './FieldIcon'

export default function FieldsSystem ({ scope }) {
  return (
    <>
      <FieldName scope={scope} disabled />
      <FieldDescription scope={scope} />
      <FieldColor scope={scope} />
      <FieldIcon scope={scope} />
    </>
  )
}
