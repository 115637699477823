import { FormSection } from '@wiz/components'
import FieldsGeneral from './FieldsGeneral'

export default function SectionList ({ scope, title, addon }) {
  return (
    <FormSection
      title={title}
      addon={addon}
    >
      <FieldsGeneral scope={scope} />
    </FormSection>
  )
}
