import { useFormContext, Controller, useWatch } from 'react-hook-form'
import {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react'
import { Tabs, DropdownMenu, FormAny } from '@wiz/components'
import { Dashboard, Sensor } from '@wiz/store'
import { uuid, get, has } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { RIGHTS } from '@/config'
import { useAuth } from '@/auth'
import ListSensorsByTwin from '@/containers/Form/ListSensorsByTwin'
import ListDashboardsWidgets from '@/components/Form/ListDashboardsWidgets'
import ObserveModel from '@/containers/ObserveModel'

export default function Section ({
  params,
  sensorIds,
  defaultValues,
}) {
  const intl = useIntl()
  const auth = useAuth()
  const {
    setValue,
    formState: { errors },
  } = useFormContext()
  const refWidgets = useRef()
  const [ list, setList ] = useState(null)
  const [ selectedDashboard, setSelectedDashboard ] = useState(null)
  const [ tab, setTab ] = useState('dataPoints')
  const dashboards = useWatch({
    name: 'dashboards',
    defaultValue: defaultValues.dashboards,
  })
  const sensors = useWatch({
    name: 'sensors',
    defaultValue: defaultValues.sensors,
  })
  const disabledWidgets = useWatch({
    name: 'disabledWidgets',
    defaultValue: defaultValues.disabledWidgets,
  })
  const newDashboards = useWatch({
    name: 'newDashboards',
    defaultValue: defaultValues.newDashboards,
  })

  const widgets = useMemo(() => {
    const sensorWidgets = sensors
      .map(sensorId => ({
        id: `sensor/${sensorId}`,
        label: sensorId,
        icon: 'fa--dot-circle',
        type: 'sensor',
        sensorId,
      }))

    return [].concat(
      ...(params?.widgets ?? []),
      ...(sensors?.length ? [{
        id: 'chart',
        label: 'Chart',
        icon: 'fa--chart-area',
        type: 'chart',
      }] : []),
      sensorWidgets,
    )
  }, [ params, sensors ])

  const currentSelectedWidgets = useMemo(() => {
    const disabled = disabledWidgets[selectedDashboard] ?? []
    return widgets.filter(item => !disabled.includes(item.id)).map(item => item.id)
  }, [ widgets, selectedDashboard, disabledWidgets ])

  const handleChangeSensor = useCallback((sensorId) => {
    const next = sensors.includes(sensorId) ?
      sensors.filter(item => item !== sensorId) :
      sensors.concat(sensorId)
    setValue('sensors', next, { shouldDirty: true })
  }, [ sensors, setValue ])

  const handleChangeDashboard = useCallback((id) => {
    const next = dashboards.includes(id) ?
      dashboards.filter(item => item !== id) :
      dashboards.concat(id)
    setValue('dashboards', next, { shouldDirty: true })
  }, [ dashboards, setValue ])

  const handleClickDashboard = useCallback((id, event) => {
    setSelectedDashboard(id)
    setList(event.currentTarget)
  }, [])

  const handleChangeSelectedWidgets = useCallback((id) => {
    const disabled = disabledWidgets[selectedDashboard] ?? []
    const next = {
      ...disabledWidgets,
      [selectedDashboard]: disabled.includes(id) ?
        disabled.filter(item => item !== id) :
        disabled.concat(id),
    }
    setValue('disabledWidgets', next, { shouldDirty: true })
  }, [ setValue, selectedDashboard, disabledWidgets ])

  const handleAddDashboard = useCallback(() => {
    const next = newDashboards.concat(Dashboard.toJSON({
      id: uuid(),
      title: '',
    }))
    setValue('newDashboards', next, { shouldDirty: true })
  }, [ newDashboards, setValue ])

  const handleRemoveDashboard = useCallback((id) => {
    const next = newDashboards.filter(item => item.id !== id)
    setValue('newDashboards', next, { shouldDirty: true })
  }, [ newDashboards, setValue ])

  const handleUpdateTitleDashboard = useCallback((id, title) => {
    const next = newDashboards.map(item => (
      item.id === id ? { ...item, title: String(title).trim() } : item
    ))
    setValue('newDashboards', next, { shouldDirty: true })
  }, [ newDashboards, setValue ])

  useEffect(() => {
    // wait to update DOM
    const timeout = list ? window.setTimeout(() => refWidgets.current.open(), 0) : 0
    return () => window.clearTimeout(timeout)
  }, [ list ])

  return (
    <>
      <Controller
        name="dashboards"
        render={({ field }) => (<FormAny {...field} />)}
      />

      <Controller
        name="newDashboards"
        render={({ field }) => (<FormAny {...field} />)}
      />

      <Controller
        name="sensors"
        render={({ field }) => (<FormAny {...field} />)}
      />

      <Controller
        name="disabledWidgets"
        render={({ field }) => (<FormAny {...field} />)}
      />

      <Tabs
        value={tab}
        options={[
          { value: 'dataPoints', label: intl.t('form.fields.dataPoints') },
          { value: 'dashboards', label: intl.t('dashboards.title') },
        ]}
        onChange={setTab}
      />

      {do {
        if (tab === 'dataPoints') {
          if (sensorIds.length) {
            <ListSensorsByTwin
              className="d-flex flex-fill min-h-0 mt-3"
              sensorIds={sensorIds}
              value={sensors}
              onChange={handleChangeSensor}
            />
          } else {
            <div className="d-flex flex-fill align-items-center justify-content-center">
              {intl.t('errors.noDataDisplay')}
            </div>
          }
        } else if (tab === 'dashboards') {
          <>
            {auth.checkAccessCreate('Dashboard') ? (
              <div className="my-3">
                <button
                  type="button"
                  className="btn btn-fill-secondary"
                  onClick={handleAddDashboard}
                  disabled={newDashboards.length > 2}
                >
                  {intl.t('dashboards.form.actions.new')}
                </button>
              </div>
            ) : null}

            {newDashboards.map((item, idx) => (
              <div key={item.id} className="mx-3 mb-2">
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    className="form-check-input mt-0 me-2 flex-shrink-0"
                    checked={dashboards.includes(item.id)}
                    onChange={() => handleChangeDashboard(item.id)}
                  />
                  <input
                    type="text"
                    className="form-control form-control-sm me-2"
                    placeholder={intl.t('dashboards.form.fields.titlePlaceholder')}
                    defaultValue={item.title}
                    onBlur={event => handleUpdateTitleDashboard(item.id, event.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-sm btn-flat-secondary"
                    onClick={() => handleRemoveDashboard(item.id)}
                  >
                    {intl.t('form.actions.remove')}
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-flat-secondary"
                    onClick={event => handleClickDashboard(item.id, event)}
                  >
                    {intl.t('dashboards.form.fields.widgets')}
                  </button>
                </div>
                {has(errors, `newDashboards.${idx}.title`) ? (
                  <div className="small text-danger">
                    {get(errors, `newDashboards.${idx}.title.message`)}
                  </div>
                ) : null}
              </div>
            ))}

            <ListDashboardsWidgets
              className="d-flex flex-fill min-h-0"
              access={RIGHTS.UPDATE}
              value={dashboards}
              onChange={handleChangeDashboard}
              onClick={handleClickDashboard}
            />
          </>
        }
      }}

      <ObserveModel model={Sensor} value={sensors}>
        {({ data }) => {
          const hash = data.reduce((out, item) => ({
            ...out,
            [item.id]: item,
          }), {})

          return (
            <DropdownMenu
              ref={refWidgets}
              target={list}
              placeholder={intl.t('errors.noDataDisplay')}
              mode={null}
              arrow
              selectable
              options={widgets.map(item => (
                item.sensorId ? {
                  ...item,
                  label: hash[item.sensorId]?.displayName || item.label,
                } : item
              ))}
              value={currentSelectedWidgets}
              onChange={handleChangeSelectedWidgets}
              onHide={() => {
                setList(null)
                setSelectedDashboard(null)
              }}
            />
          )
        }}
      </ObserveModel>
    </>
  )
}
