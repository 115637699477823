import { useCallback, useMemo } from 'react'
import {
  DiagramBlock,
  DiagramBlockSns,
} from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import BlockSection from '../Block/Section'
import SettingsSection from './SettingsSection'

export default function BlockSns ({
  block,
  settings,
  dialog,
  onClose,
  onSubmit,
}) {
  const defaultValues = useMemo(() => ({
    block: DiagramBlock.toJSON(block),
    settings: DiagramBlockSns.toJSON(settings),
    subscribers: settings ? JSON.parse(settings.subscribers) : [],
  }), [
    block,
    settings,
  ])

  const handleSubmit = useCallback((data) => {
    const { subscribers } = data
    const next = { ...data }
    const nextSubs = subscribers.map(({ id, ...rest }) => ({ ...rest }))
    // FiXME: subscibers should be inset to settings
    next.settings.subscribers = JSON.stringify(nextSubs)
    delete next.subscribers

    onSubmit(next)
  }, [ onSubmit ])

  return (
    <FormDialog
      onSubmit={handleSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="blockSnsForm"
      dialog={dialog}
    >
      <BlockSection scope="block" />
      <SettingsSection scope="settings" />
    </FormDialog>
  )
}
