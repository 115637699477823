import { useCallback } from 'react'
import { withProps } from '@wiz/components'
import { wizataApi } from '@/api'
import events from '@/utils/events'
import withTableActions from '@/containers/withTableActions'
import Component from '@/components/ExecutionScripts/List'

const enhanceProps = withProps(() => {
  const onRemove = useCallback(async (id) => {
    try {
      await wizataApi.entities.deleteById('scripts', id)
    } catch (error) {
      events.emit('app:notify', {
        type: 'error',
        title: 't/scripts.title',
        message: 't/scripts.form.errors.remove',
      })
    }
  }, [])

  return {
    onRemove,
  }
})

export default withTableActions({
  defaultFilters () {
    return {
      sortBy: 'createdDate',
    }
  },
})(
  enhanceProps(Component),
)
