import {
  useRef,
  useState,
  useCallback,
} from 'react'
import _ from 'lodash'
// import { far } from '@awesome.me/kit-9065c4792f/icons'
import { of as of$ } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import {
  withProps,
  withObservables,
  useDidUpdate,
  useForceUpdate,
} from '@wiz/components'
import { getIconByName } from '@wiz/icons'
import { dbProvider, Q, DiagramState } from '@wiz/store'
import { sanitizeGraphState, mergeGraphState } from '@wiz/utils'
import defaultDiagramState from '@/utils/defaultDiagramState'
import Component from '@/components/PhysicalTwin'

const ColorByType = {
  area: 'twinColorAreas',
  machine: 'twinColorMachines',
  equipment: 'twinColorEquipment',
}

const enhanceData = withObservables([], () => ({
  twinSettings: dbProvider.observeGlobalSettings([
    'twinColorAreas',
    'twinColorMachines',
    'twinColorEquipment',
  ]),
}))

const enhanceTreeProps = withProps(({
  value,
}) => {
  const [ twinTree, setTwinTree ] = useState(value)
  return {
    twinTree,
    setTwinTree,
  }
})

const enhanceTwinsChain = withObservables([ 'twinTree' ], ({ twinTree }) => ({
  twinsChain: twinTree ? dbProvider.database.collections.get('twins')
    .query(Q.where('id', twinTree))
    .observeWithColumns([ 'updated_at' ])
    .pipe(
      switchMap(([ item ]) => (item ? item.observeTwinsChain : of$([]))),
      map(items => items.reverse()),
    ) : of$([]),
}))

const enhanceDiagramState = withObservables([ 'twinsChain' ], ({ twinsChain }) => ({
  rootTwin: of$(twinsChain[0]),
  diagramState: DiagramState.observePhysicalTwinDiagramState(
    dbProvider.database,
    twinsChain[0],
  ).pipe(
    defaultDiagramState(twinsChain[0]),
  ),
}))

const enhanceProps = withProps(({
  diagramState,
  twinSettings,
  rootTwin,
}) => {
  const refState = useRef(diagramState)
  const refStateIgnore = useRef(false)
  const [ linkMode, setLinkMode ] = useState(null)
  const forceUpdate = useForceUpdate()

  const blockFactory = useCallback(async (block) => {
    const color = block.color || twinSettings[ColorByType[block.type]]
    let icon = 'Rectangle'
    let size = '40 40'
    // if (block.icon?.indexOf('--') === -1) {
    //   const f = far[block.icon]
    //   icon = _.startCase(`fa--${f.iconName}`).replaceAll(' ', '')

    //   return {
    //     ...block,
    //     icon,
    //     size,
    //     color,
    //   }
    // }

    const iconSource = block.icon && getIconByName(block.icon)

    if (iconSource) {
      icon = iconSource.sprite
      size = `${iconSource.width} ${iconSource.height}`
    }

    return {
      ...block,
      icon,
      size,
      color,
    }
  }, [ twinSettings ])

  const onChangeDiagram = useCallback(async (data) => {
    refStateIgnore.current = true
    refState.current = mergeGraphState(refState.current, sanitizeGraphState(data))
    const context = dbProvider.createBatchContext()

    await DiagramState.prepareReplacePhysicalTwinDiagramState(
      context,
      dbProvider.database,
      refState.current,
      rootTwin,
    )

    await dbProvider.batch(context)
  }, [ rootTwin ])

  const onToggleGrid = useCallback(() => (
    onChangeDiagram({
      diagram: {
        hideGrid: !refState.current?.diagram?.hideGrid,
      },
    })
  ), [ onChangeDiagram ])

  useDidUpdate(() => {
    if (refStateIgnore.current) {
      refStateIgnore.current = false
    } else {
      refState.current = diagramState
    }
  }, [ diagramState ])

  useDidUpdate(() => {
    refState.current = diagramState
    forceUpdate()
  }, [ rootTwin?.id ])

  return {
    blockFactory,
    linkMode,
    onChangeDiagram,
    onToggleGrid,
    rootTwin,
    setLinkMode,
    state: refState.current,
  }
})

export default enhanceData(
  enhanceTreeProps(
    enhanceTwinsChain(
      enhanceDiagramState(
        enhanceProps(Component),
      ),
    ),
  ),
)
