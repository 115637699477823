import { memo } from 'react'
import classnames from 'classnames'
import { DateTime } from 'luxon'
import { Icon } from '@wiz/components'
import { round } from '@wiz/utils'
import classes from './Legend.css'

const Legend = ({
  orient,
  showMillisecond,
  options = [],
  dir,
  collapsed,
  onToggle,
}) => {
  const isVertical = orient === 'top' || orient === 'bottom'
  const isHorizontal = orient === 'left' || orient === 'right'
  let hoverDate = options.find(item => item.hoverData?.time)?.hoverData?.time
  if (hoverDate) {
    hoverDate = DateTime.fromMillis(hoverDate)
  }

  return (
    <div className="d-flex flex-fill flex-column justify-content-between min-w-0">
      <div
        className={classnames('d-flex min-w-0', {
          'flex-column': !isVertical,
          'flex-wrap': isVertical,
        })}
      >
        {options.map(item => (
          <button
            key={item.id}
            type="button"
            className={classnames(classes.item, 'btn p-0 text-start min-w-0 outline-none align-items-center align-self-stretch', {
              'd-flex': isHorizontal,
              'd-inline-flex me-2': isVertical,
              'opacity-50': !item.selected,
              [classes.itemRtl]: orient === 'right',
            })}
            onClick={() => onToggle?.(item)}
          >
            <Icon
              name={item.error ? 'fa--exclamation-circle' : 'fa--circle'}
              size="xs"
              className={classnames(classes.icon, 'rounded-circle', {
                'me-2': orient !== 'right' && !collapsed,
                'ms-2': orient === 'right' && !collapsed,
              })}
              color={item.color}
              title={item.error?.message}
            />
            {collapsed && !isVertical ? <>&nbsp;</> : (
              <>
                <span
                  className={classnames('text-truncate', {
                    'flex-fill': !isVertical,
                    'text-danger': !!item.error,
                  })}
                >
                  {item.name}
                </span>
                {item.hoverData ? (
                  <span
                    className={classnames('font-monospace', {
                      'me-2': orient === 'right',
                      'ms-2': orient !== 'right',
                    })}
                  >
                    {round(item.hoverData.value, item.valuePrecision, true)}
                  </span>
                ) : null}
              </>
            )}
          </button>
        ))}
      </div>
      {hoverDate && !collapsed ? (
        <small dir={dir}>
          {hoverDate.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
          {showMillisecond ? hoverDate.toFormat('.S') : null}
        </small>
      ) : null}
    </div>
  )
}

export default memo(Legend)
