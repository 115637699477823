import { useState, useMemo, useRef } from 'react'
import classnames from 'classnames'
import { Icon } from '@wiz/components'
import Observe from '@/containers/Observe'
import Dropdown from '@/components/Form/Select/Dropdown'
import TwinList from '@/hoc/TwinList'

export default function Breadcrumb ({
  className,
  options,
  onChange,
}) {
  const refDropdown = useRef()
  const [ parentId, setParentId ] = useState(null)
  const [ dropdownTarget, setDropdownTarget ] = useState(null)
  const filters = useMemo(() => ({ parentId, withSensors: false }), [ parentId ])

  return (
    <nav aria-label="breadcrumb" className={className}>
      <ol className="breadcrumb m-0">
        {options.map((item, idx) => {
          const last = idx === options.length - 1
          return (
            <li
              key={item.id}
              className={classnames('breadcrumb-item', { active: last })}
              aria-current="page"
            >
              <button
                type="button"
                className="d-inline btn btn-link text-muted text-truncate p-0"
                onClick={() => onChange?.(item.id)}
              >
                {item.name}
              </button>

              <Observe count={item.queryChildrenCount}>
                {({ data }) => (data ? (
                  <button
                    type="button"
                    className="btn btn-sm text-muted p-1"
                    onClick={(event) => {
                      setDropdownTarget(event.currentTarget)
                      setParentId(item.id)
                    }}
                  >
                    <Icon name="fa--chevron-down" size="sm" />
                  </button>
                ) : null)}
              </Observe>
            </li>
          )
        })}
      </ol>

      <Dropdown
        ref={refDropdown}
        refTarget={dropdownTarget}
        filters={filters}
        ListSource={parentId ? TwinList : undefined}
        placement="bottom"
        onChange={(twin) => {
          refDropdown.current.close()
          onChange?.(twin.id)
        }}
      />
    </nav>
  )
}
