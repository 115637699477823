import { dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'
import Component from '@/components/Form/SmsConsumption'

const enhanceSettings = withObservables([], () => ({
  settings: dbProvider.observeGlobalSettings([
    'SentSmsCount',
    'FreeSmsLimit',
    'PaidFunctionalityConfirm',
    'PaidSmsLimit',
  ]),
}))

export default enhanceSettings(Component)
