import { of as of$ } from 'rxjs'
import { map, distinctUntilChanged } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { isEqual } from '@wiz/utils'

export default withObservables([ 'accessRole' ], ({ accessRole }) => ({
  groupAccessRoleIds: accessRole ? accessRole.observeGroupAccessRoles.pipe(
    map(items => items.map(item => item.id)),
  ) : of$([]).pipe(distinctUntilChanged(isEqual)),

  accessRoleIds: accessRole ? (
    accessRole.observeAccessRoles.pipe(
      map(items => items.map(item => item.id)),
    )
  ) : of$([]).pipe(distinctUntilChanged(isEqual)),
}))
