import PropTypes from 'prop-types'
import UpdatedInfo from '@/components/Form/UpdatedInfo'

export default function Content ({
  data,
  context,
}) {
  const { onAction } = context
  const isMs = onAction('dateFormatMs')

  return (
    <div className="d-flex flex-column flex-fill min-w-0">
      <div className="d-flex align-items-baseline mb-1 fw-light">
        <span className="me-2 text-truncate">
          {data.logSource}
        </span>

        <span className="me-2 text-truncate">
          {data.userEmail}
        </span>

        <UpdatedInfo
          className="ms-auto me-2 flex-shrink-0 small"
          date={data.timestamp}
          withMillis={isMs}
          withSecond
        />
      </div>

      <span className="text-truncate">
        {data.message}
      </span>
    </div>
  )
}

Content.propTypes = {
  data: PropTypes.shape({
    timestamp: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    logSource: PropTypes.string.isRequired,
    userEmail: PropTypes.string,
  }).isRequired,
}
