import { useState } from 'react'
import classnames from 'classnames'
import { Icon } from '@wiz/components'
import classes from './index.css'

const ActionBack = ({ onClick }) => (
  <button
    type="button"
    className="btn btn-sm btn-text btn-link py-0 px-1 mx-1 text-reset"
    onClick={(event) => {
      event.stopPropagation()
      onClick?.(event)
    }}
  >
    <Icon name="fa--chevron-left" />
  </button>
)

const Action = ({
  title,
  actions,
  icon,
  name,
  onClick,
}) => (
  <button
    type="button"
    title={title}
    className={classnames('btn btn-sm btn-text py-0 px-1', {
      [classes.action]: !actions,
      'btn-fill-secondary me-1': !actions,
      'btn-link mx-1 text-reset': !!actions,
    })}
    onClick={(event) => {
      event.stopPropagation()
      onClick?.(event)
    }}
  >
    {icon ? <Icon name={icon} /> : null}
    {name}
  </button>
)

const ComponentActions = {
  back: ActionBack,
}

export default function Actions ({
  children,
  className,
  classNameList,
  classNameContent,
  actions = [],
  exactions = [],
  isActive,
  onClick,
}) {
  const [ more, setMore ] = useState(null)
  const items = more ? actions.find(item => item.id === more).actions : actions

  return (
    <div className={classnames('position-absolute-fill', classes.root, className)}>
      <div
        className={classnames('list-group-item', classes.content, classNameContent, {
          'bg-primary': !!isActive,
          'bg-light': !isActive,
        })}
      >
        {children}

        <div className={classnames(classes.list, classNameList)}>
          {exactions
            .concat(more ? { id: 'back', onClick: () => setMore(null) } : [])
            .map((item) => {
              const ComponentAction = ComponentActions[item.id] || Action
              return (
                <ComponentAction {...item} key={item.id} />
              )
            })}

          {items.map(item => (
            <Action
              {...item}
              key={item.id}
              onClick={item.actions ? () => {
                setMore(item.id)
              } : () => {
                setMore(null)
                onClick?.(item)
              }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
