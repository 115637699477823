import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Table as StaticTable, Pagination } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { Script } from '@wiz/store'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'

export default function Table ({
  items,
  onRemove,
  onEdit,
  onDuplicate,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      minWidth: 300,
      Cell: ({ cell, row }) => (
        <div className="min-w-0">
          <a
            className="text-truncate pointer"
            onClick={() => auth.checkAccessUpdate(row.original) && onEdit?.(row.original.id)}
            aria-hidden
          >
            { cell.value || (
            <span className="text-muted">{intl.t('form.noname')}</span>
            )}
          </a>

          {row.original.description ? (
            <div className="font-italic text-muted text-truncate small">
              {row.original.description}
            </div>
          ) : null}
        </div>
      ),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedAt',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 220,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.updatedById}
          user={row.original.updatedUser}
        />
      ),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdAt',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 220,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.createdById}
          user={row.original.createdUser}
        />
      ),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 130,
      width: 130,
      maxWidth: 130,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell, row }) => (
        <>
          {auth.checkAccessUpdate(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0 me-2"
              onClick={() => onEdit?.(cell.value)}
            >
              {intl.t('form.actions.edit')}
            </button>
          ) : null}
          {auth.checkAccessCopy(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0 me-2"
              onClick={() => onDuplicate?.(row.original)}
            >
              {intl.t('form.actions.duplicate')}
            </button>
          ) : null}
          {auth.checkAccessRemove(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0"
              onClick={() => onRemove?.([ row.original ])}
            >
              {intl.t('form.actions.remove')}
            </button>
          ) : null}
        </>
      ),
    },
  ], [ onEdit, onRemove, intl, onDuplicate, auth ])

  return (
    <>
      <StaticTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={data}
        selection={auth.checkAccessRemove('Script')}
        {...props}
      />

      <Pagination {...props} />
    </>
  )
}

Table.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(Script)),
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
}

Table.defaultProps = {
  items: [],
  onRemove: undefined,
  onEdit: undefined,
}
