import { useEffect, useMemo } from 'react'
import classnames from 'classnames'
import { VirtualTable, Pagination, Icon as OldIcon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import useAppContext from '@/hooks/useAppContext'
import Observe from '@/containers/Observe'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import Icon from '@/shared/icon'
import EditButton from '../../shared/editButton'

function SelectionWrapComponent ({ row, children }) {
  const intl = useIntl()
  return (
    <Observe observe={row.original.observeActiveUsage}>
      {({ data }) => (data ? (
        <input
          className="form-check-input mt-0"
          type="checkbox"
          title={data ? intl.t('mlWebServices.disableActiveUsage') : undefined}
          disabled
        />
      ) : children)}
    </Observe>
  )
}

export default function Table ({
  items,
  onRemove,
  onEdit,
  onDuplicate,
  filters,
  refetch,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const { onRowSelect } = useAppContext()

  const columns = useMemo(() => [
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      width: 30,
      maxWidth: 30,
      className: 'justify-content-center text-nowrap',
      Cell: ({ cell, row }) => (
        <Observe observe={row.original.observeActiveUsage}>
          {({ data }) => (
            <>
              {auth.checkAccessUpdate('Label') ? (
                <EditButton cell={cell} onEdit={onEdit} />
              ) : null}
              {/* {auth.checkAccessCopy(row.original) ? (
                <button
                  type="button"
                  className="btn btn-sm p-0 me-2"
                  onClick={() => onDuplicate?.(row.original)}
                >
                  {intl.t('form.actions.duplicate')}
                </button>
              ) : null}
              {auth.checkAccessRemove(row.original) ? (
                <button
                  type="button"
                  className="btn btn-sm p-0"
                  disabled={data}
                  title={data ? intl.t('mlWebServices.disableActiveUsage') : undefined}
                  onClick={() => onRemove?.([ row.original ])}
                >
                  {intl.t('form.actions.remove')}
                </button>
              ) : null} */}
            </>
          )}
        </Observe>
      ),
    },
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      minWidth: 300,
      Cell: ({ cell, row }) => {
        const allowed = auth.checkAccessUpdate(row.original)
        return (
          <a
            className={classnames('d-flex align-items-center min-w-0', {
              'link pointer': allowed,
            })}
            onClick={() => (allowed ? onEdit?.(row.original.id) : undefined)}
            aria-hidden
          >
            {(row.original.icon || row.original.color) && row.original?.icon?.includes('cus-') ? (
              <OldIcon
                name={row.original.icon}
                color={row.original.color}
                className="me-2"
              />
            ) : (
              <Icon
                name={row.original.icon}
                color={row.original.color}
                className="me-2"
              />
            )}

            <div className="text-truncate">
              {cell.value}
              {row.original.description ? (
                <div className="font-italic text-muted text-truncate small">
                  {row.original.description}
                </div>
              ) : null}
            </div>
          </a>
        )
      },
    },
    {
      Header: intl.t('form.fields.type'),
      accessor: 'type',
      disableResizing: true,
      disableSortBy: true,
      width: 100,
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedDate',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={row.original.updatedDate}
          userId={row.original.updatedById}
          user={row.original.updatedUser}
        />
      ),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdDate',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={row.original.createdDate}
          userId={row.original.createdById}
          user={row.original.createdUser}
        />
      ),
    },
  ], [ intl, onEdit, onRemove, onDuplicate, auth ])

  useEffect(() => {
    if (items && !props.loading) {
      refetch()
    }
  }, [
    filters.search,
    filters.page,
    filters.pageSize,
    filters.sortDir,
    filters.sortBy,
  ])

  return (
    <>
      <VirtualTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={auth.checkAccessRemove('Label') || auth.checkAccessCreate('Label')}
        SelectionWrapComponent={SelectionWrapComponent}
        {...props}
        onSelect={onRowSelect}
      />

      <Pagination {...props} />
    </>
  )
}
