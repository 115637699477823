import { useFormContext } from 'react-hook-form'
import { useIntl } from '@wiz/intl'
import Section from '@/components/Forms/Sns/Section'
import SectionSubscribers from '@/components/Forms/Sns/SectionSubscribers'

export default function SettingsSection ({ scope }) {
  const intl = useIntl()
  const {
    register,
    watch,
  } = useFormContext()
  const id = watch(`${scope}.block.id`)

  return (
    <>
      <input
        {...register(`${scope}.id`)}
        type="hidden"
        defaultValue={id}
      />

      <Section
        scope={scope}
        registerId={false}
        title={intl.t('blockSns.section.sns')}
        description={intl.t('blockSns.section.snsDescr')}
      />
      <SectionSubscribers scope="subscribers" />
    </>
  )
}
