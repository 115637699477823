import { of as of$ } from 'rxjs'
import { withObservables } from '@wiz/components'
import observePlotlyChart from '@/utils/observePlotlyChart'
import Component from '../components/Plotly'

const enhanceRealtimeData = withObservables([
  'widgetId',
  'config',
  'hidden',
  'theme',
], ({
  widgetId,
  config,
  hidden,
  theme,
}) => {
  if (!hidden &&
    config.dataSources.length) {
    const result = observePlotlyChart(widgetId, {
      theme,
    })
    return {
      data: result,
    }
  }
  return {
    data: of$(undefined),
  }
})

export default enhanceRealtimeData(Component)
