import { Q, dbProvider } from '@wiz/store'
import { toArrayValue } from '@wiz/utils'
import { withObservables } from '@wiz/components'

const enhanceData = withObservables([ 'filters' ], ({ filters }) => {
  let query = dbProvider.database.collections.get('quality_data_templates')
    .query()

  if (filters.search) {
    const searchSanitize = Q.sanitizeLikeString(filters.search)
    query = query.extend(Q.or(
      Q.where('id', filters.search),
      Q.where('name', Q.like(`%${searchSanitize}%`)),
    ))
  }

  if (filters.twins?.length) {
    query = query.extend(Q.on(
      'rel_twins_quality_data_templates',
      Q.where('twin_id', Q.oneOf(toArrayValue(filters.twins))),
    ))
  }

  return {
    items: query.observeWithColumns([ 'updated_at' ]),
  }
})

export default enhanceData
