import { useMemo } from 'react'
import { Duration } from 'luxon'

const DefaultParts = [
  'days',
  'hours',
  'minutes',
  'seconds',
  'milliseconds',
]
const DefaultPartsMap = {
  years: 'y',
  months: 'm',
  weeks: 'w',
  days: 'd',
  hours: 'h',
  minutes: 'm',
  seconds: 's',
  milliseconds: 'ms',
}

function valueToObject (value, parts, iso) {
  let val = value || 0
  if (Number.isInteger(val)) {
    val = Duration.fromMillis(val)
      .shiftTo('hours', 'minutes', 'seconds', 'milliseconds')
      .toObject()
  } else if (typeof val === 'string' && iso) {
    val = Duration.fromISO(val)
      .shiftTo(parts.join(','))
      .toObject()
  } else {
    val = {}
  }
  return val
}

const CellTime = ({
  value, signed = false, iso = false, parts,
}) => {
  const duration = useMemo(() => {
    const obj = valueToObject(value, parts?.split(',') ?? DefaultParts, iso)
    // for (const key in obj) {
    //   if (!(
    //     Number.isInteger(obj[key]) &&
    //     ((signed && obj[key] !== 0) || obj[key] > 0)
    //   )) {
    //     obj[key] = undefined
    //   }
    // }

    return obj
  }, [ value, signed, iso, parts ])
  // {days: 140, hours: 7, minutes: 32, seconds: undefined}
  const resultString = useMemo(() => Object.entries(duration).reduce((acc, [ key, val ]) => {
    if (!val) {
      return acc
    }
    acc += Math.round(val) + DefaultPartsMap[key]
    return acc
  }, ''), [ duration ])
  return <div>{resultString || value || '--'}</div>
}

export default CellTime
