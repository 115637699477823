import { useMemo } from 'react'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import Section from './Section'

export default function HistoricalRun ({
  id,
  item,
  dialog,
  onClose,
}) {
  const defaultValues$ = useMemo(() => ({
    execution: item,
  }), [ item ])

  if (id && !item) {
    return <Removed />
  }

  return (
    <FormDialog
      onClose={onClose}
      defaultValues={defaultValues$}
      dataTestid="executionForm"
      dialog={dialog}
    >
      <Section scope="execution" />
    </FormDialog>
  )
}
