import { Ability, ForbiddenError } from '@casl/ability'
import { AccessPermission } from '@wiz/store'
import { isEmpty } from '@wiz/utils'
import { RIGHTS } from '@/config'
import { AccessRule } from './AccessRule'

// Ability.addAlias(RIGHTS.SHARE, [
//   RIGHTS.READ,
//   RIGHTS.UPDATE
// ])

export default class Access {
  abilities = {}

  /**
   * [{}, {}] OR
   * [[{}, {}]] AND
   */
  checkAccess (action, subject, field) {
    if (Array.isArray(action) && !subject) {
      for (let i = 0, len = action.length; i < len; i += 1) {
        const rule = action[i]
        const hasAccess = Array.isArray(rule) ?
          rule.every(item => this.checkAccess(item.actions, item.subject, item.field)) : // AND
          this.checkAccess(rule.actions, rule.subject, rule.field) // OR

        if (hasAccess) {
          return true
        }
      }

      return false
    }

    const abilities = this.abilities?.[this.subjectName(subject)] ?? this.abilities?.all
    if (Array.isArray(abilities)) {
      for (let i = 0; i < abilities.length; i += 1) {
        if (abilities[i].can(action, subject, field)) {
          return true
        }
      }
    }

    return false
  }

  /**
   * [{}, {}] OR
   * [[{}, {}]] AND
   */
  throwAccess (action, subject, field) {
    if (Array.isArray(action) && !subject) {
      for (let i = 0; i < action.length; i += 1) {
        const rule = action[i]
        if (Array.isArray(rule)) { // AND
          for (let j = 0; j < action.length; j += 1) {
            const item = rule[j]
            this.throwAccess(item.actions, item.subject, item.field)
          }
        } else { // OR
          this.throwAccess(rule.actions, rule.subject, rule.field)
        }
      }
    } else {
      const name = this.subjectName(subject)
      const abilities = this.abilities?.[name] ?? this.abilities?.all
      if (Array.isArray(abilities)) {
        for (let i = 0; i < abilities.length; i += 1) {
          if (abilities[i].can(action, subject, field)) {
            return
          }
        }
      }

      throw new ForbiddenError('No rights to perform this operation.', {
        subject,
        action,
      })
    }
  }

  hasAbilities () {
    return !isEmpty(this.abilities)
  }

  updateAbilities (user, roles, flags) {
    this.abilities = this.getAccessRules(user, roles, flags)
  }

  resetAbilities () {
    this.abilities = undefined
  }

  // eslint-disable-next-line class-methods-use-this
  getAllRoles (roles, hash) {
    const out = []
    const items = [].concat(roles)
    let id

    while ((id = items.shift())) {
      const role = hash[id]
      if (role) {
        out.push(role)
        items.unshift.apply(items, role.roleIds)
      }
    }

    return out
  }

  getAccessRules (user, roles = [], flags = {}) {
    if (!user) {
      return {}
    }

    const context = {
      currentUserId: user.id,
      rights: RIGHTS,
    }

    const rolesHash = roles.reduce((out, role) => ({ ...out, [role.id]: role }), {})
    const userRoles = this.getAllRoles(user.roleIds, rolesHash)

    let abilities = userRoles
      .reduce((out, role) => (
        role.permissions
          .reduce((out, item) => {
            AccessPermission.createRules(item, context).forEach(rule => {
              out[rule.subject] = out[rule.subject] || {}
              out[rule.subject][role.id] = out[rule.subject][role.id] || []
              out[rule.subject][role.id].push(rule)
            })

            return out
          }, out)
      ), {

        User: {
          default: AccessPermission.createRules({
            subject: [ 'User' ],
            actions: [ RIGHTS.READ ],
            conditions: [ 'specifiedInstance' ],
            // eslint-disable-next-line no-template-curly-in-string
            context: { ids: [ '${currentUserId}' ] },
          }, context),
        },
      })

    if (!flags.DeviceControl) {
      delete abilities.SectionDeviceCommands
      delete abilities.SectionDeviceCommandTemplates
    }

    // { Subject: [ [Ability1, Ability2], [Ability3] ] }
    abilities = Object.keys(abilities)
      .reduce((out, item) => {
        out[item] = Object.values(abilities[item]).map(item => (
          new Ability(item, { RuleType: AccessRule })
        ))
        return out
      }, {})

    return abilities
  }

  // eslint-disable-next-line class-methods-use-this
  subjectName (subject) {
    if (!subject || typeof subject === 'string') {
      return subject
    }

    const Type = typeof subject === 'object' ? subject.constructor : subject
    return Type.modelName || Type.name
  }
}
