import { useEffect, useContext } from 'react'
import { withProps } from '@wiz/components'
import { dbProvider, EventSource, IExplorer } from '@wiz/store'
import DataExplorer from '@/context/DataExplorer'

export default withProps(({
  event,
  explorer,
  explorerContext,
  sensorIds,
  id,
  onChangeBack,
}) => {
  const deContext = useContext(DataExplorer)
  const explorerId = explorer?.id

  useEffect(() => {
    async function initialize () {
      if (id) {
        if (explorerId === id) {
          deContext.setCurrentContext(explorerContext)
        } else {
          onChangeBack()
        }
      } else {
        const next = {
          ...deContext.data,
          eventSources: [],
          dataSources: [],
          dataViews: [],
          conditions: [],
        }

        for (const sensorId of sensorIds.slice(0, 10)) {
          const source = await IExplorer.createDataSourceContext(
            dbProvider.database,
            { sensorId, dataType: 'avg' },
          )
          const view = await IExplorer.createDataViewContext(
            dbProvider.database,
            { sourceId: source.id, source },
          )
          next.dataSources.push(source)
          next.dataViews.push(view)
        }

        if (event) {
          next.eventSources.push(EventSource.toJSON({
            name: event.name,
            twinId: event.twinId,
            // https://wizata.atlassian.net/browse/DEV-2961
            streamJobId: event.twinId ? undefined : event.streamJobId,
            eventTypes: event.type,
            statuses: event.status,
            sensorIds: event.sensorIds,
            dataViews: next.dataViews.map(item => item.id),
          }))

          if (event.from) {
            const eventDuration = event.to ? (event.to - event.from) : 60 * 60 * 1000
            next.dataFilter = {
              ...next.dataFilter,
              dateFrom: event.from - eventDuration,
              dateTo: event.to ? (event.to + eventDuration) : Date.now(),
              duration: 0,
            }
          }
        }

        deContext.setCurrentContext(next)
      }
    }

    initialize()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ id, explorerId, sensorIds, event ])
})
