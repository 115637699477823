import { useCallback, useState } from 'react'
import { dbProvider } from '@wiz/store'
import { withProps, withObservables } from '@wiz/components'
import Component from '@/components/dashboards/LeftBar'

const enhanceSettings = withObservables([], () => ({
  settings: dbProvider.observeSettings([
    'homepageDefaultId',
    'lastOpened',
    'spotlightFavorites',
    'dashboardFilters',
  ]),
}))

const enhanceProps = withProps(({
  settings,
  openDashboard,
}) => {
  const [ filters, setFilters ] = useState({
    sortBy: 'alpha-down',
    filterBy: null,
    followedBy: null,
    ...settings.dashboardFilters,
  })

  const onFilter = useCallback(async (data) => {
    const next = {
      ...filters,
      ...data,
    }

    setFilters(next)
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareReplaceSetting(context, 'dashboardFilters', next)
    await dbProvider.batch(context)
  }, [ filters ])

  const onActionMenu = useCallback(async (action, dashboard) => {
    if (action.id === 'select') {
      const context = dbProvider.createBatchContext()
      await dbProvider.prepareReplaceSetting(context, 'homepageDefaultId', dashboard.id)
      await dbProvider.batch(context)
    } else if (action.id === 'favorite') {
      let favorites = await dbProvider.fetchSettings('spotlightFavorites')
      favorites = favorites.includes(dashboard.id) ?
        favorites.filter(item => item !== dashboard.id) :
        favorites.concat(dashboard.id)

      const context = dbProvider.createBatchContext()
      await dbProvider.prepareReplaceSetting(context, 'spotlightFavorites', favorites)
      await dbProvider.batch(context)
    } else if (action.id === 'duplicate') {
      const context = dbProvider.createBatchContext()
      const model = await dbProvider.prepareDuplicateModel(context, dashboard, null, {
        uniqProps: [ 'title' ],
      })
      await dbProvider.batch(context)
      await window.wizConfirm({
        title: 't/form.success.duplicated',
        message: 't/dashboards.successDuplication',
      })
      openDashboard(model)
    } else if (action.id === 'remove') {
      await window.wizConfirm({ message: 't/units.confirmDelete' })
      const context = dbProvider.createBatchContext()
      await dashboard.prepareRemove(context)
      await dbProvider.batch(context)
    }
  }, [ openDashboard ])

  return {
    filters,
    onFilter,
    onActionMenu,
  }
})

export default enhanceSettings(
  enhanceProps(Component),
)
