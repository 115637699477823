import { FormSelect, withProps } from '@wiz/components'

const Formates = [
  {
    id: 'JUPYTER',
    name: 'Jupyter',
  },
  {
    id: 'HTML',
    name: 'HTML',
  },
  {
    id: 'DBC',
    name: 'DBC',
  },
  {
    id: 'SOURCE',
    name: 'Source',
  },
]

const enhanceData = withProps(() => ({
  options: Formates,
}))

const SelectNotebookFormat = enhanceData(FormSelect)

SelectNotebookFormat.displayName = 'SelectNotebookFormat'

export default SelectNotebookFormat
