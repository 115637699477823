import { map } from 'rxjs/operators'
import { auth } from '@/auth'

export default function defaultDiagramState (model) {
  return map(state => ({
    ...state,
    diagram: {
      ...state.diagram,
      isDefault: !!(
        state.diagram?.isDefault ||
        !auth.checkAccessUpdate(model)
      ),
    },
  }))
}
