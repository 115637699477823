/* eslint-disable react/no-array-index-key */
import {
  Fragment,
  useRef,
  useState,
  useEffect,
} from 'react'
import classnames from 'classnames'
import { DateTime } from 'luxon'
import {
  Controller,
  useFormContext,
  useFieldArray,
} from 'react-hook-form'
import {
  get,
  isEqual,
  RuleFreq,
  RuleFreqs,
  ruleOptionsToNextRun,
  ruleOptionsToNextRuns,
  ruleOptionsToText,
  RuleType,
  RuleTypes,
  toArrayValue,
} from '@wiz/utils'
import {
  CustomScrollbars,
  Dropdown,
  FormField,
  FormFieldInline,
  Icon,
  FormSelectNative,
  FormControl,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { StreamJobSchedule } from '@wiz/store'
import FormatDateTime from '@/containers/FormatDateTime'
import classes from './FieldsSchedule.css'

export default function FieldsSchedule ({ scope }) {
  const refNexRun = useRef()
  const intl = useIntl()

  const {
    register,
    formState: { errors },
    watch,
    getValues,
  } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    name: `${scope}.times`,
    keyName: '_id',
    shouldUnregister: true,
  })

  const scopeData = watch(scope)
  const type = watch(`${scope}.type`)
  const freq = watch(`${scope}.freq`)
  const [ options, setOptions ] = useState()
  const nextRun = options && ruleOptionsToNextRun(options)
  const nextRuns = options && ruleOptionsToNextRuns(options)
  const textRun = options && ruleOptionsToText(options)

  // can not get current data in "watch" but can get in "getValues"
  useEffect(() => {
    const data = get(getValues(), scope)
    if (!isEqual(options, data)) {
      setOptions(data)
    }
  }, [ scope, freq, type, getValues, scopeData, options ])

  return (
    <>
      <FormFieldInline
        label={intl.t('streamJobs.form.fields.explainText')}
        description={intl.t('streamJobs.form.fields.explainTextDescr')}
        complex
      >
        <div className="text-end ms-2">
          <div className="fw-bold">
            {type === RuleType.Once ?
              intl.t('streamJobs.form.fields.explainTextOnce') :
              textRun}
          </div>

          {nextRun ? (
            <>
              <button
                ref={refNexRun}
                type="button"
                className="btn btn-sm btn-link text-reset text-nowrap p-0"
              >
                {intl.t('streamJobs.form.fields.explainTextNextRun')}
                <FormatDateTime date={nextRun} />
                <Icon name="fa--chevron-down" className="ms-2" />
              </button>

              <Dropdown
                arrow
                autoclose
                target={refNexRun}
                width={180}
              >
                {() => (
                  <CustomScrollbars
                    horizontal={false}
                    autoHeight
                    autoHeightMax={200}
                  >
                    <div className="px-3 py-2 text-center">
                      {nextRuns.map((item, idx) => (
                        <div key={idx} className="mb-1">
                          {do {
                            if (item instanceof Date) {
                              <FormatDateTime date={item} />
                            } else if (item) {
                              // eslint-disable-next-line no-unused-expressions
                              '...'
                            }
                          }}
                        </div>
                      ))}
                    </div>
                  </CustomScrollbars>
                )}
              </Dropdown>
            </>
          ) : null}
        </div>
      </FormFieldInline>

      <FormField
        label={intl.t('streamJobs.form.fields.ruleType')}
        description={intl.t('streamJobs.form.fields.ruleTypeDescr')}
        errors={get(errors, `${scope}.type`)}
      >
        <Controller
          name={`${scope}.type`}
          rules={{ required: intl.t('form.errors.fieldRequired') }}
          shouldUnregister
          render={({ field, fieldState }) => (
            <FormSelectNative
              {...field}
              invalid={fieldState.invalid}
              options={RuleTypes.map(id => ({
                id,
                name: intl.t(`enum.streamJobsRuleTypes.${id}`),
              }))}
            />
          )}
        />
      </FormField>

      {type === RuleType.Custom ? (
        <>
          <FormField
            classNameControl="d-flex align-items-center"
            label={intl.t('streamJobs.form.fields.repeat')}
            description={intl.t('streamJobs.form.fields.repeatDescr')}
            errors={(
              get(errors, `${scope}.interval`)
            )}
            complex
          >
            <FormControl
              type="number"
              rules={{
                required: intl.t('form.errors.fieldRequired'),
              }}
              name={`${scope}.interval`}
              min={1}
              max={30}
              className="me-1"
              shouldUnregister
            />
            <FormSelectNative
              {...register(`${scope}.freq`, {
                valueAsNumber: true,
              })}
              className="ms-1"
              shouldUnregister
              uncontrolled
              options={RuleFreqs.map(id => ({
                id,
                name: intl.t(`enum.streamJobsRuleFreqs.freq${id}`),
              }))}
            />
          </FormField>
        </>
      ) : null}

      {(
        [ RuleType.Weekly, RuleType.Monthly ].includes(type) ||
        (type === RuleType.Custom && [ RuleFreq.Weekly, RuleFreq.Monthly ].includes(freq))
      ) ? (
        <FormField
          classNameControl="d-flex align-items-center"
          label={intl.t('streamJobs.form.fields.byweekday')}
          description={intl.t('streamJobs.form.fields.byweekdayDescr')}
          errors={get(errors, `${scope}.byweekday`)}
          complex
        >
          <Controller
            rules={{ setValueAs: value => (value?.map(Number) ?? []) }}
            name={`${scope}.byweekday`}
            shouldUnregister
            render={({ field: { value, onChange } }) => (
              <div className="d-flex flex-column flex-fill rounded border border-primary overflow-hidden">
                <div className="btn-group flex-fill" role="group">
                  {Array.from(new Array(7)).map((_, idx) => (
                    <Fragment key={idx}>
                      <input
                        id={`w${idx}`}
                        type="checkbox"
                        className="btn-check"
                        checked={value?.includes(idx)}
                        onChange={() => {
                          let next = toArrayValue(value)
                          if (next.includes(idx)) {
                            next = next.filter(item => item !== idx)
                          } else {
                            next = next.concat(idx)
                          }
                          onChange(next)
                        }}
                      />
                      <label className="btn btn-flat-primary rounded-0 px-2" htmlFor={`w${idx}`}>
                        {intl.t(`enum.weekAbbr.w${idx}`)}
                      </label>
                    </Fragment>
                  ))}
                </div>
              </div>
            )}
          />
        </FormField>
      // eslint-disable-next-line indent
      ) : null}

      {((type === RuleType.Monthly) || (type === RuleType.Custom && freq === RuleFreq.Monthly)) ? (
        <>
          <FormFieldInline
            classNameControl="d-flex align-items-center"
            label={intl.t('streamJobs.form.fields.bymonthday')}
            description={intl.t('streamJobs.form.fields.bymonthdayDescr')}
            errors={get(errors, `${scope}.bymonthday`)}
            complex
            vertical
          >
            <Controller
              rules={{ setValueAs: value => (value?.map(Number) ?? []) }}
              name={`${scope}.bymonthday`}
              shouldUnregister
              render={({ field: { value, onChange } }) => (
                <div className="d-flex flex-column flex-fill rounded border border-primary overflow-hidden">
                  <div className={classnames('btn-group flex-fill', classes.bymonthday)}>
                    {Array.from(new Array(31)).map((_, idx) => (
                      <Fragment key={idx}>
                        <input
                          id={`md${idx}`}
                          type="checkbox"
                          className="btn-check"
                          checked={value?.includes(idx)}
                          onChange={() => {
                            let next = toArrayValue(value)
                            if (next.includes(idx)) {
                              next = next.filter(item => item !== idx)
                            } else {
                              next = next.concat(idx)
                            }
                            onChange(next)
                          }}
                        />
                        <label
                          className="btn btn-flat-primary rounded-0 px-2"
                          htmlFor={`md${idx}`}
                        >
                          {idx + 1}
                        </label>
                      </Fragment>
                    ))}
                  </div>
                </div>
              )}
            />
          </FormFieldInline>

          <FormField
            classNameControl="d-flex align-items-center"
            label={intl.t('streamJobs.form.fields.bymonth')}
            description={intl.t('streamJobs.form.fields.bymonthDescr')}
            errors={get(errors, `${scope}.bymonth`)}
            complex
          >
            <Controller
              rules={{ setValueAs: value => (value?.map(Number) ?? []) }}
              name={`${scope}.bymonth`}
              shouldUnregister
              render={({ field: { value, onChange } }) => (
                <div className="d-flex flex-column flex-fill rounded border border-primary overflow-hidden">
                  <div className={classnames('btn-group flex-fill', classes.months)}>
                    {Array.from(new Array(12)).map((_, idx) => (
                      <Fragment key={idx}>
                        <input
                          id={`m${idx}`}
                          type="checkbox"
                          className="btn-check"
                          checked={value?.includes(idx)}
                          onChange={() => {
                            let next = toArrayValue(value)
                            if (next.includes(idx)) {
                              next = next.filter(item => item !== idx)
                            } else {
                              next = next.concat(idx)
                            }
                            onChange(next)
                          }}
                        />
                        <label className="btn btn-flat-primary rounded-0 px-2" htmlFor={`m${idx}`}>
                          {intl.t(`enum.monthAbbr.m${idx}`)}
                        </label>
                      </Fragment>
                    ))}
                  </div>
                </div>
              )}
            />
          </FormField>
        </>
      ) : null}

      <div className="d-flex align-items-center mb-3">
        <button
          type="button"
          className="btn btn-fill-primary flex-fill"
          onClick={() => append(StreamJobSchedule.toRuleOptions())}
        >
          {intl.t('streamJobs.form.actions.addTime')}
        </button>
      </div>

      <FormField
        label={intl.t('streamJobs.form.fields.time')}
        description={intl.t('streamJobs.form.fields.timeDescr')}
        complex
      >
        {fields.map((field, idx) => {
          const scopeRow = `${scope}.times.${idx}`
          const error = get(errors, `${scopeRow}`, {})

          return (
            <div key={field.id} className="d-flex align-items-start mb-3">
              <input
                {...register(`${scopeRow}.id`)}
                type="hidden"
              />

              {type === RuleType.Once ? (
                <div className="min-w-0 flex-fill">
                  <input
                    {...register(`${scopeRow}.dtstart`, {
                      required: intl.t('form.errors.fieldRequired'),
                      setValueAs: value => (value ? DateTime.fromISO(value).toJSDate() : undefined),
                    })}
                    className={classnames('form-control', {
                      'is-invalid': !!error.dtstart,
                    })}
                    type="datetime-local"
                  />
                  {error.dtstart ? (
                    <div className="small text-danger">{error.dtstart.message}</div>
                  ) : null}
                </div>
              ) : (
                <>
                  <FormSelectNative
                    {...register(`${scopeRow}.hours`, {
                      valueAsNumber: true,
                    })}
                    uncontrolled
                    invalid={!!error.hours}
                    options={Array.from(new Array(24)).map((_, key) => ({
                      id: key,
                      name: key.toString().padStart(2, '0'),
                    }))}
                  />

                  <span className="mx-1">:</span>

                  <FormSelectNative
                    {...register(`${scopeRow}.minutes`, {
                      valueAsNumber: true,
                    })}
                    uncontrolled
                    invalid={!!error.minutes}
                    options={Array.from(new Array(60)).map((_, key) => ({
                      id: key,
                      name: key.toString().padStart(2, '0'),
                    }))}
                  />
                </>
              )}

              {fields.length > 1 ? (
                <button
                  type="button"
                  className="btn btn-fill-secondary btn-text ms-2"
                  onClick={() => remove(idx)}
                >
                  <Icon name="fa--trash-alt" />
                </button>
              ) : null}
            </div>
          )
        })}
      </FormField>
    </>
  )
}
