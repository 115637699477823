import { useFormContext, Controller } from 'react-hook-form'
import { FormField, FormControl, FormFieldInline } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function Fields ({ scope, id }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('form.fields.key')}
        description={intl.t('groupSystem.form.fields.keyDescr')}
        errors={get(errors, `${scope}.key`)}
      >
        <FormControl
          name={`${scope}.key`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
            maxLength: {
              value: 32,
              message: intl.t('form.errors.fieldMaxlen', { max: 32 }),
            },
          }}
          disabled={id}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('groupSystem.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.description')}
        description={intl.t('groupSystem.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.description`}
          rules={{
            maxLength: {
              value: 450,
              message: intl.t('form.errors.fieldMaxlen', { max: 450 }),
            },
          }}
        />
      </FormField>
    </>
  )
}
