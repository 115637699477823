import { useCallback, memo } from 'react'
import { dbProvider } from '@wiz/store'
import { withProps } from '@wiz/components'
import Component from '@/components/Forms/QualityDataBulkEdit'

const enhanceProps = withProps(({ qualityData }) => {
  const onSubmit = useCallback(async (data) => {
    if (!data.selected?.length) {
      return
    }

    const nextData = {}
    for (const name of data.selected) {
      nextData[name] = data.qualityData[name]
    }

    const context = dbProvider.createBatchContext()
    for (const item of qualityData) {
      const applyData = { ...nextData }
      const tmpl = await item.qualityDataTemplate.fetch()
      if (!tmpl?.approval) {
        delete applyData.approval
      }
      // eslint-disable-next-line no-await-in-loop
      await item.prepareUpdateData(context, applyData)
    }
    await dbProvider.batch(context)
  }, [ qualityData ])

  return {
    onSubmit,
  }
})

export default memo(enhanceProps(Component))
