import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import cx from 'classnames'
import { useUniqueId } from '@wiz/components'
import { DashboardUploader } from '@/utils/files'

const SelectFiles = forwardRef(({
  meta,
  onChange,
  onSuccess,
  onAdd,
  restrictions,
  className,
  children,
  inline,
}, ref) => {
  const id = useUniqueId('SelectFiles')
  const refUploader = useRef()
  const refChange = useRef()
  const refFiles = useRef([])
  const refMeta = useRef()
  refMeta.current = meta
  refChange.current = onChange

  useImperativeHandle(ref, () => ({
    reset () {
      refFiles.current = []
      refChange.current?.(refFiles.current)
      return refUploader.current.reset()
    },

    upload () {
      return refUploader.current.upload()
    },

    onSelect () {
      return refFiles.current
    },

    cancelAll () {
      refUploader.current?.handleCancelAll()
    },
  }), [])

  useEffect(() => {
    const uploader = new DashboardUploader({
      target: `#${id}`,
      inline,
      restrictions,
    })

    uploader.on('upload', () => {
      uploader.setMeta({
        ...refMeta.current,
      })
    })

    uploader.on('file-added', (file) => {
      const next = refFiles.current.concat(file.id)
      refFiles.current = next
      refChange.current?.(refFiles.current)
      onAdd?.(file)
    })

    uploader.on('file-removed', (file) => {
      refFiles.current = refFiles.current.filter(item => item !== file.id)
      refChange.current?.(refFiles.current)
      onAdd?.(null)
    })

    uploader.on('file-upload-success', async (file, response) => {
      refFiles.current = refFiles.current.filter(item => item !== file.id)
      refChange.current?.(refFiles.current)
      await onSuccess?.(response)
    })

    refUploader.current = uploader

    return () => {
      uploader.destroy()
    }
  }, [ id, ref, onSuccess, onAdd ])

  return (
    <div
      id={id}
      className={cx('d-flex flex-fill', className)}
    >
      {children}
    </div>
  )
})

export default SelectFiles
