import { memo, useEffect } from 'react'
import { withProps } from '@wiz/components'
import enhanceData from './enhanceData'
import enhanceExtraData from './enhanceExtraData'

const enhanceProps = withProps(({
  qualityDataTemplate,
  measurements,
  onChange,
}) => {
  useEffect(() => (
    onChange?.({ qualityDataTemplate, measurements })
  ), [ qualityDataTemplate, measurements, onChange ])
})

export default memo(
  enhanceData(
    enhanceExtraData(
      enhanceProps(() => (null)),
    ),
  ),
)
