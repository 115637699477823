import { of as of$, combineLatest } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'
import { isEmpty, orderBy } from '@wiz/utils'
import Component from '@/components/TwinGraph/ContextMenu'

const enhanceData = withObservables([ 'block', 'blockIds' ], ({ block, blockIds }) => ({
  block: block?.id ? (
    dbProvider.database.collections.get('diagram_blocks')
      .query(Q.where('id', block.id))
      .observeWithColumns([ 'updated_at' ])
      .pipe(map(items => items[0]))
  ) : of$(undefined),

  twin: block?.id ? (
    dbProvider.database.collections.get('diagram_blocks')
      .query(
        Q.where('id', block.id),
        Q.where('type', Q.oneOf([ 'area', 'machine', 'equipment' ])),
      )
      .observeWithColumns([ 'updated_at' ])
      .pipe(
        switchMap(items => (
          items.length > 0 ? items[0].observeSettings : of$(undefined)
        )),
      )
  ) : of$(undefined),

  sensorIds: isEmpty(blockIds) ? of$([]) : dbProvider.database.collections.get('diagram_blocks')
    .query(Q.where('id', Q.oneOf(blockIds)), Q.where('type', Q.oneOf([ 'area', 'machine', 'equipment' ])))
    .observe()
    .pipe(
      switchMap(blocks => (
        blocks.length ? (
          dbProvider.database.collections.get('rel_diagram_blocks_twins')
            .query(Q.where('block_id', Q.oneOf(blocks.map(item => item.id))))
            .observe()
            .pipe(
              switchMap(rels => (
                rels.length ? (
                  dbProvider.database.collections.get('sensors')
                    .query(Q.where('twin_id', Q.oneOf(rels.map(item => item.targetId))))
                    .observe()
                    .pipe(
                      map(sensors => sensors.map(item => item.id)),
                    )
                ) : of$([])
              )),
            )
        ) : of$([])
      )),
    ),

  twinSettings: dbProvider.observeGlobalSettings([
    'twinColorAreas',
    'twinColorMachines',
    'twinColorEquipment',
  ]),

  functions: dbProvider.database.collections.get('ds_functions').query().observeWithColumns([ 'updated_at' ]).pipe(map(items => orderBy(items || [], [ item => item.title.toLowerCase() ], [ 'asc' ]))),

  blockSettings: block?.id ? (
    dbProvider.database.collections.get('diagram_blocks')
      .query(Q.where('id', block.id))
      .observeWithColumns([ 'updated_at' ])
      .pipe(switchMap(blocks => (
        blocks.length ?
          combineLatest(blocks.map(block => block.observeSettings)) :
          of$([])
      )), map(blocks => blocks[0]))
  ) : of$(undefined),
}))

export default enhanceData(Component)
