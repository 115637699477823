import { Controller } from 'react-hook-form'
import { useIntl } from '@wiz/intl'
import Section from '@/components/Forms/Script/Section'
import Select from '@/components/Form/Select'
import MlModelList from '@/hoc/MlModelList'

export default function SectionScript ({ scope }) {
  const intl = useIntl()

  return (
    <Section
      scope={scope}
      registerId={false}
      title={intl.t('form.sections.mlModel')}
      description={intl.t('dataSource.form.sections.mlModelDescr')}
      addon={() => (
        <Controller
          name={`${scope}.id`}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              ListSource={MlModelList}
              placeholder={intl.t('form.fields.mlModelPlaceholder')}
              className="min-w-0 w-100 w-md-auto mt-2 mt-md-0"
            />
          )}
        />
      )}
    />
  )
}
