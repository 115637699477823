import { useFormContext } from 'react-hook-form'
import { FormField, FormControl } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import FieldColor from './FieldColor'
import FieldIcon from './FieldIcon'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('businessLabels.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.description')}
        description={intl.t('businessLabels.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          name={`${scope}.description`}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.order')}
        description={intl.t('businessLabels.form.fields.orderDescr')}
        errors={get(errors, `${scope}.order`)}
      >
        <FormControl
          type="number"
          name={`${scope}.order`}
        />
      </FormField>

      <FieldColor scope={scope} />
      <FieldIcon scope={scope} />
    </>
  )
}
