import { useCallback } from 'react'
import { withProps } from '@wiz/components'
import { dbProvider, DataSource, DataView } from '@wiz/store'

const enhanceProps = withProps(({ widget, config }) => {
  const onSubmit = useCallback(async (data) => {
    if (widget) {
      const dataViews = []
      const dataSources = []
      let conditions = []

      if (data.config.sensorId) {
        const dataSource = DataSource.toJSON(config.dataSources?.[0] || { dataType: 'last' })
        const dataView = DataView.toJSON(config.dataViews?.[0] || { sourceId: dataSource.id })

        dataSource.sensorId = data.config.sensorId
        dataView.sourceId = dataSource.id
        dataSources.push(dataSource)
        dataViews.push(dataView)

        conditions = data.config.conditions
          .filter(item => !!item.type)
          .map((item) => {
            const inputDataSource = DataSource.toJSON(item.inputDataSources?.[0] || {
              dataType: 'last',
            })
            inputDataSource.sensorId = data.config.sensorId

            return {
              ...item,
              inputDataSources: [ inputDataSource ],
              outputDataSources: [],
            }
          })
      }

      const context = dbProvider.createBatchContext()
      await widget.prepareUpdateData(context, {
        ...data.widget,
        config: {
          displayCategoryName: data.config.displayCategoryName,
          displayDataPointName: data.config.displayDataPointName,
          precision: data.config.precision,
        },
      })
      await widget.prepareReplaceDataViews(context, dataViews, dataSources)
      await widget.prepareReplaceConditions(context, conditions)
      await dbProvider.batch(context)
    }
  }, [ widget, config ])

  return {
    onSubmit,
  }
})

export default function enhanceSettingsSensor (Component) {
  return enhanceProps(Component)
}
