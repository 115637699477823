import { useMemo } from 'react'
import {
  Table as StaticTable,
  Pagination,
  Icon,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import StreamJobList from '@/hoc/StreamJobList'
import EdgeDeviceList from '@/hoc/EdgeDeviceList'
import Select from '@/components/Form/Select'
import SelectTwin from '@/components/Form/SelectTwin'
import SelectSensor from '@/components/Form/SelectSensor'
import Content from '@/components/RightBarCommands/Content'

function Detail ({ row }) {
  const intl = useIntl()
  const { response } = row.original

  return (
    <div>
      {response || intl.t('form.noresponse')}
    </div>
  )
}

export default function Table ({
  loading,
  items,
  filters,
  forceUpdate,
  onFilter,
  onResetFilter,
  ...props
}) {
  const intl = useIntl()
  const columns = useMemo(() => [
    {
      Header: () => (
        <div className="flex-fill d-flex justify-content-between min-w-0">
          <div className="d-flex min-w-0">
            <Select
              value={filters.deviceId}
              size="sm"
              className="w-auto me-1"
              placeholder={intl.t('form.fields.devicePlaceholder')}
              ListSource={EdgeDeviceList}
              onChange={deviceId => onFilter({ deviceId })}
            />

            <Select
              value={filters.streamJobId}
              size="sm"
              className="w-auto me-1"
              placeholder={intl.t('form.fields.streamJobPlaceholder')}
              ListSource={StreamJobList}
              onChange={streamJobId => onFilter({ streamJobId })}
            />

            <SelectTwin
              value={filters.twinId}
              size="sm"
              className="w-auto me-1"
              placeholder={intl.t('form.fields.twinPlaceholder')}
              onChange={twinId => onFilter({ twinId })}
            />

            <SelectSensor
              value={filters.sensorId}
              size="sm"
              className="w-auto me-1"
              placeholder={intl.t('form.fields.sensorsPlaceholder')}
              onChange={sensorId => onFilter({ sensorId })}
            />
          </div>

          <div className="d-flex">
            <button
              type="button"
              className="btn btn-sm btn-text btn-fill-secondary me-1"
              onClick={onResetFilter}
            >
              <Icon name="fa--undo" />
            </button>

            <button
              type="button"
              className="btn btn-sm btn-text btn-fill-secondary"
              onClick={() => forceUpdate()}
            >
              <Icon
                name={loading ? 'fa--spinner' : 'fa--sync'}
                spin={loading}
              />
            </button>
          </div>
        </div>
      ),
      disableSortBy: true,
      disableResizing: true,
      accessor: 'id',
      minWidth: 100,
      Cell: ({ row }) => (
        <Content
          data={row.original}
          onFilter={onFilter}
        />
      ),
    },
  ], [
    intl,
    onFilter,
    onResetFilter,
    forceUpdate,
    loading,
    filters,
  ])

  return (
    <>
      <StaticTable
        className="flex-fill min-h-0"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={false}
        loading={loading}
        ExpandeComponent={Detail}
        expande
      />

      <Pagination
        {...props}
        className="my-1"
        currentPage={filters.page}
        pageSize={filters.pageSize}
        relative
      />
    </>
  )
}
