/* eslint-disable no-unused-expressions */
import { useMemo, useCallback } from 'react'
import { DateTime } from 'luxon'
import {
  Icon,
  VirtualTable,
  OverflowCenter,
  useMobile,
} from '@wiz/components'
import { orderBy, precision } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { Condition } from '@wiz/store'
import FormatDateTime from '@/containers/FormatDateTime'

export default function TableData ({
  config,
  data,
  defaultSort,
  onSort,
  sensors,
  sortBy,
  sortDir,
}) {
  const {
    dataFilter: { viewValuePrecision },
    utcTime,
    msTime,
  } = config

  const intl = useIntl()
  const isMobile = useMobile()

  const conditionOutputs = useMemo(() => {
    const items = {}
    for (const item of config.conditions) {
      const inputs = item.inputDataSources
      const outputs = item.outputDataSources.length ?
        item.outputDataSources :
        item.inputDataSources
      for (const output of outputs) {
        items[output.sensorId] = items[output.sensorId] || {}
        for (const input of inputs) {
          items[output.sensorId][input.sensorId] = items[output.sensorId][input.sensorId] || []
          items[output.sensorId][input.sensorId].push(item)
        }
      }
    }
    return items
  }, [ config ])

  const {
    values,
    conditions,
  } = useMemo(() => {
    const values = {}
    const dataPoints = {}
    const conditions = {}

    for (const resp of data) {
      const { sensorId } = resp
      dataPoints[sensorId] = true
      for (const item of resp.response) {
        const [ ts, value ] = item
        values[ts] = values[ts] || { ts }
        values[ts][sensorId] = value
      }
    }

    for (const resp of data) {
      const { sensorId } = resp
      if (conditionOutputs[sensorId]) {
        for (const item of resp.response) {
          const [ ts ] = item
          for (const inputSensorId in conditionOutputs[sensorId]) {
            if (dataPoints[inputSensorId]) {
              const value = values[ts][inputSensorId]
              for (const condition of conditionOutputs[sensorId][inputSensorId]) {
                if (Condition.pass(condition, ts, value)) {
                  conditions[ts] = conditions[ts] || {}
                  conditions[ts][sensorId] = conditions[ts][sensorId] || []
                  conditions[ts][sensorId].push(condition)
                }
              }
            }
          }
        }
      }
    }

    return {
      values: Object.values(values),
      conditions,
    }
  }, [ data, conditionOutputs ])

  const columns = useMemo(() => (isMobile ? [
    {
      accessor: 'ts',
      width: NaN,
      Cell: ({ cell, row }) => {
        const date = DateTime.fromMillis(Number(cell.value), { zone: utcTime ? 'utc' : 'local' })

        return (
          <div className="min-w-0 flex-fill">
            <div className="font-monospace small">
              <FormatDateTime
                date={date}
                withMillis={msTime}
                withSecond
              />
            </div>
            {Object.keys(sensors).map(sensorId => (
              <div key={sensorId} className="d-flex align-items-baseline">
                <div className="d-flex me-2 min-w-0">
                  <OverflowCenter value={sensors[sensorId].displayName} />
                </div>
                <div className="ms-auto d-flex align-items-baseline">
                  {(conditions[row.original.ts]?.[sensorId] || []).map(condition => (
                    <div
                      key={condition.id}
                      title={condition.name}
                      className="me-1 d-flex"
                    >
                      <Icon
                        name="fa--circle"
                        color={condition.color}
                      />
                    </div>
                  ))}
                  <div className="font-monospace">
                    {do {
                      if (typeof row.original[sensorId] === 'number') {
                        if (typeof viewValuePrecision === 'number') {
                          `${row.original[sensorId] < 0 ? '-' : ''}${Math.trunc(Math.abs(row.original[sensorId]))}${viewValuePrecision ? precision(row.original[sensorId], viewValuePrecision) : ''}`
                        } else {
                          row.original[sensorId]
                        }
                      } else {
                        null
                      }
                    }}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )
      },
    },
  ] : [
    {
      Header: () => (
        <>
          {intl.t('form.fields.timestamp')}
        </>
      ),
      accessor: 'ts',
      disableResizing: true,
      width: msTime ? 210 : 180,
      maxWidth: msTime ? 210 : 180,
      Cell: ({ cell }) => {
        const date = DateTime.fromMillis(Number(cell.value), { zone: utcTime ? 'utc' : 'local' })

        return (
          <span className="font-monospace small">
            <FormatDateTime
              date={date}
              withMillis={msTime}
              withSecond
            />
          </span>
        )
      },
    },
    ...Object.keys(sensors).map(sensorId => ({
      Header: () => (<OverflowCenter value={sensors[sensorId].displayName} />),
      accessor: sensorId,
      disableResizing: true,
      width: NaN,
      className: 'justify-content-end',
      Cell: ({ row, cell }) => {
        const applyConditions = conditions[row.original.ts]?.[sensorId] || []
        return (
          <>
            {applyConditions.map(condition => (
              <div
                key={condition.id}
                title={condition.name}
                className="me-1 d-flex"
              >
                <Icon
                  name="fa--circle"
                  color={condition.color}
                />
              </div>
            ))}
            <div className="font-monospace small">
              {do {
                if (typeof cell.value === 'number') {
                  if (typeof viewValuePrecision === 'number') {
                    `${cell.value < 0 ? '-' : ''}${Math.trunc(Math.abs(cell.value))}${viewValuePrecision ? precision(cell.value, viewValuePrecision) : ''}`
                  } else {
                    cell.value
                  }
                } else {
                  null
                }
              }}
            </div>
          </>
        )
      },
    })),
  ]), [
    intl,
    msTime,
    utcTime,
    sensors,
    conditions,
    viewValuePrecision,
    isMobile,
  ])

  const itemSize = 56 + (isMobile ? Object.keys(sensors).length * 24 : 0)

  const sortedValues = useMemo(() => (
    orderBy(
      values,
      [ sortBy ],
      [ sortDir ],
    )
  ), [ values, sortBy, sortDir ])

  return (
    <VirtualTable
      className="flex-fill"
      placeholder={(
        <Icon
          name="fa--spinner"
          size="lg"
          spin
        />
      )}
      columns={columns}
      data={sortedValues}
      selection={false}
      defaultSort={defaultSort}
      onSort={onSort}
      showHeader={!isMobile}
      itemSize={itemSize}
    />
  )
}
