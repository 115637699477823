/* eslint-disable react/no-danger */
import classnames from 'classnames'
import { Card, Icon, useMobile } from '@wiz/components'
import { markSearch } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { auth } from '@/auth'
import FormatDateTime from '@/containers/FormatDateTime'
import DateRelative from '@/components/DateRelative'
import Link from '@/components/Link'
import UpdatedInfo from '@/components/Form/UpdatedInfo'

export default function Option ({ data, index, style }) {
  const isMobile = useMobile()
  const intl = useIntl()
  const {
    options,
    search,
    settings,
    onAction,
    onOpen,
  } = data
  const item = options[index]
  const isFavorite = settings.spotlightFavorites?.includes(item.id) ?? false
  const isDefault = item.id === settings.homepageDefaultId
  const lastOpened = settings.lastOpened?.[item.id]

  const actions = [].concat(
    auth.checkAccessUpdate(item) ? {
      id: 'settings',
      label: intl.t('form.actions.settings'),
      icon: 'fa--cog',
    } : [],
    {
      id: 'select',
      label: intl.t('form.actions.setDefault'),
      icon: 'fa--paperclip',
      disabled: isDefault,
    },
    {
      id: 'view',
      label: intl.t('form.actions.view'),
      icon: 'fa--eye',
    },
    {
      id: 'favorite',
      icon: isFavorite ? 'fa--star' : 'far--star',
      label: isFavorite ?
        intl.t('form.actions.removeFavorite') :
        intl.t('form.actions.addFavorite'),
    },
    auth.checkAccessCopy(item) && auth.checkAccessCreate('Dashboard') ? [
      {
        id: 'duplicate',
        label: intl.t('form.actions.duplicate'),
        icon: 'fa--clone',
      },
      // {
      //   id: 'exportDefinition',
      //   label: 'Export Definition',
      //   icon: 'fa--download',
      // },
      // {
      //   id: 'importDefinition',
      //   label: 'Import Definition',
      //   icon: 'fa--upload',
      //   type: 'file',
      // },
    ] : [],
    auth.checkAccessRemove(item) ? {
      id: 'remove',
      label: intl.t('form.actions.remove'),
      icon: 'fa--trash-alt',
      disabled: isDefault,
    } : [],
  )

  return (
    <div
      className="d-flex"
      style={style}
    >
      <Card
        className={classnames('flex-fill mb-2 text-nowrap', {
          'border-primary border': isDefault,
        })}
        classNameBody="justify-content-end mt-0 mb-2"
        title={(
          <div className="d-flex align-items-center h5">
            {onOpen ? (
              <a
                className="text-truncate pointer"
                onClick={() => onOpen?.(item.id)}
                aria-hidden
              >
                <span
                  dangerouslySetInnerHTML={{ __html: markSearch(item.title, search) }}
                />
              </a>
            ) : (
              <Link
                name="dashboard"
                params={{ id: item.id }}
                className="text-reset text-truncate min-w-0"
              >
                {search ? (
                  <span
                    dangerouslySetInnerHTML={{ __html: markSearch(item.title, search) }}
                  />
                ) : item.title}
              </Link>
            )}
            {item.isGlobalShared ? (
              <Icon
                name="fa--globe"
                className="mx-2"
                title={intl.t('form.fields.globalShare')}
              />
            ) : null}
          </div>
        )}
        description={item.description}
        options={actions}
        onAction={action => onAction?.(action, item)}
      >
        {item.description ? (
          <div
            className={classnames('text-truncate flex-shrink-0', {
              'mb-1': !isMobile,
            })}
          >
            {search ? (
              <span
                dangerouslySetInnerHTML={{ __html: markSearch(item.description, search) }}
              />
            ) : item.description}
          </div>
        ) : null}

        {isMobile ? null : (
          <>
            <div className="d-flex align-items-center">
              <b className="me-1">
                {intl.t('form.fields.created')}
                :
              </b>
              <UpdatedInfo showName date={item.createdAt} />
            </div>

            <div className="d-flex align-items-center">
              <b className="me-1">
                {intl.t('form.fields.author')}
                :
              </b>
              <UpdatedInfo
                showName
                user={item.createdUser}
                userClassName=""
              />
            </div>

            <div className="d-flex align-items-center flex-wrap">
              <div className="d-flex align-items-center me-1">
                <b className="me-1">
                  {intl.t('form.fields.lastModified')}
                  :
                </b>
                <UpdatedInfo showName date={item.updatedAt} />
              </div>
              <div className="d-flex text-truncate lh-1">
                {intl.t('form.fields.by', { value: ' ' })}
                &nbsp;
                <UpdatedInfo
                  showName
                  user={item.updatedUser}
                  userClassName="text-truncate"
                />
              </div>
            </div>

            {lastOpened ? (
              <div className="d-flex align-items-center flex-wrap">
                <div className="d-flex align-items-center">
                  <b className="me-1">Last Opened At:</b>
                  <FormatDateTime date={lastOpened} withSecond withMillis={false} />
                  <sup className="ms-1">
                    <DateRelative value={lastOpened} />
                  </sup>
                </div>
              </div>
            ) : null}
          </>
        )}
      </Card>
    </div>
  )
}
