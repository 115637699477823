import { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { MlWebServiceNg, MlWebServiceNgInterface } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import ObservWebService from '@/containers/Forms/MlWebService/Observ'
import Section from '@/components/Forms/MlWebService/Section'
import SectionInterface from '@/components/Forms/MlWebService/SectionInterface'
import Select from '@/components/Form/Select'
import MLWebServiceList from '@/hoc/MLWebServiceList'

export default function SettingsSection ({ scope }) {
  const intl = useIntl()
  const {
    register,
    watch,
    setValue,
    clearErrors,
  } = useFormContext()
  const id = watch(`${scope}.webService.id`)

  const handleChange = useCallback(({ webService, interfaces }) => {
    const next = MlWebServiceNg.toJSON(webService)
    setValue(`${scope}.id`, next.id, { shouldDirty: true })
    setValue(`${scope}.webService`, next, { shouldDirty: true })
    setValue(`${scope}.interfaces`, interfaces.map(item => (
      MlWebServiceNgInterface.toJSON(item)
    )), { shouldDirty: true })
    clearErrors(scope)
  }, [ scope, setValue, clearErrors ])

  return (
    <>
      <ObservWebService
        id={id}
        onChange={handleChange}
      />

      <input
        {...register(`${scope}.id`)}
        type="hidden"
        defaultValue={id}
      />

      <Section
        scope={`${scope}.webService`}
        registerId={false}
        title={intl.t('form.sections.webService')}
        description={intl.t('form.sections.webServiceDescr')}
        addon={() => (
          <Controller
            name={`${scope}.webService.id`}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                invalid={fieldState.invalid}
                ListSource={MLWebServiceList}
                placeholder={intl.t('form.fields.webServicePlaceholder')}
                className="min-w-0 w-100 w-md-auto mt-2 mt-md-0"
              />
            )}
          />
        )}
      />

      <SectionInterface
        scope={`${scope}.interfaces`}
      />
    </>
  )
}
