import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  FormField,
  FormControl,
} from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import Selectable from '@/components/Form/Selectable'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        complex
        label={intl.t('quality.form.fields.approval')}
        description={intl.t('quality.form.fields.approvalDescr')}
        errors={get(errors, `${scope}.approval`)}
        selectable={() => <Selectable value="approval" />}
      >
        <FormControl
          type="select"
          name={`${scope}.approval`}
          options={consts.QualityDataApprovals.map(id => ({
            id,
            name: intl.t(`enum.qualityApproval.${id}`),
          }))}
          placeholder={intl.t('quality.form.fields.approvalPlaceholder')}
        />
      </FormField>

      <FormField
        complex
        label={intl.t('quality.form.fields.description')}
        description={intl.t('quality.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
        selectable={() => <Selectable value="description" />}
      >
        <FormControl
          type="textarea"
          name={`${scope}.description`}
          rules={{
            maxLength: {
              value: 2000,
              message: intl.t('form.errors.fieldMaxlen', { max: 2000 }),
            },
          }}
        />
      </FormField>
    </>
  )
}

Fields.propTypes = {
  scope: PropTypes.string.isRequired,
}
