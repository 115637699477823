import { useEffect } from 'react'
import {
  useQuery,
} from '@tanstack/react-query'
import { withProps, withPagination, withSort } from '@wiz/components'
import { wizataApi } from '@/api'
import { consts } from '@wiz/utils'
import Component from '@/components/ExecutionScripts/Table'

const SortByFields = {
  createdDate: 'createdDate',
  updatedDate: 'updatedDate',
  name: 'name',
  status: 'status',
}

const SortDirection = {
  asc: 'Ascending',
  desc: 'Descending',
}

const enhanceProps = withProps(({
  filters,
}) => {
  const {
    data: scripts, isLoading, isRefetching, refetch,
  } = useQuery({
    queryKey: [ 'executionScriptsList' ],
    queryFn: () => {
      const limit = filters.pageSize + 1
      const offset = (filters.page - 1) * filters.pageSize

      return wizataApi.entities.getList('scripts', {
        pagination: {
          take: limit - 1,
          skip: offset,
        },
        sortingList: [
          {
            direction: SortDirection[filters.sortDir],
            propertyName: SortByFields[filters.sortBy],
          },
        ],
        filters: [
          {
            type: consts.FilterType.Text,
            operationType: consts.FilterOperationType.Contains,
            value: filters.search || '',
            // start: 'string',
            // end: 'string',
            propertyName: 'name',
          },
        ],
      })
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: true,
  })

  useEffect(() => {
    if (scripts && !isRefetching) {
      refetch()
    }
  }, [
    filters.search,
    filters.page,
    filters.pageSize,
    filters.sortDir,
    filters.sortBy,
  ])

  return {
    total: scripts?.totalCount || 0,
    items: scripts?.items || [],
    data: scripts?.items || [],
    loading: isLoading || isRefetching,
  }
})

export default enhanceProps(
  withSort()(
    withPagination()(Component),
  ),
)
