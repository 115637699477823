import { OverflowCenter, Icon } from '@wiz/components'

const ContentDefault = ({
  className,
  color,
  icon,
  name,
  search,
}) => (
  <>
    {icon || color ? (
      <Icon
        name={icon || 'fa--circle'}
        color={color}
        className="me-2"
      />
    ) : null}

    <OverflowCenter
      value={name}
      mark={search}
      className={className}
    />
  </>
)

export default function ContentComponent ({
  Content,
  data,
  ...props
}) {
  const {
    color,
    icon,
    name,
  } = data

  return Content ? (
    <Content
      {...props}
      ContentDefault={ContentDefault}
      data={data}
    />
  ) : (
    <ContentDefault
      {...props}
      color={color}
      icon={icon}
      name={name}
    />
  )
}
