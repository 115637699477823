import { FormSection } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import FieldsExplorers from './FieldsExplorers'

export default function SectionExplorers ({ scope }) {
  const intl = useIntl()

  return (
    <FormSection
      title={intl.t('twin.form.fields.explorers')}
      description={intl.t('twin.form.fields.explorersDescr')}
    >
      <FieldsExplorers scope={scope} />
    </FormSection>
  )
}
