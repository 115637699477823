import { FormSection } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import FieldsDashboards from './FieldsDashboards'

export default function SectionDashboards ({ scope }) {
  const intl = useIntl()

  return (
    <FormSection
      title={intl.t('twin.form.fields.dashboards')}
      description={intl.t('twin.form.fields.dashboardsDescr')}
    >
      <FieldsDashboards scope={scope} />
    </FormSection>
  )
}
