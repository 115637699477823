import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  FormControl,
  FormSection,
  FormField,
  FormFieldInline,
  FormSelect,
} from '@wiz/components'
import {
  get,
  getDateTo,
  getDateFrom,
} from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { BlockInputAggregationTypes } from '@/config'
import SelectSensor from '@/components/Form/SelectSensor'
import SelectLabel from '@/components/Form/SelectLabel'
import FormatDateTime from '@/containers/FormatDateTime'

export default function Section ({ scope, ...restProps }) {
  const intl = useIntl()
  const {
    register,
    watch,
    formState: { errors },
    clearErrors,
  } = useFormContext()
  const [
    inputType,
    dateFrom,
    dateTo,
    duration,
    aggregationStep,
  ] = watch([
    `${scope}.inputType`,
    `${scope}.dateFrom`,
    `${scope}.dateTo`,
    `${scope}.duration`,
    `${scope}.aggregationStep`,
  ])

  const estimatedValues = useMemo(() => {
    if (aggregationStep) {
      const from = getDateFrom({ duration, dateFrom }, true)
      const to = getDateTo({ duration, dateTo }, true)
      return Math.floor((to - from) / aggregationStep)
    }
    return null
  }, [ dateFrom, dateTo, duration, aggregationStep ])

  return (
    <FormSection {...restProps}>
      <input
        {...register(`${scope}.id`)}
        type="hidden"
      />

      <FormField
        label={intl.t('form.fields.inputType')}
        description={intl.t('blockInput.form.fields.inputTypeDescr')}
        errors={get(errors, `${scope}.inputType`)}
      >
        <FormControl
          shouldUnregister
          type="select"
          name={`${scope}.inputType`}
          placeholder={intl.t('form.fields.inputTypePlaceholder')}
          options={[
            {
              id: 'groupDataPoint',
              name: intl.t('enum.inputType.groupDataPoint'),
              options: [
                { id: 'sensor', name: intl.t('enum.inputType.sensor') },
                { id: 'sensorInterval', name: intl.t('enum.inputType.sensorInterval') },
                { id: 'sensorLastValue', name: intl.t('enum.inputType.sensorLastValue') },
                { id: 'sensorWindow', name: intl.t('enum.inputType.sensorWindow') },
                { id: 'sensorRawWindow', name: intl.t('enum.inputType.sensorRawWindow') },
              ],
            },
            {
              id: 'groupUserDefined',
              name: intl.t('enum.inputType.groupUserDefined'),
              options: [
                { id: 'constant', name: intl.t('enum.inputType.constant') },
              ],
            },
          ]}
          onChange={() => {
            clearErrors(`${scope}.duration`)
          }}
        />
      </FormField>

      {[
        'sensor',
        'sensorInterval',
        'sensorWindow',
        'sensorRawWindow',
        'sensorLastValue',
      ].includes(inputType) ? (
        <>
          <FormField
            label={intl.t('form.fields.sensor')}
            description={intl.t('blockInput.form.fields.sensorDescr')}
            errors={get(errors, `${scope}.sensorId`)}
          >
            <Controller
              shouldUnregister
              name={`${scope}.sensorId`}
              render={({ field, fieldState }) => (
                <SelectSensor
                  {...field}
                  invalid={fieldState.invalid}
                  placeholder={intl.t('form.fields.sensorPlaceholder')}
                  onChange={(value) => {
                    clearErrors(`${scope}.labelId`)
                    field.onChange(value)
                  }}
                />
              )}
            />
          </FormField>

          <FormField
            label={intl.t('form.fields.label')}
            description={intl.t('blockInput.form.fields.labelDescr')}
            errors={get(errors, `${scope}.labelId`)}
          >
            <Controller
              shouldUnregister
              name={`${scope}.labelId`}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  invalid={fieldState.invalid}
                  placeholder={intl.t('form.fields.labelPlaceholder')}
                  onChange={(value) => {
                    clearErrors(`${scope}.sensorId`)
                    field.onChange(value)
                  }}
                />
              )}
            />
          </FormField>
        </>
        ) : null}

      {[ 'sensorInterval', 'sensorWindow' ].includes(inputType) ? (
        <FormField
          label={intl.t('form.fields.aggregationType')}
          description={
            inputType === 'sensorInterval' ?
              intl.t('blockInput.form.fields.aggregationTypeDescr') :
              intl.t('blockInput.form.fields.aggregationTypeDescrWindow')
          }
          errors={get(errors, `${scope}.aggregationType`)}
        >
          <Controller
            shouldUnregister
            name={`${scope}.aggregationType`}
            render={({ field, fieldState }) => (
              <FormSelect
                {...field}
                invalid={fieldState.invalid}
                placeholder={intl.t('form.fields.aggregationTypePlaceholder')}
                options={BlockInputAggregationTypes.map(id => ({
                  id,
                  name: intl.t(`enum.dataTypes.${id}`),
                }))}
              />
            )}
          />
        </FormField>
      ) : null}

      {[ 'sensorInterval' ].includes(inputType) ? (
        <>
          <FormField
            label={intl.t('form.fields.dateFrom')}
            description={intl.t('blockInput.form.fields.dateFromDescr')}
            errors={get(errors, `${scope}.dateFrom`)}
          >
            <FormControl
              shouldUnregister
              type="dateTime"
              name={`${scope}.dateFrom`}
              placeholder={intl.t('form.fields.datePlaceholder')}
              DateTimeComponent={FormatDateTime}
            />
          </FormField>

          <FormField
            label={intl.t('form.fields.dateTo')}
            description={intl.t('blockInput.form.fields.dateToDescr')}
            errors={get(errors, `${scope}.dateTo`)}
          >
            <FormControl
              shouldUnregister
              type="dateTime"
              name={`${scope}.dateTo`}
              placeholder={intl.t('form.fields.datePlaceholder')}
              DateTimeComponent={FormatDateTime}
            />
          </FormField>
        </>
      ) : null}

      {[ 'sensorWindow', 'sensorRawWindow' ].includes(inputType) ? (
        <FormField
          label={intl.t('form.fields.duration')}
          description={intl.t('blockInput.form.fields.durationDescr')}
          errors={get(errors, `${scope}.duration`)}
        >
          <FormControl
            shouldUnregister
            type="duration"
            name={`${scope}.duration`}
            parts="months,weeks,days,hours,minutes,seconds"
            clearable
          />
        </FormField>
      ) : null}

      {[ 'sensorInterval', 'sensorWindow' ].includes(inputType) ? (
        <>
          <FormField
            label={intl.t('form.fields.aggregationStep')}
            description={intl.t('blockInput.form.fields.aggregationStepDescr')}
            errors={get(errors, `${scope}.aggregationStep`)}
          >
            <FormControl
              shouldUnregister
              type="duration"
              name={`${scope}.aggregationStep`}
              parts="months,weeks,days,hours,minutes,seconds"
              clearable
            />
          </FormField>

          <FormFieldInline
            label={intl.t('form.fields.includeNull')}
            description={intl.t('blockInput.form.fields.includeNullDescr')}
            errors={get(errors, `${scope}.includeNull`)}
          >
            <FormControl
              shouldUnregister
              type="checkbox"
              name={`${scope}.includeNull`}
              className="ms-2"
              disabled={!aggregationStep}
            />
          </FormFieldInline>

          {estimatedValues ? (
            <FormFieldInline className="mb-3" complex>
              {intl.t('blockInput.form.fields.estimatedValues', { value: estimatedValues })}
            </FormFieldInline>
          ) : null}
        </>
      ) : null}

      {[ 'sensorLastValue' ].includes(inputType) ? (
        <FormField
          label={intl.t('form.fields.amount')}
          description={intl.t('blockInput.form.fields.amountDescr')}
          errors={get(errors, `${scope}.amount`)}
        >
          <FormControl
            shouldUnregister
            type="number"
            name={`${scope}.amount`}
            min={2}
            max={10000}
            step={1}
          />
        </FormField>
      ) : null}

      {[ 'constant' ].includes(inputType) ? (
        <FormField
          label={intl.t('form.fields.jsonValue')}
          description={intl.t('blockInput.form.fields.jsonValueDescr')}
          errors={get(errors, `${scope}.value`)}
        >
          <FormControl
            shouldUnregister
            type="code"
            title={intl.t('form.fields.jsonValue')}
            description={intl.t('blockInput.form.fields.jsonValueDescr')}
            name={`${scope}.value`}
            lang="json"
          />
        </FormField>
      ) : (
        <FormFieldInline
          label={intl.t('form.fields.includeTimestamp')}
          description={intl.t('blockInput.form.fields.includeTimestampDescr')}
          errors={get(errors, `${scope}.includeTimestamp`)}
        >
          <FormControl
            shouldUnregister
            type="checkbox"
            name={`${scope}.includeTimestamp`}
            className="ms-2"
          />
        </FormFieldInline>
      )}

    </FormSection>
  )
}
