import { FormSection } from '@wiz/components'
import FieldsData from './FieldsData'

export default function SectionData ({ scope, ...props }) {
  return (
    <FormSection {...props}>
      <FieldsData
        scope={scope}
      />
    </FormSection>
  )
}
