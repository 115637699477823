import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useFormContext } from 'react-hook-form'
import { Icon, FormControl } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { BulletPlotSource } from '@wiz/store'
import BulletPlotSourceForm from '@/components/Forms/BulletPlotSource'
import SourcesExplorer from './SourcesExplorer'

export default function FieldsSources ({ scope }) {
  const intl = useIntl()
  const [ editId, setEditId ] = useState(null)
  const { watch, setValue } = useFormContext()
  const bulletPlotSources = watch(`${scope}.bulletPlotSources`)

  const handleRemoveById = useCallback((id) => {
    const nextSources = bulletPlotSources.filter(item => item.id !== id)
    setValue(`${scope}.bulletPlotSources`, nextSources, { shouldDirty: true })
  }, [ scope, bulletPlotSources, setValue ])

  const handleUpdateDisabledById = useCallback((id, disabled) => {
    const nextSources = bulletPlotSources
      .map(item => (
        item.id === id ? BulletPlotSource.toJSON({ ...item, disabled }) : item
      ))

    setValue(`${scope}.bulletPlotSources`, nextSources, { shouldDirty: true })
  }, [ scope, bulletPlotSources, setValue ])

  const handleReplace = useCallback((data) => {
    let nextData = Array.isArray(data) ? data : [ data ]
    nextData = nextData.reduce((out, item) => ({ ...out, [item.id]: item }), {})

    const nextSources = bulletPlotSources
      .map((item) => {
        if (nextData[item.id]) {
          const next = nextData[item.id]
          delete nextData[item.id]
          return BulletPlotSource.toJSON({ ...item, ...next })
        }
        return item
      })
      .concat(Object.values(nextData).map(item => BulletPlotSource.toJSON(item)))

    setValue(`${scope}.bulletPlotSources`, nextSources, { shouldDirty: true })
  }, [ scope, bulletPlotSources, setValue ])

  return (
    <div
      className={classnames('d-flex flex-column', {
        'flex-fill': !!bulletPlotSources.length,
        'align-items-center': !bulletPlotSources.length,
      })}
    >
      <FormControl type="any" name={`${scope}.bulletPlotSources`} />

      {bulletPlotSources.length ? (
        <>
          <div className="d-flex mx-3 my-2">
            <button
              type="button"
              className="btn btn-sm btn-outline-primary flex-fill"
              onClick={() => setEditId('')}
            >
              <Icon name="fa--plus" className="me-1" />
              {intl.t('form.actions.addSource')}
            </button>
          </div>

          <div
            className="flex-fill d-flex flex-column mt-2"
            style={{ height: 300 }}
          >
            <SourcesExplorer
              options={bulletPlotSources}
              onRemoveById={handleRemoveById}
              onUpdateById={setEditId}
              onUpdateDisabledById={handleUpdateDisabledById}
            />
          </div>
        </>
      ) : (
        <div className="d-flex flex-column mx-3 my-2">
          {intl.t('form.info.sourcesNotFound')}

          <button
            type="button"
            className="btn btn-sm btn-outline-primary mt-2"
            onClick={() => setEditId('')}
          >
            <Icon name="fa--plus" className="me-1" />
            {intl.t('form.actions.addSource')}
          </button>
        </div>
      )}

      {editId !== null ? (
        <BulletPlotSourceForm
          id={editId}
          bulletPlotSource={bulletPlotSources.find(item => item.id === editId)}
          onSubmit={({ bulletPlotSource }) => handleReplace(bulletPlotSource)}
          onClose={() => setEditId(null)}
          dialog={{
            title: editId ?
              intl.t('widgets.dynamicBulletPlot.titleUpdateSource') :
              intl.t('widgets.dynamicBulletPlot.titleCreateSource'),
            dataTestid: 'replaceBulletPlotSourceDialog',
          }}
        />
      ) : null}
    </div>
  )
}

FieldsSources.propTypes = {
  scope: PropTypes.string.isRequired,
}
