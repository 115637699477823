import { useMemo } from 'react'
import { Dashboard as Dashboard$, ShareUserDashboard } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import { useAuth } from '@/auth'
import Removed from '../Removed'
import Section from './Section'
// import SectionProjects from './SectionProjects'
import SectionTwins from './SectionTwins'

export default function Dashboard ({
  id,
  dashboard,
  permissions,
  projects,
  twins,
  dialog,
  onClose,
  onSuccess,
  onSubmit,
  templateId,
}) {
  const auth = useAuth()
  const canUpdateGlobalShare = dashboard && auth.checkAccessUpdate(dashboard, 'isGlobalShared')
  const immutablePermissions = JSON.stringify(permissions.map(item => item?.updatedAt))
  const intl = useIntl()

  const defaultValues = useMemo(() => ({
    dashboard: {
      ...Dashboard$.toJSON(dashboard),
      templateId,
    },
    permissions: permissions.map(item => ShareUserDashboard.toJSON(item)),
    projects,
    twins,
  }), [
    dashboard?.updatedAt ?? dashboard,
    immutablePermissions,
    projects,
    twins,
    templateId,
  ])

  if (id && !dashboard) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      onSuccess={onSuccess}
      defaultValues={defaultValues}
      dataTestid="dashboardForm"
      dialog={dialog}
    >
      <Section
        scope="dashboard"
        templateId={templateId}
        canUpdateGlobalShare={canUpdateGlobalShare}
      />

      {auth.checkAccessRead('Twin') ? (
        <SectionTwins
          title={intl.t('dashboards.form.fields.twins')}
          description={intl.t('dashboards.form.fields.twinsDescr')}
          scope="twins"
          templateId={templateId}
        />
      ) : null}

      {/* {auth.checkAccessRead('Project') ? (
        <SectionProjects
          title={intl.t('dashboards.form.fields.projects')}
          description={intl.t('dashboards.form.fields.projectsDescr')}
          scope="projects"
        />
      ) : null} */}

    </FormDialog>
  )
}
