import { useCallback, useState, useRef } from 'react'
import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { withObservables, withProps } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { isEmpty, orderBy } from '@wiz/utils'
import { auth } from '@/auth'
import { TopMenu } from '@/components/TopMenu'
import SpotlightParts from '@/components/Spotlight/parts.json'
import CurrentUser from '@/hoc/CurrentUser'
import { contextTwin } from '@/utils/contextTwin'

const enhanceSettings = withObservables([ 'currentUser' ], ({ currentUser }) => ({
  spotlightFavorites: dbProvider.observeSettings('spotlightFavorites'),
  contextTwinId: contextTwin.getContextTwin(),
  globalSettings: dbProvider.observeGlobalSettings([ 'EnvironmentName', 'EnvironmentColor', 'EnvironmentLogo' ]),
  featureFlags: dbProvider.observeFlags([ 'TwinContextEnabled' ]),
  twins: currentUser.queryRelTwins ? currentUser.queryRelTwins.observeWithColumns([ 'updated_at' ])
    .pipe(
      map(twins => twins.map(
        twin => twin.twinId || twin.id,
      )),
    ) : of$([]),
  queryDashboards: dbProvider.database.collections.get('dashboards').query().observeWithColumns([ 'updated_at' ])
    .pipe(
      map(items => items.filter(item => auth.checkAccessRead(item))),
      map(items => items.map(item => ({
        id: item.id,
        path: `/dashboards/${item.id}`,
        name: item.title,
        description: item.description,
        icon: 'fa--th-large',
        group: '98d476fe-bafc-4516-aa6c-9cd34692cac7',
        menu: true,
        spotlight: true,
        route: { name: 'dashboard', params: { id: item.id } },
        PreviewComponent: 'PreviewDashboard',
        dashboard: item,
      }))),
    ),

  queryTwinGraphs: dbProvider.database.collections.get('twin_graphs').query().observeWithColumns([ 'updated_at' ])
    .pipe(
      map(items => items.filter(item => auth.checkAccessRead(item))),
      map(items => items.map(item => ({
        id: item.id,
        path: `/twin/chart/${item.id}`,
        name: item.name,
        description: item.description,
        icon: 'flow',
        group: 'c1a92608-249f-4d83-b615-838903b07b27',
        menu: true,
        spotlight: true,
        route: { name: 'chart-view', params: { id: item.id } },
        PreviewComponent: 'PreviewTwinGraph',
        twinGraph: item,
      }))),
    ),
}))

const enhanceData = withObservables([
  'spotlightFavorites',
  'twins',
  'twinContext',
  'queryDashboards',
  'queryTwinGraphs',
], ({
  spotlightFavorites,
  twins,
  twinContext,
  queryDashboards,
  queryTwinGraphs,
}) => {
  let serializedTwins
  let queryTwins = dbProvider.database.collections.get('twins').query()
  if (twins.length) {
    serializedTwins = queryTwins.extend(Q.where('id', Q.oneOf(twins))).observeWithColumns([ 'updated_at' ]).pipe(
      map(items => items.map(item => ({ id: item.id, name: item.name }))),
      map(items => orderBy(items, [ item => item.name ], [ 'asc' ])),
    )
  }

  if (twinContext) {
    queryTwins = queryTwins.extend(Q.where('id', twinContext))
  }

  const groups = SpotlightParts.filter(item => (
    !item.route &&
    !item.disabled
  ))

  const queryParts = of$(
    SpotlightParts.filter(item => !!(
      item.route &&
      !item.disabled &&
      (
        isEmpty(item.access) ||
        item.access.every(rule => auth.checkAccessRules(rule[1], rule[0]))
      )
    )),
  )

  return {
    contextTwin: twinContext ? queryTwins.observeWithColumns([ 'updated_at' ]) : of$([]),
    twins: serializedTwins ?? of$([]),
    options: queryParts
      .pipe(
        map((parts) => {
          let items = [].concat(parts, queryDashboards)
          if (queryTwinGraphs.length) {
            const idx = items.findIndex(item => item.id === 'd6bc5179-8c1a-4078-a324-c2ce81fac6c9')
            if (idx !== -1) {
              items.splice(idx + 1, 0, ...queryTwinGraphs)
            } else {
              items = items.concat(queryTwinGraphs)
            }
          }

          let partsByGroup = {}
          const options = []

          for (const part of items) {
            const group = groups.find(item => item.id === part.group)
            if (group) {
              partsByGroup[part.group] = partsByGroup[part.group] || { ...group, options: [] }
              partsByGroup[part.group].options.push({
                ...part,
                isFavorite: spotlightFavorites.includes(part.id),
              })
            }
          }

          partsByGroup = orderBy(Object.values(partsByGroup), [ 'sort' ], [ 'asc' ])
          for (const group of partsByGroup) {
            const groupOptions = group.options.filter(item => item.isFavorite)

            if (groupOptions.length) {
              options.push(...orderBy(groupOptions, [ 'sort' ], [ 'asc' ]))
            }
          }

          return options
        }),
      ),
  }
})

const enhanceProps = withProps(() => {
  const [ twinContext, setTwinContext ] = useState()
  const [ loading, setLoading ] = useState(false)
  const resetRef = useRef(false)

  const handleSyncSuccess = useCallback(() => {
    contextTwin.clearContextTwin(!!resetRef.current)
    setLoading(false)
    dbProvider.sync.removeListener('synced', handleSyncSuccess)
  }, [])

  const handleTwinChoose = useCallback(async ({ recordId }) => {
    const isResetId = recordId.startsWith('00000000')
    if (isResetId) {
      resetRef.current = true
    } else {
      resetRef.current = false
    }

    setLoading(true)

    await contextTwin.setContextTwin(recordId)
    dbProvider.sync.once('synced', handleSyncSuccess)
    await dbProvider.sync.sync()
    setTwinContext(recordId)
  }, [ handleSyncSuccess ])

  return {
    twinContext,
    handleTwinChoose,
    loading,
  }
})

export default CurrentUser(enhanceSettings(
  enhanceProps(enhanceData(TopMenu)),
))
