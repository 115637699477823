import { FormSection } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { StreamJob, Explorer, Dashboard } from '@wiz/store'
import Tree from '@/components/Form/Tree/Tree'
import Link from '@/components/Link'

function TreeContent ({ data }) {
  const intl = useIntl()
  const { name, payload, nestingLevel } = data

  if (Dashboard.is(payload)) {
    return (
      <Link
        name="dashboard"
        params={{ id: payload.id }}
        className="text-reset text-truncate min-w-0"
      >
        {intl.t(name)}
      </Link>
    )
  }

  if (Explorer.is(payload)) {
    return (
      <Link
        name="data-observation"
        params={{ id: payload.id }}
        className="text-reset text-truncate min-w-0"
      >
        {intl.t(name)}
      </Link>
    )
  }

  if (StreamJob.is(payload)) {
    return (
      <Link
        name="stream-jobs-view"
        params={{ id: payload.id }}
        className="text-reset text-truncate min-w-0"
      >
        {intl.t(name)}
      </Link>
    )
  }

  return nestingLevel > 0 ? intl.t(name) : (
    <span className="text-secondary fs-5 lh-1">
      {intl.t(name)}
      &nbsp;
      (
      {payload.count}
      )
    </span>
  )
}

export default function SectionUsage ({ usage }) {
  const intl = useIntl()
  return (
    <FormSection
      className="flex-fill d-flex flex-column min-h-0"
      classNameContent="flex-fill d-flex min-h-0"
      description={intl.t('scripts.sections.usageDescr')}
    >
      <Tree
        className="flex-fill min-h-0"
        options={usage}
        Content={TreeContent}
        isOpenByDefault={usage.length <= 1}
      />
    </FormSection>
  )
}
