/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'
import Observe from '@/containers/Observe'
import Details from './Details'

export default function ViewModel ({
  title,
  job,
  onClose,
  onRemove,
  onEdit,
}) {
  const refDialog = useRef()
  const intl = useIntl()
  const auth = useAuth()

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('form.fields.name'),
          value: job.name,
        },
        {
          title: intl.t('jobs.form.fields.state'),
          value: job.state,
        },
        {
          title: intl.t('jobs.form.fields.command'),
          value: job.command,
        },
        {
          title: intl.t('form.fields.project'),
          value: () => (
            <Observe query={job.queryProjects}>
              {({ data }) => (data.length ? (
                <div className="d-flex flex-wrap min-w-0 align-items-center">
                  {data.map((item, idx) => (
                    <div key={item.id} className="d-flex me-1 min-w-0">
                      <div className="text-truncate">
                        {item.name}
                      </div>
                      {idx === data.length - 1 ? null : ','}
                    </div>
                  ))}
                </div>
              ) : '-')}
            </Observe>
          ),
        },
        {
          title: intl.t('form.fields.details'),
          value: () => (
            <Details row={{ original: job }} />
          ),
        },
      ],
    },

    {
      title: intl.t('form.fields.updated'),
      value: () => <UpdatedInfo date={job.updatedAt} user={job.updatedUser} />,
    },
    {
      title: intl.t('form.fields.created'),
      value: () => <UpdatedInfo date={job.createdAt} user={job.createdUser} />,
    },
  ]

  const actions = [
    ...(onRemove && auth.checkAccessRemove(job) ? ([{
      title: intl.t('form.actions.remove'),
      name: 'remove',
      testid: 'removeButton',
      onClick: () => onRemove([ job ]),
    }]) : []),

    ...(onEdit && auth.checkAccessUpdate(job) ? ([{
      title: intl.t('form.actions.edit'),
      name: 'edit',
      testid: 'editButton',
      onClick: () => onEdit(job),
    }]) : []),
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      actions={actions}
      onClose={onClose}
    />
  )
}
