import PropTypes from 'prop-types'

export default function OptionDefault (props) {
  const {
    data: {
      payload: item,
    },
  } = props

  return (
    <div className="flex-fill min-w-0">
      {item.name}
    </div>
  )
}

OptionDefault.propTypes = {
  data: PropTypes.shape({
    payload: PropTypes.object.isRequired,
  }).isRequired,
}
