import { FormInputSearch, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import EdgeDeviceList from '@/hoc/EdgeDeviceList'
import Tree from '@/containers/EdgeTelemetry/Tree'
import Select from '@/components/Form/Select'

export default function List ({
  filters,
  onFilter,
  onResetFilter,
}) {
  const intl = useIntl()
  const isMobile = useMobile()

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">

      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <div className="d-flex align-items-center flex-wrap">
          <div className="px-2 mb-2 col-12 col-sm-4">
            <FormInputSearch
              name="search"
              placeholder={intl.t('form.actions.searchPlaceholder')}
              value={filters.search}
              onChange={search => onFilter({ search })}
            />
          </div>

          <div className="px-2 mb-2 col-sm-3 col-lg-2">
            <Select
              name="type"
              ListSource={EdgeDeviceList}
              placeholder={intl.t('edge.devices.form.fields.deviceIdPlaceholder')}
              value={filters.id}
              onChange={id => onFilter({ id })}
            />
          </div>

          <div className="px-2 mb-2">
            <button
              type="button"
              className="btn btn-fill-secondary"
              onClick={onResetFilter}
            >
              {intl.t('form.actions.resetFilters')}
            </button>
          </div>
        </div>

        <Tree
          filters={filters}
        />
      </div>
    </div>
  )
}
