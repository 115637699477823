/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import { Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'
import ButtonCopy from '@/components/Form/ButtonCopy'

export default function ViewModel ({
  title,
  notebook,
  onClose,
  onRemove,
  onEdit,
  onPreview,
  onExport,
}) {
  const refDialog = useRef()
  const intl = useIntl()
  const auth = useAuth()

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('notebooks.form.fields.name'),
          value: notebook.name,
        },
        {
          title: intl.t('form.fields.description'),
          value: notebook.description || '-',
        },
        {
          title: intl.t('notebooks.form.fields.databricksPath'),
          value: () => (
            <div className="d-flex align-items-center min-w-0">
              {notebook.path}
              <ButtonCopy value={notebook.path} />
            </div>
          ),
        },
        {
          title: intl.t('notebooks.form.fields.environment'),
          value: notebook.environment,
        },
        {
          title: intl.t('notebooks.form.fields.language'),
          value: notebook.language,
        },
        {
          title: intl.t('notebooks.form.fields.format'),
          value: notebook.format,
        },
        {
          title: intl.t('notebooks.form.fields.hyperlink'),
          value: () => (
            <div className="d-flex align-items-center min-w-0">
              {notebook.url ? (
                <>
                  <a
                    href={notebook.url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-truncate user-select-all"
                  >
                    {notebook.url}
                  </a>
                  <ButtonCopy value={notebook.url} />
                </>
              ) : '-'}
            </div>
          ),
        },
      ],
    },

    {
      title: intl.t('form.fields.updated'),
      value: () => <UpdatedInfo date={notebook.updatedAt} user={notebook.updatedUser} />,
    },
    {
      title: intl.t('form.fields.created'),
      value: () => <UpdatedInfo date={notebook.createdAt} user={notebook.createdUser} />,
    },
  ]

  const actions = [
    ...(onRemove && auth.checkAccessRemove(notebook) ? ([{
      title: <Icon name="fa--trash-alt" />,
      name: 'remove',
      testid: 'removeButton',
      onClick: () => onRemove([ notebook ]),
    }]) : []),

    ...(onPreview ? ([{
      title: intl.t('form.actions.preview'),
      name: 'preview',
      testid: 'previewButton',
      onClick: () => onPreview(notebook.id),
    }]) : []),

    ...(onExport && auth.checkAccessRead('SectionExportResults') ? ([{
      title: intl.t('form.actions.export'),
      name: 'export',
      testid: 'exportButton',
      onClick: () => onExport(notebook),
    }]) : []),

    ...(onEdit && auth.checkAccessUpdate(notebook) ? ([{
      title: intl.t('form.actions.edit'),
      name: 'edit',
      testid: 'editButton',
      onClick: () => onEdit(notebook.id),
    }]) : []),
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      actions={actions}
      onClose={onClose}
    />
  )
}
