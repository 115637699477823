import { useState } from 'react'
import { Icon, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import User from '@/hoc/User'
import FormUserCreate from '@/containers/Forms/UserCreate'
import FormUserUpdate from '@/containers/Forms/UserUpdate'
import FormUserBulkEdit from '@/containers/Forms/UserBulkEdit'
import Table from '@/containers/User/Table'
import TableMobile from '@/containers/User/TableMobile'
import ViewModel from './ViewModel'
import Filters from './Filters'

const ViewModelData = User(ViewModel)

export default function List ({
  filters,
  selected,
  onFilter,
  onResetFilter,
  onRemove,
  onToggleActive,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const isMobile = useMobile()
  const [ viewId, setViewId ] = useState(null)
  const [ editId, setEditId ] = useState(null)
  const [ bulkEdit, setBulkEdit ] = useState(false)

  const TableComponent = isMobile ? TableMobile : Table

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">

      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <div className="d-flex align-items-center flex-wrap">
          <div className="px-2 mb-2 flex-shrink-0">
            {auth.checkAccessCreate('User') ? (
              <button
                type="button"
                className="btn btn-flat-secondary"
                onClick={() => setEditId('')}
              >
                <Icon name="fa--plus" className="me-1" />
                {intl.t('form.actions.add')}
              </button>
            ) : null}

            {auth.checkAccessUpdate('User') ? (
              <button
                type="button"
                className="btn btn-flat-secondary btn-text"
                disabled={!selected.length}
                onClick={() => setBulkEdit(true)}
              >
                <Icon name="fa--edit" className="me-sm-1" />
                <span className="d-none d-sm-inline">{intl.t('form.actions.bulkEdit')}</span>
              </button>
            ) : null}

            {auth.checkAccessRemove('User') ? (
              <button
                type="button"
                className="btn btn-flat-secondary"
                onClick={() => onRemove(selected)}
                disabled={!selected.length}
              >
                <Icon name="fa--trash-alt" className="me-1" />
                {intl.t('form.actions.remove')}
              </button>
            ) : null}
          </div>

          <Filters
            filters={filters}
            onFilter={onFilter}
            onResetFilter={onResetFilter}
          />
        </div>

        <TableComponent
          {...props}
          filters={filters}
          onFilter={onFilter}
          onRemove={onRemove}
          onEdit={setEditId}
          onToggleActive={onToggleActive}
          onView={setViewId}
        />
      </div>

      {bulkEdit ? (
        <FormUserBulkEdit
          users={selected}
          onClose={() => setBulkEdit(false)}
          dialog={{
            title: intl.t('users.titleBulkEdit'),
            dataTestid: 'bulkEditUserDialog',
          }}
        />
      ) : null}

      {editId ? (
        <FormUserUpdate
          id={editId}
          onClose={() => setEditId(null)}
          dialog={{
            title: intl.t('users.titleUpdate'),
            dataTestid: 'replaceUserDialog',
          }}
        />
      ) : null}

      {!editId && editId !== null ? (
        <FormUserCreate
          onClose={() => setEditId(null)}
          dialog={{
            title: intl.t('users.titleCreate'),
            dataTestid: 'replaceUserDialog',
          }}
        />
      ) : null}

      {viewId ? (
        <ViewModelData
          id={viewId}
          title={intl.t('users.titleDetail')}
          onClose={() => setViewId(null)}
          onRemove={onRemove}
          onEdit={setEditId}
          onFilter={onFilter}
          dialog
        />
      ) : null}
    </div>
  )
}
