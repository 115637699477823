import { FormInputSearch } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import SelectTwin from '@/components/Form/SelectTwin'
import ListFilters from '@/components/Form/ListFilters'

export default function Filters ({
  filters,
  onFilter,
  onResetFilter,
}) {
  const intl = useIntl()
  const auth = useAuth()

  return (
    <ListFilters
      filters={filters}
      onFilter={onFilter}
      onResetFilter={onResetFilter}
    >
      {({ filters, onFilter }) => (
        <>
          <div className="px-md-2 mb-2 col-md-3">
            <FormInputSearch
              placeholder={intl.t('form.actions.searchPlaceholder')}
              value={filters.search}
              onChange={value => onFilter({ search: value })}
            />
          </div>

          {auth.checkAccessRead('Twin') ? (
            <div className="px-md-2 mb-2 col-md-3">
              <SelectTwin
                value={filters.twin}
                placeholder={intl.t('form.fields.twinPlaceholder')}
                onChange={value => onFilter({ twin: value })}
              />
            </div>
          ) : null}
        </>
      )}
    </ListFilters>
  )
}
