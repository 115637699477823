import {
  type,
  intersection,
} from 'superstruct'
import { DataTypes } from '@wiz/utils'
import { BulletPlotSource } from '@wiz/store'
import * as types from '@/utils/types'

export default type({
  // axisTicks: DataTypes.NullableInt(24, 0),
  colorPercentile: DataTypes.NullableColor(),
  colorGreen: DataTypes.NullableColor(),
  colorCurrent: DataTypes.NullableColor(),
  colorRealtime: DataTypes.NullableColor(),
  dateFrom: intersection([
    BulletPlotSource.schema.schema.dateFrom,
    types.SlidingDateFromRequired(),
    types.SlidingDateFrom(),
  ]),
  dateTo: intersection([
    BulletPlotSource.schema.schema.dateTo,
    types.SlidingDateFromRequired(),
    types.SlidingDateTo(),
  ]),
  duration: intersection([
    BulletPlotSource.schema.schema.duration,
    types.SlidingDurationRequired(),
    types.SlidingDuration(),
  ]),
  offsetDuration: BulletPlotSource.schema.schema.offsetDuration,
  stepRequest: BulletPlotSource.schema.schema.stepRequest,
  rangeDateFrom: intersection([
    BulletPlotSource.schema.schema.rangeDateFrom,
    types.SlidingDateFromRequired({ keyDuration: 'rangeDuration' }),
    types.SlidingDateFrom({ keyTo: 'rangeDateTo', keyDuration: 'rangeDuration' }),
  ]),
  rangeDateTo: intersection([
    BulletPlotSource.schema.schema.rangeDateTo,
    types.SlidingDateTo({ keyFrom: 'rangeDateFrom' }),
  ]),
  rangeDuration: intersection([
    BulletPlotSource.schema.schema.rangeDuration,
    types.SlidingDurationRequired({ keyFrom: 'rangeDateFrom' }),
    types.SlidingDuration({ keyFrom: 'rangeDateFrom' }),
  ]),
  rangeOffsetDuration: BulletPlotSource.schema.schema.rangeOffsetDuration,
  rangeStepRequest: BulletPlotSource.schema.schema.rangeStepRequest,
  limitMax: intersection([
    types.Required(),
    BulletPlotSource.schema.schema.limitMax,
    types.LimitMax(),
  ]),
  limitMin: intersection([
    types.Required(),
    BulletPlotSource.schema.schema.limitMin,
    types.LimitMin(),
  ]),
  rangeMax: intersection([
    types.Required(),
    BulletPlotSource.schema.schema.rangeMax,
    types.LimitMax({ keyMin: 'rangeMin' }),
  ]),
  rangeMin: intersection([
    types.Required(),
    BulletPlotSource.schema.schema.rangeMin,
    types.LimitMin({ keyMax: 'rangeMax' }),
  ]),
})
