export default function templates (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      templates: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value (withProperties) {
              let query = ''
              if (withProperties) {
                query += `?properties=${withProperties}`
              }
              return client.get(`/templates/${query}`).fetch()
            },
          },
          getById: {
            enumerable: true,
            value (id) {
              return client.get(`/templates/${id}/`).fetch()
            },
          },
          create: {
            enumerable: true,
            value (params) {
              return client.post('/templates', { ...params }).fetch()
            },
          },
          updateById: {
            enumerable: true,
            value (params) {
              return client.put(`/templates/${params.id}`, { ...params }).fetch()
            },
          },
          deleteById: {
            enumerable: true,
            value (id) {
              return client.delete(`/templates/${id}`).fetch()
            },
          },
          updatePropertiesById: {
            enumerable: true,
            value (id, data) {
              return client.put(`/templates/${id}/properties`, { ...data }).fetch()
            },
          },
          getRegistrationsById: {
            enumerable: true,
            value (id) {
              return client.get(`/templates/${id}/registrations`).fetch()
            },
          },
          getPropertiesByTemplateId: {
            enumerable: true,
            value (id, twinId) {
              return client.get(`/templates/${id}/registrations/${twinId}`).fetch()
            },
          },
          getRegistrationPropertiesById: {
            enumerable: true,
            value (id) {
              return client.get(`/templates/${id}/properties`).fetch()
            },
          },
          createProperty: {
            enumerable: true,
            value (id, data) {
              return client.post(`/templates/${id}/properties`, { ...data }).fetch()
            },
          },
          createRegistration: {
            enumerable: true,
            value ({ templateId, twinId, payload }) {
              return client.post(`/templates/${templateId}/registrations/${twinId}`, payload).fetch()
            },
          },
          updateRegistration: {
            enumerable: true,
            value ({ templateId, twinId, payload }) {
              return client.put(`/templates/${templateId}/registrations/${twinId}`, payload).fetch()
            },
          },
          generateTemplate: {
            enumerable: true,
            value (data) { // {experimentId: string}
              return client.post('/templates/generate', { ...data }).fetch()
            },
          },
          autoGenerateTemplate: {
            enumerable: true,
            value ({ templateId, twinId }) {
              return client.get(`/templates/${templateId}/auto-register/${twinId}/`).fetch()
            },
          },
          deleteRegistration: {
            enumerable: true,
            value (templateId, twinId) {
              return client.delete(`/templates/${templateId}/registrations/${twinId}`).fetch()
            },
          },
        }),
      },
      templateProperties: {
        enumerable: true,
        value: Object.create({}, {
          getListById: {
            enumerable: true,
            value (templateId) {
              return client.get(`/templateproperties/?templateId=${templateId}`).fetch()
            },
          },
          create: {
            enumerable: true,
            value ({ payload }) {
              return client.post('/templateproperties/', payload).fetch()
            },
          },
          update: {
            enumerable: true,
            value ({ id, payload }) {
              return client.put(`/templateproperties/${id}`, payload).fetch()
            },
          },
          deleteById: {
            enumerable: true,
            value (id) {
              return client.delete(`/templateproperties/${id}`).fetch()
            },
          },
        }),
      },
    })
  }
}
