import { useMemo } from 'react'
import { DiagramBlock, MlWebServiceNg, MlWebServiceNgInterface } from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import BlockSection from '../Block/Section'
import SettingsSection from './SettingsSection'

export default function BlockWebservice ({
  block,
  settings,
  interfaces,
  dialog,
  onClose,
  onSubmit,
}) {
  const immutableInterfaces = JSON.stringify(interfaces.map(item => item?.updatedAt))

  const defaultValues = useMemo(() => ({
    block: DiagramBlock.toJSON(block),
    settings: {
      webService: MlWebServiceNg.toJSON(settings),
      interfaces: interfaces.map(item => MlWebServiceNgInterface.toJSON(item)),
    },
  }), [
    block?.updatedAt ?? block,
    settings?.updatedAt ?? settings,
    immutableInterfaces,
  ])

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="blockWebserviceForm"
      dialog={dialog}
    >
      <BlockSection scope="block" />
      <SettingsSection scope="settings" />
    </FormDialog>
  )
}
