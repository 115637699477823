import { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Twin } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import SelectTwin from '@/components/Form/SelectTwin'
import ObservTwin from '@/containers/Forms/Twin/Observ'
import Section from '@/components/Forms/Twin/Section'
import SectionDashboards from '@/components/Forms/Twin/SectionDashboards'
import SectionExplorers from '@/components/Forms/Twin/SectionExplorers'
import SectionStreamJobs from '@/components/Forms/Twin/SectionStreamJobs'
import SectionTwinGraphs from '@/components/Forms/Twin/SectionTwinGraphs'

export default function SettingsSection ({ scope, type }) {
  const intl = useIntl()
  const {
    register,
    watch,
    setValue,
    clearErrors,
  } = useFormContext()
  const id = watch(`${scope}.twin.id`)

  const handleChange = useCallback(({
    twin,
    dashboards,
    explorers,
    streamJobs,
    twinGraphs,
  }) => {
    const next = Twin.toJSON(twin)
    setValue(`${scope}.id`, next.id, { shouldDirty: true })
    setValue(`${scope}.twin`, next, { shouldDirty: true })
    setValue(`${scope}.dashboards`, dashboards, { shouldDirty: true })
    setValue(`${scope}.explorers`, explorers, { shouldDirty: true })
    setValue(`${scope}.streamJobs`, streamJobs, { shouldDirty: true })
    setValue(`${scope}.twinGraphs`, twinGraphs, { shouldDirty: true })
    clearErrors(scope)
  }, [ scope, setValue, clearErrors ])

  const defaultValues = useMemo(() => ({ type }), [ type ])

  return (
    <>
      <ObservTwin
        id={id}
        onChange={handleChange}
        defaultValues={defaultValues}
      />

      <input
        {...register(`${scope}.id`)}
        type="hidden"
        defaultValue={id}
      />

      <Section
        scope={`${scope}.twin`}
        registerId={false}
        disabledFields={[ `${scope}.twin.type` ]}
        title={intl.t(`form.sections.${type}`)}
        description={intl.t('blockTwin.section.twinDescr')}
        addon={() => (
          <Controller
            name={`${scope}.twin.id`}
            render={({ field, fieldState }) => (
              <SelectTwin
                {...field}
                invalid={fieldState.invalid}
                filters={{ types: [ type ] }}
                placeholder={intl.t('form.fields.twinPlaceholder')}
                className="form-select-sm min-w-0 w-100 w-md-auto mt-2 mt-md-0 ms-md-2"
              />
            )}
          />
        )}
      />

      <SectionTwinGraphs scope={`${scope}.twinGraphs`} />
      <SectionDashboards scope={`${scope}.dashboards`} />
      <SectionExplorers scope={`${scope}.explorers`} />
      <SectionStreamJobs scope={`${scope}.streamJobs`} />
    </>
  )
}
