import { Controller, useFormContext } from 'react-hook-form'
import { get, consts } from '@wiz/utils'
import {
  useDidUpdate,
  FormField,
  FormSelect,
  FormControl,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import SelectSensor from '@/components/Form/SelectSensor'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const {
    formState: { errors },
    watch,
    setValue,
  } = useFormContext()
  const triggerType = watch(`${scope}.triggerType`)

  useDidUpdate(() => {
    // delay to update virtual DOM after change triggerType
    window.setTimeout(() => {
      setValue(`${scope}.triggerSensorId`, null)
      setValue(`${scope}.triggerCondition`, null)
      setValue(`${scope}.triggerValue`, null)
      setValue(`${scope}.triggerInterval`, null)
    }, 0)
  }, [ triggerType, scope, setValue ])

  return (
    <>
      <FormField
        label={intl.t('streamJobs.form.fields.triggerType')}
        description={intl.t('streamJobs.form.fields.triggerTypeDescr')}
        errors={get(errors, `${scope}.triggerType`)}
      >
        <Controller
          name={`${scope}.triggerType`}
          rules={{ required: intl.t('form.errors.fieldRequired') }}
          shouldUnregister
          render={({ field, fieldState }) => (
            <FormSelect
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('streamJobs.form.fields.triggerTypePlaceholder')}
              options={consts.StreamJobTriggerTypes.map(id => ({
                id,
                name: intl.t(`enum.streamJobTriggerType.${id}`),
              }))}
            />
          )}
        />

        {triggerType === consts.StreamJobTriggerType.SensorStream ? (
          <b className="mt-1 small d-block">
            {intl.t('streamJobs.form.fields.triggerTypeStreamDescr')}
          </b>
        ) : null}
        {triggerType === consts.StreamJobTriggerType.SensorCondition ? (
          <b className="mt-1 small d-block">
            {intl.t('streamJobs.form.fields.triggerTypeConditionDescr')}
          </b>
        ) : null}
        {triggerType === consts.StreamJobTriggerType.Interval ? (
          <b className="mt-1 small d-block">
            {intl.t('streamJobs.form.fields.triggerTypeIntervalDescr')}
          </b>
        ) : null}
      </FormField>

      {do {
        if (triggerType === consts.StreamJobTriggerType.SensorCondition) {
          <>
            <FormControl type="any" name={`${scope}.triggerInterval`} shouldUnregister />

            <FormField
              label={intl.t('form.fields.sensor')}
              description={intl.t('streamJobs.form.fields.sensorDescr')}
              errors={get(errors, `${scope}.triggerSensorId`)}
            >
              <Controller
                name={`${scope}.triggerSensorId`}
                rules={{ required: intl.t('form.errors.fieldRequired') }}
                shouldUnregister
                render={({ field, fieldState }) => (
                  <SelectSensor
                    {...field}
                    invalid={fieldState.invalid}
                    placeholder={intl.t('form.fields.sensorPlaceholder')}
                    excludeBusinessTypes={consts.ExcludedBusinessTypes}
                  />
                )}
              />
            </FormField>

            <FormField
              label={intl.t('form.fields.condition')}
              description={intl.t('streamJobs.form.fields.conditionDescr')}
              errors={get(errors, `${scope}.triggerCondition`)}
            >
              <Controller
                name={`${scope}.triggerCondition`}
                rules={{ required: intl.t('form.errors.fieldRequired') }}
                shouldUnregister
                render={({ field, fieldState }) => (
                  <FormSelect
                    {...field}
                    invalid={fieldState.invalid}
                    placeholder={intl.t('form.fields.conditionPlaceholder')}
                    options={[ 'eq', 'neq' ].map(id => ({ id, name: intl.t(`enum.conditionType.${id}`) }))}
                  />
                )}
              />
            </FormField>

            <FormField
              label={intl.t('form.fields.value')}
              description={intl.t('streamJobs.form.fields.valueDescr')}
              errors={get(errors, `${scope}.triggerValue`)}
            >
              <FormControl
                type="number"
                name={`${scope}.triggerValue`}
                rules={{ required: intl.t('form.errors.fieldRequired') }}
                scale={8}
                shouldUnregister
              />
            </FormField>
          </>
        } else if (triggerType === consts.StreamJobTriggerType.Interval) {
          <>
            <FormControl type="any" name={`${scope}.triggerSensorId`} shouldUnregister />
            <FormControl type="any" name={`${scope}.triggerCondition`} shouldUnregister />
            <FormControl type="any" name={`${scope}.triggerValue`} shouldUnregister />

            <FormField
              label={intl.t('streamJobs.form.fields.triggerInterval')}
              description={intl.t('streamJobs.form.fields.triggerIntervalDescr')}
              errors={get(errors, `${scope}.triggerInterval`)}
            >
              <FormControl
                type="duration"
                name={`${scope}.triggerInterval`}
                rules={{ required: intl.t('form.errors.fieldRequired') }}
                parts="years,months,weeks,days,hours,minutes,seconds"
                shouldUnregister
              />
            </FormField>
          </>
        } else {
          <>
            <FormControl type="any" name={`${scope}.triggerSensorId`} shouldUnregister />
            <FormControl type="any" name={`${scope}.triggerCondition`} shouldUnregister />
            <FormControl type="any" name={`${scope}.triggerValue`} shouldUnregister />
            <FormControl type="any" name={`${scope}.triggerInterval`} shouldUnregister />
          </>
        }
      }}
    </>
  )
}
