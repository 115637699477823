import { useCallback } from 'react'
import { withProps } from '@wiz/components'
import { dbProvider } from '@wiz/store'

const enhanceProps = withProps(({ widget }) => {
  const onSubmit = useCallback(async (data) => {
    if (widget) {
      const context = dbProvider.createBatchContext()
      await widget.prepareUpdateData(context, {
        ...data.widget,
        config: {
          ...data.widget.config,
          ...data.config,
        },
      })
      await widget.prepareReplaceBulletPlotSources(context, data.config.bulletPlotSources)
      await dbProvider.batch(context)
    }
  }, [ widget ])

  return {
    onSubmit,
  }
})

export default function enhanceSettingsDynamicBulletPlot (Component) {
  return enhanceProps(Component)
}
