import { markSearch, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import Link from '@/components/Link'

const StatusClassName = {
  [consts.ExperimentStatus.Completed]: 'text-success',
  [consts.ExperimentStatus.Failed]: 'text-danger',
  [consts.ExperimentStatus.Cancelled]: 'text-danger',
  [consts.ExperimentStatus.Created]: 'text-info',
  [consts.ExperimentStatus.InProgress]: 'text-info',
}

/* eslint-disable react/no-danger */
export default function CellName ({
  experiment,
  filters,
  isMobile,
  onRightBarOpen,
  onOpen,
}) {
  const intl = useIntl()

  const name = (onRightBarOpen || onOpen) ? (
    <button
      type="button"
      className="btn btn-link text-reset text-start p-0 border-0"
      onClick={() => onRightBarOpen?.(experiment) || onOpen?.(experiment)}
    >
      <span
        dangerouslySetInnerHTML={{ __html: markSearch(experiment.name, filters.search) }}
      />
    </button>
  ) : (
    <Link
      name="experiment"
      params={{ id: experiment.id }}
      className="text-reset text-truncate"
    >
      <span
        dangerouslySetInnerHTML={{ __html: markSearch(experiment.name, filters.search) }}
      />
    </Link>
  )

  return (
    <div className="min-w-0 d-flex">
      {name}
      {isMobile ? (
        <div className="text-secondary small lh-1 mt-1">
          <div>
            {intl.t(`enum.experimentType.${experiment.type}`)}
            <span className="mx-2">/</span>
            <span className={StatusClassName[experiment.status]}>
              {experiment.status ? intl.t(`enum.experimentStatus.${experiment.status}`) : '-'}
            </span>
          </div>
          {experiment.lastExecutionDate ? (
            <UpdatedInfo
              date={experiment.lastExecutionDate}
              className="my-1"
            />
          ) : null}
        </div>
      ) : null}
    </div>
  )
}
