import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'

export default function ViewModel ({
  title,
  edgeLog,
  onClose,
}) {
  const refDialog = useRef()
  const intl = useIntl()

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('form.fields.message'),
          value: edgeLog.message,
        },
        {
          title: intl.t('form.fields.device'),
          value: edgeLog.device,
        },
        {
          title: intl.t('form.fields.module'),
          value: edgeLog.module,
        },
        {
          title: intl.t('form.fields.created'),
          value: () => (<UpdatedInfo date={edgeLog.timestamp} withMillis withSecond />),
        },
      ],
    },
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      onClose={onClose}
    />
  )
}
