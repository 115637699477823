import { combineLatest, of as of$ } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { uniq, orderBy } from '@wiz/utils'

export default withObservables([ 'metaBlock', 'sensorIds', 'blockSettings', 'widgetSensorIds' ], ({
  metaBlock, sensorIds, blockSettings, widgetSensorIds,
}) => {
  const settings = Object.values(blockSettings || {})
    .filter(item => !!item)

  const twinIds = uniq(
    settings
      .map(item => (item.twinId || item.id))
      .filter(item => !!item),
  )

  const widgetSensors = widgetSensorIds ? dbProvider.database.collections.get('sensors').query(
    Q.where('id', Q.oneOf(widgetSensorIds)),
  ).observe() : of$(undefined)

  if (!twinIds.length || !sensorIds?.length) {
    return {
      twins: of$([]),
      widgetSensors,
    }
  }

  let query = dbProvider.database.collections.get('sensors').query()

  if (twinIds.length) {
    query = query.extend(Q.where('twin_id', Q.oneOf(twinIds)))
  }

  if (sensorIds?.length) {
    query = query.extend(Q.where('id', Q.oneOf(sensorIds)))
  }

  return {
    twins: query
      .observe()
      .pipe(
        map(items => settings.reduce((out, setting) => {
          const datapoints = orderBy(items.filter(item => (
            item.twinId === setting.twinId ||
            item.twinId === setting.id
          )), [ 'displayName' ], [ 'asc' ]).map((sensor) => {
            const output = { id: sensor.hardwareId }
            if (sensor.businessType) {
              output.type = sensor.businessType
            }
            return output
          })

          const res = { id: setting.id, datapoints }
          out.push(res)

          return out
        }, [])),
      ),

    diagramBlocks: metaBlock ? metaBlock.observeBlocks.pipe(
      switchMap(blocks => (
        blocks.length ?
          combineLatest(blocks.map(block => block.observeSettings)) :
          of$([])
      )),
    ) : of$([]),
    widgetSensors,
  }
})
