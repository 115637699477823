import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { withObservables } from '@wiz/components'

export default withObservables([
  'streamJob',
  'defaultValues',
], ({
  streamJob,
  defaultValues,
}) => ({
  schedules: streamJob?.observeSchedules ?
    streamJob.observeSchedules :
    of$(defaultValues?.schedules ?? []),

  twins: streamJob?.queryRelTwins ? (
    streamJob.queryRelTwins
      .observe()
      .pipe(
        map(items => items.map(item => item.twinId)),
      )
  ) : of$(defaultValues?.twins ?? []),
}))
