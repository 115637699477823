import { switchMap } from 'rxjs/operators'
import { withObservables } from '@wiz/components'

const enhanceData = withObservables([ 'query' ], ({ query }) => ({
  data: query.observe().pipe(
    switchMap(block => block.observeDiagramContext),
  ),
}))

const ObserveDiagramContext = enhanceData(({ children, ...props }) => children(props))
ObserveDiagramContext.displayName = 'ObserveDiagramContext'

export default ObserveDiagramContext
