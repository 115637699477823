import { define } from 'superstruct'
import { intl } from '@/i18n'

const SlidingDateFrom = ({
  keyTo = 'dateTo',
  keyDuration = 'duration',
} = {}) => define('SlidingDateFrom', (value, { branch }) => {
  const data = branch[branch.length - 2]
  let result = true
  if (value && data[keyDuration]) {
    result = intl.t('form.errors.fieldOptionalRequired')
  } else if (value && data[keyTo] && value >= data[keyTo]) {
    result = intl.t('form.errors.dateFromOutofrange')
  }
  return result
})

export default SlidingDateFrom
