import { withObservables } from '@wiz/components'

const enhanceData = withObservables([ 'query', 'count', 'observe' ], ({ query, count, observe }) => ({
  // eslint-disable-next-line no-nested-ternary
  data: observe || (query ? query.observe() : (count ? count.observeCount() : null)),
}), {
  forceUpdateError: true,
})

const Observe = enhanceData(({ children, ...props }) => children(props))
Observe.displayName = 'Observe'
export default Observe
