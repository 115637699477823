import { useIntl } from '@wiz/intl'
import { Icon } from '@wiz/components'

export default function SectionValidation () {
  const intl = useIntl()

  return (
    <div className="row h-100">
      <h6>{intl.t('jobs.form.fields.await')}</h6>
      <div className="d-flex flex-column flex-fill min-h-0 mx-4">

        <div className="position-center-fill d-flex flex-column">
          <Icon name="fa--spinner" size="2X" spin />
          <span>Job is proccessing right now</span>
        </div>
      </div>
    </div>
  )
}
