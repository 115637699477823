import { useMemo } from 'react'
import { VirtualTable, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'

export default function TableMobile ({
  items,
  onRemove,
  onEdit,
  onView,
  onToggleActive,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    ...(auth.checkAccessUpdate('User') ? [
      {
        Header: intl.t('form.fields.active'),
        accessor: 'isActive',
        disableResizing: true,
        minWidth: 55,
        width: 55,
        maxWidth: 55,
        className: 'justify-content-center',
        Cell: ({ cell, row }) => (auth.checkAccessUpdate(row.original) ? (
          <div className="form-check form-switch m-0 mb-n1 ps-3">
            <input
              type="checkbox"
              className="form-check-input pointer ms-n3"
              checked={cell.value}
              onChange={() => onToggleActive(row.original)}
            />
          </div>
        ) : null),
      },
    ] : []),
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      Cell: ({ cell, row }) => (
        <div className="min-w-0 text-truncate">
          {cell.value || (
            <span className="text-muted">{intl.t('form.noname')}</span>
          )}

          {row.original.email ? (
            <div className="text-muted text-truncate small">
              {row.original.email}
            </div>
          ) : null}
        </div>
      ),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell, row }) => (
        <>
          {auth.checkAccessRemove(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-2"
              onClick={() => onRemove?.([ row.original ])}
            >
              <Icon name="fa--trash-alt" />
            </button>
          ) : null}
          {auth.checkAccessUpdate(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-2"
              onClick={() => onEdit?.(cell.value)}
            >
              <Icon name="fa--edit" />
            </button>
          ) : null}
          <button
            type="button"
            className="btn btn-sm p-2"
            onClick={() => onView?.(cell.value)}
          >
            <Icon name="fa--chevron-right" />
          </button>
        </>
      ),
    },
  ], [ intl, onEdit, onRemove, onToggleActive, onView, auth ])

  return (
    <VirtualTable
      className="flex-fill"
      placeholder={intl.t('errors.noDataDisplay')}
      columns={columns}
      data={items}
      showHeader={false}
      selection={auth.checkAccessUpdate('User') || auth.checkAccessRemove('User')}
      {...props}
    />
  )
}
