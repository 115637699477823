/* eslint-disable react/destructuring-assignment */
import { createRef, Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { DateTime, Interval } from 'luxon'
import PQueue from 'p-queue'
import { parseAsync } from 'json2csv'
import { saveAs } from 'file-saver'
import Split from 'split.js'
import uniqBy from 'lodash/uniqBy'
import kebabCase from 'lodash/kebabCase'
import { Icon, useMobile, withProps } from '@wiz/components'
import {
  AbortRequestError,
  chartExportData,
  ChartLayout,
  chartSaveAsImage,
  ChartZoom,
  clone,
  consts,
  DataSourceError,
  debounce,
  duration,
  echarts,
  get,
  isEmpty,
  isEqual,
  isNil,
  OnlineTimeout,
  orderBy,
  RenewStore,
  timeout,
  timezones,
  uniq,
  validateNumberChartValues,
  validateRawSamplingDuration,
} from '@wiz/utils'
import {
  Q,
  dbProvider,
  Condition,
  DataSource,
  DataView,
} from '@wiz/store'
import { ServiceChannel, DataSourceRequest } from '@wiz/api'
import { isEqualObjectKeys } from '@/utils/object'
import { wizataApi } from '@/api'
import { intl } from '@/i18n'
import { appEnv } from '@/config'
import FormatDateTime from '@/containers/FormatDateTime'
import Dataview from './Dataview'
import Legend from './Legend'
import RawTooltip from './RawTooltip'
import classes from './index.css'

const CANCEL_TIMEOUT = 10000

const enhanceProps = withProps(() => {
  const isMobile = useMobile()
  return {
    isMobile,
  }
})

class SensorChart extends Component {
  static propTypes = {
    width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    refChart: PropTypes.shape({
      current: PropTypes.shape({
        saveAsImage: PropTypes.func,
        exploreValues: PropTypes.func,
        downloadCSV: PropTypes.func,
      }),
    }).isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    dataFilter: PropTypes.object,
    dataSources: PropTypes.array,
    dataViews: PropTypes.array,
    conditions: PropTypes.array,
    eventSources: PropTypes.array,
    theme: PropTypes.object,
    errors: PropTypes.object,
    counts: PropTypes.object,
    isMobile: PropTypes.bool,
    onLoading: PropTypes.func,
    onChangeStepDense: PropTypes.func,
    onChangeStepAuto: PropTypes.func,
    onResizeLegend: PropTypes.func,
    onChangeAppliedEvents: PropTypes.func,
    onToggleSelectedLegend: PropTypes.func,
    onErrors: PropTypes.func,
    onChangeCounts: PropTypes.func,
  }

  static defaultProps = {
    width: 0,
    height: 0,
    disabled: false,
    className: undefined,
    dataSources: [],
    dataViews: [],
    conditions: [],
    eventSources: [],
    errors: [],
    counts: {},
    theme: undefined,
    dataFilter: undefined,
    onLoading: undefined,
    onChangeStepDense: undefined,
    onChangeStepAuto: undefined,
    onResizeLegend: undefined,
    onChangeAppliedEvents: undefined,
    onToggleSelectedLegend: undefined,
    onErrors: undefined,
    onChangeCounts: undefined,
  }

  static getDerivedStateFromProps (props, state) {
    return {
      ...state,
      isTablet: props.width >= 768,
    }
  }

  refRoot = createRef()

  refChart = createRef()

  refSplitChart = createRef()

  refLegend = createRef()

  refDateFormatter = createRef()

  state = {
    isDataView: false,
    legendSelected: {},
    loading: false,
    firstLoading: null,
    error: false,
    dataView: [],
    hoverData: {},
    autoStepValue: 0,
    denseStepValue: 0,
    stepValue: 0,
    cancelDataLoad: false,
    legendOptions: [],
    events: [],
    isTablet: this.props.width >= 768,
  }

  constructor (...args) {
    super(...args)

    this.$watch = []
    this.$isInitialized = false
    this.$layout = null
    this.$split = null
    this.$realtimeTimer = 0
    this.$requests = {}
    this.$apiRequests = {}
    this.$responses = new RenewStore()
    this.$queue = new PQueue({ concurrency: 1, autoStart: false })
    this.$queueEvents = new PQueue({ concurrency: 2, autoStart: false })
  }

  componentDidMount () {
    this.initialize()

    const { refChart } = this.props

    if (refChart) {
      refChart.current = Object.freeze({
        saveAsImage: options => this.saveAsImage(options),
        exploreValues: () => this.exploreValues(),
        downloadCSV: options => this.downloadCSV(options),
      })
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return (
      this.props.dataSources !== nextProps.dataSources ||
      this.props.dataFilter !== nextProps.dataFilter ||
      this.props.dataViews !== nextProps.dataViews ||
      this.props.conditions !== nextProps.conditions ||
      this.props.eventSources !== nextProps.eventSources ||
      this.props.disabled !== nextProps.disabled ||
      this.props.className !== nextProps.className ||
      this.props.errors !== nextProps.errors ||
      this.props.width !== nextProps.width ||
      this.props.height !== nextProps.height ||
      this.props.theme !== nextProps.theme ||
      this.props.isMobile !== nextProps.isMobile ||
      this.state.hoverData !== nextState.hoverData ||
      this.state.legendOptions !== nextState.legendOptions ||
      this.state.loading !== nextState.loading ||
      this.state.firstLoading !== nextState.firstLoading ||
      this.state.error !== nextState.error ||
      this.state.cancelDataLoad !== nextState.cancelDataLoad ||
      this.state.isDataView !== nextState.isDataView ||
      this.state.isTablet !== nextState.isTablet ||
      this.state.stepValue !== nextState.stepValue ||
      this.state.autoStepValue !== nextState.autoStepValue ||
      this.state.denseStepValue !== nextState.denseStepValue ||
      this.state.dataView !== nextState.dataView ||
      this.state.events !== nextState.events
    )
  }

  componentDidUpdate (prevProps, prevState) {
    const { disabled } = this.props
    if (disabled !== prevProps.disabled) {
      if (disabled) {
        this.destroy(true)
      } else {
        this.initializeLazy()
      }
    }

    if (this.$isInitialized) {
      this.$watch.forEach(item => item(prevProps, prevState))
    }
  }

  componentWillUnmount () {
    this.destroy()
  }

  watchProps (name, callback) {
    return (prevProps) => {
      if (get(prevProps, name) !== get(this.props, name)) {
        callback()
      }
    }
  }

  watchState (name, callback) {
    return (_, prevState) => {
      if (get(prevState, name) !== get(this.state, name)) {
        callback()
      }
    }
  }

  async initialize () {
    if (this.props.disabled || this.$isInitialized) {
      return
    }

    this.setState(state => ({
      error: false,
      loading: false,
      firstLoading: state.firstLoading === true ? null : false,
    }))

    this.initSplitter()

    this.$channel = new ServiceChannel(this.handleServiceChannelSendRequest, { isDebounce: true })
    this.$channel.on('message', this.handleSensorDataMessage)
    this.$channel.on('error', this.handleSensorDataError)

    if (!this.$plot) {
      await this.refreshPlot()
    }

    this.realtimeUpdateRestart()
    this.queueUploadData()
    this.queueUploadEvents()
    this.$queue.start()
    this.$queueEvents.start()

    this.$watch = [
      this.watchProps('theme', () => this.refreshPlot(true)),
      this.watchProps('width', () => this.resizePlot()),
      this.watchProps('height', () => this.resizePlot()),
      this.watchProps('errors', () => this.updateLegendOptions()),
      this.watchProps('dataFilter.stepRequest', () => this.realtimeUpdateRestart()),
      this.watchProps('dataFilter.stepType', () => this.handleFilterViewZoom()),
      this.watchProps('dataFilter.viewZoom', () => this.handleFilterViewZoom()),
      this.watchProps('dataFilter.viewLegendOrient', () => {
        this.refreshPlot(true)
        this.initSplitter()
      }),
      this.watchProps('dataFilter.viewShowLegend', () => {
        this.refreshPlot(true)
        this.initSplitter()
      }),
      this.watchProps('dataFilter', () => this.queueUploadData()),
      this.watchProps('dataViews', () => this.queueUploadData()),
      this.watchProps('conditions', () => this.queueUploadData()),
      this.watchProps('dataSources', () => this.queueUploadData()),
      this.watchProps('dataFilter.dateFrom', () => this.queueUploadEvents()),
      this.watchProps('dataFilter.dateTo', () => this.queueUploadEvents()),
      this.watchProps('eventSources', () => this.queueUploadEvents()),
      this.watchState('stepValue', () => this.renderChartLayout()),
      this.watchState('loading', () => this.props.onLoading?.(this.state.loading)),
      this.watchState('autoStepValue', () => this.props.onChangeStepAuto?.(this.state.autoStepValue)),
      this.watchState('denseStepValue', () => this.props.onChangeStepDense?.(this.state.denseStepValue)),
      this.watchState('isDataView', () => {
        this.initSplitter()
        if (this.state.isDataView) {
          this.queueUploadData()
        } else {
          this.refreshPlot(true)
        }
      }),
      this.watchState('events', () => this.queueUpdateChart()),
      this.watchProps('counts', () => this.renderChartLayout()),
      this.watchProps('isMobile', () => {
        this.refreshPlot(true)
        this.initSplitter()
      }),
    ]

    this.$isInitialized = true
  }

  initializeLazy = debounce(() => {
    this.initialize()
  }, 300)

  destroy (isSoft) {
    this.initializeLazy.cancel()

    if (this.$isInitialized) {
      this.setState(state => ({
        loading: false,
        firstLoading: state.firstLoading === true ? null : false,
      }))

      this.resizePlot.cancel()
      this.handleMousemove.cancel()
      this.queueUploadData.cancel()
      this.queueUploadEvents.cancel()
      this.$channel?.close()
      this.$channel = null
      this.$layout?.dispose()
      this.$layout = null
      this.realtimeUpdateStop()
      this.stopDataLoadCancelTimer(true)
      this.$watch = []
      // have to abort all requests,
      // because realtime request will be new always and no need to cache it
      Object.values(this.$apiRequests).forEach(item => item.abort())
      this.$apiRequests = {}
      this.$requests = {}

      if (isSoft) {
        this.$queue.pause()
        this.$queue.clear()
        this.$queueEvents.pause()
        this.$queueEvents.clear()
      } else {
        this.$queue.clear()
        this.$queue = null
        this.$queueEvents.clear()
        this.$queueEvents = null
        this.$zoom?.dispose()
        this.$zoom = null
        this.$plot?.dispose()
        this.$plot = null
        this.$responses.clearAll()
        this.$split?.destroy()
        this.$split = null
      }

      this.$isInitialized = false
    }
  }

  initSplitter () {
    this.$split?.destroy()
    this.$split = null

    if (
      !this.props.dataFilter.viewShowLegend ||
      !this.refLegend.current ||
      !this.refSplitChart.current
    ) {
      return
    }

    const sizeLegend = Math.min(Math.max(this.props.dataFilter.viewLegendSize, 3), 70)
    const orient = this.props.isMobile ? 'bottom' : this.props.dataFilter.viewLegendOrient
    const isLegendLast = (orient === 'right' || orient === 'bottom')
    const refs = [ this.refLegend.current, this.refSplitChart.current ]
    const sizes = [ sizeLegend, 100 - sizeLegend ]
    const minSize = [ 20, 150 ]

    if (isLegendLast) {
      refs.reverse()
      sizes.reverse()
      minSize.reverse()
    }

    this.$split = Split(refs, {
      direction: orient === 'top' || orient === 'bottom' ? 'vertical' : 'horizontal',
      expandToMin: true,
      gutterSize: 5,
      sizes,
      minSize,
      gutter (index, direction) {
        const gutter = document.createElement('div')
        gutter.className = `gutter gutter-${direction} hover-visible`
        return gutter
      },
      onDragEnd: (data) => {
        this.resizePlot()
        const size = orient === 'right' || orient === 'bottom' ? data[1] : data[0]
        this.props.onResizeLegend?.(size)
      },
    })

    window.setTimeout(() => this.$split?.setSizes(sizes), 0)
  }

  updateLegendOptions () {
    let legendOptions = []
    if (this.props.dataFilter.viewShowLegend) {
      legendOptions = this.$getSources().map((item) => {
        const sourceView = this.getSourceView(item)
        const sourceGroupView = this.getSourceGroupView(item)

        const name = sourceView?.name || DataSource.getDisplayName(item)
        const color = sourceView?.color || item.color
        const valuePrecision = (
          this.props.dataFilter.viewValuePrecision ??
          sourceGroupView?.valuePrecision ??
          sourceView?.valuePrecision ?? 4
        )

        return {
          id: item.id,
          name,
          color,
          valuePrecision,
          selected: this.props.dataFilter.viewSelectedLegend?.[name] ?? true,
          error: this.props.errors[item.id],
          hoverData: this.state.hoverData[item.id],
        }
      }).filter(item => Boolean(item))
    }
    this.setState({ legendOptions })
  }

  queueUploadData = debounce(() => {
    this.updateLegendOptions()
    const props = { priority: 20 }
    this.$queue.add(async () => {
      const size = this.$queue.sizeBy(props)
      if (!size) {
        await this.registerDataRequests()
      }
    }, props)
  }, 300)

  queueUploadEvents = debounce(() => {
    const props = { priority: 15 }
    this.$queue.add(async () => {
      const size = this.$queue.sizeBy(props)
      if (!size) {
        await this.fetchEvents()
      }
    }, props)
  }, 300)

  queueUpdateChart = () => {
    const props = { priority: 10 }
    this.$queue.add(async () => {
      const size = this.$queue.sizeBy(props)
      if (!size) {
        await this.updateChartProperties()
      }
    }, props)
  }

  async fetchEvents () {
    this.$queueEvents.clear()

    const from = this.$zoom.dateFrom(this.props.dataFilter)
    const to = this.$zoom.dateTo(this.props.dataFilter)
    const interval = Interval.fromDateTimes(from, to)

    if (
      isEmpty(this.props.eventSources) ||
      !interval.isValid ||
      interval.isEmpty()
    ) {
      if (!isEmpty(this.state.events)) {
        this.setState({ events: [] })
      }
      return
    }

    const { stepType } = this.props.dataFilter
    let stepCustom = this.$zoom.step(from, to, this.props.dataFilter)
    const minStepValue = this.$zoom.minStep(from, to, this.props.dataFilter)

    if (stepType === 'dense') {
      stepCustom = minStepValue
    }

    const actions = this.props.eventSources.map(item => () => wizataApi.events.list({
      from: from.toMillis(),
      to: to.toMillis(),
      stepType,
      stepCustom,
      eventTypes: item.eventTypes || undefined,
      statuses: item.statuses || undefined,
      streamJobId: item.streamJobId || undefined,
      twinIds: item.twinId || undefined,
      sensorIds: item.sensorIds || undefined,
      includeChildTwinEvents: item.includeChildEvents || undefined,
      sortBy: 'createdDate',
      sortDir: 'desc',
    })
      .then((data) => {
        const sensorIds = uniq(data.reduce((out, event) => (
          isEmpty(event.sensorIds) ? out : out.concat(event.sensorIds)
        ), []))

        return Promise.all([
          data,
          sensorIds.length ? (
            dbProvider.database.collections.get('sensors')
              .query(Q.where('id', Q.oneOf(sensorIds)))
              .fetch()
          ) : [],
        ])
      })
      .then(([ data, sensors ]) => ({
        id: item.id,
        dataViews: item.dataViews,
        events: data.reduce((out, event) => {
          const names = isEmpty(event.sensorIds) ? [] :
            sensors
              .filter(sensor => event.sensorIds.includes(sensor.id))
              .map(sensor => sensor.displayName)

          const dur = event.from && event.to && event.from < event.to ?
            duration(event.to - event.from, { sep: ' ', full: true }) : false

          return {
            ...out,
            [event.id]: {
              name: `${event.name}${dur ? ` {b|${dur}}` : ''}${names.length ? `\n{s|${names.join('\n')}}` : ''}`,
              color: item.color,
              point: [ event.from, event.to || event.from ],
              labelShow: !!event.name,
              labelFormatter: data => (data.name),
              labelPosition: 'insideStartBottom',
            },
          }
        }, {}),
      }))
      .catch(error => ({
        id: item.id,
        error,
      })))

    if (actions.length) {
      await this.$queueEvents.addAll(actions)
        .then((data) => {
          const result = data.reduce((out, item) => ({
            ...out,
            [item.id]: item.error || true,
          }), {})

          this.setState({ events: data.filter(item => !!item.events) })
          this.props.onChangeAppliedEvents?.(result)
        })
    }
  }

  startDataLoadCancelTimer () {
    this.stopDataLoadCancelTimer(true)
    this.$cancelDataLoadTimer = window.setTimeout(() => {
      this.setState({ cancelDataLoad: true })
    }, CANCEL_TIMEOUT)
  }

  stopDataLoadCancelTimer (force) {
    if (force) {
      this.setState({ cancelDataLoad: false })
      window.clearTimeout(this.$cancelDataLoadTimer)
      window.clearTimeout(this.$cancelToCancelDataLoadTimer)
    } else {
      this.$cancelToCancelDataLoadTimer = window.setTimeout(() => {
        this.setState({ cancelDataLoad: false })
        window.clearTimeout(this.$cancelDataLoadTimer)
      }, 2000)
    }
  }

  async registerDataRequests () {
    // need to update chart props before data request
    // to drow axis and update zoom position (apply date range in single zoom)
    await this.updateChartProperties()

    const sources = this.$getUploadSources()
    if (!sources.length) {
      return this.queueUpdateChart()
    }

    const dateFrom = this.$zoom.dateFrom(this.props.dataFilter)
    const dateTo = this.$zoom.dateTo(this.props.dataFilter)
    const interval = Interval.fromDateTimes(dateFrom, dateTo)

    if (!interval.isValid || interval.isEmpty()) {
      throw new DataSourceError()
    }

    const requestSources = []
    for (const source of sources) {
      const v = this.getSourceView(source)
      requestSources.push({
        ...source,
        timeShift: v?.timeShift,
      })
    }

    const { stepType, isRounded, isTimestampAtEndOfPeriod } = this.props.dataFilter
    let stepCustom = this.$zoom.step(dateFrom, dateTo, this.props.dataFilter)
    const minStepValue = this.$zoom.minStep(dateFrom, dateTo, this.props.dataFilter)

    if (stepType === 'dense') {
      stepCustom = minStepValue
    }

    this.setState({
      stepValue: stepCustom,
      autoStepValue: this.$zoom.autoStep(dateFrom, dateTo, this.props.dataFilter),
      denseStepValue: minStepValue,
    })

    const requests = []
    const responses = []
    for (const source of requestSources) {
      const data = {
        dateTo,
        dateFrom,
        stepType,
        stepCustom,
      }

      if (!validateNumberChartValues(data, this.props.globalSettings?.SeriesQueryMaxCount)) {
        // eslint-disable-next-line no-continue
        continue
      }

      if (!validateRawSamplingDuration(data, this.props.globalSettings?.RawDateRangeLimit)) {
        // eslint-disable-next-line no-continue
        continue
      }

      const request = new DataSourceRequest({
        source,
        stepCustom,
        stepType,
        interval: [ interval.start, interval.end ],
        rounded: isRounded,
        setTimestampAtEndOfPeriod: isTimestampAtEndOfPeriod,
      })

      const response = this.$responses.getItem(item => item.isForRequest(request))
      if (response) {
        responses.push(response)
      } else if (!this.$requests[request.hash]) {
        this.$requests[request.hash] = request
        requests.push(request)
      }
    }

    if (responses.length) {
      this.queueUpdateChart()
    }

    if (requests.length) {
      this.setState(state => ({
        loading: true,
        firstLoading: state.firstLoading === null,
      }))

      this.realtimeUpdateStop()
      this.startDataLoadCancelTimer()

      for (const request of requests) {
        this.$channel.postRequest(request)
      }
    } else if (isEmpty(this.$requests)) {
      this.setState({
        error: false,
        loading: false,
        firstLoading: false,
      })

      this.realtimeUpdateRestart()
      this.stopDataLoadCancelTimer()
    }
  }

  async updateChartProperties () {
    const sources = this.$getUploadSources()
    const prevDataset = this.$getDataset()
    const dataset = []
    const nextErrors = {}
    const nextCounts = {}
    const dateFrom = this.$zoom.dateFrom(this.props.dataFilter).setZone('utc').toMillis()
    const dateTo = this.$zoom.dateTo(this.props.dataFilter).setZone('utc').toMillis()

    for (const source of sources) {
      let sensor
      if (source.sensorId) {
        [ sensor ] = await dbProvider.database.collections.get('sensors')
          .query(Q.where('id', source.sensorId))
          .fetch()
        if (!sensor) {
          continue
        }
      }

      const response = this.$responses.getItem(item => item.isSourceExists(source))
      if (!response) {
        const sourceData = prevDataset.find(item => item.id === source.id)
        if (sourceData) {
          dataset.push(sourceData)
        }
        continue
      }

      const errors = response.getErrors()
      if (errors.length) {
        nextErrors[source.id] = new DataSourceError(errors.join('\n'))
        continue
      }

      const sourceView = this.getSourceView(source)
      const sourceGroupView = this.getSourceGroupView(source)

      if (sourceView?.checked) {
        const { count } = response
        nextCounts[source.id] = {
          name: source.displayName,
          count: response.response.length,
          total: count,
          color: sourceView?.color,
        }
      }

      let interpolate = sourceView?.interpolate ?? null
      if (interpolate === null && sourceGroupView) {
        interpolate = sourceGroupView?.interpolate ?? null
      }
      if (interpolate === null) {
        interpolate = this.props.dataFilter?.viewInterpolate ?? null
      }

      let timeShift = sourceView?.timeShift
      if (timeShift === null && sourceGroupView) {
        timeShift = sourceGroupView.timeShift
      }

      let removeNull = sourceView?.removeNull
      if (removeNull === null && sourceGroupView) {
        removeNull = sourceGroupView.removeNull
      }
      if (removeNull === null) {
        removeNull = this.props.dataFilter?.viewRemoveNull ?? true
      }

      const sourceData = response.getData({
        // batchSensorId: this.props.dataFilter.stepType === 'batch' ? (sensor?.batchDataSource ?? null) : null,
        dateFrom,
        dateTo,
        interpolate,
        timeShift,
        removeNull,
      })

      if (!sourceData) {
        continue
      }

      dataset.push({
        ...sourceData,
        id: source.id,
      })
    }

    if (!isEqualObjectKeys(this.props.errors, nextErrors)) {
      this.props.onErrors?.(nextErrors)
    }

    if (!isEqual(this.props.counts, nextCounts)) {
      this.props.onChangeCounts?.(nextCounts)
    }

    if (this.state.isDataView) {
      this.updateDataView(dataset)
    } else {
      const options = await this.getProperties(dataset)
      this.$plot.setOption(options, { silent: true, notMerge: true, lazyUpdate: false })
      this.$zoom.enableSelect()
    }
  }

  handleFilterViewZoom = () => {
    if (this.$plot) {
      const zoomType = (
        // this.props.dataFilter.stepType === 'raw' ||
        this.props.dataFilter.stepType === 'batch'
      ) ? 'static' : this.props.dataFilter.viewZoom

      // need wor switch zoom with save zoom position
      // previous zoom will be removed in "create" method
      // with save/restore previous position
      this.$zoom = ChartZoom.create(this.$plot, zoomType)
      this.$zoom.on('dataupload', () => {
        this.queueUploadData()
        this.queueUploadEvents()
      })
    }
  }

  handleToggleLegend = ({ name }) => {
    this.props.onToggleSelectedLegend?.(name)
  }

  handleMousemove = debounce((event) => {
    const axesInfo = (event.axesInfo || []).reduce((out, item) => {
      if (item.axisDim === 'x') {
        out[item.axisIndex] = Math.floor(item.value)
      }
      return out
    }, {})

    const options = this.$plot.getOption()
    const hoverData = options?.series?.reduce?.((out, serie) => {
      const time = axesInfo[serie.xAxisIndex]
      if (time) {
        const item = options.dataset
          ?.find(i => i.id === serie.id)
          ?.source
          ?.find(i => i[0] >= time)

        if (item) {
          out[serie.id] = {
            time: item[0],
            value: item[1],
          }
        }
      }
      return out
    }, {}) ?? {}

    this.setState({ hoverData }, () => this.updateLegendOptions())
  }, 50)

  handleLegendselectchanged = ({ selected }) => {
    this.setState({ legendSelected: selected })
  }

  resizePlot = debounce(() => {
    if (this.$plot) {
      this.$plot.resize()
      // need to update chart to recalculate grid size for multigrid layout
      this.queueUpdateChart()
    }
  }, 300)

  updateDataView (dataset) {
    if (!this.state.isDataView) {
      if (this.state.dataView.length) {
        this.setState({ dataView: [] })
      }
      return
    }

    const dataView = {}
    const sources = this.$getSources()

    for (let j = 0, l = sources.length; j < l; j += 1) {
      const source = sources[j]
      const sourceView = this.getSourceView(source)
      const sourceGroupView = this.getSourceGroupView(source)
      const sourceData = dataset.find(item => item.id === source.id)?.source ?? []
      const valuePrecision = (
        this.props.dataFilter.viewValuePrecision ??
        sourceGroupView?.valuePrecision ??
        sourceView.valuePrecision ?? 4
      )

      for (let i = 0, len = sourceData.length; i < len; i += 1) {
        const [ ts, value, batch ] = sourceData[i]
        const key = this.props.dataFilter.stepType === 'batch' ? batch : ts
        dataView[key] = dataView[key] || {
          __idx__: sourceData[i][5],
          key,
          ts,
          batch,
          values: [],
        }
        dataView[key].values.push({
          ts,
          batch,
          source,
          value,
          valuePrecision,
        })
      }
    }

    this.setState({
      dataView: Object.values(dataView)
        .sort((a, b) => (a.__idx__ - b.__idx__)),
    })
  }

  handleServiceChannelSendRequest = (chunk) => {
    const key = Math.random()
    const apiRequest = wizataApi.getSensorsData(chunk)
    this.$apiRequests[key] = apiRequest
    return apiRequest.fetch()
      .finally(() => {
        delete this.$apiRequests[key]
      })
  }

  handleSensorDataMessage = (responses) => {
    this.$responses.setLen(this.$getUploadSources().length * 2)

    let updated = false
    for (const response of responses) {
      if (this.$requests[response.request.hash]) {
        delete this.$requests[response.request.hash]
        this.$responses.setItem(response)
        updated = true
      }
    }

    if (updated) {
      this.queueUpdateChart()

      if (isEmpty(this.$requests)) {
        this.setState({
          error: false,
          loading: false,
          firstLoading: false,
        })

        this.realtimeUpdateRestart()
        this.stopDataLoadCancelTimer()
      }
    }
  }

  handleSensorDataError = (error, requests) => {
    for (const request of requests) {
      delete this.$requests[request.hash]
    }

    this.setState({
      loading: false,
      firstLoading: false,
    })

    this.realtimeUpdateRestart()

    if (error instanceof AbortRequestError) {
      this.stopDataLoadCancelTimer(true)
    } else {
      this.setState({
        error: true,
      })
      this.stopDataLoadCancelTimer()
    }
  }

  handleCancelDataLoad = () => {
    this.setState({
      error: false,
      loading: false,
      firstLoading: false,
    })

    Object.values(this.$apiRequests).forEach(item => item.abort())
    this.$apiRequests = {}
    this.$requests = {}
    this.stopDataLoadCancelTimer(true)
  }

  realtimeUpdateRestart () {
    this.realtimeUpdateStop()
    const stepRequest = this.props.dataFilter.stepRequest ?? consts.StepRequest
    if (stepRequest && !this.props.disabled) {
      this.$realtimeTimer = new OnlineTimeout(
        () => {
          this.queueUploadData()
          this.queueUploadEvents()
        },
        Math.max(stepRequest, 5000),
      )
    }
  }

  realtimeUpdateStop () {
    if (this.$realtimeTimer) {
      this.$realtimeTimer.cancel()
      this.$realtimeTimer = null
    }
  }

  $getUploadSources () {
    const sources = this.props.conditions
      .reduce((out, item) => out.concat(item.inputDataSources || []), [])
      .concat(this.props.dataSources.filter(item => (
        !item.disabled &&
        this.getSourceView(item, this.props.dataViews)?.checked
      )))

    return uniqBy(sources, 'id')
  }

  $getUploadSource (id) {
    return this.$getUploadSources().find(item => item.id === id)
  }

  $getSources () {
    const items = orderBy(this.props.dataSources, [
      item => this.getSourceView(item)?.internalTreeSort,
    ], [
      'asc',
    ])

    return items
      .filter(item => (
        !item.disabled &&
        this.getSourceView(item)?.checked
      ))
  }

  $getSource (id) {
    return this.$getSources().find(item => item.id === id)
  }

  $getDataset () {
    return this.$plot?.getOption()?.dataset ?? []
  }

  $createDataInterval (data) {
    data = clone(data)
    const isBatch = this.props.dataFilter.stepType === 'batch'
    const interval = Interval.fromDateTimes(new Date(data[0][0]), new Date(data[1][0]))

    return Object.defineProperties(Object.create(null), {
      data: {
        enumerable: true,
        get: () => data,
      },
      interval: {
        enumerable: true,
        get: () => interval,
      },
      start: {
        enumerable: true,
        get: () => (isBatch ? data[0][2] : interval.start.startOf('second').toMillis()),
      },
      end: {
        enumerable: true,
        get: () => (isBatch ? data[1][2] : interval.end.startOf('second').toMillis()),
      },
      intersection: {
        enumerable: true,
        value: (other) => {
          const intersect = interval.intersection(other.interval)

          if (intersect && intersect.isValid) {
            const nextInterval = []
            const { start } = intersect
            const { end } = intersect

            if (start === interval.start) {
              nextInterval.push(data[0])
            } else if (start === interval.end) {
              nextInterval.push(data[1])
            } else if (start === other.interval.start) {
              nextInterval.push(other.data[0])
            } else if (start === other.interval.end) {
              nextInterval.push(other.data[1])
            }

            if (end === interval.start) {
              nextInterval.push(data[0])
            } else if (end === interval.end) {
              nextInterval.push(data[1])
            } else if (end === other.interval.start) {
              nextInterval.push(other.data[0])
            } else if (end === other.interval.end) {
              nextInterval.push(other.data[1])
            }

            if (nextInterval.length === 2) {
              return this.$createDataInterval(nextInterval)
            }
          }
        },
      },
    })
  }

  $getActualConditions (source) {
    if (!source) {
      return []
    }

    let conditions = this.$_conditions.filter((item) => {
      if (isEmpty(item.inputDataSources)) {
        return false
      }

      if (isEmpty(item.outputDataSources)) {
        return true
        // return item.inputDataSources.some(iDS => iDS.id === source.id)
      }

      return item.outputDataSources.some(oDS => oDS.id === source.id)
    })

    if (!conditions.length) {
      conditions = this.$_conditions.filter((item) => {
        if (isEmpty(item.inputDataSources)) {
          return false
        }

        if (isEmpty(item.outputDataSources)) {
          return true
          // return item.inputDataSources.some(oDS => (
          //   DataSource.getSymbol(oDS) === DataSource.getSymbol(source)
          // ))
        }

        return item.outputDataSources.some(oDS => (
          DataSource.getSymbol(oDS) === DataSource.getSymbol(source)
        ))
      })
    }

    if (!conditions.length) {
      return []
    }

    conditions = conditions.reduce((out, condition) => {
      if (condition.groupId) {
        out[condition.groupId] = out[condition.groupId] || []
        out[condition.groupId].push(condition)
      } else {
        out[condition.id] = condition
      }
      return out
    }, {})

    return conditions
  }

  $getMarkYAreaData (source) {
    const view = this.getSourceView(source)
    if (!Array.isArray(view.conditions) || !view.conditions.length) {
      return []
    }

    const data = []

    for (const condition of view.conditions) {
      const points = Condition.payloadIntervals(condition)
      if (points.length) {
        data.push(...points.map(point => ({
          color: condition.color,
          point,
        })))
      }
    }

    return data
  }

  $getMarkXAreaData (source, datasetHash, appliedConditions) {
    const conditions = this.$getActualConditions(source)

    // this.props.dataFilter

    const data = []
    for (const conditionId in conditions) {
      if (Array.isArray(conditions[conditionId])) {
        const groupANDConditions = conditions[conditionId]
        let intervals
        let color

        for (let j = 0; j < groupANDConditions.length; j += 1) {
          const condition = groupANDConditions[j]
          const datasource = datasetHash[condition.inputDataSources[0].id] || []
          if (!color) {
            color = this.props.conditions.find(item => item.id === condition.groupId)?.color
          }

          let startTime = null
          let prevTime = null
          const sourceIntervals = []
          for (let i = 0, l = datasource.length; i < l; i += 1) {
            const d = datasource[i]
            if (d[1] === null) {
              continue
            }

            if (Condition.pass(condition, d[0], d[1])) {
              if (!startTime) {
                startTime = d
              }
            } else {
              if (startTime) {
                sourceIntervals.push(
                  this.$createDataInterval([ startTime, prevTime ]),
                )
                appliedConditions[condition.id] = true
                this.$_conditions = this.$_conditions.reduce((out, item) => (
                  out.concat(item.id === condition.id ? condition : item)
                ), [])
              }

              startTime = null
            }

            prevTime = d
          }

          if (startTime) {
            sourceIntervals.push(
              this.$createDataInterval([ startTime, prevTime ]),
            )
            appliedConditions[condition.id] = true
            this.$_conditions = this.$_conditions.reduce((out, item) => (
              out.concat(item.id === condition.id ? condition : item)
            ), [])
          }

          if (!intervals) {
            intervals = sourceIntervals
          } else if (intervals.length) {
            intervals = intervals.reduce((out, item) => (
              out.concat(sourceIntervals.reduce((sout, sitem) => {
                const andInterval = item.intersection(sitem)
                if (andInterval) {
                  sout.push(andInterval)
                }
                return sout
              }, []))
            ), [])
          }
        }

        if (intervals && intervals.length) {
          intervals.forEach((item) => {
            data.push({
              color,
              point: [ item.start, item.end ],
            })
          })
        }
      } else {
        const condition = conditions[conditionId]
        const datasource = datasetHash[condition.inputDataSources[0].id] || []

        let startTime = null
        let prevTime = null
        for (let i = 0, l = datasource.length; i < l; i += 1) {
          const d = datasource[i]
          if (d[1] === null) {
            continue
          }

          if (Condition.pass(condition, d[0], d[1])) {
            if (!startTime) {
              startTime = d
            }
          } else {
            if (startTime) {
              const intraval = this.$createDataInterval([ startTime, prevTime ])
              data.push({
                color: condition.color,
                point: [ intraval.start, intraval.end ],
              })
              appliedConditions[condition.id] = true
              this.$_conditions = this.$_conditions.reduce((out, item) => (
                out.concat(item.id === condition.id ? condition : item)
              ), [])
            }

            startTime = null
          }

          prevTime = d
        }
        if (startTime) {
          const intraval = this.$createDataInterval([ startTime, prevTime ])
          data.push({
            color: condition.color,
            point: [ intraval.start, intraval.end ],
          })

          appliedConditions[condition.id] = true
          this.$_conditions = this.$_conditions.reduce((out, item) => (
            out.concat(item.id === condition.id ? condition : item)
          ), [])
        }
      }
    }

    return data
  }

  async refreshPlot (withProps) {
    this.$plot?.dispose()
    this.$plot = null

    await timeout(0)

    if (this.refChart.current) {
      this.$plot = echarts.init(this.refChart.current, this.props.theme)
      this.$plot.on('legendselectchanged', this.handleLegendselectchanged)
      this.$plot.on('updateAxisPointer', this.handleMousemove)
      this.handleFilterViewZoom(this.props.dataFilter.viewZoom)
      if (withProps) {
        this.queueUpdateChart()
      }
    }
  }

  getSourceView (source, dataViews = this.props.dataViews) {
    const hashSourceViews = {}
    let sourceView
    let maxSortlen = 0

    for (const view of dataViews) {
      hashSourceViews[view.id] = view
      if (view.sourceId === source.id) {
        sourceView = view
      }

      maxSortlen = Math.max(String(view.sort).length, maxSortlen)
    }

    if (!sourceView) {
      return undefined
    }

    const stack = [ sourceView.parentId ]
    let hasGrid = false
    let sort = String(sourceView.sort).padStart(maxSortlen, '0')
    let item

    while ((item = stack.shift())) {
      const parentView = item && hashSourceViews[item]
      if (!parentView) {
        break
      }

      sort = String(parentView.sort).padStart(maxSortlen, '0') + sort

      if (parentView.parentId) {
        stack.push(parentView.parentId)
      }

      if (parentView.role !== 'grid' && !hasGrid) {
        const { name, checked } = sourceView
        sourceView = DataView.merge(sourceView, parentView)
        sourceView.name = name
        sourceView.checked = checked
      } else {
        hasGrid = true
      }
    }

    return {
      ...sourceView,
      internalTreeSort: Number(`0.${sort}`),
    }
  }

  getSourceGroupView (source, dataViews = this.props.dataViews) {
    const hashSourceViews = {}
    let parentId

    for (const view of dataViews) {
      hashSourceViews[view.id] = view
      if (view.sourceId === source.id && view.parentId) {
        parentId = view.parentId
      }
    }

    if (!parentId) {
      return
    }

    let groupView
    const stack = [ parentId ]
    let item
    while ((item = stack.shift())) {
      const parentView = item && hashSourceViews[item]
      if (!parentView) {
        break
      }

      if (!groupView && parentView.role === 'grid') {
        groupView = parentView
      } else if (groupView) {
        groupView = DataView.merge(groupView, parentView)
      }

      if (parentView.parentId) {
        stack.push(parentView.parentId)
      }
    }

    return groupView
  }

  getTargetChartLayout () {
    let target = this.refChart.current.querySelector(`.${classes.chartProps}`)
    if (!target) {
      target = document.createElement('div')
      target.className = `root ${classes.chartProps}`
      this.refChart.current.appendChild(target)
    }
    return target
  }

  renderChartLayout () {
    if (!this.state.isDataView) {
      this.$layout?.renderLayout(this.getTargetChartLayout(), {
        stepValue: this.state.stepValue,
      })
    }
  }

  getProperties (dataset) {
    const dom = this.$plot?.getDom()
    if (!dom) {
      return Promise.resolve({})
    }

    dataset = dataset || this.$getDataset()
    dataset = dataset.filter(item => !!this.$getUploadSource(item.id))

    const getSourceAxisYName = (source) => {
      if (source?.dataType !== 'count' && source?.dataType !== 'sum') {
        return this.props.units[source.id]
      }
    }

    this.$_conditions = clone(this.props.conditions)

    const datasetHash = dataset.reduce((out, item) => {
      out[item.id] = item.source
      return out
    }, {}) ?? {}

    const valuePrecision = this.$getSources().reduce((out, source) => {
      const sourceView = this.getSourceView(source)
      const sourceGroupView = this.getSourceGroupView(source)
      const value = (
        this.props.dataFilter.viewValuePrecision ??
        sourceGroupView?.valuePrecision ??
        sourceView.valuePrecision ??
        4
      )
      out[source.id] = value
      return out
    }, {})

    const zoomData = this.$zoom.getDataZoom()

    this.$layout?.dispose()
    this.$layout = new ChartLayout({
      columns: this.props.dataFilter.viewColumns,
      dataZoomPosition: this.props.dataFilter.viewDataZoomPosition,
      dateFrom: this.props.dataFilter.dateFrom,
      dateTo: this.props.dataFilter.dateTo,
      duration: this.props.dataFilter.duration,
      id: this.props.dataFilter.id || '%root%',
      markPoints: this.props.dataFilter.viewMarkPoints,
      normalize: this.props.dataFilter.viewNormalize,
      selectedLegend: this.props.dataFilter.viewSelectedLegend,
      showDataSymbol: this.props.dataFilter.viewShowDataSymbol,
      showDataZoom: !this.props.isMobile && this.props.dataFilter.viewShowDataZoom,
      showLegend: this.props.dataFilter.viewShowLegend,
      showMillisecond: this.props.dataFilter.viewShowMillisecond,
      showTitle: this.props.dataFilter.viewShowTitle,
      showTooltip: this.props.dataFilter.viewShowTooltip,
      singleDataZoom: this.props.dataFilter.viewSingleDataZoom,
      singleLegend: true,
      singleXAxis: this.props.dataFilter.viewSingleXAxis,
      singleYAxis: this.props.dataFilter.viewSingleYAxis,
      startDataZoom: zoomData.singleDataZoom?.start ?? this.props.dataFilter.viewStartDataZoom,
      endDataZoom: zoomData.singleDataZoom?.end ?? this.props.dataFilter.viewEndDataZoom,
      stepRequest: this.props.dataFilter.stepRequest ?? consts.StepRequest,
      stepType: this.props.dataFilter.stepType,
      toolboxOptions: this.props.chartOptions?.toolboxOptions,
      zoom: this.props.dataFilter.viewZoom,
      stepValue: this.state.stepValue,
      valuePrecision,
      counts: this.props.counts,
      rawTooltip: RawTooltip,
      refDateFormatter: this.refDateFormatter,
    })

    const appliedConditions = {}
    const sources = this.$getSources()
    for (const source of sources) {
      const sourceView = this.getSourceView(source)
      const sourceGroupView = this.getSourceGroupView(source)
      const markDataX = this.$getMarkXAreaData(source, datasetHash, appliedConditions)
      const markDataY = this.$getMarkYAreaData(source, datasetHash, appliedConditions)
      const events = Object.values(
        Object.assign({}, ...(
          this.state.events.filter(item => (
            !item.dataViews?.length ||
            item.dataViews?.includes(sourceView.id)
          )).map(item => item.events)
        )),
      )

      const marks = [
        ...(events.filter(item => (
          !isNil(item.point[0]) &&
          !isNil(item.point[1]) &&
          item.point[0] !== item.point[1]
        )).map(item => ({
          type: 'xArea',
          ...item,
        }))),

        ...(events.filter(item => (
          !isNil(item.point[0]) &&
          item.point[0] === item.point[1]
        )).map(item => ({
          type: 'xArea',
          realtime: true,
          ...item,
        }))),

        ...(markDataX.filter(item => (
          !isNil(item.point[0]) &&
          !isNil(item.point[1]) &&
          item.point[0] !== item.point[1]
        )).map(item => ({
          type: 'xArea',
          ...item,
        }))),

        ...(markDataX.filter(item => (
          !isNil(item.point[0]) &&
          item.point[0] === item.point[1]
        )).map(item => ({
          type: 'xLine',
          ...item,
        }))),

        ...(markDataY.filter(item => (
          !isNil(item.point[0]) &&
          !isNil(item.point[1]) &&
          item.point[0] !== item.point[1]
        )).map(item => ({
          type: 'yArea',
          ...item,
        }))),

        ...(markDataY.filter(item => (
          !isNil(item.point[0]) &&
          item.point[0] === item.point[1]
        )).map(item => ({
          type: 'yLine',
          lineType: 'dotted',
          labelShow: true,
          labelPosition: 'end',
          ...item,
        }))),

        ...(sourceView.marks || []),
      ]

      const chartProps = {
        ...sourceView,
        id: source.id,
        groupId: sourceGroupView?.id,
        name: sourceView.name || DataSource.getDisplayName(source),
        isBatch: this.props.dataFilter.stepType === 'batch',
        nameYAxis: getSourceAxisYName(source),
        startDataZoom: zoomData[source.id]?.start ?? sourceView.startDataZoom,
        endDataZoom: zoomData[source.id]?.end ?? sourceView.endDataZoom,
        marks,
      }

      let groupProps = null
      if (sourceGroupView) {
        groupProps = {
          ...sourceGroupView,
          isBatch: this.props.dataFilter.stepType === 'batch',
          startDataZoom: zoomData[sourceGroupView.id]?.start ?? sourceGroupView.startDataZoom,
          endDataZoom: zoomData[sourceGroupView.id]?.end ?? sourceGroupView.endDataZoom,
        }
      } else if (!this.props.dataFilter.viewMultigrid) {
        groupProps = {
          ...DataView.toJSON({ id: '%default%' }),
          isBatch: this.props.dataFilter.stepType === 'batch',
          chartType: null,
          scaleYAxis: null,
          showDataZoom: null,
          showVisualMap: null,
          showXAxis: null,
          showYAxis: null,
          singleXAxis: null,
          singleYAxis: null,
        }
      }

      this.$layout.add(chartProps, groupProps)
    }

    this.props.onChangeAppliedConditions?.(appliedConditions)

    this.$layout.on('raf', (options) => {
      this.$plot?.setOption(options, { silent: true })
    })
    this.$layout.on('restoreZoom', () => this.$zoom.restore())
    this.$layout.on('toggleZoomType', () => {
      const viewZoom = this.props.dataFilter.viewZoom === 'single' ?
        'static' :
        'single'

      this.props.onChangeViewZoom?.(viewZoom)
    })

    return this.$layout.run(this.getTargetChartLayout(), dataset)
      .then((props) => {
        this.$zoom?.properties(props, this.props.dataFilter)
        // console.log(JSON.stringify(props, null, 2))
        return props
      })
  }

  saveAsImage (options) {
    return chartSaveAsImage(this.$plot, options)
  }

  exploreValues () {
    this.setState({ isDataView: !this.state.isDataView })
  }

  async downloadCSV ({ title }) {
    const tzName = this.props.globalSettings?.PlatformDateTimeZoneName
    const timeZone = timezones.find(item => item.name === tzName)?.techie
    const options = this.$plot.getOption()
    const data = chartExportData(options, null, {
      removeDataType: this.props.dataFilter.stepType === 'raw',
      timeFormat: this.props.globalSettings?.PlatformTimeFormat,
      dateFormat: this.props.globalSettings?.PlatformDateFormat,
      timeZone,
    })

    const csvData = await parseAsync(data, {
      delimiter: ';',
    })

    const blob = new Blob([ csvData ], {
      type: 'text/csv;charset=utf-8',
    })

    const name = `${kebabCase(title || 'data-export')}_${appEnv.WIZ_CLIENT}_${DateTime.local().toFormat('yyyymmdd-hh-mm')}.csv`

    saveAs(blob, name)
  }

  render () {
    const orient = this.props.isMobile ? 'bottom' : this.props.dataFilter.viewLegendOrient
    const isLegendLast = (orient === 'right' || orient === 'bottom')
    const isLegendVertical = (orient === 'top' || orient === 'bottom')

    const content = this.state.isDataView ? [] : [
      ...(this.props.dataFilter.viewShowLegend ? [
        (
          <div
            key="legend"
            ref={this.refLegend}
            className="d-flex position-relative overflow-hidden"
          >
            <Legend
              orient={orient}
              showMillisecond={this.props.dataFilter.viewShowMillisecond}
              options={this.state.legendOptions}
              dir={orient === 'right' ? 'rtl' : undefined}
              collapsed={this.props.dataFilter.viewLegendSize < 5}
              onToggle={this.handleToggleLegend}
            />
          </div>
        ),
      ] : []),
      (
        <div
          key="chart"
          ref={this.refSplitChart}
          className="flex-fill d-flex justify-content-center align-items-start position-relative overflow-hidden"
        >
          {this.state.cancelDataLoad ? (
            <button
              type="button"
              className="btn btn-sm btn-fill-secondary rounded-pill mt-2"
              style={{ zIndex: 1 }}
              onClick={this.handleCancelDataLoad}
            >
              <Icon name="fa--times-circle" className="me-2" />
              {intl.t('form.actions.cancelDataLoad')}
            </button>
          ) : null}

          {!this.state.firstLoading && this.state.loading ? (
            <Icon className={classes.loading} name="fa--spinner" spin />
          ) : null}

          <div
            ref={this.refChart}
            className="position-absolute-fill"
          />
        </div>
      ),
    ]

    return (
      <div
        ref={this.props.innerRef}
        className={classnames('d-flex flex-fill overflow-hidden position-relative', {
          [this.props.className]: !!this.props.className,
          'flex-column': isLegendVertical,
          'pe-none': this.props.disabled,
        })}
      >
        {this.state.error ? (
          <div
            className="h6 position-absolute-fill d-flex align-items-center justify-content-center text-danger"
            style={{ zIndex: 1 }}
          >
            <div className={classnames(classes.overlay, 'position-absolute-fill bg-white opacity-50 rounded')} />
            {intl.t('errors.dataLoad')}
          </div>
        ) : null}

        {this.state.firstLoading ? (
          <div className="position-absolute-fill d-flex align-items-center justify-content-center">
            <Icon size="2X" name="fa--spinner" spin />
          </div>
        ) : null}

        {do {
          if (this.state.isDataView) {
            <Dataview
              value={this.state.dataView}
              isTablet={this.state.isTablet}
              onClose={() => this.setState({ isDataView: false })}
            />
          } else if (isLegendLast) {
            content.reverse()
          } else {
            // eslint-disable-next-line no-unused-expressions
            content
          }
        }}

        <FormatDateTime ref={this.refDateFormatter} noView />
      </div>
    )
  }
}

export default enhanceProps(SensorChart)
