import { camelCase } from 'lodash'
import { OverflowCenter, Icon as OldIcon } from '@wiz/components'
import Icon from '@/shared/icon'
import Link from '@/components/Link'
import { useIntl } from '@wiz/intl'

const IconsByType = {
  equipment: 'faTools',
  machine: 'faCogs',
  flow: 'faChartNetwork',
}

export default function TwinTreeContent ({
  data,
  context,
}) {
  const intl = useIntl()

  const item = data.payload

  const {
    search,
    onChange,
  } = context

  const icon = (
    item.icon ||
    IconsByType[item.type] ||
    (item.color ? 'faCircle' : null)
  )

  return (
    <div className="d-flex align-items-center min-w-0">
      {(icon?.includes('cus-') ? (
        <OldIcon
          name={icon}
          color={icon?.color}
          className="me-2"
        />
      ) : (
        <Icon
          name={icon !== 'faCogs' && icon !== 'faTools' && camelCase(icon)}
          color={icon?.color}
          className="me-2"
        />
      )
      )}
      <Link
        name="controlpanel"
        query={{
          contextTwinId: item.id,
        }}
        className="btn btn-link text-reset p-0 min-w-0"
        onClick={() => onChange(data)}
        title={intl.t('form.actions.overview')}
      >
        <OverflowCenter
          className="min-w-0"
          value={item.displayName || item.name}
          mark={search}
        />
      </Link>
    </div>
  )
}
