import { useCallback, memo } from 'react'
import { dbProvider, Dashboard } from '@wiz/store'
import { withProps } from '@wiz/components'
import events from '@/utils/events'
import Component from '@/components/Forms/DuplicateWidget'
import enhanceWidget from './enhanceWidget'

const enhanceProps = withProps(({ widget }) => {
  const onSubmit = useCallback(async ({ dashboards, newDashboards }) => {
    let newDashboardIdx = 1

    const context = dbProvider.createBatchContext()
    for (const dashboardId of dashboards) {
      const dashboardData = newDashboards.find(item => item.id === dashboardId)
      if (dashboardData && !dashboardData.title) {
        dashboardData.title = `New Dashboard ${newDashboardIdx}`
        newDashboardIdx += 1
      }

      const dashboard = await dbProvider.prepareReplaceData(context, Dashboard, {
        ...dashboardData,
        id: dashboardId,
      })

      const nextWidgetLayout = await dashboard.getLayoutForWidget(widget)

      await dbProvider.prepareDuplicateModel(context, widget, {
        ...nextWidgetLayout,
        dashboardId: dashboard.id,
      })
    }

    if (context.size) {
      try {
        await dbProvider.batch(context)
        events.emit('app:notify', {
          type: 'success',
          title: 't/dashboards.title',
          message: 't/dashboards.form.success.update',
          duration: 2000,
        })
      } catch (error) {
        events.emit('app:notify', {
          type: 'error',
          title: 't/dashboards.title',
          message: 't/dashboards.form.errors.update',
        })
        throw error
      }
    }
  }, [ widget ])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceWidget(
    enhanceProps(Component),
  ),
)
