import { useMemo } from 'react'
import PropTypes from 'prop-types'

export default function CellBusinessLabels ({
  options,
}) {
  const content = useMemo(() => (
    options.map(item => item.name).join(', ')
  ), [ options ])

  if (!options.length) {
    return '-'
  }

  return (
    <div className="d-flex align-items-center me-2 min-w-0">
      <div className="text-truncate">
        {content}
      </div>
    </div>
  )
}

CellBusinessLabels.propTypes = {
  options: PropTypes.array.isRequired,
}
