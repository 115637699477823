import { useCallback } from 'react'
import { withProps } from '@wiz/components'
import { dbProvider } from '@wiz/store'
import Component from '@/components/dashboards/RightBarWidgets'

const enhanceProps = withProps(({ dashboard, onSuccessAttachWidget }) => {
  const onAttachWidget = useCallback(async ({ type }) => {
    const context = dbProvider.createBatchContext()
    const widget = await dashboard.prepareAttachWidget(context, { type })
    await dbProvider.batch(context)
    onSuccessAttachWidget?.(widget)
  }, [ dashboard, onSuccessAttachWidget ])

  return {
    onAttachWidget,
  }
})

export default enhanceProps(Component)
