import { useCallback, memo } from 'react'
import { dbProvider, Job } from '@wiz/store'
import { consts } from '@wiz/utils'
import { withProps } from '@wiz/components'
import Component from '@/components/Forms/Import'
import enhanceJob from './enhanceJob'
import enhanceTwins from './enhanceTwins'

const enhanceProps = withProps(() => {
  const onSubmit = useCallback(async (data) => {
    const { import: bgJob, files = '', rawFile = '' } = data || {}
    const context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareReplaceData(context, Job, {
      ...bgJob,
      command: consts.JobCommand.FileImport,
    })
    const fileId = rawFile?.id || files
    if (fileId) {
      await model.prepareReplaceFiles(context, [ fileId ])
    }
    await dbProvider.batch(context)
    return model
  }, [])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceTwins(
    enhanceJob(
      enhanceProps(Component),
    ),
  ),
)
