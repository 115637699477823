import { createRef, Component } from 'react'
import { DateTime } from 'luxon'
import {
  pick,
  clone,
  isEqual,
  debounce,
  echarts,
} from '@wiz/utils'
import { ServiceChannel, DataSourceRequest } from '@wiz/api'
import { wizataApi } from '@/api'
import FormatDateTime from '@/containers/FormatDateTime'

const WatchReloadConfigs = [
  'dataType',
  'sensorId',
  'dateFrom',
  'dateTo',
]

export default class KPICalendarHeatmap extends Component {
  static getDerivedStateFromProps (nextProps) {
    const config = clone(nextProps.config)

    const dateFrom = config.dateFrom ?
      DateTime.fromMillis(config.dateFrom) :
      DateTime.local().minus({ months: 1 }).startOf('day')

    const dateTo = config.dateTo ?
      DateTime.fromMillis(config.dateTo) :
      DateTime.local().startOf('day')

    const source = {
      id: nextProps.widget.id,
      dataType: nextProps.config.dataType,
      sensorId: nextProps.config.sensorId,
    }

    return {
      config,
      dateFrom,
      dateTo,
      source,
    }
  }

  refTarget = createRef()

  refDateFormatter = createRef()

  state = KPICalendarHeatmap.getDerivedStateFromProps(this.props)

  componentDidMount () {
    this.$channel = new ServiceChannel(this.handleServiceChannelSendRequest)
    this.$channel.on('message', this.handleSensorDataMessage)
    this.$channel.on('error', this.handleSensorDataError)
    this.resetPlot()
    this.uploadData()
  }

  componentWillUnmount () {
    this.handleDatarangeselected.cancel()
    this.$fetchRequest?.abort()
    this.$fetchRequest = null
    this.$channel.close()
    this.$channel = null
    this.$plot.dispose()
  }

  componentDidUpdate (prevProps, prevState) {
    if (
      this.props.theme !== prevProps.theme ||
      this.props.title !== prevProps.title ||
      !isEqual(this.state.config, prevState.config)
    ) {
      this.resetPlot()
    }

    if (!isEqual(
      pick(this.state.config, WatchReloadConfigs),
      pick(prevState.config, WatchReloadConfigs),
    )) {
      this.uploadData()
    }

    if (this.props.dimension !== prevProps.dimension) {
      this.$plot?.resize()
    }
  }

  resetPlot () {
    const properties = this.getProperties()
    properties.series.data = this.$plot?.getOption()?.series?.[0]?.data ?? []

    this.$plot?.dispose()
    this.$plot = echarts.init(this.refTarget.current, this.props.theme)
    this.$plot.setOption(properties, {
      notMerge: true,
      lazyUpdate: true,
      silent: true,
    })

    this.handleDatarangeselected.cancel()
    this.$plot.on('datarangeselected', this.handleDatarangeselected)
  }

  uploadData () {
    this.$plot.showLoading()

    this.$request = new DataSourceRequest({
      stepCustom: 1000 * 60 * 60 * 24,
      source: this.state.source,
      interval: [ this.state.dateFrom, this.state.dateTo ],
    })

    this.$channel.postRequest(this.$request)
  }

  handleDatarangeselected = debounce((params) => {
    this.props.onChangeSelectedDataRange?.(params.selected)
  }, 1000)

  handleServiceChannelSendRequest = (request) => {
    this.$fetchRequest = wizataApi.getSensorsData([ request ])
    return this.$fetchRequest.fetch()
  }

  handleSensorDataMessage = ([ response ]) => {
    if (!response.isForRequest(this.$request)) {
      return
    }

    this.$plot.hideLoading()

    const errors = response.getErrors(this.state.source)
    if (errors.length) {
      return
    }

    const sourceData = response.getData()
    this.$plot.setOption({
      series: {
        data: sourceData.source.map(item => ([
          item[0],
          Math.floor(item[1] * 1000) / 1000,
        ])),
      },
    }, {
      lazyUpdate: true,
    })
  }

  handleSensorDataError = (error) => {
    this.$plot.hideLoading()
    console.error(error)
  }

  getProperties () {
    const { config, dateFrom, dateTo } = this.state
    const { title } = this.props

    const properties = {
      title: {
        text: title ? `${config.displayName} (${config.dataType})` : undefined,
        subtext: `${this.refDateFormatter.current.format(dateFrom, { displayTime: false })} - ${this.refDateFormatter.current.format(dateTo, { displayTime: false })}`,
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
        formatter: (p) => {
          const format = echarts.format.formatTime('yyyy/MM/dd', p.data[0])
          return `${format}: ${p.data[1]}`
        },
      },
      visualMap: {
        min: config.range[0],
        max: config.range[1],
        ...(config.rangeSelected.length ? {
          range: [
            config.rangeSelected[0],
            config.rangeSelected[1],
          ],
        } : {}),
        calculable: true,
        orient: 'horizontal',
        left: 'center',
        top: 'bottom',
      },
      calendar: {
        range: [
          this.state.dateFrom.toISODate(),
          this.state.dateTo.toISODate(),
        ],
        cellSize: 'auto',
        top: 70,
        left: 30,
        right: 0,
        bottom: 50,
        itemStyle: {
          normal: {
            borderWidth: 0.5,
          },
        },
        yearLabel: {
          show: false,
        },
        dayLabel: {
          align: 'right',
          padding: 15,
          margin: 0,
        },
      },
      series: {
        type: 'heatmap',
        coordinateSystem: 'calendar',
        data: [],
      },
    }

    return properties
  }

  render () {
    return (
      <div className="flex-fill position-relative">
        <div
          ref={this.refTarget}
          className="position-absolute-fill"
        />
        <FormatDateTime ref={this.refDateFormatter} noView />
      </div>
    )
  }
}
