import { useMemo } from 'react'
import { withContext, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useRouter, useLocation } from '@/router'
import DataExplorerEnv from '@/context/DataExplorerEnv'
import Link from '@/components/Link'
import { CorePages } from '@/utils/consts'

export default withContext(DataExplorerEnv, ({ explorer }) => {
  const intl = useIntl()
  const router = useRouter()
  const location = useLocation()
  const [ ,, corePage ] = location.pathname.split('/')

  const context = useMemo(() => ({
    BackComponent: explorer?.dashboardId ? () => (
      <Link
        className="btn btn-sm btn-text btn-flat-secondary me-1"
        title={intl.t('dashboards.title')}
        name="dashboard"
        params={{ id: explorer.dashboardId }}
        hash={`#${explorer.id}`}
      >
        <Icon name="fa--chevron-left" />
      </Link>
    ) : null,

    openExplorer: (id) => {
      // FIXME: need to find a better way to handle
      const name = corePage === CorePages.OPERATE ? 'de' : 'data-observation'
      router.push({
        name,
        params: { id },
      })
    },
  }), [
    explorer,
    intl,
    router,
    corePage,
  ])

  return context
})
