import { useRef } from 'react'
import FormDialog from '@/components/Form/FormDialog'
import Table from '@/containers/Forms/InputSensorValueGroup/Table'

export default function InputSensorValueGroup ({
  sensorIds,
  dialog,
  onClose,
  onSubmit,
}) {
  const refForm = useRef()

  return (
    <FormDialog
      ref={refForm}
      onSubmit={onSubmit}
      onClose={onClose}
      dataTestid="inputSensorValueGroupForm"
      dialog={dialog}
      ignoreObsolete
      hasActions={false}
    >
      <Table
        sensorIds={sensorIds}
        showLastValues
        precision={10}
        onClose={() => refForm.current.close()}
      />
    </FormDialog>
  )
}
