import { memo } from 'react'
import Component from '@/components/Forms/Integration'
import { withObservables } from '@wiz/components'
import { dbProvider } from '@wiz/store'
import { of } from 'rxjs'
import enhanceProps from './enhanceProps'
import enhanceIntegration from './enhanceIntegration'
import enhanceIntegrationData from './enhanceIntegrationSettings'

const enhanceSettings = withObservables([ 'integrationSettings' ], ({ integrationSettings }) => ({
  installed: dbProvider.database.collections.get('integrations').query().observeWithColumns([ 'updated_at' ]),
  settings: integrationSettings?.length ? integrationSettings.reduce((acc, val) => {
    acc[val.type] = val.value
    return acc
  }, {}) : of(undefined),
}))

export default memo(
  enhanceIntegration(
    enhanceIntegrationData(
      enhanceSettings(
        enhanceProps(Component),
      ),
    ),
  ),
)
