import { useMemo } from 'react'
import { Table as StaticTable, Pagination } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import TwinList from '@/hoc/TwinList'
import Observe from '@/containers/Observe'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import CellTwin from './CellTwin'

const CellTwinData = TwinList(CellTwin)

export default function Table ({
  items,
  onRemove,
  onEdit,
  onDuplicate,
  onFilter,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      minWidth: 300,
      Cell: ({ cell, row }) => (
        <div className="d-flex align-items-center min-w-0">
          <a
            className="text-truncate pointer"
            onClick={() => auth.checkAccessUpdate(row.original) && onEdit?.(row.original.id)}
            aria-hidden
          >
            { cell.value }
          </a>
        </div>
      ),
    },
    {
      Header: intl.t('form.fields.twins'),
      disableResizing: true,
      disableSortBy: true,
      Cell: ({ row }) => (
        <Observe query={row.original.queryRelTwins}>
          {({ data }) => (
            <CellTwinData
              value={data.map(item => item.twinId)}
              selectedOnly
              onFilter={onFilter}
            />
          )}
        </Observe>
      ),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedAt',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 220,
      Cell: ({ cell, row }) => (<UpdatedInfo date={cell.value} userId={row.original.updatedById} user={row.original.updatedUser} />),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdAt',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 220,
      Cell: ({ cell, row }) => (<UpdatedInfo date={cell.value} userId={row.original.createdById} user={row.original.createdUser} />),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 150,
      width: 150,
      maxWidth: 150,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell, row }) => (
        <>
          {auth.checkAccessUpdate(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0 me-2"
              onClick={() => onEdit?.(cell.value)}
            >
              {intl.t('form.actions.edit')}
            </button>
          ) : null}
          {auth.checkAccessCopy(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0 me-2"
              onClick={() => onDuplicate?.(row.original)}
            >
              {intl.t('form.actions.duplicate')}
            </button>
          ) : null}
          {auth.checkAccessRemove(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0"
              onClick={() => onRemove?.([ row.original ])}
            >
              {intl.t('form.actions.remove')}
            </button>
          ) : null}
        </>
      ),
    },
  ], [ intl, onEdit, onRemove, onDuplicate, onFilter, auth ])

  return (
    <>
      <StaticTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={auth.checkAccessRemove('QualityDataTemplate')}
        {...props}
      />

      <Pagination {...props} />
    </>
  )
}
