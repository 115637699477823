import { useMemo } from 'react'
import { DiagramBlock, DiagramBlockDeviceCommand } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import BlockSection from '../Block/Section'
import Section from './Section'

export default function BlockDeviceCommand ({
  block,
  settings,
  dialog,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()
  const defaultValues = useMemo(() => ({
    block: DiagramBlock.toJSON(block),
    settings: DiagramBlockDeviceCommand.toJSON(settings),
  }), [
    block?.updatedAt ?? block,
    settings?.updatedAt ?? settings,
  ])

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="blockOutputForm"
      dialog={dialog}
    >
      <BlockSection scope="block" />
      <Section scope="settings" title={intl.t('form.sections.deviceCommand')} />
    </FormDialog>
  )
}
