import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { FormFieldInline, FormControl } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

const FieldCategoryXAxis = ({ scope }) => {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()
  return (
    <FormFieldInline
      label={intl.t('widgets.histogram.form.fields.categoryXAxis')}
      description={intl.t('widgets.histogram.form.fields.categoryXAxisDescr')}
      errors={get(errors, `${scope}.categoryXAxis`)}
    >
      <FormControl
        type="checkbox"
        name={`${scope}.categoryXAxis`}
        className="ms-2"
      />
    </FormFieldInline>
  )
}

FieldCategoryXAxis.propTypes = {
  scope: PropTypes.string.isRequired,
}

export default FieldCategoryXAxis
