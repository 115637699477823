import { useCallback, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Twin } from '@wiz/store'
import { set, unset, consts } from '@wiz/utils'
import { FormSection } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import SelectTwin from '@/components/Form/SelectTwin'
import ObservTwin from '@/containers/Forms/Twin/Observ'
import SectionTwin from '@/components/Forms/Twin/Section'
import Section from './Section'

export default function SettingsSection ({ scope }) {
  const intl = useIntl()
  const {
    watch,
    register,
    reset,
    getValues,
  } = useFormContext()
  const id = watch(`${scope}.flow.id`)
  const twinId = watch(`${scope}.flow.twinId`)
  const [ create, setCreate ] = useState(!!twinId)

  const handleChange = useCallback(({ twin }) => {
    setCreate(!!twin)
    const next = getValues()
    if (twin) {
      set(next, `${scope}.twin`, Twin.toJSON(twin))
    } else {
      unset(next, `${scope}.twin`)
    }
    reset(next, {
      keepDirty: true,
      keepTouched: true,
    })
  }, [ scope, getValues, reset ])

  const handleCreateTwin = useCallback(() => {
    setCreate(true)
    const next = getValues()
    set(next, `${scope}.twin`, Twin.toJSON({ type: consts.TwinType.Flow }))
    reset(next, {
      keepDirty: true,
      keepTouched: true,
    })
  }, [ scope, getValues, reset ])

  const SelectTwinComponent = (
    <Controller
      name={`${scope}.flow.twinId`}
      render={({ field, fieldState }) => (
        <SelectTwin
          {...field}
          invalid={fieldState.invalid}
          filters={{ types: [ consts.TwinType.Flow ] }}
          placeholder={intl.t('form.fields.twinPlaceholder')}
          className="form-select-sm w-100 w-md-auto mt-2 mt-md-0"
        />
      )}
    />
  )

  return (
    <>
      <ObservTwin
        id={twinId}
        onChange={handleChange}
      />

      <input
        {...register(`${scope}.id`)}
        type="hidden"
        defaultValue={id}
      />

      <Section
        scope={`${scope}.flow`}
      />

      {create ? (
        <SectionTwin
          scope={`${scope}.twin`}
          title={intl.t('form.sections.flow')}
          addon={SelectTwinComponent}
          disabledFields={[
            `${scope}.twin.type`,
          ]}
        />
      ) : (
        <FormSection
          title={intl.t('form.sections.flow')}
          addon={SelectTwinComponent}
        >
          <div className="text-center">
            <button
              type="button"
              className="btn btn-outline-primary px-5"
              onClick={handleCreateTwin}
            >
              {intl.t('form.actions.createConnection')}
            </button>
          </div>
        </FormSection>
      )}
    </>
  )
}
