import {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react'
import {
  withObservables,
  withProps,
  withPagination,
} from '@wiz/components'
import observeDeviceCommands from '@/utils/observeDeviceCommands'
import useFilters from '@/hooks/useFilters'
import Component from '../components/Table'

const DefaultPage = 1
const DefaultPageSize = 20

function initFilters (query = {}) {
  return {
    _: query._ || undefined,
    page: Number(query.page) || DefaultPage,
    pageSize: Number(query.pageSize) || DefaultPageSize,
    deviceId: query.deviceId,
    twinId: query.twinId,
    streamJobId: query.streamJobId,
    sensorId: query.sensorId,
    dateFrom: query.dateFrom,
    dateTo: query.dateTo,
    duration: query.duration,
    offsetDuration: query.offsetDuration,
    stepRequest: query.stepRequest,
  }
}

const enhanceFilters = withProps(({ config }) => {
  const refPageSize = useRef()
  const [ sourceFilters, setSourceFilters ] = useState(initFilters(config))
  const {
    filters,
    onFilter,
    forceUpdate,
  } = useFilters({
    initFilters,
    source: sourceFilters,
    setSource: setSourceFilters,
  })
  refPageSize.current = filters.pageSize

  const onPagination = useCallback((page, pageSize) => {
    onFilter({ page, pageSize })
  }, [ onFilter ])

  const onResetFilter = useCallback(() => {
    setSourceFilters(initFilters({
      ...config,
      pageSize: refPageSize.current,
    }))
  }, [ config ])

  const onTableFilter = useCallback((data) => {
    let next

    if (data) {
      next = {
        ...data,
        page: DefaultPage,
      }
    }

    onFilter(next)
  }, [ onFilter ])

  useEffect(() => {
    onResetFilter()
  }, [ onResetFilter ])

  return {
    DefaultPage,
    DefaultPageSize,
    filters,
    onFilter: onTableFilter,
    forceUpdate,
    onResetFilter,
    onPagination,
  }
})

const enhanceData = withObservables([
  'filters',
  'hidden',
], ({
  filters,
  hidden,
}) => {
  const limit = filters.pageSize + 1
  const offset = (filters.page - 1) * filters.pageSize
  return {
    items: observeDeviceCommands({
      dateFrom: filters.dateFrom,
      dateTo: filters.dateTo,
      duration: filters.duration,
      offsetDuration: filters.offsetDuration,
      stepRequest: filters.stepRequest,
      deviceId: filters.deviceId,
      twinId: filters.twinId,
      streamJobId: filters.streamJobId,
      sensorId: filters.sensorId,
      sortBy: 'createdDate',
      sortDir: 'desc',
      limit,
      offset,
      hidden,
    }),
  }
})

const enhanceProps = withProps(({
  items,
  filters,
}) => {
  const refData = useRef([])
  const [ loading, setLoading ] = useState(false)
  const offset = (filters.page - 1) * filters.pageSize

  useEffect(() => {
    setLoading(!items)
    if (items) {
      refData.current = items
    }
  }, [ items ])

  const nextItems = items || refData.current
  return {
    total: offset + nextItems.length,
    items: nextItems.slice(0, filters.pageSize),
    loading,
  }
})

export default enhanceFilters(
  enhanceData(
    enhanceProps(
      withPagination({
        localData: false,
      })(Component),
    ),
  ),
)
