import { useMemo } from 'react'
import { DiagramBlock, DiagramBlockFlow, Twin } from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import BlockSection from '../Block/Section'
import SettingsSection from './SettingsSection'

export default function BlockFlow ({
  block,
  settings,
  twin,
  dialog,
  onClose,
  onSubmit,
}) {
  const defaultValues = useMemo(() => ({
    block: DiagramBlock.toJSON(block),
    settings: {
      flow: DiagramBlockFlow.toJSON(settings),
      twin: twin ? Twin.toJSON(twin) : null,
    },
  }), [
    block?.updatedAt ?? block,
    settings?.updatedAt ?? settings,
    twin?.updatedAt ?? twin,
  ])

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="blockFlowForm"
      dialog={dialog}
    >
      <BlockSection scope="block" />
      <SettingsSection scope="settings" />
    </FormDialog>
  )
}
