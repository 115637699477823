import { Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Observe from '@/containers/Observe'

export default function CellBoard ({
  experiment,
  onFilter,
}) {
  const intl = useIntl()
  return experiment.boardId ? (
    <Observe query={experiment.board}>
      {({ data }) => (
        data ? (
          <div className="min-w-0">
            <div className="text-truncate">
              {data.name}
            </div>

            {onFilter ? (
              <button
                type="button"
                className="btn btn-sm p-1"
                title={intl.t('form.actions.search')}
                onClick={() => onFilter({ boardId: experiment.boardId })}
              >
                <Icon name="fa--search" />
              </button>
            ) : null}
          </div>
        ) : '-'
      )}
    </Observe>
  ) : '-'
}
