import { Controller, useFormContext } from 'react-hook-form'
import {
  FormField,
  FormFieldInline,
  FormControl,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import SelectTmplNotebook from '@/containers/Form/SelectTmplNotebook'
import SelectNotebookEnvironment from '@/containers/Form/SelectNotebookEnvironment'
import SelectNotebookLanguage from '@/containers/Form/SelectNotebookLanguage'
import SelectNotebookFormat from '@/containers/Form/SelectNotebookFormat'

export default function Fields ({ scope, disabled }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('notebooks.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          placeholder={intl.t('form.fields.namePlaceholder')}
          disabled={disabled}
        />
      </FormField>

      <FormField
        label={intl.t('notebooks.form.fields.environment')}
        description={intl.t('notebooks.form.fields.environmentDescr')}
        errors={get(errors, `${scope}.environment`)}
      >
        <Controller
          name={`${scope}.environment`}
          render={({ field, fieldState }) => (
            <SelectNotebookEnvironment
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.environmentPlaceholder')}
              disabled={disabled}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('notebooks.form.fields.language')}
        description={intl.t('notebooks.form.fields.languageDescr')}
        errors={get(errors, `${scope}.language`)}
      >
        <Controller
          name={`${scope}.language`}
          render={({ field, fieldState }) => (
            <SelectNotebookLanguage
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.languagePlaceholder')}
              disabled={disabled}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('notebooks.form.fields.format')}
        description={intl.t('notebooks.form.fields.formatDescr')}
        errors={get(errors, `${scope}.format`)}
      >
        <Controller
          name={`${scope}.format`}
          render={({ field, fieldState }) => (
            <SelectNotebookFormat
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.formatPlaceholder')}
              disabled={disabled}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.description')}
        description={intl.t('notebooks.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.description`}
        />
      </FormField>

      <FormField
        label={intl.t('notebooks.form.fields.templateFileId')}
        description={intl.t('notebooks.form.fields.templateFileIdDescr')}
        errors={get(errors, `${scope}.templateFileId`)}
      >
        <Controller
          name={`${scope}.templateFileId`}
          render={({ field, fieldState }) => (
            <SelectTmplNotebook
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.templateFileIdPlaceholder')}
              disabled={disabled}
            />
          )}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('notebooks.form.fields.useBlob')}
        description={intl.t('notebooks.form.fields.useBlobDescr')}
        errors={get(errors, `${scope}.useBlob`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.useBlob`}
          className="ms-2"
          disabled={disabled}
        />
      </FormFieldInline>
    </>
  )
}
