import { useEffect, useMemo } from 'react'
import { appEnv } from '@/config'

const BASE_DEV_URL = 'https://dev-wizard.onwizata.com/docs/index.html'
const BASE_TRAINING_URL = 'https://training-wizard.onwizata.com/docs/index.html'

const DSRedirect = () => {
  const URL = useMemo(() => {
    const { hostname } = window.location
    const [ domain ] = hostname.split('.')
    if ([ 'dev' ].includes(domain)) {
      return BASE_DEV_URL
    } if ([ 'training' ].includes(domain)) {
      return BASE_TRAINING_URL
    }
    return `${appEnv.DS_API_BASE_URL}/docs/index.html`
  }, [])
  useEffect(() => {
    if (URL) {
      window.open(URL, '_blank')
    }
  }, [ URL ])
  return null
}

export default DSRedirect
