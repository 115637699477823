import classnames from 'classnames'
import { Icon } from '@wiz/components'
import Option from './Option'
import ContentComponent from './ContentComponent'

export default function TreeOption ({
  data,
  isOpen,
  style,
  toggle,
  treeData,
}) {
  const {
    active,
    checkDragged,
    Content,
    draggable,
    onChange,
    onDragEnd,
    onDragStart,
    onDrop,
    onToggle,
    optionClassName,
    search,
    selectable,
  } = treeData

  const {
    id,
    isLeaf,
    nestingLevel,
    disabled,
  } = data

  const currentDragged = checkDragged?.(data)
  const currentDraggable = draggable?.(data)
  const currentSelectable = selectable?.(data) ?? true
  const currentActive = active?.(data)

  return (
    <Option
      className={optionClassName({
        dragged: currentDragged,
        active: currentActive,
      })}
      style={{
        ...style,
        marginLeft: `${nestingLevel + (isLeaf ? 1 : 0)}rem`,
        width: 'auto',
        right: 0,
        paddingLeft: 0,
      }}
      dataId={id}
      active={currentActive}
      dragged={currentDragged}
      disabled={disabled}
      draggable={currentDraggable}
      selectable={currentSelectable}
      toggle={isLeaf ? undefined : () => {
        toggle()
        onToggle?.(data, isOpen)
      }}
      onDragEnd={onDragEnd ? () => onDragEnd(data) : undefined}
      onDragStart={onDragStart ? () => onDragStart(data) : undefined}
      onDrop={onDrop ? type => onDrop(type, data) : undefined}
      onClick={onChange ? () => onChange(data) : undefined}
    >
      {isLeaf ? null : (
        <button
          type="button"
          className="btn p-0 me-1"
          onClick={(event) => {
            event.stopPropagation()
            toggle()
            onToggle?.(data, isOpen)
          }}
        >
          <Icon
            name="fa--caret-right"
            className={classnames('arrow', {
              'arrow-open': isOpen,
            })}
          />
        </button>
      )}

      <ContentComponent
        Content={Content}
        data={data}
        context={treeData}
        active={currentActive}
        dragged={currentDragged}
        disabled={disabled}
        search={search}
        draggable={currentDraggable}
        selectable={currentSelectable}
        isLeaf={isLeaf}
        className={classnames({
          'text-muted': !currentActive && (disabled || !currentSelectable),
        })}
      />
    </Option>
  )
}
