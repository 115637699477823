import { Q, dbProvider, AccessRole } from '@wiz/store'
import { withObservables } from '@wiz/components'

const enhanceData = withObservables([ 'filters' ], ({ filters }) => {
  let query = dbProvider.database.collections.get('access_roles').query()
  query = query.extend(AccessRole.queryWithoutPermissions())

  if (filters.search) {
    const searchSanitize = Q.sanitizeLikeString(filters.search)
    query = query.extend(Q.or(
      Q.where('id', filters.search),
      Q.where('name', Q.like(`%${searchSanitize}%`)),
    ))
  }

  return {
    items: query.observeWithColumns([ 'updated_at' ]),
  }
})

export default enhanceData
