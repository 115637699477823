import { useIntl } from '@wiz/intl'
import { Icon } from '@wiz/components'

export default function CellLogSource ({
  logSource,
  onFilter,
}) {
  const intl = useIntl()

  return (
    <div className="d-flex align-items-center min-w-0">
      <button
        type="button"
        className="btn btn-sm p-0 border-0 me-2"
        onClick={() => onFilter?.({ logSource })}
      >
        <Icon
          name="fa--search"
        />
      </button>

      <span className="text-truncate">
        {intl.t(`enum.streamJobLogSource.${logSource}`)}
      </span>
    </div>
  )
}
