/* eslint-disable no-nested-ternary */
import { useMemo, useState } from 'react'
import { Alert as Alert$, NotificationSheet, NotificationSheetTarget } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import { Tabs } from '@wiz/components'
import { useAuth } from '@/auth'
import FormDialog from '@/components/Form/FormDialog'
import SmsConsumption from '@/containers/Form/SmsConsumption'
import Removed from '../Removed'
import Section from './Section'
import SectionAlertSheet from './SectionAlertSheet'
import SectionAlertSheetSelect from './SectionAlertSheetSelect'
import SectionUsage from './SectionUsage'

export default function Alert ({
  id,
  alert,
  notificationSheet,
  notificationSheetTargets,
  usage,
  dialog,
  onClose,
  onSubmit,
}) {
  const auth = useAuth()
  const intl = useIntl()
  const [ tab, setTab ] = useState('settings')

  const immutableTargets = JSON.stringify(
    notificationSheetTargets.map(item => item?.updatedAt),
  )

  const defaultValues = useMemo(() => ({
    alert: Alert$.toJSON(alert),
    notificationSheet: NotificationSheet.toJSON(notificationSheet),
    notificationSheetTargets: notificationSheetTargets.map(item => (
      NotificationSheetTarget.toJSON(item)
    )),
  }), [
    alert?.updatedAt ?? alert,
    notificationSheet?.updatedAt ?? notificationSheet,
    immutableTargets,
  ])

  const usageCount = useMemo(() => (
    usage.reduce((acc, item) => (
      acc + (item.payload.count || 0)
    ), 0)
  ), [ usage ])

  if (id && !alert) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="alertForm"
      dialog={dialog}
    >
      <Tabs
        className="mb-2"
        value={tab}
        options={[
          { value: 'settings', label: intl.t('alert.sections.settings') },
          { value: 'usage', label: `${intl.t('alert.sections.usage')} (${usageCount})` },
        ]}
        onChange={setTab}
      />

      {tab === 'settings' ? (
        <>
          <Section
            scope="alert"
          />

          <SmsConsumption />

          {auth.checkAccessManage('NotificationSheet') ? (
            <SectionAlertSheet
              scope="notificationSheet"
              scopeTargets="notificationSheetTargets"
            />
          ) : auth.checkAccessRead('NotificationSheet') ? (
            <SectionAlertSheetSelect
              scope="alert"
            />
          ) : null}
        </>
      ) : null}

      {tab === 'usage' ? (
        <SectionUsage usage={usage} />
      ) : null}
    </FormDialog>
  )
}
