import classnames from 'classnames'
import { Icon } from '@wiz/components'
import classes from './index.css'

export default function ToggleActions ({ children }) {
  return (
    <div className="hover-toggle d-flex align-items-center ms-auto position-relative flex-shrink-0">
      <div
        className={classnames(classes.actions, 'hover-flex align-items-center position-absolute end-0 h-100')}
      >
        <div className="border-start rh-4 flex-shrink-0 mx-2" />

        {children}
      </div>

      <button
        type="button"
        className="btn btn-sm btn-flat-primary"
      >
        <Icon name="fa--ellipsis-h" />
      </button>
    </div>
  )
}
