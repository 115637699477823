import { memo } from 'react'
import Component from '@/components/Forms/StreamJob'
import enhanceProps from './enhanceProps'
import enhanceStreamJob from './enhanceStreamJob'
import enhanceStreamJobData from './enhanceStreamJobData'

export default memo(
  enhanceStreamJob(
    enhanceStreamJobData(
      enhanceProps(Component),
    ),
  ),
)
