/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'

export default function ViewModel ({
  title,
  deviceCommandTemplate,
  onClose,
  onRemove,
  onEdit,
}) {
  const refDialog = useRef()
  const intl = useIntl()
  const auth = useAuth()

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('form.fields.name'),
          value: deviceCommandTemplate.name,
        },
        {
          title: intl.t('edge.commandTemplates.form.fields.topic'),
          value: deviceCommandTemplate.topic || '-',
        },
        {
          title: intl.t('edge.commandTemplates.form.fields.payload'),
          value: () => (
            <span className="text-truncate">
              {do {
                if (typeof deviceCommandTemplate.payload === 'boolean' || typeof deviceCommandTemplate.payload === 'number') {
                  JSON.stringify(deviceCommandTemplate.payload)
                } else {
                  (deviceCommandTemplate.payload)
                }
              }}
            </span>
          ),
        },
        {
          title: intl.t('form.fields.dataType'),
          value: deviceCommandTemplate.dataType,
        },
        {
          title: intl.t('form.fields.type'),
          value: deviceCommandTemplate.type,
        },
      ],
    },

    {
      title: intl.t('form.fields.updated'),
      value: () => (
        <UpdatedInfo
          date={deviceCommandTemplate.updatedAt}
          user={deviceCommandTemplate.updatedUser}
        />
      ),
    },
    {
      title: intl.t('form.fields.created'),
      value: () => (
        <UpdatedInfo
          date={deviceCommandTemplate.createdAt}
          user={deviceCommandTemplate.createdUser}
        />
      ),
    },
  ]

  const actions = [
    ...(onRemove && auth.checkAccessRemove(deviceCommandTemplate) ? ([{
      title: intl.t('form.actions.remove'),
      name: 'remove',
      testid: 'removeButton',
      onClick: () => onRemove([ deviceCommandTemplate ]),
    }]) : []),

    ...(onEdit && auth.checkAccessUpdate(deviceCommandTemplate) ? ([{
      title: intl.t('form.actions.edit'),
      name: 'edit',
      testid: 'editButton',
      onClick: () => onEdit(deviceCommandTemplate.id),
    }]) : []),
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      actions={actions}
      onClose={onClose}
    />
  )
}
