import sample from 'lodash/sample'
import bg1 from './img/bg/bg1.jpg'
import bg2 from './img/bg/bg2.jpg'
import bg3 from './img/bg/bg3.jpg'
import bg4 from './img/bg/bg4.jpg'
import bg5 from './img/bg/bg5.jpg'
import bg6 from './img/bg/bg6.jpg'
import bg7 from './img/bg/bg7.jpg'
import bg8 from './img/bg/bg8.jpg'

const Backgrounds = [ bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8 ]

export function randomBackground () {
  return sample(Backgrounds)
}
