import { useCallback } from 'react'
import { consts } from '@wiz/utils'
import { Pagination, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import TreeList from '@/components/Form/Tree/List'
import Content from './Content'

export default function List ({
  twin,
  twinId,
  loading,
  items,
  total,
  filters,
  forceUpdate,
  onClickPage,
  onChange,
}) {
  const intl = useIntl()

  const handleCreateCommand = useCallback(() => {
    onChange('createCommand', {
      props: { twinId },
    })
  }, [ onChange, twinId ])

  return (
    <>
      <div className="d-flex align-items-center my-2 mx-3">
        <span className="text-truncate me-2">
          {twin?.displayName}
        </span>

        <button
          className="btn btn-sm btn-fill-secondary btn-text me-2 ms-auto"
          type="button"
          name="createCommand"
          onClick={handleCreateCommand}
        >
          {intl.t('form.actions.create')}
        </button>

        <button
          className="btn btn-sm btn-fill-secondary btn-text"
          type="button"
          name="refresh"
          onClick={forceUpdate}
        >
          <Icon name="fa--sync" />
        </button>
      </div>

      <div className="d-flex flex-fill min-h-0 position-relative">
        <TreeList
          className="flex-fill min-h-0"
          placeholder={intl.t('errors.noDataDisplay')}
          options={items}
          itemSize={consts.ListItemHeightMd}
          Content={Content}
        />

        {loading ? (
          <div className="position-absolute-fill position-center-fill bg-light opacity-50">
            <Icon name="fa--spinner" size="2X" spin />
          </div>
        ) : null}
      </div>

      <Pagination
        className="p-2 justify-content-center"
        pageNavigation={false}
        pageSizeNavigation={false}
        showTotal={false}
        currentPage={filters.page}
        pageSize={filters.pageSize}
        total={total}
        alwaysFastFirst
        relative
        onClickPage={onClickPage}
      />
    </>
  )
}
