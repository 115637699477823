export default class UserAD {
  static get modelName () {
    return 'UserAD'
  }

  constructor (data = {}) {
    this.id = data.id
    this.firstName = data.firstName
    this.lastName = data.lastName
    this.nickname = data.nickname
    this.email = data.email
    this.phone = data.phone

    this.name = data.lastName || data.firstName ?
      ((data.lastName || '') + (data.lastName && data.firstName ? ' ' : '') + (data.firstName || '')) :
      (data.email || data.phone)

    this.fullName = data.lastName || data.firstName ?
      (this.name + (data.email ? ` (${data.email})` : (data.phone ? ` (${data.phone})` : ''))) :
      this.name
  }
}
