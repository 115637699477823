import { useState, useCallback } from 'react'
import { useIntl } from '@wiz/intl'
import { Tabs, FormSwitch, useMobile } from '@wiz/components'
import FormEdgeModule from '@/containers/Forms/EdgeModule'
import TableModules from '@/containers/EdgeDevice/TableModules'
import TableModulesMobile from '@/containers/EdgeDevice/TableModulesMobile'
import TableConnections from '@/containers/EdgeDevice/TableConnections'
import TableConnectionsMobile from '@/containers/EdgeDevice/TableConnectionsMobile'
import ViewModuleModel from './ViewModuleModel'
import ViewConnectionModel from './ViewConnectionModel'

export default function View ({
  edge,
  ...props
}) {
  const intl = useIntl()
  const isMobile = useMobile()
  const [ tab, setTab ] = useState('modules')
  const [ editId, setEditId ] = useState(null)
  const [ viewModuleData, setViewModuleData ] = useState(null)
  const [ viewConnectionData, setViewConnectionData ] = useState(null)

  const handleChangeTab = useCallback((name) => {
    setTab(name)
  }, [])

  const TableModulesComponent = isMobile ? TableModulesMobile : TableModules
  const TableConnectionsComponent = isMobile ? TableConnectionsMobile : TableConnections

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">

      <div className="px-4">
        <div className="row my-1">
          <div className="col-12 col-sm-3 fw-bold">
            {intl.t('edge.devices.form.fields.deviceId')}
          </div>
          <div className="col-12 col-sm-9">
            {edge.deviceId}
          </div>
        </div>

        <div className="row my-1">
          <div className="col-12 col-sm-3 fw-bold">
            {intl.t('edge.devices.form.fields.runtimeResponse')}
          </div>
          <div className="col-12 col-sm-9">
            {edge.runtimeResponse}
          </div>
        </div>

        <div className="row my-1">
          <div className="col-12 col-sm-3 fw-bold">
            {intl.t('edge.devices.form.fields.enableIotHubConnection')}
          </div>
          <div className="col-12 col-sm-9">
            <FormSwitch
              value={edge.enableIotHubConnection}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <Tabs
          className="mb-2"
          value={tab}
          options={[
            { value: 'modules', label: intl.t('edge.sections.modules') },
            { value: 'connections', label: intl.t('edge.sections.hub_connections') },
          ]}
          onChange={handleChangeTab}
        />

        {tab === 'modules' ? (
          <TableModulesComponent
            {...props}
            edgeId={edge.id}
            onEdit={setEditId}
            onView={setViewModuleData}
          />
        ) : null}
        {tab === 'connections' ? (
          <TableConnectionsComponent
            {...props}
            edgeId={edge.id}
            onView={setViewConnectionData}
          />
        ) : null}
      </div>

      {editId ? (
        <FormEdgeModule
          id={editId}
          onClose={() => setEditId(null)}
          dialog={{
            title: intl.t('edge.modules.titleUpdate'),
            dataTestid: 'updateEdgeModuleDialog',
          }}
        />
      ) : null}

      {viewModuleData ? (
        <ViewModuleModel
          module={viewModuleData}
          title={intl.t('edge.modules.titleDetail')}
          onClose={() => setViewModuleData(null)}
          dialog
        />
      ) : null}

      {viewConnectionData ? (
        <ViewConnectionModel
          connection={viewConnectionData}
          title={intl.t('edge.connections.titleDetail')}
          onClose={() => setViewConnectionData(null)}
          dialog
        />
      ) : null}
    </div>
  )
}
