import { memo } from 'react'
import { withProps, useDidUpdate } from '@wiz/components'
import enhanceTwinGraph from './enhanceTwinGraph'

const enhanceProps = withProps(({ twinGraph, onChange }) => {
  useDidUpdate(() => onChange?.({ twinGraph }), [ twinGraph, onChange ])
})

export default memo(
  enhanceTwinGraph(
    enhanceProps(() => (null)),
  ),
)
