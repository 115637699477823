import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'

const enhanceData = withObservables([ 'filters' ], ({ filters }) => {
  let query = dbProvider.database.collections.get('quality_data')
    .query()

  if (filters.search) {
    const searchSanitize = Q.sanitizeLikeString(filters.search)
    query = query.extend(Q.or(
      Q.where('id', filters.search),
      Q.where('name', Q.like(`%${searchSanitize}%`)),
    ))
  }

  if (filters.tmpl) {
    query = query.extend(Q.where('quality_data_template_id', filters.tmpl))
  }

  if (filters.dateFrom) {
    query = query.extend(Q.where('register_at', Q.gte(filters.dateFrom)))
  }

  if (filters.dateTo) {
    query = query.extend(Q.where('register_at', Q.lte(filters.dateTo)))
  }

  if (filters.approval) {
    if (filters.approval === 'noneed') {
      query = query.extend(Q.on('quality_data_templates', Q.where('approval', false)))
    } else {
      query = query.extend(Q.where('approval', filters.approval))
    }
  }

  if (filters.user) {
    query = query.extend(Q.where('created_by_id', filters.user))
  }

  return {
    items: query.observeWithColumns([ 'updated_at' ]),
  }
})

export default enhanceData
