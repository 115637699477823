import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'
import { isEmpty, orderBy } from '@wiz/utils'
import Component from '@/components/Twin/Graph/ContextMenu'

const enhanceData = withObservables([ 'block', 'blockIds' ], ({ block, blockIds }) => ({
  block: block?.id ? dbProvider.database.collections.get('twins')
    .query(Q.where('id', block.id))
    .observeWithColumns([ 'updated_at' ])
    .pipe(map(items => items[0])) : of$(undefined),

  blocks: blockIds?.length ? dbProvider.database.collections.get('twins')
    .query(Q.where('id', Q.oneOf(blockIds)))
    .observeWithColumns([ 'updated_at' ]) : of$([]),

  twinSettings: dbProvider.observeGlobalSettings([
    'twinColorAreas',
    'twinColorMachines',
    'twinColorEquipment',
  ]),
  sensorIds: isEmpty(blockIds) ? of$([]) : dbProvider.database.collections.get('sensors')
    .query(Q.where('twin_id', Q.oneOf(blockIds)))
    .observe(),
  functions: dbProvider.database.collections.get('ds_functions').query().observeWithColumns([ 'updated_at' ]).pipe(map(items => orderBy(items || [], [ item => item.title.toLowerCase() ], [ 'asc' ]))),
}))

export default enhanceData(Component)
