import { useCallback } from 'react'
import { of as of$ } from 'rxjs'
import { withObservables, withProps } from '@wiz/components'
import { dbProvider } from '@wiz/store'
import Widget from './components/Widget'

const enhanceExplorerContext = withObservables([ 'widget' ], ({ widget }) => ({
  explorerContext: widget ? widget.observeExplorerContext : of$({}),
  twinSettings: dbProvider.observeGlobalSettings([
    'twinColorSensorsHardware',
    'twinColorSensorsVirtual',
  ]),
}))

const enhanceProps = withProps(({
  widget,
  onWidgetAction,
}) => {
  const onAction = useCallback(async ({ id }) => {
    await onWidgetAction(widget, id)
  }, [ widget, onWidgetAction ])

  return {
    onAction,
  }
})

export default enhanceExplorerContext(
  enhanceProps(Widget),
)
