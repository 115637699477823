import { auth } from '@/auth'
import Link from '@/components/Link'

export default function CellTwin ({ options: [ target ] }) {
  if (!target) {
    return '-'
  }

  if (!auth.checkAccessRead(target)) {
    return null
  }

  if (!auth.checkAccessRead('SectionDigitalTwinItems')) {
    return (
      <div className="min-w-0 text-truncate" title={target.name}>
        {target.name}
      </div>
    )
  }

  return (
    <div className="min-w-0 text-truncate">
      <Link
        title={target.name}
        name="twin-items-list"
        query={{
          type: target?.type,
          search: target?.id,
          view: 'table',
        }}
      >
        {target.name}
      </Link>
    </div>
  )
}
