import { useCallback, memo } from 'react'
import { dbProvider, EdgeModule } from '@wiz/store'
import { withProps } from '@wiz/components'
import Component from '@/components/Forms/EdgeModule'
import enhanceEdgeModule from './enhanceEdgeModule'

const enhanceProps = withProps(() => {
  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareReplaceData(context, EdgeModule, data.edgeModule)
    await dbProvider.batch(context)
  }, [])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceEdgeModule(
    enhanceProps(Component),
  ),
)
