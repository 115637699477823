import { useFormContext, Controller, useWatch } from 'react-hook-form'
import { useCallback, useState } from 'react'
import { FormAny } from '@wiz/components'
import { TwinGraph } from '@wiz/store'
import { uuid, has, get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import TwinGraphList from '@/hoc/TwinGraphList'
import List from '@/components/Form/Tree/List'

const BindedTwinGraphList = TwinGraphList(List)

export default function Section ({
  defaultValues,
}) {
  const [ checked, setChecked ] = useState(false)
  const intl = useIntl()
  const { setValue, formState: { errors } } = useFormContext()

  const boards = useWatch({
    name: 'boards',
    defaultValue: defaultValues.boards,
  })

  const newBoards = useWatch({
    name: 'newBoards',
    defaultValue: defaultValues.newBoards,
  })

  const handleActive = useCallback(item => (
    boards.includes(item.id)
  ), [ boards ])

  const handleChangeBoard = useCallback(({ id }) => {
    const next = boards.includes(id) ?
      boards.filter(item => item !== id) :
      boards.concat(id)
    setValue('boards', next, { shouldDirty: true })
  }, [ boards, setValue ])

  const handleAddBoard = useCallback(() => {
    const next = newBoards.concat(TwinGraph.toJSON({
      id: uuid(),
      name: '',
    }))
    setValue('newBoards', next, { shouldDirty: false })
  }, [ newBoards, setValue ])

  const handleRemoveBoard = useCallback((id) => {
    const next = newBoards.filter(item => item.id !== id)
    const nextBoards = boards.filter(item => item !== id)
    setValue('newBoards', next, { shouldDirty: true })
    setValue('boards', nextBoards, { shouldDirty: true })
  }, [ boards, newBoards, setValue ])

  const handleUpdateNameBoard = useCallback((id, name) => {
    const nextName = String(name).trim()
    const next = newBoards.map(item => (
      item.id === id ? { ...item, name: nextName } : item
    ))
    setValue('newBoards', next, { shouldDirty: true })
  }, [ newBoards, setValue ])

  const handleInputChange = useCallback((event, item) => {
    const { value } = event.target
    const isActive = handleActive(item)

    if (value && !checked && !isActive) {
      setChecked(true)
      handleChangeBoard(item)
    } else if (!value && checked && isActive) {
      setChecked(false)
      handleChangeBoard(item)
    }
  }, [ setChecked, handleActive, handleChangeBoard, checked ])
  return (
    <>
      <Controller
        name="boards"
        render={({ field }) => (<FormAny {...field} />)}
      />

      <Controller
        name="newBoards"
        render={({ field }) => (<FormAny {...field} />)}
      />

      <div className="my-3">
        <button
          type="button"
          className="btn btn-fill-secondary"
          onClick={handleAddBoard}
          disabled={newBoards.length > 2}
        >
          {intl.t('twin.form.actions.newBoard')}
        </button>
      </div>

      {newBoards.map((item, idx) => (
        <div key={item.id} className="mx-3 mb-2">
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              className="form-check-input mt-0 me-2 flex-shrink-0"
              checked={handleActive(item)}
              onChange={() => handleChangeBoard(item)}
            />
            <input
              type="text"
              className="form-control form-control-sm me-2"
              placeholder={intl.t('twin.form.fields.boardNamePlaceholder')}
              defaultValue={item.name}
              onBlur={event => handleUpdateNameBoard(item.id, event.target.value)}
              onChange={event => handleInputChange(event, item)}
            />
            <button
              type="button"
              className="btn btn-sm btn-flat-secondary"
              onClick={() => handleRemoveBoard(item.id)}
            >
              {intl.t('form.actions.remove')}
            </button>
          </div>
          {has(errors, `newBoards.${idx}.name`) ? (
            <div className="small text-danger">
              {get(errors, `newBoards.${idx}.name.message`)}
            </div>
          ) : null}
        </div>
      ))}

      <BindedTwinGraphList
        className="d-flex flex-fill min-h-0"
        active={handleActive}
        selectable
        onChange={handleChangeBoard}
      />
    </>
  )
}
