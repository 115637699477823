import { useFormContext } from 'react-hook-form'
import { get } from '@wiz/utils'
import {
  FormField,
  FormControl,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'

export default function Fields ({ scope, integration }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return integration.fields.map(field => (
    <FormField
      key={field}
      label={intl.t(`integrations.form.fields.${field}`)}
      description={intl.t(`integrations.form.fields.${field}Descr`)}
      errors={get(errors, `${scope}.${field}`)}
    >
      <FormControl
        name={`${scope}.${field}`}
        inputType={field !== 'region' ? 'password' : undefined}
        rules={{
          validate: (value) => {
            const str = String(value || '').trim()
            if (!str.length) {
              return intl.t('form.errors.fieldRequired')
            }
            if (value.length > 50) {
              return intl.t('form.errors.fieldMaxlen', { max: 50 })
            }
            return true
          },
        }}
      />
    </FormField>
  ))
}
