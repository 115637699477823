import { useMemo } from 'react'
import { VirtualTable, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'

export default function TableConnectionsMobile ({
  items,
  onView,
  ...props
}) {
  const intl = useIntl()
  const columns = useMemo(() => [
    {
      Header: intl.t('edge.connections.form.fields.clientId'),
      accessor: 'clientId',
      Cell: ({ row }) => (
        <div className="min-w-0">
          <div className="text-truncate">
            {row.original.clientId}
          </div>
          <div className="small text-secondary text-truncate">
            {row.original.status}
          </div>
        </div>
      ),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 40,
      width: 40,
      maxWidth: 40,
      className: 'justify-content-end text-nowrap',
      Cell: ({ row }) => (
        <button
          type="button"
          className="btn btn-sm p-2"
          onClick={() => onView?.(row.original)}
        >
          <Icon name="fa--chevron-right" />
        </button>
      ),
    },
  ], [ intl, onView ])

  return (
    <VirtualTable
      className="flex-fill"
      placeholder={intl.t('errors.noDataDisplay')}
      columns={columns}
      data={items}
      showHeader={false}
      selection={false}
      {...props}
    />
  )
}
