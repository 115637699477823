import { of as of$ } from 'rxjs'
import { map, distinctUntilChanged } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { isEqual } from '@wiz/utils'

export default withObservables([
  'qualityData',
  'defaultValues',
], ({
  qualityData,
  defaultValues,
}) => ({
  measurements: qualityData?.queryMeasurements ? (
    qualityData.queryMeasurements
      .observe()
      .pipe(
        map(items => (
          items.reduce((out, item) => ({
            ...out,
            [item.qualityDataTemplateMeasurementId]: item.value,
          }), {})
        )),
      )
  ) : of$(defaultValues?.measurements ?? {}).pipe(distinctUntilChanged(isEqual)),
}))
