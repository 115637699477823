import { useFormContext } from 'react-hook-form'
import { FormSection } from '@wiz/components'
import InputBins from '@/components/Form/InputBins'

export default function SectionConditions ({ scope, title, description }) {
  const {
    watch,
  } = useFormContext()
  const [
    precision,
    minInterval,
    maxInterval,
  ] = watch([
    `${scope}.precision`,
    `${scope}.minInterval`,
    `${scope}.maxInterval`,
  ])

  return (
    <FormSection
      title={title}
      description={description}
    >
      <InputBins
        titleAddBin="t/form.actions.addThreshold"
        titleBinsNotFound="t/form.info.thresholdNotFound"
        name={`${scope}.bins`}
        min={minInterval}
        max={maxInterval}
        precision={precision}
        canRemoveLast
        showMinLimit
        showMaxLimit
      />
    </FormSection>
  )
}
