import {
  forwardRef,
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import classnames from 'classnames'
import chunk from 'lodash/chunk'
import startCase from 'lodash/startCase'
import {
  Dialog,
  Icon,
  ResponsiveList,
  FormInputSearch,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import IconFa from '@/shared/icon'

const Row = ({ data, index, style }) => {
  const {
    options, value, onChange, category,
  } = data
  const items = options[index]

  return (
    <div style={style} className="d-flex justify-content-around px-2 px-sm-3">
      {items.map(item => (
        <button
          key={item.key}
          type="button"
          className={classnames('btn m-1 d-block col-md-2', {
            'btn-flat-primary': item.key === value,
            'btn-flat-secondary': item.key !== value,
          })}
          style={category === 'fa2' ? { width: 100 } : {}}
          title={item.title}
          onClick={() => onChange(item.key)}
        >
          {category === 'fa2' ? <IconFa name={item.key} size="xl" /> : <Icon name={item.key} size="2X" />}
          <p className="m-0 fs-7 text-truncate">{startCase(item.title)}</p>
        </button>
      ))}
    </div>
  )
}

const SelectIcon = forwardRef(({
  name,
  value,
  onChange,
  className,
  categories,
  invalid,
  disabled,
  fetchIcons,
  getIconInfo,
}, ref) => {
  const refDialog = useRef()
  const refList = useRef()
  const intl = useIntl()
  const info = getIconInfo(value)
  const [{ options, category }, setOptions ] = useState({})
  const [ search, setSearch ] = useState('')
  const [ selectCategory, setSelectCategory ] = useState(info?.info?.groups?.[0] || 'fa2')

  const [ open, setOpen ] = useState(false)

  const handleChange = useCallback((data) => {
    refDialog.current.close()
    onChange?.(data)
  }, [ onChange ])

  const itemData = useMemo(() => ({
    value,
    options,
    category,
    onChange: handleChange,
  }), [ value, options, category, handleChange ])

  useEffect(() => {
    async function fetchData () {
      const icons = await fetchIcons({
        search,
        category: selectCategory,
      })

      setOptions({ options: chunk(icons.items, 5), category: icons.category })
    }

    fetchData()
  }, [ selectCategory, search ])

  useEffect(() => {
    if (open && value && refList.current) {
      setTimeout(() => refList.current?.scrollToRecord(value, options), 1)
    }
  }, [
    value,
    options,
    open,
  ])

  return (
    <>
      <button
        ref={ref}
        name={name}
        type="button"
        className={classnames('form-control', className, {
          'is-invalid': !!invalid,
        })}
        disabled={disabled}
        onClick={() => setOpen(true)}
      >
        { info?.info?.isFa ? (
          <IconFa
            name={value}
            size="2xl"
          />
        ) : info?.sprite ? (
          <Icon
            name={info.sprite}
            width={info?.info?.width}
            height={info?.info?.height}
          />
        ) : (
          <span className="text-muted">Select Icon</span>
        )}
      </button>

      {open ? (
        <Dialog
          ref={refDialog}
          classNameBody="p-0"
          title={intl.t('Select Icon')}
          dataTestid="selectIconDialog"
          onClose={() => setOpen(false)}
        >
          <div className="nav justify-content-center mx-3">
            {categories.map(item => (
              <button
                key={item.id}
                type="button"
                className={classnames('btn m-2 d-block', {
                  'btn-flat-primary': selectCategory === item.id,
                  'btn-flat-secondary': selectCategory !== item.id,
                })}
                onClick={() => setSelectCategory(item.id)}
              >
                {item.id === 'fa2' ? <IconFa type="solid" name={item.icon} size="xl" /> :
                <Icon name={item.icon} size="lg" />}
                <p className="m-0">{item.name}</p>
              </button>
            ))}
          </div>

          <div className="mx-3 mb-2">
            <FormInputSearch
              placeholder={intl.t('form.actions.searchPlaceholder')}
              onChange={setSearch}
            />
          </div>
          <ResponsiveList
            refList={refList}
            className="flex-fill min-h-0"
            itemCount={options.length}
            itemSize={62}
            itemData={itemData}
          >
            {Row}
          </ResponsiveList>

          <div className="d-flex justify-content-end m-3">
            <button
              name="remove"
              type="button"
              className="btn btn-outline-danger btn-text me-3"
              title={intl.t('form.actions.remove')}
              disabled={!value}
              onClick={() => handleChange(null)}
            >
              {intl.t('form.actions.clear')}
            </button>

            <button
              name="close"
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => refDialog.current.close()}
            >
              {intl.t('form.actions.close')}
            </button>
          </div>
        </Dialog>
      ) : null}
    </>
  )
})

SelectIcon.displayName = 'SelectIcon'
export default SelectIcon
