import { useMemo } from 'react'
import { FormSection } from '@wiz/components'
import FormDialog from '@/components/Form/FormDialog'
import { Event } from '@wiz/store'
import Fields from './Fields'

export default function EventBulkEdit ({
  dialog,
  onSubmit,
  onClose,
}) {
  const defaultValues = useMemo(() => ({
    events: Event.toJSON(),
    selected: [],
  }), [])

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="eventBulkEditForm"
      dialog={dialog}
    >
      <FormSection>
        <Fields scope="events" />
      </FormSection>
    </FormDialog>
  )
}
