import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { auth } from '@/auth'

export default withObservables([ 'id', 'defaultValues' ], ({ id, defaultValues }) => ({
  dashboard: id ? dbProvider.database.collections.get('dashboards')
    .query(Q.where('id', id))
    .observeWithColumns([ 'updated_at' ])
    .pipe(
      map(items => items.filter(item => auth.checkAccessRead(item))),
      map(items => items[0]),
    ) : of$(defaultValues?.dashboard),
}))
