import { useState } from 'react'
import { Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import classnames from 'classnames'
import classes from './index.module.css'

const iconsMap = {
  sns: 'aws',
}

export default function AvailableList ({
  options,
  onClick,
  integrationsList,
}) {
  const intl = useIntl()

  const isSnsDisabled = integrationsList.filter(integration => integration.type === 'sns').length >= 1

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">
      <div className="content-container content-container-sm d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-2">
        <div className={classes.integrations}>
          {options.map(({ type }) => (
            <div key={type} className={classnames(classes.integration, { [classes.disabled]: isSnsDisabled })} onClick={() => !isSnsDisabled && onClick(type)} role="button" aria-hidden="true">
              <Icon name={`wiz--logo--${iconsMap[type]}-logo`} width={48} height={48} />
              <p className={classes.name}>{intl.t(`${type}.form.fields.name`)}</p>
              <p>{intl.t(`${type}.form.fields.description`)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
