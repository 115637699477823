import { memo } from 'react'
import { withProps, useDidUpdate } from '@wiz/components'
import enhanceTwin from './enhanceTwin'
import enhanceTwinData from './enhanceTwinData'

const enhanceProps = withProps(({
  twin,
  dashboards,
  explorers,
  streamJobs,
  twinGraphs,
  onChange,
}) => {
  useDidUpdate(() => {
    onChange?.({
      twin,
      dashboards,
      explorers,
      twinGraphs,
      streamJobs,
    })
  }, [
    twin,
    dashboards,
    explorers,
    twinGraphs,
    streamJobs,
    onChange,
  ])
})

export default memo(
  enhanceTwin(
    enhanceTwinData(
      enhanceProps(() => (null)),
    ),
  ),
)
