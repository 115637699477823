import { useEffect } from 'react'
import { useAuth } from '@/auth'

export default function Logout () {
  const auth = useAuth()

  useEffect(() => {
    document.getElementById('startup-loader').show({ title: 'Logout, please wait...' })
    auth.logout()
  }, [ auth ])

  return null
}
