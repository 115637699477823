import PropTypes from 'prop-types'
import FormDialog from '@/components/Form/FormDialog'
import Section from './Section'
import Schema from './Schema'
import resolver from './resolver'

export default function SensorBulkEdit ({
  onSubmit,
  onClose,
  dialog,
  sensors,
}) {
  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      dataTestid="sensorBulkEditForm"
      dialog={dialog}
      resolver={resolver}
      context={{ sensors }}
      schema={Schema}
    >
      <Section scope="sensors" />
    </FormDialog>
  )
}

SensorBulkEdit.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dialog: PropTypes.object,
  sensors: PropTypes.array,
}

SensorBulkEdit.defaultProps = {
  dialog: undefined,
}
