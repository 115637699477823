import { useCallback } from 'react'
import { withProps } from '@wiz/components'
import withTableActions from '@/containers/withTableActions'
import Component from '@/components/AccessRole/List'

const enhanceProps = withProps(({ onRemove }) => {
  const onRemoveAccessRole = useCallback(async (data) => {
    await onRemove?.(data)
  }, [ onRemove ])

  return {
    onRemove: onRemoveAccessRole,
  }
})

export default withTableActions()(
  enhanceProps(Component),
)
