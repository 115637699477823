import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { toArrayValue } from '@wiz/utils'

const enhanceData = withObservables([
  'search',
  'selectedOnly',
  'value',
], ({
  search,
  selectedOnly,
  value,
}) => {
  let query = dbProvider.database.collections.get('projects').query()

  if (search) {
    const sanitizeSearch = Q.sanitizeLikeString(search)
    query = query.extend(Q.or(
      Q.where('name', Q.like(`%${sanitizeSearch}%`)),
      Q.where('description', Q.like(`%${sanitizeSearch}%`)),
    ))
  }

  if (selectedOnly) {
    query = query.extend(Q.where('id', Q.oneOf(toArrayValue(value))))
  }

  const observe = selectedOnly ?
    query.observeWithColumns([ 'updated_at' ]) :
    query.observe()

  return {
    options: observe,
  }
})

export default WrappedComponent => enhanceData(WrappedComponent)
