/* eslint-disable react/no-danger */
import classNames from 'classnames'
import fileSize from 'filesize'
import {
  FormInputSearch,
  Accordion,
  CustomScrollbars,
  useMobile,
} from '@wiz/components'
import { markSearch } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import Link from '@/components/Link'
import ButtonFileDownload from '@/components/Form/ButtonFileDownload'
import classes from './View.css'

export default function View ({
  project,
  dashboards,
  notebooks,
  explorers,
  qualityDataTemplates,
  files,
  jobs,
  filters,
  onFilter,
  onResetFilter,
}) {
  const intl = useIntl()
  const isMobile = useMobile()
  const search = filters?.search || ''

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">

      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <div className="d-flex align-items-center flex-wrap">
          <div className="px-2 mb-2 col-12 col-sm-4">
            <FormInputSearch
              placeholder={intl.t('form.actions.searchPlaceholder')}
              value={search}
              onChange={value => onFilter({ search: value })}
            />
          </div>

          <div className="px-2 mb-2">
            <button
              type="button"
              className="btn btn-fill-secondary me-2"
              onClick={onResetFilter}
            >
              {intl.t('form.actions.resetFilters')}
            </button>
          </div>
        </div>

        <div className="px-2 mt-2 min-h-0 flex-fill">
          <CustomScrollbars
            horizontal={false}
            shadow={false}
            autoHide
          >
            <Accordion title={`${intl.t('dashboards.title')} (${dashboards.length || 0})`}>
              {dashboards.map(({ id, title }) => (
                <Item key={id}>
                  <Link
                    name="dashboard"
                    params={{ id }}
                    className="text-reset text-truncate min-w-0"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: markSearch(title, search) }}
                    />
                  </Link>
                </Item>
              ))}
              {!dashboards.length ? (
                <div className="p-3">{intl.t('form.nodata')}</div>
              ) : null}
            </Accordion>

            <Accordion title={`${intl.t('jobs.title')} (${jobs.length || 0})`}>
              {jobs.map(({
                id, name, command, state,
              }) => (
                <Item key={id}>
                  <div className="text-reset text-truncate min-w-0 flex-fill me-2">
                    <span
                      dangerouslySetInnerHTML={{ __html: markSearch(name, search) }}
                    />
                  </div>
                  <div className="flex-wrap d-flex align-items-center text-nowrap">
                    {state ? <div className="me-2">{state}</div> : null}
                    {command ? <div>{command}</div> : null}
                  </div>
                </Item>
              ))}
              {!jobs.length ? (
                <div className="p-3">{intl.t('form.nodata')}</div>
              ) : null}
            </Accordion>

            <Accordion title={`${intl.t('files.title')} (${files.length || 0})`}>
              {files.map(file => (
                <Item key={file.id}>
                  <div className="text-reset text-truncate min-w-0 flex-fill">
                    <span
                      dangerouslySetInnerHTML={{ __html: markSearch(file.name, search) }}
                    />
                    <div className="font-italic text-muted text-truncate small">
                      {file.description}
                    </div>
                  </div>
                  <div className="flex-wrap d-flex align-items-center text-nowrap">
                    <ButtonFileDownload file={file}>
                      {fileSize(parseInt(file.length, 10))}
                    </ButtonFileDownload>
                    <div className="mx-2">
                      {file.type}
                    </div>
                    <div>{file.contentType}</div>
                  </div>
                </Item>
              ))}
              {!files.length ? (
                <div className="p-3">{intl.t('form.nodata')}</div>
              ) : null}
            </Accordion>

            <Accordion title={`${intl.t('notebooks.title')} (${notebooks.length || 0})`}>
              {notebooks.map(({
                id, name, language, format, description,
              }) => (
                <Item key={id}>
                  <div className="text-reset text-truncate min-w-0 flex-fill me-2">
                    <span
                      dangerouslySetInnerHTML={{ __html: markSearch(name, search) }}
                    />
                    <div className="font-italic text-muted text-truncate small">
                      {description}
                    </div>
                  </div>
                  <div className="flex-wrap d-flex align-items-center text-nowrap">
                    {language ? <div className="me-2">{language}</div> : null}
                    {format ? <div>{format}</div> : null}
                  </div>
                </Item>
              ))}
              {!notebooks.length ? (
                <div className="p-3">{intl.t('form.nodata')}</div>
              ) : null}
            </Accordion>

            <Accordion title={`${intl.t('tmplQualityData.title')} (${qualityDataTemplates.length || 0})`}>
              {qualityDataTemplates.map(({
                id, name,
              }) => (
                <Item key={id}>
                  <div className="text-reset text-truncate min-w-0 flex-fill">
                    <span
                      dangerouslySetInnerHTML={{ __html: markSearch(name, search) }}
                    />
                  </div>
                </Item>
              ))}
              {!qualityDataTemplates.length ? (
                <div className="p-3">{intl.t('form.nodata')}</div>
              ) : null}
            </Accordion>

            <Accordion title={`${intl.t('explorer.title')} (${explorers.length || 0})`}>
              {explorers.map(({ id, title }) => (
                <Item key={id}>
                  <Link
                    name="data-observation"
                    params={{ id }}
                    className="text-reset text-truncate min-w-0"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: markSearch(title, search) }}
                    />
                  </Link>
                </Item>
              ))}
              {!explorers.length ? (
                <div className="p-3">{intl.t('form.nodata')}</div>
              ) : null}
            </Accordion>
          </CustomScrollbars>
        </div>
      </div>
    </div>
  )
}

const Item = ({ children }) => (
  <div className={classNames('d-flex mx-3 py-2 border-bottom align-items-center flex-wrap', classes.item)}>
    {children}
  </div>
)
