import { useEffect, useCallback } from 'react'
import { useIntl } from '@wiz/intl'
import { useRouter } from '@/router'
import FormBGJobExport from '@/containers/Forms/BGJobExport'
import FormBGJobNotebook from '@/containers/Forms/BGJobNotebook'
import FormCreateWidgets from '@/containers/Forms/CreateWidgets'
import InputSensorValueGroup from '@/containers/Forms/InputSensorValueGroup'

const BulkForms = {
  dashboard: {
    Component: FormCreateWidgets,
    title: 't/dashboards.titleCreateWidgets',
  },
  export: {
    Component: FormBGJobExport,
    title: 't/form.actions.exportTitle',
  },
  notebook: {
    Component: FormBGJobNotebook,
    title: 't/form.actions.createNotebook',
  },
  inputSensorValue: {
    Component: InputSensorValueGroup,
    title: 't/sensors.titleInputValue',
  },
}

export default function BulkSensors ({
  type,
  params,
  sensorIds,
  onClose,
  onSuccess,
}) {
  const router = useRouter()
  const intl = useIntl()
  const BulkForm = BulkForms[type]

  const handleSuccess = useCallback((payload) => {
    onSuccess?.(type, payload)
  }, [ type, onSuccess ])

  useEffect(() => {
    if (type === 'explorer') {
      router.push({
        name: 'data-observation',
        params: { id: 'new' },
        state: { selection: sensorIds },
      })
    }
  }, [ router, sensorIds, type ])

  return BulkForm ? (
    <BulkForm.Component
      params={params}
      sensorIds={sensorIds}
      defaultReady={BulkForm.defaultReady}
      onSuccess={handleSuccess}
      onClose={onClose}
      dialog={{
        title: intl.t(BulkForm.title),
        dataTestid: `${type}BulkFormDialog`,
      }}
    />
  ) : null
}
