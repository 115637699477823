import { auth } from '@/auth'
import { isEmpty } from '@wiz/utils'

const checkSettingsAreAllowed = (val) => {
  // const settingsRules = [ 'PlatformEnvironmentSettings', 'PlatformTimeSettings', 'SMSNotificationsSettings', 'PlatformDataSettings' ]
  const settingsRules = [ 'PlatformTimeSettings', 'SMSNotificationsSettings', 'PlatformDataSettings' ]

  const isAllowed = settingsRules.some(subject => auth.checkAccessManage(subject))

  return isAllowed ? val : null
}

export const checkPermissions = options => options?.reduce((acc, val) => {
  const item = !!(
    (val.route || val.actions) &&
    !val.disabled &&
    (
      isEmpty(val.access) ||
      val.access.every(rule => auth.checkAccessRules(rule[1], rule[0]))
    ))

  // -------- actions check --------
  const actions = val.actions?.filter(action => !!(
    (action.path || action.action) &&
    !action.disabled &&
    (
      isEmpty(action.access) ||
      action.access.every(rule => auth.checkAccessRules(rule[1], rule[0]))
    )))

  if (item && actions?.length) {
    acc.push({ ...val, actions })
  }
  // -------- actions check end --------

  if (item && !val.actions) {
    if (val.route?.name === 'settings') {
      const isAllowed = checkSettingsAreAllowed(val)
      isAllowed && acc.push(val)
    } else {
      acc.push(val)
    }
  }

  return acc
}, [])
