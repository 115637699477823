import { of as of$ } from 'rxjs'
import { withObservables } from '@wiz/components'
import { dbProvider, DiagramState } from '@wiz/store'
import defaultDiagramState from '@/utils/defaultDiagramState'

const enhanceDiagramState = withObservables([ 'twinsChain' ], ({ twinsChain }) => ({
  rootTwin: of$(twinsChain[twinsChain.length - 1]),
  globalSettings: dbProvider.observeGlobalSettings([ 'EnvironmentName' ]),
  diagramState: DiagramState.observePhysicalTwinDiagramState(
    dbProvider.database,
    twinsChain[twinsChain.length - 1],
  ).pipe(
    defaultDiagramState(twinsChain[twinsChain.length - 1]),
  ),
}))

export default enhanceDiagramState
