import { of as of$ } from 'rxjs'
import { map, distinctUntilChanged } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { isEqual } from '@wiz/utils'

export default withObservables([ 'id' ], ({ id }) => ({
  file: id ? dbProvider.database.collections.get('files')
    .query(Q.where('id', id))
    .observeWithColumns([ 'updated_at' ])
    .pipe(map(items => items[0])) : of$(undefined),

  projects: id ? (
    dbProvider.database.collections.get('rel_projects_files')
      .query(Q.where('file_id', id))
      .observeWithColumns([ 'updated_at' ])
      .pipe(map(items => items.map(item => item.projectId)))
  ) : (
    of$([])
      .pipe(distinctUntilChanged(isEqual))
  ),
}))
