import { withPagination, withSort } from '@wiz/components'
import Component from '@/components/EdgeDevice/TableModules'
import enhanceDataModules from './enhanceDataModules'

export default enhanceDataModules(
  withSort({
    defaultSortBy: 'moduleId',
    defaultSortDir: 'desc',
  })(
    withPagination()(Component),
  ),
)
