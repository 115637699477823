import { useMemo } from 'react'
import { Table as StaticTable, Pagination } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import TwinList from '@/hoc/TwinList'
import SensorList from '@/hoc/SensorList'
import StreamJobList from '@/hoc/StreamJobList'
import DeviceCommandTemplateList from '@/hoc/DeviceCommandTemplateList'
import EdgeDeviceList from '@/hoc/EdgeDeviceList'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import CellTwin from './CellTwin'
import CellStreamJob from './CellStreamJob'
import CellSensors from './CellSensors'
import CellTemplate from './CellTemplate'
import CellDevice from './CellDevice'

const CellSensorsData = SensorList(CellSensors)
const CellStreamJobData = StreamJobList(CellStreamJob)
const CellTwinData = TwinList(CellTwin)
const CellTemplateData = DeviceCommandTemplateList(CellTemplate)
const CellDeviceData = EdgeDeviceList(CellDevice)

function Detail ({ row }) {
  const intl = useIntl()
  const { response } = row.original

  return (
    <div className={response ? null : 'text-muted'}>
      {response || intl.t('form.noresponse')}
    </div>
  )
}

export default function Table ({
  items,
  onFilter,
  ...props
}) {
  const intl = useIntl()
  const columns = useMemo(() => [
    {
      Header: intl.t('edge.commands.form.fields.device'),
      accessor: 'deviceId',
      disableSortBy: true,
      Cell: ({ cell }) => (cell.value ? (
        <CellDeviceData
          value={cell.value}
          selectedOnly
          onFilter={onFilter}
        />
      ) : '-'),
    },
    {
      Header: intl.t('form.fields.status'),
      accessor: 'status',
      width: 90,
      maxWidth: 90,
      Cell: ({ cell }) => (
        <span className="text-truncate">
          {cell.value || '-'}
        </span>
      ),
    },
    {
      Header: intl.t('edge.commands.form.fields.template'),
      accessor: 'templateId',
      disableSortBy: true,
      Cell: ({ cell }) => (cell.value ? (
        <CellTemplateData
          value={cell.value}
          selectedOnly
        />
      ) : '-'),
    },
    {
      Header: intl.t('edge.commands.form.fields.payload'),
      accessor: 'payload',
      disableSortBy: true,
      Cell: ({ cell }) => (
        <span className="text-truncate">
          {String(cell.value)}
        </span>
      ),
    },
    {
      Header: intl.t('form.fields.streamJob'),
      accessor: 'streamJobId',
      disableSortBy: true,
      width: 100,
      Cell: ({ cell }) => (cell.value ? (
        <CellStreamJobData
          value={cell.value}
          selectedOnly
          onFilter={onFilter}
        />
      ) : '-'),
    },
    {
      Header: intl.t('form.fields.twin'),
      accessor: 'twinId',
      disableSortBy: true,
      width: 100,
      Cell: ({ cell }) => (cell.value ? (
        <CellTwinData
          value={cell.value}
          selectedOnly
        />
      ) : '-'),
    },
    {
      Header: intl.t('form.fields.dataPoint'),
      accessor: 'sensorId',
      disableSortBy: true,
      width: 100,
      Cell: ({ cell }) => (cell.value ? (
        <CellSensorsData
          value={cell.value}
          keyName="id"
          selectedOnly
          onFilter={onFilter}
        />
      ) : '-'),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedAt',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.updatedById}
        />
      ),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdAt',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.createdById}
        />
      ),
    },
  ], [ onFilter, intl ])

  return (
    <>
      <StaticTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={false}
        ExpandeComponent={Detail}
        expande
        {...props}
      />

      <Pagination {...props} relative />
    </>
  )
}
