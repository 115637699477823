import { useMemo } from 'react'
import classnames from 'classnames'
import {
  func, number, bool, string,
} from 'prop-types'
import { useIntl } from '@wiz/intl'

const ActionButtons = ({
  step, onClick, onSubmit, isValid, nextDisabled, maxSteps, disabledSteps, experimentId, isReExecution,
}) => {
  const intl = useIntl()

  const nextBtnText = useMemo(() => {
    if (step === maxSteps) {
      return 'form.actions.confirm'
    }

    // if (!isReExecution && step === 0) {
    //   if (experimentId) {
    //     return 'form.actions.select'
    //   }
    //   return 'form.actions.create'
    // }
    return 'form.actions.next'
  }, [ step, experimentId, maxSteps, isReExecution ])

  return (
    <div className="d-flex flex-wrap">
      <ul className="pagination mx-auto my-1">
        <li
          className={classnames({
            'page-item': true,
            disabled: !isReExecution ? step < 2 : !step,
          })}
        >
          <a
            className="page-link border-0 rounded rounded-2 mx-1"
            href="#"
            tabIndex={!step ? '-1' : undefined}
            aria-disabled={!isReExecution ? step < 2 : !step}
            onClick={(event) => {
              event.preventDefault()
              if (isReExecution && step > 0) {
                onClick(step - 1)
              }
              if (step > 1) {
                onClick(step - 1)
              }
            }}
          >
            <span aria-hidden="true">&laquo;</span>
            <span className="d-none d-sm-inline ms-sm-1">Previous</span>
          </a>
        </li>

        <li
          className={classnames({
            'page-item': true,
            disabled: step === disabledSteps && nextDisabled,
          })}
        >
          <a
            className="page-link border-0 rounded rounded-2 mx-1"
            href="#"
            tabIndex={step === maxSteps ? '-1' : undefined}
            aria-disabled={!isValid}
            onClick={(event) => {
              event.preventDefault()
              if (step === maxSteps) {
                onSubmit()
              } else if (isValid) {
                onClick(step + 1)
              }
            }}
          >
            <span className="d-none d-sm-inline me-sm-1">{intl.t(nextBtnText)}</span>
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </div>
  )
}

export { ActionButtons }

ActionButtons.propTypes = {
  step: number.isRequired,
  onClick: func.isRequired,
  onSubmit: func.isRequired,
  isValid: bool.isRequired,
  nextDisabled: bool.isRequired,
  disabledSteps: number,
  maxSteps: number.isRequired,
  experimentId: bool,
  isReExecution: bool,
}

ActionButtons.defaultProps = {
  disabledSteps: null,
  experimentId: false,
  isReExecution: false,
}
