import { withProps } from '@wiz/components'
import { consts } from '@wiz/utils'
import Component from '@/components/RightBarInfo'
import enhanceData from './enhanceData'

const enhanceSetPointData = withProps(() => ({
  businessType: consts.SensorBusinessType.SetPoint,
}))

export default enhanceSetPointData(
  enhanceData(Component),
)
