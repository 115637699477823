import classnames from 'classnames'
import { Icon } from '@wiz/components'
import Link from '@/components/Link'

export default function ItemContent ({
  data,
  context,
  search,
  className,
  ContentDefault,
}) {
  const item = data.payload || data
  const { onAction } = context
  const isHomepage = onAction(null, 'checkHomepage', data)
  return (
    <>
      <Link
        className={classnames('d-flex align-items-center min-w-0', {
          'text-reset': !isHomepage,
          'text-primary': isHomepage,
        })}
        name="chart-view"
        params={{ id: item.id }}
        onClick={event => onAction(event, 'chartView')}
      >
        <ContentDefault
          color={item.color}
          icon={item.icon}
          name={item.name}
          search={search}
          className={className}
        />
      </Link>

      <button
        className="hover-opacity btn btn-flat-secondary p-2 ms-auto"
        type="button"
        onClick={(event) => {
          event.stopPropagation()
          onAction(event, 'more', data)
        }}
      >
        <Icon name="fa--ellipsis-h" />
      </button>
    </>
  )
}
