import { consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import {
  Icon,
  FormInputNumber,
  FormSwitch,
  FormInput,
} from '@wiz/components'
import { auth } from '@/auth'
import { useRouter } from '@/router'
import Link from '@/components/Link'

export default function DeviceCommand ({
  device,
  template,
  sensor,
  payload,
  loading,
  config,
  onChange,
  onSendCommand,
}) {
  const intl = useIntl()
  const router = useRouter()

  return (
    <div className="flex-fill d-flex flex-column min-w-0">
      <div className="d-flex min-w-0">
        <button
          type="button"
          className="btn btn-sm p-0 border-0 me-2"
          onClick={() => {
            router.push({
              name: 'edge-commands-list',
              query: {
                sensorId: config.sensorId,
                deviceId: config.deviceId,
              },
            })
          }}
        >
          <Icon
            name="fa--search"
          />
        </button>

        {auth.checkAccessRead(device) ? (
          <Link
            className="text-truncate fw-bold"
            title={device.name}
            name="edge-devices"
            params={{
              id: device.id,
            }}
          >
            {device.name}
          </Link>
        ) : (
          <span className="text-truncate fw-bold" title={device.name}>
            {device.name}
          </span>
        )}
      </div>

      {auth.checkAccessRead(template) ? (
        <Link
          title={template.name}
          className="text-truncate"
          name="edge-command-templates-list"
          query={{
            search: template.id,
          }}
        >
          {template.name}
        </Link>
      ) : (
        <span className="text-truncate" title={template.name}>
          {template.name}
        </span>
      )}

      {sensor ? (
        <>
          {auth.checkAccessRead(sensor) ? (
            <Link
              className="text-truncate"
              title={sensor.displayName}
              name="sensors-list"
              query={{
                search: sensor.id,
              }}
            >
              {sensor.displayName}
            </Link>
          ) : (
            <span className="text-truncate" title={sensor.displayName}>
              {sensor.displayName}
            </span>
          )}
        </>
      ) : null}

      <div className="position-center-fill flex-column">
        {do {
          if (template.dataType === consts.DeviceCommandTemplateDataType.Number) {
            <FormInputNumber
              scale={8}
              disabled={loading}
              value={payload}
              onChange={onChange}
            />
          } else if (template.dataType === consts.DeviceCommandTemplateDataType.Bool) {
            <FormSwitch
              className="my-2"
              disabled={loading}
              value={payload}
              onChange={onChange}
            />
          } else {
            <FormInput
              disabled={loading}
              value={payload}
              onChange={event => onChange(event.target.value)}
            />
          }
        }}

        <button
          type="button"
          className="btn btn-fill-secondary mt-2 w-100"
          disabled={loading}
          onClick={onSendCommand}
        >
          {loading ? (
            <Icon
              className="me-1"
              name="fa--spinner"
              spin
            />
          ) : null}
          {intl.t('form.actions.send')}
        </button>
      </div>
    </div>
  )
}
