import {
  useRef, useState, useCallback, useMemo,
} from 'react'
import cx from 'classnames'
import { useQuery } from '@tanstack/react-query'
import { useRouter, useLocationQuery } from '@/router'
import {
  Icon, CustomScrollbars, withObservables,
} from '@wiz/components'
import { dbProvider } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import { wizataApi } from '@/api'
import withTableActions from '@/containers/withTableActions'
import enhanceContext from '@/containers/ExperimentResult/enhanceContext'
import enhanceResult from '@/containers/ExperimentResult/enhanceResult'
import { Debug } from '@/components/ExperimentResult'
import CombinedPlots from '@/components/ExperimentResult/Combined'

const enhanceProps = withObservables([], () => {
  const settings = dbProvider.observeGlobalSettings([
    'SeriesQueryMaxCount',
    'RawDateRangeLimit',
  ])

  return ({
    businessLabels: dbProvider.database.collections.get('business_labels').query().observe(),
    settings,
  })
})

const Result = ({
  execution: _execution, isRunning, updateConditions, toggleLegend, resizeLegend, changeViewZoom, data,
}) => {
  const [ isDebug, setIsDebug ] = useState(false)
  const ref = useRef()

  const { executionId } = useLocationQuery()

  const intl = useIntl()

  console.log(_execution, executionId)
  const {
    data: fetchedExecution,
    isLoading: executionIsLoading,
    refetch: refetchExecution,
  } = useQuery({
    queryKey: [ 'execution', executionId ],
    queryFn: () => wizataApi.executions.getById(executionId),
    enabled: !_execution && !!executionId,
    staleTime: Infinity,
  })

  const execution = _execution || fetchedExecution

  const { data: plotsList, isLoading: plotsLoading, isFetching: plotsFetching } = useQuery({
    queryKey: [ 'plots', execution?.id ],
    queryFn: () => (execution ? wizataApi.plots.getListByExecutionId(execution.id) : undefined),
    enabled: execution?.status === 'completed',
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  })

  const { data: models } = useQuery({
    queryKey: [ 'models', execution?.id ],
    queryFn: () => (execution ? wizataApi.mlmodels.getList({ generatedById: execution.id }) : undefined),
    enabled: execution?.status === 'completed',
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  })

  console.log('_execution: ', _execution)
  console.log('fetchedExecution: ', fetchedExecution)

  const handleSelect = useCallback((conditions) => {
    updateConditions(conditions, data.dataSources)
  }, [ data.dataSources, updateConditions ])

  const request = useMemo(() => JSON.parse(execution?.request || '{}'), [ execution ])
  const anomalies = useMemo(() => JSON.parse(execution?.anomaliesList || '{}'), [ execution ])
  const isDone = execution && [ 'completed', 'failed' ].includes(execution.status)

  const configured = (
    data.dataSources.length > 0 &&
    data.dataViews.length > 0
  ) || plotsList?.length

  const isError = useMemo(() => {
    if (execution?.warnings) {
      return true
    }
    return false
  }, [ execution ])

  const isPlotsLoading = plotsLoading && plotsFetching

  const isFullSuccess = !isError &&
  !plotsLoading &&
  execution &&
  !execution?.warnings &&
  !anomalies?.length &&
  !plotsList?.length &&
  execution?.status === 'completed'

  const isLoading = isPlotsLoading || [ 'queued', 'started', 'received' ].includes(execution?.status)

  const isFullError = !isLoading && (!!execution?.warnings || execution?.status === 'failed')

  const hasNoData = !execution && !plotsList && !isLoading && !isError && !anomalies?.length

  const hasData = (configured &&
  !isLoading &&
  !isError &&
  execution?.status === 'completed' &&
  (!!anomalies?.length || !!plotsList?.length))

  if (hasNoData) {
    return null
  }

  return (
    <div className="d-flex flex-fill">
      {isFullSuccess ?
        (
          <div className="position-center-fill d-flex flex-column">
            <span>Everything seems alright, we didn&apos;t detect any anomalies.</span>
          </div>
        ) : null}

      {isFullError ? (
        <div className="position-center-fill d-flex flex-column">
          <span className="text-danger">Error:</span>
          <span>{execution.warnings}</span>
        </div>
      ) : null}

      {isLoading ? (
        <div className="position-center-fill d-flex flex-column">
          <Icon name="fa--spinner" size="2X" spin />
          <span>Experiment is proccessing right now</span>
        </div>
      ) : null}

      {hasData ? (
        <CustomScrollbars
          className={cx('flex-fill', { 'd-none': isDebug })}
          classNameContent="d-flex flex-column"
          horizontal={false}
          shadow={false}
          autoHide
        >
          <CombinedPlots
            plotsList={plotsList}
            data={data}
            anomalies={anomalies}
            toggleLegend={toggleLegend}
            resizeLegend={resizeLegend}
            changeViewZoom={changeViewZoom}
            onSelect={handleSelect}
          />
        </CustomScrollbars>
      ) :
        null}
      {isDebug ? (
        <Debug {...{ request, execution }} />
      ) : null}
    </div>
  )
}

export default withTableActions({
  initFilters () {
    return {}
  },
  defaultFilters () {
    return {
      sortBy: 'from',
      includeChildEvents: true,
    }
  },
})(enhanceProps(enhanceResult(enhanceContext(Result))))
// export default Result
