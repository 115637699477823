import { useMemo } from 'react'
import { withProps } from '@wiz/components'
import { consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default withProps(({
  search,
  selectedOnly,
  value,
}) => {
  const intl = useIntl()
  const options = useMemo(() => {
    let items = consts.SensorInputModes

    if (search) {
      items = items.filter(item => (item.indexOf(search) !== -1))
    }

    if (selectedOnly) {
      const isArrayValue = Array.isArray(value)
      items = items.filter(item => (isArrayValue ? value.includes(item) : value === item))
    }

    return items.map(id => ({
      id,
      name: intl.t(`enum.sensorInputMode.${id}`),
    }))
  }, [
    search,
    selectedOnly,
    value,
    intl,
  ])

  return {
    options,
  }
})
