import { type, intersection } from 'superstruct'
import { Twin } from '@wiz/store'
import * as types from '@/utils/types'

export default type({
  type: intersection([
    types.Required(),
    Twin.schema.schema.type,
  ]),
  name: intersection([
    types.Required(),
    Twin.schema.schema.name,
  ]),
  description: Twin.schema.schema.description,
  latitude: Twin.schema.schema.latitude,
  longitude: Twin.schema.schema.longitude,
  icon: Twin.schema.schema.icon,
  color: Twin.schema.schema.color,
  hardwareId: Twin.schema.schema.hardwareId,
  parentId: Twin.schema.schema.parentId,
  flowToId: Twin.schema.schema.flowToId,
})
