import { createRef, Component } from 'react'
import PropTypes from 'prop-types'
import { DataView } from '@wiz/store'
import { tooltipFormatter, consts, echarts } from '@wiz/utils'

export default class Histogram extends Component {
  static propTypes = {
    config: PropTypes.object.isRequired,
    dimension: PropTypes.string,
    theme: PropTypes.object,
    data: PropTypes.array,
  }

  static defaultProps = {
    dimension: undefined,
    theme: undefined,
    data: [],
  }

  refTarget = createRef()

  componentDidMount () {
    this.resetPlot()
  }

  componentWillUnmount () {
    this.$plot?.dispose()
  }

  componentDidUpdate (prevProps) {
    const {
      config,
      dimension,
      data,
      theme,
    } = this.props

    if (theme !== prevProps.theme) {
      this.resetPlot()
    } else if (this.$plot) {
      if (
        config !== prevProps.config ||
        dimension !== prevProps.dimension
      ) {
        const properties = this.getProperties()
        this.$plot.setOption(properties, {
          notMerge: true,
          lazyUpdate: false,
          silent: true,
        })
        this.$plot.resize()
      } else if (data !== prevProps.data) {
        const properties = this.getDatasetProperties()
        this.$plot.setOption(properties, {
          notMerge: false,
          lazyUpdate: false,
          silent: true,
        })
      }
    }
  }

  resetPlot () {
    const { theme } = this.props
    const properties = this.getProperties()

    this.$plot?.dispose()
    this.$plot = echarts.init(this.refTarget.current, theme)
    this.$plot.setOption(properties, {
      notMerge: true,
      lazyUpdate: true,
      silent: true,
    })
  }

  getDatasetProperties () {
    const { data, config: { dataViews, categoryXAxis } } = this.props
    const sources = dataViews.filter(item => item.checked)
    const dataset = this.$plot?.getOption().dataset ?? []

    const properties = {
      dataset: sources
        .map((item) => {
          const prev = dataset.find(i => i.id === item.id)?.source ?? []
          let source = data
            .find(i => (i && i.sensorId === item.source.sensorId))?.source || prev
          if (!categoryXAxis) {
            source = source.filter(s => Number.isFinite(s[0]))
          }

          if (!source.length) {
            // fake data to show axis
            source = [
              [ 0, NaN ],
              [ 50, NaN ],
              [ 100, NaN ],
              [ 150, NaN ],
              [ 200, NaN ]]
          }

          return {
            id: item.id,
            sourceHeader: false,
            source,
          }
        }),
    }

    return properties
  }

  getProperties () {
    const dataset = this.getDatasetProperties()
    const {
      config: {
        bins,
        dataViews,
        histogramType,
        categoryXAxis,
        normalize,
      },
    } = this.props
    const sources = dataViews
      .filter(item => item.checked)
      .map(item => ({
        id: item.id,
        color: item.color,
        name: DataView.getDisplayName(item),
      }))
    const binsHashByValue = bins.reduce((out, item) => ({ ...out, [item.value]: item.name }), {})
    const precise = (data, fix = 3) => {
      const value = Number.parseFloat(data)
      if (!Number.isFinite(value)) {
        return data
      }

      return (
        value > -1 && value < 1 ?
          value.toPrecision(fix).replace(/\.?[0]+$/, '') :
          value.toFixed(fix).replace(/\.?[0]+$/, '')
      )
    }
    const preciseLabelValue = (value) => {
      if (normalize === consts.WidgetHistogramNormalizeType.Percentage) {
        return `${Math.round(value)} %`
      }
      return precise(value, 2)
    }
    const binLabelFormatter = histogramType === consts.WidgetHistogramType.Custom ?
      (value) => {
        if (value < 0) {
          return ''
        }
        if (value === 'Infinity') {
          // eslint-disable-next-line dot-notation
          return precise(binsHashByValue[null] || 'Infinity')
        }
        return precise(binsHashByValue[value] || value)
      } :
      value => (value >= 0 ? precise(value) : '')

    const properties = {
      ...dataset,

      tooltip: {
        confine: true,
        enterable: false,
        transitionDuration: 1,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: tooltipFormatter({
          precisionValue: () => (''),
          roundValue: preciseLabelValue,
          callback: (params, content) => (`
            <div>
              ${binLabelFormatter(params[0].axisValue)}
              ${content}
            </div>
          `),
        }),
      },

      grid: {
        top: 20,
        right: 20,
        bottom: 0,
        left: 40,
        containLabel: true,
      },

      yAxis: {
        type: 'value',
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          fontSize: 14,
          formatter: value => precise(value),
        },
      },

      xAxis: {
        ...(categoryXAxis ? {
          type: 'category',
          scale: false,
          boundaryGap: true,
        } : {
          type: 'value',
          scale: true,
          boundaryGap: [ '10%', '10%' ],
        }),
        axisLabel: {
          fontSize: 14,
          fontWeight: 'bold',
          formatter: binLabelFormatter,
        },
      },

      series: sources.map((item, idx) => ({
        xAxisIndex: 0,
        yAxisIndex: 0,
        datasetIndex: idx,
        name: item.name,
        type: 'bar',
        // barGap: 0,
        // animation: false,
        // hoverAnimation: false,
        // label: {
        //   show: true,
        //   position: 'insideBottom',
        //   distance: 15,
        //   align: 'left',
        //   verticalAlign: 'middle',
        //   rotate: 90,
        //   formatter: '{c} {a}',
        //   fontSize: 12,
        // },
        label: {
          show: true,
          position: 'top',
          formatter: params => preciseLabelValue(params.data[1]),
        },
        itemStyle: {
          color: item.color,
        },
        encode: {
          x: 0,
          y: 1,
          tooltip: 1,
        },
      })),
    }

    return properties
  }

  render () {
    return (
      <div className="d-flex flex-column flex-fill">
        <div
          ref={this.refTarget}
          className="flex-fill"
        />
      </div>
    )
  }
}
