import { useCallback } from 'react'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import WidgetSection from '../Widget/Section'
import Section from './Section'

export default function WidgetBulletPlot ({
  widget,
  defaultValues,
  dialog,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()

  const sourcesValidation = useCallback((sources, methods) => {
    methods.clearErrors('config.sources')
    const hasError = sources.some((item, rowIdx) => {
      const data = item.data || []
      let isError = false

      for (const [ idx, value ] of Object.entries(data)) {
        if (!Number.isFinite(value)) {
          methods.setError(`config.sources.${rowIdx}.data.${idx}`, {
            type: 'required',
            message: `#${Number(idx) + 1}. ${intl.t('form.errors.fieldRequired')}`,
          })
          isError = true
        }
      }

      for (const [ idx, value ] of Object.entries(data)) {
        if (Number.isFinite(value)) {
          const prev = data[Number(idx) - 1]
          const next = data[Number(idx) + 1]

          if (Number.isFinite(prev) && value < prev) {
            methods.setError(`config.sources.${rowIdx}.data.${idx}`, {
              type: 'required',
              message: `#${Number(idx) + 1}. ${intl.t('form.errors.smallerThanPrevious')}`,
            })
            isError = true
          }

          if (Number.isFinite(next) && value > next) {
            methods.setError(`config.sources.${rowIdx}.data.${idx}`, {
              type: 'required',
              message: `#${Number(idx) + 1}. ${intl.t('form.errors.biggerThanNext')}`,
            })
            isError = true
          }
        }
      }

      return isError
    })

    return !hasError
  }, [ intl ])

  const handleSubmit = useCallback((formData, methods) => {
    const sources = formData.config?.sources ?? []
    const nextData = {
      widget: formData.widget,
      config: {
        ...formData.config,
        sources,
      },
    }

    if (!sourcesValidation(sources, methods)) {
      throw new Error('error')
    }

    onSubmit?.(nextData)
  }, [ onSubmit, sourcesValidation ])

  return (
    <FormDialog
      onSubmit={handleSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="widgetBulletPlotForm"
      dialog={dialog}
    >
      <WidgetSection
        scope="widget"
      />

      <Section
        scope="config"
        title={intl.t(widget.info.name)}
        description={intl.t('widgets.bulletPlot.titleDescr')}
        sourcesValidation={sourcesValidation}
      />
    </FormDialog>
  )
}
