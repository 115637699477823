import { Controller, useFormContext } from 'react-hook-form'
import { get } from '@wiz/utils'
import {
  FormField,
  FormFieldInline,
  FormControl,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import SelectLocation from '@/components/Form/SelectLocation'
import SelectIcon from '@/containers/Form/SelectIcon'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('twinGraph.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (!str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value.length > 200) {
                return intl.t('form.errors.fieldMaxlen', { max: 200 })
              }
              return true
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.description')}
        description={intl.t('twinGraph.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.description`}
          rules={{
            maxLength: {
              value: 450,
              message: intl.t('form.errors.fieldMaxlen', { max: 450 }),
            },
          }}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.color')}
        description={intl.t('twinGraph.form.fields.colorDescr')}
        errors={get(errors, `${scope}.color`)}
      >
        <FormControl
          type="color"
          clearable
          name={`${scope}.color`}
        />
      </FormFieldInline>

      <FormField
        label={intl.t('twinGraph.form.fields.image')}
        description={intl.t('twinGraph.form.fields.imageDescr')}
        errors={get(errors, `${scope}.image`)}
      >
        <FormControl
          name={`${scope}.image`}
          rules={{
            maxLength: {
              value: 450,
              message: intl.t('form.errors.fieldMaxlen', { max: 450 }),
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.icon')}
        description={intl.t('twinGraph.form.fields.iconDescr')}
        errors={get(errors, `${scope}.icon`)}
      >
        <Controller
          name={`${scope}.icon`}
          render={({ field, fieldState }) => (
            <SelectIcon
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.location')}
        description={intl.t('twinGraph.form.fields.locationDescr')}
      >
        <SelectLocation scope={scope} />
      </FormField>
    </>
  )
}
