import EventEmitter from 'eventemitter3'
import immediate from 'setimmediate2'

class AppEventEmitter extends EventEmitter {
  constructor () {
    super()
    this.lock = false
    this.queue = []
  }

  hasCallback (name) {

  }

  emit (name, ...data) {
    if (name && name.type && !data.length) {
      data = [ name ]
      name = name.type
    }

    this.queue.push([ name, data ])
    if (!this.lock) {
      this.lock = true
      immediate.setImmediate(() => this.runQueue())
    }
  }

  runQueue () {
    const tasks = this.queue
    this.queue = []

    for (let i = 0, l = tasks.length; i < l; i += 1) {
      const task = tasks[i]
      super.emit(task[0], ...task[1])
    }

    if (this.queue.length) {
      immediate.setImmediate(() => this.runQueue())
    }

    this.lock = false
  }
}

export default new AppEventEmitter()
