import { useRef, useCallback, useContext } from 'react'
import { Icon, DropdownMenu } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { consts } from '@wiz/utils'
import { MENU_ALIGMENTS } from '@/config'
import { useAuth } from '@/auth'
import TwinGraphExecute from '@/context/TwinGraphExecute'
import TwinComponentOptions from './TwinComponentOptions'

export default function GraphFiltersMobile ({
  className,
  twinSettings,
  mapView,
  hideGrid,
  twinGraph,
  linkMode,
  setLinkMode,
  onToggleGrid,
  onToggleMapView,
  onEditTwinGraph,
  onDuplicateTwinGraph,
  onDiagramAlign,
  onDiagramFit,
  onDiagramReshapeLinks,
  onClickCreate,
}) {
  const refMenu = useRef()
  const auth = useAuth()
  const intl = useIntl()
  const executeContext = useContext(TwinGraphExecute)
  const canUpdate = auth.checkAccessUpdate(twinGraph)

  const canCreateTwin = (
    auth.checkAccessCreate('DiagramBlock') &&
    auth.checkAccessCreate('Twin')
  )

  const Alignments = MENU_ALIGMENTS.filter(item => item.toolbar)
    .map(item => ({
      label: intl.t(item.label),
      icon: item.icon,
      action: 'alignment',
      params: item.options,
      disabled: mapView || !canUpdate,
    }))

  const options = [
    ...(canUpdate ? [
      {
        label: intl.t('twinGraph.form.actions.editTitle'),
        icon: 'fa--edit',
        action: 'edit',
      },
    ] : []),
    ...(auth.checkAccessCopy(twinGraph) ? [
      {
        label: intl.t('form.actions.duplicate'),
        icon: 'fa--clone',
        action: 'clone',
      },
    ] : []),
    ...(canUpdate ? [
      {
        label: intl.t('twinGraph.form.actions.addBlockTitle'),
        icon: 'fa--shapes',
        options: [
          {
            label: intl.t('twinGraph.menuMachines'),
            icon: 'machines',
            ComponentOptions: TwinComponentOptions,
            disabled: !canCreateTwin,
            action: 'clickCreate',
          },
          {
            type: consts.DiagramBlockType.Area,
            color: twinSettings.twinColorAreas,
            label: intl.t('enum.twinType.area'),
            icon: 'fa--warehouse',
            disabled: !canCreateTwin,
            action: 'clickCreate',
          },
          {
            type: consts.DiagramBlockType.Machine,
            color: twinSettings.twinColorMachines,
            label: intl.t('enum.twinType.machine'),
            icon: 'fa--cogs',
            disabled: !canCreateTwin,
            action: 'clickCreate',
          },
          {
            type: consts.DiagramBlockType.Equipment,
            color: twinSettings.twinColorEquipment,
            label: intl.t('enum.twinType.equipment'),
            icon: 'fa--tools',
            disabled: !canCreateTwin,
            action: 'clickCreate',
          },
          {
            type: consts.DiagramBlockType.NestedTwinGraph,
            label: intl.t('enum.diagramBlock.nestedTwinGraph'),
            icon: 'flow',
            action: 'clickCreate',
          },
          {
            type: consts.DiagramBlockType.Widget,
            label: intl.t('enum.diagramBlock.widget'),
            icon: 'fa--th-large',
            action: 'clickCreate',
          },
        ],
      },
    ] : []),
    { divider: true },
    ...(canUpdate ? [
      {
        label: intl.t('twinGraph.form.actions.linkParentModeTitle'),
        icon: 'fa--sitemap',
        action: 'toggleLinkParentMode',
        active: linkMode === 'link-parent-mode',
      },
      {
        label: intl.t('twinGraph.form.actions.linkDescrModeTitle'),
        icon: 'fa--project-diagram',
        action: 'toggleLinkDescrMode',
        active: linkMode === 'link-descr-mode',
      },
      { divider: true },
      {
        label: intl.t('twinGraph.form.actions.toggleGridTitle'),
        icon: hideGrid ? 'fa--border-none' : 'fa--border-all',
        action: 'toggleGridTitle',
        disabled: !!mapView,
      },
      {
        label: intl.t('twinGraph.form.actions.toggleMapTitle'),
        icon: 'fa--map-marked',
        action: 'toggleMapTitle',
        active: !!mapView,
      },
      {
        label: intl.t('chart.form.actions.fitPosition'),
        icon: 'fa--compress-arrows-alt',
        action: 'fitPosition',
      },
      {
        label: intl.t('chart.form.actions.reshapeLinks'),
        icon: 'fa--paint-roller',
        action: 'reshapeLinks',
      },
      {
        label: intl.t('twinGraph.form.fields.includeChildren'),
        icon: executeContext.includeChildTwinEvents ? 'fa--calendar-plus' : 'fa--calendar-minus',
        action: 'includeChildren',
        active: executeContext.includeChildTwinEvents,
      },
      { divider: true },
      {
        label: intl.t('chart.contextMenu.alignment'),
        icon: 'fa--align-right',
        options: Alignments,
        disabled: !!mapView,
      },
    ] : []),
  ]

  const handleClick = useCallback((data) => {
    switch (data.action) {
      case 'edit':
        onEditTwinGraph()
        break
      case 'clone':
        onDuplicateTwinGraph()
        break
      case 'toggleLinkParentMode':
        setLinkMode(linkMode === 'link-parent-mode' ? null : 'link-parent-mode')
        break
      case 'toggleLinkDescrMode':
        setLinkMode(linkMode === 'link-descr-mode' ? null : 'link-descr-mode')
        break
      case 'toggleMapTitle':
        onToggleMapView()
        break
      case 'toggleGridTitle':
        onToggleGrid()
        break
      case 'alignment':
        onDiagramAlign(data.params)
        break
      case 'fitPosition':
        onDiagramFit()
        break
      case 'reshapeLinks':
        onDiagramReshapeLinks()
        break
      case 'includeChildren':
        executeContext.toggleIncludeChildTwinEvents()
        break
      case 'clickCreate':
        onClickCreate(data)
        break
      default:
    }
  }, [
    executeContext,
    linkMode,
    setLinkMode,
    onToggleGrid,
    onEditTwinGraph,
    onToggleMapView,
    onDuplicateTwinGraph,
    onDiagramAlign,
    onDiagramFit,
    onDiagramReshapeLinks,
    onClickCreate,
  ])

  return (
    <div className={className}>
      <button
        ref={refMenu}
        type="button"
        className="btn btn-flat-secondary btn-text"
      >
        <Icon name="fa--ellipsis-h" />
      </button>

      <DropdownMenu
        target={refMenu}
        options={options}
        onClick={handleClick}
        autoclose
        arrow
      />
    </div>
  )
}
