import classnames from 'classnames'
import { Icon, OverflowCenter } from '@wiz/components'
import { useIntl } from '@wiz/intl'

export default function Option ({ data, index, style, isScrolling }) {
  const {
    value,
    options,
    search,
    onChange,
  } = data

  const item = options[ index ]

  return (
    <div
      style={style}
      className={classnames('list-group-item list-group-item-action d-flex align-items-center text-nowrap pointer', {
        active: !!(value?.includes?.(item.id)),
        'pe-none': isScrolling,
      })}
      onClick={() => onChange?.(item.id)}
    >
      <Icon name="fa--circle" size="xs" className="me-2" color={item.color} />
      <OverflowCenter value={item.name} mark={search} />
    </div>
  )
}
