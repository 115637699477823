/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'

export default function ViewConnectionModel ({
  title,
  connection,
  onClose,
}) {
  const refDialog = useRef()
  const intl = useIntl()

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('edge.connections.form.fields.clientId'),
          value: connection.clientId,
        },
        {
          title: intl.t('edge.connections.form.fields.status'),
          value: connection.status || '-',
        },
        {
          title: intl.t('edge.connections.form.fields.lastConnectedTime'),
          value: () => <UpdatedInfo date={connection.lastConnectedTime} />,
        },
        {
          title: intl.t('edge.connections.form.fields.lastDisconnectedTime'),
          value: () => <UpdatedInfo date={connection.lastDisconnectedTime} />,
        },
      ],
    },
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      onClose={onClose}
    />
  )
}
