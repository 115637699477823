import { useMemo } from 'react'
import { Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { duration } from '@wiz/utils'
import Link from '@/components/Link'

export default function CellTrigger ({
  id,
  list,
}) {
  const intl = useIntl()

  const trigger = useMemo(() => {
    if (id && list?.data?.length) {
      const next = list.data.find(item => item.id === id)
      if (next) {
        const interval = duration(next.interval, { sep: '' })
        const delay = duration(next.delay, { sep: '' })

        return { ...next, name: `${interval || '0s'} - ${delay || '0s'}` }
      }
      return undefined
    }
    return undefined
  }, [ id, list ])

  if (list?.isLoading) {
    return (
      <Icon
        name="fa--spinner"
        spin
      />
    )
  }

  return id ? (
    <div className="min-w-0 d-flex">
      {trigger ? (
        <Link
          className="min-w-0 text-truncate"
          title={trigger?.name}
          name="triggers-list"
          // query={{ tree: trigger?.id }}
          // params={{ id: trigger?.id }}
        >
          {trigger.name}
        </Link>
      ) : '-'}

    </div>
  ) : '-'
}
