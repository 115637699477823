import { useState, useCallback } from 'react'
import { Icon, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import FormEvent from '@/containers/Forms/Event'
import FormEventBulkEdit from '@/containers/Forms/EventBulkEdit'
import Table from '@/containers/Event/Table'
import TableMobile from '@/containers/Event/TableMobile'
import ViewModel from './ViewModel'
import Filters from './Filters'

export default function List ({
  selected,
  filters,
  forceUpdate,
  onFilter,
  onResetFilter,
  onRemove,
  onSelect,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const isMobile = useMobile()
  const [ viewData, setViewData ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const [ editId, setEditId ] = useState(null)
  const [ bulkEdit, setBulkEdit ] = useState(false)

  const handleCloseDialog = useCallback(() => {
    setEditId(null)
    setBulkEdit(false)
  }, [])

  const handleRemoveGroup = useCallback(async () => {
    await window.wizConfirm({ message: 't/units.confirmDelete' })
    setLoading(true)
    await onRemove(selected.map(item => item.id))
    onSelect([])
    setLoading(false)
    forceUpdate()
  }, [ selected, onRemove, forceUpdate, onSelect ])

  const handleRemove = useCallback(async (ids) => {
    await window.wizConfirm({ message: 't/units.confirmDelete' })
    await onRemove(ids)
    onSelect(selected.filter(item => !ids.includes(item.id)))
    forceUpdate()
  }, [ selected, onRemove, forceUpdate, onSelect ])

  const handleSuccessBulkEdit = useCallback((events) => {
    onSelect(events)
    forceUpdate()
  }, [ forceUpdate, onSelect ])

  const TableComponent = isMobile ? TableMobile : Table

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">

      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <div className="d-flex align-items-start flex-wrap">
          <div className="px-sm-2 mb-2">
            {auth.checkAccessCreate('Event') ? (
              <button
                type="button"
                className="btn btn-flat-secondary"
                onClick={() => setEditId('')}
              >
                <Icon name="fa--plus" className="me-1" />
                {intl.t('form.actions.add')}
              </button>
            ) : null}

            {auth.checkAccessUpdate('Event') ? (
              <button
                type="button"
                className="btn btn-flat-secondary"
                disabled={!selected.length}
                onClick={() => setBulkEdit(true)}
              >
                <Icon name="fa--edit" className="me-sm-1" />
                <span className="d-none d-sm-inline">{intl.t('form.actions.bulkEdit')}</span>
              </button>
            ) : null}

            {auth.checkAccessRemove('Event') ? (
              <button
                type="button"
                className="btn btn-flat-secondary"
                disabled={loading || !selected.length}
                onClick={handleRemoveGroup}
              >
                <Icon
                  name={loading ? 'fa--spinner' : 'fa--trash-alt'}
                  spin={loading}
                />
                <span className="d-none d-sm-inline ms-1">
                  {intl.t('form.actions.remove')}
                </span>
              </button>
            ) : null}

            <button
              type="button"
              className="btn btn-flat-secondary"
              onClick={forceUpdate}
            >
              {intl.t('form.actions.refresh')}
            </button>
          </div>

          <Filters
            filters={filters}
            onFilter={onFilter}
            onResetFilter={onResetFilter}
          />
        </div>

        <TableComponent
          {...props}
          filters={filters}
          onFilter={onFilter}
          onSelect={onSelect}
          onEdit={setEditId}
          onRemove={handleRemove}
          onView={setViewData}
        />
      </div>

      {bulkEdit ? (
        <FormEventBulkEdit
          events={selected}
          onSuccess={handleSuccessBulkEdit}
          onClose={handleCloseDialog}
          dialog={{
            title: intl.t('events.titleBulkUpdate'),
            dataTestid: 'eventBulkEditDialog',
          }}
        />
      ) : null}

      {editId !== null ? (
        <FormEvent
          id={editId}
          defaultValues={{ sourceType: 'manual' }}
          onSuccess={forceUpdate}
          onClose={handleCloseDialog}
          dialog={{
            title: editId ?
              intl.t('events.titleUpdate') :
              intl.t('events.titleCreate'),
            dataTestid: 'replaceEventDialog',
          }}
        />
      ) : null}

      {viewData ? (
        <ViewModel
          event={viewData}
          title={intl.t('events.titleDetail')}
          onClose={() => setViewData(null)}
          onFilter={onFilter}
          onEdit={setEditId}
          onRemove={handleRemove}
          dialog
        />
      ) : null}
    </div>
  )
}
