import { OverflowCenter } from '@wiz/components'
import Observe from '@/containers/Observe'
import { useIntl } from '@wiz/intl'
import ButtonCopy from '@/components/Form/ButtonCopy'

const ExtraInfo = ({ data }) => {
  if (!data) {
    return null
  }
  const value = data.symbol || data.name
  return (!value || value === 'Other') ? null : (
    <span className="ps-1 extra-separate" title={data.name}>{value}</span>
  )
}

export default function Sensor ({ data, index, style }) {
  const intl = useIntl()
  const {
    options,
    value,
    search,
    onChange,
  } = data
  const item = options[index]

  return (
    <label
      style={style}
      className="hover-toggle list-group-item text-nowrap fw-light"
      htmlFor={item.id}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center min-w-0">
          <input
            className="form-check-input flex-shrink-0 m-0 me-2"
            type="checkbox"
            id={item.id}
            checked={value.includes(item.id)}
            onChange={() => onChange?.(item.id)}
          />

          <OverflowCenter
            value={item.displayName}
            mark={search}
          />

          {item.hardwareId ? (
            <ButtonCopy
              className="hover-inline-flex"
              value={item.hardwareId}
              title={intl.t('message.copy.hardwareId', { value: item.hardwareId })}
            />
          ) : null}
        </div>

        <div className="d-flex ms-1 text-info">
          {item.category ? (
            <Observe query={item.category}>
              {props => <ExtraInfo {...props} />}
            </Observe>
          ) : null}

          {item.unit ? (
            <Observe query={item.unit}>
              {props => <ExtraInfo {...props} />}
            </Observe>
          ) : null}
        </div>
      </div>

      <div className="d-flex">
        {item.description ? (
          <span className="me-1 text-secondary text-truncate">
            {item.description}
          </span>
        ) : null}
      </div>
    </label>
  )
}
