import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Icon, FormInputSearch } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import ListSensorsByBlock from '@/containers/StreamJob/RightBar/ListSensorsByBlock'
import BulkSensorOperations from '@/containers/Form/BulkSensorOperations'

export default function Info ({
  blockSettings,
  onChange,
}) {
  const intl = useIntl()
  const auth = useAuth()
  const [ search, setSearch ] = useState('')
  const [ sensorIds, setSensorIds ] = useState([])

  const handleChangeSelection = useCallback((sensorId) => {
    if (sensorIds.includes(sensorId)) {
      setSensorIds(sensorIds.filter(item => item !== sensorId))
    } else {
      setSensorIds(sensorIds.concat(sensorId))
    }
  }, [ sensorIds ])

  const handleBeforeAction = useCallback((action) => {
    if (action === 'unlink') {
      setSensorIds([])
    }
  }, [])

  return (
    <>
      <div className="d-flex flex-wrap mt-2 mx-n1 px-3">
        <div className="mx-1 mb-2 flex-fill">
          <FormInputSearch
            size="sm"
            placeholder={intl.t('form.actions.searchPlaceholder')}
            onChange={setSearch}
          />
        </div>

        <div className="d-flex mx-1 mb-2">
          <BulkSensorOperations
            className="btn-sm btn-fill-primary me-2"
            sensorIds={sensorIds}
            exclude={[ 'unlink' ]}
            onBeforeAction={handleBeforeAction}
          />

          {auth.checkAccessRead('SectionDataExplorer') ? (
            <button
              type="button"
              className="btn btn-sm btn-fill-secondary text-nowrap"
              disabled={!sensorIds.length}
              onClick={() => {
                onChange('explorer', {
                  title: intl.t('sensors.titleLinked'),
                  props: {
                    sensorIds,
                  },
                })
              }}
            >
              <Icon name="fa--search" className="me-1" />
              {intl.t('form.actions.exploreData')}
            </button>
          ) : null}
        </div>
      </div>

      <ListSensorsByBlock
        className="d-flex flex-fill"
        blockSettings={blockSettings}
        search={search}
        value={sensorIds}
        onChange={handleChangeSelection}
      />
    </>
  )
}

Info.propTypes = {
  onChange: PropTypes.func.isRequired,
  blockSettings: PropTypes.object,
}
