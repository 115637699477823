import { useCallback, memo } from 'react'
import { dbProvider, Dashboard } from '@wiz/store'
import { withProps } from '@wiz/components'
import { useAuth } from '@/auth'
import Component from '@/components/Forms/Dashboard'
import { contextTwin } from '@/utils/contextTwin'
import enhanceDashboard from './enhanceDashboard'
import enhanceDashboardData from './enhanceDashboardData'

const enhanceProps = withProps(() => {
  const auth = useAuth()
  const onSubmit = useCallback(async (data) => {
    let context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareReplaceData(context, Dashboard, data.dashboard)
    if (auth.checkAccessRead('Project')) {
      await model.prepareReplaceProjects(context, data.projects)
    }
    if (auth.checkAccessRead('Twin')) {
      const ctwin = contextTwin.getContextTwin(true)
      const twins = data.twins?.length ? data.twins : ctwin ? [ ctwin ] : []
      await model.prepareReplaceTwins(context, twins)
    }
    await dbProvider.batch(context)

    if (auth.checkAccessShare(model)) {
      context = dbProvider.createBatchContext()
      await model.prepareReplaceShareUsers(context, data.permissions)
      await dbProvider.batch(context)
    }
    return model
  }, [ auth ])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceDashboard(
    enhanceDashboardData(
      enhanceProps(Component),
    ),
  ),
)
