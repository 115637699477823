import {
  FormSwitch,
  FormInputSearch,
  FormSelectNative,
  FormSelectDateTimeRange,
} from '@wiz/components'
import { consts, debounce } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import FormatDateTime from '@/containers/FormatDateTime'
import SelectTwin from '@/components/Form/SelectTwin'
import Select from '@/components/Form/Select'
import SelectSensor from '@/components/Form/SelectSensor'
import StreamJobList from '@/hoc/StreamJobList'
import BusinessLabelList from '@/hoc/BusinessLabelList'
import ListFilters from '@/components/Form/ListFilters'

export default function Filters ({
  filters,
  onFilter,
  onResetFilter,
}) {
  const auth = useAuth()
  const intl = useIntl()

  return (
    <ListFilters
      filters={filters}
      onFilter={onFilter}
      onResetFilter={onResetFilter}
    >
      {({ filters, onFilter }) => (
        <>
          <div className="px-2 mb-2 col-md-3">
            <FormInputSearch
              placeholder={intl.t('form.actions.searchPlaceholder')}
              value={filters.search}
              onChange={debounce(value => onFilter({ search: value }), 500)}
            />
          </div>

          <div className="px-2 mb-2 col-md-auto">
            <FormSelectDateTimeRange
              className="w-auto"
              placeholderFrom="From"
              placeholderTo="To"
              value={[ filters.from, filters.to ]}
              DateTimeComponent={FormatDateTime}
              onChange={([ dateFrom, dateTo ]) => onFilter({ from: dateFrom, to: dateTo })}
            />
          </div>

          <div className="px-2 mb-2 col-md-3 col-lg-2">
            <FormSelectNative
              placeholder={intl.t('events.form.fields.typePlaceholder')}
              options={consts.EventTypes.map(id => ({ id, name: intl.t(`enum.eventType.${id}`) }))}
              value={filters.eventTypes || ''}
              onChange={value => onFilter({ eventTypes: value })}
            />
          </div>

          <div className="px-2 mb-2 col-md-3 col-lg-2">
            <FormSelectNative
              placeholder={intl.t('events.form.fields.statusPlaceholder')}
              options={consts.EventStatuses.map(id => ({ id, name: intl.t(`enum.eventStatus.${id}`) }))}
              value={filters.statuses || ''}
              onChange={value => onFilter({ statuses: value })}
            />
          </div>

          <div className="px-2 mb-2 col-md-3 col-lg-2">
            <FormSelectNative
              placeholder={intl.t('events.form.fields.sourcePlaceholder')}
              options={consts.EventSources.map(id => ({ id, name: intl.t(`enum.eventSource.${id}`) }))}
              value={filters.sourceTypes || ''}
              onChange={value => onFilter({ sourceTypes: value })}
            />
          </div>

          {auth.checkAccessRead('Twin') ? (
            <div className="px-2 mb-2 col-md-3 col-lg-2">
              <SelectTwin
                placeholder={intl.t('form.fields.twinPlaceholder')}
                value={filters.twinIds}
                displayCount={1}
                multiselect
                filters={{ withConnections: true }}
                onChange={twinIds => onFilter({ twinIds })}
              />

              <FormSwitch
                className="m-0"
                value={filters.includeChildEvents}
                label={intl.t('events.form.fields.includeChildren')}
                onChange={value => onFilter({ includeChildEvents: value })}
              />
            </div>
          ) : null}

          {auth.checkAccessRead('StreamJob') ? (
            <div className="px-2 mb-2 col-md-3 col-lg-2">
              <Select
                placeholder={intl.t('events.form.fields.streamJobPlaceholder')}
                value={filters.streamJobId}
                ListSource={StreamJobList}
                onChange={streamJobId => onFilter({ streamJobId })}
              />
            </div>
          ) : null}

          {auth.checkAccessRead('Sensor') ? (
            <div className="px-2 mb-2 col-md-3 col-lg-2">
              <SelectSensor
                placeholder={intl.t('form.fields.sensorsPlaceholder')}
                value={filters.sensorIds}
                displayCount={1}
                multiselect
                onChange={sensorIds => onFilter({ sensorIds })}
              />
            </div>
          ) : null}

          {auth.checkAccessRead('BusinessLabel') ? (
            <div className="px-2 mb-2 col-md-3 col-lg-2">
              <Select
                placeholder={intl.t('events.form.fields.businessLabelPlaceholder')}
                value={filters.businessLabelId}
                ListSource={BusinessLabelList}
                onChange={businessLabelId => onFilter({ businessLabelId })}
              />
            </div>
          ) : null}
        </>
      )}
    </ListFilters>
  )
}
