import { useState } from 'react'
import { Icon, FormInputSearch, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import BusinessLabel from '@/hoc/BusinessLabel'
import FormBusinessLabel from '@/containers/Forms/BusinessLabel'
import Table from '@/containers/BusinessLabel/Table'
import TableMobile from '@/containers/BusinessLabel/TableMobile'
import useAppContext from '@/hooks/useAppContext'
import ViewModel from './ViewModel'

const ViewModelData = BusinessLabel(ViewModel)

export default function List ({
  filters,
  selected,
  onFilter,
  onResetFilter,
  onRemove,
  ...props
}) {
  const [ viewId, setViewId ] = useState(null)
  const [ editId, setEditId ] = useState(null)

  const { onRowSelect } = useAppContext()

  const intl = useIntl()
  const auth = useAuth()
  const isMobile = useMobile()

  const TableComponent = isMobile ? TableMobile : Table

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">
      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <TableComponent
          {...props}
          filters={filters}
          onFilter={onFilter}
          onRemove={onRemove}
          onEdit={setEditId}
          onView={setViewId}
          onSelect={onRowSelect}
        />
      </div>

      {editId !== null ? (
        <FormBusinessLabel
          id={editId}
          onClose={() => setEditId(null)}
          dialog={{
            title: editId ? intl.t('businessLabels.titleUpdate') : intl.t('businessLabels.titleCreate'),
            dataTestid: 'replaceBusinessLabelDialog',
          }}
        />
      ) : null}

      {viewId ? (
        <ViewModelData
          id={viewId}
          title={intl.t('businessLabels.titleDetail')}
          onClose={() => setViewId(null)}
          onRemove={onRemove}
          onEdit={setEditId}
          dialog
        />
      ) : null}
    </div>
  )
}
