import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import FormDialog from '@/components/Form/FormDialog'
import { Icon } from '@wiz/components'
import { wizataApi } from '@/api'
import Removed from '../Removed'
import Section from './Section'

export default function HistoricalRun ({
  id,
  dialog,
  onClose,
}) {
  const { data: item, isLoading } = useQuery({
    queryKey: [ 'execution', id ],
    queryFn: () => wizataApi.executions.getById(id),
    refetchOnWindowFocus: false,
    enabled: !!id,
    staleTime: null,
    retry: false,
  })

  const defaultValues$ = useMemo(() => ({
    execution: { ...item, user: {} },
  }), [ item ])

  if (isLoading) {
    return (
      <div className="position-absolute-fill position-center-fill bg-light opacity-50">
        <Icon name="fa--spinner" size="2X" spin />
      </div>
    )
  }

  if (id && !item) {
    return <Removed />
  }

  return (
    <FormDialog
      onClose={onClose}
      defaultValues={defaultValues$}
      dataTestid="executionForm"
      dialog={dialog}
    >
      <Section scope="execution" />
    </FormDialog>
  )
}
