import { FormInputSearch, FormSelectDateTimeRange } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { consts } from '@wiz/utils'
import Select from '@/components/Form/Select'
import FormatDateTime from '@/containers/FormatDateTime'
import UserList from '@/hoc/UserList'
import QualityDataTemplateList from '@/hoc/QualityDataTemplateList'
import ListFilters from '@/components/Form/ListFilters'

export default function Filters ({
  filters,
  onFilter,
  onResetFilter,
}) {
  const intl = useIntl()

  return (
    <ListFilters
      filters={filters}
      onFilter={onFilter}
      onResetFilter={onResetFilter}
    >
      {({ filters, onFilter }) => (
        <>
          <div className="px-2 mb-2 col-md-3 col-lg-4">
            <FormInputSearch
              placeholder={intl.t('form.actions.searchPlaceholder')}
              value={filters.search}
              onChange={search => onFilter({ search })}
            />
          </div>

          <div className="px-2 mb-2">
            <FormSelectDateTimeRange
              className="w-auto"
              placeholderFrom="From"
              placeholderTo="To"
              value={[ filters.dateFrom, filters.dateTo ]}
              DateTimeComponent={FormatDateTime}
              onChange={([ dateFrom, dateTo ]) => onFilter({ dateFrom, dateTo })}
              clearable
            />
          </div>

          <div className="px-2 mb-2 col-md-3 col-lg-2">
            <Select
              value={filters.approval}
              options={consts.QualityDataApprovals.map(id => ({
                id,
                name: intl.t(`enum.qualityApproval.${id}`),
              })).concat([{
                id: 'noneed',
                name: intl.t('enum.qualityApproval.noneed'),
              }])}
              placeholder={intl.t('quality.form.fields.approvalPlaceholder')}
              onChange={approval => onFilter({ approval })}
            />
          </div>

          <div className="px-2 mb-2 col-md-3 col-lg-2">
            <Select
              value={filters.tmpl}
              ListSource={QualityDataTemplateList}
              placeholder={intl.t('quality.form.fields.qualityTemplatePlaceholder')}
              onChange={tmpl => onFilter({ tmpl })}
            />
          </div>

          <div className="px-2 mb-2 col-md-3 col-lg-2">
            <Select
              value={filters.user}
              ListSource={UserList}
              placeholder={intl.t('quality.form.fields.createdUserPlaceholder')}
              onChange={user => onFilter({ user })}
            />
          </div>
        </>
      )}
    </ListFilters>
  )
}
