/* eslint-disable react/no-danger */
import { useIntl } from '@wiz/intl'

export default function SmsConsumption ({
  description,
  settings,
}) {
  const intl = useIntl()
  const sended = Number(settings.SentSmsCount || 0)
  const total = (
    Number(settings.FreeSmsLimit || 0) +
    (settings.PaidFunctionalityConfirm ? Number(settings.PaidSmsLimit || 0) : 0)
  )

  return (
    <div className="mb-3 mt-1 mx-2">
      <span
        dangerouslySetInnerHTML={{
          __html: intl.t('actions.form.fields.smsConsumption', {
            left: Math.max(total - sended, 0),
            total,
          }),
        }}
      />
      {description ? (
        <div className="mt-1 small text-muted">
          {description}
        </div>
      ) : null}
    </div>
  )
}
