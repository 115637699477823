import {
  useEffect, useMemo, useRef, useState,
} from 'react'
import cx from 'classnames'
import { auth } from '@/auth'
import { appEnv } from '@/config'
import { useTheme } from '@/theme'
import { Skeleton } from '@wiz/components'
import DashboardView from '@/containers/Dashboards/View'
import s from './index.module.css'
import GrafanaVariablesBar from './GrafanaVariablesBar'

const GRAFANA_BASE_URL = 'https://grafana-poc.onwizata.com/' // should be replaced by using /check
const DS_API_RECENT_URL = 'https://dev-wizard.onwizata.com'
const { hostname } = new URL(appEnv.DS_API_RECENT_URL || DS_API_RECENT_URL)
const themeByName = {
  sun: 'light',
  moon: 'dark',
}

const Grafana = ({
  content, formData, isEdit, check, dashboardId, type, variables = [],
}) => {
  const theme = useTheme()
  const _theme = themeByName[theme.name]
  const refBef = useRef()
  const [ grafanaVar, setGrafanaVar ] = useState({})
  const [ token, setToken ] = useState()

  const generateToken = async () => {
    const _token = await auth.acquireToken()
    setToken(_token)
  }

  useEffect(() => {
    generateToken()
  }, [])

  useEffect(() => {
    if (variables.length) {
      const defaultGrafanaVars = variables.reduce((acc, val) => {
        if (val?.type === 'select') {
          return val.defaults.reduce((acm, defVal) => {
            const propertyArr = Array.isArray(acm[val?.name]) ? [ ...acm[val?.name] ] : []
            return { ...acm, [val?.name]: [ ...propertyArr, `&var-${val?.name}=${defVal}`.replace(/\s/g, '%20') ] }
          }, acc)
        }
        const urlParam = `&var-${val?.name}=${val?.defaults?.[0]}`.replace(/\s/g, '%20')
        return { ...acc, [val?.name]: urlParam }
      }, {})
      setGrafanaVar(defaultGrafanaVars)
    }
  }, [ variables ])

  const grafanaURL = useMemo(() => {
    let url = ''

    if (content) {
      url += (check?.data || GRAFANA_BASE_URL) + content
    }
    url += '?'
    if (!isEdit) {
      url += 'kiosk&'
    }

    return url
  }, [ content, isEdit, check?.data ])

  const streamlitURL = useMemo(() => {
    let url = ''

    if (content) {
      url += content
    }
    url += `?dsapi=${hostname}&auth_token=${token}`

    return url
  }, [ content, token ])

  const varParams = variables.length ? Object.values(grafanaVar).join('').replace(/,/g, '') : ''
  const datesExtension = useMemo(() => {
    if (formData.dateFrom && formData.dateTo) {
      return `&from=${formData.dateFrom || 'now-24h'}&to=${formData.dateTo || 'now'}`
    }
    return ''
  }, [ formData ])

  if (type === 'Dashboard') {
    return (
      <div className={cx('d-flex position-relative', s.wrap)}>
        <div ref={refBef} className="d-flex flex-column w-100">
          <DashboardView
            match={{ params: { id: dashboardId } }}
            embed
            embedWidth={refBef.current?.offsetWidth}
            rightbar={isEdit}
            isEditable={isEdit}
          />
        </div>
      </div>
    )
  }

  if (type === 'Iframe') {
    return (
      <iframe
        title="iframe component"
        className={s.twin}
        src={content}
        frameBorder="0"
      />
    )
  }

  if (type === 'Streamlit') {
    return (
      <iframe
        title="iframe component"
        className={s.twin}
        src={streamlitURL}
        frameBorder="0"
      />
    )
  }

  return (check?.isLoading && check?.isFetching) ? <Skeleton width="100%" height="100%" /> : (
    <>
      {variables.length && !isEdit ? (
        <GrafanaVariablesBar
          variables={variables}
          grafanaVar={grafanaVar}
          updateParams={setGrafanaVar}
        />
      ) :
        null}
      <iframe
        title="grafana"
        className={s.twin}
        src={`${grafanaURL}theme=${_theme}${varParams}${datesExtension}`}
        frameBorder="0"
      />
    </>
  )
}

export default Grafana
