import { useFormContext, Controller } from 'react-hook-form'
import { get, consts } from '@wiz/utils'
import {
  FormField,
  FormFieldInline,
  FormControl,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import EdgeDeviceList from '@/hoc/EdgeDeviceList'
import Select from '@/components/Form/Select'
import FormTemplates from '@/components/Form/ListTemplates'

export default function Fields ({ scope, templateId }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('form.fields.type')}
        description={intl.t('streamJobs.form.fields.typeDescr')}
        errors={get(errors, `${scope}.type`)}
      >
        <FormControl
          type="select"
          name={`${scope}.type`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          defaultValue={consts.StreamJobType.MLPipeline}
          placeholder="--"
          options={consts.StreamJobTypes.map(id => ({
            id,
            name: intl.t(`enum.streamJobs.${id}`),
          }))}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('streamJobs.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (!str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value.length > 200) {
                return intl.t('form.errors.fieldMaxlen', { max: 200 })
              }
              return true
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.description')}
        description={intl.t('streamJobs.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.description`}
          rules={{
            maxLength: {
              value: 450,
              message: intl.t('form.errors.fieldMaxlen', { max: 450 }),
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.device')}
        description={intl.t('streamJobs.form.fields.deviceDescr')}
        errors={get(errors, `${scope}.deviceId`)}
      >
        <Controller
          name={`${scope}.deviceId`}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.devicePlaceholder')}
              ListSource={EdgeDeviceList}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.template')}
        description={intl.t('sensors.form.fields.templateDescr')}
        errors={get(errors, `${scope}.templateId`)}
      >
        <Controller
          name={`${scope}.templateId`}
          render={({ field, fieldState }) => (
            <FormTemplates
              {...field}
              invalid={fieldState.invalid}
              placeholder="--"
              disabled={!!templateId}
            />
          )}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.active')}
        description={intl.t('streamJobs.form.fields.activeDescr')}
        errors={get(errors, `${scope}.active`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.active`}
          className="ms-2"
        />
      </FormFieldInline>
    </>
  )
}
