import { useMemo } from 'react'
import classnames from 'classnames'
import { VirtualTable, Badge, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'

export default function TableModulesMobile ({
  items,
  onEdit,
  onView,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      Header: intl.t('edge.modules.form.fields.moduleId'),
      accessor: 'moduleId',
      Cell: ({ row }) => (
        <div className="min-w-0">
          <div className="d-flex align-items-center">
            {row.original.configStatus ? (
              <Badge
                className={classnames('me-2', {
                  'bg-secondary': (
                    row.original.configStatus !== 'synced' &&
                    row.original.configStatus !== 'failedToSync'
                  ),
                  'bg-success': row.original.configStatus === 'synced',
                  'bg-danger': row.original.configStatus === 'failedToSync',
                })}
              >
                {row.original.configStatus}
              </Badge>
            ) : null}
            <div className="text-truncate">
              {row.original.moduleId}
            </div>
          </div>
          <div className="small text-secondary text-truncate">
            {row.original.type}
          </div>
        </div>
      ),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 90,
      width: 90,
      maxWidth: 90,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell, row }) => (
        <>
          {(
            !(
              row.original.moduleId === '$edgeAgent' ||
              row.original.moduleId === '$edgeHub'
            ) &&
            auth.checkAccessUpdate(row.original)
          ) ? (
            <button
              type="button"
              className="btn btn-sm p-0"
              onClick={() => onEdit?.(cell.value)}
            >
              {intl.t('edge.modules.form.actions.config')}
            </button>
            ) : null}
          <button
            type="button"
            className="btn btn-sm p-2"
            onClick={() => onView?.(row.original)}
          >
            <Icon name="fa--chevron-right" />
          </button>
        </>
      ),
    },
  ], [ intl, onEdit, auth, onView ])

  return (
    <VirtualTable
      className="flex-fill"
      placeholder={intl.t('errors.noDataDisplay')}
      columns={columns}
      data={items}
      showHeader={false}
      selection={false}
      {...props}
    />
  )
}
