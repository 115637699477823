import { Sensor, Label } from '@wiz/store'
import Observe from '@/containers/Observe'
import ObserveModel from '@/containers/ObserveModel'

export default function Detail ({ row }) {
  const item = row.original

  return (
    <div>
      <Observe query={item.queryMeasurements}>
        {({ data: dataMeasurements }) => (
          <Observe query={item.qualityDataTemplate}>
            {({ data: qualityTemplate }) => (
              <Observe query={qualityTemplate.queryMeasurements}>
                {({ data: qualityTemplateMeasurements }) => (
                  <table className="table table-sm table-borderless">
                    <tbody>
                      {qualityTemplateMeasurements.map(measurement => (
                        <tr key={measurement.id}>
                          <th className="text-nowrap">
                            {measurement.sensorId ? (
                              <ObserveModel model={Sensor} value={measurement.sensorId}>
                                {({ data }) => (
                                  <>{data?.[0]?.displayName}</>
                                )}
                              </ObserveModel>
                            ) : null}
                            {measurement.labelId ? (
                              <ObserveModel model={Label} value ={measurement.labelId}>
                                {({ data }) => (
                                  <>{data?.[0]?.name}</>
                                )}
                              </ObserveModel>
                            ) : null}
                          </th>
                          <td className="w-100">
                            {dataMeasurements
                              .find(m => m.qualityDataTemplateMeasurementId === measurement.id)
                              ?.value ?? '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </Observe>
            )}
          </Observe>
        )}
      </Observe>

      <div className="px-2">
        {item.description}
      </div>
    </div>
  )
}
