import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { FormField, FormControl } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

const FieldMaxValue = ({ scope }) => {
  const intl = useIntl()
  const { formState: { errors }, clearErrors } = useFormContext()
  return (
    <FormField
      label={intl.t('widgets.histogram.form.fields.maxValue')}
      description={intl.t('widgets.histogram.form.fields.maxValueDescr')}
      errors={get(errors, `${scope}.maxValue`)}
    >
      <FormControl
        type="number"
        name={`${scope}.maxValue`}
        scale={6}
        placeholder={intl.t('form.fields.autoPlaceholder')}
        clearable
        onClear={() => clearErrors([
          `${scope}.minValue`,
          `${scope}.maxValue`,
        ])}
      />
    </FormField>
  )
}

FieldMaxValue.propTypes = {
  scope: PropTypes.string.isRequired,
}

export default FieldMaxValue
