import { useIntl } from '@wiz/intl'
import EdgeDeviceList from '@/hoc/EdgeDeviceList'
import Select from '@/components/Form/Select'
import { useContext } from 'react'
import { AppContext } from '@/pages/context'
import ListFilters from '@/components/Form/ListFilters'

export default function Filters () {
  const { filters, onFilter } = useContext(AppContext)

  const intl = useIntl()

  return (
    <ListFilters
      filters={filters}
      onFilter={onFilter}
    >
      {({ filters, onFilter }) => (
        <div className="me-1 mb-2">
          <Select
            placeholder={intl.t('form.fields.devicePlaceholder')}
            ListSource={EdgeDeviceList}
            displayCount={1}
            value={filters.devices}
            multiselect
            onChange={devices => onFilter({ devices })}
          />
        </div>
      )}
    </ListFilters>
  )
}
