import { useCallback } from 'react'
import { withProps } from '@wiz/components'
import { dbProvider } from '@wiz/store'

const enhanceProps = withProps(({ widget }) => {
  const onSubmit = useCallback(async (data) => {
    if (widget) {
      const context = dbProvider.createBatchContext()
      await widget.prepareUpdateData(context, { ...data.widget, config: data.config })
      await widget.prepareReplaceFilter(context, data.config.dataFilter)
      await widget.prepareReplaceDataViews(context, data.config.dataViews, data.config.dataSources)
      await dbProvider.batch(context)
    }
  }, [ widget ])

  return {
    onSubmit,
  }
})

export default function enhanceSettingsPlotly (Component) {
  return enhanceProps(Component)
}
