import { useCallback, memo } from 'react'
import { dbProvider, QualityDataTemplate } from '@wiz/store'
import { withProps } from '@wiz/components'
import { useAuth } from '@/auth'
import events from '@/utils/events'
import Component from '@/components/Forms/QualityDataTemplate'
import enhanceData from './enhanceData'
import enhanceExtraData from './enhanceExtraData'

const enhanceProps = withProps(() => {
  const auth = useAuth()
  const onSubmit = useCallback(async (data) => {
    try {
      const context = dbProvider.createBatchContext()
      const model = await dbProvider.prepareReplaceData(
        context,
        QualityDataTemplate,
        data.qualityDataTemplate,
      )
      await model.prepareReplaceMeasurements(context, data.measurements)
      if (auth.checkAccessRead('Project')) {
        await model.prepareReplaceProjects(context, data.projects)
      }
      if (auth.checkAccessRead('Twin')) {
        await model.prepareReplaceTwins(context, data.twins)
      }
      await dbProvider.batch(context)
    } catch (error) {
      events.emit('app:notify', {
        type: 'error',
        message: error.message,
      })
      throw error
    }
  }, [ auth ])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceData(
    enhanceExtraData(
      enhanceProps(Component),
    ),
  ),
)
