import { FormSection } from '@wiz/components'
import FieldsTwins from './FieldsTwins'

export default function SectionTwins ({
  scope,
  title,
  description,
  addon,
  ...props
}) {
  return (
    <FormSection
      title={title}
      description={description}
      addon={addon}
    >
      <FieldsTwins scope={scope} {...props} />
    </FormSection>
  )
}
