import { Icon, OverflowCenter } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import classnames from 'classnames'
import { useAuth } from '@/auth'
import ExplorerList from '@/hoc/ExplorerList'
import Select from '@/components/Form/Select'
import Link from '@/components/Link'

export default function Option ({
  data,
  isOpen,
  style,
  toggle,
  treeData,
}) {
  const intl = useIntl()
  const auth = useAuth()
  const {
    search,
    onSelect,
    onAttach,
    onCreate,
    onUnlink,
  } = treeData

  const item = data.payload

  const paddingLeft = `${data.nestingLevel + (data.isLeaf ? 1 : 0)}rem`

  return (
    <div
      style={{ ...style, paddingLeft }}
      className="d-flex align-items-center list-group-item list-group-item-action hover-toggle"
    >
      {data.isLeaf ? null : (
        <button
          type="button"
          className="btn p-0 mx-1 text-reset"
          onClick={toggle}
        >
          <Icon
            name="fa--caret-right"
            className={classnames('arrow', { 'arrow-open': isOpen })}
          />
        </button>
      )}

      {item.icon ? (
        <Icon
          className="me-2"
          size="lg"
          name={item.icon}
          color={item.color}
        />
      ) : null}

      <OverflowCenter
        value={data.name}
        mark={search}
        className={classnames({
          'pointer fw-light': !!item.explorerId,
        })}
        onClick={item.explorerId ? () => (
          onSelect(item.explorerId, item)
        ) : toggle}
      />

      {item.explorerId ? (
        <div className="ms-auto d-flex">
          {auth.checkAccessRead('SectionDataExplorer') ? (
            <Link
              name="data-observation"
              params={{ id: item.explorerId }}
              className="hover-opacity btn btn-flat-secondary p-2 ms-1"
            >
              <Icon name="fa--external-link-alt" />
            </Link>
          ) : null}
          {item.accessUpdateTwin ? (
            <button
              type="button"
              className="hover-opacity btn btn-flat-secondary p-2 ms-1"
              title={intl.t('form.actions.unlinkDashboard')}
              onClick={() => onUnlink(item)}
            >
              <Icon name="fa--unlink" />
            </button>
          ) : null}
        </div>
      ) : (
        <div className="ms-auto d-flex">
          {auth.checkAccessCreate('Explorer') ? (
            <button
              className="hover-opacity btn btn-flat-secondary p-2 ms-1"
              type="button"
              onClick={() => onCreate(item)}
            >
              <Icon name="fa--plus" />
            </button>
          ) : null}
          {item.accessUpdateTwin ? (
            <Select
              className="hover-opacity btn btn-flat-secondary p-2 ms-1"
              ListSource={ExplorerList}
              value={item.ids}
              multiselect
              initialStyle
              onChange={ids => onAttach(item, ids)}
            >
              <Icon name="fa--link" />
            </Select>
          ) : null}
        </div>
      )}
    </div>
  )
}
