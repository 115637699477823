import { of as of$ } from 'rxjs'
import { map, distinctUntilChanged } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { isEqual } from '@wiz/utils'

export default withObservables([
  'qualityDataTemplate',
  'defaultValues',
], ({
  qualityDataTemplate,
  defaultValues,
}) => ({
  projects: qualityDataTemplate?.queryRelProjects ? (
    qualityDataTemplate.queryRelProjects
      .observe()
      .pipe(
        map(items => items.map(item => item.projectId)),
      )
  ) : of$(defaultValues?.projects ?? []).pipe(distinctUntilChanged(isEqual)),

  twins: qualityDataTemplate?.queryRelTwins ? (
    qualityDataTemplate.queryRelTwins
      .observe()
      .pipe(
        map(items => items.map(item => item.twinId)),
      )
  ) : of$(defaultValues?.twins ?? []).pipe(distinctUntilChanged(isEqual)),

  measurements: qualityDataTemplate?.queryMeasurements ? (
    qualityDataTemplate.queryMeasurements
      .observe()
      .pipe(
        map(items => items.map(item => item.toJSON())),
      )
  ) : of$(defaultValues?.measurements ?? []).pipe(distinctUntilChanged(isEqual)),
}))
