import { useMemo } from 'react'
import { QualityDataTemplate as QualityDataTemplateModel } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import Section from './Section'
import SectionProjects from './SectionProjects'
import SectionTwins from './SectionTwins'
import SectionMeasurements from './SectionMeasurements'

export default function QualityDataTemplate ({
  id,
  qualityDataTemplate,
  projects,
  twins,
  measurements,
  dialog,
  onClose,
  onSubmit,
}) {
  const auth = useAuth()
  const intl = useIntl()
  const defaultValues = useMemo(() => ({
    qualityDataTemplate: QualityDataTemplateModel.toJSON(qualityDataTemplate),
    projects,
    twins,
    measurements,
  }), [
    qualityDataTemplate?.updatedAt ?? qualityDataTemplate,
    projects,
    twins,
    measurements,
  ])

  console.log(measurements)

  if (id && !qualityDataTemplate) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="qualityDataTemplateForm"
      dialog={dialog}
    >
      <Section
        scope="qualityDataTemplate"
      />

      <SectionMeasurements
        title={intl.t('form.fields.measurements')}
        description={intl.t('tmplQualityData.form.fields.measurementsDescr')}
        scope="measurements"
      />

      {auth.checkAccessRead('Twin') ? (
        <SectionTwins
          title={intl.t('form.fields.twins')}
          description={intl.t('tmplQualityData.form.fields.twinsDescr')}
          scope="twins"
        />
      ) : null}

      {auth.checkAccessRead('Project') ? (
        <SectionProjects
          title={intl.t('form.fields.projects')}
          description={intl.t('tmplQualityData.form.fields.projectsDescr')}
          scope="projects"
        />
      ) : null}
    </FormDialog>
  )
}
