import PropTypes from 'prop-types'
import classnames from 'classnames'
import { OverflowCenter, Badge, Icon } from '@wiz/components'
import classes from './index.css'

const BadgeList = ({
  displayCount,
  disabled,
  readOnly,
  options,
  placeholder,
  keyName = 'id',
  Content,
  onRemove,
  initialStyle,
  noRemove,
}) => {
  if (!options.length) {
    return (
      <span className="text-truncate">
        {placeholder || <>&nbsp;</>}
      </span>
    )
  }

  const displayOptions = displayCount ? options.slice(0, displayCount) : options
  const hiddenCount = displayCount ? options.length - displayCount : 0

  return (
    <span
      className={classnames('d-flex align-items-center min-w-0', {
        'flex-wrap': hiddenCount <= 0,
        [classes.values]: !initialStyle,
      })}
    >
      {displayOptions.map(item => (
        <Badge
          key={item[keyName]}
          className={classnames({
            [classes.value]: !initialStyle,
            'bg-secondary': !item.color && !initialStyle,
          })}
          color={item.color}
          disabled={disabled}
          initialStyle={initialStyle}
          onClose={!noRemove && onRemove && !readOnly && !disabled ? (event) => {
            event.preventDefault()
            event.stopPropagation()
            onRemove(item[keyName])
          } : undefined}
        >
          {Content ? (
            <Content data={item} />
          ) : (
            <>
              {item.icon ? <Icon name={item.icon} className="me-1" /> : null}
              <OverflowCenter value={item.name || item[keyName]} />
            </>
          )}
        </Badge>
      ))}

      {hiddenCount > 0 ? (
        <span className="small ms-1">
          +
          {hiddenCount}
        </span>
      ) : null}
    </span>
  )
}

BadgeList.propTypes = {
  displayCount: PropTypes.number,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  keyName: PropTypes.string,
  placeholder: PropTypes.string,
  Content: PropTypes.any,
  onRemove: PropTypes.func,
  initialStyle: PropTypes.bool,
}

BadgeList.defaultProps = {
  displayCount: undefined,
  disabled: false,
  keyName: 'id',
  options: [],
  placeholder: undefined,
  Content: undefined,
  onRemove: undefined,
  initialStyle: false,
}

BadgeList.displayName = 'BadgeList'
export default BadgeList
