import { useState } from 'react'
import { map } from 'rxjs/operators'
import {
  Form, FormSelect, FormControl, withObservables,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import {
  arrayOf, func, string,
} from 'prop-types'
import { dbProvider, Q } from '@wiz/store'

const OPERATOR_TYPES = [
  'lt',
  'gt',
]

const enhanceProps = withObservables([ 'sensors', 'sensorIds' ], ({ sensors, sensorIds }) => {
  if ((!sensors.length && sensorIds.length) || (sensorIds?.length !== sensors?.length)) {
    const query = dbProvider.database.collections.get('sensors').query(Q.where('id', Q.oneOf(sensorIds)))
    return {
      sensors: query.observeWithColumns([ 'updated_at' ]).pipe(map(items => items.map(item => item.hardwareId))),
    }
  }

  return {
    sensors,
  }
})

const Target = enhanceProps(({
  sensors, onSave, item, getError, optional, onOptional,
}) => {
  const [ show, setShow ] = useState(!!optional)
  const intl = useIntl()
  const error = getError('target')

  if (optional && show) {
    return (
      <div className="d-flex flex-wrap flex-fill mt-2 px-3">
        <div className="d-flex flex-fill flex-column justify-content-center align-items-center text-center">
          <div className="d-flex">
            {intl.t('experiments.wizard.target')}
          </div>
          <div className="my-2">
            <button
              type="button"
              className="btn btn-fill-secondary mx-1"
              onClick={() => setShow(false)}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary mx-1"
              onClick={onOptional}
            >
              No
            </button>
          </div>
        </div>

      </div>
    )
  }

  return (
    <div className="d-flex flex-fill mt-2 px-3 m-1 flex-column">
      <div className="d-flex flex-wrap justify-content-between">
        <b>{intl.t('experiments.form.fields.targetTitle')}</b>
      </div>

      <Form>
        <div>
          <div className="flex-fill my-2">{intl.t('experiments.form.fields.target')}</div>
          <FormSelect
            placeholder="-"
            options={sensors.map(id => ({ id, name: id }))}
            onChange={sensor => onSave({ sensor })}
            defaultValue={item?.sensor || ''}
          />
          {error?.sensor ? <div className="invalid-feedback d-block">{intl.t(error.sensor)}</div> : <div>&nbsp;</div>}
        </div>

        <div>
          <div className="flex-fill my-2">{intl.t('experiments.form.fields.operator')}</div>
          <FormSelect
            placeholder="-"
            options={OPERATOR_TYPES.map(id => ({ id, name: intl.t(`enum.conditionType.${id}`) }))}
            onChange={operator => onSave({ operator })}
            defaultValue={item?.operator || ''}
          />
          {error?.operator ? <div className="invalid-feedback d-block">{intl.t(error.operator)}</div> : <div>&nbsp;</div>}
        </div>

        <div>
          <div className="flex-fill my-2">{intl.t('experiments.form.fields.threshold')}</div>
          <FormControl
            type="number"
            name="value"
            scale={8}
            onChange={threshold => onSave({ threshold })}
            defaultValue={item?.threshold || ''}
          />
          {error?.threshold ? <div className="invalid-feedback d-block">{intl.t(error.threshold)}</div> : <div>&nbsp;</div>}
        </div>
      </Form>
    </div>
  )
})

export { Target }

Target.propTypes = {
  sensors: arrayOf(string),
  onSave: func.isRequired,
}

Target.defaultProps = {
  sensors: [],
}
