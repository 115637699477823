import { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormControl } from '@wiz/components'
import { dbProvider, IExplorer, DataSource } from '@wiz/store'
import { toArrayValue } from '@wiz/utils'
import SelectedDataView from '@/context/SelectedDataView'
import ConditionExplorer from '@/components/Form/ConditionExplorer'

export default function SectionConditions ({ scope }) {
  const { watch, setValue } = useFormContext()
  const [
    dataViews,
    conditions,
  ] = watch([
    `${scope}.dataViews`,
    `${scope}.conditions`,
  ])

  const selectedDataViews = useMemo(() => (
    dataViews
      .filter(item => (
        item.source
      ))
      .map(item => ({
        id: item.source.id,
        name: item.name || DataSource.getDisplayName(item.source),
        sensorId: item.source.sensorId,
        dataType: item.source.dataType,
        color: item.color,
        selected: true,
        params: item.source,
      }))
  ), [ dataViews ])

  const handleCreateConditions = useCallback(async (data) => {
    const condition = await IExplorer.createConditionContext(dbProvider.database, data)
    const next = conditions.concat(condition)
    setValue(`${scope}.conditions`, next, { shouldDirty: true })
  }, [ setValue, scope, conditions ])

  const handleRemoveConditions = useCallback((condition) => {
    const next = conditions
      .filter(item => item.id !== condition.id)
      .map(item => (item.groupId === condition.id ? { ...item, groupId: null } : item))
    setValue(`${scope}.conditions`, next, { shouldDirty: true })
  }, [ setValue, scope, conditions ])

  const handleUpdateConditions = useCallback(async (data) => {
    const nextConditions = toArrayValue(data)
      .reduce((out, item) => ({
        ...out,
        [item.id]: item,
      }), {})

    const next = []

    for (const item of conditions) {
      if (nextConditions[item.id]) {
        const nextCondition = nextConditions[item.id]
        delete nextConditions[item.id]
        const condition = await IExplorer.createConditionContext(
          dbProvider.database,
          { ...item, ...nextCondition },
        )
        next.push(condition)
      } else {
        next.push(item)
      }
    }

    for (const item of Object.values(nextConditions)) {
      const condition = await IExplorer.createConditionContext(dbProvider.database, item)
      next.push(condition)
    }

    setValue(`${scope}.conditions`, next, { shouldDirty: true })
  }, [ setValue, scope, conditions ])

  const handleDropConditions = useCallback(async (dropItem, targetItem, type) => {
    const items = []

    if (type === 'inner') {
      const isGroup = conditions.some(item => item.groupId === targetItem.id)
      let groupId = isGroup ? targetItem.id : targetItem.groupId

      if (!groupId) {
        const group = await IExplorer.createConditionContext(dbProvider.database)
        groupId = group.id
        items.push(group, { ...targetItem, groupId })
      }

      items.push({ ...dropItem, groupId })
    } else if (dropItem.groupId !== targetItem.groupId) {
      items.push({ ...dropItem, groupId: targetItem.groupId })
    }

    if (items.length) {
      handleUpdateConditions(items)
    }
  }, [ handleUpdateConditions, conditions ])

  return (
    <div className="flex-fill d-flex flex-column mt-2">
      <FormControl type="any" name={`${scope}.conditions`} />

      <div className="flex-fill d-flex flex-column">
        <SelectedDataView.Provider value={selectedDataViews}>
          <ConditionExplorer
            options={conditions}
            onCreateCondition={handleCreateConditions}
            onRemoveCondition={handleRemoveConditions}
            onChangeCondition={handleUpdateConditions}
            onDrop={handleDropConditions}
          />
        </SelectedDataView.Provider>
      </div>
    </div>
  )
}
