import {
  useEffect, useState,
} from 'react'
import { withProps } from '@wiz/components'
import { wizataApi } from '@/api'

export default withProps(({
  search,
  selectedOnly,
  value,
}) => {
  const [ options, setOptions ] = useState([])
  useEffect(() => {
    if (selectedOnly) {
      if (value) {
        wizataApi.entities.getById('mlmodels', value).then((item) => {
          // FIXME: it should have name prop
          const next = { ...item, name: item.id }

          setOptions([ next ])
        }).catch(err => console.log('error when fetching ml model'))
      } else {
        setOptions([ ])
      }
    } else {
      wizataApi.entities.getList('mlmodels', {
        pagination: {
          take: 999,
          skip: 0,
        },
        sortingList: [],
        filters: [
        // {
        //   type: consts.FilterType.Text,
        //   operationType: consts.FilterOperationType.Contains,
        //   value: search || '',
        //   // start: 'string',
        //   // end: 'string',
        //   propertyName: 'name',
        // },
        ],
      })
        .then((data) => {
          const { items } = data || {}
          // FIXME: it should have name prop
          const next = items.map(item => ({ ...item, name: item.id }))

          setOptions(next)
        })
    }
  }, [ selectedOnly, value ])

  return {
    options,
  }
})
