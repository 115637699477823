import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { FormField, FormControl } from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

const FieldMaxNumBuckets = ({ scope }) => {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()
  return (
    <FormField
      label={intl.t('widgets.histogram.form.fields.maxNumBuckets')}
      description={intl.t('widgets.histogram.form.fields.maxNumBucketsDescr')}
      errors={get(errors, `${scope}.maxNumBuckets`)}
    >
      <FormControl
        type="number"
        name={`${scope}.maxNumBuckets`}
        clearable
        placeholder={intl.t('form.fields.placeholderDefaultValue', {
          value: consts.WidgetHistogramBinCount,
        })}
      />
    </FormField>
  )
}

FieldMaxNumBuckets.propTypes = {
  scope: PropTypes.string.isRequired,
}

export default FieldMaxNumBuckets
