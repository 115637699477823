import {
  useRef,
  useMemo,
  forwardRef,
} from 'react'
import { useQuery } from '@tanstack/react-query'
import {
  Dialog,
  Icon,
  withResizeObserver,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { wizataApi } from '@/api'
import RightBarWizard from '@/containers/RightBarWizard'

const RightBar = forwardRef(({
  refDialog,
  container = null,
  onClose,
  expandedClose,
  isOpen,
  execution,
  customFunction,
  blockSettings,
  sensorIds,
  ...props
}, ref) => {
  const {
    data: steps,
    isLoading,
  } = useQuery({
    queryKey: [ 'executionSteps', execution?.id ],
    queryFn: () => wizataApi.wizard.getSteps({
      executionId: execution.id,
      script: execution.scriptId,
      func: execution.function,
      isanomalydetection: execution.isAnomalyDetection,
    }),
    enabled: !!execution,
    staleTime: Infinity,
  })

  const refDialogElement = useRef(null)

  const intl = useIntl()

  const request = execution?.request ? JSON.parse(execution?.request) : undefined

  const ComponentTitle = useMemo(() => ({
    children,
  }) => (
    <h1 className="h4 flex-fill m-0">
      {children}
    </h1>
  ), [ ])

  return (
    <Dialog
      ref={refDialog}
      refDialog={refDialogElement}
      classNameBody="p-0 overflow-hidden"
      className="shadow-none"
      container={container}
      strategy="absolute"
      backdrop={false}
      expandedClose={expandedClose}
      size="sm"
      opened={isOpen}
      title={intl.t('experiments.titleWizard')}
      ComponentTitle={ComponentTitle}
      resize
      right={0}
      dataTestid="ExperimentRightBarDialog"
      onClose={onClose}
    >

      {isLoading ? (
        <div className="position-center-fill">
          <Icon name="fa--spinner" spin size="2X" />
        </div>
      ) : (
        <RightBarWizard
          {...props}
          blockSettings={blockSettings}
          sensorIds={sensorIds}
          sensors={sensorIds}
          experiment={request}
          container={container}
          customFunction={customFunction}
          steps={steps}
          fullData={execution}
        />
      )}
    </Dialog>
  )
})

export default withResizeObserver(RightBar)
