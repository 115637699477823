import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { wizataApi } from '@/api'
import { withProps } from '@wiz/components'
import { consts } from '@wiz/utils'

const SortByFields = {
  createdDate: 'createdDate',
  updatedDate: 'updatedDate',
  name: 'name',
}

const SortDirection = {
  asc: 'Ascending',
  desc: 'Descending',
}

const enhanceData = withProps(({
  filters,
}) => {
  const {
    data, isLoading, refetch, isFetching,
  } = useQuery({
    queryKey: [ 'categoriesList' ],
    queryFn: () => {
      const limit = filters.pageSize + 1
      const offset = (filters.page - 1) * filters.pageSize

      const nextFilters = []

      if (filters.search) {
        nextFilters.push({
          propertyName: 'name',
          type: consts.FilterType.Text,
          operationType: consts.FilterOperationType.Contains,
          value: filters.search,
        })
      }

      return wizataApi.categories.getList({
        pagination: {
          take: limit - 1,
          skip: offset,
        },
        filters: nextFilters,
        sortingList: [
          {
            direction: SortDirection[filters.sortDir],
            propertyName: SortByFields[filters.sortBy],
          },
        ],
      })
    },
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: Infinity,
  })

  useEffect(() => {
    if (filters?.page) {
      refetch()
    }
  }, [
    filters?.page,
    filters?.search,
  ])

  return {
    total: data?.totalCount || 0,
    items: data?.items || [],
    data: data?.items || [],
    loading: isLoading || isFetching,
    refetch,
  }
})

export default enhanceData
