import { Rule } from '@casl/ability'
import sift from 'sift'
// import {
//   Space,
//   Device,
//   Sensor,
//   SpaceDescr,
// } from '@/store/models'

function emptyExpression (compare, value) {
  const isEmpty = !value
  return isEmpty === compare
}

function treeExpression (/* treeRoots, subject */) {
  // if (
  //   Space.is(subject) ||
  //   Device.is(subject) ||
  //   Sensor.is(subject) ||
  //   SpaceDescr.is(subject)
  // ) {
  //   return treeRoots.some(item => (
  //     subject.id === item ||
  //     subject.parentId === item ||
  //     subject.from === item ||
  //     subject.to === item ||
  //     subject.spacePaths.some(path => path.indexOf(`/${item}`) !== -1)
  //   ))
  // }

  return false
}

export class AccessRule extends Rule {
  constructor (params) {
    super({
      ...params,
      conditions: null,
    })

    this.conditions = params.conditions
    // eslint-disable-next-line no-underscore-dangle
    this._matches = this.conditions ?
      sift(this.conditions, {
        expressions: {
          $tree: treeExpression,
          $empty: emptyExpression,
        },
      }) :
      undefined
  }
}
