import { useCallback, memo } from 'react'
import { dbProvider, Notebook } from '@wiz/store'
import { withProps } from '@wiz/components'
import { useAuth } from '@/auth'
import Component from '@/components/Forms/ExistingNotebook'

const enhanceProps = withProps(() => {
  const auth = useAuth()
  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareReplaceData(context, Notebook, data.notebook)
    if (auth.checkAccessRead('Project')) {
      await model.prepareReplaceProjects(context, data.projects)
    }
    await model.prepareReplaceSensors(context, data.sensors)
    await dbProvider.batch(context)
  }, [ auth ])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceProps(Component),
)
