import { useState, useCallback } from 'react'
import classnames from 'classnames'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Tabs,
  FormSection,
  FormField,
  FormFieldInline,
  FormControl,
  FormInputDuration,
} from '@wiz/components'
import { get, has, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import QualityDataTemplateList from '@/hoc/QualityDataTemplateList'
import SelectSensor from '@/components/Form/SelectSensor'
import SelectLabel from '@/components/Form/SelectLabel'
import Select from '@/components/Form/Select'

export default function Section ({
  scope,
  title,
  description,
}) {
  const intl = useIntl()
  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext()

  const [
    labelIds,
    sensorIds,
    qualityTemplateIds,
  ] = watch([
    `${scope}.labelIds`,
    `${scope}.sensorIds`,
    `${scope}.qualityTemplateIds`,
  ])

  const [ tab, setTab ] = useState(qualityTemplateIds?.length ? 'tmpl' : 'sensors')

  const handleChangeTab = useCallback((name) => {
    setTab(name)
    clearErrors([
      `${scope}.labelIds`,
      `${scope}.sensorIds`,
      `${scope}.qualityTemplateIds`,
    ])
  }, [ clearErrors, scope ])

  return (
    <FormSection
      title={title}
      description={description}
    >
      <Tabs
        className="mb-3"
        value={tab}
        options={[
          { value: 'sensors', label: intl.t('widgets.tableData.sections.sensorsAndLabels') },
          { value: 'tmpl', label: intl.t('widgets.tableData.sections.qualityTemplates') },
        ]}
        onChange={handleChangeTab}
      />

      {tab === 'sensors' ? (
        <>
          <FormField
            label={intl.t('widgets.tableData.form.fields.sensors')}
            description={intl.t('widgets.tableData.form.fields.sensorsDescr')}
            errors={get(errors, `${scope}.sensorIds`)}
          >
            <Controller
              name={`${scope}.sensorIds`}
              rules={{
                validate: value => (
                  tab !== 'sensors' ||
                  value.length > 0 ||
                  labelIds.length > 0 ||
                  intl.t('form.errors.fieldOptionalRequired')
                ),
              }}
              render={({ field: { onChange, ...field }, fieldState }) => (
                <SelectSensor
                  {...field}
                  invalid={fieldState.invalid}
                  placeholder={intl.t('form.fields.sensorPlaceholder')}
                  multiselect
                  onChange={(value) => {
                    clearErrors(`${scope}.labelIds`)
                    onChange(value)
                    setValue(`${scope}.qualityTemplateIds`, [])
                  }}
                  excludeBusinessTypes={consts.ExcludedBusinessTypes}
                />
              )}
            />
          </FormField>

          <FormField
            label={intl.t('widgets.tableData.form.fields.labels')}
            description={intl.t('widgets.tableData.form.fields.labelsDescr')}
            errors={get(errors, `${scope}.labelIds`)}
          >
            <Controller
              name={`${scope}.labelIds`}
              rules={{
                validate: value => (
                  tab !== 'sensors' ||
                  value.length > 0 ||
                  sensorIds.length > 0 ||
                  intl.t('form.errors.fieldOptionalRequired')
                ),
              }}
              render={({ field: { onChange, ...field }, fieldState }) => (
                <SelectLabel
                  {...field}
                  invalid={fieldState.invalid}
                  placeholder={intl.t('form.fields.labelPlaceholder')}
                  multiselect
                  onChange={(value) => {
                    clearErrors(`${scope}.sensorIds`)
                    onChange(value)
                    setValue(`${scope}.qualityTemplateIds`, [])
                  }}
                />
              )}
            />
          </FormField>
        </>
      ) : (
        <FormField
          label={intl.t('widgets.tableData.form.fields.qualityTemplates')}
          description={intl.t('widgets.tableData.form.fields.qualityTemplatesDescr')}
          errors={get(errors, `${scope}.qualityTemplateIds`)}
        >
          <Controller
            name={`${scope}.qualityTemplateIds`}
            rules={{
              validate: value => (
                tab !== 'tmpl' ||
                value.length > 0 ||
                intl.t('form.errors.fieldRequired')
              ),
            }}
            render={({ field: { onChange, ...field }, fieldState }) => (
              <Select
                {...field}
                ListSource={QualityDataTemplateList}
                invalid={fieldState.invalid}
                placeholder={intl.t('form.fields.qualityTemplatePlaceholder')}
                multiselect
                onChange={(value) => {
                  clearErrors([
                    `${scope}.sensorIds`,
                    `${scope}.labelIds`,
                  ])
                  onChange(value)
                  setValue(`${scope}.labelIds`, [])
                  setValue(`${scope}.sensorIds`, [])
                }}
              />
            )}
          />
        </FormField>
      )}

      <FormField
        label={intl.t('widgets.tableData.form.fields.precision')}
        description={intl.t('widgets.tableData.form.fields.precisionDescr')}
        errors={get(errors, `${scope}.dataFilter.viewValuePrecision`)}
      >
        <FormControl
          type="number"
          name={`${scope}.dataFilter.viewValuePrecision`}
          rules={{
            validate: {
              min: value => (
                value === null ||
                value === '' ||
                parseInt(value || 0, 10) >= 0 ||
                intl.t('form.errors.fieldMin', { min: 0 })
              ),
              max: value => (
                value === null ||
                value === '' ||
                parseInt(value || 0, 10) <= 15 ||
                intl.t('form.errors.fieldMax', { max: 15 })
              ),
            },
          }}
          min={0}
          max={15}
          step={1}
          clearable
        />
      </FormField>

      <FormField
        label={intl.t('widgets.tableData.form.fields.slidingWindow')}
        description={intl.t('widgets.tableData.form.fields.slidingWindowDescr')}
        errors={get(errors, `${scope}.dataFilter.duration`)}
      >
        <Controller
          name={`${scope}.dataFilter.duration`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState: { invalid } }) => (
            <FormInputDuration
              {...field}
              invalid={invalid}
              parts="months,weeks,days,hours,minutes"
            />
          )}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('widgets.tableData.form.fields.utcTime')}
        description={intl.t('widgets.tableData.form.fields.utcTimeDescr')}
        errors={get(errors, `${scope}.utcTime`)}
      >
        <input
          {...register(`${scope}.utcTime`)}
          type="checkbox"
          className={classnames('form-check-input mt-0 ms-2 flex-shrink-0', {
            'is-invalid': has(errors, `${scope}.utcTime`),
          })}
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('widgets.tableData.form.fields.msTime')}
        description={intl.t('widgets.tableData.form.fields.msTimeDescr')}
        errors={get(errors, `${scope}.msTime`)}
      >
        <input
          {...register(`${scope}.msTime`)}
          type="checkbox"
          className={classnames('form-check-input mt-0 ms-2 flex-shrink-0', {
            'is-invalid': has(errors, `${scope}.msTime`),
          })}
        />
      </FormFieldInline>
    </FormSection>
  )
}
