import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import classnames from 'classnames'
import { FormSection, FormFieldInline, FormControl } from '@wiz/components'
import { Condition } from '@wiz/store'
import { get, has } from '@wiz/utils'
import ConditionExplorer from '@/components/Form/ConditionExplorer'
import classes from './SectionConditions.css'

const ConditionExcludeFields = [
  'inputSource',
  'outputSource',
  'dateFrom',
  'dateTo',
]

export default function SectionConditions ({ scope, title, description }) {
  const { formState: { errors }, watch, setValue } = useFormContext()
  const conditions = watch(`${scope}.conditions`)

  const handleCreateConditions = useCallback((condition) => {
    condition = Condition.toJSON(condition)
    const next = conditions.concat(condition)
    setValue(`${scope}.conditions`, next, { shouldDirty: true })
  }, [ conditions ])

  const handleRemoveConditions = useCallback((condition) => {
    const next = conditions
      .filter(item => item.id !== condition.id)
      .map(item => (item.groupId === condition.id ? { ...item, groupId: null } : item))
    setValue(`${scope}.conditions`, next, { shouldDirty: true })
  }, [ conditions ])

  const handleUpdateConditions = useCallback((data) => {
    data = Array.isArray(data) ? data : [ data ]
    data = data.reduce((out, item) => (out[item.id] = item, out), {})

    const next = conditions
      .map(item => {
        if (data[item.id]) {
          const next = data[item.id]
          delete data[item.id]
          return Condition.toJSON({ ...item, ...next })
        }
        return item
      })
      .concat(Object.values(data).map(item => Condition.toJSON(item)))

    setValue(`${scope}.conditions`, next, { shouldDirty: true })
  }, [ conditions ])

  return (
    <FormSection
      title={title}
      description={description}
      classNameContent={classnames({
        'border border-danger': has(errors, `${scope}.conditions`),
      })}
    >
      <FormControl
        type="any"
        name={`${scope}.conditions`}
      />

      <FormFieldInline
        errors={get(errors, `${scope}.conditions`)}
        complex
        vertical
      >
        <ConditionExplorer
          className={classes.conditions}
          options={conditions}
          exclude={ConditionExcludeFields}
          onCreateCondition={handleCreateConditions}
          onRemoveCondition={handleRemoveConditions}
          onChangeCondition={handleUpdateConditions}
        />
      </FormFieldInline>
    </FormSection>
  )
}
