import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from '@/router'
import cx from 'classnames'
import { Icon, useOnline } from '@wiz/components'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import { appEnv } from '@/config'

export default function Logo ({
  className,
  env,
}) {
  const online = useOnline()
  const envLogo = env?.EnvironmentLogo
  const router = useRouter()
  const { handleTwinChange } = useGlobalExecute()
  const handleClick = useCallback(() => {
    handleTwinChange(null)
    router.push({})
  }, [ router, handleTwinChange ])

  return (
    <div
      className={cx(className, 'pointer')}
      onClick={handleClick}
      style={!online ? {
        filter: 'grayscale(1)',
      } : undefined}
    >
      {envLogo ? <img src={envLogo} alt="logo" width={40} height={40} style={{ objectFit: 'contain' }} /> : (
        <Icon
          name={appEnv.WIZ_CLIENT_LOGO}
          width={40}
          height={40}
        />
      )}
    </div>
  )
}

Logo.propTypes = {
  className: PropTypes.string,
}

Logo.defaultProps = {
  className: undefined,
}
