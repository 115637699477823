import { FormInputSearch } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import SelectTwin from '@/components/Form/SelectTwin'
import ListFilters from '@/components/Form/ListFilters'

export default function Filters ({
  filters,
  onFilter,
  onResetFilter,
}) {
  const intl = useIntl()

  return (
    <ListFilters
      filters={filters}
      onFilter={onFilter}
      onResetFilter={onResetFilter}
    >
      {({ filters, onFilter }) => (
        <>
          <div className="px-md-2 mb-2 col-sm-4">
            <FormInputSearch
              placeholder={intl.t('form.actions.searchPlaceholder')}
              value={filters.search}
              onChange={value => onFilter({ search: value })}
            />
          </div>

          <div className="px-md-2 mb-2 col-md-3 col-lg-2">
            <SelectTwin
              placeholder={intl.t('form.fields.twinPlaceholder')}
              displayCount={1}
              value={filters.twins}
              multiselect
              onChange={twins => onFilter({ twins })}
            />
          </div>
        </>
      )}
    </ListFilters>
  )
}
