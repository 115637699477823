import {
  forwardRef,
  useMemo,
  useCallback,
  useEffect,
  useState,
} from 'react'
import DropdownSelect from '@/components/Form/DropdownSelect'
import FilterSearch from '@/components/Form/FilterSearch'
import List from '@/components/Form/Tree/List'
import DefaultListSource from './DefaultListSource'

const Dropdown = forwardRef(({
  refTarget,
  filters,
  keyName = 'id',
  value,
  options,
  placement,
  ListSource = DefaultListSource,
  ListContent,
  ListController,
  clearable,
  onChange,
  onHide,
  isGlobalTwins,
  cleanList,
  withTwinFiltering,
  ...props
}, ref) => {
  const [ isFilteringByTwin, setFilteringByTwin ] = useState(false)
  const isArrayValue = useMemo(() => Array.isArray(value), [ value ])
  const DataList = useMemo(() => (
    ListSource ? ListSource(ListController || List) : (ListController || List)
  ), [ ListSource, ListController ])

  const handleActive = useCallback((data) => {
    const item = data?.payload?.id ? data.payload : data
    return (
      (value && item[keyName] === value) ||
      (isArrayValue && value.includes(item[keyName]))
    )
  }, [ value, isArrayValue, keyName ])

  const handleChange = useCallback((data) => {
    const item = data?.payload?.id ? data.payload : data
    onChange?.(item)
  }, [ onChange ])

  return (
    <DropdownSelect
      ref={ref}
      target={refTarget}
      placement={placement}
      onHide={onHide}
    >
      {() => (
        <FilterSearch
          withClearAll={clearable}
          onChange={onChange}
          cleanList={cleanList}
          withTwinFiltering={withTwinFiltering}
          onTwinFilter={() => setFilteringByTwin(!isFilteringByTwin)}
        >
          {formData => (
            <DataList
              {...formData}
              {...filters}
              {...props}
              className="position-absolute-fill"
              keyName={keyName}
              value={value}
              active={handleActive}
              options={options}
              Content={ListContent}
              onChange={handleChange}
              isGlobalTwins={isGlobalTwins}
              isFilteringByTwin={isFilteringByTwin}
            />
          )}
        </FilterSearch>
      )}
    </DropdownSelect>
  )
})

export default Dropdown
