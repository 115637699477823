import { useState } from 'react'
import { Icon, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import FormEdgeDeviceCommand from '@/containers/Forms/EdgeDeviceCommand'
import Table from '@/containers/EdgeDeviceCommand/Table'
import TableMobile from '@/containers/EdgeDeviceCommand/TableMobile'
import Filters from './Filters'
import ViewModel from './ViewModel'

export default function List ({
  filters,
  forceUpdate,
  onFilter,
  onResetFilter,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const isMobile = useMobile()
  const [ createCommand, setCreateCommand ] = useState(false)
  const [ viewId, setViewId ] = useState(null)

  const TableComponent = isMobile ? TableMobile : Table

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">

      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <div className="d-flex align-items-start flex-wrap">
          <div className="px-sm-2 mb-2">
            {auth.checkAccessCreate('EdgeDeviceCommand') ? (
              <button
                type="button"
                className="btn btn-flat-secondary"
                onClick={() => setCreateCommand(true)}
              >
                <Icon name="fa--plus" className="me-1" />
                {intl.t('form.actions.add')}
              </button>
            ) : null}

            <button
              type="button"
              className="btn btn-flat-secondary"
              onClick={forceUpdate}
            >
              {intl.t('form.actions.refresh')}
            </button>
          </div>

          <Filters
            filters={filters}
            onFilter={onFilter}
            onResetFilter={onResetFilter}
          />
        </div>

        <TableComponent
          {...props}
          filters={filters}
          onFilter={onFilter}
          onView={setViewId}
        />
      </div>

      {createCommand ? (
        <FormEdgeDeviceCommand
          onSuccess={forceUpdate}
          onClose={() => setCreateCommand(false)}
          dialog={{
            title: intl.t('edge.commands.titleCreate'),
            dataTestid: 'createDeviceCommandDialog',
          }}
        />
      ) : null}

      {viewId ? (
        <ViewModel
          deviceCommand={viewId}
          title={intl.t('edge.commands.titleDetail')}
          onClose={() => setViewId(null)}
          onFilter={onFilter}
          dialog
        />
      ) : null}
    </div>
  )
}
