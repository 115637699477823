/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'
import Observe from '@/containers/Observe'

export default function ViewModel ({
  title,
  edge,
  onClose,
}) {
  const refDialog = useRef()
  const intl = useIntl()

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('edge.devices.form.fields.deviceId'),
          value: edge.deviceId,
        },
        {
          title: intl.t('edge.devices.form.fields.runtimeResponse'),
          value: edge.runtimeResponse || '-',
        },
        {
          title: intl.t('edge.devices.form.fields.modulesCount'),
          value: () => (
            <Observe count={edge.modules}>
              {({ data }) => (data || null)}
            </Observe>
          ),
        },
        {
          title: intl.t('edge.devices.form.fields.connectionsCount'),
          value: () => (
            <Observe count={edge.hubConnections}>
              {({ data }) => (data || null)}
            </Observe>
          ),
        },
      ],
    },

    {
      title: intl.t('form.fields.updated'),
      value: () => <UpdatedInfo date={edge.updatedAt} user={edge.updatedUser} />,
    },
    {
      title: intl.t('form.fields.created'),
      value: () => <UpdatedInfo date={edge.createdAt} user={edge.createdUser} />,
    },
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      onClose={onClose}
    />
  )
}
