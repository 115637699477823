import { useRef } from 'react'
import { DateTime } from 'luxon'
import classnames from 'classnames'
import {
  Icon,
  DropdownColor,
  OverflowCenter,
  Badge,
} from '@wiz/components'
import { Condition } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import FormatDateTime from '@/containers/FormatDateTime'
import classes from './index.css'

export default function ItemContent ({
  search,
  data,
  context,
  isLeaf,
}) {
  const refColor = useRef()
  const intl = useIntl()

  const { onAction } = context
  const name = isLeaf ? data.name : 'Group AND'
  const {
    applied,
    data: item,
  } = data.payload
  const readOnly = onAction('readOnly')

  return (
    <>
      {applied ? (
        <Icon
          name="fa--check-circle"
          className="me-2 text-success"
          title={intl.t('explorer.conditions.status.apply')}
        />
      ) : null}

      {!item.groupId || !isLeaf ? (
        <>
          <button
            type="button"
            ref={refColor}
            className="btn p-0 me-2"
            style={{ color: item.color }}
          >
            <Icon name="fa--circle" size="xs" />
          </button>
          {readOnly ? null : (
            <DropdownColor
              alpha
              arrow
              target={refColor}
              value={item.color}
              onChange={color => onAction('changeCondition', { ...item, color })}
            />
          )}
        </>
      ) : null}

      <div className="d-flex align-items-center min-w-0 flex-fill">
        {name ? (
          <OverflowCenter value={name} mark={search} />
        ) : null}

        {isLeaf ? (
          <>
            {item.inverse ? (
              <Badge className="mx-1 bg-light text-secondary">not</Badge>
            ) : null}

            <Badge className="mx-1 bg-light text-secondary">
              {intl.t(`enum.conditionType.${item.type}`)}
            </Badge>

            <Badge className="mx-1 bg-light text-secondary">
              {JSON.stringify(Condition.parsePayload(item))}
            </Badge>

            {item.dateFrom ? (
              <Badge className="mx-1 bg-light text-secondary">
                From:&nbsp;
                <FormatDateTime
                  date={item.dateFrom}
                  withSecond
                />
              </Badge>
            ) : null}

            {item.dateTo ? (
              <Badge className="mx-1 bg-light text-secondary">
                To:&nbsp;
                <FormatDateTime
                  date={item.dateTo}
                  withSecond
                />
              </Badge>
            ) : null}
          </>
        ) : null}
      </div>

      {readOnly ? null : (
        <div className="hover-toggle d-flex align-items-center ms-1 position-relative flex-shrink-0">
          <div className={classnames(classes.actions, 'hover-flex align-items-center position-absolute end-0')}>
            <div className="border-start rh-4 flex-shrink-0 mx-2" />

            <button
              type="button"
              className="btn btn-sm btn-fill-secondary"
              onClick={() => onAction('removeCondition', item)}
            >
              <Icon name="fa--trash-alt" />
            </button>

            {isLeaf ? (
              <button
                type="button"
                className="btn btn-sm ms-1 btn-fill-secondary"
                title={intl.t('form.actions.edit')}
                onClick={() => onAction('editCondition', item)}
              >
                <Icon name="fa--edit" />
              </button>
            ) : null}
          </div>

          <button
            type="button"
            className="btn btn-sm ms-1 btn-flat-primary"
          >
            <Icon name="fa--ellipsis-h" />
          </button>
        </div>
      )}
    </>
  )
}
