import {
  useMemo,
  useState,
  useCallback,
} from 'react'
import { EventSource } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import EventSourceForm from '@/hoc/EventSourceForm'
import FormEventSource from '@/components/Forms/EventSource'
import List from '@/components/Form/Tree/List'
import FilterSearch from '@/components/Form/FilterSearch'
import ItemContent from './ItemContent'

const BindedFormEventSource = EventSourceForm(FormEventSource)

export default function EventSourceExplorer ({
  dataViews,
  options,
  applied,
  readOnly,
  onRemoveEventSource,
  onReplaceEventSource,
}) {
  const intl = useIntl()
  const [ search, setSearch ] = useState('')
  const [ createEventSource, setCreateEventSource ] = useState(null)
  const [ editEventSource, setEditEventSource ] = useState(null)

  const handleFilter = useCallback(({ search: data }) => (
    setSearch(data || '')
  ), [])

  const currentOptions = useMemo(() => (
    options
      ?.filter(item => (
        !search ||
        !item.name ||
        item.name.indexOf(search) !== -1
      ))
      ?.map(item => ({
        id: item.id,
        name: item.name,
        payload: {
          data: item,
          applied: applied?.[item.id],
        },
      })) ?? []
  ), [
    options,
    search,
    applied,
  ])

  const handleAction = useCallback((action, payload) => {
    if (action === 'readOnly') {
      return readOnly
    }
    if (action === 'replaceEventSource') {
      return onReplaceEventSource(payload)
    }
    if (action === 'removeEventSource') {
      return onRemoveEventSource(payload)
    }
    if (action === 'editEventSource') {
      return setEditEventSource(payload)
    }
  }, [
    readOnly,
    onReplaceEventSource,
    onRemoveEventSource,
  ])

  return (
    <>
      <FilterSearch
        searchTestid="eventsSearch"
        withSelectedOnly={false}
        onFilter={handleFilter}
        postfix={readOnly ? null : (
          <button
            type="button"
            className="btn btn-sm btn-fill-secondary-sm text-nowrap mb-1 me-1"
            onClick={() => {
              setCreateEventSource(EventSource.toJSON())
            }}
          >
            {intl.t('form.actions.addEvent')}
          </button>
        )}
      >
        {formData => (
          <List
            {...formData}
            className="position-absolute-fill"
            options={currentOptions}
            checkFiltered={() => (!!search)}
            Content={ItemContent}
            onAction={handleAction}
          />
        )}
      </FilterSearch>

      {editEventSource || createEventSource ? (
        <BindedFormEventSource
          dataViews={dataViews}
          eventSource={createEventSource || editEventSource}
          onSubmit={({ eventSource }) => {
            onReplaceEventSource(eventSource)
          }}
          onClose={() => {
            setCreateEventSource(null)
            setEditEventSource(null)
          }}
          dialog={{
            title: createEventSource ?
              intl.t('explorer.titleCreateEventSource') :
              intl.t('explorer.titleUpdateEventSource'),
            dataTestid: 'replaceEventSourceDialog',
          }}
        />
      ) : null}
    </>
  )
}
