import cx from 'classnames'
import { useLocation, useLocationQuery } from '@/router'
import { CorePages as PAGES, PagesMap } from '@/utils/consts'
import OperateTopbar from '@/pages/operate/topbar'
import Actions from './actions'
import SinglePipelineActions from './actions/singlePipeline'
import ExperimentActions from './actions/experiment'
import Filters from './filters'
import classes from './index.module.css'
import Breadcrumbs from './breadcrumbs'

const TopBar = ({ onAdd }) => {
  const { pipelineId } = useLocationQuery()
  const loc = useLocation()
  const [ ,, corePage, currentPage, id ] = loc.pathname.split('/')

  const handleAdd = (...params) => {
    if (!params[0]) {
      if (currentPage === 'templates' && id) {
        onAdd('registrations', false, id) // edge case to handle registrations form
      } else {
        onAdd(currentPage)
      }
    } else {
      onAdd(...params)
    }
  }

  return (
    <header
      className={cx('align-items-start pe-3 border-y', classes.header)}
    >
      <Breadcrumbs />
      <div className="d-flex align-items-center">
        {corePage === PAGES.OPERATE ?
          <OperateTopbar /> :
          currentPage === PagesMap.Pipelines && !!pipelineId ? (
            <SinglePipelineActions onRun={handleAdd} />
          ) : id && currentPage !== PagesMap.Templates ? null : (
            <>
              <Actions onAdd={handleAdd} />
              {currentPage !== PagesMap.Twins ?
                <Filters common={corePage === PAGES.DESIGN} /> : null}
            </>
          )}
        {currentPage === PagesMap.Experiments && id ? <ExperimentActions onEdit={handleAdd} onRun={handleAdd} /> : null}
      </div>
    </header>
  )
}

export default TopBar
