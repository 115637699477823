import { OverflowCenter } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import ButtonCopy from '@/components/Form/ButtonCopy'

export default function Widget ({ data, index, style }) {
  const intl = useIntl()
  const { options, showDescription } = data
  const item = options[index]

  return (
    <div
      style={style}
      className="d-flex align-items-start hover-toggle list-group-item bg-light"
    >
      <div className="flex-fill min-w-0">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center min-w-0">
            <OverflowCenter value={item.displayName} />
            {item.hardwareId ? (
              <ButtonCopy
                className="hover-inline-flex"
                value={item.hardwareId}
                title={intl.t('message.copy.hardwareId', { value: item.hardwareId })}
              />
            ) : null}
          </div>
        </div>
        {showDescription && item.description ? (
          <div className="d-flex">
            <span className="me-1 text-secondary text-truncate">{item.description}</span>
          </div>
        ) : null}
      </div>
    </div>
  )
}
