import cx from 'classnames'
import { useLocationQuery } from '@/router'
import { Card } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Grafana from './Grafana'
import s from './index.module.css'

export default function Widget ({
  widget,
  config,
  hidden,
  canUpdateDashboard,
  onAction,
  templateId,
}) {
  const intl = useIntl()
  const { asset, tree } = useLocationQuery()

  const actions = canUpdateDashboard ? [
    { id: 'settings', name: intl.t('form.actions.settings'), icon: 'fa--cog' },
    ...((config.panelUrl && (asset || tree)) || !templateId ?
      [{ id: 'editGrafana', name: intl.t('grafana.form.actions.editGrafana'), icon: 'fa--grafana' },
        { id: 'openGrafana', name: intl.t('grafana.form.actions.openGrafana'), icon: 'fa--grafana' }] :
      []),
    { id: 'remove', name: intl.t('form.actions.remove'), icon: 'fa--trash-alt' },
  ] : []

  return (
    <Card
      className="position-absolute-fill"
      // classNameTitle={canUpdateDashboard ? 'userdrag' : undefined}
      classNameHeader={cx(s.cardHeader, canUpdateDashboard ? 'userdrag' : undefined)}
      classNameBody={s.cardBody}
      description={widget.description}
      options={actions}
      onAction={onAction}
    >
      {do {
        if (widget.config?.panelUrl) {
          <Grafana
            widget={widget}
            hidden={hidden}
            config={config}
            templateId={templateId}
          />
        } else if (canUpdateDashboard) {
          <div className="d-flex align-items-center justify-content-center flex-fill">
            <button
              type="button"
              className="btn btn-flat-secondary"
              onClick={() => onAction({ id: 'settings' })}
            >
              {intl.t('widgets.selectPanel')}
            </button>
          </div>
        } else {
          <div className="d-flex align-items-center justify-content-center flex-fill">
            {intl.t('widgets.notConfigured')}
          </div>
        }
      }}
    </Card>
  )
}
