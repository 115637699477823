import { forwardRef, useMemo, useCallback } from 'react'
import classnames from 'classnames'
import { ResponsiveList } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { consts } from '@wiz/utils'
import { Sensor } from '@wiz/store'
import Option from './Option'

const ListSensorsByBlock = forwardRef(({
  className,
  options,
  search,
  value,
  onChange,
  onResetFilter,
}, ref) => {
  const intl = useIntl()
  const itemData = useMemo(() => ({
    options,
    search,
    value,
    onChange,
  }), [ options, search, value, onChange ])

  const itemSize = useCallback((idx) => {
    const item = options[idx]
    if (Sensor.is(item) && item.description) {
      return consts.ListItemHeightMd
    }

    if (item.list) {
      return consts.ListItemHeightMd
    }

    return consts.ListItemHeight
  }, [ options ])

  if (!options.length) {
    return (
      <div className={classnames('d-flex flex-column align-items-center justify-content-center', className)}>
        {intl.t('form.nodata')}
        {onResetFilter ? (
          <button
            type="button"
            className="btn btn-sm btn-outline-secondary mt-2"
            onClick={onResetFilter}
          >
            {intl.t('form.actions.resetFilters')}
          </button>
        ) : null}
      </div>
    )
  }

  return (
    <ResponsiveList
      className={className}
      itemCount={options.length}
      itemData={itemData}
      estimatedItemSize={consts.ListItemHeight}
      itemSize={itemSize}
    >
      {Option}
    </ResponsiveList>
  )
})

export default ListSensorsByBlock
