import { useState, useMemo } from 'react'
import { Icon, FormInputSearch, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { consts } from '@wiz/utils'
import { useAuth } from '@/auth'
import events from '@/utils/events'
import File from '@/hoc/File'
import FormFile from '@/containers/Forms/File'
import FormFileUpload from '@/containers/Forms/FileUpload'
import FormBGJobImport from '@/containers/Forms/BGJobImport'
import Table from '@/containers/File/Table'
import TableMobile from '@/containers/File/TableMobile'
import ViewModel from './ViewModel'

const ViewModelData = File(ViewModel)

export default function List ({
  filters,
  selected,
  onFilter,
  onResetFilter,
  onRemove,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const isMobile = useMobile()
  const [ editId, setEditId ] = useState(null)
  const [ viewId, setViewId ] = useState(null)
  const [ importData, setImportData ] = useState(false)
  const selectedImport = useMemo(() => (
    selected.filter(item => (
      item.type === consts.FileType.UserFile ||
      item.contentType === 'text/csv'
    ))
  ), [ selected ])

  const TableComponent = isMobile ? TableMobile : Table

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">

      <div className="d-flex flex-fill flex-column min-h-0 pb-mb-3 px-3 pt-3">
        <div className="d-flex align-items-center flex-wrap">
          {(
            auth.checkAccessCreate('File') ||
            auth.checkAccessRemove('File')
          ) ? (
            <div className="px-2 mb-2 flex-shrink-0">
              {auth.checkAccessCreate('File') ? (
                <button
                  type="button"
                  className="btn btn-flat-secondary"
                  onClick={() => setEditId('')}
                >
                  <Icon name="fa--plus" className="me-1" />
                  {intl.t('form.actions.add')}
                </button>
              ) : null}

              {(
                auth.checkAccessCreate('Job') &&
                auth.checkAccessRead('File')
              ) ? (
                <button
                  type="button"
                  className="btn btn-flat-secondary"
                  disabled={!selectedImport.length}
                  onClick={() => setImportData(true)}
                >
                  <Icon name="fa--file-download" className="me-1" />
                  {intl.t('form.actions.importDataTitle')}
                </button>
                ) : null}

              {auth.checkAccessRemove('File') ? (
                <button
                  type="button"
                  className="btn btn-flat-secondary"
                  onClick={() => onRemove(selected)}
                  disabled={!selected.length}
                >
                  <Icon name="fa--trash-alt" className="me-1" />
                  {intl.t('form.actions.remove')}
                </button>
              ) : null}
            </div>
            ) : null}

          <div className="d-flex align-items-center">
            <div className="px-2 mb-2 col-sm-auto">
              <FormInputSearch
                placeholder={intl.t('form.actions.searchPlaceholder')}
                value={filters.search}
                onChange={value => onFilter({ search: value })}
              />
            </div>

            <div className="mb-2">
              <button
                type="button"
                className="btn btn-fill-secondary text-nowrap"
                onClick={onResetFilter}
              >
                {intl.t('form.actions.resetFilters')}
              </button>
            </div>
          </div>
        </div>

        <TableComponent
          {...props}
          filters={filters}
          onRemove={onRemove}
          onEdit={setEditId}
          onView={setViewId}
        />
      </div>

      {editId ? (
        <FormFile
          id={editId}
          onClose={() => setEditId(null)}
          dialog={{
            title: intl.t('files.titleUpdate'),
            dataTestid: 'updateFileDialog',
          }}
        />
      ) : null}

      {!editId && editId !== null ? (
        <FormFileUpload
          onClose={() => setEditId(null)}
          dialog={{
            title: intl.t('files.titleCreate'),
            dataTestid: 'uploadFileDialog',
          }}
        />
      ) : null}

      {importData && selectedImport.length ? (
        <FormBGJobImport
          files={selectedImport.map(item => item.id)}
          onClose={() => setImportData(false)}
          dialog={{
            title: intl.t('jobs.titleCreateImportData'),
            dataTestid: 'replaceBGJobImportDataDialog',
          }}
          onSuccess={() => {
            events.emit('app:notify', {
              type: 'success',
              title: 't/jobs.titleCreateImportData',
              message: 't/files.form.success.importData',
            })
          }}
        />
      ) : null}

      {viewId ? (
        <ViewModelData
          id={viewId}
          title={intl.t('files.titleDetail')}
          onClose={() => setViewId(null)}
          onRemove={onRemove}
          onEdit={setEditId}
          dialog
        />
      ) : null}
    </div>
  )
}
