import { useRef, useState, useCallback } from 'react'
import {
  Icon,
  FormSection,
  FormField,
  FormInputNumber,
  LocationMap,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import ReadCurrentLocation from './ReadCurrentLocation'
import InputSearchLocation from './InputSearchLocation'

export default function Location ({
  latitude,
  longitude,
  zoom,
  dialog,
  withZoom,
  onClose,
  onSubmit,
}) {
  const refDialog = useRef(null)
  const refPrevLat = useRef(latitude)
  const refPrevLng = useRef(longitude)
  const refPrevZoom = useRef(zoom)
  const intl = useIntl()
  const [ lat, setLat ] = useState(latitude)
  const [ lng, setLng ] = useState(longitude)
  const [ zoo, setZoo ] = useState(zoom)
  const handleUpdateCoords = useCallback((lat, lng, zoo) => {
    setLat(lat)
    setLng(lng)
    if (withZoom) {
      setZoo(zoo)
    }
  }, [ withZoom ])
  const handleReset = useCallback(() => {
    setLat(refPrevLat.current)
    setLng(refPrevLng.current)
    if (withZoom) {
      setZoo(refPrevZoom.current)
    }
  }, [ withZoom ])

  return (
    <FormDialog
      ref={refDialog}
      onClose={onClose}
      dataTestid="locationForm"
      dialog={dialog}
      hasActions={false}
    >
      <div className="flex-fill d-flex flex-column">
        <FormSection>
          <FormField
            label={intl.t('location.form.fields.lat')}
            description={intl.t('location.form.fields.latDescr')}
          >
            <FormInputNumber
              className="me-2"
              scale={10}
              value={lat}
              onChange={setLat}
            />
          </FormField>

          <FormField
            label={intl.t('location.form.fields.lng')}
            description={intl.t('location.form.fields.lngDescr')}
          >
            <FormInputNumber
              className="me-2"
              scale={10}
              value={lng}
              onChange={setLng}
            />
          </FormField>

          <FormField>
            <ReadCurrentLocation
              onChange={handleUpdateCoords}
            />
          </FormField>
        </FormSection>

        <div className="d-flex mb-3">
          <InputSearchLocation
            placeholder={intl.t('location.form.fields.addressPlaceholder')}
            onChange={handleUpdateCoords}
          />
        </div>

        <div className="flex-fill position-relative">
          <LocationMap
            latitude={lat}
            longitude={lng}
            zoom={zoo}
            onChange={handleUpdateCoords}
          />
        </div>

        <footer className="d-flex justify-content-end my-3">
          <button
            name="remove"
            type="button"
            className="btn btn-outline-danger btn-text me-3"
            title={intl.t('form.actions.remove')}
            onClick={() => {
              onSubmit?.(null, null, null)
              refDialog.current?.close()
            }}
          >
            <Icon name="fa--trash-alt" />
          </button>

          <button
            name="close"
            type="button"
            className="btn btn-outline-secondary me-2 px-4"
            onClick={() => refDialog.current?.close()}
          >
            {intl.t('form.actions.close')}
          </button>

          <button
            name="cancel"
            type="button"
            className="btn btn-outline-secondary me-2 px-4"
            onClick={handleReset}
          >
            <span className="d-none d-sm-inline">{intl.t('form.actions.cancelChanges')}</span>
            <span className="d-sm-none">{intl.t('form.actions.cancel')}</span>
          </button>

          <button
            name="save"
            type="button"
            className="btn btn-primary px-4"
            onClick={() => {
              onSubmit?.(lat, lng, zoo)
              refDialog.current?.close()
            }}
          >
            {intl.t('form.actions.save')}
          </button>
        </footer>
      </div>
    </FormDialog>
  )
}
