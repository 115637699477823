import { memo, useEffect } from 'react'
import { withProps } from '@wiz/components'
import enhanceCommandTemplate from './enhanceCommandTemplate'

const enhanceProps = withProps(({
  deviceCommandTemplate,
  onChange,
}) => {
  useEffect(() => (
    onChange?.({ deviceCommandTemplate })
  ), [ deviceCommandTemplate, onChange ])
})

export default memo(
  enhanceCommandTemplate(
    enhanceProps(() => (null)),
  ),
)
