import {
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { Dialog, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'

export default function ListFilters ({
  children,
  filters,
  resetData,
  onFilter,
  onResetFilter,
}) {
  const refFilters = useRef(null)
  const intl = useIntl()
  const isMobil = useMobile()
  const [ openFilters, setOpenFilters ] = useState(false)
  const [ localFilters, setLocalFilters ] = useState(filters)
  const currentFilters = isMobil ? localFilters : filters

  const handleFilter = useCallback((data) => {
    if (isMobil) {
      setLocalFilters({ ...localFilters, ...data })
    } else {
      refFilters.current?.close()
      onFilter({ ...data })
    }
  }, [ onFilter, isMobil, localFilters ])

  useEffect(() => {
    setLocalFilters(filters)
  }, [ filters ])

  useEffect(() => () => {
    setOpenFilters(false)
  }, [ isMobil ])

  const FiltersWrap = isMobil ? Dialog : Fragment

  return (
    <>
      {isMobil ? (
        <div className="mb-2 flex-shrink-0">
          <button
            type="button"
            className="btn btn-flat-secondary"
            onClick={() => setOpenFilters(true)}
          >
            {intl.t('form.actions.filters')}
          </button>
        </div>
      ) : null}

      {openFilters || !isMobil ? (
        <FiltersWrap
          {...(isMobil ? {
            ref: refFilters,
            title: intl.t('form.sections.filters'),
            onClose: () => setOpenFilters(false),
          } : undefined)}
        >
          {children({
            isMobil,
            filters: currentFilters,
            onFilter: handleFilter,
          })}

          <div className="px-md-2 mb-2 d-flex col-md-auto">
            {onResetFilter ? (
              <button
                type="button"
                className="btn btn-fill-secondary me-2 flex-fill"
                onClick={() => {
                  refFilters.current?.close()
                  onResetFilter({ ...resetData })
                }}
              >
                {intl.t('form.actions.resetFilters')}
              </button>
            ) : null}

            {isMobil ? (
              <button
                type="button"
                className="btn btn-fill-secondary flex-fill"
                onClick={() => {
                  refFilters.current?.close()
                  onFilter(localFilters)
                }}
              >
                {intl.t('form.actions.filter')}
              </button>
            ) : null}
          </div>
        </FiltersWrap>
      ) : null}
    </>
  )
}
