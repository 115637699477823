import { useState } from 'react'
import PropTypes from 'prop-types'
import { Icon, FormInputSearch, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import Alert from '@/hoc/Alert'
import FormAlert from '@/containers/Forms/Alert'
import Table from '@/containers/Alert/Table'
import TableMobile from '@/containers/Alert/TableMobile'
import ViewModel from './ViewModel'

const ViewModelData = Alert(ViewModel)

export default function List ({
  filters,
  selected,
  onFilter,
  onResetFilter,
  onRemove,
  onDuplicate,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const isMobile = useMobile()
  const [ viewId, setViewId ] = useState(null)
  const [ editId, setEditId ] = useState(null)

  const TableComponent = isMobile ? TableMobile : Table

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">
      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <TableComponent
          {...props}
          filters={filters}
          onFilter={onFilter}
          onRemove={onRemove}
          onEdit={setEditId}
          onView={setViewId}
          onDuplicate={onDuplicate}
        />
      </div>

      {editId !== null ? (
        <FormAlert
          id={editId}
          onClose={() => setEditId(null)}
          dialog={{
            title: editId ? intl.t('alerts.titleUpdate') : intl.t('alerts.titleCreate'),
            dataTestid: 'replaceAlertDialog',
          }}
        />
      ) : null}

      {viewId ? (
        <ViewModelData
          id={viewId}
          title={intl.t('alerts.titleDetail')}
          onClose={() => setViewId(null)}
          onRemove={onRemove}
          onEdit={setEditId}
          onDuplicate={onDuplicate}
          dialog
        />
      ) : null}
    </div>
  )
}

List.propTypes = {
  onRemove: PropTypes.func,
}

List.defaultProps = {
  onRemove: undefined,
}
