import { useCallback } from 'react'
import { of as of$ } from 'rxjs'
import { withObservables, withProps } from '@wiz/components'
import { dbProvider } from '@wiz/store'
import Widget from './components/Widget'

const enhanceExplorerContext = withObservables([ 'widget' ], ({ widget }) => ({
  explorerContext: widget ? widget.observeExplorerContext : of$({}),
}))

const enhanceProps = withProps(({
  widget,
  canUpdateDashboard,
  onWidgetAction,
  explorerContext,
}) => {
  const onAction = useCallback(async ({ id }) => {
    await onWidgetAction(widget, id)
  }, [ widget, onWidgetAction ])

  const onToggleSelectedLegend = useCallback(async (name) => {
    const context = dbProvider.createBatchContext()
    await widget.prepareReplaceFilter(context, {
      viewSelectedLegend: {
        ...explorerContext.dataFilter.viewSelectedLegend,
        [name]: !(explorerContext.dataFilter.viewSelectedLegend[ name ] ?? true),
      },
    })
    await dbProvider.batch(context)
  }, [ widget, explorerContext ])

  const onResizeLegend = useCallback(async (size) => {
    const context = dbProvider.createBatchContext()
    await widget.prepareReplaceFilter(context, {
      viewLegendSize: Number(size || 0),
    })
    await dbProvider.batch(context)
  }, [ widget ])

  const onChangeViewZoom = useCallback(async (viewZoom) => {
    if (canUpdateDashboard) {
      const context = dbProvider.createBatchContext()
      await widget.prepareReplaceFilter(context, { viewZoom })
      await dbProvider.batch(context)
    }
  }, [ canUpdateDashboard, widget ])

  return {
    onAction,
    onToggleSelectedLegend,
    onResizeLegend,
    onChangeViewZoom,
  }
})

export default enhanceExplorerContext(
  enhanceProps(Widget),
)
