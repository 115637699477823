/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'
import CellParent from './CellParent'

export default function ViewModel ({
  id,
  title,
  twin,
  onClose,
  onUnlinkParent,
  onRemove,
  onEdit,
}) {
  const refDialog = useRef()
  const intl = useIntl()
  const auth = useAuth()

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('twin.form.fields.type'),
          value: twin.type,
        },
        {
          title: intl.t('form.fields.name'),
          value: twin.name || '-',
        },
        {
          title: intl.t('twin.form.fields.hardwareId'),
          value: twin.hardwareId || '-',
        },
        {
          title: intl.t('twin.form.fields.parent'),
          value: () => (
            <CellParent
              twin={twin}
              onUnlinkParent={onUnlinkParent}
            />
          ),
        },
      ],
    },

    {
      title: intl.t('form.fields.updated'),
      value: () => <UpdatedInfo date={twin.updatedAt} user={twin.updatedUser} />,
    },
    {
      title: intl.t('form.fields.created'),
      value: () => <UpdatedInfo date={twin.createdAt} user={twin.createdUser} />,
    },
  ]

  const actions = [
    ...(onRemove && auth.checkAccessRemove(twin) ? ([{
      title: intl.t('form.actions.remove'),
      name: 'remove',
      testid: 'removeButton',
      onClick: () => onRemove([ twin ]),
    }]) : []),

    ...(onEdit && auth.checkAccessUpdate(twin) ? ([{
      title: intl.t('form.actions.edit'),
      name: 'edit',
      testid: 'editButton',
      onClick: () => onEdit(id),
    }]) : []),
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      actions={actions}
      onClose={onClose}
    />
  )
}
