import { Fragment } from 'react'
import { useFormContext, Controller, useFieldArray } from 'react-hook-form'
import classnames from 'classnames'
import { useIntl } from '@wiz/intl'
import { get, has } from '@wiz/utils'
import FormatDateTime from '@/containers/FormatDateTime'
import {
  FormControl, FormSelectDateTime, FormFieldInline,
} from '@wiz/components'
import s from './index.module.css'

// const SectionRatio = [ 9, 3 ]

const byType = {
  integer: 'number',
  float: 'number',
  relative: 'text',
  string: 'text',
}

export default function FieldsVariables () {
  const intl = useIntl()

  const {
    formState: { errors }, register,
  } = useFormContext()

  const {
    fields,
  } = useFieldArray({
    name: 'variables',
    keyName: '_id',
    shouldUnregister: true,
  })
  return (
    <FormFieldInline
      label={intl.t('pipelines.form.fields.executeVariables')}
      description={intl.t('pipelines.form.fields.executeVariablesDescr')}
      errors={get(errors, 'variables')}
      complex
      vertical
    >
      <div className="list-group list-group-flush flex-fill mx-0">
        {fields?.map((item, idx) => {
          const scopeRow = `variables.${idx}`
          const error = (
            get(errors, `${scopeRow}.${item.value}`)
          )

          return (
            <Fragment
              key={item.key}
            >
              <div
                className={classnames('list-group-item py-2 px-0 d-flex align-items-center', {
                  'is-invalid': !!error,
                })}
              >
                <span className="text-truncate text-muted w-50 fs-6">
                  {`${item.key} (${item.value})`}
                </span>

                {item.value === 'json' ? (
                  <FormControl
                    type="code"
                    className={classnames(s.code, 'ms-2 w-50')}
                    required={item.required}
                    name={`${scopeRow}.${item.value}`}
                    lang="json"
                    rules={{
                      validate: (val) => {
                        const str = String(val || '').trim()
                        if (item.required && !str.length) {
                          return intl.t('form.errors.fieldRequired')
                        }
                        if (val?.length > 20000) {
                          return intl.t('form.errors.fieldMaxlen', { max: 20000 })
                        }
                        return true
                      },
                    }}
                  />
                ) : null }
                {/* {item.value === 'datapoint' ? (
                    <Controller
                      name={`${scopeRow}.sensorId`}
                      rules={{ required: item.required }}
                      render={({ field, fieldState }) => (
                        <FormListSensors
                          {...field}
                          invalid={fieldState.invalid}
                          placeholder="--"
                            // onChange={data => handleChange(data, item)}
                          className={classnames('ms-2 w-50')}
                          required={item.required}
                          twinId={twinId}
                        />
                      )}
                    />
                  ) :
                    null} */}
                {item.value === 'datetime' ? (
                  <Controller
                    name={`${scopeRow}.${item.value}`}
                    rules={{ required: item.required }}
                    render={({ field, fieldState }) => (
                      <FormSelectDateTime
                        {...field}
                        invalid={fieldState.invalid}
                        future={false}
                        DateTimeComponent={FormatDateTime}
                        className={classnames('ms-2 w-50')}
                        required={item.required}
                      />
                    )}
                  />

                ) : null}

                {[ 'integer', 'float', 'string', 'relative' ].includes(item.value) ? (
                  <input
                    {...register(`${scopeRow}.${item.value}`, {
                      validate: (value) => {
                        const str = String(value || '').trim()
                        if (item?.required && !str.length) {
                          return intl.t('form.errors.fieldRequired')
                        }
                        if (value?.length > 450) {
                          return intl.t('form.errors.fieldMaxlen', { max: 450 })
                        }
                        return true
                      },
                    })}
                    type={byType[item.value] || 'search'}
                    className={classnames('form-control w-50 ms-2', {
                      'ms-2': has(errors, `${scopeRow}.${item.value}`),
                    })}
                  />
                ) : null}
                {/* edge case, if users type is wrong */}
                {!byType[item.value] ? (
                  <input
                    {...register(`${scopeRow}.${item.value}`, {
                      validate: (value) => {
                        const str = String(value || '').trim()
                        if (item?.required && !str.length) {
                          return intl.t('form.errors.fieldRequired')
                        }
                        if (value?.length > 450) {
                          return intl.t('form.errors.fieldMaxlen', { max: 450 })
                        }
                        return true
                      },
                    })}
                    type="search"
                    className={classnames('form-control w-50 ms-2', {
                      'ms-2': has(errors, `${scopeRow}.${item.value}`),
                    })}
                  />
                ) : null}

              </div>
              {error ? (
                <div className="invalid-feedback m-0">
                  {error.message}
                </div>
              ) : null}
            </Fragment>
          )
        })}
        {!fields.length ? (
          <div className="list-group-item text-center">
            {intl.t('form.info.variablesNotFound')}
          </div>
        ) : null}
      </div>
    </FormFieldInline>

  )
}
