import classnames from 'classnames'
import { Icon } from '@wiz/components'
import { copyText } from '@wiz/utils'

export default function ButtonCopy ({ className, title, value, onClick }) {
  if (!value) {
    return null
  }

  return (
    <button
      type="button"
      className={classnames('btn btn-sm btn-link p-1 mt-n1 mb-n1 ms-1 text-reset', className)}
      title={title || value}
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()
        copyText(value)
        onClick?.()
      }}
    >
      <Icon name="fa--copy" />
    </button>
  )
}
