import { useFormContext, Controller } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'
import {
  FormField,
  FormFieldInline,
  FormControl,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function FieldsView ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('form.fields.title')}
        description={intl.t('grafana.form.fields.titleDescr')}
        errors={get(errors, `${scope}.title`)}
      >
        <FormControl
          name={`${scope}.title`}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (!str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value?.length > 250) {
                return intl.t('form.errors.fieldMaxlen', { max: 250 })
              }
              return true
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.description')}
        description={intl.t('grafana.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.description`}
          rules={{
            maxLength: {
              value: 400,
              message: intl.t('form.errors.fieldMaxlen', { max: 400 }),
            },
          }}
        />
      </FormField>
    </>
  )
}
