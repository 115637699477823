import PropTypes from 'prop-types'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import WidgetSection from '../Widget/Section'
import Section from './Section'

export default function WidgetTimeline ({
  widget,
  defaultValues,
  dialog,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="widgetTimelineForm"
      dialog={dialog}
    >
      <WidgetSection
        scope="widget"
      />

      <Section
        scope="config"
        title={intl.t(widget.info.name)}
        description={intl.t('widgets.timeline.titleDescr')}
      />
    </FormDialog>
  )
}

WidgetTimeline.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  widget: PropTypes.object.isRequired,
  defaultValues: PropTypes.object,
}

WidgetTimeline.defaultProps = {
  defaultValues: undefined,
}
