import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import FormDialog from '@/components/Form/FormDialog'
import { Experiment as IExperiment } from '@wiz/store'
import { Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { wizataApi } from '@/api'
import Removed from '../Removed'
import Section from './Section'
import SectionTwins from './SectionTwins'

export default function Experiment ({
  id,
  dialog,
  onClose,
  onSubmit,
  templateId,
}) {
  const intl = useIntl()

  const { data: item, isLoading } = useQuery({
    queryKey: [ 'experiment', id ],
    queryFn: () => wizataApi.experiments.getById(id),
    refetchOnWindowFocus: false,
    enabled: !!id,
    staleTime: null,
  })

  const defaultValues = useMemo(() => ({
    experiment: {
      ...item,
      templateId: templateId || item?.templateId,
    },
    twins: item?.twinId ?? '',
  }), [ item, templateId ])

  if (isLoading) {
    return (
      <div className="position-absolute-fill position-center-fill bg-light opacity-50">
        <Icon name="fa--spinner" size="2X" spin />
      </div>
    )
  }

  if (id && !item) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="experimentForm"
      dialog={dialog}
    >
      <Section scope="experiment" templateId={templateId} />

      <SectionTwins
        title={intl.t('experiments.form.fields.twins')}
        description={intl.t('experiments.form.fields.twinsDescr')}
        scope="twins"
        templateId={templateId}
      />
    </FormDialog>
  )
}
