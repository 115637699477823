import { Routes, Switch } from '@/router'
import PrivateView from '@/containers/Main/PrivateView'
import RoutePrivate from '@/components/RoutePrivate'
import RoutePublic from '@/components/RoutePublic'
import UserLogin from '@/components/User/Login'
import UserLogoutcb from '@/containers/User/Logoutcb'

const RouteComponents = {
  login: UserLogin,
  logoutcb: UserLogoutcb,
}

export default function View () {
  const routes = Routes.filter(item => (
    !item.private &&
    RouteComponents[item.name]
  ))

  return (
    <Switch>
      {routes.map(item => (
        <RoutePublic
          key={item.path}
          path={item.path}
          render={() => {
            const Component = RouteComponents[item.name]
            return <Component />
          }}
          exact
        />
      ))}

      <RoutePrivate path="/:lang?/*" render={() => <PrivateView />} />
    </Switch>
  )
}
