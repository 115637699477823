import classnames from 'classnames'
import { Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Link from '@/components/Link'

export default function ItemTreeContent ({
  data,
  dataContext,
  context,
  search,
  className,
  ContentDefault,
}) {
  const intl = useIntl()
  const item = data.payload || data
  const { nestedId } = data
  const { onAction } = context
  const isHomepage = onAction(null, 'checkHomepage', data)
  const isTwinGraph = item.type === 'twinGraph'
  const isNestedTwinGraph = item.type === 'nestedTwinGraph'

  return (
    <>
      {isNestedTwinGraph ? (
        <Icon name="flow" className="me-2 text-black-50" />
      ) : null}

      <Link
        className={classnames('d-flex align-items-center min-w-0', {
          'text-reset': isTwinGraph && !isHomepage,
          'text-primary': isTwinGraph && isHomepage,
          'fw-bold': isTwinGraph,
          'text-black-50': isNestedTwinGraph,
        })}
        name="chart-view"
        params={{ id: nestedId || dataContext.id }}
        onClick={event => onAction(event, 'chartView')}
      >
        <ContentDefault
          color={isNestedTwinGraph ? undefined : item.color}
          icon={isNestedTwinGraph ? undefined : item.icon}
          name={dataContext.name || item.name || intl.t('form.noname')}
          search={search}
          className={className}
        />
      </Link>

      <button
        className="hover-opacity btn btn-flat-secondary p-2 ms-auto"
        type="button"
        onClick={(event) => {
          event.stopPropagation()
          onAction(event, 'more', data)
        }}
      >
        <Icon name="fa--ellipsis-h" />
      </button>
    </>
  )
}
