import { useContext } from 'react'
import { AppContext } from '@/pages/context'
import { FormInputSearch, FormSelectDateTimeRange } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import FormatDateTime from '@/containers/FormatDateTime'
import ListFilters from '@/components/Form/ListFilters'

export default function Filters () {
  const { filters, onFilter } = useContext(AppContext)

  const intl = useIntl()

  return (
    <ListFilters
      filters={filters}
      onFilter={onFilter}
    >
      {({ filters, onFilter }) => (
        <>
          {/* <div className="px-md-2 mb-2 col-md-3">
            <FormInputSearch
              placeholder={intl.t('form.actions.searchPlaceholder')}
              value={filters.search}
              onChange={value => onFilter({ search: value })}
            />
          </div> */}

          <div className="me-1 mb-2 col-md-3">
            <FormInputSearch
              placeholder={intl.t('form.actions.recipientPlaceholder')}
              value={filters.recipient}
              onChange={value => onFilter({ recipient: value })}
            />
          </div>

          <div className="mx-1 mb-2 col-md-3">
            <FormInputSearch
              placeholder={intl.t('form.actions.notificationSheetNamePlaceholder')}
              value={filters.notificationSheetName}
              onChange={value => onFilter({ notificationSheetName: value })}
            />
          </div>

          <div className="mx-1 mb-2 col-md-auto">
            <FormSelectDateTimeRange
              className="w-auto"
              placeholderFrom="From"
              placeholderTo="To"
              value={[ filters.from, filters.to ]}
              DateTimeComponent={FormatDateTime}
              onChange={([ from, to ]) => onFilter({ from, to })}
            />
          </div>
        </>
      )}
    </ListFilters>
  )
}
