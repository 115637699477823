import { Controller, useFormContext } from 'react-hook-form'
import { consts } from '@wiz/utils'
import SelectFiles from '@/components/Form/SelectFiles'

export default function SectionFiles ({
  refFiles,
  meta,
}) {
  const { watch } = useFormContext()
  const description = watch('description') || ''
  const projectIds = watch('projects') || []

  return (
    <Controller
      name="files"
      render={({ field, fieldState }) => (
        <SelectFiles
          {...field}
          className="uploader-select-files"
          invalid={fieldState.invalid}
          ref={refFiles}
          meta={{
            fileType: consts.FileType.UserFile,
            description,
            projectIds,
            ...meta,
          }}
        />
      )}
    />
  )
}
