import { useCallback, useMemo } from 'react'
import { dbProvider } from '@wiz/store'
import { uniq } from '@wiz/utils'
import { withObservables, withProps } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { SensorDataTypes } from '@/config'
import Component from '@/components/Form/DataSourceExplorer'

const enhanceSettings = withObservables([], () => ({
  settings: dbProvider.observeSettings([
    'DataSourcesActions',
  ]),
}))

const enhanceData = withProps(({ settings, onChange }) => {
  const intl = useIntl()

  const actions = useMemo(() => {
    const data = settings.DataSourcesActions
      .filter(item => SensorDataTypes.includes(item))
      .map(item => ({
        id: item,
        title: intl.t(`enum.dataTypes.${item}`),
        name: item,
        params: { dataType: item },
      }))

    const more = SensorDataTypes
      .filter(item => !settings.DataSourcesActions.includes(item))
      .map(item => ({
        id: item,
        title: intl.t(`enum.dataTypes.${item}`),
        name: item,
        params: { dataType: item },
      }))

    if (more.length) {
      data.unshift({
        id: 'more',
        icon: 'fa--ellipsis-h',
        actions: more,
      })
    }

    return data
  }, [ intl, settings.DataSourcesActions ])

  const handleChange = useCallback(async (sensorId, action) => {
    onChange?.(action ? {
      ...action.params,
      ...(sensorId ? { sensorId } : {}),
    } : null)

    if (action?.id && SensorDataTypes.includes(action.id)) {
      const next = uniq([ action.id ].concat(settings.DataSourcesActions)).slice(0, 4)
      const context = dbProvider.createBatchContext()
      await dbProvider.prepareReplaceSetting(context, 'DataSourcesActions', next)
      await dbProvider.batch(context)
    }
  }, [ settings, onChange ])

  return {
    actions,
    onChange: handleChange,
  }
})

export default enhanceSettings(
  enhanceData(Component),
)
