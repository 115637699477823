import { useMemo } from 'react'
import classnames from 'classnames'
import { Table as StaticTable, Pagination, Badge } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { isEmpty } from '@wiz/utils'
import { useAuth } from '@/auth'

function Detail ({ row }) {
  const intl = useIntl()

  if (isEmpty(row.original.diagnostics)) {
    return intl.t('edge.modules.table.columns.emptyDiagnostics')
  }

  return (
    <div>
      {Object.keys(row.original.diagnostics).map(key => (
        <div key={key} className="row">
          <div className="col-12 col-sm-3 fw-bold">
            {intl.t(`edge.form.${key}`)}
          </div>
          <div className="col-12 col-sm-9">
            {row.original.diagnostics[key]}
          </div>
        </div>
      ))}
    </div>
  )
}

export default function TableModules ({
  items,
  onEdit,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      Header: intl.t('edge.modules.form.fields.moduleId'),
      accessor: 'moduleId',
      width: 300,
    },
    {
      Header: intl.t('edge.modules.form.fields.type'),
      accessor: 'type',
      width: 180,
    },
    {
      Header: intl.t('edge.modules.form.fields.includedToDeploy'),
      accessor: 'includedToDeploy',
      className: 'justify-content-center',
      Cell: ({ cell }) => (cell.value ? 'Yes' : 'No'),
    },
    {
      Header: intl.t('edge.modules.form.fields.reportedByDevice'),
      accessor: 'reportedByDevice',
      className: 'justify-content-center',
      Cell: ({ cell }) => (cell.value ? 'Yes' : 'No'),
    },
    {
      Header: intl.t('edge.modules.form.fields.runtimeStatus'),
      accessor: 'runtimeStatus',
      className: 'justify-content-center',
      width: 100,
    },
    {
      Header: intl.t('edge.modules.form.fields.exitCode'),
      accessor: 'exitCode',
      className: 'justify-content-center',
      width: 80,
    },
    {
      Header: intl.t('edge.modules.form.fields.configStatus'),
      accessor: 'configStatus',
      className: 'justify-content-center',
      width: 100,
      Cell: ({ cell }) => (
        <Badge
          className={classnames({
            'bg-secondary': cell.value !== 'synced' && cell.value !== 'failedToSync',
            'bg-success': cell.value === 'synced',
            'bg-danger': cell.value === 'failedToSync',
          })}
        >
          {cell.value}
        </Badge>
      ),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 60,
      width: 60,
      maxWidth: 60,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell, row }) => {
        if (
          row.original.moduleId === '$edgeAgent' ||
          row.original.moduleId === '$edgeHub'
        ) {
          return null
        }

        return auth.checkAccessUpdate(row.original) ? (
          <button
            type="button"
            className="btn btn-sm p-0"
            onClick={() => onEdit?.(cell.value)}
          >
            {intl.t('edge.modules.form.actions.config')}
          </button>
        ) : null
      },
    },
  ], [ intl, onEdit, auth ])

  return (
    <>
      <StaticTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        selection={false}
        columns={columns}
        data={items}
        ExpandeComponent={Detail}
        expande
        {...props}
      />

      <Pagination {...props} />
    </>
  )
}
