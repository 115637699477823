import { Controller, useFormContext } from 'react-hook-form'
import { FormFieldInline } from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import SelectMeasurements from './SelectMeasurements'

export default function FieldsMeasurements ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <FormFieldInline
      errors={get(errors, scope)}
      complex
    >
      <Controller
        name={scope}
        rules={{
          validate: {
            empty: (value) => {
              const emptyLabel = value.some(item => (
                item.type === consts.QualityDataTemplateMeasurementType.Label &&
                !item.labelId
              ))
              const emptySensor = value.some(item => (
                item.type === consts.QualityDataTemplateMeasurementType.Sensor &&
                !item.sensorId
              ))

              if (emptyLabel || emptySensor) {
                return intl.t('tmplQualityData.form.errors.notFilled')
              }

              return true
            },
            error: value => !value.some(item => item.error),
          },
        }}
        render={({ field, fieldState }) => (
          <SelectMeasurements
            {...field}
            invalid={fieldState.invalid}
            placeholder={intl.t('form.fields.measurementsPlaceholder')}
          />
        )}
      />
    </FormFieldInline>
  )
}
