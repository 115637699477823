export default function experiments (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      executions: {
        enumerable: true,
        value: Object.create({}, {
          getListByExperiment: {
            enumerable: true,
            value (id) {
              const request = client.get(`/execute/?experimentId=${id}`)
              return request.fetch()
            },
          },
          getListByTemplate: {
            enumerable: true,
            value (id) {
              const request = client.get(`/execute/?templateId=${id}`)
              return request.fetch()
            },
          },
          getById: {
            enumerable: true,
            value (id) {
              const request = client.get(`/execute/${id}/`)
              return request.fetch()
            },
          },
          getList: {
            enumerable: true,
            value ({
              createdDate, templateId, page, size, direction, sort, pipelineId,
            }) {
              let query = ''
              if (createdDate) {
                query += `createdDate=${createdDate}&`
              }
              if (templateId) {
                query += `templateId=${templateId}&`
              }
              if (page) {
                query += `page=${page}&`
              }
              if (size) {
                query += `size=${size}&`
              }
              if (direction) {
                query += `direction=${direction}&`
              }
              if (sort) {
                query += `sort=${sort}&`
              }
              if (pipelineId) {
                query += `pipelineId=${pipelineId}`
              }
              const request = client.get(`/execute/filter?${query}`)
              return request.fetch()
            },
          },
          create: {
            enumerable: true,
            value (params, mode) {
              let query = ''
              if (mode) {
                query += `/?mode=${mode}`
              }
              return client.post(`/execute${query}`, { ...params }).fetch()
            },
          },
          deleteById: {
            enumerable: true,
            value (id) {
              return client.delete(`/execute/${id}`).fetch()
            },
          },
          test: {
            enumerable: true,
            value (params) {
              return client.post('/execute/test', { ...params }).fetch()
            },
          },
          validate: {
            enumerable: true,
            value (params) {
              return client.post('/execute/validate', { ...params }).fetch()
            },
          },
        }),
      },
      experiments: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value (params) {
              const { key, templateId } = params || {}
              let query = ''
              if (key) {
                query += `key=${key}&`
              }
              if (templateId) {
                query += `templateId=${templateId}`
              }
              return client.get(`/experiments/?${query}`).fetch()
            },
          },
          getById: {
            enumerable: true,
            value (id) {
              return client.get(`/experiments/${id}/`).fetch()
            },
          },
          create: {
            enumerable: true,
            value (params) {
              return client.post('/experiments', { ...params }).fetch()
            },
          },
          update: {
            enumerable: true,
            value (params) {
              return client.put(`/experiments/${params.id}/`, { ...params }).fetch()
            },
          },
          checkKey: {
            enumerable: true,
            value (key) {
              return client.get(`/experiments/?key=${key}`).fetch()
            },
          },
          deleteById: {
            enumerable: true,
            value (id) {
              return client.delete(`/experiments/${id}`).fetch()
            },
          },
        }),
      },
    })
  }
}
