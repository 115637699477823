import { FormSection } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Fields from './Fields'

export default function SectionList ({ scope, integration }) {
  const intl = useIntl()

  return (
    <FormSection
      title={intl.t('integration.section.creds')}
      description={intl.t('integration.section.credsDescr')}
    >
      <Fields scope={scope} integration={integration} />
    </FormSection>

  )
}
