import { useMemo } from 'react'
import { EdgeModule as EdgeModule$ } from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import Section from './Section'
import Removed from '../Removed'

export default function EdgeModule ({
  id,
  edgeModule,
  dialog,
  onClose,
  onSubmit,
}) {
  const defaultValues = useMemo(() => ({
    edgeModule: EdgeModule$.toJSON(edgeModule),
  }), [ edgeModule?.updatedAt ?? edgeModule ])

  if (id && !edgeModule) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="edgeModuleForm"
      dialog={dialog}
    >
      <Section
        scope="edgeModule"
      />
    </FormDialog>
  )
}
