import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Icon } from '@wiz/components'
import {
  DiagramBlock,
  DiagramBlockFlow,
  Event,
  Twin,
} from '@wiz/store'
import OptionEvent from './OptionEvent'
import OptionTwin from './OptionTwin'
import OptionBlock from './OptionBlock'
import OptionFlow from './OptionFlow'
import OptionDefault from './OptionDefault'

export default function Option (props) {
  const {
    isOpen,
    style,
    toggle,
    data: {
      isLeaf,
      nestingLevel,
      payload: item,
    },
  } = props
  let Component = OptionDefault

  if (item instanceof Event) {
    Component = OptionEvent
  } else if (item instanceof DiagramBlock) {
    Component = OptionBlock
  } else if (item instanceof DiagramBlockFlow) {
    Component = OptionFlow
  } else if (item instanceof Twin) {
    Component = OptionTwin
  }

  return (
    <div
      className={classnames('list-group-item d-flex', {
        'bg-light': (item instanceof DiagramBlock),
      })}
      style={{
        ...style,
        paddingLeft: `${nestingLevel * 1.5 + (isLeaf ? 1 : 0)}rem`,
      }}
    >
      {isLeaf ? null : (
        <button
          type="button"
          className="btn btn-sm btn-text align-self-start p-0 mx-1"
          onClick={toggle}
        >
          <Icon
            name="fa--caret-right"
            className={classnames('arrow', {
              'arrow-open': isOpen,
            })}
          />
        </button>
      )}

      <Component {...props} />
    </div>
  )
}

Option.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  style: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  data: PropTypes.shape({
    isLeaf: PropTypes.bool.isRequired,
    nestingLevel: PropTypes.number.isRequired,
    payload: PropTypes.object.isRequired,
  }).isRequired,
}
