import { Controller, useFormContext } from 'react-hook-form'
import {
  FormField,
  FormFieldInline,
  FormSelect,
  FormIndeterminateCheckbox,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { ChartTypes, ChartTypesGroup } from '@/config'

const FormFieldRatio = [ 6, 6 ]

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors }, watch } = useFormContext()
  const isGroup = !watch(`${scope}.sourceId`)
  const chartTypes = isGroup ? ChartTypesGroup : ChartTypes

  return (
    <>
      {isGroup ? (
        <FormField
          size="sm"
          className="mb-2"
          ratio={FormFieldRatio}
          label={intl.t('form.fields.role')}
          errors={get(errors, `${scope}.role`)}
        >
          <Controller
            name={`${scope}.role`}
            render={({ field, fieldState }) => (
              <FormSelect
                {...field}
                invalid={fieldState.invalid}
                placeholder="--"
                options={[{ id: 'grid', name: 'Grid' }]}
              />
            )}
          />
        </FormField>
      ) : null}

      <FormField
        size="sm"
        className="mb-2"
        ratio={FormFieldRatio}
        label={intl.t('form.fields.type')}
        errors={get(errors, `${scope}.chartType`)}
      >
        <Controller
          name={`${scope}.chartType`}
          render={({ field, fieldState }) => (
            <FormSelect
              {...field}
              invalid={fieldState.invalid}
              options={chartTypes.map(id => ({ id, name: intl.t(`enum.chart.${id}`) }))}
              {...(isGroup ? {
                placeholder: '--',
              } : null)}
            />
          )}
        />
      </FormField>

      {!isGroup ? (
        <>
          <FormFieldInline
            size="sm"
            className="mb-2"
            label={intl.t('form.fields.showDataSymbol')}
            errors={get(errors, `${scope}.showDataSymbol`)}
          >
            <Controller
              name={`${scope}.showDataSymbol`}
              render={({ field, fieldState }) => (
                <FormIndeterminateCheckbox
                  {...field}
                  invalid={fieldState.invalid}
                />
              )}
            />
          </FormFieldInline>

          <FormFieldInline
            size="sm"
            className="mb-2"
            label={intl.t('form.fields.interpolate')}
            errors={get(errors, `${scope}.interpolate`)}
          >
            <Controller
              name={`${scope}.interpolate`}
              render={({ field, fieldState }) => (
                <FormIndeterminateCheckbox
                  {...field}
                  invalid={fieldState.invalid}
                />
              )}
            />
          </FormFieldInline>
        </>
      ) : null}
    </>
  )
}
