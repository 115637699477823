import {
  Controller,
  useFormContext,
} from 'react-hook-form'
import { DateTime } from 'luxon'
import { get, consts } from '@wiz/utils'
import {
  FormField,
  FormControl,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import ProjectList from '@/hoc/ProjectList'
import FileList from '@/hoc/FileList'
import TimeZoneList from '@/hoc/TimeZoneList'
import Select from '@/components/Form/Select'
import SelectTwin from '@/components/Form/SelectTwin'

const now = DateTime.local()
const DateTimeFormats = [
  'dd/MM/yyyy HH:mm:ss.ffffff',
  'dd/MM/yyyy hh:mm:ss.ffffff tt',
  'MM/dd/yyyy HH:mm:ss.ffffff',
  'MM/dd/yyyy hh:mm:ss.ffffff tt',
  'dd.MM.yyyy HH:mm:ss.ffffff',
  'dd.MM.yyyy hh:mm:ss.ffffff tt',
  'yyyy/MM/ddTHH:mm:ss.ffffff',
  'yyyy/MM/dd HH:mm:ss.ffffff',
  'yyyy/MM/dd hh:mm:ss.ffffff tt',
  'yyyy-MM-dd HH:mm:ss.ffffff',
  'yyyy-MM-dd hh:mm:ss.ffffff tt',
  'dd MMM yyyy HH:mm:ss.ffffff',
  'dd MMM yyyy hh:mm:ss.ffffff tt',
].map(item => ({
  id: item,
  name: now.toFormat(
    item
      .replace('tt', 'a')
      .replace('ffffff', 'SSS000')
      .replace('T', '\'T\''),
  ),
}))

export default function Fields ({
  scope,
  disabled,
  twins,
}) {
  const intl = useIntl()
  const auth = useAuth()

  const {
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('jobs.form.fields.importNameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
            maxLength: {
              value: 200,
              message: intl.t('form.errors.fieldMaxlen', { max: 200 }),
            },
            minLength: {
              value: 5,
              message: intl.t('form.errors.fieldMinlen', { min: 5 }),
            },
          }}
          placeholder={intl.t('jobs.form.fields.namePlaceholder')}
        />
      </FormField>

      {auth.checkAccessRead('Project') ? (
        <FormField
          label={intl.t('form.fields.projects')}
          description={intl.t('jobs.form.fields.importProjectsDescr')}
          errors={get(errors, 'projects')}
        >
          <Controller
            name="projects"
            render={({ field, fieldState }) => (
              <Select
                {...field}
                invalid={fieldState.invalid}
                ListSource={ProjectList}
                placeholder={intl.t('form.fields.projects')}
                multiselect
              />
            )}
          />
        </FormField>
      ) : null}

      <FormField
        label={intl.t('form.fields.files')}
        description={intl.t('jobs.form.fields.filesDescr')}
        errors={get(errors, 'files')}
      >
        <Controller
          name="files"
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              ListSource={FileList}
              placeholder={intl.t('form.fields.filesPlaceholder')}
              disabled={disabled}
              multiselect
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.sourceTimeZone')}
        description={intl.t('jobs.form.fields.sourceTimeZoneDescr')}
        errors={get(errors, `${scope}.parameters.sourceTimeZone`)}
      >
        <Controller
          name={`${scope}.parameters.sourceTimeZone`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              ListSource={TimeZoneList}
              placeholder={intl.t('form.fields.sourceTimeZonePlaceholder')}
              disabled={disabled}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.dateTimeFormat')}
        description={intl.t('jobs.form.fields.dateTimeFormatDescr')}
        errors={get(errors, `${scope}.parameters.dateTimeFormat`)}
      >
        <FormControl
          type="select"
          name={`${scope}.parameters.dateTimeFormat`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          placeholder={intl.t('form.fields.dateTimeFormatPlaceholder')}
          options={DateTimeFormats}
          disabled={disabled}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.decimalSeparator')}
        description={intl.t('jobs.form.fields.decimalSeparatorDescr')}
        errors={get(errors, `${scope}.parameters.decimalSeparator`)}
      >
        <FormControl
          type="select"
          name={`${scope}.parameters.decimalSeparator`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          placeholder={intl.t('form.fields.decimalSeparatorPlaceholder')}
          options={consts.DecimalSeparators.map(id => ({ id, name: id }))}
          disabled={disabled}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.csvSeparator')}
        description={intl.t('jobs.form.fields.csvSeparatorDescr')}
        errors={get(errors, `${scope}.parameters.csvSeparator`)}
      >
        <FormControl
          type="select"
          name={`${scope}.parameters.csvSeparator`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          placeholder={intl.t('form.fields.csvSeparatorPlaceholder')}
          options={consts.CsvSeparators.map(id => ({ id, name: id }))}
          disabled={disabled}
        />
      </FormField>

      <FormField
        label={intl.t('jobs.form.fields.twinId')}
        description={intl.t('jobs.form.fields.twinIdDescr')}
        errors={get(errors, `${scope}.parameters.twinId`)}
      >
        <Controller
          name={`${scope}.parameters.twinId`}
          rules={twins?.length ? {
            required: intl.t('form.errors.fieldRequired'),
          } : undefined}
          render={({ field, fieldState }) => (
            <SelectTwin
              {...field}
              invalid={fieldState.invalid}
              disabled={disabled}
              placeholder={intl.t('form.fields.twinPlaceholder')}
            />
          )}
        />
      </FormField>
    </>
  )
}
