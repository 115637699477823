import { FormSelectDateTimeRange, Icon, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import SelectTwin from '@/components/Form/SelectTwin'
import FormatDateTime from '@/containers/FormatDateTime'
import Table from '@/containers/Notification/Table'
import TableMobile from '@/containers/Notification/TableMobile'

export default function List ({
  filters,
  forceUpdate,
  onFilter,
  onResetFilter,
  disableNotifications,
  onToggleAcknowledge,
  onToggleDisableNotifications,
  ...props
}) {
  const intl = useIntl()
  const isMobile = useMobile()

  const TableComponent = isMobile ? TableMobile : Table

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">
      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <div className="d-flex align-items-center flex-wrap">
          <button
            type="button"
            className="btn btn-flat-secondary mb-2 ms-2"
            onClick={onToggleDisableNotifications}
          >
            <Icon
              name={disableNotifications ? 'fa--bell-slash' : 'fa--bell'}
              size="lg"
            />
          </button>
          <div className="px-2 mb-2">
            <FormSelectDateTimeRange
              className="w-auto"
              placeholderFrom={intl.t('form.fields.from')}
              placeholderTo={intl.t('form.fields.to')}
              value={[ filters.from, filters.to ]}
              DateTimeComponent={FormatDateTime}
              onChange={([ from, to ]) => onFilter({ from, to })}
            />
          </div>

          <div className="px-2 d-flex flex-wrap">
            <SelectTwin
              className="me-2 mb-2 w-auto"
              placeholder={intl.t('form.fields.twinPlaceholder')}
              value={filters.hardwareId}
              keyName="hardwareId"
              filters={{
                withHardware: true,
                withSensors: true,
                types: [],
              }}
              onChange={hardwareId => onFilter({ hardwareId })}
            />

            <button
              type="button"
              className="btn btn-fill-secondary me-2 mb-2"
              onClick={onResetFilter}
            >
              {intl.t('form.actions.resetFilters')}
            </button>

            <button
              type="button"
              className="btn btn-fill-secondary mb-2"
              onClick={forceUpdate}
            >
              {intl.t('form.actions.refresh')}
            </button>

            {isMobile ? (
              <button
                type="button"
                className="btn btn-fill-secondary mb-2 ms-2 btn-text"
                onClick={onToggleDisableNotifications}
              >
                <Icon
                  name={disableNotifications ? 'fa--bell-slash' : 'fa--bell'}
                  size="lg"
                />
              </button>
            ) : null}
          </div>
        </div>

        <TableComponent
          {...props}
          filters={filters}
          onFilter={onFilter}
          onToggleAcknowledge={onToggleAcknowledge}
        />
      </div>
    </div>
  )
}
