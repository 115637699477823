import { useFormContext } from 'react-hook-form'
import {
  FormField,
  FormFieldInline,
  FormControl,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function FieldsView ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('widgets.timeline.form.fields.plotVariable')}
        description={intl.t('widgets.timeline.form.fields.plotVariableDescr')}
        errors={get(errors, `${scope}.plotVariable`)}
      >
        <FormControl
          name={`${scope}.plotVariable`}
          placeholder="fig"
        />
      </FormField>

      <FormField
        label={intl.t('widgets.timeline.form.fields.dataFrameVariable')}
        description={intl.t('widgets.timeline.form.fields.dataFrameVariableDescr')}
        errors={get(errors, `${scope}.dataFrameVariable`)}
      >
        <FormControl
          name={`${scope}.dataFrameVariable`}
          placeholder="df"
        />
      </FormField>

      <FormFieldInline
        label={intl.t('widgets.timeline.form.fields.script')}
        description={intl.t('widgets.timeline.form.fields.scriptDescr')}
        errors={get(errors, `${scope}.script`)}
        vertical
      >
        <FormControl
          type="code"
          title={intl.t('widgets.timeline.form.fields.script')}
          description={intl.t('widgets.timeline.form.fields.scriptDescr')}
          name={`${scope}.script`}
        />
      </FormFieldInline>
    </>
  )
}
