export default function dashboardsComponents (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      dashboardsComponents: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value (params) {
              const request = client.post('/Components/paged', { ...params })
              return request.fetch()
            },
          },

          getById: {
            enumerable: true,
            value (id) {
              return client.get(`/Components/${id}`).fetch()
            },
          },

          getListTwinId: {
            enumerable: true,
            value (twinId, includeSubChildren = false) {
              return client.get(`/Components/twins/${twinId || ''}?includeSubChildren=${includeSubChildren}`).fetch()
            },
          },

          deleteById: {
            enumerable: true,
            value (id) {
              return client.delete(`/Components/${id}`).fetch()
            },
          },
          create: {
            enumerable: true,
            value (params) {
              return client.post('/Components', { ...params }).fetch()
            },
          },
          update: {
            enumerable: true,
            value (id, params) {
              return client.put(`/Components/${id}`, { ...params }).fetch()
            },
          },
          createDashboardNative: {
            enumerable: true,
            value (params) {
              return client.post('/Components/dashboard', { ...params }).fetch()
            },
          },
          duplicate: {
            enumerable: true,
            value (params) {
              return client.post('/Components/duplication', { ...params }).fetch()
            },
          },
        }),
      },
    })
  }
}
