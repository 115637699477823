import { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Tabs } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import WidgetSection from '@/components/Forms/Widget/Section'
import SectionData from '@/components/Forms/BulletPlotSource/SectionData'
import SectionRange from '@/components/Forms/BulletPlotSource/SectionRange'
import SectionSources from './SectionSources'
import SectionView from './SectionView'
import Schema from './Schema'

export default function WidgetDynamicBulletPlot ({
  defaultValues,
  dialog,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()
  const [ tab, setTab ] = useState('data')

  return (
    <FormDialog
      classNameBody="overflow-hidden p-0"
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      schema={Schema}
      dataTestid="widgetDynamicBulletPlotForm"
      dialog={dialog}
    >
      <Tabs
        className="mx-3"
        value={tab}
        options={[
          {
            value: 'data',
            label: intl.t('widgets.dynamicBulletPlot.form.fields.dataSourceSection'),
          },
          {
            value: 'percentiles',
            label: intl.t('widgets.dynamicBulletPlot.form.fields.percentilesSection'),
          },
          {
            value: 'values',
            label: intl.t('widgets.dynamicBulletPlot.form.fields.valuesSection'),
          },
          {
            value: 'view',
            label: intl.t('widgets.dynamicBulletPlot.form.fields.viewSection'),
          },
        ]}
        onChange={setTab}
      />

      <div
        className={classnames('min-h-0 overflow-auto px-3 flex-fill d-flex flex-column', {
          'd-none': tab !== 'data',
        })}
      >
        <SectionSources scope="config" />
      </div>

      <div
        className={classnames('min-h-0 overflow-auto px-3 flex-fill d-flex flex-column', {
          'd-none': tab !== 'percentiles',
        })}
      >
        <SectionRange
          description={intl.t('widgets.dynamicBulletPlot.form.fields.percentilesSectionDescr')}
          scope="config"
        />
      </div>

      <div
        className={classnames('min-h-0 overflow-auto px-3 flex-fill d-flex flex-column', {
          'd-none': tab !== 'values',
        })}
      >
        <SectionData
          description={intl.t('widgets.dynamicBulletPlot.form.fields.valuesSectionDescr')}
          scope="config"
        />
      </div>

      <div
        className={classnames('min-h-0 overflow-auto px-3 flex-fill d-flex flex-column', {
          'd-none': tab !== 'view',
        })}
      >
        <WidgetSection scope="widget" />
        <SectionView scope="config" />
      </div>
    </FormDialog>
  )
}

WidgetDynamicBulletPlot.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
  dialog: PropTypes.object,
}

WidgetDynamicBulletPlot.defaultProps = {
  defaultValues: undefined,
  dialog: undefined,
}
