import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import { get } from '@wiz/utils'
import {
  FormField, FormControl, FormFieldInline, FormCheckbox,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors }, getValues } = useFormContext()

  const values = getValues()
  return (
    <>

      <FormFieldInline
        errors={get(errors, `${scope}`)}
      />
      <pre className="overflow-auto form-control mt-2" style={{ maxHeight: 500 }}>
        {JSON.stringify(values, null, 2)}
      </pre>
    </>
  )
}

Fields.propTypes = {
  scope: PropTypes.string.isRequired,
}
