import { useCallback } from 'react'
import { consts } from '@wiz/utils'
import { withProps } from '@wiz/components'
import {
  Q,
  dbProvider,
  DataSource,
  DataView,
} from '@wiz/store'

const enhanceProps = withProps(({ widget, config }) => {
  const onSubmit = useCallback(async (data) => {
    if (widget) {
      const dataViews = []
      const dataSources = []

      if (data.config.sensorIds?.length) {
        const sensors = await dbProvider.database.collections.get('sensors')
          .query(Q.where('id', Q.oneOf(data.config.sensorIds)))
          .fetch()

        for (const sensor of sensors) {
          let dataSource = config.dataSources.find(item => (
            item.sensorId === sensor.id &&
            item.dataType === 'last'
          ))

          if (!dataSource) {
            dataSource = DataSource.toJSON({ sensorId: sensor.id, dataType: 'last' })
          }

          let dataView = config.dataViews.find(item => (
            item.sourceId === dataSource.id
          ))

          if (!dataView) {
            dataView = DataView.toJSON({ sourceId: dataSource.id })
          }

          dataSources.push(dataSource)
          dataViews.push(dataView)
        }
      }

      if (data.config.labelIds?.length) {
        const labels = await dbProvider.database.collections.get('labels')
          .query(Q.where('id', Q.oneOf(data.config.labelIds)))
          .fetch()

        for (const label of labels) {
          let dataSource = config.dataSources.find(item => (
            item.settings?.label?.id === label.id &&
            item.type === consts.DataSourceType.Label
          ))

          if (!dataSource) {
            dataSource = DataSource.toJSON({
              dataType: 'last',
              type: consts.DataSourceType.Label,
              settings: {
                id: label.id,
                label: label.toJSON(),
              },
            })
          }

          let dataView = config.dataViews.find(item => (
            item.sourceId === dataSource.id
          ))

          if (!dataView) {
            dataView = DataView.toJSON({ sourceId: dataSource.id })
          }

          dataSources.push(dataSource)
          dataViews.push(dataView)
        }
      }

      if (data.config.qualityTemplateIds?.length) {
        const qualityDataTemplates = await dbProvider.database.collections.get('quality_data_templates')
          .query(Q.where('id', Q.oneOf(data.config.qualityTemplateIds)))
          .fetch()

        for (const qualityDataTemplate of qualityDataTemplates) {
          let dataSource = config.dataSources.find(item => (
            item.settings?.qualityDataTemplate?.id === qualityDataTemplate.id &&
            item.type === consts.DataSourceType.QualityDataTemplate
          ))

          if (!dataSource) {
            dataSource = DataSource.toJSON({
              dataType: 'last',
              type: consts.DataSourceType.QualityDataTemplate,
              settings: {
                id: qualityDataTemplate.id,
                qualityDataTemplate: qualityDataTemplate.toJSON(),
              },
            })
          }

          let dataView = config.dataViews.find(item => (
            item.sourceId === dataSource.id
          ))

          if (!dataView) {
            dataView = DataView.toJSON({ sourceId: dataSource.id })
          }

          dataSources.push(dataSource)
          dataViews.push(dataView)
        }
      }

      const context = dbProvider.createBatchContext()
      await widget.prepareUpdateData(context, {
        ...data.widget,
        config: {
          utcTime: data.config.utcTime,
          msTime: data.config.msTime,
        },
      })
      await widget.prepareReplaceFilter(context, data.config.dataFilter)
      await widget.prepareReplaceDataViews(context, dataViews, dataSources)
      await widget.prepareReplaceConditions(context, data.config.conditions)

      context.on('success', async (successBatchContext) => {
        await widget.prepareReplaceDataSourceSettings(successBatchContext, dataSources)
      })

      await dbProvider.batch(context)
    }
  }, [ widget, config ])

  return {
    onSubmit,
  }
})

export default function enhanceSettingsTableData (Component) {
  return enhanceProps(Component)
}
