import { useState } from 'react'
import { useIntl } from '@wiz/intl'
import { useMobile } from '@wiz/components'
import Table from '@/containers/EdgeLogs/Table'
import TableMobile from '@/containers/EdgeLogs/TableMobile'
import ViewModel from './ViewModel'
import Filters from './Filters'

export default function List ({
  filters,
  forceUpdate,
  onFilter,
  onResetFilter,
  ...props
}) {
  const intl = useIntl()
  const isMobile = useMobile()
  const [ viewData, setViewData ] = useState(null)

  const TableComponent = isMobile ? TableMobile : Table

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">

      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <div className="d-flex align-items-center flex-wrap">
          <div className="px-2 mb-2">
            <button
              type="button"
              className="btn btn-flat-secondary"
              onClick={forceUpdate}
            >
              {intl.t('form.actions.refresh')}
            </button>
          </div>

          <Filters
            filters={filters}
            onFilter={onFilter}
            onResetFilter={onResetFilter}
          />
        </div>

        <TableComponent
          {...props}
          filters={filters}
          onFilter={onFilter}
          onView={setViewData}
        />
      </div>

      {viewData ? (
        <ViewModel
          edgeLog={viewData}
          title={intl.t('edgeLogs.titleDetail')}
          onClose={() => setViewData(null)}
          onFilter={onFilter}
          dialog
        />
      ) : null}
    </div>
  )
}
