import { forwardRef } from 'react'
import { useQuery } from '@tanstack/react-query'
import { wizataApi } from '@/api'
import Select from '@/components/Form/Select'
import { duration } from '@wiz/utils'

const SelectTrigger = forwardRef(({ id, disabled, ...props }, ref) => {
  const { data: pipelines, isFetched, isFetching } = useQuery({
    queryKey: [ 'pipelines' ],
    queryFn: () => wizataApi.pipelines.getList().then((response) => {
      if (response?.length) {
        const next = response.map(item => ({ ...item, name: item.key, value: item.id }))
        return next
      }
      return response
    }),
    enabled: true,
  })
  const { data, isLoading } = useQuery({
    queryKey: [ 'triggersList' ],
    queryFn: () => wizataApi.triggersDS.getList()
      .then(list => list.map((item) => {
        let name = ''
        const interval = duration(item.interval, { sep: '' })
        const delay = duration(item.delay, { sep: '' })
        if (pipelines?.length) {
          const foundPipeline = pipelines.find(ppln => ppln.id === item.pipelineId)
          name = foundPipeline?.key
        }
        return { ...item, name: `${name} - ${interval || '0s'} - ${delay || '0s'}` }
      })),
    enabled: !!pipelines,
    refetchOnWindowFocus: false,
    retry: false,
  })

  return (
    <Select
      {...props}
      invalid={props.invalid}
      options={data}
      disabled={disabled || isLoading}
      isLoading={isLoading}
    />
  )
})

export default SelectTrigger
