import { consts } from '@wiz/utils'
import FilterSearch from '@/components/Form/FilterSearch'
import ExplorerList from '@/hoc/ExplorerList'
import List from '@/components/Form/Tree/List'
import Option from './Option'

const BindedList = ExplorerList(List)

const ExplorerDataExplorers = ({
  id,
  groupByTwinId,
  onAction,
}) => (
  <FilterSearch>
    {formData => (
      <BindedList
        {...formData}
        value={id}
        className="position-absolute-fill"
        active={data => (data.id === id)}
        groupByTwinId={groupByTwinId}
        itemSize={consts.ListItemHeightMd}
        Content={Option}
        onAction={onAction}
      />
    )}
  </FilterSearch>
)

export default ExplorerDataExplorers
