import { useMemo } from 'react'
import {
  DiagramBlock,
  Alert,
  NotificationSheet,
  NotificationSheetTarget,
} from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import BlockSection from '../Block/Section'
import SettingsSection from './SettingsSection'

export default function BlockAlert ({
  block,
  settings,
  notificationSheet,
  notificationSheetTargets,
  dialog,
  onClose,
  onSubmit,
}) {
  const immutableTargets = JSON.stringify(notificationSheetTargets.map(item => item?.updatedAt))

  const defaultValues = useMemo(() => ({
    block: DiagramBlock.toJSON(block),
    settings: {
      alert: Alert.toJSON(settings),
      notificationSheet: NotificationSheet.toJSON(notificationSheet),
      notificationSheetTargets: notificationSheetTargets.map(item => (
        NotificationSheetTarget.toJSON(item)
      )),
    },
  }), [
    block?.updatedAt ?? block,
    settings?.updatedAt ?? settings,
    notificationSheet?.updatedAt ?? notificationSheet,
    immutableTargets,
  ])

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="blockAlertForm"
      dialog={dialog}
    >
      <BlockSection scope="block" />
      <SettingsSection scope="settings" />
    </FormDialog>
  )
}
