import {
  useRef,
  useCallback,
  useState,
  useMemo,
} from 'react'
import { DragProvider } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Tree from '@/components/Form/Tree/Tree'
import ConditionForm from '@/hoc/ConditionForm'
import FormCondition from '@/components/Forms/Condition'
import ItemContent from './ItemContent'

export default function ListConditions ({
  className,
  search,
  options,
  exclude,
  readOnly,
  onChangeCondition,
  onRemoveCondition,
  onResetFilter,
  onDrop,
  ComponentConditionForm,
}) {
  const refDrag = useRef()
  const intl = useIntl()
  const [ editCondition, setEditCondition ] = useState(null)

  const Form = useMemo(() => (
    ConditionForm(ComponentConditionForm || FormCondition)
  ), [ ComponentConditionForm ])

  const handleDragStart = useCallback((data) => {
    refDrag.current.data.add(data)
  }, [])

  const handleDrop = useCallback((type, data, dragData) => {
    onDrop?.(dragData[0].payload.data, data.payload.data, type)
  }, [ onDrop ])

  const handleAction = useCallback((action, payload) => {
    if (action === 'readOnly') {
      return readOnly
    }

    if (action === 'changeCondition') {
      return onChangeCondition(payload)
    }

    if (action === 'removeCondition') {
      return onRemoveCondition(payload)
    }

    if (action === 'editCondition') {
      return setEditCondition(payload)
    }

    return undefined
  }, [
    readOnly,
    onChangeCondition,
    onRemoveCondition,
  ])

  return (
    <>
      <DragProvider
        ref={refDrag}
        name="internal"
      >
        <Tree
          className={className}
          optionClassName={() => ('d-flex align-items-center')}
          draggable={onChangeCondition && onDrop && !readOnly ? 'internal' : undefined}
          search={search}
          options={options}
          Content={ItemContent}
          checkFiltered={() => (!!search)}
          onAction={handleAction}
          onDrop={handleDrop}
          onDragStart={handleDragStart}
          onResetFilter={onResetFilter}
        />
      </DragProvider>

      {editCondition ? (
        <Form
          condition={editCondition}
          exclude={exclude}
          onSubmit={({ condition }) => {
            onChangeCondition({ ...editCondition, ...condition })
          }}
          onClose={() => setEditCondition(null)}
          dialog={{
            title: intl.t('explorer.titleUpdateCondition'),
            dataTestid: 'replaceConditionDialog',
          }}
        />
      ) : null}
    </>
  )
}
