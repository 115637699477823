import { memo } from 'react'
import enhanceContext from '@/containers/ExperimentResult/enhanceContext'
import Component from '@/components/RightBarWizard'
import enhanceResult from '@/containers/ExperimentResult/enhanceResult'
import enhanceDatapointsList from './enhanceDatapointsList'
import enhanceData from './enhanceData'
import enhanceProps from './enhanceProps'

export default memo(
  enhanceDatapointsList(
    enhanceData(
      enhanceProps(
        enhanceResult(
          enhanceContext(
            Component,
          ),
        ),
      ),
    ),
  ),
)
