import { useFormContext } from 'react-hook-form'
import { FormFieldInline, FormControl } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors }, register } = useFormContext()

  return (
    <>
      <input
        {...register(`${scope}.id`)}
        type="hidden"
      />

      <FormFieldInline
        label={intl.t('users.form.fields.active')}
        description={intl.t('users.form.fields.activeDescr')}
        errors={get(errors, `${scope}.isActive`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.isActive`}
        />
      </FormFieldInline>
    </>
  )
}
