import { useMemo } from 'react'
import {
  Table as StaticTable,
  Pagination,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import Observe from '@/containers/Observe'
import Details from './Details'

export default function Table ({
  items,
  onRemove,
  onEdit,
  onFileEdit,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      minWidth: 300,
      Cell: ({ cell, row }) => (
        auth.checkAccessUpdate(row.original) ? (
          <a
            className="text-truncate pointer"
            onClick={() => onEdit?.(row.original)}
            aria-hidden
          >
            {cell.value}
          </a>
        ) : cell.value
      ),
    },
    {
      Header: intl.t('jobs.form.fields.state'),
      accessor: 'state',
      disableResizing: true,
      minWidth: 120,
      width: 120,
      maxWidth: 120,
    },
    {
      Header: intl.t('jobs.form.fields.command'),
      accessor: 'command',
      disableResizing: true,
      minWidth: 130,
      width: 130,
      maxWidth: 130,
    },
    {
      Header: intl.t('form.fields.files'),
      disableResizing: true,
      disableSortBy: true,
      minWidth: 200,
      width: 240,
      maxWidth: 300,
      Cell: ({ row }) => (
        <Observe query={row.original.queryFiles}>
          {({ data }) => (
            <div className="d-flex flex-wrap min-w-0 align-items-center">
              {data.map((item, idx) => (
                <div key={item.id} className="d-flex me-1 min-w-0">
                  <a
                    className="text-truncate pointer"
                    aria-hidden
                    onClick={() => onFileEdit(item)}
                  >
                    {item.name}
                  </a>
                  {idx === data.length - 1 ? null : ','}
                </div>
              ))}
            </div>
          )}
        </Observe>
      ),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedAt',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: ({ cell, row }) => (<UpdatedInfo date={cell.value} user={row.original.updatedUser} />),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdAt',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: ({ cell, row }) => (<UpdatedInfo date={cell.value} user={row.original.createdUser} />),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      className: 'justify-content-end text-nowrap',
      Cell: ({ row }) => (
        <>
          {auth.checkAccessUpdate(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0 me-2"
              onClick={() => onEdit?.(row.original)}
            >
              {intl.t('form.actions.edit')}
            </button>
          ) : null}
          {auth.checkAccessRemove(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0"
              onClick={() => onRemove?.([ row.original ])}
            >
              {intl.t('form.actions.remove')}
            </button>
          ) : null}
        </>
      ),
    },
  ], [ intl, onEdit, onRemove, auth ])

  return (
    <>
      <StaticTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        expande
        ExpandeComponent={Details}
        columns={columns}
        data={items}
        selection={auth.checkAccessRemove('Job') || auth.checkAccessCreate('Job')}
        {...props}
      />

      <Pagination {...props} />
    </>
  )
}
