import { auth } from '@/auth'

const useAuthComponents = () => {
  const isPersonal = auth.checkAccessPersonal('SectionComponents')
  const isOrganizational = auth.checkAccessOrganization('SectionComponents')
  const isRead = auth.checkAccessRead('SectionComponents')
  const isPersonalStrictly = isPersonal && !isOrganizational
  const isNotManagable = !isPersonal && !isOrganizational

  const isReadOnly = isRead && isNotManagable

  // console.log('isRead', isRead)
  // console.log('isReadOnly', isReadOnly)
  // console.log('isPersonal', isPersonal)
  // console.log('isPersonalStrictly', isPersonalStrictly)
  // console.log('isOrganizational', isOrganizational)

  return {
    isRead, isReadOnly, isPersonal, isPersonalStrictly, isOrganizational,
  }
}

export default useAuthComponents
