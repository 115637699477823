import { FormSection } from '@wiz/components'
import FieldsSources from './FieldsSources'

export default function Section ({
  scope,
  title,
  description,
  sourcesValidation,
}) {
  return (
    <FormSection
      title={title}
      description={description}
    >
      <FieldsSources
        scope={scope}
        sourcesValidation={sourcesValidation}
      />
    </FormSection>
  )
}
