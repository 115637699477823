import { useFormContext } from 'react-hook-form'
import {
  FormField,
  FormFieldInline,
  FormControl,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('mlWebServices.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (!str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value.length > 450) {
                return intl.t('form.errors.fieldMaxlen', { max: 450 })
              }
              return true
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.description')}
        description={intl.t('mlWebServices.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.description`}
          rules={{
            maxLength: {
              value: 450,
              message: intl.t('form.errors.fieldMaxlen', { max: 450 }),
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.scoringUrl')}
        description={intl.t('mlWebServices.form.fields.scoringUrlDescr')}
        errors={get(errors, `${scope}.scoringUrl`)}
      >
        <FormControl
          name={`${scope}.scoringUrl`}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (!str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value.length > 450) {
                return intl.t('form.errors.fieldMaxlen', { max: 450 })
              }
              return true
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.swaggerUrl')}
        description={intl.t('mlWebServices.form.fields.swaggerUrlDescr')}
        errors={get(errors, `${scope}.swaggerUrl`)}
      >
        <FormControl
          name={`${scope}.swaggerUrl`}
          rules={{
            maxLength: {
              value: 450,
              message: intl.t('form.errors.fieldMaxlen', { max: 450 }),
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.workspaceName')}
        description={intl.t('mlWebServices.form.fields.workspaceNameDescr')}
        errors={get(errors, `${scope}.workspaceName`)}
      >
        <FormControl
          name={`${scope}.workspaceName`}
          rules={{
            maxLength: {
              value: 450,
              message: intl.t('form.errors.fieldMaxlen', { max: 450 }),
            },
          }}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.compress')}
        description={intl.t('mlWebServices.form.fields.compressDescr')}
        errors={get(errors, `${scope}.compressRequest`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.compressRequest`}
          className="ms-2"
        />
      </FormFieldInline>
    </>
  )
}
