import { of as of$ } from 'rxjs'
import { map, withLatestFrom, switchMap } from 'rxjs/operators'
import { withObservables, withProps } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import {
  Routes, matchPath, useLocation, useLocationQuery,
} from '@/router'
import Component from '@/components/Header/Breadcrumb'
import { wizataApi } from '@/api'

function routeReducer (location, exact) {
  return (out, item) => {
    const match = matchPath(location.pathname, {
      path: item.path,
      exact: exact || !item.exact,
    })

    if (match) {
      out.push({
        id: item.path,
        name: item.breadcrumb,
        to: item.name,
        params: item.breadcrumbParams,
        match,
      })
    }

    return out
  }
}

const CurrentCollections = {
  dashboard: route => (
    route.match.params.id ? dbProvider.database.collections.get('dashboards')
      .query(Q.where('id', route.match.params.id))
      .observeWithColumns([ 'updated_at' ])
      .pipe(
        map(([ item ]) => (item ? { id: item.id, name: item.title } : undefined)),
      ) : of$(undefined)
  ),
  homepage: () => (
    dbProvider.observeSettings('homepageDefaultId')
      .pipe(
        switchMap(id => (id ? (
          dbProvider.database.collections.get('dashboards')
            .query(Q.where('id', id))
            .observeWithColumns([ 'updated_at' ])
            .pipe(
              map(([ item ]) => (item ? { id: item.id, name: item.title } : undefined)),
            )
        ) : of$(undefined))),
      )
  ),
  'projects-view': route => (route.match.params.id ? (
    dbProvider.database.collections.get('projects')
      .query(Q.where('id', route.match.params.id))
      .observeWithColumns([ 'updated_at' ])
      .pipe(
        map(([ item ]) => (item ? {
          id: item.id,
          name: item.name,
        } : undefined)),
      )
  ) : of$(undefined)),
  'stream-jobs-view': route => (route.match.params.id ? (
    dbProvider.database.collections.get('stream_jobs')
      .query(Q.where('id', route.match.params.id))
      .observeWithColumns([ 'updated_at' ])
      .pipe(
        map(([ item ]) => (item ? {
          id: item.id,
          name: item.name,
        } : undefined)),
      )
  ) : of$(undefined)),
  'chart-view': (route) => {
    const id = route.match.params.id ?
      decodeURIComponent(route.match.params.id).split('/')[0] : undefined
    return (id ? (
      dbProvider.database.collections.get('twin_graphs')
        .query(Q.or(
          Q.where('id', id),
          Q.where('block_id', id),
        ))
        .observeWithColumns([ 'updated_at' ])
        .pipe(
          map(([ item ]) => (item ? {
            id: item.id,
            name: item.name,
          } : undefined)),
        )
    ) : of$(undefined))
  },
  'data-observation': (route) => {
    const explorer$ = route.match.params.id ? dbProvider.database.collections.get('explorers')
      .query(Q.where('id', route.match.params.id))
      .observeWithColumns([ 'updated_at' ])
      .pipe(
        map(([ item ]) => (item ? {
          id: item.id,
          name: item.title || 'View',
        } : undefined)),
      ) : of$(undefined)

    const widget$ = route.match.params.id ? dbProvider.database.collections.get('widgets')
      .query(Q.where('id', route.match.params.id))
      .observeWithColumns([ 'updated_at' ])
      .pipe(
        map(([ item ]) => (item ? {
          id: item.id,
          name: item.title || 'Widget',
        } : undefined)),
      ) : of$(undefined)

    return explorer$.pipe(
      withLatestFrom(widget$),
      map(([ explorer, widget ]) => (explorer || widget)),
    )
  },
  'solutions-view-list': (route, query) => {
    if (!query.sub || !query.category || query.category === 'search') {
      return null
    }

    // const sub = await wizataApi.components.getList(query.twinId, { organizationOnly: true }).then((items) => {
    //   items.find(item => item.id === query.sub)
    // })
    // console.log('sub?', sub)

    return dbProvider.database.collections.get('business_labels')
      .query(Q.or(
        Q.where('id', query.category),
      ))
      .observeWithColumns([ 'updated_at' ])
      .pipe(
        map(([ item ]) => {
          // console.log('item: ', item)
          if (!item) {
            return undefined
          }
          return item

          // const bc = [{ ...item }]
          // console.log(bc)
          // if (sub) {
          //   bc.push({ id: sub.id, name: sub.name })
          // }

          // return bc
        }),
      )

    // const label = query?.category ? dbProvider.database.collections.get('business_labels')
    //   .query(Q.where('id', query.category))
    //   .observeWithColumns([ 'updated_at' ])
    //   .pipe(items => items[0]) : of$(undefined)

    // return [
    //   // { id: label?.id, name: label?.name },
    //   { id: query.sub, name: query.sub },
    // ]
  },
}

const enhanceRoute = withObservables([ 'current', 'query' ], ({ current, query }) => ({
  current: CurrentCollections[current?.to] ? (
    CurrentCollections[current.to](current, query)
  ) : of$(undefined),
}))

const enhanceData = withProps(() => {
  const location = useLocation()
  const query = useLocationQuery()
  const reducer = routeReducer(location)
  const currentReducer = routeReducer(location, true)

  const options = Routes
    .filter(item => (
      item.name !== 'not-found' &&
      item.name !== 'home' &&
      item.breadcrumb
    ))
    .reduce(reducer, [])

  const current = Routes
    .filter(item => (
      item.name !== 'not-found' &&
      item.name !== 'home'
    ))
    .reduce(currentReducer, [])[0]

  return {
    options,
    current,
    query,
  }
})

export default enhanceData(
  enhanceRoute(Component),
)
