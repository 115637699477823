import { useCallback, memo } from 'react'
import { dbProvider, Alert } from '@wiz/store'
import { withProps } from '@wiz/components'
import { useAuth } from '@/auth'
import FormAlert from '@/components/Forms/Alert'
import enhanceAlert from './enhanceAlert'
import enhanceUsage from './enhanceUsage'
import enhanceAlertSheet from '../NotificationSheet/enhanceAlertSheet'
import enhanceSheetTargets from '../NotificationSheet/enhanceSheetTargets'

const enhanceProps = withProps(() => {
  const auth = useAuth()
  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    if (auth.checkAccessManage('NotificationSheet')) {
      await Alert.prepareReplaceSettings(context, dbProvider.database, data)
    } else {
      await Alert.prepareReplaceData(context, dbProvider.database, data.alert)
    }
    await dbProvider.batch(context)
  }, [ auth ])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceAlert(
    enhanceAlertSheet(
      enhanceSheetTargets(
        enhanceUsage(
          enhanceProps(FormAlert),
        ),
      ),
    ),
  ),
)
