import { useMemo } from 'react'
import { EdgeDeviceCommand as EdgeDeviceCommandModel } from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import Section from './Section'

export default function EdgeDeviceCommand ({
  id,
  deviceCommand,
  defaultValues,
  withSensorsByTwin,
  dialog,
  onClose,
  onSubmit,
}) {
  const data = deviceCommand || defaultValues
  const currentDefaultValues = useMemo(() => ({
    deviceCommand: EdgeDeviceCommandModel.toJSON(data),
  }), [ data?.updatedAt ?? data ])

  if (id && !deviceCommand) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={currentDefaultValues}
      dataTestid="deviceCommandForm"
      dialog={dialog}
    >
      <Section
        scope="deviceCommand"
        withSensorsByTwin={withSensorsByTwin}
      />
    </FormDialog>
  )
}
