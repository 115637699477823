import { withObservables, withProps } from '@wiz/components'
import { dbProvider } from '@wiz/store'
import { consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'

const enhanceData = withObservables([], () => ({
  twinSettings: dbProvider.observeGlobalSettings([
    'twinColorAreas',
    'twinColorMachines',
    'twinColorEquipment',
  ]),
}))

const enhanceProps = withProps(({
  rootTwin,
  twinSettings,
}) => {
  const intl = useIntl()
  const auth = useAuth()

  const options = [
    {
      type: consts.TwinType.Area,
      color: twinSettings.twinColorAreas,
      name: intl.t('enum.twinType.area'),
      icon: 'fa--warehouse',
      disabled: !(
        auth.checkAccessCreate('Twin') &&
        (!rootTwin || consts.TwinLinkPairs.some(item => (
          item[0] === rootTwin.type &&
          item[1] === consts.TwinType.Area
        )))
      ),
    },
    {
      type: consts.TwinType.Machine,
      color: twinSettings.twinColorMachines,
      name: intl.t('enum.twinType.machine'),
      icon: 'fa--cogs',
      disabled: !(
        auth.checkAccessCreate('Twin') &&
        (!rootTwin || consts.TwinLinkPairs.some(item => (
          item[0] === rootTwin.type &&
          item[1] === consts.TwinType.Machine
        )))
      ),
    },
    {
      type: consts.TwinType.Equipment,
      color: twinSettings.twinColorEquipment,
      name: intl.t('enum.twinType.equipment'),
      icon: 'fa--tools',
      disabled: !(
        auth.checkAccessCreate('Twin') &&
        (!rootTwin || consts.TwinLinkPairs.some(item => (
          item[0] === rootTwin.type &&
          item[1] === consts.TwinType.Equipment
        )))
      ),
    },
  ]

  return {
    options,
  }
})

export default WrappedComponent => enhanceData(enhanceProps(WrappedComponent))
