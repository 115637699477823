import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { FormField, FormControl } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

const FieldBinBoundaryPrecision = ({ scope }) => {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()
  return (
    <FormField
      label={intl.t('widgets.histogram.form.fields.binBoundaryPrecision')}
      description={intl.t('widgets.histogram.form.fields.binBoundaryPrecisionDescr')}
      errors={get(errors, `${scope}.binBoundaryPrecision`)}
    >
      <FormControl
        type="number"
        name={`${scope}.binBoundaryPrecision`}
        min={0}
        clearable
      />
    </FormField>
  )
}

FieldBinBoundaryPrecision.propTypes = {
  scope: PropTypes.string.isRequired,
}

export default FieldBinBoundaryPrecision
