import { withObservables } from '@wiz/components'
import { dbProvider, Integration } from '@wiz/store'

const enhanceIntegrations = withObservables([], () => {
  const query = dbProvider.database.collections.get('integrations')
    .query()

  const settingsQuery = dbProvider.database.collections.get('integration_settings').query()
  const availableOptions = Integration.availableIntegrations

  return ({
    settings: settingsQuery.observeWithColumns([ 'updated_at' ]),
    options: query.observeWithColumns([ 'updated_at' ]),
    availableOptions,
  })
})

export default enhanceIntegrations
