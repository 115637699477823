/* eslint-disable no-nested-ternary */
import {
  useMemo,
  useRef,
} from 'react'
import { useLocationQuery } from '@/router'
import { useDidUpdate } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useTheme } from '@/theme'

const themeByName = {
  sun: 'light',
  moon: 'dark',
}

const Grafana = ({
  widget,
  config,
  templateId,
}) => {
  const refChart = useRef(null)

  const intl = useIntl()
  const theme = useTheme()
  const { asset, tree } = useLocationQuery()

  useDidUpdate(() => {
    refChart.current?.resize()
  }, [ widget.gridH, widget.gridW ])

  const url = useMemo(() => {
    const _theme = themeByName[theme.name]

    let fromTo

    if (config.dataFilter?.dateFrom && config.dataFilter?.dateTo) {
      fromTo = `&from=${config.dataFilter?.dateFrom}&to=${config.dataFilter?.dateTo}`
    } else if (config.dataFilter?.duration) {
      fromTo = `&from=${Date.now() - config.dataFilter?.duration}&to=${Date.now()}`
    }

    if (!templateId) {
      return `${config.panelUrl}${fromTo}&theme=${_theme}`
    }

    if (config.panelUrl && (asset || tree)) {
      return `${config.panelUrl}${fromTo}&var-twin=${asset || tree}&theme=${_theme}`
    }

    return null
  }, [ config.dataFilter.dateFrom,
    config.dataFilter.dateTo, config.dataFilter.duration, config.panelUrl, asset, templateId, theme, tree ])

  const iframe = useMemo(
    () => <iframe title={widget.title} className="w-100 h-100" src={url} frameBorder="1" />,
    [ widget.title, url ],
  )

  return (
    <div className="d-flex flex-column justify-content-end flex-fill min-h-0 position-relative">
      {!url ? (
        <div className="d-flex align-items-center justify-content-center flex-fill">
          {intl.t('widgets.grafana.nothingShown')}
        </div>
      ) :
        iframe}
    </div>
  )
}

export default Grafana
