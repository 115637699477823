import { forwardRef, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { wizataApi } from '@/api'
// import { FormSelect } from '@wiz/components'
import Select from '@/components/Form/Select'

const ListTemplates = forwardRef(({ id, disabled, ...props }, ref) => {
  const { data, isLoading } = useQuery({
    queryKey: [ 'templates' ],
    queryFn: () => wizataApi.templates.getList('false').then((response) => {
      if (response?.length) {
        const next = response.map(item => ({ ...item, value: item.id }))
        return next
      }
      return response
    }),
    enabled: true,
    refetchOnWindowFocus: false,
  })

  // return (
  //   <FormSelect
  //     {...props}
  //     invalid={props.invalid}
  //     options={data}
  //     disabled={disabled || isLoading}
  //   />
  // )

  return (
    <Select
      {...props}
      invalid={props.invalid}
      options={data}
      disabled={disabled || isLoading}
    />
  )
})

export default ListTemplates
