import { forwardRef } from 'react'
import { of as of$, combineLatest } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { withResizeObserver, withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { uniq, compact } from '@wiz/utils'
import Component from '@/components/SensorChart'

const enhanceProps = withObservables([ 'dataSources' ], ({ dataSources }) => {
  const ids = compact(uniq(dataSources.map(item => item.sensorId)))
  const units = dbProvider.database.collections.get('sensors')
    .query(Q.where('id', Q.oneOf(ids)))
    .observeWithColumns('unit_id')
    .pipe(
      switchMap(sensors => (
        sensors.length ? (
          combineLatest(of$(sensors), ...sensors.map(item => item.unit))
        ) : of$([[]])
      )),
      map(([ sensors, ...items ]) => (
        dataSources.reduce((out, ds) => {
          const sensor = ds.sensorId && sensors.find(item => item.id === ds.sensorId)
          const unit = sensor && items.find(item => item?.id === sensor.unitId)
          const symbol = unit?.symbol
          if (symbol && symbol !== 'Other') {
            out[ds.id] = symbol
          }
          return out
        }, {})
      )),
    )

  return {
    units,

    globalSettings: dbProvider.observeGlobalSettings([
      'PlatformTimeFormat',
      'PlatformDateFormat',
      'PlatformDateTimeZoneName',
      'RawDateRangeLimit',
      'SeriesQueryMaxCount',
    ]),
  }
})

export default enhanceProps(
  withResizeObserver(
    forwardRef((props, ref) => (
      <Component innerRef={ref} {...props} />
    )),
  ),
)
