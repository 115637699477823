import 'leaflet/dist/leaflet.css'
import { createRef, Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import leaflet from 'leaflet'
import { clone } from '@wiz/utils'
import deviceImage from '@/assets/img/device.png'
import { DATA_FORMAT, getBreakpointData } from '@/config'
import { intl } from '@/i18n'
import Marker from './Marker'
import classes from './Marker.css'

export default class DeviceMonitoringVisual extends Component {
  static getDerivedStateFromProps (nextProps) {
    return {
      config: clone(nextProps.config),
    }
  }

  refTarget = createRef()

  state = DeviceMonitoringVisual.getDerivedStateFromProps(this.props)

  componentDidMount () {
    this.$map = leaflet.map(this.refTarget.current, {
      crs: leaflet.CRS.Simple,
      minZoom: 2,
      maxZoom: 4,
      center: [ 0, 0 ],
      zoom: 2,
      zoomControl: false,
    })

    const southWest = this.$map.unproject([ 0, 716 ], this.$map.getMaxZoom() - 1)
    const northEast = this.$map.unproject([ 954, 0 ], this.$map.getMaxZoom() - 1)
    const bounds = new leaflet.LatLngBounds(southWest, northEast)
    leaflet.imageOverlay(deviceImage, bounds, {
      opacity: 0.6,
      interactive: false,
    }).addTo(this.$map)

    this.$markers = this.state.config.markers
      .filter(item => item.active)
      .reduce((out, item) => {
        const coords = this.$map.unproject(item.point, this.$map.getMaxZoom())
        const { type } = item
        const dataFormat = DATA_FORMAT[type]

        const marker = leaflet.marker(coords, {
          icon: this.markerIco({ dataFormat, value: null }),
          draggable: true,
          keyboard: false,
        }).addTo(this.$map).on('moveend', (event) => {
          const point = this.$map.project(event.target.getLatLng(), this.$map.getMaxZoom())
          this.handleMoveend(type, [ point.x, point.y ])
        })

        if (dataFormat) {
          marker.bindTooltip(intl.t(dataFormat.title))
        }

        out[type] = marker
        return out
      }, {})

    this.$map.setMaxBounds(bounds)

    this.$initTimer = window.setTimeout(() => {
      this.$map.invalidateSize(true)
      for (const type in this.$markers) {
        this.updateMarker(type, 42)
      }
    }, 0)
  }

  componentWillUnmount () {
    clearTimeout(this.$initTimer)
    this.$map.remove()
    this.$map = null
  }

  componentDidUpdate (prevProps, prevState) {
    // if (!isEqual(this.state.config, prevState.config)) {
    // }

    if (this.props.dimension !== prevProps.dimension) {
      this.$map.invalidateSize(true)
    }
  }

  updateMarker (type, value) {
    const dataFormat = DATA_FORMAT[type]
    const marker = this.$markers[type]
    const icon = this.markerIco({ dataFormat, value })
    marker.setIcon(icon)
  }

  // eslint-disable-next-line class-methods-use-this
  markerIco ({ dataFormat = {}, value }) {
    const { status, icon } = getBreakpointData(dataFormat, value)
    let title = ''
    if (dataFormat.format && value !== null) {
      title = dataFormat.format.replace('{value}', value)
    }

    return leaflet.divIcon({
      className: classes.marker,
      iconSize: [ 6, 6 ],
      iconAnchor: [ 3, 3 ],
      labelAnchor: [ 0, 0 ],
      popupAnchor: [ 0, 0 ],
      tooltipAnchor: [ 3, 0 ],
      html: ReactDOMServer.renderToString(
        <Marker
          icon={icon}
          title={title}
          status={status}
          loading={value === null}
        />,
      ),
    })
  }

  handleMoveend (type, point) {
    this.props?.onChangePoint(type, point)
  }

  render () {
    return (
      <div
        ref={this.refTarget}
        className="position-absolute-fill"
      />
    )
  }
}
