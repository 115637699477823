import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import union from 'lodash/union'
import { Icon, FormInputSearch, useDrag } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { toArrayValue } from '@wiz/utils'
import DashboardsComponents from '@/components/DashboardsComponents'

export default function RightBarDashboardsComponents ({
  sensorIds,
  onChange,
  onUnlinkSensors,
  blockIds,
  ...props
}) {
  const [ search, setSearch ] = useState('')
  console.log(props)
  const intl = useIntl()

  return (
    <DashboardsComponents twinIds={blockIds} />
  )
}
