import { forwardRef, useCallback } from 'react'
import classnames from 'classnames'
import { useIntl } from '@wiz/intl'
import { consts, uuid } from '@wiz/utils'
import { Icon } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import Select from '@/components/Form/Select'
import SelectLabel from '@/components/Form/SelectLabel'
import SelectSensor from '@/components/Form/SelectSensor'

const SelectMeasurements = forwardRef(({
  invalid,
  placeholder,
  value,
  onChange,
}, ref) => {
  const intl = useIntl()

  const updateErrors = useCallback(async (data) => {
    const next = []

    for (const item of data) {
      let nextItem = {
        ...item,
        error: null,
      }

      if (
        item.type === consts.QualityDataTemplateMeasurementType.Label &&
        item.labelId
      ) {
        const noneSensors = await dbProvider.database.collections.get('sensors')
          .query(
            Q.experimentalJoinTables([ 'rel_sensors_labels' ]),
            Q.and(
              Q.on('rel_sensors_labels', 'label_id', item.labelId),
              Q.where('input_mode', consts.SensorInputMode.None),
            ),
          )
          .fetchCount()

        if (noneSensors) {
          nextItem = {
            ...nextItem,
            error: 'tmplQualityData.form.errors.labelNotMatch',
          }
        }
      }

      next.push(nextItem)
    }

    return next
  }, [])

  const handleChange = useCallback(async (data, nextData) => {
    const next = await updateErrors(
      value.map(item => (item.id === data.id ? {
        ...item,
        ...nextData,
      } : item)),
    )
    onChange(next)
  }, [ value, onChange, updateErrors ])

  const handlePrepend = useCallback(async () => {
    const next = await updateErrors([
      {
        id: uuid(),
        type: consts.QualityDataTemplateMeasurementType.Sensor,
        labelId: null,
        sensorId: null,
      },
      ...value,
    ])
    onChange(next)
  }, [ value, onChange, updateErrors ])

  const handleRemove = useCallback(async (data) => {
    const next = await updateErrors(value.filter(item => item.id !== data.id))
    onChange(next)
  }, [ value, onChange, updateErrors ])

  return (
    <div className="flex-fill">
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <button
            type="button"
            className="btn btn-outline-primary flex-fill"
            onClick={handlePrepend}
          >
            <Icon name="fa--plus" className="me-1" />
            {intl.t('form.actions.add')}
          </button>
        </div>
      </div>
      {value.map((item, index) => (
        <div
          key={item.id}
          className={classnames('row g-3', index !== value.length - 1 && 'mb-2')}
        >
          <div className="col-md-4">
            <Select
              value={item.type}
              options={consts.QualityDataTemplateMeasurementTypes.map(id => ({
                id,
                name: intl.t(`enum.qualityDataTemplateMeasurementTypes.${id}`),
              }))}
              clearable={false}
              onChange={type => handleChange(item, { type, labelId: null, sensorId: null })}
            />
          </div>
          <div className="col-md-7">
            {item.type === consts.QualityDataTemplateMeasurementType.Label ? (
              <SelectLabel
                className={classnames({
                  'is-invalid': (
                    (invalid && !item.labelId) ||
                    item.error
                  ),
                })}
                value={item.labelId}
                placeholder={intl.t('form.fields.labelsPlaceholder')}
                onChange={labelId => handleChange(item, { labelId })}
              />
            ) : null}
            {item.type === consts.QualityDataTemplateMeasurementType.Sensor ? (
              <SelectSensor
                className={classnames({
                  'is-invalid': (
                    (invalid && !item.sensorId) ||
                    item.error
                  ),
                })}
                value={item.sensorId}
                placeholder={intl.t('form.fields.sensorPlaceholder')}
                onChange={sensorId => handleChange(item, { sensorId })}
                filters={{ withInputMode: true }}
              />
            ) : null}
            {item.error ? (
              <div className="invalid-feedback text-break">
                {intl.t(item.error)}
              </div>
            ) : null}
          </div>
          <div className="col-md-auto">
            <button
              type="button"
              className="btn btn-fill-secondary btn-text flex-fill"
              onClick={() => handleRemove(item)}
            >
              <Icon name="fa--trash-alt" />
            </button>
          </div>
        </div>
      ))}
      {!value.length && placeholder ? (
        <div className="text-center">
          {placeholder}
        </div>
      ) : null}
    </div>
  )
})

export default SelectMeasurements
