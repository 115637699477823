import { useMemo, useState } from 'react'
import classnames from 'classnames'
import { VirtualTable, Pagination, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'

export default function Table ({
  items,
  onFilter,
  onEdit,
  onRemove,
  onRun,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      Header: intl.t('form.fields.generatedBy'),
      accessor: 'generatedById',
      minWidth: 200,
      Cell: ({ cell, row }) => {
        const allowed = auth.checkAccessUpdate('Dataframe')
        return (
          <div className="text-truncate min-w-0">
            <div className="d-flex align-items-center">
              <div
                className={classnames('text-truncate', { 'link pointer': allowed })}
                onClick={() => (allowed ? onRun?.(cell.value) : undefined)}
                aria-hidden
              >
                { cell.value ? intl.t('entities.form.fields.openExecution') : '-' }
              </div>
            </div>

            {/* {row.original.description ? (
            <div className="font-italic text-muted text-truncate small">
              {row.original.description}
            </div>
          ) : null} */}
          </div>
        )
      },
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedDate',
      disableResizing: true,
      // disableSortBy: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.updatedById}
        />
      ),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdDate',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.createdById}
        />
      ),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell, row }) => {
        const [ loading, setLoading ] = useState(false)
        return (
          <>
            {/* {auth.checkAccessUpdate(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0 me-2"
              onClick={() => onEdit?.(cell.value)}
            >
              {intl.t('form.actions.edit')}
            </button>
            ) : null} */}
            {auth.checkAccessRemove('Dataframe') ? (
              <button
                type="button"
                className="btn btn-sm p-0"
                disabled={loading}
                onClick={async () => {
                  setLoading(true)
                  try {
                    await onRemove(cell.value)
                    setLoading(false)
                  } catch (error) {
                    setLoading(false)
                    throw error
                  }
                }}
              >
                {loading ? <Icon name="fa--spinner" className="me-1" spin /> : null}
                {intl.t('form.actions.remove')}
              </button>
            ) : null}
          </>
        )
      },
    },
  ], [ onRemove, onEdit, intl, onRun, auth ])

  return (
    <>
      <VirtualTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={auth.checkAccessRemove('Plot') || auth.checkAccessUpdate('Plot')}
        {...props}
      />

      <Pagination {...props} relative />
    </>
  )
}
