import { useCallback, useState } from 'react'
import { Icon, FormInputSearch } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import FormIntegration from '@/containers/Forms/Integration'
import { validate } from 'uuid'
import Table from './Table'
import AvailableList from './AvailableList'

export default function List ({
  editId = '',
  setEditId,
  onRemove,
  filters,
  onFilter,
  onResetFilter,
  options,
  availableOptions,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const [ search, setSearch ] = useState('')
  const [ isAdd, setAdd ] = useState(false)

  const handleAction = useCallback((integration, action) => {
    if (action) {
      if (action.id === 'edit') {
        setEditId(integration.id)
      } else if (action.id === 'remove') {
        onRemove(integration)
      }
    } else {
      setEditId(integration.id)
    }
  }, [ setEditId, onRemove ])

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">

      <div className="content-container content-container-sm d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-2">
        <div className="d-flex flex-shrink-0 align-items-center">
          {!isAdd ? (
            <>
              <FormInputSearch
                className="mb-2"
                placeholder={intl.t('form.actions.searchPlaceholder')}
                onChange={setSearch}
              />

              {/* {auth.checkAccessCreate('Integration') ? ( */}
              <button
                type="button"
                className="btn btn-fill-secondary text-nowrap mb-2 ms-2"
                title={intl.t('integration.form.actions.addTitle')}
                onClick={() => setAdd(true)}
              >
                <Icon name="fa--plus" className="me-1" />
                {intl.t('form.actions.add')}
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-outline-secondary ms-2"
                onClick={() => setAdd(false)}
              >
                <Icon name="fa--chevron-left" className="me-1" />
                {intl.t('form.actions.back')}
              </button>
              <div className="ms-4">{intl.t('integrations.availableList')}</div>
            </>
          )}
          {/* ) : null} */}
        </div>

        {/* <div className="d-flex align-items-center flex-wrap flex-shrink-0 mx-n1 mb-2">
          <Filters
            filters={filters}
            onFilter={onFilter}
          />
        </div> */}

        {!isAdd ? (
          <Table
            {...props}
            search={search}
            filters={filters}
            onFilter={onFilter}
            onAction={handleAction}
            options={options}
            onResetFilter={() => {
              onResetFilter?.()
              setSearch('')
            }}
          />
        ) : (
          <AvailableList
            options={availableOptions}
            onClick={setEditId}
            integrationsList={options}
          />
        )}
      </div>

      {do {
        if (editId && validate(editId)) {
          <FormIntegration
            id={editId}
            onClose={() => setEditId(null)}
            dialog={{
              title: intl.t('integrations.titleUpdate'),
              dataTestid: 'updateIntegrationDialog',
            }}
          />
        } else if (editId && !validate(editId)) {
          <FormIntegration
            id={editId}
            onClose={() => setEditId(null)}
            dialog={{
              title: intl.t('integrations.titleCreate'),
              dataTestid: 'updateIntegrationDialog',
            }}
          />
        }
      }}
    </div>
  )
}
