import { of as of$ } from 'rxjs'
import { map, withLatestFrom } from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'
import { auth } from '@/auth'

export default withObservables([ 'id', 'match' ], ({ id, match }) => {
  const currentId = id || match?.params?.id
  let explorerObserver = of$(undefined)
  let widgetObserver = of$(undefined)

  if (currentId) {
    explorerObserver = dbProvider.database.collections.get('explorers')
      .query(Q.where('id', currentId))
      .observeWithColumns([ 'updated_at' ])
      .pipe(
        map(items => items.filter(item => auth.checkAccessRead(item))),
        map(items => items[0]),
      )

    widgetObserver = dbProvider.database.collections.get('widgets')
      .query(Q.where('id', currentId))
      .observeWithColumns([ 'updated_at' ])
      .pipe(
        map(items => items.filter(item => auth.checkAccessRead(item))),
        map(items => items[0]),
      )
  }

  return {
    id: of$(currentId),

    explorer: explorerObserver
      .pipe(
        withLatestFrom(widgetObserver),
        map(([ explorer, widget ]) => (explorer || widget)),
      ),

    settings: dbProvider.observeSettings([
      'explorerDefaultView',
      'explorerViewFullscreen',
      'explorerViewSplit',
      'explorerViewSplitSettings',
      'explorerViewSplitInterval',
      'explorerAutosave',
      'explorerDateAutoapply',
    ]),

    globalSettings: dbProvider.observeGlobalSettings([
      'RawDateRangeLimit',
      'RawQueryLimit',
      'SeriesQueryMaxCount',
    ]),
  }
})
