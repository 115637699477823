import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import { Icon } from '@wiz/components'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'

export default function ViewModel ({
  title,
  sentSMSLog,
  onClose,
  onFilter,
}) {
  const refDialog = useRef()
  const intl = useIntl()

  const handleFilter = (filter) => {
    refDialog.current.close()
    onFilter?.(filter)
  }

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('form.fields.recipient'),
          value: () => (
            <>
              <button
                type="button"
                className="btn btn-sm btn-flat-secondary me-2"
                onClick={() => handleFilter({ recipient: sentSMSLog.recipient })}
              >
                <Icon name="fa--search" />
              </button>
              <span className="text-truncate user-select-all">{sentSMSLog.recipient}</span>
            </>
          ),
        },
        {
          title: intl.t('form.fields.message'),
          value: sentSMSLog.message,
        },
        {
          title: intl.t('form.fields.status'),
          value: sentSMSLog.status,
        },
        {
          title: intl.t('form.fields.isPaid'),
          value: () => (sentSMSLog.isPaid ? 'Yes' : null),
        },
        {
          title: intl.t('form.fields.updated'),
          value: () => (
            <UpdatedInfo date={sentSMSLog.updatedDate} userId={sentSMSLog.updatedById} />
          ),
        },
        {
          title: intl.t('form.fields.created'),
          value: () => (<UpdatedInfo date={sentSMSLog.createdDate} withMillis withSecond />),
        },
      ],
    },
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      onClose={onClose}
    />
  )
}
