import { Observable } from 'rxjs'
import { Settings } from 'luxon'
import TimeAgo from 'javascript-time-ago'
import { withObservables } from '@wiz/components'
import { Intl, IntlProvider as BaseIntlProvider } from '@wiz/intl'
import { appEnv } from '@/config'
import { TIMEAGO, UI } from '@/lang/en'

TimeAgo.addLocale(TIMEAGO)

const defaultIntl = new Intl({
  locale: appEnv.I18N_LOCALE,
  fallbackLocale: appEnv.I18N_FALLBACK_LOCALE,
  messages: {
    en: { ...UI },
  },
})

let currentIntl = defaultIntl

export const intl = {
  ago: new TimeAgo('en'),
  t: (...args) => currentIntl.t(...args),
}

function fetchLanguage (lang) {
  if (!lang || lang === currentIntl.locale) {
    return Promise.resolve(currentIntl)
  }

  return import(
    /* webpackChunkName: "[request]" */
    /* webpackMode: "lazy" */
    `@/lang/${lang}.js`
  ).then(({ TIMEAGO, UI }) => {
    TimeAgo.addLocale(TIMEAGO)
    intl.ago = new TimeAgo(lang)
    Settings.defaultLocale = lang
    document.querySelector('html').setAttribute('lang', lang)

    currentIntl = defaultIntl.clone()
    currentIntl.setLocale(lang)
    currentIntl.setLocaleMessages(lang, { ...UI })
    return currentIntl
  }).catch(() => (currentIntl))
}

function observeIntl (lang) {
  return new Observable((subscriber) => {
    fetchLanguage(lang).then((data) => {
      subscriber.next(data)
    })
  })
}

const enhanceIntl = withObservables([ 'lang' ], ({ lang }) => ({
  data: observeIntl(lang),
}))

export const IntlProvider = enhanceIntl(({ children, data }) => (
  <BaseIntlProvider intl={data}>
    {children}
  </BaseIntlProvider>
))
