import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get } from '@wiz/utils'
import { FormControl } from '@wiz/components'
import { useIntl } from '@wiz/intl'

const Regression = ({ index, scope }) => {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()
  const recordErrors = (
    get(errors, `${scope}.regression${index}Active`) ||
    get(errors, `${scope}.regression${index}Color`) ||
    get(errors, `${scope}.regression${index}NumLast`)
  )

  return (
    <>
      <div
        className={classnames('list-group-item p-2 d-flex align-items-center', {
          'is-invalid': !!recordErrors,
        })}
      >
        <div className="me-3">
          {`#${index + 1}`}
        </div>

        <FormControl
          type="checkbox"
          name={`${scope}.regression${index}Active`}
          className="me-3"
        />

        <FormControl
          type="color"
          name={`${scope}.regression${index}Color`}
          className="me-3"
          clearable
          alpha
        />

        <FormControl
          type="number"
          name={`${scope}.regression${index}NumLast`}
          rules={{
            validate: {
              positive: value => (
                !Number.isFinite(value) ||
                value >= 2 ||
                intl.t('form.errors.fieldMin', { min: 2 })
              ),
              lessThanOne: value => (
                !Number.isFinite(value) ||
                value <= 9999 ||
                intl.t('form.errors.fieldMax', { max: 9999 })
              ),
            },
          }}
          min={2}
          max={9999}
          step={1}
          placeholder={intl.t('form.fields.regressionNumLastPlaceholder')}
        />
      </div>
      {recordErrors?.message ? <div className="invalid-feedback m-0">{recordErrors.message}</div> : null}
    </>
  )
}

Regression.propTypes = {
  index: PropTypes.number.isRequired,
  scope: PropTypes.string.isRequired,
}

export default function FieldsRegressions ({ scope }) {
  return (
    <div className="list-group list-group-flush mx-0">
      <Regression scope={scope} index={0} />
      <Regression scope={scope} index={1} />
      <Regression scope={scope} index={2} />
      <Regression scope={scope} index={3} />
      <Regression scope={scope} index={4} />
      <Regression scope={scope} index={5} />
    </div>
  )
}

FieldsRegressions.propTypes = {
  scope: PropTypes.string.isRequired,
}
