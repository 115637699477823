/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { VirtualTable, Icon } from '@wiz/components'
import { StreamJob } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import Link from '@/components/Link'
import CellToggleActive from './CellToggleActive'
import CellName from './CellName'

export default function TableMobile ({
  items,
  filters,
  onFilter,
  onRemove,
  onEdit,
  onView,
  onToggleActive,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    ...(auth.checkAccessUpdate('StreamJob') ? [
      {
        Header: intl.t('form.fields.active'),
        accessor: 'active',
        disableResizing: true,
        minWidth: 55,
        width: 55,
        maxWidth: 55,
        className: 'justify-content-center',
        Cell: ({ row }) => (
          <CellToggleActive
            streamJob={row.original}
            onToggleActive={onToggleActive}
          />
        ),
      },
    ] : []),
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      Cell: ({ row }) => (
        <CellName
          streamJob={row.original}
          filters={filters}
        />
      ),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      className: 'justify-content-end text-nowrap',
      Cell: ({ row }) => (
        <>
          {auth.checkAccessRead('SectionStreamJobLogs') ? (
            <Link
              name="stream-job-logs-list"
              query={{ streamJobId: row.original.blockId }}
              className="btn btn-sm p-2"
            >
              <Icon name="fa--bars" />
            </Link>
          ) : null}
          {auth.checkAccessUpdate(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-2"
              onClick={() => onEdit?.(row.original.id)}
            >
              <Icon name="fa--edit" />
            </button>
          ) : null}
          <button
            type="button"
            className="btn btn-sm p-2"
            onClick={() => onView?.(row.original.id)}
          >
            <Icon name="fa--chevron-right" />
          </button>
        </>
      ),
    },
  ], [
    auth,
    onView,
    onEdit,
    onToggleActive,
    filters,
    intl,
  ])

  return (
    <VirtualTable
      className="flex-fill"
      placeholder={intl.t('errors.noDataDisplay')}
      columns={columns}
      data={items}
      showHeader={false}
      selection={auth.checkAccessRemove('StreamJob') || auth.checkAccessUpdate('StreamJob')}
      {...props}
    />
  )
}

TableMobile.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(StreamJob)),
  filters: PropTypes.object,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onToggleActive: PropTypes.func,
  onFilter: PropTypes.func.isRequired,
}

TableMobile.defaultProps = {
  items: [],
  filters: {},
  onRemove: undefined,
  onEdit: undefined,
  onView: undefined,
  onToggleActive: undefined,
}
