import { Card } from '@wiz/components'
import { useAuth } from '@/auth'
import { useIntl } from '@wiz/intl'

export default function Widget ({
  widget,
  canUpdateDashboard,
  onAction,
}) {
  const intl = useIntl()
  const auth = useAuth()

  const actions = []
  if (canUpdateDashboard) {
    actions.push({ id: 'settings', name: intl.t('form.actions.settings'), icon: 'fa--cog' })
    if (auth.checkAccessCopy(widget)) {
      actions.push({ id: 'duplicate', name: intl.t('form.actions.duplicate'), icon: 'fa--clone' })
      actions.push({ id: 'multiDuplicate', name: intl.t('form.actions.multiDuplicate'), icon: 'fa--folder-plus' })
    }
    actions.push({ id: 'remove', name: intl.t('form.actions.remove'), icon: 'fa--trash-alt' })
  }

  return (
    <Card
      className="position-absolute-fill"
      classNameTitle={canUpdateDashboard ? 'userdrag' : undefined}
      title={widget.title || intl.t(widget.info.name)}
      description={widget.description}
      options={actions}
      onAction={onAction}
    />
  )
}
