import { memo } from 'react'
import Component from '@/components/DataExplorer/View'
import enhanceContext from './enhanceContext'
import enhanceContextInit from './enhanceContextInit'
import enhanceEnv from './enhanceEnv'
import enhanceEthplorer from './enhanceEthplorer'
import enhanceExplorerContext from './enhanceExplorerContext'
import enhanceRunContext from './enhanceRunContext'

export default memo(
  enhanceEthplorer(
    enhanceEnv(
      enhanceExplorerContext(
        enhanceContext(
          enhanceRunContext(
            enhanceContextInit(
              Component,
            ),
          ),
        ),
      ),
    ),
  ),
)
