import { useFormContext } from 'react-hook-form'
import { FormSection } from '@wiz/components'
import FormFieldsBGJob from '@/components/Forms/BGJob/Fields'

const BGJobExportExclude = [ 'command' ]

export default function Section ({
  scope,
  title,
  addon,
  disabled,
  registerId = true,
}) {
  const { register } = useFormContext()

  return (
    <FormSection
      title={title}
      addon={addon}
    >
      {registerId ? (
        <input
          {...register(`${scope}.id`)}
          type="hidden"
        />
      ) : null}

      <FormFieldsBGJob
        scope={scope}
        exclude={BGJobExportExclude}
        disabled={disabled}
      />
    </FormSection>
  )
}
