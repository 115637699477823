/* eslint-disable react/no-danger */
import { useEffect, useState, useRef } from 'react'
import { Popup } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import events from '@/utils/events'

export default function Dialogs () {
  const refDialog = useRef()
  const intl = useIntl()
  const [ popup, setPopup ] = useState(null)

  useEffect(() => {
    const handleConfirm = data => setPopup(data)
    events.addListener('app:confirm', handleConfirm)
    return () => {
      events.removeListener('app:confirm', handleConfirm)
    }
  }, [])

  if (!popup) {
    return null
  }

  return (
    <Popup
      refDialog={refDialog}
      title={intl.t(popup.title || 'form.fields.warning')}
      size={popup.size || 'sm'}
      footer={() => (
        <>
          <button
            type="button"
            className="btn btn-fill-secondary"
            onClick={() => {
              events.emit('app:confirm:status')
              setPopup(null)
            }}
          >
            {intl.t('form.actions.cancel')}
          </button>

          <button
            type="button"
            className="btn btn-fill-primary"
            onClick={() => {
              popup.onConfirm?.(refDialog)
              events.emit('app:confirm:status', true)
              setPopup(null)
            }}
          >
            {intl.t('form.actions.ok')}
          </button>
        </>
      )}
      onClose={() => {
        events.emit('app:confirm:status')
        setPopup(null)
      }}
    >
      {popup.dangerouslyUseHTMLString ? (
        <div dangerouslySetInnerHTML={{ __html: intl.t(popup.message) }} />
      ) : intl.t(popup.message)}
    </Popup>
  )
}
