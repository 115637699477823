import withTableActions from '@/containers/withTableActions'
import Component from '@/components/EdgeLogs/List'

export default withTableActions({
  initFilters: (query = {}) => ({
    from: query.from ? Number(query.from) : null,
    to: query.to ? Number(query.to) : null,
    deviceId: query.deviceId || null,
    moduleId: query.moduleId || null,
  }),
})(Component)
