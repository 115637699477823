import { useCallback, useState } from 'react'
import { Icon } from '@wiz/components'
import { wizataApi } from '@/api'
import events from '@/utils/events'

export default function ButtonFileDownload ({
  file,
  isLinkView,
  children,
}) {
  const [ loading, setLoading ] = useState(false)

  const handleClick = useCallback((event) => {
    event?.preventDefault()

    setLoading(true)

    wizataApi.getFileDownloadLink(file)
      .then((url) => {
        setLoading(false)
        const win = window.open(url, '_blank')
        win.focus()
      })
      .catch((error) => {
        setLoading(false)
        events.emit('app:notify', {
          type: 'error',
          message: 't/form.errors.fileDownload',
        })
        console.error(error)
      })
  }, [ file ])

  if (isLinkView) {
    return (
      <a
        className="text-truncate pointer"
        onClick={handleClick}
        aria-hidden
      >
        {children}
      </a>
    )
  }

  return (
    <button
      type="button"
      className="btn btn-sm btn-flat-secondary"
      onClick={loading ? undefined : handleClick}
    >
      <Icon
        name={loading ? 'fa--spinner' : 'fa--file-download'}
        spin={loading}
        className="me-1"
      />
      {children}
    </button>
  )
}
