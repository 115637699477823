import {
  useMemo,
} from 'react'
import { map } from 'rxjs/operators'
import { of } from 'rxjs'
import { useRouter, useLocationQuery } from '@/router'
import Icon from '@/shared/icon'
import { withObservables } from '@wiz/components'
import { dbProvider, Q } from '@wiz/store'

// TODO: should be removed when rest api will work
const enhanceProps = withObservables([ 'id', 'collection' ], ({ id, collection }) => {
  if (!id || !collection) {
    return {
      item: of(undefined),
    }
  }

  const query = dbProvider.database.collections.get(collection).query(Q.where('id', id))

  return {
    item: query.observeWithColumns([ 'updated_at' ]).pipe(map(items => items[0])),
  }
})

const CellExternalLink = ({
  id,
  name,
  item,
  row,
}) => {
  const router = useRouter()
  const query = useLocationQuery()

  // const onClick = () => {
  //   const executionId = row.original.id
  //   if (name) {
  //     router.push({
  //       query: {
  //         ...query, current: page, [name]: id, executionId,
  //       },
  //     })
  //   }
  // }

  // TODO: this should work when rest api will work
  // const item = useMemo(() => {
  //   if (id && list?.data?.length) {
  //     const next = list.data.find(pipeline => pipeline.id === id)
  //     return next
  //   }
  //   return undefined
  // }, [ id, list ])
  // if (list?.isLoading) {
  //   return (
  //     <Icon
  //       name="fa--spinner"
  //       spin
  //     />
  //   )
  // }

  return id ? (
    <div className="min-w-0 d-flex">
      {item ? (
        <div
          className="text-truncate"
          // onClick={() => onClick()}
          // aria-hidden
        >
          <span>{item.name || item.key}</span>
        </div>
      ) : '-'}

    </div>
  ) : '-'
}

export default enhanceProps(CellExternalLink)
