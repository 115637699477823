import withTableActions from '@/containers/withTableActions'
import Component from '@/components/TmplQualityDataList/List'

export default withTableActions({
  initFilters (query) {
    return {
      twins: query.twins,
    }
  },
})(Component)
