import { useMemo } from 'react'
import { TwinGraph as TwinGraph$ } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import Section from './Section'
import SectionTwins from './SectionTwins'

export default function TwinGraph ({
  id,
  twinGraph,
  twins,
  dialog,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()

  const defaultValues = useMemo(() => ({
    twinGraph: TwinGraph$.toJSON(twinGraph),
    twins,
  }), [
    twinGraph?.updatedAt ?? twinGraph,
    twins,
  ])

  if (id && !twinGraph) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="twinGraphForm"
      dialog={dialog}
    >
      <Section scope="twinGraph" />

      <SectionTwins
        title={intl.t('form.fields.twins')}
        description={intl.t('twinGraph.form.fields.twinsDescr')}
        scope="twins"
      />
    </FormDialog>
  )
}
