import {
  forwardRef, useEffect, useRef, useMemo,
} from 'react'
import { DateTime } from 'luxon'
import orderBy from 'lodash/orderBy'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { wizataApi } from '@/api'
import {
  useRouter, useLocationQuery, useLocation,
} from '@/router'
import classnames from 'classnames'
import { useMobile, Skeleton, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import events from '@/utils/events'
import Dropdown from '@/components/Form/Select/Dropdown'
import IconFa from '@/shared/icon'
import { toUserNames } from '@/utils/users'
import useAppContext from '@/hooks/useAppContext'
import { useAuth } from '@/auth'
// import Observe from './observeUser'

const viewTypes = {
  grid: {
    id: 'grid',
    next: 'tab',
    icon: 'faTableColumns',
  },
  tab: {
    id: 'tab',
    next: 'grid',
    icon: 'faBrowsers',
  },
}

function formatDate (date) {
  return DateTime.fromMillis(date).toFormat('dd LLL yyyy, HH:mm:ss')
}

const Topbar = forwardRef(({
  onEdit, onRun,
}) => {
  const intervalRef = useRef()
  const refDropdown = useRef()
  const refTarget = useRef()
  const refExperimentsTarget = useRef()
  const refExperimentsDropdown = useRef()

  const queryClient = useQueryClient()
  const query = useLocationQuery()
  const loc = useLocation()

  const [ ,,, currentPage, experimentId ] = loc.pathname.split('/')

  const auth = useAuth()
  const intl = useIntl()
  const isMobile = useMobile()
  const router = useRouter()

  const {
    onFilter,
    experiments: {
      execution,
      isExecuting,
      onSelectExecution,
      isExecutionLoading,
      refetchExecution,
    },
  } = useAppContext()

  const isComplete = execution && [ 'completed', 'failed' ].includes(execution.status)

  const {
    data,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: [ 'executions', experimentId ],
    queryFn: () => wizataApi.executions.getListByExperiment(experimentId).then(toUserNames),
    enabled: !!experimentId && !isExecuting,
    onSuccess: (list) => {
      if (list?.length) {
        const sorted = orderBy(list, [
          item => item.createdDate,
        ], [ 'desc' ])
        onSelectExecution(sorted[0]?.id)
      }
    },
    refetchOnWindowFocus: false,
  })

  const {
    data: experiment,
    isLoading: experimentIsLoading,
  } = useQuery({
    queryKey: [ 'experiment', experimentId ],
    queryFn: () => wizataApi.experiments.getById(experimentId),
    enabled: !!experimentId,
    staleTime: Infinity,
  })

  const { data: experiments, isLoading: experimentsLoading, isError: experimentsError } = useQuery({
    queryKey: [ 'experiments' ],
    queryFn: () => wizataApi.experiments.getList(),
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const {
    data: pipeline,
    isLoading: pipelineIsLoading,
  } = useQuery({
    queryKey: [ 'pipeline', experiment?.pipelineId ],
    queryFn: () => wizataApi.pipelines.getById(experiment?.pipelineId),
    enabled: !!experiment?.pipelineId,
    staleTime: Infinity,
  })

  const {
    mutate: generate, isError: generationError, isLoading: generationLoading,
  } = useMutation({
    mutationKey: [ 'generate', experimentId ],
    mutationFn: () => wizataApi.templates.generateTemplate({ experimentId }),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/experiments.generate.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: (data) => {
      router.push({ name: 'template', params: { id: data.id } })
    },
  })
  const onChangeView = (next) => {
    router.push({ query: { ...query, view: next } })
  }

  const handleGenerate = async () => {
    await window.wizConfirm({ message: 't/templates.confirmGenerate' })
    generate()
  }

  const list = useMemo(() => {
    if (data) {
      const next = data.map(item => ({ ...item, name: `${formatDate(item.createdDate)} by ${item.user.name}` }))
      const sorted = orderBy(next, [
        item => item.createdDate,
      ], [ 'desc' ])
      return sorted
    }
    return []
  }, [ data ])

  const hasCorrectExecution = experiment?.id === execution?.experimentId
  const isExperimentUpdating = !hasCorrectExecution || isExecutionLoading || isExecuting ||
  [ 'queued', 'started', 'received' ].includes(execution?.status)

  const {
    mutate: deletePipeline,
  } = useMutation({
    mutationKey: [ 'deleteExperiment' ],
    mutationFn: () => wizataApi.experiments.deleteById(experimentId),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/templates.createError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'pipelinesList' ] })
      router.push({ name: 'experiments' })
    },
  })

  const handleRemove = async () => {
    await window.wizConfirm({ message: 't/units.confirmDelete' })
    deletePipeline()
  }

  useEffect(() => {
    if (list && !isExecuting) {
      refetch()
    }
  }, [ isExecuting, refetch ])

  useEffect(() => {
    if (isComplete) {
      clearInterval(intervalRef.current)
    }

    if (execution && ![ 'completed', 'failed' ].includes(execution.status) && !isComplete) {
      intervalRef.current = setInterval(() => {
        refetchExecution()
      }, 2000)
    }

    return () => clearInterval(intervalRef.current)
  }, [ execution, refetchExecution, isComplete ])

  return (
    <header
      className={classnames('d-flex align-items-center w-100', {
        'flex-wrap': !isMobile,
      })}
    >
      <div className="d-flex">
        {auth.checkAccessUpdate('Experiment') ? (
          <button
            type="button"
            className="btn btn-fill-secondary-alt btn-text me-2"
            onClick={() => onEdit('experiments', true, experimentId)}
          >
            <IconFa name="faPenToSquare" className="me-2" />
            {intl.t('form.actions.edit')}
          </button>
        ) : null}
        {auth.checkAccessRemove('Experiment') ? (
          <button
            type="button"
            className="btn btn-fill-secondary-alt btn-text me-2"
            onClick={handleRemove}
          >
            <IconFa name="faTrash" className="me-2" />
            {intl.t('form.actions.remove')}
          </button>
        ) : null}
      </div>
      <div className="fw-bold d-flex align-items-center">
        {pipelineIsLoading ? <Skeleton width={120} height={26} /> : (
          <>
            <Icon name="wiz--menu--pipeline" className="me-1" />
            {pipeline?.key}
          </>
        )}
        &nbsp;
        /
        &nbsp;
      </div>
      {experiments?.length ? (
        <div className="d-flex align-items-center">
          {isLoading ? <Skeleton width={300} height={26} /> : !experimentsError ? (
            <>
              <button
                type="button"
                className="btn btn-fill-secondary-alt btn-text"
                ref={refExperimentsTarget}
                disabled={experimentsLoading}
              >
                {experiment ? (
                  <>
                    <IconFa name="faFlask" />
                    <span className="text-truncate ms-1 me-2">
                      {experiment.key || experiment.name}
                    </span>
                  </>
                ) : null}
                <IconFa
                  name={experimentsLoading ? 'faSpinner' : 'faChevronDown'}
                  spin={experimentsLoading}
                />
              </button>

              <Dropdown
                ref={refExperimentsDropdown}
                refTarget={refExperimentsTarget}
                options={experiments.filter(exp => exp.pipelineId === pipeline?.id)}
                placement="bottom"
                onChange={(item) => {
                  refExperimentsDropdown.current.close()
                  router.push({ name: 'experiment', params: { id: item.id } })
                }}
              />
            </>
          ) : null}

          &nbsp;
          /
          &nbsp;
        </div>
      ) : null}
      {list?.length ? (
        <div className="d-flex align-items-center">
          {isExperimentUpdating ? <Skeleton width={125} height={26} className="ms-2" /> : !isError ? (
            <>
              <button
                type="button"
                className="btn btn-fill-secondary-alt btn-text"
                ref={refTarget}
                disabled={isLoading || isExecutionLoading || isExecuting}
              >
                {execution ? (
                  <>
                    <Icon name="wiz--menu--execution" size="lg" />
                    <span className="text-truncate ms-1 me-2">
                      {formatDate(execution.createdDate)}
                      &nbsp;
                      by
                      &nbsp;
                      {execution.user?.name}
                    </span>
                  </>
                ) : null}
                <IconFa
                  name={isLoading ? 'faSpinner' : 'faChevronDown'}
                  spin={isLoading}
                />
              </button>
              <Dropdown
                ref={refDropdown}
                refTarget={refTarget}
                options={list}
                placement="bottom"
                onChange={(item) => {
                  refDropdown.current.close()
                  onSelectExecution(item.id)
                  // router.push({ query: { execution: item.id } })
                }}
              />
            </>
          ) : null}

          {execution?.status === 'completed' ? (
            <button
              type="button"
              className="btn btn-fill-secondary-alt btn-text mx-1"
              title={intl.t(`experiments.topbar.${viewTypes[query.view || 'tab']?.id}`)}
              onClick={() => onChangeView(viewTypes[query.view || 'tab']?.next)}
            >
              <IconFa
                name={viewTypes[query.view || 'tab']?.icon}
              />
            </button>
          ) : null}
        </div>
      ) : null}
      {
        !isLoading && !isExecutionLoading && !list?.length ? (
          <div className="d-flex align-items-center px-4">
            <button
              type="button"
              className="btn btn-fill-secondary-alt btn-text"
              title={intl.t('experiments.topbar.noData')}
              disabled
            >
              {intl.t('experiments.topbar.noData')}
            </button>
          </div>
        ) : null
      }
      <div className="d-flex ms-auto">
        <button
          type="button"
          className="btn btn-fill-secondary-alt btn-text me-2"
          onClick={() => {
            onFilter({ experimentId, pipelineId: pipeline.id, experiments: 'isNotNull' })
            router.push({ name: 'executions-list' })
          }}
          disabled={!pipeline?.id}
        >
          <IconFa name="faEye" className="me-2" />
          {intl.t('form.actions.viewLogs')}
        </button>
        {auth.checkAccessCreate('HistoricalRun') ? (
          <button
            type="button"
            className="btn btn-fill-secondary-alt btn-text me-2"
            onClick={() => onRun('execute', null, null, { pipeline, experiment, experimentId })}
          >
            <IconFa name="faCirclePlay" className="me-2" />
            {intl.t('form.actions.execute')}
          </button>
        ) : null}
      </div>

    </header>
  )
})

export default Topbar
