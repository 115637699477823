import FormEdgeDeviceCommand from '@/containers/Forms/EdgeDeviceCommand'

export default function RightBarCreateCommand ({
  twinId,
  sensorId,
  onChangeBack,
}) {
  return (
    <FormEdgeDeviceCommand
      withSensorsByTwin
      defaultValues={{ twinId, sensorId }}
      onClose={onChangeBack}
    />
  )
}
