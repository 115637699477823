/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import { Icon } from '@wiz/components'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'
import CellCategory from './CellCategory'

export default function ViewModel ({
  title,
  unit,
  onClose,
  onRemove,
  onEdit,
}) {
  const refDialog = useRef()
  const intl = useIntl()
  const auth = useAuth()

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('form.fields.icon'),
          value: () => (unit.icon || unit.color ? (
            <Icon
              name={unit.icon || 'fa--circle'}
              color={unit.color}
              size="lg"
              className="me-2"
            />
          ) : null),
        },
        {
          title: intl.t('units.form.fields.fullName'),
          value: unit.fullName || '-',
        },
        {
          title: intl.t('units.form.fields.shortName'),
          value: unit.shortName || '-',
        },
        {
          title: intl.t('units.form.fields.symbol'),
          value: unit.symbol || '-',
        },
        {
          title: intl.t('units.form.fields.category'),
          value: () => (
            <CellCategory row={{ original: unit }} />
          ),
        },
      ],
    },

    {
      title: intl.t('form.fields.updated'),
      value: () => (
        <UpdatedInfo
          date={unit.updatedAt}
          user={unit.updatedUser}
        />
      ),
    },
    {
      title: intl.t('form.fields.created'),
      value: () => (
        <UpdatedInfo
          date={unit.createdAt}
          user={unit.createdUser}
        />
      ),
    },
  ]

  const actions = [
    ...(onRemove && auth.checkAccessRemove(unit) ? ([{
      title: intl.t('form.actions.remove'),
      name: 'remove',
      testid: 'removeButton',
      onClick: () => onRemove([ unit ]),
    }]) : []),

    ...(onEdit && auth.checkAccessUpdate(unit) ? ([{
      title: intl.t('form.actions.edit'),
      name: 'edit',
      testid: 'editButton',
      onClick: () => onEdit(unit.id),
    }]) : []),
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      actions={actions}
      onClose={onClose}
    />
  )
}
