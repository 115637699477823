import {
  useLayoutEffect,
  useEffect,
  useRef,
} from 'react'
import Split from 'split.js'

function normalizeSizes (cnt, sizes) {
  let next = sizes

  if (next.length < cnt) {
    const len = cnt - next.length
    next.splice(next.length, 0, ...(new Array(len)).fill(0, 0, len))
  }

  next = next.map(i => (i < 3 ? 0 : i))
  const sumSizes = next.reduce((o, i) => (o + i), 0)
  if (sumSizes !== 100) {
    next = sumSizes ?
      next.map(i => ((i * 100) / sumSizes)) :
      (new Array(cnt)).fill(100 / cnt, 0, cnt)
  }

  return next
}

export default function useSplit ({
  items,
  sizes,
  direction,
  defaultSizes,
  disabled,
  minSize,
  onChange,
}) {
  const refSplit = useRef()

  useLayoutEffect(() => {
    let split

    if (!disabled) {
      const cnt = items.length

      split = Split(items.map(item => item.current), {
        direction: direction || 'vertical',
        expandToMin: true,
        gutterSize: 5,
        snapOffset: 10,
        sizes: normalizeSizes(cnt, sizes || defaultSizes || []),
        minSize: minSize || 0,
        gutter (index, dir) {
          const gutter = document.createElement('div')
          gutter.className = `gutter gutter-${dir} hover-visible`
          return gutter
        },
        onDragEnd (data) {
          onChange?.(normalizeSizes(cnt, data))
        },
        elementStyle: (dimension, size, gutterSize) => ({
          'flex-basis': `calc(${size}% - ${gutterSize}px)`,
        }),
        gutterStyle: (dimension, gutterSize) => ({
          'flex-basis': `${gutterSize}px`,
        }),
      })
    }

    refSplit.current = split

    return () => {
      split?.destroy(true)
    }
  }, [
    defaultSizes,
    direction,
    disabled,
    items,
    minSize,
    onChange,
    sizes,
  ])

  useEffect(() => {
    if (refSplit.current) {
      const cnt = items.length
      const nextSizes = normalizeSizes(cnt, sizes || defaultSizes || [])
      refSplit.current.setSizes(nextSizes)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ sizes ])

  return refSplit
}
