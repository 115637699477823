import { useState, useEffect } from 'react'
import { withProps } from '@wiz/components'
import { wizataApi } from '@/api'

const enhanceData = withProps(({
  filters,
}) => {
  const [ loading, setLoading ] = useState(true)
  const [ items, setItems ] = useState([])
  const [ total, setTotal ] = useState(0)

  useEffect(() => {
    setLoading(true)

    const limit = filters.pageSize + 1
    const offset = (filters.page - 1) * filters.pageSize

    wizataApi.logs.edge({
      ...filters,
      limit,
      offset,
    })
      .then((data) => {
        setTotal(offset + data.length)
        setItems(data.slice(0, filters.pageSize))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [ filters ])

  return {
    total,
    items,
    loading,
  }
})

export default enhanceData
