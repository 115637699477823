import { useCallback } from 'react'
import { of as of$, throwError } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { withObservables, withProps } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { auth } from '@/auth'
import enhanceWidgets from '@/containers/Dashboards/enhanceWidgets'
import enhanceViewProps from '@/containers/Dashboards/enhanceViewProps'
import Component from '@/components/dashboards/View'

const enhanceDashboard = withObservables([ 'id' ], ({ id }) => ({
  dashboard: dbProvider.database.collections.get('dashboards')
    .query(Q.where('id', id))
    .observeWithColumns([ 'updated_at' ])
    .pipe(
      map(items => items.filter(item => auth.checkAccessRead(item))),
      mergeMap(items => (items.length ? of$(items[0]) : throwError('Dashboard not found'))),
    ),
}), {
  onError (error, { onClose }) {
    onClose()
  },
})

const enhanceProps = withProps(({
  onChange,
  onChangeBack,
}) => {
  const openDashboard = useCallback((data) => {
    onChange('dashboard', {
      title: data.title,
      props: { id: data.id },
    })
  }, [ onChange ])

  const closeDashboard = useCallback(() => {
    onChangeBack()
  }, [ onChangeBack ])

  return {
    openDashboard,
    closeDashboard,
  }
})

export default enhanceDashboard(
  enhanceProps(
    enhanceWidgets(
      enhanceViewProps(Component),
    ),
  ),
)
