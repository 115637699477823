import {
  forwardRef,
  useRef,
  useEffect,
  useImperativeHandle,
} from 'react'
import { useQuery } from '@tanstack/react-query'
import { DropdownMenu, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { consts } from '@wiz/utils'
import { useAuth } from '@/auth'
import { MENU_ALIGMENTS } from '@/config'
import { wizataApi } from '@/api'

const ContextMenu = forwardRef(({
  block,
  blocks,
  blockIds,
  coords,
  container,
  latlng,
  loc,
  mapView,
  onAction,
  onHide,
  hideGrid,
  rootTwin,
  twinSettings,
  sensorIds,
  functions,
}, ref) => {
  const refTarget = useRef()
  const refMenu = useRef()
  const intl = useIntl()
  const auth = useAuth()
  const isMobile = useMobile()

  useEffect(() => {
    if (coords) {
      refMenu.current?.open()
    }
    return () => {
      refMenu.current?.close()
    }
  }, [ coords ])

  useImperativeHandle(ref, () => ({
    close () {
      refMenu.current?.close()
    },
  }))

  const { data: templates } = useQuery({
    queryKey: [ 'templates', blocks.length ? blocks[0].id : rootTwin?.id ],
    queryFn: () => wizataApi.templates.getList('false'),
    enabled: !!rootTwin || !!blocks.length,
    staleTime: Infinity,
  })

  // const hasParent = rootTwin?.parentId

  const ExperimentsActions = (rootTwin || blocks.length) ? [
    {
      action: 'runWizard',
      label: 'Detect Anomalies',
      icon: 'fa--magic',
      disabled: !auth.checkAccessCreate('Experiment'),
    },
    ...(functions.length ? [
      {
        label: 'Perform an analysis',
        icon: 'fa--chart-network',
        options: functions.map(item => ({
          action: 'runWizard',
          params: { customFunction: item },
          label: item.title,
        })),
        disabled: !auth.checkAccessCreate('Experiment'),
      },
    ] : []),
    {
      label: intl.t('form.actions.selectTemplate'),
      icon: 'placeholder',
      options: templates?.map(item => ({
        action: 'selectTemplate',
        params: { templateId: item.id, twinId: blocks.length ? blocks[0].id : rootTwin?.id },
        label: item.name,
      })),
      disabled: !templates?.length || blocks.length > 1,
    },
  ] : []

  const DataPointActions = sensorIds.length ? [
    {
      label: intl.t('chart.contextMenu.sensors', { sensorsCount: sensorIds.length }),
      icon: 'fa--signal-stream',
      options: [
        {
          action: 'explorer',
          params: sensorIds,
          label: intl.t('explorer.title'),
          icon: 'fa--search',
          disabled: !auth.checkAccessCreate('SectionDataExplorer'),
        },
        {
          action: 'export',
          params: sensorIds,
          label: intl.t('form.actions.exportTitle'),
          icon: 'fa--file-download',
          disabled: !auth.checkAccessCreate('Job'),
        },
        {
          action: 'notebook',
          params: sensorIds,
          label: intl.t('form.actions.createNotebook'),
          icon: 'fa--file-contract',
          disabled: !auth.checkAccessCreate('Job'),
        },
        {
          action: 'dashboard',
          params: sensorIds,
          label: intl.t('form.actions.dashboard'),
          icon: 'fa--th-large',
          disabled: !auth.checkAccessCreate('SectionDashboards'),
        },
      ],
    },
  ] : []

  const BlockActions = (rootTwin || block) ? [
    {
      action: 'edit',
      label: intl.t('form.actions.edit'),
      icon: 'fa--edit',
      disabled: !block && rootTwin ?
        !auth.checkAccessUpdate(rootTwin) :
        !auth.checkAccessUpdate(block),
    },
  ] : []

  const SelectedActions = (rootTwin || blocks.length) ? [
    {
      label: '...',
      icon: 'placeholder',
      options: [
        ...(block?.type !== consts.TwinType.Flow ? [
          {
            action: 'selectWithChildren',
            label: intl.t('form.actions.selectWithChildren'),
            icon: 'fa--check',
            disabled: !auth.checkAccessUpdate(block),
          },
        ] : []),
        {
          action: 'duplicate',
          label: intl.t('form.actions.duplicate'),
          icon: 'fa--copy',
          disabled: !blocks.some(item => auth.checkAccessCopy(item)),
        },
        {
          action: 'duplicateWithChildren',
          label: intl.t('form.actions.duplicateWithChildren'),
          icon: 'fa--copy',
          disabled: (
            blocks.every(item => item.type === consts.TwinType.Flow) ||
          !blocks.some(item => auth.checkAccessCopy(item))
          ),
        },
        {
          action: 'findReplace',
          label: intl.t('form.actions.findReplace'),
          icon: 'fa--search',
        },
        {
          action: 'highlightOnTheTree',
          label: intl.t('form.actions.highlightOnTheTree'),
          icon: 'fa--eye',
        },
        {
          action: 'sendToBoard',
          label: intl.t('form.actions.sendToBoard'),
          icon: 'flow',
        },
        {
          action: 'remove',
          label: intl.t('form.actions.remove'),
          icon: 'fa--trash-alt',
        },
      ],
    },
  ] : []

  const AttachBlocks = [
    {
      label: intl.t('form.actions.add'),
      icon: 'fa--plus',
      options: [
        {
          action: 'attach',
          params: { type: consts.TwinType.Area },
          color: twinSettings.twinColorAreas,
          label: 't/enum.twinType.area',
          icon: 'fa--warehouse',
          disabled: !(
            auth.checkAccessCreate('Twin') &&
            (!rootTwin || consts.TwinLinkPairs.some(item => (
              item[0] === rootTwin.type &&
              item[1] === consts.TwinType.Area
            )))
          ),
        },
        {
          action: 'attach',
          params: { type: consts.TwinType.Machine },
          color: twinSettings.twinColorMachines,
          label: 't/enum.twinType.machine',
          icon: 'fa--cogs',
          disabled: !(
            auth.checkAccessCreate('Twin') &&
            (!rootTwin || consts.TwinLinkPairs.some(item => (
              item[0] === rootTwin.type &&
              item[1] === consts.TwinType.Machine
            )))
          ),
        },
        {
          action: 'attach',
          params: { type: consts.TwinType.Equipment },
          color: twinSettings.twinColorEquipment,
          label: 't/enum.twinType.equipment',
          icon: 'fa--tools',
          disabled: !(
            auth.checkAccessCreate('Twin') &&
            (!rootTwin || consts.TwinLinkPairs.some(item => (
              item[0] === rootTwin.type &&
              item[1] === consts.TwinType.Equipment
            )))
          ),
        },
      ]
        .filter(item => (
          !block ||
          consts.TwinLinkPairs.some(pair => (
            pair[0] === block.type &&
            pair[1] === item.params.type
          ))
        ))
        .map(item => ({ ...item, label: intl.t(item.label) })),
    },
  ]

  const Connect = (rootTwin || blocks.length) ? [
    {
      label: intl.t('form.actions.connect'),
      icon: 'fa--bezier-curve',
      disabled: blocks.length !== 2,
      options: [
        {
          label: intl.t('form.actions.linkParent'),
          icon: 'fa--sitemap',
          action: 'linkParentMode',
        },
        {
          label: intl.t('form.actions.linkDescr'),
          icon: 'fa--project-diagram',
          action: 'linkDescrMode',
        },
      ],
    },
  ] : []

  const Alignments = !block || block.type !== consts.TwinType.Flow ? [
    {
      label: intl.t('chart.contextMenu.alignment'),
      icon: 'fa--align-right',
      options: MENU_ALIGMENTS.filter(item => item.toolbar)
        .map(item => ({
          label: intl.t(item.label),
          icon: item.icon,
          action: 'alignment',
          params: item.options,
          disabled: mapView,
        })),
      disabled: mapView,
    },
  ] : []

  const View = !block || block.type !== consts.TwinType.Flow ? [
    {
      label: intl.t('form.actions.view'),
      icon: 'fa--arrows-alt',
      options: [
        {
          action: 'toggleGrid',
          label: intl.t('twinGraph.form.actions.toggleGridTitle'),
          icon: hideGrid ? 'fa--border-none' : 'fa--border-all',
        },
        {
          action: 'fitPosition',
          label: intl.t('chart.form.actions.fitPosition'),
          icon: 'fa--compress-arrows-alt',
        },
        {
          action: 'reshapeLinks',
          label: intl.t('chart.form.actions.reshapeLinks'),
          icon: 'fa--paint-roller',
        },
      ],
      disabled: mapView,
    },
  ] : []

  const More = isMobile && blocks.length ? [
    {
      label: intl.t('chart.contextMenu.more'),
      icon: 'fa--ellipsis-h',
      options: [
        {
          action: 'commands',
          icon: 'fa--terminal',
          label: intl.t('edge.commands.title'),
          disabled: !auth.checkAccessManage('SectionDeviceCommands'),
        },
        {
          action: 'events',
          icon: 'fa--calendar',
          label: intl.t('events.title'),
          disabled: !auth.checkAccessManage('SectionEvents'),
        },
        {
          action: 'sensors',
          icon: 'fa--sensor',
          label: intl.t('sensors.titleLinked'),
          disabled: !auth.checkAccessManage('SectionEvents'),
        },
        {
          action: 'setPoints',
          icon: 'fa--file-signature',
          label: intl.t('sensors.titleLinkedSetPoints'),
          disabled: !auth.checkAccessManage('SectionDigitalTwinSensors'),
        },
        {
          action: 'measurements',
          icon: 'fa--ruler',
          label: intl.t('sensors.titleLinkedMeasurements'),
          disabled: !auth.checkAccessManage('SectionDigitalTwinSensors'),
        },
        {
          action: 'info',
          icon: 'fa--info-circle',
          label: intl.t('twin.titleLinked'),
        },
      ],
    },
  ] : []

  const options = [
    ...BlockActions,
    ...DataPointActions,
    ...AttachBlocks,
    ...Connect,
    // ...(SelectedActions.length ? [{ divider: true }] : []),
    ...SelectedActions,
    ...((ExperimentsActions.length) ? [{ divider: true }] : []),
    ...ExperimentsActions,
    ...((View.length || More.length || Alignments.length) ? [{ divider: true }] : []),
    ...Alignments,
    ...View,
    ...More,
  ]

  return (
    <>
      <div
        ref={refTarget}
        style={coords ? {
          display: 'block',
          left: `${coords[0]}px`,
          top: `${coords[1]}px`,
          pointerEvents: 'none',
          position: 'absolute',
          zIndex: 10001,
          width: '1px',
          height: '1px',
        } : {
          display: 'none',
        }}
      />

      <DropdownMenu
        ref={refMenu}
        target={refTarget}
        mode={null}
        container={container}
        placement="right-start"
        options={options}
        onHide={onHide}
        onClick={params => onAction?.(params, {
          block,
          loc,
          latlng,
        })}
      />
    </>
  )
})

export default ContextMenu
