import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'
import Component from '@/components/StreamJobs/ContextMenu'

const enhanceData = withObservables([ 'block' ], ({ block }) => ({
  block: block?.id ? (
    dbProvider.database.collections.get('diagram_blocks')
      .query(Q.where('id', block.id))
      .observeWithColumns([ 'updated_at' ])
      .pipe(map(items => items[0]))
  ) : of$(undefined),
}))

export default enhanceData(Component)
