import { useFormContext } from 'react-hook-form'
import { get } from '@wiz/utils'
import {
  FormField,
  FormControl,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('integrations.form.fields.name')}
        description={intl.t('integrations.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (!str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value.length > 200) {
                return intl.t('form.errors.fieldMaxlen', { max: 200 })
              }
              return true
            },
          }}
        />
      </FormField>
      <FormField
        label={intl.t('integrations.form.fields.description')}
        description={intl.t('integrations.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          name={`${scope}.description`}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (!str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value.length > 200) {
                return intl.t('form.errors.fieldMaxlen', { max: 200 })
              }
              return true
            },
          }}
        />
      </FormField>
    </>
  )
}
