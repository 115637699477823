import { auth } from '@/auth'

export default function sync (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      sync: {
        enumerable: true,
        value: Object.create({}, {
          pullChanges: {
            enumerable: true,
            value (lastPulledAt, twinContextId) {
              return client.get('/Sync', { last_pulled_at: lastPulledAt, twin_context_id: twinContextId }, {
                headers: {
                  AUTH_SCHEME: auth.authScheme,
                },
              }).fetch()
            },
          },

          pushChanges: {
            enumerable: true,
            value (lastPulledAt, changes, twinContextId) {
              return client.post('/Sync', changes, {
                params: { last_pulled_at: lastPulledAt, twin_context_id: twinContextId },
                headers: {
                  AUTH_SCHEME: auth.authScheme,
                },
              }).fetch()
            },
          },
        }),
      },
    })
  }
}
