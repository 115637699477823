import { useRef } from 'react'
import { FormSection, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import FieldsTargets from './FieldsTargets'

export default function SectionTargets ({ scope }) {
  const refFields = useRef()
  const intl = useIntl()

  return (
    <FormSection
      title={intl.t('notificationSheet.section.recipients')}
      description={intl.t('notificationSheet.section.recipientsDescr')}
      addon={(
        <button
          type="button"
          className="btn btn-sm btn-flat-secondary mt-2 mt-md-0 ms-md-3 text-nowrap min-w-0"
          onClick={() => refFields.current.add()}
        >
          <Icon name="fa--plus" size="md" className="me-1" />
          {intl.t('form.actions.addRecipient')}
        </button>
      )}
    >
      <FieldsTargets ref={refFields} scope={scope} />
    </FormSection>
  )
}
