import {
  useEffect, useRef, useImperativeHandle, useState, useMemo,
} from 'react'
import {
  useFormContext,
} from 'react-hook-form'
import cx from 'classnames'
import { DateTime } from 'luxon'
import { useIntl } from '@wiz/intl'
import {
  FormControl,
  FormField,
  FormFieldInline,
  CustomScrollbars,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { autolink } from '@/utils/string'
import { wizataApi } from '@/api'
import classes from './index.css'

const now = DateTime.local()
const DateTimeFormats = [
  'dd/MM/yyyy HH:mm:ss.ffffff',
  'dd/MM/yyyy hh:mm:ss.ffffff tt',
  'MM/dd/yyyy HH:mm:ss.ffffff',
  'MM/dd/yyyy hh:mm:ss.ffffff tt',
  'dd.MM.yyyy HH:mm:ss.ffffff',
  'dd.MM.yyyy hh:mm:ss.ffffff tt',
  'yyyy/MM/ddTHH:mm:ss.ffffff',
  'yyyy/MM/dd HH:mm:ss.ffffff',
  'yyyy/MM/dd hh:mm:ss.ffffff tt',
  'yyyy-MM-dd HH:mm:ss.ffffff',
  'yyyy-MM-dd hh:mm:ss.ffffff tt',
  'dd MMM yyyy HH:mm:ss.ffffff',
  'dd MMM yyyy hh:mm:ss.ffffff tt',
].map(item => ({
  id: item,
  name: now.toFormat(
    item
      .replace('tt', 'a')
      .replace('ffffff', 'SSS000')
      .replace('T', '\'T\''),
  ),
}))

export default function SectionValidation ({
  job,
  scope,
  refValidation,
}) {
  const [ detectedDTFormats, setFormats ] = useState(null)

  const intl = useIntl()
  const autoDetectRef = useRef(false)

  const {
    formState: { errors }, watch, clearErrors, setError, getValues,
  } = useFormContext()

  const [ isAccepted, autoDetect ] = watch([ `${scope}.validation.accept`, `${scope}.parameters.autoDetect` ])

  const DTFormats = useMemo(() => {
    if (detectedDTFormats) {
      return detectedDTFormats.map(item => ({
        id: item,
        name: now.toFormat(
          item
            .replace('tt', 'a')
            .replace('ffffff', 'SSS000')
            .replace('T', '\'T\''),
        ),
      }))
    }
    return null
  }, [ detectedDTFormats ])

  const formField = useMemo(() => {
    const finalDTFormats = DTFormats || DateTimeFormats

    return (
      <FormControl
        type="select"
        name={`${scope}.parameters.dateTimeFormat`}
        rules={{
          required: intl.t('form.errors.fieldRequired'),
        }}
        placeholder={intl.t('form.fields.dateTimeFormatPlaceholder')}
        options={finalDTFormats}
      />
    )
  }, [ scope, intl, DTFormats ])

  useImperativeHandle(refValidation, () => ({
    checkAccepted () {
      if (isAccepted) {
        clearErrors(`${scope}.validation.accept`)
        return true
      }
      setError(`${scope}.validation.accept`, { type: 'custom', message: intl.t('form.errors.fieldRequired') })
      return false
    },
  }))

  useEffect(() => {
    if (isAccepted) {
      clearErrors(`${scope}.validation.accept`)
    }
  }, [ isAccepted, clearErrors ])

  useEffect(() => {
    if (autoDetect && !autoDetectRef.current) {
      const { files, uploadedFiles } = getValues()
      const file = files || uploadedFiles?.[0]
      if (file && job) {
        /*
          {
            "success": bool,
            "dateTimeFormat": string[] || null
          }
        */
        wizataApi.getFileImportAutoDetection(job.id, file).then(({ success, dateTimeFormat }) => {
          if (success) { // auto-detection went well
            if (dateTimeFormat?.length) {
              setFormats(dateTimeFormat)
            }
          } else {
            setFormats('')
          }
        })
      }
      autoDetectRef.current = true
    }
  }, [ autoDetect, setFormats, getValues ])

  return (
    <div className="d-flex flex-column h-100">
      <h6>{intl.t('jobs.form.fields.validation')}</h6>
      <CustomScrollbars
        horizontal={false}
        shadow={false}
        autoHeightMax={999}
      >
        {/* <div dangerouslySetInnerHTML={{ __html: job?.validationResult }} /> */}
        <p className="small">
          {job.validationResult ? (
          // eslint-disable-next-line react/no-danger
            <span dangerouslySetInnerHTML={{ __html: autolink(job.validationResult, true) }} />
          ) : intl.t('form.nodata')}
        </p>
      </CustomScrollbars>

      <FormFieldInline
        label={intl.t('form.fields.autoDetect')}
        description={intl.t('alert.form.fields.autoDetectDescr')}
        errors={get(errors, `${scope}.parameters.autoDetect`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.parameters.autoDetect`}
          className="ms-2"
        />
      </FormFieldInline>

      <FormField
        label={intl.t('form.fields.dateTimeFormat')}
        description={intl.t('jobs.form.fields.dateTimeFormatDescr')}
        errors={get(errors, `${scope}.parameters.dateTimeFormat`)}
      >
        {formField}
      </FormField>

      <FormFieldInline
        label={intl.t('job.form.fields.acceptValidation')}
        description={intl.t('job.form.fields.acceptValidationDescr')}
        errors={get(errors, `${scope}.validation.accept`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.validation.accept`}
          className={cx('ms-2', classes.zIndex)}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
        />
      </FormFieldInline>
    </div>
  )
}
