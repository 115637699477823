import { Controller, useFormContext } from 'react-hook-form'
import {
  FormSection,
  FormField,
} from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import Select from '@/components/Form/Select'
import SelectSensor from '@/components/Form/SelectSensor'
import EdgeDeviceList from '@/hoc/EdgeDeviceList'
import DeviceCommandTemplateList from '@/hoc/DeviceCommandTemplateList'

export default function Section ({ scope, title, description }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <FormSection
      title={title}
      description={description}
    >
      <FormField
        label={intl.t('form.fields.device')}
        description={intl.t('widgets.deviceCommand.form.fields.deviceDescr')}
        errors={get(errors, `${scope}.deviceId`)}
      >
        <Controller
          name={`${scope}.deviceId`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.devicePlaceholder')}
              ListSource={EdgeDeviceList}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('widgets.deviceCommand.form.fields.template')}
        description={intl.t('widgets.deviceCommand.form.fields.templateDescr')}
        errors={get(errors, `${scope}.templateId`)}
      >
        <Controller
          name={`${scope}.templateId`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('widgets.deviceCommand.form.fields.templatePlaceholder')}
              ListSource={DeviceCommandTemplateList}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.sensor')}
        description={intl.t('widgets.deviceCommand.form.fields.sensorDescr')}
        errors={get(errors, `${scope}.sensorId`)}
      >
        <Controller
          name={`${scope}.sensorId`}
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorPlaceholder')}
              excludeBusinessTypes={consts.ExcludedBusinessTypes}
            />
          )}
        />
      </FormField>
    </FormSection>
  )
}
