import {
  forwardRef,
  useState,
  useImperativeHandle,
} from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import classnames from 'classnames'
import { uuid, has } from '@wiz/utils'
import {
  FormFieldInline,
  Icon,
  useDidUpdate,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'

const InputText = forwardRef(({
  scope,
  name,
  className,
  onChange,
  placeholder,
}, ref) => {
  const intl = useIntl()
  const { watch, trigger, setValue } = useFormContext()
  const [ defaultValue ] = watch([
    name,
  ])

  const [ currentValue, setCurrentValue ] = useState(defaultValue)

  useDidUpdate(() => {
    setCurrentValue('')
    setValue(`${scope}.${placeholder}`, '', { shouldDirty: true })
  }, [])

  return (
    <input
      ref={ref}
      name={name}
      value={currentValue || ''}
      type="search"
      placeholder={placeholder}
      className={classnames('form-control w-100', className)}
      onChange={(event) => {
        setCurrentValue(event.target.value)
        onChange(event.target.value)
        trigger(name)
      }}
    />
  )
})

const FieldsSubscribers = forwardRef(({ scope }, ref) => {
  const intl = useIntl()

  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext()

  const { fields, prepend, remove } = useFieldArray({
    name: scope,
    keyName: '_id',
    shouldUnregister: true,
  })

  useImperativeHandle(ref, () => ({
    add () {
      prepend({
        id: uuid(),
        protocol: '',
        endpoint: null,
        subscriptionArn: '',
        active: true,
      })
    },
  }))

  return (
    <FormFieldInline complex>
      <div className="list-group list-group-flush mx-0 flex-fill">
        {fields.map((item, idx) => {
          const scopeRow = `${scope}.${idx}`

          return (
            <div
              key={item._id}
              className="list-group-item py-2 px-0 d-flex align-items-center"
            >
              <input
                {...register(`${scopeRow}.subscriptionArn`)}
                type="hidden"
              />

              <input
                {...register(`${scopeRow}.id`)}
                type="hidden"
              />

              <input
                {...register(`${scopeRow}.active`)}
                type="checkbox"
                className={classnames('form-check-input mt-0 me-3 flex-shrink-0', {
                  'is-invalid': has(errors, `${scopeRow}.active`),
                })}
              />

              <InputText
                {...register(`${scopeRow}.protocol`, {
                  required: intl.t('form.errors.fieldRequired'),
                  maxLength: {
                    value: 450,
                    message: intl.t('form.errors.fieldMaxlen', { max: 450 }),
                  },
                })}
                placeholder="protocol"
                scope={scopeRow}
                className={classnames('me-2', {
                  'is-invalid': has(errors, `${scopeRow}.protocol`),
                })}
                onChange={(value) => {
                  setValue(`${scopeRow}.protocol`, value, { shouldDirty: true })
                }}
              />

              <InputText
                {...register(`${scopeRow}.endpoint`, {
                  required: intl.t('form.errors.fieldRequired'),
                  maxLength: {
                    value: 450,
                    message: intl.t('form.errors.fieldMaxlen', { max: 450 }),
                  },
                })}
                placeholder="endpoint"
                scope={scopeRow}
                className={classnames('me-2', {
                  'is-invalid': has(errors, `${scopeRow}.endpoint`),
                })}
                onChange={(value) => {
                  setValue(`${scopeRow}.endpoint`, value, { shouldDirty: true })
                }}
              />

              <button
                type="button"
                className="btn btn-flat-secondary"
                title={intl.t('form.actions.remove')}
                onClick={() => remove(idx)}
              >
                <Icon name="fa--trash-alt" />
              </button>
            </div>
          )
        })}
        {!fields.length ? (
          <div className="list-group-item text-center">
            {intl.t('form.info.recipientsNotFound')}
          </div>
        ) : null}
      </div>
    </FormFieldInline>
  )
})

export default FieldsSubscribers
