import {
  useRef,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { useDidUpdate, useForceUpdate } from '@wiz/components'
import { isEmpty } from '@wiz/utils'
import RightBarMenu from '@/components/RightBarMenu'
import RightBarFixedMenu from '@/components/RightBarFixedMenu'

const RightBarDiagram = forwardRef(({
  blocks,
  blockSettings,
  container,
  options,
  blockIds,
  setEditBlockId,
  fixed,
  attachedSensorsCount,
  ...props
}, ref) => {
  const { streamJob } = props
  const refDialog = useRef(null)
  const refBlocks = useRef(blocks)
  const refBlockSettings = useRef(blockSettings)
  const [ value, setValue ] = useState(null)
  const [ back, setBack ] = useState(null)
  const [ pinned, setPinned ] = useState(false)
  const [ expanded, setExpanded ] = useState({ expanded: false, expandedClose: false })
  const forceUpdate = useForceUpdate()
  const hasBlockSettings = !isEmpty(refBlockSettings.current)

  const onClose = useCallback(() => {
    if (!pinned) {
      refDialog.current?.close()
    }
  }, [ pinned ])

  const onChange = useCallback((name, params) => {
    if (name === 'edit') {
      setEditBlockId?.(blockIds[0])
    }

    const data = options.find(item => item.id === name)
    if (!data) {
      return
    }

    if (params === true) {
      if (name === value?.id) {
        onClose()
      } else {
        setBack(name)
        setValue(data)
      }
    } else {
      if (params?.props?.expanded) {
        setExpanded({ expanded: true, expandedClose: true })
      }
      setValue({
        ...data,
        ...params,
        back,
      })
    }
  }, [
    options,
    back,
    value,
    onClose,
    setEditBlockId,
    blockIds,
  ])

  useDidUpdate(() => {
    if (!hasBlockSettings) {
      onClose()
    }
  }, [ hasBlockSettings, onClose ])

  useDidUpdate(() => {
    if (fixed) {
      setPinned(true)
    }
    /*
      FIXME: remake this check properly.
      1st check is for closing the rightbar if nothing selected(or unselect all) on diagram
      2nd check is for SIJ, there we have no settings and 1st check is true
      3rd check is for solutions/experiments in Boards

      problem: without last 2 checks it 'closes' the rightbar before init,
               it means it will open it anyway but will not consider it
               and will not be able to close it
    */
    if (!hasBlockSettings && !streamJob && !value?.id === 'solutions') {
      onClose()
    }
  }, [ value?.id ])

  useDidUpdate(() => {
    refBlocks.current = blocks
    refBlockSettings.current = blockSettings
    forceUpdate()
  }, [ blocks, blockSettings, pinned, forceUpdate ])

  useImperativeHandle(ref, () => ({
    open: onChange,
    close: onClose,
  }), [ onChange, onClose ])

  const Component = fixed ? RightBarFixedMenu : RightBarMenu

  return (
    <Component
      refDialog={refDialog}
      container={container}
      value={value}
      options={options}
      selectedProps={{
        blocks: refBlocks.current,
        blockSettings: refBlockSettings.current,
        blockIds,
        onChange,
        onClose,
        ...props,
      }}
      attachedSensorsCount={attachedSensorsCount}
      pinned={pinned}
      disabled={!hasBlockSettings}
      onChange={onChange}
      onClose={() => {
        setValue(null)
        setBack(null)
        setPinned(false)
      }}
      onPin={() => setPinned(!pinned)}
      {...expanded}
    />
  )
})

export default RightBarDiagram
