import { Observable } from 'rxjs'
import {
  consts,
  OnlineTimeout,
  network,
  AbortRequestError,
} from '@wiz/utils'
import { wizataApi } from '@/api'

export default function observeDeviceCommands ({
  hidden = false,
  withStepRequest = true,
  withDateDuration = true,
  ...filters
} = {}) {
  const fetchRequest = (onSuccess, onFinally) => {
    let request
    let from
    let to

    if (filters.dateFrom && filters.dateTo) {
      from = filters.dateFrom
      to = filters.dateTo
    } else if (filters.duration || withDateDuration) {
      to = Date.now()
      from = to - (filters.duration || consts.WidgetDeviceCommandsDuration)
    }

    wizataApi.deviceCommands.list({
      from,
      to,
      limit: filters.limit,
      offset: filters.offset,
      deviceId: filters.deviceId,
      twinId: filters.twinId,
      streamJobId: filters.streamJobId,
      sensorId: filters.sensorId,
      sortBy: filters.sortBy,
      sortDir: filters.sortDir,
    }, (data) => {
      request = data
    })
      .then((data) => {
        onSuccess(data)
        onFinally()
      })
      .catch((error) => {
        if (!(error instanceof AbortRequestError)) {
          onFinally()
        }
      })

    return request
  }

  return new Observable((subscriber) => {
    let timer
    let request

    const handleRequest = () => {
      if (hidden) {
        return
      }

      timer?.cancel()
      request = fetchRequest((data) => {
        if (!subscriber.closed && !hidden) {
          subscriber.next(data)
        }
      }, () => {
        if (
          withStepRequest &&
          !subscriber.closed &&
          !hidden
        ) {
          timer = new OnlineTimeout(
            handleRequest,
            filters.stepRequest || consts.WidgetDeviceCommandsStepRequest,
          )
        }
      })
    }

    handleRequest()
    network.on('online', handleRequest)
    subscriber.next(undefined)

    return () => {
      request?.abort()
      network.removeListener('online', handleRequest)
      timer?.cancel()
    }
  })
}
