import { useFormContext } from 'react-hook-form'
import { FormSection } from '@wiz/components'
import { useImperativeHandle } from 'react'
import Fields from './Fields'

export default function Section ({
  scope,
  title,
  addon,
  disabled,
  twins,
  refImport,
  registerId = true,
}) {
  const {
    register, getValues, reset, setValue,
  } = useFormContext()

  useImperativeHandle(refImport, () => ({
    getValues (d) {
      return getValues(d)
    },
    setValue (...args) {
      setValue(...args)
    },
    reset () {
      reset()
    },
  }))

  return (
    <FormSection
      title={title}
      addon={addon}
    >
      {registerId ? (
        <input
          {...register(`${scope}.id`)}
          type="hidden"
        />
      ) : null}

      <Fields
        scope={scope}
        disabled={disabled}
        twins={twins}
      />
    </FormSection>
  )
}
