import { useState } from 'react'
import { Icon, FormInputSearch, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import FormTwinGraph from '@/containers/Forms/TwinGraph'
import Table from '@/containers/TwinGraph/Table'
import Filters from './Filters'

export default function List ({
  editId,
  setEditId,
  filters,
  onFilter,
  onResetFilter,
  onAction,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const isMobile = useMobile()
  const [ search, setSearch ] = useState('')

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">

      <div className="content-container content-container-sm d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-2">
        <div className="d-flex flex-shrink-0 align-items-center">
          <FormInputSearch
            className="mb-2"
            placeholder={intl.t('form.actions.searchPlaceholder')}
            onChange={setSearch}
          />

          {auth.checkAccessCreate('TwinGraph') ? (
            <button
              type="button"
              className="btn btn-fill-secondary text-nowrap mb-2 ms-2"
              title={intl.t('twinGraph.form.actions.addTitle')}
              onClick={() => setEditId('')}
            >
              <Icon name="fa--plus" className="me-1" />
              {intl.t('form.actions.add')}
            </button>
          ) : null}
        </div>

        <div className="d-flex align-items-center flex-wrap flex-shrink-0 mx-n1 mb-2">
          <Filters
            filters={filters}
            onFilter={onFilter}
          />
        </div>

        <Table
          {...props}
          search={search}
          filters={filters}
          onFilter={onFilter}
          onAction={onAction}
          onResetFilter={() => {
            onResetFilter?.()
            setSearch('')
          }}
        />
      </div>

      {do {
        if (editId) {
          <FormTwinGraph
            id={editId}
            onClose={() => setEditId(null)}
            dialog={{
              title: intl.t('twinGraphs.titleUpdate'),
              dataTestid: 'updateTwinBlockDialog',
            }}
          />
        } else if (editId !== null) {
          <FormTwinGraph
            onClose={() => setEditId(null)}
            dialog={{
              title: intl.t('twinGraphs.titleCreate'),
              dataTestid: 'updateTwinBlockDialog',
            }}
          />
        }
      }}
    </div>
  )
}
