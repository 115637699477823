import { useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { wizataApi } from '@/api'
import { Script, ScriptInterface } from '@wiz/store'
import ObservScript from '@/containers/Forms/Script/Observ'
import SectionMlModel from './SectionMlModel'
import SectionMlModelInterface from './SectionMlModelInterface'

export default function SettingsSectionMlModel ({ scope }) {
  const {
    register,
    watch,
    setValue,
    clearErrors,
  } = useFormContext()
  const id = watch(`${scope}.script.id`)

  // const handleChange = useCallback(({ script, interfaces }) => {
  //   const next = Script.toJSON(script)
  //   setValue(`${scope}.id`, next.id, { shouldDirty: true })
  //   setValue(`${scope}.script`, next, { shouldDirty: true })
  //   setValue(`${scope}.interfaces`, interfaces.map(item => (
  //     ScriptInterface.toJSON(item)
  //   )), { shouldDirty: true })
  //   clearErrors(scope)
  // }, [ scope, setValue, clearErrors ])

  useEffect(() => {
    if (id) {
      wizataApi.entities.getById('mlmodels', id).then(({ inputColumns, outputColumns }) => {
        const inputs = JSON.parse(inputColumns)
          .map(input => ({ ...ScriptInterface.toJSON({ name: input, type: 'input', dataType: 'array' }) }))
        const outputs = JSON.parse(outputColumns)
          .map(output => ({ ...ScriptInterface.toJSON({ name: output, type: 'output', dataType: 'array' }) }))
        setValue(`${scope}.interfaces`, [ ...inputs, ...outputs ], { shouldDirty: true })
        setValue(`${scope}.script.name`, `zML_MODEL_TEST=${id}`, { shouldDirty: true })
        setValue(`${scope}.script.code`, `zML_MODEL_TEST=${id}\n`, { shouldDirty: true })
      }).catch(err => console.log('error when fetching ml model'))
    } else {
      setValue(`${scope}.interfaces`, [ ], { shouldDirty: true })
    }
  }, [ id ])

  return (
    <>
      {/* <ObservScript id={id} onChange={handleChange} /> */}
      <input
        {...register(`${scope}.id`)}
        type="hidden"
        defaultValue={id}
      />

      <SectionMlModel scope={`${scope}.script`} />
      <SectionMlModelInterface scope={`${scope}.interfaces`} />
    </>
  )
}
