import { useIntl } from '@wiz/intl'
import ListFilters from '@/components/Form/ListFilters'
import SelectTemplate from '@/components/Form/ListTemplates'
import SelectPipeline from '@/components/Form/ListPipelines'
import useAppContext from '@/hooks/useAppContext'

export default function Filters () {
  const { filters, onFilter } = useAppContext()
  const intl = useIntl()

  return (
    <ListFilters
      filters={filters}
      onFilter={onFilter}
    >
      {({ filters, onFilter }) => (
        <>
          <div className="mx-1 mb-2">
            <SelectTemplate
              placeholder={intl.t('form.fields.templatePlaceholder')}
              value={filters.templateId}
              onChange={templateId => onFilter({ templateId })}
              className="form-select-sm"
            />
          </div>
          <div className="me-1 mb-2 col-md-auto">
            <SelectPipeline
              placeholder={intl.t('form.fields.pipelinePlaceholder')}
              value={filters.pipelineId}
              // multiselect
              onChange={pipelineId => onFilter({ pipelineId })}
              className="form-select-sm"
            />
          </div>
        </>
      )}
    </ListFilters>
  )
}
