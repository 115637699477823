import { useCallback } from 'react'
import { withProps, withObservables } from '@wiz/components'
import { dbProvider } from '@wiz/store'
import { wizataApi } from '@/api'
import withTableActions from '@/containers/withTableActions'
import Component from '@/components/Notification/List'

const enhanceData = withObservables([], () => ({
  disableNotifications: dbProvider.observeSettings('disableNotifications'),
}))

const enhanceProps = withProps(() => {
  const onToggleAcknowledge = useCallback(async (id, acknowledged) => {
    await wizataApi.setNotificationAsk(id, acknowledged)
  }, [])

  const onToggleDisableNotifications = useCallback(async () => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareToggleSetting(context, 'disableNotifications')
    await dbProvider.batch(context)
  }, [])

  return {
    onToggleAcknowledge,
    onToggleDisableNotifications,
  }
})

export default withTableActions({
  initFilters: (query = {}) => ({
    from: query.from ? Number(query.from) : undefined,
    to: query.to ? Number(query.to) : undefined,
    hardwareId: query.hardwareId || undefined,
    // includeAcknowledged: query.includeAcknowledged || undefined,
  }),

  defaultFilters: () => ({
    sortBy: 'createdDate',
  }),
})(
  enhanceData(
    enhanceProps(Component),
  ),
)
