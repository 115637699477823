import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { FormField, FormControl } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

const FieldBucketSize = ({ scope }) => {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()
  return (
    <FormField
      label={intl.t('widgets.histogram.form.fields.bucketSize')}
      description={intl.t('widgets.histogram.form.fields.bucketSizeDescr')}
      errors={get(errors, `${scope}.bucketSize`)}
    >
      <FormControl
        type="number"
        name={`${scope}.bucketSize`}
        placeholder={intl.t('form.fields.autoPlaceholder')}
        scale={4}
        clearable
      />
    </FormField>
  )
}

FieldBucketSize.propTypes = {
  scope: PropTypes.string.isRequired,
}

export default FieldBucketSize
