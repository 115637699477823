import { useCallback } from 'react'
import { of as of$, combineLatest } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { withObservables, withProps } from '@wiz/components'
import { assignDefaultIsset, consts } from '@wiz/utils'
import { dbProvider } from '@wiz/store'
import Component from '@/components/PhysicalTwin/Graph'

const DiagramGroupTypes = [
  consts.TwinType.Area,
  consts.TwinType.Machine,
]

const IconsByType = {
  [consts.TwinType.Area]: 'fa--warehouse',
  [consts.TwinType.Equipment]: 'fa--tools',
  [consts.TwinType.Flow]: 'fa--chart-network',
  [consts.TwinType.Machine]: 'fa--cogs',
}

const enhanceBlocks = withObservables([ 'rootTwin' ], ({ rootTwin }) => ({
  blocks: rootTwin ? rootTwin.observeTwinsTree
    .pipe(
      switchMap(items => (items.length ? combineLatest(
        items.map(item => item.observeDiagramContext),
      ) : of$([]))),

      map((items) => {
        const blocks = items
          .filter(item => item.type !== consts.TwinType.Flow)
          .map(item => assignDefaultIsset(item, {
            icon: IconsByType[item.type],
            color: null, // empty binding for gojs
            category: item.type,
            isGroup: DiagramGroupTypes.includes(item.type),
          }))

        const links = items
          .filter(item => item.type !== consts.TwinType.Flow)
          .reduce((out, item) => {
            if (item.parentId && item.parentId !== rootTwin.id) {
              out.push({
                id: `${item.parentId}/${item.id}`,
                fromId: item.parentId,
                toId: item.id,
                type: consts.DiagramBlockCategory.Parent,
                category: consts.DiagramBlockCategory.Parent,
              })
            }
            return out
          }, [])

        const flows = items
          .filter(item => (
            item.type === consts.TwinType.Flow &&
            items.some(i => i.id === item.parentId) &&
            items.some(i => i.id === item.flowToId)
          ))
          .map(item => assignDefaultIsset(item, {
            fromId: item.parentId,
            toId: item.flowToId,
            type: consts.DiagramBlockCategory.Descr,
            category: consts.DiagramBlockCategory.Descr,
          }))

        return blocks.concat(links, flows)
      }),
    ) : of$([]),
}))

const enhanceProps = withProps(({
  rootTwin,
}) => {
  const onLinkDiagram = useCallback(async (data, prev) => {
    if (data.category === consts.DiagramBlockCategory.Parent) {
      const rootTwinId = rootTwin?.id || null
      const collection = dbProvider.database.collections.get('twins')
      const context = dbProvider.createBatchContext()
      const twin = await collection.find(data.toId)
      const twinParentId = twin.parentId
      await twin.prepareMoveInner(context, data.fromId)

      // when update link
      if (prev && prev.id !== twinParentId) {
        const twinPrev = await collection.find(prev.id)
        await twinPrev.prepareMoveInner(context, rootTwinId)
      }

      await dbProvider.batch(context)
    } else if (data.category === consts.DiagramBlockCategory.Descr) {
      const context = dbProvider.createBatchContext()
      const collection = dbProvider.database.collections.get('twins')
      const fromName = (await collection.find(data.fromId))?.name
      const toName = (await collection.find(data.toId))?.name
      const model = await dbProvider.prepareReplaceData(context, collection.modelClass, {
        id: data.twinId,
        type: consts.TwinType.Flow,
        name: data.name || `${fromName} -> ${toName}`,
        parentId: data.fromId,
        flowToId: data.toId,
      })
      await model.prepareMoveInner(context, data.fromId)
      await dbProvider.batch(context)
    }
  }, [ rootTwin ])

  return {
    onLinkDiagram,
  }
})

export default enhanceBlocks(
  enhanceProps(Component),
)
