import { of as of$, combineLatest } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'
import Component from '@/components/TwinGraph/Option/ItemTreeContent'

const enhanceData = withObservables([ 'data' ], ({ data }) => ({
  dataContext: dbProvider.database.collections.get('diagram_blocks')
    .query(Q.where('id', data.recordId))
    .observeWithColumns([ 'updated_at' ])
    .pipe(
      switchMap(items => (items.length ? (
        combineLatest(...items.map(item => item.observeDiagramContext))
      ) : of$([]))),
      map(items => items[0] || data),
    ),
}))

export default enhanceData(Component)
