import { useMemo, useEffect } from 'react'
import camelCase from 'lodash/camelCase'
import { VirtualTable, Pagination, Icon as OldIcon } from '@wiz/components'
import { useAuth } from '@/auth'
import { useIntl } from '@wiz/intl'
import Icon from '@/shared/icon'
import useAppContext from '@/hooks/useAppContext'
import EditButton from '@/shared/editButton'
import UpdatedInfo from '@/components/Form/UpdatedInfo'

const CellUpdated = ({ cell, row }) => (
  <UpdatedInfo
    userId={row.original.updatedById}
    date={cell.value}
    user={row.original.updatedUser}
  />
)

const CellCreated = ({ cell, row }) => (
  <UpdatedInfo
    userId={row.original.createdById}
    date={cell.value}
    user={row.original.createdUser}
  />
)

export default function Table ({
  items,
  onRemove,
  onEdit,
  refetch,
  filters,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const { onRowSelect } = useAppContext()

  const columns = useMemo(() => [
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      maxWidth: 30,
      className: 'justify-content-center text-nowrap',
      Cell: ({ cell, row }) => (auth.checkAccessUpdate('BusinessLabel') ? (
        <EditButton cell={cell} onEdit={onEdit} />
      ) : null),
    },
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      minWidth: 300,
      Cell: ({ cell, row }) => (
        <div className="min-w-0">
          {(row.original.icon || row.original.color) && row.original?.icon?.includes('cus-') ? (
            <OldIcon
              name={row.original.icon}
              color={row.original.color}
              size="lg"
              className="me-2"
            />
          ) : (
            <Icon
              name={camelCase(row.original.icon)}
              color={row.original.color}
              size="lg"
              className="me-2"
              width={18}
            />
          )}
          <a
            className="text-truncate pointer"
            onClick={() => auth.checkAccessUpdate(row.original) && onEdit?.(row.original.id)}
            aria-hidden
          >
            {cell.value}
          </a>
          {row.original.description ? (
            <div className="font-italic text-muted text-truncate small">
              {row.original.description}
            </div>
          ) : null}
        </div>
      ),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedDate',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: CellUpdated,
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdDate',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: CellCreated,
    },
  ], [ onEdit, intl, auth ])

  useEffect(() => {
    if (items && !props.loading) {
      refetch()
    }
  }, [
    filters.search,
    filters.page,
    filters.pageSize,
    filters.sortDir,
    filters.sortBy,
  ])

  return (
    <>
      <VirtualTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={auth.checkAccessRemove('BusinessLabel')}
        {...props}
        onSelect={onRowSelect}
      />

      <Pagination {...props} />
    </>
  )
}
