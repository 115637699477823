import { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Tabs, CustomScrollbars } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import WidgetSection from '@/components/Forms/Widget/Section'
import SectionView from './SectionView'
import SectionSources from './SectionSources'
import SectionFilters from './SectionFilters'
import Schema from './Schema'

export default function WidgetHistogram ({
  defaultValues,
  dialog,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()
  const [ tab, setTab ] = useState('data')

  return (
    <FormDialog
      classNameBody="overflow-hidden p-0"
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      schema={Schema}
      dataTestid="widgetHistogramForm"
      dialog={dialog}
    >
      <Tabs
        className="mx-3"
        value={tab}
        options={[
          {
            value: 'data',
            label: intl.t('widgets.histogram.form.fields.dataSourceSection'),
          },
          {
            value: 'filters',
            label: intl.t('widgets.histogram.form.fields.filtersSection'),
          },
          {
            value: 'view',
            label: intl.t('widgets.histogram.form.fields.viewSection'),
          },
        ]}
        onChange={setTab}
      />

      <CustomScrollbars
        className="flex-fill"
        classNameContent="px-3 d-flex"
        horizontal={false}
        shadow={false}
        autoHide
      >
        <div
          className={classnames('d-flex flex-column flex-fill', {
            'd-none': tab !== 'data',
          })}
        >
          <SectionSources scope="config" />
        </div>

        <div
          className={classnames('d-flex flex-column flex-fill', {
            'd-none': tab !== 'filters',
          })}
        >
          <SectionFilters scope="config.dataFilter" />
        </div>

        <div
          className={classnames('d-flex flex-column flex-fill', {
            'd-none': tab !== 'view',
          })}
        >
          <WidgetSection scope="widget" />
          <SectionView scope="config" />
        </div>
      </CustomScrollbars>
    </FormDialog>
  )
}

WidgetHistogram.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
  dialog: PropTypes.object,
}

WidgetHistogram.defaultProps = {
  defaultValues: undefined,
  dialog: undefined,
}
