import { useEffect } from 'react'
import {
  Controller,
  useFormContext,
} from 'react-hook-form'
import { DateTime } from 'luxon'
import { get, consts } from '@wiz/utils'
import {
  FormField,
  FormControl,
  FormFieldInline,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import SelectTwin from '@/components/Form/SelectTwin'
import TimeZoneList from '@/hoc/TimeZoneList'
import Select from '@/components/Form/Select'

export default function Fields ({
  scope,
  disabled,
  twins,
}) {
  const intl = useIntl()

  const {
    formState: { errors },
    watch,
    clearErrors,
    setValue,
  } = useFormContext()

  const [
    allowUpdate,
    allowCreate,
    rawFile,
    files,
  ] = watch([
    `${scope}.validationParameters.AllowDataPointUpdate`,
    `${scope}.validationParameters.AllowDataPointCreation`,
    'rawFile',
    'files',
  ])

  useEffect(() => {
    if (allowCreate || allowUpdate) {
      clearErrors([ `${scope}.validationParameters` ])
    }
  }, [ allowCreate, allowUpdate ])

  useEffect(() => {
    const value = `${DateTime.now().toFormat('yyyy/MM/dd hh:mm')} ${rawFile?.name || ''}`
    setValue(`${scope}.name`, value)
  }, [])

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('jobs.form.fields.importNameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
            maxLength: {
              value: 200,
              message: intl.t('form.errors.fieldMaxlen', { max: 200 }),
            },
            minLength: {
              value: 3,
              message: intl.t('form.errors.fieldMinlen', { min: 3 }),
            },
          }}
          placeholder={intl.t('jobs.form.fields.importPlaceholder')}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.sourceTimeZone')}
        description={intl.t('jobs.form.fields.sourceTimeZoneDescr')}
        errors={get(errors, `${scope}.parameters.sourceTimeZone`)}
      >
        <Controller
          name={`${scope}.parameters.sourceTimeZone`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              ListSource={TimeZoneList}
              placeholder={intl.t('form.fields.sourceTimeZonePlaceholder')}
              disabled={disabled}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.decimalSeparator')}
        description={intl.t('jobs.form.fields.decimalSeparatorDescr')}
        errors={get(errors, `${scope}.parameters.decimalSeparator`)}
      >
        <FormControl
          type="select"
          name={`${scope}.parameters.decimalSeparator`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          placeholder={intl.t('form.fields.decimalSeparatorPlaceholder')}
          options={consts.DecimalSeparators.map(id => ({ id, name: id }))}
          disabled={disabled}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.csvSeparator')}
        description={intl.t('jobs.form.fields.csvSeparatorDescr')}
        errors={get(errors, `${scope}.parameters.csvSeparator`)}
      >
        <FormControl
          type="select"
          name={`${scope}.parameters.csvSeparator`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          placeholder={intl.t('form.fields.csvSeparatorPlaceholder')}
          options={consts.CsvSeparators.map(id => ({ id, name: id }))}
          disabled={disabled}
        />
      </FormField>

      <FormField
        label={intl.t('jobs.form.fields.twinId')}
        description={intl.t('jobs.form.fields.twinIdDescr')}
        errors={get(errors, `${scope}.parameters.twinId`)}
      >
        <Controller
          name={`${scope}.parameters.twinId`}
          rules={twins?.length ? {
            required: intl.t('form.errors.fieldRequired'),
          } : undefined}
          render={({ field, fieldState }) => (
            <SelectTwin
              {...field}
              invalid={fieldState.invalid}
              disabled={disabled}
              placeholder={intl.t('form.fields.twinPlaceholder')}
            />
          )}
        />
      </FormField>
      {/* <FormField
        label={intl.t('form.fields.info')}
        description={intl.t('jobs.form.fields.importInfoDescr')}
        errors={get(errors, `${scope}.parameters.info`)}
      >
        <FormControl
          name={`${scope}.parameters.info`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
            maxLength: {
              value: 200,
              message: intl.t('form.errors.fieldMaxlen', { max: 200 }),
            },
            minLength: {
              value: 5,
              message: intl.t('form.errors.fieldMinlen', { min: 5 }),
            },
          }}
          placeholder={intl.t('jobs.form.fields.infoPlaceholder')}
          disabled
        />
      </FormField> */}

      <FormFieldInline
        label={intl.t('sensor.form.fields.allowModify')}
        description={intl.t('sensor.form.fields.allowModifyDescr')}
        errors={get(errors, `${scope}.validationParameters.AllowDataPointUpdate`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.validationParameters.AllowDataPointUpdate`}
          className="ms-2"
          rules={{
            required: (allowCreate || allowUpdate) ? false : intl.t('form.errors.fieldRequired'),
          }}
        />
      </FormFieldInline>

      {/* <FormFieldInline
        label={intl.t('sensor.form.fields.updateExisting')}
        description={intl.t('sensor.form.fields.updateExistingDescr')}
        errors={get(errors, `${scope}.validationParameters.updateExisting`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.validationParameters.updateExisting`}
          className="ms-2"
          disabled={!allowModify}
        />
      </FormFieldInline> */}

      <FormFieldInline
        label={intl.t('sensor.form.fields.allowCreate')}
        description={intl.t('sensor.form.fields.allowCreateDescr')}
        errors={get(errors, `${scope}.validationParameters.AllowDataPointCreation`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.validationParameters.AllowDataPointCreation`}
          className="ms-2"
          rules={{
            required: (allowCreate || allowUpdate) ? false : intl.t('form.errors.fieldRequired'),
          }}
        />
      </FormFieldInline>
    </>
  )
}
