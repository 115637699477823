import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'
import { isEmpty } from '@wiz/utils'

const enhanceData = withObservables([ 'filters' ], ({ filters }) => {
  let query = dbProvider.database.collections.get('twins')
    .query()

  if (filters.search) {
    const sanitizeSearch = Q.sanitizeLikeString(filters.search)
    query = query.extend(Q.or(
      Q.where('id', filters.search),
      Q.where('name', Q.like(`%${sanitizeSearch}%`)),
      Q.where('hardware_id', Q.like(`%${sanitizeSearch}%`)),
      Q.where('description', Q.like(`%${sanitizeSearch}%`)),
    ))
  }

  if (!isEmpty(filters.type)) {
    query = query.extend(
      Q.where('type', Array.isArray(filters.type) ? Q.oneOf(filters.type) : filters.type),
    )
  }

  return {
    items: query.observeWithColumns([ 'updated_at' ]),
  }
})

export default enhanceData
