export default function ml (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      ml: {
        enumerable: true,
        value: Object.create({}, {
          testModel: {
            enumerable: true,
            value (mlPipelineId) {
              return client.post(`/ML/test-model/${mlPipelineId}`).fetch()
            },
          },
        }),
      },
    })
  }
}
