import Link from '@/components/Link'

export default function NotFound () {
  return (
    <div className="position-center-fill flex-column h-100">
      <h4>Page not found</h4>
      <Link name="home">
        Go to Home page
      </Link>
    </div>
  )
}
