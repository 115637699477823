import { useCallback, memo } from 'react'
import { dbProvider, Unit } from '@wiz/store'
import { withProps } from '@wiz/components'
import FormUnit from '@/components/Forms/Unit'
import enhanceUnit from './enhanceUnit'

const enhanceProps = withProps(() => {
  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareReplaceData(context, Unit, data.unit)
    await dbProvider.batch(context)
  }, [])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceUnit(
    enhanceProps(FormUnit),
  ),
)
