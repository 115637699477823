import { Controller, useFormContext } from 'react-hook-form'
import { FormField, FormControl, FormFieldInline } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import CategorieList from '@/hoc/CategorieList'
import Select from '@/components/Form/Select'
import SelectIcon from '@/containers/Form/SelectIcon'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('units.form.fields.fullName')}
        description={intl.t('units.form.fields.fullNameDescr')}
        errors={get(errors, `${scope}.fullName`)}
      >
        <FormControl
          name={`${scope}.fullName`}
        />
      </FormField>

      <FormField
        label={intl.t('units.form.fields.shortName')}
        description={intl.t('units.form.fields.shortNameDescr')}
        errors={get(errors, `${scope}.shortName`)}
      >
        <FormControl
          name={`${scope}.shortName`}
        />
      </FormField>

      <FormField
        label={intl.t('units.form.fields.symbol')}
        description={intl.t('units.form.fields.symbolDescr')}
        errors={get(errors, `${scope}.symbol`)}
      >
        <FormControl
          name={`${scope}.symbol`}
          rules={{
            setValueAs: value => (value || null),
          }}
        />
      </FormField>

      <FormField
        label={intl.t('units.form.fields.baseFactor')}
        description={intl.t('units.form.fields.baseFactorDescr')}
        errors={get(errors, `${scope}.baseFactor`)}
      >
        <FormControl
          type="number"
          name={`${scope}.baseFactor`}
          min={0}
          max={100000}
        />
      </FormField>

      <FormField
        label={intl.t('units.form.fields.category')}
        description={intl.t('units.form.fields.categoryDescr')}
        errors={get(errors, `${scope}.categoryId`)}
      >
        <Controller
          name={`${scope}.categoryId`}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              ListSource={CategorieList}
              placeholder="--"
            />
          )}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.color')}
        description={intl.t('twin.form.fields.colorDescr')}
        errors={get(errors, `${scope}.color`)}
      >
        <FormControl
          type="color"
          name={`${scope}.color`}
          clearable
        />
      </FormFieldInline>

      <FormField
        label={intl.t('form.fields.icon')}
        description={intl.t('twin.form.fields.iconDescr')}
        errors={get(errors, `${scope}.icon`)}
      >
        <Controller
          name={`${scope}.icon`}
          render={({ field, fieldState }) => (
            <SelectIcon
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormField>
    </>
  )
}
