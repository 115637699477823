import { forwardRef, useMemo } from 'react'
import classnames from 'classnames'
import { ResponsiveList } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import DashboardList from '@/hoc/DashboardList'

const Option = ({ data, index, style }) => {
  const intl = useIntl()
  const {
    options,
    value,
    onChange,
    onClick,
  } = data
  const item = options[index]

  return (
    <div
      style={style}
      className="list-group-item list-group-item-action d-flex align-items-center text-nowrap"
    >
      <label
        className="d-inline-flex align-items-center min-w-0 me-2 flex-fill"
        htmlFor={item.id}
      >
        <input
          className="form-check-input flex-shrink-0 m-0 me-2"
          type="checkbox"
          id={item.id}
          checked={value?.includes(item.id)}
          onChange={() => onChange?.(item.id)}
        />

        <span className="text-truncate">
          {item.name}
        </span>
      </label>

      {onClick ? (
        <button
          type="button"
          className="btn btn-sm btn-flat-secondary ms-2"
          onClick={event => onClick(item.id, event)}
        >
          {intl.t('dashboards.form.fields.widgets')}
        </button>
      ) : null}
    </div>
  )
}

const ListDashboardsWidgets = forwardRef(({
  className,
  options,
  value,
  search,
  onChange,
  onClick,
}, ref) => {
  const intl = useIntl()
  const itemData = useMemo(() => ({
    options,
    value,
    search,
    onChange,
    onClick,
  }), [ options, value, search, onChange, onClick ])

  if (!options.length) {
    return (
      <div className={classnames('d-flex align-items-center justify-content-center', className)}>
        {intl.t('form.nodata')}
      </div>
    )
  }

  return (
    <ResponsiveList
      className={className}
      itemCount={options.length}
      itemData={itemData}
      itemSize={36}
    >
      {Option}
    </ResponsiveList>
  )
})

export default DashboardList(ListDashboardsWidgets)
