import classnames from 'classnames'
import { Icon } from '@wiz/components'
import SensorValue from '@/containers/Sensor/SensorValue'
import DateRelative from '@/components/DateRelative'
import classes from './SensorInline.css'

export default function SensorInline ({
  title,
  subTitle,
  hidden,
  precision,
  sensor,
  conditions,
  twinSettings,
  value,
  onChange,
}) {
  return (
    <div className={classes.root}>
      {sensor.icon ? (
        <Icon
          className={classes.icon}
          name={sensor.icon}
          color={sensor.color || twinSettings?.twinColorSensorsHardware}
          width="10%"
          height="100%"
        />
      ) : null}

      {title || subTitle ? (
        <div className={classes.title}>
          <span className="text-truncate">{title}</span>
          {subTitle ? <span className="text-truncate small">{subTitle}</span> : null}
        </div>
      ) : null}

      <SensorValue
        className={classes.value}
        sensor={sensor}
        conditions={conditions}
        precision={precision}
        disabled={hidden}
        initialData={value}
        showUnit
        fit
        onChange={onChange}
      />

      {value?.timestamp ? (
        <DateRelative
          className={classnames(classes.time, 'text-nowrap')}
          value={value.timestamp}
        />
      ) : null}
    </div>
  )
}
