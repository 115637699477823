import { Icon, withObservables } from '@wiz/components'
import { dbProvider } from '@wiz/store'

const enhanceSettings = withObservables([], () => ({
  twinSettings: dbProvider.observeGlobalSettings([
    'twinColorAreas',
    'twinColorMachines',
    'twinColorEquipment',
    // 'twinColorSensorsHardware',
    // 'twinColorSensorsVirtual',
  ]),
}))

const ColorByType = {
  area: 'twinColorAreas',
  machine: 'twinColorMachines',
  equipment: 'twinColorEquipment',
}

export default enhanceSettings(({ twin, twinSettings, ...props }) => (
  twin.icon ? (
    <Icon
      {...props}
      name={twin.icon}
      color={twin.color || twinSettings[ColorByType[twin.type]]}
    />
  ) : null
))
