import {
  useRef,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import union from 'lodash/union'
import difference from 'lodash/difference'
import { useIntl } from '@wiz/intl'
import { FormControl, Dropdown } from '@wiz/components'
import { dbProvider } from '@wiz/store'
import Tree from '@/components/Form/Tree/Tree'
import { RoutesThree } from '@/router'
import { useAuth } from '@/auth'
import Access from '@/auth/Access'
import { RIGHTS } from '@/config'

function reqThreeAccess (items, auth) {
  const routes = []
  for (const item of items) {
    if (item.manageAccess !== false) {
      const children = item.children ? reqThreeAccess(item.children, auth) : undefined
      const isComponents = auth.checkAccessPersonal(item.access) || auth.checkAccessOrganization(item.access)
      if (children?.length || (item.access && (auth.checkAccessManage(item.access) || isComponents))) {
        routes.push({
          id: item.id,
          name: item.name,
          payload: item,
          children,
        })
      }
    }
  }
  return routes
}

const findRoles = async (subject, action) => {
  const collection = dbProvider.database.collections.get('access_roles')
  const { modelClass } = collection
  const roles = await collection
    .query(modelClass.queryWithPermissions([ subject ], [ action ]))
    .fetch()
  return roles.map(({ id }) => id)
}

function ItemContent ({
  data: { payload },
  context: { onAction },
}) {
  const refTooltip = useRef(null)
  const intl = useIntl()
  const access = onAction('access')
  const inheritAccess = onAction('inheritAccess')

  const accessRead = !!(
    access &&
    access.checkAccess(RIGHTS.READ, payload.access)
  )
  const inheritAccessRead = !!(
    inheritAccess &&
    inheritAccess.checkAccess(RIGHTS.READ, payload.access)
  )
  const accessManage = !!(
    access &&
    access.checkAccess(RIGHTS.MANAGE, payload.access)
  )
  let inheritAccessManage = !!(
    inheritAccess &&
    inheritAccess.checkAccess(RIGHTS.MANAGE, payload.access)
  )

  const accessPersonal = !!(
    access &&
    access.checkAccess(RIGHTS.PERSONAL, payload.access)
  )
  const inheritAccessPersonal = !!(
    inheritAccess &&
    inheritAccess.checkAccess(RIGHTS.PERSONAL, payload.access)
  )

  const accessOrganization = !!(
    access &&
    access.checkAccess(RIGHTS.ORGANIZATION, payload.access)
  )
  const inheritAccessOrganization = !!(
    inheritAccess &&
    inheritAccess.checkAccess(RIGHTS.ORGANIZATION, payload.access)
  )

  if (
    (accessRead || inheritAccessRead) &&
    !(accessManage || inheritAccessManage)
  ) {
    const accessRoles = onAction('roles', payload.access)
    const hasAccessManage = accessRoles.every(item => (
      access.checkAccess(RIGHTS.MANAGE, item) ||
      inheritAccess.checkAccess(RIGHTS.MANAGE, item)
    ))
    if (hasAccessManage) {
      inheritAccessManage = true
    }
  }

  const handleAccessRead = useCallback((event) => {
    event.stopPropagation()
    onAction('toggleAccess', {
      subject: payload.access,
      action: RIGHTS.READ,
      toggle: !accessRead,
    })
  }, [ payload, accessRead, onAction ])

  const handleAccessManage = useCallback((event) => {
    event.stopPropagation()
    onAction('toggleAccess', {
      subject: payload.access,
      action: RIGHTS.MANAGE,
      toggle: !accessManage,
    })
  }, [ payload, accessManage, onAction ])

  const handleAccessPersonal = useCallback((event) => {
    event.stopPropagation()
    onAction('toggleAccess', {
      subject: payload.access,
      action: RIGHTS.PERSONAL,
      toggle: !accessPersonal,
    })
  }, [ payload, accessPersonal, onAction ])

  const handleAccessOrganization = useCallback((event) => {
    event.stopPropagation()
    onAction('toggleAccess', {
      subject: payload.access,
      action: RIGHTS.ORGANIZATION,
      toggle: !accessOrganization,
    })
  }, [ payload, accessOrganization, onAction ])

  const isComponents = payload.access === 'SectionComponents' // FIXME: use another approach

  return (
    <>
      <div>
        {payload.breadcrumb ? intl.t(payload.breadcrumb) : payload.name}
      </div>
      {payload.access ? (
        <div className="ms-auto">
          <button
            type="button"
            className={classnames('btn btn-sm ms-2 border-0', {
              'btn-primary': accessRead || inheritAccessRead,
              'btn-fill-secondary': !(accessRead || inheritAccessRead),
            })}
            disabled={inheritAccessRead}
            onClick={handleAccessRead}
          >
            read
          </button>
          {isComponents ? (
            <>
              <button
                type="button"
                className={classnames('btn btn-sm ms-1 border-0', {
                  'btn-primary': accessOrganization || inheritAccessOrganization,
                  'btn-fill-secondary': !(accessOrganization || inheritAccessOrganization),
                })}
                disabled={inheritAccessOrganization}
                onClick={handleAccessOrganization}
              >
                org
              </button>
              <button
                type="button"
                className={classnames('btn btn-sm ms-1 border-0', {
                  'btn-primary': accessPersonal || inheritAccessPersonal,
                  'btn-fill-secondary': !(accessPersonal || inheritAccessPersonal),
                })}
                disabled={inheritAccessPersonal}
                onClick={handleAccessPersonal}
              >
                personal
              </button>
            </>
          ) : null}
          {isComponents ? null : (
            <span ref={refTooltip}>
              <button
                type="button"
                className={classnames('btn btn-sm ms-1 border-0', {
                  'btn-primary': accessManage || inheritAccessManage,
                  'btn-fill-secondary': !(accessManage || inheritAccessManage),
                })}
                disabled={inheritAccessManage}
                onClick={handleAccessManage}
              >
                manage
              </button>
            </span>
          )}

          {inheritAccessManage ? (
            <Dropdown
              target={refTooltip}
              mode="mouseenter"
              className="p-3"
              arrow
            >
              {intl.t('roles.disableManageTooltip')}
            </Dropdown>
          ) : null}
        </div>
      ) : null}
    </>
  )
}

export default function SectionPermissionsThree ({ scope, groupScope }) {
  const refInheritAccess = useRef(null)
  const refAccess = useRef(null)
  const refRoles = useRef([])
  const auth = useAuth()
  const [ forceUpdate, setForceUpdate ] = useState(null)

  const {
    watch,
    setValue,
  } = useFormContext()

  const [
    accessRoleIds,
    groupAccessRoleIds,
  ] = watch([
    scope,
    groupScope,
  ])

  const RoutesThreeAccess = useMemo(() => (
    reqThreeAccess(RoutesThree, auth)
  ), [ auth ])

  const handleAction = useCallback((name, data) => {
    if (name === 'access') {
      return refAccess.current
    }

    if (name === 'inheritAccess') {
      return refInheritAccess.current
    }

    if (name === 'roles') {
      const role = refRoles.current.filter(item => (
        item.permissions.length === 1 &&
        item.permissions[0].subject.includes(data) &&
        item.permissions[0].actions.includes(RIGHTS.MANAGE)
      ))[0]

      let accessRoles = []

      if (role) {
        accessRoles = refRoles.current.filter(item => (
          role.roleIds.includes(item.id)
        )).reduce((acc, item) => ([
          ...acc,
          ...item.permissions.reduce((out, p) => ([ ...out, ...p.subject ]), []),
        ]), [])
      }

      return accessRoles
    }

    if (name === 'toggleAccess') {
      const {
        subject,
        action,
        toggle,
      } = data
      findRoles(subject, action).then((roleIds) => {
        const next = toggle ?
          union(accessRoleIds, roleIds) :
          difference(accessRoleIds, roleIds)
        setValue(scope, next, { shouldDirty: true })
      })
    }

    return null
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessRoleIds,
    setValue,
    scope,
    forceUpdate,
  ])
  useEffect(() => {
    async function updateAccess () {
      const roles = await dbProvider.database.collections.get('access_roles')
        .query()
        .fetch()

      const rels = await dbProvider.database.collections.get('rel_access_roles')
        .query()
        .fetch()

      const permissions = await dbProvider.database.collections.get('access_permissions')
        .query()
        .fetch()

      const flags = await dbProvider.fetchFlags()

      const fullRoles = roles.map(item => ({
        ...item.toJSON(),
        roleIds: rels.filter(r => r.groupRoleId === item.id).map(r => r.childRoleId),
        permissions: permissions.filter(m => m.accessRoleId === item.id).map(m => m.toJSON()),
      }))

      const nextAccess = new Access()
      nextAccess.updateAbilities({
        id: '00000000-0000-0000-0000-000000000000',
        roleIds: accessRoleIds,
      }, fullRoles, flags)

      const nextInheritAccess = new Access()
      nextInheritAccess.updateAbilities({
        id: '00000000-0000-0000-0000-000000000000',
        roleIds: groupAccessRoleIds,
      }, fullRoles, flags)

      refRoles.current = fullRoles
      refAccess.current = nextAccess
      refInheritAccess.current = nextInheritAccess
      setForceUpdate({})
    }

    const timer = window.setTimeout(updateAccess, 100)
    return () => {
      window.clearTimeout(timer)
    }
  }, [
    accessRoleIds,
    groupAccessRoleIds,
  ])

  return (
    <div className="flex-fill d-flex flex-column mt-2">
      <FormControl
        type="any"
        name={scope}
      />
      <Tree
        className="flex-fill"
        options={RoutesThreeAccess}
        onAction={handleAction}
        Content={ItemContent}
      />
    </div>
  )
}

SectionPermissionsThree.propTypes = {
  scope: PropTypes.string.isRequired,
}
