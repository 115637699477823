import {
  DiagramThemeContext,
  PhysicalTwinDiagram,
} from '@wiz/components'
import { useTheme } from '@/theme'

export default function Graph ({
  refDiagram,
  blocks,
  state,
  linkMode,
  graphScrollTo,
  blockFactory,
  onChangeDiagram,
  onLinkDiagram,
}) {
  const theme = useTheme()

  return (
    <DiagramThemeContext.Provider value={theme.gojs}>
      <PhysicalTwinDiagram
        ref={refDiagram}
        scope={state?.scope}
        className="flex-fill"
        linkMode={linkMode}
        blocks={blocks}
        state={state}
        blockFactory={blockFactory}
        scrollTo={graphScrollTo}
        onLink={onLinkDiagram}
        onChange={onChangeDiagram}
      />
    </DiagramThemeContext.Provider>
  )
}
