import { useMemo } from 'react'
import { Table as StaticTable, Pagination } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Link from '@/components/Link'
import Observe from '@/containers/Observe'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ButtonCopy from '@/components/Form/ButtonCopy'

export default function Table ({
  items,
  ...props
}) {
  const intl = useIntl()
  const columns = useMemo(() => [
    {
      Header: intl.t('edge.devices.form.fields.deviceId'),
      accessor: 'deviceId',
      minWidth: 300,
      Cell: ({ cell, row }) => (
        <div className="min-w-0">
          <div className="d-flex align-items-center min-w-0">
            <Link
              name="edge-devices"
              params={{ id: row.original.id }}
            >
              <span className="user-select-all text-truncate">{cell.value}</span>
            </Link>
            <ButtonCopy value={cell.value} className="text-muted" />
          </div>

          {row.original.runtimeResponse ? (
            <div className="font-italic text-muted small user-select-all">
              {row.original.runtimeResponse}
            </div>
          ) : null}
        </div>
      ),
    },
    {
      Header: intl.t('edge.devices.form.fields.modulesCount'),
      accessor: 'modules',
      width: 100,
      className: 'justify-content-center',
      Cell: ({ cell }) => (
        <Observe count={cell.value}>
          {({ data }) => (data || null)}
        </Observe>
      ),
    },
    {
      Header: intl.t('edge.devices.form.fields.connectionsCount'),
      accessor: 'hubConnections',
      width: 100,
      className: 'justify-content-center',
      Cell: ({ cell }) => (
        <Observe count={cell.value}>
          {({ data }) => (data || null)}
        </Observe>
      ),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedAt',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: ({ cell, row }) => (<UpdatedInfo date={cell.value} user={row.original.updatedUser} />),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdAt',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: ({ cell, row }) => (<UpdatedInfo date={cell.value} user={row.original.createdUser} />),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 60,
      width: 60,
      maxWidth: 60,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell }) => (
        <Link
          name="edge-devices"
          params={{ id: cell.value }}
        >
          {intl.t('form.actions.view')}
        </Link>
      ),
    },
  ], [ intl ])

  return (
    <>
      <StaticTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        selection={false}
        columns={columns}
        data={items}
        {...props}
      />

      <Pagination {...props} />
    </>
  )
}
