import { useFormContext } from 'react-hook-form'
import { FormSection } from '@wiz/components'
import Fields from './Fields'

export default function Section ({
  scope,
  title,
  description,
  addon,
  registerId = true,
  templateId,
  pipelineId,
  pipelines,
}) {
  const { register } = useFormContext()

  return (
    <FormSection
      title={title}
      description={description}
      addon={addon}
    >
      {registerId ? (
        <input
          {...register(`${scope}.id`)}
          type="hidden"
        />
      ) : null}
      <Fields scope={scope} templateId={templateId} pipelineId={pipelineId} pipelines={pipelines} />
    </FormSection>
  )
}
