import { useMemo } from 'react'
import { withProps } from '@wiz/components'
import { consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default withProps(({
  search,
  selectedOnly,
  userCreated,
  autoCreated,
  canInputValue,
  filter,
  value,
  excludeBusinessTypes = [],
}) => {
  const intl = useIntl()
  const options = useMemo(() => {
    let items = consts.SensorBusinessTypes.filter(type => !excludeBusinessTypes.includes(type)) || []

    if (canInputValue) {
      items = consts.SensorBusinessTypesInput
    }

    if (filter) {
      items = items.filter(item => filter(item))
    }

    if (search) {
      items = items.filter(item => (item.indexOf(search) !== -1))
    }

    if (selectedOnly) {
      const isArrayValue = Array.isArray(value)
      items = items.filter(item => (isArrayValue ? value.includes(item) : value === item))
    }

    // if (userCreated) {
    //   items = items.filter(item => consts.SensorBusinessTypesUserCreate.includes(item))
    // }

    // if (autoCreated) {
    //   items = items.filter(item => !consts.SensorBusinessTypesUserCreate.includes(item))
    // }

    return items.map(id => ({
      id,
      name: intl.t(`enum.sensorBusinessType.${id}`),
    }))
  }, [
    search,
    selectedOnly,
    value,
    userCreated,
    autoCreated,
    canInputValue,
    intl,
  ])

  return {
    options,
  }
})
