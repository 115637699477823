import { useCallback, useEffect, useState } from 'react'
import { useTheme } from '@/theme'
import { useAuth } from '@/auth'
import { appEnv } from '@/config'

const STONLY_WID = 'ca627086-e8c3-11ec-9fb8-0ae9fa2a18a2'

const initStonly = async () => {
  window.STONLY_WID = STONLY_WID

  !(function (s, t, o, n, l, y, w, g) {
    s.StonlyWidget || ((w = s.StonlyWidget = function () {
      w._api ? w._api.apply(w, arguments) : w.queue.push(arguments)
    }).scriptPath = n, w.queue = [], (y = t.createElement(o)).async = !0,
    (g = new XMLHttpRequest()).open('GET', `${n}version?v=${Date.now()}`, !0), g.onreadystatechange = function () {
      g.readyState === 4 && (y.src = `${n}stonly-widget.js?v=${g.status === 200 ? g.responseText : Date.now()}`,
      (l = t.getElementsByTagName(o)[0]).parentNode.insertBefore(y, l))
    }, g.send())
  }(window, document, 'script', 'https://stonly.com/js/widget/v2/'))
}

export const useStonly = () => {
  const [ isLaunched, setLaunched ] = useState(false)
  const auth = useAuth()

  const user = auth.getCurrentUser()

  const send = useCallback((attr) => {
    if (user?.id) {
      window.StonlyWidget?.('identify', user.id, attr)
    }
  }, [ user ])

  useEffect(() => {
    if (!isLaunched) {
      initStonly()
      setLaunched(true)
    }
  }, [])

  return { isLaunched, send }
}

export const Stonly = () => {
  const [ sent, setSent ] = useState(false)
  const theme = useTheme()
  const auth = useAuth()

  const { isLaunched } = useStonly()
  const user = auth.getCurrentUser()

  useEffect(() => {
    if (isLaunched && user?.id && !sent) {
      const version = appEnv.VERSION_SHORT.match(/^\d+.\d/)?.[0]
      window.StonlyWidget?.('identify', user?.id, {
        'user-theme': theme?.name,
        'user-email': user?.email,
        'wizata-release-version': version,
      })
      setSent(true)
    }
  }, [ isLaunched, user ])

  return null
}
