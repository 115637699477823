import cx from 'classnames'
import GrafanaVariable from './GrafanaVariable'
import styles from './index.module.css'

export default function GrafanaVariablesBar ({ variables, ...props }) {
  return (
    <div className={cx(styles.grafanaVarsBar)}>
      {
        variables.map((variable) => {
          const mappedOptions = variable?.type === 'select' ?
            variable?.options?.map((opt, idx) => ({ id: idx + 1, name: opt })) :
            []

          const mappedVariable = variable?.type === 'select' ?
            {
              ...variable,
              options: mappedOptions,
              defaults: variable?.multi ?
                mappedOptions
                  .filter(opt => variable.defaults.includes(opt.name))
                  .map(op => op.id) :
                mappedOptions[0].id,
            } :
            variable

          return (
            <GrafanaVariable
              key={variable?.name}
              variable={mappedVariable}
              {...props}
            />
          )
        })
      }
    </div>
  )
}
