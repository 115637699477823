// import { hot } from 'react-hot-loader/root'
import Amplify from 'aws-amplify'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { consts } from '@wiz/utils'
import { AuthProvider } from '@/auth'
import { IntlProvider } from '@/i18n'
import {
  Router,
  Route,
  Redirect,
  useParams,
} from '@/router'
import { ErrorBoundary, withProfiler } from '@/utils/analytics'
import MainView from '@/components/Main/View'
import { awsConfig } from '@/auth/cognito'
import { useIntl } from '@wiz/intl'
import { Icon } from '@wiz/components'
import '@/sprite'
import '@wiz/components/lib/index.css'
import './assets/style/main.css'

Amplify.configure(awsConfig)

const queryClient = new QueryClient()

const Exception = (error) => {
  const intl = useIntl()
  console.log('exception error', error)

  return (
    <div className="drag rounded shadow-sm opacity-50 position-absolute-fill bg-white d-flex flex-column align-items-center justify-content-center text-danger">
      <Icon name="fa--bug" size="2X" />
      <div className="mt-2">{intl.t('errors.initializationError')}</div>
    </div>
  )
}

const Content = () => {
  const { lang } = useParams()

  if (!consts.Langs.includes(lang)) {
    return <Redirect to={`/en/${lang || ''}`} />
  }

  return (
    <IntlProvider lang={lang}>
      <MainView />
    </IntlProvider>
  )
}

const App = () => (
  <ErrorBoundary
    onError={(err) => {
      console.log('custom error', err)
      document.getElementById('startup-loader')
        .show({ status: 'internal-error' })
    }}
    fallback={Exception}
  >
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Route path="/:lang?">
            <Content />
          </Route>
        </Router>
      </QueryClientProvider>
    </AuthProvider>
  </ErrorBoundary>
)

// export default withProfiler(hot(App), {
//   name: 'App',
// })

export default withProfiler(App, {
  name: 'App',
})
