/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import { Icon } from '@wiz/components'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'

export default function ViewModel ({
  title,
  category,
  onClose,
  onRemove,
  onEdit,
}) {
  const refDialog = useRef()
  const intl = useIntl()
  const auth = useAuth()

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('form.fields.icon'),
          value: () => (category.icon || category.color ? (
            <Icon
              name={category.icon || 'fa--circle'}
              color={category.color}
              size="lg"
              className="me-2"
            />
          ) : null),
        },
        {
          title: intl.t('form.fields.name'),
          value: category.name || '-',
        },
        {
          title: intl.t('form.fields.description'),
          value: category.comment || '-',
        },
      ],
    },

    {
      title: intl.t('form.fields.updated'),
      value: () => (
        <UpdatedInfo
          date={category.updatedAt}
          user={category.updatedUser}
        />
      ),
    },
    {
      title: intl.t('form.fields.created'),
      value: () => (
        <UpdatedInfo
          date={category.createdAt}
          user={category.createdUser}
        />
      ),
    },
  ]

  const actions = [
    ...(onRemove && auth.checkAccessRemove(category) ? ([{
      title: intl.t('form.actions.remove'),
      name: 'remove',
      testid: 'removeButton',
      onClick: () => onRemove([ category ]),
    }]) : []),

    ...(onEdit && auth.checkAccessUpdate(category) ? ([{
      title: intl.t('form.actions.edit'),
      name: 'edit',
      testid: 'editButton',
      onClick: () => onEdit(category.id),
    }]) : []),
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      actions={actions}
      onClose={onClose}
    />
  )
}
