import {
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'
import classnames from 'classnames'
import { toArrayValue } from '@wiz/utils'
import {
  Dropdown,
  OverflowCenter,
  Badge,
  Icon,
} from '@wiz/components'
import DataExplorer from '@/context/DataExplorer'
import Explorer from './Explorer'
import classes from './index.css'

const SelectDataView = forwardRef(({
  name,
  options,
  className,
  value,
  disabled,
  placeholder,
  multiple,
  invalid,
  onChange,
  onBlur
}, ref) => {
  const refTarget = useRef()
  const context = useContext(DataExplorer)

  const arrayValue = useMemo(() => toArrayValue(value), [ value ])

  const currentOptions = useMemo(() => (
    options || context?.data?.dataViews || []
  ), [ options, context ])

  const handleChange = useCallback((data) => {
    let nextValue = []

    if (multiple) {
      if (arrayValue.includes(data)) {
        nextValue = arrayValue.filter(item => item !== data)
      } else {
        nextValue = arrayValue.concat(data)
      }
    } else if (data) {
      nextValue = [ data ]
    }

    onChange?.(nextValue)
  }, [
    multiple,
    arrayValue,
    onChange,
  ])

  const handleRemove = useCallback((data) => {
    const nextValue = arrayValue.filter(item => item !== data)
    onChange?.(nextValue)
  }, [ arrayValue, onChange ])

  const displayValue = useMemo(() => (
    arrayValue
      .map(id => currentOptions.find(item => item.id === id))
      .filter(item => !!item)
      .map(item => ({
        id: item.id,
        name: item.name || item.source?.displayName,
        color: item.color,
      }))
  ), [ arrayValue, currentOptions ])

  useImperativeHandle(ref, () => ({
    focus () {
      refTarget.current.focus()
    },
  }), [])

  return (
    <>
      <button
        ref={refTarget}
        type="button"
        name={name}
        className={classnames('text-start form-control text-truncate d-flex align-items-center', className, {
          'is-invalid': !!invalid,
        })}
        disabled={disabled}
        onBlur={onBlur}
      >
        {do {
          if (displayValue.length) {
            (
              <span className={classnames('d-flex align-items-center flex-wrap min-w-0', classes.values)}>
                {displayValue.map(item => (
                  <Badge
                    key={item.id}
                    className={classnames('bg-secondary', classes.value)}
                    disabled={disabled}
                    onClose={(event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      handleRemove(item.id)
                    }}
                  >
                    <Icon name="fa--circle" size="xs" className="me-1" color={item.color} />
                    <OverflowCenter value={item.name} />
                  </Badge>
                ))}
              </span>
            )
          } else if (placeholder) {
            (
              <span className="text-truncate text-muted">
                {placeholder}
              </span>
            )
          }
        }}
      </button>

      <Dropdown
        target={refTarget}
        placement="bottom-end"
        size="md"
        height={300}
        arrow
      >
        {() => (
          <Explorer
            autoFocus
            options={currentOptions}
            value={arrayValue}
            onChange={handleChange}
          />
        )}
      </Dropdown>
    </>
  )
})

SelectDataView.displayName = 'SelectDataView'
export default SelectDataView
