/* eslint-disable jsx-a11y/label-has-associated-control */
import { Fragment } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import classnames from 'classnames'
import { get, has } from '@wiz/utils'
import { Icon, FormControl } from '@wiz/components'
import { Condition } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import { ConditionIntervalTypes } from '@/config'

const InputConditionPayload = ({ scope }) => {
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext()

  const currentType = watch(`${scope}.type`)

  return currentType === 'between' ? (
    <>
      <input
        {...register(`${scope}.payload.between.from`)}
        type="number"
        placeholder="from"
        precision="4"
        className={classnames('form-control me-1', {
          'is-invalid': has(errors, `${scope}.payload.between.from`),
        })}
      />
      <input
        {...register(`${scope}.payload.between.to`)}
        type="number"
        placeholder="to"
        precision="4"
        className={classnames('form-control me-1', {
          'is-invalid': has(errors, `${scope}.payload.between.to`),
        })}
      />
    </>
  ) : (
    <input
      {...register(`${scope}.payload.logical`)}
      type="number"
      precision="4"
      className={classnames('form-control', {
        'is-invalid': has(errors, `${scope}.payload.logical`),
      })}
    />
  )
}

export default function FieldsConditions ({ scope }) {
  const intl = useIntl()
  const { register, formState: { errors } } = useFormContext()
  const { fields, prepend, remove } = useFieldArray({
    name: `${scope}.conditions`,
    keyName: '_id',
    shouldUnregister: true,
  })

  if (!fields.length) {
    return (
      <div className="d-flex flex-column align-items-center">
        {intl.t('form.info.conditionsNotFound')}
        <button
          type="button"
          className="btn btn-sm btn-outline-primary mt-2"
          onClick={() => prepend(Condition.toJSON())}
        >
          <Icon name="fa--plus" className="me-1" />
          {intl.t('form.actions.addCondition')}
        </button>
      </div>
    )
  }

  return (
    <div className="list-group list-group-flush mx-0">
      <button
        type="button"
        className="btn btn-sm btn-outline-primary mb-2"
        onClick={() => prepend(Condition.toJSON())}
      >
        <Icon name="fa--plus" className="me-1" />
        {intl.t('form.actions.addCondition')}
      </button>

      {fields.map((item, idx) => {
        const scopeRow = `${scope}.conditions.${idx}`
        const recordErrors = (
          get(errors, `${scopeRow}.color`) ||
          get(errors, `${scopeRow}.name`) ||
          get(errors, `${scopeRow}.type`) ||
          get(errors, `${scopeRow}.payload.between.from`) ||
          get(errors, `${scopeRow}.payload.between.to`) ||
          get(errors, `${scopeRow}.payload.logical`)
        )

        const handleRemove = () => remove(idx)

        return (
          <Fragment key={item.id}>
            <details
              className={classnames('list-group-item p-0 d-flex', {
                'is-invalid': !!recordErrors,
              })}
            >
              <summary className="d-flex align-items-center p-2 rounded">
                <FormControl
                  type="color"
                  name={`${scopeRow}.color`}
                  // defaultValue={item.color}
                  rules={{
                    required: intl.t('form.errors.fieldRequired'),
                  }}
                  alpha
                  className="me-2"
                />

                <div className="flex-fill d-flex align-items-center min-w-0 me-2">
                  {!item.name && !item.type ? (
                    <div className="text-secondary text-truncate">
                      {intl.t('form.fields.createConditionPlaceholder')}
                    </div>
                  ) : (
                    <div className="text-truncate">{item.name}</div>
                  )}
                </div>
                <div className="d-flex align-items-center">
                  {item.type ? (
                    <>
                      <div className="badge bg-light border border-secondary text-secondary ms-1">
                        {intl.t(`enum.conditionType.${item.type}`)}
                      </div>
                      <div className="badge bg-light border border-secondary text-secondary ms-1">
                        {JSON.stringify(Condition.parsePayload(item))}
                      </div>
                    </>
                  ) : null}
                  <button
                    type="button"
                    className="btn btn-flat-secondary ms-2"
                    title={intl.t('form.actions.remove')}
                    onClick={handleRemove}
                  >
                    <Icon name="fa--trash-alt" />
                  </button>
                </div>
              </summary>

              <div className="my-1 mx-2">
                <input
                  {...register(`${scopeRow}.id`)}
                  type="hidden"
                />

                <label className="row mb-2 align-items-center">
                  <div className="col-sm-6 col-form-label py-1">
                    {intl.t('form.fields.name')}
                    <div className="text-muted small">
                      {intl.t('dataView.form.fields.condNameDescr')}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <input
                      {...register(`${scopeRow}.name`, {
                        maxLength: {
                          value: 50,
                          message: intl.t('form.errors.fieldMaxlen', { max: 50 }),
                        },
                      })}
                      type="search"
                      className={classnames('form-control', {
                        'is-invalid': has(errors, `${scopeRow}.name`),
                      })}
                    />
                  </div>
                </label>

                <label className="row mb-2 align-items-center">
                  <div className="col-sm-6 col-form-label py-1">
                    {intl.t('form.fields.condition')}
                    <div className="text-muted small">
                      {intl.t('dataView.form.fields.conditionDescr')}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <select
                      {...register(`${scopeRow}.type`, {
                        required: intl.t('form.errors.fieldRequired'),
                      })}
                      placeholder={intl.t('form.fields.conditionPlaceholder')}
                      className={classnames('form-select', {
                        'is-invalid': has(errors, `${scopeRow}.type`),
                      })}
                    >
                      <option value="">--</option>
                      {ConditionIntervalTypes.map(type => (
                        <option key={type} value={type}>
                          {intl.t(`enum.conditionType.${type}`)}
                        </option>
                      ))}
                    </select>
                  </div>
                </label>

                <label className="row mb-2 align-items-center">
                  <div className="col-sm-6 col-form-label py-1">
                    {intl.t('form.fields.value')}
                    <div className="text-muted small">
                      {intl.t('dataView.form.fields.condValueDescr')}
                    </div>
                  </div>
                  <div className="col-sm-6 d-flex">
                    <InputConditionPayload
                      scope={scopeRow}
                    />
                  </div>
                </label>
              </div>
            </details>

            {recordErrors?.message ? <div className="invalid-feedback m-0">{recordErrors.message}</div> : null}
          </Fragment>
        )
      })}
    </div>
  )
}
