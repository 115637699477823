import { forwardRef } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { get, consts } from '@wiz/utils'
import {
  FormField,
  FormFieldInline,
  FormControl,
  FormAny,
  Dropdown,
  FormInputTransformation,
  withObservables,
} from '@wiz/components'
import { DataSource, dbProvider } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import DataSourceExplorer from '@/containers/Form/DataSourceExplorer'

const filterWithFeatureFlag = (item, flag) => {
  if (!flag) {
    return item !== consts.DataSourceType.MlModel
  }
  return item
}

const enhaceProps = withObservables([], () => ({
  featureFlags: dbProvider.observeFlags([ 'AdvancedDigitalTwinExperience' ]),
}))

const DropdownDataSource = forwardRef(({
  value,
  batchOnly,
  onChange,
  ...props
}, ref) => (
  <Dropdown
    {...props}
    ref={ref}
    size="md"
    height={300}
    arrow
  >
    {() => (
      <DataSourceExplorer
        value={value}
        batchOnly={batchOnly}
        autoFocus
        keyName="hardwareId"
        onChange={(data) => {
          if (!data) {
            onChange(data)
          } else {
            const { dataType, sensorId, sensor } = data
            onChange({ dataType, sensorId: sensor?.hardwareId || sensorId })
          }
        }}
      />
    )}
  </Dropdown>
))

function Fields ({ scope, featureFlags }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()
  const type = useWatch({ name: `${scope}.type` })

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('dataSource.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (!str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value.length > 100) {
                return intl.t('form.errors.fieldMaxlen', { max: 100 })
              }
              return true
            },
          }}
          placeholder={intl.t('form.fields.namePlaceholder')}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.type')}
        description={intl.t('dataSource.form.fields.typeDescr')}
        errors={get(errors, `${scope}.type`)}
      >
        <FormControl
          type="select"
          name={`${scope}.type`}
          placeholder={intl.t('form.fields.typePlaceholder')}
          options={consts.VirtualDataSourceTypes
            .filter(item => filterWithFeatureFlag(item, featureFlags.AdvancedDigitalTwinExperience))
            .map(id => ({
              id,
              name: intl.t(`enum.dataSourceType.${id}`),
            }))}
        />
      </FormField>

      {!type ? (
        <FormFieldInline
          label={intl.t('form.fields.code')}
          description={intl.t('dataSource.form.fields.codeDescr')}
          errors={get(errors, `${scope}.code`)}
          className="d-flex flex-fill"
          vertical
          complex
        >
          <Controller
            name={`${scope}.code`}
            rules={{
              required: intl.t('form.errors.fieldRequired'),
              validate: value => (
                DataSource.isValidCode(value) ? true : intl.t('form.errors.fieldRequired')
              ),
            }}
            render={({ field, fieldState }) => (
              <FormInputTransformation
                {...field}
                invalid={fieldState.invalid}
                Dropdown={DropdownDataSource}
                className="mt-1 flex-fill"
              />
            )}
          />
        </FormFieldInline>
      ) : (
        <Controller
          name={`${scope}.code`}
          render={({ field }) => (<FormAny {...field} />)}
        />
      )}
    </>
  )
}

export default enhaceProps(Fields)
