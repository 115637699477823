import { useCallback } from 'react'
import { of as of$, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { withObservables, withProps } from '@wiz/components'
import { orderBy } from '@wiz/utils'
import { auth } from '@/auth'
import Component from '@/components/Form/ListDataExplorerByTwin'

const enhanceProps = withProps(({ onSelect }) => {
  const onUnlink = useCallback(async ({ explorerId, twinId }) => {
    const [ twin ] = await dbProvider.database.collections.get('twins')
      .query(Q.where('id', twinId))
      .fetch()

    const [ explorer ] = await dbProvider.database.collections.get('explorers')
      .query(Q.where('id', explorerId))
      .fetch()

    if (twin && explorer) {
      const context = dbProvider.createBatchContext()
      await twin.prepareUnlinkExplorer(context, explorer)
      await dbProvider.batch(context)
    }
  }, [])

  const onAttach = useCallback(async ({ twinId }, ids) => {
    const [ twin ] = await dbProvider.database.collections.get('twins')
      .query(Q.where('id', twinId))
      .fetch()

    if (twin) {
      const context = dbProvider.createBatchContext()
      await twin.prepareReplaceExplorers(context, ids)
      await dbProvider.batch(context)
    }
  }, [])

  const handleSelect = useCallback(async (id, context) => {
    const [ explorer ] = await dbProvider.database.collections.get('explorers')
      .query(Q.where('id', id))
      .fetch()

    if (explorer) {
      onSelect(explorer, context)
    }
  }, [ onSelect ])

  return {
    onUnlink,
    onAttach,
    onSelect: handleSelect,
  }
})

const enhanceData = withObservables([
  'search',
  'selectedOnly',
  'twins',
  'value',
], ({
  search,
  selectedOnly,
  twins,
  value,
}) => {
  let query = dbProvider.database.collections.get('explorers').query()

  if (selectedOnly) {
    query = query.extend(
      Q.where('id', Array.isArray(value) ? Q.oneOf(value) : value),
    )
  }

  if (search) {
    const sanitizeSearch = Q.sanitizeLikeString(search)
    query = query.extend(Q.or(
      Q.where('title', Q.like(`%${sanitizeSearch}%`)),
      Q.where('id', search),
    ))
  }

  return {
    options: twins.length ? (
      combineLatest(twins.reduce((out, twin) => (
        out.concat(
          twin.observeDiagramContext.pipe(
            map(data => ({
              ...data,
              accessUpdateTwin: auth.checkAccessUpdate(twin),
            })),
          ),
          twin.observeExplorers(query)
            .pipe(
              map(items => items.filter(item => auth.checkAccessRead(item))),
              map(items => (
                orderBy(items.map(item => ({
                  id: item.id,
                  name: item.name,
                  payload: {
                    explorerId: item.id,
                    twinId: twin.id,
                  },
                })), [ item => item.name.toLowerCase() ], [ 'asc' ])
              )),
            ),
        )
      ), [])).pipe(
        map(items => (
          items
            .reduce((out, item, idx) => (
              idx % 2 === 0 ? out.concat({
                id: item.twinId,
                name: item.name,
                payload: {
                  ...item,
                  ids: items[idx + 1]?.map(d => d.id) ?? [],
                },
                children: items[idx + 1],
              }) : out
            ), [])
        )),
      )
    ) : of$([]),
  }
})

export default enhanceProps(
  enhanceData(Component),
)
