import PropTypes from 'prop-types'
import { FormSection } from '@wiz/components'
import TimeSettings from '@/components/Form/TimeSettings'

export default function Section ({ scope, title, description }) {
  return (
    <FormSection
      title={title}
      description={description}
    >
      <TimeSettings
        dateFromFieldName={`${scope}.dateFrom`}
        dateToFieldName={`${scope}.dateTo`}
        durationFieldName={`${scope}.duration`}
        offsetDurationFieldName={`${scope}.offsetDuration`}
        stepRequestFieldName={`${scope}.stepRequest`}
      />
    </FormSection>
  )
}

Section.propTypes = {
  scope: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
}

Section.defaultProps = {
  title: undefined,
  description: undefined,
}
