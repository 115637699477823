import { withSort } from '@wiz/components'
import Component from '@/components/EdgeDevice/TableConnectionsMobile'
import enhanceDataConnections from './enhanceDataConnections'

export default enhanceDataConnections(
  withSort({
    defaultSortBy: 'clientId',
    defaultSortDir: 'desc',
  })(Component),
)
