import { useEffect, useMemo } from 'react'
import classnames from 'classnames'
import { VirtualTable, Pagination, Icon as OldIcon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import useAppContext from '@/hooks/useAppContext'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import Icon from '@/shared/icon'
import EditButton from '../../shared/editButton'

const CellUpdated = ({ cell, row }) => (
  <UpdatedInfo
    userId={row.original.updatedById}
    date={row.original.updatedDate}
    user={row.original.updatedUser}
  />
)

const CellCreated = ({ cell, row }) => (
  <UpdatedInfo
    userId={row.original.createdById}
    date={row.original.createdDate}
    user={row.original.createdUser}
  />
)

export default function Table ({
  items,
  onRemove,
  onEdit,
  refetch,
  filters,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const { onRowSelect } = useAppContext()

  const columns = useMemo(() => [
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      width: 30,
      maxWidth: 30,
      className: 'justify-content-center text-nowrap',
      Cell: ({ cell, row }) => (
        <>
          {auth.checkAccessUpdate('Category') ? (
            <EditButton cell={cell} onEdit={onEdit} />
          ) : null}
          {/* {auth.checkAccessRemove(row.original) ? (
          <button
            type="button"
            className="btn btn-sm p-0"
            onClick={() => onRemove?.([ row.original ])}
          >
            {intl.t('form.actions.remove')}
          </button>
        ) : null} */}
        </>
      ),
    },
    {
      Header: intl.t('categories.form.fields.name'),
      accessor: 'name',
      minWidth: 300,
      Cell: ({ cell, row }) => {
        const allowed = auth.checkAccessUpdate(row.original)
        return (
          <a
            className={classnames('d-flex align-items-center min-w-0', {
              'link pointer': allowed,
            })}
            onClick={() => (allowed ? onEdit?.(row.original.id) : undefined)}
            aria-hidden
          >
            {(row.original.icon || row.original.color) && row.original?.icon?.includes('cus-') ? (
              <OldIcon
                name={row.original.icon}
                color={row.original.color}
                size="lg"
                className="me-2"
              />

            ) : (
              <Icon
                name={row.original.icon}
                color={row.original.color}
                size="lg"
                className="me-2"
              />
            )}

            <div className="text-truncate">
              {cell.value}
              {row.original.comment ? (
                <div className="font-italic text-muted text-truncate small">
                  {row.original.comment}
                </div>
              ) : null}
            </div>
          </a>
        )
      },
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedDate',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: CellUpdated,
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdDate',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: CellCreated,
    },
  ], [ onEdit, onRemove, intl, auth ])

  useEffect(() => {
    if (items && !props.loading) {
      refetch()
    }
  }, [
    filters.page,
    filters.pageSize,
    filters.sortDir,
    filters.sortBy,
    filters.search,
  ])

  return (
    <>
      <VirtualTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={auth.checkAccessRemove('Category')}
        {...props}
        onSelect={onRowSelect}
      />

      <Pagination {...props} />
    </>
  )
}
