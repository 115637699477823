import { switchMap } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import observeDBMount from '@/utils/observeDBMount'
import observeDBInitialize from '@/utils/observeDBInitialize'
import Component from '@/components/Main/PrivateView'

const enhanceMount = withObservables([], () => ({
  initialized: observeDBMount()
    .pipe(
      switchMap(accountId => observeDBInitialize(accountId)),
    ),
}))

export default enhanceMount(Component)
