import {
  useRef, useEffect, useCallback,
} from 'react'
import { echarts } from '@wiz/utils'
import IconFa from '@/shared/icon'
import useAppContext from '@/hooks/useAppContext'

const EChart = ({
  data: properties, onChartClick, isLoading, filters,
}) => {
  const refChart = useRef()
  const refTarget = useRef()
  const { handleTwinChange } = useAppContext()

  const onClick = useCallback((args) => {
    if (args.data.group === 'twinId') {
      if (args.data.id === 'null') {
        return null
      }
      handleTwinChange(args.data.id)
    }

    const optionData = refChart.current?.getOption() || {}
    onChartClick(args, optionData, filters)
  }, [ filters, handleTwinChange, onChartClick ])

  useEffect(() => {
    const chart = echarts.init(refTarget.current)
    chart.on('click', onClick)
    refChart.current = chart
    return () => {
      chart.dispose()
      chart.off('click', onClick)
    }
  }, [ ])

  useEffect(() => {
    if (properties && Object.keys(properties)) {
      refChart.current?.setOption(properties, true)
    }
  }, [ properties ])

  return (
    <div className="d-flex flex-column flex-fill w-100">
      {isLoading ? (
        <div className="position-absolute-fill position-center-fill bg-light opacity-50" style={{ zIndex: 1 }}>
          <IconFa name="faSpinner" size="xl" spin />
        </div>
      ) : null}
      <div
        ref={refTarget}
        className="flex-fill"
        style={{ width: '503px', height: '300px' }}
      />
    </div>
  )
}

export default EChart
