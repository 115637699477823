import { useMemo } from 'react'
import classnames from 'classnames'
import {
  VirtualTable,
  Pagination,
  Icon,
  Badge,
  FormInputSearch,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { consts } from '@wiz/utils'
import TwinList from '@/hoc/TwinList'
import SensorList from '@/hoc/SensorList'
import StreamJobList from '@/hoc/StreamJobList'
import Duration from './Duration'
import CellTwin from './CellTwin'
import CellStreamJob from './CellStreamJob'
import CellSensors from './CellSensors'

const CellSensorsData = SensorList(CellSensors)
const CellStreamJobData = StreamJobList(CellStreamJob)
const CellTwinData = TwinList(CellTwin)

export default function Events ({
  loading,
  items,
  total,
  filters,
  forceUpdate,
  config,
  onFilter,
  onResetFilter,
  onSort,
  onClickPage,
  onClickPageSize,
}) {
  const intl = useIntl()
  const columns = useMemo(() => [
    {
      Header: () => (config.displaySearchBar ? intl.t('form.fields.name') : (
        <div className="d-flex align-items-center">
          {intl.t('form.fields.name')}
        </div>
      )),
      accessor: 'name',
      minWidth: 100,
      Cell: ({ cell, row }) => (
        <div className="text-truncate min-w-0">
          <div className="d-flex align-items-center mb-1">
            {row.original.sourceType === consts.EventSource.Manual ? (
              <Icon
                name="fa--tools"
                title={intl.t(`enum.eventSourceType.${row.original.sourceType}`)}
                className="me-1"
              />
            ) : null}

            <span className="text-truncate min-w-0">
              {cell.value}
            </span>
          </div>

          <div className="d-flex align-items-center">
            <div className="small me-2">
              <Badge
                title={intl.t('form.fields.type')}
                className={classnames('me-1', {
                  'bg-secondary': row.original.type !== consts.EventType.Anomaly,
                  'bg-danger': row.original.type === consts.EventType.Anomaly,
                })}
              >
                {intl.t(`enum.eventType.${row.original.type}`)}
              </Badge>

              <Badge
                title={intl.t('form.fields.status')}
                className={classnames({
                  'bg-secondary': row.original.status !== consts.EventStatus.Active,
                  'bg-primary': row.original.status === consts.EventStatus.Active,
                })}
              >
                {intl.t(`enum.eventStatus.${row.original.status}`)}
              </Badge>
            </div>

            <Duration data={row.original} />

            {row.original.sensorIds?.length ? (
              <CellSensorsData
                value={row.original.sensorIds}
                event={row.original}
                selectedOnly
                onFilter={onFilter}
              />
            ) : null}

            {row.original.streamJobId ? (
              <CellStreamJobData
                value={row.original.streamJobId}
                selectedOnly
              />
            ) : null}

            {row.original.twinId ? (
              <CellTwinData
                value={row.original.twinId}
                selectedOnly
              />
            ) : null}

            {row.original.description ? (
              <div className="font-italic text-muted text-truncate small">
                {row.original.description}
              </div>
            ) : null}
          </div>
        </div>
      ),
    },
  ], [ onFilter, config.displaySearchBar, ])

  return (
    <>
      {config.displaySearchBar ? (
        <div className="d-flex px-3 mt-1 mb-2">
          <FormInputSearch
            size="sm"
            className="me-2"
            placeholder={intl.t('form.actions.searchPlaceholder')}
            value={filters.search}
            onChange={value => onFilter({ search: value })}
          />

          <button
            type="button"
            className="btn btn-sm btn-fill-secondary me-2"
            onClick={onResetFilter}
          >
            {intl.t('form.actions.reset')}
          </button>

          <button
            type="button"
            className="btn btn-sm btn-fill-secondary"
            onClick={() => forceUpdate()}
          >
            {loading ? <Icon name="fa--spinner" className="me-1" spin /> : null}
            {intl.t('form.actions.refresh')}
          </button>
        </div>
      ) : null}

      <VirtualTable
        className="flex-fill min-h-0"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={false}
        defaultSort={[{ id: filters.sortBy, desc: filters.sortDir === 'desc' }]}
        loading={loading}
        onSort={onSort}
      />

      <Pagination
        className="my-1"
        total={total}
        currentPage={filters.page}
        pageSize={filters.pageSize}
        relative
        onClickPage={onClickPage}
        onClickPageSize={onClickPageSize}
      />
    </>
  )
}
