import {
  useRef,
  useMemo,
  useState,
  useCallback,
} from 'react'
import classnames from 'classnames'
import { consts } from '@wiz/utils'
import {
  useDrag,
  Icon,
  FormInputSearch,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import FormulaList from '@/hoc/FormulaList'
import ScriptList from '@/hoc/ScriptList'
import MLWebServiceList from '@/hoc/MLWebServiceList'
import AlertList from '@/hoc/AlertList'
import LabelList from '@/hoc/LabelList'
import List from '@/components/Form/Tree/List'
import ListSensors from '@/components/Form/ListSensors'
import FormLogical from '@/containers/Forms/Sensor/Logical'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'

function OutputListSensors (props) {
  const intl = useIntl()
  const context = useGlobalExecute()
  const [ createSensor, setCreateSensor ] = useState(false)

  const formDialogParams = useMemo(() => ({
    title: intl.t('sensors.titleCreate'),
    dataTestid: 'replaceSensorDialog',
  }), [ intl ])

  const handleCloseForm = useCallback(() => {
    setCreateSensor(false)
  }, [])

  const handleFilterBusinessTypes = useCallback(item => (
    consts.SensorBusinessType.SetPoint === item ||
    consts.SensorBusinessType.Logical === item ||
    consts.SensorBusinessType.Measurement === item
  ), [])

  return (
    <>
      <ListSensors
        {...props}
        outputAutoInputValue
        filterBusinessTypes={handleFilterBusinessTypes}
        postfix={context.filters.edges ? null : (
          <button
            type="button"
            className="btn btn-secondary badge me-1 mb-1"
            onClick={() => setCreateSensor(true)}
          >
            {intl.t('form.actions.create')}
          </button>
        )}
      />

      {createSensor ? (
        <FormLogical
          onClose={handleCloseForm}
          dialog={formDialogParams}
        />
      ) : null}
    </>
  )
}

const ListByType = [
  {
    id: 'input-sensor',
    name: 't/streamJobs.section.inputSensor',
    type: consts.DiagramBlockType.Input,
    Component: ListSensors,
    draggable: true,
  },
  {
    id: 'input-label',
    name: 't/streamJobs.section.inputLabel',
    type: consts.DiagramBlockType.Input,
    Component: LabelList(List),
    draggable: 'true',
  },
  {
    id: 'formula',
    type: consts.DiagramBlockType.Formula,
    Component: FormulaList(List),
    draggable: 'true',
  },
  {
    id: 'script',
    type: consts.DiagramBlockType.Script,
    Component: ScriptList(List),
    draggable: 'true',
  },
  {
    id: 'ml-web-service',
    type: consts.DiagramBlockType.Webservice,
    Component: MLWebServiceList(List),
    draggable: 'true',
  },
  {
    id: 'alert',
    type: consts.DiagramBlockType.Alert,
    Component: AlertList(List),
    draggable: 'true',
  },
  {
    id: 'output-sensor',
    type: consts.DiagramBlockType.Output,
    Component: OutputListSensors,
    draggable: true,
  },
  // {
  //   id: 'sns',
  //   type: consts.DiagramBlockType.Sns,
  //   Component: AlertList(List),
  //   draggable: 'true',
  // },
  // {
  //   id: 'device-command-template',
  //   type: consts.DiagramBlockType.DeviceCommand,
  //   Component: DeviceCommandTemplateList(List),
  //   draggable: true,
  // },
]

function BlockTypeContent ({ data }) {
  return (
    <>
      <span className={classnames('badge d-flex me-2 p-1', data.className)}>
        <Icon
          name={data.icon}
        />
      </span>
      <span className="text-truncate">
        {data.name}
      </span>
    </>
  )
}

export default function Shapes ({
  onClickCreate,
  snsAvailable,
}) {
  const auth = useAuth()
  const drag = useDrag()
  const intl = useIntl()
  const refSearch = useRef()
  const [ listType, setListType ] = useState(null)
  const [ search, setSearch ] = useState('')
  const [ selected, setSelected ] = useState([])

  const handleDragStart = useCallback((data) => {
    drag.context.type = ListByType.find(item => item.id === listType)?.type
    drag.data.add(data)
    const dragIds = Array.from(drag.data).map(item => item.id)
    selected
      .filter(item => !dragIds.includes(item.id))
      .forEach(item => drag.data.add(item))
  }, [ drag, listType, selected ])

  const handleResetFilter = useCallback(() => {
    refSearch.current.reset()
  }, [])

  const handleChange = useCallback((data) => {
    let next = []
    if (selected.some(item => item.id === data.id)) {
      next = selected.filter(item => item.id !== data.id)
    } else {
      next = selected.concat(data)
    }
    setSelected(next)
  }, [ selected ])

  const active = useCallback(data => (
    selected.some(item => item.id === data.id)
  ), [ selected ])

  const {
    Component,
    draggable,
    selectable,
  } = ListByType.find(item => item.id === listType) || {}

  const snsBlock = snsAvailable ? [{
    id: consts.DiagramBlockType.Sns,
    symbol: 'store',
    icon: 'fa--bell',
    className: 'bg-danger',
    textClassName: 'text-danger',
  }] : []

  return (
    <>
      <div className="mt-2 mb-1 mx-n1 px-3">
        <div className="d-flex mx-1 mb-2">
          <FormInputSearch
            ref={refSearch}
            size="sm"
            placeholder={intl.t('form.actions.searchPlaceholder')}
            onChange={setSearch}
          />
        </div>

        <div className="d-flex flex-wrap mx-1">
          {ListByType.map((item) => {
            const info = consts.StreamJobBlockTypeInfo[item.type]
            return (
              <button
                key={item.id}
                type="button"
                className={classnames('btn btn-sm btn-fill-secondary text-nowrap me-1 mb-1', {
                  [info.className]: listType === item.id,
                  active: listType === item.id,
                })}
                onClick={() => {
                  setSelected([])
                  setListType(listType === item.id ? null : item.id)
                }}
              >
                <Icon
                  name={info.icon}
                  className={classnames('me-1', {
                    'text-reset': listType === item.id,
                    [info.textClassName]: listType !== item.id,
                  })}
                />

                {item.name ? intl.t(item.name) : intl.t(`enum.diagramBlock.${item.type}`)}
              </button>
            )
          })}
        </div>
      </div>

      {Component ? (
        <Component
          className="d-flex flex-fill min-h-0"
          draggable={draggable}
          selectable={selectable}
          search={search}
          active={active}
          onChange={handleChange}
          onDragStart={handleDragStart}
          onResetFilter={handleResetFilter}
        />
      ) : (
        <List
          className="d-flex flex-fill min-h-0 zzzzzz"
          draggable="true"
          Content={BlockTypeContent}
          options={consts.StreamJobBlockTypesInfo
            .concat(snsBlock)
            .filter(item => (
              item.id !== consts.DiagramBlockType.DeviceCommand ||
              auth.checkAccessManage('SectionDeviceCommands')
            ))
            .map(item => ({
              id: item.id,
              icon: item.icon,
              name: intl.t(`enum.diagramBlock.${item.id}`),
              className: item.className,
            }))}
          onChange={(data) => {
            onClickCreate(data.id)
          }}
          onDragStart={(data) => {
            drag.context.type = data.id
          }}
        />
      )}
    </>
  )
}
