import { of as of$, combineLatest } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'

const enhanceUnit = withObservables([ 'sensorId' ], ({ sensorId }) => {
  const unit = sensorId ? dbProvider.database.collections.get('sensors')
    .query(Q.where('id', sensorId))
    .observeWithColumns('unit_id')
    .pipe(
      switchMap(sensors => (
        sensors.length ? combineLatest(sensors.map(item => item.unit)) : of$([])
      )),
      map(items => items[0]),
    ) : of$(undefined)

  return {
    unit,
  }
})

export default enhanceUnit(({ children, unit }) => (
  children?.(unit) || null
))
