import { forwardRef, useImperativeHandle } from 'react'
import formatter from '@/utils/formatter'

const FormatDateTime = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    format (date, options) {
      return formatter({
        ...props,
        ...options,
        date,
      })
    },

    is12h () {
      return props.settings?.PlatformTimeFormat?.indexOf('Short12') !== -1
    },
  }), [ props ])

  return props.noView ? null : formatter(props)
})

export default FormatDateTime
