/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  VirtualTable,
  Pagination,
  Icon,
  Badge,
} from '@wiz/components'
import { EdgeDeviceCommand } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import DeviceCommandTemplateList from '@/hoc/DeviceCommandTemplateList'
import EdgeDeviceList from '@/hoc/EdgeDeviceList'
import CellTemplate from './CellTemplate'
import CellDevice from './CellDevice'

const CellTemplateData = DeviceCommandTemplateList(CellTemplate)
const CellDeviceData = EdgeDeviceList(CellDevice)

export default function TableMobile ({
  items,
  onView,
  onFilter,
  ...props
}) {
  const intl = useIntl()
  const columns = useMemo(() => [
    {
      Header: intl.t('edge.commands.form.fields.device'),
      accessor: 'deviceId',
      disableSortBy: true,
      Cell: ({ row }) => (
        <div className="min-w-0">
          <div className="d-flex">
            {row.original.deviceId ? (
              <CellDeviceData
                value={row.original.deviceId}
                selectedOnly
                onFilter={onFilter}
              />
            ) : '-'}
            <Badge className="rounded-pill border text-secondary ms-1 flex-shrink-0">
              {row.original.status}
            </Badge>
          </div>

          <div className="d-flex">
            {row.original.templateId ? (
              <CellTemplateData
                value={row.original.templateId}
                selectedOnly
              />
            ) : '-'}
            <span className="mx-1">/</span>
            {String(row.original.payload)}
          </div>
        </div>
      ),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 45,
      width: 45,
      maxWidth: 45,
      className: 'justify-content-end text-nowrap',
      Cell: ({ row }) => (
        <button
          type="button"
          className="btn btn-sm p-2"
          onClick={() => onView?.(row.original)}
        >
          <Icon name="fa--chevron-right" />
        </button>
      ),
    },
  ], [
    onView,
    onFilter,
    intl,
  ])

  return (
    <>
      <VirtualTable
        className="flex-fill"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        showHeader={false}
        {...props}
      />

      <Pagination
        {...props}
        className="mt-3"
        pageSizeNavigation={false}
        showTotal={false}
        relative
      />
    </>
  )
}

TableMobile.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(EdgeDeviceCommand)),
  filters: PropTypes.object,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onToggleActive: PropTypes.func,
  onFilter: PropTypes.func.isRequired,
}

TableMobile.defaultProps = {
  items: [],
  filters: {},
  onRemove: undefined,
  onEdit: undefined,
  onView: undefined,
  onToggleActive: undefined,
}
