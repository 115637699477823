import { useIntl } from '@wiz/intl'

export default function Removed () {
  const intl = useIntl()
  return (
    <div className="alert alert-danger m-3" role="alert">
      {intl.t('errors.dataRemoved')}
    </div>
  )
}
