import { useMemo } from 'react'
import { VirtualTable, Pagination } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import Icon from '@/shared/icon'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import EditButton from '../../shared/editButton'

const CellName = ({ cell, row, onEdit }) => {
  const intl = useIntl()
  const auth = useAuth()
  return (
    <div className="min-w-0">
      <a
        className="text-truncate pointer"
        onClick={() => auth.checkAccessUpdate(row.original) && onEdit?.(row.original.id)}
        aria-hidden
      >
        { cell.value || (
        <span className="text-muted">{intl.t('form.noname')}</span>
        )}
      </a>

      {row.original.description ? (
        <div className="font-italic text-muted text-truncate small">
          {row.original.description}
        </div>
      ) : null}
    </div>
  )
}

const CellUpdated = ({ cell, row }) => (
  <UpdatedInfo
    date={cell.value}
    user={row.original.updatedUser}
  />
)

const CellCreated = ({ cell, row }) => (
  <UpdatedInfo
    date={cell.value}
    user={row.original.createdUser}
  />
)

export default function Table ({
  items,
  onRemove,
  onEdit,
  onDuplicate,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      width: 30,
      className: 'justify-content-center text-nowrap',
      Cell: ({ cell, row }) => (
        <>
          {auth.checkAccessUpdate(row.original) ? (
            <EditButton cell={{ id: cell.value, templateId: row.original.templateId }} onEdit={onEdit} />
          ) : null}
          {/* {auth.checkAccessCopy(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0 me-2"
              onClick={() => onDuplicate?.(row.original)}
            >
              {intl.t('form.actions.duplicate')}
            </button>
          ) : null} */}
        </>
      ),
    },
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      minWidth: 300,
      Cell: props => <CellName onEdit={onEdit} {...props} />,
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedAt',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: CellUpdated,
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdAt',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: CellCreated,
    },
  ], [ onEdit, onDuplicate, intl, auth ])

  return (
    <>
      <VirtualTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={auth.checkAccessRemove('Alert')}
        {...props}
      />

      <Pagination {...props} />
    </>
  )
}
