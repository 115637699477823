import { useState, useCallback } from 'react'
import { uniq } from '@wiz/utils'
import { withProps } from '@wiz/components'
import { dbProvider, Q, Twin } from '@wiz/store'
import events from '@/utils/events'

const enhanceListProps = withProps(({
  filters,
  onFilter,
}) => {
  const [ selectedIds, setSelectedIds ] = useState([])

  const onRemove = useCallback(async (data, rootTwin, onlyData) => {
    await window.wizConfirm({ message: 't/units.confirmDelete' })

    const rootTwinId = rootTwin?.id || null
    const context = dbProvider.createBatchContext()

    const ids = uniq(
      [].concat(data, onlyData ? [] : selectedIds)
        .map(item => String((item instanceof Twin) ? item.id : item))
        .filter(Boolean),
    )

    const twinIds = ids.filter(id => id.indexOf('/') === -1)
    const removeParentIds = ids.filter(id => id.indexOf('/') !== -1)
      .map(id => id.split('/')[1])
      .filter(id => !twinIds.includes(id))

    let redirectTree
    if (filters.tree) {
      const [ filtersTree ] = await dbProvider.database.collections.get('twins')
        .query(Q.where('id', filters.tree))
        .fetch()

      if (filtersTree) {
        const filtersTreeChain = await filtersTree.fetchTwinsChain()
        const maxChainIndex = twinIds.reduce((out, id) => (
          Math.max(out, filtersTreeChain.findIndex(chainItem => chainItem.id === id))
        ), -1)

        if (maxChainIndex > -1) {
          redirectTree = (
            filtersTreeChain[maxChainIndex + 1] ||
            filtersTreeChain[filtersTreeChain.length - 1]
          )
        }
      }
    }

    const items = await dbProvider.database.collections.get('twins')
      .query(Q.where('id', Q.oneOf(twinIds)))
      .fetch()

    for (const item of items) {
      await item.prepareRemove(context, {
        removeIds: twinIds,
      })
    }

    const children = await dbProvider.database.collections.get('twins')
      .query(
        Q.or(
          Q.where('id', Q.oneOf(removeParentIds)),
          Q.and(
            Q.where('id', Q.notIn(twinIds)),
            Q.where('parent_id', Q.oneOf(twinIds)),
          ),
        ),
      )
      .fetch()

    for (const item of children) {
      await item.prepareMoveInner(context, rootTwinId)
    }

    try {
      await dbProvider.batch(context)

      if (redirectTree) {
        onFilter?.({ tree: redirectTree.id })
      }
    } catch (error) {
      events.emit('app:notify', {
        type: 'error',
        message: error.message,
      })
      throw error
    }
  }, [
    selectedIds,
    filters,
    onFilter,
  ])

  const onUnlinkParent = useCallback(async (model) => {
    const context = dbProvider.createBatchContext()
    await model.prepareMoveInner(context, null)
    await dbProvider.batch(context)
  }, [])

  return {
    selectedIds,
    setSelectedIds,
    onUnlinkParent,
    onRemove,
  }
})

export default enhanceListProps
