import { useState } from 'react'
import { Icon, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import StreamJob from '@/hoc/StreamJob'
import FormStreamJob from '@/containers/Forms/StreamJob'
import Table from '@/containers/StreamJob/Table'
import TableMobile from '@/containers/StreamJob/TableMobile'
import ViewModel from './ViewModel'

const ViewStreamJob = StreamJob(ViewModel)

export default function List ({
  filters,
  selected,
  onFilter,
  onResetFilter,
  onRemove,
  onDuplicate,
  onToggleActive,
  embed,
  templateId,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const isMobile = useMobile()
  const [ editId, setEditId ] = useState(null)
  const [ viewId, setViewId ] = useState(null)

  const TableComponent = isMobile ? TableMobile : Table

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">
      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <TableComponent
          {...props}
          filters={filters}
          onFilter={onFilter}
          onRemove={onRemove}
          onEdit={setEditId}
          onToggleActive={onToggleActive}
          onView={setViewId}
          onDuplicate={onDuplicate}
        />
      </div>

      {editId !== null ? (
        <FormStreamJob
          id={editId}
          templateId={templateId}
          onClose={() => setEditId(null)}
          dialog={{
            title: editId ?
              intl.t('streamJobs.titleUpdate') :
              intl.t('streamJobs.titleCreate'),
            dataTestid: 'replaceStreamJobDialog',
          }}
        />
      ) : null}

      {viewId ? (
        <ViewStreamJob
          id={viewId}
          title={intl.t('streamJobs.titleDetail')}
          onClose={() => setViewId(null)}
          onFilter={onFilter}
          onRemove={onRemove}
          onEdit={setEditId}
          onDuplicate={onDuplicate}
          dialog
        />
      ) : null}
    </div>
  )
}
