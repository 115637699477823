import { useMemo } from 'react'
import { withProps } from '@wiz/components'
import { castArray, timezones } from '@wiz/utils'

export default withProps(({
  search,
  selectedOnly,
  value,
}) => {
  const options = useMemo(() => {
    let items = timezones.map(item => ({
      id: item.name,
      name: `${item.name} (${item.offset})`,
    }))

    if (selectedOnly) {
      const arrayValue = castArray(value)
      items = items.filter(item => arrayValue.includes(item.id))
    }

    if (search) {
      const searchLower = search.trim().toLowerCase()
      items = items.filter(item => (item.name.toLowerCase().indexOf(searchLower) !== -1))
    }

    return items
  }, [
    selectedOnly,
    value,
    search,
  ])

  return {
    options,
  }
})
