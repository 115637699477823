import {
  useRef,
  useCallback,
  useMemo,
} from 'react'
import classnames from 'classnames'
import {
  Form,
  CustomScrollbars,
  useWindow,
} from '@wiz/components'
import { conformsToDeep } from '@wiz/utils'
import FormDialogActions from '@/components/Form/FormDialogActions'
import { captureException } from '@/utils/analytics'
import { useAuth } from '@/auth'
import classes from './index.css'

const Content = ({
  children,
  className,
  classNameBody,
  dataTestid,
  defaultReady,
  defaultValues,
  ignoreObsolete,
  refForm,
  resolver,
  disabled,
  context,
  schema,
  size,
  hasActions = true,
  closeOnSuccess = true,
  resetOnSuccess = false,
  setIsDirty,
  onClose,
  onReset,
  onSubmit,
  onSuccess,
  isSubmit,
  isFirstStep,
  onPrev,
  submitText,
  noCancelChanges,
  footer,
}) => {
  const refCurrentForm = useRef()
  const auth = useAuth()
  const win = useWindow()

  if (refForm) {
    // eslint-disable-next-line no-param-reassign
    refForm.current = refCurrentForm.current
  }

  const currentIgnoreObsolete = useMemo(() => (
    ignoreObsolete ||
    !conformsToDeep(defaultValues, {
      updatedById: value => value !== auth.getCurrentUserId(),
    })
  ), [ ignoreObsolete, defaultValues, auth ])

  const handleSuccess = useCallback((data) => {
    setIsDirty?.(false)
    if (closeOnSuccess) {
      onClose?.()
    }

    onSuccess?.(data)
    refCurrentForm.current.reset()
  }, [
    setIsDirty,
    onClose,
    onSuccess,
    closeOnSuccess,
    resetOnSuccess,
  ])

  const handleError = useCallback((error) => {
    console.error(error)
    captureException(error)
  }, [])

  const currentSize = size || (win?.match('smobil') ? 'sm' : undefined)

  return (
    <Form
      ref={refCurrentForm}
      className={classnames(classes.form, className)}
      dataTestid={dataTestid}
      defaultValues={defaultValues}
      ignoreObsolete={currentIgnoreObsolete}
      onDirty={setIsDirty}
      onError={handleError}
      onReset={onReset}
      onSubmit={onSubmit}
      onSuccess={handleSuccess}
      resolver={resolver}
      context={context}
      schema={schema}
      size={currentSize}
      disabled={disabled}
    >
      <CustomScrollbars
        className="flex-fill"
        classNameContent="d-flex"
        horizontal={false}
        shadow={false}
        autoHide
      >
        <div className={classnames('min-w-0', classes.body, classNameBody)}>
          {children}
        </div>
      </CustomScrollbars>

      {hasActions ? (
        <FormDialogActions
          size={currentSize}
          onSubmit={() => refCurrentForm.current.submit()}
          onReset={() => refCurrentForm.current.reset()}
          {...{
            isSubmit, defaultReady, disabled, onClose, onPrev, isFirstStep, submitText, noCancelChanges,
          }}
        />
      ) : null}
      {!hasActions && footer ? footer : null}
    </Form>
  )
}

export default Content
