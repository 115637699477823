import { useMemo } from 'react'
import { InputSensorValue as InputSensorValueModel } from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import Section from './Section'

export default function InputSensorValue ({
  id,
  inputSensorValue,
  defaultValues,
  businessType,
  closeOnSuccess,
  resetOnSuccess,
  dialog,
  onClose,
  onSubmit,
}) {
  const data = inputSensorValue || defaultValues
  const currentDefaultValues = useMemo(() => ({
    inputSensorValue: InputSensorValueModel.toJSON(data),
  }), [ data?.updatedAt ?? data ])

  if (id && !inputSensorValue) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={currentDefaultValues}
      closeOnSuccess={closeOnSuccess}
      resetOnSuccess={resetOnSuccess}
      dataTestid="inputSensorValueForm"
      dialog={dialog}
      ignoreObsolete
    >
      <Section
        scope="inputSensorValue"
        businessType={businessType}
      />
    </FormDialog>
  )
}
