import { define } from 'superstruct'
import { intl } from '@/i18n'

const Required = () => define('Required', (value) => {
  let result = true
  if (
    value === null ||
    typeof value === 'undefined' ||
    Number.isNaN(value) ||
    (Array.isArray(value) && !value.length) ||
    value === ''
  ) {
    result = intl.t('form.errors.fieldRequired')
  }
  return result
})

export default Required
