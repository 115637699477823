import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { FormField, FormControl } from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

const FieldNormalize = ({ scope }) => {
  const intl = useIntl()
  const { formState: { errors }, watch } = useFormContext()
  const normalize = watch(`${scope}.normalize`)
  return (
    <FormField
      label={intl.t('widgets.histogram.form.fields.normalize')}
      description={do {
        if (normalize === consts.WidgetHistogramNormalizeType.Count) {
          intl.t('widgets.histogram.form.fields.normalizeCountDescr')
        } else if (normalize === consts.WidgetHistogramNormalizeType.Percentage) {
          intl.t('widgets.histogram.form.fields.normalizePercentageDescr')
        } else {
          intl.t('widgets.histogram.form.fields.normalizeDescr')
        }
      }}
      errors={get(errors, `${scope}.normalize`)}
    >
      <FormControl
        type="select"
        name={`${scope}.normalize`}
        rules={{ setValueAs: value => (value || null) }}
        defaultValue={null}
        placeholder={intl.t('widgets.histogram.form.fields.normalizePlaceholder')}
        options={consts.WidgetHistogramNormalizeTypes.map(id => ({
          id,
          name: intl.t(`enum.histogramNormalizeType.${id}`),
        }))}
      />
    </FormField>
  )
}

FieldNormalize.propTypes = {
  scope: PropTypes.string.isRequired,
}

export default FieldNormalize
