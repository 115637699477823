import { useCallback, memo, useMemo } from 'react'
import { map } from 'rxjs/operators'

import { dbProvider, Q } from '@wiz/store'
import { withObservables, withProps } from '@wiz/components'

import BlockAlert from '@/components/Forms/BlockAlert'
import BlockEvent from '@/components/Forms/BlockEvent'
import BlockFlow from '@/components/Forms/BlockFlow'
import BlockFlowStream from '@/components/Forms/BlockFlowStream'
import BlockFormula from '@/components/Forms/BlockFormula'
import BlockInput from '@/components/Forms/BlockInput'
import BlockNestedTwinGraph from '@/components/Forms/BlockNestedTwinGraph'
import BlockOutput from '@/components/Forms/BlockOutput'
import BlockRemoved from '@/components/Forms/Removed'
import BlockScript from '@/components/Forms/BlockScript'
import BlockTwin from '@/components/Forms/BlockTwin'
import BlockWebservice from '@/components/Forms/BlockWebservice'
import BlockWidget from '@/components/Forms/BlockWidget'
import BlockDeviceCommand from '@/components/Forms/BlockDeviceCommand'
import BlockSns from '@/components/Forms/BlockSns'

import { consts } from '@wiz/utils'
import enhanceSettingsSheet from '../NotificationSheet/enhanceSettingsSheet'
import enhanceSheetTargets from '../NotificationSheet/enhanceSheetTargets'
import enhanceWebServiceInterfaces from '../MlWebService/enhanceSettingsInterfaces'
import enhanceScriptInterfaces from '../Script/enhanceSettingsInterfaces'
import enhanceBlockEventInterfaces from '../BlockEvent/enhanceSettingsInterfaces'
import enhanceSettingsTwin from '../BlockFlow/enhanceSettingsTwin'
import enhanceWidgetConfig from '../BlockWidget/enhanceWidgetConfig'
import enhanceSettingsData from '../Twin/enhanceSettingsData'
import enhanceBlock from './enhanceBlock'
import enhanceSettings from './enhanceSettings'

const BlockTwinArea = withProps(() => ({ type: 'area' }))(BlockTwin)
const BlockTwinMachine = withProps(() => ({ type: 'machine' }))(BlockTwin)
const BlockTwinEquipment = withProps(() => ({ type: 'equipment' }))(BlockTwin)

const FormByType = {
  alert: enhanceSettingsSheet(enhanceSheetTargets(BlockAlert)),
  area: enhanceSettingsData(BlockTwinArea),
  equipment: enhanceSettingsData(BlockTwinEquipment),
  event: enhanceBlockEventInterfaces(BlockEvent),
  flow: enhanceSettingsTwin(BlockFlow),
  flowStream: BlockFlowStream, // alias for "flow" type for Stream Jobs
  formula: BlockFormula,
  input: BlockInput,
  machine: enhanceSettingsData(BlockTwinMachine),
  nestedTwinGraph: BlockNestedTwinGraph,
  output: BlockOutput,
  script: enhanceScriptInterfaces(BlockScript),
  webservice: enhanceWebServiceInterfaces(BlockWebservice),
  widget: enhanceWidgetConfig(BlockWidget),
  deviceCommand: BlockDeviceCommand,
  sns: BlockSns,
}

const enhanceProps = withProps(({
  block, blocks, id,
}) => {
  // TODO: think on complexity
  // related to inputs/outputs in sij
  const outputs = useMemo(() => {
    if (!blocks?.length) {
      return {}
    }
    const next = {}
    for (const item of blocks) {
      if (item.type === consts.DiagramBlockType.Output) {
        for (const _block of blocks) {
          if (_block.type === consts.DiagramBlockType.Flow) {
            if (_block.toId === item.id) { // formula id, it's _block.fromId
              for (const _item of blocks) {
                if (_item.toId === _block.fromId) {
                  // _item.fromId - input id
                  if (next[item.id]) {
                    next[item.id].push(_item.fromId)
                  } else {
                    next[item.id] = [ _item.fromId ]
                  }
                }
              }
            }
          }
        }
      }
    }
    return next
  }, [ blocks ])

  const onSubmit = useCallback(async (data) => {
    if (block) {
      const context = dbProvider.createBatchContext()
      if (data.settings) {
        await block.prepareReplaceSettings(context, data.settings)
      }
      await block.prepareUpdateData(context, data.block)
      await dbProvider.batch(context)
    }
  }, [ block ])

  const isOutput = block?.type === consts.DiagramBlockType.Output

  return {
    onSubmit,
    blockIds: isOutput ? outputs[id] || [ id ] : [],
    isOutput,
  }
})

const enhanceDatapoints = withObservables([ 'blockIds' ], ({ blockIds }) => ({
  sensorIds: dbProvider.database.collections.get('diagram_block_inputs').query(Q.where('block_id', Q.oneOf(blockIds))).observeWithColumns([ 'updated_at' ]).pipe(map(items => items.map(block => block.sensorId))),
}))

const FormBlock = ({ blockTypeAlias, ...props }) => {
  let type = props.block?.type
  if (blockTypeAlias?.[type]) {
    type = blockTypeAlias[type]
  }

  const FormComponent = FormByType[type]
  return FormComponent ? <FormComponent {...props} /> : <BlockRemoved />
}

export default memo(
  enhanceBlock(
    enhanceSettings(
      enhanceProps(
        enhanceDatapoints(FormBlock),
      ),
    ),
  ),
)
