import { of as of$, throwError } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import defaultDiagramState from '@/utils/defaultDiagramState'
import enhancePropsDiagram from '@/containers/StreamJob/enhancePropsDiagram'
import enhanceBlocks from '@/containers/StreamJob/enhanceBlocks'
import Component from '@/components/StreamJobs/View'

const enhanceStreamJob = withObservables([ 'id' ], ({ id }) => ({
  streamJob: dbProvider.database.collections.get('stream_jobs')
    .query(Q.where('id', id))
    .observeWithColumns([ 'updated_at' ])
    .pipe(
      mergeMap(items => (items.length ? of$(items[0]) : throwError('Stream job not found'))),
    ),
}), {
  onError (error, { onClose }) {
    onClose()
  },
})

const enhanceMetaBlock = withObservables([ 'streamJob' ], ({ streamJob }) => ({
  diagramState: streamJob.observeDiagramState.pipe(
    defaultDiagramState(streamJob),
  ),
  metaBlock: dbProvider.database.collections.get('diagram_blocks')
    .query(Q.where('id', String(streamJob?.blockId)))
    .observeWithColumns([ 'updated_at' ])
    .pipe(
      mergeMap(items => (items.length ? of$(items[0]) : throwError('Meta block not found'))),
    ),
}), {
  onError (error, { onClose }) {
    onClose()
  },
})

export default enhanceStreamJob(
  enhanceMetaBlock(
    enhanceBlocks(
      enhancePropsDiagram(Component),
    ),
  ),
)
