import { useRef } from 'react'
import { FormSection, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import SectionScriptInterfaceFields from './SectionMlModelInterfaceFields'

const SectionRatio = [ 9, 3 ]

export default function SectionScriptInterface ({ scope }) {
  const refFields = useRef()
  const intl = useIntl()

  return (
    <FormSection
      ratio={SectionRatio}
      title={intl.t('mlModel.form.fields.params')}
      description={intl.t('mlModel.form.fields.paramsDescr')}
      addon={() => (
        <button
          type="button"
          className="btn btn-sm btn-flat-secondary mt-2 mt-md-0 text-nowrap min-w-0"
          onClick={() => refFields.current.add()}
        >
          <Icon name="fa--plus" size="md" className="me-1" />
          {intl.t('form.actions.addParameter')}
        </button>
      )}
    >
      <SectionScriptInterfaceFields
        ref={refFields}
        scope={scope}
      />
    </FormSection>
  )
}
