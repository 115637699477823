import { forwardRef } from 'react'
import { Dropdown, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'

const RawTooltip = forwardRef(({
  counts,
  showInfo = false,
}, ref) => {
  const intl = useIntl()

  return (
    <Dropdown
      target={ref}
      className="d-flex flex-fill p-2"
      mode="mouseenter"
      placement="top"
      maxWidth={360}
      container={null}
      arrow
    >
      {Object.keys(counts).map((sourceId) => {
        const {
          color,
          count,
          total,
          name,
        } = counts[sourceId]

        return (
          <div key={sourceId} className="d-flex flex-fill align-items-center">
            <Icon
              className="me-lg-2"
              name="fa--circle"
              width="0.6em"
              height="0.6em"
              color={color}
            />
            <span className="me-2 fw-light text-truncate">
              {name}
            </span>
            <span className="ms-auto text-nowrap">
              {count < total ? (`${count} from ${total}`) : 'raw'}
            </span>
          </div>
        )
      })}

      {showInfo ? (
        <i className="small mt-1">
          {intl.t('explorer.stepTip')}
        </i>
      ) : null}
    </Dropdown>
  )
})

export default RawTooltip
