import { useCallback } from 'react'
import { firstValueFrom } from 'rxjs'
import { parseAsync } from 'json2csv'
import { saveAs } from 'file-saver'
import kebabCase from 'lodash/kebabCase'
import { DateTime } from 'luxon'
import { Q, dbProvider } from '@wiz/store'
import { set } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { withProps, withObservables } from '@wiz/components'
import { useRouter } from '@/router'
import { appEnv } from '@/config'
import List from '@/components/dashboards/List'
import useFilters from '@/hooks/useFilters'

function initFilters (query = {}) {
  return {
    sortBy: query.sortBy || 'alpha-down',
    filterBy: query.filterBy || null,
    followedBy: query.followedBy || null,
  }
}

const enhanceSettings = withObservables([], () => ({
  settings: dbProvider.observeSettings([
    'dashboardFilters',
  ]),
}))

const enhanceProps = withProps(({ settings }) => {
  const intl = useIntl()
  const router = useRouter()
  const { filters, onFilter, onResetFilter } = useFilters({
    initFilters,
    source: settings.dashboardFilters,
    setSource: async (data) => {
      const context = dbProvider.createBatchContext()
      await dbProvider.prepareReplaceSetting(context, 'dashboardFilters', data)
      await dbProvider.batch(context)
    },
  })

  const onAction = useCallback(async (action, dashboard) => {
    if (action.id === 'select') {
      const context = dbProvider.createBatchContext()
      await dbProvider.prepareReplaceSetting(context, 'homepageDefaultId', dashboard.id)
      await dbProvider.batch(context)
    } else if (action.id === 'view') {
      router.push({ name: 'dashboard', params: { id: dashboard.id } })
    } else if (action.id === 'favorite') {
      let favorites = await dbProvider.fetchSettings('spotlightFavorites')
      favorites = favorites.includes(dashboard.id) ?
        favorites.filter(item => item !== dashboard.id) :
        favorites.concat(dashboard.id)

      const context = dbProvider.createBatchContext()
      await dbProvider.prepareReplaceSetting(context, 'spotlightFavorites', favorites)
      await dbProvider.batch(context)
    } else if (action.id === 'duplicate') {
      const context = dbProvider.createBatchContext()
      const model = await dbProvider.prepareDuplicateModel(context, dashboard, null, {
        uniqProps: [ 'title' ],
      })
      await dbProvider.batch(context)
      await window.wizConfirm({
        title: 't/form.success.duplicated',
        message: 't/dashboards.successDuplication',
      })
      router.push({ name: 'dashboard', params: { id: model.id } })
    } else if (action.id === 'remove') {
      await window.wizConfirm({ message: 't/units.confirmDelete' })
      const context = dbProvider.createBatchContext()
      await dashboard.prepareRemove(context)
      await dbProvider.batch(context)
    } else if (action.id === 'exportDefinition') {
      const data = await firstValueFrom(dashboard.observeSensorsStruture)

      const cvs = (tree, level = 0, parentId) => {
        const result = []
        for (const node of tree) {
          if (node.name) {
            result.push({
              name: node.options ? `${'#'.repeat(level + 1)} ${intl.t(node.name)}` : node.name,
              next: undefined,
              id: node.options ? undefined : `${parentId}/${node.id}`,
            })
          }
          if (node.options) {
            result.push(...cvs(node.options, level + 1, node.id))
          }
          if (!level) {
            result.push({})
          }
        }
        return result
      }

      const csvData = await parseAsync(cvs(data), {
        delimiter: ';',
        header: false,
        includeEmptyRows: true,
      })

      const blob = new Blob([ csvData ], {
        type: 'text/csv;charset=utf-8',
      })

      const name = `${kebabCase(dashboard.title)}_${appEnv.WIZ_CLIENT}_${DateTime.local().toFormat('yyyymmdd-hh-mm')}.csv`

      saveAs(blob, name)
    } else if (action.id === 'importDefinition') {
      const reader = new FileReader()
      reader.addEventListener('load', async (event) => {
        const data = event.target.result
          .split('\n')
          .map(line => line.split(';'))
          .map(([ name, next, id ]) => ({
            name: name && JSON.parse(name),
            next: next && JSON.parse(next),
            id: id && JSON.parse(id),
          }))
          .filter(({ id }) => id)

        const hardwareIds = data.map(({ next, name }) => (next || name))
        if (hardwareIds.length) {
          const sensors = (
            await dbProvider.database.collections.get('sensors')
              .query(Q.where('hardware_id', Q.oneOf(hardwareIds)))
              .fetch()
          ).reduce((acc, item) => ({ ...acc, [item.hardwareId]: item.id }), {})

          const replaceContext = {}
          data.forEach(({ id, name, next }) => {
            if (sensors[next || name]) {
              set(replaceContext, id.split('/'), sensors[next || name])
            }
          })

          const context = dbProvider.createBatchContext()
          const model = await dbProvider.prepareDuplicateModel(context, dashboard, undefined, {
            replaceContext,
            uniqProps: [ 'title' ],
          })
          await dbProvider.batch(context)
          await window.wizConfirm({
            title: 't/form.success.duplicated',
            message: 't/dashboards.successDuplication',
          })
          router.push({ name: 'dashboard', params: { id: model.id } })
        }
      }, false)
      reader.readAsText(action.fileList[0])
    }
  }, [ router, intl ])

  return {
    filters,
    onAction,
    onFilter,
    onResetFilter,
  }
})

export default enhanceSettings(
  enhanceProps(List),
)
