import { useMemo } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import FormDialog from '@/components/Form/FormDialog'
import { Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { wizataApi } from '@/api'
import { uuid } from '@wiz/utils'
// import { useSignal } from '@/components/Template/components/pipeline/hooks/useSignal'
import { testJSON } from '@/utils/json'
import Section from './Section'
import Removed from '../Removed'
import SectionTwins from './SectionTwins'

const Trigger = ({
  id,
  templateId,
  dialog,
  onClose,
  onUpdate,
  pipelines,
}) => {
  const queryClient = useQueryClient()
  const intl = useIntl()

  // const { init, destroy, messages } = useSignal()

  const { data: item, isLoading } = useQuery({
    queryKey: [ 'trigger', id ],
    queryFn: () => wizataApi.triggers.getById(id),
    enabled: !!id,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  })

  const {
    mutateAsync: mutateTrigger,
  } = useMutation({
    mutationKey: [ 'mutateTrigger' ],
    mutationFn: (data) => {
      if (!data.id) {
        return wizataApi.triggers.create({ ...data, id: uuid() })
      }
      return wizataApi.triggers.update(data)
    },
    // onError: (err) => {
    //   events.emit('app:notify', {
    //     type: 'error',
    //     title: 't/triggers.update.error',
    //     message: err.message,
    //     duration: 5000,
    //   })
    // },
    onSuccess: () => {
      onUpdate?.()
      queryClient.invalidateQueries({ queryKey: [ 'trigger', id ] })
      queryClient.invalidateQueries({ queryKey: [ 'triggersPagedList' ] })
    },
    // onSettled: () => {
    //   onUpdate?.()
    //   queryClient.invalidateQueries({ queryKey: [ 'trigger', id ] })
    // },
  })

  const handleSubmit = async ({ trigger, twinIds }) => {
    const next = { ...trigger, twinIds }
    if (!trigger.jsonProperties || !testJSON(trigger.jsonProperties)) {
      next.jsonProperties = JSON.stringify({})
    }
    await mutateTrigger(next)
  }

  const defaultValues = useMemo(() => ({
    trigger: {
      ...item,
      jsonProperties: item?.jsonProperties || '{}',
      templateId: item ? item.templateId : templateId,
    },
    twinIds: item?.twinIds,
  }), [ item, templateId ])

  if (id && isLoading) {
    return (
      <div className="position-absolute-fill position-center-fill bg-light opacity-50">
        <Icon name="fa--spinner" size="2X" spin />
      </div>
    )
  }

  if (id && !item) {
    return <Removed />
  }

  return (
    <FormDialog
      onClose={onClose}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      dataTestid="triggerForm"
      dialog={dialog}
    >
      <Section
        scope="trigger"
        templateId={item ? item.templateId : templateId}
        pipelineId={item?.pipelineId}
        pipelines={pipelines}
      />
      <SectionTwins
        title={intl.t('form.fields.twins')}
        description={intl.t('form.fields.twinsDescr')}
        scope="twinIds"
        templateId={item ? item.templateId : templateId}
      />
    </FormDialog>
  )
}

export default Trigger
