import pick from 'lodash/pick'

export default function logs (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      logs: {
        enumerable: true,
        value: Object.create({}, {
          streamJob: {
            enumerable: true,
            value (params) {
              return client.get('/Logs/streamjobs', pick(params, [
                'from',
                'to',
                'userEmail',
                'streamJobId',
                'logSource',
                'offset',
                'limit',
              ])).fetch()
            },
          },

          sms: {
            enumerable: true,
            value (params) {
              return client.get('/Logs/sms', pick(params, [
                'from',
                'to',
                'recipient',
                'search',
                'isPaid',
                'offset',
                'limit',
                'sortBy',
                'sortDir',
              ])).fetch()
            },
          },

          email: {
            enumerable: true,
            value (params) {
              return client.get('/Logs/email', pick(params, [
                'from',
                'to',
                'recipient',
                'notificationSheetName',
                'search',
                'offset',
                'limit',
                'sortBy',
                'sortDir',
              ])).fetch()
            },
          },

          edge: {
            enumerable: true,
            value (params) {
              return client.get('/Logs/edge', pick(params, [
                'from',
                'to',
                'deviceId',
                'moduleId',
                'offset',
                'limit',
              ])).fetch()
            },
          },
        }),
      },
    })
  }
}
