import { useMemo } from 'react'
import { Table as StaticTable, Pagination } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import Observe from '@/containers/Observe'
import FormatDateTime from '@/containers/FormatDateTime'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import QualityDataTemplateList from '@/hoc/QualityDataTemplateList'
import Detail from './Detail'
import CellTemplate from './CellTemplate'

const CellTemplateData = QualityDataTemplateList(CellTemplate)

export default function Table ({
  items,
  onRemove,
  onEdit,
  onDuplicate,
  onFilter,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      Header: intl.t('quality.form.fields.registerAt'),
      accessor: 'registerAt',
      minWidth: 300,
      Cell: ({ cell }) => (<FormatDateTime date={cell.value} />),
    },
    {
      Header: intl.t('quality.form.fields.approval'),
      accessor: 'approval',
      Cell: ({ cell, row }) => (
        <Observe query={row.original.qualityDataTemplate}>
          {({ data }) => (
            !data?.approval ?
              intl.t('enum.qualityApproval.noneed') :
              intl.t(`enum.qualityApproval.${cell.value}`)
          )}
        </Observe>
      ),
    },
    {
      Header: intl.t('quality.form.fields.qualityTemplate'),
      accessor: 'qualityDataTemplateId',
      disableSortBy: true,
      Cell: ({ cell }) => (cell.value ? (
        <CellTemplateData
          value={cell.value}
          selectedOnly
          onFilter={onFilter}
        />
      ) : '-'),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedAt',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 220,
      Cell: ({ cell, row }) => (<UpdatedInfo date={cell.value} userId={row.original.updatedById} user={row.original.updatedUser} />),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdAt',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 220,
      Cell: ({ cell, row }) => (<UpdatedInfo date={cell.value} userId={row.original.createdById} user={row.original.createdUser} />),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 150,
      width: 150,
      maxWidth: 150,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell, row }) => (
        <>
          {auth.checkAccessUpdate(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0 me-2"
              onClick={() => onEdit?.(cell.value)}
            >
              {intl.t('form.actions.edit')}
            </button>
          ) : null}
          {auth.checkAccessRemove(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0"
              onClick={() => onRemove?.([ row.original ])}
            >
              {intl.t('form.actions.remove')}
            </button>
          ) : null}
        </>
      ),
    },
  ], [ intl, onEdit, onRemove, onFilter, auth ])

  return (
    <>
      <StaticTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={auth.checkAccessRemove('QualityData')}
        ExpandeComponent={Detail}
        expande
        {...props}
      />

      <Pagination {...props} />
    </>
  )
}
