import { forwardRef } from 'react'
import LabelList from '@/hoc/LabelList'
import Select from '@/components/Form/Select'

const SelectLabel = forwardRef((props, ref) => (
  <Select
    {...props}
    ref={ref}
    ListSource={LabelList}
  />
))

SelectLabel.displayName = 'SelectLabel'
export default SelectLabel
