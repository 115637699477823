import PropTypes from 'prop-types'
import { OverflowCenter, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import ButtonCopy from '@/components/Form/ButtonCopy'

export default function OptionTwin (props) {
  const intl = useIntl()
  const {
    treeData: {
      onAttachEvent,
    },
    data: {
      payload: item,
    },
  } = props

  return (
    <div className="flex-fill d-flex align-items-start hover-toggle min-w-0">
      <div className="flex-fill min-w-0">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center min-w-0">
            <OverflowCenter value={item.displayName} />

            {item.hardwareId ? (
              <ButtonCopy
                className="hover-inline-flex"
                value={item.hardwareId}
                title={intl.t('message.copy.hardwareId', { value: item.hardwareId })}
              />
            ) : null}
          </div>
        </div>
        {item.description ? (
          <div className="d-flex">
            <span className="me-1 text-secondary text-truncate">{item.description}</span>
          </div>
        ) : null}
      </div>

      {onAttachEvent ? (
        <button
          type="button"
          className="hover-opacity btn btn-flat-secondary align-self-center p-2 ms-1"
          title={intl.t('twinGraph.form.actions.attachEvent')}
          onClick={() => onAttachEvent(item)}
        >
          <Icon name="fa--plus" />
        </button>
      ) : null}
    </div>
  )
}

OptionTwin.propTypes = {
  treeData: PropTypes.shape({
    onAttachEvent: PropTypes.func.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    payload: PropTypes.object.isRequired,
  }).isRequired,
}
