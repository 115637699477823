import { map } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { auth } from '@/auth'

export default withObservables([], () => ({
  currentUser: dbProvider.database.collections.get('users')
    .query(Q.where('id', auth.getCurrentUserId()))
    .observeWithColumns([ 'updated_at' ])
    .pipe(map(items => items[0])),
}))
