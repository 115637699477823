import classnames from 'classnames'
import { Icon, OverflowCenter } from '@wiz/components'
import ObserveDiagramContext from '@/containers/ObserveDiagramContext'

export default function NameDiagramBlockFlow ({ value, className }) {
  return (
    <div className={classnames('d-inline-flex align-items-center min-w-0', className)}>
      <ObserveDiagramContext query={value.fromBlock}>
        {({ data }) => (
          <OverflowCenter value={data.name} />
        )}
      </ObserveDiagramContext>
      <Icon name="fa--arrow-right" className="mx-2" />
      <ObserveDiagramContext query={value.toBlock}>
        {({ data }) => (
          <OverflowCenter value={data.name} />
        )}
      </ObserveDiagramContext>
    </div>
  )
}
