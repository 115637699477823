import {
  useCallback,
  useRef,
  useMemo,
  useState,
} from 'react'
import merge from 'lodash/merge'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import isEqualWith from 'lodash/isEqualWith'
import { withProps } from '@wiz/components'
import {
  dbProvider,
  IExplorer,
  DataFilter,
  Condition,
  Experiment,
} from '@wiz/store'
import {
  orderBy,
  validateNumberChartValues,
  validateRawSamplingDuration,
} from '@wiz/utils'

const compare = (next, old) => {
  const isDot = isEqual(old.from, old.to)
  const _old = { ...old }

  if (isDot) {
    _old.from -= 30000
    _old.to += 30000
  }

  if (isEqual(next.name, _old.name) && isEqual(next.dateFrom, _old.from) && isEqual(next.dateTo, _old.to)) {
    return true
  }
  return false
}

function setInitialContext (context) {
  return {
    ...context,
    dataFilter: context?.dataFilter || DataFilter.toJSON(),
    dataViews: orderBy(context?.dataViews || [], [ 'sort' ], [ 'asc' ])
      .map((item, idx) => ({ ...item, sort: idx * 10 })),
    dataSources: context?.dataSources || [],
    conditions: context?.conditions || [],
    eventSources: context?.eventSources || [],
  }
}

export default withProps(({
  getAnomalyWithSensorIds, settings,
}) => {
  const [ currentContext, setCurrentContext ] = useState(setInitialContext({}))
  const selectedRef = useRef([])

  const updateContext = useCallback(async (data) => {
    setCurrentContext(merge({}, currentContext, data))
  }, [ currentContext ])

  const toggleLegend = useCallback(async (name) => {
    setCurrentContext(prev => merge({}, prev, {
      dataFilter: {
        viewSelectedLegend: {
          [name]: !(prev.dataFilter.viewSelectedLegend[name] ?? true),
        },
      },
    }))
  }, [ ])

  const resizeLegend = useCallback(async (size) => {
    setCurrentContext(prev => merge({}, prev, {
      dataFilter: {
        viewLegendSize: Number(size || 0),
      },
    }))
  }, [ ])

  const changeViewZoom = useCallback(async (viewZoom) => {
    setCurrentContext(prev => merge({}, prev, {
      dataFilter: { viewZoom },
    }))
  }, [ ])

  const handleValidateNumberChartValues = useCallback((params) => {
    if (settings?.SeriesQueryMaxCount) {
      const {
        dateTo, dateFrom, duration, stepCustom,
      } = params || {}
      const maxNumberChartValues = settings.SeriesQueryMaxCount
      const rawDateRangeLimit = settings.RawDateRangeLimit
      console.log('dpp count', Math.ceil((duration || dateTo - dateFrom) / stepCustom))
      if (!validateNumberChartValues(params, maxNumberChartValues)) {
        params.stepType = 'raw'
        if (validateRawSamplingDuration(params, rawDateRangeLimit)) {
          return 'raw'
        }
        return 'dense'
      }
    }
    return 'custom'
  }, [ settings ])

  const createDataSource = useCallback(async (anomaly, request) => {
    const { dataFilter } = currentContext

    const nextDataSources = [ ]
    const nextDataViews = [ ]

    for (const sensorId of anomaly.sensorIds) {
      const source = await IExplorer.createDataSourceContext(
        dbProvider.database,
        { dataType: 'avg', sensorId },
      )
      const view = await IExplorer.createDataViewContext(
        dbProvider.database,
        { sourceId: source.id, source },
      )
      nextDataSources.push(source)
      nextDataViews.push(view)
    }

    // group sensors
    let items = []
    if (nextDataViews.length > 1) {
      const dropItem = nextDataViews[nextDataViews.length - 1]
      const targetItem = nextDataViews[nextDataViews.length - 2]

      const sort = targetItem?.sort ?? dropItem?.sort
      // let parentId = targetItem?.role === 'grid' ? targetItem.id : targetItem?.parentId

      const parentView = await IExplorer.createDataViewContext(
        dbProvider.database,
        { role: 'grid', sort },
      )

      items.push(parentView)
      const parentId = parentView.id

      const views = nextDataViews.map(item => ({ ...item, parentId }))

      items = items.concat(views)
    } else {
      items = items.concat(nextDataViews)
    }

    const conditions = []

    const data = {
      stepType: 'custom',
      stepCustom: request?.aggregations?.interval,
      duration: null,
      dateFrom: request?.timeframe?.start,
      dateTo: request?.timeframe?.end,
    }

    const stepType = await handleValidateNumberChartValues(data)

    setCurrentContext({
      ...currentContext,
      conditions,
      dataFilter: {
        ...dataFilter,
        ...data,
        stepType,
      },
      dataViews: items,
      dataSources: nextDataSources,
    })
  }, [
    currentContext,
    setCurrentContext,
    handleValidateNumberChartValues,
  ])

  const updateConditions = useCallback(async (list, dataSources) => {
    const conditions = []

    const next = differenceWith(list, selectedRef.current, isEqual)
    const rmNext = differenceWith(selectedRef.current, list, isEqual)

    if (next.length) {
      for (const item of next) {
        const nextCondition = Condition.toJSON()
        const isDot = item.from === item.to

        const data = {
          ...nextCondition,
          // color: '#a7a7af',
          dateFrom: isDot ? item.from - 30000 : item.from,
          dateTo: isDot ? item.to + 30000 : item.to,
          name: item.name,
          type: 'gte',
          inputDataSources: [ dataSources[0] ], // need to investigate
          payload: {
            ...nextCondition.payload,
            logical: Number.NEGATIVE_INFINITY,
          },
        }

        const condition = await IExplorer.createConditionContext(
          dbProvider.database,
          data,
        )
        conditions.push(condition)
      }

      setCurrentContext(prev => ({
        ...prev,
        conditions: prev.conditions.concat(conditions),
      }))
    } else if (rmNext.length) {
      const nextConditions = [ ...currentContext.conditions ]

      const res = differenceWith(nextConditions, rmNext, (a, b) => isEqualWith(a, b, compare))

      setCurrentContext(prev => ({
        ...prev,
        conditions: res,
      }))
    }

    selectedRef.current = list
  }, [ selectedRef, currentContext ])

  const updateResultView = useCallback(async (request, anomaliesList) => {
    const nextList = await getAnomalyWithSensorIds(anomaliesList) // anomalies[]

    for (const anomaly of nextList) {
      createDataSource(anomaly, request)
    }
  }, [ createDataSource, getAnomalyWithSensorIds ])

  const handleCreate = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    const prepareModel = await dbProvider.prepareReplaceData(context, Experiment, data)
    // FIXME: please find another way to update this. We need to get new id and re-attach it
    const request = JSON.parse(prepareModel.request)
    const next = {
      ...Experiment.toJSON(prepareModel),
      request: JSON.stringify({ experiment_id: prepareModel.id, ...request }),
    }
    const model = await dbProvider.prepareReplaceData(context, Experiment, next)
    await dbProvider.batch(context)
    return model
  }, [ ])

  const context = useMemo(() => ({
    changeViewZoom,
    createDataSource,
    resizeLegend,
    toggleLegend,
    updateContext,
    updateResultView,
    setCurrentContext,
    data: currentContext,
    updateConditions,
    handleCreate,
  }), [
    changeViewZoom,
    createDataSource,
    resizeLegend,
    toggleLegend,
    updateContext,
    updateResultView,
    setCurrentContext,
    currentContext,
    updateConditions,
    handleCreate,
  ])

  return context
})
