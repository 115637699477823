import { Auth } from 'aws-amplify'

export const COGNITO = {
  REGION: 'eu-central-1',
  USER_POOL_ID: 'eu-central-1_ogXXuKJAD',
  APP_CLIENT_ID: '4em4mmn63rla19gt4bcos24skl',
  redirectUri: `${window.location.origin}/token.html`,
}

export const awsConfig = {
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
  Auth: {
    userPoolId: COGNITO.USER_POOL_ID,
    region: COGNITO.REGION,
    userPoolWebClientId: COGNITO.APP_CLIENT_ID,
  },
}

export const AUTH_SCHEMES = {
  azure: 'AzureAd',
  aws: 'AwsCognito',
}

export const CHALLENGE_NAME = {
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
}

let user

export const awsLogin = async (data) => {
  if (user && user.challengeName === CHALLENGE_NAME.NEW_PASSWORD_REQUIRED) {
    await user.completeNewPasswordChallenge(data.newPassword, {}, {
      onSuccess: async (session, confirmationRequired) => {
        user = await Auth.signIn(data?.email, data?.newPassword)
        window.history.go(0)
      },
      onFailure: e => e,
    })
    // const { requiredAttributes } = user.challengeParam
    // user = await Auth.completeNewPassword(
    //   data.user,
    //   data.newPassword,
    //   {
    //     ...requiredAttributes,
    //   },
    // )
    return user
  }

  try {
    user = await Auth.signIn(data?.email, data?.password)
  } catch (err) {
    throw Error(err?.message)
  }

  return user
}
