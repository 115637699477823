import { useQuery } from '@tanstack/react-query'
import { Icon, CustomScrollbars } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { consts } from '@wiz/utils'
import { useAuth } from '@/auth'
import useDashboardExecute from '@/hooks/useDashboardExecute'
import { wizataApi } from '@/api'

export default function RightBarWidgets ({ dashboard, onAttachWidget }) {
  const intl = useIntl()
  const auth = useAuth()
  const {
    isDroppable,
    canUpdateDashboard,
    setDragType,
  } = useDashboardExecute()

  const { isCheckLoading, isError, isSuccess } = useQuery({
    queryKey: [ 'grafanaCheck' ],
    queryFn: () => wizataApi.grafana.check(),
    enabled: true,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  })

  const items = (dashboard.availableWidgets || [])
    .filter(item => (
      !item.access ||
      auth.checkAccessManage(item.access)
    ))

  return (
    <CustomScrollbars
      horizontal={false}
      shadow={false}
    >
      <ul className="list-group list-group-flush rounded-0">
        {items.map((item) => {
          const isGrafanaDisabled = item.type === 'grafana' && (isCheckLoading || isError || !isSuccess)
          return (
            <button
              key={item.type}
              type="button"
              className="list-group-item list-group-item-action d-flex align-items-center"
              disabled={!canUpdateDashboard || isGrafanaDisabled}
              {...(isDroppable ? {
                draggable: true,
                onDragStart: (event) => {
                  setDragType(item.type)
                  event.dataTransfer.setData('text', '')
                  event.dataTransfer.setDragImage(consts.DragImagesByModelName.Widget, 20, 20)
                },
                onDragEnd: () => setDragType(null),
              } : undefined)}
              {...(canUpdateDashboard ? {
                onClick: () => onAttachWidget(item),
              } : undefined)}
            >
              <Icon name={item.icon} className="me-3" size="lg" />
              {intl.t(item.name)}
            </button>
          )
        })}
      </ul>
    </CustomScrollbars>
  )
}
