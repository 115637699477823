import { useMemo, useState, useCallback } from 'react'
import { withContext, useDidUpdate } from '@wiz/components'
import TwinGraphExecute from '@/context/TwinGraphExecute'

const enhanceRunContext = withContext(TwinGraphExecute, ({
  twinGraph,
}) => {
  const [ includeChildTwinEvents, setIncludeChildTwinEvents ] = useState(true)
  const toggleIncludeChildTwinEvents = useCallback(() => {
    setIncludeChildTwinEvents(!includeChildTwinEvents)
  }, [ includeChildTwinEvents ])

  useDidUpdate(() => {
    setIncludeChildTwinEvents(true)
  }, [ twinGraph ])

  const context = useMemo(() => ({
    includeChildTwinEvents,
    toggleIncludeChildTwinEvents,
  }), [
    includeChildTwinEvents,
    toggleIncludeChildTwinEvents,
  ])

  return context
})

export default enhanceRunContext
