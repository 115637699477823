import PropTypes from 'prop-types'
import { Icon } from '@wiz/components'

export default function CellSensors ({
  options: [ target ],
  onFilter,
}) {
  if (!target) {
    return '-'
  }

  return (
    <div className="d-flex align-items-center me-2 min-w-0">
      <button
        type="button"
        className="btn btn-sm p-0 border-0 me-2"
        onClick={() => onFilter?.({ sensorId: target.id })}
      >
        <Icon
          name="fa--search"
        />
      </button>

      <span className="text-truncate">
        {target.displayName}
      </span>
    </div>
  )
}

CellSensors.propTypes = {
  options: PropTypes.array.isRequired,
  onFilter: PropTypes.func,
}

CellSensors.defaultProps = {
  onFilter: undefined,
}
