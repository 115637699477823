import classnames from 'classnames'
import { FormSection, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { StreamJob } from '@wiz/store'
import Tree from '@/components/Form/Tree/Tree'
import Link from '@/components/Link'

function TreeContent ({ data }) {
  const intl = useIntl()
  const { name, payload, nestingLevel } = data

  if (StreamJob.is(payload)) {
    return (
      <div className="d-flex align-items-center">
        <Icon
          title={payload.active ? intl.t('form.fields.active') : undefined}
          name="fa--circle"
          className={classnames('me-2', {
            'text-primary': payload.active,
            'text-secondary': !payload.active,
          })}
        />
        <Link
          name="stream-jobs-view"
          params={{ id: payload.id }}
          className="text-reset text-truncate min-w-0"
        >
          {intl.t(name)}
        </Link>
      </div>
    )
  }

  return nestingLevel > 0 ? intl.t(name) : (
    <span className="text-secondary fs-5 lh-1">
      {intl.t(name)}
      &nbsp;
      (
      {payload.count}
      )
    </span>
  )
}

export default function SectionUsage ({ usage }) {
  const intl = useIntl()
  return (
    <FormSection
      className="flex-fill d-flex flex-column min-h-0"
      classNameContent="flex-fill d-flex min-h-0"
      description={intl.t('alert.sections.usageDescr')}
    >
      <Tree
        className="flex-fill min-h-0"
        options={usage}
        Content={TreeContent}
        isOpenByDefault={usage.length <= 1}
      />
    </FormSection>
  )
}
