import { withObservables } from '@wiz/components'
import observeDeviceCommands from '@/utils/observeDeviceCommands'

const SortByFields = {
  createdAt: 'createdDate',
  updatedAt: 'updatedDate',
  status: 'status',
}

const enhanceData = withObservables([ 'filters' ], ({ filters }) => {
  const limit = filters.pageSize + 1
  const offset = (filters.page - 1) * filters.pageSize
  return {
    items: observeDeviceCommands({
      dateFrom: filters.dateFrom,
      dateTo: filters.dateTo,
      deviceId: filters.deviceId,
      twinId: filters.twinId,
      streamJobId: filters.streamJobId,
      sensorId: filters.sensorId,
      sortBy: SortByFields[filters.sortBy],
      sortDir: filters.sortDir,
      limit,
      offset,
      withStepRequest: false,
      withDateDuration: false,
    }),
  }
})

export default enhanceData
