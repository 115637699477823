import { useState, useEffect } from 'react'
import { of as of$ } from 'rxjs'
import { withObservables, withProps, useDidUpdate } from '@wiz/components'
import observeSensorValue from '@/utils/observeSensorValue'
import Component from '../components/Gauge'

const enhanceValue = withObservables([
  'sensor',
  'hidden',
], ({
  sensor,
  hidden,
}) => ({
  data: hidden ? of$(null) : observeSensorValue(sensor.id),
}))

const enhanceProps = withProps(({
  data,
  sensor,
}) => {
  const [ value, setValue ] = useState(null)
  const [ prevValue, setPrevValue ] = useState(null)

  useEffect(() => {
    if (data) {
      setPrevValue(value)
      setValue(data)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ data ])

  useDidUpdate(() => {
    setValue(null)
    setPrevValue(null)
  }, [ sensor.hardwareId ])

  return {
    data: data || value,
    prevData: prevValue,
  }
})

export default enhanceValue(
  enhanceProps(Component),
)
