import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'

const enhanceData = withObservables([ 'filters', 'blocks', 'items' ], ({ filters, blocks, items }) => {
  if (items) {
    return { items }
  }

  const twinIds = blocks?.map(({ id }) => (id))

  let query = dbProvider.database.collections.get('experiments').query()

  if (twinIds?.length) {
    query = query.extend(Q.where('twin_id', Q.oneOf(twinIds)))
  }

  if (filters.search) {
    const searchSanitize = Q.sanitizeLikeString(filters.search)
    query = query.extend(Q.or(
      Q.where('id', filters.search),
      Q.where('name', Q.like(`%${searchSanitize}%`)),
    ))
  }

  if (filters.boardId) {
    query = query.extend(Q.where('board_id', filters.boardId))
  }

  if (filters.twinId) {
    query = query.extend(Q.where('twin_id', filters.twinId))
  }

  if (filters.type) {
    query = query.extend(Q.where('type', filters.type))
  }

  if (filters.status) {
    query = query.extend(Q.where('status', filters.status))
  }

  return {
    items: query.observeWithColumns([ 'updated_at' ]),
  }
})

export default enhanceData
