import { map } from 'rxjs/operators'
import { orderBy, snakeCase, toArrayValue } from '@wiz/utils'
import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'

export default withObservables([
  'search',
  'selectedOnly',
  'value',
  'withBlockId',
  'keyName',
], ({
  search,
  selectedOnly,
  value,
  withBlockId,
  keyName = 'id',
}) => {
  let query = dbProvider.database.collections.get('stream_jobs').query()

  if (search) {
    const sanitizeSearch = Q.sanitizeLikeString(search)
    query = query.extend(Q.or(
      Q.where('name', Q.like(`%${sanitizeSearch}%`)),
      Q.where('description', Q.like(`%${sanitizeSearch}%`)),
    ))
  }

  if (withBlockId) {
    query = query.extend(Q.where('block_id', Q.notEq(null)))
  }

  if (selectedOnly) {
    query = query.extend(Q.where(snakeCase(keyName), Q.oneOf(toArrayValue(value))))
  }

  const observe = selectedOnly ?
    query.observeWithColumns([ 'updated_at' ]) :
    query.observe()

  return {
    options: observe
      .pipe(
        map(items => (
          orderBy(items, [ item => String(item.name).toLowerCase() ], [ 'asc' ])
        )),
      ),
  }
})
