import { Controller, useFormContext } from 'react-hook-form'
import {
  FormControl,
  FormField,
  FormFieldInline,
  FormSection,
} from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import Select from '@/components/Form/Select'
import SelectTwin from '@/components/Form/SelectTwin'
import SelectSensor from '@/components/Form/SelectSensor'
import StreamJobList from '@/hoc/StreamJobList'

export default function Section ({ scope, title, description }) {
  const intl = useIntl()
  const {
    formState: { errors },
    watch,
  } = useFormContext()
  const sourceTypes = watch(`${scope}.sourceTypes`)

  return (
    <FormSection
      title={title}
      description={description}
    >
      <FormField
        label={intl.t('widgets.events.form.fields.statuses')}
        description={intl.t('widgets.events.form.fields.statusesDescr')}
        errors={get(errors, `${scope}.statuses`)}
      >
        <FormControl
          type="select"
          name={`${scope}.statuses`}
          placeholder={intl.t('events.form.fields.statusAllPlaceholder')}
          options={consts.EventStatuses.map(id => ({
            id,
            name: intl.t(`enum.eventStatus.${id}`),
          }))}
        />
      </FormField>

      <FormField
        label={intl.t('widgets.events.form.fields.sourceTypes')}
        description={() => (
          <>
            <div>{intl.t('widgets.events.form.fields.sourceTypesDescr')}</div>
            <b>
              {do {
                if (sourceTypes === 'auto') {
                  intl.t('widgets.events.form.fields.sourceTypesAutoDescr')
                } else if (sourceTypes === 'manual') {
                  intl.t('widgets.events.form.fields.sourceTypesManualDescr')
                }
              }}
            </b>
          </>
        )}
        errors={get(errors, `${scope}.sourceTypes`)}
      >
        <FormControl
          type="select"
          name={`${scope}.sourceTypes`}
          placeholder={intl.t('events.form.fields.sourceAllPlaceholder')}
          options={consts.EventSources.map(id => ({
            id,
            name: intl.t(`enum.eventSource.${id}`),
          }))}
        />
      </FormField>

      <FormField
        label={intl.t('widgets.events.form.fields.streamJobId')}
        description={intl.t('widgets.events.form.fields.streamJobIdDescr')}
        errors={get(errors, `${scope}.streamJobId`)}
      >
        <Controller
          name={`${scope}.streamJobId`}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.streamJobPlaceholder')}
              ListSource={StreamJobList}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('widgets.events.form.fields.twinId')}
        description={intl.t('widgets.events.form.fields.twinIdDescr')}
        errors={get(errors, `${scope}.twinIds`)}
      >
        <Controller
          name={`${scope}.twinIds`}
          render={({ field, fieldState }) => (
            <SelectTwin
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.twinPlaceholder')}
              multiselect
            />
          )}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('events.form.fields.includeChildren')}
        description={intl.t('events.form.fields.includeChildrenDescr')}
        errors={get(errors, `${scope}.includeChildEvents`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.includeChildEvents`}
        />
      </FormFieldInline>

      <FormField
        label={intl.t('form.fields.dataPoints')}
        description={intl.t('widgets.events.form.fields.sensorIdsDescr')}
        errors={get(errors, `${scope}.sensorIds`)}
      >
        <Controller
          name={`${scope}.sensorIds`}
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorsPlaceholder')}
              multiselect
              excludeBusinessTypes={consts.ExcludedBusinessTypes}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('widgets.events.form.fields.eventTypes')}
        description={intl.t('widgets.events.form.fields.eventTypesDescr')}
        errors={get(errors, `${scope}.eventTypes`)}
      >
        <FormControl
          type="select"
          name={`${scope}.eventTypes`}
          placeholder={intl.t('events.form.fields.typeAllPlaceholder')}
          options={consts.EventTypes.map(id => ({
            id,
            name: intl.t(`enum.eventType.${id}`),
          }))}
        />
      </FormField>

    </FormSection>
  )
}
