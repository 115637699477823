import { useMemo } from 'react'
import { withProps } from '@wiz/components'
import { castArray, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default withProps(({
  selectedOnly,
  types,
  value,
  withSensors,
  withConnections,
}) => {
  const intl = useIntl()
  const hasTypes = !!types?.length
  const currentWithSensors = withSensors ?? (!hasTypes || types.includes('sensor'))

  const options = useMemo(() => {
    let items = consts.TwinTypes.map(id => ({
      id,
      name: intl.t(`enum.twinType.${id}`),
    }))

    if (types?.length) {
      items = items.filter(item => types.includes(item.id))
    }

    if (currentWithSensors) {
      items.push({
        id: 'sensor',
        name: intl.t('form.fields.sensor'),
      })
    }

    if (withConnections && !items.find(item => item.id === consts.TwinType.Flow)) {
      items.push({
        id: consts.TwinType.Flow,
        name: intl.t(`enum.twinType.${consts.TwinType.Flow}`),
      })
    }

    if (selectedOnly) {
      const arrayValue = castArray(value)
      items = items.filter(item => arrayValue.includes(item.id))
    }

    return items
  }, [
    intl,
    selectedOnly,
    types,
    value,
    currentWithSensors,
    withConnections,
  ])

  return {
    options,
  }
})
