import { Controller, useFormContext } from 'react-hook-form'
import {
  FormSection,
  FormField,
  FormControl,
} from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import SelectTwin from '@/components/Form/SelectTwin'

export default function Section ({ scope, ...other }) {
  const intl = useIntl()
  const { register, formState: { errors } } = useFormContext()

  return (
    <FormSection {...other}>
      <input
        {...register(`${scope}.id`)}
        type="hidden"
      />

      <FormField
        label={intl.t('form.fields.type')}
        description={intl.t('events.form.fields.typeDescr')}
        errors={get(errors, `${scope}.type`)}
      >
        <FormControl
          type="select"
          name={`${scope}.type`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          placeholder="--"
          options={consts.EventTypes.map(id => ({
            id,
            name: intl.t(`enum.eventType.${id}`),
          }))}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('events.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (!str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value.length > 450) {
                return intl.t('form.errors.fieldMaxlen', { max: 450 })
              }
              return true
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.description')}
        description={intl.t('events.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.description`}
          rules={{
            maxLength: {
              value: 450,
              message: intl.t('form.errors.fieldMaxlen', { max: 450 }),
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.twin')}
        description={intl.t('events.form.fields.twinDescr')}
        errors={get(errors, `${scope}.twinId`)}
      >
        <Controller
          name={`${scope}.twinId`}
          render={({ field, fieldState }) => (
            <SelectTwin
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.twinPlaceholder')}
            />
          )}
        />
      </FormField>
    </FormSection>
  )
}
