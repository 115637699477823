import { useCallback, memo } from 'react'
import { dbProvider, User } from '@wiz/store'
import { withProps } from '@wiz/components'
import { useAuth } from '@/auth'
import Component from '@/components/Forms/UserCreate'

const enhanceProps = withProps(() => {
  const auth = useAuth()

  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareReplaceData(context, User, data.user)
    if (auth.checkAccessRead('AccessRole')) {
      await model.prepareReplaceAccessRoles(context, data.accessRoleIds)
    }
    if (auth.checkAccessRead('Twin')) {
      await model.prepareReplaceTwins(context, data.twins)
    }

    await dbProvider.batch(context)
  }, [ auth ])

  return {
    onSubmit,
  }
})

export default memo(enhanceProps(Component))
