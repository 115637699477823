import { Controller, useFormContext } from 'react-hook-form'
import {
  FormSection,
  FormField,
  FormFieldInline,
  FormCheckbox,
  FormControl,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { get } from '@wiz/utils'
import SelectSensor from '@/components/Form/SelectSensor'

export default function SectionGeneral () {
  const intl = useIntl()
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <FormSection>
      <input {...register('dataView.id')} type="hidden" />
      <input {...register('dataSource.id')} type="hidden" />

      <FormField
        label={intl.t('form.fields.dataPoint')}
        errors={get(errors, 'dataSource.sensorId')}
      >
        <Controller
          name="dataSource.sensorId"
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorsPlaceholder')}
            />
          )}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.color')}
        description={intl.t('widgets.histogram.form.fields.colorDescr')}
        errors={get(errors, 'dataView.userColor')}
      >
        <FormControl
          type="color"
          name="dataView.userColor"
          className="ms-2"
          clearable
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('form.fields.disabled')}
        errors={get(errors, 'dataView.checked')}
      >
        <Controller
          name="dataView.checked"
          render={({ field, fieldState }) => (
            <FormCheckbox
              {...field}
              invalid={fieldState.invalid}
              value={!field.value}
              className="ms-2"
              onChange={value => field.onChange(!value)}
            />
          )}
        />
      </FormFieldInline>
    </FormSection>
  )
}
