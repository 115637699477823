import {
  useCallback, useState, useMemo, useEffect,
  useRef,
} from 'react'
import xor from 'lodash/xor'
import union from 'lodash/union'
import difference from 'lodash/difference'
import {
  useDrag, Icon as LegacyIcon, withProps,
} from '@wiz/components'
import Icon from '@/shared/icon'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import {
  Q, dbProvider,
} from '@wiz/store'
import cx from 'classnames'
import { consts, toArrayValue } from '@wiz/utils'
import { useLocationQuery } from '@/router'
import useAppContext from '@/hooks/useAppContext'
import LabelList from '@/hoc/LabelList'
import CategorieList from '@/hoc/CategorieList'
import UnitList from '@/hoc/UnitList'
import BusinessTypeList from '@/hoc/BusinessTypeList'
import FilterSearch from '@/components/Form/FilterSearch'
import ListSensors from '@/components/Form/ListSensors'
import Select from '@/components/Form/Select'
import ListFilters from '@/components/Form/ListFilters'
import FormSensor from '@/containers/Forms/Sensor'
import FormSetPoint from '@/containers/Forms/Sensor/SetPoint'
import FormMeasurement from '@/containers/Forms/Sensor/Measurement'
import events from '@/utils/events'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import classes from './index.css'

const enhanceBlockSettings = withProps(() => {
  const { contextTwinId } = useGlobalExecute()
  const { selectedTwinIds } = useAppContext()
  const [ selectedTwin, setSelectedTwin ] = useState({})
  const activeTwinIds = selectedTwinIds.length ? selectedTwinIds : [ contextTwinId ]

  dbProvider.database.collections.get('twins')
    .query(Q.where('id', Q.oneOf(activeTwinIds)))
    .fetch()
    .then(items => setSelectedTwin(items[0]))

  return {
    selectedBlockSettings: selectedTwin,
  }
})

const SensorForms = {
  [consts.SensorBusinessType.SetPoint]: FormSetPoint,
  [consts.SensorBusinessType.Measurement]: FormMeasurement,
}

function AttachSensors ({
  businessType,
  selectedBlockSettings,
  onAttachSensors,
  onResetSelectedBlockSettings,
  setSelectedSensorsCount,
}) {
  const drag = useDrag()
  const intl = useIntl()
  const auth = useAuth()
  const query = useLocationQuery()
  const filtersRef = useRef()
  const btnFiltersRef = useRef()
  const [ value, setValue ] = useState([])
  const [ editId, setEditId ] = useState(null)
  const [ createSensor, setCreateSensor ] = useState(false)
  const [ isFiltersOpen, setIsFiltersOpen ] = useState(false)
  const { datapointFilters, setDatapointFilters } = useAppContext()
  const [ visibleOptions, setVisibleOptions ] = useState([])

  const handleActive = useCallback((data) => {
    const item = data?.payload ?? data
    return (
      (value && item.id === value) ||
      value?.includes?.(item.id)
    )
  }, [ value ])

  console.log('value:', value)
  const handleChange = useCallback((data) => {
    console.log('change:', data)
    const ids = toArrayValue(data).map(item => (item.payload || item).id)
    let nextValue

    if (data === null) {
      nextValue = []
    } else if (Array.isArray(data)) {
      nextValue = union(value, ids)
    } else {
      nextValue = xor(value, ids)
    }

    setValue(nextValue)
    setSelectedSensorsCount(nextValue.length)
  }, [ value ])

  const handleEdit = useCallback((id) => {
    setEditId(id)
  }, [])

  const handleDragStart = useCallback((data) => {
    console.log('dragin', data)
    console.log('dragin2', selectedBlockSettings)
    const ids = toArrayValue(data).map(item => (item.payload || item).id)
    drag.context.id = selectedBlockSettings?.id || data.id
    union(value, ids).forEach(id => drag.data.add(id))
    console.log('drag:', drag)
  }, [ drag, value, selectedBlockSettings ])

  const handleDragEnd = useCallback(async () => {
    drag.clear()
    setValue([])
    setSelectedSensorsCount(0)
  }, [ drag, setValue ])

  const FormComponent = (
    businessType &&
    consts.SensorBusinessTypesUserCreate.includes(businessType)
  ) ? SensorForms[businessType] : null

  const formDialogParams = useMemo(() => ({
    title: intl.t('sensors.titleCreate'),
    dataTestid: 'replaceSensorDialog',
  }), [ intl ])

  const handleClickOutside = (event) => {
    const popup = document.querySelectorAll('div[id^="popper"]')
    const isPopupNode = event.target.closest(popup[0])
    if (filtersRef.current && !filtersRef.current.contains(event.target) && !isPopupNode &&
      !event.target.contains(btnFiltersRef.current)) {
      setIsFiltersOpen(false)
    }
  }

  const toggleAllSelected = () => {
    const diff = difference(visibleOptions, value)

    if (diff.length) {
      setValue(union(value, visibleOptions))
    } else {
      setValue(difference(value, visibleOptions))
    }
  }

  const clearSelection = () => {
    setValue([])
    setSelectedSensorsCount(0)
  }

  const handleAttachSensors = () => {
    onAttachSensors(selectedBlockSettings, value)
    clearSelection()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ filtersRef ])

  useEffect(() => {
    const selectedOptions = visibleOptions.filter(val => value.includes(val))
    setSelectedSensorsCount(selectedOptions.length)
  }, [ value, visibleOptions ])

  const ExtraButtons = (
    <>
      <button
        type="button"
        className="btn btn-sm btn-fill-secondary-alt text-nowrap mb-1 me-2"
        // disabled={!value.length}
        onClick={toggleAllSelected}
      >
        <LegacyIcon name="wiz--other--clear-selection" width={12} height={12} />
      </button>

      <button
        type="button"
        className="btn btn-sm btn-fill-secondary-alt text-nowrap mb-1 me-2"
        onClick={() => setDatapointFilters({})}
      >
        <Icon name="faFilterSlash" />
      </button>

      <button
        ref={btnFiltersRef}
        type="button"
        className="btn btn-sm btn-fill-secondary-alt text-nowrap mb-1 me-2"
        onClick={() => setIsFiltersOpen(!isFiltersOpen)}
      >
        <Icon name="faFilter" className="me-2" />
        {intl.t('form.actions.filters')}
      </button>

      <button
        type="button"
        className="btn btn-sm btn-fill-secondary-alt text-nowrap mb-1"
        disabled={!value.length || !selectedBlockSettings}
        onClick={handleAttachSensors}
      >
        {intl.t('form.actions.attach')}
      </button>
    </>
  )

  const onCloseForm = useCallback(() => {
    setCreateSensor(false)
    onResetSelectedBlockSettings()
  }, [ onResetSelectedBlockSettings ])

  useEffect(() => {
    // const handleSensorsAttached = () => {
    //   setValue([])
    // }
    events.on('digitalTwin:sensorsAttached', handleDragEnd)

    return () => {
      events.removeListener('digitalTwin:sensorsAttached', handleDragEnd)
    }
  }, [ handleDragEnd ])

  const handleFilters = useCallback((data) => {
    setDatapointFilters(prev => ({
      ...prev,
      ...data,
    }))
  }, [])

  return (
    <>
      <FilterSearch
        onChange={handleChange}
        withSelectedOnly={false}
        withClearAll={false}
        postfix={ExtraButtons}
        listClassName="flex-column"
      >
        {(formData, refList) => (
          <>
            {isFiltersOpen ? (
              <div
                className={cx(classes.sensorFilters, 'd-flex flex-wrap')}
                ref={filtersRef}
              >
                <ListFilters>
                  {() => (
                    <>
                      <div className="me-1 mb-2" style={{}}>
                        <Select
                          placeholder={intl.t('form.fields.labelsPlaceholder')}
                          multiselect
                          ListSource={LabelList}
                          displayCount={1}
                          value={datapointFilters.labels}
                          onChange={labels => handleFilters({ labels })}
                          className="form-select-sm"
                        />
                      </div>

                      <div className="me-1 mb-2" style={{}}>
                        <Select
                          placeholder={intl.t('form.fields.categoryPlaceholder')}
                          // multiselect
                          ListSource={CategorieList}
                          value={datapointFilters.categories}
                          onChange={categories => handleFilters({ categories })}
                          className="form-select-sm"
                        />
                      </div>

                      <div className="me-1 mb-2" style={{}}>
                        <Select
                          placeholder={intl.t('form.fields.unitPlaceholder')}
                          // multiselect
                          ListSource={UnitList}
                          value={datapointFilters.units}
                          onChange={units => handleFilters({ units })}
                          className="form-select-sm"
                        />
                      </div>

                      <div className="me-1 mb-2">
                        <Select
                          placeholder={intl.t('sensors.form.fields.businessTypePlaceholder')}
                          ListSource={BusinessTypeList}
                          value={datapointFilters.businessType || ''}
                          onChange={businessType => handleFilters({ businessType })}
                          className="form-select-sm"
                        />
                      </div>

                    </>
                  )}
                </ListFilters>
              </div>
            ) : null}
            <ListSensors
              {...formData}
              ref={refList}
              className="flex-fill min-h-0"
              unusedOnly
              draggable
              keyName="id"
              value={value}
              hideDefaultFilters
              customLabels={datapointFilters.labels}
              customCategories={datapointFilters.categories}
              customUnits={datapointFilters.units}
              customBusinessTypes={datapointFilters.businessType}
              businessType={businessType}
              active={handleActive}
              onChange={handleChange}
              onEdit={handleEdit}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              setVisibleOptions={setVisibleOptions}
            />
          </>
        )}
      </FilterSearch>

      {/* <footer className="d-flex justify-content-end mx-3 my-2">
        <button
          type="button"
          className="btn btn-primary ms-2"
          disabled={!value.length || !selectedBlockSettings}
          onClick={() => onAttachSensors(selectedBlockSettings, value)}
        >
          {intl.t('form.actions.attach')}
        </button>
      </footer> */}

      {editId !== null ? (
        <FormSensor
          id={editId}
          onClose={() => setEditId(null)}
          dialog={{
            title: editId ? intl.t('sensors.titleUpdate') : intl.t('sensors.titleCreate'),
            dataTestid: 'replaceSensorDialog',
          }}
        />
      ) : null}

      {createSensor && FormComponent ? (
        <FormComponent
          onClose={onCloseForm}
          dialog={formDialogParams}
        />
      ) : null}
    </>
  )
}

export default enhanceBlockSettings(AttachSensors)
