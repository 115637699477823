import { useCallback, useState } from 'react'
import { dbProvider, Integration } from '@wiz/store'
import { withProps, withObservables } from '@wiz/components'
import { useRouter } from '@/router'
import useFilters from '@/hooks/useFilters'
import Component from '@/components/Integrations/List'
import enhanceIntegrations from './enhanceIntegrations'

function initFilters (query = {}) {
  return {
    sortBy: query.sortBy || 'alpha-down',
    filterBy: query.filterBy || null,
    followedBy: query.followedBy || null,
  }
}

const enhanceSettings = withObservables([], () => ({
  settings: dbProvider.observeSettings([
    'boardsFilters',
  ]),
}))

const enhanceProps = withProps(({ settings }) => {
  const router = useRouter()
  const [ editId, setEditId ] = useState(null)

  const { filters, onFilter, onResetFilter } = useFilters({
    initFilters,
    setSource: async (data) => {
      const context = dbProvider.createBatchContext()
      await dbProvider.prepareReplaceSetting(context, 'boardsFilters', data)
      await dbProvider.batch(context)
    },
  })

  const onRemove = useCallback(async (integration) => {
    await window.wizConfirm({ message: 'This will permanently delete the integration. Continue?' })
    const context = dbProvider.createBatchContext()
    await integration.prepareRemove(context)
    await dbProvider.batch(context)
  }, [])

  const onActivate = useCallback(async ({ id, isOn }) => {
    const context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareReplaceData(context, Integration, {
      id,
      isOn: !isOn,
    })

    await dbProvider.batch(context)
  }, [])

  return {
    editId,
    setEditId,
    filters,
    onFilter,
    onResetFilter,
    onRemove,
    onActivate,
  }
})

export default enhanceSettings(enhanceProps(enhanceIntegrations(Component)))
