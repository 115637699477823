import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react'
import merge from 'lodash/merge'
import { withContext } from '@wiz/components'
import {
  dbProvider,
  IExplorer,
  DataFilter,
  Explorer,
  Widget,
  StreamJob,
} from '@wiz/store'
import {
  clone,
  orderBy,
  toArrayValue,
} from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { useRouter } from '@/router'
import { useAuth } from '@/auth'
import events from '@/utils/events'
import DataExplorer from '@/context/DataExplorer'
import DataExplorerEnv from '@/context/DataExplorerEnv'

const colorsPalette = [
  '#7CFFB2', '#4992FF', '#FDDD60', '#FF6E76',
  '#9E7CFF', '#E992FF', '#FFA686', '#FFF0B5',
  '#D1E2EE', '#6FE7F7', '#91C778', '#7B7D88',
]

function setInitialContext (context) {
  return {
    ...context,
    dataFilter: context?.dataFilter || DataFilter.toJSON(),
    dataViews: orderBy(context?.dataViews || [], [ 'sort' ], [ 'asc' ])
      .map((item, idx) => ({ ...item, sort: idx * 10 })),
    dataSources: context?.dataSources || [],
    conditions: context?.conditions || [],
    eventSources: context?.eventSources || [],
  }
}

function useDataContext (initialData, onChange) {
  const [ currentContext, setCurrentContext ] = useState(setInitialContext(initialData))
  const updateContext = useCallback((data) => {
    const next = setInitialContext(data)
    setCurrentContext(next)
    onChange?.(next)
  }, [ onChange ])
  return [ currentContext, updateContext ]
}

export default withContext(DataExplorer, ({
  id,
  explorer,
  explorerContext,
  settings,
  globalSettings,
}) => {
  const intl = useIntl()
  const router = useRouter()
  const auth = useAuth()
  const refShouldUpdateContext = useRef(false)
  const { openExplorer } = useContext(DataExplorerEnv)
  const readOnly = !(
    auth.checkAccessUpdate(explorer) ||
    (!explorer && auth.checkAccessCreate('Explorer'))
  )
  const isWidget = Widget.is(explorer)
  const [ isContextUpdated, setIsContextUpdated ] = useState()
  const [ currentContext, setCurrentContext ] = useDataContext(explorerContext, () => {
    setIsContextUpdated({})
  })

  const toggleFullscreen = useCallback(async () => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareToggleSetting(context, 'explorerViewFullscreen')
    await dbProvider.batch(context)
  }, [])

  const toggleDateAutoapply = useCallback(async () => {
    const context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareToggleSetting(context, 'explorerDateAutoapply')
    await dbProvider.batch(context)
    return model.value
  }, [])

  const updateSplit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareReplaceSetting(context, 'explorerViewFullscreen', false)
    await dbProvider.prepareReplaceSetting(context, 'explorerViewSplit', data)
    await dbProvider.batch(context)
  }, [])

  const updateSplitSettings = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareReplaceSetting(context, 'explorerViewSplitSettings', data)
    await dbProvider.batch(context)
  }, [])

  const updateSplitInterval = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareReplaceSetting(context, 'explorerViewFullscreen', false)
    await dbProvider.prepareReplaceSetting(context, 'explorerViewSplitInterval', data)
    await dbProvider.batch(context)
  }, [])

  const updateContext = useCallback(async (data) => {
    setCurrentContext(merge({}, currentContext, data))
  }, [ isContextUpdated ])

  const toggleLegend = useCallback(async (name) => {
    setCurrentContext(merge({}, currentContext, {
      dataFilter: {
        viewSelectedLegend: {
          [name]: !(currentContext.dataFilter.viewSelectedLegend[name] ?? true),
        },
      },
    }))
  }, [ isContextUpdated ])

  const resizeLegend = useCallback(async (size) => {
    setCurrentContext(merge({}, currentContext, {
      dataFilter: {
        viewLegendSize: Number(size || 0),
      },
    }))
  }, [ isContextUpdated ])

  const changeViewZoom = useCallback(async (viewZoom) => {
    setCurrentContext(merge({}, currentContext, {
      dataFilter: { viewZoom },
    }))
  }, [ isContextUpdated ])

  const changeDataView = useCallback(async (data) => {
    const nextViews = toArrayValue(data)
      .reduce((out, item) => ({
        ...out,
        [item.id]: item,
      }), {})

    const dataViews = []

    for (const item of currentContext.dataViews) {
      if (nextViews[item.id]) {
        const nextView = nextViews[item.id]
        delete nextViews[item.id]
        const view = await IExplorer.createDataViewContext(
          dbProvider.database,
          { ...item, ...nextView },
        )
        dataViews.push(view)
      } else {
        dataViews.push(item)
      }
    }

    for (const item of Object.values(nextViews)) {
      const view = await IExplorer.createDataViewContext(
        dbProvider.database,
        item,
      )
      dataViews.push(view)
    }

    setCurrentContext({
      ...currentContext,
      dataViews,
    })
  }, [ isContextUpdated ])

  const removeDataView = useCallback(async (view) => {
    let { dataSources } = currentContext
    const {
      id,
      sort,
      parentId,
      sourceId,
    } = view

    const dataViews = currentContext.dataViews
      .filter(item => item.id !== id)
      .map(item => (item.parentId === id ? {
        ...item,
        sort,
        parentId,
      } : item))

    const eventSources = currentContext.eventSources
      .map(item => ({
        ...item,
        dataViews: item.dataViews.filter(dataViewId => dataViewId !== id),
      }))

    if (sourceId) {
      dataSources = dataSources.filter(item => item.id !== sourceId)
    }

    setCurrentContext({
      ...currentContext,
      dataViews,
      dataSources,
      eventSources,
    })
  }, [ isContextUpdated ])

  const changeDataSource = useCallback(async (data) => {
    const source = await IExplorer.createDataSourceContext(
      dbProvider.database,
      data,
    )

    const dataSources = currentContext.dataSources
      .map(item => (item.id === source.id ? source : item))

    const dataViews = []
    for (const item of currentContext.dataViews) {
      if (item.sourceId === source.id) {
        const view = await IExplorer.createDataViewContext(
          dbProvider.database,
          { ...item, source },
        )
        dataViews.push(view)
      } else {
        dataViews.push(item)
      }
    }

    const conditions = currentContext.conditions
      .reduce((out, item) => {
        const next = { ...item }
        if (next.inputDataSources) {
          next.inputDataSources = next.inputDataSources
            .map(ds => (ds.id === source.id ? source : ds))
        }
        if (next.outputDataSources) {
          next.outputDataSources = next.outputDataSources
            .map(ds => (ds.id === source.id ? source : ds))
        }
        out.push(next)
        return out
      }, [])

    setCurrentContext({
      ...currentContext,
      conditions,
      dataSources,
      dataViews,
    })
  }, [ isContextUpdated ])

  const removeDataSource = useCallback(async (source) => {
    const dataSources = currentContext.dataSources.filter(item => item.id !== source.id)
    const dataViews = currentContext.dataViews.filter(item => item.sourceId !== source.id)
    const conditions = currentContext.conditions
      .reduce((out, item) => {
        const next = { ...item }
        if (next.inputDataSources) {
          next.inputDataSources = next.inputDataSources.filter(ds => ds.id !== source.id)
        }
        if (next.outputDataSources) {
          next.outputDataSources = next.outputDataSources.filter(ds => ds.id !== source.id)
        }
        out.push(next)
        return out
      }, [])

    setCurrentContext({
      ...currentContext,
      conditions,
      dataSources,
      dataViews,
    })
  }, [ isContextUpdated ])

  const getViewColor = useCallback((dataViews) => {
    let colors = [ ...colorsPalette ]

    if (!dataViews.length) {
      return colors[0]
    }

    for (const view of dataViews) {
      colors = colors.filter(color => color !== view?.color)
    }

    if (!colors.length) {
      return null
    }

    return colors[0]
  }, [])

  const createDataSource = useCallback(async (data) => {
    let { dataViews, dataSources } = currentContext
    if (data === null) {
      const viewSources = dataViews.reduce((out, item) => (
        item.sourceId ? out.concat(item.sourceId) : out
      ), [])
      dataSources = dataSources.filter(item => (
        !viewSources.includes(item.id)
      ))
      dataViews = []
    } else {
      const source = await IExplorer.createDataSourceContext(
        dbProvider.database,
        data,
      )
      const view = await IExplorer.createDataViewContext(
        dbProvider.database,
        { sourceId: source.id, source },
      )

      const color = getViewColor(dataViews)
      if (color) {
        view.color = color
        view.userColor = color
        if (view?.source?.sensor) {
          view.source.sensor.color = color
        }
      }
      dataSources = dataSources.concat(source)
      dataViews = dataViews.concat(view)
    }

    setCurrentContext({
      ...currentContext,
      dataViews,
      dataSources,
    })
  }, [ isContextUpdated ])

  const dropDataView = useCallback(async (dropItem, targetItem, type) => {
    const items = []

    if (type === 'inner') {
      const sort = targetItem?.sort ?? dropItem?.sort
      let parentId = targetItem?.role === 'grid' ? targetItem.id : targetItem?.parentId

      if (!parentId) {
        const parentView = await IExplorer.createDataViewContext(
          dbProvider.database,
          { role: 'grid', sort },
        )

        items.push(parentView)
        parentId = parentView.id

        if (targetItem) {
          items.push({ ...targetItem, parentId })
        }
      }

      items.push({
        ...dropItem,
        sort,
        parentId,
      })
    } else if (type === 'before') {
      items.push({
        ...dropItem,
        sort: targetItem.sort - 1,
        parentId: dropItem.parentId !== targetItem.parentId ?
          targetItem.parentId :
          dropItem.parentId,
      })
    } else if (type === 'after') {
      items.push({
        ...dropItem,
        sort: targetItem.sort + 1,
        parentId: dropItem.parentId !== targetItem.parentId ?
          targetItem.parentId :
          dropItem.parentId,
      })
    }

    if (items.length) {
      await changeDataView(items)
    }
  }, [ changeDataView ])

  const changeCondition = useCallback(async (data) => {
    const nextConditions = toArrayValue(data)
      .reduce((out, item) => ({
        ...out,
        [item.id]: item,
      }), {})

    const conditions = []

    for (const item of currentContext.conditions) {
      if (nextConditions[item.id]) {
        const next = nextConditions[item.id]
        delete nextConditions[item.id]
        const condition = await IExplorer.createConditionContext(
          dbProvider.database,
          { ...item, ...next },
        )
        conditions.push(condition)
      } else {
        conditions.push(item)
      }
    }

    for (const item of Object.values(nextConditions)) {
      const condition = await IExplorer.createConditionContext(
        dbProvider.database,
        item,
      )
      conditions.push(condition)
    }

    setCurrentContext({
      ...currentContext,
      conditions: conditions.filter(item => (
        item.type ||
        conditions.some(i => i.groupId === item.id)
      )),
    })
  }, [ isContextUpdated ])

  const dropCondition = useCallback(async (dropItem, targetItem, type) => {
    const items = []

    if (type === 'inner') {
      const isGroup = currentContext.conditions.some(item => item.groupId === targetItem.id)
      let groupId = isGroup ? targetItem.id : targetItem.groupId

      if (!groupId) {
        const group = await IExplorer.createConditionContext(dbProvider.database)
        groupId = group.id
        items.push(group, { ...targetItem, groupId })
      }

      items.push({ ...dropItem, groupId })
    } else if (dropItem.groupId !== targetItem.groupId) {
      items.push({ ...dropItem, groupId: targetItem.groupId })
    }

    if (items.length) {
      await changeCondition(items)
    }
  }, [ isContextUpdated, changeCondition ])

  const removeCondition = useCallback(async (data) => {
    const conditions = currentContext.conditions
      .filter(item => item.id !== data.id)
      .map(item => (item.groupId === data.id ? { ...item, groupId: null } : item))

    setCurrentContext({ ...currentContext, conditions })
  }, [ isContextUpdated ])

  const createCondition = useCallback(async (data) => {
    const condition = await IExplorer.createConditionContext(
      dbProvider.database,
      data,
    )
    const conditions = currentContext.conditions.concat(condition)
    setCurrentContext({ ...currentContext, conditions })
  }, [ isContextUpdated ])

  const replaceEventSource = useCallback(async (data) => {
    const nextEventSources = toArrayValue(data)
      .reduce((out, item) => ({
        ...out,
        [item.id]: item,
      }), {})

    const eventSources = []

    for (const item of currentContext.eventSources) {
      if (nextEventSources[item.id]) {
        const next = nextEventSources[item.id]
        delete nextEventSources[item.id]
        const eventSource = await IExplorer.createEventSourceContext(
          dbProvider.database,
          { ...item, ...next },
        )
        eventSources.push(eventSource)
      } else {
        eventSources.push(item)
      }
    }

    for (const item of Object.values(nextEventSources)) {
      const eventSource = await IExplorer.createEventSourceContext(
        dbProvider.database,
        item,
      )
      eventSources.push(eventSource)
    }

    setCurrentContext({ ...currentContext, eventSources })
  }, [ isContextUpdated ])

  const removeEventSource = useCallback(async (data) => {
    const eventSources = currentContext.eventSources.filter(item => item.id !== data.id)
    setCurrentContext({ ...currentContext, eventSources })
  }, [ isContextUpdated ])

  const removeExplorer = useCallback(async (key) => {
    await window.wizConfirm({ message: 't/units.confirmDelete' })

    let model
    try {
      model = await dbProvider.database.collections.get('explorers').find(key)
    } catch (error) {
      model = await dbProvider.database.collections.get('widgets').find(key)
    }

    const context = dbProvider.createBatchContext()
    await model.prepareRemove(context)
    if (settings.explorerDefaultView === key) {
      await dbProvider.prepareReplaceSetting(context, 'explorerDefaultView', null)
    }
    await dbProvider.batch(context)

    if (id === key) {
      openExplorer()
    }
  }, [
    id,
    settings.explorerDefaultView,
    openExplorer,
  ])

  const saveContext = useCallback(async (data = {}) => {
    const explorerData = isWidget ? Widget.toJSON(explorer) : Explorer.toJSON(explorer)
    const batchContext = dbProvider.createBatchContext()
    const nextContext = { ...currentContext, ...data.context }
    const nextEthplorer = { ...explorerData, ...data.explorer }

    if (data.settings) {
      for (const name in data.settings) {
        if (Object.hasOwnProperty.call(data.settings, name)) {
          await dbProvider.prepareReplaceSetting(batchContext, name, data.settings[name])
        }
      }
    }

    let model
    if (nextEthplorer?.dashboardId) {
      model = await Widget.prepareReplaceData(batchContext, dbProvider.database, nextEthplorer)
    } else {
      model = await Explorer.prepareReplaceData(batchContext, dbProvider.database, nextEthplorer)
    }

    if (data.projects) {
      await model.prepareReplaceProjects(batchContext, data.projects)
    }

    if (!isWidget && data.twins) {
      await model.prepareReplaceTwins(batchContext, data.twins)
    }

    await model.prepareReplaceFilter(batchContext, nextContext.dataFilter)
    await model.prepareReplaceDataViews(
      batchContext,
      nextContext.dataViews,
      nextContext.dataSources,
    )
    await model.prepareReplaceConditions(batchContext, nextContext.conditions)
    await model.prepareReplaceEventSources(batchContext, nextContext.eventSources)

    batchContext.on('success', async () => {
      const successBatchContext = dbProvider.createBatchContext()
      await model.prepareReplaceDataSourceSettings(successBatchContext, nextContext.dataSources)
      return dbProvider.batch(successBatchContext)
    })

    try {
      if (await dbProvider.batch(batchContext)) {
        if (nextEthplorer.id) {
          // force update to update last updated time
          if (Explorer.is(model) && !batchContext.has(model)) {
            const touchContext = dbProvider.createBatchContext()
            await model.prepareTouch(touchContext)
            await dbProvider.batch(touchContext)
          }

          events.emit('app:notify', {
            type: 'success',
            title: 't/explorer.title',
            message: Widget.is(model) ?
              't/explorer.form.success.updateWidget' :
              't/explorer.form.success.updateView',
            duration: 2000,
          })
        } else {
          events.emit('app:notify', {
            type: 'success',
            title: 't/explorer.title',
            message: 't/explorer.form.success.createView',
            duration: 2000,
          })
        }

        if (explorerData.id !== model.id) {
          openExplorer(model.id)
        } else {
          refShouldUpdateContext.current = true
        }
      }
    } catch (error) {
      const message = Widget.is(model) ?
        intl.t('explorer.form.errors.saveWidget') :
        intl.t('explorer.form.errors.saveView')

      events.emit('app:notify', {
        type: 'error',
        title: 't/explorer.title',
        message,
      })
      throw error
    }
  }, [
    intl,
    explorer,
    isContextUpdated,
    openExplorer,
    isWidget,
  ])

  const dublicateContext = useCallback(() => {
    const nextContext = clone(currentContext)
    const dataSources = []
    const dataViews = []
    const conditions = []
    const eventSources = []
    const dataSourcesHash = {}
    const dataViewsHash = {}
    const conditionsHash = {}

    for (const item of nextContext.dataSources) {
      dataSourcesHash[item.id] = dbProvider.nextId()

      let nextSettings
      if (item.settings) {
        nextSettings = clone(item.settings)

        if (item.settings.formula) {
          const ports = {}
          // eslint-disable-next-line guard-for-in
          for (const type in item.settings.ports) {
            ports[type] = ports[type] || {}
            // eslint-disable-next-line guard-for-in
            for (const name in item.settings.ports[type]) {
              const data = item.settings.ports[type][name]
              ports[type][name] = {
                ...data,
                relDSPortId: dbProvider.nextId(),
                dataSource: data.dataSource ? {
                  ...data.dataSource,
                  id: dbProvider.nextId(),
                } : null,
              }
            }
          }
          nextSettings.ports = ports
        } else if (item.settings.script) {
          nextSettings.interfaces = item.settings.interfaces.map(data => ({
            ...data,
            relDSPortId: dbProvider.nextId(),
            dataSource: data.dataSource ? {
              ...data.dataSource,
              id: dbProvider.nextId(),
            } : null,
          }))
        }
      }

      dataSources.push({
        ...item,
        settings: nextSettings,
        id: dataSourcesHash[item.id],
      })
    }

    for (const item of nextContext.dataViews) {
      dataViewsHash[item.id] = dbProvider.nextId()
      dataViews.push({
        ...item,
        id: dataViewsHash[item.id],
        sourceId: dataSourcesHash[item.sourceId],
        source: dataSources.find(ds => ds.id === dataSourcesHash[item.sourceId]),
        conditions: item.conditions?.map(cond => ({ ...cond, id: dbProvider.nextId() })) ?? [],
      })
    }

    for (const item of dataViews) {
      item.parentId = dataViewsHash[item.parentId]
    }

    for (const item of nextContext.conditions) {
      conditionsHash[item.id] = dbProvider.nextId()
      conditions.push({
        ...item,
        id: conditionsHash[item.id],

        inputDataSources: item.inputDataSources?.map((ds) => {
          let source = dataSources.find(i => i.id === dataSourcesHash[ds.id])
          if (!source) {
            source = {
              ...ds,
              id: dbProvider.nextId(),
            }
          }
          return source
        }) ?? [],

        outputDataSources: item.outputDataSources?.map((ds) => {
          let source = dataSources.find(i => i.id === dataSourcesHash[ds.id])
          if (!source) {
            source = {
              ...ds,
              id: dbProvider.nextId(),
            }
          }
          return source
        }) ?? [],
      })
    }

    for (const item of conditions) {
      item.groupId = conditionsHash[item.groupId]
    }

    for (const item of nextContext.eventSources) {
      eventSources.push({
        ...item,
        id: dbProvider.nextId(),
        dataViews: item.dataViews
          ?.map(id => dataViewsHash[id])
          ?.filter(id => !!id) ?? [],
      })
    }

    return {
      ...nextContext,
      dataViews,
      dataSources,
      conditions,
      eventSources,
      dataFilter: {
        ...nextContext.dataFilter,
        id: null,
      },
    }
  }, [ isContextUpdated ])

  const copyContext = useCallback(async (data) => {
    await saveContext({
      ...data,
      explorer: {
        ...data.explorer,
        id: null,
      },
      context: dublicateContext(),
    })
  }, [ saveContext, dublicateContext ])

  const prepareSaveContextTo = useCallback(async (batchContext, model) => {
    const nextContext = dublicateContext()
    await model.prepareReplaceFilter(batchContext, nextContext.dataFilter)
    await model.prepareReplaceDataViews(
      batchContext,
      nextContext.dataViews,
      nextContext.dataSources,
    )
    await model.prepareReplaceConditions(batchContext, nextContext.conditions)
    await model.prepareReplaceEventSources(batchContext, nextContext.eventSources)

    batchContext.on('success', async () => {
      const successBatchContext = dbProvider.createBatchContext()
      await model.prepareReplaceDataSourceSettings(successBatchContext, nextContext.dataSources)
      return dbProvider.batch(successBatchContext)
    })
  }, [ dublicateContext ])

  const createStreamJob = useCallback(async () => {
    let model
    try {
      const context = dbProvider.createBatchContext()
      model = await StreamJob.prepareCreateFromExplorer(
        context,
        dbProvider.database,
        currentContext,
      )
      await dbProvider.batch(context)
    } catch (error) {
      events.emit('app:notify', {
        type: 'error',
        title: 't/streamJobs.titleCreate',
        rawMessage: intl.t(error.message),
      })
      throw error
    }

    if (model) {
      await window.wizConfirm({
        title: 't/streamJobs.titleCreate',
        message: 't/streamJobs.successCreate',
      })
      router.push({
        name: 'stream-jobs-view',
        params: { id: model.id },
      })
    }
  }, [ isContextUpdated, intl, router ])

  useEffect(() => {
    let timer
    if (settings.explorerAutosave && isContextUpdated && !readOnly) {
      timer = window.setTimeout(() => saveContext(), 1000)
    }
    return () => window.clearTimeout(timer)
  }, [ settings.explorerAutosave, saveContext, isContextUpdated, readOnly ])

  // remove and replace to lastValueFrom from rxjs 7
  useEffect(() => {
    let timer
    if (refShouldUpdateContext.current) {
      timer = window.setTimeout(() => {
        refShouldUpdateContext.current = false
        setCurrentContext(explorerContext)
      })
    }
    return () => {
      window.clearTimeout(timer)
    }
  }, [ setCurrentContext, explorerContext ])

  const context = useMemo(() => ({
    changeCondition,
    changeDataSource,
    changeDataView,
    changeViewZoom,
    copyContext,
    createCondition,
    createDataSource,
    createStreamJob,
    data: currentContext,
    dropCondition,
    dropDataView,
    explorer: isWidget ? Widget.toJSON(explorer) : Explorer.toJSON(explorer),
    readOnly,
    prepareSaveContextTo,
    removeCondition,
    removeDataSource,
    removeDataView,
    removeEventSource,
    removeExplorer,
    replaceEventSource,
    resizeLegend,
    saveContext,
    setCurrentContext,
    settings,
    globalSettings,
    toggleDateAutoapply,
    toggleFullscreen,
    toggleLegend,
    updateContext,
    updateSplit,
    updateSplitInterval,
    updateSplitSettings,
  }), [
    changeCondition,
    changeDataSource,
    changeDataView,
    changeViewZoom,
    copyContext,
    createCondition,
    createDataSource,
    createStreamJob,
    currentContext,
    dropCondition,
    dropDataView,
    explorer,
    readOnly,
    isWidget,
    prepareSaveContextTo,
    removeCondition,
    removeDataSource,
    removeDataView,
    removeEventSource,
    removeExplorer,
    replaceEventSource,
    resizeLegend,
    saveContext,
    setCurrentContext,
    settings,
    toggleDateAutoapply,
    toggleFullscreen,
    toggleLegend,
    updateContext,
    updateSplit,
    updateSplitInterval,
    updateSplitSettings,
  ])

  return context
})
