import { useMemo } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { wizataApi } from '@/api'
import { BusinessLabel as BusinessLabelModel } from '@wiz/store'
import { uuid } from '@wiz/utils'
import FormDialog from '@/components/Form/FormDialog'
import Section from './Section'
import Removed from '../Removed'
import Schema from './Schema'

export default function BusinessLabel ({
  id,
  businessLabel,
  dialog,
  onClose,
  onSubmit,
}) {
  const queryClient = useQueryClient()

  const { data: item, isLoading } = useQuery({
    queryKey: [ 'businessLabel', id ],
    queryFn: () => wizataApi.businessLabels.getById(id),
    enabled: !!id,
    staleTime: Infinity,
  })

  const {
    mutateAsync: mutateBusinessLabel,
  } = useMutation({
    mutationKey: [ 'mutateBusinessLabel' ],
    mutationFn: (data) => {
      if (!id) {
        return wizataApi.businessLabels.create({ ...data?.businessLabel, id: uuid() })
      }
      return wizataApi.businessLabels.update({ id, ...data?.businessLabel })
    },
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/businessLabels.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'businessLabel', id ] })
      queryClient.invalidateQueries({ queryKey: [ 'businessLabelsList' ] })
    },
  })

  const handleSubmit = async (data) => {
    await mutateBusinessLabel(data)
  }

  const defaultValues = useMemo(() => ({
    businessLabel: { ...item },
  }), [ item ])

  if (id && !item) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={handleSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      schema={Schema}
      dataTestid="businessLabelForm"
      dialog={dialog}
    >
      <Section
        scope="businessLabel"
      />
    </FormDialog>
  )
}
