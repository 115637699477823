import { memo, useCallback } from 'react'
import { withProps } from '@wiz/components'
import { consts } from '@wiz/utils'
import Component from '@/components/Forms/Sensor/SetPoint'
import enhanceSensor from './enhanceSensor'
import enhanceLabels from './enhanceLabels'
import enhanceProps from './enhanceProps'

const enhanceDefault = withProps(({ defaultValues }) => ({
  defaultValues: {
    ...defaultValues,
    businessType: consts.SensorBusinessType.SetPoint,
  },
}))

const enhanceSetPointProps = withProps(({ onSubmit }) => {
  const handleSubmit = useCallback(async (data) => {
    await onSubmit({
      ...data,
      sensor: {
        ...data.sensor,
        businessType: consts.SensorBusinessType.SetPoint,
      },
    })
  }, [ onSubmit ])

  return {
    onSubmit: handleSubmit,
  }
})

export default memo(
  enhanceDefault(
    enhanceSensor(
      enhanceLabels(
        enhanceProps(
          enhanceSetPointProps(Component),
        ),
      ),
    ),
  ),
)
