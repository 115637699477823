import { useFormContext, Controller } from 'react-hook-form'
import { FormField, FormControl, FormFieldInline } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import SelectIcon from '@/containers/Form/SelectIcon'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('categories.form.fields.name')}
        description={intl.t('categories.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
        />
      </FormField>

      <FormField
        label={intl.t('categories.form.fields.comment')}
        description={intl.t('categories.form.fields.commentDescr')}
        errors={get(errors, `${scope}.comment`)}
      >
        <FormControl
          name={`${scope}.comment`}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.color')}
        description={intl.t('twin.form.fields.colorDescr')}
        errors={get(errors, `${scope}.color`)}
      >
        <FormControl
          type="color"
          name={`${scope}.color`}
          clearable
        />
      </FormFieldInline>

      <FormField
        label={intl.t('form.fields.icon')}
        description={intl.t('twin.form.fields.iconDescr')}
        errors={get(errors, `${scope}.icon`)}
      >
        <Controller
          name={`${scope}.icon`}
          render={({ field, fieldState }) => (
            <SelectIcon
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormField>
    </>
  )
}
