import {
  forwardRef,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
} from 'react'
import classnames from 'classnames'
import { FormInputSearch } from '@wiz/components'
import { useIntl } from '@wiz/intl'

const FilterSearch = forwardRef(({
  autoFocus,
  children,
  withSelectedOnly = true,
  withClearAll = true,
  withSelectAll = false,
  withTwinFiltering = false,
  cleanList,
  postfix,
  prefix,
  listClassName,
  searchTestid,
  onChange,
  onFilter,
  onTwinFilter,
}, ref) => {
  const intl = useIntl()
  const refSearch = useRef()
  const refList = useRef()
  const [ keyPrefix ] = useState(String(Date.now()).slice(-5))
  const [ search, setSearch ] = useState('')
  const [ selectedOnly, setSelectedOnly ] = useState(false) // !isEmpty(value)

  const handleChangeSearch = useCallback((value) => {
    setSearch(value)
    onFilter?.({ search: value })
  }, [ onFilter ])

  const onResetFilter = useCallback(() => {
    setSelectedOnly(false)
    refSearch.current.reset()
  }, [])

  useImperativeHandle(ref, () => ({
    reset () {
      onResetFilter()
    },
  }), [ onResetFilter ])

  return (
    <>
      {cleanList ? null : (
        <div className="d-flex flex-wrap mt-2 mb-1 mx-3">
          <div
            className={classnames('flex-fill mb-1', {
              'me-2': !!(withSelectedOnly || withClearAll || withSelectAll || postfix),
            })}
          >
            <FormInputSearch
              ref={refSearch}
              size="sm"
              testid={searchTestid}
              placeholder={intl.t('form.actions.searchPlaceholder')}
              autoFocus={autoFocus}
              onChange={handleChangeSearch}
            />
          </div>
          {prefix}
          {withSelectedOnly ? (
            <div className="d-inline-flex mb-1 me-1">
              <input
                type="checkbox"
                className="btn-check"
                id={`selected${keyPrefix}`}
                autoComplete="off"
                checked={selectedOnly}
                onChange={() => setSelectedOnly(!selectedOnly)}
              />
              <label
                className="btn btn-sm btn-fill-secondary-alt"
                htmlFor={`selected${keyPrefix}`}
              >
                {intl.t('form.actions.selected')}
              </label>
            </div>
          ) : null}

          {withClearAll && onChange ? (
            <button
              type="button"
              className="btn btn-sm btn-fill-secondary-alt text-nowrap mb-1 me-1"
              onClick={() => onChange?.(null)}
            >
              {intl.t('form.actions.clearAll')}
            </button>
          ) : null}

          {withSelectAll && onChange ? (
            <button
              type="button"
              className="btn btn-sm btn-fill-secondar-alt text-nowrap mb-1 me-1"
              onClick={() => {
                const visible = refList.current?.getVisible?.() ?? []
                onChange?.(visible)
              }}
            >
              {intl.t('form.actions.selectVisible')}
            </button>
          ) : null}

          {withTwinFiltering ? (
            <button
              type="button"
              className={classnames('btn btn-sm btn-fill-secondary-alt text-nowrap mb-1 me-1', {
              })}
              onClick={onTwinFilter}
            >
              {intl.t('form.actions.filterByTwin')}
            </button>
          ) : null}
          {postfix}
        </div>
      )}

      <div className={classnames('position-relative d-flex flex-fill min-h-0', listClassName)}>
        {children({
          search,
          selectedOnly,
          onResetFilter,
        }, refList)}
      </div>
    </>
  )
})

export default FilterSearch
