import { useQuery } from '@tanstack/react-query'
import { useAuth } from '@/auth'
import { useLocationQuery } from '@/router'
import { wizataApi } from '@/api'
import { appEnv } from '@/config'
import Loading from '@/shared/loading'

// FIXME: use env url
// const URL = 'https://docs.wizata.com'
const DEV_URL = 'dev.onwizata.com'
const HUB_URL = 'https://hub.onwizata.com/redirect_docs'
const DS_API_RECENT_URL = 'https://dev-wizard.onwizata.com'

const AuthDocsRedirect = () => {
  const auth = useAuth()
  const query = useLocationQuery()

  const { hostname } = new URL(appEnv.DS_API_RECENT_URL || DS_API_RECENT_URL)
  const origin = window.location.host.endsWith(9000) ? DEV_URL : window.location.host

  const config = {
    name: auth.currentUser.name || `${auth.currentUser.firstName} ${auth.currentUser.lastName}`,
    email: auth.currentUser.email,
    version: '11.0',
    domain: hostname,
  }

  const {
    isError,
    isLoading,
  } = useQuery({
    queryKey: [ 'auth-docs', config ],
    queryFn: () => wizataApi.whoami.docs(config),
    enabled: !!auth?.currentUser && !!config?.email,
    staleTime: Infinity,
    onSuccess: (res) => {
      let redirectUrl = `${HUB_URL}?auth_token=${res}&domain=${origin}`
      if (query.redirect) {
        redirectUrl += `&redirect=${query.redirect}`
      }
      window.location.href = redirectUrl
    },
  })
  if (isLoading) {
    return <Loading />
  }
  return (
    <div className="m-auto">
      {isError ? 'api call error occured, please contact administrator' : 'You will be redirected in a second'}
    </div>
  )
}

export default AuthDocsRedirect
