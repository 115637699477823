/* eslint-disable no-unused-expressions */
import { useRef } from 'react'
import { DateTime } from 'luxon'
import classnames from 'classnames'
import {
  Icon,
  DropdownColor,
  OverflowCenter,
  FormCheckbox,
} from '@wiz/components'
import { duration, consts } from '@wiz/utils'
import { DataSource } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import { SensorValidityDefault } from '@/config'
import ButtonCopy from '@/components/Form/ButtonCopy'
import classes from './index.css'

export default function ItemContent ({
  search,
  data,
  context,
}) {
  const refColor = useRef()
  const intl = useIntl()

  const {
    onAction,
  } = context
  const {
    name,
    payload: { source, view, error },
  } = data
  const sensor = source?.sensor
  const isIndeterminate = onAction('isIndeterminate', view.id)
  const canChangeView = onAction('canChangeView')
  const canChangeSource = onAction('canChangeSource')
  const canRemoveView = onAction('canRemoveView')
  const readOnly = onAction('readOnly')

  return (
    <>
      {canChangeView && !readOnly ? (
        <FormCheckbox
          className="me-2"
          checked={isIndeterminate ? null : view.checked}
          onChange={(checked) => {
            onAction('changeChecked', { view, checked })
          }}
        />
      ) : null}

      {view.role === 'grid' ? (
        <Icon
          name="fa--th"
          className="me-2"
        />
      ) : null}

      {error ? (
        <Icon
          name="fa--exclamation-circle"
          className="me-2 text-danger"
          title={error.message}
        />
      ) : null}

      {canChangeView && view.color ? (
        <>
          <button
            ref={refColor}
            type="button"
            className="btn p-0 me-2"
            style={{ color: view.color }}
          >
            <Icon name="fa--circle" size="xs" />
          </button>

          {readOnly ? null : (
            <DropdownColor
              disabled={source?.disabled}
              autoclose
              arrow
              mini
              target={refColor}
              value={view.color}
              onChange={(userColor) => {
                onAction('changeView', { ...view, userColor })
              }}
            />
          )}
        </>
      ) : null}

      <div className="d-flex align-items-center min-w-0 flex-fill">
        <span className="d-flex align-items-center min-w-0 hover-toggle position-relative">
          <OverflowCenter value={name} mark={search} />

          {sensor?.hardwareId ? (
            <ButtonCopy
              className={classnames(classes.actions, 'hover-inline-flex position-absolute end-0')}
              value={sensor.hardwareId}
              title={intl.t('message.copy.hardwareId', { value: sensor.hardwareId })}
            />
          ) : null}
        </span>
      </div>

      <div className="d-flex align-items-center flex-shrink-0 min-w-0 overflow-x-none ms-1">
        {source?.timeShift ? (
          <span
            className="badge bg-light text-secondary ms-1"
            title={intl.t('explorer.form.fields.timeShift')}
          >
            {duration(source.timeShift)}
          </span>
        ) : null}

        {sensor?.unit?.name ? (
          <span className="badge bg-light text-secondary ms-1">
            {sensor.unit.name}
          </span>
        ) : null}

        {Number.isFinite(sensor?.validity) && sensor.validity !== SensorValidityDefault ? (
          <span
            className="badge bg-light text-secondary ms-1"
            title={intl.t('form.fields.validity')}
          >
            {duration(sensor.validity * 1000)}
          </span>
        ) : null}

        {/* {sensor?.batchDataSource ? (
          <Icon
            className="ms-1"
            title={intl.t('sensors.form.fields.batchDataSourceTitle')}
            name="batch"
          />
        ) : null} */}

        {sensor?.source === 'virtual' ? (
          <Icon
            className="ms-1"
            name="fa--puzzle-piece"
          />
        ) : null}

        {source?.dateFrom || source?.dateTo ? (
          <Icon
            className="ms-1"
            title={do {
              if (source.dateFrom && source.dateTo) {
                `from ${DateTime
                  .fromMillis(source.dateFrom)
                  .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)} to ${DateTime
                  .fromMillis(source.dateTo)
                  .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}`
              } else if (source.dateFrom) {
                `from ${DateTime
                  .fromMillis(source.dateFrom)
                  .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}`
              } else if (source.dateTo) {
                `to ${DateTime
                  .fromMillis(source.dateTo)
                  .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}`
              }
            }}
            name="fa--clock"
          />
        ) : null}

        {do {
          if (source?.type === consts.DataSourceType.Formula) {
            <Icon
              className="ms-1"
              name="fa--function"
            />
          } else if (DataSource.hasCode(source)) {
            <Icon
              className="ms-1"
              title={intl.t('form.fields.transformationCode')}
              name="fa--code"
            />
          }
        }}

        {view.conditions?.length ? (
          <span
            className="d-flex align-items-center text-secondary ms-1"
            title={intl.t('form.sections.horizontalConditions')}
          >
            <small>{view.conditions.length}</small>
            <Icon name="fa--stream" />
          </span>
        ) : null}
      </div>

      {!readOnly && (canRemoveView || canChangeSource || canChangeView) ? (
        <div className="hover-toggle d-flex align-items-center ms-1 position-relative flex-shrink-0">
          <div className={classnames(classes.actions, 'hover-flex align-items-center position-absolute end-0')}>
            <div className="border-start rh-4 flex-shrink-0 mx-2" />

            {canRemoveView ? (
              <button
                type="button"
                className="btn btn-sm btn-fill-secondary"
                disabled={source?.disabled}
                data-testid="deleteButton"
                onClick={() => onAction('removeView', view)}
              >
                <Icon name="fa--trash-alt" />
              </button>
            ) : null}

            {canChangeSource && source ? (
              <button
                type="button"
                className="btn btn-sm ms-1 btn-fill-secondary"
                title={intl.t('form.actions.edit')}
                disabled={source.disabled}
                onClick={() => onAction('editSource', source)}
              >
                <Icon name="fa--edit" />
              </button>
            ) : null}

            {canChangeView ? (
              <button
                type="button"
                className="btn btn-sm ms-1 btn-fill-secondary"
                disabled={source?.disabled}
                data-testid="settingsButton"
                onClick={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  onAction('editViewMini', {
                    target: event.currentTarget,
                    view,
                  })
                }}
              >
                <Icon name="fa--paint-brush" />
              </button>
            ) : null}
          </div>

          <button
            type="button"
            className="btn btn-sm ms-1 btn-flat-primary"
            disabled={source?.disabled}
          >
            <Icon name="fa--ellipsis-h" />
          </button>
        </div>
      ) : null}
    </>
  )
}
