import {
  useState, useEffect, useCallback,
} from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { withProps, withPagination, withSort } from '@wiz/components'
import { wizataApi } from '@/api'
import { uuid } from '@wiz/utils'
import Component from '@/components/MLModels/Table'

const SortByFields = {
  createdDate: 'createdDate',
  updatedDate: 'updatedDate',
  key: 'key',
  identifier: 'identifier',
}

const SortDirection = {
  asc: 'Ascending',
  desc: 'Descending',
}

const enhanceProps = withProps(({
  filters,
  items,
}) => {
  if (items) {
    return {
      total: items.data?.length || 0,
      items: items.data || [],
      loading: items.isLoading,
    }
  }

  const limit = filters.pageSize + 1
  const offset = (filters.page - 1) * filters.pageSize

  const {
    data, isLoading, refetch, isFetching,
  } = useQuery({
    queryKey: [ 'mlmodelsList' ],
    queryFn: () => wizataApi.mlmodelsv2.getPagedList({
      pagination: {
        take: limit - 1,
        skip: offset,
      },
      filters: [
        // {
        //   type: consts.FilterType.Text,
        //   operationType: consts.FilterOperationType.Contains,
        //   value: filters.search || '',
        //   propertyName: 'name',
        // },
      ],
      sortingList: [
        {
          direction: SortDirection[filters.sortDir],
          propertyName: SortByFields[filters.sortBy],
        },
      ],
    }).then(res => (res ? res.map(model => ({ ...model, id: model.id || uuid() })) : undefined)),
    refetchOnWindowFocus: false,
    enabled: true,
  })

  return {
    total: data?.totalCount || data?.length || 0,
    items: data?.items || data || [],
    loading: isLoading || isFetching,
    refetch,
  }
})

export default enhanceProps(
  withSort()((Component)),
)
