import { useCallback, memo } from 'react'
import { dbProvider, Notebook } from '@wiz/store'
import { withProps } from '@wiz/components'
import { useAuth } from '@/auth'
import Component from '@/components/Forms/Notebook'
import enhanceNotebook from './enhanceNotebook'
import enhanceNotebookData from './enhanceNotebookData'

const enhanceProps = withProps(() => {
  const auth = useAuth()
  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()

    if (data.notebook.id) {
      const model = await dbProvider.prepareReplaceData(context, Notebook, {
        id: data.notebook.id,
        description: data.notebook.description,
      })
      if (auth.checkAccessRead('Project')) {
        await model.prepareReplaceProjects(context, data.projects)
      }
    } else {
      const model = await dbProvider.prepareReplaceData(context, Notebook, data.notebook)
      if (auth.checkAccessRead('Project')) {
        await model.prepareReplaceProjects(context, data.projects)
      }
      await model.prepareReplaceSensors(context, data.sensors)
    }

    await dbProvider.batch(context)
  }, [ auth ])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceNotebook(
    enhanceNotebookData(
      enhanceProps(Component),
    ),
  ),
)
