import { useCallback, memo } from 'react'
import { dbProvider } from '@wiz/store'
import { withProps } from '@wiz/components'
import Component from '@/components/Forms/SensorBulkEdit'

const enhanceProps = withProps(({ sensors }) => {
  const onSubmit = useCallback(async (data) => {
    if (!data.selected?.length) {
      return
    }

    const nextData = {}
    for (const name of data.selected) {
      nextData[name] = data.sensors[name]
    }

    const context = dbProvider.createBatchContext()
    for (const item of sensors) {
      // eslint-disable-next-line no-await-in-loop
      await item.prepareUpdateData(context, nextData)
      if (nextData.labels) {
        // eslint-disable-next-line no-await-in-loop
        await item.prepareReplaceLabels(context, nextData.labels)
      }
    }
    await dbProvider.batch(context)
  }, [ sensors ])

  return {
    onSubmit,
  }
})

export default memo(enhanceProps(Component))
