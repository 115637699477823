/* eslint-disable no-unused-expressions */
import { useEffect } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import {
  FormField,
  FormControl,
  FormInput,
  FormSwitch,
  FormInputNumber,
} from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const {
    formState: { errors },
    watch,
    getValues,
    clearErrors,
    setValue,
  } = useFormContext()

  const [
    dataType,
  ] = watch([
    `${scope}.dataType`,
  ])

  useEffect(() => {
    let payload = getValues(`${scope}.payload`)

    if (dataType === consts.DeviceCommandTemplateDataType.Number) {
      payload = Number(payload) || 0
    } else if (dataType === consts.DeviceCommandTemplateDataType.Bool) {
      payload = Boolean(payload)
    } else {
      payload = String(payload || '')
    }

    clearErrors(`${scope}.payload`)
    setValue(`${scope}.payload`, payload)
  }, [
    dataType,
    getValues,
    clearErrors,
    setValue,
    scope,
  ])

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('edge.commandTemplates.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
        />
      </FormField>

      <FormField
        label={intl.t('edge.commandTemplates.form.fields.topic')}
        description={intl.t('edge.commandTemplates.form.fields.topicDescr')}
        errors={get(errors, `${scope}.topic`)}
      >
        <FormControl
          name={`${scope}.topic`}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.type')}
        description={intl.t('edge.commandTemplates.form.fields.typeDescr')}
        errors={get(errors, `${scope}.type`)}
      >
        <FormControl
          type="select"
          name={`${scope}.type`}
          placeholder={intl.t('form.fields.typePlaceholder')}
          options={consts.DeviceCommandTemplateTypes.map(id => ({
            id,
            name: id,
          }))}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.dataType')}
        description={intl.t('edge.commandTemplates.form.fields.dataTypeDescr')}
        errors={get(errors, `${scope}.dataType`)}
      >
        <FormControl
          type="select"
          name={`${scope}.dataType`}
          placeholder={intl.t('form.fields.dataTypePlaceholder')}
          options={consts.DeviceCommandTemplateDataTypes.map(id => ({
            id,
            name: id,
          }))}
        />
      </FormField>

      <FormField
        label={intl.t('edge.commandTemplates.form.fields.payload')}
        description={intl.t('edge.commandTemplates.form.fields.payloadDescr')}
        errors={get(errors, `${scope}.payload`)}
      >
        <Controller
          name={`${scope}.payload`}
          render={({ field, fieldState }) => (do {
            if (dataType === consts.DeviceCommandTemplateDataType.Number) {
              <FormInputNumber
                {...field}
                invalid={fieldState.invalid}
                scale={8}
              />
            } else if (dataType === consts.DeviceCommandTemplateDataType.Bool) {
              <FormSwitch
                {...field}
                invalid={fieldState.invalid}
              />
            } else {
              <FormInput
                {...field}
                invalid={fieldState.invalid}
              />
            }
          })}
        />
      </FormField>
    </>
  )
}
