import {
  useRef,
  useMemo,
  useCallback,
  useState,
  createContext,
  useEffect,
} from 'react'
import { DateTime } from 'luxon'
import { useQuery, useIsMutating, useMutation } from '@tanstack/react-query'
import { useLocation, useLocationQuery, useRouter } from '@/router'
import { toUserNames } from '@/utils/users'
import { PagesMap } from '@/utils/consts'
import { wizataApi } from '@/api'
import { StaticRanges } from '@wiz/components'

export const AppContext = createContext()

export default function AppContextProvider ({ children }) {
  const [ filters, setFilters ] = useState({})
  const [ datapointFilters, setDatapointFilters ] = useState({})
  const [ linkMode, setLinkMode ] = useState(null)
  const [ selectedExecutionId, setSelectedExecutionId ] = useState()
  const [ selectedIds, setSelectedIds ] = useState() // selected items(ids) from current table
  const [ selectedTwinIds, setSelectedTwinIds ] = useState([])

  const defaultFiltersRef = useRef()

  const isExecuting = useIsMutating({ mutationKey: [ 'execute' ] })

  const loc = useLocation()
  const router = useRouter()
  const query = useLocationQuery()
  const [ ,,, currentPage, id ] = loc.pathname.split('/')

  const {
    data: execution,
    isLoading: isExecutionLoading,
    refetch: refetchExecution,
  } = useQuery({
    queryKey: [ 'execution', selectedExecutionId ],
    queryFn: () => wizataApi.executions.getById(selectedExecutionId).then(toUserNames),
    enabled: !!selectedExecutionId && !isExecuting,
    // staleTime: Infinity,
  })

  const handleSelectExecution = useCallback((executionId) => {
    setSelectedExecutionId(executionId)
  }, [])

  const handleFilters = useCallback((data) => {
    setFilters(prev => ({
      ...prev,
      ...data,
    }))
  }, [])

  const dateFilters = useCallback(() => {
    const def = {
      dateRange: 'Last Hour',
      dateFrom: DateTime.local().minus({ hours: 1 }).toMillis(),
      dateTo: DateTime.local().toMillis(),
    }
    if (!filters.dateRange && !filters.dateFrom && !filters.dateTo) {
      return def
    }

    if (!filters.dateRange && filters.dateFrom && filters.dateTo) {
      return {}
    }
    if (filters.dateRange) {
      const range = StaticRanges.find(r => r.label === filters.dateRange)

      if (range) {
        const [ dateFrom, dateTo ] = range.range()
        return { label: range.label, dateFrom, dateTo }
      }
    }
    return def
  }, [ filters ])

  const handleUpdateRefresh = useCallback(() => {
    // update dates
    const dates = dateFilters()
    handleFilters({ ...dates })
  }, [ dateFilters, handleFilters ])

  const handleFiltersReset = useCallback(() => {
    const nextFilters = { ...defaultFiltersRef.current }
    setFilters(nextFilters)
    if (query.search) {
      router.replace({ query: { ...query, search: '' } })
    }
  }, [ query, router ])

  const handleRowSelect = useCallback((data) => {
    let selected
    if (data?.length) {
      selected = data.map(item => item.id)
    }
    setSelectedIds(selected)
  }, [])

  const hasActiveFilters = !!Object.entries(filters).filter(([ key, value ]) => {
    if (defaultFiltersRef.current?.[key] === value) {
      return false
    }
    if (Array.isArray(value)) {
      return !!value.length
    }
    return !!value
  }).length

  const value = useMemo(() => ({
    filters,
    hasActiveFilters,
    onFilter: handleFilters,
    onFiltersReset: handleFiltersReset,
    datapointFilters,
    setDatapointFilters,
    selectedIds,
    selectedTwinIds,
    setSelectedTwinIds,
    onRowSelect: handleRowSelect,
    onRefresh: handleUpdateRefresh,
    experiments: {
      execution,
      isExecutionLoading,
      refetchExecution,
      onSelectExecution: handleSelectExecution,
    },
    linkMode,
    setLinkMode,
  }), [
    filters,
    hasActiveFilters,
    handleFilters,
    handleFiltersReset,
    datapointFilters,
    setDatapointFilters,
    execution,
    isExecutionLoading,
    refetchExecution,
    handleSelectExecution,
    selectedIds,
    selectedTwinIds,
    setSelectedTwinIds,
    handleRowSelect,
    linkMode,
    setLinkMode,
    handleUpdateRefresh,
  ])

  useEffect(() => {
    setSelectedIds()
    if (currentPage === PagesMap.Executions) {
      const dates = dateFilters()

      const defaultFilters = {
        ...dates,
      }
      if (!filters.experiments && filters.experiments !== 'isNull') {
        defaultFilters.experiments = 'isNull'
      }
      handleFilters({
        ...defaultFilters,
      })
      defaultFiltersRef.current = {
        ...defaultFilters,
        experiments: 'isNull',
      }
    }
  }, [ currentPage ])

  useEffect(() => {
    if (query?.executionTriggerId && !filters.executionTriggerId) {
      handleFilters({ executionTriggerId: query.executionTriggerId })
    }
  }, [ query?.executionTriggerId ])

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  )
}
