import { FormSelect, withProps } from '@wiz/components'

const Environments = [
  {
    id: 'azure_databricks',
    name: 'Azure Databricks',
  },
]

const enhanceData = withProps(() => ({
  options: Environments,
}))

const SelectNotebookEnvironment = enhanceData(FormSelect)

SelectNotebookEnvironment.displayName = 'SelectNotebookEnvironment'

export default SelectNotebookEnvironment
