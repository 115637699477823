import { useCallback, memo } from 'react'
import { dbProvider, Category } from '@wiz/store'
import { withProps } from '@wiz/components'
import Component from '@/components/Forms/Category'
import enhanceCategory from './enhanceCategory'

const enhanceProps = withProps(() => {
  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareReplaceData(context, Category, data.category)
    await dbProvider.batch(context)
  }, [])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceCategory(
    enhanceProps(Component),
  ),
)
