import { useMemo, useContext } from 'react'
import classnames from 'classnames'
import { VirtualTable, Pagination } from '@wiz/components'
import { markSearch } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import { hasBulkSensorOperations } from '@/containers/Form/BulkSensorOperations'
import { AppContext } from '@/pages/context'
import EditButton from '@/shared/editButton'
import CellTwin from './CellTwin'
import CellExternal from './CellExternal'

export default function Table ({
  items,
  filters,
  onFilter,
  onRemove,
  onEdit,
  onUnlink,
  refetch,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()

  const { onRowSelect } = useContext(AppContext)

  const columns = useMemo(() => [
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      width: 30,
      maxWidth: 30,
      className: 'justify-content-center text-nowrap',
      Cell: ({ cell, row }) => (auth.checkAccessUpdate('SectionDigitalTwinSensors') ? (
        <EditButton cell={cell} onEdit={onEdit} />
      ) : null),
    },
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      Cell: ({ cell, row }) => {
        const allowed = auth.checkAccessUpdate('SectionDigitalTwinSensors')

        return (
          <div
            className={classnames('min-w-0 text-truncate', {
              'link pointer': allowed,
            })}
            onClick={() => (allowed ? onEdit?.(row.original.id) : undefined)}
            aria-hidden
          >
            <span
              className={classnames({ 'text-muted': !cell.value })}
              dangerouslySetInnerHTML={{
                __html: cell.value ? markSearch(cell.value, filters.search) : intl.t('form.noname'),
              }}
            />

            {row.original.description ? (
              <div
                className="font-italic text-muted text-truncate small"
                dangerouslySetInnerHTML={{
                  __html: markSearch(row.original.description, filters.search),
                }}
              />
            ) : null}
          </div>
        )
      },
    },
    {
      Header: intl.t('sensors.form.fields.businessType'),
      accessor: 'businessType',
      disableResizing: true,
      minWidth: 80,
      width: 80,
      maxWidth: 80,
      Cell: ({ cell }) => (
        <span className="text-break small lh-1">
          {intl.t(`enum.sensorBusinessType.${cell.value}`)}
        </span>
      ),
    },
    {
      Header: intl.t('sensors.form.fields.inputMode'),
      accessor: 'inputMode',
      disableResizing: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      Cell: ({ cell }) => (
        <span className="text-break small lh-1">
          {cell.value}
        </span>
      ),
    },
    {
      Header: intl.t('sensors.form.fields.twin'),
      accessor: 'twinId',
      disableResizing: true,
      minWidth: 120,
      width: 120,
      maxWidth: 120,
      Cell: ({ cell }) => (
        <CellTwin
          twinId={cell.value}
        />
      ),
    },
    {
      Header: intl.t('form.fields.category'),
      accessor: 'categoryId',
      disableResizing: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      Cell: ({ row, cell }) => (
        <CellExternal
          id={cell.value}
          // list={pipelines}
          name="categoryId"
          collection="categories"
          row={row}
        />
      ),
    },
    {
      Header: intl.t('form.fields.unit'),
      accessor: 'unitId',
      disableResizing: true,
      minWidth: 80,
      width: 80,
      maxWidth: 80,
      Cell: ({ row, cell }) => (
        <CellExternal
          id={cell.value}
      // list={pipelines}
          name="unitId"
          collection="units"
          row={row}
        />
      ),
    },
    // {
    //   id: 'labels',
    //   Header: intl.t('form.fields.labels'),
    //   accessor: 'id',
    //   disableResizing: true,
    //   minWidth: 100,
    //   width: 100,
    //   maxWidth: 100,
    //   Cell: ({ row, cell }) => (
    //     <CellExternal
    //       id={cell.value}
    //       // list={pipelines}
    //       name="labelIds"
    //       page="labels"
    //       row={row}
    //     />
    //   ),
    // },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedDate',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 220,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.updatedById}
          // user={row.original.updatedById}
        />
      ),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdDate',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 220,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.createdById}
          // user={row.original.createdById}
        />
      ),
    },
  ], [
    auth,
    onEdit,
    filters,
    intl,
  ])

  return (
    <>
      <VirtualTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={(
          auth.checkAccessRemove('Sensor')
          // || hasBulkSensorOperations(auth)
        )}
        {...props}
        onSelect={onRowSelect}
      />

      <Pagination
        {...props}
      />
    </>
  )
}
