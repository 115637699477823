/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import QualityDataTemplateList from '@/hoc/QualityDataTemplateList'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'
import FormatDateTime from '@/containers/FormatDateTime'
import Observe from '@/containers/Observe'
import CellTemplate from './CellTemplate'
import Detail from './Detail'

const CellTemplateData = QualityDataTemplateList(CellTemplate)

export default function ViewModel ({
  id,
  title,
  qualityData,
  onClose,
  onFilter,
  onRemove,
  onEdit,
}) {
  const refDialog = useRef()
  const intl = useIntl()
  const auth = useAuth()

  const handleFilter = (filter) => {
    refDialog.current.close()
    onFilter?.(filter)
  }

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('quality.form.fields.registerAt'),
          value: () => (<FormatDateTime date={qualityData.registerAt} />),
        },
        {
          title: intl.t('quality.form.fields.approval'),
          value: () => (
            <Observe query={qualityData.qualityDataTemplate}>
              {({ data }) => (
                !data?.approval ?
                  intl.t('enum.qualityApproval.noneed') :
                  intl.t(`enum.qualityApproval.${qualityData.value}`)
              )}
            </Observe>
          ),
        },
        {
          title: intl.t('quality.form.fields.qualityTemplate'),
          value: () => (qualityData.qualityDataTemplateId ? (
            <CellTemplateData
              value={qualityData.qualityDataTemplateId}
              selectedOnly
              onFilter={handleFilter}
            />
          ) : '-'),
        },
      ],
    },

    {
      title: intl.t('form.sections.details'),
      value: () => (<Detail row={{ original: qualityData }} />),
    },
    {
      title: intl.t('form.fields.updated'),
      value: () => <UpdatedInfo date={qualityData.updatedAt} user={qualityData.updatedUser} />,
    },
    {
      title: intl.t('form.fields.created'),
      value: () => <UpdatedInfo date={qualityData.createdAt} user={qualityData.createdUser} />,
    },
  ]

  const actions = [
    ...(onRemove && auth.checkAccessRemove(qualityData) ? ([{
      title: intl.t('form.actions.remove'),
      name: 'remove',
      testid: 'removeButton',
      onClick: () => onRemove([ qualityData ]),
    }]) : []),

    ...(onEdit && auth.checkAccessUpdate(qualityData) ? ([{
      title: intl.t('form.actions.edit'),
      name: 'edit',
      testid: 'editButton',
      onClick: () => onEdit(id),
    }]) : []),
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      actions={actions}
      onClose={onClose}
    />
  )
}
