import {
  map,
} from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'
import { createLocation } from '@/router'
import Component from '@/components/Project/View'
import withTableActions from '@/containers/withTableActions'
import enhanceView from './enhanceView'

const enhanceProject = withObservables([ 'match' ], ({ match }) => {
  const projectObserver = dbProvider.database.collections.get('projects')
    .query(Q.where('id', String(match.params.id)))
    .observeWithColumns([ 'updated_at' ])
    .pipe(map(items => items[0]))

  return {
    project: projectObserver,
  }
}, {
  onError (error, { history, match }) {
    history.push(createLocation({
      name: 'projects-list',
      params: match.params,
    }))
  },
})

export default withTableActions()(
  enhanceProject(
    enhanceView(Component),
  ),
)
