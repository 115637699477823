import { Fragment } from 'react'
import classnames from 'classnames'
import { Icon } from '@wiz/components'
import Link from '@/components/Link'

export default function NavBar ({
  className,
  options,
  twinGraph,
}) {
  return (
    <div className={classnames('d-flex flex-fill align-items-center min-w-0', className)}>
      {options.map(item => (
        <Fragment key={item.id}>
          <Link
            className="text-muted text-truncate min-w-0"
            name="chart-view"
            params={{ id: item.id }}
          >
            {item.name}
          </Link>
          <Icon name="fa--chevron-right" size="sm" className="mx-2 text-muted" />
        </Fragment>
      ))}
      <div className="text-truncate h6 m-0">
        {twinGraph.name}
      </div>
    </div>
  )
}
