import { useRef, useEffect } from 'react'
import { Icon } from '@wiz/components'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'

export default function NotebookPreview ({
  id,
  notebook,
  preview,
  dialog,
  onClose,
}) {
  const refTarget = useRef()

  useEffect(() => {
    if (preview) {
      const iframe = document.createElement('iframe')
      iframe.className = 'flex-fill border-0'
      refTarget.current.appendChild(iframe)
      iframe.contentWindow.document.open()
      iframe.contentWindow.document.write(preview)
      iframe.contentWindow.document.close()
    }
  }, [ preview ])

  if (id && !notebook) {
    return <Removed />
  }

  return (
    <FormDialog
      onClose={onClose}
      dataTestid="notebookPreviewForm"
      dialog={dialog}
    >
      <div ref={refTarget} className="flex-fill d-flex">
        {!preview ? (
          <div className="position-center-fill">
            <Icon name="fa--spinner" spin size="2X" />
          </div>
        ) : null}
      </div>
    </FormDialog>
  )
}
