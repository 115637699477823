import { useState, useEffect } from 'react'
import { withProps, withPagination, withSort } from '@wiz/components'
import { wizataApi } from '@/api'
import Component from '@/components/Notification/Table'

const enhanceProps = withProps(({
  filters,
}) => {
  const [ loading, setLoading ] = useState(true)
  const [ items, setItems ] = useState([])
  const [ total, setTotal ] = useState(0)

  useEffect(() => {
    setLoading(true)

    const limit = filters.pageSize + 1
    const offset = (filters.page - 1) * filters.pageSize

    wizataApi.getNotifications({
      ...filters,
      limit,
      offset,
    })
      .then((data) => {
        setTotal(offset + data.length)
        setItems(data.slice(0, filters.pageSize))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [ filters ])

  return {
    total,
    items,
    loading,
  }
})

export default enhanceProps(
  withSort({ localData: false })(
    withPagination({ localData: false })(Component),
  ),
)
