import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Card, withResizeObserver } from '@wiz/components'
import { Widget as WidgetModel } from '@wiz/store'
import { consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { auth } from '@/auth'
import { useTheme } from '@/theme'
import Histogram from '../containers/Histogram'

const Widget = forwardRef(({
  widget,
  config,
  disabled,
  dimension,
  canUpdateDashboard,
  onAction,
}, ref) => {
  const intl = useIntl()
  const theme = useTheme()

  const actions = []
  if (canUpdateDashboard) {
    actions.push({ id: 'settings', name: intl.t('form.actions.settings'), icon: 'fa--cog' })
    if (auth.checkAccessCopy(widget)) {
      actions.push({ id: 'duplicate', name: intl.t('form.actions.duplicate'), icon: 'fa--clone' })
      actions.push({ id: 'multiDuplicate', name: intl.t('form.actions.multiDuplicate'), icon: 'fa--folder-plus' })
    }
    actions.push({ id: 'remove', name: intl.t('form.actions.remove'), icon: 'fa--trash-alt' })
  }

  return (
    <Card
      ref={ref}
      className="position-absolute-fill"
      classNameTitle={canUpdateDashboard ? 'userdrag' : undefined}
      title={widget.title || intl.t(widget.info.name)}
      description={widget.description}
      options={actions}
      onAction={onAction}
    >
      {do {
        if (
          config.dataViews.length &&
          (
            config.histogramType !== consts.WidgetHistogramType.Custom ||
            config.bins?.length
          )
        ) {
          (
            <Histogram
              config={config}
              theme={theme.echarts}
              dimension={dimension}
              disabled={disabled}
            />
          )
        } else if (canUpdateDashboard) {
          (
            <div className="d-flex align-items-center justify-content-center flex-fill">
              <button
                type="button"
                className="btn btn-flat-secondary"
                onClick={() => onAction({ id: 'settings' })}
              >
                {intl.t('widgets.configure')}
              </button>
            </div>
          )
        } else {
          (
            <div className="d-flex align-items-center justify-content-center flex-fill">
              {intl.t('widgets.notConfigured')}
            </div>
          )
        }
      }}
    </Card>
  )
})

Widget.propTypes = {
  widget: PropTypes.instanceOf(WidgetModel).isRequired,
  config: PropTypes.object,
  disabled: PropTypes.bool,
  canUpdateDashboard: PropTypes.bool,
  dimension: PropTypes.string,
  onAction: PropTypes.func.isRequired,
}

Widget.defaultProps = {
  disabled: false,
  canUpdateDashboard: false,
  dimension: undefined,
}

export default withResizeObserver(Widget)
