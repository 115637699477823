import { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { NotificationSheet, NotificationSheetTarget } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import ObservNotificationSheet from '@/containers/Forms/NotificationSheet/Observ'
import Section from '@/components/Forms/NotificationSheet/Section'
import SectionTargets from '@/components/Forms/NotificationSheet/SectionTargets'
import Select from '@/components/Form/Select'
import NotificationSheetList from '@/hoc/NotificationSheetList'

export default function SectionAlertSheet ({ scope, scopeTargets }) {
  const intl = useIntl()
  const { watch, setValue, clearErrors } = useFormContext()
  const id = watch(`${scope}.id`)

  const handleChange = useCallback(({ notificationSheet, notificationSheetTargets }) => {
    setValue(scope, NotificationSheet.toJSON(notificationSheet), { shouldDirty: true })
    setValue(scopeTargets, notificationSheetTargets.map(item => (
      NotificationSheetTarget.toJSON(item)
    )), { shouldDirty: true })
    clearErrors(scope)
    clearErrors(scopeTargets)
  }, [ setValue, scope, scopeTargets ])

  return (
    <>
      <ObservNotificationSheet
        id={id}
        onChange={handleChange}
      />

      <Section
        scope={scope}
        title={intl.t('alert.section.notificationSheet')}
        description={intl.t('alert.section.notificationSheetDescr')}
        registerId={false}
        addon={() => (
          <Controller
            name={`${scope}.id`}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                invalid={fieldState.invalid}
                ListSource={NotificationSheetList}
                placeholder={intl.t('form.fields.notificationSheetPlaceholder')}
                className="min-w-0 w-100 w-md-auto mt-2 mt-md-0 ms-md-3"
              />
            )}
          />
        )}
      />

      <SectionTargets
        scope={scopeTargets}
      />
    </>
  )
}
