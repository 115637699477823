import { withPagination, withSort } from '@wiz/components'
import Component from '@/components/EdgeDevice/TableConnections'
import enhanceDataConnections from './enhanceDataConnections'

export default enhanceDataConnections(
  withSort({
    defaultSortBy: 'clientId',
    defaultSortDir: 'desc',
  })(
    withPagination()(Component),
  ),
)
