import { Controller, useFormContext } from 'react-hook-form'
import {
  FormField,
  FormSection,
  FormControl,
  FormFieldInline,
} from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import SelectSensor from '@/components/Form/SelectSensor'

export default function Section ({ scope, title, description }) {
  const intl = useIntl()
  const {
    formState: { errors },
    watch,
  } = useFormContext()

  let businessType = watch(`${scope}.businessType`)
  if (!consts.SensorBusinessTypesInput.includes(businessType)) {
    businessType = undefined
  }

  return (
    <FormSection
      title={title}
      description={description}
    >
      <FormField
        label={intl.t('widgets.inputSensorValues.form.fields.businessType')}
        description={intl.t('widgets.inputSensorValues.form.fields.businessTypeDescr')}
        errors={get(errors, `${scope}.businessType`)}
      >
        <FormControl
          type="select"
          name={`${scope}.businessType`}
          placeholder="--"
          options={consts.SensorBusinessTypesInput.map(id => ({
            id,
            name: intl.t(`enum.sensorBusinessType.${id}`),
          }))}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.dataPoints')}
        description={intl.t('widgets.inputSensorValues.form.fields.sensorIdsDescr')}
        errors={get(errors, `${scope}.sensorIds`)}
      >
        <Controller
          name={`${scope}.sensorIds`}
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorsPlaceholder')}
              multiselect
              filters={{
                businessType,
                canInputValue: true,
              }}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.precision')}
        description={intl.t('widgets.inputSensorValues.form.fields.precisionDescr')}
        errors={get(errors, `${scope}.precision`)}
      >
        <FormControl
          type="number"
          name={`${scope}.precision`}
          min={0}
          max={10}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('widgets.inputSensorValues.form.fields.formModify')}
        description={intl.t('widgets.inputSensorValues.form.fields.formModifyDescr')}
        errors={get(errors, `${scope}.formModify`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.formModify`}
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('widgets.inputSensorValues.form.fields.showLastValues')}
        description={intl.t('widgets.inputSensorValues.form.fields.showLastValuesDescr')}
        errors={get(errors, `${scope}.showLastValues`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.showLastValues`}
        />
      </FormFieldInline>
    </FormSection>
  )
}
