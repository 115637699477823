import {
  useMemo, useState, useEffect,
} from 'react'
import { useQuery } from '@tanstack/react-query'
import {
  VirtualTable, Pagination, withPagination, withSort,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { auth } from '@/auth'
import { wizataApi } from '@/api'
import Icon from '@/shared/icon'
import useAppContext from '@/hooks/useAppContext'
import ChartsDrawer from '@/components/ChartsDrawer'
import { consts } from '@wiz/utils'
import withTableActions from '@/containers/withTableActions'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import useAuthComponents from '@/components/GrafanaDashboards/hooks'
import FormGrafanaDashboards from '@/components/Forms/GrafanaDashboards'
import CellExternalLink from './CellExternalLink'
import CellTwin from './CellTwin'
import EditButton from '../../shared/editButton'
import { useGlobalExecute } from '../../context/GlobalExecuteProvider'

const dashboardTypes = [
  {
    value: 'grafana',
    name: 'Grafana',
    id: 'grafana',
  },
  {
    value: 'dashboard',
    name: 'Dashboard',
    id: 'dashboard',
  },
  {
    value: 'streamlit',
    name: 'Streamlit',
    id: 'streamlit',
  },
]

const SortByFields = {
  createdDate: 'createdDate',
  updatedDate: 'updatedDate',
  name: 'name',
}

const SortDirection = {
  asc: 'Ascending',
  desc: 'Descending',
}

const Table = props => (
  <>
    <VirtualTable className="flex-fill mb-3" {...props} />
    <Pagination {...props} />
  </>
)

const TableComponent = withSort({ localData: false })(
  withPagination({ localData: false })(Table),
)

const DashboardComponentsList = ({
  id,
  filters,
  onFilter,
  onResetFilter,
  twinIds,
  templateId,
  embed,
  ...props
}) => {
  const [ editItem, setEditItem ] = useState(null)
  const { isReadOnly, isPersonalStrictly, isOrganizational } = useAuthComponents()

  const { filters: contextFilters, onRowSelect } = useAppContext()
  const { contextTwinId } = useGlobalExecute()

  const intl = useIntl()

  const {
    data: components, isLoading, refetch, isError, error,
  } = useQuery({
    queryKey: [ 'componentsList' ],
    queryFn: () => {
      const limit = filters.pageSize + 1
      const offset = (filters.page - 1) * filters.pageSize
      const filtersArr = [
        {
          name: 'name',
          type: consts.FilterType.Text,
          operationType: consts.FilterOperationType.Contains,
          value: filters.search,
        },
        {
          name: 'type',
          type: consts.FilterType.Enum,
          operationType: consts.FilterOperationType.Equals,
          value: contextFilters.dashboardType,
        },
        ...(contextFilters.labelId ? [{
          name: 'labelId',
          operationType: consts.FilterOperationType.Contains,
          type: consts.FilterType.Guid,
          values: [ contextFilters.labelId ],
        }] : []),
        ...((templateId || contextFilters.templateId) ? [{
          name: 'templateId',
          operationType: consts.FilterOperationType.Equals,
          type: (templateId || contextFilters.templateId) === 'null' ? consts.FilterType.Guid : consts.FilterType.Enum,
          canBeNull: (templateId || contextFilters.templateId) === 'null' ? true :
            !(templateId || contextFilters.templateId),
          ...((templateId || contextFilters.templateId) && (templateId || contextFilters.templateId) !== 'null') ?
            { value: (templateId || contextFilters.templateId) } : {},
        }] : []),
        ...((contextFilters.ownerId) ? [{
          name: 'ownerId',
          operationType: consts.FilterOperationType.Equals,
          type: (contextFilters.ownerId) === 'null' ? consts.FilterType.Guid : consts.FilterType.Enum,
          canBeNull: (contextFilters.ownerId) === 'null' ? true :
            !(contextFilters.ownerId),
          ...((contextFilters.ownerId) && (contextFilters.ownerId) !== 'null') ?
            { value: (contextFilters.ownerId) } : {},
        }] : []),
      ]
      const complexFilters = {}
      if (contextTwinId) {
        complexFilters.twinId = contextTwinId
        complexFilters.includeChildren = true
      }

      return wizataApi.dashboardsComponents.getList({
        ...complexFilters,
        pagination: {
          take: limit - 1,
          skip: offset || 0,
        },
        sortingList: [
          {
            direction: SortDirection[filters.sortDir],
            propertyName: SortByFields[filters.sortBy],
          },
        ],
        filters: filtersArr.map(({
          name, value, values, operationType, type, canBeNull,
        }) => {
          if (!value && !values && !canBeNull) {
            return undefined
          }

          const next = {
            type,
            operationType,
            // start: 'string',
            // end: 'string',
            propertyName: name,
            canBeNull,
          }

          if (value) {
            next.value = value
          } else if (values?.length) {
            next.values = values
          }

          return {
            ...next,
          }
        }).filter(Boolean),
      })
    },
    // enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const labels = useQuery({
    queryKey: [ 'labelsList' ],
    queryFn: () => wizataApi.components.getLabelsList(),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
  })
  const templates = useQuery({
    queryKey: [ 'templatesList' ],
    queryFn: () => wizataApi.templates.getList('false'),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
  })
  // TODO: probably will use it later
  // const twins = useQuery({
  //   queryKey: [ 'twinsList' ],
  //   queryFn: () => wizataApi.twins.getList(),
  //   staleTime: Infinity,
  //   refetchOnWindowFocus: false,
  //   retry: false,
  // })

  const columns = useMemo(() => [
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      maxWidth: 30,
      className: 'justify-content-center text-nowrap',
      Cell: ({ row }) => {
        if (isReadOnly) {
          return null
        }
        if (!isOrganizational && !(isPersonalStrictly && auth.currentUser.id === row.original.ownerId)) {
          return null
        }
        return (
          <EditButton onChange={() => setEditItem(row.original)} />
        )
      },
    },
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 200,
    },
    {
      Header: intl.t('form.fields.type'),
      accessor: 'type',
      disableSortBy: true,
      width: 160,
    },
    {
      Header: intl.t('form.fields.label'),
      accessor: 'labelId',
      disableSortBy: true,
      width: 160,
      Cell: ({ cell }) => (
        <CellExternalLink
          id={cell.value}
          list={labels}
          name="labelId"
          embed={embed}
        />
      ),
    },
    {
      Header: intl.t('form.fields.template'),
      accessor: 'templateId',
      disableSortBy: true,
      width: 200,
      Cell: ({ cell }) => {
        const allowed = auth.checkAccessUpdate('Template')
        return (
          <CellExternalLink
            id={cell.value}
            list={templates}
            name="templateId"
            embed={embed}
            allowed={allowed}
          />
        )
      },
    },
    {
      Header: intl.t('form.fields.twin'),
      accessor: 'twinId',
      disableSortBy: true,
      width: 200,
      show: false,
      Cell: ({ cell }) => {
        const allowed = auth.checkAccessUpdate('Template')
        return (
          <CellTwin
            twinId={cell.value}
            allowed={allowed}
          />
        )
      },
    },
    {
      Header: intl.t('form.fields.owner'),
      accessor: 'ownerId',
      disableSortBy: true,
      width: 200,
      Cell: ({ cell }) => (
        <CellExternalLink
          id={cell.value}
          // list={twins}
          name="ownerId"
          embed={embed}
        />
      ),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedDate',
      disableResizing: true,
      // disableSortBy: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.updatedById}
        />
      ),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdDate',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.createdById}
        />
      ),
    },
  ], [
    intl,
    labels,
    templates,
    isPersonalStrictly,
    isReadOnly,
    isOrganizational,
    embed,
  ])

  const itemsList = useMemo(() => ({
    dashboardType: dashboardTypes,
    templateId: templates?.data,
    labelId: labels?.data,
  }), [ templates, labels ])

  const drawerSequence = [
    {
      name: 'twinId',
      chart: consts.PanelChartType.pie,
    },
    {
      name: 'templateId',
      chart: consts.PanelChartType.bar, // histogram
    },
    {
      name: 'labelId',
      chart: consts.PanelChartType.pie,
    },
    {
      name: 'ownerId',
      chart: consts.PanelChartType.pie,
    },
    {
      name: 'dashboardType',
      chart: consts.PanelChartType.pie,
    },
  ]

  const possibleFilters = useMemo(() => ({
    templateId: contextFilters.templateId,
    labelId: contextFilters.labelId,
    ownerId: contextFilters.ownerId,
    dashboardType: contextFilters.dashboardType,
    twinId: contextTwinId,
  }), [ contextFilters, contextTwinId ])

  useEffect(() => {
    if (components) {
      refetch()
    }
  }, [
    filters.search,
    contextFilters.dashboardType,
    contextFilters.labelId,
    contextFilters.templateId,
    contextFilters.twins,
    contextFilters.ownerId,
    contextFilters.labelId,
    contextTwinId,
    filters.twins,
    filters.page,
    filters.pageSize,
    filters.sortDir,
    filters.sortBy,
  ])

  if (isLoading) {
    return (
      <div className="position-absolute-fill position-center-fill bg-light-alt opacity-50">
        <Icon name="faSpinner" spin />
      </div>
    )
  }

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">
      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        {isError ? (
          <div className="position-center-fill flex-column h-100">
            <h4>{intl.t('executions.loadingError')}</h4>
            <p className="text-danger">{error.message}</p>
            <button
              type="button"
              className="btn btn-fill-secondary"
              onClick={refetch}
            >
              {intl.t('form.actions.refresh')}
            </button>
          </div>
        ) :
          (
            <TableComponent
              placeholder={intl.t('errors.noDataDisplay')}
              columns={columns}
              filters={filters}
              data={components.items}
              items={components.items}
              total={components.totalCount}
              {...props}
              selection={!auth.checkAccessRead('SectionComponents')}
              onSelect={onRowSelect}
            />
          )}
      </div>
      <ChartsDrawer
        filters={possibleFilters}
        sequence={drawerSequence}
        entity="components"
        itemsList={itemsList}
        includeChildren
      />
      {editItem ? (
        <FormGrafanaDashboards
          scope={editItem?.type?.toLowerCase?.()}
          item={editItem}
          onClose={() => {
            setEditItem(null)
          }}
          items={components.items}
          onSuccess={refetch}
          dialog={{
            title: intl.t(`components.form.edit.${editItem?.type?.toLowerCase?.()}`),
            dataTestid: 'editDashboardComponentDialog',
          }}
        />
      ) : null}
    </div>
  )
}

export default withTableActions({
  initFilters (query) {
    return {
      // page: query.page,
      type: query.type,
    }
  },
  defaultFilters () {
    return {
      sortBy: 'updatedDate',
      sortDir: 'desc',
    }
  },
})(
  DashboardComponentsList,
)
