import { useCallback, memo } from 'react'
import { dbProvider, MlWebServiceNg } from '@wiz/store'
import { withProps } from '@wiz/components'
import FormMlWebService from '@/components/Forms/MlWebService'
import enhanceWebService from './enhanceWebService'
import enhanceInterfaces from './enhanceInterfaces'
import enhanceUsage from './enhanceUsage'

const enhanceProps = withProps(() => {
  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    await MlWebServiceNg.prepareReplaceSettings(context, dbProvider.database, data)
    await dbProvider.batch(context)
  }, [])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceWebService(
    enhanceInterfaces(
      enhanceUsage(
        enhanceProps(FormMlWebService),
      ),
    ),
  ),
)
