/* eslint-disable no-nested-ternary */
import {
  useMemo, useCallback, useState, useEffect, useRef,
} from 'react'
import { DateTime } from 'luxon'
import { useIntl } from '@wiz/intl'
import {
  Badge,
  FormSection,
  FormField,
  FormSelectNative,
  CustomScrollbars,
  FormFieldInline,
} from '@wiz/components'
import { wizataApi } from '@/api'
import Icon from '@/shared/icon'
import { get, consts, isEmpty } from '@wiz/utils'
import classnames from 'classnames'
import { useAuth } from '@/auth'
import { useTheme } from '@/theme'
import { useRouteMatch } from '@/router'
import {
  validateImageMaxSize, convertToBase64, dataUrlToFile,
} from '@/utils/global'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import events from '@/utils/events'
import classes from './index.module.css'

export default function View ({
  currentUser,
  roles,
  settings,
  globalSettings,
  onChangeLang,
  onResetLocalData,
  onChangeSetting,
}) {
  const intl = useIntl()
  const auth = useAuth()
  const match = useRouteMatch()
  const { user, setUser } = useGlobalExecute()
  const [ userState, setUserState ] = useState({})
  const [ formValid, setFormValid ] = useState({ emailIsValid: true, phoneIsValid: true })
  const userRef = useRef()

  const BASE_URL = String(process.env.WIZATA_API_BASE_URL).replace('[apiVersion]', '1.0')
  const AVA_ENDPOINT = `${BASE_URL}/Users/${currentUser.id}/image`

  const { data: userData, refetch } = useQuery({
    queryKey: [ 'userData', currentUser.id ],
    queryFn: () => wizataApi.users.getById(currentUser.id),
    enabled: !!currentUser.id,
    staleTime: Infinity,
  })

  const {
    mutateAsync: updateUserData,
  } = useMutation({
    mutationKey: [ 'updateUserData' ],
    mutationFn: (data) => {
      if (formValid.emailIsValid && formValid.phoneIsValid) {
        return wizataApi.users.updateById(currentUser.id, data)
      }
    },
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/settings.user.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      refetch()
    },
  })

  const { formState: { errors }, setError, clearErrors } = useForm()

  const langs = useMemo(() => consts.Langs.map(id => ({
    id,
    name: intl.t(`lang.${id}`),
  })), [ intl ])

  const timeFormatList = useMemo(() => {
    const now = DateTime.local()
    return Object.entries(consts.PLATFORM_TIME_FORMAT).map(item => ({
      id: item[0],
      name: now.toFormat(item[1]),
    }))
  }, [])

  const dateFormatList = useMemo(() => {
    const now = DateTime.local()
    return Object.entries(consts.PLATFORM_DATE_FORMAT).map(item => ({
      id: item[0],
      name: now.toFormat(item[1]),
    }))
  }, [])

  const resetProfile = () => {
    userRef.current = {
      ...userRef.current,
      userOverridden: null,
    }
    updateUserData(userRef.current)
  }

  useEffect(() => {
    if (!isEmpty(userData)) {
      userRef.current = {
        userOverridden: userData.userOverridden ? { ...userData.userOverridden } : null,
        nickname: userData.nickname,
        title: userData.title,
        description: userData.description,
      }
      setUserState(userData)
    }

    return () => {
      if (userRef.current) {
        updateUserData(userRef.current)
        userRef.current = null
      }
    }
  }, [ userData?.userOverridden ])

  const handleChangeUserProfile = (e) => {
    const { value, name } = e.target
    const emailRegExp = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
    const phoneRegExp = new RegExp(/\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/)
    const isOverriden = name === 'firstName' || name === 'lastName' || name === 'phone' || name === 'email'

    if (name === 'email' && !emailRegExp.test(value)) {
      setFormValid({ ...formValid, emailIsValid: false })
    } else if (name === 'email' && emailRegExp.test(value)) {
      setFormValid({ ...formValid, emailIsValid: true })
    }

    if (name === 'phone' && !phoneRegExp.test(value)) {
      setFormValid({ ...formValid, phoneIsValid: false })
    } else if (name === 'phone' && phoneRegExp.test(value)) {
      setFormValid({ ...formValid, phoneIsValid: true })
    }

    if (isOverriden) {
      userRef.current = {
        ...userRef.current,
        userOverridden: {
          ...userRef.current?.userOverridden,
          [name]: value === userData[name] ? null : value,
        },
      }
    } else {
      userRef.current = {
        ...userRef.current,
        [name]: value,
      }
    }
  }

  const onAvatarChange = async (event) => {
    const fileList = event.target.files
    const formData = new FormData()
    const file = fileList[0]
    clearErrors('settings.userAvatar')
    const error = validateImageMaxSize(file, 128)

    if (error) {
      setError('settings.userAvatar', { type: 'custom', message: error })
      events.emit('app:notify', {
        type: 'error',
        title: 'file size error',
        message: error,
        duration: 5000,
      })
    } else {
      const prepareFile = await convertToBase64(file)
      const prep = await dataUrlToFile(prepareFile, file.name)
      formData.append('file', prep, file.name)
      const token = await auth.acquireToken()

      fetch(AVA_ENDPOINT, {
        method: 'PUT',
        body: formData,
        headers: {
          Authorization: `BEARER ${token}`,
        },
      }).then(() => setUser({ avatar: prepareFile }))
    }
  }

  return (
    <CustomScrollbars
      horizontal={false}
      shadow={false}
    >
      <div className="d-flex flex-column container py-5" style={{ maxWidth: 800 }}>
        <div className={classnames(classes.userInfo, 'bg-light-alt')}>
          <h5 className="mb-4">{intl.t('settings.profile.title')}</h5>
          <div className={classnames('row mb-3 rounded-2')}>
            <div className={classnames(classes.userInfoForm, 'col-sm-9')}>

              <FormField
                label={intl.t('settings.profile.nickname')}
                description={intl.t('settings.profile.nicknameDescr')}
              >
                <input
                  type="text"
                  name="nickname"
                  className="form-control me-2"
                  defaultValue={userState?.nickname}
                  onBlur={handleChangeUserProfile}
                  // disabled={!auth.checkAccessManage('SectionGeneralSettings')}
                />
              </FormField>

              <FormField
                label={intl.t('settings.profile.firstName')}
                description={intl.t('settings.profile.firstNameDescr')}
              >
                <input
                  type="text"
                  name="firstName"
                  className="form-control me-2"
                  defaultValue={userState?.userOverridden && userState?.userOverridden.firstName ? userState?.userOverridden.firstName : userState?.firstName}
                  onBlur={handleChangeUserProfile}
                  // disabled={!auth.checkAccessManage('SectionGeneralSettings')}
                />
              </FormField>

              <FormField
                label={intl.t('settings.profile.lastName')}
                description={intl.t('settings.profile.lastNameDescr')}
              >
                <input
                  type="text"
                  name="lastName"
                  className="form-control me-2"
                  defaultValue={userState?.userOverridden && userState?.userOverridden.lastName ? userState?.userOverridden.lastName : userState?.lastName}
                  onBlur={handleChangeUserProfile}
                  // disabled={!auth.checkAccessManage('SectionGeneralSettings')}
                />
              </FormField>

              <FormField
                label={intl.t('settings.profile.userTitle')}
                description={intl.t('settings.profile.userTitleDescr')}
              >
                <input
                  type="text"
                  name="title"
                  className="form-control me-2"
                  defaultValue={userState?.title}
                  onBlur={handleChangeUserProfile}
                  // disabled={!auth.checkAccessManage('SectionGeneralSettings')}
                />
              </FormField>

              <FormField
                label={intl.t('settings.profile.email')}
                description={intl.t('settings.profile.emailDescr')}
              >
                <input
                  type="email"
                  name="email"
                  className={classnames('form-control me-2', { 'border-danger': !formValid.emailIsValid })}
                  defaultValue={userState?.userOverridden && userState?.userOverridden.email ? userState?.userOverridden.email : userState?.email}
                  onBlur={handleChangeUserProfile}
                  disabled={!auth.checkAccessManage('SectionGeneralSettings')}
                />
              </FormField>

              <FormField
                label={intl.t('settings.profile.phone')}
                description={intl.t('settings.profile.phoneDescr')}
                errors={get(errors, `${settings}.subject`)}
              >
                <input
                  type="tel"
                  name="phone"
                  className={classnames('form-control me-2', { 'border-danger': !formValid.phoneIsValid })}
                  defaultValue={userState?.userOverridden && userState?.userOverridden.phone ? userState?.userOverridden.phone : userState?.phone}
                  onBlur={handleChangeUserProfile}
                  // disabled={!auth.checkAccessManage('SectionGeneralSettings')}
                />
              </FormField>
            </div>
            <div className="col-sm-3">
              <div className={classnames(classes.userInfoView)}>
                <div className={classnames(classes.userImageBox)}>
                  <div className={classnames(classes.userImage, { 'border-0': user.avatar })}>
                    <FormFieldInline className="pointer">
                      <input
                        type="file"
                        className="d-none"
                        accept="image/png, image/jpeg, image/svg+xml"
                        // disabled={!auth.checkAccessManage('SectionGeneralSettings')}
                        onChange={onAvatarChange}
                      />
                      {user.avatar ?
                        <img src={user.avatar} alt="user" /> : (
                          <Icon
                            style={{ fontSize: 75 }}
                            name="faUser"
                            size="xl"
                          />
                        )}
                      <span
                        className={classnames(classes.btnUpload)}
                      >
                        <Icon
                          name="faPenToSquare"
                          size="xs"
                        />
                      </span>
                    </FormFieldInline>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-fill-secondary-alt w-100 mb-2"
                  onClick={() => auth.relogin()}
                >
                  <Icon name="faUsers" className="me-1" />
                  {intl.t('user.relogin')}
                </button>
                <button
                  type="button"
                  className="btn btn-fill-secondary-alt w-100"
                  onClick={async () => {
                    await window.wizConfirm({ message: 't/form.confirmResetProfile' })
                    resetProfile()
                  }}
                >
                  <Icon name="faArrowsRotate" className="me-1" />
                  {intl.t('user.resetProfile')}
                </button>
              </div>
            </div>
          </div>
        </div>

        <FormSection
          title={intl.t('settings.general.title')}
        >
          <FormField
            label={intl.t('settings.general.language')}
            description={intl.t('settings.general.languageDescr')}
          >
            <FormSelectNative
              options={langs}
              value={match.params.lang}
              onChange={onChangeLang}
            />
          </FormField>

          <FormField
            label={intl.t('form.actions.resetLocalData')}
            description={intl.t('form.actions.resetLocalDataDescr')}
            complex
          >
            <button
              type="button"
              className="btn btn-fill-secondary-alt"
              onClick={onResetLocalData}
            >
              {intl.t('form.actions.resetLocalData')}
            </button>
          </FormField>

          <FormField
            label={intl.t('settings.general.timeFormat')}
            description={intl.t('settings.general.userTimeFormatDescr')}
          >
            <FormSelectNative
              options={timeFormatList}
              value={settings.PlatformTimeFormat || globalSettings.PlatformTimeFormat}
              onChange={value => onChangeSetting('PlatformTimeFormat', value)}
            />
          </FormField>

          <FormField
            label={intl.t('settings.general.dateFormat')}
            description={intl.t('settings.general.userDateFormatDescr')}
          >
            <FormSelectNative
              options={dateFormatList}
              value={settings.PlatformDateFormat || globalSettings.PlatformDateFormat}
              onChange={value => onChangeSetting('PlatformDateFormat', value)}
            />
          </FormField>

          <FormField
            label={intl.t('settings.general.roles')}
            description={intl.t('settings.general.rolesDescr')}
            complex
          >
            <div className="form-control">
              {roles.map(item => (
                <Badge key={item.id} className={classnames(classes.userRolesBadge, 'me-1')}>
                  {item.name}
                </Badge>
              ))}
            </div>
          </FormField>

        </FormSection>
      </div>
    </CustomScrollbars>
  )
}
