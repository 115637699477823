export default function components (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      components: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value (twinId, { organizationOnly, ownerId }) {
              let query = ''

              if (twinId) {
                query += `twinId=${twinId}`
              }

              if (ownerId) {
                query += `&ownerId=${ownerId}`
              }

              if (organizationOnly) {
                query += `&organizationOnly=${organizationOnly}`
              }

              const request = client.get(`/components/?${query}`)
              return request.fetch()
            },
          },
          getLabelsList: {
            enumerable: true,
            value () {
              const request = client.get('/components/labels/')
              return request.fetch()
            },
          },
          getById: {
            enumerable: true,
            value (id) {
              return client.get(`/components/${id}`).fetch()
            },
          },
          deleteById: {
            enumerable: true,
            value (id) {
              return client.delete(`/components/${id}`).fetch()
            },
          },
          create: {
            enumerable: true,
            value (params) {
              return client.post('/components', { ...params }).fetch()
            },
          },
          update: {
            enumerable: true,
            value (params) {
              return client.put(`/components/${params.id}`, { ...params }).fetch()
            },
          },
        }),
      },
    })
  }
}
