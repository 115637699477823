import { useCallback, useState } from 'react'
import { dbProvider } from '@wiz/store'
import { withProps, withObservables } from '@wiz/components'
import { useRouter } from '@/router'
import useFilters from '@/hooks/useFilters'
import List from '@/components/TwinGraph/List'

function initFilters (query = {}) {
  return {
    sortBy: query.sortBy || 'alpha-down',
    filterBy: query.filterBy || null,
    followedBy: query.followedBy || null,
  }
}

const enhanceSettings = withObservables([], () => ({
  settings: dbProvider.observeSettings([
    'boardsFilters',
  ]),
}))

const enhanceProps = withProps(({ settings }) => {
  const router = useRouter()
  const [ editId, setEditId ] = useState(null)

  const { filters, onFilter, onResetFilter } = useFilters({
    initFilters,
    source: settings.boardsFilters,
    setSource: async (data) => {
      const context = dbProvider.createBatchContext()
      await dbProvider.prepareReplaceSetting(context, 'boardsFilters', data)
      await dbProvider.batch(context)
    },
  })

  const onAction = useCallback(async ({ id: action }, item) => {
    if (action === 'view') {
      router.push({ name: 'chart-view', params: { id: item.id } })
    } else if (action === 'remove') {
      await window.wizConfirm({ message: 't/twinGraph.confirmDeleteBlock' })
      const context = dbProvider.createBatchContext()
      if (item.blockId) {
        try {
          const block = await item.block.fetch()
          await block.prepareRemove(context)
        } catch (error) {
          await item.prepareRemove(context)
        }
      } else {
        await item.prepareRemove(context)
      }
      await dbProvider.batch(context)
    } else if (action === 'select') {
      const homepageDefaultId = await dbProvider.fetchSettings('homepageDefaultId')
      const context = dbProvider.createBatchContext()
      await dbProvider.prepareReplaceSetting(
        context,
        'homepageDefaultId',
        homepageDefaultId === item.id ? null : item.id,
      )
      await dbProvider.batch(context)
    } else if (action === 'settings') {
      setEditId(item.id)
    } else if (action === 'favorite') {
      let favorites = await dbProvider.fetchSettings('spotlightFavorites')
      favorites = favorites.includes(item.id) ?
        favorites.filter(row => row !== item.id) :
        favorites.concat(item.id)

      const context = dbProvider.createBatchContext()
      await dbProvider.prepareReplaceSetting(context, 'spotlightFavorites', favorites)
      await dbProvider.batch(context)
    } else if (action === 'duplicate') {
      const context = dbProvider.createBatchContext()
      const model = await dbProvider.prepareDuplicateModel(context, item, null, {
        uniqProps: [ 'name' ],
      })
      await dbProvider.batch(context)
      await window.wizConfirm({
        title: 't/form.success.duplicated',
        message: 't/twinGraph.successDuplication',
      })
      router.push({
        name: 'chart-view',
        params: { id: model.id },
      })
    }
  }, [ router ])

  return {
    editId,
    setEditId,
    onAction,
    filters,
    onFilter,
    onResetFilter,
  }
})

export default enhanceSettings(
  enhanceProps(List),
)
