import { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useIntl } from '@wiz/intl'
import { Icon, Badge } from '@wiz/components'
import SensorList from '@/hoc/SensorList'
import Duration from './Duration'
import Sensors from './Sensors'

const SensorsData = SensorList(Sensors)

export default function OptionEvent (props) {
  const intl = useIntl()
  const [ removeLock, setRemoveLock ] = useState(false)
  const {
    treeData: {
      onEditEvent,
      onRemoveEvent,
      onOpenExplorer,
    },
    data: {
      payload: item,
    },
  } = props

  return (
    <div
      className="flex-fill d-flex align-items-center justify-content-between text-nowrap hover-toggle min-w-0 fw-light"
    >
      <div className="d-flex flex-column min-w-0">
        <div className="d-flex align-items-center">
          {item.sourceType === 'manual' ? (
            <Icon
              name="fa--tools"
              title={intl.t(`enum.eventSourceType.${item.sourceType}`)}
              className="me-1"
            />
          ) : null}

          <span className="text-truncate min-w-0">
            {item.name}
          </span>
        </div>

        <div className="d-flex align-items-center">
          <div className="small me-2">
            <Badge
              title={intl.t('form.fields.type')}
              className={classnames({
                'bg-secondary': item.type !== 'anomaly',
                'bg-danger': item.type === 'anomaly',
              })}
            >
              {intl.t(`enum.eventType.${item.type}`)}
            </Badge>
          </div>

          <Duration
            className="me-2"
            data={item}
          />

          {item.description ? (
            <div className="font-italic text-muted text-truncate small">
              {item.description}
            </div>
          ) : null}
        </div>

        {item.sensorIds?.length ? (
          <SensorsData
            value={item.sensorIds}
            keyName="id"
            selectedOnly
            onOpenExplorer={sensorIds => onOpenExplorer({
              sensorIds,
              event: item,
            })}
          />
        ) : null}
      </div>

      {onEditEvent || onRemoveEvent ? (
        <div className="d-flex align-items-center">
          {onEditEvent ? (
            <button
              type="button"
              className="hover-opacity btn btn-flat-secondary align-self-center p-2 ms-1"
              title={intl.t('twinGraph.form.actions.updateEvent')}
              onClick={() => onEditEvent(item)}
            >
              <Icon name="fa--edit" />
            </button>
          ) : null}

          {onRemoveEvent ? (
            <button
              type="button"
              className="hover-opacity btn btn-flat-secondary align-self-center p-2 ms-1"
              title={intl.t('twinGraph.form.actions.removeEvent')}
              disabled={removeLock}
              onClick={() => {
                setRemoveLock(true)
                onRemoveEvent(item)
              }}
            >
              <Icon
                name={removeLock ? 'fa--spinner' : 'fa--trash-alt'}
                spin={removeLock}
              />
            </button>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

OptionEvent.propTypes = {
  treeData: PropTypes.shape({
    onEditEvent: PropTypes.func.isRequired,
    onRemoveEvent: PropTypes.func.isRequired,
    onOpenExplorer: PropTypes.func.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    payload: PropTypes.object.isRequired,
  }).isRequired,
}
