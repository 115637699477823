import { useEffect, useCallback } from 'react'
import { of as of$, combineLatest } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import difference from 'lodash/difference'
import { uniq, orderBy, intersection } from '@wiz/utils'
import {
  Q, dbProvider, Sensor, Twin,
} from '@wiz/store'
import { withObservables, withProps } from '@wiz/components'
import Component from '@/components/Form/ListSensorsByTwin'

const enhanceData = withObservables([
  'blockSettings',
  'businessType',
  'sensorIds',
  'search',
  'twinsRequired',
], ({
  blockSettings,
  businessType,
  sensorIds,
  search,
  twinsRequired,
}) => {
  const settings = Object.values(blockSettings || {})
    .filter(item => !!item)
  const twinIds = uniq(
    settings
      .map(item => (item.twinId || item.id))
      .filter(item => !!item),
  )

  if (twinsRequired && !twinIds.length) {
    return {
      options: of$([]),
    }
  }

  let query = dbProvider.database.collections.get('sensors').query()

  if (twinIds.length) {
    query = query.extend(Q.where('twin_id', Q.oneOf(twinIds)))
  }

  if (sensorIds?.length) {
    query = query.extend(Q.where('id', Q.oneOf(sensorIds)))
  }

  if (search) {
    const sanitizeSearch = Q.sanitizeLikeString(search)
    query = query.extend(Q.or(
      Q.where('name', Q.like(`%${sanitizeSearch}%`)),
      Q.where('description', Q.like(`%${sanitizeSearch}%`)),
      Q.where('hardware_id', Q.like(`%${sanitizeSearch}%`)),
    ))
  }

  if (businessType) {
    query = query.extend(Q.where('business_type', businessType))
  }

  return {
    options: query
      .observe()
      .pipe(
        settings.length ? (
          map(items => settings.reduce((out, setting) => out.concat(
            setting,
            orderBy(items.filter(item => (
              item.twinId === setting.twinId ||
              item.twinId === setting.id
            )), [ 'displayName' ], [ 'asc' ]),
          ), []))
        ) : (
          switchMap((sensors) => {
            if (!sensors.length) {
              return of$([])
            }

            const twins = Object.values(
              sensors.reduce((out, item) => {
                if (item.twinId && !out[item.twinId]) {
                  return { ...out, [item.twinId]: item.twin.observe() }
                }
                return out
              }, {}),
            )

            if (!twins.length) {
              return of$(sensors)
            }

            return combineLatest(...twins).pipe(
              map(items => items.reduce((out, setting) => out.concat(
                setting,
                orderBy(
                  sensors.filter(item => (item.twinId === setting.id)),
                  [ 'displayName' ],
                  [ 'asc' ],
                ),
              ), orderBy(
                sensors.filter(item => !item.twinId),
                [ 'displayName' ],
                [ 'asc' ],
              ))),
            )
          })
        ),
      ),
    searchedSensors: query
      .observe()
      .pipe(
        map(items => settings.reduce((out, setting) => out.concat(
          // setting,
          orderBy(items.filter(item => (
            item.twinId === setting.twinId ||
            item.twinId === setting.id
          )), [ 'displayName' ], [ 'asc' ]),
        ), [])),
      ),
  }
})

const enhanceProps = withProps(({
  blockSettings, selectedSettings, options, onSettingsUpdate, value, searchedSensors,
}) => {
  const checkTwinForActiveSensors = useCallback(async (twinId) => {
    const query = await dbProvider.database.collections.get('sensors').query(Q.where('twin_id', twinId)).fetch()

    const existingIds = query.map(sensor => sensor.id)
    const matches = intersection(value, existingIds)
    if (matches.length) {
      return false
    }
    return true
  }, [ value ])

  useEffect(() => {
    const next = []

    const settings = Object.values(selectedSettings || {})
      .filter(item => !!item)

    const allSettings = Object.values(blockSettings || {})
      .filter(item => !!item)

    const twinIds = uniq(
      settings
        .map(item => (item.twinId || item.id))
        .filter(item => !!item),
    )

    const allIds = uniq(
      allSettings
        .map(item => (item.twinId || item.id))
        .filter(item => !!item),
    )

    const diff = difference(allIds, twinIds)

    for (const item of diff) {
      const index = options.map(option => option.id).indexOf(item)

      if (index >= 0 && index + 1 && options[index + 1] instanceof Sensor) {
        checkTwinForActiveSensors(options[index].id).then((check) => {
          if (check) {
            const toRemove = Object.entries(blockSettings).reduce((acc, [ key, value ]) => {
              if (value.id === options[index].id) {
                acc = key
              }
              return acc
            }, '')
            next.push(toRemove)
            onSettingsUpdate?.([ toRemove ])
          }
        })
      }
    }
  }, [ selectedSettings ])
})

export default enhanceData(enhanceProps(Component))
