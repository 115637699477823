import { useCallback } from 'react'
import { withProps, withObservables } from '@wiz/components'
import { dbProvider } from '@wiz/store'
import Component from '@/components/TwinStyle/View'

const enhanceData = withObservables([], () => ({
  settings: dbProvider.observeGlobalSettings([
    'twinColorAreas',
    'twinColorMachines',
    'twinColorEquipment',
    'twinColorSensorsHardware',
    'twinColorSensorsVirtual',
    'twinColorNestedTwinGraph',
  ]),
}))

const enhanceProps = withProps(() => {
  const onChangeGlobalSetting = useCallback(async (name, value) => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareReplaceGlobalSetting(context, name, value)
    await dbProvider.batch(context)
  }, [])

  return {
    onChangeGlobalSetting,
  }
})

export default enhanceData(
  enhanceProps(Component),
)
