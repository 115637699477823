import { useFormContext } from 'react-hook-form'
import {
  FormSection,
  FormField,
  FormControl,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function Section ({
  scope,
  titles,
}) {
  const intl = useIntl()
  const { register, formState: { errors } } = useFormContext()

  return (
    <FormSection
      title={intl.t('block.section.title')}
      folding
    >
      <input
        {...register(`${scope}.id`)}
        type="hidden"
      />

      <FormField
        label={intl.t('form.fields.name')}
        description={titles?.nameDescr || intl.t('block.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            maxLength: {
              value: 100,
              message: intl.t('form.errors.fieldMaxlen', { max: 100 }),
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.description')}
        description={titles?.descriptionDescr || intl.t('block.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.description`}
          rules={{
            maxLength: {
              value: 450,
              message: intl.t('form.errors.fieldMaxlen', { max: 450 }),
            },
          }}
        />
      </FormField>
    </FormSection>
  )
}
