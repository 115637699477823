import { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Script, ScriptInterface } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import ObservScript from '@/containers/Forms/Script/Observ'
import Section from '@/components/Forms/Script/Section'
import SectionInterface from '@/components/Forms/Script/SectionInterface'
import Select from '@/components/Form/Select'
import ScriptList from '@/hoc/ScriptList'

export default function SettingsSection ({ scope }) {
  const intl = useIntl()
  const {
    register,
    watch,
    setValue,
    clearErrors,
  } = useFormContext()
  const id = watch(`${scope}.script.id`)

  const handleChange = useCallback(({ script, interfaces }) => {
    const next = Script.toJSON(script)
    setValue(`${scope}.id`, next.id, { shouldDirty: true })
    setValue(`${scope}.script`, next, { shouldDirty: true })
    setValue(`${scope}.interfaces`, interfaces.map(item => ScriptInterface.toJSON(item)), {
      shouldDirty: true,
    })
    clearErrors(scope)
  }, [ scope, setValue, clearErrors ])

  return (
    <>
      <ObservScript
        id={id}
        onChange={handleChange}
      />

      <input
        {...register(`${scope}.id`)}
        type="hidden"
        defaultValue={id}
      />

      <Section
        scope={`${scope}.script`}
        registerId={false}
        title={intl.t('form.sections.script')}
        description={intl.t('form.sections.scriptDescr')}
        addon={() => (
          <Controller
            name={`${scope}.script.id`}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                invalid={fieldState.invalid}
                ListSource={ScriptList}
                placeholder={intl.t('form.fields.scriptPlaceholder')}
                className="min-w-0 w-100 w-md-auto mt-2 mt-md-0"
              />
            )}
          />
        )}
      />

      <SectionInterface
        scope={`${scope}.interfaces`}
      />
    </>
  )
}
