import Component from '@/components/Twin/Graph'
import enhanceRunContext from '@/containers/TwinGraph/enhanceRunContext'
import enhanceDiagramState from './enhanceDiagramState'
import enhanceDiagramProps from './enhanceDiagramProps'
import enhanceDiagramData from './enhanceDiagramData'

export default enhanceDiagramData(
  enhanceDiagramState(
    enhanceDiagramProps(
      enhanceRunContext(Component),
    ),
  ),
)
