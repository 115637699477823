/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import {
  VirtualTable,
  Pagination,
} from '@wiz/components'
import { Notification } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import UpdatedInfo from '@/components/Form/UpdatedInfo'

export default function TableMobile ({
  items,
  ...props
}) {
  const intl = useIntl()
  const columns = useMemo(() => [
    {
      Header: intl.t('notifications.form.fields.message'),
      accessor: 'message',
      Cell: ({ row, cell }) => (
        <div className="min-w-0">
          <div className="d-flex align-items-center">
            <span className="text-truncate min-w-0">
              {cell.value}
            </span>
          </div>

          <div className="font-italic text-muted text-truncate small">
            <span
              className={classnames('me-2', {
                'text-danger': row.original.level === 'error',
                'text-warning': row.original.level === 'warn',
                'text-info': row.original.level === 'info',
              })}
            >
              {row.original.level}
            </span>

            {row.original.source}
          </div>

          <div className="small">
            <UpdatedInfo date={row.original.timestamp} withMillis withSecond />
          </div>
        </div>
      ),
    },
  ], [ intl ])

  return (
    <>
      <VirtualTable
        className="flex-fill"
        placeholder={intl.t('errors.noDataDisplay')}
        itemSize={68}
        columns={columns}
        data={items}
        selection={false}
        showHeader={false}
        {...props}
      />

      <Pagination
        {...props}
        className="mt-3"
        pageSizeNavigation={false}
        showTotal={false}
        relative
      />
    </>
  )
}

TableMobile.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(Notification)),
  filters: PropTypes.object,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onToggleActive: PropTypes.func,
}

TableMobile.defaultProps = {
  items: [],
  filters: {},
  onRemove: undefined,
  onEdit: undefined,
  onView: undefined,
  onToggleActive: undefined,
}
