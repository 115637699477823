import { dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'
import Component from '@/components/FormatDateTime'

const enhanceSettings = withObservables([], () => ({
  settings: dbProvider.observeSettings([
    'PlatformDateFormat',
    'PlatformTimeFormat',
  ]),
  // globalSettings: dbProvider.observeGlobalSettings([
  //   'PlatformDateFormat',
  //   'PlatformTimeFormat',
  // ]),
}))

export default enhanceSettings(Component)
