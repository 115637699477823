import { forwardRef } from 'react'
import { Card, withResizeObserver } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import { useTheme } from '@/theme'
import KPICalendarHeatmap from './KPICalendarHeatmap'

const Widget = withResizeObserver(forwardRef(({
  widget,
  config,
  dimension,
  canUpdateDashboard,
  onAction,
  onChangeSelectedDataRange,
}, ref) => {
  const intl = useIntl()
  const theme = useTheme()
  const auth = useAuth()

  const actions = []
  if (canUpdateDashboard) {
    actions.push({ id: 'settings', name: intl.t('form.actions.settings'), icon: 'fa--cog' })
    if (auth.checkAccessCopy(widget)) {
      actions.push({ id: 'duplicate', name: intl.t('form.actions.duplicate'), icon: 'fa--clone' })
      actions.push({ id: 'multiDuplicate', name: intl.t('form.actions.multiDuplicate'), icon: 'fa--folder-plus' })
    }
    actions.push({ id: 'remove', name: intl.t('form.actions.remove'), icon: 'fa--trash-alt' })
  }

  const configured = !!(
    config.dataType &&
    config.sensorId
  )

  return (
    <Card
      ref={ref}
      className="position-absolute-fill"
      classNameTitle={canUpdateDashboard ? 'userdrag' : undefined}
      title={widget.title || (configured ? `${config.displayName} (${config.dataType})` : intl.t(widget.info.name))}
      description={widget.description}
      options={actions}
      onAction={onAction}
    >
      {do {
        if (configured) {
          <KPICalendarHeatmap
            widget={widget}
            title={widget.title}
            config={config}
            theme={theme.echarts}
            dimension={dimension}
            onChangeSelectedDataRange={onChangeSelectedDataRange}
          />
        } else if (canUpdateDashboard) {
          <div className="d-flex align-items-center justify-content-center flex-fill">
            <button
              type="button"
              className="btn btn-flat-secondary"
              onClick={() => onAction({ id: 'settings' })}
            >
              {intl.t('widgets.selectSensor')}
            </button>
          </div>
        } else {
          <div className="d-flex align-items-center justify-content-center flex-fill">
            {intl.t('widgets.notConfigured')}
          </div>
        }
      }}
    </Card>
  )
}))

export default Widget
