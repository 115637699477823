/* eslint-disable no-nested-ternary */
import { Card } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import Table from '@/containers/Forms/InputSensorValueGroup/Table'

export default function Widget ({
  widget,
  config,
  hidden,
  canUpdateDashboard,
  onAction,
}) {
  const intl = useIntl()
  const auth = useAuth()
  const actions = []

  if (canUpdateDashboard) {
    actions.push({ id: 'settings', name: intl.t('form.actions.settings'), icon: 'fa--cog' })
  }

  if (auth.checkAccessCopy(widget) && canUpdateDashboard) {
    actions.push({ id: 'duplicate', name: intl.t('form.actions.duplicate'), icon: 'fa--clone' })
    actions.push({ id: 'multiDuplicate', name: intl.t('form.actions.multiDuplicate'), icon: 'fa--folder-plus' })
  }

  if (canUpdateDashboard) {
    actions.push({ id: 'remove', name: intl.t('form.actions.remove'), icon: 'fa--trash-alt' })
  }

  return (
    <Card
      className="position-absolute-fill"
      classNameTitle={canUpdateDashboard ? 'userdrag' : undefined}
      classNameBody="my-0"
      title={widget.title || intl.t(widget.info.name)}
      description={widget.description}
      options={actions}
      onAction={onAction}
    >
      {do {
        if (!auth.checkAccessRead('Dashboard')) {
          <div className="d-flex align-items-center justify-content-center flex-fill">
            {intl.t('widgets.notAccess')}
          </div>
        } else {
          <Table
            hidden={hidden}
            sensorIds={config.sensorIds}
            showLastValues={config.showLastValues}
            precision={config.precision}
            businessType={config.businessType}
            formModify={config.formModify}
            size={widget.gridW <= 13 ? 'sm' : undefined}
          />
        }
      }}
    </Card>
  )
}
