import { type, intersection, define } from 'superstruct'
import { DiagramBlockInput } from '@wiz/store'
import * as types from '@/utils/types'
import { intl } from '@/i18n'

const BlockInputSensorId = () => define('BlockInputSensorId', (value, { branch }) => {
  const settings = branch[branch.length - 2]
  let result = true

  if ([
    'sensor',
    'sensorInterval',
    'sensorWindow',
    'sensorRawWindow',
    'sensorLastValue',
  ].includes(settings.inputType)) {
    if (!value && !settings.labelId) {
      result = intl.t('form.errors.fieldOptionalRequired')
    }
  }

  return result
})

const BlockInputLabelId = () => define('BlockInputLabelId', (value, { branch }) => {
  const settings = branch[branch.length - 2]
  let result = true

  if ([
    'sensor',
    'sensorInterval',
    'sensorWindow',
    'sensorRawWindow',
    'sensorLastValue',
  ].includes(settings.inputType)) {
    if (!value && !settings.sensorId) {
      result = intl.t('form.errors.fieldOptionalRequired')
    }
  }

  return result
})

const BlockInputAggregationType = () => define('BlockInputAggregationType', (value, { branch }) => {
  const settings = branch[branch.length - 2]
  let result = true

  if ([ 'sensorInterval', 'sensorWindow' ].includes(settings.inputType)) {
    if (!value) {
      result = intl.t('form.errors.fieldRequired')
    }
  }

  return result
})

const BlockInputDateFrom = () => define('BlockInputDateFrom', (value, { branch }) => {
  const settings = branch[branch.length - 2]
  let result = true

  if ([ 'sensorInterval' ].includes(settings.inputType)) {
    if (!value) {
      result = intl.t('form.errors.fieldRequired')
    } else if (settings.dateTo && value > settings.dateTo) {
      result = intl.t('form.errors.dateFromOutofrange')
    }
  }

  return result
})

const BlockInputDateTo = () => define('BlockInputDateTo', (value, { branch }) => {
  const settings = branch[branch.length - 2]
  let result = true

  if ([ 'sensorInterval' ].includes(settings.inputType)) {
    if (!value) {
      result = intl.t('form.errors.fieldRequired')
    } else if (settings.dateFrom && value < settings.dateFrom) {
      result = intl.t('form.errors.dateToOutofrange')
    }
  }

  return result
})

const BlockInputDuration = () => define('BlockInputDuration', (value, { branch }) => {
  const settings = branch[branch.length - 2]
  let result = true

  if ([ 'sensorWindow', 'sensorRawWindow' ].includes(settings.inputType)) {
    if (!value) {
      result = intl.t('form.errors.fieldRequired')
    }
  }

  return result
})

const BlockInputAmount = () => define('BlockInputAmount', (value, { branch }) => {
  const settings = branch[branch.length - 2]
  let result = true

  if ([ 'sensorLastValue' ].includes(settings.inputType)) {
    if (!value) {
      result = intl.t('form.errors.fieldRequired')
    } else if (value < 2) {
      result = intl.t('form.errors.mustBeMore', { value: 2 })
    } else if (value > 10000) {
      result = intl.t('form.errors.mustBeLess', { value: 10000 })
    }
  }

  return result
})

const BlockInputValue = () => define('BlockInputValue', (value, { branch }) => {
  const settings = branch[branch.length - 2]
  let result = true

  if ([ 'constant' ].includes(settings.inputType)) {
    if (!value) {
      result = intl.t('form.errors.fieldRequired')
    } else {
      try {
        if (JSON.parse(value) === null) {
          result = intl.t('form.errors.fieldRequired')
        }
      } catch (error) {
        result = error.message
      }
    }
  }

  return result
})

export default type({
  inputType: intersection([
    types.Required(),
    DiagramBlockInput.schema.schema.inputType,
  ]),
  sensorId: intersection([
    BlockInputSensorId(),
    DiagramBlockInput.schema.schema.sensorId,
  ]),
  labelId: intersection([
    BlockInputLabelId(),
    DiagramBlockInput.schema.schema.labelId,
  ]),
  aggregationType: intersection([
    BlockInputAggregationType(),
    DiagramBlockInput.schema.schema.aggregationType,
  ]),
  dateFrom: intersection([
    BlockInputDateFrom(),
    DiagramBlockInput.schema.schema.dateFrom,
  ]),
  dateTo: intersection([
    BlockInputDateTo(),
    DiagramBlockInput.schema.schema.dateTo,
  ]),
  duration: intersection([
    BlockInputDuration(),
    DiagramBlockInput.schema.schema.duration,
  ]),
  amount: intersection([
    BlockInputAmount(),
    DiagramBlockInput.schema.schema.amount,
  ]),
  value: intersection([
    BlockInputValue(),
    DiagramBlockInput.schema.schema.value,
  ]),
})
