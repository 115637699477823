import { FormSection } from '@wiz/components'
import FormDialog from '@/components/Form/FormDialog'
import Fields from './Fields'
import FieldsRoles from './FieldsRoles'
import FieldsTwins from './FieldsTwins'

export default function UserBulkEdit ({
  dialog,
  onSubmit,
  onClose,
}) {
  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      dataTestid="userBulkEditForm"
      dialog={dialog}
    >
      <FormSection>
        <Fields scope="users" />
        <FieldsRoles scope="roles" />
        <FieldsTwins scope="twins" />
      </FormSection>
    </FormDialog>
  )
}
