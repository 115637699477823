import { switchMap } from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import Select from '@/components/Form/Select'
import { withObservables } from '@wiz/components'

const enhanceData = withObservables([ 'nestedBlock' ], ({ nestedBlock }) => {
  const collection = dbProvider.database.collections.get('twin_graphs')
  return ({
    options: nestedBlock ? (
      nestedBlock.observeParentTwinGraphs.pipe(
        switchMap(items => collection.query(
          Q.where('id', Q.notIn(items.map(item => item.id))),
        ).observe()),
      )
    ) : collection.query().observe(),
  })
})

const SelectTwinGraph = enhanceData(Select)

SelectTwinGraph.displayName = 'SelectTwinGraph'
export default SelectTwinGraph
