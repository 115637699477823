import { of as of$, combineLatest } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'
import Component from '@/components/TwinGraph/NavBar'

const enhanceData = withObservables([ 'nestedTwinGraphs' ], ({ nestedTwinGraphs }) => {
  nestedTwinGraphs = nestedTwinGraphs.slice(0, -1)

  return {
    options: nestedTwinGraphs.length ? (
      dbProvider.database.collections.get('diagram_blocks')
        .query(Q.where('id', Q.oneOf(nestedTwinGraphs)))
        .observe()
        .pipe(
          switchMap(items => items.length ? (
            combineLatest(items.map(item => item.observeFullSettings))
          ) : of$([])),
          map((items) => {
            const nested = []
            return items.map(item => {
              nested.push(item.blockId)
              const id = nested.join('/')
              return { id, name: item.name }
            })
          }),
        )
    ) : of$([]),
  }
})

export default enhanceData(Component)
