export default function pipelines (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      // TODO: unify this in future
      pipelines: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value (templateId) {
              let query = ''
              if (templateId) {
                query += `templateId=${templateId}`
              }
              return client.get(`/pipelines/?${query}`).fetch()
            },
          },
          getById: {
            enumerable: true,
            value (id) {
              return client.get(`/pipelines/${id}/`).fetch()
            },
          },
          deleteById: {
            enumerable: true,
            value (templateId) {
              return client.delete(`/pipelines/${templateId}/`).fetch()
            },
          },
          create: {
            enumerable: true,
            value (params) {
              return client.post('/pipelines', { ...params }).fetch()
            },
          },
          update: {
            enumerable: true,
            value (params) {
              return client.put(`/pipelines/${params.id}`, { ...params }).fetch()
            },
          },
          reconfigure: {
            enumerable: true,
            value () {
              return client.post('/pipelines/reconfigure/').fetch()
            },
          },
          getPipelineImages: {
            enumerable: true,
            value (pipelineKey) {
              let query = ''
              if (pipelineKey) {
                query += `/?key=${pipelineKey}`
              }
              return client.get(`/pipelineimages/${query}`).fetch()
            },
          },
        }),
      },
      triggersDS: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value () {
              return client.get('/triggers/').fetch()
            },
          },
        }),
      },
    })
  }
}
