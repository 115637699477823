import { useState, useCallback } from 'react'
import classnames from 'classnames'
import { Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import TwinList from '@/hoc/TwinList'
import TwinTree from '@/hoc/TwinTree'
import ContextTwinTree from '@/hoc/ContextTwinTree'
import TwinTypeList from '@/hoc/TwinTypeList'
import List from '@/components/Form/Tree/List'
import Tree from '@/components/Form/Tree/Tree'
import SelectMode from '@/components/Form/SelectMode'
import ListBadges from '@/components/Form/ListBadges'
import ItemContent from './ItemContent'

const BindedTwinTypeList = TwinTypeList(List)
const BindedTwinTypeListBadges = TwinTypeList(ListBadges)
const BindedTwinList = TwinList(List)
const BindedTwinTree = TwinTree(Tree)
const BindedContextTwinTree = ContextTwinTree(Tree)

export default function ListTwins ({
  types,
  withSensors,
  withConnections,
  className,
  onResetFilter,
  isGlobalTwins = false,
  ...props
}) {
  const intl = useIntl()
  const [ selectList, setSelectList ] = useState(null)
  const [ mode, setMode ] = useState('tree')
  const [ currentTypes, setCurrentTypes ] = useState([])

  const handleChangeType = useCallback((data) => {
    const next = currentTypes.includes(data.id) ?
      currentTypes.filter(item => item !== data.id) :
      currentTypes.concat(data.id)
    setCurrentTypes(next)
  }, [ currentTypes ])

  const handleRemoveType = useCallback((id) => {
    const next = currentTypes.filter(item => item !== id)
    setCurrentTypes(next)
  }, [ currentTypes ])

  const handleResetFilter = useCallback(() => {
    setCurrentTypes([])
    onResetFilter?.()
  }, [ onResetFilter ])

  const handleActiveType = useCallback(data => (
    currentTypes.includes(data.id)
  ), [ currentTypes ])

  const TwinTreeComponent = isGlobalTwins ? BindedContextTwinTree : BindedTwinTree
  const Component = mode === 'list' ? BindedTwinList : TwinTreeComponent

  return (
    <div className={classnames('d-flex flex-column', className)}>
      <div className="d-flex flex-wrap mx-3 mb-1">
        <div className="d-flex align-items-center flex-wrap">
          <SelectMode
            icon={false}
            checked={mode === 'list'}
            onClick={() => setMode(mode === 'list' ? null : 'list')}
          >
            <Icon name={mode === 'list' ? 'fa--bars' : 'fa--folder-tree'} />
          </SelectMode>

          <SelectMode
            checked={selectList === 'types'}
            onClick={() => setSelectList(selectList === 'types' ? null : 'types')}
          >
            {intl.t('form.fields.type')}
          </SelectMode>

          <BindedTwinTypeListBadges
            types={types}
            withSensors={withSensors}
            withConnections={withConnections}
            value={currentTypes}
            selectedOnly
            onRemove={handleRemoveType}
          />
        </div>
      </div>

      {do {
        if (selectList === 'types') {
          <BindedTwinTypeList
            className="d-flex flex-fill min-h-0"
            types={types}
            withSensors={withSensors}
            withConnections={withConnections}
            active={handleActiveType}
            onChange={handleChangeType}
          />
        } else {
          <Component
            {...props}
            className="d-flex flex-fill min-h-0"
            types={currentTypes.length ? currentTypes : types}
            withSensors={withSensors}
            withConnections={withConnections}
            Content={ItemContent}
            onResetFilter={handleResetFilter}
          />
        }
      }}
    </div>
  )
}
