import {
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react'
import difference from 'lodash/difference'
import { withProps } from '@wiz/components'
import { uuid } from '@wiz/utils'
import { wizataApi } from '@/api'
import events from '@/utils/events'
import Component from '@/components/Forms/InputSensorValueGroup/Table'

function createRow (id) {
  return {
    id: uuid(),
    sensorIds: [ id ],
    values: [
      {
        id: uuid(),
        value: undefined,
        timestamp: Date.now(),
      },
    ],
  }
}

const enhanceProps = withProps(({
  sensorIds,
}) => {
  const refSensorIds = useRef(sensorIds)
  const [ items, setItems ] = useState(sensorIds.map(createRow))

  const [ readOnly, setReadOnly ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ timestamp, setTimestamp ] = useState(Date.now())
  const [ onlyNow, setOnlyNow ] = useState(false)
  const [ singleTime, setSingleTime ] = useState(false)
  const [ errors, setErrors ] = useState([])

  const onCreateRecord = useCallback(() => {
    const rowId = uuid()
    setItems([
      ...items,
      {
        id: rowId,
        sensorIds: null,
        values: [
          {
            id: uuid(),
            value: undefined,
            timestamp: (singleTime && timestamp) || Date.now(),
          },
        ],
      },
    ])

    window.setTimeout(() => {
      document.querySelector(`.form-select[name='${rowId}.sensorIds']`)?.focus()
    }, 0)
  }, [
    items,
    singleTime,
    timestamp,
  ])

  const onRemoveRecord = useCallback((id) => {
    setItems(items.filter(item => item.id !== id))
  }, [ items ])

  const onUpdateRecord = useCallback((id, data) => {
    const next = items.map(item => (
      item.id === id ? { ...item, ...data } : item
    ))
    setItems(next)
  }, [ items ])

  const onUpdateValue = useCallback((id, valueId, data) => {
    const next = items.map(item => (
      item.id === id ? {
        ...item,
        values: item.values.map(value => (
          value.id === valueId ? { ...value, ...data } : value
        )),
      } : item
    ))
    setItems(next)
  }, [ items ])

  const onRemoveValue = useCallback((id, valueId) => {
    const next = items.map(item => (
      item.id === id ? {
        ...item,
        values: item.values.filter(value => value.id !== valueId),
      } : item
    ))
    setItems(next)
  }, [ items ])

  const onCreateValue = useCallback((id, prevValueId) => {
    const next = items.map(item => (
      item.id === id ? {
        ...item,
        values: [
          ...item.values.slice(0, item.values.findIndex(value => value.id === prevValueId) + 1),
          {
            id: uuid(),
            value: undefined,
            timestamp: (singleTime && timestamp) || Date.now(),
          },
          ...item.values.slice(item.values.findIndex(value => value.id === prevValueId) + 1),
        ],
      } : item
    ))
    setItems(next)
  }, [
    items,
    singleTime,
    timestamp,
  ])

  const onApplyTimestamp = useCallback((data) => {
    const next = items.map(item => ({
      ...item,
      values: item.values.map(value => ({
        ...value,
        timestamp: data || timestamp,
      })),
    }))
    setItems(next)
  }, [ timestamp, items ])

  const onConfirm = useCallback(() => {
    setReadOnly(true)
  }, [])

  const onDiscard = useCallback(() => {
    setReadOnly(false)
  }, [])

  const onSend = useCallback(async () => {
    setErrors([])
    const now = Date.now()
    const values = []
    for (const item of items) {
      if (item.sensorIds?.length && item.values?.length) {
        for (const sensorId of item.sensorIds) {
          for (const data of item.values) {
            if (sensorId && Number.isFinite(data.value)) {
              values.push({
                sensorId,
                value: data.value,
                timestamp: onlyNow ? now : data.timestamp,
              })
            }
          }
        }
      }
    }

    if (values.length) {
      setLoading(true)
      const data = await wizataApi.setSensorValues(values)
      setLoading(false)

      const nextErrors = data.filter(item => item.result?.error)

      if (nextErrors.length) {
        setReadOnly(false)
        setErrors(nextErrors)
        events.emit('app:notify', {
          type: 'error',
          title: 't/sensors.titleInputValue',
          message: '',
        })
      } else {
        setItems(sensorIds.map(createRow))
        setReadOnly(false)
        events.emit('app:notify', {
          type: 'success',
          title: 't/widgets.inputSensorValues.titleSend',
          message: 't/widgets.inputSensorValues.form.success.sended',
          duration: 2000,
        })
      }
    }
  }, [
    onlyNow,
    items,
    sensorIds,
  ])

  useEffect(() => {
    const created = difference(sensorIds, refSensorIds.current)
    const removed = difference(refSensorIds.current, sensorIds)
    refSensorIds.current = sensorIds
    let next = items

    if (created.length) {
      next = next.concat(created.map(createRow))
    }

    if (removed.length) {
      next = next.filter(item => !(
        item.sensorIds?.length === 1 &&
        removed.indexOf(item.sensorIds[0]) !== -1
      ))
    }

    if (next !== items) {
      setItems(next)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ sensorIds ])

  return {
    total: items.length,
    errors,
    items,
    loading,
    timestamp,
    setTimestamp,
    singleTime,
    setSingleTime,
    onlyNow,
    setOnlyNow,
    readOnly,
    onSend,
    onConfirm,
    onDiscard,
    onApplyTimestamp,
    onCreateRecord,
    onCreateValue,
    onRemoveRecord,
    onRemoveValue,
    onUpdateRecord,
    onUpdateValue,
  }
})

export default enhanceProps(Component)
