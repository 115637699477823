import {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  InteractionRequiredAuthError,
  EventType as MsalEventType,
} from '@azure/msal-browser'
import EventEmitter from 'eventemitter3'
import { isMobile, Deferred } from '@wiz/utils'
import { RIGHTS } from '@/config'
import { Auth as AWSAuth } from 'aws-amplify'
import msal from './msal'
import { AUTH_SCHEMES, awsLogin, CHALLENGE_NAME } from './cognito'
import Access from './Access'

export const EventType = {
  LoginSuccess: MsalEventType.LOGIN_SUCCESS,
  LoginFailure: MsalEventType.LOGIN_FAILURE,
}

class Auth extends EventEmitter {
  currentUser = null

  awsUser = null

  tokenRequest = null

  access = null

  authScheme = null

  scopesLogin = [
    'user.read',
  ]

  scopesApi = [
    `${process.env.RESOURCE_ID}/api`,
    // `${process.env.SIGNAL_RESOURCE_ID}/signal`,
  ]

  isReady = new Deferred()

  constructor () {
    super()
    this.access = new Access()

    msal.initialize().then(() => {
      msal.addEventCallback((message) => {
        window.setTimeout(() => this.emit(message.eventType, message), 0)
      })

      msal.handleRedirectPromise()
        .then((data) => {
          this.setActiveAccount(data?.account)
        })
        .finally(() => {
          this.isReady.resolve()
        })
    }).finally(() => {
      this.setActiveAccount()
    })
  }

  // eslint-disable-next-line class-methods-use-this
  setActiveAccount (data) {
    if (data) {
      msal.setActiveAccount(data)
      localStorage.setItem('wizata-active', data.localAccountId)
    } else {
      const id = localStorage.getItem('wizata-active')
      AWSAuth.currentSession()
        .then((session) => {
          AWSAuth.currentAuthenticatedUser().then((user) => {
            this.authScheme = AUTH_SCHEMES.aws
            this.awsUser = user
            const activeId = localStorage.getItem('wizata-active')
            if (activeId !== user.username) {
              localStorage.setItem('wizata-active', user.username) // need to understand if username is id or not
            }
          }).catch(err => err)
        })
        .catch(err => err)

      const accounts = msal.getAllAccounts()
      if (accounts.length) {
        this.authScheme = AUTH_SCHEMES.azure
        const account = accounts.find(item => item.localAccountId === id) || accounts[0]
        msal.setActiveAccount(account)

        if (account.localAccountId !== id) {
          localStorage.setItem('wizata-active', account.localAccountId)
        }
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this

  getAuthAccountId () {
    if (this.authScheme === AUTH_SCHEMES.aws) {
      return this.awsUser?.username
    }
    return msal.getActiveAccount()?.localAccountId
  }

  getCurrentUser () {
    return this.currentUser
  }

  getCurrentUserId () {
    return this.currentUser?.id
  }

  async silentLogin () {
    try {
      const response = await msal.ssoSilent({
        scopes: [
          ...this.scopesLogin,
          ...this.scopesApi,
        ],
      })

      this.setActiveAccount(response?.account)
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await this.login()
      } else {
        throw error
      }
    }
  }

  async login (data) {
    const isMob = isMobile()
    const props = {
      scopes: [
        ...this.scopesLogin,
        ...this.scopesApi,
      ],
      prompt: 'select_account',
      redirectUri: window.location.origin,
    }

    const isCognito = data?.scheme && data?.scheme === AUTH_SCHEMES.aws

    let response

    try {
      if (isCognito) {
        response = await awsLogin(data)
        if (response.challengeName !== CHALLENGE_NAME.NEW_PASSWORD_REQUIRED) {
          window.history.go(0) // !TODO: handle possible variants
        }
      } else {
        response = isMob ?
          await msal.loginRedirect(props) :
          await msal.loginPopup(props) // login popup
      }
    } catch (error) {
      if (isCognito) {
        return { error }
      }
      if (!isMob && error.errorCode === 'popup_window_error') {
        response = await msal.loginRedirect(props)
      }
    }

    if (data?.scheme) {
      this.authScheme = data?.scheme
    }

    this.setActiveAccount(response?.account)
    return response
  }

  relogin () {
    return this.login().then((response) => {
      if (response) {
        window.ReactNativeWebView?.postMessage('reload')
        window.location.reload()
      }
    })
  }

  async logout () {
    if (this.authScheme === AUTH_SCHEMES.aws) {
      await AWSAuth.signOut()
    } else {
      await msal.logoutRedirect({
        account: msal.getActiveAccount(),
      })
    }
    window.history.go(0)
    this.currentUser = null
    this.tokenRequest = null
  }

  acquireToken (forceRefresh) {
    if (this.tokenRequest) {
      return this.tokenRequest
    }

    const request = this.acquireTokenRequest(forceRefresh)
      .finally(() => {
        this.tokenRequest = null
      })

    this.tokenRequest = request
    return request
  }

  acquireTokenRequest (forceRefresh = false) {
    if (this.authScheme === AUTH_SCHEMES.aws) {
      // return AWSAuth.currentAuthenticatedUser().then((user) => {
      //   const session = user.getSignInUserSession()
      //   return session.idToken.jwtToken
      // })
      return AWSAuth.currentSession().then(data => data.getIdToken().getJwtToken())
    }

    const account = msal.getActiveAccount()
    return msal.acquireTokenSilent({
      scopes: [ ...this.scopesApi ],
      account,
      forceRefresh,
    })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          const isMob = isMobile()
          const params = {
            scopes: [ ...this.scopesApi ],
            loginHint: account.username,
            redirectUri: window.location.origin,
          }
          return isMob ?
            msal.acquireTokenRedirect(params) :
            msal.acquireTokenPopup(params)
              .catch((popupError) => {
                if (!isMob && popupError.errorCode === 'popup_window_error') {
                  return msal.acquireTokenRedirect(params)
                }
                throw popupError
              })
        }
        throw error
      })
      .then(data => (data.accessToken))
      .catch((error) => {
        throw error
      })
  }

  // eslint-disable-next-line class-methods-use-this

  checkAuthenticated () {
    if (this.authScheme === AUTH_SCHEMES.aws) {
      return !!this.awsUser
    }

    return !!msal.getActiveAccount()
  }

  updateCurrentUser (user, roles, flags) {
    this.currentUser = Object.freeze(user)
    this.access.updateAbilities(this.currentUser, roles, flags)
  }

  checkAccessRules (action, subject, field) {
    return this.access.checkAccess(action, subject, field)
  }

  checkAccessManage (subject, field) {
    return this.access.checkAccess(RIGHTS.MANAGE, subject, field)
  }

  checkAccessCreate (subject, field) {
    return this.access.checkAccess(RIGHTS.CREATE, subject, field)
  }

  checkAccessRemove (subject, field) {
    return this.access.checkAccess(RIGHTS.REMOVE, subject, field)
  }

  checkAccessShare (subject, field) {
    return this.access.checkAccess(RIGHTS.SHARE, subject, field)
  }

  checkAccessUpdate (subject, field) {
    return this.access.checkAccess(RIGHTS.UPDATE, subject, field)
  }

  checkAccessRead (subject, field) {
    return this.access.checkAccess(RIGHTS.READ, subject, field)
  }

  checkAccessCopy (subject, field) {
    return this.access.checkAccess(RIGHTS.COPY, subject, field)
  }

  checkAccessPersonal (subject, field) {
    return this.access.checkAccess(RIGHTS.PERSONAL, subject, field)
  }

  checkAccessOrganization (subject, field) {
    return this.access.checkAccess(RIGHTS.ORGANIZATION, subject, field)
  }

  throwAccessRules (action, subject, field) {
    return this.access.throwAccess(action, subject, field)
  }
}

export const auth = new Auth()

const AuthContext = createContext(auth)

export const AuthProvider = ({ children }) => {
  const [ ready, setReady ] = useState(false)

  useEffect(() => {
    auth.isReady.promise.then(() => setReady(true))
  }, [])

  return ready ? (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  ) : null
}

export const useAuth = () => useContext(AuthContext)
