import { Controller, useFormContext } from 'react-hook-form'
import {
  FormField,
  FormControl,
  FormInputFormula,
  FormFieldInline,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('formulas.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (!str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value.length > 450) {
                return intl.t('form.errors.fieldMaxlen', { max: 450 })
              }
              return true
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.description')}
        description={intl.t('formulas.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.description`}
          rules={{
            maxLength: {
              value: 450,
              message: intl.t('form.errors.fieldMaxlen', { max: 450 }),
            },
          }}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.code')}
        description={intl.t('dataSource.form.fields.codeDescr')}
        errors={get(errors, `${scope}.expression`)}
        className="d-flex flex-fill"
        vertical
        complex
      >
        <Controller
          name={`${scope}.expression`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <FormInputFormula
              {...field}
              invalid={fieldState.invalid}
              className="mt-1 flex-fill"
            />
          )}
        />
      </FormFieldInline>
    </>
  )
}
