import { useMemo } from 'react'
import { DeviceCommandTemplate as DeviceCommandTemplateModel } from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import Section from './Section'
import resolver from './resolver'
import Schema from './Schema'
import Removed from '../Removed'

export default function DeviceCommandTemplate ({
  id,
  deviceCommandTemplate,
  dialog,
  onClose,
  onSubmit,
}) {
  const defaultValues = useMemo(() => ({
    deviceCommandTemplate: DeviceCommandTemplateModel.toJSON(deviceCommandTemplate),
  }), [ deviceCommandTemplate?.updatedAt ?? deviceCommandTemplate ])

  if (id && !deviceCommandTemplate) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      schema={Schema}
      resolver={resolver}
      dataTestid="deviceCommandTemplateForm"
      dialog={dialog}
    >
      <Section
        scope="deviceCommandTemplate"
      />
    </FormDialog>
  )
}
