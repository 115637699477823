import { Controller, useFormContext } from 'react-hook-form'
import {
  FormField,
  FormFieldInline,
  FormSelect,
  FormIndeterminateCheckbox,
  FormControl,
} from '@wiz/components'
import {
  get,
  uniq,
  duration,
} from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import {
  ChartTypes,
  ChartTypesGroup,
  HistogramTypes,
  RegressionTypes,
  TimeShifts,
} from '@/config'

const FormFieldRatio = [ 8, 4 ]
const shiftFormatter = data => (data < 0 ? `${duration(-1 * data)} later` : data === 0 ? 'None' : `${duration(data)} earlier`)

export default function FieldsGeneral ({ scope }) {
  const intl = useIntl()
  const { formState: { errors }, watch, setValue } = useFormContext()
  const chartType = watch(`${scope}.chartType`)
  const regressionType = watch(`${scope}.regressionType`)
  const autoTimeShift = watch(`${scope}.autoTimeShift`)
  const isGroup = !watch(`${scope}.sourceId`)
  const chartTypes = isGroup ? ChartTypesGroup : ChartTypes
  let timeShifts = [].concat(TimeShifts)
  if (autoTimeShift) {
    timeShifts.push(autoTimeShift)
  }

  timeShifts = uniq(timeShifts)
    .sort((a, b) => (a - b))
    .map(id => ({
      id,
      name: id === autoTimeShift ? `Auto (${shiftFormatter(id)})` : shiftFormatter(id),
    }))

  return (
    <>
      <FormField
        label={intl.t('form.fields.title')}
        description={intl.t('form.fields.titleDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            maxLength: {
              value: 100,
              message: intl.t('form.errors.fieldMaxlen', { max: 100 }),
            },
          }}
          placeholder={intl.t('form.fields.namePlaceholder')}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.titleShow')}
        description={intl.t('form.fields.titleShowDescr')}
        errors={get(errors, `${scope}.showTitle`)}
      >
        <Controller
          name={`${scope}.showTitle`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>

      {isGroup ? (
        <FormField
          ratio={FormFieldRatio}
          label={intl.t('form.fields.role')}
          errors={get(errors, `${scope}.role`)}
        >
          <Controller
            name={`${scope}.role`}
            render={({ field, fieldState }) => (
              <FormSelect
                {...field}
                invalid={fieldState.invalid}
                placeholder="--"
                options={[{ id: 'grid', name: 'Grid' }]}
              />
            )}
          />
        </FormField>
      ) : null}

      <FormField
        ratio={FormFieldRatio}
        label={intl.t('form.fields.type')}
        description={intl.t('form.fields.typeDescr')}
        errors={get(errors, `${scope}.chartType`)}
      >
        <Controller
          name={`${scope}.chartType`}
          render={({ field, fieldState }) => (
            <FormSelect
              {...field}
              invalid={fieldState.invalid}
              placeholder="--"
              options={chartTypes.map(id => ({ id, name: intl.t(`enum.chart.${id}`) }))}
            />
          )}
        />
      </FormField>

      {!isGroup && (!chartType || [ 'line', 'lineRough', 'lineStep' ].includes(chartType)) ? (
        <FormFieldInline
          label={intl.t('form.fields.fillChartArea')}
          description={intl.t('form.fields.fillChartAreaDescr')}
          errors={get(errors, `${scope}.fillChartArea`)}
        >
          <FormControl
            type="checkbox"
            name={`${scope}.fillChartArea`}
            className="ms-2"
          />
        </FormFieldInline>
      ) : null}

      <FormField
        ratio={FormFieldRatio}
        label={intl.t('explorer.form.fields.histogramType')}
        description={intl.t('form.fields.histogramTypeDescr')}
        errors={get(errors, `${scope}.histogramType`)}
      >
        <Controller
          name={`${scope}.histogramType`}
          render={({ field, fieldState }) => (
            <FormSelect
              {...field}
              invalid={fieldState.invalid}
              placeholder="--"
              options={HistogramTypes.map(id => ({ id, name: intl.t(`enum.histogram.${id}`) }))}
              disabled={chartType !== 'histogram'}
            />
          )}
        />
      </FormField>

      <FormField
        ratio={FormFieldRatio}
        label={intl.t('explorer.form.fields.regressionType')}
        description={intl.t('form.fields.regressionTypeDescr')}
        errors={get(errors, `${scope}.regressionType`)}
      >
        <Controller
          name={`${scope}.regressionType`}
          render={({ field, fieldState }) => (
            <FormSelect
              {...field}
              invalid={fieldState.invalid}
              placeholder="--"
              options={RegressionTypes.map(id => ({ id, name: intl.t(`enum.regression.${id}`) }))}
            />
          )}
        />
      </FormField>

      {regressionType === 'loess' ? (
        <FormField
          ratio={FormFieldRatio}
          label={intl.t('explorer.form.fields.loessBandwidth')}
          errors={get(errors, `${scope}.loessBandwidth`)}
        >
          <FormControl
            type="number"
            name={`${scope}.loessBandwidth`}
            rules={{
              validate: {
                positive: value => (
                  !Number.isFinite(value) ||
                  value >= 0 ||
                  intl.t('form.errors.fieldMin', { min: 0 })
                ),
                lessThanOne: value => (
                  !Number.isFinite(value) ||
                  value <= 1 ||
                  intl.t('form.errors.fieldMax', { max: 1 })
                ),
              },
            }}
            min={0}
            max={1}
            scale={8}
          />
        </FormField>
      ) : null}

      <FormField
        ratio={FormFieldRatio}
        label={intl.t('explorer.form.fields.timeShift')}
        description={intl.t('form.fields.timeShiftDescr')}
        errors={get(errors, `${scope}.timeShift`)}
      >
        <Controller
          name={`${scope}.timeShift`}
          render={({ field, fieldState }) => (
            <FormSelect
              {...field}
              invalid={fieldState.invalid}
              placeholder="--"
              options={timeShifts}
            />
          )}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.chartColor')}
        description={intl.t('form.fields.chartColorDescr')}
        errors={get(errors, `${scope}.userColor`)}
      >
        <FormControl
          type="color"
          name={`${scope}.color`}
          alpha
          clearable
          onChange={(value) => {
            setValue(`${scope}.userColor`, value, {
              shouldValidate: true,
              shouldDirty: true,
            })
          }}
        />
      </FormFieldInline>
    </>
  )
}
