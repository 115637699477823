import { memo } from 'react'
import { withProps, useDidUpdate } from '@wiz/components'
import enhanceWebService from './enhanceWebService'
import enhanceInterfaces from './enhanceInterfaces'

const enhanceProps = withProps(({ webService, interfaces, onChange }) => {
  useDidUpdate(() => onChange?.({ webService, interfaces }), [ webService, interfaces, onChange ])
})

export default memo(
  enhanceWebService(
    enhanceInterfaces(
      enhanceProps(() => (null)),
    ),
  ),
)
