import { useCallback, useEffect } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  FormSection,
  FormField,
  FormControl,
  FormCheckbox,
  FormFieldInline,
} from '@wiz/components'
import {
  consts,
  get,
  validateNumberChartValues,
  validateRawSamplingDuration,
} from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import TimeSettings from '@/components/Form/TimeSettings'

export default function SectionFilters ({
  scope,
  onError,
  maxNumberChartValues,
  rawDateRangeLimit,
  rawSampleMaxCount,
}) {
  const intl = useIntl()
  const {
    formState: { errors },
    watch,
    setValue,
    setError,
    clearErrors,
  } = useFormContext()

  const [
    stepType,
    stepCustom,
    duration,
    dateFrom,
    dateTo,
  ] = watch([
    `${scope}.dataFilter.stepType`,
    `${scope}.dataFilter.stepCustom`,
    `${scope}.dataFilter.duration`,
    `${scope}.dataFilter.dateFrom`,
    `${scope}.dataFilter.dateTo`,
  ])
  const numberChartValuesError = get(errors, `${scope}.dataFilter.numberChartValues`)
  const rawDateRangeLimitError = get(errors, `${scope}.dataFilter.rawDateRangeLimit`)

  const handleValidateNumberChartValues = useCallback((params) => {
    const data = {
      stepType,
      stepCustom,
      duration,
      dateFrom,
      dateTo,
      ...params,
    }

    if (validateNumberChartValues(data, maxNumberChartValues)) {
      clearErrors(`${scope}.dataFilter.numberChartValues`)
    } else {
      setError(`${scope}.dataFilter.numberChartValues`, {
        type: 'manual',
      })
    }
  }, [
    clearErrors,
    dateFrom,
    dateTo,
    duration,
    maxNumberChartValues,
    scope,
    setError,
    stepCustom,
    stepType,
  ])

  const handleValidateRawSamplingDuration = useCallback((params) => {
    const data = {
      stepType,
      duration,
      dateFrom,
      dateTo,
      ...params,
    }

    if (validateRawSamplingDuration(data, rawDateRangeLimit)) {
      clearErrors(`${scope}.dataFilter.rawDateRangeLimit`)
    } else {
      setError(`${scope}.dataFilter.rawDateRangeLimit`, {
        type: 'manual',
      })
    }
  }, [
    clearErrors,
    dateFrom,
    dateTo,
    duration,
    rawDateRangeLimit,
    scope,
    setError,
    stepType,
  ])

  // eslint-disable-next-line no-shadow
  const validateTimeSettings = useCallback((params) => {
    handleValidateNumberChartValues(params)
    handleValidateRawSamplingDuration(params)
  }, [
    handleValidateNumberChartValues,
    handleValidateRawSamplingDuration,
  ])

  const adjustTimePeriod = useCallback(() => {
    const data = {
      duration,
      dateFrom,
      dateTo,
      stepType,
      stepCustom,
    }

    if (data.stepType !== 'custom') {
      data.stepType = 'custom'
      setValue(`${scope}.dataFilter.stepType`, data.stepType)
    }

    if (!data.stepCustom) {
      data.stepCustom = 60000
      setValue(`${scope}.dataFilter.stepCustom`, data.stepCustom)
    }

    if (data.duration) {
      data.duration = data.stepCustom * maxNumberChartValues
      setValue(`${scope}.dataFilter.duration`, data.duration)
    } else if (data.dateFrom && data.dateTo) {
      data.dateFrom = data.dateTo - data.stepCustom * maxNumberChartValues
      setValue(`${scope}.dataFilter.dateFrom`, data.dateFrom)
    }

    handleValidateNumberChartValues(data)
    handleValidateRawSamplingDuration(data)
  }, [
    scope,
    duration,
    stepType,
    stepCustom,
    dateFrom,
    dateTo,
    setValue,
    maxNumberChartValues,
    handleValidateNumberChartValues,
    handleValidateRawSamplingDuration,
  ])

  const adjustStepCustom = useCallback(() => {
    const nextStepCustom = (
      Math.ceil((duration || dateTo - dateFrom) / maxNumberChartValues / 1000) *
      1000
    )

    setValue(`${scope}.dataFilter.stepCustom`, nextStepCustom)
    clearErrors(`${scope}.dataFilter.numberChartValues`)
  }, [
    clearErrors,
    dateFrom,
    dateTo,
    duration,
    maxNumberChartValues,
    scope,
    setValue,
  ])

  const adujestRawTimePeriod = useCallback(() => {
    if (duration) {
      setValue(`${scope}.dataFilter.duration`, rawDateRangeLimit * 60 * 1000)
    } else if (dateFrom && dateTo) {
      setValue(`${scope}.dataFilter.dateFrom`, dateTo - rawDateRangeLimit * 60 * 1000)
    }

    clearErrors(`${scope}.dataFilter.rawDateRangeLimit`)
  }, [
    clearErrors,
    dateFrom,
    dateTo,
    duration,
    rawDateRangeLimit,
    scope,
    setValue,
  ])

  const selectDense = useCallback(() => {
    setValue(`${scope}.dataFilter.stepType`, 'dense')
    clearErrors(`${scope}.dataFilter.rawDateRangeLimit`)
  }, [ setValue, scope, clearErrors ])

  const error = (
    get(errors, `${scope}.dataFilter.stepType`) ||
    get(errors, `${scope}.dataFilter.stepCustom`)
  )

  useEffect(() => {
    onError?.(error)
  }, [ error, onError ])

  return (
    <FormSection>
      <TimeSettings
        className="mb-3"
        dateFromFieldName={`${scope}.dataFilter.dateFrom`}
        dateToFieldName={`${scope}.dataFilter.dateTo`}
        durationFieldName={`${scope}.dataFilter.duration`}
        offsetDurationFieldName={`${scope}.dataFilter.offsetDuration`}
        stepRequestFieldName={`${scope}.dataFilter.stepRequest`}
        onError={onError}
        validate={validateTimeSettings}
      />

      <FormField
        label={intl.t('widgets.chart.form.fields.step')}
        description={intl.t('widgets.chart.form.fields.stepDescr')}
        errors={get(errors, `${scope}.dataFilter.stepType`)}
      >
        <FormControl
          type="select"
          name={`${scope}.dataFilter.stepType`}
          options={[
            {
              id: 'raw-options',
              name: 'Raw',
              options: [
                { id: consts.StepType.Raw, name: 'Sampling' },
                { id: consts.StepType.FirstN, name: `First ${rawSampleMaxCount} values` },
                { id: consts.StepType.LastN, name: `Last ${rawSampleMaxCount} values` },
              ],
            },
            {
              id: 'aggregated-options',
              name: 'Aggregated',
              options: [
                { id: consts.StepType.Auto, name: 'Smooth' },
                { id: consts.StepType.Dense, name: 'Dense' },
                // { id: consts.StepType.Batch, name: 'Batch' },
                { id: consts.StepType.Custom, name: 'Custom' },
              ],
            },
          ]}
          onChange={(value) => {
            clearErrors(`${scope}.dataFilter.stepCustom`)
            handleValidateNumberChartValues({ stepType: value })
            handleValidateRawSamplingDuration({ stepType: value })
          }}
        />
      </FormField>

      <FormField
        label={intl.t('widgets.chart.form.fields.stepCustom')}
        description={intl.t('widgets.chart.form.fields.stepCustomDescr')}
        errors={get(errors, `${scope}.dataFilter.stepCustom`)}
      >
        <FormControl
          type="duration"
          name={`${scope}.dataFilter.stepCustom`}
          rules={{
            validate: (value) => {
              if (!value && stepType === 'custom') {
                return intl.t('form.errors.fieldRequired')
              }
              return true
            },
          }}
          onChange={(value) => {
            handleValidateNumberChartValues({ stepCustom: value })
          }}
          parts="days,hours,minutes,seconds"
          disabled={stepType !== 'custom'}
          clearable
        />
      </FormField>

      <FormFieldInline
        label={intl.t('widgets.chart.form.fields.rounded')}
        description={intl.t('widgets.chart.form.fields.roundedDescr')}
        errors={get(errors, `${scope}.dataFilter.isRounded`)}
      >
        <Controller
          shouldUnregister
          name={`${scope}.dataFilter.isRounded`}
          render={({ field, fieldState }) => (
            <FormCheckbox
              {...field}
              invalid={fieldState.invalid}
              className="ms-2"
            />
          )}
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('widgets.chart.form.fields.timestampAtEndOfPeriod')}
        description={intl.t('widgets.chart.form.fields.timestampAtEndOfPeriodDescr')}
        errors={get(errors, `${scope}.dataFilter.isTimestampAtEndOfPeriod`)}
      >
        <Controller
          shouldUnregister
          name={`${scope}.dataFilter.isTimestampAtEndOfPeriod`}
          render={({ field, fieldState }) => (
            <FormCheckbox
              {...field}
              invalid={fieldState.invalid}
              className="ms-2"
            />
          )}
        />
      </FormFieldInline>

      {numberChartValuesError ? (
        <FormFieldInline complex>
          <div className="text-danger">
            {intl.t('widgets.chart.form.errors.tooMuchValues', {
              value: Math.ceil((duration || dateTo - dateFrom) / stepCustom).toLocaleString(),
            })}
            {' '}
            <button
              type="button"
              className="btn btn-link text-reset p-0 d-inline text-decoration-underline align-baseline"
              onClick={adjustTimePeriod}
            >
              {intl.t('form.actions.adjustTimePeriod')}
            </button>
            {' '}
            {intl.t('form.fields.or')}
            {' '}
            <button
              type="button"
              className="btn btn-link text-reset p-0 d-inline text-decoration-underline align-baseline"
              onClick={adjustStepCustom}
            >
              {intl.t('form.actions.adjustStep')}
            </button>
          </div>
        </FormFieldInline>
      ) : null}

      {rawDateRangeLimitError ? (
        <FormFieldInline complex>
          <div className="text-danger">
            {intl.t('widgets.chart.form.errors.rawTimePeriodExceed', {
              value: rawDateRangeLimit,
            })}
            {' '}
            <button
              type="button"
              className="btn btn-link text-reset p-0 d-inline text-decoration-underline align-baseline"
              onClick={adujestRawTimePeriod}
            >
              {intl.t('form.actions.adjustTimePeriod')}
            </button>
            {' '}
            {intl.t('form.fields.or')}
            {' '}
            <button
              type="button"
              className="btn btn-link text-reset p-0 d-inline text-decoration-underline align-baseline"
              onClick={selectDense}
            >
              {intl.t('form.actions.selectDense')}
            </button>
          </div>
        </FormFieldInline>
      ) : null}

    </FormSection>
  )
}

SectionFilters.propTypes = {
  scope: PropTypes.string.isRequired,
  onError: PropTypes.func,
  maxNumberChartValues: PropTypes.number,
  rawDateRangeLimit: PropTypes.number,
  rawSampleMaxCount: PropTypes.number,
}

SectionFilters.defaultProps = {
  onError: undefined,
  maxNumberChartValues: undefined,
  rawDateRangeLimit: undefined,
  rawSampleMaxCount: undefined,
}
