import { Controller, useFormContext } from 'react-hook-form'
import {
  FormSection,
  FormField,
  FormFieldInline,
  FormControl,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import SelectTwin from '@/components/Form/SelectTwin'
import SelectLocation from '@/components/Form/SelectLocation'

export default function Section ({ scope, config, ...props }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <FormSection {...props}>
      <FormField
        label={intl.t('issues.form.fields.twinUnits')}
        errors={get(errors, `${scope}.units`)}
      >
        <Controller
          name={`${scope}.units`}
          render={({ field, fieldState }) => (
            <SelectTwin
              {...field}
              invalid={fieldState.invalid}
              multiselect
              placeholder={intl.t('form.fields.unitsPlaceholder')}
              filters={{
                withLocation: true,
              }}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.mapArea')}
        description={intl.t('twin.form.fields.mapAreaDescr')}
      >
        <SelectLocation scope={scope} withZoom />
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.fixedLocation')}
        description={intl.t('alert.form.fields.fixedLocationDescr')}
        errors={get(errors, `${scope}.fixedLocation`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.fixedLocation`}
          className="ms-2"
        />
      </FormFieldInline>
    </FormSection>
  )
}
