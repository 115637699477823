import {
  useCallback, useEffect, useState,
} from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import cx from 'classnames'
import { yupResolver } from '@hookform/resolvers/yup'
import { auth } from '@/auth'
import { CHALLENGE_NAME } from '@/auth/cognito'
import { useIntl } from '@wiz/intl'
import { Form, FormSection, Icon } from '@wiz/components'

const formSchema = yup.object().shape({
  email: yup.string()
    .email('Not a valid email')
    .required('Email is required'),
  password: yup.string()
    .required('Password is required')
    .min(3, 'Password must be at 3 char long'),
  newPassword: yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
    ),
})

export const CognitoLogin = ({
  open = false,
  onClose,
  scheme = '',
}) => {
  const [ err, setErr ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const [ challenge, setChallenge ] = useState(null)
  const intl = useIntl()
  const formOptions = { resolver: yupResolver(formSchema) }
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm(formOptions)

  const onConfirm = useCallback(async (data) => {
    setErr(null)
    setLoading(true)
    try {
      const authData = { scheme, ...data }
      const response = await auth.login(authData)
      if (response?.error) {
        setErr(response?.error?.message)
      } else {
        setChallenge(response)
      }
    } catch (error) {
      setErr(error.message)
    } finally {
      setLoading(false)
    }
  }, [ scheme ])

  useEffect(() => {
    setErr(null)
    setChallenge(null)
  }, [])

  if (!open) {
    return null
  }

  const placeholder = <div>&nbsp;</div>
  const newPasswordRequired = challenge?.challengeName === CHALLENGE_NAME.NEW_PASSWORD_REQUIRED

  return (
    <>
      {newPasswordRequired ? (
        <p className="card-text text-center text-danger">
          {intl.t('login.cognito.newPasswordRequired')}
        </p>
      ) : placeholder}
      <Form onSubmit={handleSubmit(onConfirm)}>
        <FormSection className="mb-0" classNameContent="bg-transparent pb-0 pt-0">
          <label className="w-100" htmlFor="name">
            {intl.t('form.fields.email')}
            <input
              {...register('email')}
              name="email"
              type="email"
              className={cx('form-control me-2 bg-transparent text-white', { 'is-invalid': errors.email })}
              disabled={newPasswordRequired}
            />
            {errors.email?.message ? <div className="invalid-feedback">{errors.email?.message}</div> : placeholder}

          </label>

          <label className="w-100" htmlFor="password">
            {intl.t('form.fields.password')}
            <input
              {...register('password')}
              type="password"
              name="password"
              className={cx('form-control me-2 bg-transparent text-white', { 'is-invalid': errors.password })}
              disabled={newPasswordRequired}
            />
            {errors.password?.message ? <div className="invalid-feedback">{errors.password?.message}</div> : placeholder}
          </label>
          {newPasswordRequired ? (
            <label className="w-100" htmlFor="newPassword">
              {intl.t('form.fields.createPassword')}
              <input
                {...register('newPassword')}
                type="password"
                name="newPassword"
                required
                className={cx('form-control me-2 bg-transparent text-white', { 'is-invalid': errors.newPassword })}
              />
              {errors.newPassword?.message ? <div className="invalid-feedback">{errors.newPassword?.message}</div> : placeholder}
            </label>
          ) : null}
        </FormSection>
        <div className="modal-footer">
          {err ? <div className="invalid-feedback d-block">{intl.t(err)}</div> : null}

          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={onClose}
          >
            {intl.t('form.actions.cancel')}
          </button>

          <button
            type="submit"
            className="btn btn-outline-primary"
          >
            {loading ? (
              <Icon
                className="me-2"
                name="fa--spinner"
                spin={loading}
              />
            ) : null }
            {intl.t('form.actions.login')}
          </button>
        </div>
      </Form>
    </>
  )
}
