import { useCallback, useEffect, useMemo } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  FormField,
  FormInputDuration,
  FormSelectNative,
  FormSelectDateTimeRange,
  FormAny,
  Nav,
} from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import FormatDateTime from '@/containers/FormatDateTime'

export default function TimeSettings ({
  className,
  dateFromFieldName,
  dateToFieldName,
  durationFieldName,
  offsetDurationFieldName,
  stepRequestFieldName,
  stepRequestDisabled,
  stepRequests,
  defaultStepRequest,
  onError,
  validate,
}) {
  const intl = useIntl()
  const {
    clearErrors,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext()

  const [
    dateFrom,
    dateTo,
    duration,
  ] = watch([
    dateFromFieldName,
    dateToFieldName,
    durationFieldName,
  ])

  const error = (
    get(errors, durationFieldName) ||
    get(errors, offsetDurationFieldName) ||
    get(errors, stepRequestFieldName) ||
    get(errors, dateFromFieldName) ||
    get(errors, dateToFieldName)
  )

  const interval = useMemo(() => (
    dateFrom && dateTo ? [ dateFrom, dateTo ] : null
  ), [ dateFrom, dateTo ])

  const handleNavChange = useCallback(() => {
    validate?.()
  }, [ validate ])

  const timeSettings = useMemo(() => [
    {
      id: 'sliding',
      name: intl.t('form.fields.slidingWindow'),
      active: !!duration,
      content: () => (
        <>
          <FormField
            label={intl.t('form.fields.slidingWindow')}
            description={intl.t('form.fields.slidingWindowDescr')}
            errors={get(errors, durationFieldName)}
          >
            <Controller
              name={durationFieldName}
              rules={{
                validate: (value) => {
                  let result = true
                  if (!value && !dateFrom) {
                    result = intl.t('form.errors.fieldOptionalRequired')
                  } else if (value && dateFrom) {
                    result = intl.t('form.errors.fieldOptionalRequired')
                  }
                  return result
                },
              }}
              render={({ field: { onChange, ...field }, fieldState }) => (
                <FormInputDuration
                  {...field}
                  invalid={fieldState.invalid}
                  clearable
                  onChange={(value) => {
                    onChange(value)
                    setValue(dateFromFieldName, null, { shouldDirty: true })
                    setValue(dateToFieldName, null, { shouldDirty: true })
                    validate?.({ duration: value })
                  }}
                />
              )}
            />
          </FormField>

          <FormField
            label={intl.t('form.fields.offsetDuration')}
            description={intl.t('form.fields.offsetDurationDescr')}
            errors={get(errors, offsetDurationFieldName)}
          >
            <Controller
              name={offsetDurationFieldName}
              render={({ field: { onChange, ...field }, fieldState }) => (
                <FormInputDuration
                  {...field}
                  invalid={fieldState.invalid}
                  clearable
                  signed
                  onChange={(value) => {
                    onChange(value)
                    setValue(dateFromFieldName, null, { shouldDirty: true })
                    setValue(dateToFieldName, null, { shouldDirty: true })
                    validate?.({ offsetDuration: value })
                  }}
                />
              )}
            />
          </FormField>

          {stepRequestFieldName ? (
            <FormField
              label={intl.t('form.fields.requestInterval')}
              description={intl.t('form.fields.requestIntervalDescr')}
              errors={get(errors, stepRequestFieldName)}
            >
              <Controller
                name={stepRequestFieldName}
                render={({ field: { onChange, value, ...field }, fieldState }) => (
                  <FormSelectNative
                    {...field}
                    invalid={fieldState.invalid}
                    value={value || defaultStepRequest || consts.StepRequest}
                    placeholder="--"
                    options={stepRequests || consts.StepRequests}
                    disabled={stepRequestDisabled}
                    onChange={(value) => {
                      onChange(Number(value || 0))
                      setValue(dateFromFieldName, null, { shouldDirty: true })
                      setValue(dateToFieldName, null, { shouldDirty: true })
                      validate?.({ requestInterval: value })
                    }}
                  />
                )}
              />
            </FormField>
          ) : null}
        </>
      ),
    },
    {
      id: 'fixed',
      name: intl.t('form.fields.fixedInterval'),
      active: !duration,
      content: () => (
        <FormField
          ratio={[ 4, 8 ]}
          label={intl.t('form.fields.interval')}
          description={intl.t('form.fields.intervalDescr')}
          errors={(
            get(errors, dateFromFieldName) ||
            get(errors, dateToFieldName)
          )}
        >
          <Controller
            name={dateToFieldName}
            render={({ field }) => (
              <FormAny {...field} />
            )}
          />

          <Controller
            name={dateFromFieldName}
            rules={{
              validate: (value) => {
                let result = true
                if (!value && !duration) {
                  result = intl.t('form.errors.fieldOptionalRequired')
                } else if (value && duration) {
                  result = intl.t('form.errors.fieldOptionalRequired')
                }
                return result
              },
            }}
            render={({ field, fieldState }) => (
              <>
                <FormAny {...field} />
                <FormSelectDateTimeRange
                  invalid={fieldState.invalid}
                  value={interval}
                  placeholder={intl.t('form.fields.datePlaceholder')}
                  placeholderFrom={intl.t('form.fields.dateFromPlaceholder')}
                  placeholderTo={intl.t('form.fields.dateToPlaceholder')}
                  DateTimeComponent={FormatDateTime}
                  clearable
                  onChange={([ df, dt ]) => {
                    setValue(dateFromFieldName, df, { shouldDirty: true })
                    setValue(dateToFieldName, dt, { shouldDirty: true })
                    setValue(durationFieldName, null, { shouldDirty: true })
                    setValue(offsetDurationFieldName, null, { shouldDirty: true })
                    setValue(stepRequestFieldName, null, { shouldDirty: true })
                    validate?.({ dateFrom: df, dateTo: dt })
                  }}
                />
              </>
            )}
          />
        </FormField>
      ),
    },
  ], [
    dateFromFieldName,
    dateToFieldName,
    durationFieldName,
    offsetDurationFieldName,
    stepRequestFieldName,
    stepRequestDisabled,
    intl,
    dateFrom,
    errors,
    setValue,
    duration,
    interval,
    defaultStepRequest,
    stepRequests,
    validate,
  ])

  useEffect(() => {
    clearErrors([
      durationFieldName,
      dateFromFieldName,
      dateToFieldName,
    ])
  }, [
    clearErrors,
    dateFrom,
    dateFromFieldName,
    dateTo,
    dateToFieldName,
    duration,
    durationFieldName,
  ])

  useEffect(() => {
    onError?.(error)
  }, [ error, onError ])

  useEffect(() => {
    validate?.()
  }, [ validate ])

  return (
    <Nav
      className={className}
      options={timeSettings}
      onChange={handleNavChange}
    />
  )
}

TimeSettings.propTypes = {
  className: PropTypes.string,
  dateFromFieldName: PropTypes.string,
  dateToFieldName: PropTypes.string,
  durationFieldName: PropTypes.string,
  offsetDurationFieldName: PropTypes.string,
  stepRequestFieldName: PropTypes.string,
  stepRequestDisabled: PropTypes.bool,
  defaultStepRequest: PropTypes.number,
  stepRequests: PropTypes.array,
  onError: PropTypes.func,
  validate: PropTypes.func,
}

TimeSettings.defaultProps = {
  className: undefined,
  dateFromFieldName: undefined,
  dateToFieldName: undefined,
  durationFieldName: undefined,
  offsetDurationFieldName: undefined,
  stepRequestFieldName: undefined,
  stepRequestDisabled: false,
  defaultStepRequest: undefined,
  stepRequests: undefined,
  onError: undefined,
  validate: undefined,
}
