import { useEffect } from 'react'
import { of as of$, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { isEmpty, uniq } from '@wiz/utils'
import { Q, dbProvider, Sensor } from '@wiz/store'
import { withObservables, withProps } from '@wiz/components'
import Component from '@/components/StreamJobs/RightBar/ListSensorsByBlock'

const enhanceData = withObservables([
  'blockSettings',
  'search',
  'sensorIds',
], ({
  blockSettings,
  search,
  sensorIds,
}) => {
  const settings = isEmpty(blockSettings) ? [] :
    Object.values(blockSettings).filter(item => !!item)

  let query = dbProvider.database.collections.get('sensors').query()

  if (sensorIds?.length) {
    query = query.extend(Q.where('id', Q.oneOf(sensorIds)))
  }

  if (search) {
    const sanitizeSearch = Q.sanitizeLikeString(search)
    query = query.extend(Q.or(
      Q.where('name', Q.like(`%${sanitizeSearch}%`)),
      Q.where('description', Q.like(`%${sanitizeSearch}%`)),
      Q.where('hardware_id', Q.like(`%${sanitizeSearch}%`)),
    ))
  }

  return {
    options: settings.length ? (
      combineLatest(settings.reduce((out, item) => (
        out.concat(item.observeDiagramContext, item.observeSensors(query))
      ), [])).pipe(
        map(items => items.flat(Infinity)),
      )
    ) : of$([]),
  }
})

const enhanceProps = withProps(({ options, onChange }) => {
  useEffect(() => {
    const selected = uniq(
      options
        .filter(item => Sensor.is(item))
        .map(item => item.id),
    )

    onChange(selected)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
})

export default enhanceData(enhanceProps(Component))
