const fontFamily = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'

module.exports = {
  font: `14px ${fontFamily}`,
  fontSm: `8px ${fontFamily}`,
  color: '#d8d9da',
  gridColor1: '#323435',
  gridColor2: '#4c4d4e',
  areaFill: '#e64600',
  equipmentFill: '#e64600',
  // emojiFont: `24px ${fontFamily}`,
  parentColor: '#6f7275',
  groupFill: '#212124',
  groupStroke: '#696969',
  descrColor: '#b2aea9',
  descrPipeColor: '#888888',
  outputFill: '#2c2c2c',
  inputFill: '#2c2c2c',
  webServiceFill: '#2c2c2c',
  sensorFill: '#d8d9da',
  widgetFill: '#1d2b3a',
}
