import { Controller, useFormContext } from 'react-hook-form'
import {
  FormField,
  FormControl,
} from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import Select from '@/components/Form/Select'
import FormatDateTime from '@/containers/FormatDateTime'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('projects.form.fields.name')}
        description={intl.t('projects.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (!str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value.length > 450) {
                return intl.t('form.errors.fieldMaxlen', { max: 450 })
              }
              return true
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('projects.form.fields.type')}
        description={intl.t('projects.form.fields.typeDescr')}
        errors={get(errors, `${scope}.type`)}
      >
        <Controller
          name={`${scope}.type`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              options={consts.ProjectTypes.map(id => ({
                id,
                name: intl.t(`enum.projectTypes.${id}`),
              }))}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('projects.form.fields.status')}
        description={intl.t('projects.form.fields.statusDescr')}
        errors={get(errors, `${scope}.status`)}
      >
        <Controller
          name={`${scope}.status`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              options={consts.ProjectStatuses.map(id => ({
                id,
                name: intl.t(`enum.projectStatuses.${id}`),
              }))}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('projects.form.fields.percentageComplete')}
        description={intl.t('projects.form.fields.percentageCompleteDescr')}
        errors={get(errors, `${scope}.percentageComplete`)}
      >
        <FormControl
          type="number"
          name={`${scope}.percentageComplete`}
          min={0}
          max={100}
          step={1}
          clearable
          optional
        />
      </FormField>

      <FormField
        label={intl.t('projects.form.fields.startDate')}
        description={intl.t('projects.form.fields.startDateDescr')}
        errors={get(errors, `${scope}.startDate`)}
      >
        <FormControl
          type="dateTime"
          name={`${scope}.startDate`}
          showTimeInput={false}
          clearable
          DateTimeComponent={FormatDateTime}
        />
      </FormField>

      <FormField
        label={intl.t('projects.form.fields.endDate')}
        description={intl.t('projects.form.fields.endDateDescr')}
        errors={get(errors, `${scope}.endDate`)}
      >
        <FormControl
          type="dateTime"
          name={`${scope}.endDate`}
          showTimeInput={false}
          clearable
          DateTimeComponent={FormatDateTime}
        />
      </FormField>

      <FormField
        label={intl.t('projects.form.fields.description')}
        description={intl.t('projects.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.description`}
          rules={{
            maxLength: {
              value: 450,
              message: intl.t('form.errors.fieldMaxlen', { max: 450 }),
            },
          }}
        />
      </FormField>
    </>
  )
}
