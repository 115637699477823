import { useCallback } from 'react'
import { withProps } from '@wiz/components'
import { dbProvider } from '@wiz/store'
import Widget from './components/Widget'

const enhanceProps = withProps(({
  widget,
  canUpdateDashboard,
  onWidgetAction,
}) => {
  const onAction = useCallback(async ({ id }) => {
    await onWidgetAction(widget, id)
  }, [ widget, onWidgetAction ])

  const onChangePoint = useCallback(async (type, point) => {
    if (!canUpdateDashboard) {
      return
    }

    const context = dbProvider.createBatchContext()
    await widget.prepareUpdateData(context, {
      config: {
        ...widget.config,
        markers: widget.config.markers.map(item => (item.type === type ? {
          ...item,
          point,
        } : item)),
      },
    })
    await dbProvider.batch(context)
  }, [ widget, canUpdateDashboard ])

  return {
    onAction,
    onChangePoint,
  }
})

export default enhanceProps(Widget)
