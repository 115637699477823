import { useRef } from 'react'
import PropTypes from 'prop-types'
import {
  FormCheckbox,
  Icon,
  DropdownColor,
  OverflowCenter,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { DataView } from '@wiz/store'
import ButtonCopy from '@/components/Form/ButtonCopy'
import ToggleActions from '@/components/Form/ToggleActions'

export default function DataViewRow ({
  data,
  context,
}) {
  const refColor = useRef()
  const intl = useIntl()
  const { onAction } = context

  return (
    <>
      <FormCheckbox
        className="me-2"
        checked={data.checked}
        onChange={checked => onAction('update', { ...data, checked })}
      />

      <button
        ref={refColor}
        type="button"
        className="btn p-0 me-2"
        style={{ color: data.color }}
      >
        <Icon name="fa--circle" size="xs" />
      </button>

      <DropdownColor
        autoclose
        arrow
        mini
        target={refColor}
        value={data.color}
        onChange={color => onAction('update', { ...data, userColor: color })}
      />

      <OverflowCenter
        value={DataView.getDisplayName(data)}
      />

      {data.source?.sensor?.hardwareId ? (
        <ButtonCopy
          className="hover-inline-flex"
          value={data.source.sensor.hardwareId}
          title={intl.t('message.copy.hardwareId', { value: data.source.sensor.hardwareId })}
        />
      ) : null}

      <ToggleActions className="ms-auto">
        <button
          type="button"
          className="btn btn-sm btn-fill-secondary"
          onClick={() => onAction('remove', data)}
        >
          <Icon name="fa--trash-alt" />
        </button>

        <button
          type="button"
          className="btn btn-sm btn-fill-secondary ms-1"
          onClick={() => onAction('edit', data)}
        >
          <Icon name="fa--edit" />
        </button>
      </ToggleActions>
    </>
  )
}

DataViewRow.propTypes = {
  data: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
}
