/* eslint-disable no-unused-expressions */
import { useMemo, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {
  useFormContext,
  useFieldArray,
  useWatch,
} from 'react-hook-form'
import { Icon } from '@wiz/components'
import {
  randomColor,
  uuid,
  debounce,
  get,
  has,
} from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function InputBins ({
  className,
  name,
  canEditFirst = false,
  canEditColor = true,
  canRemoveLast = false,
  showMinLimit = false,
  showMaxLimit = false,
  min = 0,
  max,
  minFirst,
  maxFirst = 0,
  precision = 1,
  keyName = '_id',
  titleAddBin = 't/form.actions.addBin',
  titleBinsNotFound = 't/form.info.binsNotFound',
}) {
  const intl = useIntl()
  const {
    setValue,
    trigger,
    register,
    clearErrors,
    formState: { errors },
  } = useFormContext()

  const { fields } = useFieldArray({
    name,
    keyName,
    shouldUnregister: true,
  })

  const len = fields.length
  const watchFieldArray = useWatch({ name })
  const controlledFields = fields
    .map((field, index) => ({
      ...field,
      ...watchFieldArray?.[index],
    }))
    .map((field, index) => ({
      ...field,
      value: index === len - 1 ? null : field.value,
      prevValue: index === 0 ? field.prevValue : null,
    }))

  const getValue = idx => (controlledFields[idx]?.value ?? null)
  const getPrevValue = idx => (controlledFields[idx - 1]?.value ?? (showMinLimit ? min : null))
  const getNextValue = idx => (controlledFields[idx + 1]?.value ?? null)

  const handleUpdate = useMemo(() => debounce(() => {
    if (len > 0) {
      clearErrors(Array.from(new Array(len)).map((_, i) => (`${name}.${i}.value`)))
      trigger(Array.from(new Array(len)).map((_, idx) => (`${name}.${idx}.value`)))
    }
  }, 100), [ trigger, clearErrors, name, len ])

  const handleRemoveBin = (idx) => {
    clearErrors(Array.from(new Array(len)).map((_, i) => (`${name}.${i}.value`)))
    let next = [
      ...controlledFields.slice(0, idx),
      ...controlledFields.slice(idx + 1),
    ]
    next = [
      ...next.slice(0, next.length - 1),
      ...next.slice(next.length - 1).map(item => ({ ...item, value: null })),
    ]
    setValue(name, next, { shouldDirty: true })
  }

  const handleAddBin = () => {
    clearErrors(Array.from(new Array(len)).map((_, i) => (`${name}.${i}.value`)))
    const value = len ? Math.max(...controlledFields.map(item => item.value || 0), 0) + 1 : 0
    const bin = {
      id: uuid(),
      name: '',
      prevValue: null,
      value,
      ...(canEditColor ? {
        color: randomColor(),
      } : {}),
    }

    const next = [
      ...controlledFields.slice(0, len - 1),
      bin,
      ...controlledFields.slice(len - 1).map(item => ({ ...item, value: null })),
    ]

    setValue(name, next, { shouldDirty: true })
  }

  useEffect(() => () => {
    handleUpdate()
    return () => {
      handleUpdate?.cancel()
    }
  }, [ handleUpdate ])

  if (!len) {
    return (
      <div className={classnames('d-flex flex-column align-items-center', className)}>
        {intl.t(titleBinsNotFound)}
        <button
          type="button"
          className="btn btn-outline-primary mt-2"
          onClick={handleAddBin}
        >
          <Icon name="fa--plus" className="me-1" />
          {intl.t(titleAddBin)}
        </button>
      </div>
    )
  }

  return (
    <div className={classnames('d-flex flex-column', className)}>
      <button
        type="button"
        className="btn btn-outline-primary mb-3"
        onClick={handleAddBin}
      >
        <Icon name="fa--plus" className="me-1" />
        {intl.t(titleAddBin)}
      </button>

      <div>
        {controlledFields.map((item, idx) => {
          const scope = `${name}.${idx}`
          const isLast = idx === len - 1
          const isFirst = idx === 0
          const canEditStartValue = (
            isFirst &&
            canEditFirst &&
            len > 1
          )

          const error = (
            get(errors, `${scope}.color`) ||
            get(errors, `${scope}.value`) ||
            get(errors, `${scope}.prevValue`) ||
            get(errors, `${scope}.name`)
          )

          const placeholder = do {
            const prev = getPrevValue(idx) || 0
            const value = getValue(idx) || 0
            if (isLast) {
              if (showMaxLimit) {
                if (prev < max) {
                  `${prev} - ${max}`
                } else {
                  max
                }
              } else {
                `> ${prev}`
              }
            } else if (isFirst && !prev) {
              `< ${value}`
            } else {
              `${prev} - ${value}`
            }
          }

          const registerValue = isLast ? null : register(`${scope}.value`, {
            shouldUnregister: true,
            setValueAs: (value) => {
              const next = Number(value)
              return Number.isNaN(next) ? 0 : next
            },
            validate: {
              min: (value) => {
                if (isLast || !Number.isFinite(min)) {
                  return true
                }
                return (
                  value === null ||
                  value === '' ||
                  parseInt(value || 0, 10) >= min ||
                  intl.t('form.errors.fieldMin', { min })
                )
              },
              max: (value) => {
                if (isLast || !Number.isFinite(max)) {
                  return true
                }

                return (
                  value === null ||
                  value === '' ||
                  parseInt(value || 0, 10) <= max ||
                  intl.t('form.errors.fieldMax', { max })
                )
              },
              bins: (value) => {
                if (isLast) {
                  return true
                }

                const prev = getPrevValue(idx)
                if (prev !== null && value <= prev) {
                  return intl.t('form.errors.mustBeMore', { value: prev })
                }

                const nextValue = getNextValue(idx) || null

                if (nextValue !== null && value >= nextValue) {
                  return intl.t('form.errors.mustBeLess', { value: nextValue })
                }

                return true
              },
            },
          })

          const registerPrevValue = !canEditStartValue ? null : register(`${scope}.prevValue`, {
            shouldUnregister: true,
            setValueAs: (value) => {
              const next = value && value !== '-' ? Number(value) : 0
              return Number.isNaN(next) ? 0 : next
            },
            validate: {
              min: (value) => {
                if (canEditStartValue && Number.isFinite(minFirst)) {
                  return (
                    parseInt(value || 0, 10) >= minFirst ||
                    intl.t('form.errors.fieldMin', { min: minFirst })
                  )
                }
                return true
              },
              max: (value) => {
                if (canEditStartValue && Number.isFinite(maxFirst)) {
                  return (
                    parseInt(value || 0, 10) <= maxFirst ||
                    intl.t('form.errors.fieldMax', { max: maxFirst })
                  )
                }
                return true
              },
            },
          })

          return (
            <Fragment key={item.id}>
              <div
                className={classnames('row g-2', {
                  'is-invalid': !!error,
                  'mb-2': !isLast,
                })}
              >
                <input
                  {...register(`${scope}.id`)}
                  type="hidden"
                />

                {canEditColor ? (
                  <div className="col-1">
                    <input
                      {...register(`${scope}.color`, {
                        required: intl.t('form.errors.fieldRequired'),
                      })}
                      type="color"
                      className="form-control form-control-color me-1"
                    />
                  </div>
                ) : null}

                <div className="col-3">
                  {registerPrevValue ? (
                    <input
                      {...registerPrevValue}
                      type="number"
                      step="0.1"
                      precision={precision}
                      className={classnames('form-control me-2', {
                        'is-invalid': has(errors, `${scope}.prevValue`),
                      })}
                      min={minFirst}
                      max={maxFirst}
                    />
                  ) : (
                    <input
                      // {...register(`${scope}.prevValue`, {
                      //   shouldUnregister: true,
                      // })}
                      type="text"
                      className="form-control me-2"
                      placeholder={getPrevValue(idx) || 0}
                      readOnly
                    />
                  )}
                </div>

                <div className="col-3">
                  {registerValue ? (
                    <input
                      {...registerValue}
                      type="number"
                      placeholder={placeholder}
                      className={classnames('form-control me-2', {
                        'is-invalid': has(errors, `${scope}.value`),
                      })}
                      min={min}
                      max={max}
                      precision={precision}
                      step={0.1}
                      onChange={(event) => {
                        registerValue.onChange(event)
                        handleUpdate()
                      }}
                    />
                  ) : (
                    <input
                      // {...register(`${scope}.value`, {
                      //   shouldUnregister: true,
                      //   setValueAs: (value) => {
                      //     if (value === null) {
                      //       return null
                      //     }
                      //     const next = Number(value)
                      //     return Number.isNaN(next) ? 0 : next
                      //   },
                      // })}
                      className="form-control me-2"
                      placeholder={placeholder}
                      readOnly
                    />
                  )}
                </div>

                <div className="col">
                  <input
                    {...register(`${scope}.name`, {
                      maxLength: {
                        value: 50,
                        message: intl.t('form.errors.fieldMaxlen', { max: 50 }),
                      },
                    })}
                    type="search"
                    maxLength="50"
                    placeholder={placeholder}
                    className={classnames('form-control me-2', {
                      'is-invalid': has(errors, `${scope}.name`),
                    })}
                  />
                </div>

                <div className="col-1 d-flex">
                  <button
                    type="button"
                    className="btn btn-flat-secondary btn-text flex-fill"
                    title={intl.t('form.actions.remove')}
                    disabled={(isLast || controlledFields.length <= 2) && !canRemoveLast}
                    onClick={() => handleRemoveBin(idx)}
                  >
                    <Icon name="fa--trash-alt" />
                  </button>
                </div>
              </div>
              {error?.message ? (
                <div className="invalid-feedback mb-2 mt-0">
                  {error.message}
                </div>
              ) : null}
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

InputBins.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  canEditFirst: PropTypes.bool,
  canEditColor: PropTypes.bool,
  canRemoveLast: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  minFirst: PropTypes.number,
  maxFirst: PropTypes.number,
  precision: PropTypes.number,
  keyName: PropTypes.string,
  showMinLimit: PropTypes.bool,
  showMaxLimit: PropTypes.bool,
  titleAddBin: PropTypes.string,
  titleBinsNotFound: PropTypes.string,
}

InputBins.defaultProps = {
  className: undefined,
  canEditFirst: false,
  canEditColor: true,
  canRemoveLast: false,
  min: undefined,
  max: undefined,
  minFirst: undefined,
  maxFirst: undefined,
  precision: undefined,
  keyName: '_id',
  showMinLimit: false,
  showMaxLimit: false,
  titleAddBin: 't/form.actions.addBin',
  titleBinsNotFound: 't/form.info.binsNotFound',
}
