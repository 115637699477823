import { useCallback, useState } from 'react'
import { withProps } from '@wiz/components'

export default withProps(() => {
  const [ solutions, setSolutions ] = useState([])

  return {
    solutions,
  }
})
