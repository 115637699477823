export default function registrations (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      registrations: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value (templateId, twinId) {
              let query = ''

              if (templateId) {
                query += `templateId=${templateId}&`
              }
              if (twinId) {
                query += `twinId=${twinId}&`
              }

              return client.get(`/registrations/?${query}`).fetch()
            },
          },
          create: {
            enumerable: true,
            value ({ payload }) {
              return client.post('/registrations/', payload).fetch()
            },
          },
          update: {
            enumerable: true,
            value ({ id, payload }) {
              return client.put(`/registrations/${id}`, payload).fetch()
            },
          },
          deleteById: {
            enumerable: true,
            value (id) {
              return client.delete(`/registrations/${id}`).fetch()
            },
          },
          getProperties: {
            enumerable: true,
            value (registrationId) {
              return client.get(`/registrationproperties/?registrationId=${registrationId}`).fetch()
            },
          },
        }),
      },
    })
  }
}
