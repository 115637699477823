import { useCallback, useMemo, memo } from 'react'
import { Q, dbProvider } from '@wiz/store'
import { uniq } from '@wiz/utils'
import { withProps } from '@wiz/components'
import Component from '@/components/Forms/TwinBulkEdit'

const enhanceProps = withProps(({ twins, twinIds }) => {
  const selectedIds = useMemo(() => uniq(
    [].concat(
      twins.map(item => item.id),
      twinIds,
    ).filter(Boolean),
  ), [ twins, twinIds ])

  const onSubmit = useCallback(async (data) => {
    if (!data.selected?.length || !selectedIds?.length) {
      return
    }

    const nextData = {}
    for (const name of data.selected) {
      nextData[name] = data.twins[name]
    }

    const items = await dbProvider.database.collections.get('twins')
      .query(Q.where('id', Q.oneOf(selectedIds)))
      .fetch()

    const context = dbProvider.createBatchContext()
    for (const item of items) {
      await item.prepareUpdateData(context, nextData)
    }
    await dbProvider.batch(context)
  }, [ selectedIds ])

  return {
    onSubmit,
    selectedIds,
  }
})

export default memo(enhanceProps(Component))
