/**
 * https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-2.1
 * https://docs.microsoft.com/en-us/javascript/api/%40aspnet/signalr/hubconnectionbuilder?view=signalr-js-latest
 * https://github.com/aspnet/SignalR/tree/release/2.2/clients/ts/signalr
 */
import { DateTime } from 'luxon'
import * as signalR from '@microsoft/signalr'
import events from '@/utils/events'
import { auth } from '@/auth'
import { appEnv } from '@/config'
import { network, debounce } from '@wiz/utils'
import { Notification } from '@wiz/store'

class SignalAPI {
  constructor () {
    this.reconnectSensorsStreamLazy = debounce(this.reconnectSensorsStream, 1000)

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(process.env.SIGNAL_API_BASE_URL, {
        accessTokenFactory: () => auth.acquireToken(),
      })
      .configureLogging(signalR.LogLevel.Error) // signalR.LogLevel.Error signalR.LogLevel.Trace
      .build()

    this.connection.onclose(this.handleErrorConnect)

    events.addListener('app:ready', this.handleAppReady)
    network.on('status', this.handleNetworkStatus)
  }

  connection = null

  sensorsStream = null

  reconnectionTime = 0

  reconnectionTimer = 0

  checkVersionTimer = 0

  lockConnection = false

  SignalStream = {
    EventsRefresh: 'TriggerEventsRefresh',
  }

  destroy () {
    events.removeListener('app:ready', this.handleAppReady)
    network.removeListener('status', this.handleNetworkStatus)
    this.disconnect()
    this.connection = null
  }

  handleNetworkStatus = (onLine) => {
    if (onLine) {
      this.connect()
    } else {
      this.disconnect()
    }
  }

  disconnect = () => {
    this.reconnectSensorsStreamLazy.cancel()

    this.connection.stop()
      .then(this.handleSuccessDisconnect)

    this.sensorsStream = null

    window.clearTimeout(this.checkVersionTimer)
    this.checkVersionTimer = null
  }

  reconnect = () => {
    if (!network.onLine) {
      return
    }

    this.reconnectionTime = Math.min(this.reconnectionTime + 3000, 30000)
    window.clearTimeout(this.reconnectionTimer)
    this.reconnectionTimer = window.setTimeout(this.connect, this.reconnectionTime)
  }

  connect = () => {
    if (!network.onLine || this.lockConnection) {
      return
    }

    this.lockConnection = true

    this.connection.start({ withCredentials: false })
      .then(this.handleSuccessConnect)
      .catch(this.handleErrorConnect)

    this.checkVersion()
  }

  reconnectSensorsStream = () => {
    if (this.sensorsStream) {
      this.sensorsStream.dispose()
      this.sensorsStream = null
    }

    if (this.connection.state !== 1) {
      return
    }

    const sensorIds = events.eventNames()
      .filter(item => item.indexOf('services:signal:sensor:') !== -1)
      .map(item => item.replace('services:signal:sensor:', ''))

    if (sensorIds.length) {
      this.sensorsStream = this.connection.stream('GetSensorValuesFiltered', sensorIds, 1) // GetSensorValues
        .subscribe({
          next: this.handleGetSensorValuesFiltered,
        })
    }
  }

  checkVersion = () => {
    if (
      !appEnv.VERSION_URL ||
      !appEnv.VERSION ||
      appEnv.VERSION === 'dev'
    ) {
      return
    }

    this.checkVersionTimer = 0

    fetch(appEnv.VERSION_URL, {
      cache: 'no-cache',
      credentials: 'same-origin',
    })
      .then(result => result.text())
      .then((text) => {
        const version = text.match(/^.+/)?.[0]
        if (version && version !== appEnv.VERSION) {
          events.emit('app:version', version)
        }

        if (this.checkVersionTimer !== null) {
          this.checkVersionTimer = window.setTimeout(this.checkVersion, 5 * 60 * 1000)
        }
      })
      .catch(() => {
        if (this.checkVersionTimer !== null) {
          this.checkVersionTimer = window.setTimeout(this.checkVersion, 5 * 60 * 1000)
        }
      })
  }

  registerStream (name, callback) {
    this.connection.off(name, callback)
    this.connection.on(name, callback)
  }

  unregisterStream (name, callback) {
    this.connection.off(name, callback)
  }

  handleAppReady = (isReady) => {
    if (isReady) {
      this.connect()
    } else {
      this.disconnect()
    }
  }

  handleErrorConnect = (error) => {
    this.lockConnection = false
    events.emit('services:signal:connection:error', error)
    this.reconnectSensorsStreamLazy.cancel()
    this.reconnect()
  }

  // eslint-disable-next-line class-methods-use-this
  handleConnectionAlert = (message) => {
    try {
      events.emit('services:signal:alert', Notification.fromPayload(JSON.parse(message)))
    } catch (e) {
      // empty
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleConnectionInformation = (message) => {
    if (message) {
      events.emit('services:signal:info', message)
      events.emit('app:notify', {
        type: 'info',
        message,
      })
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleConnectionDataSynchronization = () => {
    events.emit('services:signal:synchronization')
  }

  handleSuccessConnect = () => {
    this.reconnectSensorsStreamLazy()

    this.connection.off('Alert', this.handleConnectionAlert)
    this.connection.on('Alert', this.handleConnectionAlert)

    this.connection.off('Information', this.handleConnectionInformation)
    this.connection.on('Information', this.handleConnectionInformation)

    this.connection.off('DataSynchronization', this.handleConnectionDataSynchronization)
    this.connection.on('DataSynchronization', this.handleConnectionDataSynchronization)

    this.lockConnection = false
    this.reconnectionTime = 0
    window.clearTimeout(this.reconnectionTimer)
    events.emit('services:signal:connection', true)
  }

  handleSuccessDisconnect = () => {
    this.reconnectionTime = 0
    window.clearTimeout(this.reconnectionTimer)
    events.emit('services:signal:connection', false)
  }

  handleGetSensorValuesFiltered = (event) => {
    try {
      event = JSON.parse(event)
      const count = events.listenerCount(`services:signal:sensor:${event.HardwareId}`)

      if (count) {
        event = {
          hardwareId: event.HardwareId,
          value: event.SensorValue,
          timestamp: DateTime.fromISO(event.Timestamp, { setZone: false }).toMillis(),
        }

        events.emit(`services:signal:sensor:${event.hardwareId}`, event)
      }
    } catch (error) {
      console.error(error)
    }
  }
}

export const signalApi = new SignalAPI()

export function registerSensorStream (hardwareId, callback) {
  events.addListener(`services:signal:sensor:${hardwareId}`, callback)
  signalApi.reconnectSensorsStreamLazy()
}

export function unregisterSensorStream (hardwareId, callback) {
  events.removeListener(`services:signal:sensor:${hardwareId}`, callback)
  signalApi.reconnectSensorsStreamLazy()
}

// export function registerMetricsStream (callback) {
//   events.addListener('services:signal:metrics', callback)
// }

// export function unregisterMetricsStream (callback) {
//   events.removeListener('services:signal:metrics', callback)
// }

export function registerAlertStream (callback) {
  events.addListener('services:signal:alert', callback)
}

export function unregisterAlertStream (callback) {
  events.removeListener('services:signal:alert', callback)
}

export function registerInfoStream (callback) {
  events.addListener('services:signal:info', callback)
}

export function unregisterInfoStream (callback) {
  events.removeListener('services:signal:info', callback)
}

export function registerDataSyncStream (callback) {
  events.addListener('services:signal:synchronization', callback)
}

export function unregisterDataSyncStream (callback) {
  events.removeListener('services:signal:synchronization', callback)
}
