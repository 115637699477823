import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import { get } from '@wiz/utils'
import {
  FormField, FormControl, FormFieldInline, FormCheckbox,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormFieldInline
        label={intl.t('entities.form.fields.hasAnomalies')}
        description={intl.t('entities.form.fields.hasAnomaliesDescr')}
        errors={get(errors, `${scope}.hasAnomalies`)}
      >
        <Controller
          shouldUnregister
          name={`${scope}.hasAnomalies`}
          render={({ field, fieldState }) => (
            <FormCheckbox
              {...field}
              invalid={fieldState.invalid}
              className="ms-2"
              disabled
              onChange={() => {
              }}
            />
          )}
        />
      </FormFieldInline>
      <FormFieldInline
        label={intl.t('entities.form.fields.hasTargetFeat')}
        description={intl.t('entities.form.fields.hasTargetFeatDescr')}
        errors={get(errors, `${scope}.hasTargetFeat`)}
      >
        <Controller
          shouldUnregister
          name={`${scope}.hasTargetFeat`}
          render={({ field, fieldState }) => (
            <FormCheckbox
              {...field}
              invalid={fieldState.invalid}
              className="ms-2"
              disabled
              onChange={() => {
              }}
            />
          )}
        />
      </FormFieldInline>
      <FormFieldInline
        label={intl.t('entities.form.fields.canGeneratePlot')}
        description={intl.t('entities.form.fields.canGeneratePlotDescr')}
        errors={get(errors, `${scope}.canGeneratePlot`)}
      >
        <Controller
          shouldUnregister
          name={`${scope}.canGeneratePlot`}
          render={({ field, fieldState }) => (
            <FormCheckbox
              {...field}
              invalid={fieldState.invalid}
              className="ms-2"
              disabled
              onChange={() => {
              }}
            />
          )}
        />
      </FormFieldInline>
      <FormFieldInline
        label={intl.t('entities.form.fields.needExactColumnNames')}
        description={intl.t('entities.form.fields.needExactColumnNamesDescr')}
        errors={get(errors, `${scope}.needExactColumnNames`)}
      >
        <Controller
          shouldUnregister
          name={`${scope}.needExactColumnNames`}
          render={({ field, fieldState }) => (
            <FormCheckbox
              {...field}
              invalid={fieldState.invalid}
              className="ms-2"
              disabled
              onChange={() => {
              }}
            />
          )}
        />
      </FormFieldInline>
      <FormFieldInline
        label={intl.t('entities.form.fields.needExactColumnNumbers')}
        description={intl.t('entities.form.fields.needExactColumnNumbersDescr')}
        errors={get(errors, `${scope}.needExactColumnNumbers`)}
      >
        <Controller
          shouldUnregister
          name={`${scope}.needExactColumnNumbers`}
          render={({ field, fieldState }) => (
            <FormCheckbox
              {...field}
              invalid={fieldState.invalid}
              className="ms-2"
              disabled
              onChange={() => {
              }}
            />
          )}
        />
      </FormFieldInline>
      <FormField
        label={intl.t('entities.form.fields.inputColumns')}
        description={intl.t('entities.form.fields.inputColumnsDescr')}
        errors={get(errors, `${scope}.inputColumns`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.inputColumns`}
          rows={10}
          disabled
        />
      </FormField>
      <FormField
        label={intl.t('entities.form.fields.outputColumns')}
        description={intl.t('entities.form.fields.outputColumnsDescr')}
        errors={get(errors, `${scope}.outputColumns`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.outputColumns`}
          rows={10}
          disabled
        />
      </FormField>
    </>
  )
}

Fields.propTypes = {
  scope: PropTypes.string.isRequired,
}
