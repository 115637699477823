import { memo } from 'react'
import { withProps, useDidUpdate } from '@wiz/components'
import enhanceAlert from './enhanceAlert'
import enhanceAlertSheet from '../NotificationSheet/enhanceAlertSheet'
import enhanceSheetTargets from '../NotificationSheet/enhanceSheetTargets'

const enhanceProps = withProps(({
  alert,
  notificationSheet,
  notificationSheetTargets,
  onChange,
}) => {
  useDidUpdate(() => (
    onChange?.({ alert, notificationSheet, notificationSheetTargets })
  ), [ alert, notificationSheet, notificationSheetTargets, onChange ])
})

export default memo(
  enhanceAlert(
    enhanceAlertSheet(
      enhanceSheetTargets(
        enhanceProps(() => (null)),
      ),
    ),
  ),
)
