import { useState, useEffect } from 'react'
import { dbProvider } from '@wiz/store'

export default function useSyncStatus () {
  const [ status, setStatus ] = useState(dbProvider.sync.getStatus())

  useEffect(() => {
    dbProvider.sync.on('status', setStatus)
    return () => dbProvider.sync.removeListener('status', setStatus)
  }, [])

  return status
}
