import { useFormContext, Controller } from 'react-hook-form'
import { FormSection, FormField, FormControl } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import SelectSensor from '@/components/Form/SelectSensor'
import { useEffect } from 'react'

export default function TimeSettingsSection ({ scope, sensorIds, ...props }) {
  const intl = useIntl()
  const {
    register, formState: { errors }, watch, clearErrors,
  } = useFormContext()

  const type = watch(`${scope}.outputTimestampType`)

  useEffect(() => {
    // clear error if user tried to save with dataPoint type
    // and empty input dataPoint and then changed type to current
    // (without this it will keep error and will not give user a chance to save it)
    if (type === 'current') {
      clearErrors([ `${scope}.outputTimestampSensorId` ])
    }
  }, [ type, clearErrors, scope ])

  return (
    <FormSection {...props}>
      <input
        {...register(`${scope}.id`)}
        type="hidden"
      />

      <FormField
        label={intl.t('form.fields.outputTimestampType')}
        description={intl.t('blockInput.form.fields.outputTimestampTypeDescr')}
        errors={get(errors, `${scope}.outputTimestampType`)}
      >
        <FormControl
          shouldUnregister
          type="select"
          defaultValue="current"
          name={`${scope}.outputTimestampType`}
          options={[
            { id: 'current', name: intl.t('enum.timeSettings.current') },
            { id: 'dataPoint', name: intl.t('enum.timeSettings.dataPoint') },
          ]}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.outputTimestampSensorId')}
        description={intl.t('blockOutput.form.fields.outputTimestampSensorIdDescr')}
        errors={get(errors, `${scope}.outputTimestampSensorId`)}
      >
        <Controller
          name={`${scope}.outputTimestampSensorId`}
          rules={{ required: type === 'dataPoint' ? intl.t('form.errors.fieldRequired') : false }}
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              sensorIds={sensorIds}
              disabled={type !== 'dataPoint'}
              noData={!sensorIds?.length}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorPlaceholder')}
            />
          )}
        />
      </FormField>
    </FormSection>
  )
}
