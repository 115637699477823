import {
  forwardRef,
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  Dialog,
  DropdownMenu,
  FormInputSearch,
  Icon,
  useDebounce,
  useMobile,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import DashboardList from '@/hoc/DashboardList'
import List from '@/components/Form/Tree/List'
import LeftBarListContent from './LeftBarListContent'
import Filters from './Filters'

const BindedList = DashboardList(List)

const LeftBar = forwardRef(({
  filters,
  settings,
  dashboard,
  openDashboard,
  setEditId,
  onFilter,
  onActionMenu,
  onClose,
}, ref) => {
  const auth = useAuth()
  const intl = useIntl()
  const wait = useDebounce(300) // open dialog delay
  const refSearch = useRef()
  const refList = useRef()
  const refMenu = useRef()
  const refDialog = useRef()
  const [ search, setSearch ] = useState('')
  const [ menuTarget, setMenuTarget ] = useState(null)
  const [ menuData, setMenuData ] = useState(null)
  const isMobile = useMobile()

  // const [ showTwinForm, setShowTwinForm ] = useState(false)

  const menuOptions = useMemo(() => {
    if (!menuData) {
      return []
    }

    const isFavorite = settings.spotlightFavorites?.includes(menuData.target.id) ?? false
    const isDefault = menuData.target.id === settings.homepageDefaultId

    return [
      {
        id: 'edit',
        label: intl.t('form.actions.edit'),
        icon: 'fa--edit',
        disabled: !auth.checkAccessUpdate(menuData.target),
      },
      {
        id: 'select',
        label: intl.t('form.actions.setDefault'),
        icon: 'fa--paperclip',
        disabled: isDefault,
      },
      {
        id: 'favorite',
        icon: isFavorite ? 'fa--star' : 'far--star',
        label: isFavorite ?
          intl.t('form.actions.removeFavorite') :
          intl.t('form.actions.addFavorite'),
      },
      {
        id: 'duplicate',
        label: intl.t('form.actions.duplicate'),
        icon: 'fa--clone',
        disabled: !auth.checkAccessCopy(menuData.target) || !auth.checkAccessCreate('Dashboard'),
      },
      {
        id: 'remove',
        label: intl.t('form.actions.remove'),
        icon: 'fa--trash-alt',
        disabled: isDefault || !auth.checkAccessRemove(menuData.target),
      },
    ]
  }, [
    intl,
    auth,
    settings,
    menuData,
  ])

  const handleActionMenu = useCallback((params) => {
    if (params.id === 'edit') {
      setEditId(menuData.target.id)
    } else {
      onActionMenu?.(params, menuData.target, menuData)
    }
  }, [
    menuData,
    setEditId,
    onActionMenu,
  ])

  const handleActive = useCallback(data => (
    data.id === dashboard.id
  ), [ dashboard ])

  const handleAction = useCallback((event, name, data) => {
    if (name === 'more') {
      const target = event.currentTarget
      refMenu.current.close()
      // wait to update DOM
      window.setTimeout(() => {
        setMenuTarget(target)
        setMenuData({ target: data })
      }, 0)
    } else if (name === 'open') {
      openDashboard(data)
      if (isMobile) {
        (ref?.current || refDialog.current)?.close()
      }
    }
  }, [ openDashboard, isMobile, ref ])

  useEffect(() => {
    if (!wait && dashboard && refList.current) {
      refList.current?.scrollTo(dashboard.id)
    }
  }, [
    wait,
    dashboard,
  ])

  useEffect(() => {
    // wait to update DOM
    const timeout = menuTarget ? window.setTimeout(() => refMenu.current.open(), 0) : 0
    return () => window.clearTimeout(timeout)
  }, [ menuTarget ])

  return (
    <>
      <Dialog
        ref={ref || refDialog}
        classNameBody="p-0 overflow-hidden position-relative"
        classTitle="fs-inherit lh-inherit"
        classHeader="pb-0"
        container={null}
        strategy="absolute"
        orient="left"
        backdrop={false}
        resize
        size="sm"
        ComponentTitle="div"
        title={() => (
          <FormInputSearch
            ref={refSearch}
            className="form-control-sm"
            placeholder={intl.t('form.actions.searchPlaceholder')}
            onChange={setSearch}
          />
        )}
        dataTestid="twinTreeLeftBarDialog"
        onClose={onClose}
      >
        {wait ? (
          <div className="flex-fill d-flex align-items-center justify-content-center">
            <Icon name="fa--spinner" size="lg" spin />
          </div>
        ) : (
          <>
            <div className="d-flex align-items-center mx-2 mt-1 px-1 flex-wrap">
              <Filters
                compact
                filters={filters}
                onFilter={onFilter}
              />
            </div>
            <div className="flex-fill d-flex flex-column">
              {/* {showTwinForm ? (
                <InlineForm
                  onClose={handleCloseInlineForm}
                  onSuccess={handleSuccessInlineForm}
                />
              ) : null} */}

              <div className="flex-fill position-relative">
                <BindedList
                  ref={refList}
                  className="position-absolute-fill"
                  settings={settings}
                  search={search}
                  Content={LeftBarListContent}
                  active={handleActive}
                  onAction={handleAction}
                  {...filters}
                />
              </div>
            </div>
          </>
        )}
      </Dialog>

      <DropdownMenu
        ref={refMenu}
        target={menuTarget}
        mode={null}
        arrow
        autoclose
        options={menuOptions}
        onHide={() => {
          setMenuTarget(null)
          setMenuData(null)
        }}
        onClick={handleActionMenu}
      />
    </>
  )
})

export default LeftBar
