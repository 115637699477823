import { Controller, useFormContext } from 'react-hook-form'
import classnames from 'classnames'
import {
  FormField,
  FormFieldInline,
  FormControl,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import FormTemplates from '@/components/Form/ListTemplates'

export default function Fields ({
  scope,
  canUpdateGlobalShare,
  templateId,
}) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('dashboards.form.fields.title')}
        description={intl.t('dashboards.form.fields.titleDescr')}
        errors={get(errors, `${scope}.title`)}
      >
        <FormControl
          name={`${scope}.title`}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (!str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value.length > 100) {
                return intl.t('form.errors.fieldMaxlen', { max: 100 })
              }
              return true
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('dashboards.form.fields.description')}
        description={intl.t('dashboards.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.description`}
          rules={{
            maxLength: {
              value: 500,
              message: intl.t('form.errors.fieldMaxlen', { max: 500 }),
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('dashboards.form.fields.bgLink')}
        description={
          <span dangerouslySetInnerHTML={{ __html: intl.t('dashboards.form.fields.bgLinkDescr') }} />
        }
        errors={get(errors, `${scope}.bgLink`)}
      >
        <FormControl
          name={`${scope}.bgLink`}
          rules={{
            maxLength: {
              value: 500,
              message: intl.t('form.errors.fieldMaxlen', { max: 500 }),
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('dashboards.form.fields.bgOpacity')}
        description={intl.t('dashboards.form.fields.bgOpacityDescr')}
        errors={get(errors, `${scope}.bgOpacity`)}
        classNameControl="d-flex align-items-center"
      >
        <Controller
          name={`${scope}.bgOpacity`}
          rules={{
            validate: {
              positive: value => (
                value === null ||
                value === '' ||
                parseInt(value || 0, 10) >= 0 ||
                intl.t('form.errors.fieldMin', { min: 0 })
              ),
              lessThanOne: value => (
                value === null ||
                value === '' ||
                parseInt(value || 0, 10) <= 1 ||
                intl.t('form.errors.fieldMax', { max: 1 })
              ),
            },
          }}
          render={({ field, fieldState }) => (
            <input
              {...field}
              type="range"
              min="0"
              max="1"
              step="0.1"
              className={classnames('form-range', {
                'is-invalid': fieldState.invalid,
              })}
            />
          )}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('dashboards.form.fields.globalShare')}
        description={intl.t('dashboards.form.fields.globalShareDescr')}
        errors={get(errors, `${scope}.isGlobalShared`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.isGlobalShared`}
          className="ms-2"
          disabled={!canUpdateGlobalShare}
        />
      </FormFieldInline>

      <FormField
        label={intl.t('form.fields.template')}
        description={intl.t('sensors.form.fields.templateDescr')}
        errors={get(errors, `${scope}.templateId`)}
      >
        <Controller
          name={`${scope}.templateId`}
          disabled={templateId}
          render={({ field, fieldState }) => (
            <FormTemplates
              {...field}
              invalid={fieldState.invalid}
              placeholder="--"
              disabled={templateId}
            />
          )}
        />
      </FormField>

    </>
  )
}
