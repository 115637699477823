'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

const wizata = '#e64810';

// --- new colors here ---
const main = wizata;
const secondary = '#184980';
const tertiary = '#031e37';
const fourth = '#062b56';
const highlight = '#11caf0';
const error = '#D71818';
const errorAlt = '#590917';
const success = '#7fb654';
const successAlt = '#0e4a35';
// --- end new colors ---

const white = '#01101D';
const black = '#ffffff';
const blue = '#8ab4f8';
const red = '#dc3545';
const primary = blue;
const danger = red;
const componentActiveBg = primary;
const gray = {
  100: '#35363b',
  200: fourth
};
const bodyBg = '#01101D';
const bodyColor = '#d8d9da';
var variables = {
  white: white,
  black: black,
  'gray-100': gray[100],
  'gray-200': gray[200],
  'gray-300': secondary,
  'gray-400': secondary,
  'gray-500': '#8f8f99',
  'gray-600': black,
  'gray-700': '#bfbfc5',
  'gray-800': '#d7d7db',
  'gray-900': '#e8eaed',
  blue: blue,
  //
  primary: primary,
  danger: danger,
  wizata: wizata,
  //
  'body-bg': bodyBg,
  'body-color': bodyColor,
  //
  'input-bg': white,
  'form-check-input-bg': white,
  //
  'component-active-bg': componentActiveBg,
  //
  'list-group-bg': 'transparent',
  'list-group-hover-bg': gray[100],
  'list-group-active-bg': componentActiveBg,
  'list-group-action-active-bg': gray[200],
  //
  main: main,
  'secondary-alt': secondary,
  tertiary: tertiary,
  fourth: fourth,
  highlight: highlight,
  error: error,
  'error-alt': errorAlt,
  success: success,
  'success-alt': successAlt
};

exports.variables = variables;
