import { PublicClientApplication, LogLevel } from '@azure/msal-browser'

const config = {
  auth: {
    clientId: process.env.AUTH_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.AUTH_TENANT}`,
    redirectUri: `${window.location.origin}/token.html`,
    postLogoutRedirectUri: `${window.location.origin}`,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    loadFrameTimeout: 5 * 60 * 1000,
    allowRedirectInIframe: true,
    ...(process.env.NODE_ENV !== 'production' ? {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message)
              break
            case LogLevel.Info:
              // console.info(message)
              break
            case LogLevel.Verbose:
              console.debug(message)
              break
            case LogLevel.Warning:
              console.warn(message)
              break
            default:
              console.log(message)
          }
        },
        piiLoggingEnabled: false,
      },
    } : {}),
  },
}

const msalInstance = new PublicClientApplication(config)

export default msalInstance
