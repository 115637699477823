import { useState } from 'react'
import { Icon, FormInputSearch, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import DeviceCommandTemplate from '@/hoc/DeviceCommandTemplate'
import FormDeviceCommandTemplate from '@/containers/Forms/DeviceCommandTemplate'
import Table from '@/containers/EdgeDeviceCommandTemplate/Table'
import TableMobile from '@/containers/EdgeDeviceCommandTemplate/TableMobile'
import ViewModel from './ViewModel'

const ViewModelData = DeviceCommandTemplate(ViewModel)

export default function List ({
  filters,
  selected,
  onFilter,
  onResetFilter,
  onRemove,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const isMobile = useMobile()
  const [ editId, setEditId ] = useState(null)
  const [ viewId, setViewId ] = useState(null)

  const TableComponent = isMobile ? TableMobile : Table

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">

      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <div className="d-flex align-items-center flex-wrap">
          {(
            auth.checkAccessCreate('DeviceCommandTemplate') ||
            auth.checkAccessRemove('DeviceCommandTemplate')
          ) ? (
            <div className="px-2 mb-2 flex-shrink-0">
              {auth.checkAccessCreate('DeviceCommandTemplate') ? (
                <button
                  type="button"
                  className="btn btn-flat-secondary"
                  onClick={() => setEditId('')}
                >
                  <Icon name="fa--plus" className="me-1" />
                  {intl.t('form.actions.add')}
                </button>
              ) : null}
              {auth.checkAccessRemove('DeviceCommandTemplate') ? (
                <button
                  type="button"
                  className="btn btn-flat-secondary"
                  onClick={() => onRemove(selected)}
                  disabled={!selected.length}
                >
                  <Icon name="fa--trash-alt" className="me-1" />
                  {intl.t('form.actions.remove')}
                </button>
              ) : null}
            </div>
            ) : null}

          <div className="d-flex align-items-center">
            <div className="px-2 mb-2 col-sm-auto">
              <FormInputSearch
                placeholder={intl.t('form.actions.searchPlaceholder')}
                value={filters.search}
                onChange={value => onFilter({ search: value })}
              />
            </div>

            <div className="mb-2">
              <button
                type="button"
                className="btn btn-fill-secondary text-nowrap"
                onClick={onResetFilter}
              >
                {intl.t('form.actions.resetFilters')}
              </button>
            </div>
          </div>
        </div>

        <TableComponent
          {...props}
          filters={filters}
          onFilter={onFilter}
          onRemove={onRemove}
          onEdit={setEditId}
          onView={setViewId}
        />
      </div>

      {editId !== null ? (
        <FormDeviceCommandTemplate
          id={editId}
          onClose={() => setEditId(null)}
          dialog={{
            title: editId ? intl.t('edge.commandTemplates.titleUpdate') : intl.t('edge.commandTemplates.titleCreate'),
            dataTestid: 'replaceDeviceCommandTemplateDialog',
          }}
        />
      ) : null}

      {viewId ? (
        <ViewModelData
          id={viewId}
          title={intl.t('edge.commandTemplates.titleDetail')}
          onClose={() => setViewId(null)}
          onRemove={onRemove}
          onEdit={setEditId}
          dialog
        />
      ) : null}
    </div>
  )
}
