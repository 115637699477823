import { useCallback, memo } from 'react'
import { useAuth } from '@/auth'
import { dbProvider, User } from '@wiz/store'
import { withProps } from '@wiz/components'
import Component from '@/components/Forms/UserUpdate'
import enhanceUser from './enhanceUser'
import enhanceUserData from './enhanceUserData'

const enhanceProps = withProps(({ id }) => {
  const auth = useAuth()

  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareReplaceData(context, User, {
      id: data.user.id,
      firstName: data.user.firstName,
      isActive: data.user.isActive,
    })
    if (auth.checkAccessRead('AccessRole')) {
      await model.prepareReplaceAccessRoles(context, data.accessRoleIds)
    }
    if (auth.checkAccessRead('Twin')) {
      await model.prepareReplaceTwins(context, data.twins)
    }

    // if (auth.checkAccessRead('Twin')) {
    //   const twin = data.twins.length ? data.defaultTwin : ''
    //   await dbProvider.prepareReplaceSetting(context, 'DefaultTwin', twin, id)
    // }

    await dbProvider.batch(context)
  }, [ auth, id ])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceUser(
    enhanceUserData(
      enhanceProps(Component),
    ),
  ),
)
