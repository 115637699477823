import { useLocationQuery, useRouter } from '@/router'
import { useIntl } from '@wiz/intl'
import TwinTypeList from '@/hoc/TwinTypeList'
import Select from '@/components/Form/Select'
import ListFilters from '@/components/Form/ListFilters'
import useAppContext from '@/hooks/useAppContext'

export default function Filters () {
  const { filters, onFilter } = useAppContext()

  const intl = useIntl()
  const query = useLocationQuery()
  const router = useRouter()

  return (
    <ListFilters
      filters={filters}
      onFilter={onFilter}
    >
      {({ filters, onFilter }) => (
        <div className="me-1 mb-2 col-md-auto">
          <Select
            name="type"
            multiselect
            ListSource={TwinTypeList}
            placeholder={intl.t('form.fields.typePlaceholder')}
            displayCount={1}
            filters={{ withSensors: false }}
            value={filters.twinType}
            onChange={(twinType) => {
              onFilter({ twinType })
              router.replace({ query: { ...query, type: twinType } })
            }}
          />
        </div>
      )}
    </ListFilters>
  )
}
