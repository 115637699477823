import { Event } from '@wiz/store'
import { createApi } from '../utils'

export default function events (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      events: {
        enumerable: true,
        value: Object.create({}, {
          ...createApi(client, 'Events', Event),

          count: {
            enumerable: true,
            value (params, onRequest) {
              const request = client.post('/Events/count', { ...params })
              onRequest?.(request)
              return request.fetch()
            },
          },

          bulkRemove: {
            enumerable: true,
            value (ids) {
              return client.delete('/Events', ids).fetch()
            },
          },
        }),
      },
    })
  }
}
