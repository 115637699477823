import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Card, withResizeObserver } from '@wiz/components'
import { Widget as Widget$ } from '@wiz/store'
import { useAuth } from '@/auth'
import { useTheme } from '@/theme'
import { useIntl } from '@wiz/intl'
import Plotly from '../containers/Plotly'

const Widget = forwardRef(({
  widget,
  config,
  hidden,
  width,
  height,
  canUpdateDashboard,
  onAction,
}, ref) => {
  const intl = useIntl()
  const theme = useTheme()
  const auth = useAuth()

  const actions = []
  if (canUpdateDashboard) {
    actions.push({ id: 'settings', name: intl.t('form.actions.settings'), icon: 'fa--cog' })
    if (auth.checkAccessCopy(widget)) {
      actions.push({ id: 'duplicate', name: intl.t('form.actions.duplicate'), icon: 'fa--clone' })
      actions.push({ id: 'multiDuplicate', name: intl.t('form.actions.multiDuplicate'), icon: 'fa--folder-plus' })
    }
    actions.push({ id: 'remove', name: intl.t('form.actions.remove'), icon: 'fa--trash-alt' })
  }

  return (
    <Card
      className="position-absolute-fill"
      classNameTitle={canUpdateDashboard ? 'userdrag' : undefined}
      title={widget.title || intl.t(widget.info.name)}
      description={widget.description}
      options={actions}
      onAction={onAction}
    >
      <div
        ref={ref}
        className="d-flex position-absolute-fill"
      >
        {do {
          if (config.dataViews.length) {
            (
              <Plotly
                widgetId={widget.id}
                config={config}
                hidden={hidden}
                width={width}
                height={height}
                theme={theme}
              />
            )
          } else if (canUpdateDashboard) {
            (
              <div className="d-flex align-items-center justify-content-center flex-fill">
                <button
                  type="button"
                  className="btn btn-flat-secondary"
                  onClick={() => onAction({ id: 'settings' })}
                >
                  {intl.t('widgets.selectSource')}
                </button>
              </div>
            )
          } else {
            (
              <div className="d-flex align-items-center justify-content-center flex-fill">
                {intl.t('widgets.notConfigured')}
              </div>
            )
          }
        }}
      </div>
    </Card>
  )
})

Widget.propTypes = {
  widget: PropTypes.instanceOf(Widget$).isRequired,
  config: PropTypes.object,
  canUpdateDashboard: PropTypes.bool,
  hidden: PropTypes.bool,
  width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  height: PropTypes.number,
  onAction: PropTypes.func.isRequired,
}

Widget.defaultProps = {
  hidden: true,
  canUpdateDashboard: false,
  width: undefined,
  height: undefined,
}

export default withResizeObserver(Widget)
