import { useCallback } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import classnames from 'classnames'
import { Icon, FormControl } from '@wiz/components'
import { has, uuid, get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

function FieldsSourcesRow ({
  scope,
  className,
  onRemove,
  onDataChange,
}) {
  const intl = useIntl()
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <div className={className}>
      <div className="flex-wrap flex-xl-nowrap d-flex align-items-end">
        <input
          {...register(`${scope}.id`)}
          type="hidden"
        />

        <div className="d-flex align-items-end mt-2">
          <button
            type="button"
            className="btn btn-sm btn-fill-secondary btn-text"
            onClick={onRemove}
          >
            <Icon name="fa--minus" />
          </button>

          <div className="ms-2">
            <div>Label</div>
            <input
              {...register(`${scope}.label`, {
                maxLength: {
                  value: 100,
                  message: intl.t('form.errors.fieldMaxlen', { max: 100 }),
                },
              })}
              type="search"
              className={classnames('form-control form-control-sm', {
                'is-invalid': has(errors, `${scope}.label`),
              })}
            />
          </div>

          <div className="ms-2">
            <div>Label X</div>
            <input
              {...register(`${scope}.xAxisLabel`, {
                maxLength: {
                  value: 100,
                  message: intl.t('form.errors.fieldMaxlen', { max: 100 }),
                },
              })}
              type="search"
              className={classnames('form-control form-control-sm', {
                'is-invalid': has(errors, `${scope}.xAxisLabel`),
              })}
            />
          </div>
        </div>

        <div className="d-flex align-items-end mt-2 w-50">
          <div className="ms-2 w-25">
            <div>Data</div>

            <FormControl
              type="number"
              name={`${scope}.data.0`}
              scale={8}
              className="form-control-sm"
              onChange={onDataChange}
            />
          </div>

          <div className="ms-2 w-25">
            <FormControl
              type="number"
              name={`${scope}.data.1`}
              scale={8}
              className="form-control-sm"
              onChange={onDataChange}
            />
          </div>

          <div className="ms-2 w-25">
            <FormControl
              type="number"
              name={`${scope}.data.2`}
              scale={8}
              className="form-control-sm"
              onChange={onDataChange}
            />
          </div>

          <div className="ms-2 w-25">
            <FormControl
              type="number"
              name={`${scope}.data.3`}
              scale={8}
              className="form-control-sm"
              onChange={onDataChange}
            />
          </div>
        </div>
      </div>

      {has(errors, `${scope}.data`) ? (
        <div className="small text-danger mt-1">
          <div>{get(errors, `${scope}.data.0.message`)}</div>
          <div>{get(errors, `${scope}.data.1.message`)}</div>
          <div>{get(errors, `${scope}.data.2.message`)}</div>
          <div>{get(errors, `${scope}.data.3.message`)}</div>
        </div>
      ) : null}
    </div>
  )
}

export default function FieldsSources ({
  scope,
  sourcesValidation,
}) {
  const intl = useIntl()
  const methods = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name: `${scope}.sources`,
    keyName: '_id',
    shouldUnregister: true,
  })
  const sources = methods.watch(`${scope}.sources`)

  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...sources[index],
  }))

  const handleCreate = useCallback(() => {
    append({
      id: uuid(),
      label: '',
      xAxisLabel: '',
      data: [ null, null, null, null ],
    })
  }, [ append ])

  const handleDataChange = useCallback(() => {
    sourcesValidation(sources, methods)
  }, [ sourcesValidation, sources ])

  if (!controlledFields.length) {
    return (
      <div className="d-flex flex-column align-items-center">
        {intl.t('form.info.sourcesNotFound')}
        <button
          type="button"
          className="btn btn-sm btn-outline-primary mt-2"
          onClick={handleCreate}
        >
          <Icon name="fa--plus" className="me-1" />
          {intl.t('form.actions.addSource')}
        </button>
      </div>
    )
  }

  return (
    <div className="d-flex flex-column">
      <button
        type="button"
        className="btn btn-sm btn-outline-primary mb-2"
        onClick={handleCreate}
      >
        <Icon name="fa--plus" className="me-1" />
        {intl.t('form.actions.addSource')}
      </button>

      {controlledFields.map((item, index) => (
        <FieldsSourcesRow
          key={item.id}
          scope={`${scope}.sources.${index}`}
          className={classnames({
            'mt-2': !!index,
          })}
          onRemove={() => remove(index)}
          onDataChange={handleDataChange}
        />
      ))}
    </div>
  )
}
