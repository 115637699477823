import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Card, withResizeObserver } from '@wiz/components'
import { Widget as WidgetModel } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import TableData from '../containers/TableData'

const Widget = forwardRef(({
  widget,
  config,
  hidden,
  dimension,
  canUpdateDashboard,
  onAction,
}, ref) => {
  const intl = useIntl()
  const auth = useAuth()

  const actions = []
  if (canUpdateDashboard) {
    actions.push({ id: 'settings', name: intl.t('form.actions.settings'), icon: 'fa--cog' })
    if (auth.checkAccessCopy(widget)) {
      actions.push({ id: 'duplicate', name: intl.t('form.actions.duplicate'), icon: 'fa--clone' })
      actions.push({ id: 'multiDuplicate', name: intl.t('form.actions.multiDuplicate'), icon: 'fa--folder-plus' })
    }
    actions.push({ id: 'remove', name: intl.t('form.actions.remove'), icon: 'fa--trash-alt' })
  }

  return (
    <Card
      ref={ref}
      className="position-absolute-fill"
      classNameTitle={canUpdateDashboard ? 'userdrag' : undefined}
      title={widget.title || intl.t(widget.info.name)}
      description={widget.description}
      options={actions}
      onAction={onAction}
    >
      {do {
        if (
          config.sensorIds?.length ||
          config.labelIds?.length ||
          config.qualityTemplateIds?.length
        ) {
          (
            <TableData
              config={config}
              dimension={dimension}
              disabled={hidden}
            />
          )
        } else if (canUpdateDashboard) {
          (
            <div className="d-flex align-items-center justify-content-center flex-fill">
              <button
                type="button"
                className="btn btn-flat-secondary"
                onClick={() => onAction({ id: 'settings' })}
              >
                {intl.t('widgets.selectSource')}
              </button>
            </div>
          )
        } else {
          (
            <div className="d-flex align-items-center justify-content-center flex-fill">
              {intl.t('widgets.notConfigured')}
            </div>
          )
        }
      }}
    </Card>
  )
})

Widget.propTypes = {
  canUpdateDashboard: PropTypes.bool,
  widget: PropTypes.instanceOf(WidgetModel).isRequired,
  config: PropTypes.object,
  hidden: PropTypes.bool,
  dimension: PropTypes.string,
  onAction: PropTypes.func.isRequired,
}

Widget.defaultProps = {
  hidden: false,
  dimension: undefined,
  canUpdateDashboard: false,
}

export default withResizeObserver(Widget)
