import {
  useState, useContext, useEffect, useMemo,
} from 'react'
import { useQuery } from '@tanstack/react-query'
import {
  useMobile, withSort, withPagination,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { wizataApi } from '@/api'
import { useAuth } from '@/auth'
import { AppContext } from '@/pages/context'
import Experiment from '@/hoc/Experiment'
import { consts } from '@wiz/utils'
import FormExperiment from '@/containers/Forms/Experiment'
import TableMobile from '@/containers/Experiment/TableMobile'
import ChartsDrawer from '@/components/ChartsDrawer'
import Component from './Table'
import ViewModel from './ViewModel'
import ExperimentResult from './ExperimentResult'
import { useGlobalExecute } from '../../context/GlobalExecuteProvider'

const SortByFields = {
  createdDate: 'createdDate',
  updatedDate: 'updatedDate',
  name: 'name',
  waitingTime: 'waitingTime',
  executionTime: 'executionTime',
  startedDate: 'startedDate',
  queuedDate: 'queuedDate',
}

const SortDirection = {
  asc: 'Ascending',
  desc: 'Descending',
}

const Table = withSort()(
  withPagination()(Component),
)

const ViewModelData = Experiment(ViewModel)
const ViewExperimentResult = Experiment(ExperimentResult)

export default function List ({
  filters,
  selected,
  hideHeader,
  actions,
  container,
  onFilter,
  onResetFilter,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const isMobile = useMobile()
  const [ viewId, setViewId ] = useState(null)
  const [ editId, setEditId ] = useState(null)
  const [ resultId, setResultId ] = useState(null)

  const { filters: contextFilters, onRowSelect } = useContext(AppContext)
  const { contextTwinId } = useGlobalExecute()

  const TableComponent = isMobile ? TableMobile : Table

  const experiments = useQuery({
    queryKey: [ 'experimentsList' ],
    queryFn: () => {
      const limit = filters.pageSize + 1
      const offset = (filters.page - 1) * filters.pageSize

      const nextFilters = []

      if (contextFilters.templateId) {
        nextFilters.push({
          propertyName: 'templateId',
          operationType: consts.FilterOperationType.Equals,
          type: contextFilters.templateId === 'null' ? consts.FilterType.Guid : consts.FilterType.Enum,
          canBeNull: contextFilters.templateId === 'null' ? true : !contextFilters.templateId,
          ...(contextFilters.templateId && contextFilters.templateId !== 'null') ?
            { value: contextFilters.templateId } : {},
        })
      }
      if (contextFilters.pipelineId) {
        nextFilters.push({
          propertyName: 'pipelineId',
          operationType: consts.FilterOperationType.Equals,
          type: contextFilters.pipelineId === 'null' ? consts.FilterType.Guid : consts.FilterType.Enum,
          canBeNull: contextFilters.pipelineId === 'null' ? true : !contextFilters.pipelineId,
          ...(contextFilters.pipelineId && contextFilters.pipelineId !== 'null') ?
            { value: contextFilters.pipelineId } : {},
        })
      }
      if (contextFilters.type) {
        nextFilters.push({
          propertyName: 'type',
          type: consts.FilterType.Enum,
          operationType: consts.FilterOperationType.Equals,
          value: contextFilters.type,
        })
      }

      const complexFilters = {}
      if (contextTwinId) {
        complexFilters.twinId = contextTwinId
        complexFilters.includeChildren = true
      }
      if (contextFilters.status) {
        nextFilters.push({
          propertyName: 'status',
          type: consts.FilterType.Enum,
          operationType: consts.FilterOperationType.Equals,
          value: contextFilters.status,
        })
      }
      if (filters.search) {
        nextFilters.push({
          type: consts.FilterType.Text,
          operationType: consts.FilterOperationType.Contains,
          value: filters.search || '',
          propertyName: 'name',
        })
      }

      return wizataApi.entities.getList('experiments', {
        ...complexFilters,
        pagination: {
          take: limit - 1,
          skip: offset,
        },
        sortingList: [
          {
            direction: SortDirection[filters.sortDir],
            propertyName: SortByFields[filters.sortBy],
          },
        ],
        filters: nextFilters,
      })
      // .then((data) => {
      //   const { items, totalCount } = data || {}
      //   setTotal(totalCount)
      //   const _items = items.slice(0, filters.pageSize)
      //   setItems(_items)
      // })
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: true,
  })

  const pipelines = useQuery({
    queryKey: [ 'pipelines' ],
    queryFn: () => wizataApi.pipelines.getList(),
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const templates = useQuery({
    queryKey: [ 'templatesList' ],
    queryFn: () => wizataApi.templates.getList('false'),
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const itemsList = useMemo(() => ({
    pipelineId: pipelines?.data,
    templateId: templates?.data,
  }), [ pipelines, templates ])

  const drawerSequence = [
    {
      name: 'pipelineId',
      chart: consts.PanelChartType.bar,
    },
    {
      name: 'templateId',
      chart: consts.PanelChartType.pie,
    },
    {
      name: 'twinId',
      chart: consts.PanelChartType.pie,
    },
  ]

  const possibleFilters = useMemo(() => ({
    pipelineId: contextFilters.pipelineId,
    templateId: contextFilters.templateId,
    twinId: contextTwinId,
  }), [ contextFilters, contextTwinId ])

  useEffect(() => {
    if (experiments?.data && !experiments.isRefetching) {
      experiments.refetch()
    }
  }, [
    filters.page,
    filters.pageSize,
    filters.sortDir,
    filters.sortBy,
    filters.search,
    contextFilters.status,
    contextFilters.type,
    contextFilters.templateId,
    contextFilters.pipelineId,
    contextTwinId,
  ])

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">
      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <TableComponent
          {...props}
          {...experiments}
          filters={filters}
          onFilter={onFilter}
          onView={setViewId}
          onEdit={setEditId}
          onSelect={onRowSelect}
        />
      </div>
      <ChartsDrawer
        filters={possibleFilters}
        valuesList={[]}
        sequence={drawerSequence}
        entity="experiments"
        itemsList={itemsList}
      />
      {viewId ? (
        <ViewModelData
          id={viewId}
          title={intl.t('experiments.titleDetail')}
          onClose={() => setViewId(null)}
          onFilter={onFilter}
          dialog
        />
      ) : null}

      {resultId ? (
        <ViewExperimentResult
          id={resultId}
          container={container}
          onClose={() => setResultId(null)}
        />
      ) : null}

      {editId !== null ? (
        <FormExperiment
          id={editId}
          onClose={() => setEditId(null)}
          dialog={{
            title: intl.t('experiments.titleUpdate'),
            dataTestid: 'replaceDashboardDialog',
          }}
        />
      ) : null}
    </div>
  )
}
