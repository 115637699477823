import { useMemo, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { Label as Label$ } from '@wiz/store'
import { wizataApi } from '@/api'
import { consts, uuid } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { Tabs } from '@wiz/components'
import FormDialog from '@/components/Form/FormDialog'
import events from '@/utils/events'
import Removed from '../Removed'
import SectionUser from './SectionUser'
import SectionSystem from './SectionSystem'
import SectionUsage from './SectionUsage'
import SectionLinkedSensors from './SectionLinkedSensors'

export default function Label ({
  id,
  label,
  dialog,
  usage,
  activeUsage,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()
  const [ tab, setTab ] = useState('settings')
  const queryClient = useQueryClient()

  const { data: item, isLoading } = useQuery({
    queryKey: [ 'label', id ],
    queryFn: () => wizataApi.labels.getById(id),
    enabled: !!id,
    staleTime: Infinity,
  })

  const {
    mutateAsync: mutateLabel,
  } = useMutation({
    mutationKey: [ 'mutateLabel' ],
    mutationFn: (data) => {
      if (!id) {
        return wizataApi.labels.create({ ...data?.label, id: uuid() })
      }
      return wizataApi.labels.update({ id, ...data?.label })
    },
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/labels.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'label', id ] })
      queryClient.invalidateQueries({ queryKey: [ 'labelsList' ] })
    },
  })

  const handleSubmit = async (data) => {
    await mutateLabel(data)
  }

  const defaultValues = useMemo(() => ({
    label: { ...item },
  }), [ item ])

  const usageCount = useMemo(() => (
    usage.reduce((acc, item) => (
      acc + (item.payload.count || 0)
    ), 0)
  ), [ usage ])

  if (id && !item) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={handleSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="labelForm"
      dialog={dialog}
      disabled={activeUsage}
    >
      {activeUsage ? (
        <div className="alert alert-warning" role="alert">
          {intl.t('label.disableActiveUsage')}
        </div>
      ) : null}

      <Tabs
        className="mb-2"
        value={tab}
        options={[
          { value: 'settings', label: intl.t('label.sections.settings') },
          { value: 'usage', label: `${intl.t('label.sections.usage')} (${usageCount})` },
          { value: 'dataPoints', label: intl.t('label.section.linkedSensors') },
        ]}
        onChange={setTab}
      />

      {tab === 'settings' && (!id || defaultValues.label.type === consts.LabelType.User) ? (
        <SectionUser scope="label" />
      ) : null}

      {tab === 'settings' && !(!id || defaultValues.label.type === consts.LabelType.User) ? (
        <SectionSystem scope="label" />
      ) : null}

      {tab === 'usage' ? (
        <SectionUsage usage={usage} />
      ) : null}

      {tab === 'dataPoints' ? (
        <SectionLinkedSensors scope="label" />
      ) : null}
    </FormDialog>
  )
}
