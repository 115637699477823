import { useMemo } from 'react'
import { Condition as Condition$ } from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import Section from './Section'

export default function Condition ({
  id,
  condition,
  exclude,
  dialog,
  onClose,
  onSubmit,
}) {
  const defaultValues = useMemo(() => ({
    condition: Condition$.toJSON(condition),
  }), [ condition?.updatedAt ?? condition ])

  if (id && !condition) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="conditionForm"
      dialog={dialog}
    >
      <Section
        scope="condition"
        exclude={exclude}
      />
    </FormDialog>
  )
}
