import { useState, useMemo, useRef } from 'react'
import classnames from 'classnames'
import { Icon } from '@wiz/components'
import Observe from '@/containers/Observe'
import Dropdown from '@/components/Form/Select/Dropdown'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import TwinList from '@/hoc/TwinList'
import { appEnv } from '@/config'
import classes from './index.css'

export default function Breadcrumb ({
  className,
  options,
  onChange,
  onRootTwinOpen,
  envName = appEnv.WIZ_CLIENT_TITLE,
}) {
  const refDropdown = useRef()
  const [ parentId, setParentId ] = useState(null)
  const [ dropdownTarget, setDropdownTarget ] = useState(null)
  const filters = useMemo(() => ({ parentId, withSensors: false }), [ parentId ])
  const { contextTwinId } = useGlobalExecute()

  return (
    <nav aria-label="breadcrumb" className={className}>
      <ol className="breadcrumb m-0">
        <li
          className="breadcrumb-item"
          aria-current="page"
        >
          <button
            type="button"
            className={classnames(
              'btn btn-text text-muted text-truncate p-0 opacity-100 lh-1',
              { 'fw-bold': !options.length },
            )}
            onClick={onRootTwinOpen}
            // disabled
          >
            <Icon name="wiz--menu--digital-twin" className="me-2" size="md" />
            {envName}
          </button>
        </li>
        {options.map((item, idx) => {
          const last = idx === options.length - 1
          return (
            contextTwinId ? (
              <li
                key={item.id}
                className="breadcrumb-item align-content-center"
                aria-current="page"
              >
                <button
                  type="button"
                  className={classnames('d-inline btn btn-link text-muted text-truncate p-0', { 'fw-bold': last })}
                  onClick={() => onChange?.(item.id)}
                >
                  {item.name}
                </button>

                {/* <Observe count={item.queryChildrenCount}>
                  {({ data }) => (data ? (
                    <button
                      type="button"
                      className="btn btn-sm text-muted p-1"
                      onClick={(event) => {
                        setDropdownTarget(event.currentTarget)
                        setParentId(item.id)
                      }}
                    >
                      <Icon name="fa--chevron-down" size="sm" />
                    </button>
                  ) : null)}
                </Observe> */}
              </li>
            ) :
              null
          )
        })}
      </ol>

      <Dropdown
        ref={refDropdown}
        refTarget={dropdownTarget}
        filters={filters}
        ListSource={parentId ? TwinList : undefined}
        placement="bottom"
        onChange={(twin) => {
          refDropdown.current.close()
          onChange?.(twin.id)
        }}
      />
    </nav>
  )
}
