import { useState, useEffect, Fragment } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import classnames from 'classnames'
import {
  FormSection,
  FormFieldInline,
  FormInput,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { dbProvider, Formula, IExplorer } from '@wiz/store'
import { get, uuid } from '@wiz/utils'
import SelectDataSources from '@/components/Form/SelectDataSources'

export default function SectionFormulaDataSources ({ scope, expression }) {
  const intl = useIntl()
  const { register, setValue, formState: { errors } } = useFormContext()
  const ports = useWatch({ name: scope })
  const [ currentPorts, setCurrentPorts ] = useState([])

  useEffect(() => {
    if (expression) {
      Formula.parseExpression(expression)
        .then(({ portsInput, portsOutput }) => {
          const next = [].concat(portsOutput, portsInput)
            .map((item) => {
              const port = ports?.[item.type]?.[item.portId]
              return {
                ...item,
                relDSPortId: port?.relDSPortId || uuid(), // must be always!
                dataSource: port?.dataSource,
              }
            })

          setCurrentPorts(next)
        })
        .catch(() => {
          setCurrentPorts([])
        })
    } else {
      setCurrentPorts([])
    }
  }, [ expression ])

  return (
    <FormSection
      title={intl.t('formulas.form.fields.params')}
      description={intl.t('formulas.form.fields.paramsDescr')}
    >
      <FormFieldInline complex>
        <div className="list-group list-group-flush flex-fill mx-0">
          {currentPorts.map((item) => {
            const scopeRow = `${scope}.${item.type}.${item.portId}`
            const error = (
              get(errors, `${scopeRow}.dataSource`) ||
              get(errors, `${scopeRow}.type`) ||
              get(errors, `${scopeRow}.portId`)
            )

            return (
              <Fragment key={scopeRow}>
                <div
                  className={classnames('list-group-item py-2 px-0 d-flex align-items-center', {
                    'is-invalid': !!error,
                  })}
                >
                  {item.type === 'input' ? (
                    <>
                      <input
                        {...register(`${scopeRow}.id`, { shouldUnregister: true })}
                        type="hidden"
                        value={item.id}
                      />

                      <input
                        {...register(`${scopeRow}.type`, { shouldUnregister: true })}
                        type="hidden"
                        value={item.type}
                      />

                      <input
                        {...register(`${scopeRow}.portId`, { shouldUnregister: true })}
                        type="hidden"
                        value={item.portId}
                      />

                      <input
                        {...register(`${scopeRow}.relDSPortId`, { shouldUnregister: true })}
                        type="hidden"
                        value={item.relDSPortId}
                      />

                      <FormInput
                        value={item.type}
                        className="w-auto"
                        disabled
                      />

                      <FormInput
                        value={item.portId}
                        className="ms-2"
                        disabled
                      />

                      <Controller
                        name={`${scopeRow}.dataSource`}
                        rules={{
                          required: intl.t('form.errors.fieldRequired'),
                        }}
                        shouldUnregister
                        render={({ field, fieldState }) => (
                          <SelectDataSources
                            {...field}
                            invalid={fieldState.invalid}
                            className="ms-2"
                            hasImportant
                            onChange={async (data) => {
                              const dataSource = data ? await IExplorer.createDataSourceContext(
                                dbProvider.database,
                                data,
                              ) : null
                              setValue(`${scopeRow}.dataSource`, dataSource, {
                                shouldDirty: true,
                                shouldValidate: true,
                              })
                            }}
                          />
                        )}
                      />
                    </>
                  ) : (
                    <>
                      <input
                        className="form-control w-auto"
                        value={item.type}
                        disabled
                      />

                      <input
                        className="form-control ms-2"
                        value={item.portId}
                        disabled
                      />
                    </>
                  )}
                </div>
                {error ? (
                  <div className="invalid-feedback m-0">
                    {error.message}
                  </div>
                ) : null}
              </Fragment>
            )
          })}
          {!currentPorts.length ? (
            <div className="list-group-item d-flex justify-content-center">
              {intl.t('form.fields.formulaPlaceholder')}
            </div>
          ) : null}
        </div>
      </FormFieldInline>
    </FormSection>
  )
}
