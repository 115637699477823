import { CustomScrollbars } from '@wiz/components'
import { useIntl } from '@wiz/intl'
// import { autolink } from '@/utils/string'
import SensorList from '@/hoc/SensorList'

const Details = SensorList(({ row, options }) => {
  const intl = useIntl()
  const detail = row.original
  const sensorHash = options.reduce((out, item) => ({
    ...out,
    [item.id]: item.displayName,
  }), {})

  return (
    <CustomScrollbars
      horizontal={false}
      autoHeightMax={300}
      autoHeight
      autoHide
    >
      {/* <div className="row">
        <div className="col">
          <h6>{intl.t('jobs.form.fields.result')}</h6>
          <pre className="overflow-auto small">
            {detail.result ? (
              // eslint-disable-next-line react/no-danger
              <span dangerouslySetInnerHTML={{ __html: autolink(detail.result, true) }} />
            ) : intl.t('form.nodata')}
          </pre>
        </div>
      </div> */}
      <div className="row">
        <div className="col">
          <h6>{intl.t('jobs.form.fields.parameters')}</h6>
          <pre className="overflow-auto small">
            {JSON.stringify(detail.parameters, (key, value) => {
              if (key === 'SensorsAggregates') {
                return Object.keys(value || {}).reduce((out, sensorId) => {
                  const sensorName = sensorHash[sensorId] || sensorId
                  return {
                    ...out,
                    [sensorName]: value[sensorId],
                  }
                }, {})
              }
              return value
            }, 2)}
          </pre>
        </div>
      </div>
    </CustomScrollbars>
  )
})

const BindedDetails = ({ row }) => (
  <Details
    row={row}
    value={Object.keys(row.original?.parameters?.SensorsAggregates || {})}
    selectedOnly
  />
)

export default BindedDetails
