import {
  useRef,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react'
import { DragProvider, Dropdown } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Tree from '@/components/Form/Tree/Tree'
import DataViewForm from '@/hoc/DataViewForm'
import DataSourceForm from '@/hoc/DataSourceForm'
import FormDataView from '@/components/Forms/DataView'
import FormDataSource from '@/components/Forms/DataSource'
import FormDataViewMini from '@/components/Forms/DataViewMini'
import ItemContent from './ItemContent'

const BindedFormDataView = DataViewForm(FormDataView)
const BindedFormDataSource = DataSourceForm(FormDataSource)

const getChildrenViews = (options, id, found) => {
  const views = []
  for (let i = 0; i < options.length; i += 1) {
    const item = options[i]
    if (item.id === id) {
      return getChildrenViews(item.children, id, true)
    }
    if (found) {
      views.push(item.payload.view)
    }
    views.push(...getChildrenViews(item.children, id, found))
  }
  return views
}

const getIndeterminateIds = (options, parentIds = []) => {
  const ids = {}
  for (let i = 0; i < options.length; i += 1) {
    const item = options[i]
    if (!item.payload.view.checked) {
      Object.assign(ids, parentIds.reduce((out, id) => ({ ...out, [id]: true }), {}))
    }
    Object.assign(ids, getIndeterminateIds(item.children, parentIds.concat(item.id)))
  }
  return ids
}

const getParentViews = (options, id, parents = []) => {
  for (let i = 0; i < options.length; i += 1) {
    const item = options[i]
    if (item.id === id) {
      return parents
    }

    const data = getParentViews(item.children, id, parents.concat(item))
    if (data) {
      return data
    }
  }
  return undefined
}

export default function ListDataViews ({
  className,
  search,
  options,
  readOnly,
  onRemoveView,
  onChangeView,
  onChangeSource,
  onDrop,
  onResetFilter,
}) {
  const refDrag = useRef()
  const refDropdownView = useRef()
  const intl = useIntl()
  const [ editSource, setEditSource ] = useState(null)
  const [ editView, setEditView ] = useState(null)
  const [ editViewMini, setEditViewMini ] = useState(null)

  const indeterminateIds = useMemo(() => {
    const ids = getIndeterminateIds(options)
    for (const key in ids) {
      if (Object.hasOwnProperty.call(ids, key)) {
        const children = getChildrenViews(options, key)
        if (!children.length || children.every(view => !view.checked)) {
          delete ids[key]
        }
      }
    }
    return ids
  }, [ options ])

  const handleChangeChecked = useCallback(({ view, checked }) => {
    const views = [{ ...view, checked }]
    // eslint-disable-next-line no-param-reassign
    view.checked = checked

    getChildrenViews(options, view.id).forEach((item) => {
      views.push({ ...item, checked })
      // eslint-disable-next-line no-param-reassign
      item.checked = checked
    })

    const parents = getParentViews(options, view.id)
    let parent = parents.pop()
    while (parent) {
      const children = getChildrenViews(options, parent.id)
      if (children.length) {
        if (children.every(item => item.checked)) {
          views.push({ ...parent, checked: true })
          parent.checked = true
        } else if (children.every(item => !item.checked)) {
          views.push({ ...parent, checked: false })
          parent.checked = false
        }
      }
      parent = parents.pop()
    }

    onChangeView(views)
  }, [ onChangeView, options ])

  const handleSubmitDataView = useCallback(({ dataView }) => {
    onChangeView({ ...editView, ...dataView })
  }, [ editView, onChangeView ])

  const handleSubmitDataSource = useCallback(({ dataSource }) => {
    onChangeSource({ ...editSource, ...dataSource })
  }, [ editSource, onChangeSource ])

  const handleDragStart = useCallback((data) => {
    refDrag.current.data.add(data)
  }, [])

  const handleDrop = useCallback((type, data, dragData) => {
    onDrop?.(dragData[0].payload.view, data.payload.view, type)
  }, [ onDrop ])

  const handleAction = useCallback((action, payload) => {
    if (action === 'readOnly') {
      return readOnly
    }

    if (action === 'canChangeView') {
      return !!onChangeView
    }

    if (action === 'canChangeSource') {
      return !!onChangeSource
    }

    if (action === 'canRemoveView') {
      return !!onRemoveView
    }

    if (action === 'isIndeterminate') {
      return indeterminateIds[payload]
    }

    if (action === 'changeChecked') {
      return handleChangeChecked(payload)
    }

    if (action === 'changeView') {
      return onChangeView(payload)
    }

    if (action === 'removeView') {
      return onRemoveView(payload)
    }

    if (action === 'editSource') {
      return setEditSource(payload)
    }

    if (action === 'editView') {
      return setEditView(payload)
    }

    if (action === 'editViewMini') {
      return setEditViewMini(payload)
    }

    return undefined
  }, [
    readOnly,
    indeterminateIds,
    onChangeView,
    onChangeSource,
    onRemoveView,
    handleChangeChecked,
  ])

  useEffect(() => {
    // wait to update DOM
    const timeout = editViewMini ? window.setTimeout(() => (
      refDropdownView.current.open()
    ), 0) : 0
    return () => {
      window.clearTimeout(timeout)
    }
  }, [ editViewMini ])

  return (
    <>
      <DragProvider
        ref={refDrag}
        name="internal"
      >
        <Tree
          className={className}
          optionClassName={() => ('d-flex align-items-center')}
          draggable={onChangeView && onDrop && !readOnly ? 'internal' : undefined}
          search={search}
          options={options}
          Content={ItemContent}
          checkFiltered={() => (!!search)}
          onAction={handleAction}
          onDrop={handleDrop}
          onDragStart={handleDragStart}
          onResetFilter={onResetFilter}
        />
      </DragProvider>

      <Dropdown
        ref={refDropdownView}
        mode={null}
        arrow
        autoclose
        target={editViewMini?.target}
        width={300}
      >
        {() => (editViewMini?.view ? (
          <FormDataViewMini
            dataView={editViewMini.view}
            onClose={() => refDropdownView.current.close()}
            onSubmit={({ dataView }) => {
              refDropdownView.current.close()
              handleAction('changeView', { ...editViewMini.view, ...dataView })
            }}
            onShowSettings={() => {
              refDropdownView.current.close()
              handleAction('editView', editViewMini.view)
            }}
          />
        ) : null)}
      </Dropdown>

      {do {
        if (editSource) {
          <BindedFormDataSource
            dataSource={editSource}
            onSubmit={handleSubmitDataSource}
            onClose={() => {
              setEditView(null)
              setEditSource(null)
            }}
            dialog={{
              title: editSource.isVirtual ?
                intl.t('explorer.titleUpdateVirtualDataSource') :
                intl.t('explorer.titleUpdateDataSource'),
              dataTestid: 'updateDataSourceDialog',
            }}
          />
        } else if (editView) {
          <BindedFormDataView
            dataView={editView}
            onSubmit={handleSubmitDataView}
            onClose={() => {
              setEditView(null)
              setEditSource(null)
            }}
            dialog={{
              title: intl.t('explorer.titleUpdateDataView'),
              dataTestid: 'updateDataViewDialog',
            }}
          />
        }
      }}
    </>
  )
}
