import { useState, useMemo } from 'react'
import classnames from 'classnames'
import { Condition } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import ConditionForm from '@/hoc/ConditionForm'
import FormCondition from '@/components/Forms/Condition'

export default function ButtonCreateCondition ({
  className,
  exclude,
  onCreateCondition,
  ComponentConditionForm,
}) {
  const intl = useIntl()
  const [ createCondition, setCreateCondition ] = useState(false)
  const Form = useMemo(() => (
    ConditionForm(ComponentConditionForm || FormCondition)
  ), [ ComponentConditionForm ])

  return (
    <>
      <button
        type="button"
        className={classnames('btn btn-sm btn-fill-secondary text-nowrap', className)}
        onClick={() => setCreateCondition(Condition.toJSON())}
      >
        {intl.t('form.actions.addCondition')}
      </button>

      {createCondition ? (
        <Form
          condition={createCondition}
          exclude={exclude}
          onSubmit={({ condition }) => {
            onCreateCondition?.(condition)
          }}
          onClose={() => setCreateCondition(null)}
          dialog={{
            title: intl.t('explorer.titleCreateCondition'),
            dataTestid: 'replaceConditionDialog',
          }}
        />
      ) : null}
    </>
  )
}
