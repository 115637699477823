import { useCallback, useState } from 'react'
import { dbProvider } from '@wiz/store'
import { withProps } from '@wiz/components'
import events from '@/utils/events'
import useFilters from '@/hooks/useFilters'

const DefaultPage = 1
const DefaultPageSize = 20
const DefaultSortBy = 'updatedAt'
const DefaultSortDir = 'desc'
const DefaultFilters = {
  sortBy: DefaultSortBy,
  sortDir: DefaultSortDir,
  page: DefaultPage,
  pageSize: DefaultPageSize,
}

const withTableActions = ({
  initFilters,
  confirmDeleteMessage = 't/units.confirmDelete',
  defaultFilters,
} = {}) => {
  const defaultData = () => ({
    ...DefaultFilters,
    ...defaultFilters?.(),
  })

  const initFiltersDefault = (query = {}) => {
    const data = defaultData()
    return {
      _: query._ || undefined,
      search: query.search ? decodeURIComponent(query.search) : undefined,
      sortBy: query.sortBy || data.sortBy,
      sortDir: query.sortDir || data.sortDir,
      page: Number(query.page) || data.page,
      pageSize: Number(query.pageSize) || data.pageSize,
    }
  }

  const initializeFilters = data => ({
    ...initFiltersDefault(data),
    ...initFilters?.(data),
  })

  return withProps(({
    filters: staticFilters,
  }) => {
    const [ selected, onSelect ] = useState([])
    const {
      filters,
      onFilter,
      forceUpdate,
      onResetFilter,
    } = useFilters({
      initFilters: initializeFilters,
      defaultFilters: defaultData,
    })

    const onTableFilter = useCallback((data) => {
      let next

      if (data) {
        next = {
          ...data,
          page: 1,
        }
      }

      onFilter(next)
    }, [ onFilter ])

    const onRemove = useCallback(async (data) => {
      await window.wizConfirm({ message: confirmDeleteMessage })
      try {
        const context = dbProvider.createBatchContext()
        for (const item of data) {
          await item.prepareRemove(context)
        }
        await dbProvider.batch(context)
      } catch (error) {
        events.emit('app:notify', {
          type: 'error',
          message: error.message,
        })
        throw error
      }
    }, [])

    const onSort = useCallback((data) => {
      if (data[0]) {
        onFilter({
          sortBy: data[0].id,
          sortDir: data[0].desc ? 'desc' : 'asc',
        })
      }
    }, [ onFilter ])

    const onPagination = useCallback((page, pageSize) => {
      onFilter({ page, pageSize })
    }, [ onFilter ])

    const onDuplicate = useCallback((async (model, extra, options) => {
      const context = dbProvider.createBatchContext()
      await dbProvider.prepareDuplicateModel(context, model, extra, {
        uniqProps: [ 'name' ],
        ...options,
      })
      await dbProvider.batch(context)
    }), [])

    return {
      DefaultPage,
      DefaultPageSize,
      DefaultSortBy,
      DefaultSortDir,
      filters: {
        ...filters,
        ...staticFilters,
      },
      forceUpdate,
      onFilter: onTableFilter,
      onPagination,
      onRemove,
      onResetFilter,
      onDuplicate,
      onSelect,
      onSort,
      selected,
    }
  })
}

export default withTableActions
