import {
  type,
  array,
  optional,
  nullable,
  intersection,
} from 'superstruct'
import { DataTypes } from '@wiz/utils'
import { Dashboard } from '@wiz/store'
import * as types from '@/utils/types'

export default type({
  newDashboards: optional(nullable(array(type({
    id: DataTypes.StructUuid(),
    title: intersection([
      types.Required(),
      types.Maxlen(100),
      Dashboard.schema.schema.title,
    ]),
  })))),
  dashboards: optional(nullable(array(DataTypes.StructUuid()))),
})
