import { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Alert, NotificationSheet, NotificationSheetTarget } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import ObservAlert from '@/containers/Forms/Alert/Observ'
import Section from '@/components/Forms/Alert/Section'
import SectionAlertSheet from '@/components/Forms/Alert/SectionAlertSheet'
import SmsConsumption from '@/containers/Form/SmsConsumption'
import Select from '@/components/Form/Select'
import AlertList from '@/hoc/AlertList'

export default function SettingsSection ({ scope }) {
  const intl = useIntl()
  const {
    register,
    watch,
    setValue,
    clearErrors,
  } = useFormContext()
  const id = watch(`${scope}.alert.id`)

  const handleChange = useCallback(({ alert, notificationSheet, notificationSheetTargets }) => {
    const next = Alert.toJSON(alert)
    setValue(`${scope}.id`, next.id, { shouldDirty: true })
    setValue(`${scope}.alert`, next, { shouldDirty: true })
    setValue(`${scope}.notificationSheet`, NotificationSheet.toJSON(notificationSheet), { shouldDirty: true })
    setValue(`${scope}.notificationSheetTargets`, notificationSheetTargets.map(item => (
      NotificationSheetTarget.toJSON(item)
    )), { shouldDirty: true })
    clearErrors(scope)
  }, [ scope, setValue, clearErrors ])

  return (
    <>
      <ObservAlert
        id={id}
        onChange={handleChange}
      />

      <input
        {...register(`${scope}.id`)}
        type="hidden"
        defaultValue={id}
      />

      <Section
        scope={`${scope}.alert`}
        registerId={false}
        title={intl.t('blockAlert.section.alert')}
        description={intl.t('blockAlert.section.alertDescr')}
        addon={() => (
          <Controller
            name={`${scope}.alert.id`}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                invalid={fieldState.invalid}
                ListSource={AlertList}
                placeholder={intl.t('form.fields.alertPlaceholder')}
                className="min-w-0 w-100 w-md-auto mt-2 mt-md-0"
              />
            )}
          />
        )}
      />

      <SmsConsumption
        description={intl.t('blockAlert.section.smsConsumptionDescr')}
      />

      <SectionAlertSheet
        scope={`${scope}.notificationSheet`}
        scopeTargets={`${scope}.notificationSheetTargets`}
      />
    </>
  )
}
