import { useMemo } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import FormDialog from '@/components/Form/FormDialog'
import { Icon } from '@wiz/components'
import { wizataApi } from '@/api'
import events from '@/utils/events'
import { uuid } from '@wiz/utils'
import Section from './Section'
import Removed from '../Removed'

const Template = ({
  id,
  dialog,
  onClose,
  onUpdate,
}) => {
  const queryClient = useQueryClient()

  const { data: item, isLoading } = useQuery({
    queryKey: [ 'template', id ],
    queryFn: () => wizataApi.templates.getById(id),
    enabled: !!id,
    staleTime: Infinity,
  })

  const {
    mutateAsync: mutateTemlpate,
  } = useMutation({
    mutationKey: [ 'mutateTemlpate' ],
    mutationFn: (data) => {
      if (!data.id) {
        return wizataApi.templates.create({ ...data, id: uuid() })
      }
      return wizataApi.templates.updateById(data)
    },
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/templates.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      onUpdate?.()
      queryClient.invalidateQueries({ queryKey: [ 'templatesPagedList' ] })
    },
  })

  const handleSubmit = async ({ template }) => {
    await mutateTemlpate(template)
  }

  const defaultValues = useMemo(() => ({
    template: item,
  }), [ item ])

  if (id && isLoading) {
    return (
      <div className="position-absolute-fill position-center-fill bg-light opacity-50">
        <Icon name="fa--spinner" size="2X" spin />
      </div>
    )
  }

  if (id && !item) {
    return <Removed />
  }

  return (
    <FormDialog
      onClose={onClose}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      dataTestid="templateForm"
      dialog={dialog}
    >
      <Section scope="template" isEdit={!!id} />
    </FormDialog>
  )
}

export default Template
