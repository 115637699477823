import classnames from 'classnames'
import {
  Icon,
  useWindow,
  CustomScrollbars,
  FormSelectDateTime,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import SensorList from '@/hoc/SensorList'
import FormatDateTime from '@/containers/FormatDateTime'
import InputItem from './InputItem'

const SensorListBinded = SensorList(({ options }) => (
  <b>
    {options.map(item => (item.displayName))}
  </b>
))

export default function Table ({
  businessType,
  errors,
  size,
  items,
  loading,
  onlyNow,
  precision,
  readOnly,
  setOnlyNow,
  setSingleTime,
  setTimestamp,
  showLastValues,
  singleTime,
  timestamp,
  formModify,
  onClose,
  onSend,
  onConfirm,
  onDiscard,
  onCreateRecord,
  onUpdateRecord,
  onUpdateValue,
  onRemoveValue,
  onCreateValue,
  onApplyTimestamp,
  onRemoveRecord,
}) {
  const intl = useIntl()
  const win = useWindow()

  const currentItems = readOnly ? (
    items.filter(item => (
      item.sensorIds?.length &&
      item.values?.length &&
      item.values.some(v => Number.isFinite(v.value))
    ))
  ) : items

  return (
    <>
      <div className="my-2 d-flex justify-content-end">
        <div className="d-flex flex-wrap m-n1">
          <FormSelectDateTime
            className="m-1 w-auto"
            future={false}
            size="sm"
            disabled={readOnly}
            value={timestamp}
            DateTimeComponent={FormatDateTime}
            onChange={(value) => {
              setTimestamp(value)
              if (singleTime) {
                onApplyTimestamp(value)
              }
            }}
          />

          <button
            type="button"
            className={classnames('btn btn-sm btn-fill-secondary m-1 text-nowrap', {
              active: singleTime,
            })}
            disabled={readOnly}
            onClick={() => {
              setOnlyNow(false)
              if (!singleTime) {
                setSingleTime(true)
                onApplyTimestamp()
              } else {
                setSingleTime(false)
              }
            }}
          >
            {intl.t('widgets.inputSensorValues.form.actions.applyTime')}
          </button>

          <button
            type="button"
            className={classnames('btn btn-sm btn-fill-secondary m-1 text-nowrap', {
              active: onlyNow,
            })}
            disabled={readOnly}
            onClick={() => {
              setSingleTime(false)
              setOnlyNow(!onlyNow)
            }}
          >
            {intl.t('widgets.inputSensorValues.form.actions.nowOnly')}
          </button>
        </div>
      </div>

      {errors.length > 0 ? (
        <div className="my-2 d-flex">
          <div className="alert alert-danger m-0 flex-fill" role="alert">
            <ul className="m-0 ps-3">
              {errors.map((item, idx) => (
                <li key={idx}>
                  <SensorListBinded
                    value={item.sensorId}
                    keyName="id"
                    selectedOnly
                  />
                  {', '}
                  <FormatDateTime date={item.timestamp} />
                  {', '}
                  {item.value}
                  <br />
                  {item.result.error}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}

      <CustomScrollbars
        className="flex-fill min-h-0"
        classNameContent="p-1 pt-2"
        horizontal={false}
        shadow={false}
      >
        {currentItems.map((item, idx) => (
          <InputItem
            key={item.id}
            idx={idx}
            isSingle={currentItems.length === 1}
            isLast={idx === currentItems.length - 1}
            len={currentItems.length}
            row={item}
            size={size || (win?.width && win.width < 660 ? 'sm' : undefined)}
            showLastValues={showLastValues}
            precision={precision}
            businessType={businessType}
            readOnly={readOnly}
            formModify={formModify}
            onlyNow={onlyNow}
            onUpdateRecord={onUpdateRecord}
            onUpdateValue={onUpdateValue}
            onRemoveValue={onRemoveValue}
            onCreateValue={onCreateValue}
            onRemoveRecord={onRemoveRecord}
          />
        ))}
        {currentItems.length ? null : (
          <div className="position-absolute-fill position-center-fill">
            {intl.t('errors.noDataDisplay')}
          </div>
        )}
      </CustomScrollbars>

      <div className="py-2 d-flex justify-content-end text-nowrap">
        {readOnly ? (
          <>
            <button
              type="button"
              data-testid="discardButton"
              className="btn btn-outline-secondary m-1"
              disabled={loading}
              onClick={onDiscard}
            >
              {intl.t('widgets.inputSensorValues.form.actions.discard')}
            </button>

            <button
              type="button"
              data-testid="confirmButton"
              className="btn btn-primary m-1"
              disabled={loading || !currentItems.length}
              onClick={onSend}
            >
              {loading ? (
                <Icon name="fa--spinner" className="me-1" spin />
              ) : null}
              {intl.t('widgets.inputSensorValues.form.actions.confirmSend')}
            </button>
          </>
        ) : (
          <>
            {formModify ? (
              <button
                type="button"
                className="btn btn-outline-secondary m-1 text-nowrap"
                onClick={onCreateRecord}
              >
                {intl.t('widgets.inputSensorValues.form.actions.add')}
              </button>
            ) : null}

            {onClose ? (
              <button
                name="close"
                type="button"
                data-testid="closeButton"
                className="btn btn-outline-secondary m-1"
                onClick={onClose}
              >
                {intl.t('form.actions.close')}
              </button>
            ) : null}

            <button
              type="button"
              data-testid="sendButton"
              className="btn btn-primary m-1 text-nowrap"
              onClick={onConfirm}
            >
              {intl.t('widgets.inputSensorValues.form.actions.send')}
            </button>
          </>
        )}
      </div>
    </>
  )
}
