import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { DataSource, DataView } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import SectionData from './SectionData'
import SectionGeneral from './SectionGeneral'
import Schema from './Schema'

export default function HistogramSource ({
  id,
  dataView,
  dataSource,
  dialog,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()
  const defaultValues = useMemo(() => ({
    dataView: DataView.toJSON(dataView),
    dataSource: DataSource.toJSON(dataSource),
  }), [
    dataView?.updatedAt ?? dataView,
    dataSource?.updatedAt ?? dataSource,
  ])

  if (id && !dataView) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      schema={Schema}
      dataTestid="historgamSourceForm"
      dialog={dialog}
    >
      <SectionGeneral />

      <SectionData
        title={intl.t('widgets.histogram.form.fields.dataSection')}
        description={intl.t('widgets.histogram.form.fields.dataSectionDescr')}
        scope="dataSource"
      />
    </FormDialog>
  )
}

HistogramSource.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dataView: PropTypes.object,
  dataSource: PropTypes.object,
}

HistogramSource.defaultProps = {
  dataView: undefined,
  dataSource: undefined,
}
