import { Icon } from '@wiz/components'
import { useAuth } from '@/auth'
import Link from '@/components/Link'

function CellTwin ({ target, onFilter }) {
  const auth = useAuth()
  return (
    <div className="d-flex align-items-center min-w-0 me-2">
      {onFilter ? (
        <button
          type="button"
          className="btn btn-sm p-0 border-0 me-2"
          onClick={() => onFilter?.({ twin: target.id })}
        >
          <Icon
            name="fa--search"
          />
        </button>
      ) : null}

      {auth.checkAccessRead(target) ? (
        <Link
          className="text-truncate"
          title={target.displayName}
          name="twin-items-list"
          query={{
            type: target?.type,
            search: target.id,
            view: 'table',
          }}
        >
          {target.displayName}
        </Link>
      ) : (
        <span className="text-truncate" title={target.displayName}>
          {target.displayName}
        </span>
      )}
    </div>
  )
}

export default function CellTwins ({
  options,
  onFilter,
}) {
  return options.map(target => (
    <CellTwin key={target.id} target={target} onFilter={onFilter} />
  ))
}
