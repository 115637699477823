import { of as of$, combineLatest } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { uniqKey, createNestedTree, sortNestedTree } from '@wiz/utils'
import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'
import EventList from '@/hoc/EventList'
import Component from '@/components/RightBarEvents/List'

const handleSortNestedTree = (a, b) => {
  let compare
  if (a.children && b.children) {
    compare = a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  } else if (a.children && !b.children) {
    compare = 1
  } else if (!a.children && b.children) {
    compare = -1
  } else {
    compare = 0
  }
  return compare
}

const enhanceTwinData = withObservables([
  'blocks',
  'blockSettings',
  'options',
  'twinIds',
], ({
  blocks,
  blockSettings,
  options,
  twinIds,
}) => {
  let eventTwins = of$([])

  if (options.length) {
    eventTwins = dbProvider.database.collections.get('twins')
      .query(Q.where('id', Q.oneOf(uniqKey(options, 'twinId'))))
      .observe()
      .pipe(
        switchMap(items => (
          items.length ? combineLatest(
            items.map(item => item.observeTwinsChainTo(twinIds)).concat(of$(items)),
          ) : of$([])
        )),
      )
  }

  const selectedTwins = dbProvider.database.collections.get('twins')
    .query(Q.where('id', Q.oneOf(twinIds)))
    .observe()

  return {
    options: combineLatest(selectedTwins, eventTwins)
      .pipe(
        map(items => Array.from(new Set(items.flat(Infinity))).concat(options)),
        map(createNestedTree()),
        map(items => Object.keys(blockSettings).reduce((out, id) => {
          const setting = blockSettings[id]
          const twinId = setting?.twinId || setting?.id
          const block = blocks.find(item => item.id === id)

          if (block && twinId && twinIds.includes(twinId)) {
            let children = items.find(item => item.id === setting.id)?.children || []

            if (!children.length && twinId) {
              children = options
                .filter(item => item.twinId === twinId)
                .map(item => ({
                  id: item.id,
                  name: item.name,
                  payload: item,
                }))
            }

            out.push({
              id: block.id,
              name: block.name || setting?.name,
              payload: block,
              children,
            })
          }

          return out
        }, [])),
        map(items => sortNestedTree(items, handleSortNestedTree)),
      ),
  }
})

export default EventList(
  enhanceTwinData(Component),
)
