/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  VirtualTable,
  Pagination,
  Icon,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'

export default function TableMobile ({
  items,
  onView,
  onEdit,
  onRemove,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      Cell: ({ row, cell }) => (
        <div className="min-w-0">
          <div className="d-flex align-items-center">
            <span className="text-truncate min-w-0">
              {cell.value}
            </span>
          </div>

          {/* {row.original.description ? (
            <div className="font-italic text-muted text-truncate small">
              {row.original.description}
            </div>
          ) : null} */}
        </div>
      ),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      className: 'justify-content-end text-nowrap',
      Cell: ({ row, cell }) => (
        <>
          {auth.checkAccessRemove(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-2"
              onClick={() => onRemove?.(row.original)}
            >
              <Icon name="fa--trash-alt" />
            </button>
          ) : null}
          {auth.checkAccessUpdate(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-2"
              onClick={() => onEdit?.(cell.value)}
            >
              <Icon name="fa--edit" />
            </button>
          ) : null}
        </>
      ),
    },
  ], [
    onRemove,
    intl,
    auth,
    onEdit,
  ])

  return (
    <>
      <VirtualTable
        className="flex-fill"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        showHeader={false}
        {...props}
      />

      <Pagination
        {...props}
        className="mt-3"
        pageSizeNavigation={false}
        showTotal={false}
        relative
      />
    </>
  )
}

TableMobile.propTypes = {
  items: PropTypes.arrayOf(),
  filters: PropTypes.objectOf(),
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onToggleActive: PropTypes.func,
}

TableMobile.defaultProps = {
  items: [],
  filters: {},
  onRemove: undefined,
  onEdit: undefined,
  onView: undefined,
  onToggleActive: undefined,
}
