/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import TwinList from '@/hoc/TwinList'
import Observe from '@/containers/Observe'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'
import CellTwin from './CellTwin'

const CellTwinData = TwinList(CellTwin)

export default function ViewModel ({
  title,
  qualityDataTemplate,
  onClose,
  onRemove,
  onEdit,
  onDuplicate,
  onFilter,
}) {
  const refDialog = useRef()
  const intl = useIntl()
  const auth = useAuth()

  const handleFilter = (filter) => {
    refDialog.current.close()
    onFilter?.(filter)
  }

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('form.fields.name'),
          value: qualityDataTemplate.name || '-',
        },
        {
          title: intl.t('form.fields.twins'),
          value: () => (
            <Observe query={qualityDataTemplate.queryRelTwins}>
              {({ data }) => (
                <CellTwinData
                  value={data.map(item => item.twinId)}
                  selectedOnly
                  onFilter={handleFilter}
                />
              )}
            </Observe>
          ),
        },
      ],
    },

    {
      title: intl.t('form.fields.updated'),
      value: () => (
        <UpdatedInfo
          date={qualityDataTemplate.updatedAt}
          user={qualityDataTemplate.updatedUser}
        />
      ),
    },
    {
      title: intl.t('form.fields.created'),
      value: () => (
        <UpdatedInfo
          date={qualityDataTemplate.createdAt}
          user={qualityDataTemplate.createdUser}
        />
      ),
    },
  ]

  const actions = [
    ...(onRemove && auth.checkAccessRemove(qualityDataTemplate) ? ([{
      title: intl.t('form.actions.remove'),
      name: 'remove',
      testid: 'removeButton',
      onClick: () => onRemove([ qualityDataTemplate ]),
    }]) : []),

    ...(onDuplicate && auth.checkAccessCopy(qualityDataTemplate) ? ([{
      title: intl.t('form.actions.duplicate'),
      name: 'duplicate',
      testid: 'duplicateButton',
      onClick: () => onDuplicate(qualityDataTemplate),
    }]) : []),

    ...(onEdit && auth.checkAccessUpdate(qualityDataTemplate) ? ([{
      title: intl.t('form.actions.edit'),
      name: 'edit',
      testid: 'editButton',
      onClick: () => onEdit(qualityDataTemplate.id),
    }]) : []),
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      actions={actions}
      onClose={onClose}
    />
  )
}
