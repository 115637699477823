/* eslint-disable no-unused-expressions */
import {
  forwardRef,
  useRef,
  useEffect,
  useContext,
  useMemo,
} from 'react'
import classnames from 'classnames'
import { withResizeObserver, useMatchWidth } from '@wiz/components'
import DataExplorer from '@/context/DataExplorer'
import useSplit from '@/hooks/useSplit'
import RightBar from './RightBar'
import LayoutSettings from './LayoutSettings'
import LayoutExplore from './LayoutExplore'

const DefaultSplitSizes = [ 30, 70 ]
const DefaultSplitSizesFullScreen = [ 0, 100 ]
const SplitMinSizes = [ 0, 300 ]
const VerticalDefaultSplitSizes = [ 70, 30 ]
const VerticalDefaultSplitSizesFullScreen = [ 100, 0 ]
const VerticalSplitMinSizes = [ 300, 0 ]

function ViewContent ({
  width,
}) {
  const refSettings = useRef()
  const refExplore = useRef()
  const context = useContext(DataExplorer)
  const isVertical = useMatchWidth(width, 'tablet')

  const {
    settings: {
      explorerViewFullscreen,
      explorerViewSplit,
    },
    updateSplit,
  } = context

  const splitParams = useMemo(() => (isVertical ? {
    items: [ refExplore, refSettings ],
    direction: 'vertical',
    sizes: (do {
      if (explorerViewFullscreen) {
        VerticalDefaultSplitSizesFullScreen
      } else if (explorerViewSplit) {
        explorerViewSplit.slice().reverse()
      }
    }),
    defaultSizes: VerticalDefaultSplitSizes,
    minSize: VerticalSplitMinSizes,
    onChange: data => updateSplit(data.slice().reverse()),
  } : {
    items: [ refSettings, refExplore ],
    direction: 'horizontal',
    sizes: explorerViewFullscreen ?
      DefaultSplitSizesFullScreen :
      explorerViewSplit,
    defaultSizes: DefaultSplitSizes,
    minSize: SplitMinSizes,
    onChange: data => updateSplit(data),
  }), [
    isVertical,
    explorerViewFullscreen,
    explorerViewSplit,
    updateSplit,
  ])

  const refSplit = useSplit(splitParams)

  useEffect(() => {
    if (refSplit?.current) {
      if (explorerViewFullscreen) {
        refSplit.current.collapse(isVertical ? 1 : 0)
      } else {
        const sizes = do {
          if (isVertical) {
            if (explorerViewSplit) {
              explorerViewSplit.slice().reverse()
            } else {
              VerticalDefaultSplitSizes
            }
          } else {
            (explorerViewSplit || DefaultSplitSizes)
          }
        }

        refSplit.current.setSizes(sizes)
      }
    }
  }, [
    explorerViewFullscreen,
    explorerViewSplit,
    isVertical,
    refSplit,
  ])

  const content = [
    <LayoutSettings
      key="settings"
      ref={refSettings}
    />,

    <LayoutExplore
      key="explore"
      ref={refExplore}
    />,
  ]

  return (
    <div
      className={classnames('d-flex flex-fill min-h-0 min-w-0', {
        'flex-column': isVertical,
      })}
    >
      {isVertical ? content.slice().reverse() : content}
    </div>
  )
}

function View ({
  width,
  container,
}, ref) {
  if (typeof width !== 'number') {
    return <div ref={ref} className="flex-fill" />
  }

  return (
    <div
      ref={ref}
      className="d-flex flex-fill min-h-0 min-w-0"
    >
      <ViewContent
        width={width}
      />
      <RightBar container={container} />
    </div>
  )
}

export default withResizeObserver(forwardRef(View))
