import { of as of$, combineLatest } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'
import Component from '@/components/RightBarTwinGraph'

const enhanceData = withObservables([ 'blockIds' ], ({ blockIds }) => {
  const blocksCollection = dbProvider.database.collections.get('diagram_blocks')
  return {
    // [ [block model], ... ]
    blocks: blocksCollection
      .query(Q.where('id', Q.oneOf(blockIds)))
      .observeWithColumns([ 'updated_at' ]),

    // { [block model uuid]: [setting model], ... }
    blockSettings: blocksCollection
      .query(
        Q.where('id', Q.oneOf(blockIds)),
        Q.where('type', Q.oneOf([
          'area',
          'equipment',
          'flow',
          'machine',
          'nestedTwinGraph',
          'twinGraph',
        ])),
      )
      .observe()
      .pipe(
        switchMap(items => (
          items.length ? (
            combineLatest(items.map(item => item.observeFullSettings))
              .pipe(
                map(settings => settings.map((setting, idx) => ([
                  items[idx].id,
                  setting && !setting.hyperedge ? setting : null,
                ]))),
              )
          ) : of$([])
        )),

        map(Object.fromEntries),
      ),
  }
})

export default enhanceData(Component)
