import BlockRemoved from '@/components/Forms/Removed'
import WidgetBulletPlot from '@/components/Forms/WidgetBulletPlot'
import WidgetChart from '@/components/Forms/WidgetChart'
import WidgetDeviceCommand from '@/components/Forms/WidgetDeviceCommand'
import WidgetDeviceCommands from '@/components/Forms/WidgetDeviceCommands'
import WidgetDeviceMonitoringVisual from '@/components/Forms/WidgetDeviceMonitoringVisual'
import WidgetDynamicBulletPlot from '@/components/Forms/WidgetDynamicBulletPlot'
import WidgetEvents from '@/components/Forms/WidgetEvents'
import WidgetGauge from '@/components/Forms/WidgetGauge'
import WidgetHistogram from '@/components/Forms/WidgetHistogram'
import WidgetInputSensorValues from '@/components/Forms/WidgetInputSensorValues'
import WidgetIssues from '@/components/Forms/WidgetIssues'
import WidgetKPICalendarHeatmap from '@/components/Forms/WidgetKPICalendarHeatmap'
import WidgetLocation from '@/components/Forms/WidgetLocation'
import WidgetLossAndSavings from '@/components/Forms/WidgetLossAndSavings'
import WidgetPlotly from '@/components/Forms/WidgetPlotly'
import WidgetProjects from '@/components/Forms/WidgetProjects'
import WidgetRoiProject from '@/components/Forms/WidgetRoiProject'
import WidgetRoseWind from '@/components/Forms/WidgetRoseWind'
import WidgetSensor from '@/components/Forms/WidgetSensor'
import WidgetTableData from '@/components/Forms/WidgetTableData'
import WidgetTimeline from '@/components/Forms/WidgetTimeline'
import WidgetGrafana from '@/components/Forms/WidgetGrafana'

import enhanceSettingsChart from '../WidgetChart/enhanceSettingsChart'
import enhanceSettingsDynamicBulletPlot from '../WidgetDynamicBulletPlot/enhanceSettingsDynamicBulletPlot'
import enhanceSettingsHistogram from '../WidgetHistogram/enhanceSettingsHistogram'
import enhanceSettingsPlotly from '../WidgetPlotly/enhanceSettingsPlotly'
import enhanceSettingsSensor from '../WidgetSensor/enhanceSettingsSensor'
import enhanceSettingsGauge from '../WidgetGauge/enhanceSettingsSensor'
import enhanceSettingsTableData from '../WidgetTableData/enhanceSettingsTableData'
import enhanceSettingsGrafana from '../WidgetGrafana/enhanceSettingsGrafana'

const FormByType = {
  bulletPlot: WidgetBulletPlot,
  chart: enhanceSettingsChart(WidgetChart),
  deviceCommand: WidgetDeviceCommand,
  deviceCommands: WidgetDeviceCommands,
  deviceMonitoringVisual: WidgetDeviceMonitoringVisual,
  dynamicBulletPlot: enhanceSettingsDynamicBulletPlot(WidgetDynamicBulletPlot),
  events: WidgetEvents,
  gauge: enhanceSettingsGauge(WidgetGauge),
  grafana: enhanceSettingsGrafana(WidgetGrafana),
  histogram: enhanceSettingsHistogram(WidgetHistogram),
  inputSensorValues: WidgetInputSensorValues,
  issues: WidgetIssues,
  kpiCalendarHeatmap: WidgetKPICalendarHeatmap,
  location: WidgetLocation,
  lossAndSavings: WidgetLossAndSavings,
  plotly: enhanceSettingsPlotly(WidgetPlotly),
  projects: WidgetProjects,
  roiProject: WidgetRoiProject,
  roseWind: WidgetRoseWind,
  sensor: enhanceSettingsSensor(WidgetSensor),
  tableData: enhanceSettingsTableData(WidgetTableData),
  timeline: WidgetTimeline,
}

export default function FormWidget (props) {
  const type = props.widget?.type
  const FormComponent = FormByType[type]
  return FormComponent ? <FormComponent {...props} /> : <BlockRemoved />
}
