/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'

export default function ViewModel ({
  title,
  alert,
  onClose,
  onRemove,
  onEdit,
  onDuplicate,
}) {
  const refDialog = useRef()
  const intl = useIntl()
  const auth = useAuth()

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('form.fields.name'),
          value: alert.name || '-',
        },
        {
          title: intl.t('form.fields.description'),
          value: alert.description || '-',
        },
      ],
    },

    {
      title: intl.t('form.fields.updated'),
      value: () => (
        <UpdatedInfo
          date={alert.updatedAt}
          user={alert.updatedUser}
        />
      ),
    },
    {
      title: intl.t('form.fields.created'),
      value: () => (
        <UpdatedInfo
          date={alert.createdAt}
          user={alert.createdUser}
        />
      ),
    },
  ]

  const actions = [
    ...(onRemove && auth.checkAccessRemove(alert) ? ([{
      title: intl.t('form.actions.remove'),
      name: 'remove',
      testid: 'removeButton',
      onClick: () => onRemove([ alert ]),
    }]) : []),

    ...(onDuplicate && auth.checkAccessCopy(alert) ? ([{
      title: intl.t('form.actions.duplicate'),
      name: 'duplicate',
      testid: 'duplicateButton',
      onClick: () => onDuplicate(alert),
    }]) : []),

    ...(onEdit && auth.checkAccessUpdate(alert) ? ([{
      title: intl.t('form.actions.edit'),
      name: 'edit',
      testid: 'editButton',
      onClick: () => onEdit(alert.id),
    }]) : []),
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      actions={actions}
      onClose={onClose}
    />
  )
}
