/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import ModelView from '@/components/Dialogs/ModelView'

export default function ViewModuleModel ({
  title,
  module,
  onClose,
}) {
  const refDialog = useRef()
  const intl = useIntl()

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('edge.modules.form.fields.moduleId'),
          value: module.moduleId,
        },
        {
          title: intl.t('edge.modules.form.fields.type'),
          value: module.type,
        },
        {
          title: intl.t('edge.modules.form.fields.includedToDeploy'),
          value: module.includedToDeploy ? 'Yes' : 'No',
        },
        {
          title: intl.t('edge.modules.form.fields.reportedByDevice'),
          value: module.reportedByDevice ? 'Yes' : 'No',
        },
        {
          title: intl.t('edge.modules.form.fields.runtimeStatus'),
          value: module.runtimeStatus,
        },
        {
          title: intl.t('edge.modules.form.fields.exitCode'),
          value: module.exitCode,
        },
        {
          title: intl.t('edge.modules.form.fields.configStatus'),
          value: module.configStatus,
        },
      ],
    },
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      onClose={onClose}
    />
  )
}
