import {
  forwardRef,
  useRef,
  useEffect,
  useImperativeHandle,
} from 'react'
import { DropdownMenu } from '@wiz/components'
import { consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { auth } from '@/auth'

const ContextMenu = forwardRef(({
  block,
  coords,
  container,
  loc,
  onHide,
  onAction,
  snsAvailable,
}, ref) => {
  const refTarget = useRef()
  const refMenu = useRef()
  const intl = useIntl()

  useEffect(() => {
    if (coords) {
      refMenu.current?.open()
    }
    return () => {
      refMenu.current?.close()
    }
  }, [ coords ])

  useImperativeHandle(ref, () => ({
    close () {
      refMenu.current?.close()
    },
  }))

  const snsBlock = snsAvailable ? [{
    id: consts.DiagramBlockType.Sns,
    symbol: 'store',
    icon: 'fa--bell',
    className: 'bg-danger',
    textClassName: 'text-danger',
  }] : []

  const options = block ? [
    {
      action: 'edit',
      params: block,
      label: intl.t('form.actions.edit'),
      icon: 'fa--edit',
      disabled: !auth.checkAccessUpdate(block),
    },
    {
      action: 'copy',
      params: block,
      label: intl.t('form.actions.duplicate'),
      icon: 'fa--copy',
      disabled: !(
        block.type !== consts.DiagramBlockType.Flow &&
        auth.checkAccessCopy(block)
      ),
    },
    {
      action: 'remove',
      params: block,
      label: intl.t('form.actions.remove'),
      icon: 'fa--trash-alt',
      disabled: !auth.checkAccessRemove(block),
    },
  ] : consts.StreamJobBlockTypesInfo.concat(snsBlock)
    .filter(item => (
      item.id !== consts.DiagramBlockType.DeviceCommand ||
      auth.checkAccessManage('SectionDeviceCommands')
    )).map(item => ({
      action: 'attach',
      params: { type: item.id },
      label: intl.t(`enum.diagramBlock.${item.id}`),
      className: item.className,
      icon: item.icon,
    }))

  return (
    <>
      <div
        ref={refTarget}
        style={coords ? {
          display: 'block',
          left: `${coords[0]}px`,
          top: `${coords[1]}px`,
          pointerEvents: 'none',
          position: 'absolute',
          zIndex: 10001,
          width: '1px',
          height: '1px',
        } : {
          display: 'none',
        }}
      />

      <DropdownMenu
        ref={refMenu}
        target={refTarget}
        mode={null}
        container={container}
        placement="right-start"
        options={options}
        onHide={onHide}
        onClick={params => onAction?.(params, { block, loc })}
      />
    </>
  )
})

export default ContextMenu
