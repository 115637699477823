import { Controller, useFormContext } from 'react-hook-form'
import {
  FormField,
  FormControl,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import ProjectList from '@/hoc/ProjectList'
import Select from '@/components/Form/Select'

export default function Fields () {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('form.fields.description')}
        description={intl.t('files.form.fields.descriptionDescr')}
        errors={get(errors, 'description')}
      >
        <FormControl
          type="textarea"
          name="description"
          rules={{
            maxLength: {
              value: 450,
              message: intl.t('form.errors.fieldMaxlen', { max: 450 }),
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.projects')}
        description={intl.t('files.form.fields.projectsDescr')}
        errors={get(errors, 'projects')}
      >
        <Controller
          name="projects"
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              ListSource={ProjectList}
              placeholder={intl.t('form.fields.projects')}
              multiselect
            />
          )}
        />
      </FormField>
    </>
  )
}
