import { useState } from 'react'
import { useIntl } from '@wiz/intl'
import { useMobile } from '@wiz/components'
import Table from '@/containers/SentSMSLogs/Table'
import TableMobile from '@/containers/SentSMSLogs/TableMobile'
import ViewModel from './ViewModel'

export default function List ({
  filters,
  forceUpdate,
  onFilter,
  onResetFilter,
  ...props
}) {
  const intl = useIntl()
  const isMobile = useMobile()
  const [ viewData, setViewData ] = useState(null)

  const TableComponent = isMobile ? TableMobile : Table

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">
      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <TableComponent
          {...props}
          filters={filters}
          onFilter={onFilter}
          onView={setViewData}
        />
      </div>

      {viewData ? (
        <ViewModel
          sentSMSLog={viewData}
          title={intl.t('sentSMSLogs.titleDetail')}
          onClose={() => setViewData(null)}
          onFilter={onFilter}
          dialog
        />
      ) : null}
    </div>
  )
}
