/*
http://echarts.baidu.com/theme-builder/
*/

const color = require('color')

module.exports = {
  color: [
    '#f05b5b',
    '#759aa0',
    '#e69d87',
    '#8dc1a9',
    '#ea7e53',
    '#eedd78',
    '#73a373',
    '#73b9bc',
    '#7289ab',
    '#91ca8c',
    '#f49f42',
  ],
  markLine: {
    label: {
      color: '#fff',
    },
  },
  backgroundColor: 'transparent',
  textStyle: {
    color: '#d8d9da',
    fontFamily: 'Montserrat, Helvetica Neue, Arial, sans-serif',
    fontSize: 12,
    fontWeight: 400,
  },
  title: {
    textStyle: {
      color: '#d8d9da',
      fontFamily: 'Montserrat, Helvetica Neue, Arial, sans-serif',
      fontSize: 15,
      lineHeight: 15,
      fontWeight: 400,
    },
    subtextStyle: {
      color: '#d8d9da',
      fontFamily: 'Montserrat, Helvetica Neue, Arial, sans-serif',
      fontSize: 12,
      fontWeight: 400,
    },
  },
  line: {
    itemStyle: {
      normal: {
        borderWidth: 1.5,
      },
    },
    lineStyle: {
      width: 1.5,
    },
    symbolSize: 6,
    symbol: 'circle',
    smooth: true,
  },
  radar: {
    itemStyle: {
      normal: {
        borderWidth: 1,
      },
    },
    lineStyle: {
      normal: {
        width: 2,
      },
    },
    symbolSize: 4,
    symbol: 'emptyCircle',
    smooth: false,
  },
  bar: {
    itemStyle: {
      normal: {
        barBorderWidth: 0,
        barBorderColor: '#d8d9da',
      },
      emphasis: {
        barBorderWidth: 0,
        barBorderColor: '#d8d9da',
      },
    },
    label: {
      color: '#fff',
    },
  },
  pie: {
    itemStyle: {
      normal: {
        borderWidth: 0,
        borderColor: '#d8d9da',
      },
      emphasis: {
        borderWidth: 0,
        borderColor: '#d8d9da',
      },
    },
  },
  scatter: {
    itemStyle: {
      normal: {
        borderWidth: 0,
        borderColor: '#d8d9da',
      },
      emphasis: {
        borderWidth: 0,
        borderColor: '#d8d9da',
      },
    },
  },
  boxplot: {
    itemStyle: {
      normal: {
        borderWidth: 0,
        borderColor: '#d8d9da',
      },
      emphasis: {
        borderWidth: 0,
        borderColor: '#d8d9da',
      },
    },
  },
  parallel: {
    itemStyle: {
      normal: {
        borderWidth: 0,
        borderColor: '#d8d9da',
      },
      emphasis: {
        borderWidth: 0,
        borderColor: '#d8d9da',
      },
    },
  },
  sankey: {
    itemStyle: {
      normal: {
        borderWidth: 0,
        borderColor: '#d8d9da',
      },
      emphasis: {
        borderWidth: 0,
        borderColor: '#d8d9da',
      },
    },
  },
  funnel: {
    itemStyle: {
      normal: {
        borderWidth: 0,
        borderColor: '#d8d9da',
      },
      emphasis: {
        borderWidth: 0,
        borderColor: '#d8d9da',
      },
    },
  },
  gauge: {
    itemStyle: {
      normal: {
        borderWidth: 0,
        borderColor: '#d8d9da',
      },
      emphasis: {
        borderWidth: 0,
        borderColor: '#d8d9da',
      },
    },
    title: {
      color: '#d8d9da',
      fontFamily: 'Montserrat, Helvetica Neue, Arial, sans-serif',
      fontSize: 12,
      fontWeight: 400,
    },
  },
  candlestick: {
    itemStyle: {
      normal: {
        color: '#fd1050',
        color0: '#0cf49b',
        borderColor: '#fd1050',
        borderColor0: '#0cf49b',
        borderWidth: 1,
      },
    },
  },
  graph: {
    itemStyle: {
      normal: {
        borderWidth: 0,
        borderColor: '#d8d9da',
      },
    },
    lineStyle: {
      normal: {
        width: 1,
        color: '#aaaaaa',
      },
    },
    symbolSize: 4,
    symbol: 'emptyCircle',
    smooth: false,
    color: [
      '#f05b5b',
      '#759aa0',
      '#e69d87',
      '#8dc1a9',
      '#ea7e53',
      '#eedd78',
      '#73a373',
      '#73b9bc',
      '#7289ab',
      '#91ca8c',
      '#f49f42',
    ],
    label: {
      normal: {
        textStyle: {
          color: '#d8d9da',
        },
      },
    },
  },
  map: {
    itemStyle: {
      normal: {
        areaColor: '#eeeeee',
        borderColor: '#444444',
        borderWidth: 0.5,
      },
      emphasis: {
        areaColor: 'rgba(255,215,0,0.8)',
        borderColor: '#444444',
        borderWidth: 1,
      },
    },
    label: {
      normal: {
        textStyle: {
          color: '#000000',
        },
      },
      emphasis: {
        textStyle: {
          color: 'rgb(100,0,0)',
        },
      },
    },
  },
  geo: {
    itemStyle: {
      normal: {
        areaColor: '#eeeeee',
        borderColor: '#444444',
        borderWidth: 0.5,
      },
      emphasis: {
        areaColor: 'rgba(255,215,0,0.8)',
        borderColor: '#444444',
        borderWidth: 1,
      },
    },
    label: {
      normal: {
        textStyle: {
          color: '#000000',
        },
      },
      emphasis: {
        textStyle: {
          color: 'rgb(100,0,0)',
        },
      },
    },
  },
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#464648',
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#464648',
      },
    },
    axisLabel: {
      show: true,
      fontSize: 11,
      margin: 8,
      textStyle: {
        color: '#d8d9da',
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        opacity: 0.3,
        color: [
          '#464648',
        ],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: [
          '#eeeeee',
        ],
      },
    },
  },
  angleAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#464648',
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#464648',
      },
    },
    axisLabel: {
      show: true,
      fontSize: 11,
      margin: 8,
      textStyle: {
        color: '#d8d9da',
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        opacity: 0.3,
        color: [
          '#464648',
        ],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: [
          '#eeeeee',
        ],
      },
    },
  },
  radiusAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#464648',
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#464648',
      },
    },
    axisLabel: {
      show: true,
      fontSize: 11,
      margin: 8,
      textStyle: {
        color: '#d8d9da',
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        opacity: 0.3,
        color: [
          '#464648',
        ],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: [
          '#eeeeee',
        ],
      },
    },
  },
  valueAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#464648',
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#464648',
      },
    },
    axisLabel: {
      show: true,
      fontSize: 11,
      margin: 8,
      textStyle: {
        color: '#d8d9da',
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        opacity: 0.3,
        color: [
          '#464648',
        ],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: [
          '#eeeeee',
        ],
      },
    },
  },
  logAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#464648',
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#464648',
      },
    },
    axisLabel: {
      show: true,
      fontSize: 11,
      margin: 8,
      textStyle: {
        color: '#d8d9da',
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        opacity: 0.3,
        color: [
          '#464648',
        ],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: [
          '#eeeeee',
        ],
      },
    },
  },
  timeAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#464648',
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#464648',
      },
    },
    axisLabel: {
      show: true,
      fontSize: 11,
      margin: 8,
      textStyle: {
        color: '#d8d9da',
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        opacity: 0.3,
        color: [
          '#464648',
        ],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: [
          '#eeeeee',
        ],
      },
    },
  },
  toolbox: {
    iconStyle: {
      normal: {
        borderColor: '#464648',
      },
      emphasis: {
        borderColor: '#666666',
      },
    },
  },
  legend: {
    textStyle: {
      color: '#d8d9da',
    },
  },
  tooltip: {
    backgroundColor: color('#323232').alpha(0.9).rgb().toString(),
    borderWidth: 0,
    textStyle: {
      color: '#d8d9da',
    },
    axisPointer: {
      label: {
        color: '#fff',
        shadowBlur: 0,
        fontSize: 11,
        backgroundColor: color('#171819').alpha(0.7).rgb().toString(),
      },
      lineStyle: {
        color: '#eeeeee',
        width: 1,
      },
      crossStyle: {
        color: '#eeeeee',
        width: 1,
      },
    },
  },
  timeline: {
    lineStyle: {
      color: '#eeeeee',
      width: 1,
    },
    itemStyle: {
      normal: {
        color: '#dd6b66',
        borderWidth: '1',
      },
      emphasis: {
        color: '#a9334c',
      },
    },
    controlStyle: {
      normal: {
        color: '#eeeeee',
        borderColor: '#eeeeee',
        borderWidth: 0.5,
      },
      emphasis: {
        color: '#eeeeee',
        borderColor: '#eeeeee',
        borderWidth: 0.5,
      },
    },
    checkpointStyle: {
      color: '#e43c59',
      borderColor: 'rgba(194,53,49,0.5)',
    },
    label: {
      normal: {
        textStyle: {
          color: '#eeeeee',
        },
      },
      emphasis: {
        textStyle: {
          color: '#eeeeee',
        },
      },
    },
  },
  visualMap: {
    color: [
      '#bf444c',
      '#d88273',
      '#f6efa6',
    ],
    textStyle: {
      color: '#d8d9da',
      fontFamily: 'Montserrat, Helvetica Neue, Arial, sans-serif',
      fontSize: 12,
      fontWeight: 400,
    },
  },
  dataZoom: {
    borderColor: 'transparent',
    backgroundColor: color('#464648').alpha(0.2).rgb().toString(),
    dataBackground: { lineStyle: { opacity: 1 } },
    dataBackgroundColor: 'transparent',
    fillerColor: color('#464648').alpha(0.25).rgb().toString(),
    handleColor: '#464648',
    // "handleIcon": "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z",
    textStyle: {
      color: '#d8d9da',
      fontFamily: 'Montserrat, Helvetica Neue, Arial, sans-serif',
      fontSize: 11,
      fontWeight: 400,
    },
  },
  brush: {
    brushStyle: {
      color: color('#a7b7cc').alpha(0.2).rgb().toString(),
    },
  },
  markArea: {
    label: {
      color: '#d8d9da',
      normal: {
        textStyle: {
          color: '#d8d9da',
        },
      },
      emphasis: {
        textStyle: {
          color: '#d8d9da',
        },
      },
    },
  },
  markPoint: {
    label: {
      color: '#fff',
      normal: {
        textStyle: {
          color: '#fff',
        },
      },
      emphasis: {
        textStyle: {
          color: '#fff',
        },
      },
    },
  },
  calendar: {
    dayLabel: {
      color: '#d8d9da',
      fontFamily: 'Montserrat, Helvetica Neue, Arial, sans-serif',
      fontSize: 14,
      fontWeight: 400,
    },
    monthLabel: {
      color: '#d8d9da',
      fontFamily: 'Montserrat, Helvetica Neue, Arial, sans-serif',
      fontSize: 14,
      fontWeight: 400,
    },
    splitLine: {
      lineStyle: {
        opacity: 0.3,
        color: '#2c2c2c',
        width: 1,
      },
    },
    itemStyle: {
      normal: {
        color: '#323c48',
        borderWidth: 1,
        borderColor: '#888888',
      },
    },
  },
}
