import { type } from 'superstruct'
import SchemaTwin from './SchemaTwin'
import SchemaDashboards from './SchemaDashboards'
import SchemaExplorers from './SchemaExplorers'
import SchemaStreamJobs from './SchemaStreamJobs'
import SchemaTwinGraphs from './SchemaTwinGraphs'

export default type({
  twin: SchemaTwin,
  dashboards: SchemaDashboards,
  explorers: SchemaExplorers,
  streamJobs: SchemaStreamJobs,
  twinGraphs: SchemaTwinGraphs,
})
