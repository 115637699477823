import { Sensor } from '@wiz/store'
import OptionSensor from './Sensor'
import OptionBlock from './Block'

const Option = (props) => {
  const item = props.data.options[ props.index ]
  let Component

  if (item instanceof Sensor) {
    Component = OptionSensor
  } else {
    Component = OptionBlock
  }

  return <Component {...props} />
}

export default Option
