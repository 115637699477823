import { useMemo } from 'react'
import { Event as Event$ } from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import Section from './Section'

export default function Event ({
  id,
  event,
  defaultValues,
  dialog,
  onClose,
  onSubmit,
}) {
  const data = event || defaultValues
  const defaultValues$ = useMemo(() => ({
    event: Event$.toJSON(data),
  }), [ data?.updatedAt ?? data ])

  if (id && !event) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues$}
      dataTestid="eventForm"
      dialog={dialog}
    >
      <Section scope="event" />
    </FormDialog>
  )
}
