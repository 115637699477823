import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { withObservables } from '@wiz/components'

export default withObservables([ 'settings' ], ({ settings }) => ({
  dashboards: settings?.queryRelDashboards ? (
    settings.queryRelDashboards
      .observe()
      .pipe(
        map(items => items.map(item => item.dashboardId)),
      )
  ) : of$([]),

  explorers: settings?.queryRelExplorers ? (
    settings.queryRelExplorers
      .observe()
      .pipe(
        map(items => items.map(item => item.explorerId)),
      )
  ) : of$([]),

  streamJobs: settings?.queryRelStreamJobs ? (
    settings.queryRelStreamJobs
      .observe()
      .pipe(
        map(items => items.map(item => item.streamJobId)),
      )
  ) : of$([]),

  twinGraphs: settings?.queryRelTwinGraphs ? (
    settings.queryRelTwinGraphs
      .observe()
      .pipe(
        map(items => items.map(item => item.twinGraphId)),
      )
  ) : of$([]),
}))
