import { useFormContext } from 'react-hook-form'
import { FormFieldInline, FormControl } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function FieldColor ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <FormFieldInline
      label={intl.t('form.fields.color')}
      description={intl.t('label.form.fields.colorDescr')}
      errors={get(errors, `${scope}.color`)}
    >
      <FormControl
        type="color"
        name={`${scope}.color`}
        clearable
      />
    </FormFieldInline>
  )
}
