import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'

export default withObservables([ 'id' ], ({ id }) => ({
  // category: id ? dbProvider.database.collections.get('categories')
  //   .query(Q.where('id', id))
  //   .observeWithColumns([ 'updated_at' ])
  //   .pipe(map(items => items[0])) : of$(undefined),
  category: {},
}))
