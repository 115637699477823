import {
  FormSelectDateTimeRange,
  FormSelectNative,
  FormSelectDuration,
} from '@wiz/components'
import { consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import {
  shape, number, func, string,
} from 'prop-types'
import FormatDateTime from '@/containers/FormatDateTime'

const timeFrameOptions = [
  { name: 'drop', id: 'drop' },
  { name: 'all', id: 'all' },
  { name: 'ignore', id: 'ignore' },
]

const TimeRangeChooser = ({
  onDatesChange, dates, aggregations, onAggregationsChange, getError,
}) => {
  const intl = useIntl()

  const error = getError('timeRange') // dateFrom, dateTo, interval, aggMethod

  return (
    <div className="d-flex flex-fill m-1 flex-column px-3">
      <div className="d-flex flex-column flex-wrap">
        <div className="my-2">Time Range:</div>
        <FormSelectDateTimeRange
          className="w-auto"
          value={([ dates.dateFrom, dates.dateTo ])}
          DateTimeComponent={FormatDateTime}
          size="sm"
          onChange={([ dateFrom, dateTo ]) => {
            onDatesChange({ dateFrom, dateTo })
          }}
          invalid={error?.dateFrom || error?.dateTo}
        />
        {error?.dateFrom ? <div className="invalid-feedback d-block">{intl.t(error.dateFrom)}</div> : <div>&nbsp;</div>}

      </div>
      <div className="d-flex flex-wrap mt-2 mb-1">
        <b>Please select aggregation information:</b>
      </div>
      <div className="d-flex flex-column flex-wrap">
        <div className="flex-fill mb-2">Interval:</div>
        <FormSelectDuration
          value={aggregations.interval}
          className="flex-shrink-0 w-auto"
          size="sm"
          label={intl.t('form.fields.interval')}
          parts="days,hours,minutes,seconds"
          clearable
          onChange={interval => onAggregationsChange(prev => ({ ...prev, interval }))}
          invalid={error?.interval}
        />
        {error?.interval ? <div className="invalid-feedback d-block">{intl.t(error.interval)}</div> : <div>&nbsp;</div>}
      </div>
      <div className="d-flex flex-column flex-wrap">
        <div className="flex-fill mb-2">Method:</div>
        <FormSelectNative
          className="form-select-sm w-auto"
          placeholder="--"
          options={consts.AggregationMethod}
          defaultValue={aggregations.aggMethod}
          onChange={aggMethod => onAggregationsChange(prev => ({ ...prev, aggMethod }))}
          invalid={error?.aggMethod}
        />
        {error?.aggMethod ? <div className="invalid-feedback d-block">{intl.t(error.aggMethod)}</div> : <div>&nbsp;</div>}
      </div>
      <div className="d-flex flex-column flex-wrap">
        <div className="flex-fill mb-2">{intl.t('experiments.form.fields.selectOption')}</div>
        <FormSelectNative
          className="form-select-sm w-auto"
          options={timeFrameOptions}
          defaultValue={aggregations.option}
          onChange={option => onAggregationsChange(prev => ({ ...prev, option }))}
          invalid={error?.option}
        />
      </div>

    </div>
  )
}

export { TimeRangeChooser }

TimeRangeChooser.propTypes = {
  dates: shape({ dateFrom: number, dateTo: number }).isRequired,
  aggregations: shape({ aggMethod: string, interval: number }).isRequired,
  onDatesChange: func.isRequired,
  onAggregationsChange: func.isRequired,
}
