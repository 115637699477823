import { intersection, type } from 'superstruct'
import { Category } from '@wiz/store'
import * as types from '@/utils/types'

export default type({
  category: type({
    name: intersection([
      types.Required(),
      Category.schema.schema.name,
    ]),
    comment: Category.schema.schema.comment,
    color: Category.schema.schema.color,
    icon: Category.schema.schema.icon,
  }),
})
