import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { FormSection, useDidUpdate } from '@wiz/components'
import { set, get, consts } from '@wiz/utils'
import { DataSource } from '@wiz/store'
import FieldsVirtual from './FieldsVirtual'
import SettingsSectionScript from './SettingsSectionScript'
import SettingsSectionFormula from './SettingsSectionFormula'
import SettingsSectionMlModel from './SettingsSectionMlModel'

export default function SectionVirtual ({
  scope,
  title,
  addon,
  registerId = true,
}) {
  const {
    register, getValues, reset, setValue,
  } = useFormContext()
  const type = useWatch({ name: `${scope}.type` })
  const name = useWatch({ name: `${scope}.settings.script.name` })

  useDidUpdate(() => {
    // reset with keepDirty doesn't keep dirty fields if type is null
    // ignore it
    if (type) {
      const values = getValues()
      const dataSource = get(values, scope, {})
      dataSource.settings = undefined // need to remove before reset to clean ID and other fields
      set(values, scope, DataSource.toJSON(dataSource))
      reset(values, { keepDirty: true })
    }
  }, [ type, scope, reset, getValues ])

  useEffect(() => {
    if (type === consts.DataSourceType.Script && name?.startsWith('zML_MODEL_TEST')) {
      setValue(`${scope}.type`, consts.DataSourceType.MlModel)
    }
  }, [])

  return (
    <>
      <FormSection
        title={title}
        addon={addon}
        className="d-flex flex-fill"
        classNameContent="d-flex flex-column"
      >
        {registerId ? (
          <input
            {...register(`${scope}.id`)}
            type="hidden"
          />
        ) : null}

        <FieldsVirtual scope={scope} />
      </FormSection>

      {do {
        if (type === consts.DataSourceType.Formula) {
          <SettingsSectionFormula scope={`${scope}.settings`} />
        } else if (type === consts.DataSourceType.Script) {
          <SettingsSectionScript scope={`${scope}.settings`} />
        } else if (type === consts.DataSourceType.MlModel) {
          <SettingsSectionMlModel scope={`${scope}.settings`} />
        }
      }}
    </>
  )
}
