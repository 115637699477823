import { set, duration } from '@wiz/utils'
import { dbProvider } from '@wiz/store'
import { intl } from '@/i18n'

export default async function resolver (data) {
  const errors = {}

  if (
    data?.settings?.duration &&
    data?.settings?.inputType === 'sensorRawWindow'
  ) {
    const RawDataLimit = await dbProvider.fetchGlobalSettings('RawDataLimit')

    if (data.settings.duration > RawDataLimit) {
      set(errors, 'settings.duration', {
        type: 'max',
        message: intl.t('form.errors.fieldMax', { max: duration(RawDataLimit, { full: true }) }),
      })
    }
  }

  return {
    errors,
  }
}
