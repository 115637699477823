import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'
import { FormSection } from '@wiz/components'
import { consts } from '@wiz/utils'
import FieldMinValue from './FieldMinValue'
import FieldMaxValue from './FieldMaxValue'
import FieldBucketSize from './FieldBucketSize'
import FieldFactor from './FieldFactor'
import FieldMaxNumBuckets from './FieldMaxNumBuckets'
import FieldCategoryXAxis from './FieldCategoryXAxis'
import FieldInfinity from './FieldInfinity'
import FieldHistogramType from './FieldHistogramType'
import FieldNormalize from './FieldNormalize'
import FieldCumulative from './FieldCumulative'
import FieldBins from './FieldBins'
import FieldBinBoundaryPrecision from './FieldBinBoundaryPrecision'

export default function SectionView ({ scope }) {
  const histogramType = useWatch({ name: `${scope}.histogramType` })

  return (
    <FormSection>
      <FieldHistogramType scope={scope} />
      <FieldNormalize scope={scope} />
      <FieldCumulative scope={scope} />
      <FieldCategoryXAxis scope={scope} />
      <FieldBinBoundaryPrecision scope={scope} />

      {do {
        if (histogramType === consts.WidgetHistogramType.Custom) {
          <FieldBins scope={scope} />
        } else {
          (
            <>
              <FieldMaxNumBuckets scope={scope} />

              {do {
                if (histogramType === consts.WidgetHistogramType.Linear) {
                  <FieldBucketSize scope={scope} />
                }
              }}

              <FieldMinValue scope={scope} />

              {do {
                if (histogramType === consts.WidgetHistogramType.Logarithmic) {
                  <FieldFactor scope={scope} />
                } else {
                  <FieldMaxValue scope={scope} />
                }
              }}
              <FieldInfinity scope={scope} />
            </>
          )
        }
      }}
    </FormSection>
  )
}

SectionView.propTypes = {
  scope: PropTypes.string.isRequired,
}
