import { useCallback } from 'react'
import { func } from 'prop-types'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import List from '@/containers/Experiment/List'
import { consts } from '@wiz/utils'

export default function RightBarSolutionsList ({
  twinGraph,
  container,
  onChange,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()

  const handleAddExperiment = useCallback(() => {
    onChange('runWizard')
  }, [ onChange ])

  const handleRightBarOpen = useCallback(({ id, name, type }) => {
    const customFunction = type === consts.ExperimentType.Function
    onChange('experiment', { title: name, props: { id, customFunction: !!customFunction } })
  }, [ onChange ])

  return (
    <List
      {...props}
      hideHeader
      filters={twinGraph ? { boardId: twinGraph.id } : {}}
      exclude={twinGraph ? [ 'boardId' ] : []}
      container={container}
      onRightBarOpen={handleRightBarOpen}
      actions={[
        ...(auth.checkAccessCreate('Experiment') ? [
          {
            id: 'add',
            icon: 'fa--plus',
            title: intl.t('form.actions.add'),
            onClick: handleAddExperiment,
          },
        ] : []),
      ]}
    />
  )
}

RightBarSolutionsList.propTypes = {
  onChange: func.isRequired,
}
