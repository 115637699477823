import { User } from '@wiz/store'
import { Icon } from '@wiz/components'
import ObserveModel from '@/containers/ObserveModel'

export default function CellUserEmail ({
  userEmail,
  onFilter,
}) {
  return (
    userEmail ? (
      <ObserveModel model={User} value={userEmail} field="email">
        {({ data }) => (
          <div className="d-flex align-items-center min-w-0">
            <button
              type="button"
              className="btn btn-sm p-0 border-0 me-2"
              onClick={() => onFilter?.({ userEmail })}
            >
              <Icon
                name="fa--search"
              />
            </button>

            <span className="text-truncate">
              {data[0]?.name}
              {data[0]?.name !== userEmail ? (
                <>
                  <br />
                  <span className="small text-muted">{userEmail}</span>
                </>
              ) : null}
            </span>
          </div>
        )}
      </ObserveModel>
    ) : '-'
  )
}
