export default function pipelines (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      pipelinesCore: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value (params) {
              const request = client.post('/Pipelines/paged', { ...params })
              return request.fetch()
            },
          },
          getById: {
            enumerable: true,
            value (id) {
              return client.get(`/Pipelines/${id}`).fetch()
            },
          },
          deleteById: {
            enumerable: true,
            value (id) {
              return client.delete(`/Pipelines/${id}`).fetch()
            },
          },
          create: {
            enumerable: true,
            value (params) {
              return client.post('/Pipelines', { ...params }).fetch()
            },
          },
          update: {
            enumerable: true,
            value ({ id, ...params }) {
              return client.put(`/Pipelines/${id}`, { ...params }).fetch()
            },
          },
        }),
      },
      triggers: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value (params) {
              const request = client.post('/ExecutionTriggers/paged', { ...params })
              return request.fetch()
            },
          },
          getById: {
            enumerable: true,
            value (id) {
              return client.get(`/ExecutionTriggers/${id}`).fetch()
            },
          },
          deleteById: {
            enumerable: true,
            value (id) {
              return client.delete(`/ExecutionTriggers/${id}`).fetch()
            },
          },
          create: {
            enumerable: true,
            value (params) {
              return client.post('/ExecutionTriggers', { ...params }).fetch()
            },
          },
          update: {
            enumerable: true,
            value ({ id, ...params }) {
              return client.put(`/ExecutionTriggers/${id}`, { ...params }).fetch()
            },
          },
        }),
      },
      mlmodelsCore: {
        enumerable: true,
        value: Object.create({}, {
          getPagedList: {
            enumerable: true,
            value (params) {
              const request = client.post('/MlModels/paged', { ...params })
              return request.fetch()
            },
          },
          getById: {
            enumerable: true,
            value (id) {
              return client.get(`/MlModels/${id}`).fetch()
            },
          },
          deleteById: {
            enumerable: true,
            value (id) {
              return client.delete(`/MlModels/${id}`).fetch()
            },
          },
          create: {
            enumerable: true,
            value (params) {
              return client.post('/MlModels', { ...params }).fetch()
            },
          },
          update: {
            enumerable: true,
            value ({ id, ...params }) {
              return client.put(`/MlModels/${id}`, { ...params }).fetch()
            },
          },
        }),
      },
    })
  }
}
