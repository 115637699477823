import {
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react'
import {
  useDrag,
  useDidUpdate,
  useMobile,
} from '@wiz/components'
import { useMutation } from '@tanstack/react-query'
import { useIntl } from '@wiz/intl'
import events from '@/utils/events'
import { consts, mergeElementLocations } from '@wiz/utils'
import Diagram from '@/containers/Twin/Graph/Diagram'
// import LeftBar from '@/containers/Twin/Graph/LeftBar'
import FormTwin from '@/containers/Forms/Twin'
import FormSendToBoard from '@/containers/Forms/SendToBoard'
import BulkSensors from '@/components/Dialogs/BulkSensors'
import useAppContext from '@/hooks/useAppContext'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import { useRouter } from '@/router'
import { wizataApi } from '@/api'
import Icon from '@/shared/icon'
import { useAuth } from '@/auth'
import GraphToolbar from './GraphToolbar'
import classes from './index.css'

export default function Graph ({
  blockFactory,
  container,
  defaultRootTwin,
  defaultTree,
  filters,
  graphScrollTo,
  linkMode,
  onAdd,
  onBulkEdit,
  onChangeDiagram,
  onChangeViewMode,
  onDropCreateDiagram,
  onDuplicate,
  onFilter,
  onLinkDiagram,
  onMove,
  onPaste,
  onRemove,
  onSelect,
  onToggleGrid,
  onUpdateDefaultTree,
  rootTwin,
  rootTwinDiagram,
  selected,
  selectedIds,
  setLinkMode,
  state,
  twinsChain,
  globalSettings,
}) {
  const filterTree = filters?.tree
  const auth = useAuth()
  const intl = useIntl()
  const drag = useDrag()
  const router = useRouter()
  const isMobile = useMobile()
  const refDiagram = useRef()
  const refGraphDiagram = useRef()
  const refLeftBar = useRef()
  const refScroll = useRef()
  const refPreventScroll = useRef(false)
  const [ leftBarExpanded, setLeftBarExpanded ] = useState(false) // !rootTwin
  const [ scrollTo, setScrollTo ] = useState(graphScrollTo || filterTree)
  const [ editBlockId, setEditBlockId ] = useState()
  const [ createState, setCreateState ] = useState()
  const [ createBlockState, setCreateBlockState ] = useState()
  const [ sendToBoard, setSendToBoard ] = useState(false)
  const [ bulkSensorIds, setBulkSensorIds ] = useState([])
  const [ bulkSensorsType, setBulkSensorsType ] = useState()
  const { contextTwinId, handleTwinChange, setIsOpenTwinSelector } = useGlobalExecute()
  const { setSelectedTwinIds } = useAppContext()

  // const handleDiagramAlign = useCallback((value) => {
  //   refDiagram.current.align(value)
  // }, [])

  const {
    mutateAsync: autoRegisterTemplate,
  } = useMutation({
    mutationKey: [ 'autoRegisterTemplate' ],
    mutationFn: data => wizataApi.templates.autoGenerateTemplate(data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/templates.createError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: (data, { templateId }) => {
      router.push({ name: 'template', params: { id: templateId } })
    }
    ,
  })

  const handleDiagramFit = useCallback(() => {
    refDiagram.current.fit()
  }, [])

  const handleDiagramReshapeLinks = useCallback(() => {
    refDiagram.current.reshapeLinks()
  }, [])

  const handleLeftBarExpand = useCallback(() => {
    if (!leftBarExpanded) {
      setLeftBarExpanded(true)
    } else {
      refLeftBar.current.close()
    }
  }, [ leftBarExpanded ])

  const handleView = useCallback((id) => {
    // onFilter?.({ tree: id })
    handleTwinChange(id)
    router.push({ name: 'twin-items-list', query: { tree: id } })
  }, [ router ])

  const handleRootTwin = useCallback(() => {
    handleView('')
  }, [ handleView ])

  const handleViewInRoot = useCallback(({ recordId }) => {
    refPreventScroll.current = true
    onFilter?.({ tree: recordId })
    // router.push({ name: 'twin-items-list', query: { tree: recordId } })
    if (isMobile) {
      refLeftBar.current.close()
    }
  }, [ onFilter, isMobile ])

  const handleDropCreateDiagram = useCallback(async (blockState, parentNode) => {
    const { type } = drag.context
    let parent = parentNode
    if (!parentNode && rootTwinDiagram && consts.TwinTypes.includes(type)) {
      const isParentAllow = consts.TwinLinkPairs.some(item => (
        item[0] === rootTwin.type &&
        item[1] === type
      ))
    }
    if (!parentNode) {
      parent = rootTwin
    }

    await onDropCreateDiagram(blockState, parent)
  }, [ drag, onDropCreateDiagram, rootTwin ])

  const handleClickCreate = useCallback(async () => (
    handleDropCreateDiagram(refDiagram.current.getCenterCoords())
  ), [ handleDropCreateDiagram ])

  const handleSubmitSuccess = useCallback(async (model) => {
    // only for created blocks
    if (createBlockState) {
      drag.data.add(model.id)
      await onDropCreateDiagram(createBlockState)
      drag.clear()
    }
  }, [ drag, onDropCreateDiagram, createBlockState ])

  const handleDiagramDblClick = useCallback(({ id, category }) => {
    if (category !== consts.DiagramBlockCategory.Parent) {
      if (category === consts.DiagramBlockCategory.Descr) {
        setEditBlockId(id)
      } else {
        handleView(id)
      }
    }
  }, [ handleView ])

  const handleActionContextMenu = useCallback(async (
    { action, params },
    { block, loc, latlng },
  ) => {
    if (action === 'attach' && params.type) {
      const parentId = block?.id || rootTwin?.id || rootTwinDiagram?.id
      const { type } = params

      let blockState = mergeElementLocations(
        { loc, latlng },
        block,
        state?.elements?.[block?.id],
        rootTwinDiagram,
        state?.elements?.[rootTwinDiagram?.id],
      )
      // move block out from the parent block
      if (block) {
        blockState = mergeElementLocations({}, block, blockState)
      }

      setCreateBlockState(blockState)
      setCreateState({
        ...blockState,
        parentId,
        type,
      })
    } else if (action === 'edit') {
      if (block?.id) {
        if (selectedIds?.length > 1) {
          onBulkEdit()
        } else {
          setEditBlockId(block.id)
        }
      } else if (rootTwin?.id) {
        setEditBlockId(rootTwin.id)
      }
    } else if (action === 'remove' && block.id) {
      onRemove([ block ], rootTwinDiagram)
    } else if (action === 'toggleGrid') {
      onToggleGrid()
    } else if (action === 'fitPosition') {
      handleDiagramFit()
    } else if (action === 'reshapeLinks') {
      handleDiagramReshapeLinks()
    } else if (action === 'duplicate') {
      const ids = await onDuplicate(selectedIds)
      refDiagram.current?.selectAfterUpdate(ids)
    } else if (action === 'duplicateWithChildren') {
      const ids = await onDuplicate(selectedIds, undefined, { withChildren: true })
      refDiagram.current?.selectAfterUpdate(ids)
    } else if (action === 'highlightOnTheTree') {
      refScroll.current?.scrollTo(selectedIds[0])
    } else if (action === 'sendToBoard') {
      setSendToBoard(true)
    } else if ((
      action === 'explorer' ||
      action === 'export' ||
      action === 'notebook' ||
      action === 'dashboard'
    ) && params?.length) {
      setBulkSensorsType(action)
      const ids = params.map(sensor => sensor.id)
      setBulkSensorIds(ids)
    } else if (
      action === 'commands' ||
      action === 'events' ||
      action === 'sensors' ||
      action === 'setPoints' ||
      action === 'measurements' ||
      action === 'findReplace' ||
      action === 'info' ||
      action === 'solutions'
    ) {
      refGraphDiagram.current.openRightBar(action)
    } else if (action === 'runWizard') {
      refGraphDiagram.current.openRightBar(action, {
        title: params?.customFunction?.title || intl.t('experiments.titleWizard'),
        props: params,
      })
    } else if (action === 'selectTemplate') {
      const { twinId, templateId } = params || {}
      autoRegisterTemplate({ templateId, twinId })
    }
  }, [
    onDuplicate,
    onRemove,
    handleDiagramReshapeLinks,
    handleDiagramFit,
    onToggleGrid,
    rootTwinDiagram,
    selectedIds,
    state,
    onBulkEdit,
    intl,
    rootTwin,
    autoRegisterTemplate,
  ])

  const handleActionLeftBarMenu = useCallback(async (
    { id },
    twin,
    { prev, next } = {},
  ) => {
    if (id === 'edit') {
      setEditBlockId(twin.id)
    } else if (id === 'remove') {
      onRemove([ twin ], rootTwinDiagram, true)
    } else if (id === 'duplicate') {
      onDuplicate([ twin.id ], undefined, {
        moveAfter: twin.id,
      })
    } else if (id === 'duplicateWithChildren') {
      onDuplicate([ twin.id ], undefined, {
        moveAfter: twin.id,
        withChildren: true,
      })
    } else if (id === 'attach-area') {
      drag.context.type = consts.TwinType.Area
      await onDropCreateDiagram(undefined, twin)
      drag.clear()
    } else if (id === 'attach-machine') {
      drag.context.type = consts.TwinType.Machine
      await onDropCreateDiagram(undefined, twin)
      drag.clear()
    } else if (id === 'attach-equipment') {
      drag.context.type = consts.TwinType.Equipment
      await onDropCreateDiagram(undefined, twin)
      drag.clear()
    } else if (id === 'up') {
      onMove('before', prev, [ twin ])
    } else if (id === 'down') {
      onMove('after', next, [ twin ])
    }
  }, [
    drag,
    onDropCreateDiagram,
    onDuplicate,
    onMove,
    onRemove,
    rootTwinDiagram,
  ])

  const handleRemove = useCallback((data) => {
    onRemove(data, rootTwinDiagram)
  }, [ onRemove, rootTwinDiagram ])

  const handleClose = useCallback(() => {
    setCreateBlockState(undefined)
    setEditBlockId(undefined)
    setCreateState(undefined)
  }, [])

  useDidUpdate(() => {
    if (filterTree) {
      if (refPreventScroll.current) {
        refPreventScroll.current = false
        setScrollTo(null)
      } else {
        setScrollTo(filterTree)
      }

      if (filterTree === rootTwin?.id) {
        refDiagram.current.fit()
      } else {
        refDiagram.current.scrollTo(filterTree)
      }
    }
  }, [ filterTree, rootTwin ])

  useEffect(() => {
    if (rootTwin) {
      onUpdateDefaultTree(rootTwin.id)
    }
  }, [ rootTwin, onUpdateDefaultTree ])

  // only first run
  useEffect(() => {
    if (!filterTree) {
      if (contextTwinId) {
        handleView(contextTwinId)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!contextTwinId) {
      handleClose()
    }
  }, [ contextTwinId ])

  const handleSelect = (data) => {
    setSelectedTwinIds(data)
    onSelect(data)
  }

  return (
    <div className="d-flex flex-fill flex-column">
      <div className="d-flex flex-fill position-relative overflow-hidden">
        <Diagram
          ref={refGraphDiagram}
          blockFactory={blockFactory}
          className={!rootTwin ? classes.mute : undefined}
          container={container}
          graphScrollTo={scrollTo}
          linkMode={linkMode}
          setLinkMode={setLinkMode}
          onActionContextMenu={handleActionContextMenu}
          onChangeDiagram={onChangeDiagram}
          onDblClick={handleDiagramDblClick}
          onDropCreateDiagram={handleDropCreateDiagram}
          onLinkDiagram={onLinkDiagram}
          onPaste={onPaste}
          onRemove={handleRemove}
          onSelect={handleSelect}
          refDiagram={refDiagram}
          rootTwin={rootTwin}
          selectedIds={selectedIds}
          state={state}
          setEditBlockId={setEditBlockId}
          onToggleGrid={onToggleGrid}
          hideGrid={state?.diagram?.hideGrid}
          onBulkEdit={onBulkEdit}
          onDiagramFit={handleDiagramFit}
          onDiagramReshapeLinks={handleDiagramReshapeLinks}
          onClickCreate={handleClickCreate}
        />

        {!rootTwin ? (
          <div className={classes.backdrop}>
            <button
              type="button"
              className="btn btn-outline-secondary me-2"
              onClick={() => {
                if (!leftBarExpanded) {
                  setIsOpenTwinSelector(true)
                } else {
                  refLeftBar.current.close()
                }
              }}
            >
              <Icon type="solid" name="faFolderTree" className="me-2" />
              {intl.t('twin.selectTree')}
            </button>
            {auth.checkAccessCreate('SectionDigitalTwinItems') ? (
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={onAdd}
              >
                <Icon type="solid" name="faPlus" className="me-2" />
                {intl.t('twin.createTwin')}
              </button>
            ) : null}
          </div>
        ) : null}

        {/* {leftBarExpanded ? (
          <LeftBar
            ref={refLeftBar}
            refScroll={refScroll}
            value={filterTree}
            rootTwin={rootTwin}
            scrollTo={scrollTo}
            onView={handleViewInRoot}
            onMove={onMove}
            onClose={() => setLeftBarExpanded(false)}
            onActionMenu={handleActionLeftBarMenu}
          />
        ) : null} */}

        {editBlockId || createState ? (
          <FormTwin
            id={editBlockId}
            defaultValues={createState}
            onSuccess={handleSubmitSuccess}
            onClose={handleClose}
            dialog={{
              title: editBlockId ? intl.t('twin.titleUpdate') : intl.t('twin.titleCreate'),
              dataTestid: 'replaceTwinDialog',
            }}
          />
        ) : null}

        {sendToBoard ? (
          <FormSendToBoard
            state={state}
            selected={selectedIds}
            onClose={() => {
              setSendToBoard(false)
            }}
            dialog={{
              title: intl.t('twin.titleSendToBoard'),
              dataTestid: 'sendToBoardDialog',
            }}
          />
        ) : null}

        {bulkSensorsType && bulkSensorIds.length ? (
          <BulkSensors
            type={bulkSensorsType}
            sensorIds={bulkSensorIds}
            onClose={() => {
              setBulkSensorsType(null)
              setBulkSensorIds([])
            }}
          />
        ) : null}

      </div>
    </div>
  )
}
