import { useEffect } from 'react'
import { Controller, useWatch, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import difference from 'lodash/difference'
import { FormCheckbox } from '@wiz/components'

function getDirtyFields (data) {
  const out = []
  for (const item in data) {
    if (data[item] === true) {
      out.push(item)
    } else if (
      typeof data[item] === 'object' &&
      data[item] !== null
    ) {
      out.push(...getDirtyFields(data[item]))
    }
  }
  return out
}

const Selectable = ({
  value,
  disabled,
}) => {
  const { setValue, formState: { dirtyFields } } = useFormContext()
  const valueArray = Array.isArray(value) ? value : [ value ]
  let selected = useWatch({ name: 'selected', defaultValue: [] })
  selected = selected || []
  const immutableSelected = JSON.stringify(selected)
  const immutableDirtyFields = JSON.stringify(dirtyFields)

  useEffect(() => {
    const dirtyItems = getDirtyFields(dirtyFields).filter(item => (item !== 'selected'))
    const diff = difference(dirtyItems, selected)

    if (diff.length) {
      setValue('selected', selected.concat(diff), { shouldDirty: false })
    }
  }, [ immutableDirtyFields, setValue, immutableSelected ])

  return (
    <Controller
      name="selected"
      render={({ field, fieldState }) => (
        <FormCheckbox
          {...field}
          invalid={fieldState.invalid}
          checked={selected.some(item => valueArray.includes(item))}
          disabled={disabled}
          className="me-2"
          onChange={(checked) => {
            const next = checked ?
              selected.concat(valueArray) :
              selected.filter(item => !valueArray.includes(item))
            setValue('selected', next, { shouldDirty: true })
          }}
        />
      )}
    />
  )
}

Selectable.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  disabled: PropTypes.bool,
}

Selectable.defaultProps = {
  disabled: false,
}

export default Selectable
