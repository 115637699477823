import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  FormField,
  FormFieldInline,
  FormControl,
  FormCheckbox,
} from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('alert.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (!str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value?.length > 250) {
                return intl.t('form.errors.fieldMaxlen', { max: 250 })
              }
              return true
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.description')}
        description={intl.t('alert.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.description`}
          rules={{
            maxLength: {
              value: 400,
              message: intl.t('form.errors.fieldMaxlen', { max: 400 }),
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.message')}
        description={intl.t('alert.form.fields.messageDescr')}
        errors={get(errors, `${scope}.message`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.message`}
          rules={{
            maxLength: {
              value: 400,
              message: intl.t('form.errors.fieldMaxlen', { max: 400 }),
            },
          }}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.active')}
        description={intl.t('alert.form.fields.activeDescr')}
        errors={get(errors, `${scope}.active`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.active`}
          className="ms-2"
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('form.fields.notificationLog')}
        errors={get(errors, `${scope}.notificationLog`)}
      >
        <FormCheckbox
          disabled
          checked
          className="ms-2"
        />
      </FormFieldInline>
    </>
  )
}

Fields.propTypes = {
  scope: PropTypes.string.isRequired,
}
