/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import filesize from 'filesize'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'
import ButtonFileDownload from '@/components/Form/ButtonFileDownload'

export default function ViewModel ({
  title,
  file,
  onClose,
  onRemove,
  onEdit,
}) {
  const refDialog = useRef()
  const intl = useIntl()
  const auth = useAuth()

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('form.fields.name'),
          value: file.name || '-',
        },
        {
          title: intl.t('form.fields.description'),
          value: file.description || '-',
        },
        {
          title: intl.t('files.form.fields.fileType'),
          value: file.type,
        },
        {
          title: intl.t('files.form.fields.contentType'),
          value: file.contentType,
        },
        {
          title: intl.t('files.form.fields.size'),
          value: () => (
            <ButtonFileDownload file={file}>
              {filesize(parseInt(file.length, 10))}
            </ButtonFileDownload>
          ),
        },
      ],
    },

    {
      title: intl.t('form.fields.updated'),
      value: () => (
        <UpdatedInfo
          date={file.updatedAt}
          user={file.updatedUser}
        />
      ),
    },
    {
      title: intl.t('form.fields.created'),
      value: () => (
        <UpdatedInfo
          date={file.createdAt}
          user={file.createdUser}
        />
      ),
    },
  ]

  const actions = [
    ...(onRemove && auth.checkAccessRemove(file) ? ([{
      title: intl.t('form.actions.remove'),
      name: 'remove',
      testid: 'removeButton',
      onClick: () => onRemove([ file ]),
    }]) : []),

    ...(onEdit && auth.checkAccessUpdate(file) ? ([{
      title: intl.t('form.actions.edit'),
      name: 'edit',
      testid: 'editButton',
      onClick: () => onEdit(file.id),
    }]) : []),
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      actions={actions}
      onClose={onClose}
    />
  )
}
