import { useMemo } from 'react'
import { VirtualTable, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import QualityDataTemplateList from '@/hoc/QualityDataTemplateList'
import FormatDateTime from '@/containers/FormatDateTime'
import CellTemplate from './CellTemplate'

const CellTemplateData = QualityDataTemplateList(CellTemplate)

export default function Table ({
  items,
  onRemove,
  onEdit,
  onView,
  onFilter,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      Header: intl.t('quality.form.fields.registerAt'),
      accessor: 'registerAt',
      Cell: ({ row }) => (
        <div className="min-w-0">
          <div>
            <FormatDateTime date={row.original.registerAt} />
          </div>
          <div>
            {row.original.qualityDataTemplateId ? (
              <CellTemplateData
                value={row.original.qualityDataTemplateId}
                selectedOnly
                onFilter={onFilter}
              />
            ) : '-'}
          </div>
        </div>
      ),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell, row }) => (
        <>
          {auth.checkAccessRemove(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-2"
              onClick={() => onRemove?.([ row.original ])}
            >
              <Icon name="fa--trash-alt" />
            </button>
          ) : null}
          {auth.checkAccessUpdate(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-2"
              onClick={() => onEdit?.(cell.value)}
            >
              <Icon name="fa--edit" />
            </button>
          ) : null}
          <button
            type="button"
            className="btn btn-sm p-2"
            onClick={() => onView?.(cell.value)}
          >
            <Icon name="fa--chevron-right" />
          </button>
        </>
      ),
    },
  ], [ intl, onEdit, onRemove, onView, onFilter, auth ])

  return (
    <VirtualTable
      className="flex-fill"
      placeholder={intl.t('errors.noDataDisplay')}
      columns={columns}
      data={items}
      showHeader={false}
      selection={auth.checkAccessRemove('QualityData')}
      {...props}
    />
  )
}
