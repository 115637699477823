import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { FormFieldInline } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import InputBins from '@/components/Form/InputBins'

const FieldBins = ({ scope }) => {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <FormFieldInline
      label={intl.t('widgets.histogram.form.fields.bins')}
      description={intl.t('widgets.histogram.form.fields.binsDescr')}
      errors={get(errors, `${scope}.bins`)}
      className="mt-3"
      complex
      vertical
    >
      <InputBins
        className="mt-3"
        name={`${scope}.bins`}
        keyName="id"
        precision={6}
        canEditColor={false}
        canEditFirst
        canRemoveLast
      />
    </FormFieldInline>
  )
}

FieldBins.propTypes = {
  scope: PropTypes.string.isRequired,
}

export default FieldBins
