import Option from './Option'
import ContentComponent from './ContentComponent'

export default function ListOption ({
  data: listData,
  style,
  index,
}) {
  const {
    Content,
    options,
    search,
  } = listData

  const data = options[index]
  return (
    <Option
      className="d-flex align-items-center hover-toggle"
      style={style}
    >
      <ContentComponent
        Content={Content}
        data={data}
        context={listData}
        search={search}
      />
    </Option>
  )
}
