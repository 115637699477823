import { memo } from 'react'
import { withProps, useDidUpdate } from '@wiz/components'
import enhanceSheet from './enhanceSheet'
import enhanceSheetTargets from './enhanceSheetTargets'

const enhanceProps = withProps(({ notificationSheet, notificationSheetTargets, onChange }) => {
  useDidUpdate(() => (
    onChange?.({ notificationSheet, notificationSheetTargets })
  ), [ notificationSheet, notificationSheetTargets, onChange ])
})

export default memo(
  enhanceSheet(
    enhanceSheetTargets(
      enhanceProps(() => (null)),
    ),
  ),
)
