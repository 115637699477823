import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import { wizataApi } from '@/api'
import Icon from '@/shared/icon'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'

const TemplateActions = ({ onAction, id }) => {
  const intl = useIntl()
  const auth = useAuth()

  // const onEdit = () => {
  //   onAction('templates', true, id)
  // }

  const { data: item, isLoading } = useQuery({
    queryKey: [ 'template', id ],
    queryFn: () => wizataApi.templates.getById(id),
    enabled: !!id,
    staleTime: Infinity,
  })

  const onEditProperties = () => {
    onAction('templateProperties', true, id)
  }

  if (!auth.checkAccessUpdate('Template')) {
    return null
  }

  return (
    <>
      {/* <button
        type="button"
        className="btn btn-fill-secondary-alt btn-text me-2"
        onClick={onEdit}
      >
        <Icon name="faPenToSquare" className="me-2" />
        {intl.t('form.actions.edit')}
      </button> */}
      <button
        type="button"
        className="btn btn-fill-secondary-alt btn-text me-2"
        onClick={onEditProperties}
      >
        <Icon name="faEye" className="me-2" />
        {intl.t('form.actions.showRegistrationColumns')}
      </button>
      <div className="fw-bold">
        {item?.key}
      </div>
    </>
  )
}

export default TemplateActions
