import { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TwinGraph } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import SelectTwinGraph from '@/containers/Form/SelectTwinGraph'
import ObservTwinGraph from '@/containers/Forms/TwinGraph/Observ'
import Section from '@/components/Forms/TwinGraph/Section'

export default function SettingsSection ({ block }) {
  const intl = useIntl()
  const { watch, setValue, clearErrors } = useFormContext()
  const id = watch('settings.id')

  const handleChange = useCallback(({ twinGraph }) => {
    setValue('settings', TwinGraph.toJSON(twinGraph), { shouldDirty: true })
    clearErrors('settings')
  }, [ setValue, clearErrors ])

  return (
    <>
      <ObservTwinGraph
        id={id}
        onChange={handleChange}
      />

      <Section
        scope="settings"
        registerId={false}
        title={intl.t('form.sections.twinGraph')}
        addon={() => (
          <Controller
            name="settings.id"
            render={({ field, fieldState }) => (
              <SelectTwinGraph
                {...field}
                invalid={fieldState.invalid}
                nestedBlock={block}
                placeholder={intl.t('form.fields.twinGraphPlaceholder')}
                className="form-select-sm min-w-0 w-100 w-md-auto mt-2 mt-md-0"
              />
            )}
          />
        )}
      />
    </>
  )
}
