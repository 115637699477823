import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  FormSection,
  FormField,
  FormControl,
} from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import TimeSettings from '@/components/Form/TimeSettings'

export default function SectionFilters ({ scope }) {
  const intl = useIntl()
  const {
    formState: { errors },
    watch,
    clearErrors,
  } = useFormContext()
  const stepType = watch(`${scope}.dataFilter.stepType`)

  return (
    <FormSection>
      <TimeSettings
        className="mb-3"
        dateFromFieldName={`${scope}.dataFilter.dateFrom`}
        dateToFieldName={`${scope}.dataFilter.dateTo`}
        durationFieldName={`${scope}.dataFilter.duration`}
        offsetDurationFieldName={`${scope}.dataFilter.offsetDuration`}
        stepRequestFieldName={`${scope}.dataFilter.stepRequest`}
        stepRequests={consts.PlotlyStepRequests}
        defaultStepRequest={consts.PlotlyStepRequest}
      />

      <FormField
        label={intl.t('widgets.chart.form.fields.step')}
        description={intl.t('widgets.chart.form.fields.stepDescr')}
        errors={get(errors, `${scope}.dataFilter.stepType`)}
      >
        <FormControl
          type="select"
          name={`${scope}.dataFilter.stepType`}
          options={[
            { id: 'auto', name: 'Smooth' },
            { id: 'dense', name: 'Dense' },
            { id: 'custom', name: 'Custom' },
          ]}
          onChange={() => {
            clearErrors(`${scope}.dataFilter.stepCustom`)
          }}
        />
      </FormField>

      <FormField
        label={intl.t('widgets.chart.form.fields.stepCustom')}
        description={intl.t('widgets.chart.form.fields.stepCustomDescr')}
        errors={get(errors, `${scope}.dataFilter.stepCustom`)}
      >
        <FormControl
          type="duration"
          name={`${scope}.dataFilter.stepCustom`}
          rules={{
            validate: (value) => {
              if (!value && stepType === 'custom') {
                return intl.t('form.errors.fieldRequired')
              }
              return true
            },
          }}
          parts="days,hours,minutes,seconds"
          disabled={stepType !== 'custom'}
          clearable
        />
      </FormField>
    </FormSection>
  )
}

SectionFilters.propTypes = {
  scope: PropTypes.string.isRequired,
}
