import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { BulletPlotSource as BulletPlotSourceModel } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import SectionData from './SectionData'
import SectionGeneral from './SectionGeneral'
import SectionRange from './SectionRange'
import Schema from './Schema'

export default function BulletPlotSource ({
  id,
  bulletPlotSource,
  dialog,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()
  const defaultValues = useMemo(() => ({
    bulletPlotSource: BulletPlotSourceModel.toJSON(bulletPlotSource),
  }), [ bulletPlotSource?.updatedAt ?? bulletPlotSource ])

  if (id && !bulletPlotSource) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      schema={Schema}
      dataTestid="blockBulletPlotForm"
      dialog={dialog}
    >
      <SectionGeneral
        scope="bulletPlotSource"
      />

      <SectionRange
        title={intl.t('bulletPlotSource.form.fields.rangeSection')}
        description={intl.t('bulletPlotSource.form.fields.rangeSectionDescr')}
        scope="bulletPlotSource"
      />

      <SectionData
        title={intl.t('bulletPlotSource.form.fields.dataSection')}
        description={intl.t('bulletPlotSource.form.fields.dataSectionDescr')}
        scope="bulletPlotSource"
      />
    </FormDialog>
  )
}

BulletPlotSource.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  bulletPlotSource: PropTypes.object,
  dialog: PropTypes.object,
}

BulletPlotSource.defaultProps = {
  bulletPlotSource: undefined,
  dialog: undefined,
}
