import { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Twin } from '@wiz/store'
import { FormInputSearch, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useRouter } from '@/router'
import { useAuth } from '@/auth'
import ListDashboardsByTwin from '@/containers/Form/ListDashboardsByTwin'
import ListDataExplorerByTwin from '@/containers/Form/ListDataExplorerByTwin'
import ListStreamJobsByTwin from '@/containers/Form/ListStreamJobsByTwin'
import ListTwinGraphsByTwin from '@/containers/Form/ListTwinGraphsByTwin'

export default function RightBarLinked ({
  blockSettings,
  onChange,
}) {
  const intl = useIntl()
  const auth = useAuth()
  const router = useRouter()
  const [ search, setSearch ] = useState('')
  const [ collapsed, setCollapsed ] = useState([])

  const toggleCollapsed = useCallback((value) => {
    let next
    if (collapsed.includes(value)) {
      next = collapsed.filter(item => item !== value)
    } else {
      next = collapsed.concat(value)
    }
    setCollapsed(next)
  }, [ collapsed ])

  const twins = useMemo(() => (
    Object.values(blockSettings)
      .filter(item => Twin.is(item))
  ), [ blockSettings ])

  return (
    <>
      <div className="my-2 mx-3">
        <FormInputSearch
          size="sm"
          placeholder={intl.t('form.actions.searchPlaceholder')}
          onChange={setSearch}
        />
      </div>

      {auth.checkAccessRead('SectionDigitalTwinChart') ? (
        <>
          <button
            type="button"
            className="btn justify-content-start fs-5 border-0 px-2 py-0 m-2"
            onClick={() => toggleCollapsed('twinGraphs')}
          >
            <Icon
              className="me-1"
              name={collapsed.includes('twinGraphs') ? 'fa--angle-up' : 'fa--angle-down'}
              size="md"
            />
            {intl.t('twin.titleLinkedTwinGraphs')}
          </button>

          <ListTwinGraphsByTwin
            className={classnames('flex-fill min-h-0 h-100 overflow-hidden', {
              'd-none': collapsed.includes('twinGraphs'),
            })}
            twins={twins}
            search={search}
            onSelect={(model) => {
              router.push({
                name: 'chart-view',
                params: { id: model.blockId },
              })
            }}
          />
        </>
      ) : null}

      {auth.checkAccessRead('SectionDashboards') ? (
        <>
          <button
            type="button"
            className="btn justify-content-start fs-5 border-0 px-2 py-0 m-2"
            onClick={() => toggleCollapsed('dashboards')}
          >
            <Icon
              className="me-1"
              name={collapsed.includes('dashboards') ? 'fa--angle-up' : 'fa--angle-down'}
              size="md"
            />
            {intl.t('twin.titleLinkedDashboards')}
          </button>

          <ListDashboardsByTwin
            className={classnames('flex-fill min-h-0 h-100 overflow-hidden', {
              'd-none': collapsed.includes('dashboards'),
            })}
            twins={twins}
            search={search}
            onSelect={model => onChange('dashboard', {
              title: model.title,
              props: {
                id: model.id,
              },
            })}
          />
        </>
      ) : null}

      {auth.checkAccessRead('SectionDataExplorer') ? (
        <>
          <button
            type="button"
            className="btn justify-content-start fs-5 border-0 px-2 py-0 m-2"
            onClick={() => toggleCollapsed('explorers')}
          >
            <Icon
              className="me-1"
              name={collapsed.includes('explorers') ? 'fa--angle-up' : 'fa--angle-down'}
              size="md"
            />
            {intl.t('twin.titleLinkedExplorers')}
          </button>

          <ListDataExplorerByTwin
            className={classnames('flex-fill min-h-0 h-100 overflow-hidden', {
              'd-none': collapsed.includes('explorers'),
            })}
            twins={twins}
            search={search}
            onSelect={(model, context) => onChange('explorer', {
              title: model.title,
              props: {
                ...context,
                id: model.id,
              },
            })}
          />
        </>
      ) : null}

      {auth.checkAccessRead('SectionStreamJobs') ? (
        <>
          <button
            type="button"
            className="btn justify-content-start fs-5 border-0 px-2 py-0 m-2"
            onClick={() => toggleCollapsed('streamJobs')}
          >
            <Icon
              className="me-1"
              name={collapsed.includes('streamJobs') ? 'fa--angle-up' : 'fa--angle-down'}
              size="md"
            />
            {intl.t('twin.titleLinkedStreamJobs')}
          </button>

          <ListStreamJobsByTwin
            className={classnames('flex-fill min-h-0 h-100 overflow-hidden', {
              'd-none': collapsed.includes('streamJobs'),
            })}
            twins={twins}
            search={search}
            onSelect={model => onChange('streamJob', {
              title: model.name,
              props: {
                id: model.id,
                withTitle: false,
                withBack: false,
              },
            })}
          />
        </>
      ) : null}
    </>
  )
}

RightBarLinked.propTypes = {
  blockSettings: PropTypes.shape.isRequired,
  onChange: PropTypes.func.isRequired,
}
