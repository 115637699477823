import { useState, useCallback, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { wizataApi } from '@/api'
import AttachSensors from './AttachSensors'

export default function RightBarInfo ({
  businessType,
  onAttachSensors,
  blocks,
  setSelectedSensorsCount,
}) {
  const [ selectedBlockSettings, setSelectedBlockSettings ] = useState(null)

  const {
    data: labels,
    isLoading: isLabelsLoading,
    refetch: refetchLabels,
  } = useQuery({
    queryKey: [ 'labels' ],
    queryFn: () => wizataApi.labels.getList({
      pagination: {
        take: 500,
        skip: 0,
      },
      filters: [],
    }),
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: Infinity,
  })

  const {
    data: categories,
    isLoading: isCategoriesLoading,
    refetch: refetchCategories,
  } = useQuery({
    queryKey: [ 'categories' ],
    queryFn: () => wizataApi.categories.getList({
      pagination: {
        take: 500,
        skip: 0,
      },
      filters: [],
    }),
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: Infinity,
  })

  const {
    data: units,
    isLoading: isUnitsLoading,
    refetch: refetchUnits,
  } = useQuery({
    queryKey: [ 'units' ],
    queryFn: () => wizataApi.units.getList({
      pagination: {
        take: 500,
        skip: 0,
      },
      filters: [],
    }).then((itemsObj) => {
      const mappedItems = itemsObj.items.map(item => ({ ...item, name: `${item.symbol} (${item.fullName})` }))
      return { items: mappedItems, totalCount: itemsObj.totalCount }
    }),
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: Infinity,
  })

  useEffect(() => {
    if (blocks?.length && selectedBlockSettings?.id !== blocks[0]?.id) {
      setSelectedBlockSettings(blocks[0])
    }
  }, [ blocks ])

  return (
    <AttachSensors
      // labels={labels?.items}
      // categories={categories?.items}
      // units={units?.items}
      businessType={businessType}
      selectedBlockSettings={selectedBlockSettings}
      onAttachSensors={onAttachSensors}
      onResetSelectedBlockSettings={() => setSelectedBlockSettings(null)}
      setSelectedSensorsCount={setSelectedSensorsCount}
    />
  )
}

RightBarInfo.propTypes = {
  businessType: PropTypes.string,
  onAttachSensors: PropTypes.func,
  blocks: PropTypes.arrayOf(PropTypes.shape({})),
}

RightBarInfo.defaultProps = {
  businessType: undefined,
  onAttachSensors: undefined,
  blocks: undefined,
}
