import { useMemo } from 'react'
import { Table as TableComponent, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import CellName from './CellName'

export default function TableMobile ({
  items,
  data,
  filters,
  onRemove,
  onView,
  onRightBarOpen,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      Header: intl.t('categories.form.fields.name'),
      accessor: 'name',
      Cell: ({ row }) => (
        <CellName
          experiment={row.original}
          filters={filters}
          onRightBarOpen={onRightBarOpen}
          isMobile
        />
      ),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell, row }) => (
        <>
          {auth.checkAccessRemove(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-2"
              onClick={() => onRemove?.([ row.original ])}
            >
              <Icon name="fa--trash-alt" />
            </button>
          ) : null}
          <button
            type="button"
            className="btn btn-sm p-2"
            onClick={() => onView?.(cell.value)}
          >
            <Icon name="fa--chevron-right" />
          </button>
        </>
      ),
    },
  ], [ intl, filters, onRemove, onView, auth, onRightBarOpen ])

  return (
    <TableComponent
      itemSize={72}
      className="flex-fill"
      placeholder={intl.t('errors.noDataDisplay')}
      columns={columns}
      data={data?.items || []}
      showHeader={false}
      selection={auth.checkAccessRemove('Experiment')}
      {...props}
    />
  )
}
