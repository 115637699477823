import { Controller, useFormContext } from 'react-hook-form'
import { get } from '@wiz/utils'
import {
  FormField,
  FormFieldInline,
  FormSelect,
  FormControl,
} from '@wiz/components'
import { Condition } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import SelectDataSources from '@/components/Form/SelectDataSources'
import FormatDateTime from '@/containers/FormatDateTime'

const ValuePayloads = [
  { id: 'null', name: 'null' },
  { id: 'true', name: 'true' },
  { id: 'false', name: 'false' },
]

export default function Fields ({ scope, exclude }) {
  const intl = useIntl()
  const {
    formState: { errors },
    watch,
    trigger,
  } = useFormContext()

  const [
    type,
    dateFrom,
    dateTo,
  ] = watch([
    `${scope}.type`,
    `${scope}.dateFrom`,
    `${scope}.dateTo`,
  ])

  const [
    betweenFrom,
    betweenTo,
  ] = watch([
    `${scope}.payload.between.from`,
    `${scope}.payload.between.to`,
  ])

  return (
    <>
      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('conditions.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            maxLength: {
              value: 50,
              message: intl.t('form.errors.fieldMaxlen', { max: 50 }),
            },
          }}
          placeholder={intl.t('form.fields.namePlaceholder')}
        />
      </FormField>

      {exclude?.includes('inputSource') ? null : (
        <FormField
          label={intl.t('explorer.conditions.form.fields.inputSource')}
          description={intl.t('conditions.form.fields.inputSourceDescr')}
          errors={get(errors, `${scope}.inputDataSources`)}
        >
          <Controller
            name={`${scope}.inputDataSources`}
            rules={{
              required: intl.t('explorer.conditions.errors.inputSourceRequired'),
            }}
            render={({ field, fieldState }) => (
              <SelectDataSources
                {...field}
                invalid={fieldState.invalid}
                placeholder={intl.t('explorer.conditions.form.fields.inputSourcePlaceholder')}
                onChange={(data) => {
                  field.onChange(data ? [ data ] : [])
                }}
              />
            )}
          />
        </FormField>
      )}

      {exclude?.includes('outputSource') ? null : (
        <FormField
          label={intl.t('explorer.conditions.form.fields.outputSource')}
          description={intl.t('conditions.form.fields.outputSourceDescr')}
          errors={get(errors, `${scope}.outputDataSources`)}
        >
          <Controller
            name={`${scope}.outputDataSources`}
            render={({ field, fieldState }) => (
              <SelectDataSources
                {...field}
                invalid={fieldState.invalid}
                multiselect
                unique
                placeholder={intl.t('explorer.conditions.form.fields.outputSourcePlaceholder')}
              />
            )}
          />
        </FormField>
      )}

      {exclude?.includes('inverse') ? null : (
        <FormFieldInline
          label={intl.t('conditions.form.fields.inverse')}
          description={intl.t('conditions.form.fields.inverseDescr')}
          errors={get(errors, `${scope}.inverse`)}
        >
          <FormControl
            type="checkbox"
            name={`${scope}.inverse`}
            className="ms-2"
          />
        </FormFieldInline>
      )}

      <FormField
        label={intl.t('conditions.form.fields.type')}
        description={intl.t('conditions.form.fields.typeDescr')}
        errors={get(errors, `${scope}.type`)}
      >
        <Controller
          name={`${scope}.type`}
          rules={{
            required: intl.t('explorer.conditions.errors.conditionRequired'),
          }}
          render={({ field, fieldState }) => (
            <FormSelect
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('conditions.form.fields.typePlaceholder')}
              options={Condition.TYPES.map(id => ({ id, name: intl.t(`enum.conditionType.${id}`) }))}
            />
          )}
        />
      </FormField>

      {do {
        if (type === 'in') {
          <FormField
            label={intl.t('conditions.form.fields.value')}
            description={intl.t('conditions.form.fields.valueDescr')}
            errors={get(errors, `${scope}.payload.in`)}
          >
            <FormControl
              name={`${scope}.payload.in`}
            />
          </FormField>
        } else if (type === 'is') {
          <FormField
            label={intl.t('conditions.form.fields.value')}
            description={intl.t('conditions.form.fields.valueDescr')}
            errors={get(errors, `${scope}.payload.is`)}
          >
            <Controller
              name={`${scope}.payload.is`}
              render={({ field, fieldState }) => (
                <FormSelect
                  {...field}
                  invalid={fieldState.invalid}
                  options={ValuePayloads}
                />
              )}
            />
          </FormField>
        } else if (type === 'between') {
          <>
            <FormField
              label={intl.t('conditions.form.fields.valueFrom')}
              description={intl.t('conditions.form.fields.valueFromDescr')}
              errors={get(errors, `${scope}.payload.between.from`)}
            >
              <FormControl
                type="number"
                name={`${scope}.payload.between.from`}
                scale={8}
                rules={{
                  validate: (value) => {
                    if (value && betweenTo && value >= betweenTo) {
                      return intl.t('form.errors.durationFromOutofrange')
                    }
                    return true
                  },
                }}
                onChange={() => {
                  window.setTimeout(() => {
                    trigger([
                      `${scope}.payload.between.to`,
                      `${scope}.payload.between.from`,
                    ])
                  }, 0)
                }}
              />
            </FormField>
            <FormField
              label={intl.t('conditions.form.fields.valueTo')}
              description={intl.t('conditions.form.fields.valueToDescr')}
              errors={get(errors, `${scope}.payload.between.to`)}
            >
              <FormControl
                type="number"
                name={`${scope}.payload.between.to`}
                scale={8}
                rules={{
                  validate: (value) => {
                    if (value && betweenFrom && value <= betweenFrom) {
                      return intl.t('form.errors.durationToOutofrange')
                    }
                    return true
                  },
                }}
                onChange={() => {
                  window.setTimeout(() => {
                    trigger([
                      `${scope}.payload.between.to`,
                      `${scope}.payload.between.from`,
                    ])
                  }, 0)
                }}
              />
            </FormField>
          </>
        } else {
          <FormField
            label={intl.t('conditions.form.fields.value')}
            description={intl.t('conditions.form.fields.valueDescr')}
            errors={get(errors, `${scope}.payload.logical`)}
          >
            <FormControl
              type="number"
              name={`${scope}.payload.logical`}
              scale={8}
            />
          </FormField>
        }
      }}

      {exclude?.includes('dateFrom') ? null : (
        <FormField
          label={intl.t('form.fields.dateFrom')}
          description={intl.t('conditions.form.fields.dateFromDescr')}
          errors={get(errors, `${scope}.dateFrom`)}
        >
          <FormControl
            type="dateTime"
            name={`${scope}.dateFrom`}
            rules={{
              validate: (value) => {
                if (value && dateTo && value >= dateTo) {
                  return intl.t('form.errors.dateFromOutofrange')
                }
                return true
              },
            }}
            placeholder={intl.t('form.fields.datePlaceholder')}
            DateTimeComponent={FormatDateTime}
            clearable
          />
        </FormField>
      )}

      {exclude?.includes('dateTo') ? null : (
        <FormField
          label={intl.t('form.fields.dateTo')}
          description={intl.t('conditions.form.fields.dateToDescr')}
          errors={get(errors, `${scope}.dateTo`)}
        >
          <FormControl
            type="dateTime"
            name={`${scope}.dateTo`}
            rules={{
              validate: (value) => {
                if (value && dateFrom && value <= dateFrom) {
                  return intl.t('form.errors.dateToOutofrange')
                }
                return true
              },
            }}
            placeholder={intl.t('form.fields.datePlaceholder')}
            DateTimeComponent={FormatDateTime}
            clearable
          />
        </FormField>
      )}
    </>
  )
}
