import {
  useContext,
  useMemo,
  useCallback,
  useRef,
} from 'react'
import { DateTime } from 'luxon'
import { Timeline, TimelineAccess } from '@wiz/components'
import { DataSource } from '@wiz/store'
import { getDateFrom, getDateTo } from '@wiz/utils'
import DataExplorer from '@/context/DataExplorer'
import DataExplorerExecute from '@/context/DataExplorerExecute'
import FormatDateTime from '@/containers/FormatDateTime'

export default function SourceIntervals () {
  const refDateFormatter = useRef()
  const context = useContext(DataExplorer)
  const runContext = useContext(DataExplorerExecute)
  const disabled = !!(runContext.loading || context.data.dataFilter.stepRequest)

  const groups = useMemo(() => (
    Object.values(context.data.dataSources.reduce((out, source) => {
      const view = context.data.dataViews.find(item => item.sourceId === source.id)
      const id = DataSource.getSymbol(source)

      return {
        ...out,
        [id]: {
          id,
          content: DataSource.getDisplayName(source),
          color: view?.color,
        },
      }
    }, {}))
  ), [ context.data.dataSources, context.data.dataViews ])

  const items = useMemo(() => {
    const sourceToTimelineItem = (source) => {
      const start = DateTime.fromMillis(
        source.dateFrom ||
        getDateFrom(context.data.dataFilter, true),
      ).toJSDate()

      const end = DateTime.fromMillis(
        source.dateTo ||
        getDateTo(context.data.dataFilter, true),
      ).toJSDate()

      if (start >= end) {
        return null
      }

      const view = context.data.dataViews.find(item => item.sourceId === source.id)
      const _disabled = Boolean(
        disabled ||
        source.disabled ||
        !view?.checked,
      )

      return {
        id: source.id,
        group: DataSource.getSymbol(source),
        content: DataSource.getDisplayName(source),
        color: view?.color,
        editable: _disabled ? false : TimelineAccess,
        className: _disabled ? 'opacity-50' : undefined,
        fstart: refDateFormatter?.current?.format(start),
        fend: refDateFormatter?.current?.format(end),
        start,
        end,
      }
    }

    return context.data.dataSources.reduce((out, source) => {
      const item = sourceToTimelineItem(source)
      if (!item) {
        return out
      }
      out.push(item)
      return out
    }, [])
  }, [ disabled, context.data ])

  const minStart = useMemo(() => Math.min(items.reduce((out, item) => Math.min(out, item.start.getTime()), Infinity), Date.now()), [ items ])

  const maxEnd = useMemo(() => items.reduce((out, item) => Math.max(out, item.end.getTime()), 0) || Date.now(), [ items ])

  const handleMove = useCallback((data) => {
    const ds = context.data.dataSources.find(item => item.id === data.id)
    if (ds) {
      context.changeDataSource({
        ...ds,
        dateFrom: data.start.getTime(),
        dateTo: data.end.getTime(),
      })
    }
  }, [ context.data.dataSources, context.changeDataSource ])

  return (
    <>
      <Timeline
        items={items}
        groups={groups}
        disabled={disabled}
        minStart={minStart}
        maxEnd={maxEnd}
        onMove={handleMove}
        onRemove={context.removeDataSource}
      />
      <FormatDateTime ref={refDateFormatter} noView />
    </>
  )
}
