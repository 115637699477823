import { useEffect, useMemo } from 'react'
import { Icon } from '@wiz/components'
import { round } from '@wiz/utils'
import { Condition } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import classes from './SensorValue.css'

const SizeByLen = [
  [ 3, 1.4 ],
  [ 4, 1.3 ],
  [ 5, 1.2 ],
  [ 6, 1.1 ],
  [ 7, 0.95 ],
  [ 8, 0.9 ],
  [ 9, 0.8 ],
  [ 11, 0.7 ],
  [ 12, 0.6 ],
  [ 13, 0.55 ],
  [ 14, 0.5 ],
  [ 18, 0.4 ],
  [ 23, 0.3 ],
]

const SensorValue = ({
  className,
  unit,
  data,
  initialData,
  conditions,
  loader,
  precision = 3,
  Content,
  fit,
  onChange,
}) => {
  const intl = useIntl()
  const { value, timestamp } = data || initialData || {}
  const unitSymbol = unit && unit.symbol !== 'Other' ? unit.symbol : null

  const condition = useMemo(() => (
    timestamp && conditions && conditions.find(item => Condition.pass(item, timestamp, value))
  ), [ conditions, timestamp, value ])

  useEffect(() => {
    if (onChange && timestamp) {
      onChange(data)
    }
  }, [ timestamp, onChange ])

  if (!Number.isFinite(value)) {
    return (
      <div className={className}>
        {loader ?? (
          <Icon
            name="fa--spinner"
            spin
            size="lg"
            title={intl.t('form.loading')}
          />
        )}
      </div>
    )
  }

  if (!fit) {
    return Content ? (
      <Content
        value={round(value, precision)}
        unitSymbol={unitSymbol}
        timestamp={timestamp}
      />
    ) : (
      <>
        {round(value, precision)}
        {unitSymbol}
      </>
    )
  }

  const strValue = String(round(value, precision))
  const titleValue = strValue + String(unitSymbol && ` ${unitSymbol}` || '')

  if (condition?.name) {
    const len = condition.name.length || 10
    const fontSize = SizeByLen.find(item => len <= item[0])?.[1] ?? 0.2
    return (
      <div
        className={className}
        title={titleValue}
        style={{ color: condition.color }}
      >
        <svg viewBox="0 0 56 18" className={classes.fit}>
          <foreignObject
            width="100%"
            height="100%"
            fill="#ffffff"
            style={{
              textAlign: 'center',
              fontSize: `${fontSize}em`,
              lineHeight: 1,
            }}
          >
            {condition.name}
          </foreignObject>
        </svg>
      </div>
    )
  }

  const len = titleValue.length || 10
  const fontSize = SizeByLen.find(item => len <= item[0])?.[1] ?? 0.2

  return (
    <div
      className={className}
      style={{ color: condition?.color }}
    >
      <svg viewBox="0 0 56 18" className={classes.fit}>
        <foreignObject
          width="100%"
          height="100%"
          fill="currentColor"
          style={{
            textAlign: 'center',
            fontSize: `${fontSize}em`,
            lineHeight: 1,
          }}
        >
          {strValue}
          {unitSymbol ? (
            <span className="ms-1" style={{ fontSize: '50%' }}>
              {unitSymbol}
            </span>
          ) : null}
        </foreignObject>
      </svg>
    </div>
  )
}

export default SensorValue
