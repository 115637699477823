import {
  useCallback, useEffect, useState, useMemo,
} from 'react'
import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import {
  Q, dbProvider,
} from '@wiz/store'
import { withObservables, withProps } from '@wiz/components'
import Component from './rightbar'

const enhanceDatapoints = withObservables([ 'request', 'sensorIds' ], ({ request, sensorIds }) => {
  // const twinIds = request?.equipments_list.map(twin => twin.id).filter(twin => !!twin)

  const byhwId = sensorIds ?
    dbProvider.database.collections.get('sensors')
      .query(Q.where('hardware_id', Q.oneOf(sensorIds)))
      .observe() :
    of$([])

  return {
    customFunction: request?.function ? dbProvider.database.collections.get('ds_functions')
      .query(Q.where('function', request.function))
      .observeWithColumns([ 'updated_at' ])
      .pipe(map(items => items[0])) : of$(undefined),
    hardwares: byhwId,
    sensors: sensorIds ?
      dbProvider.database.collections.get('sensors')
        .query(Q.where('id', Q.oneOf(sensorIds)))
        .observe() :
      of$([]),
  }
})

const enhanceBlockSettings = withObservables(
  [ 'hardwares', 'sensors' ],
  ({ hardwares, sensors }) => {
    const list = hardwares?.length ? hardwares : sensors
    const twinIds = list.map(({ twin }) => twin.id).filter(id => id)
    const sensorIds = list.map(({ id }) => id).filter(id => id)
    const widgetSensorIds = list.filter(({ twin }) => !twin.id).map(({ id }) => id)

    const blockSettings = twinIds ? dbProvider.database.collections.get('twins')
      .query(Q.where('id', Q.oneOf(twinIds)))
      .observe()
      .pipe(map(items => items.reduce((acc, val) => {
        acc[val.id] = val
        return acc
      }, {}))) : of$({})

    const next = {
      blockSettings,
      sensorIds,
    }

    if (widgetSensorIds?.length) {
      next.widgetSensorIds = widgetSensorIds
    }

    return {
      ...next,
    }
  },
)

const enhanceProps = withProps(() => {
  const [ sensorIds, setSensorIds ] = useState(undefined)

  const handleChange = useCallback((list) => {
    setSensorIds(list)
  }, [ ])

  return {
    sensorIds,
    onChangeDatapoints: handleChange,
  }
})

export default enhanceProps(enhanceDatapoints(enhanceBlockSettings(Component)))
