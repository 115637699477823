import { define } from 'superstruct'
import { intl } from '@/i18n'

const LimitMinRequired = ({
  keyMax = 'limitMax',
} = {}) => define('LimitMinRequired', (value, { branch }) => {
  const data = branch[branch.length - 2]
  let result = true
  if (!Number.isFinite(value) && Number.isFinite(data[keyMax])) {
    result = intl.t('form.errors.fieldRequired')
  }
  return result
})

export default LimitMinRequired
