import { memo, useCallback } from 'react'
import { of as of$, throwError } from 'rxjs'
import { map, mergeMap, delay } from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { withObservables, withProps } from '@wiz/components'
import { auth } from '@/auth'
import { createLocation, useRouter } from '@/router'
import Component from '@/components/dashboards/View'
import enhanceWidgets from './enhanceWidgets'
import enhanceViewProps from './enhanceViewProps'

const enhanceDashboard = withObservables([ 'match' ], ({ match }) => ({
  dashboard: dbProvider.database.collections.get('dashboards')
    .query(Q.where('id', String(match.params.id)))
    .observeWithColumns([ 'updated_at' ])
    .pipe(
      map(items => items.filter(item => auth.checkAccessRead(item))),
      mergeMap((items) => {
        if (items.length) {
          return of$(items[0])
        }

        throwError('Dashboard not found')
        return of$(undefined)
      }),
      delay(100),
    ),

}), {
  // onError (error, { history, match }) {
  //   setTimeout(() => {
  //     events.emit('app:notify', {
  //       type: 'success',
  //       title: 'sync',
  //       message: 'started sync',
  //       duration: 5000,
  //     })
  //     // history?.push(createLocation({
  //     //   name: 'dashboards',
  //     //   params: match.params,
  //     // }))
  //   }, 10000)
  // },
})

const enhanceProps = withProps(() => {
  const router = useRouter()

  const openDashboard = useCallback((data) => {
    router.push({ name: 'dashboard', params: { id: data.id } })
  }, [ router ])

  const closeDashboard = useCallback(() => {
    router.push({ name: 'dashboards' })
  }, [ router ])

  return {
    openDashboard,
    closeDashboard,
  }
})

export default memo(
  enhanceDashboard(
    enhanceProps(
      enhanceWidgets(
        enhanceViewProps(Component),
      ),
    ),
  ),
)
