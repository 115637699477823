import { Controller, useFormContext } from 'react-hook-form'
import { get } from '@wiz/utils'
import {
  FormField,
  FormFieldInline,
  FormIndeterminateCheckbox,
  FormControl,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'

const FormFieldRatio = [ 8, 4 ]

export default function FieldsAxis ({ scope }) {
  const intl = useIntl()
  const { formState: { errors }, watch } = useFormContext()
  const scaleYAxis = watch(`${scope}.scaleYAxis`)
  const normalize = watch(`${scope}.normalize`)

  return (
    <>
      <FormFieldInline
        label={intl.t('form.fields.showXAxis')}
        description={intl.t('form.fields.showXAxisDescr')}
        errors={get(errors, `${scope}.showXAxis`)}
      >
        <Controller
          name={`${scope}.showXAxis`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('form.fields.showXSplitLine')}
        description={intl.t('form.fields.showXSplitLineDescr')}
        errors={get(errors, `${scope}.showXSplitLine`)}
      >
        <Controller
          name={`${scope}.showXSplitLine`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('form.fields.showYAxis')}
        description={intl.t('form.fields.showYAxisDescr')}
        errors={get(errors, `${scope}.showYAxis`)}
      >
        <Controller
          name={`${scope}.showYAxis`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('form.fields.showYSplitLine')}
        description={intl.t('form.fields.showYSplitLineDescr')}
        errors={get(errors, `${scope}.showYSplitLine`)}
      >
        <Controller
          name={`${scope}.showYSplitLine`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('form.fields.singleXAxis')}
        description={intl.t('form.fields.singleXAxisDescr')}
        errors={get(errors, `${scope}.singleXAxis`)}
      >
        <Controller
          name={`${scope}.singleXAxis`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('form.fields.singleYAxis')}
        description={intl.t('form.fields.singleYAxisDescr')}
        errors={get(errors, `${scope}.singleYAxis`)}
      >
        <Controller
          name={`${scope}.singleYAxis`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('form.fields.scaleYAxis')}
        description={intl.t('form.fields.scaleYAxisDescr')}
        errors={get(errors, `${scope}.scaleYAxis`)}
      >
        <Controller
          name={`${scope}.scaleYAxis`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>

      <FormField
        ratio={FormFieldRatio}
        label={intl.t('form.fields.minYAxis')}
        description={intl.t('form.fields.minYAxisDescr')}
        errors={get(errors, `${scope}.minYAxis`)}
      >
        <FormControl
          type="number"
          name={`${scope}.minYAxis`}
          scale={8}
          disabled={!!(scaleYAxis || normalize)}
          clearable
        />
      </FormField>

      <FormField
        ratio={FormFieldRatio}
        label={intl.t('form.fields.maxYAxis')}
        description={intl.t('form.fields.maxYAxisDescr')}
        errors={get(errors, `${scope}.maxYAxis`)}
      >
        <FormControl
          type="number"
          name={`${scope}.maxYAxis`}
          scale={8}
          disabled={!!(scaleYAxis || normalize)}
          clearable
        />
      </FormField>
    </>
  )
}
