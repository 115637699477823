import { useMemo } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { wizataApi } from '@/api'
import FormDialog from '@/components/Form/FormDialog'
import events from '@/utils/events'
import Section from './Section'
import Removed from '../Removed'

export default function GroupSystem ({
  id,
  dialog,
  onClose,
  onSubmit,
}) {
  const queryClient = useQueryClient()

  const { data: item, isLoading } = useQuery({
    queryKey: [ 'groupSystem', id ],
    queryFn: () => wizataApi.groupSystems.getById(id),
    enabled: !!id,
    staleTime: Infinity,
  })

  const {
    mutateAsync: mutateGroupSystem,
  } = useMutation({
    mutationKey: [ 'mutateGroupSystem' ],
    mutationFn: (data) => {
      if (!id) {
        return wizataApi.groupSystems.create({
          ...data?.groupSystem,
          key: data?.groupSystem.key.replace(/ /g, '_'),
        })
      }
      return wizataApi.groupSystems.update({ id, ...data?.groupSystem })
    },
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/groupSystem.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'groupSystem', id ] })
      queryClient.invalidateQueries({ queryKey: [ 'groupSystemsList' ] })
    },
  })

  const handleSubmit = async (data) => {
    console.log('handleSubmit data', data)
    await mutateGroupSystem(data)
  }

  const defaultValues = useMemo(() => ({
    groupSystem: { ...item },
  }), [ item ])

  if (id && !item) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={handleSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="groupSystemForm"
      dialog={dialog}
    >
      <Section
        scope="groupSystem"
        id={id}
      />
    </FormDialog>
  )
}
