import {
  type,
  assign,
  any,
} from 'superstruct'
import SchemaWidget from '@/components/Forms/Widget/Schema'
import SchemaConfig from './SchemaConfig'

export default type({
  widget: SchemaWidget,
  config: assign(
    SchemaConfig,
    type({ bulletPlotSources: any() }),
  ),
})
