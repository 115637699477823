import { useEffect, useState } from 'react'
import { withProps } from '@wiz/components'
import { wizataApi } from '@/api'

export default withProps(({ id }) => {
  const [ preview, setPreview ] = useState(null)

  useEffect(() => {
    const request = wizataApi.notebookPreviewRequest(id)
    request.fetch(true).then(data => setPreview(data))
    return () => request.abort()
  }, [ id ])

  return {
    preview,
  }
})
