import { Icon, OverflowCenter } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Observe from '@/containers/Observe'
import ButtonCopy from '@/components/Form/ButtonCopy'
import NameDiagramBlockFlow from '@/components/Form/NameDiagramBlockFlow'

export default function Flow ({ data, index, style }) {
  const intl = useIntl()
  const { options, onAttachSensors } = data
  const item = options[index]

  return (
    <div
      style={style}
      className="d-flex align-items-start hover-toggle list-group-item bg-light"
    >
      <div className="flex-fill min-w-0">
        <div className="d-flex align-items-center justify-content-between">
          {item.twinId ? (
            <Observe query={item.twin}>
              {({ data }) => (
                <div className="d-flex align-items-center min-w-0">
                  <OverflowCenter value={data.displayName} />
                  {data.hardwareId ? (
                    <ButtonCopy
                      className="hover-inline-flex"
                      value={data.hardwareId}
                      title={intl.t('message.copy.hardwareId', { value: data.hardwareId })}
                    />
                  ) : null}
                </div>
              )}
            </Observe>
          ) : (
            <NameDiagramBlockFlow value={item} />
          )}
        </div>
        {item.twinId ? (
          <NameDiagramBlockFlow value={item} />
        ) : null}
      </div>

      {onAttachSensors ? (
        <button
          type="button"
          className="hover-opacity btn btn-flat-secondary align-self-center p-2 ms-1"
          title={intl.t('form.actions.attachDataPoint')}
          onClick={() => onAttachSensors(item, index)}
        >
          <Icon name="fa--plus" />
        </button>
      ) : null}
    </div>
  )
}
