import { useCallback, memo } from 'react'
import { dbProvider, Explorer } from '@wiz/store'
import { withProps } from '@wiz/components'
import { auth } from '@/auth'
import Component from '@/components/Forms/Explorer'
import enhanceExplorer from './enhanceExplorer'
import enhanceExplorerData from './enhanceExplorerData'

const enhanceProps = withProps(() => {
  const onSubmit = useCallback(async (data) => {
    let context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareReplaceData(context, Explorer, data.explorer)
    await model.prepareReplaceProjects(context, data.projects)
    await model.prepareReplaceTwins(context, data.twins)
    await dbProvider.batch(context)

    if (auth.checkAccessShare(model)) {
      context = dbProvider.createBatchContext()
      await model.prepareReplaceShareUsers(context, data.permissions)
      await dbProvider.batch(context)
    }

    return model
  }, [])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceExplorer(
    enhanceExplorerData(
      enhanceProps(Component),
    ),
  ),
)
