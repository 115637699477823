import { useMemo } from 'react'
import {
  DiagramBlock,
  DiagramBlockEvent,
  DiagramBlockEventInterface,
} from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import BlockSection from '../Block/Section'
import SettingsSection from './SettingsSection'

export default function BlockEvent ({
  block,
  settings,
  interfaces,
  dialog,
  onClose,
  onSubmit,
}) {
  const immutableInterfaces = JSON.stringify(interfaces.map(item => item?.updatedAt))
  const defaultValues = useMemo(() => ({
    block: DiagramBlock.toJSON(block),
    settings: {
      event: DiagramBlockEvent.toJSON(settings),
      interfaces: interfaces.map(item => DiagramBlockEventInterface.toJSON(item)),
    },
  }), [
    block?.updatedAt ?? block,
    settings?.updatedAt ?? settings,
    immutableInterfaces,
  ])

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="blockEventForm"
      dialog={dialog}
    >
      <BlockSection scope="block" />
      <SettingsSection scope="settings" />
    </FormDialog>
  )
}
