import { useMemo } from 'react'
import { Job } from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import Section from './Section'
import Removed from '../Removed'

export default function BGJobImport ({
  id,
  bgJob,
  projects,
  files,
  twins,
  dialog,
  onClose,
  onSubmit,
  onSuccess,
}) {
  const disabled = !!id
  const defaultValues = useMemo(() => ({
    bgJob: Job.toJSON(bgJob),
    projects,
    files,
  }), [
    bgJob?.updatedAt ?? bgJob,
    projects,
    files,
  ])

  if (id && !bgJob?.id) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      onSuccess={onSuccess}
      defaultValues={defaultValues}
      dataTestid="bgJobImportForm"
      dialog={dialog}
    >
      <Section
        scope="bgJob"
        disabled={disabled}
        twins={twins}
      />
    </FormDialog>
  )
}
