import { useState, useEffect, useRef } from 'react'
import { wizataApi } from '@/api'
import { withProps, withPagination, withSort } from '@wiz/components'
import { isEqual } from '@wiz/utils'
import { contextTwin } from '@/utils/contextTwin'
import Component from '@/components/Events/TableMobile'

const SortByFields = {
  createdAt: 'createdDate',
  name: 'name',
  sourceType: 'sourceType',
  status: 'status',
  type: 'type',
}

const enhanceProps = withProps(({
  filters,
}) => {
  const [ loading, setLoading ] = useState(true)
  const [ items, setItems ] = useState([])
  const [ total, setTotal ] = useState(0)
  const refresh = useRef(null)
  const twinContextId = contextTwin.getContextTwin(true)

  useEffect(() => {
    const isChanged = !isEqual(refresh.current, filters)
    if (isChanged) {
      setLoading(true)

      const limit = filters.pageSize + 1
      const offset = (filters.page - 1) * filters.pageSize

      wizataApi.events.list({
        from: filters.from,
        to: filters.to,
        search: filters.search,
        eventTypes: filters.eventTypes,
        statuses: filters.statuses,
        sourceTypes: filters.sourceTypes,
        streamJobId: filters.streamJobId,
        twinIds: filters.twinIds,
        sensorIds: filters.sensorIds,
        includeChildTwinEvents: filters.includeChildEvents,
        sortBy: SortByFields[filters.sortBy],
        sortDir: filters.sortDir,
        limit,
        offset,
        twinContextId,
      })
        .then((data) => {
          setTotal(offset + data.length)
          setItems(data.slice(0, filters.pageSize))
        })
        .finally(() => {
          refresh.current = filters
          setLoading(false)
        })
    }
  }, [ filters, twinContextId ])

  return {
    total,
    items,
    loading,
  }
})

export default enhanceProps(
  withSort({ localData: false })(
    withPagination({ localData: false })(Component),
  ),
)
