import { memo } from 'react'
import { of as of$, throwError } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'
import { createLocation } from '@/router'
import { auth } from '@/auth'
import Component from '@/components/EdgeDevice/View'
import withTableActions from '@/containers/withTableActions'

const enhanceEdge = withObservables([ 'match' ], ({ match }) => ({
  edge: dbProvider.database.collections.get('edges')
    .query(Q.where('id', match.params.id))
    .observeWithColumns([ 'updated_at' ])
    .pipe(
      map(items => items.filter(item => auth.checkAccessRead(item))),
      mergeMap(items => (items.length ? of$(items[0]) : throwError('Edge not found'))),
    ),
}), {
  onError (error, { history, match }) {
    history.push(createLocation({
      name: 'edge-devices-list',
      params: match.params,
    }))
  },
})

export default memo(
  withTableActions({
    defaultFilters: () => ({
      sortBy: null,
      sortDir: null,
    }),
  })(
    enhanceEdge(Component),
  ),
)
