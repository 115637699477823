import { intersection, type } from 'superstruct'
import { Unit } from '@wiz/store'
import * as types from '@/utils/types'

export default type({
  unit: type({
    symbol: intersection([
      types.Required(),
      Unit.schema.schema.symbol,
    ]),
    shortName: Unit.schema.schema.shortName,
    fullName: Unit.schema.schema.fullName,
    baseFactor: Unit.schema.schema.baseFactor,
    categoryId: Unit.schema.schema.categoryId,
    color: Unit.schema.schema.color,
    icon: Unit.schema.schema.icon,
  }),
})
