import { useMemo, useCallback } from 'react'
import {
  DataSource as DataSource$, dbProvider, Formula, Script,
} from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import { consts } from '@wiz/utils'
import Removed from '../Removed'
import Section from './Section'
import SectionVirtual from './SectionVirtual'

export default function DataSource ({
  id,
  isDataSource,
  dataSource,
  dialog,
  onClose,
  onSubmit,
}) {
  const defaultValues = useMemo(() => ({
    dataSource: DataSource$.toJSON(dataSource),
  }), [ dataSource?.updatedAt ?? dataSource ])

  const handleSubmit = useCallback(async ({ dataSource }) => {
    const type = dataSource.type === consts.DataSourceType.MlModel ? consts.DataSourceType.Script : dataSource.type
    const next = { ...dataSource, type }
    if (type === 'formula') {
      const context = dbProvider.createBatchContext()
      await dbProvider.prepareReplaceData(context, Formula, next?.settings?.formula)
      await dbProvider.batch(context)
    } else if (type === 'script') {
      const context = dbProvider.createBatchContext()
      await Script.prepareReplaceSettings(context, dbProvider.database, next?.settings)
      await dbProvider.batch(context)
    }

    onSubmit?.({
      dataSource: DataSource$.toJSON(next),
    })
  }, [ onSubmit ])

  if (id && !dataSource) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={handleSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="dataSourceForm"
      dialog={dialog}
    >
      {defaultValues.dataSource.isVirtual && !isDataSource ? (
        <SectionVirtual
          scope="dataSource"
        />
      ) : (
        <Section
          scope="dataSource"
        />
      )}
    </FormDialog>
  )
}
