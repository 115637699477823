import { useMemo } from 'react'
import { Condition } from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import Section from './Section'

export default function WidgetTableDataCondition ({
  id,
  condition,
  dialog,
  onClose,
  onSubmit,
  ComponentInputDataSources,
  ComponentOutputDataSources,
}) {
  const defaultValues = useMemo(() => ({
    condition: Condition.toJSON(condition),
  }), [ condition?.updatedAt ?? condition ])

  if (id && !condition) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="conditionForm"
      dialog={dialog}
    >
      <Section
        scope="condition"
        ComponentInputDataSources={ComponentInputDataSources}
        ComponentOutputDataSources={ComponentOutputDataSources}
      />
    </FormDialog>
  )
}
