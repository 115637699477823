import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { FormFieldInline, FormControl } from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

const FieldInfinity = ({ scope }) => {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()
  return (
    <FormFieldInline
      label={intl.t('widgets.histogram.form.fields.infinity')}
      description={intl.t('widgets.histogram.form.fields.infinityDescr')}
      errors={get(errors, `${scope}.infinity`)}
    >
      <FormControl
        type="checkbox"
        name={`${scope}.infinity`}
        defaultValue={consts.WidgetHistogramInfinity}
        className="ms-2"
      />
    </FormFieldInline>
  )
}

FieldInfinity.propTypes = {
  scope: PropTypes.string.isRequired,
}

export default FieldInfinity
