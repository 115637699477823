/* eslint-disable no-param-reassign */
import { createContext, useContext } from 'react'
import { Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'
import moonStyles from '@wiz/style/lib/moon.css?themecss'
import { variables as moonVariables } from '@wiz/style/lib/moon'
import moonEcharts from './assets/style/themes/moon/echarts'
import moonGojs from './assets/style/themes/moon/gojs'

const ThemeContext = createContext()

function handleSuccess (target, [{ variables }, echarts, gojs, styles ]) {
  target.styles?.unuse?.()
  target.variables = variables
  target.echarts = echarts.default
  target.gojs = gojs.default
  target.styles = styles.default
  target.status = 'resolved'
  return target
}

const Themes = {
  // sun: {
  //   status: undefined,
  //   name: 'sun',
  //   icon: 'faSun',
  //   opposite: 'moon',
  //   variables: null,
  //   echarts: null,
  //   gojs: null,
  //   styles: null,
  //   fetch: () => {
  //     if (Themes.sun.status === 'resolved') {
  //       return Promise.resolve(Themes.sun)
  //     }

  //     return Promise.all([
  //       import(
  //         /* webpackChunkName: "sun" */
  //         /* webpackMode: "lazy" */
  //         '@wiz/style/lib/sun'
  //       ),
  //       import(
  //         /* webpackChunkName: "sun" */
  //         /* webpackMode: "lazy" */
  //         './assets/style/themes/sun/echarts.js'
  //       ),
  //       import(
  //         /* webpackChunkName: "sun" */
  //         /* webpackMode: "lazy" */
  //         './assets/style/themes/sun/gojs.js'
  //       ),
  //       import(
  //         /* webpackChunkName: "sun" */
  //         /* webpackMode: "lazy" */
  //         '@wiz/style/lib/sun.css?themecss'
  //       ),
  //     ]).then(data => handleSuccess(Themes.sun, data))
  //   },
  // },

  // FIXME: should be removed or updated if we'll have light theme
  // ps. we need this now to switch all users with light/dark theme to one dark theme.
  sun: {
    status: 'resolved',
    name: 'moon',
    icon: 'faMoon',
    opposite: 'sun',
    variables: moonVariables,
    echarts: moonEcharts,
    gojs: moonGojs,
    styles: moonStyles,
    fetch: () => Promise.resolve(Themes.moon),
  },
  moon: {
    status: 'resolved',
    name: 'moon',
    icon: 'faMoon',
    opposite: 'sun',
    variables: moonVariables,
    echarts: moonEcharts,
    gojs: moonGojs,
    styles: moonStyles,
    fetch: () => Promise.resolve(Themes.moon),
  },
}

function applyTheme (theme) {
  document.documentElement.setAttribute('data-theme', theme.name)
  // const { style } = document.documentElement
  // style.cssText = ''
  // for (const varName in theme.variables) {
  //   if (Object.hasOwnProperty.call(theme.variables, varName)) {
  //     style.setProperty(varName, theme.variables[varName])
  //   }
  // }

  theme.styles.use()
}

function unloadTheme () {
  const name = document.documentElement.getAttribute('data-theme')
  const theme = Themes[name]
  theme?.styles?.unuse?.()
}

applyTheme(Themes.moon)

function observeTheme (name) {
  return new Observable((subscriber) => {
    const theme = Themes[name] || Themes.moon
    theme.fetch().then(() => {
      unloadTheme()
      applyTheme(theme)
      subscriber.next(theme)
    })
  })
}

export function useTheme () {
  return useContext(ThemeContext)
}

const enhanceTheme = withObservables([ 'themeId' ], ({ themeId }) => ({
  theme: themeId ?
    observeTheme(themeId) :
    dbProvider.observeSettings('themeId')
      .pipe(switchMap(data => observeTheme(data))),
}))

export const ThemeProvider = enhanceTheme(({ theme, children }) => (
  <ThemeContext.Provider value={theme}>
    {children}
  </ThemeContext.Provider>
))
