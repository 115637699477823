/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import {
  Form,
  FormField,
  FormSection,
  Dialog,
  CustomScrollbars,
  useWindow,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import classNames from 'classnames'

export default function ModelView ({
  refDialog,
  title,
  data,
  actions = [],
  onClose,
}) {
  const refRoot = useRef()
  const refDialogCurrent = refDialog || refRoot
  const intl = useIntl()
  const win = useWindow()
  const currentSize = win?.match('smobil') ? 'sm' : undefined

  return (
    <Dialog
      ref={refDialogCurrent}
      title={title}
      classNameBody="p-0"
      onClose={onClose}
    >
      <Form
        className="flex-fill d-flex flex-column min-h-0"
        size={currentSize}
      >
        <CustomScrollbars
          className="flex-fill"
          classNameContent="d-flex"
          horizontal={false}
          shadow={false}
          autoHide
        >
          <div className="flex-fill d-flex flex-column px-3 min-w-0">
            {data.map((item, index) => (item.options ? (
              <FormSection key={index} title={item.title}>
                {item.options.map((option, idx) => (
                  <FormField key={idx} label={option.title} classNameLabel="text-secondary fw-bold small">
                    <div className="form-control bg-transparent border-0 px-0 text-break">
                      {typeof option.value === 'function' ? option.value() : option.value}
                    </div>
                  </FormField>
                ))}
              </FormSection>
            ) : (
              <FormField key={index} label={item.title} classNameLabel="text-secondary fw-bold small">
                <div className="form-control bg-transparent border-0 px-0 text-break">
                  {typeof item.value === 'function' ? item.value() : item.value}
                </div>
              </FormField>
            )))}
          </div>
        </CustomScrollbars>

        <footer className="d-flex justify-content-end m-2 p-1">
          {actions.map(item => (
            <button
              key={item.name}
              name={item.name}
              type="button"
              data-testid={item.testid}
              className={classNames('btn m-1', {
                'btn-outline-secondary': item.name !== 'remove',
                'btn-outline-danger': item.name === 'remove',
              })}
              onClick={() => {
                refDialogCurrent.current.close()
                item.onClick()
              }}
            >
              {item.title}
            </button>
          ))}

          <button
            name="close"
            type="button"
            data-testid="closeButton"
            className="btn btn-outline-secondary m-1"
            onClick={() => refDialogCurrent.current.close()}
          >
            {intl.t('form.actions.close')}
          </button>
        </footer>
      </Form>
    </Dialog>
  )
}
