import { duration } from '@wiz/utils'
import classes from './index.module.css'

const SizeByLen = [
  [ 3, 1.4 ],
  [ 4, 1.3 ],
  [ 5, 1.2 ],
  [ 6, 1.1 ],
  [ 7, 1 ],
  [ 8, 0.9 ],
  [ 9, 0.8 ],
  [ 11, 0.7 ],
  [ 12, 0.6 ],
  [ 13, 0.55 ],
  [ 14, 0.5 ],
  [ 18, 0.4 ],
  [ 23, 0.3 ],
]

const SingleValue = ({
  value, pure,
}) => {
  const title = pure ? value : duration(value, { sep: ' ' }) || value
  const len = pure ? 10 : title?.length

  // const len = titleValue.length || 10
  const fontSize = SizeByLen.find(item => item[0] >= len)?.[1] ?? 0.2

  return (
    <div
      className="mt-2"
    >
      <svg viewBox="0 0 56 18" className={classes.fit}>
        <foreignObject
          width="100%"
          height="100%"
          fill="currentColor"
          style={{
            textAlign: 'center',
            fontSize: `${fontSize}em`,
            lineHeight: 1,
          }}
        >
          {title}
        </foreignObject>
      </svg>
    </div>

  )
}

export default SingleValue
