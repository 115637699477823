import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { wizataApi } from '@/api'
import { VirtualTable as TableComponent, Pagination } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import Icon from '@/shared/icon'
import CellTwin from './CellTwin'
import CellTemplate from './CellTemplate'
import CellName from './CellName'
import CellKey from './CellKey'
import EditButton from '../../shared/editButton'

const CellUpdated = ({ cell, row }) => {
  const {
    updatedUser, updatedById, updatedDate, updatedAt,
  } = row.original

  return (
    <UpdatedInfo
      date={updatedDate || updatedAt}
      user={updatedUser}
      userId={updatedById}
    />
  )
}

const CellCreated = ({ cell, row }) => {
  const {
    createdUser, createdById, createdDate, createdAt,
  } = row.original
  return (
    <UpdatedInfo
      date={createdDate || createdAt}
      user={createdUser}
      userId={createdById}
    />
  )
}

export default function Table ({
  data,
  filters,
  onFilter,
  onRightBarOpen,
  onOpen,
  onEdit,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()

  const list = useQuery({
    queryKey: [ 'templatesList' ],
    queryFn: () => wizataApi.templates.getList('false'),
    // refetchOnWindowFocus: false,
  })

  const pipelines = useQuery({
    queryKey: [ 'pipelines' ],
    queryFn: () => wizataApi.pipelines.getList(),
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const columns = useMemo(() => [
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      maxWidth: 30,
      className: 'justify-content-center text-nowrap',
      Cell: ({ row, cell }) => (auth.checkAccessUpdate('Experiment') ? (
        <EditButton cell={cell} onEdit={onEdit} />
      ) : null),
    },
    {
      Header: intl.t('form.fields.key'),
      accessor: 'key',
      width: 100,
      Cell: ({ row }) => (
        <CellKey
          experiment={row.original}
          filters={filters}
          onOpen={onOpen}
          onRightBarOpen={onRightBarOpen}
        />
      ),
    },
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      minWidth: 200,
      Cell: ({ row }) => (
        <CellName
          experiment={row.original}
          filters={filters}
          onOpen={onOpen}
          onRightBarOpen={onRightBarOpen}
        />
      ),
    },
    {
      Header: intl.t('form.fields.template'),
      accessor: 'templateId',
      disableResizing: true,
      minWidth: 220,
      width: 220,
      maxWidth: 220,
      Cell: ({ cell }) => (
        <CellTemplate
          id={cell.value}
          list={list}
          entity="templates-list"
        />
      ),
    },
    {
      Header: intl.t('form.fields.pipeline'),
      accessor: 'pipelineId',
      disableResizing: true,
      minWidth: 220,
      width: 220,
      maxWidth: 220,
      Cell: ({ cell }) => (
        <CellTemplate
          id={cell.value}
          list={pipelines}
          entity="pipelines-list"
        />
      ),
    },
    {
      Header: intl.t('form.fields.twin'),
      accessor: 'twinId',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 200,
      Cell: ({ row }) => (
        <CellTwin
          experiment={row.original}
          onFilter={onFilter}
        />
      ),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedDate',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: CellUpdated,
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdDate',
      disableResizing: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: CellCreated,
    },
  ], [
    filters,
    intl,
    onFilter,
    onRightBarOpen,
    onOpen,
    list,
    onEdit,
    auth,
    pipelines,
  ])

  return (
    <>
      <TableComponent
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={data?.items || []}
        selection={auth.checkAccessRemove('Experiment')}
        {...props}
      />

      <Pagination {...props} total={data?.totalCount || 0} />
    </>
  )
}
