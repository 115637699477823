import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import WidgetSection from '../Widget/Section'
import Section from './Section'
import SectionView from './SectionView'
import SectionTime from './SectionTime'

export default function WidgetEvents ({
  defaultValues,
  dialog,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="widgetEventsForm"
      dialog={dialog}
    >
      <WidgetSection
        scope="widget"
        description={intl.t('widgets.events.titleDescr')}
      />

      <SectionView
        scope="config"
        title={intl.t('widgets.events.form.sections.view')}
        description={intl.t('widgets.events.form.sections.viewDescr')}
      />

      <SectionTime
        scope="config"
        title={intl.t('widgets.events.form.sections.time')}
        description={intl.t('widgets.events.form.sections.timeDescr')}
      />

      <Section
        scope="config"
        title={intl.t('widgets.events.form.sections.filters')}
        description={intl.t('widgets.events.form.sections.filtersDescr')}
      />
    </FormDialog>
  )
}
