import {
  useEffect,
  useCallback,
  useRef,
} from 'react'
import cx from 'classnames'
import { useIntl } from '@wiz/intl'
import { Dropdown } from '@wiz/components'
import { useRouter } from '@/router'
import { useStonly } from '@/integrations/stonly'
import { useAuth } from '@/auth'
import { checkPermissions } from '@/utils/menu'
import Icon from '@/shared/icon'
import { MenuItem } from '../TopMenu/MenuItem'
import { settingsRoutes } from './settingsRoutes'

const RoutesById = {
  dashboard: 'homepage',
  profile: 'profile',
  logout: 'logout',
}

export const Options = ({ onClick, items }) => {
  const auth = useAuth()
  const router = useRouter()
  const intl = useIntl()

  const handleClick = useCallback(({ id }) => {
    if (id === 'relogin') {
      auth.relogin()
    } else if (RoutesById[id]) {
      router.push({ name: RoutesById[id] })
    }
  }, [ auth, router ])

  const itemsWithAccess = checkPermissions(settingsRoutes)
  const data = items || itemsWithAccess

  if (!data.length) {
    return null
  }

  return data?.map(({ name, id, ...rest }, idx) => {
    if (rest.hidden) {
      return null
    }
    return (
      <MenuItem
        key={id}
        idx={idx}
        className={cx('d-flex align-items-center')}
        {...rest}
        onAction={handleClick}
        onClose={onClick}
      >
        <span className="d-flex flex-fill" role={!rest.actions && 'menuitem'}>{intl.t(name)}</span>
        {rest.actions?.length ? <Icon type="solid" name="faCaretRight" className="ms-4" /> : null}
      </MenuItem>
    )
  })
}

export default function ButtonSettings () {
  const refTarget = useRef()
  const { send } = useStonly()
  const intl = useIntl()

  const itemsWithAccess = checkPermissions(settingsRoutes)

  useEffect(() => {
    const key = 'permission-settings'
    const value = itemsWithAccess.length ? 'read' : 'none' // add 'write' for boards/dashboards create

    send({ [key]: value })
  }, [])

  if (!itemsWithAccess.length) {
    return null
  }

  return (
    <>
      <button
        ref={refTarget}
        type="button"
        className="btn btn-text btn-flat-secondary"
        title={intl.t('menu.tooltip.settings')}
      >
        <Icon type="solid" name="faCog" size="lg" />
      </button>

      <Dropdown
        arrow
        autoclose
        target={refTarget}
        placement="bottom-end"
        className="py-2 text-break"
      >
        <Options items={itemsWithAccess} />
      </Dropdown>
    </>
  )
}
