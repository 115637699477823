import { useFormContext } from 'react-hook-form'
import { FormSection } from '@wiz/components'
import Fields from './Fields'

export default function Section ({
  scope,
  title,
  addon,
  validates,
  exclude,
  registerId = true
}) {
  const { register } = useFormContext()

  return (
    <FormSection
      title={title}
      addon={addon}
    >
      {registerId ? (
        <input
          {...register(`${scope}.id`)}
          type="hidden"
        />
      ) : null}

      <Fields
        scope={scope}
        exclude={exclude}
        validates={validates}
      />

      {/* {businessType ? (
      ) : (
        <div className="d-flex">
          <button
            type="button"
            className="d-flex flex-column flex-fill btn btn-fill-secondary my-5 mx-3 p-5 btn-lg"
            onClick={() => {
              setValue(`${scope}.businessType`, consts.SensorBusinessTypesHardware[0], {
                shouldDirty: true,
              })
            }}
          >
            <Icon name="fa--microchip" size="3X" className="mb-2" />
            {intl.t('sensors.form.actions.selectHardware')}
          </button>

          <button
            type="button"
            className="d-flex flex-column flex-fill btn btn-fill-secondary my-5 mx-3 p-5 btn-lg"
            onClick={() => {
              setValue(`${scope}.businessType`, consts.SensorBusinessTypesVirtual[0], {
                shouldDirty: true,
              })
            }}
          >
            <Icon name="sensors" size="3X" className="mb-2" />
            {intl.t('sensors.form.actions.selectVirtual')}
          </button>
        </div>
      )} */}
    </FormSection>
  )
}
