import { map } from 'rxjs/operators'
import { FormSelect, withObservables } from '@wiz/components'
import { orderBy, consts } from '@wiz/utils'
import { Q, dbProvider } from '@wiz/store'

const enhanceData = withObservables([], () => ({
  options: dbProvider.database.collections.get('files')
    .query(Q.where('type', consts.FileType.NotebookTemplate))
    .observe()
    .pipe(
      map(items => (
        orderBy(items.map(item => ({
          id: item.id,
          name: item.name,
        })), [ item => String(item.name).toLowerCase() ], [ 'asc' ])
      )),
    ),
}))

const SelectTmplNotebook = enhanceData(FormSelect)

SelectTmplNotebook.displayName = 'SelectTmplNotebook'

export default SelectTmplNotebook
