import { useState, useCallback, useEffect } from 'react'
import { arrayOf, shape } from 'prop-types'
import { FormInputSearch } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { debounce } from '@wiz/utils'
import classNames from 'classnames'
import { dbProvider, Twin } from '@wiz/store'
import List from './List'
import Item from './ItemContent'

export default function RightBarLinked ({ blocks }) {
  const intl = useIntl()

  const [ options, setOptions ] = useState(blocks)
  const [ search, setSearch ] = useState('')
  const [ replaceString, setReplaceString ] = useState('')

  const handleChangeSearch = debounce((str) => {
    if (str) {
      const next = blocks.filter(twin => twin.name.toLowerCase().indexOf(str.toLowerCase()) > -1)
      setSearch(str)
      setOptions(next)
    } else {
      setOptions(blocks)
    }
  }, 1000)

  const handleChangeReplaceString = useCallback((str) => {
    setReplaceString(str)
  }, [])

  const handleSubmit = useCallback(async () => {
    const context = dbProvider.createBatchContext()
    for (const item of options) {
      const nextName = item.name.replace(search, replaceString)
      await dbProvider.prepareReplaceData(
        context,
        Twin,
        { id: item.id, name: nextName, type: item.type },
      )
    }
    await dbProvider.batch(context)

    handleChangeSearch(search) // <- updating options to filter the list with new names
  }, [ options, search, replaceString, handleChangeSearch ])

  return (

    <div className={classNames('d-flex flex-wrap mt-2 mb-1 mx-3 flex-column', {
      'h-100': blocks?.length,
    })}
    >
      <div className="mb-1">
        <FormInputSearch
            // ref={refSearch}
          size="sm"
          placeholder={intl.t('form.actions.searchPlaceholder')}
          onChange={handleChangeSearch}
        />
      </div>
      <div className="d-flex mb-1">
        <FormInputSearch
            // ref={refSearch}
          size="sm"
          placeholder={intl.t('form.actions.replace')}
          onChange={handleChangeReplaceString}
        />
        <button
          name="replace"
          type="button"
          className="btn btn-sm btn-fill-secondary ms-2"
          onClick={handleSubmit}
          disabled={!search}
        >
          {intl.t('form.actions.replace')}
        </button>
      </div>
      <div className="position-relative d-flex flex-fill min-h-0">
        <List options={options} Content={Item} search={search} />
      </div>
    </div>

  )
}

RightBarLinked.propTypes = {
  blocks: arrayOf(shape({})).isRequired,
}
