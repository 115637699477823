/* eslint-disable max-classes-per-file */
import EventEmitter from 'eventemitter3'
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import StatusBar from '@uppy/status-bar'
import Webcam from '@uppy/webcam'
import UppyXHRUpload from '@uppy/xhr-upload'
import { network } from '@wiz/utils'
import { auth } from '@/auth'
import { intl } from '@/i18n'
import events from '@/utils/events'

const BASE_URL = String(process.env.WIZATA_API_BASE_URL).replace('[apiVersion]', '1.0')

class XHRUpload extends UppyXHRUpload {
  // eslint-disable-next-line class-methods-use-this
  addMetadata (formData, meta, opts) {
    const metaFields = Array.isArray(opts.metaFields) ? opts.metaFields : Object.keys(meta)
    for (const item of metaFields) {
      if (Array.isArray(meta[item])) {
        for (const value of meta[item]) {
          formData.append(`${item}[]`, value)
        }
      } else {
        formData.append(item, meta[item])
      }
    }
  }
}

const uppyInstance = Uppy({
  id: 'files',
  debug: true,
  autoProceed: false,
  allowMultipleUploads: true,
  restrictions: {
    maxFileSize: 100 * 1000 * 1000, // in bytes, 100 Mb
    maxNumberOfFiles: 5,
    minNumberOfFiles: 1,
  },
  meta: {},
  // store: {},
  // onBeforeFileAdded: (currentFile, files) => {
  //   if (!currentFile.type) {
  //     uppyInstance.log('Skipping file because it has no type')
  //     uppyInstance.info('Skipping file because it has no type', 'error', 3000)
  //     return false
  //   }
  // },
})
  .use(Webcam)
  .use(XHRUpload, {
    endpoint: `${BASE_URL}/Files/uploadFile`,
    timeout: 20 * 60 * 1000,
    fieldName: 'files',
    bundle: false,
    limit: 1,
    metaFields: [ 'name', 'fileType', 'description', 'projectIds' ],
    getResponseError (responseText) {
      let message = 'Internal server error'
      try {
        message = JSON.parse(responseText).errors.join('\n')
      } catch (error) {
        // skip
      }
      return new Error(message)
    },
  })
  .on('upload', () => {
    // store.dispatch('statusbar/appenStatus', 'uploader')
  })
  .on('complete', (result) => {
    if (result?.successful?.length > 0) {
      events.emit('app:sync')
      result.successful.forEach((file) => {
        uppyInstance.removeFile(file.id)
      })
    }

    if (result?.failed?.length > 0) {
      events.emit('app:notify', {
        type: 'error',
        title: 't/files.titleUpload',
        message: intl.t('files.form.errors.upload', {
          message: result.failed.map(file => file.error).join('; '),
        }),
      })
    } else if (result?.successful?.length > 0) {
      events.emit('app:notify', {
        type: 'success',
        title: 't/files.titleUpload',
        message: 't/files.form.success.upload',
      })
    }
  })

export class StatusBarUploader extends EventEmitter {
  #id = null

  constructor ({ target }) {
    super()

    this.#id = `StatusBar-${Math.random()}`

    uppyInstance
      .use(StatusBar, {
        id: this.#id,
        target,
        hideUploadButton: true,
        hideAfterFinish: false,
        replaceTargetContent: false,
      })
  }

  destroy () {
    this.removeAllListeners()
    const plugin = uppyInstance.getPlugin(this.#id)
    if (plugin) {
      uppyInstance.removePlugin(plugin)
    }
    this.#id = null
  }
}

export class DashboardUploader extends EventEmitter {
  #id = null

  constructor ({
    target = 'body',
    inline = target !== 'body',
    restrictions,
  } = {}) {
    super()

    this.#id = `Dashboard-${Math.random()}`
    uppyInstance
      .use(Dashboard, {
        id: this.#id,
        target,
        inline,
        trigger: '.uploader-select-files',
        hideUploadButton: true,
        replaceTargetContent: false,
        showProgressDetails: true,
        proudlyDisplayPoweredByUppy: false,
        // showLinkToFileUploadResult: false,
        note: '1-5 files, up to 100 MB',
        width: 'auto',
        height: 'auto',
        plugins: [
          'Webcam',
        ],
        restrictions,
      })
      .on('file-added', this.handleFileAdded)
      .on('file-removed', this.handleFileRemoved)
      .on('upload', this.handleUpload)
      .on('upload-success', this.handleUploadSuccess)
      .on('upload-error', this.handleUploadError)
  }

  destroy () {
    this.removeAllListeners()
    uppyInstance
      .off('file-added', this.handleFileAdded)
      .off('file-removed', this.handleFileRemoved)
      .off('upload', this.handleUpload)
      .off('upload-success', this.handleUploadSuccess)
      .off('upload-error', this.handleUploadError)

    const plugin = uppyInstance.getPlugin(this.#id)
    if (plugin) {
      uppyInstance.removePlugin(plugin)
    }

    this.#id = null
  }

  handleUploadSuccess = (file, response) => {
    this.emit('file-upload-success', file, response?.body?.[0])
  }

  handleUploadError = (file, error, response) => {
    this.emit('file-upload-error', file, error, response?.body?.errors)
  }

  handleFileAdded = (file) => {
    this.emit('file-added', file)
  }

  handleFileRemoved = (file) => {
    this.emit('file-removed', file)
  }

  handleUpload = () => {
    this.emit('upload')
  }

  // eslint-disable-next-line class-methods-use-this
  handleCancelAll () {
    uppyInstance.cancelAll()
  }

  // eslint-disable-next-line class-methods-use-this
  setMeta (data) {
    uppyInstance.setMeta(data)
  }

  // eslint-disable-next-line class-methods-use-this
  setFileMeta (id, meta) {
    uppyInstance.setFileMeta(id, meta)
  }

  // eslint-disable-next-line class-methods-use-this
  reset () {
    uppyInstance.reset()
  }

  // eslint-disable-next-line class-methods-use-this
  async upload () {
    if (!network.onLine) {
      events.emit('app:notify', {
        type: 'error',
        title: 't/files.titleUpload',
        message: 't/files.form.errors.uploadOffline',
      })

      throw new Error('An error occurred while uploading files.')
    }

    const token = await auth.acquireToken()
    const upload = uppyInstance.getPlugin('XHRUpload')

    upload.opts.headers = {
      Authorization: `BEARER ${token}`,
    }

    let result = await uppyInstance.retryAll()
    if (result?.failed?.length > 0) {
      throw new Error('An error occurred while uploading files.')
    }

    result = await uppyInstance.upload()
    if (result?.failed?.length > 0) {
      throw new Error('An error occurred while uploading files.')
    }
  }
}
