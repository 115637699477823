class ContextTwin {
  contextTwin = null

  currentContextTwin = null

  setContextTwin (id) {
    this.contextTwin = id
    this.currentContextTwin = id
  }

  getContextTwin (current) {
    if (current) {
      return this.currentContextTwin
    }
    return this.contextTwin
  }

  clearContextTwin (force) {
    if (force) {
      this.currentContextTwin = null
    }
    this.contextTwin = null
  }
}

export const contextTwin = new ContextTwin()
