import { useMemo, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { consts } from '@wiz/utils'
import { Tree, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { Event, DiagramBlock, Twin } from '@wiz/store'
import Option from './Option'

export default function List ({
  isLoading,
  blockSettings,
  options,
  onEditEvent,
  onRemoveEvent,
  onAttachEvent,
  onOpenExplorer,
}) {
  const refTree = useRef()
  const intl = useIntl()
  const itemData = useMemo(() => ({
    blockSettings,
    onEditEvent,
    onRemoveEvent,
    onAttachEvent,
    onOpenExplorer,
  }), [
    blockSettings,
    onEditEvent,
    onRemoveEvent,
    onAttachEvent,
    onOpenExplorer,
  ])

  const itemSize = useCallback((index) => {
    const item = refTree.current?.getItemByIndex(index)?.payload
    let size = consts.ListItemHeight
    if ((item instanceof Event) && item.sensorIds?.length) {
      size = consts.ListItemHeightLg
    } else if ((item instanceof DiagramBlock) && blockSettings[item.id] && item.name) {
      size = consts.ListItemHeightMd
    } else if ((item instanceof Twin) && item.description) {
      size = consts.ListItemHeightMd
    }
    return size
  }, [ blockSettings ])

  if (!options.length) {
    return (
      <div className="d-flex align-items-center justify-content-center flex-fill">
        {isLoading ? (
          <Icon name="fa--spinner" size="2X" spin />
        ) : intl.t('errors.noDataDisplay')}
      </div>
    )
  }

  return (
    <div className="d-flex flex-fill min-h-0 position-relative">
      <Tree
        refTree={refTree}
        className="flex-fill min-h-0"
        options={options}
        itemData={itemData}
        itemSize={itemSize}
        estimatedItemSize={consts.ListItemHeight}
      >
        {Option}
      </Tree>

      {isLoading ? (
        <div className="position-absolute-fill d-flex align-items-center justify-content-center opacity-50 bg-light">
          <Icon name="fa--spinner" size="2X" spin />
        </div>
      ) : null}
    </div>
  )
}

List.propTypes = {
  options: PropTypes.array.isRequired,
  blockSettings: PropTypes.object,
  onEditEvent: PropTypes.func,
  onRemoveEvent: PropTypes.func,
  onAttachEvent: PropTypes.func,
  onOpenExplorer: PropTypes.func,
  isLoading: PropTypes.bool,
}

List.defaultProps = {
  blockSettings: undefined,
  onEditEvent: undefined,
  onRemoveEvent: undefined,
  onAttachEvent: undefined,
  onOpenExplorer: undefined,
  isLoading: false,
}
