import { useCallback } from 'react'
import { of as of$ } from 'rxjs'
import { withObservables, withProps } from '@wiz/components'
import { dbProvider } from '@wiz/store'
import CurrentUser from '@/hoc/CurrentUser'
import { useRouter } from '@/router'
import Component from '@/components/Profile/View'

const enhanceData = withObservables([ 'currentUser' ], ({ currentUser }) => ({
  roles: currentUser ? currentUser.observeAccessRoles : of$([]),
  twins: currentUser ? currentUser.queryRelTwins.observe() : of$([]),
  settings: dbProvider.observeSettings([
    'homepageDefaultId',
    'PlatformDateFormat',
    'PlatformTimeFormat',
    'DefaultTwin',
  ]),
  globalSettings: dbProvider.observeGlobalSettings([
    'PlatformDateFormat',
    'PlatformTimeFormat',
  ]),
}))

const enhanceProps = withProps(() => {
  const router = useRouter()

  const onChangeLang = useCallback((lang) => {
    router.push({ params: { lang } })
  }, [ router ])

  const onChangeTheme = useCallback(async (theme) => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareReplaceSetting(context, 'themeId', theme)
    await dbProvider.batch(context)
  }, [])

  const onResetLocalData = useCallback(() => {
    window.wizConfirm({ message: 't/form.confirmResetLocalData' })
      .then(() => dbProvider.scheduledResetDatabase())
  }, [])

  const onChangeHomepage = useCallback(async (id) => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareReplaceSetting(context, 'homepageDefaultId', id)
    await dbProvider.prepareReplaceSetting(context, 'dashboardDefaultId', undefined)
    await dbProvider.batch(context)
  }, [])

  const onChangeSetting = useCallback(async (name, value) => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareReplaceSetting(context, name, value)
    await dbProvider.batch(context)
  }, [])

  return {
    onChangeTheme,
    onChangeLang,
    onResetLocalData,
    onChangeHomepage,
    onChangeSetting,
  }
})

export default CurrentUser(
  enhanceData(
    enhanceProps(Component),
  ),
)
