import { useMemo } from 'react'
import { DiagramBlock, TwinGraph } from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import BlockSection from '../Block/Section'
import SettingsSection from './SettingsSection'

export default function BlockNestedTwinGraph ({
  block,
  settings,
  dialog,
  onClose,
  onSubmit,
}) {
  const defaultValues = useMemo(() => ({
    block: DiagramBlock.toJSON(block),
    settings: TwinGraph.toJSON(settings),
  }), [
    block?.updatedAt ?? block,
    settings?.updatedAt ?? settings,
  ])

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="blockNestedTwinGraphForm"
      dialog={dialog}
    >
      <BlockSection
        scope="block"
      />

      <SettingsSection
        block={block}
      />
    </FormDialog>
  )
}
