import { useMemo, useState } from 'react'
import {
  Table as StaticTable,
  Pagination,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import CellStreamJob from './CellStreamJob'
import CellLogSource from './CellLogSource'
import CellUserEmail from './CellUserEmail'

export default function Table ({
  items,
  onFilter,
  ...props
}) {
  const intl = useIntl()
  const [ isMs, setIsMs ] = useState(false)

  const columns = useMemo(() => [
    {
      Header: () => (
        <>
          {intl.t('streamJobLogs.form.fields.timestamp')}
          <button
            type="button"
            className="btn btn-sm btn-fill-secondary ms-2"
            name="toggleMs"
            title=""
            onClick={() => setIsMs(!isMs)}
          >
            {isMs ? intl.t('form.fields.ms') : intl.t('form.fields.s')}
          </button>
        </>
      ),
      accessor: 'timestamp',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 260,
      width: 260,
      maxWidth: 260,
      Cell: ({ cell }) => (
        <UpdatedInfo
          date={cell.value}
          withMillis={isMs}
          withSecond
        />
      ),
    },
    {
      Header: intl.t('streamJobLogs.form.fields.message'),
      disableSortBy: true,
      accessor: 'message',
      minWidth: 300,
      Cell: ({ cell }) => (cell.value),
    },
    {
      Header: intl.t('streamJobLogs.form.fields.streamJob'),
      accessor: 'streamJobId',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 220,
      width: 220,
      maxWidth: 220,
      Cell: ({ cell }) => (
        <CellStreamJob
          streamJobId={cell.value}
          onFilter={onFilter}
        />
      ),
    },
    {
      Header: intl.t('streamJobLogs.form.fields.logSource'),
      accessor: 'logSource',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 150,
      width: 150,
      maxWidth: 150,
      Cell: ({ cell }) => (
        <CellLogSource
          logSource={cell.value}
          onFilter={onFilter}
        />
      ),
    },
    {
      Header: intl.t('streamJobLogs.form.fields.user'),
      accessor: 'userEmail',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 200,
      width: 200,
      maxWidth: 200,
      Cell: ({ cell }) => (
        <CellUserEmail
          userEmail={cell.value}
          onFilter={onFilter}
        />
      ),
    },
  ], [ intl, onFilter, isMs ])

  return (
    <>
      <StaticTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={false}
        {...props}
      />

      <Pagination {...props} relative />
    </>
  )
}
