import PropTypes from 'prop-types'
import classnames from 'classnames'
import { DateTime } from 'luxon'
import { duration } from '@wiz/utils'
import DateRelative from '@/components/DateRelative'

export default function Duration ({ className, data }) {
  const from = DateTime.fromMillis(data.from)
  const to = data.to ? DateTime.fromMillis(data.to) : null

  return (
    <span className={classnames('d-flex align-items-center min-w-0', className)}>
      {from && to ? <b className="me-2">{duration(to.toMillis() - from.toMillis())}</b> : null}
      <span className="text-truncate min-w-0">
        {DateTime.fromMillis(data.from).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
      </span>
      <sup className="ms-1">
        <DateRelative value={data.from} future />
      </sup>
    </span>
  )
}

Duration.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    from: PropTypes.number.isRequired,
    to: PropTypes.number,
  }).isRequired,
}

Duration.defaultProps = {
  className: '',
}
