import { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormControl } from '@wiz/components'
import { dbProvider, IExplorer } from '@wiz/store'
import {
  clone,
  orderBy,
  toArrayValue,
} from '@wiz/utils'
import DataViewExplorer from '@/components/Form/DataViewExplorer'
import DataSourceExplorer from '@/containers/Form/DataSourceExplorer'

function updateDataViewsSort (data) {
  return orderBy(data || [], [ 'sort' ], [ 'asc' ])
    .map((item, idx) => ({ ...item, sort: idx * 10 }))
}

export default function SectionData ({ scope }) {
  const { watch, setValue } = useFormContext()
  const [
    dataSources,
    dataViews,
    dataFilter,
    conditions,
    eventSources,
  ] = watch([
    `${scope}.dataSources`,
    `${scope}.dataViews`,
    `${scope}.dataFilter`,
    `${scope}.conditions`,
    `${scope}.eventSources`,
  ])

  const cloneDataViews = useMemo(() => clone(dataViews), [ dataViews ])

  const handleCreateSource = useCallback(async (data) => {
    let nextSources = []
    let nextViews = []

    if (data) {
      const source = await IExplorer.createDataSourceContext(
        dbProvider.database,
        data,
      )
      const view = await IExplorer.createDataViewContext(
        dbProvider.database,
        { sourceId: source.id, source },
      )
      nextSources = dataSources.concat(source)
      nextViews = dataViews.concat(view)
    } else {
      const viewSources = dataViews.reduce((out, item) => (
        item.sourceId ? out.concat(item.sourceId) : out
      ), [])
      nextSources = dataSources.filter(item => !viewSources.includes(item.id))
    }

    setValue(`${scope}.dataSources`, nextSources, { shouldDirty: true })
    setValue(`${scope}.dataViews`, updateDataViewsSort(nextViews), { shouldDirty: true })
  }, [ scope, dataSources, dataViews, setValue ])

  const handleRemoveView = useCallback((view) => {
    const {
      id,
      sort,
      parentId,
      sourceId,
    } = view

    const nextViews = dataViews
      .filter(item => item.id !== id)
      .map(item => (item.parentId === id ? {
        ...item,
        sort,
        parentId,
      } : item))

    const nextEventSources = eventSources
      .map(item => ({
        ...item,
        dataViews: item.dataViews.filter(dataViewId => dataViewId !== id),
      }))

    if (sourceId) {
      const nextSources = dataSources.filter(item => item.id !== sourceId)
      setValue(`${scope}.dataSources`, nextSources, { shouldDirty: true })
    }

    setValue(`${scope}.dataViews`, updateDataViewsSort(nextViews), { shouldDirty: true })
    setValue(`${scope}.eventSources`, nextEventSources, { shouldDirty: true })
  }, [ scope, dataSources, dataViews, eventSources, setValue ])

  const handleUpdateView = useCallback(async (data) => {
    const nextViews = toArrayValue(data)
      .reduce((out, item) => ({
        ...out,
        [item.id]: item,
      }), {})

    const next = []

    for (const item of dataViews) {
      if (nextViews[item.id]) {
        const nextView = nextViews[item.id]
        delete nextViews[item.id]
        const view = await IExplorer.createDataViewContext(
          dbProvider.database,
          { ...item, ...nextView },
        )
        next.push(view)
      } else {
        next.push(item)
      }
    }

    for (const item of Object.values(nextViews)) {
      const view = await IExplorer.createDataViewContext(dbProvider.database, item)
      next.push(view)
    }

    setValue(`${scope}.dataViews`, updateDataViewsSort(next), { shouldDirty: true })
  }, [ scope, dataViews, setValue ])

  const handleUpdateSource = useCallback(async (data) => {
    const source = await IExplorer.createDataSourceContext(dbProvider.database, data)

    const nextSources = dataSources
      .map(item => (item.id === source.id ? source : item))

    const nextViews = []
    for (const item of dataViews) {
      if (item.sourceId === source.id) {
        const view = await IExplorer.createDataViewContext(
          dbProvider.database,
          { ...item, source },
        )
        nextViews.push(view)
      } else {
        nextViews.push(item)
      }
    }

    const nextConditions = conditions
      .reduce((out, item) => {
        const next = { ...item }
        if (next.inputDataSources) {
          next.inputDataSources = next.inputDataSources
            .map(ds => (ds.id === source.id ? source : ds))
        }
        if (next.outputDataSources) {
          next.outputDataSources = next.outputDataSources
            .map(ds => (ds.id === source.id ? source : ds))
        }
        out.push(next)
        return out
      }, [])

    setValue(`${scope}.dataViews`, nextViews, { shouldDirty: true })
    setValue(`${scope}.dataSources`, nextSources, { shouldDirty: true })
    setValue(`${scope}.conditions`, nextConditions, { shouldDirty: true })
  }, [ scope, dataViews, dataSources, conditions, setValue ])

  const handleDropView = useCallback(async (dropItem, targetItem, type) => {
    const items = []

    if (type === 'inner') {
      const sort = targetItem?.sort ?? dropItem?.sort
      let parentId = targetItem?.role === 'grid' ? targetItem.id : targetItem?.parentId

      if (!parentId) {
        const parentView = await IExplorer.createDataViewContext(
          dbProvider.database,
          { role: 'grid', sort },
        )

        items.push(parentView)
        parentId = parentView.id

        if (targetItem) {
          items.push({ ...targetItem, parentId })
        }
      }

      items.push({
        ...dropItem,
        sort,
        parentId,
      })
    } else if (type === 'before') {
      items.push({
        ...dropItem,
        sort: targetItem.sort - 1,
        parentId: dropItem.parentId !== targetItem.parentId ?
          targetItem.parentId :
          dropItem.parentId,
      })
    } else if (type === 'after') {
      items.push({
        ...dropItem,
        sort: targetItem.sort + 1,
        parentId: dropItem.parentId !== targetItem.parentId ?
          targetItem.parentId :
          dropItem.parentId,
      })
    }

    if (items.length) {
      handleUpdateView(items)
    }
  }, [ handleUpdateView ])

  const sensorIds = useMemo(() => (
    dataSources
      .filter(item => item.sensorId)
      .map(item => item.sensorId)
  ), [ dataSources ])

  return (
    <div className="flex-fill d-flex flex-column mt-2">
      <FormControl type="any" name={`${scope}.dataSources`} />
      <FormControl type="any" name={`${scope}.dataViews`} />

      <div className="flex-fill d-flex flex-column">
        <DataSourceExplorer
          value={sensorIds}
          batchOnly={dataFilter?.stepType === 'batch'}
          onChange={handleCreateSource}
        />
      </div>

      <div className="flex-fill d-flex flex-column pt-2">
        <DataViewExplorer
          options={cloneDataViews}
          onRemoveView={handleRemoveView}
          onChangeView={handleUpdateView}
          onChangeSource={handleUpdateSource}
          onCreateSource={handleCreateSource}
          onDrop={handleDropView}
        />
      </div>
    </div>
  )
}
