import FormDialog from '@/components/Form/FormDialog'
import WidgetSection from '../Widget/Section'

export default function WidgetRoiProject ({
  defaultValues,
  dialog,
  onClose,
  onSubmit,
}) {
  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="widgetRoiProjectForm"
      dialog={dialog}
    >
      <WidgetSection
        scope="widget"
      />
    </FormDialog>
  )
}
