import enhanceEthplorer from '@/containers/DataExplorer/enhanceEthplorer'
import enhanceExplorerContext from '@/containers/DataExplorer/enhanceExplorerContext'
import enhanceContext from '@/containers/DataExplorer/enhanceContext'
import enhanceRunContext from '@/containers/DataExplorer/enhanceRunContext'
import Component from '@/components/DataExplorer/View'
import enhanceEnv from './enhanceEnv'
import enhanceContextInit from './enhanceContextInit'

export default enhanceEthplorer(
  enhanceEnv(
    enhanceExplorerContext(
      enhanceContext(
        enhanceRunContext(
          enhanceContextInit(
            Component,
          ),
        ),
      ),
    ),
  ),
)
