import { useContext } from 'react'
import classnames from 'classnames'
import { Icon, OverflowCenter } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { Explorer } from '@wiz/store'
import { useAuth } from '@/auth'
import DataExplorerEnv from '@/context/DataExplorerEnv'
import Observe from '@/containers/Observe'
import DateRelative from '@/components/DateRelative'
import Link from '@/components/Link'

export default function Option ({
  active,
  data,
  context,
}) {
  const intl = useIntl()
  const auth = useAuth()
  const { ExplorerListComponent } = useContext(DataExplorerEnv)
  const canRemove = auth.checkAccessRemove(data)
  const canUpdate = auth.checkAccessUpdate(data)
  const { onAction, search } = context

  if (!Explorer.is(data)) {
    return (
      <div className="d-flex flex-column min-w-0 h-100">
        <div
          className={classnames('fs-5 lh-1 text-truncate', {
            'my-auto': !data.twinName,
          })}
        >
          {intl.t(data.name)}
        </div>

        {data.twinName ? (
          <OverflowCenter
            className="fw-bold"
            value={data.twinName}
          />
        ) : null}
      </div>
    )
  }

  return (
    <>
      <div className="d-flex flex-column flex-fill min-w-0">
        <div className="d-flex min-w-0">
          {ExplorerListComponent ? (
            <ExplorerListComponent
              value={data}
              search={search}
              onClick={() => onAction('close')}
            />
          ) : (
            <Link
              name="data-observation"
              params={{ id: data.id }}
              className="d-inline-flex min-w-0 text-reset"
              onClick={() => onAction('view')}
            >
              {data.title ? (
                <OverflowCenter
                  value={data.title}
                  mark={search}
                />
              ) : intl.t('form.noname')}
            </Link>
          )}
        </div>

        <div className="d-flex text-nowrap fw-light">
          <Observe query={data.createdUser}>
            {({ data: user }) => (
              <span className="text-truncate me-2">{user?.name}</span>
            )}
          </Observe>

          <DateRelative value={data.updatedAt || data.createdAt} />
        </div>
      </div>

      {canRemove ? (
        <button
          type="button"
          className={classnames('btn btn-flat-secondary p-2 ms-1 hover-opacity', {
            'text-reset': active,
          })}
          data-testid="deleteView"
          onClick={() => onAction('remove', data.id)}
        >
          <Icon name="fa--trash-alt" />
        </button>
      ) : null}

      {canUpdate ? (
        <button
          type="button"
          className={classnames('btn btn-flat-secondary p-2 ms-1 hover-opacity', {
            'text-reset': active,
          })}
          data-testid="editView"
          onClick={() => onAction('edit', data.id)}
        >
          <Icon name="fa--edit" />
        </button>
      ) : null}
    </>
  )
}
