import {
  useRef,
  useCallback,
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react'
import { Dialog } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { Prompt } from '@/router'
import Content from './Content'

const FormDialog = forwardRef(({
  dialog,
  onClose,
  disabled,
  ...props
}, ref) => {
  const refDialog = useRef()
  const intl = useIntl()
  const [ isDirty, setIsDirty ] = useState(false)

  const handlePrompt = useCallback(async () => {
    if (isDirty && !disabled) {
      await window.wizConfirm({ message: 't/form.confirmClose' })
    }
  }, [ isDirty, disabled ])

  useImperativeHandle(ref, () => ({
    close () {
      refDialog.current.close()
    },
  }), [])

  const showAlert = useCallback((e) => {
    e.preventDefault()
    e.returnValue = ''
  }, [])

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      if (isDirty && !disabled) {
        window.addEventListener('beforeunload', showAlert)
      }

      return () => {
        window.removeEventListener('beforeunload', showAlert)
      }
    }
  }, [ isDirty, showAlert, disabled ])

  return dialog ? (
    <Dialog
      {...dialog}
      ref={refDialog}
      classNameBody="p-0 bg-light-alt"
      onClose={onClose}
      onPrompt={handlePrompt}
      strategy="absolute"
    >
      <Content
        disabled={disabled}
        onClose={() => refDialog.current.close()}
        setIsDirty={setIsDirty}
        {...props}
      />

      <Prompt
        when={isDirty}
        message={intl.t('form.confirmClose')}
      />
    </Dialog>
  ) : (
    <Content
      disabled={disabled}
      onClose={onClose}
      {...props}
    />
  )
})

export default FormDialog
