import { useCallback } from 'react'
import capitalize from 'lodash/capitalize'
import { dbProvider } from '@wiz/store'
import { useTheme } from '@/theme'
import Icon from '@/shared/icon'

export default function ButtonTheme ({ label }) {
  const theme = useTheme()
  const textLabel = theme.name === 'sun' ? `Dark ${label}` : `Light ${label}`

  const handleChangeTheme = useCallback(async () => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareReplaceSetting(context, 'themeId', theme.opposite)
    await dbProvider.batch(context)
  }, [ theme ])

  return (
    <button
      name="changeTheme"
      type="button"
      className="btn btn-text btn-flat-secondary py-2 px-3"
      onClick={handleChangeTheme}
    >
      <Icon type="solid" name={`fa${capitalize(theme?.opposite)}`} size="lg" />
      {label ? textLabel : null}
    </button>
  )
}
