import { forwardRef } from 'react'
import { Card, withResizeObserver } from '@wiz/components'
import { useAuth } from '@/auth'
import { useIntl } from '@wiz/intl'
import DeviceMonitoringVisual from './DeviceMonitoringVisual'

const Widget = withResizeObserver(forwardRef(({
  widget,
  config,
  dimension,
  canUpdateDashboard,
  onAction,
  onChangePoint,
}, ref) => {
  const intl = useIntl()
  const auth = useAuth()

  const actions = []
  if (canUpdateDashboard) {
    actions.push({ id: 'settings', name: intl.t('form.actions.settings'), icon: 'fa--cog' })
    if (auth.checkAccessCopy(widget)) {
      actions.push({ id: 'duplicate', name: intl.t('form.actions.duplicate'), icon: 'fa--clone' })
      actions.push({ id: 'multiDuplicate', name: intl.t('form.actions.multiDuplicate'), icon: 'fa--folder-plus' })
    }
    actions.push({ id: 'remove', name: intl.t('form.actions.remove'), icon: 'fa--trash-alt' })
  }

  return (
    <Card
      ref={ref}
      className="position-absolute-fill"
      classNameTitle={canUpdateDashboard ? 'userdrag' : undefined}
      classNameBody="m-0"
      title={widget.title || intl.t(widget.info.name)}
      description={widget.description}
      options={actions}
      onAction={onAction}
    >
      <DeviceMonitoringVisual
        dimension={dimension}
        config={config}
        onChangePoint={onChangePoint}
      />
    </Card>
  )
}))

export default Widget
