import { map } from 'rxjs/operators'
import { of } from 'rxjs'
import { Icon, withObservables } from '@wiz/components'
import Link from '@/components/Link'
import { useIntl } from '@wiz/intl'
import { dbProvider, Q } from '@wiz/store'

const enhanceProps = withObservables([ 'experiment' ], ({ experiment }) => {
  if (!experiment?.twinId) {
    return {
      twin: of(undefined),
    }
  }

  const query = dbProvider.database.collections.get('twins').query(Q.where('id', experiment.twinId))

  return {
    twin: query.observeWithColumns([ 'updated_at' ]).pipe(map(items => items[0])),
  }
})

function CellTwin ({
  onFilter,
  twin,
}) {
  const intl = useIntl()

  if (!twin) {
    return <>-</>
  }
  return (
    <div className="min-w-0 d-flex">
      <Link
        className="min-w-0 text-truncate"
        title={twin.name}
        name="twin-items-list"
        query={{ tree: twin?.id }}
      >
        {twin.name}
      </Link>

      {onFilter ? (
        <button
          type="button"
          className="btn btn-sm p-1"
          title={intl.t('form.actions.search')}
          onClick={() => onFilter({ twinId: twin.id })}
        >
          <Icon name="fa--search" />
        </button>
      ) : null}
    </div>
  )
}

export default enhanceProps(CellTwin)
