import { useCallback, memo } from 'react'
import { withProps } from '@wiz/components'
import { EdgeDeviceCommand } from '@wiz/store'
import { wizataApi } from '@/api'
import events from '@/utils/events'
import Component from '@/components/Forms/EdgeDeviceCommand'

const enhanceProps = withProps(({ onSuccess }) => {
  const onSubmit = useCallback(async (data) => {
    try {
      const model = new EdgeDeviceCommand(data.deviceCommand)
      await wizataApi.deviceCommands.replace(model)
      events.emit('app:notify', {
        type: 'success',
        title: 't/edge.commands.title',
        message: 't/edge.commands.form.success.create',
        duration: 2000,
      })
      onSuccess?.()
    } catch (error) {
      events.emit('app:notify', {
        type: 'error',
        title: 't/edge.commands.title',
        message: error.message,
      })
      throw error
    }
  }, [ onSuccess ])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceProps(Component),
)
