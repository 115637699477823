import { Controller, useFormContext } from 'react-hook-form'
import { Range, getTrackBackground } from 'react-range'
import { FormSection, FormField, FormControl } from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import SelectSensor from '@/components/Form/SelectSensor'
import SelectDataType from '@/containers/Form/SelectDataType'
import FormatDateTime from '@/containers/FormatDateTime'

export default function Section ({ scope, ...props }) {
  const intl = useIntl()
  const { formState: { errors }, watch } = useFormContext()
  const dateFrom = watch(`${scope}.dateFrom`)
  const dateTo = watch(`${scope}.dateTo`)
  const range = watch(`${scope}.range`)

  return (
    <FormSection {...props}>
      <FormField
        label={intl.t('widgets.kpiHeatmap.form.fields.sensor')}
        errors={get(errors, `${scope}.sensorId`)}
      >
        <Controller
          name={`${scope}.sensorId`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorPlaceholder')}
              excludeBusinessTypes={consts.ExcludedBusinessTypes}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.dataType')}
        errors={get(errors, `${scope}.dataType`)}
      >
        <Controller
          name={`${scope}.dataType`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <SelectDataType
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.dataTypePlaceholder')}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.dateFrom')}
        errors={get(errors, `${scope}.dateFrom`)}
      >
        <FormControl
          type="dateTime"
          name={`${scope}.dateFrom`}
          rules={{
            validate: (value) => {
              if (value && dateTo && value > dateTo) {
                return intl.t('form.errors.dateFromOutofrange')
              }
              return true
            },
          }}
          clearable
          showTimeInput
          placeholder={intl.t('form.fields.datePlaceholder')}
          DateTimeComponent={FormatDateTime}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.dateTo')}
        errors={get(errors, `${scope}.dateTo`)}
      >
        <FormControl
          type="dateTime"
          name={`${scope}.dateTo`}
          rules={{
            validate: (value) => {
              if (value && dateFrom && value < dateFrom) {
                return intl.t('form.errors.dateToOutofrange')
              }
              if (dateFrom && !value) {
                return intl.t('form.errors.dateToOutofrange')
              }
              return true
            },
          }}
          clearable
          showTimeInput
          placeholder={intl.t('form.fields.datePlaceholder')}
          DateTimeComponent={FormatDateTime}
        />
      </FormField>

      <FormField
        label={intl.t('widgets.kpiHeatmap.form.fields.range')}
        errors={get(errors, `${scope}.range`)}
        classNameControl="d-flex align-items-center"
      >
        <Controller
          name={`${scope}.range`}
          rules={{
            validate: {
              positive: value => (
                !Number.isFinite(value) ||
                value >= 0 ||
                intl.t('form.errors.fieldMin', { min: 0 })
              ),
              lessThanOne: value => (
                !Number.isFinite(value) ||
                value <= 1000 ||
                intl.t('form.errors.fieldMax', { max: 1000 })
              ),
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Range
              step={1}
              min={0}
              max={1000}
              values={value}
              draggableTrack
              onChange={onChange}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  className="rounded-pill"
                  style={{
                    ...props.style,
                    height: '6px',
                    width: '100%',
                    background: getTrackBackground({
                      values: value,
                      colors: [ '#ccc', '#409eff', '#ccc' ],
                      min: 0,
                      max: 1000,
                    }),
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ index, props }) => (
                <div
                  {...props}
                  className="rounded-circle"
                  title={range?.[index]}
                  style={{
                    ...props.style,
                    height: '16px',
                    width: '16px',
                    backgroundColor: '#409eff',
                  }}
                />
              )}
            />
          )}
        />
      </FormField>
    </FormSection>
  )
}
