import {
  useState, useEffect, useContext, useMemo,
} from 'react'
import { withProps } from '@wiz/components'
import { wizataApi } from '@/api'
import { AppContext } from '@/pages/context'

const enhanceData = withProps(({
  filters,
}) => {
  const [ loading, setLoading ] = useState(true)
  const [ items, setItems ] = useState([])
  const [ total, setTotal ] = useState(0)

  const { filters: contextFilters } = useContext(AppContext)

  const nextFilters = useMemo(() => ({
    dateFrom: contextFilters.dateFrom ? Number(contextFilters.dateFrom) : undefined,
    dateTo: contextFilters.dateTo ? Number(contextFilters.dateTo) : undefined,
    recipient: contextFilters.recipient || undefined,
    notificationSheetName: contextFilters.notificationSheetName || undefined,
  }), [ contextFilters ])

  useEffect(() => {
    setLoading(true)

    const limit = filters.pageSize + 1
    const offset = (filters.page - 1) * filters.pageSize

    wizataApi.logs.email({
      ...nextFilters,
      limit,
      offset,
    })
      .then((data) => {
        setTotal(offset + data.length)
        setItems(data.slice(0, filters.pageSize))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [ filters.pageSize, filters.page, nextFilters ])

  return {
    total,
    items,
    loading,
  }
})

export default enhanceData
