import { useMemo } from 'react'
import { Explorer as Explorer$, ShareUserExplorer } from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import { auth } from '@/auth'
import { useIntl } from '@wiz/intl'
import Removed from '../Removed'
import Section from './Section'
import SectionProjects from './SectionProjects'
import SectionTwins from './SectionTwins'

export default function Explorer ({
  id,
  explorer,
  permissions,
  projects,
  twins,
  dialog,
  onClose,
  onSuccess,
  onSubmit,
}) {
  const canUpdateGlobalShare = explorer && auth.checkAccessUpdate(explorer, 'isGlobalShared')
  const immutablePermissions = JSON.stringify(permissions.map(item => item?.updatedAt))
  const intl = useIntl()

  const defaultValues = useMemo(() => ({
    explorer: Explorer$.toJSON(explorer),
    permissions: permissions.map(item => ShareUserExplorer.toJSON(item)),
    projects,
    twins,
  }), [
    explorer?.updatedAt ?? explorer,
    immutablePermissions,
    projects,
    twins,
  ])

  if (id && !explorer) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      onSuccess={onSuccess}
      defaultValues={defaultValues}
      dataTestid="explorerForm"
      dialog={dialog}
    >
      <Section
        scope="explorer"
        canUpdateGlobalShare={canUpdateGlobalShare}
      />

      <SectionTwins
        title={intl.t('explorer.form.fields.twins')}
        description={intl.t('explorer.form.fields.twinsDescr')}
        scope="twins"
      />

      <SectionProjects
        title={intl.t('form.fields.projects')}
        scope="projects"
      />
    </FormDialog>
  )
}
