import {
  useCallback, useState, useRef,
} from 'react'
import cx from 'classnames'
import { useRouter } from '@/router'
import { Dropdown, useMobile, withObservables } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import { isEmpty } from '@wiz/utils'
import { dbProvider } from '@wiz/store'
import s from './index.css'

const enhaceProps = withObservables([], () => ({
  featureFlags: dbProvider.observeFlags([ 'AdvancedDigitalTwinExperience' ]),
}))

const Items = ({
  actions, favorites, onClick, ...props
}) => {
  const intl = useIntl()
  const isMobile = useMobile()

  return (
    <>
      {actions?.length ? actions.map(({
        action, name, path, italic, id, attr, component, icon, 
      }) => (
        component
          ? component 
          : <MenuItem
              id={attr?.id}
              className={cx({ 'fst-italic': italic, 'ms-2': isMobile })}
              onClick={() => onClick(id, action, path)}
              key={name}
            >
              {icon && icon}
              {intl.t(name)}
            </MenuItem>
      )) : null}
      {favorites?.length ? <hr className={cx('my-0', { 'mx-3': isMobile })} /> : null}
      {favorites?.map(({
        twinGraph, dashboard, route, id,
      }) => {
        const favorite = twinGraph || dashboard

        return (
          <MenuItem
            id={props.attr.id}
            onClick={() => onClick(null, null, route)}
            className={cx({ 'ms-2': isMobile })}
            key={id}
          >
            {intl.t(favorite.name || favorite.title)}
          </MenuItem>
        )
      })}
    </>
  )
}

const MenuItem = enhaceProps(({
  onAction, children, actions, idx,
  className, onClick, instance, options,
  onClose, attr, featureFlags, ...props
}) => {
  const [ openId, setOpenId ] = useState('')
  const ref = useRef()
  const isMobile = useMobile()
  const auth = useAuth()
  const router = useRouter()

  const handleClick = useCallback((id, action, path) => {
    if (path) {
      const p = (typeof path === 'string') ? { name: path } : path
      router.push(p)
    } else if (action && id) {
      onAction?.(id, action)
    }
    onClose?.()
  }, [ onAction, router, onClose ])

  const favorites = instance ? options?.filter(item => !!item[instance]) : null

  const active = openId === props.id

  // FIXME: remove if not neccessary
  const _actions = actions?.filter(item => !!(
    (item.path || item.action) && !item.disabled &&
    (
      isEmpty(item.access) ||
      item.access.every(rule => auth.checkAccessRules(rule[1], rule[0]))
    )))

  if (Array.isArray(_actions) && !_actions.length) {
    return null
  }

  if (!featureFlags.AdvancedDigitalTwinExperience && attr?.id === 'interactive') {
    return null
  }

  return (
    <>
      <div
        id={attr?.id || props.id}
        className={cx('py-2 px-3 d-flex align-items-center', className, s.item)}
        ref={ref}
        role={!_actions ? 'menuitem' : undefined}
        tabIndex={idx}
        aria-hidden="true"
        onClick={() => {
          if (onClick) {
            onClick()
          } else if (isMobile && _actions && !onClick) {
            if (active) {
              setOpenId('')
            } else {
              setOpenId(props.id)
            }
          } else {
            !_actions && handleClick(null, null, props.route)
          }
        }}
      >
        {children}
      </div>
      {_actions ?
        isMobile && openId === props.id ? (
          <Items {...{
            favorites, actions: _actions, onClick: handleClick, attr,
          }}
          />
        ) :
          (
            <Dropdown
              arrow
              autoclose
              target={ref}
              placement="right-start"
              className={cx('py-2 text-break', s.menu)}
            >
              <Items {...{
                favorites, actions: _actions, onClick: handleClick, attr,
              }}
              />
            </Dropdown>
          ) : null}
    </>
  )
})

export { MenuItem }
