import { useState } from 'react'
import { Icon, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import QualityData from '@/hoc/QualityData'
import FormQualityData from '@/containers/Forms/QualityData'
import FormQualityDataBulkEdit from '@/containers/Forms/QualityDataBulkEdit'
import Table from '@/containers/QualityDataList/Table'
import TableMobile from '@/containers/QualityDataList/TableMobile'
import Filters from './Filters'
import ViewModel from './ViewModel'

const ViewModelData = QualityData(ViewModel)

export default function List ({
  filters,
  selected,
  onFilter,
  onResetFilter,
  onRemove,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const isMobile = useMobile()
  const [ editId, setEditId ] = useState(null)
  const [ bulkEdit, setBulkEdit ] = useState(false)
  const [ viewId, setViewId ] = useState(null)

  const TableComponent = isMobile ? TableMobile : Table

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">

      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <div className="d-flex align-items-center flex-wrap">
          <div className="px-sm-2 mb-2 flex-shrink-0">
            {auth.checkAccessCreate('QualityData') ? (
              <button
                type="button"
                className="btn btn-flat-secondary"
                onClick={() => setEditId('')}
              >
                <Icon name="fa--plus" className="me-1" />
                {intl.t('form.actions.add')}
              </button>
            ) : null}
            {auth.checkAccessUpdate('QualityData') ? (
              <button
                type="button"
                className="btn btn-flat-secondary"
                disabled={!selected.length}
                onClick={() => setBulkEdit(true)}
              >
                <Icon name="fa--edit" />
                <span className="d-none d-sm-inline ms-1">
                  {intl.t('form.actions.bulkEdit')}
                </span>
              </button>
            ) : null}
            {auth.checkAccessRemove('QualityData') ? (
              <button
                type="button"
                className="btn btn-flat-secondary"
                disabled={!selected.length}
                onClick={() => onRemove(selected)}
              >
                <Icon name="fa--trash-alt" />
                <span className="d-none d-sm-inline ms-1">
                  {intl.t('form.actions.remove')}
                </span>
              </button>
            ) : null}
          </div>

          <Filters
            filters={filters}
            onFilter={onFilter}
            onResetFilter={onResetFilter}
          />
        </div>

        <TableComponent
          {...props}
          filters={filters}
          onFilter={onFilter}
          onRemove={onRemove}
          onEdit={setEditId}
          onView={setViewId}
        />
      </div>

      {do {
        if (bulkEdit) {
          (
            <FormQualityDataBulkEdit
              qualityData={selected}
              onClose={() => setBulkEdit(false)}
              dialog={{
                title: intl.t('quality.titleBulkEdit'),
                dataTestid: 'bulkEditQualityDataDialog',
              }}
            />
          )
        } else if (editId !== null) {
          (
            <FormQualityData
              id={editId}
              onClose={() => setEditId(null)}
              dialog={{
                title: editId ?
                  intl.t('quality.titleUpdate') :
                  intl.t('quality.titleCreate'),
                dataTestid: 'replaceQualityDataDialog',
              }}
            />
          )
        } else if (viewId) {
          (
            <ViewModelData
              id={viewId}
              title={intl.t('quality.titleDetail')}
              onClose={() => setViewId(null)}
              onFilter={onFilter}
              onRemove={onRemove}
              onEdit={setEditId}
              dialog
            />
          )
        }
      }}
    </div>
  )
}
