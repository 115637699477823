import { useFormContext } from 'react-hook-form'
import classnames from 'classnames'
import { FormSection, FormFieldInline } from '@wiz/components'
import { get, has } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function Section ({ scope, title, description }) {
  const intl = useIntl()
  const { register, formState: { errors } } = useFormContext()
  // const viewType = watch(`${scope}.viewType`)

  return (
    <FormSection
      title={title}
      description={description}
    >
      {/* <FormField
        label={intl.t('widgets.events.form.fields.viewType')}
        description={
          <>
            <div>{intl.t('widgets.events.form.fields.viewTypeDescr')}</div>
            <b>
              {viewType === 'table' ? intl.t('widgets.events.form.fields.viewTypeTableDescr') :
              viewType === 'timeline' ? intl.t('widgets.events.form.fields.viewTypeTimelineDescr') : null}
            </b>
          </>
        }
        errors={get(errors, `${scope}.viewType`)}
      >
        <FormSelect
          {...register(`${scope}.viewType`)}
          options={[
            { name: intl.t('enum.eventWidgetViewType.table'), id: 'table', },
            { name: intl.t('enum.eventWidgetViewType.timeline'), id: 'timeline', },
          ]}
        />
      </FormField> */}

      <FormFieldInline
        label={intl.t('widgets.events.form.fields.displaySearchBar')}
        description={intl.t('widgets.events.form.fields.displaySearchBarDescr')}
        errors={get(errors, `${scope}.displaySearchBar`)}
      >
        <input
          {...register(`${scope}.displaySearchBar`)}
          type="checkbox"
          className={classnames('form-check-input mt-0 ms-2 flex-shrink-0', {
            'is-invalid': has(errors, `${scope}.displaySearchBar`),
          })}
        />
      </FormFieldInline>

    </FormSection>
  )
}
