import { Controller } from 'react-hook-form'
import { useIntl } from '@wiz/intl'
import Section from '@/components/Forms/Formula/Section'
import Select from '@/components/Form/Select'
import FormulaList from '@/hoc/FormulaList'

export default function SectionFormula ({ scope }) {
  const intl = useIntl()

  return (
    <Section
      scope={scope}
      registerId={false}
      title={intl.t('blockFormula.section.formula')}
      description={intl.t('blockFormula.section.formulaDescr')}
      addon={() => (
        <Controller
          name={`${scope}.id`}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              ListSource={FormulaList}
              placeholder={intl.t('form.fields.formulaPlaceholder')}
              className="min-w-0 w-100 w-md-auto mt-2 mt-md-0"
            />
          )}
        />
      )}
    />
  )
}
