import { useMemo } from 'react'
import {
  VirtualTable,
  Pagination,
  Icon,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import UpdatedInfo from '@/components/Form/UpdatedInfo'

export default function TableMobile ({
  items,
  onView,
  ...props
}) {
  const intl = useIntl()
  const columns = useMemo(() => [
    {
      Header: intl.t('form.fields.message'),
      accessor: 'message',
      Cell: ({ row, cell }) => (
        <div className="min-w-0 text-truncate">
          {cell.value}
          <div className="font-italic text-muted text-truncate small">
            <UpdatedInfo date={row.original.timestamp} withSecond withMillis />
          </div>
        </div>
      ),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 40,
      width: 40,
      maxWidth: 40,
      className: 'justify-content-end text-nowrap',
      Cell: ({ row }) => (
        <button
          type="button"
          className="btn btn-sm p-2"
          onClick={() => onView?.(row.original)}
        >
          <Icon name="fa--chevron-right" />
        </button>
      ),
    },
  ], [
    onView,
    intl,
  ])

  return (
    <>
      <VirtualTable
        className="flex-fill"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        showHeader={false}
        {...props}
      />

      <Pagination
        {...props}
        className="mt-3"
        pageSizeNavigation={false}
        showTotal={false}
        relative
      />
    </>
  )
}
