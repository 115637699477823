import { useCallback, useMemo } from 'react'
import { DiagramBlock, DiagramBlockInput } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import BlockSection from '../Block/Section'
import Section from './Section'
import Schema from './Schema'
import resolver from './resolver'

export default function BlockInput ({
  block,
  settings,
  dialog,
  onClose,
  onSubmit,
}) {
  const intl = useIntl()
  const defaultValues = useMemo(() => {
    const data = DiagramBlockInput.toJSON(settings)
    if (data.inputType === 'constant') {
      data.value = JSON.stringify(data.value)
    }

    return {
      block: DiagramBlock.toJSON(block),
      settings: data,
    }
  }, [
    block?.updatedAt ?? block,
    settings?.updatedAt ?? settings,
  ])

  const handleSubmit = useCallback((data) => {
    data = {
      ...data,
      settings: {
        sensorId: null,
        aggregationType: null,
        duration: null,
        dateFrom: null,
        dateTo: null,
        amount: null,
        value: null,
        includeTimestamp: false,
        ...data.settings,
      },
    }

    if (data.settings.inputType === 'constant') {
      try {
        data.settings.value = JSON.parse(data.settings.value)
      } catch (error) {
        // empty
      }
    }

    onSubmit?.(data)
  }, [ onSubmit ])

  return (
    <FormDialog
      onSubmit={handleSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      schema={Schema}
      resolver={resolver}
      dataTestid="blockInputForm"
      dialog={dialog}
    >
      <BlockSection scope="block" />
      <Section scope="settings" title={intl.t('form.sections.input')} />
    </FormDialog>
  )
}
