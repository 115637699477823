import { useMemo } from 'react'
import { useIntl } from '@wiz/intl'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import { useAuth } from '@/auth'
import withTableActions from '@/containers/withTableActions'
import {
  VirtualTable, Pagination, withPagination, withSort,
} from '@wiz/components'
import Icon from '@/shared/icon'
import CellTwin from './cellTwin'
import useAppContext from '../../../hooks/useAppContext'
import EditButton from '../../../shared/editButton'

const TableComponent = withSort({ localData: false })(
  withPagination({ localData: false })(VirtualTable),
)

const List = ({
  id,
  onEdit,
  list,
  error,
  isError,
  isUpdating,
  ...props
}) => {
  const { onRowSelect } = useAppContext()

  const auth = useAuth()
  const intl = useIntl()

  const columns = useMemo(() => [
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      width: 30,
      maxWidth: 30,
      className: 'justify-content-center text-nowrap',
      Cell: ({ row }) => (auth.checkAccessUpdate('Template') ? (
        <EditButton onChange={() => onEdit(row.original)} />
      ) : null),
    },
    {
      Header: intl.t('form.fields.name'),
      accessor: 'twinId',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 200,
      Cell: ({ cell }) => (<CellTwin twinId={cell.value} />),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedDate',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.updatedById}
        />
      ),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdDate',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.createdById}
        />
      ),
    },
  ], [ onEdit, intl, auth ])

  if (isError) {
    return (
      <div className="position-center-fill flex-column h-100">
        <h4>{intl.t('registrations.loadingError')}</h4>
        <p className="text-danger">{error.message}</p>
        {/* <button
          type="button"
          className="btn btn-fill-secondary"
          onClick={refetch}
        >
          {intl.t('form.actions.refresh')}
        </button> */}
      </div>
    )
  }

  return (
    <>
      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <TableComponent
          className="flex-fill mb-3"
          placeholder={intl.t('errors.noDataDisplay')}
          columns={columns}
          data={list}
          items={list}
          {...props}
          selection={auth.checkAccessManage('SectionTemplates')}
          onSelect={onRowSelect}
        />

        <Pagination total={list?.length} pageSize={100} pageSizeNavigation={false} items={list} {...props} relative />

      </div>
      {isUpdating ? (
        <div className="position-absolute-fill position-center-fill bg-light opacity-50">
          <Icon name="faSpinner" spin />
        </div>
      ) : null}

    </>
  )
}

export default withTableActions({
  initFilters () {
    return {}
  },
})(
  List,
)
