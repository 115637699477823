import { useRef } from 'react'
import { Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'

export default function ViewModel ({
  title,
  sentEmailLog,
  onClose,
  onFilter,
}) {
  const refDialog = useRef()
  const intl = useIntl()

  const handleFilter = (filter) => {
    refDialog.current.close()
    onFilter?.(filter)
  }

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('form.fields.recipient'),
          value: () => (
            <>
              <button
                type="button"
                className="btn btn-sm btn-flat-secondary me-2"
                onClick={() => handleFilter({ recipient: sentEmailLog.recipient })}
              >
                <Icon name="fa--search" />
              </button>
              <span className="text-truncate user-select-all">{sentEmailLog.recipient}</span>
            </>
          ),
        },
        {
          title: intl.t('form.fields.message'),
          value: sentEmailLog.message,
        },
        {
          title: intl.t('form.fields.status'),
          value: sentEmailLog.status,
        },
        {
          title: intl.t('form.fields.recipientList'),
          value: sentEmailLog.recipientList,
        },
        {
          title: intl.t('form.fields.updated'),
          value: () => (<UpdatedInfo date={sentEmailLog.updatedDate} />),
        },
        {
          title: intl.t('form.fields.created'),
          value: () => (<UpdatedInfo date={sentEmailLog.createdDate} withMillis withSecond />),
        },
      ],
    },
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      onClose={onClose}
    />
  )
}
