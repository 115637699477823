import { Fragment } from 'react'
import {
  FormSelectNative,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'

const Sensitivity = ({
  value, onChange, options,
}) => {
  const intl = useIntl()
  return (
    <div className="d-flex flex-fill flex-column px-3">
      <div className="d-flex flex-wrap mt-2 m-1">
        <b>Please set the experiment properties:</b>
      </div>

      <div className="d-flex flex-column flex-wrap m-1">
        <div className="flex-fill my-2">Sensitivity:</div>

        {/* {options.map((id, index) => (
          <Fragment key={id}>
            <input
              id={id}
              type="radio"
              name="sensitivity"
              value={id}
              className="btn-check"
              onChange={() => onChange(id)}
              checked={value === id}
            />
            <label className="btn btn-fill-secondary justify-content-center mb-1" htmlFor={id}>
              {id}
            </label>
          </Fragment>
        ))} */}

        <FormSelectNative
          className="form-select-sm w-auto"
          defaultValue={options[2]}
          options={options.map(opt => ({ id: opt, name: opt }))}
          onChange={onChange}
        />
      </div>

    </div>
  )
}

export { Sensitivity }
