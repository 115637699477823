import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { withObservables } from '@wiz/components'

export default withObservables([ 'notebook' ], ({ notebook }) => ({
  projects: notebook ? notebook.queryRelProjects
    .observe()
    .pipe(map(items => items.map(item => item.projectId))) : of$([]),

  sensors: notebook ? notebook.queryRelSensors
    .observe()
    .pipe(map(items => items.map(item => item.sensorId))) : of$([]),
}))
