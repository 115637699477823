import { useMemo } from 'react'
import { Notebook as Notebook$ } from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import Section from './Section'

export default function Notebook ({
  dialog,
  onClose,
  onSubmit,
}) {
  const defaultValues = useMemo(() => ({
    notebook: Notebook$.toJSON(),
    projects: [],
    sensors: [],
  }), [])

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="existingNotebookForm"
      dialog={dialog}
    >
      <Section
        scope="notebook"
      />
    </FormDialog>
  )
}
