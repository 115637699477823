import { memo } from 'react'
import Component from '@/components/Forms/TwinGraph'
import enhanceProps from './enhanceProps'
import enhanceTwinGraph from './enhanceTwinGraph'
import enhanceTwinGraphData from './enhanceTwinGraphData'

export default memo(
  enhanceTwinGraph(
    enhanceTwinGraphData(
      enhanceProps(Component),
    ),
  ),
)
