import { Controller, useFormContext } from 'react-hook-form'
import { FormFieldInline } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import SelectTwin from '@/components/Form/SelectTwin'

export default function FieldsTwins ({ scope, templateId }) {
  const intl = useIntl()
  const { watch, formState: { errors } } = useFormContext()
  const selectedTemplateId = watch('experiment.templateId')

  return (
    <FormFieldInline
      errors={get(errors, scope)}
    >
      <Controller
        name={scope}
        render={({ field, fieldState }) => (
          <SelectTwin
            {...field}
            invalid={fieldState.invalid}
            placeholder={intl.t('form.fields.twinPlaceholder')}
            // multiselect
            templateId={templateId || selectedTemplateId}
          />
        )}
      />
    </FormFieldInline>
  )
}
