import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import {
  FormField,
  FormControl,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { get } from '@wiz/utils'

export default function FieldsLimit ({ scope }) {
  const intl = useIntl()
  const { formState: { errors }, watch, clearErrors } = useFormContext()
  const limitMin = watch(`${scope}.limitMin`)
  const limitMax = watch(`${scope}.limitMax`)

  useEffect(() => {
    clearErrors([
      `${scope}.limitMin`,
      `${scope}.limitMax`,
    ])
  }, [ scope, limitMin, limitMax, clearErrors ])

  return (
    <>
      <FormField
        label={intl.t('bulletPlotSource.form.fields.limitMin')}
        description={intl.t('bulletPlotSource.form.fields.limitMinDescr')}
        errors={get(errors, `${scope}.limitMin`)}
      >
        <FormControl
          type="number"
          name={`${scope}.limitMin`}
          step={1}
          scale={8}
          clearable
        />
      </FormField>

      <FormField
        label={intl.t('bulletPlotSource.form.fields.limitMax')}
        description={intl.t('bulletPlotSource.form.fields.limitMaxDescr')}
        errors={get(errors, `${scope}.limitMax`)}
      >
        <FormControl
          type="number"
          name={`${scope}.limitMax`}
          step={1}
          scale={8}
          clearable
        />
      </FormField>
    </>
  )
}

FieldsLimit.propTypes = {
  scope: PropTypes.string.isRequired,
}
