import { useState, useRef, useMemo } from 'react'
import { of } from 'rxjs'
import cx from 'classnames'
import {
  Skeleton, withObservables, DropdownMenu, Icon as LegacyIcon, CustomScrollbars, FormCheckButton,
} from '@wiz/components'
import { useRouter, useLocationQuery } from '@/router'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import { dbProvider, Q } from '@wiz/store'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import useAuthComponents from '@/components/GrafanaDashboards/hooks'
import Icon from '@/shared/icon'
import useAppContext from '@/hooks/useAppContext'
import placeholderImg from './placeholder-image.png'
import s from './index.module.css'

const dashboardTypes = [
  {
    value: 'grafana',
    name: 'Grafana',
    id: 'grafana',
  },
  {
    value: 'dashboard',
    name: 'Dashboard',
    id: 'dashboard',
  },
  {
    value: 'iframe',
    name: 'Iframe',
    id: 'iframe',
  },
  {
    value: 'streamlit',
    name: 'Streamlit',
    id: 'streamlit',
  },
]

const CardView = ({ twins, onChange }) => {
  const query = useLocationQuery()
  // FIXME: remove when new api migration done
  return twins?.map((a) => {
    if (query.search && !a.name.toLowerCase().includes(query.search?.toLowerCase())) {
      return null
    }
    return (
      <div
        key={a.id}
        className={cx('card me-2 mb-2', s.card)}
        onClick={() => onChange(a.id)}
        aria-hidden
      >
        <div className={cx('position-relative', s.cardImg)}>
          <img src={a.image || placeholderImg} className="card-img-top" alt={a.name} />
          <div className={cx('card-action-open position-absolute', s.hover)}>Open</div>

        </div>
        <div className="card-body">
          <div className="card-title">{a.name}</div>
        </div>
      </div>
    )
  })
}

const enhance = withObservables([ ], () => ({
  settings: dbProvider.observeGlobalSettings([ 'EnvironmentColor' ]),
}))

const Cards = enhance(CardView)

const TilesView = ({
  components, deepList, list, twins,
}) => {
  const refMenu = useRef()
  const refMenuTarget = useRef()

  const {
    handleAdd,
    labels,
    isShowChildren,
    onComponentsRefetch,
    grafanaCheckData,
  } = useAppContext()

  const {
    contextTwinId,
    handleTwinChange,
    setSelectedComponent,
  } = useGlobalExecute()

  const router = useRouter()
  const query = useLocationQuery()
  const { currentUser } = useAuth()
  const { isReadOnly } = useAuthComponents()
  const intl = useIntl()

  const handleChange = (id) => {
    // router.push({query: { category: 'search' }})
    handleTwinChange(id)
  }

  const handleDashboardClick = (twinId, dashboard, queryOptions) => {
    handleTwinChange(twinId)
    setSelectedComponent(prev => ({ ...prev, category: queryOptions.category, dashboard }))
    router.push({ query: { ...query, ...queryOptions } })
  }

  const options = useMemo(() => {
    const data = components?.data?.reduce((acc, val) => {
      const subCat = acc.find(item => item.twinId === val.twinId)
      if (!subCat) {
        acc.push(val)
      }

      return acc
    }, [])
    if (data?.length) {
      const twinIds = data.map(cmp => cmp.twinId).filter(i => !!i && i !== contextTwinId)
      if (!contextTwinId) {
        return twins
      }

      return twins?.filter((twin) => {
        const foundTwin = twinIds.includes(twin.id)
        if (!foundTwin) {
          return false
        }
        if (query.search) {
          const match = twin.name?.toLowerCase()?.includes(query.search.toLowerCase())
          if (!match) {
            return false
          }
        }
        return foundTwin
      })
    }

    return []
  }, [ components, contextTwinId, twins, query.search ])
  const filteredOptions = useMemo(() => components?.data?.filter((item) => {
    if (query.view === 'personal') {
      return item.ownerId === currentUser.id
    } if (query.view === 'org') {
      return !item.ownerId
    }
    return item.ownerId === currentUser.id || !item.ownerId
  }), [ components?.data, query.view ])

  const categories = useMemo(
    () => filteredOptions?.reduce((acc, val) => {
      const label = labels?.find(l => l.id === val.labelId)

      const cat = acc.find(item => item.labelId === val.labelId)
      const isChild = val?.twinId === contextTwinId

      if (!cat && isChild) {
        acc.push({
          ...val,
          label: label?.name,
          value: val.id,
          icon: label?.icon,
          order: label?.order,
          color: label?.color,
        })
      }

      return acc
    }, []).sort((a, b) => a.order - b.order) || [],
    [ filteredOptions, labels, contextTwinId ],
  )

  const dashboards = useMemo(() => filteredOptions?.reduce((acc, val) => {
    const isChild = val?.twinId === contextTwinId
    if (!val.twinId || !isChild) {
      return acc
    }
    if (query.search) {
      const match = val.name?.toLowerCase()?.includes(query.search.toLowerCase())
      if (!match) {
        return acc
      }
    }
    if (!acc[val.labelId]) {
      acc[val.labelId] = [ val ]
    } else {
      acc[val.labelId].push(val)
    }
    return acc
  }, {}), [ filteredOptions, contextTwinId, query.search ])

  const isLoading = (deepList.isLoading && deepList.isFetching) || (list.isLoading && list.isFetching)
  return (
    <div className={cx('d-flex flex-column min-h-0 pt-3 pb-md-3 px-3', s.root)}>
      <div className="d-flex">
        <div className="mb-2">{intl.t('menu.title.assets')}</div>
        <div className={cx('mx-2 flex-shrink-0', s.connections, {
          // [s.disabled]: !contextTwinId,
        })}
        >
          <FormCheckButton
            className={cx(s.btn, {
              [s.active]: !isShowChildren,
            })}
            checked={!isShowChildren}
            label={<LegacyIcon name="wiz--other--blocks" width={14} height={14} size="2X" />}
            title={intl.t('form.actions.showAssets')}
            onChange={() => onComponentsRefetch(false)}
          />
          <FormCheckButton
            className={cx(s.btn, {
              [s.active]: isShowChildren,
            })}
            checked={isShowChildren}
            label={<LegacyIcon name="wiz--other--blocks-children" width={14} height={14} size="2X" />}
            title={intl.t('form.actions.showAssetsChildren')}
            onChange={() => onComponentsRefetch(true)}
          />
        </div>
      </div>

      <div className="d-flex flex-wrap mt-2">
        {isLoading ?
          Array.from(new Array(5)).map((_, idx) => (
            <Skeleton
              key={idx}
              width={200}
              height={260}
              className="me-2 mb-2"
            />
          )) : options?.length ? (
            <Cards twins={options} onChange={handleChange} />) : intl.t('errors.noDataDisplay')}
      </div>
      <div className="d-flex align-items-center my-4">
        <div>{intl.t('menu.title.dashboards')}</div>
        {isReadOnly ? null : (
          <button
            ref={refMenuTarget}
            type="button"
            className="btn btn-fill-secondary-alt btn-text ms-3"
            disabled={!contextTwinId}
          >
            <Icon name="faPlus" />
            {intl.t('form.actions.add')}
          </button>
        )}
      </div>
      <div className={s.tilesDashboardsGrid}>
        {contextTwinId && categories?.map((item) => {
          const children = dashboards[item.labelId]

          if (!children) {
            return null
          }
          let isLegacyIcon = true
          if (item.icon?.includes('--')) {
            isLegacyIcon = false
          }
          return (
            <div>
              <div
                key={item.id}
                className={cx(
                  'mb-2',
                  s.tilesDashboardsTitle,
                )}
              >
                {isLegacyIcon ? <Icon name={item.icon} color={item.color} size="sm" className="me-2" /> : (
                  <LegacyIcon
                    name={item.icon || 'placeholder'}
                    color={item.color}
                    size="lg"
                    className="me-2"
                  />
                )}
                <span className="mt-1 fs-6 text-truncate">
                  {intl.t(item.label)}
                </span>
              </div>
              <CustomScrollbars
                horizontal={false}
                shadow={false}
                className={s.w90}
              >
                {children.map((sub) => {
                  const isPersonalComponent = currentUser.id === sub.ownerId
                  return (
                    <div
                      key={sub.id}
                      className={cx(
                        'btn btn-fill-secondary-alt position-relative py-2 ps-3 d-flex justify-content-start mb-1',
                        s.menuItem,
                      )}
                      onClick={() => handleDashboardClick(sub.twinId, sub, { category: item.labelId, sub: sub.id })}
                      aria-hidden
                    >
                      <span className="mt-1 fs-6">
                        {intl.t(sub.name)}
                      </span>
                      {isPersonalComponent ? (
                        <Icon
                          type="solid"
                          className="position-absolute end-0 bottom-0 mb-1 me-2"
                          name="faUser"
                          size="xs"
                          title="Personal"
                        />
                      ) : null}
                    </div>
                  )
                })}
              </CustomScrollbars>
            </div>
          )
        })}
      </div>

      <DropdownMenu
        ref={refMenu}
        target={refMenuTarget}
        arrow
        placement="bottom-start"
        draggable
        autoclose
        options={dashboardTypes
          .filter(dashboardType => (grafanaCheckData?.isSuccess ? dashboardType : dashboardType.id !== 'grafana'))}
        onClick={handleAdd}
        // onDragStart={handleDragStart}
        // onDragEnd={handleDragEnd}
      />
    </div>
  )
}

const enhanceProps = withObservables([ 'components' ], ({ components }) => {
  const twinIds = components?.data?.map(cmp => cmp.twinId).filter(t => !!t)

  const query = twinIds?.length ? dbProvider.database.collections
    .get('twins')
    .query(Q.where('id', Q.oneOf(twinIds)))
    .observeWithColumns([ 'updated_at' ]) : of([])

  return {
    twins: query,
  }
})

export default enhanceProps(TilesView)
