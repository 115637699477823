import classnames from 'classnames'
import { User } from '@wiz/store'
import Observe from '@/containers/Observe'
import ObserveModel from '@/containers/ObserveModel'
import FormatDateTime from '@/containers/FormatDateTime'
import Icon from '@/shared/icon'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import events from '@/utils/events'

const styles = {
  width: '22px',
  height: '22px',
  overflow: 'hidden',
  borderRadius: '50%',
  marginTop: '4px',
  textAlign: 'center',
  lineHeight: '22px',
}

const UserInfo = ({
  showName = false,
  userId,
  data,
  className = 'small mt-n1 text-muted text-truncate',
}) => {
  const { users } = useGlobalExecute()

  return (
    <div
      title={data.nickname ?? data.name}
      className={className}
    >
      {showName ?
        (data.name) :
        (
          <div className={classnames('me-2')} style={styles}>
            {users && users[userId] && !users[userId].includes('/json') ?
              <img src={users[userId]} alt="user" style={{ width: '100%' }} /> : (
                <Icon
                  style={{ fontSize: 14 }}
                  name="faUser"
                  size="sm"
                />
              )}
          </div>
        )}
    </div>
  )
}

const UpdatedInfo = ({
  className,
  userClassName,
  showName,
  date,
  formattedDate,
  dateFormat = 'yy-MM-dd HH:mm:ss',
  user,
  userId,
  userInfo,
  withMillis,
  withSecond = true,
}) => {
  const handleUserClick = async () => {
    await navigator.clipboard.writeText(date)
    events.emit('app:notify', {
      type: 'success',
      title: 't/updatedInfo.copiedTitle',
      duration: 1,
      rawMessage: 't/updatedInfo.copiedMessage',
    })
  }

  return (
    <div
      onClick={handleUserClick}
      title={date}
      style={{
        fontFamily: '"Noto Sans Mono", monospace',
        fontSize: 14,
      }}
      className={classnames('text-truncate min-w-0 d-flex align-items-center flex-row-reverse pointer', className)}
    >
      {date ? (
        <>
          {formattedDate || (
          <FormatDateTime
            date={date}
            dateFormat={dateFormat}
            withMillis={withMillis}
            withSecond={withSecond}
          />
          )}
          {/* <sup className="ms-1"><DateRelative value={date} /></sup> */}
        </>
      ) : null}

      {do {
        if (user) {
          <Observe query={user}>
            {({ data }) => (data ? (
              <UserInfo userId={userId} showName={showName} data={data} className={userClassName} />
            ) : null)}
          </Observe>
        } else if (userInfo) {
          <UserInfo userId={userId} showName={showName} data={{ name: userInfo }} className={userClassName} />
        } else if (userId) {
          <ObserveModel model={User} value={userId}>
            {({ data }) => (data.length ? (
              <UserInfo userId={userId} showName={showName} data={data[0]} className={userClassName} />
            ) : null)}
          </ObserveModel>
        }
      }}
    </div>
  )
}

export default UpdatedInfo
