import { useCallback } from 'react'
import { withProps, withObservables } from '@wiz/components'
import { dbProvider } from '@wiz/store'
import { timezones } from '@wiz/utils'
import Component from '@/components/Settings/View'

const enhanceData = withObservables([], () => ({
  settings: dbProvider.observeGlobalSettings([
    'EmailNotificationGroup',
    'FreeSentSmsCount',
    'FreeSmsLimit',
    'PaidFunctionalityConfirm',
    'PaidSentSmsCount',
    'PaidSmsLimit',
    'PlatformDateFormat',
    'PlatformDateTimeZoneName',
    'PlatformTimeFormat',
    'RawDataLimit',
    'SendEmailNotificationGroup',
    'SentSmsCount',
    'SmsSendingEnabled',
    'EnvironmentName',
    'EnvironmentColor',
    'EnvironmentLogo',
    'Color',
    'Logo',
  ]),
}))

const enhanceProps = withProps(() => {
  const onChangeGlobalSetting = useCallback(async (name, value) => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareReplaceGlobalSetting(context, name, value)
    await dbProvider.batch(context)
  }, [])

  const onChangeZone = useCallback(async (name) => {
    const tz = timezones.find(item => item.name === name)
    if (tz) {
      await onChangeGlobalSetting('PlatformDateTimeZoneName', tz.name)
    }
  }, [ onChangeGlobalSetting ])

  return {
    onChangeGlobalSetting,
    onChangeZone,
  }
})

export default enhanceData(
  enhanceProps(Component),
)
