import { Fragment, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import classnames from 'classnames'
import { get } from '@wiz/utils'
import {
  FormField,
  FormFieldInline,
  FormIndeterminateCheckbox,
  FormControl,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { MarkPoints } from '@/config'

const FormFieldRatio84 = [ 8, 4 ]
const FormFieldRatio66 = [ 6, 6 ]

export default function FieldsContent ({ scope }) {
  const intl = useIntl()
  const [ uid ] = useState(Date.now())
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormFieldInline
        label={intl.t('explorer.form.fields.showDataZoom')}
        description={intl.t('form.fields.showDataZoomDescr')}
        errors={get(errors, `${scope}.showDataZoom`)}
      >
        <Controller
          name={`${scope}.showDataZoom`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>

      <FormField
        ratio={FormFieldRatio66}
        label={intl.t('explorer.form.fields.markPoints')}
        description={intl.t('form.fields.markPointsDescr')}
        errors={get(errors, `${scope}.markPoints`)}
        classNameControl="d-flex justify-content-sm-end align-items-center"
        complex
      >
        <Controller
          name={`${scope}.markPoints`}
          render={({ field: { value, onChange }, fieldState }) => (
            <div
              className={classnames('btn-group flex-fill', {
                'is-invalid': fieldState.invalid,
              })}
            >
              {MarkPoints.map(id => (
                <Fragment key={id}>
                  <input
                    id={`${uid}.${id}`}
                    type="checkbox"
                    className="btn-check"
                    checked={value.includes(id)}
                    onChange={() => {
                      const next = value.includes(id) ?
                        value.filter(item => item !== id) :
                        value.concat(id)
                      onChange(next)
                    }}
                  />

                  <label
                    className="btn btn-fill-primary justify-content-center"
                    htmlFor={`${uid}.${id}`}
                  >
                    {id}
                  </label>
                </Fragment>
              ))}
            </div>
          )}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('explorer.form.fields.showDataSymbol')}
        description={intl.t('form.fields.showDataSymbolDescr')}
        errors={get(errors, `${scope}.showDataSymbol`)}
      >
        <Controller
          name={`${scope}.showDataSymbol`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('explorer.form.fields.normalize')}
        description={intl.t('explorer.form.fields.normalizeDescr')}
        errors={get(errors, `${scope}.normalize`)}
      >
        <Controller
          name={`${scope}.normalize`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('explorer.form.fields.interpolate')}
        description={intl.t('form.fields.interpolateDescr')}
        errors={get(errors, `${scope}.interpolate`)}
      >
        <Controller
          name={`${scope}.interpolate`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>

      <FormField
        ratio={FormFieldRatio84}
        label={intl.t('form.fields.precision')}
        description={intl.t('form.fields.precisionDescr')}
        errors={get(errors, `${scope}.valuePrecision`)}
      >
        <FormControl
          type="number"
          name={`${scope}.valuePrecision`}
          rules={{
            validate: {
              positive: value => (
                !Number.isFinite(value) ||
                value >= 0 ||
                intl.t('form.errors.fieldMin', { min: 0 })
              ),
              lessThanOne: value => (
                !Number.isFinite(value) ||
                value <= 10 ||
                intl.t('form.errors.fieldMax', { max: 10 })
              ),
            },
          }}
          min={0}
          max={10}
          step={1}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.removeNull')}
        description={intl.t('form.fields.removeNullDescr')}
        errors={get(errors, `${scope}.removeNull`)}
      >
        <Controller
          name={`${scope}.removeNull`}
          render={({ field, fieldState }) => (
            <FormIndeterminateCheckbox
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormFieldInline>
    </>
  )
}
