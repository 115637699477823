import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import { FormField, FormControl } from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import Select from '@/components/Form/Select'
import Selectable from '@/components/Form/Selectable'
import SelectTwin from '@/components/Form/SelectTwin'
import SelectSensor from '@/components/Form/SelectSensor'
import StreamJobList from '@/hoc/StreamJobList'

export default function Fields ({ scope }) {
  const intl = useIntl()
  const { formState: { errors }, watch } = useFormContext()
  const selected = watch('selected')
  const status = watch(`${scope}.status`)

  return (
    <>
      <FormField
        complex
        label={intl.t('form.fields.type')}
        description={intl.t('events.form.fields.typeDescr')}
        errors={get(errors, `${scope}.type`)}
        selectable={() => <Selectable value="type" />}
      >
        <FormControl
          type="select"
          name={`${scope}.type`}
          rules={{
            validate: (value) => {
              if (!value && selected.includes('type')) {
                return intl.t('form.errors.fieldRequired')
              }
              return true
            },
          }}
          placeholder="--"
          options={consts.EventTypes.map(id => ({
            id,
            name: intl.t(`enum.eventType.${id}`),
          }))}
        />
      </FormField>

      <FormField
        complex
        errors={get(errors, `${scope}.status`)}
        label={intl.t('form.fields.status')}
        description={() => (
          <>
            <div>{intl.t('events.form.fields.statusDescr')}</div>
            <b>
              {do {
                if (status === 'active') {
                  intl.t('events.form.fields.statusActiveDescr')
                } else if (status === 'resolved') {
                  intl.t('events.form.fields.statusResolvedDescr')
                } else if (status === 'terminated') {
                  intl.t('events.form.fields.statusTerminatedDescr')
                }
              }}
            </b>
          </>
        )}
        selectable={() => <Selectable value="status" />}
      >
        <FormControl
          type="select"
          name={`${scope}.status`}
          rules={{
            validate: (value) => {
              if (!value && selected.includes('status')) {
                return intl.t('form.errors.fieldRequired')
              }
              return true
            },
          }}
          placeholder="--"
          options={consts.EventStatuses.map(id => ({
            id,
            name: intl.t(`enum.eventStatus.${id}`),
          }))}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.twin')}
        description={intl.t('events.form.fields.twinDescr')}
        errors={get(errors, `${scope}.twinId`)}
        selectable={() => <Selectable value="twinId" />}
      >
        <Controller
          name={`${scope}.twinId`}
          render={({ field, fieldState }) => (
            <SelectTwin
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.twinPlaceholder')}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.dataPoints')}
        description={intl.t('events.form.fields.dataPointsDescr')}
        errors={get(errors, `${scope}.sensorIds`)}
        selectable={() => <Selectable value="sensorIds" />}
      >
        <Controller
          name={`${scope}.sensorIds`}
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorsPlaceholder')}
              multiselect
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('events.form.fields.streamJob')}
        description={intl.t('events.form.fields.streamJobDescr')}
        errors={get(errors, `${scope}.streamJobId`)}
        selectable={() => <Selectable value="streamJobId" />}
      >
        <Controller
          name={`${scope}.streamJobId`}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('events.form.fields.streamJobPlaceholder')}
              ListSource={StreamJobList}
            />
          )}
        />
      </FormField>
    </>
  )
}

Fields.propTypes = {
  scope: PropTypes.string.isRequired,
}
