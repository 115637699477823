/* eslint-disable react/no-danger */
import { useMemo, useCallback } from 'react'
import classnames from 'classnames'
import { ResponsiveList, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Option from './Option'

export default function SearchList ({
  className,
  options,
  settings,
  search,
  onAction,
  onResetFilter,
  onOpen,
}) {
  const intl = useIntl()
  const isMobile = useMobile()
  const itemData = useMemo(() => ({
    options,
    search,
    settings,
    onAction,
    onOpen,
  }), [ options, search, settings, onAction, onOpen ])

  const itemSize = useCallback((index) => {
    const data = itemData.options[index]
    let size = isMobile ? 45 : 130
    if (data.description) {
      size += 30
    }
    if (!isMobile && itemData.settings.lastOpened?.[data.id]) {
      size += 20
    }
    return size
  }, [ itemData, isMobile ])

  if (!options.length) {
    return (
      <div className={classnames('d-flex flex-column align-items-center justify-content-center', className)}>
        {intl.t('errors.noDataDisplay')}
        {onResetFilter ? (
          <button
            className="btn btn-sm btn-outline-secondary mt-2"
            type="button"
            onClick={onResetFilter}
          >
            {intl.t('form.actions.resetFilters')}
          </button>
        ) : null}
      </div>
    )
  }

  return (
    <ResponsiveList
      className={classnames('mx-n1', className)}
      itemCount={options.length}
      itemData={itemData}
      itemSize={itemSize}
      estimatedItemSize={130}
    >
      {Option}
    </ResponsiveList>
  )
}
