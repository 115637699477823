import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Twin } from '@wiz/store'
import { FormInputSearch } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useRouter } from '@/router'
import { useAuth } from '@/auth'
import ListTwinGraphsByTwin from '@/containers/Form/ListTwinGraphsByTwin'

export default function RightBarLinked ({
  blockSettings,
}) {
  const intl = useIntl()
  const auth = useAuth()
  const router = useRouter()
  const [ search, setSearch ] = useState('')

  const twins = useMemo(() => (
    Object.values(blockSettings)
      .filter(item => Twin.is(item))
  ), [ blockSettings ])

  return (
    <>
      <div className="my-2 mx-3">
        <FormInputSearch
          size="sm"
          placeholder={intl.t('form.actions.searchPlaceholder')}
          onChange={setSearch}
        />
      </div>

      {auth.checkAccessRead('SectionDigitalTwinChart') ? (
        <ListTwinGraphsByTwin
          className={classnames('flex-fill min-h-0 h-100 overflow-hidden')}
          twins={twins}
          search={search}
          onSelect={(model) => {
            router.push({
              name: 'chart-view',
              params: { id: model.blockId },
            })
          }}
        />
      ) : null}
    </>
  )
}

RightBarLinked.propTypes = {
  blockSettings: PropTypes.shape().isRequired,
}
