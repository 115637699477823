import { Controller, useFormContext } from 'react-hook-form'
import { FormFieldInline } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import SelectTwin from '@/components/Form/SelectTwin'

export default function FieldTwins () {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <FormFieldInline
      label={intl.t('explorer.form.fields.twins')}
      description={intl.t('explorer.form.fields.twinsDescr')}
      errors={get(errors, 'twins')}
      vertical
    >
      <Controller
        name="twins"
        render={({ field, fieldState }) => (
          <SelectTwin
            {...field}
            invalid={fieldState.invalid}
            placeholder={intl.t('form.fields.twinPlaceholder')}
            multiselect
          />
        )}
      />
    </FormFieldInline>
  )
}
