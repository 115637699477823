import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as ic from '@awesome.me/kit-9065c4792f/icons/classic/solid'

const map = {
  // regular: far,
  // solid: fas,
  // light: fal,
}

const Icon = ({ name, ...props }) => {
  // if (!map[type]) {
  //   throw new Error('Icon type not found. Please use far|fas|fal.')
  // }
  const icon = ic[name]
  return (
    <FontAwesomeIcon icon={icon} {...props} />
  )
}

// const iconList = Object
//   .keys(Icons)
//   .filter(key => key !== "fas" && key !== "prefix" )
//   .map(icon => Icons[icon])

export default Icon

//  interface FontAwesomeIconProps extends BackwardCompatibleOmit<SVGAttributes<SVGSVGElement>,
//  'children' | 'mask' | 'transform'>, RefAttributes<SVGSVGElement> {
//   icon: IconProp
//   mask?: IconProp
//   maskId?: string
//   className?: string
//   color?: string
//   spin?: boolean
//   spinPulse?: boolean
//   spinReverse?: boolean
//   pulse?: boolean
//   beat?: boolean
//   fade?: boolean
//   beatFade?: boolean
//   bounce?: boolean
//   shake?: boolean
//   border?: boolean
//   fixedWidth?: boolean
//   inverse?: boolean
//   listItem?: boolean
//   flip?: FlipProp
//   size?: SizeProp
//   pull?: PullProp
//   rotation?: RotateProp
//   transform?: string | Transform
//   symbol?: FaSymbol
//   style?: CSSProperties
//   tabIndex?: number;
//   title?: string;
//   titleId?: string;
//   swapOpacity?: boolean;
// }

// export type SizeProp =
//   | "2xs"
//   | "xs"
//   | "sm"
//   | "lg"
//   | "xl"
//   | "2xl"
//   | "1x"
//   | "2x"
//   | "3x"
//   | "4x"
//   | "5x"
//   | "6x"
//   | "7x"
//   | "8x"
//   | "9x"
//   | "10x";
