import { forwardRef } from 'react'
import { Dropdown } from '@wiz/components'
import classes from './index.css'

const DropdownSelect = forwardRef(({
  children,
  placement = 'bottom-end',
  ...props
}, ref) => (
  <Dropdown
    {...props}
    ref={ref}
    className={classes.root}
    placement={placement}
    size="md"
    arrow
  >
    {children}
  </Dropdown>
))

DropdownSelect.displayName = 'DropdownSelect'
export default DropdownSelect
