import { map } from 'rxjs/operators'
import { toArrayValue, orderBy } from '@wiz/utils'
import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { auth } from '@/auth'
import { RIGHTS } from '@/config'

export default withObservables([
  'access',
  'filterBy',
  'followedBy',
  'search',
  'selectedOnly',
  'settings',
  'sortBy',
  'value',
], ({
  access,
  filterBy,
  followedBy,
  search,
  selectedOnly,
  settings,
  sortBy,
  value,
}) => {
  let query = dbProvider.database.collections.get('dashboards').query()

  if (search) {
    const sanitizeSearch = Q.sanitizeLikeString(search)
    query = query.extend(Q.or(
      Q.where('title', Q.like(`%${sanitizeSearch}%`)),
      Q.where('description', Q.like(`%${sanitizeSearch}%`)),
      Q.where('id', search),
    ))
  }

  if (selectedOnly) {
    query = query.extend(Q.where('id', Q.oneOf(toArrayValue(value))))
  }

  switch (filterBy) {
    case 'favorite':
      query = query.extend(Q.where('id', Q.oneOf(settings?.spotlightFavorites || [])))
      break
    case 'my':
      query = query.extend(Q.where('created_by_id', auth.getCurrentUserId()))
      break
    case 'public':
      query = query.extend(Q.where('is_global_shared', true))
      break
    case 'shared':
      query = query.extend(
        Q.where('is_global_shared', false),
        Q.on('share_users_dashboards', 'user_id', auth.getCurrentUserId()),
      )
      break
    default:
  }

  if (followedBy) {
    query = query.extend(Q.where('created_by_id', followedBy))
  }

  return {
    options: query
      .observeWithColumns([ 'updated_at' ])
      .pipe(
        map(items => items.filter(item => (
          auth.checkAccessRules(access || RIGHTS.READ, item)
        ))),
        map((items) => {
          let next
          if (sortBy === 'opened') {
            next = orderBy(items, [
              item => (settings?.lastOpened?.[item.id] || 0),
            ], [ 'desc' ])
          } else {
            next = orderBy(items, [
              item => item.title.toLowerCase(),
            ], [ 'asc' ])
          }
          return next
        }),
      ),
  }
})
