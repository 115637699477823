/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { forwardRef } from 'react'
import {
  createLocation,
  Link as RouterLink,
  useRouteMatch,
} from '@/router'

const CustomLink = forwardRef(({ navigate, ...props }, ref) => (
  <a
    ref={ref}
    {...props}
    onClick={(event) => {
      event.preventDefault()
      navigate?.()
      props.onClick?.(event)
    }}
  >
    {props.children}
  </a>
))

export default function Link ({
  children,
  className,
  disabled,
  hash,
  keepQuery,
  name,
  params,
  query,
  state,
  title,
  onClick,
}) {
  const match = useRouteMatch()

  return (
    <RouterLink
      title={title}
      className={className}
      disabled={disabled}
      component={CustomLink}
      onClick={onClick}
      to={createLocation({
        name,
        query,
        hash,
        state,
        keepQuery,
        params: {
          ...match.params,
          ...params,
        },
      })}
    >
      {children}
    </RouterLink>
  )
}
