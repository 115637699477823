/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  useRef,
  useCallback,
  useState,
} from 'react'
import {
  DFDiagram,
  DiagramThemeContext,
  FormSwitch,
  Icon,
  useDrag,
  useMobile,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import { useTheme } from '@/theme'
import Link from '@/components/Link'
import FormBlock from '@/containers/Forms/Block'
import FormStreamJob from '@/containers/Forms/StreamJob'
import ContextMenu from '@/containers/StreamJob/ContextMenu'
import RightBar from '@/containers/StreamJob/RightBar'
import { consts } from '@wiz/utils'

const StreamBlockTypeAlias = {
  flow: 'flowStream',
}

export default function View ({
  withTitle = true,
  withBack = true,
  container,
  diagramState,
  streamJob,
  blocks,
  blockFactory,
  onChangeDiagram,
  onClickCreate,
  onDeletedDiagram,
  onDropCreateDiagram,
  onLinkDiagram,
  onPlayDiagram,
  onToggleActive,
  onToggleGrid,
  onCopyBlock,
  onPaste,
  onDuplicate,
  snsAvailable,
}) {
  const refDiagram = useRef()
  const refRightBar = useRef()
  const refContextMenu = useRef()
  const intl = useIntl()
  const auth = useAuth()
  const theme = useTheme()
  const drag = useDrag()
  const isMobile = useMobile()

  const [ editBlockId, setEditBlockId ] = useState(null)
  const [ editStreamId, setEditStreamId ] = useState(null)
  const [ play, setPlay ] = useState(false)
  const [ contextMenuData, setContextMenuData ] = useState()
  const [ selectedIds, setSelectedIds ] = useState([])
  const [ loadingToggleActive, setLoadingToggleActive ] = useState(false)

  const handleDiagramDblClick = useCallback(({ id }) => {
    setEditBlockId(id)
  }, [])

  const handleClickPlay = useCallback(() => {
    if (onPlayDiagram) {
      setPlay(true)
      window.setTimeout(() => onPlayDiagram().finally(() => setPlay(false)), 500)
    }
  }, [ onPlayDiagram ])

  const handleActionContextMenu = useCallback(async ({ action, params }, { block, loc }) => {
    refContextMenu.current?.close()
    if (action === 'edit' && params.id) {
      setEditBlockId(params.id)
    } else if (action === 'remove' && params.id) {
      onDeletedDiagram(selectedIds.length ? selectedIds : [ params.id ])
    } else if (action === 'attach' && params.type) {
      drag.context.type = params.type
      await onDropCreateDiagram?.({ loc })
      drag.clear()
    } else if (action === 'copy') {
      onCopyBlock?.(block, { loc })
    }
  }, [
    drag,
    selectedIds,
    onDeletedDiagram,
    onCopyBlock,
    onDropCreateDiagram,
  ])

  const handleToggleActive = useCallback(async () => {
    setLoadingToggleActive(true)
    await onToggleActive()
    setLoadingToggleActive(false)
  }, [ onToggleActive ])

  const inputBlocks = blocks
    .map(block => (block.category === consts.DiagramBlockType.Input ? block.id : undefined))
    .filter(block => !!block)

  return (
    <div className="d-flex flex-column flex-fill">
      <header className="d-flex align-items-center flex-wrap px-2 border-bottom">
        {withBack && !isMobile ? (
          <div className="mx-2">
            <Link
              name="stream-jobs-list"
              className="btn btn-flat-secondary btn-text"
            >
              <Icon name="fa--chevron-double-left" />
            </Link>
          </div>
        ) : null}

        {withTitle && !isMobile ? (
          <div className="d-flex flex-fill align-items-center mx-2 min-w-0">
            <div className="text-truncate h6 m-0">
              {streamJob.name}
            </div>
          </div>
        ) : null}

        <div className="d-flex align-items-center flex-wrap my-2">
          {auth.checkAccessUpdate(streamJob) ? (
            <label className="d-flex align-items-center flex-shrink-0 mx-2">
              {loadingToggleActive ? (
                <span className="me-3">
                  <Icon name="fa--spinner" spin />
                </span>
              ) : (
                <FormSwitch
                  checked={streamJob.active}
                  onChange={handleToggleActive}
                />
              )}

              {intl.t('form.fields.active')}
            </label>
          ) : null}

          {(
            auth.checkAccessUpdate(streamJob) ||
            auth.checkAccessCopy(streamJob)
          ) ? (
            <>
              <div className="border-start rh-4 flex-shrink-0" />
              <div className="flex-shrink-0 mx-2">
                {auth.checkAccessUpdate(streamJob) ? (
                  <button
                    type="button"
                    className="btn btn-flat-secondary btn-text"
                    title={intl.t('streamJobs.tooltipSettings')}
                    onClick={() => setEditStreamId(streamJob.id)}
                  >
                    <Icon name="fa--edit" />
                  </button>
                ) : null}
                {auth.checkAccessCopy(streamJob) ? (
                  <button
                    type="button"
                    className="btn btn-flat-secondary btn-text"
                    title={intl.t('t/streamJobs.tooltipDuplicate')}
                    onClick={() => onDuplicate(streamJob)}
                  >
                    <Icon name="fa--copy" />
                  </button>
                ) : null}
              </div>
            </>
            ) : null}

          <div className="border-start rh-4 flex-shrink-0" />

          <div className="mx-2 flex-shrink-0">
            <button
              type="button"
              title={intl.t(diagramState.diagram.hideGrid ? 'streamJobs.tooltipShowGrid' : 'streamJobs.tooltipHideGrid')}
              className="btn btn-flat-secondary btn-text"
              onClick={onToggleGrid}
            >
              <Icon name={diagramState.diagram.hideGrid ? 'fa--border-none' : 'fa--border-all'} />
            </button>

            <button
              type="button"
              className="btn btn-flat-secondary btn-text"
              title={intl.t('chart.form.actions.fitPosition')}
              onClick={() => refDiagram.current.fit()}
            >
              <Icon name="fa--compress-arrows-alt" />
            </button>
          </div>

          {auth.checkAccessUpdate(streamJob) ? (
            <>
              <div className="border-start rh-4 flex-shrink-0" />
              <div className="mx-2 flex-shrink-0">
                <button
                  type="button"
                  className="btn btn-flat-primary btn-text"
                  onClick={handleClickPlay}
                  disabled={play}
                  title={intl.t('mlworkflow.actions.testModel')}
                >
                  <Icon
                    name={play ? 'fa--spinner' : 'fa--play'}
                    spin={play}
                  />
                </button>
              </div>
            </>
          ) : null}
        </div>
      </header>

      <div
        id="dfd-graph-container"
        className="d-flex flex-fill position-relative overflow-hidden"
      >
        <DiagramThemeContext.Provider value={theme.gojs}>
          <DFDiagram
            ref={refDiagram}
            scope={streamJob.id}
            className="flex-fill"
            state={diagramState}
            blocks={blocks}
            blockFactory={blockFactory}
            watermark={streamJob.active ? intl.t('t/streamJobs.warningEditActive') : ''}
            onSelection={setSelectedIds}
            {...(auth.checkAccessUpdate(streamJob) ? {
              onDropCreate: onDropCreateDiagram,
              onDeleted: onDeletedDiagram,
              onLink: onLinkDiagram,
              onChange: onChangeDiagram,
              onDblClick: handleDiagramDblClick,
              onContextMenu: setContextMenuData,
              onPaste,
            } : {
              isReadOnly: true,
            })}
          />

          <RightBar
            ref={refRightBar}
            container={container || '#dfd-graph-container'}
            blockIds={selectedIds}
            streamJob={streamJob}
            onClickCreate={type => onClickCreate(
              type,
              refDiagram.current.getCenterCoords(),
            )}
          />
        </DiagramThemeContext.Provider>

        <ContextMenu
          {...contextMenuData}
          ref={refContextMenu}
          container={container || '#dfd-graph-container'}
          onAction={handleActionContextMenu}
          snsAvailable={snsAvailable}
        />
      </div>

      {editBlockId ? (
        <FormBlock
          id={editBlockId}
          inputBlocks={inputBlocks}
          blocks={blocks}
          blockTypeAlias={StreamBlockTypeAlias}
          onClose={() => setEditBlockId(null)}
          dialog={{
            title: intl.t('streamJobs.titleUpdateBlock'),
            dataTestid: 'updateStreamJobBlockDialog',
          }}
        />
      ) : null}

      {editStreamId ? (
        <FormStreamJob
          id={editStreamId}
          onClose={() => setEditStreamId(null)}
          dialog={{
            title: intl.t('streamJobs.titleUpdate'),
            dataTestid: 'updateStreamJobDialog',
          }}
        />
      ) : null}
    </div>
  )
}
