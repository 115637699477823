import { useIntl } from '@wiz/intl'
import { Icon } from '@wiz/components'
import Observe from '@/containers/Observe'

export default function CellParent ({
  twin,
  onUnlinkParent,
}) {
  const intl = useIntl()
  return (twin.parentId ? (
    <Observe query={twin.parent}>
      {({ data, error }) => {
        if (error) {
          return (
            <button
              type="button"
              title={error.message}
              className="btn btn-flat-danger btn-sm"
              onClick={() => onUnlinkParent?.(twin)}
            >
              <Icon name="fa--unlink" className="me-1" />
              {intl.t('errors.notFound')}
            </button>
          )
        }

        return data?.name || null
      }}
    </Observe>
  ) : '-')
}
