import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { withObservables } from '@wiz/components'

export default withObservables([
  'dashboard',
  'defaultValues',
], ({
  dashboard,
  defaultValues,
}) => ({
  permissions: dashboard?.queryRelShareUsers ? (
    dashboard.queryRelShareUsers
      .observeWithColumns([ 'updated_at' ])
  ) : of$(defaultValues?.permissions ?? []),

  projects: dashboard?.queryProjects ? (
    dashboard.queryProjects
      .observe()
      .pipe(
        map(items => items.map(item => item.projectId)),
      )
  ) : of$(defaultValues?.projects ?? []),

  twins: dashboard?.queryRelTwins ? (
    dashboard.queryRelTwins
      .observe()
      .pipe(
        map(items => items.map(item => item.twinId)),
      )
  ) : of$(defaultValues?.twins ?? []),
}))
