import {
  forwardRef,
  useRef,
  useMemo,
  useImperativeHandle,
  useLayoutEffect,
} from 'react'
import { ResponsiveList } from '@wiz/components'
import { consts } from '@wiz/utils'
import ListOption from './ListOption'
import NoDataDisplay from './NoDataDisplay'

function List ({
  className,
  options,
  search,
  Content,
  itemSize = consts.ListItemHeight,
  onResetFilter,
}, ref) {
  const refList = useRef()

  const itemData = useMemo(() => ({
    Content,
    options,
    search,
  }), [
    Content,
    options,
    search,
  ])

  useImperativeHandle(ref, () => ({
    scrollTo (id) {
      if (id && refList.current) {
        refList.current.scrollToRecord(id)
      }
    },

    getItemById (id) {
      if (id && refList.current) {
        return refList.current.getItemById(id)
      }

      return undefined
    },

    getSiblingsById (id) {
      if (id && refList.current) {
        return refList.current.getSiblingsById(id)
      }

      return []
    },

    getVisible () {
      return refList.current?.getVisible()
    },
  }), [])

  if (!options.length) {
    return (
      <NoDataDisplay
        className="w-100 mt-2"
        onResetFilter={onResetFilter}
      />
    )
  }

  return (
    <ResponsiveList
      refList={refList}
      className="w-100 mt-2"
      itemCount={options.length}
      itemSize={itemSize}
      itemData={itemData}
      height={Math.min(window.innerHeight, itemSize * options.length)}
    >
      {ListOption}
    </ResponsiveList>
  )
}

export default forwardRef(List)
