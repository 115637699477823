import {
  Fragment, useCallback, useEffect, useMemo,
} from 'react'
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { useFormContext, useFieldArray } from 'react-hook-form'
import classnames from 'classnames'
import {
  FormControl, FormSection, FormFieldInline, Icon,
} from '@wiz/components'
import FormDialog from '@/components/Form/FormDialog'
import { useIntl } from '@wiz/intl'
import { wizataApi } from '@/api'
import events from '@/utils/events'
import { get, has } from '@wiz/utils'

const SectionRatio = [ 9, 3 ]

const propertyTypes = [ 'datapoint', 'integer', 'float', 'string', 'relative', 'datetime', 'json' ]

const Properties = ({
  properties, isLoading, isUpdating,
}) => {
  const intl = useIntl()

  const {
    register,
    formState: { errors },
    reset,
  } = useFormContext()

  const {
    fields, remove, append,
  } = useFieldArray({
    name: 'properties',
    keyName: '_id',
    shouldUnregister: true,
  })

  const handleAdd = useCallback(() => {
    append({
      type: '',
      name: '',
      required: false,
    })
  }, [ append ])

  useEffect(() => {
    if (!fields?.length) {
      reset({ properties })
    }
  }, [ properties ])

  return (
    <>
      {isUpdating ? (
        <div className="position-absolute-fill position-center-fill bg-light opacity-50" style={{ zIndex: 1 }}>
          <Icon name="fa--spinner" size="2X" spin />
        </div>
      ) : null}
      <FormSection
        ratio={SectionRatio}
        title={intl.t('templates.form.fields.properties')}
        description={intl.t('templates.form.fields.propertiesDescr')}
        addon={() => (
          <button
            type="button"
            className="btn btn-sm btn-flat-secondary mt-2 mt-md-0 text-nowrap min-w-0"
            onClick={() => handleAdd()}
          >
            <Icon name="fa--plus" size="md" className="me-1" />
            {intl.t('form.actions.addProperty')}
          </button>
        )}
      >
        <FormFieldInline complex>
          <div className="list-group list-group-flush flex-fill mx-0">
            {!isLoading && properties?.length ? (
              <div className="d-flex">
                <div className="me-2 flex-fill">{intl.t('form.fields.name')}</div>
                <div className="me-5">{intl.t('form.fields.type')}</div>
                <div className="mx-2">{intl.t('form.fields.required')}</div>
              </div>
            ) : null}
            {!isLoading && properties && fields.map((item, idx) => {
              const scopeRow = `properties.${idx}`
              const error = (
                get(errors, `${scopeRow}.type`) ||
                    get(errors, `${scopeRow}.name`)
              )
              return (
                <Fragment
                  key={item.name}
                >
                  <div
                    className={classnames('list-group-item py-2 px-0 d-flex align-items-center', {
                      'is-invalid': !!error,
                    })}
                  >
                    <input
                      {...register(`${scopeRow}.name`, {
                        validate: (value) => {
                          const str = String(value || '').trim()
                          if (!str.length) {
                            return intl.t('form.errors.fieldRequired')
                          }
                          if (value.length > 450) {
                            return intl.t('form.errors.fieldMaxlen', { max: 450 })
                          }
                          return true
                        },
                      })}
                      type="search"
                      className={classnames('form-control me-2 w-100', {
                        'is-invalid': has(errors, `${scopeRow}.name`),
                      })}
                    />
                    <select
                      {...register(`${scopeRow}.type`, {
                        required: intl.t('form.errors.fieldRequired'),
                      })}
                      className={classnames('form-select me-2 w-auto', {
                        'is-invalid': has(errors, `${scopeRow}.type`),
                      })}
                    >
                      {propertyTypes.map(val => (
                        <option key={val} value={val}>{val}</option>
                      ))}
                    </select>

                    <FormControl
                      type="checkbox"
                      className="ms-2"
                      name={`${scopeRow}.required`}
                    />
                    <button
                      type="button"
                      className="btn btn-flat-secondary"
                      title={intl.t('form.actions.remove')}
                      onClick={() => remove(idx)}
                    >
                      <Icon name="fa--trash-alt" />
                    </button>
                  </div>
                  {error ? (
                    <div className="invalid-feedback m-0">
                      {error.message}
                    </div>
                  ) : null}
                </Fragment>
              )
            })}
            {isLoading ? (
              <div className="list-group-item text-center">
                <Icon
                  className="ms-2"
                  name="fa--spinner"
                  spin
                />
              </div>
            ) : null}
            {!properties?.length && !isLoading ? (
              <div className="list-group-item text-center">
                {intl.t('form.info.variablesNotFound')}
              </div>
            ) : null}
          </div>
        </FormFieldInline>
      </FormSection>
    </>
  )
}

const FormProperties = ({
  dialog, onClose, template, id,
}) => {
  const queryClient = useQueryClient()

  const { data: properties, isLoading } = useQuery({
    queryKey: [ 'templateProperties', id ],
    queryFn: () => wizataApi.templateProperties.getListById(id).then((res) => {
      if (Array.isArray(res)) {
        return res
      }

      return undefined
    }),
    enabled: !!id,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const {
    mutateAsync: update,
    isLoading: isUpdating,
  } = useMutation({
    mutationKey: [ 'updateProperties', id ],
    mutationFn: data => wizataApi.templates.updatePropertiesById(id, data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/templates.updateError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => queryClient.invalidateQueries([ 'templateProperties', id ]),
  })
  const handleSubmit = async (data) => {
    const next = {
      ...template, ...data,
    }
    await update(next)
  }

  const defaultValues = useMemo(() => ({
    properties,
  }), [ properties ])

  return (
    <FormDialog
      onSubmit={handleSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="propertiesForm"
      dialog={dialog}
      noCancelChanges
    >
      <Properties
        properties={properties}
        isLoading={isLoading}
        isUpdating={isUpdating}
      />
    </FormDialog>
  )
}

export default FormProperties
