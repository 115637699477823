import { Controller } from 'react-hook-form'
import { useIntl } from '@wiz/intl'
import { FormSection } from '@wiz/components'
import Select from '@/components/Form/Select'
import NotificationSheetList from '@/hoc/NotificationSheetList'

export default function SectionAlertSheet ({ scope }) {
  const intl = useIntl()

  return (
    <FormSection
      title={intl.t('alert.section.notificationSheet')}
    >
      <Controller
        name={`${scope}.notificationSheetId`}
        render={({ field, fieldState }) => (
          <Select
            {...field}
            invalid={fieldState.invalid}
            ListSource={NotificationSheetList}
            placeholder={intl.t('form.fields.notificationSheetPlaceholder')}
          />
        )}
      />
    </FormSection>
  )
}
