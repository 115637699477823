import { useContext, useEffect } from 'react'
import withTableActions from '@/containers/withTableActions'
import Component from '@/components/StreamJobLogs/List'
import { withProps } from '@wiz/components'
import { AppContext } from '@/pages/context'

const enhanceFilters = withProps(() => {
  const { filters, onFilter } = useContext(AppContext)

  useEffect(() => {
    if (!filters.dateFrom && !filters.dateTo) {
      onFilter({
        dateFrom: Date.now() - 60 * 60 * 1000,
        dateTo: Date.now(),
      })
    }
  }, [ filters.dateFrom, filters.dateTo, onFilter ])

  return {
    contextFilters: {
      ...filters,
    },
  }
})

export default withTableActions({

  defaultFilters: () => ({
    sortBy: 'timestamp',
    // sortDir: 'desc',
  }),
})(enhanceFilters(Component))
