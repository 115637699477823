import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import classnames from 'classnames'
import {
  FormSection,
  FormField,
  FormFieldInline,
  FormControl,
} from '@wiz/components'
import { get, has, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import SelectSensor from '@/components/Form/SelectSensor'

export default function Section ({
  scope, title, description, widgetScope,
}) {
  const intl = useIntl()
  const {
    register, watch, formState: { errors }, setValue,
  } = useFormContext()

  const [
    widgetName,
    displayDataPointName,
  ] = watch([
    `${widgetScope}.name`,
    `${scope}.displayDataPointName`,
  ])
  const [ checked, setChecked ] = useState(!!displayDataPointName)
  const handleChange = () => {
    setChecked(!checked)
    setValue(`${scope}.displayDataPointName`, !checked, { shouldDirty: true })
  }

  useEffect(() => {
    if (widgetScope && widgetName && checked) {
      setChecked(false)
      setValue(`${scope}.displayDataPointName`, false)
    }
  }, [ widgetName ])

  return (
    <FormSection
      title={title}
      description={description}
    >
      <FormField
        label={intl.t('widgets.sensor.form.fields.sensor')}
        description={intl.t('widgets.sensor.form.fields.sensorDescr')}
        errors={get(errors, `${scope}.sensorId`)}
      >
        <Controller
          name={`${scope}.sensorId`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
          render={({ field, fieldState }) => (
            <SelectSensor
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.sensorPlaceholder')}
              excludeBusinessTypes={consts.ExcludedBusinessTypes}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('widgets.sensor.form.fields.precision')}
        description={intl.t('widgets.sensor.form.fields.precisionDescr')}
        errors={get(errors, `${scope}.precision`)}
      >
        <FormControl
          type="number"
          name={`${scope}.precision`}
          rules={{
            validate: {
              min: value => (
                value === null ||
                value === '' ||
                parseInt(value || 0, 10) >= 0 ||
                intl.t('form.errors.fieldMin', { min: 0 })
              ),
              max: value => (
                value === null ||
                value === '' ||
                parseInt(value || 0, 10) <= 15 ||
                intl.t('form.errors.fieldMax', { max: 15 })
              ),
            },
          }}
          min={0}
          max={15}
          step={1}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('widgets.sensor.form.fields.displayDataPointName')}
        description={intl.t('widgets.sensor.form.fields.displayDataPointNameDescr')}
        errors={get(errors, `${scope}.displayDataPointName`)}
      >
        <input
          {...register(`${scope}.displayDataPointName`)}
          type="checkbox"
          disabled={widgetName}
          className={classnames('form-check-input mt-0 ms-2 flex-shrink-0', {
            'is-invalid': has(errors, `${scope}.displayDataPointName`),
          })}
          checked={checked}
          onChange={handleChange}
        />
      </FormFieldInline>

      <FormFieldInline
        label={intl.t('widgets.sensor.form.fields.displayCategoryName')}
        description={intl.t('widgets.sensor.form.fields.displayCategoryNameDescr')}
        errors={get(errors, `${scope}.displayCategoryName`)}
      >
        <input
          {...register(`${scope}.displayCategoryName`)}
          type="checkbox"
          className={classnames('form-check-input mt-0 ms-2 flex-shrink-0', {
            'is-invalid': has(errors, `${scope}.displayCategoryName`),
          })}
        />
      </FormFieldInline>
    </FormSection>
  )
}
