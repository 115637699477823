import { useCallback, useState } from 'react'
import xor from 'lodash/xor'
import union from 'lodash/union'
import { useDrag } from '@wiz/components'
import { toArrayValue } from '@wiz/utils'
import FilterSearch from '@/components/Form/FilterSearch'
import ListLibraryItems from '@/components/Form/ListLibraryItems'

export default function AttachSensors ({
  businessType,
  onClickCreate,
}) {
  const drag = useDrag()
  const [ value, setValue ] = useState([])

  const handleChange = useCallback((data) => {
    const ids = toArrayValue(data).map(item => (item.payload || item).id)
    let nextValue

    if (data === null) {
      nextValue = []
    } else if (Array.isArray(data)) {
      nextValue = union(value, ids)
    } else {
      nextValue = xor(value, ids)
    }

    setValue(nextValue)
  }, [ value ])

  const handleClick = useCallback(async ({ type }) => {
    drag.context.type = type
    await onClickCreate()
    drag.clear()
  }, [ drag, onClickCreate ])

  const handleDragStart = useCallback(({ type }) => {
    drag.context.type = type
  }, [ drag ])

  const handleDragEnd = useCallback(() => {
    drag.clear()
  }, [ drag ])

  return (
    <FilterSearch
      withSelectedOnly={false}
      onChange={handleChange}
    >
      {(formData, refList) => (
        <ListLibraryItems
          {...formData}
          ref={refList}
          className="flex-fill min-h-0"
          draggable
          selectable
          keyName="id"
          value={value}
          businessType={businessType}
          onChange={handleChange}
          onClick={handleClick}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        />
      )}
    </FilterSearch>
  )
}
