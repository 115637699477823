import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { Q, dbProvider, Sensor } from '@wiz/store'
import { withObservables, withProps } from '@wiz/components'
import {
  consts,
  createNestedTree,
  filterNestedTree,
  isEmpty,
  orderBy,
  sortDLList,
  toArrayValue,
} from '@wiz/utils'

const enhanceData = withObservables([
  'selectedRoot',
  'twinIds',
  'twinsRequired',
  'types',
  'withSensors',
], ({
  selectedRoot,
  twinIds,
  twinsRequired,
  types,
  withSensors,
}) => {
  const currentWithSensors = withSensors ?? (
    isEmpty(types) ||
    types.includes(consts.TwinType.Sensor)
  )
  let queryTwins = dbProvider.database.collections.get('context_twins').query()
  let querySensors = dbProvider.database.collections.get('sensors').query()

  let observeTwins
  let observeSensors
  if (selectedRoot) {
    observeTwins = selectedRoot.observeTwinsTree
      .pipe(map(items => [ selectedRoot ].concat(items)))
  } else {
    if (twinIds?.length || twinsRequired) {
      queryTwins = queryTwins.extend(Q.where('id', Q.oneOf(toArrayValue(twinIds))))
    }
    observeTwins = queryTwins.observeWithColumns([ 'updated_at' ])
  }

  if (currentWithSensors) {
    if (twinIds?.length || twinsRequired) {
      querySensors = querySensors.extend(Q.where('twin_id', Q.oneOf(toArrayValue(twinIds))))
    }
    observeSensors = querySensors.observeWithColumns([ 'updated_at' ])
  }

  const observe = observeSensors ? (
    combineLatest(observeTwins, observeSensors)
      .pipe(map(items => [].concat(...items)))
  ) : observeTwins

  return {
    options: observe.pipe(
      map(items => sortDLList(orderBy(
        items,
        [ 'type', item => item.displayName.toLowerCase() ],
        [ 'asc', 'asc' ],
      ))),
    ),
  }
})

const enhanceTree = withProps(({
  excludeFrom,
  keyName,
  options,
  search,
  selectedOnly,
  types,
  value,
  withConnections,
  withHardware,
  withLocation,
  withSensors,
}) => {
  const currentKeyName = keyName || 'id'
  const isArrayValue = Array.isArray(value)
  const isArrayExclude = Array.isArray(excludeFrom)
  const sanitizeSearch = search && String(search).toLowerCase()
  const currentTypes = toArrayValue(types)
  if (withConnections) {
    currentTypes.push(consts.TwinType.Flow)
  }
  if (withSensors) {
    currentTypes.push(consts.TwinType.Sensor)
  }
  const hasTypes = !!currentTypes.length

  const disabledFrom = (data) => {
    if (isArrayExclude) {
      return excludeFrom.includes(data.id)
    }
    return data.id === excludeFrom
  }

  const checkEnabled = (data) => {
    let enabled = true

    if (enabled && sanitizeSearch) {
      enabled = data.displayName.toLowerCase().indexOf(sanitizeSearch) !== -1
    }

    if (enabled && withHardware) {
      enabled = !!(data.hardwareId)
    }

    if (enabled && withLocation) {
      enabled = !!(
        data.latitude &&
        data.longitude
      )
    }

    if (enabled && hasTypes) {
      enabled = (
        (currentTypes.includes(consts.TwinType.Sensor) && Sensor.is(data)) ||
        currentTypes.includes(data.type)
      )
    }

    if (enabled && selectedOnly) {
      const key = data[currentKeyName]
      if (isArrayValue) {
        enabled = value.includes(key)
      } else {
        enabled = !!(value && key === value)
      }
    }

    return enabled
  }

  return {
    options: filterNestedTree({ disabledFrom })(
      createNestedTree({ checkEnabled })(options),
    ),
  }
})

export default WrappedComponent => enhanceData(
  enhanceTree(WrappedComponent),
)
