import { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormControl } from '@wiz/components'
import { dbProvider, IExplorer } from '@wiz/store'
import DataViewExplorer from '@/components/Form/DataViewExplorer'
import DataSourceExplorer from '@/containers/Form/DataSourceExplorer'

export default function SectionData ({ scope }) {
  const { watch, setValue } = useFormContext()
  const dataSources = watch(`${scope}.dataSources`)
  const dataViews = watch(`${scope}.dataViews`)

  const handleCreateSource = useCallback(async (data) => {
    let nextSources = []
    let nextViews = []

    if (data) {
      const source = await IExplorer.createDataSourceContext(
        dbProvider.database,
        data,
      )
      const view = await IExplorer.createDataViewContext(
        dbProvider.database,
        { sourceId: source.id, source },
      )
      nextSources = dataSources.concat(source)
      nextViews = dataViews.concat(view)
    }

    setValue(`${scope}.dataSources`, nextSources, { shouldDirty: true })
    setValue(`${scope}.dataViews`, nextViews, { shouldDirty: true })
  }, [ scope, dataSources, dataViews, setValue ])

  const handleRemoveView = useCallback((view) => {
    const { parentId } = view
    const nextViews = dataViews
      .filter(item => item.id !== view.id)
      .map(item => (item.parentId === view.id ? { ...item, parentId } : item))

    if (view.sourceId) {
      const nextSources = dataSources.filter(item => item.id !== view.sourceId)
      setValue(`${scope}.dataSources`, nextSources, { shouldDirty: true })
    }

    setValue(`${scope}.dataViews`, nextViews, { shouldDirty: true })
  }, [ scope, dataSources, dataViews, setValue ])

  const sensorIds = useMemo(() => (
    dataSources
      .filter(item => item.sensorId)
      .map(item => item.sensorId)
  ), [ dataSources ])

  return (
    <div className="flex-fill d-flex flex-column mt-2">
      <FormControl
        type="any"
        name={`${scope}.dataSources`}
      />

      <FormControl
        type="any"
        name={`${scope}.dataViews`}
      />

      <div className="flex-fill d-flex flex-column">
        <DataSourceExplorer
          value={sensorIds}
          onChange={handleCreateSource}
        />
      </div>

      <div className="flex-fill d-flex flex-column pt-2">
        <DataViewExplorer
          options={dataViews}
          canCreateSource={false}
          canCreateVirtualSource={false}
          onRemoveView={handleRemoveView}
        />
      </div>
    </div>
  )
}
