import { useState, useRef } from 'react'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { DateTime } from 'luxon'
import { auth } from '@/auth'

const initialMessage = JSON.stringify({
  id: 'd50ea560-9d03-4ac3-9ad1-a02e230a403f', experimentId: 'f2ec8da0-c7e6-4b4d-a481-7793c7ea7b43', properties: '{"twinId": "e4c160de-fc4a-4c43-8c61-ce0f1eb0d833"}', pipelineId: 'f40a5b2b-653e-4f89-a732-c48d71fa827b', pipelineJson: '{"id": "f40a5b2b-653e-4f89-a732-c48d71fa827b", "steps": "[]", "plots": "[]", "variables": "{}"}', plots: [], models: [], anomaliesList: '[]', status: 'started',
})

const nextMessage = JSON.stringify({
  id: '5440dd64-e358-4da1-96a4-79e072de0f81', executionId: 'd50ea560-9d03-4ac3-9ad1-a02e230a403f', stepId: 'ffdcf523-7013-4b25-824b-4b8d1eea714d', status: 'completed',
})

const res = JSON.stringify({
  id: 'd50ea560-9d03-4ac3-9ad1-a02e230a403f', experimentId: 'f2ec8da0-c7e6-4b4d-a481-7793c7ea7b43', properties: '{"twinId": "e4c160de-fc4a-4c43-8c61-ce0f1eb0d833"}', pipelineId: 'f40a5b2b-653e-4f89-a732-c48d71fa827b', pipelineJson: '{"id": "f40a5b2b-653e-4f89-a732-c48d71fa827b", "key": "UAT-pipeline21", "steps": "[{\\"id\\": \\"5d485ea7-4276-4dc4-85cc-c99874ab9bbd\\", \\"type\\": \\"query\\", \\"config\\": {\\"id\\": \\"0c22e9c4-2626-4247-8e5e-14c0cc806188\\", \\"equipments_list\\": [{\\"datapoints\\": [\\"bearing1\\", \\"bearing2\\"]}], \\"timeframe\\": {\\"start\\": 1682035200000, \\"end\\": 1682294400000}, \\"aggregations\\": {\\"agg_method\\": \\"mean\\", \\"interval\\": 60000}, \\"template\\": \\"639e7537-0d80-4056-9670-06a63870d386\\", \\"filters\\": {}, \\"options\\": {}}, \\"inputs\\": [], \\"outputs\\": [{\\"dataframe\\": \\"query_output\\"}]}, {\\"id\\": \\"ffdcf523-7013-4b25-824b-4b8d1eea714d\\", \\"type\\": \\"script\\", \\"config\\": {\\"function\\": \\"average_gf\\"}, \\"inputs\\": [{\\"dataframe\\": \\"query_output\\"}], \\"outputs\\": [{\\"dataframe\\": \\"average_dataframe\\"}]}]", "plots": "[{\\"script\\": \\"describe_df\\", \\"dataframe\\": \\"average_dataframe\\"}]", "variables": "{}", "templateId": "639e7537-0d80-4056-9670-06a63870d386", "experimentKey": "bc8131e4-123e-4edb-88e1-0168ab2dc480", "createdById": "000c4cae-0340-4c69-b5f1-f1bde4ceab24", "createdDate": "1686644548873", "updatedById": "000c4cae-0340-4c69-b5f1-f1bde4ceab24", "updatedDate": "1686644548873"}', plots: [], models: [], anomaliesList: '[]', status: 'completed',
})

const URL = 'https://dev-streams.onwizata.com/'

const getCurrentTime = () => DateTime.now().toLocaleString(DateTime.TIME_24_WITH_SECONDS)

export const useSignal = () => {
  const connectionRef = useRef()
  const [ messages, setMessages ] = useState([])
  const [ execution, setExecution ] = useState(null)

  // console.log('initialMessage', JSON.parse(initialMessage))
  // console.log('nextMessage', JSON.parse(nextMessage))
  // console.log('res', JSON.parse(res))

  const destroy = () => {
    connectionRef.current?.stop()
  }

  const init = async (id) => {
    setMessages(prev => prev.concat({ dt: getCurrentTime(), message: 'Connecting...' }))

    const connect = new HubConnectionBuilder()
      .withUrl(`${URL}execution`, {
        accessTokenFactory: () => auth.acquireToken(),
      })
      .configureLogging(LogLevel.Error)
      .withAutomaticReconnect()
      .build()

    connect.onclose(() => setMessages(prev => prev.concat(
      { dt: getCurrentTime(), status: 'error', message: 'Connection closed' },
    )))

    connectionRef.current = connect

    if (connectionRef.current) {
      const connection = connectionRef.current
      connection.on('Send', (message) => {
        setMessages(prev => prev.concat({ dt: getCurrentTime(), message }))
      })

      connection.on('SendNotifyExecutionStatus', (message) => {
        // console.log('SendNotifyExecutionStatus', message)
        setMessages(prev => prev.concat({ dt: getCurrentTime(), message }))
      })

      connection.on('SendNotifyStepStatus', (message) => {
        // console.log('SendNotifyStepStatus', message)
        const result = JSON.parse(message)

        if (result.status === 'completed') {
          setMessages(prev => prev.concat({ dt: getCurrentTime(), message: 'Execution completed', status: 'success' }))
          setExecution(result)
          destroy()
        } else if (result.status === 'failed') {
          setMessages(prev => prev.concat({ dt: getCurrentTime(), message: 'Execution failed', status: 'error' }))
          setExecution(result)
          destroy()
        } else if (result.status === 'started') {
          setMessages(prev => prev.concat({ dt: getCurrentTime(), message: 'Execution started', status: 'started' }))
          setExecution(result)
        } else {
          setMessages(prev => prev.concat({ dt: getCurrentTime(), message: `Execution ${result.status}` }))
        }
        // setMessages(prev => prev.concat({ dt: getCurrentTime(), message }))
      })

      connection
        .start({ withCredentials: false })
        .then(() => {
          setMessages(prev => prev.concat(
            { dt: getCurrentTime(), status: 'success', message: 'Connection estabilished' },
          ))
          connection.invoke('JoinExecution', id)
        })
        .catch((error) => {
          setMessages(prev => prev.concat({ dt: getCurrentTime(), status: 'error', message: error.message }))
        })
    }
  }

  return {
    destroy, init, messages, execution,
  }
}
