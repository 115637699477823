import {
  forwardRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react'
import classnames from 'classnames'
import { DiagramBlockFlow, Sensor } from '@wiz/store'
import { ResponsiveList } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { consts } from '@wiz/utils'
import Option from './Option'
import withDrag from './withDrag'

const ListSensorsByTwin = ({
  className,
  options = [],
  searchedSensors,
  search,
  value,
  defaultCheckedAll,
  showDescription = true,
  onChange,
  onAttachSensors,
  onCreateCommand,
  onInputSensorValue,
  onResetFilter,
  onToggleByTwin,
  onToggleAll,
  otherValues = [],
  draggable,
  checkDragged,
  onDragEnd,
  onDragStart,
  onDrop,
  onEdit,
  setAttachedSensorsCount,
}, ref) => {
  const intl = useIntl()

  const extraOptions = useMemo(() => (otherValues.length ?
    [ 'divider' ].concat(otherValues) :
    []), [ otherValues ])

  const itemData = useMemo(() => ({
    options: options.concat(extraOptions),
    checkDragged,
    search,
    value,
    showDescription,
    onChange,
    onAttachSensors,
    onCreateCommand,
    onInputSensorValue,
    onToggleByTwin,
    onToggleAll,
    draggable,
    onDragEnd,
    onDragStart,
    onDrop,
    onEdit,
  }), [
    extraOptions,
    options,
    checkDragged,
    search,
    value,
    showDescription,
    onChange,
    onAttachSensors,
    onCreateCommand,
    onInputSensorValue,
    onToggleByTwin,
    onToggleAll,
    draggable,
    onDragEnd,
    onDragStart,
    onDrop,
    onEdit,
  ])

  const itemSize = useCallback((index) => {
    const item = itemData.options[index]
    let height = consts.ListItemHeight

    if ((item instanceof DiagramBlockFlow) && item.twinId) {
      height = consts.ListItemHeightMd
    }

    if (item.description && showDescription) {
      height = consts.ListItemHeightMd
    }

    return height
  }, [ itemData, showDescription ])

  useEffect(() => {
    if (defaultCheckedAll && !value) {
      onChange?.(
        itemData.options
          .filter(item => Sensor.is(item))
          .map(item => item.id),
        itemData.options
          .filter(item => Sensor.is(item))
          .map(item => item.hardwareId),
      )
    }
    const selectedSensors = searchedSensors?.filter(sensor => value?.includes(sensor.id)) || []
    setAttachedSensorsCount?.(selectedSensors.length)
  }, [ defaultCheckedAll, value, onChange, itemData ])

  if (!options.length && !otherValues.length) {
    return (
      <div className={classnames('d-flex flex-column align-items-center justify-content-center', className)}>
        {intl.t('form.nodata')}
        {onResetFilter ? (
          <button type="button" className="btn btn-sm btn-outline-secondary mt-2" onClick={onResetFilter}>
            {intl.t('form.actions.resetFilters')}
          </button>
        ) : null}
      </div>
    )
  }
  return (
    <ResponsiveList
      className={className}
      itemCount={itemData.options.length}
      itemData={itemData}
      itemSize={itemSize}
      estimatedItemSize={consts.ListItemHeight}
    >
      {Option}
    </ResponsiveList>
  )
}

ListSensorsByTwin.displayName = 'ListSensorsByTwin'
export default withDrag(forwardRef(ListSensorsByTwin))
