import { useContext, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { wizataApi } from '@/api'
import { withProps } from '@wiz/components'
import { consts } from '@wiz/utils'
import { AppContext } from '@/pages/context'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'

const SortByFields = {
  createdDate: 'createdDate',
  updatedDate: 'updatedDate',
  name: 'name',
  businessType: 'businessType',
  inputMode: 'inputMode',
  categoryId: 'categoryId',
  unitId: 'unitId',
  twinId: 'twinId',
}

const SortDirection = {
  asc: 'Ascending',
  desc: 'Descending',
}

const enhanceData = withProps(({
  filters,
}) => {
  const { filters: contextFilters } = useContext(AppContext)
  const { contextTwinId } = useGlobalExecute()

  const {
    data, isLoading, refetch, isFetching,
  } = useQuery({
    queryKey: [ 'sensorsPagedList' ],
    queryFn: () => {
      const limit = filters.pageSize + 1
      const offset = (filters.page - 1) * filters.pageSize

      const nextFilters = []
      const complexFilters = {}

      if (contextTwinId) {
        complexFilters.twinId = contextTwinId
        complexFilters.includeChildren = true
      }
      // if (contextFilters.unitId) {
      //   nextFilters.push({
      //     propertyName: 'unitId',
      //     type: consts.FilterType.Guid,
      //     operationType: consts.FilterOperationType.Equals,
      //     value: contextFilters.unitId,
      //   })
      // }
      if (contextFilters.unitId) {
        nextFilters.push({
          propertyName: 'unitId',
          type: contextFilters.unitId === 'null' ? consts.FilterType.Guid : consts.FilterType.Enum,
          operationType: consts.FilterOperationType.Equals,
          canBeNull: contextFilters.unitId === 'null' ? true : !contextFilters.unitId,
          ...(contextFilters.unitId && contextFilters.unitId !== 'null') ? { value: contextFilters.unitId } : {},
        })
      }
      if (contextFilters.categoryId) {
        nextFilters.push({
          propertyName: 'categoryId',
          type: contextFilters.categoryId === 'null' ? consts.FilterType.Guid : consts.FilterType.Enum,
          operationType: consts.FilterOperationType.Equals,
          canBeNull: contextFilters.categoryId === 'null' ? true : !contextFilters.categoryId,
          ...(contextFilters.categoryId && contextFilters.categoryId !== 'null') ?
            { value: contextFilters.categoryId } : {},
        })
      }
      // if (contextFilters.categoryId) {
      //   nextFilters.push({
      //     propertyName: 'categoryId',
      //     type: consts.FilterType.Guid,
      //     operationType: consts.FilterOperationType.Equals,
      //     value: contextFilters.categoryId,
      //   })
      // }
      if (contextFilters.businessType) {
        nextFilters.push({
          propertyName: 'businessType',
          type: consts.FilterType.Enum,
          operationType: consts.FilterOperationType.Equals,
          value: contextFilters.businessType,
        })
      }
      if (filters.search) {
        nextFilters.push({
          propertyName: 'name',
          type: consts.FilterType.Text,
          operationType: consts.FilterOperationType.Contains,
          value: filters.search,
        })
      }
      if (contextFilters.inputMode) {
        nextFilters.push({
          propertyName: 'inputMode',
          type: consts.FilterType.Enum,
          operationType: consts.FilterOperationType.Equals,
          value: contextFilters.inputMode,
        })
      }
      // if (contextFilters.labelIds?.length) {
      //   complexFilters.labelIds = contextFilters.labelIds
      // }

      return wizataApi.sensors.getList({
        pagination: {
          take: limit - 1,
          skip: offset,
        },
        filters: nextFilters,
        ...complexFilters,
        sortingList: [
          {
            direction: SortDirection[filters.sortDir],
            propertyName: SortByFields[filters.sortBy],
          },
        ],
      })
    },
    refetchOnWindowFocus: false,
    enabled: true,
  })

  useEffect(() => {
    if (data && !isLoading) {
      refetch()
    }
  }, [
    filters.page,
    filters.pageSize,
    filters.sortDir,
    filters.sortBy,
    filters.search,
    contextFilters.categoryId,
    // contextFilters.labelIds,
    contextFilters.unitId,
    contextFilters.inputMode,
    contextFilters.businessType,
    contextTwinId,
  ])

  return {
    total: data?.totalCount || 0,
    items: data?.items || [],
    data: data?.items || [],
    loading: isLoading || isFetching,
    refetch,
  }
})

export default enhanceData
