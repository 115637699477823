import { useCallback, memo } from 'react'
import { dbProvider, BusinessLabel } from '@wiz/store'
import { withProps } from '@wiz/components'
import Component from '@/components/Forms/BusinessLabel'
import enhanceBusinessLabel from './enhanceBusinessLabel'

const enhanceProps = withProps(() => {
  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareReplaceData(context, BusinessLabel, data.businessLabel)
    await dbProvider.batch(context)
  }, [])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceBusinessLabel(
    enhanceProps(Component),
  ),
)
