/* eslint-disable jsx-a11y/label-has-associated-control */
import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { Icon, OverflowCenter, useDrag } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import { consts } from '@wiz/utils'
import Observe from '@/containers/Observe'
import ButtonCopy from '@/components/Form/ButtonCopy'
import SensorValue from '@/containers/Sensor/SensorValue'
import { Twin } from '@wiz/store'

const onDragOver = event => event.preventDefault()

const ExtraInfo = ({ data }) => {
  if (!data) {
    return null
  }
  const value = data.symbol || data.name
  return (!value || value === 'Other') ? null : (
    <span className="ps-1 extra-separate" title={data.name}>{value}</span>
  )
}

const SensorValueContent = ({ value, timestamp }) => (timestamp ? (
  <span
    className="text-secondary ps-1"
    title={DateTime.fromMillis(timestamp).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
  >
    {value}
  </span>
) : null)

export default function Sensor ({ data, index, style }) {
  const intl = useIntl()
  const auth = useAuth()
  const drag = useDrag()
  const {
    options,
    value,
    search,
    showDescription,
    onChange,
    onCreateCommand,
    onInputSensorValue,
    draggable,
    onDragStart,
    onDrop,
    onDragEnd,
    checkDragged,
    onEdit,
  } = data
  const item = options[index]

  const currentDragged = checkDragged?.(item)
  const currentDraggable = draggable?.(item)

  const isInternalDrag = !!(
    currentDraggable === true ||
    (currentDraggable && currentDraggable === drag.context.name) ||
    (Array.isArray(currentDraggable) && currentDraggable.includes(drag.context.name))
  )

  const takeCurrentTwin = useMemo(() => {
    const next = []
    for (const i of options) {
      if (i instanceof Twin) {
        next.push([ i ])
      } else {
        next[next.length - 1]?.push(i)
        // console.warn('no assosiated twin')
      }
      if (i.id === item.id) {
        return next[next.length - 1]?.[0]
      }
    }
    return null
  }, [ options, item ])

  return (
    <label
      style={style}
      className="d-flex align-items-start hover-toggle list-group-item text-nowrap fw-light"
      {...(currentDraggable ? {
        draggable: 'true',
        onDragEnd,
        onDragOver,
        onDragStart: (event) => {
          event.dataTransfer.setData('text/plain', null)
          event.dataTransfer.setDragImage(consts.DragImagesByModelName.Widget, 20, 20)
          onDragStart?.(item, takeCurrentTwin)
        },
        onDrop: currentDragged || !isInternalDrag ? undefined : (event) => {
          event.preventDefault()
          event.stopPropagation()
          onDrop?.('inner')
        },
      } : undefined)}
    >
      <div
        className="flex-fill min-w-0"
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center min-w-0">
            <input
              className="form-check-input flex-shrink-0 m-0 me-2"
              type="checkbox"
              checked={value?.includes(item.id) || false}
              onChange={() => onChange?.(item.id, item.hardwareId)}
            />

            <OverflowCenter
              value={item.displayName}
              mark={search}
            />

            {item.hardwareId ? (
              <ButtonCopy
                className="hover-inline-flex"
                value={item.hardwareId}
                title={intl.t('message.copy.hardwareId', { value: item.hardwareId })}
              />
            ) : null}
            {item.hardwareId && onEdit ? (
              <button
                type="button"
                className="hover-inline-flex btn btn-sm btn-link p-1 mt-n1 mb-n1 ms-1 text-reset"
                title={intl.t('form.actions.edit')}
                onClick={() => onEdit(item.id)}
              >
                <Icon name="fa--pen-square" />
              </button>
            ) : null}
          </div>
          <div className="d-flex ms-1 text-info">
            <SensorValue
              sensor={item}
              loader=" "
              Content={SensorValueContent}
            />
            {item.category ? (
              <Observe query={item.category}>
                {props => <ExtraInfo {...props} />}
              </Observe>
            ) : null}
            {item.unit ? (
              <Observe query={item.unit}>
                {props => <ExtraInfo {...props} />}
              </Observe>
            ) : null}
          </div>
        </div>
        {showDescription && item.description ? (
          <div className="d-flex">
            <div className="me-1 text-secondary text-truncate">
              {item.description}
            </div>
          </div>
        ) : null}
      </div>

      {onCreateCommand && auth.checkAccessManage('SectionDeviceCommands') ? (
        <button
          type="button"
          className="hover-opacity btn btn-flat-secondary align-self-center p-2 ms-1"
          title={intl.t('form.actions.createCommand')}
          onClick={() => onCreateCommand({
            sensorId: item.id,
            twinId: item.twinId,
          })}
        >
          <Icon name="fa--terminal" />
        </button>
      ) : null}

      {onInputSensorValue && item.canInputValue ? (
        <button
          type="button"
          className="hover-opacity btn btn-flat-secondary align-self-center p-2 ms-1"
          title={intl.t('form.actions.inputSensorValue')}
          onClick={() => onInputSensorValue({
            sensorId: item.id,
            businessType: item.businessType,
          })}
        >
          <Icon name="fa--pen-square" />
        </button>
      ) : null}
    </label>
  )
}
