import { forwardRef, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { wizataApi } from '@/api'
import Select from '@/components/Form/Select'

const SortByFields = {
  createdDate: 'createdDate',
  updatedDate: 'updatedDate',
  name: 'name',
}

const SortDirection = {
  asc: 'Ascending',
  desc: 'Descending',
}

const SelectGroupSystem = forwardRef(({
  id, disabled, ...props
}, ref) => {
  const {
    data, isLoading, refetch, isFetching,
  } = useQuery({
    queryKey: [ 'groupSystems' ],
    queryFn: () => {
      const limit = 100 + 1
      const offset = 0

      const nextFilters = []

      return wizataApi.groupSystems.getList({
        pagination: {
          take: limit - 1,
          skip: offset,
        },
        filters: nextFilters,
        sortingList: [
          {
            direction: SortDirection.desc,
            propertyName: SortByFields.createdDate,
          },
        ],
      })
    },
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false,
  })

  useEffect(() => {
    if (data) {
      refetch()
    }
  }, [ id ])

  return (
    <Select
      {...props}
      invalid={props.invalid}
      options={data?.items}
      disabled={disabled || isLoading || isFetching}
      isLoading={isLoading || isFetching}
    />
  )
})

export default SelectGroupSystem
