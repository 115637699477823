import { of as of$, combineLatest } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'
import RightBar from '@/components/StreamJobs/RightBar'

const enhanceData = withObservables([ 'blockIds' ], ({ blockIds }) => {
  const blocksCollection = dbProvider.database.collections.get('diagram_blocks')
  return {
    blocks: blocksCollection
      .query(Q.where('id', Q.oneOf(blockIds)))
      .observeWithColumns([ 'updated_at' ]),

    blockSettings: blocksCollection
      .query(
        Q.where('id', Q.oneOf(blockIds)),
        Q.where('type', Q.oneOf([
          'input',
          'output',
        ])),
      )
      .observe()
      .pipe(
        switchMap(items => (
          items.length ? (
            combineLatest(items.map(item => item.observeFullSettings))
              .pipe(
                map(settings => settings.map((setting, idx) => ([
                  items[idx].id,
                  setting && !setting.hyperedge ? setting : null,
                ]))),
              )
          ) : of$([])
        )),

        map(Object.fromEntries),
      ),
  }
})

export default enhanceData(RightBar)
