import Autolinker from 'autolinker'

const autolinker = new Autolinker({
  stripPrefix: false,
  truncate: { length: 50, location: 'middle' }
})

export function svgToDataURL (svg) {
  if (svg instanceof Element) {
    svg = svg.outerHTML
  }

  const encoded = encodeURIComponent(svg)
    .replace(/'/g, '%27')
		.replace(/"/g, '%22')

	return `data:image/svg+xml;charset=UTF-8,${encoded}`
}

export function autolink (data, isOnlyLink) {
  // return autolinker.link(isOnlyLink ? encodeURI(data) : data)
  return autolinker.link(data)
}

export function strip (html) {
  const tmp = document.createElement('div')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}
