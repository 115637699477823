import {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import { withObservables, DropdownMenu } from '@wiz/components'
import { dbProvider } from '@wiz/store'
import { debounce } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { useRouter, useRouteMatch } from '@/router'
import { registerAlertStream, unregisterAlertStream } from '@/api'
import events from '@/utils/events'
import Icon from '@/shared/icon'

const enhanceSettings = withObservables([], () => ({
  disableNotifications: dbProvider.observeSettings('disableNotifications'),
}))

const runAlarm = debounce(() => {
  try {
    new Audio('data:audio/mp3;base64,//twxAAAA4ADb7QAACPaMyz/OYCQAgA1AvA4+mEo6H5wv8/g+a/5z//0f/w//n/8H2BBAHKIE4AYA+H6+XgyBhBIVmCSsY8GJjQOgwJjAqMLAYIFZjRAHZR8YDWho0PI5lvAUSgCHg4wBxfBSAU98GTNigcs43aA22nYddH6riRpukIeKdmJNEoDao1GW2Im+rT7X3FKU6nqn31cKdi+dlr8AyJ1pO709EnOikO0F+hyzmYbfuny3h78JdyrdLFpc4WctpZRlYwtbpJVJa3KKtlllj3eLyZ5ZynLPnd4TFj/qSy33eIxYvi8tNi8QzRK61uwK4e39/cHXPzhydo7FjuGGGP/nVp5r/pu7ucufl269NJPXLcRpt4ZwRcqWqt2Hf/+JXf/KpVscAVnZlrueoHCpERgUJCqCv/7UMQIgA4dd1f9hAApI4Rm/DewjC1UqYEptca9Rv6/sV/lYVFVWGa5a9Vq4Zf+JVZVahuVa/+9lWGZv/lV/+1VVpajVdryWYk1fhlrZvldf///9hYFQCpv///8MHQCwer/////tfqvwtexR0U6Lk8kUCt8igaqaQ8PM321RGzqgGlBDiQneLDYbG6BG6VKuERVZ0RCVgdFQ0IhEJQaApAsBWUtEoiJgqRLPoDoKslSwFEqj0qIiX/gIRu/KklMQU1FMy45OC4yVVVVVVVVVVX/+xDECQPAAAGkAAAAIAAANIAAAARVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVQ==').play()
  } catch (e) {
    // empty
  }
}, 1000)

const ButtonNotify = ({
  disableNotifications,
}) => {
  const refTarget = useRef()
  const refDropdowm = useRef()
  const [ showMark, setShowMark ] = useState(false)
  const router = useRouter()
  const intl = useIntl()
  const match = useRouteMatch({
    path: '/:lang?/notifications',
  })

  const handleClick = useCallback(async ({ id }) => {
    refDropdowm.current?.close()
    if (id === 'toggle') {
      const context = dbProvider.createBatchContext()
      await dbProvider.prepareToggleSetting(context, 'disableNotifications')
      await dbProvider.batch(context)
    } else if (id === 'link') {
      router.push({ name: 'notifications-list' })
    }
  }, [ router ])

  const options = useMemo(() => [
    {
      id: 'toggle',
      icon: disableNotifications ? 'fa--bell' : 'fa--bell-slash',
      name: disableNotifications ?
        intl.t('notifications.form.turnOn') :
        intl.t('notifications.form.turnOff'),
    },
    { id: 'link', name: intl.t('notifications.form.showMore') },
  ], [ intl, disableNotifications ])

  useEffect(() => {
    let handleNotification

    if (!disableNotifications) {
      handleNotification = (notify) => {
        runAlarm()
        setShowMark(true)
        events.emit('app:notify', {
          type: notify.level,
          title: notify.source,
          rawMessage: notify.message,
        })
      }

      registerAlertStream(handleNotification)
    }

    return () => {
      runAlarm.cancel()
      unregisterAlertStream(handleNotification)
    }
  }, [ disableNotifications ])

  return (
    <>
      <button
        ref={refTarget}
        type="button"
        className="btn btn-text btn-flat-secondary"
        onClick={match ? () => handleClick({ id: 'toggle' }) : undefined}
        title={intl.t('menu.tooltip.notifications')}
      >
        <Icon
          type="solid"
          name={disableNotifications ? 'faBellSlash' : 'faBell'}
          size="lg"
        />

        {showMark ? (
          <div className="d-flex position-absolute start-0 bottom-0 text-warning ms-2 mb-1">
            <Icon
              name="faCircle"
              size="xs"
            />
          </div>
        ) : null}
      </button>

      {!match ? (
        <DropdownMenu
          ref={refDropdowm}
          target={refTarget}
          options={options}
          placement="bottom-end"
          arrow
          width={200}
          onClick={handleClick}
        />
      ) : null}
    </>
  )
}

ButtonNotify.propTypes = {
  disableNotifications: PropTypes.bool,
}

ButtonNotify.defaultProps = {
  disableNotifications: false,
}

export default enhanceSettings(
  ButtonNotify,
)
