import { useIntl } from '@wiz/intl'
import Section from './Section'
import SectionConditions from './SectionConditions'

export default function SettingsSection ({ widget }) {
  const intl = useIntl()
  return (
    <>
      <Section
        scope="config"
        title={intl.t(widget.info.name)}
        description={intl.t('widgets.gauge.titleDescr')}
      />

      <SectionConditions
        scope="config"
        title={intl.t('widgets.gauge.thresholdSection')}
        description={intl.t('widgets.gauge.thresholdSectionDescr')}
      />
    </>
  )
}
