import {
  useRef,
  useCallback,
  useState,
} from 'react'
import {
  DiagramMap,
  DiagramThemeContext,
  useDrag,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import { useTheme } from '@/theme'
import FormBlock from '@/containers/Forms/Block'
import FormTwinGraph from '@/containers/Forms/TwinGraph'
import RightBar from '@/containers/TwinGraph/RightBar'
import ContextMenu from '@/containers/TwinGraph/ContextMenu'
import BulkSensors from '@/components/Dialogs/BulkSensors'
import LeftBar from '@/containers/TwinGraph/LeftBar'
import TwinDiagram from '@/containers/TwinGraph/TwinDiagram'
import GraphToolbar from './GraphToolbar'

export default function View ({
  blockFactory,
  diagramState,
  mapView,
  metaBlock,
  onChangeDiagram,
  onClickCreate,
  onCopyBlock,
  onDeletedDiagram,
  onDropCreateDiagram,
  onDuplicateTwinGraph,
  onLinkDiagram,
  onOpenNestedTwinGraph,
  onPaste,
  onToggleGrid,
  onToggleMapView,
  onView,
  request,
  twinGraph,
  twinSettings,
}) {
  const refMap = useRef()
  const refDiagram = useRef()
  const refLeftBar = useRef()
  const refRightBar = useRef()
  const refContextMenu = useRef()
  const intl = useIntl()
  const auth = useAuth()
  const theme = useTheme()
  const drag = useDrag()

  const [ editBlockId, setEditBlockId ] = useState(null)
  const [ editTwinGraph, setEditTwinGraph ] = useState(null)
  const [ linkMode, setLinkMode ] = useState(null)
  const [ selectedIds, setSelectedIds ] = useState([])
  const [ contextMenuData, setContextMenuData ] = useState()
  const [ bulkSensorIds, setBulkSensorIds ] = useState([])
  const [ bulkSensorsType, setBulkSensorsType ] = useState()
  const [ leftBarExpanded, setLeftBarExpanded ] = useState(false)

  // const scrollToLeftBar = selectedIds.length ?
  //   request.nestedTwinGraphs.concat(selectedIds[0]).join('/') :
  //   request.nestedTwinGraphs.join('/')

  const handleClickEvents = useCallback(() => refRightBar.current.open('events'), [])
  const handleSelection = useCallback(data => setSelectedIds(data), [])
  const handleDiagramDblClick = useCallback(({ id }) => setEditBlockId(id), [])

  const handleToggleLeftBar = useCallback(() => {
    if (!leftBarExpanded) {
      setLeftBarExpanded(true)
    } else {
      refLeftBar.current.close()
    }
  }, [ leftBarExpanded ])

  const handleEditTwinGraph = useCallback(() => {
    setEditTwinGraph(twinGraph.id)
  }, [ twinGraph ])

  const handleDiagramAlign = useCallback((params) => {
    refDiagram.current.align(params)
  }, [])

  const handleDiagramFit = useCallback(() => {
    refDiagram.current.fit()
  }, [])

  const handleDiagramReshapeLinks = useCallback(() => {
    refDiagram.current.reshapeLinks()
  }, [])

  const handleDragStart = useCallback(({ type, payload }) => {
    if (payload) {
      drag.context.type = payload.type
      drag.data.add(payload)
    } else {
      drag.context.type = type
    }
  }, [ drag ])

  const handleDragEnd = useCallback(() => {
    drag.clear()
  }, [ drag ])

  const handleClickCreate = useCallback(({ type, payload }) => {
    onClickCreate?.({
      type: type || payload.type,
      model: payload,
      state: refDiagram.current.getCenterCoords(),
    })
  }, [ onClickCreate ])

  const handleActionContextMenu = useCallback(async (
    { action, params },
    { block, loc, latlng },
  ) => {
    refContextMenu.current?.close()

    if (action === 'alignment') {
      refDiagram.current.align(params)
    } else if (action === 'attach' && params.type) {
      drag.context.type = params.type
      await onDropCreateDiagram?.({ loc, latlng }, block)
      drag.clear()
    } else if (action === 'edit' && params.id) {
      setEditBlockId(params.id)
    } else if (action === 'remove' && params.id) {
      onDeletedDiagram([ params.id ])
    } else if ((
      action === 'explorer' ||
      action === 'export' ||
      action === 'notebook' ||
      action === 'dashboard'
    ) && params?.length) {
      setBulkSensorsType(action)
      setBulkSensorIds(params)
    } else if (action === 'select') {
      onOpenNestedTwinGraph(block)
    } else if (action === 'copy') {
      const ids = await onCopyBlock?.(selectedIds)
      refDiagram.current?.selectAfterUpdate(ids)
    } else if (action === 'duplicateWithChildren') {
      const ids = await onCopyBlock?.(selectedIds, { withChildren: true })
      refDiagram.current?.selectAfterUpdate(ids)
    } else if (action === 'showOnDigitalTwin') {
      refRightBar.current.open('digitalTwin', {
        title: intl.t('twin.titleItems'),
        props: {
          selectedTwinId: params.id,
        },
      })
    } else if (
      action === 'commands' ||
      action === 'events' ||
      action === 'sensors' ||
      action === 'setPoints' ||
      action === 'measurements' ||
      action === 'info' ||
      action === 'experiment' ||
      action === 'solutions'
    ) {
      refRightBar.current.open(action)
    } else if (action === 'runWizard') {
      refRightBar.current.open('runWizard', {
        title: params?.customFunction?.title || intl.t('experiments.titleWizard'),
        props: params,
      })
    }
  }, [
    intl,
    drag,
    selectedIds,
    onDropCreateDiagram,
    onDeletedDiagram,
    onOpenNestedTwinGraph,
    onCopyBlock,
  ])

  return (
    <div className="d-flex flex-column flex-fill">
      <GraphToolbar
        mapView={mapView}
        hideGrid={diagramState.diagram.hideGrid}
        request={request}
        twinGraph={twinGraph}
        twinSettings={twinSettings}
        linkMode={linkMode}
        setLinkMode={setLinkMode}
        onToggleGrid={onToggleGrid}
        onToggleMapView={onToggleMapView}
        onToggleLeftBar={handleToggleLeftBar}
        onEditTwinGraph={handleEditTwinGraph}
        onDuplicateTwinGraph={onDuplicateTwinGraph}
        onDiagramAlign={handleDiagramAlign}
        onDiagramFit={handleDiagramFit}
        onDiagramReshapeLinks={handleDiagramReshapeLinks}
        onClickCreate={handleClickCreate}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      />

      <div
        id="twin-graph-container"
        className="d-flex flex-fill position-relative overflow-hidden"
      >
        <DiagramThemeContext.Provider value={theme.gojs}>
          <DiagramMap
            ref={refMap}
            refDiagram={refDiagram}
            className="position-absolute-fill"
            scope={twinGraph.id}
            scale={diagramState.diagram.mapScale}
            position={diagramState.diagram.mapPosition}
            disabled={!mapView}
          >
            <TwinDiagram
              ref={refDiagram}
              refMap={mapView ? refMap : null}
              metaBlock={metaBlock}
              scope={twinGraph.id}
              mapView={mapView}
              className="position-absolute-fill"
              linkMode={linkMode}
              state={diagramState}
              blockFactory={blockFactory}
              scrollTo={request.scrollToBlock}
              bgImage={twinGraph.image}
              onSelection={handleSelection}
              {...(auth.checkAccessUpdate(twinGraph) ? {
                onDropCreate: onDropCreateDiagram,
                onDblClick: handleDiagramDblClick,
                onDeleted: onDeletedDiagram,
                onLink: onLinkDiagram,
                onChange: onChangeDiagram,
                onContextMenu: setContextMenuData,
                onClickEvents: handleClickEvents,
                onPaste,
              } : {
                isReadOnly: true,
              })}
            />
          </DiagramMap>
        </DiagramThemeContext.Provider>

        {leftBarExpanded ? (
          <LeftBar
            ref={refLeftBar}
            setEditTwinGraph={setEditTwinGraph}
            setEditBlockId={setEditBlockId}
            onView={onView}
            onClose={() => setLeftBarExpanded(false)}
          />
        ) : null}

        <RightBar
          ref={refRightBar}
          container="#twin-graph-container"
          blockIds={selectedIds}
          metaBlock={metaBlock}
          twinGraph={twinGraph}
        />

        <ContextMenu
          {...contextMenuData}
          ref={refContextMenu}
          container="#twin-graph-container"
          blockIds={selectedIds}
          mapView={mapView}
          onAction={handleActionContextMenu}
        />
      </div>

      {bulkSensorsType && bulkSensorIds.length ? (
        <BulkSensors
          type={bulkSensorsType}
          sensorIds={bulkSensorIds}
          onClose={() => {
            setBulkSensorsType(null)
            setBulkSensorIds([])
          }}
        />
      ) : null}

      {do {
        if (editBlockId) {
          <FormBlock
            id={editBlockId}
            onClose={() => {
              setEditBlockId(null)
              setEditTwinGraph(null)
            }}
            dialog={{
              title: intl.t('twinGraphs.titleUpdateBlock'),
              dataTestid: 'updateTwinBlockDialog',
            }}
          />
        } else if (editTwinGraph !== null) {
          <FormTwinGraph
            id={editTwinGraph}
            onClose={() => {
              setEditBlockId(null)
              setEditTwinGraph(null)
            }}
            dialog={{
              title: editTwinGraph ?
                intl.t('twinGraphs.titleUpdate') :
                intl.t('twinGraphs.titleCreate'),
              dataTestid: 'replaceTwinGraphDialog',
            }}
          />
        }
      }}
    </div>
  )
}
