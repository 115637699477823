import { useContext } from 'react'
import { FormInputSearch, FormSelect, FormSelectDateTimeRange } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { AppContext } from '@/pages/context'
import FormatDateTime from '@/containers/FormatDateTime'
import ListFilters from '@/components/Form/ListFilters'

const EnumIsPaid = [ 'true', 'false' ]

export default function Filters () {
  const { filters, onFilter } = useContext(AppContext)

  const intl = useIntl()

  return (
    <ListFilters
      filters={filters}
      onFilter={onFilter}
    >
      {({ filters, onFilter }) => (
        <>
          <div className="me-1 mb-2 col-md-2">
            <FormInputSearch
              placeholder={intl.t('form.actions.recipientPlaceholder')}
              value={filters.recipient}
              onChange={value => onFilter({ recipient: value })}
            />
          </div>

          <div className="me-1 mb-2 col-md-auto">
            <FormSelectDateTimeRange
              className="w-auto"
              placeholderFrom="From"
              placeholderTo="To"
              value={[ filters.from, filters.to ]}
              DateTimeComponent={FormatDateTime}
              onChange={([ from, to ]) => onFilter({ from, to })}
            />
          </div>

          <div className="mx-1 mb-2 col-md-3">
            <FormSelect
              placeholder={intl.t('sentSMSLogs.form.fields.isPaidPlaceholder')}
              options={EnumIsPaid.map(id => ({ id, name: intl.t(`enum.isPaid.${id}`) }))}
              value={filters.isPaid || ''}
              onChange={value => onFilter({ isPaid: value })}
            />
          </div>
        </>
      )}
    </ListFilters>
  )
}
