import { useFormContext } from 'react-hook-form'
import { FormField, FormControl } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function FieldDescription ({ scope }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <FormField
      label={intl.t('form.fields.description')}
      description={intl.t('label.form.fields.descriptionDescr')}
      errors={get(errors, `${scope}.description`)}
    >
      <FormControl
        type="textarea"
        name={`${scope}.description`}
        rules={{
          maxLength: {
            value: 450,
            message: intl.t('form.errors.fieldMaxlen', { max: 450 }),
          },
        }}
      />
    </FormField>
  )
}
