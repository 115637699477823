import withTableActions from '@/containers/withTableActions'
import Component from '@/components/Labels/List'

export default withTableActions({
  defaultFilters () {
    return {
      sortBy: 'createdDate',
    }
  },
})(Component)
