import { useIntl } from '@wiz/intl'
import Section from './Section'
import SectionInterface from './SectionInterface'

export default function SettingsSection ({ scope }) {
  const intl = useIntl()

  return (
    <>
      <Section
        scope={`${scope}.event`}
        title={intl.t('form.sections.event')}
        description={intl.t('events.form.sections.eventDescr')}
      />

      <SectionInterface
        scope={`${scope}.interfaces`}
      />
    </>
  )
}
