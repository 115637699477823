import {
  useState,
  useRef,
  useMemo,
  forwardRef,
} from 'react'
import classnames from 'classnames'
import {
  Dialog,
  Icon,
  withResizeObserver,
} from '@wiz/components'
import Draggable from 'react-draggable'
import { useIntl } from '@wiz/intl'
import Link from '@/components/Link'
import classes from './index.css'

const RightBar = forwardRef(({
  disabled,
  options,
  value,
  width,
  onChange,
  blocks,
}, ref) => {
  const [ isOpen, setOpen ] = useState(false)

  const intl = useIntl()
  const refNode = useRef(null)
  const id = value?.id
  const menuOptions = options.filter(item => !item.hidden)

  return (
    <Draggable
      axis="y"
      bounds="parent"
      handle=".draggable"
      nodeRef={refNode}
      positionOffset={{ x: -(width), y: 0 }}
    >
      <div
        ref={refNode}
        className={classnames(classes.root, {
          'bg-light rounded-2 shadow-sm p-2 m-2': menuOptions.length > 0,
        })}
      >
        {menuOptions.length ? (
          <div className="draggable opacity-25 d-flex justify-content-center p-2 mx-n1 mb-1">
            <Icon name="fa--grip-horizontal" />
          </div>
        ) : null}

        <div className="d-flex flex-column">
          {menuOptions.map((item, idx) => {
            const isDisabled = Boolean(disabled && item.canDisable)
            const isEditable = item.id === 'edit' ? !isDisabled && blocks.length === 1 : !isDisabled
            const className = classnames('btn py-2', {
              'mt-1': idx > 0,
              'btn-flat-primary': item.id === id && !isDisabled,
              'btn-flat-secondary': item.id !== id || !isEditable || isDisabled,
            })

            return item.path ? (
              <Link
                key={item.id}
                title={intl.t(item.title)}
                className={className}
                disabled={!isEditable || isDisabled}
                {...item.path}
              >
                <Icon name={item.icon} size="md" />
              </Link>
            ) : (
              <button
                key={item.id}
                type="button"
                title={intl.t(item.title)}
                className={classnames(className, 'd-flex align-items-center')}
                disabled={!isEditable || isDisabled}
                onClick={() => onChange(item.id, true)}
              >
                <span className={classnames(classes.btnText, 'flex-fill me-3 text-start', {
                // [classes.closed]: !isOpen,
                  'd-none': !isOpen,
                })}
                >
                  {intl.t(item.title)}
                </span>
                <Icon name={item.icon} size="md" />
              </button>
            )
          })}
        </div>
        <button
          type="button"
          className={classnames(classes.expand, 'btn btn-md bg-light rounded-circle p-1')}
          onClick={() => setOpen(!isOpen)}
          title="Expand menu"
        >
          {isOpen ? <Icon name="fa--caret-right" /> : <Icon name="fa--caret-left" />}
        </button>
      </div>
    </Draggable>
  )
})

const RightBarResize = withResizeObserver(RightBar)

const RightBarMenu = forwardRef(({
  refDialog,
  container = null,
  disabled,
  pinned,
  options,
  selectedProps,
  value,
  width,
  onClose,
  onChange,
  onPin,
  expanded,
  expandedClose,
  ...rest
}, ref) => {
  const intl = useIntl()
  const refDialogElement = useRef(null)

  const {
    back,
    props,
    id: dialogId,
    component: DialogComponent,
    title: dialogTitle,
  } = value || {}

  const onChangeBack = useMemo(() => (
    back && back !== dialogId ? () => onChange(back) : undefined
  ), [ back, dialogId, onChange ])

  const ComponentTitle = useMemo(() => ({
    children,
  }) => (
    <>
      {onChangeBack ? (
        <button
          type="button"
          className="btn btn-flat-secondary me-2"
          onClick={onChangeBack}
        >
          <Icon name="fa--chevron-left" size="md" />
        </button>
      ) : null}

      <h1 className="h4 flex-fill m-0">
        {children}
      </h1>
    </>
  ), [ onChangeBack ])

  return (
    <>
      <Dialog
        ref={refDialog}
        refDialog={refDialogElement}
        classNameBody="p-0 overflow-hidden"
        container={container}
        strategy="absolute"
        backdrop={false}
        pinned={pinned}
        expanded={expanded}
        expandedClose={expandedClose}
        size="sm"
        opened={Boolean(DialogComponent)}
        title={intl.t(dialogTitle)}
        ComponentTitle={ComponentTitle}
        resize
        dataTestid={`${dialogId}RightBarDialog`}
        onClose={onClose}
        onPin={onPin}
      >
        {DialogComponent ? (
          <DialogComponent
            {...props}
            {...selectedProps}
            container={container}
            onChangeBack={onChangeBack}
          />
        ) : null}
      </Dialog>

      <RightBarResize
        refResize={refDialogElement}
        disabled={disabled}
        options={options}
        value={value}
        width={width}
        onChange={onChange}
        blocks={selectedProps.blocks}
      />
    </>
  )
})

export default withResizeObserver(RightBarMenu)
