import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { Q, dbProvider, Twin } from '@wiz/store'
import { withObservables } from '@wiz/components'
import {
  orderBy, toArrayValue, consts, filterByTypes,
} from '@wiz/utils'

export default withObservables([
  'excludeFrom',
  'keyName',
  'search',
  'selectedOnly',
  'types',
  'value',
  'withConnections',
  'withHardware',
  'withLocation',
  'withSensors',
  'collection',
], ({
  excludeFrom,
  keyName,
  parentId,
  search,
  selectedOnly,
  types,
  value,
  withConnections,
  withHardware,
  withLocation,
  withSensors,
  collection = 'twins',
  excludeBusinessTypes = [],
}) => {
  const currentKeyName = keyName || 'id'
  const hasTypes = !!types?.length
  const currentWithSensors = withSensors ?? (!hasTypes || types.includes(consts.TwinType.Sensor))
  let queryTwins = dbProvider.database.collections.get(collection).query()
  let querySensors = dbProvider.database.collections.get('sensors').query()

  if (search) {
    const sanitizeSearch = Q.sanitizeLikeString(search)
    const condition = Q.or(
      Q.where('name', Q.like(`%${sanitizeSearch}%`)),
      Q.where('description', Q.like(`%${sanitizeSearch}%`)),
      Q.where('hardware_id', Q.like(`%${sanitizeSearch}%`)),
    )
    queryTwins = queryTwins.extend(condition)
    querySensors = querySensors.extend(condition)
  }

  if (parentId) {
    queryTwins = queryTwins.extend(Q.where('parent_id', parentId))
  }

  if (selectedOnly) {
    const key = currentKeyName === 'hardwareId' ? 'hardware_id' : currentKeyName
    const condition = Q.where(key, Q.oneOf(toArrayValue(value)))
    queryTwins = queryTwins.extend(condition)
    querySensors = querySensors.extend(condition)
  }

  if (withHardware) {
    const condition = Q.where('hardware_id', Q.notEq(null))
    queryTwins = queryTwins.extend(condition)
    querySensors = querySensors.extend(condition)
  }

  if (withLocation) {
    const condition = Q.or(Q.where('latitude', Q.notEq(null)), Q.where('longitude', Q.notEq(null)))
    queryTwins = queryTwins.extend(condition)
    querySensors = querySensors.extend(condition)
  }

  if (hasTypes || withConnections) {
    const currentTypes = toArrayValue(types)
    if (withConnections) {
      currentTypes.push(consts.TwinType.Flow)
    }
    queryTwins = queryTwins.extend(Q.where('type', Q.oneOf(currentTypes)))
  }

  if (excludeFrom) {
    queryTwins = queryTwins.extend(Twin.conditionExcludeChildren(excludeFrom))
  }
  let observe

  if (selectedOnly) {
    if (currentWithSensors) {
      observe = combineLatest(
        queryTwins.observeWithColumns([ 'updated_at' ]),
        querySensors.observeWithColumns([ 'updated_at' ]),
      ).pipe(map(items => [].concat(...items)))
    } else {
      observe = queryTwins.observeWithColumns([ 'updated_at' ])
    }
  } else if (currentWithSensors) {
    observe = combineLatest(
      queryTwins.observe(),
      querySensors.observe(),
    ).pipe(map(items => [].concat(...items)))
  } else {
    observe = queryTwins.observe()
  }

  return {
    options: observe.pipe(
      map(items => filterByTypes(excludeBusinessTypes, orderBy(
        items,
        [ 'type', item => item.displayName.toLowerCase() ],
        [ 'asc', 'asc' ],
      ))),
    ),
  }
})
