import cx from 'classnames'
import { Icon } from '@wiz/components'

const textByStatus = {
  error: 'text-danger',
  success: 'text-success',
  info: 'text-primary',
}

const LogsConsole = ({ isRunning, messages }) => (
  <div className="d-flex flex-column flex-fill">
    <h5>Console</h5>
    <div className={cx()}>
      {messages?.map(({ status, message, dt }) => (
        <div className={textByStatus[status]}>
          <span>{dt}</span>
          &nbsp;
          {message}
        </div>
      ))}
    </div>
    {/* <div className="position-relative position-center-fill bg-light opacity-50">
      <Icon name="fa--spinner" size="2X" spin />
    </div> */}
  </div>
)

export default LogsConsole
