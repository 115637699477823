import { of as of$ } from 'rxjs'
import { map, distinctUntilChanged } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { isEqual } from '@wiz/utils'
import { dbProvider, Setting } from '@wiz/store'

export default withObservables([
  'user',
  'defaultValues',
], ({
  user,
  defaultValues,
}) => ({
  accessRoleIds: user?.observeAccessRoles ?
    user.observeAccessRoles.pipe(map(items => items.map(item => item.id))) :
    of$(defaultValues?.accessRoleIds ?? []).pipe(distinctUntilChanged(isEqual)),

  twins: user?.queryRelTwins ?
    user.queryRelTwins.observe().pipe(map(items => items.map(item => item.twinId))) :
    of$(defaultValues?.twins ?? []).pipe(distinctUntilChanged(isEqual)),

  settings: Setting.observeSettings(dbProvider.database, user.id, [
    'DefaultTwin',
  ]),
}))
