import { forwardRef, useMemo } from 'react'
import { useAuth } from '@/auth'
import RightBarCommands from '@/containers/RightBarCommands'
import RightBarCreateCommand from '@/components/RightBarCreateCommand'
import RightBarDashboard from '@/containers/RightBarDashboard'
import RightBarDiagram from '@/components/RightBarDiagram'
import RightBarDigitalTwin from '@/containers/RightBarDigitalTwin'
import RightBarEvents from '@/containers/RightBarEvents'
import RightBarExplorer from '@/containers/RightBarExplorer'
import RightBarInfo from '@/containers/RightBarInfo'
import RightBarInfoMeasurements from '@/containers/RightBarInfo/Measurements'
import RightBarInfoSetPoints from '@/containers/RightBarInfo/SetPoints'
import RightBarInputSensorValue from '@/components/RightBarInputSensorValue'
import RightBarLinked from '@/containers/RightBarLinked'
import RightBarStreamJob from '@/containers/RightBarStreamJob'
import RightBarWizard from '@/containers/RightBarWizard'
import RightBarSolutionsList from '@/components/RightBarSolutionsList'
import RightBarConnectionEdit from '@/containers/RightBarConnectionEdit'
import RightBarResults from '@/containers/ExperimentResult'

const RightBarItems = [
  {
    id: 'commands',
    icon: 'fa--terminal',
    title: 't/edge.commands.title',
    canDisable: true,
    component: RightBarCommands,
    access: auth => auth.checkAccessManage('SectionDeviceCommands'),
  },
  {
    id: 'events',
    icon: 'fa--calendar',
    title: 't/events.title',
    canDisable: true,
    component: RightBarEvents,
    access: auth => auth.checkAccessManage('SectionEvents'),
  },
  {
    id: 'sensors',
    icon: 'fa--sensor',
    title: 't/sensors.titleLinked',
    canDisable: true,
    component: RightBarInfo,
    access: auth => auth.checkAccessManage('SectionEvents'),
  },
  {
    id: 'setPoints',
    icon: 'fa--file-signature',
    title: 't/sensors.titleLinkedSetPoints',
    canDisable: true,
    component: RightBarInfoSetPoints,
    access: auth => auth.checkAccessManage('SectionDigitalTwinSensors'),
  },
  {
    id: 'measurements',
    icon: 'fa--ruler',
    title: 't/sensors.titleLinkedMeasurements',
    canDisable: true,
    component: RightBarInfoMeasurements,
    access: auth => auth.checkAccessManage('SectionDigitalTwinSensors'),
  },
  {
    id: 'info',
    icon: 'fa--info-circle',
    title: 't/twin.titleLinked',
    canDisable: true,
    component: RightBarLinked,
  },
  {
    id: 'solutions',
    icon: 'fa--flask',
    title: 't/experiments.titleRightBar',
    canDisable: false,
    component: RightBarSolutionsList,
  },
  {
    id: 'dashboard',
    icon: 'fa--info-circle',
    hidden: true,
    component: RightBarDashboard,
    access: auth => auth.checkAccessManage('SectionDashboards'),
  },
  {
    id: 'explorer',
    icon: 'fa--info-circle',
    hidden: true,
    component: RightBarExplorer,
    access: auth => auth.checkAccessManage('SectionDataExplorer'),
  },
  {
    id: 'streamJob',
    icon: 'fa--info-circle',
    hidden: true,
    component: RightBarStreamJob,
    access: auth => auth.checkAccessManage('SectionStreamJobs'),
  },
  {
    id: 'digitalTwin',
    icon: 'fa--info-circle',
    hidden: true,
    component: RightBarDigitalTwin,
    access: auth => auth.checkAccessManage('SectionDigitalTwinItems'),
  },
  {
    id: 'createCommand',
    icon: 'fa--terminal',
    title: 't/edge.commands.titleCreate',
    hidden: true,
    component: RightBarCreateCommand,
    access: auth => auth.checkAccessManage('SectionDeviceCommands'),
  },
  {
    id: 'inputSensorValue',
    icon: 'fa--pen-square',
    title: 't/form.actions.inputSensorValue',
    hidden: true,
    component: RightBarInputSensorValue,
    access: auth => auth.checkAccessManage('SectionDigitalTwinSensors'),
  },
  {
    id: 'runWizard',
    icon: 'fa--lightbulb',
    title: 't/experiments.titleWizard',
    hidden: true,
    component: RightBarWizard,
  },
  {
    id: 'experiment',
    icon: 'fa--lightbulb',
    hidden: true,
    component: RightBarResults,
  },
  {
    id: 'editConnection',
    icon: 'fa--pen-square',
    title: 'Edit connection',
    hidden: true,
    component: RightBarConnectionEdit,
  },
]

const RightBarTwinGraph = forwardRef((props, ref) => {
  const auth = useAuth()
  const options = useMemo(() => (
    RightBarItems
      .filter(item => (!item.access || item.access(auth)))
      .map(item => ({
        ...item,
        hidden: item.hidden,
      }))
  ), [ auth ])

  return (
    <RightBarDiagram
      ref={ref}
      {...props}
      options={options}
    />
  )
})

export default RightBarTwinGraph
