import { define } from 'superstruct'
import { intl } from '@/i18n'

const SlidingDateTo = ({
  keyFrom = 'dateFrom',
} = {}) => define('SlidingDateTo', (value, { branch }) => {
  const data = branch[branch.length - 2]
  let result = true
  if (!value && data[keyFrom]) {
    result = intl.t('form.errors.fieldRequired')
  } else if (value && data[keyFrom] && value <= data[keyFrom]) {
    result = intl.t('form.errors.dateToOutofrange')
  }
  return result
})

export default SlidingDateTo
