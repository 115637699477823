import Icon from '@/containers/Twin/Icon'
import classes from './Marker.css'

export default function Marker ({ value }) {
  return (
    <div className={classes.root}>
      <div className={classes.marker}>
        <Icon
          className={classes.img}
          twin={value}
          size="sm"
        />
      </div>
    </div>
  )
}
