import {
  useMemo,
} from 'react'
import { map, of } from 'rxjs'
import { useRouter, useLocationQuery } from '@/router'
import { Icon, withObservables } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { dbProvider, Q } from '@wiz/store'

const enhance = withObservables([ 'name', 'id' ], ({ name, id }) => ({
  user: name === 'ownerId' ? dbProvider.database.collections.get('users')
    .query(Q.where('id', id))
    .observeWithColumns([ 'updated_at' ])
    .pipe(map(items => items[0])) : of(undefined),
}))

const CellExternalLink = ({
  id,
  list,
  name,
  page,
  user,
  embed,
  allowed,
}) => {
  const intl = useIntl()
  const router = useRouter()
  const query = useLocationQuery()
  const onClick = () => {
    if (name === 'templateId' && !embed) {
      router.push({ name: 'template', params: { id } })
    } else if (name) {
      router.push({ query: { ...query, page, [name]: id } })
    }
  }

  const data = useMemo(() => {
    if (user) {
      return user
    }

    if (id && list?.data?.length) {
      const next = list.data.find(item => item.id === id)
      return next
    }
    return undefined
  }, [ id, list, user ])

  if (list?.isLoading) {
    return (
      <Icon
        name="fa--spinner"
        spin
      />
    )
  }

  if (name !== 'templateId' && !embed) {
    return id ? (
      <div className="text-truncate min-w-0">
        {data?.name}
      </div>
    ) : '-'
  }

  return id ? (
    <div className="min-w-0 d-flex">
      {data ? (
        allowed ? (
          <a
            className="text-truncate pointer"
            onClick={() => onClick()}
            aria-hidden
          >
            <span>{data.name || data.key}</span>
          </a>
        ) : <span>{data.name || data.key}</span>
      ) : '-'}

    </div>
  ) : '-'
}

export default enhance(CellExternalLink)
