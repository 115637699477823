import { withObservables } from '@wiz/components'
import { dbProvider } from '@wiz/store'
import Component from '@/components/LeftBarConnections'

const enhanceData = withObservables([], () => ({
  twinSettings: dbProvider.observeGlobalSettings([
    'twinColorAreas',
    'twinColorMachines',
    'twinColorEquipment',
  ]),
}))

export default enhanceData(Component)
