/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import {
  useEffect, useMemo, useRef, useCallback,
} from 'react'
import cx from 'classnames'
import {
  VirtualTable,
  Card,
  useMobile,
  Icon,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import classes from './index.module.css'

const Integrations = ({
  options,
  installedOptions,
  settings,
  onAction,
  onActivate,
  ...props
}) => {
  const intl = useIntl()
  const auth = useAuth()
  const isMobile = useMobile()
  const { saveScrollPosition, boards } = useGlobalExecute()
  const ref = useRef()
  const refScrollPos = useRef(boards.scrollPosition)

  const columns = useMemo(() => [
    {
      accessor: 'name',
      className: 'd-flex pb-2',
      Cell: ({ row, cell }) => (
        <Card
          className="flex-fill"
          classNameBody={cx('mt-0 justify-content-end', {
            'mb-1': isMobile,
          })}
          title={(
            <a
              onClick={() => onAction(row.original, { id: 'edit' })}
              className="text-truncate h5 pointer"
              aria-hidden="true"
            >
              {cell.value}
            </a>
          )}
          options={(
            [
              {
                id: 'edit',
                label: intl.t('form.actions.edit'),
                icon: 'fa--pen',
              },
              {
                id: 'remove',
                label: intl.t('form.actions.remove'),
                icon: 'fa--trash-alt',
              },
            ]
          )}
          onAction={action => onAction?.(row.original, action)}
        >
          <div className="d-flex">
            {row.original.description ? (
              <div className="flex-shrink-0 text-muted text-truncate">
                {row.original.description}
              </div>
            ) : null}
            <Icon name="wiz--logo--aws-logo" width={48} className="ms-auto" />
          </div>

          {isMobile ? null : (
            <>
              <div className="d-flex align-items-center">
                <b className="me-1">
                  {intl.t('form.fields.created')}
                  :
                </b>
                <UpdatedInfo date={row.original.createdAt} />
              </div>

              <div className="d-flex align-items-center">
                <b className="me-1">
                  {intl.t('form.fields.author')}
                  :
                </b>
                <UpdatedInfo
                  user={row.original.createdUser}
                  userClassName=""
                />
              </div>

              <div className="d-flex align-items-center flex-wrap text-nowrap">
                <div className="d-flex align-items-center me-1">
                  <b className="me-1">
                    {intl.t('form.fields.lastModified')}
                    :
                  </b>
                  <UpdatedInfo date={row.original.updatedAt} />
                </div>
                {intl.t('form.fields.by', { value: ' ' })}
                &nbsp;
                <UpdatedInfo
                  user={row.original.updatedUser}
                  userClassName=""
                />
              </div>

              <div className="d-flex align-items-center">
                <b className="me-1">
                  {intl.t('form.fields.status')}
                  :
                </b>
                <div className={cx(classes.status, { [classes.active]: row.original.isOn })}>
                  <div className="form-check form-switch">
                    <input className="form-check-input pointer" type="checkbox" id="checkIsActive" checked={row.original.isOn} onChange={() => onActivate(row.original)} />
                    <label className="form-check-label pointer" htmlFor="checkIsActive">{row.original.isOn ? 'active' : 'not active'}</label>
                  </div>

                </div>
              </div>
            </>
          )}
          <div className={row.original.isOn ? classes.on : classes.off} />
        </Card>
      ),
    },
  ], [
    intl,
    isMobile,
    onAction,
    onActivate,
  ])

  const handleScroll = useCallback((event) => {
    refScrollPos.current = event?.target?.scrollTop ?? boards.scrollPosition
  }, [ boards.scrollPosition ])

  const itemSize = useCallback((idx) => {
    const item = options[idx]
    let height = isMobile ? 45 : 135
    if (item.description) {
      height += 25
    }
    if (!isMobile) {
      height += 30
    }
    return height
  }, [ options, isMobile ])

  useEffect(() => {
    ref.current?.scrollTo({ top: boards.scrollPosition })
    return () => {
      saveScrollPosition({ entity: 'boards', scrollPosition: refScrollPos.current })
    }
  }, [])

  return (
    <VirtualTable
      className="flex-fill no-hover"
      onScroll={handleScroll}
      forwardedRef={(r) => {
        ref.current = r
      }}
      columns={columns}
      data={options}
      itemSize={itemSize}
      estimatedItemSize={135}
      selection={false}
      showHeader={false}
      placeholder={(
        <div className="flex-column position-center-fill position-absolute-fill">
          {intl.t('errors.noDataDisplay')}
        </div>
      )}
      resetCellStyle
      {...props}
    />
  )
}

export default Integrations
