/* eslint-disable no-unused-expressions */
import { useState, useCallback } from 'react'
import { Icon, FormInputSearch, useMobile } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import Notebook from '@/hoc/Notebook'
import FormExistingNotebook from '@/containers/Forms/ExistingNotebook'
import FormNotebook from '@/containers/Forms/Notebook'
import FormNotebookPreview from '@/containers/Forms/NotebookPreview'
import Table from '@/containers/Notebook/Table'
import TableMobile from '@/containers/Notebook/TableMobile'
import ViewModel from './ViewModel'

const ViewModelData = Notebook(ViewModel)

export default function List ({
  filters,
  selected,
  onFilter,
  onResetFilter,
  onRemove,
  onExport,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const isMobile = useMobile()
  const [ editId, setEditId ] = useState(null)
  const [ viewId, setViewId ] = useState(null)
  const [ previewId, setPreviewId ] = useState(null)
  const [ addExisting, setAddExisting ] = useState(false)

  const TableComponent = isMobile ? TableMobile : Table

  const handleCloseForm = useCallback(() => {
    setEditId(null)
    setPreviewId(null)
    setAddExisting(false)
  }, [])

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">

      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <div className="d-flex align-items-center flex-wrap">
          {(
            auth.checkAccessCreate('Notebook') ||
            auth.checkAccessRemove('Notebook')
          ) ? (
            <div className="px-2 mb-2 flex-shrink-0">
              {auth.checkAccessCreate('Notebook') ? (
                <button
                  type="button"
                  className="btn btn-flat-secondary"
                  onClick={() => setEditId('')}
                >
                  <Icon name="fa--plus" className="me-1" />
                  {intl.t('form.actions.add')}
                </button>
              ) : null}

              {auth.checkAccessCreate('Notebook') ? (
                <button
                  type="button"
                  className="btn btn-flat-secondary"
                  onClick={() => setAddExisting(true)}
                >
                  <Icon name="fa--external-link-alt" className="me-1" />
                  {intl.t('form.actions.addExisting')}
                </button>
              ) : null}

              {auth.checkAccessRemove('Notebook') ? (
                <button
                  type="button"
                  className="btn btn-flat-secondary"
                  onClick={() => onRemove(selected)}
                  disabled={!selected.length}
                >
                  <Icon name="fa--trash-alt" className="me-1" />
                  {intl.t('form.actions.remove')}
                </button>
              ) : null}
            </div>
            ) : null}

          <div className="d-flex align-items-center">
            <div className="px-2 mb-2 col-sm-auto">
              <FormInputSearch
                placeholder={intl.t('form.actions.searchPlaceholder')}
                value={filters.search}
                onChange={value => onFilter({ search: value })}
              />
            </div>

            <div className="mb-2">
              <button
                type="button"
                className="btn btn-fill-secondary text-nowrap"
                onClick={onResetFilter}
              >
                {intl.t('form.actions.resetFilters')}
              </button>
            </div>
          </div>
        </div>

        <TableComponent
          filters={filters}
          onRemove={onRemove}
          onEdit={setEditId}
          onExport={onExport}
          onPreview={setPreviewId}
          onView={setViewId}
          {...props}
        />
      </div>

      {previewId ? (
        <FormNotebookPreview
          id={previewId}
          onClose={handleCloseForm}
          dialog={{
            title: intl.t('notebooks.titlePreview'),
            dataTestid: 'notebookPreviewDialog',
          }}
        />
      ) : null}

      {addExisting ? (
        <FormExistingNotebook
          onClose={handleCloseForm}
          dialog={{
            title: intl.t('notebooks.titleAddExisting'),
            dataTestid: 'existingNotebookDialog',
          }}
        />
      ) : null}

      {editId !== null ? (
        <FormNotebook
          id={editId}
          onClose={handleCloseForm}
          dialog={{
            title: editId ? intl.t('notebooks.titleUpdate') : intl.t('notebooks.titleCreate'),
            dataTestid: 'replaceNotebookDialog',
          }}
        />
      ) : null}

      {viewId ? (
        <ViewModelData
          id={viewId}
          title={intl.t('notebooks.titleDetail')}
          onClose={() => setViewId(null)}
          onRemove={onRemove}
          onEdit={setEditId}
          onExport={onExport}
          onPreview={setPreviewId}
          dialog
        />
      ) : null}
    </div>
  )
}
