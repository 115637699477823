import {
  useRef, useCallback, useEffect, useState,
} from 'react'
import union from 'lodash/union'
import difference from 'lodash/difference'
import { Q, dbProvider } from '@wiz/store'
import { toArrayValue } from '@wiz/utils'
import { withProps } from '@wiz/components'

export default withProps(({
  businessType,
  blockSettings,
}) => {
  const [ sensorIds, setSensorIds ] = useState(undefined)
  const blockSettingsRef = useRef([])

  const onAttachSensors = useCallback(async (blockSettings, ids) => {
    const sensors = await dbProvider.database.collections.get('sensors')
      .query(Q.where('id', Q.oneOf(ids)))
      .fetch()

    const context = dbProvider.createBatchContext()
    for (const sensor of sensors) {
      await sensor.prepareLinkTo(context, blockSettings)
    }
    await dbProvider.batch(context)
  }, [])

  const onToggleByTwin = useCallback(async (twin) => {
    let query = dbProvider.database.collections.get('sensors')
      .query(Q.where('twin_id', twin.id))

    if (businessType) {
      query = query.extend(Q.where('business_type', businessType))
    }

    const sensors = await query.fetch()
    const toggleIds = sensors.map(item => item.id)
    const diff = difference(toggleIds, sensorIds)
    if (diff.length) {
      setSensorIds(union(sensorIds, toggleIds))
    } else {
      setSensorIds(difference(sensorIds, toggleIds))
    }
  }, [ sensorIds, businessType ])

  const onToggleAll = useCallback((sensors) => {
    const toggleIds = sensors.map(item => item.id)
    const diff = difference(toggleIds, sensorIds)

    if (diff.length) {
      setSensorIds(union(sensorIds, toggleIds))
    } else {
      setSensorIds(difference(sensorIds, toggleIds))
    }
  }, [ sensorIds, businessType ])

  const onChangeSelection = useCallback((id) => {
    const arrayIds = toArrayValue(sensorIds)
    if (arrayIds.includes(id)) {
      setSensorIds(arrayIds.filter(item => item !== id))
    } else {
      setSensorIds(arrayIds.concat(id))
    }
  }, [ sensorIds ])

  const onBeforeAction = useCallback((action) => {
    if (action === 'unlink') {
      setSensorIds([])
    }
  }, [])

  const onUnlinkSensors = async () => {
    const sensors = await dbProvider.database.collections.get('sensors')
      .query(Q.where('id', Q.oneOf(sensorIds)))
      .fetch()

    const context = dbProvider.createBatchContext()
    for (const sensor of sensors) {
      // eslint-disable-next-line no-await-in-loop
      await sensor.prepareUnlink(context)
    }
    await dbProvider.batch(context)
    setSensorIds([])
  }

  useEffect(() => {
    const settings = Object.keys(blockSettings)
    const removeUnrelatedSensors = async (twinIds) => {
      const query = dbProvider.database.collections.get('sensors').query(Q.where('twin_id', Q.oneOf(twinIds)))

      const sensors = await query.fetch().then(items => items.map(sensor => sensor.id))
      // const diff1 = difference(sensors, sensorIds)
      const next = difference(sensorIds, sensors)

      setSensorIds(next)
    }

    const diff = difference(blockSettingsRef.current, settings)

    if (diff.length) {
      removeUnrelatedSensors(diff)
      // setSensorIds([])
    }
    blockSettingsRef.current = settings
  }, [ blockSettings ])

  return {
    sensorIds,
    onAttachSensors,
    onToggleByTwin,
    onToggleAll,
    onChangeSelection,
    onBeforeAction,
    onUnlinkSensors,
  }
})
