/* eslint-disable no-console */
import { Observable } from 'rxjs'
import {
  dbProvider,
  DBSyncError,
  DBSyncPushError,
  DBSyncPullError,
  DBSyncFirstPullError,
} from '@wiz/store'
import {
  network,
  UnauthorizedError,
  ForbiddenError,
} from '@wiz/utils'
import { wizataApi } from '@/api'
import { intl } from '@/i18n'
import { auth } from '@/auth'
import events from '@/utils/events'
import * as analytics from '@/utils/analytics'

function handleStatusUpdate ({ status, lastLog }) {
  if (status === 'synced') {
    network.setOnline()
    events.emit('app:notify:remove', {
      id: '1a47af15-5712-4ae8-a80f-d8928de34020',
    })
  } else if (network.onLine && lastLog?.error) {
    events.emit('app:notify', {
      id: '1a47af15-5712-4ae8-a80f-d8928de34020',
      group: 'sync',
      type: 'error',
      title: 't/errors.syncTitle',
      duration: -1,
      rawMessage: intl.t('errors.syncMessage', { message: lastLog.error.message }),
    })
  }
}

export default function observeDBMount () {
  return new Observable((subscriber) => {
    let sync
    let handleSyncedSuccess
    const accountId = auth.getAuthAccountId()

    function handleError (error) {
      console.error(error)
      sync?.removeListener('synced', handleSyncedSuccess)
      sync?.removeListener('error', handleError)
      analytics.captureException(error)

      if (
        (error instanceof UnauthorizedError) ||
        (error instanceof ForbiddenError)
      ) {
        auth.logout()
      } else if (
        (error instanceof DBSyncPullError) ||
        (error instanceof DBSyncPushError) ||
        (error instanceof DBSyncError)
      ) {
        handleSyncedSuccess()
      } else {
        const loader = document.getElementById('startup-loader')
        const onCommand = () => {
          loader.show({ title: 'Reset local data...' })
          dbProvider.scheduledResetDatabase()
        }

        if (error instanceof DBSyncFirstPullError) {
          loader.show({
            status: 'sync-error',
            message: error.message,
            onCommand,
          })
        } else {
          loader.show({
            status: 'internal-error',
            onCommand,
          })
        }
      }
    }

    handleSyncedSuccess = () => {
      sync.removeListener('synced', handleSyncedSuccess)
      sync.removeListener('error', handleError)
      subscriber.next(accountId)
    }

    async function mount () {
      try {
        await dbProvider.initDatabase(accountId)
        sync = dbProvider.initSync(wizataApi.sync)
        sync.once('synced', handleSyncedSuccess)
        sync.once('error', handleError)
        sync.on('status', handleStatusUpdate)
        sync.sync()
      } catch (error) {
        handleError(error)
      }
    }

    mount()

    return () => {
      sync?.removeListener('status', handleStatusUpdate)
      sync?.removeListener('synced', handleSyncedSuccess)
      sync?.removeListener('error', handleError)
    }
  })
}
