import { FormSection } from '@wiz/components'
import Fields from '@/components/Forms/DataExplorerView/Fields'

const FormFieldRatio = [ 7, 5 ]

export default function SectionView ({
  scope,
  config,
  ...props
}) {
  return (
    <FormSection {...props}>
      <Fields
        scope={`${scope}.dataFilter`}
        fieldRatio={FormFieldRatio}
      />
    </FormSection>
  )
}
