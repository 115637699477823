import {
  forwardRef,
  useRef,
  useEffect,
  useContext,
  useMemo,
} from 'react'
import DataExplorer from '@/context/DataExplorer'
import useSplit from '@/hooks/useSplit'
import Filter from './Filter'
import Chart from './Chart'
import SourceIntervals from './SourceIntervals'

const DefaultSplitSizes = [ 0, 100 ]

const LayoutExplore = forwardRef((_, ref) => {
  const refSectionIntervals = useRef()
  const refSectionChart = useRef()
  const refChart = useRef()
  const context = useContext(DataExplorer)

  const splitItems = useMemo(() => [
    refSectionIntervals,
    refSectionChart,
  ], [])

  const refSplit = useSplit({
    items: splitItems,
    sizes: context.settings.explorerViewSplitInterval,
    defaultSizes: DefaultSplitSizes,
    onChange: context.updateSplitInterval,
  })

  useEffect(() => {
    if (context.settings.explorerViewFullscreen) {
      refSplit.current.collapse(0)
    }
  }, [
    context.settings.explorerViewFullscreen,
    refSplit,
  ])

  return (
    <div ref={ref} className="d-flex flex-column min-h-0 min-w-0">
      <div
        ref={refSectionIntervals}
        className="position-relative overflow-hidden bg-white"
      >
        <SourceIntervals />
      </div>

      <div
        ref={refSectionChart}
        className="overflow-hidden d-flex flex-column"
      >
        <Filter
          className="mx-2"
          hasSaveExplorer={!context.readOnly}
          onSaveAsImage={() => refChart.current.saveAsImage()}
          onExploreValues={() => refChart.current.exploreValues()}
          onDownloadCSV={() => refChart.current.downloadCSV()}
        />

        <Chart
          ref={refChart}
          className="d-flex flex-fill position-relative overflow-hidden m-2"
        />
      </div>
    </div>
  )
})

LayoutExplore.displayName = 'LayoutExplore'
export default LayoutExplore
