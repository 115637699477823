import { of as of$ } from 'rxjs'
import { isEmpty } from '@wiz/utils'
import { Q, dbProvider } from '@wiz/store'
import { withObservables } from '@wiz/components'

const enhanceData = withObservables([
  'model',
  'field',
  'value',
], ({
  model,
  field,
  value,
}) => {
  const query = isEmpty(value) ? of$([]) : dbProvider.database.collections.get(model.table)
    .query(Q.where(field || 'id', Array.isArray(value) ? Q.oneOf(value) : value))
    .observeWithColumns([ 'updated_at' ])

  return {
    data: query,
  }
})

const ObserveModel = enhanceData(({ children, ...props }) => children(props))
ObserveModel.displayName = 'ObserveModel'
export default ObserveModel
