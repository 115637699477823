import { useMemo } from 'react'
import FormDialog from '@/components/Form/FormDialog'
import Removed from '../Removed'
import Section from './Section'

export default function ExecutionScript ({
  id,
  item,
  dialog,
  onClose,
}) {
  const defaultValues$ = useMemo(() => ({
    script: item,
  }), [ item ])

  if (id && !item) {
    return <Removed />
  }

  return (
    <FormDialog
      onClose={onClose}
      defaultValues={defaultValues$}
      dataTestid="scriptForm"
      dialog={dialog}
    >
      <Section scope="script" />
    </FormDialog>
  )
}
