import { useCallback, useEffect } from 'react'
import { of as of$, throwError, Observable } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import {
  Q,
  dbProvider,
  duplicateActionsIterator,
  duplicateActionsPairsIterator,
} from '@wiz/store'
import { withProps, withObservables, useDrag } from '@wiz/components'
import { getIconByName } from '@wiz/icons'
import { consts, mergeElementLocations, duplicateElementState } from '@wiz/utils'
import { createLocation, useRouter } from '@/router'
import defaultDiagramState from '@/utils/defaultDiagramState'
import Component from '@/components/TwinGraph/View'
import enhanceRunContext from './enhanceRunContext'

const ColorByType = {
  area: 'twinColorAreas',
  machine: 'twinColorMachines',
  equipment: 'twinColorEquipment',
  nestedTwinGraph: 'twinColorNestedTwinGraph',
}

function observeRequest (requestId) {
  return new Observable((subscriber) => {
    async function parseRequest (id) {
      let ids = decodeURIComponent(id).split('/')

      if (ids.length === 1) {
        const [ twinGraph ] = await dbProvider.database.collections.get('twin_graphs')
          .query(Q.where('id', ids[0]))
          .fetch()

        if (twinGraph) {
          ids = [ twinGraph.blockId ]
        }
      }

      const blocks = await dbProvider.database.collections.get('diagram_blocks')
        .query(Q.where('id', Q.oneOf(ids)))
        .fetch()

      if (blocks.length !== ids.length) {
        throw new Error('Twin Request: number of blocks not equal')
      }

      const nestedTwinGraphs = ids.map(item => blocks.find(block => (
        block.id === item &&
        block.type === 'twinGraph'
      ))).filter(item => !!item)

      const selectedBlockId = ids[ids.length - 1]
      const selectedBlock = blocks.find(block => (block.id === selectedBlockId))
      let selectedMetaBlock = selectedBlock.type === 'twinGraph' ?
        selectedBlock :
        nestedTwinGraphs[nestedTwinGraphs.length - 1]

      if (!selectedMetaBlock) {
        selectedMetaBlock = await selectedBlock.fetchRootTwinGraph()
        if (!selectedMetaBlock) {
          throw new Error('Twin Request: twin graph not found')
        }
        nestedTwinGraphs.push(selectedMetaBlock)
      }

      return {
        queryBlock: selectedBlock.id,
        queryMetaBlock: selectedMetaBlock.id,
        scrollToBlock: selectedBlock.id !== selectedMetaBlock.id ? selectedBlock.id : undefined,
        nestedTwinGraphs: nestedTwinGraphs.map(item => item.id),
      }
    }

    parseRequest(requestId)
      .then(data => subscriber.next(data))
      .catch(error => subscriber.error(error))
  })
}

// TODO check that chain of parents is correct
const enhanceRequest = withObservables([ 'match' ], ({ match }) => ({
  request: observeRequest(match.params.id),
}), {
  onError (error, { history, match }) {
    history.push(createLocation({
      name: 'chart-list',
      params: match.params,
    }))
  },
})

const enhanceBlock = withObservables([ 'request' ], ({ request }) => ({
  metaBlock: dbProvider.database.collections.get('diagram_blocks')
    .query(Q.where('id', request.queryMetaBlock), Q.where('type', 'twinGraph'))
    .observeWithColumns([ 'updated_at' ])
    .pipe(
      mergeMap(items => (
        items.length ? of$(items[0]) : throwError(`Meta Block not found: ${request.queryMetaBlock}`)
      )),
    ),
}), {
  onError (error, { history, match }) {
    history.push(createLocation({
      name: 'chart-list',
      params: match.params,
    }))
  },
})

const enhanceDetails = withObservables([ 'metaBlock' ], ({ metaBlock }) => ({
  twinGraph: metaBlock ? (
    metaBlock.observeFullSettings
      .pipe(
        mergeMap(item => (
          item ? of$(item) : throwError('Twin Graph not found')
        )),
      )
  ) : throwError('Twin Graph not found'),

  mapView: dbProvider.observeSettings('DiagramMapView'),

  twinSettings: dbProvider.observeGlobalSettings([
    'twinColorAreas',
    'twinColorMachines',
    'twinColorEquipment',
    'twinColorSensorsHardware',
    'twinColorSensorsVirtual',
    'twinColorNestedTwinGraph',
  ]),
}), {
  onError (error, { history, match }) {
    history.push(createLocation({
      name: 'chart-list',
      params: match.params,
    }))
  },
})

const enhanceTwinGraphState = withObservables([ 'twinGraph' ], ({ twinGraph }) => ({
  diagramState: twinGraph.observeDiagramState.pipe(
    defaultDiagramState(twinGraph),
  ),
}))

const enhanceProps = withProps(({
  diagramState,
  history,
  mapView,
  match,
  metaBlock,
  request,
  twinGraph,
  twinSettings,
}) => {
  const { hideGrid } = diagramState.diagram
  const drag = useDrag()
  const router = useRouter()

  const blockFactory = useCallback(async (block) => {
    const iconSource = block.icon && getIconByName(block.icon)
    const color = block.color || twinSettings[ColorByType[block.type]]
    let icon = 'Rectangle'
    // let size = 'NaN NaN'

    if (iconSource) {
      icon = iconSource.sprite
      // size = `${iconSource.width} ${iconSource.height}`
    }

    return {
      ...block,
      icon,
      // size,
      color,
    }
  }, [ twinSettings ])

  const onClickCreate = useCallback(async ({ type, model, state }) => {
    const context = dbProvider.createBatchContext().setContextBlock(metaBlock)
    const block = await metaBlock.prepareReplaceBlock(context, { type })
    if (model) {
      await block.prepareLinkSettings(context, { model })
    }
    await metaBlock.prepareReplaceHyperedge(context, block)
    if (state) {
      await metaBlock.prepareReplaceBlockState(context, block, state)
    }
    await dbProvider.batch(context)
  }, [ metaBlock ])

  const onDropCreateDiagram = useCallback(async (blockState, parentNode) => {
    let parentBlock = metaBlock
    if (parentNode?.id) {
      try {
        parentBlock = await metaBlock.collection.find(parentNode.id)
      } catch (error) {
        // empty
      }
    }

    const currentBlockState = mergeElementLocations(
      blockState,
      parentNode,
      diagramState.elements[parentNode?.id],
    )

    const { type } = drag.context
    const dragData = drag.data
    drag.clear()

    if (consts.DiagramBlockTypes.includes(type)) {
      if (dragData.size) {
        const context = dbProvider.createBatchContext().setContextBlock(metaBlock)
        for (const model of dragData) {
          const block = await metaBlock.prepareReplaceBlock(context, { type })
          await block.prepareLinkSettings(context, { model })
          await parentBlock.prepareReplaceHyperedge(context, block)
          await metaBlock.prepareReplaceBlockState(context, block, currentBlockState)
        }
        await dbProvider.batch(context)
      } else {
        const context = dbProvider.createBatchContext().setContextBlock(metaBlock)
        const block = await metaBlock.prepareReplaceBlock(context, { type })
        await parentBlock.prepareReplaceHyperedge(context, block)
        await metaBlock.prepareReplaceBlockState(context, block, currentBlockState)
        await dbProvider.batch(context)
      }
    } else if (dragData.size) {
      if (parentNode && parentBlock && parentBlock !== metaBlock) {
        const [ settings ] = await parentBlock.querySettings.fetch()
        const items = await dbProvider.database.collections
          .get('sensors')
          .query(
            Q.where('id', Q.oneOf(Array.from(dragData))),
            Q.where('twin_id', null),
          )
          .fetch()

        const context = dbProvider.createBatchContext()
        for (const item of items) {
          await item.prepareLinkTo(context, settings)
        }
        await dbProvider.batch(context)
      }
    }
  }, [
    diagramState,
    metaBlock,
    drag,
  ])

  const onDeletedDiagram = useCallback(async (ids) => {
    await window.wizConfirm({ message: 't/twinGraph.confirmDeleteBlock' })
    const context = dbProvider.createBatchContext().setContextBlock(metaBlock)
    await metaBlock.prepareStrictRemoveByIds(context, ids)
    await dbProvider.batch(context)
  }, [ metaBlock ])

  const onLinkDiagram = useCallback(async (data) => {
    const next = { ...data, type: 'flow' }
    const {
      fromId,
      fromPort,
      toId,
      toPort,
      hyperedge,
      ...blockData
    } = next
    const context = dbProvider.createBatchContext().setContextBlock(metaBlock)
    const block = await metaBlock.prepareReplaceBlock(context, blockData)
    await block.prepareReplaceSettings(context, {
      flow: {
        fromId,
        fromPort,
        toId,
        toPort,
        hyperedge,
      },
    })
    await dbProvider.batch(context)
  }, [ metaBlock ])

  const onChangeDiagram = useCallback(async ({ elements, diagram }) => {
    const context = dbProvider.createBatchContext()
    await twinGraph.prepareUpdateElementsState(context, elements)
    await twinGraph.prepareUpdateDiagramState(context, diagram)

    if (mapView) {
      await twinGraph.prepareUpdateSettingsFromGraphState(
        context,
        { elements },
      )
    }

    await dbProvider.batch(context)
  }, [ twinGraph, mapView ])

  const onToggleGrid = useCallback(async () => {
    const context = dbProvider.createBatchContext()
    await twinGraph.prepareUpdateDiagramState(context, 'hideGrid', !hideGrid)
    await dbProvider.batch(context)
  }, [ twinGraph, hideGrid ])

  const onToggleMapView = useCallback(async () => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareToggleSetting(context, 'DiagramMapView')
    await dbProvider.batch(context)
  }, [])

  // open from the left menu
  const onView = useCallback(async (data, blockContext) => {
    const id = (data || blockContext.id) // we already have fill ID with all nested ids
    history.push(createLocation({
      name: 'chart-view',
      params: { ...match.params, id },
    }))
  }, [ history, match ])

  // open from the context menu
  const onOpenNestedTwinGraph = useCallback(async (block) => {
    const [ twinGraphSetting ] = await block.querySettings.fetch()
    if (twinGraphSetting?.blockId) {
      const id = request.nestedTwinGraphs.concat(twinGraphSetting.blockId).join('/')
      history.push(createLocation({
        name: 'chart-view',
        params: { ...match.params, id },
      }))
    }
  }, [ history, match, request.nestedTwinGraphs ])

  const onDuplicateTwinGraph = useCallback(async () => {
    const context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareDuplicateModel(context, twinGraph, null, {
      uniqProps: [ 'name' ],
    })
    await dbProvider.batch(context)
    await window.wizConfirm({
      title: 't/form.success.duplicated',
      message: 't/twinGraph.successDuplication',
    })
    router.push({
      name: 'chart-view',
      params: { id: model.id },
    })
  }, [ router, twinGraph ])

  const onPaste = useCallback(async (data, {
    withChildren = false,
  } = {}) => {
    const hash = data.reduce((out, item) => ({ ...out, [item.id]: item }), {})
    const blocks = await dbProvider.database.collections.get('diagram_blocks')
      .query(Q.where('id', Q.oneOf(Object.keys(hash))))
      .fetch()

    const nodes = blocks.filter(item => item.type !== consts.DiagramBlockType.Flow)
    const links = blocks.filter(item => item.type === consts.DiagramBlockType.Flow)
    const pairs = {}
    const state = {}
    const actions = {}
    const nextIds = []
    const context = dbProvider.createBatchContext()

    for (const node of nodes) {
      const model = await node.prepareDuplicate(actions, undefined, {
        withChildren,
        uniqProps: [ 'name' ],
      })

      await metaBlock.prepareReplaceHyperedge(context, model)
      pairs[node.id] = model.id
    }

    const pairsIt = duplicateActionsPairsIterator(actions)
    // need to update state for all blocks, include children blocks
    for (const [ oldId, newId ] of pairsIt) {
      state[newId] = duplicateElementState(
        mergeElementLocations(hash[oldId], diagramState.elements[oldId]),
        diagramState.elements[oldId],
      )
    }

    const it = duplicateActionsIterator(actions)
    for (const item of it) {
      context.add(item)
      nextIds.push(item.id)
    }

    for (const link of links) {
      const [ settings ] = await link.querySettings.fetch()
      if (settings) {
        const { fromId, toId } = settings
        const model = await dbProvider.prepareDuplicateModel(context, link, {
          fromId: pairs[fromId] || fromId,
          toId: pairs[toId] || toId,
        }, {
          uniqProps: [ 'name' ],
        })
        nextIds.push(model.id)
      }
    }

    await metaBlock.prepareReplaceBlocksState(context, state)
    await dbProvider.batch(context)
    return nextIds
  }, [ metaBlock, diagramState ])

  const onCopyBlock = useCallback(async (selectedIds, {
    withChildren = false,
  } = {}) => {
    const selectedBlocks = selectedIds.map(id => ({ id }))
    return onPaste(selectedBlocks, { withChildren })
  }, [ onPaste, diagramState ])

  useEffect(() => {
    async function updateLastOpened () {
      const lastOpened = await dbProvider.fetchSettings('lastOpened')
      const context = dbProvider.createBatchContext()
      await dbProvider.prepareReplaceSetting(context, 'lastOpened', {
        ...lastOpened,
        [twinGraph.id]: Date.now(),
      })
      await dbProvider.batch(context)
    }

    updateLastOpened()
  }, [ twinGraph ])

  return {
    blockFactory,
    onChangeDiagram,
    onClickCreate,
    onCopyBlock,
    onDeletedDiagram,
    onDropCreateDiagram,
    onDuplicateTwinGraph,
    onLinkDiagram,
    onOpenNestedTwinGraph,
    onPaste,
    onToggleGrid,
    onToggleMapView,
    onView,
  }
})

export default enhanceRequest(
  enhanceBlock(
    enhanceDetails(
      enhanceTwinGraphState(
        enhanceProps(
          enhanceRunContext(Component),
        ),
      ),
    ),
  ),
)
