import { FormSection } from '@wiz/components'
import Fields from './Fields'
import FieldsExtra from './FieldsExtra'

export default function Section ({
  scope,
}) {
  return (
    <FormSection>
      <Fields
        scope={scope}
      />

      <FieldsExtra />
    </FormSection>
  )
}
