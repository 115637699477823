import {
  useRef,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react'
import { useDidUpdate, useForceUpdate } from '@wiz/components'
import { isEmpty } from '@wiz/utils'
import { useLocation } from '@/router'
import LeftBarMenu from '@/components/LeftBarMenu'
import { LeftBarItems } from '@/components/LeftBarDigitalTwin'

const LeftBarDiagram = forwardRef(({
  blocks,
  blockSettings,
  container,
  options,
  blockIds,
  setEditBlockId,
  rootTwin,
  selectedSensorsCount,
  ...props
}, ref) => {
  const refDialog = useRef(null)
  const refBlocks = useRef(blocks)
  const refBlockSettings = useRef(blockSettings)
  const [ value, setValue ] = useState(null)
  const [ back, setBack ] = useState(null)
  const [ pinned, setPinned ] = useState(false)
  const [ expanded, setExpanded ] = useState({ expanded: false, expandedClose: false })
  const forceUpdate = useForceUpdate()
  // const hasBlockSettings = !isEmpty(refBlockSettings.current)

  const loc = useLocation()
  const [ ,, corePage, currentPage ] = loc.pathname.split('/')

  const onClose = useCallback(() => {
    if (!pinned) {
      refDialog.current?.close()
    } else if (!rootTwin) {
      refDialog.current?.close()
    }
  }, [ pinned, rootTwin ])

  const onChange = useCallback((name, params) => {
    if (name === 'edit') {
      setEditBlockId(blockIds[0])
    }

    const data = options.find(item => item.id === name)
    if (!data) {
      return
    }

    if (params === true) {
      if (name === value?.id) {
        onClose()
      } else {
        setBack(name)
        setValue(data)
      }
    } else {
      if (params?.props?.expanded) {
        setExpanded({ expanded: true, expandedClose: true })
      }
      setValue({
        ...data,
        ...params,
        back,
      })
    }
  }, [
    options,
    back,
    value,
    onClose,
    setEditBlockId,
    blockIds,
  ])

  useEffect(() => {
    refDialog.current?.close()
  }, [ corePage, currentPage ])

  useDidUpdate(() => {
    const isPinned = LeftBarItems.map(({ id }) => id)?.includes(value?.id)
    if (isPinned) {
      setPinned(true)
    }
  }, [ value?.id ])

  useDidUpdate(() => {
    refBlocks.current = blocks
    refBlockSettings.current = blockSettings
    forceUpdate()
  }, [ blocks, blockSettings, pinned, forceUpdate ])

  useImperativeHandle(ref, () => ({
    open: onChange,
    close: onClose,
  }), [ onChange, onClose ])

  return (
    <LeftBarMenu
      refDialog={refDialog}
      container={container}
      value={value}
      options={options}
      selectedSensorsCount={selectedSensorsCount}
      selectedProps={{
        blocks: refBlocks.current,
        blockSettings: refBlockSettings.current,
        onChange,
        onClose,
        ...props,
      }}
      pinned={pinned}
      disabled={!rootTwin}
      onChange={onChange}
      onClose={() => {
        setValue(null)
        setBack(null)
        setPinned(false)
      }}
      // onPin={() => setPinned(!pinned)}
      {...expanded}
    />
  )
})

export default LeftBarDiagram
