/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'
import CellTwin from './CellTwin'
import CellCategory from './CellCategory'
import CellUnit from './CellUnit'
import CellLabels from './CellLabels'

export default function ViewModel ({
  id,
  title,
  sensor,
  onClose,
  onUnlink,
  onFilter,
  onRemove,
  onEdit,
}) {
  const refDialog = useRef()
  const intl = useIntl()
  const auth = useAuth()

  const handleFilter = (filter) => {
    refDialog.current.close()
    onFilter?.(filter)
  }

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('form.fields.name'),
          value: sensor.name,
        },
        {
          title: intl.t('sensors.form.fields.businessType'),
          value: intl.t(`enum.sensorBusinessType.${sensor.businessType}`),
        },
        {
          title: intl.t('sensors.form.fields.inputMode'),
          value: intl.t(`enum.sensorInputMode.${sensor.inputMode}`),
        },
        {
          title: intl.t('form.fields.description'),
          value: sensor.description,
        },
      ],
    },

    {
      title: intl.t('sensors.section.extra'),
      options: [
        {
          title: intl.t('sensors.form.fields.twin'),
          value: () => <CellTwin sensor={sensor} onUnlink={onUnlink} onFilter={handleFilter} />,
        },
        {
          title: intl.t('form.fields.category'),
          value: () => <CellCategory sensor={sensor} />,
        },
        {
          title: intl.t('form.fields.unit'),
          value: () => <CellUnit sensor={sensor} />,
        },
        {
          title: intl.t('form.fields.labels'),
          value: () => <CellLabels sensor={sensor} />,
        },
      ],
    },

    {
      title: intl.t('form.fields.updated'),
      value: () => <UpdatedInfo date={sensor.updatedAt} user={sensor.updatedUser} />,
    },
    {
      title: intl.t('form.fields.created'),
      value: () => <UpdatedInfo date={sensor.createdAt} user={sensor.createdUser} />,
    },
  ]

  const actions = [
    ...(onRemove && auth.checkAccessRemove(sensor) ? ([{
      title: intl.t('form.actions.remove'),
      name: 'remove',
      testid: 'removeButton',
      onClick: () => onRemove([ sensor ]),
    }]) : []),

    ...(onEdit && auth.checkAccessUpdate(sensor) ? ([{
      title: intl.t('form.actions.edit'),
      name: 'edit',
      testid: 'editButton',
      onClick: () => onEdit(id),
    }]) : []),
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      actions={actions}
      onClose={onClose}
    />
  )
}
