import { Q, dbProvider } from '@wiz/store'
import { consts } from '@wiz/utils'
import { withObservables } from '@wiz/components'

const enhanceData = withObservables([ 'filters' ], ({ filters }) => {
  let query = dbProvider.database.collections.get('files')
    .query(Q.where('type', consts.FileType.NotebookExport))

  if (filters.search) {
    const searchSanitize = Q.sanitizeLikeString(filters.search)
    query = query.extend(Q.or(
      Q.where('id', filters.search),
      Q.where('name', Q.like(`%${searchSanitize}%`)),
      Q.where('description', Q.like(`%${searchSanitize}%`)),
    ))
  }

  return {
    items: query.observeWithColumns([ 'updated_at' ]),
  }
})

export default enhanceData
