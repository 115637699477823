import { useContext } from 'react'
import { withProps } from '@wiz/components'
import DataExplorer from '@/context/DataExplorer'

export default withProps(({ dataView }) => {
  const context = useContext(DataExplorer)
  dataView = context?.data?.dataViews?.find(item => (
    item.id === dataView.id
  )) || dataView

  return {
    dataView,
  }
})
