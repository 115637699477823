import {
  useMemo,
} from 'react'
import { useRouter, useLocationQuery } from '@/router'
import Icon from '@/shared/icon'

const CellExternalLink = ({
  id,
  list,
  name,
  page,
  row,
  embed,
}) => {
  const router = useRouter()
  const query = useLocationQuery()

  const onClick = () => {
    const executionId = row.original.id
    if (!embed) {
      router.push({ name: `${page}-list`, query: { [name]: id } })
    } else if (name) {
      router.push({
        query: {
          ...query, current: page, [name]: id, executionId,
        },
      })
    }
  }

  const item = useMemo(() => {
    if (id && list?.data?.length) {
      const next = list.data.find(pipeline => pipeline.id === id)
      return next
    }
    return undefined
  }, [ id, list ])
  if (list?.isLoading) {
    return (
      <Icon
        name="faSpinner"
        spin
      />
    )
  }

  return id ? (
    <div className="min-w-0 d-flex">
      {item ? (
        <a
          className="text-truncate pointer"
          onClick={() => onClick()}
          aria-hidden
        >
          <span>{item.name || item.key}</span>
        </a>
      ) : '-'}

    </div>
  ) : '-'
}

export default CellExternalLink
