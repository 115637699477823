import { useCallback } from 'react'
import { withProps } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { map } from 'rxjs/operators'

export default withProps(() => {
  const getAnomalyWithSensorIds = useCallback(anomaliesList => (
    anomaliesList.map((anomaly) => {
      const sensors = dbProvider.database.collections.get('sensors').query(
        Q.where('hardware_id', Q.oneOf(anomaly.hardwareIds)),
      ).observe()
      const sensorIds = []
      sensors.pipe(map(items => items.map(sensor => sensor.id)))
        .subscribe(ids => (ids ? ids.forEach(id => sensorIds.push(id)) : ids))

      const result = {
        ...anomaly,
        sensorIds,
      }
      return result
    }) : []), [])

  return {
    getAnomalyWithSensorIds,
  }
})
