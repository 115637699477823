import { Icon, OverflowCenter } from '@wiz/components'

const Item = ({ item }) => (
  <div className="d-flex align-items-center">
    {item.icon ? (
      <span className={`badge d-flex me-2 p-1 ${item.className}`}>
        <Icon name={item.icon} />
      </span>
    ) : null}

    {item.name ? (
      <OverflowCenter value={item.name} />
    ) : null}
  </div>
)

export default function Group ({ data, index, style }) {
  const { options } = data
  const item = options[index]

  return (
    <div
      style={style}
      className="d-flex flex-column justify-content-center align-items-start list-group-item bg-light"
    >
      {item.list ? item.list.map((row, key) => (
        <Item key={key} item={row} />
      )) : (
        <Item item={item} />
      )}
    </div>
  )
}
