import { useMemo, useState } from 'react'
import classnames from 'classnames'
import { VirtualTable, Pagination, Icon } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import TwinList from '@/hoc/TwinList'
import SensorList from '@/hoc/SensorList'
import StreamJobList from '@/hoc/StreamJobList'
import BusinessLabelList from '@/hoc/BusinessLabelList'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import CellTwin from './CellTwin'
import CellStreamJob from './CellStreamJob'
import CellSensors from './CellSensors'
import CellDuration from './CellDuration'
import CellBusinessLabels from './CellBusinessLabels'

const CellSensorsData = SensorList(CellSensors)
const CellStreamJobData = StreamJobList(CellStreamJob)
const CellTwinData = TwinList(CellTwin)
const CellBusinessLabelsData = BusinessLabelList(CellBusinessLabels)

export default function Table ({
  items,
  onFilter,
  onEdit,
  onRemove,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      minWidth: 200,
      Cell: ({ cell, row }) => (
        <div className="text-truncate min-w-0">
          <div className="d-flex align-items-center">
            {row.original.sourceType === 'manual' ? (
              <Icon
                name="fa--tools"
                title={intl.t(`enum.eventSourceType.${row.original.sourceType}`)}
                className="me-1"
              />
            ) : null}
            <a
              className="text-truncate pointer"
              onClick={() => auth.checkAccessUpdate(row.original) && onEdit?.(row.original.id)}
              aria-hidden
            >
              { cell.value }
            </a>
          </div>

          {row.original.description ? (
            <div className="font-italic text-muted text-truncate small">
              {row.original.description}
            </div>
          ) : null}

          {auth.checkAccessRead('BusinessLabel') && row.original.businessLabelIds?.length ? (
            <CellBusinessLabelsData
              value={cell.value}
              keyName="id"
              selectedOnly
            />
          ) : null}
        </div>
      ),
    },
    {
      Header: intl.t('form.fields.type'),
      accessor: 'type',
      width: 80,
      maxWidth: 80,
      Cell: ({ cell }) => (cell.value ? (
        <span className="text-truncate">
          {intl.t(`enum.eventType.${cell.value}`)}
        </span>
      ) : '-'),
    },
    {
      Header: intl.t('form.fields.status'),
      accessor: 'status',
      width: 90,
      maxWidth: 90,
      Cell: ({ cell }) => (cell.value ? (
        <span
          className={classnames('text-truncate', {
            'text-primary': cell.value === 'active',
            'text-muted': cell.value === 'resolved',
          })}
        >
          {intl.t(`enum.eventStatus.${cell.value}`)}
        </span>
      ) : '-'),
    },
    {
      id: 'duration',
      Header: intl.t('form.fields.duration'),
      accessor: 'id',
      disableSortBy: true,
      width: 200,
      maxWidth: 200,
      Cell: ({ row }) => (
        <CellDuration data={row.original} />
      ),
    },
    ...(auth.checkAccessRead('StreamJob') ? [
      {
        Header: intl.t('events.form.fields.streamJob'),
        accessor: 'streamJobId',
        disableSortBy: true,
        width: 100,
        Cell: ({ cell }) => (
          <CellStreamJobData
            value={cell.value}
            selectedOnly
          />
        ),
      },
    ] : []),
    ...(auth.checkAccessRead('Twin') ? [
      {
        Header: intl.t('form.fields.twin'),
        accessor: 'twinId',
        disableSortBy: true,
        width: 100,
        Cell: ({ cell }) => (
          <CellTwinData
            value={cell.value}
            selectedOnly
          />
        ),
      },
    ] : []),
    ...(auth.checkAccessRead('Sensor') ? [
      {
        Header: intl.t('form.fields.dataPoints'),
        accessor: 'sensorIds',
        disableSortBy: true,
        width: 100,
        Cell: ({ cell, row }) => (
          <CellSensorsData
            value={cell.value}
            event={row.original}
            keyName="id"
            selectedOnly
            onFilter={onFilter}
          />
        ),
      },
    ] : []),
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedAt',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.updatedById}
        />
      ),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdAt',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          userId={row.original.createdById}
        />
      ),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell, row }) => {
        const [ loading, setLoading ] = useState(false)
        return (
          <>
            {auth.checkAccessUpdate(row.original) ? (
              <button
                type="button"
                className="btn btn-sm p-0 me-2"
                onClick={() => onEdit?.(cell.value)}
              >
                {intl.t('form.actions.edit')}
              </button>
            ) : null}
            {auth.checkAccessRemove(row.original) ? (
              <button
                type="button"
                className="btn btn-sm p-0"
                disabled={loading}
                onClick={async () => {
                  setLoading(true)
                  try {
                    await onRemove([ cell.value ])
                    setLoading(false)
                  } catch (error) {
                    setLoading(false)
                    throw error
                  }
                }}
              >
                {loading ? <Icon name="fa--spinner" className="me-1" spin /> : null}
                {intl.t('form.actions.remove')}
              </button>
            ) : null}
          </>
        )
      },
    },
  ], [ onFilter, onRemove, onEdit, intl, auth ])

  return (
    <>
      <VirtualTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={auth.checkAccessRemove('Event') || auth.checkAccessUpdate('Event')}
        {...props}
      />

      <Pagination {...props} relative />
    </>
  )
}
