import { define } from 'superstruct'
import { intl } from '@/i18n'

const BinPrevValue = ({
  max = 0, // Number.POSITIVE_INFINITY,
} = {}) => define('BinPrevValue', (value, { branch }) => {
  const data = branch[branch.length - 2]
  const bins = branch[branch.length - 3]
  const idx = bins.findIndex(item => item.id === data.id)

  let result = true

  if (idx === 0 && value > max) {
    result = intl.t('form.errors.fieldMax', { max })
  }

  return result
})

export default BinPrevValue
