import { useFormContext } from 'react-hook-form'
import { FormSection } from '@wiz/components'
import Fields from './Fields'

export default function Section ({
  scope,
  title,
  addon,
  registerId = true,
}) {
  const { register } = useFormContext()

  return (
    <FormSection
      title={title}
      addon={addon}
    >
      {registerId ? (
        <input
          {...register(`${scope}.id`)}
          type="hidden"
        />
      ) : null}

      <Fields scope={scope} />
    </FormSection>
  )
}
