import { useMemo, useState } from 'react'
import classnames from 'classnames'
import { VirtualTable as StaticTable, Pagination } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import UpdatedInfo from '@/components/Form/UpdatedInfo'

export default function Table ({
  items,
  ...props
}) {
  const intl = useIntl()
  const [ isMs, setIsMs ] = useState(false)
  const columns = useMemo(() => [
    {
      Header: intl.t('notifications.form.fields.message'),
      accessor: 'message',
      width: 500,
      disableResizing: true,
      disableSortBy: true,
    },
    {
      Header: intl.t('notifications.form.fields.level'),
      accessor: 'level',
      width: 100,
      disableResizing: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <span
          className={classnames({
            'text-danger': cell.value === 'error',
            'text-warning': cell.value === 'warn',
            'text-info': cell.value === 'info',
          })}
        >
          {cell.value}
        </span>
      ),
    },
    {
      Header: intl.t('notifications.form.fields.source'),
      accessor: 'source',
      width: 100,
      disableResizing: true,
      disableSortBy: true,
    },
    {
      Header: () => (
        <>
          {intl.t('form.fields.created')}
          <button
            type="button"
            className="btn btn-sm btn-flat-secondary ms-2"
            onClick={(e) => {
              e.stopPropagation()
              setIsMs(!isMs)
            }}
          >
            {isMs ? intl.t('form.fields.ms') : intl.t('form.fields.s')}
          </button>
        </>
      ),
      accessor: 'timestamp',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 260,
      width: 260,
      maxWidth: 260,
      Cell: ({ cell }) => (<UpdatedInfo date={cell.value} withMillis={isMs} withSecond />),
    },
  ], [ intl, isMs ])

  return (
    <>
      <StaticTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={false}
        {...props}
      />

      <Pagination {...props} relative />
    </>
  )
}
