import { useCallback } from 'react'
import { withProps } from '@wiz/components'
import { useRouter } from '@/router'
import withTableActions from '@/containers/withTableActions'
import Component from '@/components/Category/List'

const enhanceProps = withProps(({ onRemove }) => {
  const router = useRouter()
  const onRemoveCategory = useCallback(async (data) => {
    try {
      await onRemove?.(data)
    } catch (error) {
      if (data.length === 1 && error?.message === 't/categories.form.errors.removalSensorsUsed') {
        try {
          await window.wizConfirm({ message: 't/categories.confirmOpenDataPoints' })
          router.push({
            name: 'sensors-list',
            query: {
              category: data[0].id,
            },
          })
        } catch (e) {
          // no-error
        }
      }
      throw error
    }
  }, [ onRemove, router ])

  return {
    onRemove: onRemoveCategory,
  }
})

export default withTableActions({
  defaultFilters () {
    return {
      sortBy: 'createdDate',
    }
  },
})(
  enhanceProps(Component),
)
