import { set } from '@wiz/utils'
import { dbProvider, checkUniqueField } from '@wiz/store'
import { intl } from '@/i18n'

export default async function resolver (data) {
  const errors = {}
  const row = data?.deviceCommandTemplate || {}

  if (row.name) {
    const isUniqName = await checkUniqueField(
      row,
      'name',
      dbProvider.database.collections.get('device_command_templates').query(),
    )

    if (!isUniqName) {
      set(errors, 'deviceCommandTemplate.name', {
        type: 'unique',
        message: intl.t('form.errors.unique'),
      })
    }
  }

  return {
    errors,
  }
}
