import { useState, useCallback, useMemo } from 'react'
import { DataView } from '@wiz/store'
import FilterSearch from '@/components/Form/FilterSearch'
import ButtonCreateSource from './ButtonCreateSource'
import ListDataViews from './ListDataViews'

const DataViewExplorer = ({
  options,
  errors,
  readOnly,
  canCreateSource,
  canCreateVirtualSource,
  onRemoveView,
  onChangeView,
  onChangeSource,
  onCreateSource,
  onDrop,
}) => {
  const [ search, setSearch ] = useState('')
  const handleFilter = useCallback(({ search: data }) => setSearch(data || ''), [])
  const treeData = useMemo(() => {
    const data = []
    const hash = {}
    const filterSearch = search.trim().toLowerCase()

    for (const view of options) {
      const name = DataView.getDisplayName(view)

      if (!filterSearch || name.toLowerCase().indexOf(filterSearch) !== -1) {
        hash[view.id] = {
          id: view.id,
          name,
          payload: {
            view,
            source: view.source,
            error: errors?.[view.source?.id],
          },
          children: [],
        }
      }
    }

    for (const view of options) {
      if (!view.parentId && hash[view.id]) {
        data.push(hash[view.id])
      } else if (view.parentId && hash[view.parentId]) {
        hash[view.parentId].children.push(hash[view.id])
      }
    }

    return data
  }, [ options, search, errors ])

  return (
    <FilterSearch
      searchTestid="selectedSearch"
      withSelectedOnly={false}
      onFilter={handleFilter}
      postfix={readOnly ? null : (
        <ButtonCreateSource
          className="mb-1 me-1"
          onCreateSource={onCreateSource}
          canCreateSource={canCreateSource}
          canCreateVirtualSource={canCreateVirtualSource}
        />
      )}
    >
      {formData => (
        <ListDataViews
          {...formData}
          className="position-absolute-fill"
          options={treeData}
          onRemoveView={onRemoveView}
          onChangeView={onChangeView}
          onChangeSource={onChangeSource}
          onDrop={onDrop}
          readOnly={readOnly}
        />
      )}
    </FilterSearch>
  )
}

export default DataViewExplorer
