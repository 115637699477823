import classnames from 'classnames'
import { Icon } from '@wiz/components'

export default function SelectMode ({
  children,
  checked,
  disabled,
  icon = 'fa--plus',
  onClick,
}) {
  return (
    <button
      className={classnames('btn badge border me-1 mb-1 bg-transparent d-inline-flex', {
        'border-info text-info': checked,
        'text-secondary': !checked,
      })}
      type="button"
      tabIndex="0"
      disabled={disabled}
      onClick={onClick}
    >
      {icon ? (
        <Icon
          name={icon}
          className="me-1"
        />
      ) : null}
      {children}
    </button>
  )
}
