import { withSort } from '@wiz/components'
import Component from '@/components/EdgeDevice/TableModulesMobile'
import enhanceDataModules from './enhanceDataModules'

export default enhanceDataModules(
  withSort({
    defaultSortBy: 'moduleId',
    defaultSortDir: 'desc',
  })(Component),
)
