import {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import classnames from 'classnames'
import orderBy from 'lodash/orderBy'
import { uuid } from '@wiz/utils'
import { Icon, CustomScrollbars } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import DateRelative from '@/components/DateRelative'
import events from '@/utils/events'
import { strip } from '@/utils/string'

const DefaultDuration = 5000
const DisplayNotifyCount = 3

const FilterNotify = ({ id, group } = {}) => item => (
  (!group || item.group !== group) &&
  (!id || item.id !== id)
)

const TypesLevel = {
  error: 20,
  warn: 10,
}

const Notify = () => {
  const intl = useIntl()
  const timer = useRef()
  const [ items, setItems ] = useState([])

  const handleClose = useCallback((id) => {
    const next = items.filter(item => item.id !== id)
    setItems(next)
  }, [ items ])

  useEffect(() => {
    const handleAppNotifyRemove = (data) => {
      const next = items
        .filter(FilterNotify(data))

      if (next.length !== items.length) {
        setItems(next)
      }
    }

    const handleAppNotify = ({
      id,
      type,
      icon,
      title,
      group,
      message,
      rawMessage,
      content,
      weight,
      onAction,
      duration = DefaultDuration,
    }) => {
      if (group === 'nextVersion') {
        content = notify => (
          <div className="p-2 px-3">
            <p className="mb-2">{intl.t('app.version.update')}</p>
            <button
              type="button"
              className="btn btn-fill-primary btn-sm"
              onClick={() => {
                handleClose(notify.id)
                onAction?.()
              }}
            >
              {intl.t('app.version.updateAction')}
            </button>
          </div>
        )
      }

      let next = items
        .filter(FilterNotify({ id, group }))
        .concat({
          id: id || uuid(),
          time: Date.now(),
          group,
          icon,
          title,
          type,
          duration,
          content,
          weight,
          message: rawMessage ? strip(rawMessage) : message,
        })

      next = orderBy(next, [
        item => (item.weight ?? (TypesLevel[item.type] || 0)),
        'time',
      ], [
        'desc',
        'desc',
      ]).slice(0, DisplayNotifyCount)

      setItems(next)
    }

    events.addListener('app:notify', handleAppNotify)
    events.addListener('app:notify:remove', handleAppNotifyRemove)

    if (items.length) {
      const handleTimer = () => {
        const now = Date.now()
        const next = items.filter(item => (
          item.duration <= 0 ||
          (item.time + item.duration) > now
        ))

        if (next.length !== items.length) {
          setItems(next)
        } else if (items.length) {
          timer.current = window.setTimeout(handleTimer, DefaultDuration)
        }
      }

      timer.current = window.setTimeout(handleTimer, DefaultDuration)
    }

    return () => {
      window.clearTimeout(timer.current)
      events.removeListener('app:notify', handleAppNotify)
      events.removeListener('app:notify:remove', handleAppNotifyRemove)
    }
  }, [ intl, items, handleClose ])

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      className="d-flex justify-content-center position-relative"
      style={{ zIndex: 3000 }}
    >
      <div className="toast-container position-absolute top-0 p-3">
        {items.map(item => (
          <div
            key={item.id}
            className={classnames('toast show border-0', {
              'bg-warning': item.type === 'warn',
              'bg-danger': item.type === 'error',
              'bg-info': item.type === 'info',
              'bg-success': item.type === 'success',
            })}
            style={item.type ? { color: '#ffffff' } : undefined}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div
              className={classnames('toast-header p-2 px-3', {
                'border-0': !item.content && !item.message,
              })}
            >
              {item.icon ? <Icon className="me-2" name={item.icon} /> : null}
              <strong className="me-auto">{intl.t(item.title)}</strong>
              <small className="text-muted">
                <DateRelative value={item.time} />
              </small>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="toast"
                aria-label="Close"
                onClick={() => handleClose(item.id)}
              />
            </div>
            {do {
              if (item.content) {
                item.content(item)
              } else if (item.message) {
                <CustomScrollbars
                  className="toast-body p-0"
                  autoHeight
                  autoHeightMax={100}
                  autoHide
                  horizontal={false}
                  shadow={false}
                >
                  <div className="p-2 px-3">
                    {intl.t(item.message)}
                  </div>
                </CustomScrollbars>
              }
            }}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Notify
