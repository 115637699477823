import { useMemo } from 'react'
import { DiagramBlock } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import FormDialog from '@/components/Form/FormDialog'
import WidgetSensorSettingsSection from '@/components/Forms/WidgetSensor/SettingsSection'
import WidgetPlotlySettingsSection from '@/components/Forms/WidgetPlotly/SettingsSection'
import WidgetTableDataSettingsSection from '@/components/Forms/WidgetTableData/SettingsSection'
import BlockSection from '../Block/Section'
import SectionEmpty from './SectionEmpty'

const WidgetSettingsSection = {
  sensor: WidgetSensorSettingsSection,
  plotly: WidgetPlotlySettingsSection,
  tableData: WidgetTableDataSettingsSection,
}

export default function BlockWidget ({
  block,
  settings,
  config,
  dialog,
  onClose,
  onSubmit,
  onCreateWidget,
  onRemoveWidget,
}) {
  const intl = useIntl()
  const defaultValues = useMemo(() => ({
    block: DiagramBlock.toJSON(block),
    config,
  }), [
    block?.updatedAt ?? block,
    config,
  ])

  const Section = WidgetSettingsSection[settings?.type] || (
    () => (
      <SectionEmpty
        title={intl.t('form.sections.widget')}
        onCreateWidget={onCreateWidget}
      />
    )
  )

  return (
    <FormDialog
      onSubmit={onSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="blockWidgetForm"
      dialog={dialog}
    >
      <BlockSection
        scope="block"
      />

      {settings ? (
        <button
          type="button"
          className="btn btn-outline-danger mb-3 mt-1"
          onClick={onRemoveWidget}
        >
          {intl.t('form.actions.removeWidgetSettings')}
        </button>
      ) : null}

      <Section
        widget={settings}
        widgetScope="block"
        config={config}
      />
    </FormDialog>
  )
}
