import { useCallback, memo } from 'react'
import { dbProvider, Label } from '@wiz/store'
import { withProps } from '@wiz/components'
import FormLabel from '@/components/Forms/Label'
import enhanceLabel from './enhanceLabel'
import enhanceUsage from './enhanceUsage'

const enhanceProps = withProps(() => {
  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    await dbProvider.prepareReplaceData(context, Label, data.label)
    await dbProvider.batch(context)
  }, [])

  return {
    onSubmit,
  }
})

export default memo(
  enhanceLabel(
    enhanceUsage(
      enhanceProps(FormLabel),
    ),
  ),
)
