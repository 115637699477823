import { define } from 'superstruct'
import { intl } from '@/i18n'

const LimitMaxRequired = ({
  keyMin = 'limitMin',
} = {}) => define('LimitMaxRequired', (value, { branch }) => {
  const data = branch[branch.length - 2]
  let result = true
  if (!Number.isFinite(value) && Number.isFinite(data[keyMin])) {
    result = intl.t('form.errors.fieldRequired')
  }
  return result
})

export default LimitMaxRequired
