import { memo, useCallback } from 'react'
import { Q, dbProvider, TwinGraph } from '@wiz/store'
import { withProps } from '@wiz/components'
import { go, mergeElementLocations, consts } from '@wiz/utils'
import events from '@/utils/events'
import Component from '@/components/Forms/SendToBoard'

const enhanceProps = withProps(({
  selected,
  state,
}) => {
  const onSubmit = useCallback(async ({
    boards,
    newBoards,
  }) => {
    const nodes = selected.filter(item => !item.includes('/'))

    let flows = await dbProvider.database.collections.get('twins')
      .query(
        Q.where('id', Q.oneOf(nodes)),
        Q.where('type', consts.TwinType.Flow),
      )
      .fetch()

    const twins = await dbProvider.database.collections.get('twins')
      .query(
        Q.where('id', Q.oneOf([].concat(
          nodes,
          flows.map(item => item.parentId),
          flows.map(item => item.flowToId),
        ))),
        Q.where('type', Q.notEq(consts.TwinType.Flow)),
      )
      .fetch()

    flows = flows.filter(item => (
      twins.some(twin => twin.id === item.parentId) &&
      twins.some(twin => twin.id === item.flowToId)
    ))

    const twinsState = twins
      .reduce((acc, twin) => {
        const twinState = state?.elements?.[twin?.id]
        if (twinState?.loc) {
          acc[twin.id] = go.Point.parse(twinState.loc)
        }
        return acc
      }, {})

    const minX = Math.min(...Object.values(twinsState).map(twinState => twinState.x))
    const minY = Math.min(...Object.values(twinsState).map(twinState => twinState.y))

    const nextTwinsState = Object.keys(twinsState)
      .reduce((acc, twinId) => {
        const twinState = twinsState[twinId]
        const x = twinState.x - minX
        const y = twinState.y - minY
        acc[twinId] = { loc: `${x} ${y}` }
        return acc
      }, {})

    const links = selected
      .filter(item => item.includes('/'))
      .map(item => item.split('/'))
      .map(([ from, to ]) => ({
        from: twins.find(twin => twin.id === from)?.id,
        to: twins.find(twin => twin.id === to)?.id,
      }))
      .filter(({ from, to }) => (from && to))

    let newBoardIdx = 1
    let context = dbProvider.createBatchContext()
    for (const boardId of boards) {
      const twinGraphData = newBoards.find(item => item.id === boardId)
      if (twinGraphData && !twinGraphData.name) {
        twinGraphData.name = `New Board ${newBoardIdx}`
        newBoardIdx += 1
      }

      await dbProvider.prepareReplaceData(context, TwinGraph, {
        ...twinGraphData,
        id: boardId,
      })
    }
    await dbProvider.batch(context)

    context = dbProvider.createBatchContext()
    for (const boardId of boards) {
      const [ twinGraph ] = await dbProvider.database.collections.get('twin_graphs')
        .query(Q.where('id', boardId))
        .fetch()

      const metaBlock = await twinGraph.block.fetch()
      context.setContextBlock(metaBlock)

      const blocks = {}
      let currentBlockState = { loc: '0 0' }
      for (const model of twins) {
        currentBlockState = {
          ...currentBlockState,
          ...nextTwinsState?.[model.id],
        }
        const block = await metaBlock.prepareReplaceBlock(context, { type: model.type })
        await block.prepareLinkSettings(context, { model })
        await metaBlock.prepareReplaceHyperedge(context, block)
        await metaBlock.prepareReplaceBlockState(context, block, currentBlockState)
        currentBlockState = mergeElementLocations({}, currentBlockState)
        blocks[model.id] = block.id
      }

      for (const { from, to } of links) {
        if (blocks[from] && blocks[to]) {
          const block = await metaBlock.prepareReplaceBlock(context, { type: 'flow' })
          await block.prepareReplaceSettings(context, {
            flow: {
              fromId: blocks[from],
              toId: blocks[to],
              hyperedge: true,
            },
          })
        }
      }

      for (const flow of flows) {
        const block = await metaBlock.prepareReplaceBlock(context, { type: 'flow' })
        await block.prepareReplaceSettings(context, {
          flow: {
            fromId: blocks[flow.parentId],
            toId: blocks[flow.flowToId],
            hyperedge: false,
          },
          twin: {
            id: flow.id,
          },
        })
      }
    }

    if (context.size) {
      try {
        await dbProvider.batch(context)
        events.emit('app:notify', {
          type: 'success',
          title: 't/twin.titleBoardUpdate',
          message: 't/twin.form.success.boardUpdate',
          duration: 2000,
        })
      } catch (error) {
        events.emit('app:notify', {
          type: 'error',
          title: 't/twin.titleBoardUpdate',
          message: 't/twin.form.errors.boardUpdate',
        })
        throw error
      }
    }
  }, [ selected, state ])

  return {
    onSubmit,
  }
})

export default memo(enhanceProps(Component))
