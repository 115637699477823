import { useState, useEffect } from 'react'
import { OnlineTimeout } from '@wiz/utils'
import { withProps } from '@wiz/components'
import { wizataApi } from '@/api'
import Component from '../components/Timeline'

const enhanceData = withProps(({
  hidden,
  config,
  currentPage,
  pageSize,
  onChangeCount,
}) => {
  const [ items, setItems ] = useState([])
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    let timer

    async function fetchData () {
      if (hidden) {
        return
      }

      setLoading(true)
      try {
        const limit = pageSize + 1
        const offset = (currentPage - 1) * pageSize
        const data = await wizataApi.getNotifications({
          hardwareId: config.hardwareId,
          from: Date.now() - (config.duration || 2 * 60 * 60 * 1000),
          limit,
          offset,
        })

        onChangeCount?.(offset + data.length)
        setItems(data)
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }

    function updateRelativeTime () {
      timer?.cancel()
      if (!hidden) {
        timer = new OnlineTimeout(() => {
          fetchData()
            .then(updateRelativeTime)
            .catch(updateRelativeTime)
        }, 1000 * 60)
      }
    }

    fetchData()
      .then(updateRelativeTime)
      .catch(updateRelativeTime)

    return () => {
      timer?.cancel()
    }
  }, [
    hidden,
    config,
    currentPage,
    pageSize,
    onChangeCount,
  ])

  return {
    loading,
    items,
  }
})

export default enhanceData(Component)
