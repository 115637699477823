/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import { useMemo } from 'react'
import {
  VirtualTable,
  Pagination,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import Link from '@/components/Link'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import EdgeDeviceList from '@/hoc/EdgeDeviceList'
import TwinList from '@/hoc/TwinList'
import useAppContext from '@/hooks/useAppContext'
import Observe from '@/containers/Observe'
import EditButton from '@/shared/editButton'
import Icon from '@/shared/icon'
import CellDevice from './CellDevice'
import CellTwin from './CellTwin'
import CellToggleActive from './CellToggleActive'
import CellName from './CellName'

const CellDeviceData = EdgeDeviceList(CellDevice)
const CellTwinData = TwinList(CellTwin)

export default function Table ({
  items,
  filters,
  onRemove,
  onEdit,
  onToggleActive,
  onDuplicate,
  onFilter,
  onOpen,
  twins,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const { onRowSelect } = useAppContext()
  const columns = useMemo(() => [
    ...(auth.checkAccessUpdate('StreamJob') ? [
      {
        Header: intl.t('form.fields.active'),
        accessor: 'active',
        disableResizing: true,
        minWidth: 55,
        width: 55,
        maxWidth: 55,
        className: 'justify-content-center',
        Cell: ({ row }) => (
          <CellToggleActive
            streamJob={row.original}
            onToggleActive={onToggleActive}
          />
        ),
      },
    ] : []),
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      width: 25,
      className: 'justify-content-center text-nowrap',
      Cell: ({ cell, row }) => (
        <>
          {auth.checkAccessUpdate(row.original) ? (
            <EditButton cell={cell} onEdit={onEdit} className="p-0" />
          ) : null}
          {auth.checkAccessRead('SectionStreamJobLogs') ? (
            <Link
              name="stream-job-logs-list"
              query={{ streamJobId: row.original.blockId }}
              className="btn btn-text p-0 ms-2"
            >
              {/* {intl.t('form.actions.logs')} */}
              <Icon name="faMemoPad" />
            </Link>
          ) : null}
          {/* {auth.checkAccessCopy(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0 me-2"
              onClick={() => onDuplicate?.(
                row.original,
                { active: false },
              )}
            >
              {intl.t('form.actions.duplicate')}
            </button>
          ) : null} */}
        </>
      ),
    },
    {
      Header: intl.t('form.fields.name'),
      accessor: 'name',
      Cell: ({ row }) => (
        <CellName
          onOpen={onOpen}
          streamJob={row.original}
          filters={filters}
        />
      ),
    },
    {
      Header: intl.t('form.fields.device'),
      accessor: 'deviceId',
      disableSortBy: true,
      Cell: ({ cell }) => (cell.value ? (
        <CellDeviceData
          value={cell.value}
          selectedOnly
          onFilter={onFilter}
        />
      ) : '-'),
    },
    {
      Header: intl.t('form.fields.twins'),
      disableResizing: true,
      disableSortBy: true,
      Cell: ({ row }) => (
        <Observe query={row.original.queryRelTwins}>
          {({ data }) => (
            <CellTwinData
              value={data.map(item => item.twinId)}
              selectedOnly
              onFilter={onFilter}
            />
          )}
        </Observe>
      ),
    },
    {
      Header: intl.t('form.fields.type'),
      accessor: 'type',
      disableResizing: true,
      minWidth: 160,
      width: 160,
      maxWidth: 160,
      Cell: ({ cell }) => intl.t(`enum.streamJobs.${cell.value}`),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedAt',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 220,
      Cell: ({ cell, row }) => (<UpdatedInfo date={cell.value} userId={row.original.updatedById} user={row.original.updatedUser} />),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdAt',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 220,
      Cell: ({ cell, row }) => (<UpdatedInfo date={cell.value} userId={row.original.createdById} user={row.original.createdUser} />),
    },
  ], [
    intl,
    auth,
    filters,
    onEdit,
    onToggleActive,
    onDuplicate,
    onFilter,
    onOpen,
  ])

  return (
    <>
      <VirtualTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={twins || items}
        selection={auth.checkAccessRemove('StreamJob')}
        {...props}
        onSelect={onRowSelect}
      />

      <Pagination {...props} />
    </>
  )
}
