/* eslint-disable no-unused-expressions */
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react'
import GridLayout from '@rikishi/react-grid-layout'
import {
  withResizeObserver,
  useIntersectionObserver,
  CustomScrollbars,
  useVisibilityObserver,
  Icon,
} from '@wiz/components'
import { dbProvider, Widget as WidgetModel } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import { intersectionObserver, orderBy, scrollIntoView } from '@wiz/utils'
import { useAuth } from '@/auth'
import { randomBackground } from '@/assets/bg'
import { DashboardExecuteProvider } from '@/context/DashboardExecuteProvider'
import useDashboardExecute from '@/hooks/useDashboardExecute'
import useAuthComponents from '@/components/GrafanaDashboards/hooks'
import FormWidget from '@/containers/Forms/Widget'
import FormDashboard from '@/containers/Forms/Dashboard'
import FormDuplicateWidget from '@/containers/Forms/DuplicateWidget'
import Widget from '@/containers/Dashboards/Widget'
import LeftBar from '@/containers/Dashboards/LeftBar'
import RightBar from './RightBar'
import Toolbar from './Toolbar'
import Topbar from './Topbar'

const GridLayoutMargin = [ 20, 20 ]

const Cell = forwardRef(({
  id,
  children,
  onWidgetAction,
  templateId,
  ...props
}, ref) => {
  const executeContext = useDashboardExecute()
  const isIntersecting = useIntersectionObserver('dashboard', ref)
  const isHidden = useVisibilityObserver()
  const disabled = executeContext.disabled || !isIntersecting || isHidden
  const hidden = !isIntersecting || isHidden

  return (
    <div
      ref={ref}
      data-id={id}
      {...props}
    >
      <Widget
        id={id}
        disabled={disabled}
        hidden={hidden}
        templateId={templateId}
        canUpdateDashboard={executeContext.canUpdateDashboard}
        onWidgetAction={onWidgetAction}
      />
      {children}
    </div>
  )
})

const Grid = ({
  layout,
  width,
  onLayoutChange,
  onWidgetAction,
  onCreateDropWidget,
  templateId,
}) => {
  const {
    dragType,
    isMobil,
    isDraggable,
    isResizable,
    isDroppable,
    canUpdateLayout,
  } = useDashboardExecute()

  const displayLayout = useMemo(() => {
    if (!isMobil) {
      return layout.map(item => ({
        ...item,
        isDraggable,
        isResizable,
      }))
    }

    let h = 0
    return orderBy(layout, [ 'y', 'x' ], [ 'asc', 'asc' ]).map((item) => {
      const y = h
      h += item.h
      return {
        ...item,
        y,
        x: 0,
        w: 1,
        minW: 1,
        maxW: 1,
        isDraggable,
        isResizable,
      }
    })
  }, [
    isDraggable,
    isResizable,
    isMobil,
    layout,
  ])

  const children = useMemo(() => displayLayout.map(item => (
    <Cell
      key={item.i}
      id={item.i}
      templateId={templateId}
      onWidgetAction={onWidgetAction}
    />
  )), [ displayLayout, onWidgetAction, templateId ])

  return (
    <GridLayout
      layout={displayLayout}
      width={width}
      rowHeight={40}
      cols={isMobil ? 1 : 24}
      margin={GridLayoutMargin}
      style={{ minHeight: '100%' }}
      isDraggable={isDraggable}
      isResizable={isResizable}
      isDroppable={isDroppable}
      draggableHandle=".userdrag"
      onLayoutChange={canUpdateLayout ? onLayoutChange : undefined}
      {...(isDroppable && dragType ? {
        onDrop: dragLayout => onCreateDropWidget(dragType, dragLayout),
        onDropDragOver: () => {
          const info = WidgetModel.info(dragType)
          return {
            w: info?.defaultW || 1,
            h: info?.defaultH || 1,
          }
        },
      } : undefined)}
    >
      {children}
    </GridLayout>
  )
}

function DashboardScrollbars ({ children }) {
  const { setDisabled } = useDashboardExecute()

  return (
    <CustomScrollbars
      className="position-absolute-fill"
      shadow={false}
      horizontal={false}
      autoHide
      onScrollStart={() => setDisabled(true)}
      onScrollStop={() => setDisabled(false)}
    >
      {children}
    </CustomScrollbars>
  )
}

const View = ({
  dashboard,
  layout,
  width,
  openDashboard,
  onLayoutChange,
  onWidgetAction,
  onCreateDropWidget,
  embed,
  rightbar = true,
  isEditable,
  embedWidth,
}, ref) => {
  const [ editId, setEditId ] = useState(null)
  const [ editWidget, setEditWidget ] = useState(null)
  const [ duplicateWidget, setDuplicateWidget ] = useState(null)
  const [ backgroundImage ] = useState(randomBackground())
  const [ leftBarExpanded, setLeftBarExpanded ] = useState(false)

  const refRightBar = useRef(null)

  const auth = useAuth()
  const intl = useIntl()
  const { isPersonal } = useAuthComponents()

  const displayLayout = useMemo(() => (
    layout.filter(item => (
      !item.access ||
      auth.checkAccessManage(item.access)
    ))
  ), [ layout, auth ])

  const handleWidgetAction = useCallback(async (widget, action) => {
    const canEdit = auth.checkAccessUpdate(dashboard) || isPersonal

    if (action === 'settings' && canEdit) {
      setEditWidget(widget)
    } else if (action === 'multiDuplicate' && auth.checkAccessCopy(widget) && canEdit) {
      setDuplicateWidget(widget)
    } else {
      await onWidgetAction(widget, action)
    }
  }, [ auth, dashboard, onWidgetAction, isPersonal ])

  const handleAction = useCallback((data) => {
    refRightBar.current.open(data.id)
  }, [])

  const scrollToWidget = useCallback((widget) => {
    window.setTimeout(() => {
      const el = document.querySelector(`.react-grid-item[data-id="${widget.id}"]`)
      scrollIntoView(el, {
        behavior: 'smooth',
        block: 'end',
        inline: 'center',
      })
    }, 100)
  }, [])
  useEffect(() => {
    intersectionObserver.register('dashboard', {
      root: ref.current,
      rootMargin: '0px',
      threshold: [ 0, 0.25, 0.5, 0.75, 1.0 ],
    })
    return () => {
      intersectionObserver.unregister('dashboard')
    }
  }, [ ])

  if (!dashboard) {
    return (
      <div className="position-absolute-fill position-center-fill flex-column bg-light opacity-50">
        <div><Icon name="fa--spinner" size="2X" spin /></div>
      </div>
    )
  }

  if (typeof width !== 'number' && !embed) {
    return <div ref={ref} className="flex-fill" />
  }

  return (
    <div
      ref={ref}
      className="d-flex flex-fill flex-column h-100"
    >
      {/* {embed ? null : <Topbar dashboard={dashboard} />} */}
      <Toolbar
        dashboard={dashboard}
        onAction={handleAction}
      />

      <div className="d-flex flex-fill position-relative">
        <div
          className="position-absolute-fill"
          style={{
            backgroundImage: `url(${dashboard.bgLink ?? backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            opacity: dashboard.bgOpacity ?? 0.5,
          }}
        />

        <DashboardExecuteProvider
          dashboard={dashboard}
          width={embedWidth || (width - 100)}
          isEditable={isEditable}
        >
          <DashboardScrollbars>
            <Grid
              layout={displayLayout}
              className="position-absolute-fill"
              width={(embedWidth || width) - 100}
              templateId={dashboard.templateId}
              onLayoutChange={onLayoutChange}
              onWidgetAction={handleWidgetAction}
              onCreateDropWidget={onCreateDropWidget}
            />
          </DashboardScrollbars>

          {rightbar ? (
            <RightBar
              ref={refRightBar}
              dashboard={dashboard}
              setEditId={setEditId}
              setLeftBarExpanded={setLeftBarExpanded}
              scrollToWidget={scrollToWidget}
            />

          ) : null}
        </DashboardExecuteProvider>

        {leftBarExpanded ? (
          <LeftBar
            dashboard={dashboard}
            setEditId={setEditId}
            openDashboard={openDashboard}
            onClose={() => setLeftBarExpanded(false)}
          />
        ) : null}

        {editId ? (
          <FormDashboard
            id={editId}
            templateId={dashboard?.templateId}
            onClose={() => setEditId(null)}
            dialog={{
              title: intl.t('dashboards.titleUpdate'),
              dataTestid: 'updateDashboardDialog',
            }}
          />
        ) : null}

        {editWidget ? (
          <FormWidget
            id={editWidget.id}
            templateId={dashboard.templateId}
            onClose={() => setEditWidget(null)}
            dialog={{
              title: intl.t(editWidget.info.name),
              dataTestid: 'updateWidgetDialog',
            }}
          />
        ) : null}

        {duplicateWidget ? (
          <FormDuplicateWidget
            id={duplicateWidget.id}
            onClose={() => setDuplicateWidget(null)}
            dialog={{
              title: intl.t('dashboards.titleDuplicate'),
              dataTestid: 'duplicateWidgetDialog',
            }}
          />
        ) : null}
      </div>
    </div>
  )
}

export default withResizeObserver(forwardRef(View))
