/* eslint-disable no-nested-ternary */
import {
  useMemo,
  useEffect,
  useRef,
} from 'react'
import classnames from 'classnames'
import { useDidUpdate } from '@wiz/components'
import { echarts, round, orderBy } from '@wiz/utils'
import DateRelative from '@/components/DateRelative'
import { useTheme } from '@/theme'

const Gauge = ({
  widget,
  config,
  sensor,
  conditions,
  data,
  prevData,
}) => {
  const refChart = useRef(null)
  const refTarget = useRef(null)
  const theme = useTheme()
  const mini = widget.gridH <= 5 || widget.gridW <= 5
  const micro = widget.gridH <= 4 || widget.gridW <= 4
  const unitSymbol = sensor.unit?.symbol && sensor.unit.symbol !== 'Other' ? ` ${sensor.unit.symbol}` : ''
  const dataValue = data?.value
  const prevDataValue = prevData?.value

  const ranges = useMemo(() => {
    const interval = config.maxInterval - config.minInterval
    return orderBy(conditions.map(item => ([
      (item.payload.between.to - config.minInterval) / interval,
      item.color,
      item.name,
    ])), [ 0 ], 'asc')
  }, [ config, conditions ])

  const properties = useMemo(() => ({
    animation: false,
    ...(conditions.some(item => item.name) ? ({
      visualMap: {
        show: !micro,
        type: 'piecewise',
        hoverLink: false,
        selectedMode: false,
        pieces: conditions.filter(item => item.name).map(item => ({
          gte: item.payload.between.from,
          lte: item.payload.between.to,
          color: item.color,
          label: item.name,
        })),
      },
    }) : undefined),
    series: [
      {
        type: 'gauge',
        center: micro ? [ '50%', '50%' ] : [ '50%', '55%' ],
        radius: '100%',
        min: config.minInterval,
        max: config.maxInterval,
        splitNumber: 8,
        axisLine: {
          lineStyle: {
            width: mini ? 2 : 4,
            color: ranges.length ?
              ranges :
              [[ 1, theme.echarts.gauge.itemStyle.normal.borderColor ]],
          },
        },
        pointer: {
          itemStyle: {
            color: 'auto',
          },
        },
        axisTick: {
          distance: micro ? -2 : (mini ? 5 : 10),
          length: 4,
          lineStyle: {
            color: 'auto',
            width: 1,
          },
        },
        splitLine: {
          distance: micro ? -2 : (mini ? 5 : 10),
          length: 12,
          lineStyle: {
            color: 'auto',
            width: 2,
          },
        },
        axisLabel: {
          color: 'auto',
          fontSize: mini ? 10 : 16,
          distance: mini ? 5 : 10,
          show: !micro,
          formatter: value => round(value, 2),
        },
        title: {
          offsetCenter: [ 0, '80%' ],
          fontSize: micro ? 10 : 14,
          show: Number.isFinite(prevDataValue),
        },
        detail: {
          fontSize: micro ? 12 : (mini ? 16 : 25),
          offsetCenter: [ 0, '40%' ],
          valueAnimation: true,
          formatter: value => (Number.isFinite(value) ?
            `${String(round(value, config.precision))}${unitSymbol}` : ''),
          color: 'auto',
        },
        data: [
          {
            value: Number.isFinite(dataValue) ? dataValue : NaN,
            visualMap: false,
            name: Number.isFinite(prevDataValue) ?
              `${String(round(prevDataValue, config.precision))}${unitSymbol}` : '',
          },
        ],
      },
    ],
  }), [ mini, micro, unitSymbol, dataValue, config, ranges, conditions, prevDataValue, theme ])

  useEffect(() => {
    const chart = echarts.init(refTarget.current, theme.echarts)

    chart.setOption(properties, {
      notMerge: true,
      lazyUpdate: true,
      silent: true,
    })

    refChart.current = chart

    return () => {
      chart?.dispose()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  useEffect(() => {
    if (!Number.isFinite(dataValue)) {
      refChart.current?.showLoading({
        text: '',
        maskColor: 'rgba(255, 255, 255, 0)',
        spinnerRadius: 20,
      })
    }
    return () => {
      refChart.current?.hideLoading()
    }
  }, [ dataValue ])

  useDidUpdate(() => {
    refChart.current?.resize()
  }, [ widget.gridH, widget.gridW ])

  useDidUpdate(() => {
    refChart.current?.setOption(properties, {
      notMerge: true,
      lazyUpdate: true,
      silent: true,
    })
  }, [ properties ])

  return (
    <div className="d-flex flex-column justify-content-end flex-fill min-h-0 position-relative">
      <div
        ref={refTarget}
        className="position-absolute-fill"
      />

      <div className="position-absolute-fill" />

      {data?.timestamp ? (
        <div
          className={classnames('d-flex align-items-center justify-content-end lh-1', {
            small: micro,
          })}
        >
          <DateRelative value={data.timestamp} />
        </div>
      ) : null}
    </div>
  )
}

export default Gauge
