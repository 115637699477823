import { useIntl } from '@wiz/intl'
import {
  Badge,
  Icon,
  OverflowCenter,
  Card,
} from '@wiz/components'
import Observe from '@/containers/Observe'

export default function Detail ({ row }) {
  const intl = useIntl()
  const item = row.original

  return (
    <div>
      <Observe query={item.queryAccessPermissions}>
        {({ data }) => (
          <div className="d-flex flex-wrap">
            {data.map(permission => (
              <Card key={permission.id} className="mb-2 me-2">
                <div>
                  {permission.reason ? (
                    <div className="d-flex min-w-0">
                      <OverflowCenter value={permission.reason} />
                    </div>
                  ) : null}

                  {permission.inverted ? <Icon className="me-1" name="fa--not-equal" /> : null}
                  {permission.subject?.length ? (
                    <Badge className="bg-secondary me-1">{permission.subject.join(', ')}</Badge>
                  ) : null}
                  {permission.fields?.length ? (
                    <Badge className="bg-secondary me-1">{permission.fields.join(', ')}</Badge>
                  ) : null}
                  {permission.actions?.length ? (
                    <Badge className="bg-warning me-1">{permission.actions.join(', ')}</Badge>
                  ) : null}
                  {permission.conditions?.length ? (
                    <Badge className="bg-info me-1">
                      {permission.conditions.map(id => intl.t(`conditions.names.${id}`)).join(', ')}
                    </Badge>
                  ) : null}
                </div>
              </Card>
            ))}
          </div>
        )}
      </Observe>

      <Observe query={item.queryAccessRoles}>
        {({ data }) => (do {
          if (data.length) {
            <>
              <h6>{intl.t('roles.form.fields.roles')}</h6>
              {data.map(role => (
                <Badge
                  key={role.id}
                  className="bg-secondary me-1 mb-1"
                >
                  {role.name || role.code}
                </Badge>
              ))}
            </>
          } else {
            null
          }
        })}
      </Observe>
    </div>
  )
}
