import Component from '@/components/StreamJobs/RightBar/Shapes'
import { withObservables } from '@wiz/components'
import { dbProvider, Q } from '@wiz/store'
import { switchMap } from 'rxjs/operators'
import { of } from 'rxjs'

const enhanceShapes = withObservables([], () => {
  const query = dbProvider.database.collections.get('integrations').query(Q.where('type', 'sns')).observeWithColumns([ 'updated_at' ])

  return {
    snsAvailable: query.pipe(
      switchMap(items => (items?.length && items[0].isOn ? of(true) : of(false))),
    ),
  }
})

export default enhanceShapes(Component)
