import { useMemo } from 'react'
import filesize from 'filesize'
import { Table as StaticTable, Pagination } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import Observe from '@/containers/Observe'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ButtonFileDownload from '@/components/Form/ButtonFileDownload'

export default function Table ({
  items,
  onRemove,
  onEdit,
  ...props
}) {
  const intl = useIntl()
  const auth = useAuth()
  const columns = useMemo(() => [
    {
      Header: intl.t('files.form.fields.name'),
      accessor: 'name',
      minWidth: 300,
      Cell: ({ cell, row }) => (
        <div className="d-flex align-items-center min-w-0">
          {auth.checkAccessUpdate(row.original) ? (
            <a
              className="text-truncate pointer"
              onClick={() => onEdit?.(row.original.id)}
              aria-hidden
            >
              {cell.value}
              {row.original.description ? (
                <div className="font-italic text-muted text-truncate small">
                  {row.original.description}
                </div>
              ) : null}
            </a>
          ) : cell.value}
        </div>
      ),
    },
    {
      Header: intl.t('files.form.fields.fileType'),
      accessor: 'type',
      minWidth: 120,
    },
    {
      Header: intl.t('files.form.fields.contentType'),
      accessor: 'contentType',
      minWidth: 120,
    },
    {
      Header: intl.t('files.form.fields.size'),
      accessor: 'length',
      minWidth: 120,
      Cell: ({ cell, row }) => (
        <ButtonFileDownload file={row.original}>
          {filesize(parseInt(cell.value, 10))}
        </ButtonFileDownload>
      ),
    },
    {
      Header: intl.t('form.fields.project'),
      disableResizing: true,
      disableSortBy: true,
      minWidth: 130,
      width: 130,
      maxWidth: 130,
      Cell: ({ row }) => (
        <Observe query={row.original.queryProjects}>
          {({ data }) => (
            <div className="d-flex flex-wrap min-w-0 align-items-center">
              {data.map((item, idx) => (
                <div key={item.id} className="d-flex me-1 min-w-0">
                  <div className="text-truncate">
                    {item.name}
                  </div>
                  {idx === data.length - 1 ? null : ','}
                </div>
              ))}
            </div>
          )}
        </Observe>
      ),
    },
    {
      Header: intl.t('form.fields.updated'),
      accessor: 'updatedAt',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 220,
      Cell: ({ cell, row }) => (<UpdatedInfo date={cell.value} user={row.original.updatedUser} />),
    },
    {
      Header: intl.t('form.fields.created'),
      accessor: 'createdAt',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 220,
      Cell: ({ cell, row }) => (<UpdatedInfo date={cell.value} user={row.original.createdUser} />),
    },
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      className: 'justify-content-end text-nowrap',
      Cell: ({ cell, row }) => (
        <>
          {auth.checkAccessUpdate(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0 me-2"
              onClick={() => onEdit?.(cell.value)}
            >
              {intl.t('form.actions.edit')}
            </button>
          ) : null}
          {auth.checkAccessRemove(row.original) ? (
            <button
              type="button"
              className="btn btn-sm p-0"
              onClick={() => onRemove?.([ row.original ])}
            >
              {intl.t('form.actions.remove')}
            </button>
          ) : null}
        </>
      ),
    },
  ], [ intl, onEdit, onRemove, auth ])

  return (
    <>
      <StaticTable
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={items}
        selection={auth.checkAccessRemove('File') || auth.checkAccessCreate('File')}
        {...props}
      />

      <Pagination {...props} />
    </>
  )
}
