/* eslint-disable react/no-array-index-key */
import { useRef } from 'react'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import ModelView from '@/components/Dialogs/ModelView'
import { decodeBytes } from '@/utils/experiments'
import CellTwin from './CellTwin'
import CellBoard from './CellBoard'

export default function ViewModel ({
  title,
  experiment,
  exclude,
  onClose,
  onRemove,
  onFilter,
}) {
  const refDialog = useRef()
  const intl = useIntl()
  const auth = useAuth()

  const handleFilter = (filter) => {
    refDialog.current.close()
    onFilter?.(filter)
  }
  const decoded = decodeBytes(experiment?.response)
  const cleanDecoded = decoded.replace(/\r?\n|\r|\0|/g, '')
  const response = JSON.parse(cleanDecoded || '{}')

  const data = [
    {
      title: intl.t('form.sections.general'),
      options: [
        {
          title: intl.t('form.fields.name'),
          value: experiment.name || '-',
        },
        {
          title: intl.t('form.fields.type'),
          value: intl.t(`enum.experimentType.${experiment.type}`),
        },
        {
          title: intl.t('form.fields.status'),
          value: intl.t(`enum.experimentStatus.${experiment.status}`),
        },
        {
          title: intl.t('form.fields.executDate'),
          value: () => (experiment.lastExecutionDate ?
            <UpdatedInfo date={experiment.lastExecutionDate} /> : '-'),
        },
        {
          title: intl.t('form.fields.twin'),
          value: () => (
            <CellTwin
              experiment={experiment}
              onFilter={handleFilter}
            />
          ),
        },
        {
          title: intl.t('form.fields.board'),
          value: () => (
            <CellBoard
              experiment={experiment}
              onFilter={exclude?.includes('boardId') ? undefined : handleFilter}
            />
          ),
        },
        {
          title: intl.t('experiments.form.fields.function'),
          value: experiment.function,
        },
        {
          title: intl.t('experiments.form.fields.request'),
          value: () => (
            <pre className="overflow-auto form-control" style={{ maxHeight: 200 }}>
              {JSON.stringify(JSON.parse(experiment.request), null, 2)}
            </pre>
          ),
        },
        {
          title: intl.t('experiments.form.fields.response'),
          value: () => (
            <pre className="overflow-auto form-control" style={{ maxHeight: 200 }}>
              {JSON.stringify(response, null, 2)}
            </pre>
          ),
        },
      ],
    },

    {
      title: intl.t('form.fields.updated'),
      value: () => (
        <UpdatedInfo
          date={experiment.updatedAt}
          user={experiment.updatedUser}
        />
      ),
    },
    {
      title: intl.t('form.fields.created'),
      value: () => (
        <UpdatedInfo
          date={experiment.createdAt}
          user={experiment.createdUser}
        />
      ),
    },
  ]

  const actions = [
    ...(onRemove && auth.checkAccessRemove(experiment) ? ([{
      title: intl.t('form.actions.remove'),
      name: 'remove',
      testid: 'removeButton',
      onClick: () => onRemove([ experiment ]),
    }]) : []),
  ]

  return (
    <ModelView
      refDialog={refDialog}
      title={title}
      data={data}
      actions={actions}
      onClose={onClose}
    />
  )
}
