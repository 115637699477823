import { forwardRef, useState, useCallback } from 'react'
import classnames from 'classnames'
import { Icon } from '@wiz/components'
import { Sensor } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import SensorList from '@/hoc/SensorList'
import SensorTree from '@/hoc/SensorTree'
import FilteredSensorTree from '@/hoc/FilteredSensorTree'
import UnitList from '@/hoc/UnitList'
import CategorieList from '@/hoc/CategorieList'
import BusinessTypeList from '@/hoc/BusinessTypeList'
import List from '@/components/Form/Tree/List'
import Tree from '@/components/Form/Tree/Tree'
import SelectMode from '@/components/Form/SelectMode'
import ListBadges from '@/components/Form/ListBadges'
import ItemContent from './ItemContent'

const BindedUnitList = UnitList(List)
const BindedUnitsListBadges = UnitList(ListBadges)
const BindedCategorieList = CategorieList(List)
const BindedCategoriesListBadges = CategorieList(ListBadges)
const BindedBusinessTypeList = BusinessTypeList(List)
const BindedBusinessTypeListBadges = BusinessTypeList(ListBadges)
// const BindedSensorTree = SensorTree(Tree)
const BindedSensorTree = FilteredSensorTree(Tree)
const BindedSensorList = SensorList(List)

const DefaultDraggable = data => (Sensor.is(data.payload || data) ? 'true' : undefined)
const DefaultSelectable = data => Sensor.is(data.payload || data)

const ListSensors = ({
  businessType,
  canInputValue,
  className,
  Content = ItemContent,
  draggable,
  onResetFilter,
  postfix,
  filterBusinessTypes,
  selectable = DefaultSelectable,
  unusedOnly,
  hideDefaultFilters = false,
  customLabels,
  customCategories,
  customUnits,
  customBusinessTypes,
  setVisibleOptions,
  excludeBusinessTypes,
  ...props
}, ref) => {
  const intl = useIntl()
  const [ selectList, setSelectList ] = useState(null)
  const [ mode, setMode ] = useState(unusedOnly ? 'list' : null)
  const [ labels, setLabels ] = useState([])
  const [ units, setUnits ] = useState([])
  const [ categories, setCategories ] = useState([])
  const [ businessTypes, setBusinessTypes ] = useState([])

  const handleChangeUnit = useCallback((data) => {
    const next = units.includes(data.id) ?
      units.filter(item => item !== data.id) :
      units.concat(data.id)
    setUnits(next)
  }, [ units ])

  const handleRemoveUnit = useCallback((id) => {
    setUnits(units.filter(item => item !== id))
  }, [ units ])

  const handleActiveUnit = useCallback(data => (
    units.includes(data.id)
  ), [ units ])

  const handleChangeCategory = useCallback((data) => {
    const next = categories.includes(data.id) ?
      categories.filter(item => item !== data.id) :
      categories.concat(data.id)
    setCategories(next)
  }, [ categories ])

  const handleRemoveCategory = useCallback((id) => {
    setCategories(categories.filter(item => item !== id))
  }, [ categories ])

  const handleActiveCategory = useCallback(data => (
    categories.includes(data.id)
  ), [ categories ])

  const handleChangeBusinessType = useCallback((data) => {
    const next = businessTypes.includes(data.id) ?
      businessTypes.filter(item => item !== data.id) :
      businessTypes.concat(data.id)
    setBusinessTypes(next)
  }, [ businessTypes ])

  const handleRemoveBusinessType = useCallback((id) => {
    setBusinessTypes(businessTypes.filter(item => item !== id))
  }, [ businessTypes ])

  const handleActiveBusinessType = useCallback(data => (
    businessTypes.includes(data.id)
  ), [ businessTypes ])

  const handleResetFilter = useCallback(() => {
    setUnits([])
    setCategories([])
    setBusinessTypes([])
    onResetFilter?.()
  }, [ onResetFilter ])

  const Component = mode === 'list' ? BindedSensorList : BindedSensorTree

  return (
    <div className={classnames('d-flex flex-column', className)}>
      <div className="d-flex flex-wrap mx-3 mb-1">
        <div className="d-flex align-items-center flex-wrap">
          {unusedOnly ? null : (
            <SelectMode
              icon={false}
              checked={mode === 'list'}
              onClick={() => setMode(mode === 'list' ? null : 'list')}
            >
              <Icon name={mode === 'list' ? 'fa--bars' : 'fa--folder-tree'} />
            </SelectMode>
          )}
          {hideDefaultFilters ? null : (
            <>
              <SelectMode
                checked={selectList === 'category'}
                onClick={() => setSelectList(selectList === 'category' ? null : 'category')}
              >
                {intl.t('form.fields.category')}
              </SelectMode>

              <BindedCategoriesListBadges
                value={categories}
                selectedOnly
                onRemove={handleRemoveCategory}
              />

              <SelectMode
                checked={selectList === 'unit'}
                onClick={() => setSelectList(selectList === 'unit' ? null : 'unit')}
              >
                {intl.t('form.fields.unit')}
              </SelectMode>

              <BindedUnitsListBadges
                value={units}
                selectedOnly
                onRemove={handleRemoveUnit}
              />

              {!businessType ? (
                <>
                  <SelectMode
                    checked={selectList === 'businessType'}
                    onClick={() => setSelectList(selectList === 'businessType' ? null : 'businessType')}
                  >
                    {intl.t('form.fields.businessType')}
                  </SelectMode>

                  <BindedBusinessTypeListBadges
                    value={businessTypes}
                    selectedOnly
                    filter={filterBusinessTypes}
                    canInputValue={canInputValue}
                    onRemove={handleRemoveBusinessType}
                  />
                </>
              ) : null}
            </>
          )}

          {postfix}
        </div>
      </div>

      {do {
        if (selectList === 'unit') {
          <BindedUnitList
            className="d-flex flex-fill min-h-0"
            active={handleActiveUnit}
            onChange={handleChangeUnit}
          />
        } else if (selectList === 'category') {
          <BindedCategorieList
            className="d-flex flex-fill min-h-0"
            active={handleActiveCategory}
            onChange={handleChangeCategory}
          />
        } else if (selectList === 'businessType') {
          <BindedBusinessTypeList
            className="d-flex flex-fill min-h-0"
            filter={filterBusinessTypes}
            canInputValue={canInputValue}
            active={handleActiveBusinessType}
            onChange={handleChangeBusinessType}
            excludeBusinessTypes={excludeBusinessTypes}
          />
        } else {
          <Component
            {...props}
            ref={ref}
            className="d-flex flex-fill min-h-0 px-2"
            businessType={customBusinessTypes || businessType || businessTypes}
            canInputValue={canInputValue}
            unusedOnly={unusedOnly}
            labels={customLabels || labels}
            categories={customCategories || categories}
            units={customUnits || units}
            Content={Content}
            draggable={draggable === true ? DefaultDraggable : draggable}
            selectable={selectable}
            onResetFilter={handleResetFilter}
            setVisibleOptions={setVisibleOptions}
            excludeBusinessTypes={excludeBusinessTypes}
          />
        }
      }}
    </div>
  )
}

export default forwardRef(ListSensors)
