import { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Formula } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import ObservFormula from '@/containers/Forms/Formula/Observ'
import Section from '@/components/Forms/Formula/Section'
import Select from '@/components/Form/Select'
import FormulaList from '@/hoc/FormulaList'

export default function SettingsSection ({ scope }) {
  const intl = useIntl()
  const {
    register,
    watch,
    setValue,
    clearErrors,
  } = useFormContext()
  const id = watch(`${scope}.formula.id`)

  const handleChange = useCallback(({ formula }) => {
    const next = Formula.toJSON(formula)
    setValue(`${scope}.id`, next.id, { shouldDirty: true })
    setValue(`${scope}.formula`, next, { shouldDirty: true })
    clearErrors(scope)
  }, [ scope, setValue, clearErrors ])

  return (
    <>
      <ObservFormula
        id={id}
        onChange={handleChange}
      />

      <input
        {...register(`${scope}.id`)}
        type="hidden"
        defaultValue={id}
      />

      <Section
        scope={`${scope}.formula`}
        registerId={false}
        title={intl.t('blockFormula.section.formula')}
        description={intl.t('blockFormula.section.formulaDescr')}
        addon={() => (
          <Controller
            name={`${scope}.formula.id`}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                invalid={fieldState.invalid}
                ListSource={FormulaList}
                placeholder={intl.t('form.fields.formulaPlaceholder')}
                className="min-w-0 w-100 w-md-auto mt-2 mt-md-0"
              />
            )}
          />
        )}
      />
    </>
  )
}
