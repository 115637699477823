import { map } from 'rxjs/operators'
import orderBy from 'lodash/orderBy'
import { dbProvider, Q } from '@wiz/store'
import { withProps, withObservables } from '@wiz/components'
import Component from '@/components/dashboards/SearchList'
import { auth } from '@/auth'

const enhanceSettings = withObservables([], () => ({
  settings: dbProvider.observeSettings([
    'homepageDefaultId',
    'lastOpened',
    'spotlightFavorites',
  ]),
}))

const enhanceData = withObservables([
  'search',
  'filters',
  'settings',
  'items',
  'templateId',
], ({
  search,
  filters,
  settings,
  items,
  templateId,
}) => {
  if (items) {
    return { options: items }
  }

  let query = dbProvider.database.collections.get('dashboards').query()

  switch (filters.filterBy) {
    case 'favorite':
      query = query.extend(Q.where('id', Q.oneOf(settings.spotlightFavorites)))
      break
    case 'my':
      query = query.extend(Q.where('created_by_id', auth.getCurrentUserId()))
      break
    case 'public':
      query = query.extend(Q.where('is_global_shared', true))
      break
    case 'shared':
      query = query.extend(
        Q.where('is_global_shared', false),
        Q.on('share_users_dashboards', 'user_id', auth.getCurrentUserId()),
      )
      break
    default:
  }

  if (filters.followedBy) {
    query = query.extend(Q.where('created_by_id', filters.followedBy))
  }

  if (templateId) {
    query = query.extend(Q.where('template_id', templateId))
  }

  if (search) {
    const searchSanitize = Q.sanitizeLikeString(search)
    query = query.extend(Q.or(
      Q.where('title', Q.like(`%${searchSanitize}%`)),
      Q.where('description', Q.like(`%${searchSanitize}%`)),
    ))
  }

  return {
    options: query.observeWithColumns([ 'updated_at' ])
      .pipe(
        map(items => items.filter(item => auth.checkAccessRead(item))),
      ),
  }
})

const enhanceFilters = withProps(({
  options, filters, settings,
}) => {
  let nextOptions
  switch (filters.sortBy) {
    case 'opened':
      nextOptions = orderBy(options, [
        item => (settings.lastOpened?.[item.id] || 0),
      ], [ 'desc' ])
      break
    default:
      nextOptions = orderBy(options, [
        item => item.title.toLowerCase(),
      ], [ 'asc' ])
  }

  return {
    options: nextOptions,
  }
})

export default enhanceSettings(
  enhanceData(
    enhanceFilters(Component),
  ),
)
