import { useCallback, memo, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dbProvider, Twin } from '@wiz/store'
import { withProps } from '@wiz/components'
import { wizataApi } from '@/api'
import events from '@/utils/events'
import FormTwin from '@/components/Forms/Twin'
import enhanceTwin from './enhanceTwin'
import enhanceTwinData from './enhanceTwinData'

const enhanceProps = withProps(() => {
  const [ createdTwinId, setCreatedTwinId ] = useState('')
  const queryClient = useQueryClient()

  const {
    mutateAsync: updateTwin,
  } = useMutation({
    mutationKey: [ 'updateTwin' ],
    mutationFn: data => wizataApi.twinsCore.update(data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/components.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'twinsList' ] })
    },
  })

  const {
    mutateAsync: createTwin,
  } = useMutation({
    mutationKey: [ 'createTwin' ],
    mutationFn: data => wizataApi.twinsCore.create(data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/components.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'twinsList' ] })
    },
  })

  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareReplaceData(context, Twin, data.twin)
    await model.prepareReplaceDashboards(context, data.dashboards)
    await model.prepareReplaceExplorers(context, data.explorers)
    await model.prepareReplaceStreamJobs(context, data.streamJobs)
    await model.prepareReplaceTwinGraphs(context, data.twinGraphs)
    await dbProvider.batch(context)

    if (data.twin.id) {
      await updateTwin(data.twin)
    } else {
      await createTwin({ ...data.twin, id: model?.id })
    }
    setCreatedTwinId(model?.id)
    return model
  }, [])

  return {
    createdTwinId,
    onSubmit,
  }
})

export default memo(
  enhanceTwin(
    enhanceTwinData(
      enhanceProps(FormTwin),
    ),
  ),
)
