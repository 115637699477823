import { useCallback } from 'react'
import { Card } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import { useRouter } from '@/router'
import DeviceCommand from '../containers/DeviceCommand'

export default function Widget ({
  widget,
  config,
  hidden,
  canUpdateDashboard,
  onAction,
}) {
  const router = useRouter()
  const intl = useIntl()
  const auth = useAuth()
  const actions = []

  if (canUpdateDashboard) {
    actions.push({ id: 'settings', name: intl.t('form.actions.settings'), icon: 'fa--cog' })
  }

  if (auth.checkAccessRead('SectionDeviceCommands')) {
    actions.push({ id: 'commands', name: intl.t('form.actions.exploreCommands'), icon: 'fa--terminal' })
  }

  if (auth.checkAccessCopy(widget) && canUpdateDashboard) {
    actions.push({ id: 'duplicate', name: intl.t('form.actions.duplicate'), icon: 'fa--clone' })
    actions.push({ id: 'multiDuplicate', name: intl.t('form.actions.multiDuplicate'), icon: 'fa--folder-plus' })
  }

  if (canUpdateDashboard) {
    actions.push({ id: 'remove', name: intl.t('form.actions.remove'), icon: 'fa--trash-alt' })
  }

  const handleAction = useCallback((action) => {
    if (action.id === 'commands') {
      router.push({
        name: 'edge-commands-list',
        query: {
          sensorId: config.sensorId,
          deviceId: config.deviceId,
        },
      })
    } else {
      onAction?.(action)
    }
  }, [ config, onAction, router ])

  return (
    <Card
      className="position-absolute-fill"
      classNameTitle={canUpdateDashboard ? 'userdrag' : undefined}
      title={widget.title || intl.t(widget.info.name)}
      description={widget.description}
      {...(auth.checkAccessManage('SectionDeviceCommands') ? {
        options: actions,
        onAction: handleAction,
      } : undefined)}
    >
      {do {
        if (!auth.checkAccessManage('SectionDeviceCommands')) {
          <div className="d-flex align-items-center justify-content-center flex-fill">
            {intl.t('widgets.notAccess')}
          </div>
        } else if (config.device && config.template) {
          <DeviceCommand
            hidden={hidden}
            config={config}
          />
        } else if (canUpdateDashboard) {
          <div className="d-flex align-items-center justify-content-center flex-fill">
            <button
              type="button"
              className="btn btn-flat-secondary"
              onClick={() => onAction({ id: 'settings' })}
            >
              {intl.t('widgets.notConfigured')}
            </button>
          </div>
        } else {
          <div className="d-flex align-items-center justify-content-center flex-fill">
            {intl.t('widgets.notConfigured')}
          </div>
        }
      }}
    </Card>
  )
}
