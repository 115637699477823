/* eslint-disable react/jsx-one-expression-per-line */
import classnames from 'classnames'
import { Icon, FormCheckbox } from '@wiz/components'
import { useAuth } from '@/auth'

export default function TreeOption ({
  data,
  isOpen,
  style,
  toggle,
  treeData,
}) {
  const auth = useAuth()
  const { onToggleSubscribe } = treeData
  const { nestingLevel, isLeaf, name } = data
  const item = data.payload

  return (
    <div
      className="list-group-item list-group-item-action d-flex align-items-center text-nowrap"
      style={{
        ...style,
        paddingLeft: `${nestingLevel * 1.5 + (isLeaf ? 1 : 0)}rem`,
      }}
    >
      {isLeaf ? null : (
        <button type="button" className="btn p-0 mx-1" onClick={() => toggle()}>
          <Icon
            name="fa--caret-right"
            className={classnames('arrow', {
              'arrow-open': isOpen,
            })}
          />
        </button>
      )}

      {item.selectable ? (
        <FormCheckbox
          className="me-2"
          value={item.subscribed}
          disabled={!auth.checkAccessUpdate('EdgeEndpointNode')}
          onChange={() => onToggleSubscribe(item.id)}
        />
      ) : null}

      {item.status === 'marked_for_subscription' || item.status === 'marked_for_unsubscription' ? (
        <Icon name="fa--clock" className="me-2" />
      ) : null}

      {item.status === 'failed_to_subscribe' || item.status === 'failed_to_unsubscribe' ? (
        <Icon name="fa--exclamation-circle" className="me-2" />
      ) : null}

      <span className="text-truncate">
        {name}
      </span>

      {item.isNode ? (
        <span className="ms-2 text-truncate">
          (ID: {item.nodeId}, Heardbeat: {item.heartbeatInterval || 0})
        </span>
      ) : null}
    </div>
  )
}
