import { useState, useMemo } from 'react'
import classnames from 'classnames'
import { FormInputSearch, ResponsiveList } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import Option from './Option'

export default function Explorer ({
  autoFocus,
  options,
  className,
  value,
  onChange,
}) {
  const intl = useIntl()
  const immutableValue = JSON.stringify(value)
  const [ search, setSearch ] = useState('')

  const itemData = useMemo(() => ({
    options: options
      .filter(item => item.role !== 'grid')
      .map(item => ({
        id: item.id,
        name: item.name || item.source?.displayName,
        color: item.color,
      }))
      .filter(item => (
        !search ||
        !item.name ||
        item.name.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1
      )),
    value,
    search,
    onChange,
  }), [
    options,
    immutableValue,
    search,
    onChange,
  ])

  return (
    <div className={classnames('d-flex flex-column flex-fill', className)}>
      <div className="d-flex my-2 mx-3">
        <div className="flex-fill">
          <FormInputSearch
            size="sm"
            placeholder={intl.t('form.actions.searchPlaceholder')}
            autoFocus={autoFocus}
            onChange={setSearch}
          />
        </div>
      </div>

      {itemData.options.length ? (
        <ResponsiveList
          className="flex-fill d-flex min-h-0"
          itemCount={itemData.options.length}
          itemData={itemData}
          itemSize={36}
        >
          {Option}
        </ResponsiveList>
      ) : (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          {intl.t('errors.noDataDisplay')}
        </div>
      )}
    </div>
  )
}
