import { of as of$ } from 'rxjs'
import { map } from 'rxjs/operators'
import { withObservables } from '@wiz/components'
import { Q, dbProvider } from '@wiz/store'
import { validate } from 'uuid'

export default withObservables([ 'id' ], ({ id = '' }) => {
  const isEdit = validate(id)

  return {
    integration: isEdit ? dbProvider.database.collections.get('integrations')
      .query(Q.where('id', id))
      .observeWithColumns([ 'updated_at' ])
      .pipe(map(items => items[0])) : of$(undefined),
  }
})
