import PropTypes from 'prop-types'
import { FormSection } from '@wiz/components'
import Fields from './Fields'

export default function Section ({ scope, title, addon }) {
  return (
    <FormSection
      title={title}
      addon={addon}
    >
      <Fields scope={scope} />
    </FormSection>
  )
}

Section.propTypes = {
  scope: PropTypes.string.isRequired,
  title: PropTypes.string,
  addon: PropTypes.any,
}

Section.defaultProps = {
  title: undefined,
  addon: undefined,
}
