import { of as of$, Observable } from 'rxjs'
import { withObservables } from '@wiz/components'
import { wizataApi } from '@/api'

export default withObservables([ 'id' ], ({ id }) => ({
  item: id ? new Observable((subscriber) => {
    wizataApi.entities.getById('dataframes', id)
      .then(data => subscriber.next(data))
      .catch(() => subscriber.next(undefined))
      .finally(() => subscriber.complete())
  }) : of$(undefined),
}))
